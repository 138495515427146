import { memo, useMemo } from 'react';
import classnames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import { colors } from '@/constants/tailwind';
import AnimatedCounter from '@/components/ui/AnimatedCounter';

export interface IDonutColors {
  startColor: string;
  stopColor: string;
  circleColor: string;
}

export type ICreditDonutProps = {
  size?: number;
  creditsCounted: number;
  creditsEarned: number;
  creditsRequired: number;
  donutColors: IDonutColors;
  isLoading?: boolean;
  totalCreditEarnedTooltip?: string;
  showCounter?: boolean;
  donutSize?: 'sm' | 'md' | 'lg' | 'miniTracker';
  className?: string;
  donutBackgroundColor?: string;
};

const CreditDonut = memo(
  ({
    size = 260,
    creditsCounted,
    creditsEarned,
    creditsRequired,
    donutColors,
    isLoading,
    totalCreditEarnedTooltip,
    className,
    showCounter = true,
    donutSize = 'lg',
    donutBackgroundColor = undefined
  }: ICreditDonutProps) => {
    const percentCompleted =
      creditsCounted > creditsRequired ? 100 : (creditsCounted / creditsRequired) * 100;
    const strokeWidth = 30;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const isComplete = percentCompleted === 100;
    const notStarted = percentCompleted === 0 || Number.isNaN(percentCompleted);
    const altText = 'percent of credits completed';
    const uniqueId = useMemo(() => uuid4(), []);

    const circlePath =
      'M130,15.8c15,0,29.9,3,43.7,8.7c13.9,5.7,26.5,14.2,37.1,24.8s19,23.2,24.8,37.1c5.7,13.9,8.7,28.7,8.7,43.7s-3,29.9-8.7,43.7c-5.7,13.9-14.2,26.5-24.8,37.1s-23.2,19-37.1,24.8c-13.9,5.7-28.7,8.7-43.7,8.7s-29.9-3-43.7-8.7c-13.9-5.7-26.4-14.2-37.1-24.8s-19-23.2-24.8-37.1c-5.7-13.9-8.7-28.7-8.7-43.7c0-15,3-29.9,8.7-43.7c5.7-13.9,14.2-26.4,24.8-37.1s23.2-19,37.1-24.8C100.1,18.7,115,15.8,130,15.8L130,15.8z';

    const strokeDashArray = [(percentCompleted * circumference) / 100, circumference].join(',');

    const fillEarnedArray = () => {
      const length = creditsCounted > 1 ? Math.round(creditsCounted - 1) : creditsCounted;
      const array = Array.from(Array(length >= 1 ? length : 1).keys());
      array.push(creditsCounted);

      return array;
    };
    const earnedArray = fillEarnedArray();
    const animationTimeOffset = (percentCompleted / 100) * 2.2;

    const progressGradient = {
      background: `conic-gradient(from 352deg, ${donutColors.startColor} 0%, ${donutColors.stopColor} ${percentCompleted}%)`,
      animation: `${isComplete ? 'colorRotate .25s ease-in-out forwards' : 'none'}`,
      animationDelay: '1930ms'
    };

    const progressAnimation = {
      stroke: `${notStarted ? 'none' : 'black'}`,
      strokeDashoffset: `${(percentCompleted * circumference) / 100}px`,
      animation: `pathStroke-${uniqueId} ${animationTimeOffset}s 1 ease-in-out forwards`,
      animationDelay: '20ms'
    };

    const maskAnimation = {
      strokeDashoffset: `${(percentCompleted * circumference) / 100}px`,
      animation: `pathStroke-${uniqueId} ${animationTimeOffset}s 1 ease-in-out forwards`,
      animationDelay: '20ms'
    };

    const animatedCircle = {
      animation: `moveAlongPath-${uniqueId} ${animationTimeOffset}s 1 ease-in-out forwards`,
      offsetPath: `path('${circlePath}')`,
      offsetAnchor: 'bottom right'
    };

    const checkmarkAnimation = {
      animation: `checkmarkStroke-${uniqueId} 1s 1 cubic-bezier(0, 0, 0.1, 1) forwards`,
      animationDelay: `${animationTimeOffset + 1}s`
    };

    const calculatedOverFlowText = useMemo(() => {
      if (creditsEarned > creditsCounted) return `${creditsEarned} total earned`;
    }, [creditsCounted, creditsEarned]);

    const containerClassNames = classnames('relative fill-none', className, {
      'size-[16.25rem]': donutSize === 'lg',
      'size-[100px]': donutSize === 'md',
      'size-16': donutSize === 'sm',
      'size-[110px]': donutSize === 'miniTracker'
    });

    return (
      <div key={creditsCounted} className={containerClassNames}>
        {isLoading ? (
          <svg
            role="img"
            viewBox={`0 0 ${size} ${size}`}
            aria-label="loading credit donut"
            className="z-50"
          >
            <path
              d={circlePath}
              className="animate-pulse bg-transparent stroke-gray-light"
              strokeWidth={strokeWidth}
            />
          </svg>
        ) : (
          <>
            {donutSize !== 'miniTracker' && (
              <svg
                role="img"
                viewBox={`0 0 ${size} ${size}`}
                style={{ zIndex: 60 }}
                aria-label={`${percentCompleted.toFixed(0).toString()} ${altText}`}
                className="absolute"
              >
                <defs>
                  <mask id={`progressPathMask2-${uniqueId}`}>
                    <rect x="0" y="0" width="100%" height="100%" fill="white" />
                    <path
                      style={maskAnimation}
                      strokeWidth={strokeWidth}
                      strokeDasharray={strokeDashArray}
                      strokeLinecap="round"
                      shapeRendering="geometricPrecision"
                      d={circlePath}
                      stroke="black"
                    />
                    <circle
                      style={animatedCircle}
                      cx={size}
                      cy={size}
                      r={15}
                      fill="black"
                      fillOpacity={notStarted ? '.1' : '1'}
                    ></circle>
                  </mask>
                </defs>
                <path
                  d={circlePath}
                  stroke="#F5F5F5"
                  strokeWidth={strokeWidth}
                  mask={`url(#progressPathMask2-${uniqueId})`}
                />
              </svg>
            )}
            <svg
              role="img"
              viewBox={`0 0 ${size} ${size}`}
              aria-label={`${percentCompleted.toFixed(0).toString()} ${altText}`}
              className="absolute z-50"
            >
              <defs>
                {donutBackgroundColor && (
                  <mask id={`progressPathMask-${uniqueId}`}>
                    <path
                      style={maskAnimation}
                      strokeWidth={strokeWidth}
                      strokeDasharray={strokeDashArray}
                      strokeLinecap="round"
                      shapeRendering="geometricPrecision"
                      d={circlePath}
                      stroke="white"
                    />
                    <circle
                      style={animatedCircle}
                      cx={size}
                      cy={size}
                      r={15}
                      fill="white"
                      fillOpacity={notStarted ? '.1' : '1'}
                    ></circle>
                  </mask>
                )}
                <mask id={`circleMask-${uniqueId}`}>
                  <circle cx="130" cy="130" r="130" fill="white"></circle>
                  <circle cx="130" cy="130" r="99" fill="black"></circle>
                </mask>
              </defs>
              <g mask={donutBackgroundColor ? `url(#progressPathMask-${uniqueId})` : ''}>
                {!notStarted && (
                  <>
                    <foreignObject
                      x="0"
                      y="0"
                      width={size}
                      height={size}
                      mask={donutBackgroundColor ? `url(#circleMask-${uniqueId})` : ''}
                    >
                      <div style={progressGradient} className="size-full rounded-full" />
                    </foreignObject>
                    <g
                      style={{ mixBlendMode: 'screen' }}
                      mask={donutBackgroundColor ? `url(#circleMask-${uniqueId})` : ''}
                    >
                      <rect
                        style={{ fill: 'white' }}
                        x="0"
                        y="0"
                        width={size}
                        height={size}
                        shapeRendering="geometricPrecision"
                      />
                      <path
                        style={progressAnimation}
                        strokeWidth={strokeWidth}
                        strokeDasharray={strokeDashArray}
                        strokeLinecap="round"
                        shapeRendering="geometricPrecision"
                        d={circlePath}
                        vectorEffect="non-scaling-stroke"
                      />
                    </g>
                  </>
                )}
                <path
                  d={circlePath}
                  className={showCounter ? 'stroke-black/5' : ''}
                  strokeWidth={strokeWidth}
                />
                <circle
                  style={animatedCircle}
                  cx={size}
                  cy={size}
                  r={15}
                  fill={notStarted ? '#000000' : donutColors.circleColor}
                  fillOpacity={notStarted ? '.1' : '1'}
                ></circle>
                <circle style={animatedCircle} cx={size} cy={size} r={3} fill="white"></circle>
              </g>
            </svg>
            {donutBackgroundColor && (
              <svg role="img" viewBox={`0 0 ${size} ${size}`} className="absolute z-40">
                <path
                  d={circlePath}
                  style={{ stroke: `#${donutBackgroundColor}` }}
                  strokeWidth={strokeWidth}
                />
              </svg>
            )}
            <div className="absolute left-1/2 top-1/2 z-[61] m-auto w-fit -translate-x-1/2 -translate-y-1/2">
              {isComplete && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="m-auto flex flex-col items-center justify-center"
                  width="37"
                  height="26"
                  fill="none"
                >
                  <path
                    style={checkmarkAnimation}
                    stroke={colors.serpentine.DEFAULT}
                    strokeLinecap="square"
                    strokeWidth="7"
                    strokeDasharray="36,100"
                    strokeDashoffset="36"
                    d="M5.345 12.797 13.37 21 31.656 5"
                    vectorEffect="non-scaling-stroke"
                  />
                </svg>
              )}
              {showCounter && (
                <AnimatedCounter
                  containerClassName={`mt-2 flex h-8 w-full flex-col items-center justify-center overflow-hidden`}
                  total={creditsRequired}
                  numbers={earnedArray}
                  description={!isComplete ? 'Counted / Required' : ''}
                  overFlowText={calculatedOverFlowText}
                  hexColor={
                    !isComplete && donutColors.stopColor === '#CF4B00' ? donutColors.stopColor : ''
                  }
                  animationOffset={animationTimeOffset}
                  totalCreditEarnedTooltip={totalCreditEarnedTooltip}
                />
              )}
            </div>
            <style>
              {`
          @keyframes pathStroke-${uniqueId} {
            0% {  stroke-dashoffset: ${((percentCompleted * circumference) / 100 - 1).toFixed(0)};}
            100% { stroke-dashoffset: 0; }
          }
          @keyframes checkmarkStroke-${uniqueId} {
            0% {  stroke-dashoffset: 36; }
            100% { stroke-dashoffset: 0; }
          }
          @keyframes colorRotate {
            0% { background: conic-gradient(from 352deg, ${donutColors.startColor} 0%, ${donutColors.stopColor} ${percentCompleted}%);}
            100% { background: conic-gradient(from 357deg, ${donutColors.startColor} 0%, ${donutColors.stopColor} ${percentCompleted}%);}
          }
          @keyframes moveAlongPath-${uniqueId} {
             0% { offset-distance: 0%; }
            100% {
              offset-distance: ${percentCompleted < 50 || isComplete ? percentCompleted : (percentCompleted + 1).toFixed(0)}%;
            }
          }
        `}
            </style>
          </>
        )}
      </div>
    );
  }
);

CreditDonut.displayName = 'CreditDonut';
export default CreditDonut;
