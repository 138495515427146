import BaseCard, { ThumbnailProps } from '@/components/ui/Cards/BaseCard';
import { MenuItemProps } from '@/components/ui/Menu/MenuItem';
import { DateType } from '@/components/ui/DateTag';
import { TagProps } from '@/components/ui/Tag';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import ShareModalCard from '@/components/ui/Cards/ShareModalCard';
import { useState, useCallback, useEffect, useMemo } from 'react';
import RemoveLibraryModalCard from '@/components/ui/Cards/RemoveLibraryModal';
import { useAddToLibraryMutation, useRemoveFromLibraryMutation } from '@/redux/api/client/library';
import useToast from '@/hooks/useToast';
import { ConfirmRemoveButtonToast } from '@/components/ui/Cards/ConfirmRemoveButtonToast';
import { SaveToLibraryToast } from '@/components/ui/Cards/SaveToLibraryToast';
import BaseCardList from '@/components/ui/Cards/BaseCardList';
import ProgramMaterialsModal from '../PDP/Modals/ProgramMaterialsModal';
import { MaterialsLabels } from '@/components/ui/PDP/labels';
import { useLazyGetProgramMaterialsQuery } from '@/redux/api/client/library';
import { ItemAnalyticsModel, ProgramMaterial } from '@/@types/client-api';
import { useGetMediaStreamsQuery } from '@/redux/api/client/product';
import EcommPanel from '../SearchResults/EcommPanel.tsx';
import { EcommPanelParams } from '../SearchResults/hooks/useEcommData';
import { SearchableType } from '../SearchResults/searchableTypeToSnippetVariant.ts';
import moreLikeThis from '@/utils/moreLikeThis.ts';
import { useSelector } from 'react-redux';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors.ts';
import { useLaunch } from '@/hooks/shared/useLaunch.ts';
import { useAddToCart } from '@/hooks/shared/useAddToCart.tsx';
import { v4 as uuid4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { launchNotifyMePopup } from '@/utils/helpers.ts';
import { AnalyticsContext } from '@/analytics/constants.ts';
import { trackAddWishListEvent, trackRemoveWishListEvent } from '@/analytics/ecommerce.ts';
import { trackCardRequestCreditEvent } from '@/analytics/cards.ts';

enum Type {
  Standard = 'standard',
  Faculty = 'faculty',
  Library = 'library'
}

export interface ProgramCardProps {
  image?: {
    url: string;
    alt?: string;
  };
  video?: {
    url: string;
    autoPlay?: boolean;
    muted?: boolean;
  };
  dates?: DateType;
  tag?: TagProps;
  variant?:
    | 'notRegistered'
    | 'registeredOnline'
    | 'registeredInPerson'
    | 'live'
    | 'liveRegistered'
    | 'onDemand'
    | 'onDemandRetail'
    | 'notifyMe';
  size?: 'small' | 'medium' | 'large';
  heading?: string;
  labelDetail?: string;
  labelInfo?: string;
  href?: string;
  target?: string;
  onClickCard?: () => void;
  listPosition?: number;
  variationPk?: string;
  isSaved?: boolean;
  heightAuto?: boolean;
  widthAuto?: boolean;
  labelIcon?: string;
  showAsList?: boolean;
  forceGetVideo?: boolean;
  searchableType?: string;
  productCode?: string | null;
  certificateRequestUrl?: string | null;
  hasCreditsForRequest?: boolean;
  type?: `${Type}`;
  guidValue?: string | null;
  isPurchased?: boolean;
  watchUrl?: string | null;
  clickOnCardContent?: () => void;
  creditTrackerCode?: string;
  itemAnalyticsModel?: ItemAnalyticsModel;
}

interface ProgramCardLabelProps extends ProgramCardProps {
  labelDetailIcon?: string;
  color?: 'dark' | 'light';
  notifyMeUrl?: string;
}

const ProgramCard: React.FC<ProgramCardLabelProps> = ({
  variant,
  image,
  video,
  dates,
  tag,
  variationPk,
  isSaved,
  labelIcon,
  showAsList,
  forceGetVideo,
  searchableType,
  productCode,
  notifyMeUrl,
  certificateRequestUrl,
  hasCreditsForRequest = false,
  type = Type.Standard,
  guidValue,
  isPurchased,
  watchUrl,
  clickOnCardContent,
  creditTrackerCode,
  itemAnalyticsModel,
  ...props
}) => {
  const customModalId = useMemo(() => `program-card-modal-${uuid4()}`, []);
  const customPanelId = useMemo(() => `program-card-panel-${uuid4()}`, []);

  const { launch } = useLaunch(AnalyticsContext.Cards, creditTrackerCode);
  const { addToCart } = useAddToCart(undefined, creditTrackerCode);

  const pageLinkPaths = useSelector(selectPageLinkPaths);
  const searchPageUrl = pageLinkPaths?.Search;

  let videoProps;
  let buttonOptionsVariation;
  let buttonOptions: (MenuItemProps | { separator: true })[];
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [openProgramMaterials, setOpenProgramMaterials] = useState(false);
  const [openEcom, setOpenEcom] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [materials, setMaterials] = useState<ProgramMaterial[]>([]);
  const [addToLibrary] = useAddToLibraryMutation();
  const [removeFromLibrary] = useRemoveFromLibraryMutation();
  const [getMaterials] = useLazyGetProgramMaterialsQuery();
  const { showSuccessMessage, showFailMessage } = useToast();
  const [isSavedToLibrary, setIsSavedToLibrary] = useState(false);
  let params: EcommPanelParams | undefined;

  const getStreamingUrl = variant === 'live' || variant === 'liveRegistered' || forceGetVideo;
  const showRequestCreditOption = hasCreditsForRequest && type == Type.Library;

  const { data: productMedia } = useGetMediaStreamsQuery(variationPk!, {
    skip: !variationPk || !getStreamingUrl
  });
  let streamingUrl = '';
  if (getStreamingUrl) {
    if (productMedia?.length) {
      streamingUrl = productMedia[0].playbackUrl || '';
    }
  }

  const belowLg = useMediaQuery(ScreenSizeQueries.belowLg);
  const navigate = useNavigate();

  if (video) {
    videoProps = {
      url: video!.url,
      autoPlay: true
    };
  }

  let thumbnail: ThumbnailProps = {
    dates,
    tag,
    video: streamingUrl ? { ...videoProps, url: streamingUrl } : undefined,
    forceShowVideoPreview: streamingUrl ? true : undefined
  };

  let programCardProps: ProgramCardLabelProps = {
    ...props
  };

  const buildButtonVariations = (
    label: string,
    icon: string,
    onClickButton: () => void,
    buttonOptions: (MenuItemProps | { separator: true })[]
  ) => {
    return [{ label: label, onClick: onClickButton, icon: icon }, ...(buttonOptions ?? [])];
  };

  const shareButtonFunction = () => {
    setIsOpenShareModal(!isOpenShareModal);
  };

  const accessMaterialButtonFunction = () => {
    handleLoadMaterials();
    setOpenProgramMaterials(!openProgramMaterials);
  };

  const removeButtonFunction = () => {
    setIsOpenRemoveModal(!isOpenRemoveModal);
  };

  const removeFromLibraryQuery = useCallback(
    async (pk: string, undoAction?: boolean) => {
      const dataResponse = await removeFromLibrary(pk);
      trackRemoveWishListEvent(itemAnalyticsModel, AnalyticsContext.Cards);
      if ('error' in dataResponse) {
        return { error: true };
      } else if ('data' in dataResponse && undoAction) {
        setIsSavedToLibrary(false);
        return showSuccessMessage('Item Removed from library');
      } else if ('data' in dataResponse) {
        setIsSavedToLibrary(false);
        return { data: true };
      }
    },
    [setIsSavedToLibrary, removeFromLibrary, showSuccessMessage, itemAnalyticsModel]
  );

  const addToLibraryQuery = useCallback(
    async (pk: string, undoAction?: boolean) => {
      const dataResponse = await addToLibrary(pk);

      if ('error' in dataResponse) {
        return { error: true };
      } else if ('data' in dataResponse && undoAction) {
        setIsSavedToLibrary(true);
        return showSuccessMessage('Item Added to library');
      } else if ('data' in dataResponse) {
        return { data: true };
      }
    },
    [addToLibrary, showSuccessMessage, setIsSavedToLibrary]
  );

  const confirmRemoveButtonFunction = useCallback(
    async (pk: string) => {
      const onClickFunction = () => {
        addToLibraryQuery(variationPk!, true);
      };

      const toastNode = (
        <ConfirmRemoveButtonToast
          message="Thanks for the feedback, the program has been removed. "
          onClickFunction={onClickFunction}
        />
      );

      const removeResponse = await removeFromLibraryQuery(pk);

      if (removeResponse && 'error' in removeResponse) {
        showFailMessage('Error while removing item to library');
      } else if (removeResponse && 'data' in removeResponse) {
        showSuccessMessage(toastNode);
        setIsSavedToLibrary(false);
      }
    },
    [
      setIsSavedToLibrary,
      addToLibraryQuery,
      removeFromLibraryQuery,
      showSuccessMessage,
      showFailMessage,
      variationPk
    ]
  );

  const saveToLibraryFunction = useCallback(
    async (pk: string) => {
      const onClickFunction = () => {
        removeFromLibraryQuery(variationPk!, true);
      };

      const toastNode = (
        <SaveToLibraryToast message="Saved item to library. " onClickFunction={onClickFunction} />
      );

      const addResponse = await addToLibraryQuery(pk);

      trackAddWishListEvent(itemAnalyticsModel, AnalyticsContext.Cards);

      if (addResponse && 'error' in addResponse) {
        showFailMessage('Error while adding item to library');
      } else if (addResponse && 'data' in addResponse) {
        showSuccessMessage(toastNode);
        setIsSavedToLibrary(true);
      }
    },
    [
      addToLibraryQuery,
      removeFromLibraryQuery,
      showFailMessage,
      showSuccessMessage,
      variationPk,
      itemAnalyticsModel
    ]
  );

  const handleWatchOrLaunch = useCallback(() => {
    if (isPurchased && watchUrl) {
      launch(variationPk, false, watchUrl, itemAnalyticsModel);
    } else {
      launch(variationPk!, undefined, undefined, itemAnalyticsModel);
    }
  }, [isPurchased, watchUrl, launch, variationPk, itemAnalyticsModel]);

  const shareButtonOption = { label: 'Share', onClick: shareButtonFunction, icon: 'share' };

  const removeFromLibraryOption = {
    label: 'Remove from library',
    onClick: removeButtonFunction,
    icon: 'delete'
  };

  const saveToLibraryOption = {
    label: 'Save to my library',
    onClick: () => saveToLibraryFunction(variationPk!),
    icon: 'add'
  };

  const requestCreditOption = {
    label: 'Request credits',
    onClick: () => {
      trackCardRequestCreditEvent(itemAnalyticsModel, AnalyticsContext.Cards);
      window.open(certificateRequestUrl ?? undefined, '_blank');
    },
    icon: 'credits',
    visible: showRequestCreditOption && certificateRequestUrl
  };

  useEffect(() => {
    setIsSavedToLibrary(Boolean(isSaved));
  }, [isSaved]);

  const saveToLibrary = isSavedToLibrary ? removeFromLibraryOption : saveToLibraryOption;

  const accessMaterials = {
    label: 'Access Materials',
    onClick: accessMaterialButtonFunction,
    icon: 'publications',
    visible: isPurchased
  };

  const handleLoadMaterials = useCallback(() => {
    setLoading(true);
    getMaterials(variationPk!, true)
      .then(response => setMaterials(response.data ?? []))
      .catch(() => setMaterials([]))
      .finally(() => setLoading(false));
  }, [getMaterials, variationPk]);

  const openEcomPanel = useCallback(() => {
    setOpenEcom(true);
  }, []);

  const moreLikeThisFunc = useCallback(
    () => moreLikeThis(navigate, productCode, searchPageUrl, itemAnalyticsModel),
    [navigate, productCode, searchPageUrl, itemAnalyticsModel]
  );

  switch (variant) {
    case 'notRegistered':
      thumbnail = {
        ...thumbnail,
        image,
        iconThumbnail: { name: 'upcoming', label: 'Register now' }
      };

      buttonOptions = [
        saveToLibrary,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'Register now',
          'upcoming',
          openEcomPanel,
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      params = {
        pk: variationPk!,
        url: props.href!,
        title: props.heading!,
        searchableType: searchableType as SearchableType,
        action: 'Register',
        guidValue: guidValue ?? null
      };

      programCardProps = {
        ...programCardProps,
        onClickCard: openEcomPanel
      };
      break;
    case 'registeredOnline':
      thumbnail = {
        ...thumbnail,
        image,
        iconThumbnail: { name: 'global', label: 'View registration' }
      };

      buttonOptions = [
        accessMaterials,
        saveToLibrary,
        requestCreditOption,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'View registration',
          'global',
          openEcomPanel,
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      params = {
        pk: variationPk!,
        url: props.href!,
        title: props.heading!,
        searchableType: searchableType as SearchableType,
        action: 'ViewRegistration',
        guidValue: guidValue ?? null
      };

      programCardProps = {
        ...programCardProps,
        labelDetailIcon: 'checkmark',
        onClickCard: openEcomPanel
      };
      break;
    case 'registeredInPerson':
      thumbnail = {
        ...thumbnail,
        image,
        iconThumbnail: {
          name: showRequestCreditOption ? 'credits' : 'location-pin',
          label: showRequestCreditOption ? 'Request credits' : 'View registration'
        }
      };

      buttonOptions = [
        accessMaterials,
        saveToLibrary,
        requestCreditOption,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'View registration',
          'location-pin',
          openEcomPanel,
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      params = {
        pk: variationPk!,
        url: props.href!,
        title: props.heading!,
        searchableType: searchableType as SearchableType,
        action: 'ViewRegistration',
        guidValue: guidValue ?? null
      };

      programCardProps = {
        ...programCardProps,
        labelDetailIcon: 'checkmark',
        onClickCard: () => {
          if (showRequestCreditOption)
            trackCardRequestCreditEvent(itemAnalyticsModel, AnalyticsContext.Cards);
          openEcomPanel();
        }
      };
      break;
    case 'live':
      thumbnail = {
        ...thumbnail,
        image,
        video: streamingUrl ? { ...videoProps, url: streamingUrl } : video,
        tag: { children: 'Live', variant: 'emphasis' },
        iconThumbnail: { name: 'play', label: 'Join now' },
        forceShowVideoPreview: true
      };

      buttonOptions = [
        accessMaterials,
        saveToLibrary,
        requestCreditOption,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'Join now',
          'play',
          () => handleWatchOrLaunch(),
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      programCardProps = {
        ...programCardProps,
        color: 'dark',
        onClickCard: () => handleWatchOrLaunch()
      };
      break;
    case 'liveRegistered':
      thumbnail = {
        ...thumbnail,
        image,
        video: streamingUrl ? { ...videoProps, url: streamingUrl } : video,
        tag: { children: 'Live', variant: 'emphasis' },
        iconThumbnail: { name: 'play', label: 'Join now' },
        forceShowVideoPreview: true
      };

      buttonOptions = [
        accessMaterials,
        saveToLibrary,
        requestCreditOption,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'Join now',
          'play',
          () => handleWatchOrLaunch(),
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      programCardProps = {
        ...programCardProps,
        color: 'dark',
        labelDetailIcon: 'checkmark',
        onClickCard: () => handleWatchOrLaunch()
      };
      break;
    case 'onDemand':
      thumbnail = {
        ...thumbnail,
        image,
        tag,
        iconThumbnail: {
          name: showRequestCreditOption ? 'credits' : 'play',
          label: showRequestCreditOption ? 'Request credits' : 'Launch Now'
        }
      };

      buttonOptions = [
        accessMaterials,
        saveToLibrary,
        requestCreditOption,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'Launch now',
          'play',
          () => handleWatchOrLaunch(),
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      programCardProps = {
        ...programCardProps,
        onClickCard: () => {
          if (showRequestCreditOption) {
            trackCardRequestCreditEvent(itemAnalyticsModel, AnalyticsContext.Cards);
            window.open(certificateRequestUrl ?? undefined, '_blank');
          } else handleWatchOrLaunch();
        }
      };
      break;
    case 'onDemandRetail':
      thumbnail = {
        ...thumbnail,
        image,
        tag,
        iconThumbnail: { name: 'cart', label: labelIcon || 'Add to cart' }
      };

      buttonOptions = [
        accessMaterials,
        saveToLibrary,
        requestCreditOption,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'Add to cart',
          'cart',
          () => addToCart(variationPk, props.heading),
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      programCardProps = {
        ...programCardProps,
        onClickCard: () => addToCart(variationPk, props.heading)
      };
      break;
    case 'notifyMe':
      thumbnail = {
        ...thumbnail,
        image,
        iconThumbnail: {
          name: showRequestCreditOption ? 'credits' : 'email',
          label: showRequestCreditOption ? 'Request credits' : 'Notify me'
        }
      };

      buttonOptions = [
        saveToLibrary,
        requestCreditOption,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'Notify me',
          'email',
          () => launchNotifyMePopup(notifyMeUrl!),
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      programCardProps = {
        ...programCardProps,
        onClickCard: () => {
          if (showRequestCreditOption)
            trackCardRequestCreditEvent(itemAnalyticsModel, AnalyticsContext.Cards);
          launchNotifyMePopup(notifyMeUrl!);
        }
      };
      break;
  }

  return (
    <>
      {showAsList ? (
        <BaseCardList
          buttonOptions={buttonOptionsVariation}
          thumbnailProps={thumbnail}
          {...programCardProps}
        />
      ) : (
        <BaseCard
          thumbnailProps={thumbnail}
          buttonOptions={buttonOptionsVariation}
          dropdownButtonAriaControls={customModalId}
          buttonAriaControls={customPanelId}
          clickOnCardContent={clickOnCardContent}
          {...programCardProps}
        />
      )}

      <ShareModalCard
        modalHeader="Share"
        pathShareLink={`/product/${variationPk}`}
        programTitle={props.heading!}
        isOpen={isOpenShareModal}
        cardOpenModal={setIsOpenShareModal}
        customModalId={customModalId}
        itemAnalyticsModel={itemAnalyticsModel}
        context={AnalyticsContext.Cards}
      />

      <RemoveLibraryModalCard
        modalHeader="Do you want to remove this program from your Library?"
        isOpen={isOpenRemoveModal}
        onSaveButtonPress={() => confirmRemoveButtonFunction(variationPk!)}
        cardOpenModal={setIsOpenRemoveModal}
        customModalId={customModalId}
      />

      <ProgramMaterialsModal
        open={openProgramMaterials}
        setOpen={setOpenProgramMaterials}
        programTitle={props.heading ?? ''}
        labels={MaterialsLabels}
        materials={materials}
        isLoading={isLoading}
        customModalId={customModalId}
      />

      {openEcom && params && (
        <EcommPanel
          customPanelId={customPanelId}
          params={{ ...params, creditTrackerCode }}
          onClose={() => setOpenEcom(false)}
        />
      )}
    </>
  );
};

export default ProgramCard;
