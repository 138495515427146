import { useMemo } from 'react';
import { ActionItem } from '@/components/ui/PDP/ActionBar';
import { isValidForSale } from '@/components/ui/PDP/helpers';
import {
  OnDemandProgramVariation,
  OnDemandSegmentCatalogRelations,
  ProgramCatalogRelations,
  ProgramVariation
} from './useProgramCatalogRelations';
import { PublicationCatalogRelations } from './usePublicationCatalogRelations';

export const useDownloadAction = (
  variant: ProgramVariation,
  catalogRelations: ProgramCatalogRelations | PublicationCatalogRelations,
  openDownloadModal?: (bool: boolean) => void
) => {
  const downloadAction: ActionItem | undefined = useMemo(() => {
    // For Segment PDPs
    const segmentDownloadVariants = (catalogRelations as OnDemandSegmentCatalogRelations)
      ?.downloadVariants;
    // Single Segment or OHB Program PDPs
    const programDownloadVariants = (variant as OnDemandProgramVariation)?.downloadVariants;
    const downloadVariants = (programDownloadVariants ?? segmentDownloadVariants)?.filter(
      isValidForSale
    );
    const download =
      downloadVariants?.length > 0
        ? {
            label: 'Download',
            icon: 'download',
            onClick: () => openDownloadModal?.(true)
          }
        : undefined;
    return download;
  }, [catalogRelations, openDownloadModal, variant]);

  return downloadAction;
};

export default useDownloadAction;
