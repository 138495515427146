import type { CreditLicenseCardProps } from '@/components/ui/PDP/ProgramCredit/CreditLicenseCard';
import * as Collapsible from '@radix-ui/react-collapsible';
import CreditLicenseCard from '@/components/ui/PDP/ProgramCredit/CreditLicenseCard';
import React, { useState, useMemo, useCallback, useRef } from 'react';
import Icon from '@/components/ui/Icon';
import classnames from 'classnames';
import Text from '@/components/cms/Text';
import AddLicenseBlock from '@/components/blocks/EmptyStateBlock/AddLicenseBlock';
import ViewCreditsForAllJurisdiction from './ViewCreditsForAllJurisdiction';
import { CreditJurisdiction, UserLicense, CreditRegionViewModel } from '@/@types/client-api';
import Shimmer from '../../Shimmer';
import { CreditLicenseCardLabels } from '../labels';
import ViewCreditsForYourLicenses from './ViewCreditsForYourLicenses';
import AddNewLicenseButton from '../../LicensesPage/AddNewLicenseButton';
import { LicenseData } from '../../SearchModals/utils/licenses';
import { AnalyticsContext } from '@/analytics/constants';

type ProgramCreditDetailsProps = {
  labels?: {
    heading: string;
    addLicenseButton: string;
    viewAllJurisdictionsButton: string;
    viewAllJurisdictionsModalHeader: string;
    viewAllLicensesModalHeader: string;
    addLicenseBlockHeading: string;
    addLicenseBlockSubheading: string;
    addLicenseBlockButtonText: string;
    showAllLicenses: string;
    showLessLicenses: string;
  };
  code: string;
  credits?: CreditRegionViewModel[];
  licenses?: UserLicense[];
  allCreditJurisdictions?: CreditJurisdiction[];
  availableCreditJurisdictions?: CreditJurisdiction[];
  handleOnSaveLicense: (licenseData: LicenseData) => void;
  handleOnSaveEditLicense: (licenseData: LicenseData) => void;
  handleOnDeleteLicense: (licenseData?: LicenseData) => void;
  isLoading?: boolean;
  isLoggedOut?: boolean;
  handleLoggedOutAddLicense?: (license: UserLicense) => void;
  handleLoggedOutRemoveLicense?: (regionSk: number) => void;
  isSegmentCreditDetail?: boolean;
  width?: number;
  context?: AnalyticsContext;
};

export const CREDIT_DETAILS_SECTION_ID = 'credit-details';
const getSegmentCreditDetailSectionId = (code: string) => `credit-details-${code}`;

const ProgramCreditDetails: React.FC<ProgramCreditDetailsProps> = ({
  code,
  credits,
  licenses,
  allCreditJurisdictions,
  availableCreditJurisdictions,
  handleOnSaveLicense,
  handleOnSaveEditLicense,
  handleOnDeleteLicense,
  isLoading,
  labels,
  isLoggedOut = false,
  handleLoggedOutAddLicense,
  handleLoggedOutRemoveLicense,
  isSegmentCreditDetail,
  context,
  width
}) => {
  const creditDetailsSectionRef = useRef<HTMLElement>(null);
  const [seeMoreLicenseModalId, setSeeMoreLicenseModalId] = useState<string | undefined>(undefined);
  const [openCreditLicenseModal, setOpenCreditLicenseModal] = useState(false);
  const [creditLicenseModalFocusedRegionSk, setCreditLicenseModalFocusedRegionSk] = useState<
    number | undefined
  >();

  const seeMoreFunction = useCallback(
    (regionSk: number) => {
      setSeeMoreLicenseModalId(`see-more-license-modal-${code}-${regionSk}`);
      setCreditLicenseModalFocusedRegionSk(regionSk);
      setOpenCreditLicenseModal(true);
    },
    [code]
  );

  const showAllLicenseButtonId = `see-more-license-modal-${code}`;
  const showAllLicensesModalFunction = useCallback(() => {
    setSeeMoreLicenseModalId(showAllLicenseButtonId);
    setOpenCreditLicenseModal(true);
  }, [showAllLicenseButtonId]);

  const validUserLicensesRegionSk = licenses?.map(license => license.creditRegion_SK);
  const validCreditsForUser: CreditLicenseCardProps[] = useMemo(
    () =>
      credits
        ?.filter(
          credit =>
            credit.regionSk !== undefined && validUserLicensesRegionSk?.includes(credit.regionSk)
        )
        ?.map((credit: CreditRegionViewModel) => {
          return {
            ...credit,
            code: code,
            creditDetailSectionRef: creditDetailsSectionRef,
            labels: CreditLicenseCardLabels,
            license: licenses?.find(l => l.creditRegion_SK === credit.regionSk),
            handleOnSaveEditLicense,
            handleOnDeleteLicense,
            creditJurisdictions: allCreditJurisdictions,
            seeMoreFunction: () => seeMoreFunction(credit.regionSk!),
            isLoggedOut: isLoggedOut,
            handleLoggedOutRemoveLicense: handleLoggedOutRemoveLicense
          };
        }) ?? [],
    [
      credits,
      validUserLicensesRegionSk,
      code,
      licenses,
      handleOnSaveEditLicense,
      handleOnDeleteLicense,
      allCreditJurisdictions,
      isLoggedOut,
      handleLoggedOutRemoveLicense,
      seeMoreFunction
    ]
  );

  const viewCreditForYourLicenses = (
    <ViewCreditsForYourLicenses
      modalHeader={labels?.viewAllLicensesModalHeader}
      code={code}
      credits={validCreditsForUser?.map(c => {
        return {
          ...c,
          seeMoreFunction: undefined
        };
      })}
      open={openCreditLicenseModal}
      setOpen={setOpenCreditLicenseModal}
      focusedCreditRegionSk={creditLicenseModalFocusedRegionSk}
      seeMoreLicenseModalId={seeMoreLicenseModalId}
    />
  );
  const allCredits = credits?.map(credit => {
    return {
      ...credit,
      code: code,
      creditDetailSectionRef: creditDetailsSectionRef,
      labels: CreditLicenseCardLabels,
      modalVariation: true
    };
  });
  const [expanded, setExpanded] = useState(false);

  const isEmpty = licenses?.length === 0;

  const maxLicensesToShowInitially = 4;

  const creditsAlwaysVisible = useMemo(
    () => validCreditsForUser?.slice(0, maxLicensesToShowInitially),
    [validCreditsForUser, maxLicensesToShowInitially]
  );

  const creditsHidden = useMemo(
    () => validCreditsForUser?.slice(maxLicensesToShowInitially, validCreditsForUser?.length),
    [validCreditsForUser, maxLicensesToShowInitially]
  );

  const viewCreditAllJurisdiction = (
    <ViewCreditsForAllJurisdiction
      header={labels?.viewAllJurisdictionsButton}
      modalHeader={labels?.viewAllJurisdictionsModalHeader}
      credits={allCredits}
    />
  );

  const toggleExpanded = useCallback(() => {
    setExpanded(state => !state);
  }, []);

  const handleAdd = useCallback(
    (licenseData: LicenseData) => {
      handleOnSaveLicense(licenseData);
      creditDetailsSectionRef.current?.focus();
    },
    [handleOnSaveLicense]
  );

  const addLicenseProps = {
    onSave: handleAdd,
    creditJurisdictions: availableCreditJurisdictions,
    isLoggedOut: isLoggedOut,
    handleLoggedOutSave: handleLoggedOutAddLicense
  };

  const creditDetailHeadingText = (
    <Text
      element={isSegmentCreditDetail ? 'h4' : 'h2'}
      className={isSegmentCreditDetail ? 'text-1-medium' : 'heading-5'}
    >
      {labels?.heading}
    </Text>
  );

  const loadingState = (
    <div
      className={classnames(
        { 'mb-8': !isSegmentCreditDetail },
        { 'mb-3 sm:mb-4': isSegmentCreditDetail }
      )}
    >
      {creditDetailHeadingText}
      <Shimmer className="h-[200px] w-full" />
    </div>
  );

  const loadedCreditDetails =
    isEmpty && !isSegmentCreditDetail ? (
      <AddLicenseBlock
        heading={labels?.addLicenseBlockHeading}
        subheading={labels?.addLicenseBlockSubheading}
        viewAllJurisdiction={viewCreditAllJurisdiction}
        buttonText={labels?.addLicenseBlockButtonText}
        {...addLicenseProps}
      />
    ) : (
      <>
        <div
          className={classnames(
            'flex items-center justify-between',
            { 'mb-8': !isSegmentCreditDetail },
            { 'mb-3 sm:mb-4': isSegmentCreditDetail && !isEmpty }
          )}
        >
          {isSegmentCreditDetail && isEmpty ? <></> : creditDetailHeadingText}
          <div className="hidden sm:flex">
            <AddNewLicenseButton
              {...addLicenseProps}
              triggerButton={
                <button className="text-2 mr-6 underline hover:text-red">
                  {labels?.addLicenseButton}
                </button>
              }
            ></AddNewLicenseButton>

            {viewCreditAllJurisdiction}
            {viewCreditForYourLicenses}
          </div>
        </div>
        <Collapsible.Root open={expanded} onOpenChange={setExpanded}>
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4">
            {creditsAlwaysVisible?.map((credit, index) => (
              <CreditLicenseCard key={index} {...credit} context={context} />
            ))}
          </div>

          {/* If > 4 licenses and is rendered in Credit Details section, expand/collapse remaining licenses in-line */}
          {creditsHidden && creditsHidden.length > 0 && !isSegmentCreditDetail && (
            <>
              <Collapsible.Content className="mt-2 grid grid-cols-1 gap-2 sm:mt-4 sm:grid-cols-2 sm:gap-4">
                {creditsHidden?.map((credit, index) => (
                  <CreditLicenseCard key={index} {...credit} context={context} />
                ))}
              </Collapsible.Content>

              <Collapsible.Trigger asChild>
                <button
                  onClick={toggleExpanded}
                  className={classnames(
                    'text-2 mt-8 flex items-center gap-1 font-bold hover:text-red'
                  )}
                  aria-expanded={expanded}
                >
                  {expanded ? labels?.showLessLicenses : labels?.showAllLicenses}
                  <Icon size="small" name={expanded ? 'chevron-up' : 'chevron-down'} />
                </button>
              </Collapsible.Trigger>
            </>
          )}
          {/* If > 4 licenses and is rendered within Segment section Credit Details, open in modal (to avoid double collapse button as designed)*/}
          {creditsHidden && creditsHidden.length > 0 && isSegmentCreditDetail && (
            <button
              aria-controls={showAllLicenseButtonId}
              className="text-1 pt-3 underline hover:text-red sm:pt-4"
              onClick={() => showAllLicensesModalFunction()}
            >
              {labels?.showAllLicenses}
            </button>
          )}
          <div className="mt-6 flex sm:hidden">
            <AddNewLicenseButton
              {...addLicenseProps}
              triggerButton={
                <button className="text-1 mr-6 underline hover:text-red">
                  {labels?.addLicenseButton}
                </button>
              }
            />
            {viewCreditAllJurisdiction}
            {viewCreditForYourLicenses}
          </div>
        </Collapsible.Root>
      </>
    );

  const widthPct = isSegmentCreditDetail && width ? `${width}%` : undefined;

  return (
    <section
      tabIndex={-1}
      ref={creditDetailsSectionRef}
      id={isSegmentCreditDetail ? getSegmentCreditDetailSectionId(code) : CREDIT_DETAILS_SECTION_ID}
      style={{
        width: widthPct
      }}
      className={classnames({
        'container module-spacing-pdp lg:py-12': !isSegmentCreditDetail,
        'pb-6 lg:pb-8': isSegmentCreditDetail
      })}
    >
      {isLoading ? loadingState : loadedCreditDetails}
    </section>
  );
};

export default ProgramCreditDetails;
