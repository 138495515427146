import { MouseEvent } from 'react';
import { format } from 'date-fns';

import type { RenderSnippetProps } from './types';
import Link from '@/components/ui/Link';
import IconMessage from '@/components/ui/IconMessage';
import {
  getOnDemandThumbnail,
  aggregateUserData,
  getFormattedLocationDates,
  getCreditRequestDetails,
  hasMultipleRecordings,
  getIsSavedToLibrary
} from './helpers';

import type {
  SnippetProps,
  SnippetPrimaryStatus,
  SnippetButtonProps
} from '@/components/ui/Snippet/Snippet';
import type { SnippetMetadataProps } from '@/components/ui/Snippet/SnippetMetadata';
import type { EyebrowProps } from '@/components/ui/Eyebrow/Eyebrow';
import { SnippetButtonLabel } from './buttonsLabels';

import {
  getAccessMaterialsButton,
  getCreditRequestedButton,
  getNotifyMeAction,
  getRequestCreditsButton,
  getViewCertificatesButton,
  getViewCreditDetailsButton,
  getViewTranscriptButton,
  renderEcommPanelButtonWrapper
} from './buttons';

const renderLiveProgramExpired = ({
  data,
  actions,
  context,
  links,
  modalPanelState
}: RenderSnippetProps): SnippetProps => {
  const {
    title = '',
    creditDetailsFormatted = [],
    userList = [],
    runTimeSeconds = 0,
    variationPk = null,
    url,
    creditRequestStatus,
    certificateRequestUrl,
    creditRequestDate,
    accessExpirationDate,
    registrationDetails,
    odProgram,
    isOdProgramAvailable,
    notificationDetails,
    itemAnalyticsModel
  } = data;
  const userAggregatedData = aggregateUserData({ userList, variant: 'liveprogram' });

  const firstCreditDetailsFormatted = (creditDetailsFormatted || [])[0];

  const onDemandAvailable = context === 'library' && (isOdProgramAvailable || !!odProgram);

  const metadata: SnippetMetadataProps = {
    items: []
  };

  if (firstCreditDetailsFormatted) {
    metadata.items.push(
      getViewCreditDetailsButton(
        context,
        variationPk ?? '',
        firstCreditDetailsFormatted.keyword ?? '',
        itemAnalyticsModel
      )
    );
  }

  if (runTimeSeconds) {
    if (runTimeSeconds >= 3600) {
      metadata.items.push(`${Math.round(runTimeSeconds / 3600)}-hour program`);
    } else {
      metadata.items.push(`${Math.floor(runTimeSeconds / 60)}-minute program`);
    }
  }

  const isNotifyMeGracefulTransitionState =
    context === 'library' && notificationDetails && !!notificationDetails?.fullUrl;

  const { cleProgressPct = 0 } = userAggregatedData || {};
  let isAccessExpired = false;
  const creditsRequestState = getCreditRequestDetails({
    cleProgressPct,
    creditRequestStatus: creditRequestStatus as unknown as string
  });

  const { startDateUtc, endDateUtc } = registrationDetails || {};
  const streamingDate = getFormattedLocationDates({
    startTimeUtc: startDateUtc,
    endTimeUtc: endDateUtc
  });

  if (streamingDate) {
    metadata.items.push([`Streamed on ${streamingDate}`]);
  }

  if (context === 'library') {
    if (accessExpirationDate) {
      const expirationDate = new Date(accessExpirationDate);
      const today = new Date();

      if (expirationDate < today) {
        isAccessExpired = true;
      }

      if (isAccessExpired) {
        metadata.items.push(
          <span className="inline-flex text-red">
            Access expired on {format(expirationDate, 'MMMM dd, yyyy')}
            {creditsRequestState === 'credits-available' && (
              <>
                &nbsp;- Please &nbsp;
                <Link text="contact us" href="#" className="text-red" as="a" /> &nbsp; to request
                credit.
              </>
            )}
          </span>
        );
      } else if (onDemandAvailable) {
        metadata.items.push(`Access expires on ${format(expirationDate, 'MMMM dd, yyyy')}`);
      }
    }
  }

  let hasWatchProgress;
  if (userAggregatedData?.playbackProgressPct || userAggregatedData?.cleProgressPct) {
    hasWatchProgress = (
      <IconMessage
        text={`You have also watched some of this program while Live.`}
        iconName="pending"
      />
    );
  }

  let primaryCallback = onDemandAvailable ? actions.buy : undefined;

  let primaryCtaWrapper: SnippetButtonProps['renderButtonWrapper'] = hasMultipleRecordings(userList)
    ? renderEcommPanelButtonWrapper(context, data, 'StartWatching')
    : undefined;

  let userPrimaryStatus: SnippetPrimaryStatus | null = 'not-purchased';

  if (!userAggregatedData?.isPurchaseRequired) {
    userPrimaryStatus = null;
  }

  if (creditsRequestState) {
    userPrimaryStatus = creditsRequestState;
  }

  if (isAccessExpired) {
    userPrimaryStatus = 'expired';
  }

  const onRemoveFromLibrary = (e?: MouseEvent<HTMLButtonElement>) => {
    const siblingVariantPks =
      context === 'search'
        ? userList?.filter(getIsSavedToLibrary).map(u => u.pk ?? '')
        : [variationPk ?? ''];
    actions.removeFromLibrary.bind(e, siblingVariantPks)();
  };

  let primaryCTALabel = SnippetButtonLabel.notifyMe;
  let primaryCTAIcon = 'email';

  if (onDemandAvailable && !userAggregatedData?.isPurchaseRequired) {
    let watchProgressCtaLabel = SnippetButtonLabel.buyNow;
    let watchProgressCtaIcon = 'chevron-right';
    switch (userAggregatedData?.playbackProgressPct) {
      case 0:
        watchProgressCtaLabel = SnippetButtonLabel.startWatching;
        watchProgressCtaIcon = 'play';
        break;
      case 100:
        watchProgressCtaLabel = SnippetButtonLabel.watchAgain;
        watchProgressCtaIcon = 'redo';
        break;
      default:
        watchProgressCtaLabel = SnippetButtonLabel.continueWatching;
        watchProgressCtaIcon = 'play';
        break;
    }
    primaryCTALabel = watchProgressCtaLabel;
    primaryCTAIcon = watchProgressCtaIcon;
    primaryCallback = actions.launch;
  }

  if (isNotifyMeGracefulTransitionState) {
    primaryCTALabel = SnippetButtonLabel.notifyMe;
    primaryCTAIcon = 'email';
    primaryCallback = getNotifyMeAction(
      context,
      variationPk ?? '',
      notificationDetails?.fullUrl ?? null,
      itemAnalyticsModel
    );
    primaryCtaWrapper = undefined;
  }

  const thumbnail = getOnDemandThumbnail({
    variant: 'ondemandprogram',
    data,
    userInfo: { playbackProgressPct: userAggregatedData?.playbackProgressPct || 0 },
    isPurchaseRequired: userAggregatedData?.isPurchaseRequired ?? true,
    isAccessExpired: isAccessExpired || !onDemandAvailable,
    isPrerelease: false,
    action: primaryCallback,
    renderButtonWrapper: primaryCtaWrapper
  });

  const buttons: SnippetButtonProps[] = [
    {
      color:
        context === 'library' &&
        (userPrimaryStatus === 'certificates-available' ||
          userPrimaryStatus === 'credits-requested' ||
          (userPrimaryStatus === 'credits-available' && cleProgressPct === 100))
          ? 'outline-black'
          : 'black', // "View Certificates" as well as "Credits Requested" have higher priority as the Primary CTA
      label: primaryCTALabel,
      onClick: primaryCallback || (() => {}),
      renderButtonWrapper: primaryCtaWrapper,
      size: 'short',
      iconRight: primaryCTAIcon
    }
  ];

  const creditsRequestedEyebrows: EyebrowProps[] = [];

  if (context === 'library') {
    if (userPrimaryStatus === 'credits-available') {
      const requestCreditsButton = getRequestCreditsButton(
        context,
        variationPk ?? '',
        certificateRequestUrl,
        cleProgressPct,
        itemAnalyticsModel,
      );
      if (requestCreditsButton) {
        buttons.push(requestCreditsButton);
      }
      creditsRequestedEyebrows.push({
        label: 'Credit for request',
        icon: 'credits',
        color: cleProgressPct < 100 ? undefined : 'green'
      });
    } else if (userPrimaryStatus === 'credits-requested') {
      buttons.push(getCreditRequestedButton(creditRequestDate));
      if (creditRequestDate) {
        const creditsRequestedDateFormatted = format(new Date(creditRequestDate), 'MMM d, yyyy');
        creditsRequestedEyebrows.push({
          label: `Credits requested on ${creditsRequestedDateFormatted}`,
          icon: 'credits'
        });
      }
    } else if (userPrimaryStatus === 'certificates-available') {
      buttons.push(getViewCertificatesButton(context, variationPk ?? '', links.Certificates, itemAnalyticsModel));
    }
    if (!userAggregatedData?.isPurchaseRequired && !isNotifyMeGracefulTransitionState) {
      buttons.push(
        getAccessMaterialsButton(context, variationPk ?? '', title ?? '', actions.accessMaterials)
      );
      const { plusUrl } = odProgram || {};
      if (plusUrl) {
        buttons.push(getViewTranscriptButton(context, variationPk ?? '', plusUrl, itemAnalyticsModel));
      }
    }

    if (!onDemandAvailable && !isNotifyMeGracefulTransitionState) {
      buttons.splice(
        buttons.findIndex(button => button.label === SnippetButtonLabel.notifyMe),
        1
      );
      const requestCreditsCTA = buttons.find(
        button => button.label === SnippetButtonLabel.requestCredits
      );
      if (requestCreditsCTA?.color === 'outline-black') {
        buttons[buttons.indexOf(requestCreditsCTA)] = { ...requestCreditsCTA, color: 'black' };
      }
    }
    if (userPrimaryStatus === 'expired') {
      buttons.splice(0, buttons.length);
      if (creditsRequestState === 'certificates-available') {
        buttons.push(getViewCertificatesButton(context, variationPk ?? '', links.Certificates, itemAnalyticsModel));
      }
    }
  }

  const menuItems = [];
  if (context === 'library') {
    menuItems.push({
      label: SnippetButtonLabel.removeFromLibrary,
      onClick: () => {
        modalPanelState.deleteModal.show({ onConfirm: onRemoveFromLibrary });
      },
      icon: 'delete',
      menuStyle: 'standalone' as const
    });
  }

  return {
    variant: 'live-expired' as const,
    pk: variationPk ?? '',
    title: title ?? '',
    metadata,
    menuItems,
    buttons,
    thumbnail,
    link: url || '',
    primaryStatus: userPrimaryStatus,
    secondaryStatus: userAggregatedData?.isSavedToLibrary ? 'saved-to-library' : null,
    eyebrows: creditsRequestedEyebrows,
    content: hasWatchProgress
  };
};

export default renderLiveProgramExpired;
