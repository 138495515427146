import { DateType } from '@/components/ui/DateTag';
import { forwardRef as ReactForwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MenuItemProps } from '@/components/ui/Menu/MenuItem';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { TagProps } from '@/components/ui/Tag';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import classnames from 'classnames';
import DropdownMenu from '@/components/ui/DropdownMenu';
import Icon from '@/components/ui/Icon';
import IconButton from '@/components/ui/IconButton';
import Thumbnail from '@/components/ui/Thumbnail';
import { ResponsiveImageProps } from '../ResponsiveImage';
import { CardType } from './constants';
import PublicationThumbnail from '../Thumbnail';

export interface ThumbnailProps {
  video?: {
    url: string;
    autoPlay?: boolean;
    muted?: boolean;
    playable?: boolean;
    className?: string;
  };
  image?: {
    url: string;
    alt?: string;
  };
  progressValue?: number;
  iconThumbnail?: {
    name: string;
    label?: string;
  };
  tag?: TagProps;
  dates?: DateType;
  forceShowVideoPreview?: boolean;
}

export interface CardProps {
  cardType?: CardType;
  size?: 'small' | 'medium' | 'large';
  color?: 'dark' | 'light';
  className?: string;
  cardClassName?: string;
  headingSmall?: string;
  heading?: string;
  labelInfo?: string;
  labelDetail?: string;
  labelDetailIcon?: string;
  labelDetailClassName?: string;
  thumbnailProps?: ThumbnailProps;
  href?: string;
  target?: string;
  onClickCard?: () => void;
  buttonOptions?: (MenuItemProps | { separator: true })[];
  listPosition?: number;
  containerClass?: string;
  heightAuto?: boolean;
  widthAuto?: boolean;
  dropdownButtonAriaControls?: string;
  buttonAriaControls?: string;
  clickOnCardContent?: () => void;
}

export interface CardContentProps {
  size?: 'small' | 'medium' | 'large';
  color?: 'dark' | 'light';
  headingSmall?: string;
  heading?: string;
  labelInfo?: string;
  labelDetail?: string;
  labelDetailIcon?: string;
  labelDetailClassName?: string;
  containerClass?: string;
  widthAuto?: boolean;
  href?: string;
  topSectionHeight?: number;
  onClick?: () => void;
}

const CardContent: React.FC<CardContentProps> = ({
  headingSmall,
  heading,
  labelInfo,
  labelDetail,
  labelDetailIcon,
  labelDetailClassName,
  size,
  color,
  containerClass,
  widthAuto,
  onClick,
  href
}) => {
  const contentContainerClasses = classnames(
    'relative flex w-full flex-col items-center justify-center',
    {
      'size-full p-6 pb-3.5': size === 'small' || size === 'large',
      'p-4 lg:max-w-[326px] lg:p-6 lg:pb-3.5': size === 'medium',
      'size-full sm:max-w-[368px] lg:max-w-[654px] lg:px-[40px] lg:py-[32px] lg:pb-[22px]':
        size === 'large',
      'group bg-black': color === 'dark',
      'bg-gray-lightest transition hover:bg-gray-light focus:bg-gray-light': color === 'light'
    },
    {
      'w-full !max-w-none': widthAuto
    },
    containerClass
  );

  const contentTextClasses = classnames(
    'text-left transition',
    {
      'text-1 font-medium ': size === 'small' || size === 'large',
      'text-1 line-clamp-3 font-medium': size === 'small',
      'text-2 line-clamp-3 font-medium lg:heading-6-medium': size === 'medium',
      'line-clamp-4 sm:heading-6-medium lg:heading-5-medium': size === 'large',
      'text-white group-hover:text-silver': color === 'dark'
    },
    {
      '!line-clamp-2': size === 'small' && headingSmall,
      '!line-clamp-2 lg:!line-clamp-3': size === 'medium' && headingSmall,
      '!line-clamp-3 sm:!line-clamp-4': size === 'large' && headingSmall
    }
  );

  const labelDetailClasses = classnames(
    'mb-2 line-clamp-1 flex w-full items-center',
    labelDetailClassName
  );

  const contentLabelsClasses = classnames('text-2 text-left font-medium', {
    'pb-[10px]': size === 'small' || size === 'large',
    'lg:pb-[10px]': size === 'medium',
    'text-silver': color === 'dark',
    'text-gray-darker': color === 'light'
  });

  const headingWrapperClasses = classnames('flex flex-col justify-start', {
    'gap-1': size === 'small' || size === 'medium',
    'gap-2': size === 'large'
  });

  const linkClassnames = classnames('focus-visible:outline-offset-[-6px]', {
    'outline-white': color === 'dark',
    'rounded-b-lg': size === 'small'
  });

  return (
    <Link
      to={href || ''}
      onClick={onClick}
      className={classnames(contentContainerClasses, linkClassnames)}
    >
      <div className="flex size-full flex-col justify-between gap-4">
        <div className={headingWrapperClasses}>
          {headingSmall && (
            <span className="text-2 text-left font-medium text-gray-darker">{headingSmall}</span>
          )}
          <h3 className={contentTextClasses}>{heading}</h3>
        </div>
        <div className="flex items-end justify-between">
          <div className={contentLabelsClasses}>
            <p className={labelDetailClasses}>
              {labelDetailIcon ? (
                <Icon name={labelDetailIcon} size="small" className="mr-1 inline" />
              ) : null}
              {labelDetail}
            </p>
            <p>{labelInfo}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

const BaseCard: React.FC<CardProps> = ReactForwardRef(
  (
    {
      size,
      cardType,
      className,
      cardClassName,
      color = 'light',
      headingSmall,
      heading,
      labelInfo,
      labelDetail,
      labelDetailIcon,
      labelDetailClassName,
      thumbnailProps,
      href,
      target = '_self',
      onClickCard,
      buttonOptions,
      listPosition,
      containerClass,
      heightAuto,
      widthAuto,
      dropdownButtonAriaControls,
      buttonAriaControls,
      clickOnCardContent,
      ...props
    },
    ref
  ) => {
    let ratio: number = 1;
    let imageSizes: ResponsiveImageProps['imageSizes'] = useMemo(() => {
      return {
        xs: '320px'
      };
    }, []);

    const xSmallWindow = useMediaQuery(ScreenSizeQueries.xsOnly);
    const smallWindow = useMediaQuery(ScreenSizeQueries.sm);
    const largeWindow = useMediaQuery(ScreenSizeQueries.lg);

    if (size === 'large') {
      if (xSmallWindow || largeWindow) {
        ratio = 3 / 2;
      } else if (smallWindow) {
        ratio = 1;
      }
    } else if (size === 'medium') {
      ratio = 1;
    } else {
      ratio = 3 / 2;
    }

    if (size === 'medium') {
      imageSizes = {
        xs: '172px',
        lg: '302px'
      };
    } else if (size === 'large') {
      imageSizes = {
        xs: '302px',
        sm: '352px',
        lg: '630px'
      };
    }

    const cardClasses = classnames(
      'relative',
      {
        'h-[350px] max-w-[240px] sm:h-[370px] sm:max-w-[260px] lg:h-[400px] lg:max-w-[300px]':
          size === 'small',
        'h-[400px] max-w-[300px]': size === 'large',
        'min-h-[168px] max-w-[327px] md:max-w-[352px] lg:min-h-[300px] lg:max-w-[628px]':
          size === 'medium',
        'sm:h-[352px] sm:max-w-[720px] lg:min-h-[420px] lg:max-w-[1280px] ': size === 'large'
      },
      {
        '!h-full': (heightAuto && size === 'small') || xSmallWindow
      },
      {
        'w-full !max-w-none': widthAuto
      },
      cardClassName
    );

    const imageContainerClasses = classnames('group -z-1 rounded-none', {
      'h-full min-w-[172px] md:min-w-[168px] lg:min-w-[302px]': size === 'medium',
      'sm:min-w-[352px] lg:min-w-[630px]': size === 'large'
    });

    const sizeClasses = classnames(
      'flex size-full overflow-hidden rounded-lg',
      {
        'h-[350px] flex-col sm:h-[370px] lg:h-[400px]': size === 'small',
        'h-[400px] flex-col': size === 'large',
        'flex flex-row-reverse': size === 'medium',
        'sm:flex sm:h-[352px] sm:flex-row-reverse lg:min-h-[420px]': size === 'large',
        'lg:max-w-[1280px]': size === 'large' && !widthAuto
      },
      {
        '!h-full': (heightAuto && size === 'small') || xSmallWindow
      },
      className
    );

    const optionButtonClasses = classnames('z-1', {
      'absolute bottom-3 right-3.5 rounded p-[10px]': size === 'small' || size === 'large',
      'absolute bottom-3 right-[188px] lg:right-[326px] lg:rounded lg:p-[10px]': size === 'medium',
      'sm:right-[374px] lg:bottom-5 lg:right-[666px]': size === 'large',
      'text-silver hover:bg-charcoal': color === 'dark',
      'text-gray-darker hover:bg-silver': color === 'light',
      'focus-visible:outline-white': color === 'dark',
      'focus-visible:outline-black': color === 'light'
    });

    const buttonClasses = classnames('z-1 block rounded-t-lg focus-visible:outline-offset-[-3px]', {
      'h-full': heightAuto,
      'w-full': !widthAuto
    });

    const ThumbnailContent = useMemo(
      () =>
        cardType && cardType === CardType.Publication ? (
          <PublicationThumbnail
            ratio={ratio}
            icon={largeWindow ? thumbnailProps?.iconThumbnail : undefined}
            className={imageContainerClasses}
            rounded={false}
            card={true}
            fitImage={false}
            listPosition={size === 'small' ? listPosition : undefined}
            onClick={onClickCard}
            {...thumbnailProps}
          />
        ) : (
          <Thumbnail
            ratio={ratio}
            icon={largeWindow ? thumbnailProps?.iconThumbnail : undefined}
            className={imageContainerClasses}
            rounded={false}
            card={true}
            listPosition={size === 'small' ? listPosition : undefined}
            {...thumbnailProps}
            imageSizes={imageSizes}
            onClick={onClickCard}
          />
        ),
      [
        imageContainerClasses,
        imageSizes,
        largeWindow,
        listPosition,
        ratio,
        size,
        thumbnailProps,
        cardType,
        onClickCard
      ]
    );

    const ButtonContent = useMemo(
      () => (
        <div className={sizeClasses} data-component={'BaseCard'}>
          {onClickCard ? (
            <div
              className={buttonClasses}
              ref={ref as React.Ref<HTMLDivElement>}
              aria-controls={buttonAriaControls}
            >
              {ThumbnailContent}
            </div>
          ) : (
            <Link
              className={buttonClasses}
              ref={ref as React.Ref<HTMLAnchorElement>}
              to={href || ''}
              target={target}
              aria-controls={buttonAriaControls}
            >
              {ThumbnailContent}
            </Link>
          )}

          <CardContent
            size={size}
            color={color}
            headingSmall={headingSmall}
            heading={heading}
            labelInfo={labelInfo}
            labelDetail={labelDetail}
            labelDetailIcon={labelDetailIcon}
            labelDetailClassName={labelDetailClassName}
            containerClass={containerClass}
            widthAuto={widthAuto}
            href={href}
            onClick={clickOnCardContent}
          />
        </div>
      ),
      [
        sizeClasses,
        onClickCard,
        buttonClasses,
        ref,
        buttonAriaControls,
        ThumbnailContent,
        href,
        target,
        size,
        color,
        headingSmall,
        heading,
        labelInfo,
        labelDetail,
        labelDetailIcon,
        labelDetailClassName,
        containerClass,
        clickOnCardContent,
        widthAuto
      ]
    );

    return (
      <article className={cardClasses} {...props} ref={ref as React.Ref<HTMLElement>}>
        {ButtonContent}

        <DropdownMenu
          button={() => (
            <IconButton
              size="large"
              label="More options"
              name="more-ios"
              className={optionButtonClasses}
              aria-controls={dropdownButtonAriaControls}
            />
          )}
          align="start"
          items={buttonOptions}
        />
      </article>
    );
  }
);

export default BaseCard;
