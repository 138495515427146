import React, { MouseEvent } from 'react';
import Icon from '@/components/ui/Icon';
import { useEditPanelContext } from '@/components/ui/Panels/EditPanel/context';
import classnames from 'classnames';
import RequiredFieldMessage from './RequiredFieldMessage';
import { v4 as uuidv4 } from 'uuid';

interface SelectedOptionButtonProps {
  label: string;
  selectedItems?: string[];
  isMultiSelect?: boolean;
  onRemoveItem: (item: string) => void;
  onGetItemLabel: (item: string) => string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  required?: boolean;
  disabled?: boolean;
}

const SelectedOptionButton: React.FC<SelectedOptionButtonProps> = ({
  label,
  isMultiSelect = false,
  selectedItems,
  onGetItemLabel,
  onRemoveItem,
  onClick,
  className,
  required = false,
  disabled = false
}) => {
  const { validationFailed } = useEditPanelContext();

  const invalidValue = required && validationFailed;
  const requiredMessageId = `required-message-${uuidv4()}`;

  return (
    <div className={classnames('w-full', className)}>
      <button
        onClick={e => {
          e.stopPropagation();
          if (disabled) e.preventDefault();
          onClick?.(e);
        }}
        className={classnames('w-full', { 'cursor-not-allowed ': disabled })}
        aria-disabled={disabled}
        aria-required={required}
        aria-invalid={invalidValue}
        aria-errormessage={requiredMessageId}
        type="button"
      >
        <div className="text-1 relative border-b border-b-gray-dark py-2">
          <div
            className={classnames('flex flex-1 items-center justify-between', {
              'text-gray': disabled
            })}
          >
            <p
              className={classnames({
                'text-gray-dark': !disabled,
                'text-2 -mt-3': selectedItems?.length
              })}
            >{`${label}${required ? ' *' : ''}`}</p>
            <Icon
              className={classnames('absolute bottom-2.5 right-0')}
              name="chevron-right"
              size="small"
            />
          </div>
          {!isMultiSelect && selectedItems?.length === 1 && (
            <p className={disabled ? 'text-left text-gray' : 'pt-2 text-left'}>
              {onGetItemLabel(selectedItems[0])}
            </p>
          )}
        </div>
      </button>
      {invalidValue && (selectedItems?.length || 0) === 0 && (
        <RequiredFieldMessage id={requiredMessageId} label={label} />
      )}
      {isMultiSelect && !!selectedItems?.length && selectedItems.length > 0 && (
        <div className="mt-2 flex flex-col gap-2">
          {selectedItems.map(item => {
            const label = onGetItemLabel(item);
            return (
              <button
                key={item}
                value={item}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  onRemoveItem?.(item);
                }}
                aria-label={label}
                className="text-2 flex w-full items-center justify-between rounded bg-gray-lightest px-3 py-2 font-normal"
              >
                {label}
                <Icon className="flex" name="close" size="small" />
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectedOptionButton;
