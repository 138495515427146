import { AnalyticsEventName, FormContext } from './constants';
import { trackEvent } from './utils';

type FormSubmitEventProps = {
  formName?: string;
  context: FormContext;
};

export const trackFormSubmitComplete = ({ formName, context }: FormSubmitEventProps) => {
  if (formName) {
    trackEvent({
      eventName: AnalyticsEventName.FormSubmitComplete,
      parameters: {
        context: context,
        form_name: formName
      },
      skipBaseEventParameters: true
    });
  }
};
