import { Fragment, useCallback, useEffect, useState } from 'react';
import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';
import LicenseForm, { LicenseFormProps } from '@/components/ui/SearchModals/LicenseForm';
import { LicenseData, validateRequiredFields } from '../SearchModals/utils/licenses';
import DeleteLicenseModal from './DeleteLicenseModal';
import { useAuth } from 'react-oidc-context';
import { existsCookie, removeCookie } from '@/utils/cookie-list-values';
import { trackerCookieNameKey } from '../CreditTracker/util/constants';
import { invalidateAllTrackerData } from '@/redux/slices/creditTrackerSlice';
import { useDispatch } from 'react-redux';

export type EditLicenseModalProps = ModalProps &
  Omit<LicenseFormProps, 'creditTypeOptions' | 'jurisdictionOptions'> & {
    onSave: () => void;
    onDelete: () => void;
    triggerButton?: JSX.Element;
    customModalId?: string;
  };

const EditLicenseModal = ({
  open,
  setOpen,
  data,
  onDataChange,
  complianceGroupOptions,
  licenseNumberSettings,
  onSave,
  onDelete,
  triggerButton,
  customModalId
}: EditLicenseModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const [validationFailed, setValidationFailed] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (open) {
      setValidationFailed(false);
    }
  }, [open]);

  const handleOnSave = useCallback(() => {
    if (validateRequiredFields(data)) {
      setOpen(false);
      onSave();
    } else {
      setValidationFailed(true);
    }
  }, [data, onSave, setOpen]);

  const handleOnDataChange = useCallback(
    (data: LicenseData) => {
      setValidationFailed(false);
      onDataChange?.(data);
    },
    [onDataChange]
  );

  const handleOnDeleteModalClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOnRemoveLicense = useCallback(() => {
    const trackerCookieName = `${trackerCookieNameKey}_${user?.profile?.sub}`;

    if (existsCookie(trackerCookieName)) {
      removeCookie(trackerCookieName);
    }

    !!onDelete && onDelete();
    dispatch(invalidateAllTrackerData());
  }, [dispatch, onDelete, user?.profile?.sub]);

  return (
    <Modal open={open} setOpen={setOpen}>
      {triggerButton && <Modal.Trigger asChild>{triggerButton}</Modal.Trigger>}
      <ModalContent
        title={
          <Fragment>
            <span className="heading-4 block text-gray">
              {data && 'creditRegionName' in data
                ? (data.creditRegionName as string)
                : data?.creditRegion}
            </span>
            <span className="heading-4 mt-2 block">Edit license</span>
          </Fragment>
        }
        subtitle="* Indicates required field"
        saveButtonLabel="Save to my profile"
        onSaveButtonPress={handleOnSave}
        isMultiStep={true}
        customModalId={customModalId}
      >
        <div className="mt-px flex flex-col gap-11">
          <LicenseForm
            data={data}
            onDataChange={handleOnDataChange}
            complianceGroupOptions={complianceGroupOptions}
            licenseNumberSettings={licenseNumberSettings}
            validationFailed={validationFailed}
          />
          <div>
            <DeleteLicenseModal
              onConfirm={handleOnRemoveLicense}
              onClose={handleOnDeleteModalClose}
              triggerButton={
                <button type="button" className="text-link text-2 text-gray-dark underline">
                  Remove from my profile
                </button>
              }
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default EditLicenseModal;
