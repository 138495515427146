import { CreditRegionViewModel, TransitionalStatus } from '@/@types/client-api';
import ModalContent from '@/components/ui/Modal/ModalContent';
import CreditLicenseCard from '@/components/ui/PDP/ProgramCredit/CreditLicenseCard';
import { CreditLicenseCardLabels, ProgramCreditLabels } from '../PDP/labels';
import Shimmer from '../Shimmer';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { RowInfo } from '../PDP/ProgramCredit/ViewCreditsForAllJurisdiction';
import AllJurisdictionsTable from '../Tables/AllJurisdictionsTable';

type ViewCreditsForAllJurisdictionProps = {
  credits: CreditRegionViewModel[];
  onBack?: () => void;
  isLoading?: boolean;
  code: string;
};

const ViewCreditsForAllJurisdiction: React.FC<ViewCreditsForAllJurisdictionProps> = ({
  credits,
  code,
  onBack,
  isLoading
}) => {
  const belowMd = useMediaQuery(ScreenSizeQueries.belowMd);

  const renderTableRows = (credits: CreditRegionViewModel[]) => {
    return (
      credits?.map((credit, i) => {
        return {
          key: i,
          jurisdiction: credit.description ?? '',
          statusDescription: credit.statusDescription ?? '',
          statusEnum: credit.statusEnum ?? '',
          total: credit.totalOffered ?? 0,
          creditDetails: credit.credits ?? '',
          isTransitional: credit.transitionalStatus === TransitionalStatus._1,
          regionSk: credit.regionSk
        } as RowInfo;
      }) ?? []
    );
  };

  return (
    <ModalContent
      title={ProgramCreditLabels.viewAllJurisdictionsModalHeader}
      onBackButtonPress={onBack}
      isLarge={!belowMd}
      containerClassName="max-w-[1278px]"
    >
      <div className="flex flex-col gap-4">
        {isLoading ? (
          <Shimmer className="h-[184px] w-full rounded " />
        ) : belowMd ? (
          credits.map(credit => (
            <CreditLicenseCard
              code={code}
              key={credit.regionSk}
              {...credit}
              labels={CreditLicenseCardLabels}
              modalVariation={true}
            />
          ))
        ) : (
          <AllJurisdictionsTable
            tableHeader={['Jurisdiction', 'Status', 'Total', 'Credit details']}
            rows={renderTableRows(credits ?? [])}
          />
        )}
      </div>
    </ModalContent>
  );
};

export default ViewCreditsForAllJurisdiction;
