import { Link } from 'react-router-dom';
import AvatarComponent from '@/components/ui/Avatar';
import RichText from '@/components/cms/RichText';
import { FacultyLinkProps } from './FacultyLink';

export interface FacultyLinkBioProps extends FacultyLinkProps {
  biography?: string;
}

const FacultyLinkBio: React.FC<FacultyLinkBioProps> = ({
  url,
  displayName,
  imageUrl,
  firstName,
  lastName,
  biography
}) => {
  return (
    <div className="flex h-full min-w-px grow flex-col items-center gap-4 pb-8 md:flex-row">
      <div className="w-full place-content-center justify-items-center align-top md:w-1/5">
        <AvatarComponent
          fullName={displayName ?? ''}
          firstName={firstName}
          lastName={lastName}
          className="w-[180px] place-content-center"
          imageUrl={imageUrl}
        />
      </div>
      <div className="w-full min-w-px md:w-4/5">
        <p className="heading-5-regular block pb-3 font-medium transition">{displayName}</p>
        {biography && (
          <RichText
            propertyName="biography"
            content={biography || ''}
            className="text-1 line-clamp-5 text-gray-dark"
            checkList={true}
            checklistRed={false}
          />
        )}
        <Link
          to={url ?? '#'}
          className="group my-5 flex content-between items-center gap-4 underline"
        >
          {'Read more'}
        </Link>
      </div>
    </div>
  );
};

export default FacultyLinkBio;
