import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { FinishProfileBlock as FinishProfileBlockProps } from '@/@types/content';
import Icon from '@/components/ui/Icon';
import RichText from '@/components/cms/RichText';
import Text from '@/components/cms/Text';
import { RootState } from '@/redux/store';
import { dismissElement } from '@/redux/slices/pageSlice';
import Button from '../../ui/Buttons/Button';
import MultiStepModalBlock from './MultiStepModalBlock';
import Block from '@/components/cms/Block';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';

export type FinishProfileBlockType = FinishProfileBlockProps & { cookieName: string };

const FinishProfileBlock: React.FC<FinishProfileBlockType> = ({
  contentLink,
  heading,
  copy,
  linkLabel,
  multiStepModalBlock
}) => {
  const dispatch = useDispatch();
  const isEditMode = isEditOrPreviewMode();
  const cookieName = window.env?.FINISH_PROFILE_BLOCK_COOKIE_NAME || 'finishProfileBlock';
  const findAProgramCookieName =
    window.env?.FIND_A_PROGRAM_BLOCK_COOKIE_NAME || 'findAProgramBlock';
  const dismissedElements = useSelector((state: RootState) => state.page.dismissedElements);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (dismissedElements[cookieName] && !isEditMode) return null;

  const handleDismiss = () => {
    dispatch(dismissElement(cookieName));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Block
      contentLinkID={contentLink?.id}
      className="flex h-full flex-col items-start justify-between rounded-lg  bg-gray-lightest p-6 lg:p-8"
    >
      <div className="mb-8 w-full lg:mb-10">
        <div className="mb-4 flex items-start justify-between gap-4">
          <Text element="h3" propertyName="Heading" className="heading-5">
            {heading}
          </Text>
          <button aria-label="dismiss" onClick={handleDismiss}>
            <Icon name="close" size="large" />
          </button>
        </div>
        <RichText
          propertyName="Copy"
          content={copy}
          className={classnames('text-1 text-gray-dark', {
            'max-w-[468px]': !dismissedElements[findAProgramCookieName],
            'max-w-[922px]': dismissedElements[findAProgramCookieName]
          })}
        />
      </div>
      <Button
        iconLeft="add"
        label={linkLabel}
        size="large"
        color="outline-black"
        onClick={handleOpenModal}
      />
      {multiStepModalBlock && (
        <MultiStepModalBlock
          {...multiStepModalBlock[0]}
          open={isModalOpen}
          setOpen={setIsModalOpen}
        />
      )}
    </Block>
  );
};

export default FinishProfileBlock;
