import { CardProps } from '@/components/ui/Cards/BaseCard';
import classnames from 'classnames';
import DropdownMenu from '@/components/ui/DropdownMenu';
import IconButton from '@/components/ui/IconButton';
import Link from '@/components/ui/Link';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Thumbnail from '../Thumbnail';
import DotSeparatedContent from '../DotSeparatedContent';

const BaseCardList = ({
  labelDetail,
  heading,
  href,
  buttonOptions,
  size,
  thumbnailProps,
  headingSmall,
  cardType
}: CardProps) => {
  const isLarge = size === 'large';
  let ratio;
  const xSmallWindow = useMediaQuery(ScreenSizeQueries.xsOnly);
  const smallWindow = useMediaQuery(ScreenSizeQueries.sm);
  const mediumWindow = useMediaQuery(ScreenSizeQueries.md);

  if (smallWindow || mediumWindow) {
    ratio = 3 / 2;
  } else if (smallWindow || xSmallWindow) {
    ratio = 1;
  }
  const imageContainerClasses = classnames(
    'group rounded',
    'xs:max-h-[70px] my-[25px] min-w-[70px] sm:min-w-[168px] md:max-h-[112px] md:max-w-[168px] lg:max-w-[302px]'
  );

  return (
    <div className={classnames('flex items-center gap-5 pr-6', 'lg:pr-8')}
      data-component={"BaseCardList"}
    >
      {isLarge ? (
        <div className={classnames('flex w-full flex-col items-start gap-1 py-6')}>
          <Link as="a" href={href} className="!heading-6-medium no-underline" text={heading} />
          <span className="label text-gray-dark">
            {' '}
            <DotSeparatedContent content={[labelDetail, headingSmall]} />
          </span>
        </div>
      ) : (
        <div className={classnames('flex w-full flex-col items-start gap-1')}>
          <span className="label text-gray-dark">{labelDetail}</span>
          <Link as="a" href={href} className="!heading-6-medium no-underline" text={heading} />
        </div>
      )}

      {isLarge ? (
        <Thumbnail
          ratio={ratio}
          icon={thumbnailProps?.iconThumbnail}
          className={imageContainerClasses}
          rounded={true}
          card={true}
          {...thumbnailProps}
          imageSizes={{
            xs: '70px',
            md: '168px',
            lg: '302px'
          }}
          fitImage={cardType !== 'publication'}
        />
      ) : (
        <DropdownMenu
          button={() => <IconButton size="large" label="More options" name="more-ios" />}
          align="end"
          items={buttonOptions}
        />
      )}
    </div>
  );
};

export default BaseCardList;
