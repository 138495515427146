import { useCallback, useEffect } from 'react';
import { Root } from '@radix-ui/react-form';
import DropdownSelectAutocomplete, {
  SelectOption
} from '@/components/ui/DropdownSelectAutocomplete';
import TextInput from '@/components/ui/Form/TextInput';
import RequiredFieldMessage from '@/components/ui/Form/RequiredFieldMessage';
import {
  getInvalidYearAdmittedMessage,
  isYearAdmittedValid,
  LicenseData,
  LicenseNumberSettings
} from './utils/licenses';
import InvalidFieldValueMessage from '../Form/InvalidFieldValueMessage';

export type LicenseFormProps = {
  data?: LicenseData;
  onDataChange?: (data: LicenseData) => void;
  creditTypeOptions?: SelectOption[];
  jurisdictionOptions?: SelectOption[];
  complianceGroupOptions?: SelectOption[];
  licenseNumberSettings?: LicenseNumberSettings;
  creditTypeDisabled?: boolean;
  jurisdictionDisabled?: boolean;
  validationFailed?: boolean;
  isLoggedOut?: boolean;
};

const LicenseForm = ({
  data,
  onDataChange,
  creditTypeOptions,
  jurisdictionOptions,
  complianceGroupOptions,
  licenseNumberSettings,
  creditTypeDisabled = false,
  jurisdictionDisabled = false,
  validationFailed = false,
  isLoggedOut = false
}: LicenseFormProps): JSX.Element => {
  const handleOnCreditTypeChange = useCallback(
    (value: string) => {
      const creditClass_SK = parseInt(value);
      if (data?.creditClass_SK === creditClass_SK) {
        return;
      }
      const creditClass = creditTypeOptions?.find(option => option.value === value)?.label;
      onDataChange?.({
        ...data,
        creditClass_SK,
        creditClass,
        creditRegion_SK: undefined,
        creditRegion: undefined,
        stateLicenseNumber: undefined,
        yearAdmitted: undefined,
        complianceGroup_SK: undefined,
        complianceGroup: undefined
      });
    },
    [creditTypeOptions, data, onDataChange]
  );

  /* If only one jurisdiction is available, automatically select it and hide the jurisdiction dropdown menu. */

  useEffect(() => {
    if (!jurisdictionOptions) {
      return;
    }

    const creditRegion_SK = parseInt(jurisdictionOptions?.[0]?.value);

    if (data?.creditRegion_SK === creditRegion_SK) {
      return;
    }

    data?.creditClass &&
      jurisdictionOptions?.length === 1 &&
      onDataChange?.({
        ...data,
        creditRegion_SK,
        creditRegion: jurisdictionOptions[0].label,
        stateLicenseNumber: undefined,
        yearAdmitted: undefined,
        complianceGroup_SK: undefined,
        complianceGroup: undefined
      });
  }, [data, onDataChange, jurisdictionOptions]);

  const handleOnJurisdictionChange = useCallback(
    (value: string) => {
      const creditRegion_SK = parseInt(value);
      if (data?.creditRegion_SK === creditRegion_SK) {
        return;
      }

      onDataChange?.({
        ...data,
        creditRegion_SK,
        creditRegion: jurisdictionOptions?.find(option => option.value === value)?.label,
        stateLicenseNumber: undefined,
        yearAdmitted: undefined,
        complianceGroup_SK: undefined,
        complianceGroup: undefined
      });
    },
    [jurisdictionOptions, data, onDataChange]
  );

  const handleOnLicenseNumberChange = useCallback(
    (value: string) => {
      onDataChange?.({ ...data, stateLicenseNumber: value });
    },
    [data, onDataChange]
  );

  const handleOnLicenseYearChange = useCallback(
    (value: string) => {
      onDataChange?.({ ...data, yearAdmitted: value });
    },
    [data, onDataChange]
  );

  const handleOnComplianceGroupChange = useCallback(
    (value: string) => {
      onDataChange?.({
        ...data,
        complianceGroup_SK: parseInt(value),
        complianceGroup: complianceGroupOptions?.find(option => option.value === value)?.label
      });
    },
    [complianceGroupOptions, data, onDataChange]
  );

  const invalidCreditType = validationFailed && !data?.creditClass_SK;
  const invalidCreditRegion = validationFailed && !data?.creditRegion_SK;
  const invalidYearAdmitted = validationFailed && !isYearAdmittedValid(data);

  return (
    <Root onSubmit={event => event.preventDefault()}>
      <div className="flex flex-col gap-8">
        {creditTypeOptions && (
          <div
            className="relative"
            aria-invalid={invalidCreditType}
            aria-errormessage="credit-type-required-message"
          >
            <DropdownSelectAutocomplete
              label="License type"
              placeholder="Select a license type..."
              options={creditTypeOptions}
              value={data?.creditClass_SK?.toString()}
              onValueChange={handleOnCreditTypeChange}
              disabled={creditTypeDisabled}
              required={true}
            />
            {invalidCreditType && (
              <RequiredFieldMessage id="credit-type-required-message" label="License type" />
            )}
          </div>
        )}
        {!data?.isTopLevelSection &&
          !!data?.creditClass_SK &&
          jurisdictionOptions &&
          jurisdictionOptions.length > 1 && (
            <div
              className="relative"
              aria-invalid={invalidCreditRegion}
              aria-errormessage="jurisdiction-required-message"
            >
              <DropdownSelectAutocomplete
                label="Jurisdiction"
                placeholder="Select a jurisdiction..."
                options={jurisdictionOptions}
                value={data?.creditRegion_SK?.toString()}
                onValueChange={handleOnJurisdictionChange}
                disabled={jurisdictionDisabled}
                required={true}
              />
              {invalidCreditRegion && (
                <RequiredFieldMessage id="jurisdiction-required-message" label="Jurisdiction" />
              )}
            </div>
          )}
        {!isLoggedOut ? (
          <>
            {!!data?.creditRegion_SK && (
              <TextInput
                variant="container"
                label="License number"
                placeholder={licenseNumberSettings?.placeholder}
                value={data?.stateLicenseNumber ?? ''}
                onChange={handleOnLicenseNumberChange}
                errorValidationEnabled={false}
              />
            )}
            {!!data?.creditRegion_SK && (
              <div
                className="relative"
                aria-invalid={invalidYearAdmitted}
                aria-errormessage="year-admitted-invalid-message"
              >
                <TextInput
                  variant="container"
                  label="Year admitted/licensed"
                  placeholder="YYYY"
                  maxLength={4}
                  value={data?.yearAdmitted ?? ''}
                  onChange={handleOnLicenseYearChange}
                  acceptLetters={false}
                  acceptSpaces={false}
                  acceptSpecialChars={false}
                />
                {invalidYearAdmitted && (
                  <InvalidFieldValueMessage
                    id="year-admitted-invalid-message"
                    message={getInvalidYearAdmittedMessage(data)}
                  />
                )}
              </div>
            )}
            {!!data?.creditRegion_SK &&
              complianceGroupOptions &&
              complianceGroupOptions.length > 0 && (
                <DropdownSelectAutocomplete
                  label="Compliance group"
                  placeholder="Select a compliance group..."
                  options={complianceGroupOptions}
                  value={data?.complianceGroup_SK?.toString()}
                  onValueChange={handleOnComplianceGroupChange}
                />
              )}
          </>
        ) : (
          <></>
        )}
      </div>
    </Root>
  );
};

export default LicenseForm;
