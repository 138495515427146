import { useABTreatiseCatalogRelations } from '@/hooks/PDP/usePublicationCatalogRelations';
import EcommPanelPublicationContent, {
  EcommPanelPublicationContentProps
} from './EcommPanelPublicationContent';

type EcommPanelABTreatiseProps = Omit<
  EcommPanelPublicationContentProps,
  'catalogRelations' | 'libraryRelations'
>;

const EcommPanelABTreatise = ({
  isLoadingContent,
  content,
  params,
  onClose,
  context
}: EcommPanelABTreatiseProps) => {
  const catalogRelations = useABTreatiseCatalogRelations(content);

  return (
    <EcommPanelPublicationContent
      isLoadingContent={isLoadingContent}
      content={content}
      params={params}
      catalogRelations={catalogRelations}
      onClose={onClose}
      context={context}
    />
  );
};

export default EcommPanelABTreatise;
