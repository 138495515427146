import { FC, ReactElement } from 'react';
import Text from '@/components/cms/Text';
import Button from '@/components/ui/Buttons/Button';
import type { EmptyStateBlock } from '@/@types/content';
import classnames from 'classnames';
import { useGetLicenseData, useOnSaveLicense } from '@/components/ui/LicensesPage/hooks';
import AddNewLicenseButton from '@/components/ui/LicensesPage/AddNewLicenseButton';
import { UserLicense } from '@/@types/client-api';

interface EmptyStateBlockProps extends EmptyStateBlock {
  onClick?: () => void;
  viewAllJurisdiction?: ReactElement;
  isLoggedOut?: boolean;
  handleLoggedOutSave?: (license: UserLicense) => void;
}

const AddLicenseBlock: FC<EmptyStateBlockProps> = ({
  heading,
  subheading,
  buttonText,
  viewAllJurisdiction,
  onClick,
  isLoggedOut = false,
  handleLoggedOutSave
}) => {
  const hasButton = buttonText;
  const { availableCreditJurisdictions } = useGetLicenseData();
  const handleOnSaveLicense = useOnSaveLicense();
  return (
    <>
      <div
        className={classnames('flex items-center justify-between', {
          'mb-3': !!subheading
        })}
      >
        <Text element="h2" propertyName="Heading" className="heading-5">
          {heading}
        </Text>
        {viewAllJurisdiction}
      </div>
      <Text
        element="p"
        propertyName="SubHeading"
        className={classnames('text-2 text-gray-dark', { 'mt-2': hasButton })}
      >
        {subheading}
      </Text>
      {hasButton && (
        <AddNewLicenseButton
          creditJurisdictions={availableCreditJurisdictions}
          onSave={handleOnSaveLicense}
          triggerButton={
            <Button
              label={buttonText}
              iconLeft="add"
              size="short"
              color="black"
              className="mt-4"
              onClick={onClick}
            />
          }
          isLoggedOut={isLoggedOut}
          handleLoggedOutSave={handleLoggedOutSave}
        />
      )}
    </>
  );
};

export default AddLicenseBlock;
