import React, { useState, useRef, useCallback, useLayoutEffect, RefObject } from 'react';

import Icon from '../Icon';
import Divider from '../Divider';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import * as Dialog from '@radix-ui/react-dialog';
import classnames from 'classnames';
import { height, xPadding } from './utils';
import { Suggestion } from './SearchOverlaySuggestions';
import { SUGGESTIONS_ID } from './constants';

interface SearchOverlayHeaderProps {
  setSearchText: (text: string) => void;
  inputRef?: RefObject<HTMLInputElement>;
  searchText: string;
  suggestionList?: Suggestion[];
  onInputKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  completionText?: string;
  isFilled?: boolean;
  onClear?: () => void;
  onFill?: () => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  closeLabel?: string;
}

const SearchOverlayHeader: React.FC<SearchOverlayHeaderProps> = ({
  closeLabel,
  inputRef,
  searchText,
  onClear,
  onInputKeyDown,
  onInputChange,
  completionText,
  onFill,
  isFilled,
  onFocus,
  suggestionList
}) => {
  const isAboveSM = useMediaQuery(ScreenSizeQueries.sm);
  const isAboveMD = useMediaQuery(ScreenSizeQueries.md);

  const inputWidthRef = useRef<HTMLSpanElement>(null);
  const [inputWidth, setInputWidth] = useState(0);

  const calculateInputWidth = useCallback(() => {
    const spanElement = inputWidthRef.current;
    if (spanElement) {
      const spanWidth = spanElement.getBoundingClientRect().width;
      setInputWidth(spanWidth);
    }
  }, [inputWidthRef, setInputWidth]);

  useLayoutEffect(() => {
    calculateInputWidth();

    window.addEventListener('resize', calculateInputWidth);
    return () => {
      window.removeEventListener('resize', calculateInputWidth);
    };
  }, [searchText, inputWidthRef, calculateInputWidth]);

  return (
    <header>
      <label
        htmlFor="search"
        className={classnames('grid grid-cols-[minmax(0,1fr),50px]', xPadding, height)}
      >
        <div className="heading-4 relative flex size-full items-center">
          {isAboveMD && (
            <label className="absolute left-0 top-1/2 -translate-y-1/2" htmlFor="search">
              <Icon name="search" size="xx-large" />
            </label>
          )}
          <div
            className="relative flex w-full select-none overflow-hidden md:ml-14"
            onClick={() => inputRef?.current?.focus()}
          >
            <input
              autoComplete="off"
              onKeyDown={onInputKeyDown}
              onFocus={onFocus}
              id="search"
              value={searchText}
              className="z-1 h-[45px] w-full border-none bg-transparent caret-red placeholder:text-gray focus:outline-none"
              placeholder={isAboveMD ? 'Find a program, publication, or credit' : 'Search'}
              onChange={onInputChange}
              ref={inputRef}
              style={{ width: inputWidth && inputWidth > 0 && isAboveSM ? inputWidth : undefined }}
              aria-autocomplete="list"
              role="combobox"
              aria-owns={SUGGESTIONS_ID}
              aria-expanded={!!suggestionList?.length}
              aria-controls={SUGGESTIONS_ID}
            />
            <span className="hidden select-none items-center sm:flex">
              <span
                className="whitespace-nowrap text-gray"
                dangerouslySetInnerHTML={{
                  __html: (searchText && completionText?.replace(/ /g, '&nbsp;')) || ''
                }}
              />
              {completionText?.length && searchText && !isFilled ? (
                <button onClick={onFill} className="text-2 ml-2 font-thin text-gray-dark">
                  {isAboveMD ? 'Press tab' : 'Tap to fill'}
                </button>
              ) : null}
            </span>

            {/* span used to calc input width */}
            <span
              ref={inputWidthRef}
              aria-hidden
              className="pointer-events-none absolute select-none opacity-0"
              dangerouslySetInnerHTML={{
                __html: searchText && searchText?.replace(/ /g, '&nbsp;')
              }}
            />
            {searchText ? (
              <div className="z-1 ml-auto select-none overflow-visible pl-6 sm:pl-8 lg:pl-10">
                <button
                  className="text-link text-2 mr-[5px] font-normal text-gray-dark underline"
                  onClick={onClear}
                >
                  Clear
                </button>
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex items-center justify-center">
          <Dialog.Close className="bg-white text-black" aria-label={closeLabel}>
            <Icon name="close" size="large" />
          </Dialog.Close>
        </div>
      </label>
      <Divider color="light" />
    </header>
  );
};

export default SearchOverlayHeader;
