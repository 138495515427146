import { AuthContextProps, useAuth } from 'react-oidc-context';
import {
  LiveProgramCatalogRelations,
  OnDemandProgramCatalogRelations,
  ProgramCatalogRelations,
  ProgramVariation
} from './useProgramCatalogRelations';
import { isOfContentType } from '@/lib/helpers/contentType';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

const isLmsAdmin = (auth?: AuthContextProps) => {
  const isLMSAdminString: string = (auth?.user?.profile?.IsLMSAdmin as string) ?? 'false';
  if (isLMSAdminString.toLowerCase() === 'true') return true;
  return false;
};
const POECVariantContentTypes = [
  'OnDemandPbrWebVariation',
  'LiveProgramPbrSeminarVariation',
  'LiveProgramPBRWebcastVariation'
];
const isPOECVariant = (programVariation: ProgramVariation) =>
  isOfContentType(programVariation, POECVariantContentTypes);
const groupcastItemClassIds = [13, 25, 32, 83, 104, 195, 202, 210, 211, 201, 185, 188, 199, 183];
const canRequestGroupcast = (programVariation: ProgramVariation) => {
  // TODO: pending DIGI-28006, an additional thing will need to be checked to exclude some SECI WS
  if (!programVariation?.itemClassId) return false;
  return groupcastItemClassIds.find(x => x === programVariation.itemClassId);
};

const makeLMSUrl = (programVariation: ProgramVariation, urlType: 'groupcast' | 'roster') => {
  if (!programVariation.externalSystemKey) return;
  return `${window.env?.APP_LMS_BASEURL}${urlType === 'groupcast' ? window.env?.APP_LMS_REQUEST_GROUPCAST_PATH : window.env?.APP_LMS_ROSTER_REGISTER_PATH}`.replace(
    ':SK',
    programVariation.externalSystemKey.toString()
  );
};

export const useMemsuite = (
  variant: ProgramVariation,
  catalogRelations: ProgramCatalogRelations
) => {
  const memsuiteModalId = useMemo(() => `memsuite-modal-${uuid4()}`, []);
  const auth = useAuth();
  const isMemsuiteAllowedForUser = isLmsAdmin(auth);
  const isMemsuiteAllowed = isMemsuiteAllowedForUser && !isPOECVariant(variant);

  const areOtherOptionsAvailable =
    (catalogRelations as LiveProgramCatalogRelations)?.variants?.length > 1 ||
    (catalogRelations as OnDemandProgramCatalogRelations)?.programVariants?.length > 1;

  const registerFromRosterFn = useCallback(() => {
    const url = makeLMSUrl(variant, 'roster');
    window.open(url, '_blank');
  }, [variant]);

  const requestGroupcastFn = useCallback(() => {
    const url = makeLMSUrl(variant, 'groupcast');
    window.open(url, '_blank');
  }, [variant]);

  const [openMemsuiteModal, setOpenMemsuiteModal] = useState(false);

  const registerFromRosterAction = isMemsuiteAllowed ? registerFromRosterFn : undefined;
  const requestGroupcastAction =
    isMemsuiteAllowed && canRequestGroupcast(variant) ? requestGroupcastFn : undefined;
  const actionBarMode = isMemsuiteAllowed
    ? requestGroupcastAction && registerFromRosterAction
      ? 'memsuite'
      : 'roster'
    : undefined;

  return {
    isMemsuiteAllowed,
    programVariation: variant,
    areOtherOptionsAvailable,
    registerFromRosterAction,
    requestGroupcastAction,
    actionBarMode,
    open: openMemsuiteModal,
    setOpen: setOpenMemsuiteModal,
    customModalId: memsuiteModalId
  };
};
