import { ClientAPI } from '@/redux/api';
import {
  CreditCardRequest,
  PaymentMethodResponse,
  PaymentProviderTokenInfo
} from '@/@types/client-api';

const userPaymentMethodsEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getUserPaymentMethods: builder.query<PaymentMethodResponse, void>({
      query: () => ({
        url: '/user/payment-methods/credit-cards/tokens',
        method: 'GET'
      })
    }),
    postPaymentMethod: builder.mutation<void, CreditCardRequest>({
      query: body => ({
        url: '/user/payment-methods/credit-cards/tokens',
        method: 'POST',
        body
      })
    }),
    updateDefaultPaymentMethod: builder.mutation<void, Pick<CreditCardRequest, 'sk'>>({
      query: ({ sk }) => ({
        url: `/user/payment-methods/credit-cards/tokens/${sk}/default`,
        method: 'PUT'
      })
    }),
    deletePaymentMethod: builder.mutation<void, Pick<CreditCardRequest, 'sk'>>({
      query: ({ sk }) => ({
        url: `/user/payment-methods/credit-cards/tokens/${sk}`,
        method: 'DELETE'
      })
    }),
    getPaymentProviderPublicKey: builder.query<PaymentProviderTokenInfo, string | undefined>({
      query: (targetOrigin = window.location.origin, provider = 'cybersource') => ({
        // For some reason, the targetOrigin prop throws off the API and we have to use the full path
        url: `${window.env?.CONTENT_DELIVERY_API ?? ''}/api/client/user/payment-methods/credit-cards/providers/${provider}/public-key-id?targetOrigin=${targetOrigin}`
      })
    })
  })
});

export const {
  useGetUserPaymentMethodsQuery,
  usePostPaymentMethodMutation,
  useUpdateDefaultPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useLazyGetPaymentProviderPublicKeyQuery
} = userPaymentMethodsEndpoints;

export default userPaymentMethodsEndpoints;
