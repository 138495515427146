import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';

type DeleteCreditCardModalProps = Pick<ModalProps, 'open' | 'setOpen'> & {
  onConfirm: () => void;
};

const DeleteCreditCardModal = ({ onConfirm, open, setOpen }: DeleteCreditCardModalProps) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <ModalContent
        title="Are you sure?"
        saveButtonLabel="Remove from my account"
        onSaveButtonPress={onConfirm}
      >
        <div>
          Removing this credit card will delete it permanently from your account. You can add this
          card back to your PLI account at any time through the payment area of your profile.
        </div>
      </ModalContent>
    </Modal>
  );
};

export default DeleteCreditCardModal;
