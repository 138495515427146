import { ComponentProps, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import Icon from '../Icon';
import classnames from 'classnames';

export type Props = ComponentProps<typeof Checkbox.Root> & {
  count?: number;
  label: string;
  size: 'small' | 'medium' | 'large';
  variant?: 'default' | 'subpanel';
  maxLines?: number;
};

const Selector = forwardRef<HTMLButtonElement, Props>(
  ({ size, label, count, className, variant, maxLines, ...props }, forwardedRef) => {
    const [truncatedText, setTruncatedText] = useState(label);
    const labelRef = useRef<HTMLLabelElement>(null);
    const { checked, disabled } = props;
    const isSubpanel = variant === 'subpanel';

    const wrapperClasses = classnames(
      'block w-full rounded-lg bg-gray-feather text-left transition-colors duration-short hover:border-black disabled:cursor-not-allowed disabled:border-silver disabled:bg-transparent disabled:text-gray',
      {
        'border border-gray': !checked,
        'border-2 border-black': checked && !isSubpanel,
        'h-16': size === 'small',
        'border-0 bg-transparent hover:bg-gray-lightest': isSubpanel
      },
      className
    );

    const innerClasses = classnames(
      // Using an extra inner span with a transparent border to avoid jumps when border size changes
      'flex w-full items-center justify-between gap-3 border border-transparent',
      {
        'text-2 px-4': size === 'small',
        'text-1 px-6 py-4 md:px-8': size === 'medium',
        'text-1 p-6 lg:px-8': size === 'large'
      }
    );

    const ariaLabel = count ? `${label}. ${count} result${count > 1 ? 's' : ''}` : undefined;

    const truncateText = useCallback(() => {
      if (!labelRef.current || !maxLines) return;

      const lineHeight = parseFloat(getComputedStyle(labelRef.current).lineHeight);
      const maxHeight = maxLines * lineHeight;

      const adjustText = (currentText: string) => {
        setTruncatedText(currentText);

        // Using requestAnimationFrame to ensure the DOM is updated while checking the numbers
        requestAnimationFrame(() => {
          if (
            labelRef.current != null &&
            labelRef.current.scrollHeight > maxHeight &&
            currentText.length > 0
          ) {
            adjustText(currentText.slice(0, -1));
          } else if (currentText.length < label.length) {
            setTruncatedText(currentText.slice(0, -4).trim() + '...');
          }
        });
      };

      adjustText(label);
    }, [label, maxLines]);

    useEffect(() => {
      truncateText();
    }, [truncateText]);

    useEffect(() => {
      const handleResize = () => {
        truncateText(); // Re-truncate on window resize
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [truncateText]);

    return (
      <Checkbox.Root
        aria-label={ariaLabel}
        className={wrapperClasses}
        ref={forwardedRef}
        {...props}
      >
        <span className={innerClasses}>
          <label
            ref={labelRef}
            className={classnames('cursor-pointer', { '!cursor-not-allowed': disabled })}
          >
            {truncatedText}
            {!!count && <>&nbsp;({count})</>}
          </label>
          {(size === 'large' || size === 'medium') && (
            <Icon
              name="checkmark"
              size="medium"
              className={classnames('transition-opacity duration-short', {
                'opacity-0': !checked
              })}
            />
          )}
        </span>
      </Checkbox.Root>
    );
  }
);

export default Selector;
