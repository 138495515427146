import { ICertificateViewModel } from '@/@types/client-api';
import { useCallback } from 'react';
import useCertificateFileHook from '../../Certificates/hooks/useCertificateFileHook';
import { useAuth } from 'react-oidc-context';
import useToast from '@/hooks/useToast';
import { getKeysAndBlobUris } from '../../Certificates/util/certificateHelpers';
import { trackDownloadEvent, trackDownloadMultiEvent } from '@/analytics/certificates';
import { AnalyticsContext } from '@/analytics/constants';

const useDownloadCertificates = () => {
  const { showSuccessMessage, showFailMessage } = useToast();

  const auth = useAuth();
  const customerId = auth.user?.profile?.sub;

  const { downloadBulkCertificateFiles, downloadCertificateFile } = useCertificateFileHook(
    customerId ? parseInt(customerId) : undefined
  );

  const downloadCertificates = useCallback(
    async (certificates?: ICertificateViewModel[], fileName?: string) => {
      if (certificates?.length) {
        trackDownloadMultiEvent(AnalyticsContext.Certificates);
        const { keys, blobUris } = getKeysAndBlobUris(certificates);
        try {
          await downloadBulkCertificateFiles(keys, blobUris, fileName);
          showSuccessMessage('Certificates downloaded');
        } catch {
          showFailMessage('There was an error downloading certificates');
        }
      }
    },
    [downloadBulkCertificateFiles, showFailMessage, showSuccessMessage]
  );

  const downloadCertificate = useCallback(
    async (certificate: ICertificateViewModel) => {
      if (certificate) {
        trackDownloadEvent(AnalyticsContext.Certificates);
        try {
          if (certificate.certificateBlobs?.length) {
            await Promise.all(
              certificate.certificateBlobs
                .filter(blob => blob?.blobUri)
                .map(blob => downloadCertificateFile(blob.blobUri!, blob?.fileName))
            );
          } else if (certificate?.attachment_SK) {
            await downloadBulkCertificateFiles(
              [certificate?.attachment_SK],
              [],
              certificate.documentId
            );
          }
          showSuccessMessage('Certificate downloaded');
        } catch {
          showFailMessage('There was an error downloading certificate');
        }
      }
    },
    [downloadBulkCertificateFiles, downloadCertificateFile, showFailMessage, showSuccessMessage]
  );

  return {
    downloadCertificates,
    downloadCertificate
  };
};

export default useDownloadCertificates;
