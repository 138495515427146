import Link from '@/components/ui/Link';
import { TopicsAndIndustriesLabels } from './labels';

interface TaxonomyLink {
  description: string;
  url: string;
  id: number;
}
interface TopicsAndIndustriesProps {
  topics: TaxonomyLink[];
  industries: TaxonomyLink[];
}

interface TaxonomyLinkListProps {
  heading: string;
  links: TaxonomyLink[];
}
const TaxonomyLinkList = ({ heading, links }: TaxonomyLinkListProps) => {
  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-1-medium">{heading}</h2>
      <div>
        <ul className="flex flex-wrap content-start items-start gap-x-4 gap-y-1">
          {links.map(link => (
            <li key={link.id} className="flex items-center py-3">
              <Link text={link.description} href={link.url} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const TopicsAndIndustries = ({ topics, industries }: TopicsAndIndustriesProps) => {
  if (!topics.length && !industries.length) return <></>;

  const topicsSection = topics.length ? (
    <TaxonomyLinkList heading={TopicsAndIndustriesLabels.topics} links={topics} />
  ) : undefined;
  const industriesSection = industries.length ? (
    <TaxonomyLinkList heading={TopicsAndIndustriesLabels.industries} links={industries} />
  ) : undefined;

  return (
    <div className="container module-spacing-pdp flex flex-col gap-10">
      {topicsSection}
      {industriesSection}
    </div>
  );
};

export default TopicsAndIndustries;
