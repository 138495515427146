import libraryEndpoints, {
  useAddToLibraryMutation,
  useRemoveFromLibraryMutation
} from '@/redux/api/client/library';
import useToast from '../useToast';
import { SaveToLibraryToast } from '@/components/ui/Cards/SaveToLibraryToast';
import { ActionItem } from '@/components/ui/PDP/ActionBar';
import { useDispatch } from 'react-redux';
import { LibraryItem } from '@/@types/client-api';
import { AnalyticsContext } from '@/analytics/constants';
import { trackAddWishListEvent, trackRemoveWishListEvent } from '@/analytics/ecommerce';

export const useLibraryAction = (
  code: string,
  library: {
    data?: LibraryItem;
    isFetching?: boolean;
  },
  context?: AnalyticsContext
) => {
  const { data, isFetching } = library;
  const [addToLibrary] = useAddToLibraryMutation();
  const [removeFromLibrary] = useRemoveFromLibraryMutation();
  const { showSuccessMessage, showFailMessage } = useToast();

  const dispatch = useDispatch();

  const addToLibraryAction = async (code: string, undoAction?: boolean) => {
    if (library?.data?.itemAnalyticsModel)
      trackAddWishListEvent(library?.data?.itemAnalyticsModel, context);

    const dataResponse = await addToLibrary(code);

    if ('error' in dataResponse) {
      showFailMessage('Error while saving item My Library');
    } else if ('data' in dataResponse) {
      dispatch(
        libraryEndpoints.util.invalidateTags([
          { type: 'LibraryItem', id: code },
          { type: 'Library', id: 'List' }
        ])
      );
      if (undoAction) {
        const toastNode = (
          <SaveToLibraryToast
            message="Saved to My Library."
            onClickFunction={() => removeFromLibaryAction(code)}
          />
        );
        return showSuccessMessage(toastNode);
      } else {
        return showSuccessMessage('Saved to My Library');
      }
    }
  };

  const removeFromLibaryAction = async (code: string, undoAction?: boolean) => {
    if (library?.data?.itemAnalyticsModel)
      trackRemoveWishListEvent(library?.data?.itemAnalyticsModel, context);

    const dataResponse = await removeFromLibrary(code);

    if ('error' in dataResponse) {
      showFailMessage('Error while removing item from My Library');
    } else if ('data' in dataResponse) {
      dispatch(
        libraryEndpoints.util.invalidateTags([
          { type: 'LibraryItem', id: code },
          { type: 'Library', id: 'List' }
        ])
      );
      if (undoAction) {
        const toastNode = (
          <SaveToLibraryToast
            message="Removed from My Library. "
            onClickFunction={() => addToLibraryAction(code)}
          />
        );
        return showSuccessMessage(toastNode);
      } else {
        return showSuccessMessage('Removed from My Library');
      }
    }
  };

  const isInLibrary = !!((data?.isPurchased && !data?.isArchived) || data?.isFavorite);

  const libraryAction: ActionItem = isInLibrary
    ? {
        label: 'Saved to My Library',
        icon: 'checkmark',
        onClick: () => removeFromLibaryAction(code),
        loading: isFetching
      }
    : {
        label: 'Save to My Library',
        icon: 'add',
        onClick: () => addToLibraryAction(code),
        loading: isFetching
      };
  return libraryAction;
};
