import { FacetOperation, IFacetGroup, SearchRequest, SearchResponse } from '@/@types/client-api';
import {
  EXCLUDE_FROM_URL_PARAMS,
  FACET_DELIMITER,
  FacetFilter,
  FILTERS,
  FIRST_PAGE,
  InvariantFilter,
  PAGE_SIZE,
  QUERY_PARAMS
} from '../constants';
import { getCurrentPage } from '@/utils/pagination';
import { isStartEndDateFilter } from '../../Filter/DateFilterUtils';
import { addFacetFiltersToFacetGroups } from './filters';
import { useSelector } from 'react-redux';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { useCallback } from 'react';
import usePrevious from '@/hooks/usePrevious';
import { selectSearchData } from '@/redux/selectors/searchSelectors';

const separator = '|';

export type FacetGroupOverrides = { [filterType: string]: { operation: FacetOperation } };

export const getInitialSearchRequest = (
  urlQueryParams: URLSearchParams,
  semanticFilters?: IFacetGroup[] | null,
  facetFilters?: FacetFilter[] | null,
  facetOverrides?: FacetGroupOverrides | null
): SearchRequest => {
  const pageParam = urlQueryParams.get(QUERY_PARAMS.PAGE);
  const page = pageParam ? parseInt(pageParam) : FIRST_PAGE;
  const sort = urlQueryParams.get(QUERY_PARAMS.SORT);

  const facetGroups: IFacetGroup[] = [];
  Object.values(FILTERS).forEach(filterName => {
    if (EXCLUDE_FROM_URL_PARAMS.includes(filterName)) return;

    const filterValue = urlQueryParams.get(filterName);
    if (filterValue) {
      const facets = filterValue.split(FACET_DELIMITER).map(value => {
        if (FILTERS.PRACTICE_AREA === filterName || FILTERS.DATERANGE === filterName) {
          const [name, facetValue] = value.split(separator);
          return FILTERS.DATERANGE === filterName && facetValue
            ? { name, value: facetValue }
            : { name, value: facetValue, checked: true };
        } else if (
          FILTERS.CREDIT_TYPE === filterName &&
          urlQueryParams.has(QUERY_PARAMS.OVERRIDE_EXCLUSIVE)
        ) {
          const overrides = (urlQueryParams.get(QUERY_PARAMS.OVERRIDE_EXCLUSIVE) ?? '').split(
            FACET_DELIMITER
          );

          return { value, checked: true, overrideIsExclusive: overrides.includes(value) };
        }
        return { value, checked: true };
      });
      facetGroups.push({
        groupTypeId: filterName,
        facets,
        ...facetOverrides?.[filterName]
      });
    }
  });

  /* Auto apply CMS facet filters */
  const filterApplied = urlQueryParams.get(QUERY_PARAMS.CMS_FACET_FILTERS_APPLIED);
  if (!filterApplied) {
    addFacetFiltersToFacetGroups({ facetFilters: facetFilters || undefined, facetGroups });
  }

  semanticFilters?.forEach(filter => {
    const existingFilter = facetGroups.find(group => group.groupTypeId === filter.groupTypeId);
    if (existingFilter) {
      filter.facets?.concat(filter.facets);
    } else {
      facetGroups.push(filter);
    }
  });

  return {
    // If we need to load multiple pages, we do this via the pageSize prop
    pageSize: PAGE_SIZE * page,
    // From the API's perspective we are on the first page
    currentPage: FIRST_PAGE,
    sort,
    facetGroups
  };
};

export const searchDataToSearchParams = (
  data: Partial<SearchResponse>,
  existingParameters = new URLSearchParams(),
  invariantFilters?: InvariantFilter[],
  facetFilters?: FacetFilter[]
): URLSearchParams => {
  const { searchResultSummary, facetResults, searchResults, sortResults } = data || {};

  const page = getCurrentPage(
    searchResultSummary?.currentPage,
    searchResults?.searchResultsItems?.length
  );

  /* Set a query param to auto apply CMS facet filters only on the first load */

  if (facetFilters?.length) {
    existingParameters?.set(QUERY_PARAMS.CMS_FACET_FILTERS_APPLIED, 'true');
  }

  existingParameters.delete(QUERY_PARAMS.NEW_SEARCH);

  if (page === FIRST_PAGE) {
    existingParameters.delete(QUERY_PARAMS.PAGE);
  } else {
    existingParameters.set(QUERY_PARAMS.PAGE, page.toString());
  }

  if (facetResults?.facetGroups) {
    facetResults.facetGroups.forEach(group => {
      if (!group || EXCLUDE_FROM_URL_PARAMS.includes(group.groupTypeId!)) return;

      const checkedFacets = group.facets?.filter(facet => facet.checked && !facet.isDefault);
      const dateFacets = group.facets?.filter(
        facet => facet.value && isStartEndDateFilter(facet.name)
      );

      if (dateFacets?.length) {
        const paramValue = dateFacets
          .map(facet => {
            if (group.groupTypeId === FILTERS.DATERANGE) {
              return `${facet.name}${separator}${facet.value}`;
            }
            return facet.value;
          })
          .join(FACET_DELIMITER);

        existingParameters.set(group.groupTypeId!, paramValue);
      } else if (checkedFacets?.length) {
        const paramValue = checkedFacets
          .map(facet => {
            if (group.groupTypeId === FILTERS.PRACTICE_AREA) {
              return `${facet.name}${separator}${facet.value}`;
            }
            return facet.value;
          })
          .join(FACET_DELIMITER);

        existingParameters.set(group.groupTypeId!, paramValue);

        if (group.groupTypeId == FILTERS.CREDIT_TYPE) {
          const creditsToOverrideExclusive = group.facets?.filter(
            facet => facet.overrideIsExclusive
          );

          if (creditsToOverrideExclusive?.length) {
            existingParameters.set(
              QUERY_PARAMS.OVERRIDE_EXCLUSIVE,
              creditsToOverrideExclusive.map(facet => facet.value).join(FACET_DELIMITER)
            );
          }
        }
      } else {
        existingParameters.delete(group.groupTypeId!);
      }
    });
  }

  const sort = sortResults?.sortResultItems?.find(
    sortItem => sortItem.selected && !sortItem.isDefault
  )?.value;

  if (sort) {
    existingParameters.set(QUERY_PARAMS.SORT, sort);
  } else {
    existingParameters.delete(QUERY_PARAMS.SORT);
  }

  if (invariantFilters) {
    invariantFilters.forEach(filter => existingParameters.delete(filter.facetGroupType));
  }

  return existingParameters;
};

export const useBuildNewSearchLink = () => {
  const pageLinks = useSelector(selectPageLinkPaths);
  const previousData = usePrevious(useSelector(selectSearchData));
  const previousParams = searchDataToSearchParams(previousData || {});

  return useCallback(
    (
      data: Partial<SearchResponse>,
      paramCallback: (input: URLSearchParams) => URLSearchParams = params => params
    ) => {
      const params = searchDataToSearchParams(data);
      previousParams.delete(FILTERS.CONTENT_TABS);
      if (params.toString() !== previousParams.toString()) {
        params.set(QUERY_PARAMS.NEW_SEARCH, '1');
      }
      const newSearchParams = paramCallback(params);
      return {
        newSearchLink: `${pageLinks?.Search}?${newSearchParams.toString()}`,
        newSearchParams
      };
    },
    [pageLinks?.Search, previousParams]
  );
};
