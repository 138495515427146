export enum AnalyticsEventName {
  SetUserInfo = 'set_user_information',
  Login = 'login',
  PliPromo = 'pli_promo',
  AutoComplete = 'autocomplete',
  Search = 'search',
  ViewSearchResults = 'view_search_results',
  SearchNoResults = 'search_no_results',
  LoadMore = 'load_more',
  Pagination = 'pagination',
  Filter = 'filter',
  Sort = 'sort',
  LibraryFilter = 'library_filter',
  LibrarySort = 'library_sort',
  AddToCalendar = 'add_to_cal',
  ContactSupport = 'contact_support',
  NavTrackerMini = 'nav_tracker_mini',
  NavMemsuite = 'nav_memsuite',
  LaunchCreditDetails = 'launch_credit_details',
  AddToWishlist = 'add_to_wishlist',
  RemoveFromWishlist = 'remove_from_wishlist',
  ViewMaterials = 'view_materials',
  DownloadSchedule = 'download_schedule',
  DownloadMaterials = 'download_materials',
  ViewTranscript = 'view_transcript',
  ReadTranscript = 'read_transcript',
  NotifyMe = 'notify_me',
  StartListening = 'start_listening',
  StartListeningOtherServices = 'start_listening_other_services',
  SelectItem = 'select_item',
  AddToCart = 'add_to_cart',
  LaunchAProgram = 'launch_a_program',
  ViewCertificates = 'view_certificates',
  ReadNow = 'read_now',
  EditReservation = 'edit_reservation',
  CancelReservation = 'cancel_reservation',
  RequestCreditStart = 'request_credit_start',
  Exception = 'exception',
  EditProfile = 'edit_profile',
  ViewNudge = 'view_nudge',
  DismissNudge = 'dismiss_nudge',
  UpdateNudge = 'update_nudge',
  ViewPracticeAreaItem = 'view_pa_item',
  ViewIndustryItem = 'view_industry_item',
  ViewFacultyItem = 'view_faculty_item',
  ViewFooterLink = 'view_footer_link',
  OrderHistorySort = 'profile_order_history_sort',
  OrderHistoryFilter = 'profile_order_history_filter',
  CertificatesSort = 'cert_sort',
  CertificatesFilter = 'cert_filter',
  CertificatesDownload = 'cert_download',
  CertificatesDownloadMulti = 'cert_download_multi',
  CertificatesEdit = 'cert_edit',
  FindApprovedPrograms = 'find_approved_programs',
  ViewRequirements = 'view_reqs',
  EarnCredit = 'earn_credit',
  AddLicense = 'add_license',
  EditLicense = 'edit_license',
  RemoveLicense = 'remove_license',
  EditCertificatePreferences = 'edit_certificate_preferences',
  Share = 'share',
  ViewCompliancePeriod = 'view_compliance_period',
  EditCompliancePeriod = 'edit_compliance_period',
  ViewTrackerCompliance = 'view_tracker_compliance',
  ViewPromotion = 'view_promotion',
  SelectPromotion = 'select_promotion',
  AddExternal = 'add_external',
  ViewAllClick = 'view_all_click',
  AddCarryover = 'add_carryover',
  ViewTracker = 'view_tracker',
  MoreLikeThis = 'more_like_this',
  ViewItemList = 'view_item_list',
  DownloadContents = 'download_contents',
  PubUpdateInfo = 'pub_update_info',
  SearchContents = 'search_contents',
  ViewItem = 'view_item',
  FormSubmitComplete = 'form_submit_complete',
  BeginCheckout = 'begin_checkout',
  Purchase = 'purchase',
  ZeroPurchaseValue = 'zero_purchase_value',
  ViewAllJurisdictions = 'view_all_jrsd',
  ViewCrossLink = 'view_crosslnk'
}

export const ClearAllFiltersEventProperties = {
  facetGroup: 'Clear All',
  facetValue: 'clear_all'
};

export enum AnalyticsContext {
  Search = 'search',
  SearchOverlay = 'search_overlay',
  Library = 'library',
  PDP = 'pdp',
  Certificates = 'certificates',
  CreditTracker = 'credit_tracker',
  CreditTrackerMini = 'mini_tracker',
  Licenses = 'licenses',
  Compliance = 'compliance',
  NonCompliance = 'non_compliance',
  MarketingModule = 'marketing_module',
  Cards = 'cards'
}

export enum NudgeAnalyticsContext {
  About = 'About',
  Contact = 'Contact',
  Address = 'Address',
  PracticeArea = 'Practice Area',
  Industry = 'Industry',
  Faculty = 'Faculty',
  Payment = 'Payment',
  Licenses = 'Licenses'
}

export enum ProfileSection {
  About = 'About',
  Contact = 'Contact',
  Address = 'Address',
  LoginDetails = 'Login details',
  Payment = 'Payment',
  Preferences = 'Preferences'
}

export enum FilterContext {
  Overlay = 'overlay',
  QuickFilter = 'quick',
  AllFiltersPanel = 'all_filter'
}

export enum SearchContext {
  ResultsPage = 'results_page',
  Overlay = 'overlay',
  ResultsBlock = 'results_block',
  FAPBlock = 'FAP_block'
}

export enum FormContext {
  RequestAQuote = 'request_a_quote',
  StartTrial = 'start_trial',
  Subscribe = 'subscribe'
}
