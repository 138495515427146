import classnames from 'classnames';
import React, { ReactNode } from 'react';
import IconButton from '../IconButton';

type MenuContentProps = {
  mobileFooterContent?: (props: { onClose: () => void }) => JSX.Element;
  children: ReactNode;
  onClose: () => void;
  menuStyle: 'standalone' | 'form-field' | 'standalone-dark' | 'standalone-dark-unsorted';
  mobileTitle?: string;
};

const MenuContent = React.forwardRef<HTMLDivElement, MenuContentProps>(
  ({ children, mobileTitle, mobileFooterContent, onClose, menuStyle, ...props }, forwardedRef) => {
    const wrapperClasses = classnames({
      // Max height allows first 7 items to show fully
      'scrollbar-hide sm:z-10 sm:max-h-[341px] sm:w-[var(--radix-popper-anchor-width)] sm:overflow-y-auto sm:rounded-lg sm:rounded-t-none sm:border sm:border-t-0 sm:border-gray-medium sm:bg-gray-feather sm:py-4':
        menuStyle === 'form-field',
      'sm:z-20 sm:mt-2 sm:min-w-[210px] sm:rounded sm:border sm:border-silver sm:bg-white sm:py-3':
        menuStyle === 'standalone',
      'max-h-[385px] overflow-y-auto rounded-t-lg bg-white sm:z-20 sm:mt-2 sm:max-h-[250px] sm:min-w-[210px] sm:rounded sm:border sm:border-silver sm:py-3':
        menuStyle === 'standalone-dark' || menuStyle === 'standalone-dark-unsorted'
    });

    const contentClasses = classnames({
      // Max height allows the next item to peak to indicate content is scrollable
      'max-sm:max-h-[457px] max-sm:overflow-y-auto': menuStyle === 'form-field'
    });

    return (
      <div {...props} className={wrapperClasses} ref={forwardedRef} data-component={"MenuContent"}>
        <div className="flex justify-between border-b border-gray-light p-6 sm:hidden">
          <h2 className="text-1 text-gray-dark">{mobileTitle}</h2>
          <IconButton className="block" label="Close" name="close" size="small" onClick={onClose} />
        </div>
        <div className={contentClasses}>{children}</div>
        {mobileFooterContent && (
          <div className="flex border-t border-gray-light px-6  py-5 sm:hidden">
            {mobileFooterContent({ onClose })}
          </div>
        )}
      </div>
    );
  }
);

export default MenuContent;
