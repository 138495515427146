import { OnDemandOhbMp4Variation as OnDemandOhbMp4VariationProps } from '@/@types/content';
import withNav from '@/components/hocs/withNav';
import OnDemandProgramPDP from '@/components/ui/PDP/OnDemandProgramPDP';
import PDPShimmer from '@/components/ui/PDP/PDPShimmer';
import { useOnDemandProgramCatalogRelations } from '@/hooks/PDP/useProgramCatalogRelations';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';
import { AnalyticsContext } from '@/analytics/constants';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';

const OnDemandOhbMp4VariationComponent = (variant: OnDemandOhbMp4VariationProps) => {
  const authReady = useSelector((state: RootState) => state.auth.ready);
  const { relations: programCatalogRelations, isLoading } =
    useOnDemandProgramCatalogRelations(variant);
  const { isLoading: isLabelsLoading } = useCatalogLabels();

  if (programCatalogRelations.initialVariantSelection && authReady && !isLabelsLoading) {
    return (
      <OnDemandProgramPDP
        catalogRelations={programCatalogRelations}
        loadingCatalogRelations={isLoading}
        selectDownloadByDefault={variant}
        context={AnalyticsContext.PDP}
      />
    );
  }

  return <PDPShimmer show={true} />;
};

const OnDemandOhbMp4Variation = withNav(OnDemandOhbMp4VariationComponent);
export default OnDemandOhbMp4Variation;
