import Button from '@/components/ui/Buttons/Button';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Divider from '../Divider';
import CertificatePreferencesPanel, {
  CertificatePreference
} from '../ProfilePanels/CertificatePreferencesPanel';
import { useMemo } from 'react';
import { trackEditCertificatePreferencesEvent } from '@/analytics/licenses';

export type CertificatesPreferencesSectionProps = {
  certificatePreferences: CertificatePreference[];
  selectedKey?: number;
  onSave: (selectedKey: string) => void;
};

const CertificatesPreferencesSection = ({
  certificatePreferences,
  selectedKey,
  onSave
}: CertificatesPreferencesSectionProps): JSX.Element => {
  const isMediumAndAbove = useMediaQuery(ScreenSizeQueries.md);

  const selectedCertificatePreference = useMemo(
    () => certificatePreferences.find(preference => preference.key === selectedKey),
    [certificatePreferences, selectedKey]
  );

  return (
    <div className="py-10 lg:py-16">
      <div className="mb-4 flex flex-col md:mb-0 md:flex-row">
        <div className="flex flex-col md:flex-1">
          <h3 className="heading-6-medium">Certificate preferences</h3>
          <p className="text-2 mb-4 mt-2 text-gray-dark">
            Please indicate how you would like to receive your certificates.
          </p>
        </div>

        <CertificatePreferencesPanel
          selectedOption={selectedCertificatePreference}
          options={certificatePreferences}
          onSave={onSave}
          triggerButton={
            <Button
              color="light-gray"
              iconRight={isMediumAndAbove ? undefined : 'edit'}
              label="Edit"
              size={isMediumAndAbove ? 'small' : 'short'}
              onClick={trackEditCertificatePreferencesEvent}
            />
          }
        />
      </div>
      <Divider color="light" />
      <p className="text-2 pt-8 lg:pt-12">{selectedCertificatePreference?.text}</p>
    </div>
  );
};

export default CertificatesPreferencesSection;
