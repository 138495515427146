import { forwardRef as ReactForwardRef } from 'react';
import { Link } from 'react-router-dom';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import classnames from 'classnames';
import DropdownMenu from '@/components/ui/DropdownMenu';
import IconButton from '@/components/ui/IconButton';
import Thumbnail from '@/components/ui/Thumbnail';
import { ResponsiveImageProps } from '../ResponsiveImage';
import React from 'react';

type OverlayColor = 'bg-red-darker';

export interface ThumbnailProps {
  image?: {
    url: string;
    alt?: string;
  };
  iconThumbnail?: {
    name: string;
    label?: string;
  };
}

export interface CardProps {
  size?: 'medium' | 'large';
  className?: string;
  headingSmall?: string;
  heading?: string;
  subheading?: string;
  labels?: string[];
  image?: {
    url: string;
    alt?: string;
  };
  href?: string;
  target?: string;
  hasDropdown?: boolean;
  overlayColor?: OverlayColor;
  isLabelColumn?: boolean;
  onClickCard?: () => void;
}

export interface CardContentProps {
  size?: 'medium' | 'large';
  headingSmall?: string;
  heading?: string;
  subheading?: string;
  labels?: string[];
  isLabelColumn?: boolean;
  onOptionsButton?: () => void;
}

const CardContent: React.FC<CardContentProps> = ({
  headingSmall,
  heading,
  subheading,
  labels,
  size,
  isLabelColumn
}) => {
  const contentContainerClasses = classnames(
    'pointer-events-none absolute inset-0 flex flex-col justify-between bg-transparent',
    {
      'size-full p-6 pb-3.5 lg:px-[40px] lg:py-[32px] lg:pb-[22px]': size === 'large',
      'size-full p-4 lg:p-6 lg:pb-3.5': size === 'medium'
    }
  );

  const contentTextClasses = classnames('line-clamp-3 text-left text-white', {
    'heading-6-medium font-medium lg:heading-5-medium ': size === 'large',
    'text-2 font-medium lg:heading-6-medium': size === 'medium'
  });

  const contentLabelsClasses = classnames('text-left font-medium text-white', {
    'text-2 pb-[10px]': size === 'large',
    'label lg:pb-[10px]': size === 'medium'
  });

  const headingWrapperClasses = classnames('flex flex-col', {
    'gap-2': size === 'medium',
    'gap-4': size === 'large'
  });

  const headingSmallClasses = classnames('text-left font-medium text-white', {
    'text-2': size === 'large',
    label: size === 'medium'
  });

  const largeWindow = useMediaQuery(ScreenSizeQueries.lg);

  let labelInfo;

  if (largeWindow || size === 'large' || isLabelColumn) {
    labelInfo = labels?.map((label, index) => (
      <p key={index} className="text-white">
        {label}
      </p>
    ));
  } else {
    labelInfo = <p className="text-white">{labels?.join(', ')}</p>;
  }

  const formatText = (text: string) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className={contentContainerClasses}>
      <div className={headingWrapperClasses}>
        {headingSmall && <span className={headingSmallClasses}>{headingSmall}</span>}
        <span>
          <h3 className={contentTextClasses}>{formatText(heading || '')}</h3>
          <h4 className={contentTextClasses}>{subheading}</h4>
        </span>
      </div>
      <div className="flex items-end justify-between">
        <div className={contentLabelsClasses}>{labelInfo}</div>
      </div>
    </div>
  );
};

const CollectionCard: React.FC<CardProps> = ReactForwardRef(
  (
    {
      size,
      className,
      headingSmall,
      heading,
      subheading,
      labels,
      image,
      href,
      target = '_self',
      hasDropdown = true,
      overlayColor,
      isLabelColumn,
      onClickCard,
      ...props
    },
    ref
  ) => {
    let ratio;
    const belowMdWindow = useMediaQuery(ScreenSizeQueries.belowMd);
    const largeWindow = useMediaQuery(ScreenSizeQueries.lg);

    if (size === 'large') {
      if (belowMdWindow) {
        ratio = 327 / 420;
      } else {
        ratio = 3 / 2;
      }
    } else {
      ratio = 3 / 2;
    }

    const sizeClassesRules = {
      'h-[420px] w-full md:h-[352px] lg:h-[417px]': size === 'large',
      'h-[155px] w-full md:h-[168px] lg:h-[302px]': size === 'medium'
    };

    const cardClasses = classnames(
      'relative overflow-hidden rounded-lg bg-collection-card-gradient',
      sizeClassesRules
    );
    const imageContainerClasses = classnames(
      'flex size-full items-center justify-center',
      image?.url ? '' : 'opacity-30',
      overlayColor || ''
    );
    const sizeClasses = classnames('group', sizeClassesRules, className);

    const optionButtonClasses = classnames('z-1 text-white hover:bg-charcoal focus:bg-charcoal', {
      'absolute bottom-3.5 right-3.5 rounded p-[10px]': size === 'large',
      'absolute bottom-3.5 right-[20px] p-[10px] lg:bottom-2 lg:right-[15px] lg:rounded':
        size === 'medium'
    });

    let ButtonOrLink;

    const thumbnailProps: ThumbnailProps = {
      image
    };

    let imageSizes: ResponsiveImageProps['imageSizes'] = {
      xs: '327px',
      md: '720px',
      lg: '1280px'
    };

    if (size === 'medium') {
      imageSizes = {
        xs: '327px',
        md: '352px',
        lg: '628px'
      };
    }

    const ButtonContent = (
      <div className={sizeClasses}>
        <Thumbnail
          ratio={ratio}
          icon={largeWindow ? thumbnailProps?.iconThumbnail : undefined}
          className={imageContainerClasses}
          {...thumbnailProps}
          imageSizes={imageSizes}
        />
        <CardContent
          size={size}
          headingSmall={headingSmall}
          heading={heading}
          subheading={subheading}
          labels={labels}
          isLabelColumn={isLabelColumn}
        />
      </div>
    );

    if (href) {
      ButtonOrLink = (
        <Link ref={ref as React.Ref<HTMLAnchorElement>} to={href} target={target}>
          {ButtonContent}
        </Link>
      );
    } else {
      ButtonOrLink = (
        <button ref={ref as React.Ref<HTMLButtonElement>} onClick={onClickCard}>
          {ButtonContent}
        </button>
      );
    }

    return (
      <article className={cardClasses} {...props} ref={ref as React.Ref<HTMLElement>}
      data-component={"CollectionCard"}
      >
        {ButtonOrLink}sfvsdfg
        {hasDropdown && (
          <DropdownMenu
            button={() => (
              <IconButton
                size="large"
                label="More options"
                name="more-ios"
                className={optionButtonClasses}
              />
            )}
            align="start"
            items={[
              {
                label: 'Share',
                onClick: () => {},
                icon: 'share'
              }
            ]}
          />
        )}
      </article>
    );
  }
);

export default CollectionCard;
