import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Panel, { PanelProps } from '@/components/ui/Panel';
import Button from '@/components/ui/Buttons/Button';
import Link from '@/components/ui/Link';
import { Root } from '@radix-ui/react-form';
import EditPanelProvider from './context';

export type EditPanelProps = Omit<PanelProps, 'size'> & {
  loading?: boolean;
  title: string;
  onSave: () => void;
  onDelete?: () => void;
  onValidateFields?: () => boolean;
  triggerButton: JSX.Element;
  contentRef?: RefObject<HTMLDivElement>;
};

const EditPanel = ({
  isOpen,
  loading,
  onOpenChange,
  theme,
  children,
  title,
  onSave,
  onValidateFields,
  onDelete,
  triggerButton,
  contentRef
}: EditPanelProps): JSX.Element => {
  const buttonsDiv = useRef<HTMLDivElement>(null);
  const [validationFailed, setValidationFailed] = useState(false);
  const [buttonsDivHeight, setButtonsDivHeight] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setValidationFailed(false);
      setTimeout(() => {
        setButtonsDivHeight(buttonsDiv.current?.clientHeight || 0);
      }, 0);
    }
  }, [isOpen]);

  const handleOnSave = useCallback(() => {
    const fieldsValid = onValidateFields ? onValidateFields() : true;
    if (fieldsValid) {
      onSave();
    } else {
      setValidationFailed(true);
    }
  }, [onSave, onValidateFields]);

  const fieldsContainerStyle = useMemo(
    () => ({ paddingBottom: buttonsDivHeight }),
    [buttonsDivHeight]
  );

  const footerDivStyle =
    "fixed bottom-0 right-0 z-50 w-full max-w-[422px] bg-white before:pointer-events-none before:absolute before:-top-6 before:left-0 before:h-6 before:w-full before:bg-gradient-to-b before:from-transparent before:to-white before:content-['']";

  return (
    <Panel isOpen={isOpen} onOpenChange={onOpenChange} theme={theme} size="small">
      <Panel.Trigger asChild>{triggerButton}</Panel.Trigger>
      <Panel.Content side="right">
        <Panel.Header className="z-50">{title}</Panel.Header>
        <Panel.Body>
          <Root onSubmit={event => event.preventDefault()}>
            <div className="relative">
              <div style={fieldsContainerStyle}>
                <EditPanelProvider validationFailed={validationFailed}>
                  <div className="flex flex-col gap-10 pb-8" ref={contentRef}>
                    {children}
                  </div>
                </EditPanelProvider>
              </div>
              <div ref={buttonsDiv} className={footerDivStyle}>
                <div className="px-6 pb-5 md:px-8">
                  <div className="flex gap-2">
                    <Button
                      loading={loading}
                      className="flex-1"
                      label="Save"
                      size="large"
                      onClick={handleOnSave}
                    />
                  </div>
                  {onDelete && (
                    <div className="flex justify-center pt-4">
                      <Link text="Delete" variant="text-link-2" as="button" onClick={onDelete} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Root>
        </Panel.Body>
      </Panel.Content>
    </Panel>
  );
};

export default EditPanel;
