import { PayloadAction, SerializedError, createSlice } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  CommonSearchState,
  clearFilterHelper,
  createSuccessHelper,
  createToggleFilterHelper
} from '../helpers/search';
import { FILTERS } from '@/components/ui/SearchResults/constants';

export type FindAProgramState = CommonSearchState;

const initialFindAProgramState: FindAProgramState = {
  data: null,
  request: null,
  isLoading: false,
  isFetching: false,
  error: null,
  adHocFilters: [],
  preselectedFiltersThatHaveBeenDeselected: []
};

const ALLOWED_AD_HOC_FILTERS = [FILTERS.JURISDICTIONS, FILTERS.PRACTICE_AREA];

const findAProgramSlice = createSlice({
  name: 'findAProgram',
  initialState: initialFindAProgramState,
  reducers: {
    findAProgramLoading: (state, payload: PayloadAction<'loading' | 'fetching' | 'none'>) => {
      state.isLoading = payload.payload === 'loading';
      state.isFetching = payload.payload === 'fetching';
    },
    findAProgramSuccess: createSuccessHelper(ALLOWED_AD_HOC_FILTERS),
    findAProgramFailure: (state, action: PayloadAction<FetchBaseQueryError | SerializedError>) => {
      state.isLoading = false;
      state.isFetching = false;
      state.error = action.payload;
    },
    toggleFindAProgramFilter: createToggleFilterHelper(ALLOWED_AD_HOC_FILTERS),
    clearFindAProgramFilters: clearFilterHelper
  }
});

export const {
  findAProgramLoading,
  findAProgramSuccess,
  findAProgramFailure,
  toggleFindAProgramFilter,
  clearFindAProgramFilters
} = findAProgramSlice.actions;

export default findAProgramSlice.reducer;
