import { formatBEDateToFEDate } from '@/utils/helpers';
import { DATE_FACETS } from '../SearchResults/constants';

export const isStartEndDateFilter = (name: string | null | undefined) =>
  isStartDateFilter(name) || isEndDateFilter(name);

export const isStartDateFilter = (name: string | null | undefined) =>
  name === DATE_FACETS.START_DATE;

export const isEndDateFilter = (name: string | null | undefined) => name === DATE_FACETS.END_DATE;

export const StartDateFilterLabel = 'Start date';
export const EndDateFilterLabel = 'End date';

export const getDateFilterLabel = (name: string | null | undefined) => {
  if (isStartDateFilter(name)) return StartDateFilterLabel;
  if (isEndDateFilter(name)) return EndDateFilterLabel;
  return name ?? '';
};

export const formatDateFilter = (
  name: string | null | undefined,
  value: string | null | undefined
) => `${getDateFilterLabel(name)}: ${formatBEDateToFEDate(value ?? undefined)}`;
