import * as Tabs from '@radix-ui/react-tabs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '@/redux/store';
import {
  Variant,
  selectVariant,
  setMaterialsLoading,
  setOpenRegistrationModal,
  setSoftSelectedVariant
} from '@/redux/slices/pdpSlice';
import useInViewport from '@/hooks/useInViewport';
import BaseBox from '../components/BaseBox';
import ActionButton from './components/ActionButton';
import VariantsSelector from './components/VariantsSelector';
import Registrations from './components/Registrations';
import Button from '@/components/ui/Buttons/Button';
import RegistrationUpdatePanel from './components/RegistrationUpdatePanel';
import { useAddToCart } from '@/hooks/shared/useAddToCart';
import { useRegistrations } from '@/hooks/shared/useRegistrations';
import { useCancelRegistrations } from '@/hooks/shared/useCancelRegistrations';
import classnames from 'classnames';
import { useLazyGetRegistrationCancellableQuery } from '@/redux/api/client/registration';
import LiveVariant from './components/LiveVariant';
import { MaterialsLabels } from '../../labels';
import ProgramMaterialsModal from '../../Modals/ProgramMaterialsModal';
import { useLaunch } from '@/hooks/shared/useLaunch';
import AddToCalendarModal from '../../Modals/AddToCalendarModal';
import { v4 as uuid4 } from 'uuid';
import { resetSearchAPIs } from '@/redux/api/client/search';
import { AnalyticsContext } from '@/analytics/constants';
import Loading from './components/Loading';
import { useFetchAnalyticsItems } from '@/analytics/useFetchAnalyticsItems';
import { trackAddToCart } from '@/analytics/ecommerce';
import { createTransactionId } from '@/analytics/analyticsUtils';

export interface UpcomingLiveProps {
  labels: {
    registrations: string;
    variantsSelector: string;
    online: string;
    inPerson: string;
    groupcast: string;
    registerButton: string;
    purchaseButton: string;
    launchButton: string;
    privilegedMembers?: string;
    seciWsDiscountAdvice?: string;
    extraMessage?: string;
    programId: string;
    waitlist: string;
    waitlistMessage: string;
    waitlistButton: string;
    noSelectionMsg: string;
    changeOrCancelLabel: string;
    cancelLabel: string;
    materials: string;
    addToCalendar: string;
    addToCalendarModalHeader: string;
    registrationSuccessMessage: string;
    cartSuccessMessage: string;
    cartFailedMessage: string;
    changeSuccessMessage: string;
    cancelSuccessMessage: string;
    watchNow: string;
    joinNow: string;
    ondemandCrosslink: string;
  };
  onDemandProgram?: {
    url?: string;
    title?: string;
    publicationDate?: string;
    isLoading?: boolean;
    label: string;
  };
  isMobile?: boolean;
  title?: string;
  onOpenPanel?: () => void;
  onClosePanel?: () => void;
  seeDetailsLink?: string;
  autoOpenChangeOrCancelRegistrationPanel?: boolean;
  loadingCatalogRelations?: boolean;
  fetchingRegistrations?: boolean;
  context?: AnalyticsContext;
  creditTrackerCode?: string;
}

const memoizedRegisteredVariants = createSelector(
  [(state: RootState) => state.pdp.catalogRelations?.variants],
  variants => variants?.filter(p => p.registered && !p.isExpired) || []
);

const memoizedNonRegisteredVariants = createSelector(
  [(state: RootState) => state.pdp.catalogRelations?.variants],
  variants => variants?.filter(p => !p.registered && !p.isExpired) || []
);

const UpcomingLive: React.FC<UpcomingLiveProps> = ({
  labels,
  onDemandProgram,
  isMobile = false,
  title,
  onClosePanel,
  seeDetailsLink,
  loadingCatalogRelations,
  fetchingRegistrations,
  autoOpenChangeOrCancelRegistrationPanel = false,
  context,
  creditTrackerCode
}) => {
  const materialModalId = useMemo(() => `material-modal-${uuid4()}`, []);

  const dispatch = useDispatch<AppDispatch>();
  const [stickyRegisterButtonRef, isInViewRegister, positionRegister] =
    useInViewport<HTMLButtonElement>();
  const [stickyRegistrationButtonRef, isInViewMaterials, positionMaterials] =
    useInViewport<HTMLButtonElement>();
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>();
  const [showCheck, setShowCheck] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLoadingPanel, setShowLoadingPanel] = useState(false);
  const [shimmerRegUpdatePanel, setShimmerRegUpdatePanel] = useState(false);
  const selectedVariant = useSelector((state: RootState) => state.pdp.selectedVariant);
  const hardSelected = useSelector((state: RootState) => state.pdp.hardSelected);
  const registeredVariants = useSelector(memoizedRegisteredVariants);
  const nonRegisteredVariants = useSelector(memoizedNonRegisteredVariants);
  const registrationModalOpen = useSelector((state: RootState) => state.pdp.registrationModalOpen);
  const [registrationPhoneModalOpen, setRegistrationPhoneModelOpen] = useState(false);
  const {
    addToCart,
    isSuccess: isCartSuccess,
    reset: cartReset,
    isLoading: isCartLoading,
    isError: isCartError
  } = useAddToCart(context, creditTrackerCode);
  const {
    placeRegistration,
    isSuccess: isRegSuccess,
    isLoading: isRegLoading,
    isError: isRegError,
    reset: regReset
  } = useRegistrations(context, creditTrackerCode);
  const {
    cancelRegistration,
    isSuccess: isCancelSuccess,
    reset: cancelRegReset,
    isLoading: isCancelLoading,
    isError: isCancelError
  } = useCancelRegistrations(context);
  const {
    launch,
    isLoading: isLaunchLoading,
    isSuccess: isLaunchSuccess,
    isError: isLaunchError
  } = useLaunch(context, creditTrackerCode);
  const [getRegCancellable] = useLazyGetRegistrationCancellableQuery();

  const { getAnalyticItem } = useFetchAnalyticsItems();

  const trackEvent = useCallback(
    async (code: string, context?: AnalyticsContext) => {
      const data = await getAnalyticItem(code);
      if (data) {
        const transactionId = createTransactionId('');
        trackAddToCart(data, context, transactionId);
      }
    },
    [getAnalyticItem]
  );

  const variantsCount = useSelector((state: RootState) => state.pdp.catalogRelations?.variants);
  const nonExpiredVariants = useMemo(
    () => variantsCount?.filter(v => !v.isExpired),
    [variantsCount]
  );

  const withinPanel = !!onClosePanel;

  const isRegistered = useMemo(() => registeredVariants?.length > 0, [registeredVariants]);
  const isHardSelectedRegistered = useMemo(
    () => !!hardSelected && selectedVariant?.registered,
    [hardSelected, selectedVariant?.registered]
  );

  const isRegistrationSticky = useMemo(
    () => !isInViewRegister && positionRegister === 'above',
    [isInViewRegister, positionRegister]
  );

  const isMaterialSticky = useMemo(
    () => !isInViewMaterials && positionMaterials === 'above',
    [isInViewMaterials, positionMaterials]
  );

  useEffect(() => {
    if ((isRegSuccess || isCartSuccess) && withinPanel) {
      if (isRegSuccess) resetSearchAPIs(dispatch);
      onClosePanel?.();
      return;
    }
    if (isRegSuccess || isCancelSuccess || isCartSuccess || isLaunchSuccess) {
      if (isCancelSuccess && withinPanel) resetSearchAPIs(dispatch);
      setShowLoading(false);
      setShowError(false);
      setShowCheck(true);
      setTimeout(() => {
        setShowCheck(false);
        if (isRegSuccess) regReset();
        if (isCancelSuccess) cancelRegReset();
        if (isCartSuccess) cartReset();
      }, 1000);
    }
    if (isRegLoading || isCancelLoading || isCartLoading || isLaunchLoading) {
      setShowLoading(true);
    }

    if (isRegError || isCartError || isCancelError || isLaunchError) {
      setShowError(true);
      setShowLoading(false);
    }
  }, [
    cancelRegReset,
    cartReset,
    dispatch,
    isCancelError,
    isCancelLoading,
    isCancelSuccess,
    isCartError,
    isCartLoading,
    isCartSuccess,
    isRegError,
    isRegLoading,
    isRegSuccess,
    onClosePanel,
    regReset,
    showCheck,
    showLoading,
    isLaunchError,
    isLaunchLoading,
    isLaunchSuccess,
    withinPanel
  ]);

  useEffect(() => {
    // If the user is registered, select the registrations tab, otherwise select the selector tab
    if (isRegistered || isRegSuccess) {
      setSelectedTab('registrations');
      if (shouldScroll) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setShouldScroll(false);
      }
    } else {
      if (!isRegLoading) setSelectedTab('selector');
    }
  }, [isRegLoading, isRegSuccess, isRegistered, shouldScroll]);

  useEffect(() => {
    // If the user is registered and the selected variant is not in the registered list, select the first one
    if (selectedTab === 'registrations') {
      if (
        registeredVariants.length > 0 &&
        !registeredVariants.find((variant: Variant) => variant.code === selectedVariant?.code)
      ) {
        dispatch(selectVariant(registeredVariants[0]));
      }
    }
  }, [dispatch, registeredVariants, selectedTab, selectedVariant?.code]);

  const launchUrl = useMemo(
    () =>
      selectedVariant?.registered || selectedVariant?.purchased
        ? selectedVariant?.launchUrl
        : undefined,
    [selectedVariant?.launchUrl, selectedVariant?.purchased, selectedVariant?.registered]
  );
  const handleSubmit = useCallback(() => {
    if (selectedVariant?.purchased || selectedVariant?.registered || !selectedVariant?.retail)
      launch(selectedVariant?.code, !selectedVariant?.registered, launchUrl);
    else {
      addToCart(selectedVariant?.code, selectedVariant?.displayName ?? '');
    }
  }, [launch, addToCart, launchUrl, selectedVariant]);

  const handleRegistration = useCallback(
    async (variant: Variant) => {
      if (variant.retail) {
        addToCart(variant?.code, variant?.displayName ?? '');
      } else {
        dispatch(setMaterialsLoading(true));
        if (variant?.isLive && variant?.type === 'online')
          launch(variant?.code, !selectedVariant?.registered, launchUrl);
        else {
          await placeRegistration(variant);
        }
        setShouldScroll(!withinPanel);
      }
      setShowModal(false);
    },
    [
      addToCart,
      dispatch,
      launch,
      selectedVariant?.registered,
      launchUrl,
      withinPanel,
      placeRegistration
    ]
  );

  const setOpenEditPanel = useCallback(
    (isOpen: boolean) => {
      dispatch(setOpenRegistrationModal(isOpen));
      if (isOpen!) {
        setRegistrationPhoneModelOpen(false);
      }
      if (!isOpen && withinPanel) {
        onClosePanel();
      }
    },
    [dispatch, onClosePanel, withinPanel]
  );

  const handleRegCheck = useCallback(async () => {
    if (selectedVariant?.sk) {
      setOpenEditPanel(true);
      setShimmerRegUpdatePanel(true);
      const response = await getRegCancellable(selectedVariant?.sk);
      setShimmerRegUpdatePanel(false);
      if (response?.data?.isCancelable) setRegistrationPhoneModelOpen(false);
      else setRegistrationPhoneModelOpen(true);
    }
  }, [getRegCancellable, selectedVariant?.sk, setOpenEditPanel]);

  const handleChangeRegistration = useCallback(
    async (variant: Variant) => {
      if (selectedVariant?.sk) {
        setShowLoadingPanel(true);

        await cancelRegistration(selectedVariant?.sk, true, variant?.code).catch(() =>
          setShowLoadingPanel(false)
        );
        setOpenEditPanel(false);
        await placeRegistration(variant, true).finally(() => {
          setShowLoadingPanel(false);
          if (context && variant?.code) {
            trackEvent(variant?.code, context);
          }
        });
        setOpenEditPanel(false);
      }
    },
    [
      cancelRegistration,
      placeRegistration,
      selectedVariant?.sk,
      setOpenEditPanel,
      trackEvent,
      context
    ]
  );

  const handleCancelRegistration = useCallback(async () => {
    setShowLoadingPanel(true);

    await cancelRegistration(selectedVariant?.sk, false, selectedVariant?.code).finally(() =>
      setShowLoadingPanel(false)
    );
    setOpenEditPanel(false);
  }, [cancelRegistration, selectedVariant?.sk, setOpenEditPanel, selectedVariant?.code]);

  const [openProgramMaterials, setOpenProgramMaterials] = useState(false);
  const getProgramMaterialsProps = useCallback(() => {
    return {
      programCode: selectedVariant?.code,
      programTitle: selectedVariant?.displayName ?? '',
      materials: selectedVariant?.materials ?? [],
      open: openProgramMaterials,
      setOpen: setOpenProgramMaterials,
      labels: MaterialsLabels
    };
  }, [
    openProgramMaterials,
    selectedVariant?.displayName,
    selectedVariant?.materials,
    selectedVariant?.code
  ]);
  const onClickMaterialsBtn = () => setOpenProgramMaterials(true);

  useEffect(() => {
    if (withinPanel && autoOpenChangeOrCancelRegistrationPanel) {
      dispatch(setOpenRegistrationModal(true));
    }
  }, [dispatch, autoOpenChangeOrCancelRegistrationPanel, withinPanel]);

  if (!selectedVariant) return null;

  if (loadingCatalogRelations || fetchingRegistrations) {
    return <Loading isMobile={isMobile} />;
  }

  const addToCalendarFunction = () => {
    setShowCalendarModal(!showCalendarModal);
  };

  if (withinPanel && autoOpenChangeOrCancelRegistrationPanel) {
    return (
      <RegistrationUpdatePanel
        labels={labels}
        isOpen={registrationModalOpen}
        isPhoneMode={registrationPhoneModalOpen}
        isShimmering={shimmerRegUpdatePanel}
        onOpenChange={setOpenEditPanel}
        onChange={handleChangeRegistration}
        onCancel={handleCancelRegistration}
        isLoading={showLoadingPanel}
        withinPanel={withinPanel}
      />
    );
  }

  return (
    <>
      <ProgramMaterialsModal
        customModalId={materialModalId}
        {...getProgramMaterialsProps()}
        context={context}
      />
      <BaseBox
        withinPanel={withinPanel}
        sticky={!isMobile}
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
      >
        {withinPanel && (
          <BaseBox.PanelHeader
            productCode={selectedVariant?.code}
            title={title}
            seeDetailsLink={seeDetailsLink}
            context={context}
          />
        )}

        <Tabs.Root
          value={selectedTab}
          defaultValue="selector"
          onValueChange={x => {
            setSelectedTab(x);
            dispatch(setSoftSelectedVariant(selectedVariant));
          }}
          className="flex grow flex-col overflow-hidden"
        >
          {isRegistered && (
            <Tabs.List
              className={classnames('container z-1 -mb-1 flex bg-white lg:px-10 ', {
                'pt-10 lg:pt-16': !withinPanel,
                'pt-12': withinPanel
              })}
            >
              <Tabs.Trigger
                value="registrations"
                className="text-2 shrink-0 grow border-b border-gray pb-[13px] pr-12 text-left font-medium text-gray transition-colors hover:text-black data-[state=active]:pointer-events-none data-[state=active]:border-black data-[state=active]:text-black"
              >
                {labels.registrations}
              </Tabs.Trigger>
              {!!nonRegisteredVariants.length && (
                <Tabs.Trigger
                  value="selector"
                  className="text-2 shrink-0 grow border-b border-gray pb-[13px] pr-12 text-left font-medium text-gray transition-colors hover:text-black data-[state=active]:pointer-events-none data-[state=active]:border-black data-[state=active]:text-black"
                >
                  {labels.variantsSelector}
                </Tabs.Trigger>
              )}
            </Tabs.List>
          )}

          <Tabs.Content
            value="registrations"
            className="hidden min-h-0 grow flex-col data-[state=active]:flex"
          >
            {registeredVariants.length === 1 && selectedVariant?.isLive ? (
              <LiveVariant
                ref={stickyRegistrationButtonRef}
                labels={labels}
                variant={selectedVariant}
                onSubmit={handleSubmit}
                onSubmitReg={handleRegistration}
                openMaterials={onClickMaterialsBtn}
              />
            ) : (
              <Registrations
                ref={stickyRegistrationButtonRef}
                labels={labels}
                registrations={registeredVariants}
                onModify={handleRegCheck}
                programCard={onDemandProgram}
                openMaterials={onClickMaterialsBtn}
                launch={handleSubmit}
                withinPanel={withinPanel}
                withinTabs
                context={context}
              />
            )}
          </Tabs.Content>

          <Tabs.Content
            value="selector"
            className="hidden min-h-0 grow flex-col data-[state=active]:flex"
          >
            {!isRegistered && nonExpiredVariants?.length === 1 && selectedVariant?.isLive ? (
              <LiveVariant
                ref={stickyRegistrationButtonRef}
                labels={labels}
                variant={selectedVariant}
                onSubmit={handleSubmit}
                isSuccess={showCheck}
                isLoading={showLoading}
                isError={showError}
                withinPanel={withinPanel}
              />
            ) : (
              <VariantsSelector
                ref={stickyRegisterButtonRef}
                labels={labels}
                softSelection={selectedVariant}
                isHardSelection={isHardSelectedRegistered}
                programCard={onDemandProgram}
                onVariantSelect={variant => dispatch(selectVariant(variant))}
                onSubmit={handleRegistration}
                isSuccess={showCheck}
                isLoading={showLoading}
                isError={showError}
                withinPanel={withinPanel}
              />
            )}
          </Tabs.Content>
        </Tabs.Root>

        {isMobile && (
          <>
            <BaseBox.Modal className="flex h-screen flex-col">
              {nonExpiredVariants?.length === 1 && selectedVariant?.isLive ? (
                <LiveVariant
                  labels={labels}
                  variant={selectedVariant}
                  onSubmit={handleSubmit}
                  isModal
                  openMaterials={onClickMaterialsBtn}
                  isSuccess={showCheck}
                  isLoading={showLoading}
                  isError={showError}
                  withinPanel={withinPanel}
                />
              ) : (
                <VariantsSelector
                  isModal
                  labels={labels}
                  softSelection={selectedVariant}
                  programCard={onDemandProgram}
                  onSubmit={handleRegistration}
                  isSuccess={showCheck}
                  isLoading={showLoading}
                  isError={showError}
                  withinPanel={withinPanel}
                />
              )}
            </BaseBox.Modal>

            <BaseBox.StickyButton
              sticky={
                isRegistrationSticky &&
                (!isRegistered || !isHardSelectedRegistered || !selectedVariant.hasMaterials)
              }
            >
              <ActionButton
                labels={labels}
                selectedVariant={selectedVariant}
                onClick={() => setShowModal(true)}
                isSuccess={showCheck}
                isLoading={showLoading}
                isError={showError}
              />
            </BaseBox.StickyButton>

            {isRegistered && (
              <BaseBox.StickyButton
                sticky={isMaterialSticky || (isRegistrationSticky && !hardSelected)}
              >
                {selectedVariant.hasMaterials ? (
                  <Button
                    label={labels.materials}
                    color="red"
                    size="large"
                    className="w-full"
                    onClick={() => onClickMaterialsBtn()}
                    aria-controls={materialModalId}
                  />
                ) : (
                  <>
                    <Button
                      label={labels.addToCalendar}
                      color="red"
                      size="large"
                      className="w-full"
                      onClick={() => addToCalendarFunction()}
                    />
                    <AddToCalendarModal
                      modalHeader={labels.addToCalendarModalHeader}
                      productCode={selectedVariant?.code}
                      open={showCalendarModal}
                      setOpen={setShowCalendarModal}
                      context={context}
                    />
                  </>
                )}
              </BaseBox.StickyButton>
            )}
          </>
        )}
      </BaseBox>

      <RegistrationUpdatePanel
        labels={labels}
        isOpen={registrationModalOpen}
        isPhoneMode={registrationPhoneModalOpen}
        isShimmering={shimmerRegUpdatePanel}
        onOpenChange={setOpenEditPanel}
        onChange={handleChangeRegistration}
        onCancel={handleCancelRegistration}
        isLoading={showLoadingPanel}
        withinPanel={withinPanel}
      />
    </>
  );
};

export default UpcomingLive;
