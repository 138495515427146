import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';

export type DeleteProgramModalProps = ModalProps & {
  onConfirm?: () => void;
};

const DeleteProgramModal = ({ open, setOpen, onConfirm }: DeleteProgramModalProps): JSX.Element => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <ModalContent
        eyebrow={{ text: 'Remove from My Library', icon: 'delete' }}
        title="Do you want to remove this program from your Library?"
        saveButtonLabel="Yes, remove from My Library"
        dismissButtonLabel="Cancel"
        onSaveButtonPress={onConfirm}
      />
    </Modal>
  );
};

export default DeleteProgramModal;
