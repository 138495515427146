import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UserTrackerData } from '@/@types/client-api';
import {
  findSelectedClePeriod,
  getActiveCompliancePeriodValue
} from '@/components/ui/CreditTracker/util/TrackerHelpers';

export const selectUserTrackerData = (state: RootState) => state.creditTracker.trackers;
export const selectActiveUserTrackerData = (state: RootState) => state.creditTracker.selectedRegion;
export const selectAnnouncements = (state: RootState) => state.creditTracker.announcements;
export const selectProviders = (state: RootState) => state.creditTracker.providers;
export const selectTrackerIsLoading = (state: RootState) => state.creditTracker.isLoading;
export const selectAllGlobalBanners = (state: RootState) => state.creditTracker.globalBanners;

const selectUserTrackerDataRequest = (state: RootState) => state.creditTracker.request;

export const selectGlobalBanners = (regionKey?: string | null) => {
  return createSelector(selectAllGlobalBanners, x =>
    regionKey ? x?.[regionKey]?.map(a => a) : []
  );
};

export const selectCreditTrackerDataRequestResponse = createSelector(
  selectUserTrackerDataRequest,
  selectUserTrackerData,
  (request, trackers) => ({ request, trackers })
);

const selectTracker = (data: Record<number, UserTrackerData[]>, key?: number | null) => {
  return data[key ?? parseInt(Object.keys(data)?.[0])];
};

export const selectUserRegions = (id?: number) =>
  createSelector([selectUserTrackerData], x => {
    return selectTracker(x, id)?.map((a: UserTrackerData) => {
      return {
        creditRegionShortDescription: a.creditRegionShortDescription,
        creditRegionName: a.creditRegionName,
        creditRegionLongDescription: a.creditRegionLongDescription,
        baseSortOrder: a.baseSortOrder,
        jurisdiction: a.jurisdiction,
        userInfoIncomplete: a.jurisdiction?.clePeriod?.isUserInfoIncomplete
      };
    });
  });

export const selectTrackerById = (id?: number | null) =>
  createSelector([selectUserTrackerData], x => selectTracker(x, id));

export const selectAnnouncementsByRegion = (region: string) =>
  createSelector([selectAnnouncements], x => x?.[region] ?? []);

export const selectAnnouncementsByManyRegions = (regions: string[]) =>
  createSelector([selectAnnouncements], x => {
    const res: typeof x = {};

    for (const key in x) {
      if (regions.some(a => a.toLowerCase() == key.toLowerCase())) res[key] = x[key];
    }
    return res;
  });

export const selectActiveClePeriod = () => {
  return createSelector([selectActiveUserTrackerData], x =>
    findSelectedClePeriod(x?.jurisdiction?.clePeriod)
  );
};

export function selectPreviousClePeriod() {
  return createSelector([selectActiveUserTrackerData], trackerData => {
    const periodValue = getActiveCompliancePeriodValue(trackerData ?? null);
    switch (periodValue) {
      case 'current':
        return trackerData?.jurisdiction?.clePeriod?.previousPeriod;
      case 'next':
        return trackerData?.jurisdiction?.clePeriod;
      default:
        return null;
    }
  });
}
