import TabsComponent, { TabTrigger } from '@/components/ui/Tab';
import type { SubnavBlock as SubnavBlockProps } from '@/@types/content';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import Button from '@/components/ui/Buttons/Button';

const SubnavBlock: React.FC<SubnavBlockProps> = ({ stickyTabs = true, tabItems = [] }) => {
  const tabsTriggers = (tabItems as { link: { text: string; href: string } }[]).map(({ link }) => ({
    label: link?.text || '',
    id: new URL(link?.href || '').pathname || ''
  })) as TabTrigger[];

  const [selectedValue, setSelectedValue] = useState<TabTrigger>(() => {
    const currentPath = window.location.pathname;
    const initialTab = tabsTriggers.find(tab => tab.id === currentPath) || { label: '', id: '' };
    return initialTab;
  });

  useEffect(() => {
    if (selectedValue.id && window.location.pathname !== selectedValue.id) {
      window.location.href = selectedValue.id;
    }
  }, [selectedValue]);

  const tabsWrapperClasses = classnames(
    'relative z-sticky-subnav border-b border-silver bg-white py-10',
    'sm:py-0',
    {
      'sticky top-0': stickyTabs
    }
  );

  return (
    <div className={tabsWrapperClasses}  data-component={"SubnavBlock"}>
      <div className="container">
        <TabsComponent
          children={null}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          variant={'page'}
          tabsTriggers={tabsTriggers}
          registerButton={
            <Button propertyName="OverlayButtonText" color="black" size="large" label="Register" />
          }
        />
      </div>
    </div>
  );
};

export default SubnavBlock;
