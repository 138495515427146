import { Fragment, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import Link from '@/components/ui/Link';
import CardBuilder from '@/components/ui/Cards/CardBuilder';
import Divider from '@/components/ui/Divider';
import Shimmer from '@/components/ui/Shimmer';
import classnames from 'classnames';

interface ProductListProps {
  cards: Record<string, unknown>[];
  isLoading?: boolean;
  seeMoreLabel?: string;
  listLimit?: number;
}

const ProductList = ({ cards, isLoading, seeMoreLabel, listLimit = 4 }: ProductListProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const wrapperClasses = classnames('flex flex-col gap-8');

  return (
    <div className={wrapperClasses}>
      {isLoading ? (
        <Fragment>
          {Array.from({ length: listLimit }).map((_, index) => (
            <div key={index} className={wrapperClasses}>
              <div className={classnames('flex w-full items-center gap-5 pr-6', 'lg:pr-8')}>
                <div className={classnames('flex w-full flex-col gap-1')}>
                  <Shimmer className={classnames('h-3 w-11', 'sm:h-4')} />
                  <Shimmer className={classnames('h-4 w-3/4', 'sm:h-6')} />
                  <Shimmer className={classnames('h-4 w-full', 'sm:hidden')} />
                  <Shimmer className={classnames('h-4 w-2/4', 'sm:hidden')} />
                </div>

                <Shimmer className="size-6 flex-[0_0_24px]" />
              </div>
              {index < listLimit - 1 && <Divider className="bg-silver" color="light" />}
            </div>
          ))}
        </Fragment>
      ) : (
        <Fragment>
          {cards.slice(0, listLimit).map((card, index) => (
            <Fragment key={index}>
              <CardBuilder cardProps={card} showAsList={true} />
              {(index < listLimit - 1 || isExpanded) && (
                <Divider className="bg-silver" color="light" />
              )}
            </Fragment>
          ))}

          {cards.length > listLimit && (
            <Collapsible.Root open={isExpanded} onOpenChange={setIsExpanded}>
              <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown">
                <div className={wrapperClasses}>
                  {cards.slice(listLimit).map((card, index) => (
                    <Fragment key={index}>
                      <CardBuilder cardProps={card} showAsList={true} />
                      {index < cards.length - listLimit - 1 && (
                        <Divider className="bg-silver" color="light" />
                      )}
                    </Fragment>
                  ))}
                </div>
              </Collapsible.Content>

              {!isExpanded && (
                <Collapsible.Trigger asChild>
                  <Link
                    as="button"
                    variant="bold-link-with-icon"
                    text={seeMoreLabel}
                    icon="chevron-down"
                  />
                </Collapsible.Trigger>
              )}
            </Collapsible.Root>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ProductList;
