import { NavLink } from 'react-router-dom';
import Icon from '@/components/ui/Icon';
import BaseBox from '../../components/BaseBox';
import { getFormatedDateRangeString } from '@/lib/helpers/date';
import { LiveSegment } from '@/hooks/PDP/useProgramCatalogRelations';
import Shimmer from '@/components/ui/Shimmer';
import { determineLiveProgramStatus } from '../../../helpers';
import Tag from '@/components/ui/Tag';
import { useTrackViewCrossLink } from '@/analytics/pdp';
import { AnalyticsContext } from '@/analytics/constants';

export interface UpcomingLiveCardProps {
  url?: string;
  eventStartDate?: string;
  eventEndDate?: string;
  segments?: LiveSegment[];
  isLoading?: boolean;
  imageUrl?: string;
  labels?: {
    upcomingLiveCrosslink: string;
    liveNowCrosslink: string;
  };
  location?: string;
  city?: string;
  programTitle?: string;
  context?: AnalyticsContext;
}

const UpcomingLiveCard: React.FC<UpcomingLiveCardProps> = ({
  labels,
  url,
  eventStartDate,
  eventEndDate,
  segments,
  imageUrl,
  isLoading,
  location,
  city,
  programTitle,
  context
}) => {
  const trackViewCrossLink = useTrackViewCrossLink();

  if (isLoading) {
    return (
      <BaseBox.Card>
        <Shimmer className="size-full" />
      </BaseBox.Card>
    );
  }

  const handleClick = () => {
    trackViewCrossLink(context);
  };

  const hasDateProps = !!eventStartDate?.length && !!eventEndDate?.length && segments?.length;

  if (!labels || !url || !hasDateProps) return <></>;

  const liveStatus = determineLiveProgramStatus(eventStartDate, eventEndDate, segments);

  if (liveStatus === 'Live ended') return <></>;

  const getDaysUntilLive = () => {
    const now = new Date().getTime();
    const start = new Date(eventStartDate).getTime();
    if (now > start) return 1; // if liveStatus is not currently live, suggests we are between segments/days, which has been spec'd as '1 day away'

    return Math.ceil((start - now) / (1000 * 60 * 60 * 24));
  };

  const daysUntilLive = getDaysUntilLive();

  const isLiveWithinWeek = daysUntilLive <= 7;

  const replaceLabelTokens = (baseLabel: string) => {
    return baseLabel
      .replace('{LOCATION}', location ?? '')
      .replace('{CITY}', city ?? '')
      .replace('{PROGRAMTITLE}', programTitle ?? '')
      .replace('{YEAR}', new Date(eventStartDate).getFullYear().toString());
  };

  const upcomingLiveLabel = replaceLabelTokens(labels.upcomingLiveCrosslink);
  const liveNowLabel = replaceLabelTokens(labels.liveNowCrosslink);
  const timeUntilLiveNode = isLiveWithinWeek ? (
    <Tag variant="dark" size="small">
      <span className="label px-1">{`${daysUntilLive} day${daysUntilLive > 1 ? 's' : ''} away`}</span>
    </Tag>
  ) : (
    <p className="label text-gray-dark">{getFormatedDateRangeString(eventStartDate)}</p>
  );

  return (
    <BaseBox.Card>
      <NavLink
        to={url}
        className="flex h-[88px] w-full items-center justify-between gap-3 bg-gray-lightest p-8 hover:bg-gray-light"
        onClick={handleClick}
      >
        {liveStatus === 'Live' ? (
          <div className="flex items-center gap-4">
            <img className="size-12 rounded" src={`${imageUrl}?width=48&height=48`} />
            <div className="flex flex-col gap-1">
              <div className="ml-[-4px] inline-flex gap-1 lg:label">
                <Icon name="live-circle" size="small" />
                <p className="fixed-size-label">Live</p>
              </div>
              <p className="text-2-bold w-[158px] sm:w-[427px] lg:w-[189px]">{liveNowLabel}</p>
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-3">
            <Icon name="calendar" size="medium" />
            <p className="text-2 font-bold">{upcomingLiveLabel}</p>
          </div>
        )}

        <span className="flex shrink-0 items-center gap-[10px]">
          {liveStatus === 'Upcoming Live' && timeUntilLiveNode}
          <Icon name="chevron-right" size="large" />
        </span>
      </NavLink>
    </BaseBox.Card>
  );
};

export default UpcomingLiveCard;
