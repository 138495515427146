import { PageLinks } from '@/redux/slices/pageSlice';
import Button from '../../Buttons/Button';
import BaseBox from './components/BaseBox';
import { UpcomingLiveProps } from './UpcomingLive';
import { OnDemandProps } from './OnDemand';
import UpcomingLiveCard from './OnDemand/components/UpcomingLiveCard';
import OnDemandCard from './UpcomingLive/components/OnDemandCard';

export interface ProgramExpiredProps {
  programId: string;
  labels: {
    headingProgram: string;
    bodyProgram: string;
    exploreButtonLabel: string;
    exploreButtonUrl: string;
    myLibraryLabel: string;
    programId: string;
  };
  isMobile?: boolean;
  pageLinks?: PageLinks;
  programCard?: {
    liveProps?: OnDemandProps['upcomingLiveProgram'];
    onDemandProps?: UpcomingLiveProps['onDemandProgram'];
  };
}

export const ProgramExpired = ({
  programId,
  labels,
  isMobile = false,
  pageLinks,
  programCard
}: ProgramExpiredProps) => {
  return (
    <BaseBox sticky={!isMobile}>
      <BaseBox.Content>
        <div className="flex w-full flex-col gap-12 pt-6">
          <div className="flex flex-col gap-6">
            <h2 className="heading-6-medium">{labels.headingProgram}</h2>
            <p className="text-1 text-charcoal">{labels.bodyProgram}</p>
          </div>
          <div className="flex w-full flex-col items-center gap-4 ">
            <Button
              color="black"
              size="large"
              label={labels.exploreButtonLabel}
              href={labels.exploreButtonUrl}
              iconRight="link-out"
              className="w-full !justify-center"
            />
            <Button
              color="outline-black"
              size="large"
              label={labels.myLibraryLabel}
              href={pageLinks?.Library}
              iconRight="link-out"
              className="w-full !justify-center"
            />
            <span className="label py-4 text-gray-dark">{`${labels.programId}: ${programId}`}</span>
          </div>
        </div>
      </BaseBox.Content>
      <BaseBox.Footer></BaseBox.Footer>
      {programCard ? (
        <>
          {programCard.liveProps ? (
            <UpcomingLiveCard {...programCard.liveProps} />
          ) : (
            <OnDemandCard {...programCard.onDemandProps} />
          )}
        </>
      ) : (
        <></>
      )}
    </BaseBox>
  );
};

export default ProgramExpired;
