import { LibraryFilters } from '@/@types/client-api';
import { FilterTypes } from '@/components/ui/SearchResults/constants';

export const LIBRARY_FILTERS = {
  GROUP_SEARCHABLE_TYPE: 'groupSearchableType',
  CREDIT_STATUS: 'creditStatus',
  LIBRARY_ITEM_STATUS: 'libraryItemStatus',
  FORMAT: 'format',
  BOOK_FORMAT: 'bookFormat',
  DURATION: 'duration'
} as const;

export type LibraryFilterKey = keyof LibraryFilters;
export type LibraryFilterValue = LibraryFilters[LibraryFilterKey];

export const ALL_TAB = 'All';
export const TAB_ORDER = [ALL_TAB, 'Programs', 'Publications', 'Podcasts'];

export const DEFAULT_SORT = 'LastAdded';

export const LIBRARY_SORT_OPTIONS: { value: string; label: string }[] = [
  {
    value: 'LastAdded',
    label: 'Last Added'
  },
  {
    value: 'ExpiringSoonest',
    label: 'Expiring Soonest'
  },
  {
    value: 'LastWatched',
    label: 'Last Watched'
  },
  {
    value: 'NewestFirst',
    label: 'Newest First'
  },
  {
    value: 'OldestFirst',
    label: 'Oldest First'
  }
];

export const LIBRARY_FILTER_GROUPS = {
  [LIBRARY_FILTERS.CREDIT_STATUS]: {
    title: 'Credit Status',
    filters: [
      { value: 'creditsForRequest', label: 'Credits for request' },
      { value: 'creditsRequested', label: 'Credits requested' }
    ]
  },
  [LIBRARY_FILTERS.LIBRARY_ITEM_STATUS]: {
    title: 'Program Status',
    filters: [
      { value: 'continueWatching', label: 'Continue Watching' },
      { value: 'upcoming', label: 'Upcoming' },
      { value: 'isSaved', label: 'Saved' }
    ]
  },
  [LIBRARY_FILTERS.FORMAT]: {
    title: 'Formats',
    filters: [
      { value: 'liveInPerson', label: 'Live In-Person' },
      { value: 'liveWebcast', label: 'Live Webcast' },
      { value: 'onDemand', label: 'On-Demand' },
      { value: 'podcastAndRelatedMedia', label: 'Podcast & Related Media' },
      // Intentionally the same label as above to combine filters
      { value: 'videoPodcast', label: 'Podcast & Related Media' }
    ]
  },
  [LIBRARY_FILTERS.BOOK_FORMAT]: {
    title: 'Publication Formats',
    filters: [
      { value: 'Course Handbooks', label: 'Course Handbooks' },
      { value: 'Answer Books', label: 'Answer Books' },
      { value: 'Treatises', label: 'Treatises' },
      { value: 'Skills Books', label: 'Skills Books' },
      { value: 'Case Summaries', label: 'Case Summaries' },
      { value: 'Practice Guides', label: 'Practice Guides' }
    ]
  },
  [LIBRARY_FILTERS.DURATION]: {
    title: 'Duration',
    filters: [
      { value: '60+min', label: '60 Min' },
      { value: 'half+day', label: 'Half Day' },
      { value: 'full+day', label: 'Full Day' },
      { value: 'multi+day', label: 'Multi Day' }
    ]
  }
} as const;

type SuggestionForGroup<T extends (typeof LIBRARY_FILTERS)[keyof typeof LIBRARY_FILTERS]> =
  T extends keyof typeof LIBRARY_FILTER_GROUPS
    ? {
        value: (typeof LIBRARY_FILTER_GROUPS)[T]['filters'][number]['value'];
        group: T;
      }
    : never;

type SuggestedFacet = {
  [key in keyof typeof LIBRARY_FILTER_GROUPS]: SuggestionForGroup<key>;
}[keyof typeof LIBRARY_FILTER_GROUPS];

export const SUGGESTED_FILTER_FACETS: Record<string, SuggestedFacet[]> = {
  All: [
    { value: 'continueWatching', group: LIBRARY_FILTERS.LIBRARY_ITEM_STATUS },
    { value: 'isSaved', group: LIBRARY_FILTERS.LIBRARY_ITEM_STATUS },
    { value: 'liveInPerson', group: LIBRARY_FILTERS.FORMAT },
    { value: 'creditsForRequest', group: LIBRARY_FILTERS.CREDIT_STATUS }
  ],
  Programs: [
    { value: 'continueWatching', group: LIBRARY_FILTERS.LIBRARY_ITEM_STATUS },
    { value: 'isSaved', group: LIBRARY_FILTERS.LIBRARY_ITEM_STATUS },
    { value: 'liveInPerson', group: LIBRARY_FILTERS.FORMAT },
    { value: 'creditsForRequest', group: LIBRARY_FILTERS.CREDIT_STATUS }
  ],
  Publications: [
    { value: 'isSaved', group: LIBRARY_FILTERS.LIBRARY_ITEM_STATUS },
    { value: 'Course Handbooks', group: LIBRARY_FILTERS.BOOK_FORMAT },
    { value: 'Treatises', group: LIBRARY_FILTERS.BOOK_FORMAT },
    { value: 'Practice Guides', group: LIBRARY_FILTERS.BOOK_FORMAT },
    { value: 'Case Summaries', group: LIBRARY_FILTERS.BOOK_FORMAT },
    { value: 'Skills Books', group: LIBRARY_FILTERS.BOOK_FORMAT },
    { value: 'Answer Books', group: LIBRARY_FILTERS.BOOK_FORMAT }
  ],
  Podcasts: []
};

export const LibraryFilterTypeMap: Record<string, FilterTypes> = {
  groupSearchableType: FilterTypes.TABS,
  creditStatus: FilterTypes.SELECT,
  libraryItemStatus: FilterTypes.SELECT,
  format: FilterTypes.SELECT,
  bookFormat: FilterTypes.SELECT,
  duration: FilterTypes.SELECT
};

export const FILTER_LIST = Object.values(LIBRARY_FILTERS);

export const DEFAULT_FILTER_VALUES: Record<string, string> = {
  groupSearchableType: 'All'
};
