import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation
} from '@/redux/api/client/userProfile';
import { UserProfile } from '@/@types/client-api';
import AboutPanel from '@/components/ui/ProfilePanels/AboutPanel';
import useToast from '@/hooks/useToast';
import ProfileSectionWrapper from './ProfileSectionWrapper';
import { PersonalInfoAboutBlock as PersonalInfoAboutBlockProps } from '@/@types/content';
import PersonalInfo from '@/components/ui/PersonalInfo';
import useNudge from '@/hooks/shared/useNudge';
import { IComponentProps } from '@/lib/ComponentFactory';
import PersonalInfoButton from '@/components/ui/PersonalInfoButton';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import { trackEditProfileEvent } from '@/analytics/profile';
import { ProfileSection, NudgeAnalyticsContext } from '@/analytics/constants';
import { ProfileNudgeSectionNames } from '@/hooks/shared/useNudge/utils';

const AboutSection = ({
  messages,
  index,
  timeUntilStale
}: PersonalInfoAboutBlockProps & IComponentProps): JSX.Element => {
  const { data, isLoading, isError, refetch } = useGetUserProfileQuery();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { showSuccessMessage, showFailMessage } = useToast();

  const subSections = useMemo(() => {
    if (!data || isError) {
      return [
        { title: 'Name', description: '-' },
        { title: 'Suffix', description: '-' },
        { title: 'Salutation', description: '-' },
        { title: 'Title', description: '-' },
        { title: 'Role(s)', description: '-' }
      ];
    }
    return [
      {
        title: 'Name',
        description: data.middleInitial
          ? `${data.firstName} ${data.middleInitial} ${data.lastName}`
          : `${data.firstName} ${data.lastName}`,
        fields: ['firstName', 'middleInitial', 'lastName']
      },
      { title: 'Suffix', description: data.suffix || '-', fields: ['suffix'] },
      { title: 'Salutation', description: data.salutation || '-', fields: ['salutation'] },
      { title: 'Title', description: data.title || '-', fields: ['title'] },
      {
        title: 'Role(s)',
        description: data.roles?.map(role => role.longDescription).join(', ') || '-',
        fields: ['roles']
      }
    ];
  }, [data, isError]);

  const handleOnEditClick = useCallback(() => {
    trackEditProfileEvent(ProfileSection.About);
    setIsEditing(true);
  }, []);

  const handleOnSave = useCallback(
    async (data: UserProfile) => {
      setIsSaving(true);
      try {
        await updateUserProfile(data).unwrap();
        await refetch();
        showSuccessMessage('Your profile information has been updated');
      } catch (error) {
        showFailMessage('Error saving profile information');
      } finally {
        setIsSaving(false);
      }
    },
    [refetch, showFailMessage, showSuccessMessage, updateUserProfile]
  );

  useEffect(() => {
    isError && showFailMessage('Error loading profile information');
  }, [isError, showFailMessage]);

  const nudge = useNudge({
    messagesJSON: messages,
    sectionData: data,
    sectionName: ProfileNudgeSectionNames.About,
    context: NudgeAnalyticsContext.About,
    sectionIndex: index,
    onAccept: handleOnEditClick,
    timeUntilStale
  });

  const debouncedLoading = useDebounceLoading(isLoading);
  const debouncedSaving = useDebounceLoading(isSaving);
  const debouncedEditing = useDebounceLoading(isEditing);

  return (
    <ProfileSectionWrapper>
      <PersonalInfo
        sectionTitle="About"
        loading={debouncedLoading || debouncedSaving}
        subSections={subSections}
        nudge={nudge}
        button={
          !debouncedLoading && !isError && data ? (
            <AboutPanel
              isOpen={debouncedEditing}
              onOpenChange={setIsEditing}
              data={data}
              onSave={handleOnSave}
              triggerButton={<PersonalInfoButton onClick={handleOnEditClick} />}
              messages={messages}
            />
          ) : undefined
        }
      />
    </ProfileSectionWrapper>
  );
};

export default AboutSection;
