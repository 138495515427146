import React, { ComponentProps, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Command } from 'cmdk';

import Icon from '../Icon';
import MenuText from './MenuText';
import { isExternalUrl } from '@/utils/helpers';

export type MenuItemText = {
  label?: ReactNode;
  // Used outside the menu
  eyebrow?: string;
  // Used inside the menu, falling back to eyebrow
  shortEyebrow?: string;
  smallClassName?: string;
};

export type MenuItemProps = MenuItemText & {
  disabled?: boolean;
  condensed?: boolean;
  visible?: boolean;
  icon?: ComponentProps<typeof Icon>['name'];
  key?: string | undefined;
  rightIcon?: ComponentProps<typeof Icon>['name'];
} & (
    | {
        onClick: () => void;
        Element?: undefined;
        isHighlighted?: undefined;
      }
    | {
        to: string;
        Element: typeof Link;
        isHighlighted?: undefined;
      }
    | { Element: typeof Command.Item; onSelect: () => void; value: string; isHighlighted?: boolean }
  );

type Props = MenuItemProps & {
  menuStyle: 'standalone' | 'form-field' | 'standalone-dark' | 'standalone-dark-unsorted';
};

const MenuItem = React.forwardRef<HTMLDivElement | HTMLAnchorElement, Props>(
  (
    {
      label,
      icon,
      rightIcon,
      eyebrow,
      menuStyle,
      Element = 'div',
      smallClassName,
      condensed,
      visible = true,
      ...props
    },
    forwardedRef
  ) => {
    const wrapperClasses = classnames(
      'text-1 flex items-center gap-3 px-6 py-5 text-left text-black outline-none last:border-0 hover:cursor-pointer hover:bg-gray-light aria-[highlighted]:bg-gray-light',
      'focus-visible:bg-gray-light',
      "aria-[selected='true']:bg-gray-light",
      {
        'border-b border-gray-light sm:border-0 sm:py-3': !condensed,
        'py-3': condensed,
        'sm:p-4': menuStyle === 'form-field',
        'font-medium': menuStyle === 'standalone'
        // 'bg-gray-light':
        //   isHighlighted &&
        //   (menuStyle !== 'standalone-dark' && menuStyle === 'standalone-dark-unsorted')
      }
    );

    const iconUrl = useMemo(() => (isExternalUrl(icon ?? '') ? icon : undefined), [icon]);

    if (!visible) return null;

    return (
      <Element
        // @ts-expect-error - union type leading to errors
        ref={forwardedRef}
        className={wrapperClasses}
        disabled={props.disabled ?? false}
        {...props}
      >
        {icon && <Icon size="medium" name={icon} url={iconUrl} />}
        <MenuText
          smallClassName={`text-2 font-normal text-gray-dark ${smallClassName}`}
          value={label}
          eyebrow={eyebrow}
          valueClassName="truncate"
        />
        {rightIcon && <Icon size="medium" className="ml-4" name={rightIcon} />}
      </Element>
    );
  }
);

export default MenuItem;
