import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { OrganizationMember } from '@/@types/client-api';

export const selectMembersByOrginization = (state: RootState) =>
  state.creditTrackerAdmin.organization;

export const selectTrackerByOrg = (orgSk: number) =>
  createSelector(
    [selectMembersByOrginization],
    (item: Record<string, Array<OrganizationMember>>) => item?.[orgSk]
  );

export const selectTrackerByMember = (orgSk: number, userID: number) =>
  createSelector([selectMembersByOrginization], (item: Record<string, Array<OrganizationMember>>) =>
    item?.[orgSk]?.find(a => a.individual_SK == userID)
  );
