import { trackEvent } from '@/analytics/utils';
import {
  AnalyticsContext,
  AnalyticsEventName,
  ClearAllFiltersEventProperties,
  FilterContext
} from './constants';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { ConvertItemAnalyticsModelBase } from './analyticsUtils';

type TrackFilterEventProps = {
  facetGroup: string;
  facetValue: string;
  context?: FilterContext;
};

const trackItemEvent = (
  eventName: AnalyticsEventName,
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName,
      parameters: {
        context: context || '',
        items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
      },
      skipBaseEventParameters: true
    });
  }
};

export const trackFilterEvent = ({ facetGroup, facetValue, context }: TrackFilterEventProps) => {
  trackEvent({
    eventName: AnalyticsEventName.LibraryFilter,
    parameters: {
      facet_group_name: facetGroup,
      facet_value: facetValue,
      context: context ?? ''
    }
  });
};

export const trackClearAllFiltersEvent = ({
  facetGroup
}: Pick<TrackFilterEventProps, 'facetGroup'>) => {
  trackFilterEvent({
    ...ClearAllFiltersEventProperties,
    ...(facetGroup ? { facetGroup } : {}),
    context: FilterContext.AllFiltersPanel
  });
};

export const trackSortEvent = (method?: string) => {
  if (method) {
    trackEvent({
      eventName: AnalyticsEventName.LibrarySort,
      parameters: { method }
    });
  }
};

export const trackViewMaterialsEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => trackItemEvent(AnalyticsEventName.ViewMaterials, itemAnalyticsModel, context);

export const trackReadTranscriptEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => trackItemEvent(AnalyticsEventName.ReadTranscript, itemAnalyticsModel, context);

export const trackViewCertificatesEvent = (itemAnalyticsModel?: ItemAnalyticsModel) =>
  trackItemEvent(AnalyticsEventName.ViewCertificates, itemAnalyticsModel);

export const trackNotifyMeEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => trackItemEvent(AnalyticsEventName.NotifyMe, itemAnalyticsModel, context);

export const trackCancelReservationEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => trackItemEvent(AnalyticsEventName.CancelReservation, itemAnalyticsModel, context);

export const trackReadNowEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => trackItemEvent(AnalyticsEventName.ReadNow, itemAnalyticsModel, context);

export const trackLaunchCreditDetailsEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => trackItemEvent(AnalyticsEventName.LaunchCreditDetails, itemAnalyticsModel, context);

export const trackStartListeningEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => trackItemEvent(AnalyticsEventName.StartListening, itemAnalyticsModel, context);

export const trackStartListeningOtherServicesEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext,
  provider?: string
) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.StartListeningOtherServices,
      parameters: {
        context: context || '',
        provider: provider || '',
        items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
      },
      skipBaseEventParameters: true
    });
  }
};

export const trackDownloadMaterialsEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => trackItemEvent(AnalyticsEventName.DownloadMaterials, itemAnalyticsModel, context);

export const trackEditReservationEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => trackItemEvent(AnalyticsEventName.EditReservation, itemAnalyticsModel, context);
