import { useMemo } from 'react';
import { EditorialSlideBlock as EditorialSlideBlockProps } from '@/@types/content';
import Button from '@/components/ui/Buttons/Button';
import classnames from 'classnames';
import ResponsiveImage from '@/components/ui/ResponsiveImage';
import VimeoVideo from '@/components/ui/VimeoVimeo';
import RichText from '@/components/cms/RichText';

enum LinkStyle {
  Primary = 'primary',
  Secondary = 'secondary'
}

const EditorialSlideBlock: React.FC<EditorialSlideBlockProps> = ({
  image,
  pageTitleLine1,
  pageTitleLine2,
  pageTitleLine3,
  pageTitleLine1Theme,
  pageTitleLine2Theme,
  pageTitleLine3Theme,
  textCopy,
  optionalLink1,
  optionalLink2,
  optionalLink1Style,
  optionalLink2Style,
  videoId
}) => {
  const flexClasses = classnames(
    'flex w-full flex-col-reverse items-center gap-12 sm:gap-10 md:flex-row-reverse md:gap-10 lg:gap-[135px]'
  );

  const imageColumnClasses = classnames('w-full lg:w-1/2 lg:flex-[0_0_calc(50%-12px)]');

  const infoColumnClasses = classnames('flex w-full flex-col gap-8 lg:gap-8');

  const infoContentClasses = classnames('flex flex-col items-start gap-4');

  const linkColor = (style?: string) => {
    switch (style) {
      case LinkStyle.Primary:
        return 'black';
      case LinkStyle.Secondary:
        return 'outline-black';
      default:
        return 'outline-black';
    }
  };

  const headingColor = useMemo(() => {
    const getColor = (color?: string) => {
      return classnames({
        'text-black': color === 'black',
        'text-red': color === 'red',
        'text-gold': color === 'gold',
        'text-green-100': color === 'green-100',
        'text-green-200': color === 'green-200',
        'text-green-300': color === 'green-300',
        'text-teal-100': color === 'teal-100',
        'text-teal-200': color === 'teal-200',
        'text-teal-300': color === 'teal-300',
        'text-blue-100': color === 'blue-100',
        'text-blue-200': color === 'blue-200',
        'text-blue-300': color === 'blue-300',
        'text-indigo-100': color === 'indigo-100',
        'text-indigo-200': color === 'indigo-200',
        'text-indigo-300': color === 'indigo-300',
        'text-purple-100': color === 'purple-100',
        'text-purple-200': color === 'purple-200',
        'text-purple-300': color === 'purple-300',
        'text-magenta-100': color === 'magenta-100',
        'text-magenta-200': color === 'magenta-200',
        'text-magenta-300': color === 'magenta-300'
      });
    };
    const heading1 = getColor(pageTitleLine1Theme);
    const heading2 = getColor(pageTitleLine2Theme);
    const heading3 = getColor(pageTitleLine3Theme);

    return { heading1, heading2, heading3 };
  }, [pageTitleLine1Theme, pageTitleLine2Theme, pageTitleLine3Theme]);

  return (
    <div className="container module-spacing relative mt-[161px] flex grow md:mt-0 lg:mt-[64px] lg:items-start lg:pt-[97px]"  data-component={"EditorialSlideBlock"}>
      <div className={flexClasses}>
        {image || videoId ? (
          <div className={imageColumnClasses}>
            {!videoId && (
              <ResponsiveImage
                propertyName="Image"
                src={image?.url}
                className={classnames('size-full rounded-lg object-cover', {
                  'aspect-square': !videoId,
                  'aspect-video': videoId
                })}
                aspectRatio={1}
                imageSizes={{
                  xs: '330px',
                  sm: '500px',
                  md: '768px',
                  lg: '638px'
                }}
              />
            )}

            {videoId && <VimeoVideo videoId={videoId} thumbnail={image} ratio={16 / 9} />}
          </div>
        ) : null}

        <div className={infoColumnClasses}>
          <div className={infoContentClasses}>
            <div className="flex flex-col gap-1">
              {pageTitleLine1 && (
                <RichText
                  propertyName="PageTitleLine1"
                  content={pageTitleLine1 || ''}
                  className={classnames('heading-1 lg:heading-2', headingColor.heading1)}
                />
              )}
              {pageTitleLine2 && (
                <RichText
                  propertyName="PageTitleLine2"
                  content={pageTitleLine2 || ''}
                  className={classnames('heading-1 lg:heading-2', headingColor.heading2)}
                />
              )}
              {pageTitleLine3 && (
                <RichText
                  propertyName="PageTitleLine3"
                  content={pageTitleLine3 || ''}
                  className={classnames('heading-1 lg:heading-2', headingColor.heading3)}
                />
              )}
            </div>
            {textCopy && (
              <RichText
                propertyName="TextCopy"
                content={textCopy || ''}
                className="text-1 max-w-[470px] lg:heading-6-regular"
              />
            )}
          </div>

          {optionalLink1 || optionalLink2 ? (
            <div className="flex w-fit flex-col gap-2">
              {optionalLink1 ? (
                <Button
                  className="!w-auto !justify-center"
                  propertyName="PrimaryLink"
                  color={linkColor(optionalLink1Style)}
                  href={optionalLink1?.href}
                  label={optionalLink1?.text}
                  target={optionalLink1?.target}
                  title={optionalLink1?.title}
                  size="large"
                />
              ) : null}

              {optionalLink2 ? (
                <Button
                  className="!w-auto !justify-center"
                  propertyName="SecondaryLink"
                  color={linkColor(optionalLink2Style)}
                  href={optionalLink2?.href}
                  label={optionalLink2?.text}
                  target={optionalLink2?.target}
                  title={optionalLink2?.title}
                  size="large"
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EditorialSlideBlock;
