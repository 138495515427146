import type { OnDemandWebVariation as OnDemandWebVariationProps } from '@/@types/content';
import PDPShimmer from '@/components/ui/PDP/PDPShimmer';

import withNav from '@/components/hocs/withNav';
import OnDemandWebVariationProgramWrapper from '@/components/ui/PDP/OnDemandWebVariationProgramWrapper';
import OnDemandWebVariationSegmentWrapper from '@/components/ui/PDP/OnDemandWebVariationSegmentWrapper';

const OnDemandWebVariationComponent = (variant: OnDemandWebVariationProps) => {
  // TODO: Fix isSegment check later, onDemandType is undefined for programs
  const isSegment = variant.onDemandType === 1;

  if (isSegment) return <OnDemandWebVariationSegmentWrapper variant={variant} />;
  if (!isSegment) return <OnDemandWebVariationProgramWrapper variant={variant} />;
  return <PDPShimmer show={true} />;
};

const OnDemandWebVariation = withNav(OnDemandWebVariationComponent);
export default OnDemandWebVariation;
