import { useSendEventsMutation } from '@/redux/api/client/eventHub';
import { useCallback, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { getUID } from '../analyticsUtils';

const intervalInSeconds = 5;

// Send events to EventHubs reading window.eventHubDataLayer array every N seconds
export const useEventHubDataLayerConsumer = () => {
  const lastIndexProcessed = useRef<number>(0);

  const [sendEvents] = useSendEventsMutation();
  const sessionGuid = Cookies.get('_ga') ?? 'NoSession';

  const processEvents = useCallback(async () => {
    // console.debug('Calling useEventHubDataLayerConsumer');
    const currentLastIndex = window.eventHubDataLayer?.length;
    if (currentLastIndex > 0) {
      const eventsToBeProcessed =
        window.eventHubDataLayer.slice(lastIndexProcessed.current, currentLastIndex) ?? [];
      if (eventsToBeProcessed.length > 0) {
        try {
          // console.debug(`Consuming window.eventHubDataLayer. Sending ${eventsToBeProcessed.length} event(s) to eventHub...`);

          const analyticsEventHash = getUID();
          const analyticsSentEventTimestamp = new Date().getTime();
          const jsonEventsToBeProcessed = () =>
            eventsToBeProcessed.map(function (event) {
              const newEvent = {
                ...event,
                analytics_event_hash: analyticsEventHash,
                analytics_sent_event_timestamp: analyticsSentEventTimestamp,
                sessionGuid: sessionGuid,
                dataSource: 'FrontEnd'
              };
              const jsonEvent = JSON.stringify(newEvent);

              return jsonEvent;
            });
          const response = await sendEvents(jsonEventsToBeProcessed()).unwrap();
          if (response.dataSentOK) {
            lastIndexProcessed.current = currentLastIndex;
            return;
          }
          // console.warn(response);
        } catch (error) {
          // Handle error if needed on client. Errors and logging were handled on the backend
          //console.warn(error);
        }
      }
    }
  }, [sendEvents, sessionGuid]);

  useEffect(() => {
    const timerInterval = setInterval(async () => {
      processEvents();
    }, intervalInSeconds * 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [processEvents]);

  return {
    processEvents
  };
};
