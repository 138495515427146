import { FacultyMember } from '@/@types/content';
import { useCallback, useEffect, useState } from 'react';
import FacultyLink, { FacultyLinkProps } from '../ui/PDP/ProgramFaculty/FacultyLink';
import * as Collapsible from '@radix-ui/react-collapsible';
import Icon from '../ui/Icon';
import Divider from '../ui/Divider';
import FacultyLinkBio from '../ui/PDP/ProgramFaculty/FacultyLinkBio';
import EditorialToutWithImageBlock from './EditorialToutWithImageBlock';
import { IContent, LinkItem } from '@/@types/cms';
import FacultyRowContainerBlock from './FacultyRowContainer/FacultyRowContainerBlock';

interface FeaturedGuestsProps {
  guestsContent?: Array<IContent>;
}

enum DisplayMode {
  Bio = 'Bio',
  NoBio = 'NoBio',
  Single = 'Single',
  TwoCol = 'TwoCol'
}
const FeaturedGuests: React.FC<FeaturedGuestsProps> = ({ guestsContent }) => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.NoBio);
  const [expanded, setExpanded] = useState(true);
  const [guests, setGuests] = useState<FacultyMember[]>([]);

  useEffect(() => {
    if (guestsContent) {
      const mappedGuests: FacultyMember[] = guestsContent.map(x => ({
        ...x,
        status: Number(x.status)
      }));
      setGuests(mappedGuests);
    }
  }, [guestsContent]);

  useEffect(() => {
    if (guests) {
      if (guests?.length == 1 && guests[0].biography) {
        setDisplayMode(DisplayMode.Single);
      } else if (guests?.length >= 6) {
        setDisplayMode(DisplayMode.TwoCol);
      } else if (guests?.filter(x => x.biography).length > 1) {
        setDisplayMode(DisplayMode.Bio);
      }
    }
  }, [guests]);

  const renderComponent = (items: FacultyMember[], content: IContent[]) => {
    switch (displayMode) {
      case DisplayMode.Bio:
        return bioLayout(items);
      case DisplayMode.Single:
        return singleFacultyLayout(items[0]);
      case DisplayMode.TwoCol:
        return twoColLayout(items);
      default:
        return noBioLayout(content);
    }
  };

  const RoleGroupGuests = (members: FacultyLinkProps[]) => {
    return (
      <>
        <div className="pb-10 pt-4">
          <div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2">
            {members.map((member, index) => (
              <FacultyLink key={index} {...member} showTitle={true} hideAffiliation={true} />
            ))}
          </div>
        </div>
        <Divider color="light" />
      </>
    );
  };

  const bioLayout = (items: FacultyMember[]) => {
    const facultyLinks = mapFacultyMembersToFacultyLinks(items);
    return (
      <div className="my-10 flex flex-col">
        {facultyLinks.map((x, index) => (
          <FacultyLinkBio key={index} {...x} />
        ))}
      </div>
    );
  };

  const twoColLayout = (items: FacultyMember[]) => {
    const facultyLinks = mapFacultyMembersToFacultyLinks(items);
    return (
      <div className="my-20">
        <Collapsible.Root open={expanded} onOpenChange={setExpanded}>
          <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown">
            {RoleGroupGuests(facultyLinks)}
          </Collapsible.Content>
          <Collapsible.Trigger className="text-2-fixed mt-10 flex items-center font-bold">
            {expanded ? 'Show less' : 'Show more'}
            {expanded ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
          </Collapsible.Trigger>
        </Collapsible.Root>
      </div>
    );
  };

  const singleFacultyLayout = (item: FacultyMember) => {
    if (item) {
      const link: LinkItem = {
        text: 'Find out more',
        href: item.contentLink?.url,
        title: 'Find out more'
      };
      return (
        <EditorialToutWithImageBlock
          smallImage={false}
          contentLink={item.contentLink}
          image={item.image?.expanded?.contentLink ?? item.image}
          copy={item.biography}
          heading={item.displayName}
          eyebrowText={`Featured in this episode`}
          primaryLink={link}
          layout={'media-left'}
        />
      );
    }
    return <></>;
  };

  const noBioLayout = (items: IContent[]) => {
    return (
      <FacultyRowContainerBlock facultyMembers={items} title="" layout="4-columns" format="podcast" />
    );
  };

  const mapFacultyMembersToFacultyLinks = useCallback((members: FacultyMember[]) => {
    const links: FacultyLinkProps[] = members.map(m => ({ ...m, imageUrl: m.image?.url }));
    return links;
  }, []);

  if (!guests?.length) return <></>;

  return (
    <section id="episode-faculty" className="module-spacing-pdp">
      {displayMode != DisplayMode.Single && <h2 className="heading-6-medium">Featured in this episode</h2>}
      {renderComponent(guests!, guestsContent!)}
    </section>
  );
};
export default FeaturedGuests;
