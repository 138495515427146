import Snippet from '@/components/ui/Snippet/Snippet';

import useGetLibraryRow from './useGetLibraryRow';
import { LibraryItem } from '@/@types/client-api';
import SegmentList from '@/components/ui/Snippet/SegmentList/SegmentList';
import useGetSegmentList from './useGetSegmentList';
import { SearchModalPanelState } from './hooks/useSearchModalPanelState';
import ComposedSegmentList from './Snippets/ComposedSegmentList';

interface LibraryRowProps {
  resultItem: LibraryItem;
  isLoading: boolean;
  modalPanelState: SearchModalPanelState;
  refetchData?: () => void;
  shouldBeAllowedToAutoplayVideo?: boolean;
}

const LibraryRow = ({
  resultItem,
  isLoading,
  modalPanelState,
  refetchData,
  shouldBeAllowedToAutoplayVideo
}: LibraryRowProps) => {
  const snippet = useGetLibraryRow(resultItem, modalPanelState, refetchData);
  const segmentList = useGetSegmentList({ resultItem, context: 'library' });
  
  return (
    !!snippet && (
      <div className="flex-col space-y-8"       data-component={"LibraryRow"}>
        <Snippet
          {...snippet}
          loading={isLoading}
          context="library"
          modalPanelState={modalPanelState}
          shouldBeAllowedToAutoplayVideo={shouldBeAllowedToAutoplayVideo}
          itemAnalyticsModel={resultItem?.itemAnalyticsModel}
        />
        {segmentList &&
          ('liveSegments' in segmentList || 'segments' in segmentList ? (
            <ComposedSegmentList {...segmentList} />
          ) : (
            <SegmentList {...segmentList} />
          ))}
      </div>
    )
  );
};

export default LibraryRow;
