// this modal is triggered from the 'add to calendar' button within the PDP page
// we have two AddToCalendarModal components, one in the Search Results folder and one in the PDP folder
// because each triggers and uses the open/close state differently

import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import AddToCalendarModalContent from './AddToCalendarModalContent';
import { AddToCalendarModalContentProps } from './AddToCalendarModalContent';

type AddToCalendarModalProps = ModalProps &
  AddToCalendarModalContentProps & {
    ctaLabel?: string;
    className?: string;
  };

const AddToCalendarModal: React.FC<AddToCalendarModalProps> = ({
  modalHeader,
  productCode,
  open,
  setOpen,
  context
}) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <AddToCalendarModalContent
        modalHeader={modalHeader}
        productCode={productCode}
        context={context}
      />
    </Modal>
  );
};

export default AddToCalendarModal;
