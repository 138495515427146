import { TabBlock as TabBlockProps } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';

const TabBlock: React.FC<TabBlockProps> = ({ content }) => {
  if (!content) return null;

  return <ContentArea propertyName="Item" components={content} />;
};

export default TabBlock;
