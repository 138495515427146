import { SubjectResponse, UserIndustry } from '@/@types/client-api';
import { TextLinkBlock } from '@/@types/content';
import { ClientAPI } from '@/redux/api';

const taxonomyEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getTaxonomyCatalogChildren: builder.query<TextLinkBlock[], string>({
      query: params => ({
        url: `/taxonomy/catalog-children?id=${params}`,
        method: 'GET'
      })
    }),
    getTaxonomySubjects: builder.query<SubjectResponse[], boolean>({
      query: onlyBabyPool => ({
        url: `/taxonomy/subjects?onlyBabyPool=${onlyBabyPool}`,
        method: 'GET',
        credentials: 'omit'
      })
    }),
    getTaxonomyIndustries: builder.query<UserIndustry[], void>({
      query: () => ({
        url: '/taxonomy/industries',
        method: 'GET',
        credentials: 'omit'
      })
    })
  })
});

export const {
  useGetTaxonomyCatalogChildrenQuery,
  useGetTaxonomySubjectsQuery,
  useGetTaxonomyIndustriesQuery
} = taxonomyEndpoints;

export default taxonomyEndpoints;
