import Panel from '@/components/ui/Panel';
import Link from '@/components/ui/Link';
import ContentArea from '@/components/cms/ContentArea';
import { NavigationItemBlock } from '@/@types/content';
import { LinkItem } from '@/@types/cms';
import classnames from 'classnames';

enum Variant {
  Light = 'light',
  Dark = 'dark'
}

interface PanelAccountNavTabletProps {
  theme: `${Variant}`;
  isAccountNavOpen: boolean;
  setIsAccountNavOpen: (value: boolean) => void;
  accountNavClickHandler: () => void;
  secondaryNavData: NavigationItemBlock[];
  logoutLink?: LinkItem;
  onSignout?(): void;
  focusVisibleClasses: string;
  memoizedAvatar: React.ReactNode;
  userAriaLabel?: string;
}

const PanelAccountNavTablet = ({
  theme,
  isAccountNavOpen,
  setIsAccountNavOpen,
  accountNavClickHandler,
  secondaryNavData,
  logoutLink,
  onSignout,
  focusVisibleClasses,
  memoizedAvatar,
  userAriaLabel
}: PanelAccountNavTabletProps) => {
  const btnLoginUserClasses = classnames(
    'relative',
    'after:pointer-events-none after:absolute after:inset-x-0 after:-bottom-7 after:hidden after:h-0 after:transition-all lg:after:content-[""]',
    'lg:after:block',
    {
      'hover:after:h-[3px] hover:after:bg-red': !isAccountNavOpen
    },
    {
      'after:h-[3px] after:bg-red': isAccountNavOpen
    },
    focusVisibleClasses
  );

  const panelTabletClasses = classnames(
    '!max-w-[1024px]',
    'before:pointer-events-none before:absolute before:left-0 before:top-0 before:block before:h-full before:w-1/3 before:border-r before:content-[""]',
    'after:pointer-events-none after:absolute after:left-0 after:top-0 after:block after:h-full after:w-2/3 after:border-r after:content-[""]',
    {
      'before:border-gray-light': theme === Variant.Light,
      'before:border-gray-darker': theme === Variant.Dark
    },
    {
      'after:border-gray-light': theme === Variant.Light,
      'after:border-gray-darker': theme === Variant.Dark
    }
  );

  const logoutLinkClasses = classnames({
    'text-gray-dark': theme === Variant.Light,
    'text-gray-medium': theme === Variant.Dark
  });

  return (
    <Panel theme={theme} isOpen={isAccountNavOpen} onOpenChange={setIsAccountNavOpen}>
      <Panel.Trigger asChild>
        <button
          className={btnLoginUserClasses}
          onClick={accountNavClickHandler}
          aria-label={userAriaLabel}
          data-component={"PanelAccountNavTablet-button"}
        >
          {memoizedAvatar}
        </button>
      </Panel.Trigger>

      <Panel.Content
        hasHorizontalPadding={false}
        side="left"
        className={panelTabletClasses}
        leftHeaderContent={<div className="pl-6">{memoizedAvatar}</div>}
      >
        <Panel.Body scrollClassName="pt-8" className="nav-panel-full-h !px-0">
          <div className="flex h-full pb-[34px] pt-6"  data-component={"PanelAccountNavTablet-body"}>
            <div className="relative flex w-1/3 flex-col items-start px-[26px] pl-12">
              {logoutLink ? (
                <Link
                  as="button"
                  onClick={onSignout}
                  text={logoutLink.text as string}
                  title={logoutLink.title as string}
                  variant="text-link-2"
                  className={classnames(logoutLinkClasses, 'mt-auto')}
                />
              ) : null}
            </div>

            <div className="flex w-2/3">
              <ContentArea
                propertyName="SubnavItems"
                components={secondaryNavData.slice(0, 2)}
                className="flex w-1/2 flex-col gap-8 px-[26px]"
                componentsProps={{
                  theme
                }}
                element="div"
              />

              <ContentArea
                propertyName="SubnavItems"
                components={secondaryNavData.slice(2)}
                className="flex w-1/2 flex-col gap-8 px-[26px]"
                componentsProps={{
                  theme
                }}
                element="div"
              />
            </div>
          </div>
        </Panel.Body>
      </Panel.Content>
    </Panel>
  );
};

export default PanelAccountNavTablet;
