import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ContentTab } from '@/components/ui/ContentTabs';
import { FilterProps, SelectorItem } from '@/components/ui/Filter/types';
import {
  LIBRARY_FILTERS,
  LIBRARY_FILTER_GROUPS,
  LibraryFilterKey,
  SUGGESTED_FILTER_FACETS,
  TAB_ORDER
} from '@/utils/libraryConstants';
import { getOnlyActiveTabLabel, isItemChecked } from '@/utils/libraryUtils';

// Selectors to access specific parts of the state
export const selectLibraryData = (state: RootState) => state.library.data;
export const selectLibraryLoading = (state: RootState) => state.library.isLoading;
export const selectLibraryFetching = (state: RootState) => state.library.isFetching;
export const selectLibraryError = (state: RootState) => state.library.error;

const selectLibraryTabs = createSelector(selectLibraryData, data => {
  const onlyActiveTab = getOnlyActiveTabLabel(data);

  const tabs: ContentTab[] = Object.entries(data?.summary?.headerGroupSearchableTypeCounters || [])
    .map(([label, count]) => ({
      label,
      resultsCount: count ?? 0,
      disabled: (onlyActiveTab && label !== onlyActiveTab) || !count,
      value: label
    }))
    .sort((a, b) => TAB_ORDER.indexOf(a.label) - TAB_ORDER.indexOf(b.label));

  const activeTab = tabs.find(
    tab => tab.value === data?.summary?.filtersApplied?.groupSearchableType
  ) || { label: '', value: '', resultsCount: 0, disabled: true };

  return {
    tabs,
    activeTab
  };
});

const selectLibraryFilters = createSelector(selectLibraryData, (data): FilterProps[] => {
  return (
    data?.summary?.filterResultSummary?.reduce<FilterProps[]>((acc, filter) => {
      const group =
        filter.name && LIBRARY_FILTER_GROUPS[filter.name as keyof typeof LIBRARY_FILTER_GROUPS];
      if (!group) return acc;

      const items =
        filter.filters?.reduce<SelectorItem[]>((itemsAcc, i) => {
          const itemInfo = i.name && group.filters.find(f => f.value === i.name);
          if (!itemInfo || !i.count) return itemsAcc;

          // Handling edge case we want to consolidate two items under one label
          const existingItem = itemsAcc.find(item => item.label === itemInfo.label);
          if (existingItem?.count) {
            existingItem.count += i.count;
            return itemsAcc;
          }

          itemsAcc.push({
            label: itemInfo.label,
            value: i.name!,
            count: i.count,
            checked: isItemChecked(
              i.name,
              data?.summary?.filtersApplied?.[filter.name as LibraryFilterKey]
            )
          });

          return itemsAcc;
        }, []) || [];

      if (items.length) {
        acc.push({
          title: group.title,
          items,
          groupTypeId: filter.name!,
          type: 'select'
        });
      }

      return acc;
    }, []) || []
  );
});

const selectQuickFilters = createSelector(
  selectLibraryFilters,
  selectLibraryTabs,
  (filters, { activeTab }) => {
    const suggestions = activeTab
      ? SUGGESTED_FILTER_FACETS[activeTab.value as keyof typeof SUGGESTED_FILTER_FACETS]
      : [];

    return (
      filters
        .filter(group => group.groupTypeId !== LIBRARY_FILTERS.GROUP_SEARCHABLE_TYPE)
        .flatMap(group =>
          group.items
            .filter(
              item =>
                // Quick filters are a mix of selected filters...
                item.checked ||
                // ...and suggested filters
                suggestions.some(s => s.value === item.value && s.group === group.groupTypeId)
            )
            .map(item => ({
              data: {
                label: item.label,
                checked: item.checked,
                value: item.value,
                groupTypeId: group.groupTypeId
              },
              canDeselect: true
            }))
        )
        .filter(Boolean)
        // Sort checked items first, then by the order they appear in the `suggestions` array, then alphabetically
        .sort((a, b) => {
          if (a.data.checked === b.data.checked) {
            return (
              suggestions.findIndex(
                s => s.value === a.data.value && s.group === a.data.groupTypeId
              ) -
                suggestions.findIndex(
                  s => s.value === b.data.value && s.group === b.data.groupTypeId
                ) || a.data.label.localeCompare(b.data.label)
            );
          }
          return a.data.checked ? -1 : 1;
        })
    );
  }
);

// Combined selector to select multiple parts of the state
export const selectLibrary = createSelector(
  selectLibraryData,
  selectLibraryLoading,
  selectLibraryFetching,
  selectLibraryError,
  selectLibraryTabs,
  selectLibraryFilters,
  selectQuickFilters,
  (data, loading, fetching, error, tabProps, filters, quickFilters) => ({
    data,
    loading,
    fetching,
    error,
    tabProps,
    filters,
    quickFilters
  })
);
