import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: window.env.APP_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: [
      '*.live-video.net',
      '*.my.salesforce-scrt.com',
      '*.cybersource.com',
      '*.trustarc.com',
      '*blog.pli.edu',
      '*connect.pli.edu'
    ]
  }
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
