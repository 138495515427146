import { UsePressReleaseListBlockResponse } from '@/components/blocks/PressReleaseListBlock';
import { ClientAPI } from '@/redux/api';

const pressReleaseEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getPressReleaseList: builder.query<UsePressReleaseListBlockResponse[], void>({
      query: () => ({
        url: '/pressrelease/list',
        method: 'GET'
      })
    })
  })
});

export const { useGetPressReleaseListQuery } = pressReleaseEndpoints;
export default pressReleaseEndpoints;
