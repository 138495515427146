import UpcomingLive from '@/components/ui/PDP/ECommBox/UpcomingLive';
import {
  resetPDPContext,
  setCatalogRelations,
  setHardSelectedVariant,
  setMaterialsLoading,
  setSoftSelectedVariant
} from '@/redux/slices/pdpSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { getLiveVariants } from '@/hooks/PDP/useProgramCatalogRelations';
import { getLiveType, isLive } from '@/hooks/PDP/useProgramVariantSelection';
import {
  getAddressFromVenue,
  getExpiredStatus,
  getPriceFromContent,
  isWaitlist
} from '../PDP/helpers';
import useEcommPanelData, { EcommPanelParams } from './hooks/useEcommData';
import { useGetUpcomingRegistrationsQuery } from '@/redux/api/client/registration';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAuth } from 'react-oidc-context';
import { transformIfPLIUrl } from '@/utils/helpers';
import { AnalyticsContext } from '@/analytics/constants';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import Loading from '../PDP/ECommBox/UpcomingLive/components/Loading';

type EcommPanelLiveProgramProps = {
  params: EcommPanelParams;
  onClose: () => void;
  context?: AnalyticsContext;
};

const EcommPanelLiveProgram = ({ params, onClose, context }: EcommPanelLiveProgramProps) => {
  const dispatch = useDispatch();
  const auth = useAuth();

  const { data, isLoading } = useEcommPanelData(params);
  const { data: registrations, isFetching: isRegFetching } = useGetUpcomingRegistrationsQuery(
    !auth.isAuthenticated ? skipToken : undefined
  );

  const { liveEcomLabels, isLoading: isLabelsLoading } = useCatalogLabels();

  const loading = isLoading || isRegFetching || isLabelsLoading;

  const liveVariants = useMemo(() => getLiveVariants(data), [data]);

  const variants = useMemo(
    () => ({
      variants: liveVariants.map(variant => {
        const priceInfo = getPriceFromContent(variant.content);
        const registrationInfo = registrations?.find(r => r.pk === variant.content.code);
        return {
          code: variant.content.code!,
          location: variant.content.location ?? '',
          eventStartDate: variant.content.eventStartDate ?? '',
          eventEndDate: variant.content.eventEndDate ?? '',
          timeZoneIdentifier: variant.content.timeZoneIdentifier ?? '',
          type: getLiveType(variant),
          displayName: variant.content.displayName ?? '',
          itemClass: variant.content.itemClassId,
          retailPrice: priceInfo.rawRetailPrice,
          discountPrice: priceInfo.rawDiscountPrice,
          price: priceInfo.price,
          originalPrice: priceInfo.strikePrice,
          retail: priceInfo.retail,
          isExpired: getExpiredStatus(variant.content),
          registered: registrations?.some(reg => reg.pk === variant.content.code),
          sk: variant.content.externalSystemKey,
          isLive: isLive(variant),
          waitlist: isWaitlist(variant.content),
          ...getAddressFromVenue(variant.content.typedVenue),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          launchUrl: registrationInfo ? (variant as any)?.launchUrl : undefined
        };
      })
    }),
    [liveVariants, registrations]
  );

  useEffect(() => {
    dispatch(resetPDPContext());
    return () => {
      dispatch(resetPDPContext());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const selected = variants.variants.find(variant => variant.code === params.pk)!;
    if (variants.variants.length === 1) dispatch(setHardSelectedVariant(selected));
    else dispatch(setSoftSelectedVariant(selected));
    dispatch(setCatalogRelations(variants));
    dispatch(setMaterialsLoading(false));
  }, [variants, dispatch, params, data]);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <UpcomingLive
      labels={liveEcomLabels}
      onClosePanel={onClose}
      title={params.title}
      seeDetailsLink={transformIfPLIUrl(params.url)}
      autoOpenChangeOrCancelRegistrationPanel={params.action === 'ChangeOrCancelRegistration'}
      context={context}
      creditTrackerCode={params.creditTrackerCode}
    />
  );
};

export default EcommPanelLiveProgram;
