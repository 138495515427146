import classnames from 'classnames';
import { ReactNode, useCallback, useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import * as Collapsible from '@radix-ui/react-collapsible';

import Icon from '@/components/ui/Icon';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Shimmer from '@/components/ui/Shimmer';

export type ContentTab = {
  label: string;
  resultsCount: number;
  disabled: boolean;
  value: string;
};

interface ContentTabProps {
  tabs: ContentTab[];
  activeTab: ContentTab;
  onTabChange: (tab: ContentTab) => void;
  ariaLabel?: string;
  children: ReactNode;
  loading?: boolean;
  isVisible?: boolean;
}

const Tab = ({ tab, classNames }: { tab: ContentTab; classNames?: string }) => (
  <p className={classnames('heading-5', classNames)} 
  data-component="Tab"

  >
    {tab?.label}
    <sup className={classnames('text-1 ml-[2px] font-bold', { hidden: tab?.disabled })}>
      {(tab?.resultsCount || 0).toLocaleString()}
    </sup>
  </p>
);

const ContentTabs = ({
  tabs,
  ariaLabel,
  activeTab,
  onTabChange,
  children,
  loading = false,
  isVisible = true
}: ContentTabProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasTabs = useMediaQuery(ScreenSizeQueries.md);
  const hasMultipleItems = tabs?.length > 1;
  const hasCollapsible = !hasTabs && (loading || hasMultipleItems);

  const onDropdownOptionClick = useCallback(
    (tab: ContentTab) => {
      onTabChange(tab);
      setIsOpen(false);
    },
    [onTabChange]
  );

  return (
    <Tabs.Root
      className="flex flex-col"
      value={activeTab?.value}
      onValueChange={value =>
        tabs && onTabChange(tabs.find(tab => tab?.value === value) || tabs[0])
      }
      data-component={"ContentTabs"}
    >
      {loading ? (
        <div className="flex justify-start">
          {Array.from({ length: 4 }).map((_, index) => (
            <Shimmer key={index} className="mr-[40px] h-7 w-[150px]" />
          ))}
        </div>
      ) : isVisible ? (
        <Tabs.List className="flex gap-8 text-nowrap max-md:hidden" aria-label={ariaLabel}>
          {tabs?.map(tab => (
            <Tabs.Trigger
              className={classnames(
                'transition-colors duration-short hover:text-red data-[disabled]:text-gray-medium',
                {
                  'data-[state=active]:text-red': hasMultipleItems
                }
              )}
              key={tab.value}
              value={tab.value}
              aria-label={`${tab.label} ${tab.resultsCount}`}
              disabled={tab.disabled}
            >
              <Tab tab={tab} />
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      ) : null}

      {isVisible && hasCollapsible && (
        <Collapsible.Root
          className="md:hidden"
          defaultOpen={false}
          open={isOpen}
          onOpenChange={open => {
            setIsOpen(open);
          }}
        >
          <Collapsible.Trigger
            value={activeTab?.value}
            className={classnames(
              'flex w-full justify-between hover:text-red data-[state=open]:text-red [&[data-state=open]>svg]:rotate-180',
              { 'pointer-events-none text-gray-medium': activeTab?.disabled }
            )}
          >
            {!loading && (
              <>
                <Tab tab={activeTab} classNames="transition-colors duration-short" />
                <Icon name="chevron-down" size="large" />
              </>
            )}
          </Collapsible.Trigger>
          <Collapsible.Content>
            <div className="mt-5 flex flex-col items-start gap-5">
              {tabs
                ?.filter(tab => tab?.value !== activeTab?.value)
                .map(tab => {
                  return (
                    <button
                      onClick={() => onDropdownOptionClick(tab)}
                      key={tab?.value}
                      className={classnames({
                        'pointer-events-none text-gray-medium': tab?.disabled
                      })}
                    >
                      <Tab tab={tab} classNames="transition-colors duration-short hover:text-red" />
                    </button>
                  );
                })}
            </div>
          </Collapsible.Content>
        </Collapsible.Root>
      )}
      {isVisible && !hasCollapsible && (
        <Tab tab={activeTab} classNames="transition-colors duration-short md:hidden" />
      )}

      {hasTabs ? <Tabs.Content value={activeTab?.value}>{children}</Tabs.Content> : children}
    </Tabs.Root>
  );
};

export default ContentTabs;
