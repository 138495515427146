import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectUserCertificates = (state: RootState) => state.userProfile.certificates;
export const certificateUploadProgress = (state: RootState) => state.userProfile.uploadProgress;

export const selectCertificatesByUserAndRegion = (region: string, user: string) =>
  createSelector([selectUserCertificates], x => x?.[user]?.filter(a => a.regionKey == region));

export const selectCertificatesByUser = (userID: string) =>
  createSelector([selectUserCertificates], x => x?.[userID]);
