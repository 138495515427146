import { Address, AddressResponse } from '@/@types/client-api';
import { DropDownItem, SubSection } from '@/components/ui/PersonalInfoSubSection';
import {
  BillingAddressTitle,
  ShippingAddressTitle,
  getAddressCategoryDescription,
  isBillingAddress,
  isShippingAddress
} from '@/components/ui/ProfilePanels/AddressPanel/utils';

const EmptyDescription = '-';

type AddressActions = {
  onEdit: (addressIndex: number, address: Address) => void;
  onSetAsDefaultBilling: (address: Address) => void;
  onSetAsDefaultShipping: (address: Address) => void;
  onDelete: (address: Address) => void;
};

export const getAddressesSubSections = (
  data: AddressResponse | undefined,
  isError: boolean,
  actions: AddressActions
): SubSection[] => {
  if (!data || isError) {
    return [
      { title: BillingAddressTitle, description: EmptyDescription },
      { title: ShippingAddressTitle, description: EmptyDescription }
    ];
  }
  const billingAddress = data.items?.find(isBillingAddress);
  const shippingAddress = data.items?.find(isShippingAddress);
  const otherAddresses =
    data.items?.filter(item => item !== billingAddress && item !== shippingAddress) || [];

  return [
    {
      title: BillingAddressTitle,
      description: getAddressDescription(data, billingAddress),
      dropdownItems: createDropDownItems(actions, 0, billingAddress)
    },
    {
      title: ShippingAddressTitle,
      description:
        !!shippingAddress && billingAddress === shippingAddress
          ? 'Same as billing address'
          : getAddressDescription(data, shippingAddress),
      dropdownItems: createDropDownItems(actions, 1, shippingAddress)
    },
    ...otherAddresses.map((address, index) => ({
      title: getAddressCategoryDescription(data.addressCategoryLookup ?? [], address),
      description: getAddressDescription(data, address),
      dropdownItems: createDropDownItems(actions, index + 2, address)
    }))
  ];
};
const getAddressDescription = (data: AddressResponse, address?: Address): string => {
  if (!address) return EmptyDescription;
  const country = data.addressCountryLookup?.find(item => item.pk === address.country);
  const lines = [
    address.attention,
    address.company,
    address.address1,
    address.address2,
    (address.city ?? '') +
      (address.state || address.zip
        ? `, ${address.state ? address.state + ' ' : ''}${address.zip}${address.plus4 ? '-' + address.plus4 : ''}`
        : ''),
    country?.countryName
  ];
  return lines.filter(line => !!line).join('\n');
};

const createDropDownItems = (
  actions: AddressActions,
  addressIndex: number,
  address?: Address
): DropDownItem[] | undefined => {
  if (!address) {
    return undefined;
  }
  const isBilling = isBillingAddress(address);
  const isShipping = isShippingAddress(address);
  const items: DropDownItem[] = [];
  items.push({
    label: 'Edit address',
    onClick: () => actions.onEdit(addressIndex, address)
  });
  if (!isBilling) {
    items.push({
      label: 'Set as default billing',
      onClick: () => actions.onSetAsDefaultBilling(address)
    });
  }
  if (!isShipping) {
    items.push({
      label: 'Set as default shipping',
      onClick: () => actions.onSetAsDefaultShipping(address)
    });
  }
  if (!isBilling && !isShipping) {
    items.push({
      label: 'Delete',
      onClick: () => actions.onDelete(address)
    });
  }
  return items;
};
