import { AnalyticsEventName } from './constants';
import { AuthContextProps } from 'react-oidc-context';
import { trackEvent } from './utils';
import { UserProfile } from 'oidc-client-ts';

export const getMemberType = (auth: AuthContextProps) => {
  const userProfile = auth.user?.profile;

  if (!userProfile || Object.keys(userProfile || {}).length === 0 || !auth.isAuthenticated) {
    return 'Anon';
  }

  if (userProfile.sub === '0') {
    return 'Non-Member';
  }
  if ((userProfile.IsIM as string)?.toLowerCase() === 'true') {
    return 'Individual Member';
  }
  if ((userProfile.IsPM as string)?.toLowerCase() === 'true') {
    return 'Privileged Member';
  }

  return 'Member';
};

export const getAccountType = (userProfile?: UserProfile): string => {
  if (!userProfile) {
    return 'Regular';
  }
  return (
    ['PM', 'IM', 'LMSAdmin'].find(
      type => (userProfile[`Is${type}`] as string)?.toLowerCase() === 'true'
    ) || 'Regular'
  );
};

export const trackSetUserInfoEvent = (auth: AuthContextProps) => {
  const userProfile = auth.user?.profile;
  const isMember = (role: 'IsPM' | 'IsIM') =>
    (userProfile?.[role] as string)?.toLowerCase() === 'true';

  let persona: string | null = null;
  if (auth.isAuthenticated) {
    // TODO: call cms api to get persona
    persona = 'persona';
  }
  trackEvent({
    eventName: AnalyticsEventName.SetUserInfo,
    parameters: {
      userId: userProfile?.sub ?? null,
      membership: isMember('IsPM') || isMember('IsIM'),
      memberType: getMemberType(auth),
      organizationId: userProfile?.OrganizationSK ?? null,
      persona: persona,
      source: 'frontend.pli.edu'
    }
  });
};

export const trackAccountLoginEvent = (userProfile?: UserProfile) => {
  const accountType = getAccountType(userProfile);
  trackEvent({
    eventName: AnalyticsEventName.Login,
    parameters: { accountType }
  });
};

export const trackPliPromoEvent = (tcode?: string | null) => {
  if (tcode) {
    trackEvent({
      eventName: AnalyticsEventName.PliPromo,
      parameters: { tcode: tcode }
    });
  }
};
