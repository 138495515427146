import { TaxonomyTabItemBlock as TaxonomyTabItemBlockProps } from '@/@types/content';
import TaxonomyItem from './TaxonomyItem';
import { IContent } from '@/@types/cms';
import LinkGridBlock from '../LinkGridBlock/LinkGridBlock';
import ProfileSectionWrapper from '@/components/ui/ProfileSections/ProfileSectionWrapper';
import type { LinkBlock as LinkBlockProps } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';

type LinkBlocks = LinkBlockProps;

export type LinkBlocksPracticeAreas = IContent & {
  thumbnailUrl?: string;
  id?: string;
};

export type SubnodeTaxonomyProps = TaxonomyTabItemBlockProps & {
  userPracticeAreas?: Array<LinkBlocksPracticeAreas>;
  seeMoreLabel?: string;
  seeLessLabel?: string;
  practiceAreasFollowedLabel?: string;
};

const TaxonomyTabItemBlock: React.FC<SubnodeTaxonomyProps> = ({
  catalogChildren,
  otherChildren,
  heading,
  userPracticeAreas,
  practiceAreasFollowedLabel,
  name
}) => {
  const userPracticeAreasLinks = userPracticeAreas?.map(
    ({ url, name, thumbnailUrl }: LinkBlocksPracticeAreas) => {
      const linkMapper: LinkBlocks = {
        contentType: ['LinkBlock'],
        link: {
          href: url,
          text: name,
          title: name
        }
      };

      if (thumbnailUrl === undefined) {
        return linkMapper;
      }

      linkMapper.image = {
        url: thumbnailUrl,
        alt: name
      };

      return linkMapper;
    }
  );

  const isPracticeArea = name === 'Practice Areas' ? true : false;

  return (
    <div  data-component={"TaxonomyTabItemBlock"}>
      {userPracticeAreas && userPracticeAreas.length > 0 && (
        <ProfileSectionWrapper>
          <LinkGridBlock
            linkBlocks={userPracticeAreasLinks}
            heading={practiceAreasFollowedLabel}
            numberOfRows={2}
            elementType="h3"
            layout="3"
            showThumbnailImagescontrol={true}
          />
        </ProfileSectionWrapper>
      )}
      {catalogChildren && catalogChildren.length > 0 && (
        <TaxonomyItem
          heading={heading}
          catalogChildren={catalogChildren}
          isPracticeArea={isPracticeArea}
        />
      )}
      {otherChildren && <ContentArea components={otherChildren} propertyName="otherChildren" />}
    </div>
  );
};

export default TaxonomyTabItemBlock;
