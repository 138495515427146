import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

export interface BaseBoxStickyButtonProps {
  children: React.ReactNode;
  sticky?: boolean;
}

const StickyButton: React.FC<BaseBoxStickyButtonProps> = ({ children, sticky }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const isFooterInView = useSelector((state: RootState) => state.pdp.isFooterInView);
  const isSiteNavFooterInView = useSelector((state: RootState) => state.page.footerVisible);

  useEffect(() => {
    if (sticky) {
      if (!loaded) {
        setLoaded(true);
      }
    }
  }, [loaded, sticky]);

  const containerClasses = classnames(
    'container fixed inset-x-0 bottom-0 z-panel bg-sticky-button-gradient pb-6 pt-8',
    {
      'animate-slideInUp': sticky && !isFooterInView && !isSiteNavFooterInView,
      'animate-slideOutDown': !sticky || isFooterInView || isSiteNavFooterInView
    }
  );

  return loaded ? (
    <div ref={ref} className={containerClasses}>
      {children}
    </div>
  ) : null;
};

export default StickyButton;
