import Icon from '@/components/ui/Icon';
import { getFormatedDateRangeParts } from '@/lib/helpers/date';
import classnames from 'classnames';
import mapIcon from '@/assets/registration-in-person.png';

interface RegistrationItemProps {
  label: string;
  type: string;
  location?: string;
  address1?: string;
  address2?: string;
  startDate: string;
  endDate?: string;
  timezone: string;
  isLive?: boolean;
  selected?: boolean;
  state?: string;
  onSelect?: () => void;
}

const RegistrationItem: React.FC<RegistrationItemProps> = ({
  label,
  type,
  location,
  address1,
  address2,
  startDate,
  endDate,
  timezone,
  isLive,
  selected,
  state = 'Default',
  onSelect
}) => {
  const formattedDate = getFormatedDateRangeParts(timezone, startDate, endDate);

  const contents = (
    <div className="flex gap-4">
      <div className="w-12 shrink-0">
        {type === 'in-person' && state == 'Default' ? (
          <div className="relative">
            <img src={mapIcon} alt="description" className="size-full" />
          </div>
        ) : (
          <div
            className={classnames(
              'relative flex size-12 items-center justify-center rounded border border-black/[0.07]',
              { 'bg-gray-lightest': state === 'Default' }
            )}
          >
            {type === 'in-person' && <Icon name="location-pin" />}
            {type === 'online' && <Icon name="global" />}
            {type === 'groupcast' && <Icon name="users" />}
            {state === 'ChangeConfirmation' && (
              <span className="absolute top-[26px] block h-[35.2px] w-px border border-dashed border-black/[0.07]"></span>
            )}
          </div>
        )}
      </div>
      <div>
        <div
          className={classnames('text-2 font-medium', {
            'text-gray-dark opacity-100': state === 'ChangeConfirmation',
            'text-black': state !== 'ChangeConfirmation'
          })}
        >
          {isLive && (
            <p className="label mb-1 flex items-center gap-2">
              <span className="block size-3 rounded-full bg-red"></span>
              Live now
            </p>
          )}
          <p>{label}</p>
          <p>{location}</p>
        </div>
        <div
          className={classnames('text-gray-dark', {
            'opacity-100': state !== 'ChangeNewSelection'
          })}
        >
          {address1 && (
            <p className="label mt-4">
              {address1}
              <br />
              {address2}
            </p>
          )}
          <p className="label mt-4 flex items-center gap-3">
            <span>{formattedDate.date}</span>
            <span className="inline-block h-4 w-px bg-gray-medium"></span>
            <span>{formattedDate.time}</span>
          </p>
        </div>
      </div>
    </div>
  );

  return onSelect ? (
    <button
      onClick={onSelect}
      className={classnames(
        'rounded border px-5 py-[18px] text-left transition-colors hover:border-black',
        {
          'border-black bg-gray-lightest': selected,
          'border-silver': !selected
        }
      )}
    >
      {contents}
    </button>
  ) : (
    contents
  );
};

export default RegistrationItem;
