import ProgramFaculty from '@/components/ui/PDP/ProgramFaculty';
import { buildProgramFacultyGroups } from '@/components/ui/PDP/helpers';
import { FacultyModuleLabels, PDPSectionLabels } from '@/components/ui/PDP/labels';
import { LiveProgramCatalogRelations } from '@/hooks/PDP/useProgramCatalogRelations';
import { Variant } from '@/redux/slices/pdpSlice';

interface IPDPProgramFacultyLayoutBlockProps {
  variant: Variant;
  catalogRelations: LiveProgramCatalogRelations;
}

const IPDPProgramFacultyLayoutBlock: React.FC<IPDPProgramFacultyLayoutBlockProps> = ({
  variant,
  catalogRelations
}) => (
  <ProgramFaculty
    labels={FacultyModuleLabels}
    heading={PDPSectionLabels.faculty}
    memberGroups={buildProgramFacultyGroups(variant, catalogRelations.masterProgram)}
  />
);

export default IPDPProgramFacultyLayoutBlock;
