import Button from '@/components/ui/Buttons/Button';
import {
  useGetUserCommunicationPreferencesQuery,
  useUpdateUserCommunicationPreferencesMutation
} from '@/redux/api/client/userCommunicationPreferences';
import { useCallback, useState } from 'react';
import PreferencesPanel from '../ProfilePanels/PreferencesPanel';
import { UserCommunicationPreferences } from '@/@types/client-api';
import useToast from '@/hooks/useToast';
import Shimmer from '../Shimmer';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import { trackEditProfileEvent } from '@/analytics/profile';
import { ProfileSection } from '@/analytics/constants';

type TPreference = { title: string; description: string; isEnabled?: boolean };
const Preference = ({ title, description, isEnabled }: TPreference) => (
  <div>
    <h3 className="text-1 font-medium text-black">
      {title}: {isEnabled ? 'Active' : isEnabled !== undefined ? 'Disabled' : ''}
    </h3>
    <p className="text-2 mt-1 text-gray-dark">{description}</p>
  </div>
);

const PreferencesSection = (): JSX.Element => {
  const { data, isLoading, isError, refetch } = useGetUserCommunicationPreferencesQuery();
  const [updatePreferences] = useUpdateUserCommunicationPreferencesMutation();
  const { showSuccessMessage, showFailMessage } = useToast();

  const [isEditing, setIsEditing] = useState(false);

  const handleSave = useCallback(
    async (data: UserCommunicationPreferences) => {
      try {
        await updatePreferences(data);
        await refetch();
        showSuccessMessage('Preferences updated');
      } catch {
        showFailMessage('Error updating preferences');
      }
    },
    [refetch, showFailMessage, showSuccessMessage, updatePreferences]
  );

  const handleOnEdit = useCallback(() => {
    trackEditProfileEvent(ProfileSection.Preferences);
  }, []);

  const preferencesList: TPreference[] = [
    {
      title: 'Personalized emails',
      description:
        'PLI will send emails about programs and content related to your profile preferences',
      isEnabled: data?.allowPromotionalMails
    },
    {
      title: 'SMS text account notifications',
      description: 'PLI will send updates about registrations and purchases',
      isEnabled: data?.allowSMSContact
    }
  ];

  const debouncedLoading = useDebounceLoading(isLoading);

  return (
    <section className="mt-16 py-12">
      <div className="flex w-full items-center justify-between">
        <h1 className="heading-5-medium">Communication preferences</h1>
        <PreferencesPanel
          data={data}
          isOpen={isEditing}
          onOpenChange={setIsEditing}
          onSave={handleSave}
          triggerButton={
            <Button
              label="Edit"
              color="light-gray"
              disabled={debouncedLoading || isError}
              onClick={handleOnEdit}
            />
          }
        />
      </div>
      <ul className="mt-10 flex flex-col gap-y-6">
        {preferencesList.map((preference, idx) => (
          <li key={idx}>
            {debouncedLoading ? (
              <Shimmer className="h-12 w-full md:w-[500px]" />
            ) : (
              <Preference {...preference} />
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default PreferencesSection;
