import Divider from '../../Divider';
import FacultyLink, { FacultyLinkProps } from './FacultyLink';

export interface RoleGroupProps {
  name: string;
  members: FacultyLinkProps[];
}

const RoleGroup: React.FC<RoleGroupProps> = ({ name, members }) => (
  <>
    <div className="pb-10 pt-4">
      <h3 className="text-1 mb-8 font-medium">{name}</h3>
      <div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2">
        {members.map((member, index) => (
          <FacultyLink key={index} {...member} />
        ))}
      </div>
    </div>
    <Divider color="light" />
  </>
);

export default RoleGroup;
