import { MouseEvent } from 'react';
import type { SnippetButtonProps, SnippetProps } from '@/components/ui/Snippet/Snippet';
import {
  SnippetMetadataLink,
  type SnippetMetadataProps
} from '@/components/ui/Snippet/SnippetMetadata';

import type { RenderSnippetProps } from './types';
import {
  aggregateUserData,
  getThumbnailImageUrl,
  mapPodcastEyebrows,
  getIsSavedToLibrary,
  snippetToAnalyticsContext
} from './helpers';
import { SnippetButtonLabel } from './buttonsLabels';
import { trackStartListeningEvent, trackStartListeningOtherServicesEvent } from '@/analytics/library';

const renderPodcast = ({
  data,
  actions,
  context,
  modalPanelState,
  actionStatus,
  itemAnalyticsModel
}: RenderSnippetProps): SnippetProps => {
  const {
    datesFormatted,
    lastUpdatedOnLabel,
    parentProductLink,
    parentProductLinkText,
    title,
    variationPk,
    url,
    userList = [],
    formats: _formats = [],
    podcastEpisodeMediaProviders
  } = data;
  const userAggregatedData = aggregateUserData({ userList, variant: 'podcastepisode' });
  const formats = (_formats || [])?.map(f => (typeof f === 'object' ? f.displayName! : f));

  const metaItems = [
    parentProductLink && parentProductLinkText ? (
      <>
        from{' '}
        <SnippetMetadataLink to={parentProductLink || ''} label={parentProductLinkText || ''} />
      </>
    ) : null,
    datesFormatted && `${lastUpdatedOnLabel || 'Published on'} ${datesFormatted}`
  ].filter(n => n); // Filter out the null value from the list

  const metadata: SnippetMetadataProps = {
    items: metaItems
  };

  const onSaveToLibrary = actions.addToLibrary;
  const onRemoveFromLibrary = (e?: MouseEvent<HTMLButtonElement>) => {
    const siblingVariantPks =
      context === 'search'
        ? userList?.filter(getIsSavedToLibrary).map(u => u.pk ?? '')
        : [variationPk ?? ''];
    actions.removeFromLibrary.bind(e, siblingVariantPks)();
  };

  const thumbnail = {
    icon: {
      name: 'play',
      label: 'Launch now'
    },
    image: {
      url: getThumbnailImageUrl(variationPk),
      alt: title || ''
    },
    link: { url: url || '#' }
  };

  const mappedFormats = mapPodcastEyebrows(formats);

  const buttons: SnippetButtonProps[] = [
    {
      label: mappedFormats.find(format => format.label.toLowerCase().includes('video'))
        ? SnippetButtonLabel.startWatching
        : SnippetButtonLabel.startListening,
      color: 'black',
      size: 'short',
      iconRight: 'play',
      href: url ?? '',
      onClick: () => {
        const analyticsContext = snippetToAnalyticsContext(context);
        if (analyticsContext && itemAnalyticsModel) {
          trackStartListeningEvent(itemAnalyticsModel, analyticsContext);
        }
      }
    }
  ];

  if (context === 'search') {
    buttons.push({
      label: userAggregatedData?.isSavedToLibrary
        ? SnippetButtonLabel.savedToLibrary
        : SnippetButtonLabel.saveToLibrary,
      color: 'outline-black',
      size: 'short',
      iconRight: userAggregatedData?.isSavedToLibrary ? 'check' : 'add',
      onClick: userAggregatedData?.isSavedToLibrary ? () => onRemoveFromLibrary : onSaveToLibrary,
      actionStatus
    });
  }

  const menuItems = [];

  if (context === 'library') {
    if (podcastEpisodeMediaProviders?.length) {
      podcastEpisodeMediaProviders.forEach(podcastMediaProvider => {
        const { provider = '', url = '', icon } = podcastMediaProvider;
        if (provider && url) {
          menuItems.push({
            label: `Listen on ${provider}`,
            icon,
            key: `listenOn${provider}`,
            href: url ?? '',
            target: '_blank',
            menuStyle: 'standalone' as const,
            onClick: () => {
              const analyticsContext = snippetToAnalyticsContext(context);
              if (analyticsContext && itemAnalyticsModel) {
                trackStartListeningOtherServicesEvent(itemAnalyticsModel, analyticsContext, provider);
              }
            }
          });
        }
      });
    }
    if (menuItems.length > 0) {
      menuItems.push({ separator: true as const });
    }
    menuItems.push({
      label: SnippetButtonLabel.removeFromLibrary,
      onClick: () => {
        modalPanelState.deleteModal.show({ onConfirm: onRemoveFromLibrary });
      },
      icon: 'delete',
      menuStyle: 'standalone' as const
    });
  }

  return {
    variant: 'podcast',
    pk: variationPk ?? '',
    title: title || '',
    link: url || '#',
    metadata,
    buttons,
    thumbnail,
    menuItems,
    secondaryStatus: userAggregatedData?.isSavedToLibrary ? 'saved-to-library' : null,
    eyebrows: mappedFormats
  };
};

export default renderPodcast;
