import classnames from 'classnames';

export interface TagProps {
  variant?: 'emphasis' | 'dark' | 'transparent' | 'light' | 'outline' | 'warning';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  children?: React.ReactNode;
}

const Tag: React.FC<TagProps> = ({ variant = 'dark', size = 'medium', className, children }) => {
  const tagClasses = classnames(
    'text-2-fixed-medium inline-flex items-center justify-center rounded py-1',
    className,
    {
      'bg-black/60 text-white': variant === 'emphasis' || variant === 'transparent',
      'bg-black text-white': variant === 'dark',
      'bg-white text-black': variant === 'light',
      'border border-gray text-black': variant === 'outline',
      'bg-orange text-white': variant === 'warning'
    },
    {
      'h-6 px-[6px]': size === 'small',
      'h-8 px-[10px]': size === 'medium',
      'h-10 px-3': size === 'large'
    }
  );

  return (
    <div className={tagClasses}>
      {variant === 'emphasis' && (
        <div className="mr-2 size-[10px] rounded-full bg-red backdrop-blur" />
      )}
      <span>{children}</span>
    </div>
  );
};

export default Tag;
