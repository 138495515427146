import { useState } from 'react';
import classnames from 'classnames';
import Icon, { IconSizes } from './Icon';
import { TagProps } from './Tag';

interface IconTagProps extends Omit<TagProps, 'children'> {
  icon: string;
  isHovered?: boolean;
  label?: string;
}

const IconTag: React.FC<IconTagProps> = ({ size = 'medium', label, icon, isHovered }) => {
  const [isItemHovered, setIsItemHovered] = useState(false);

  const isItemOrParentHover = isHovered || isItemHovered;
  const tagClasses = classnames(
    'ease text-2-fixed-medium inline-flex items-center overflow-hidden whitespace-nowrap rounded-full bg-black/60 text-white backdrop-blur transition-all duration-short',
    {
      'h-6 max-w-6 pr-2': size === 'small',
      'h-8 max-w-8 pr-3': size === 'medium',
      'h-10 max-w-10 pr-4': size === 'large',
      'hover:max-w-[200px] group-hover:!max-w-[200px]': label,
      '!max-w-[200px]': label && isItemOrParentHover
    }
  );

  const iconClasses = classnames({
    'px-[6px]': size === 'small',
    'px-2': size === 'medium',
    'pl-[10px] pr-2': size === 'large'
  });

  const iconSize: Record<string, IconSizes> = {
    small: 'x-small',
    medium: 'small',
    large: 'medium'
  };

  return (
    <div
      className={tagClasses}
      aria-label={label}
      onMouseEnter={() => setIsItemHovered(true)}
      onMouseLeave={() => setIsItemHovered(false)}
    >
      <span className={iconClasses}>
        <Icon name={icon} size={iconSize[size] as IconSizes} />
      </span>
      {label}
    </div>
  );
};

export default IconTag;
