import { AnalyticsEventName, NudgeAnalyticsContext } from './constants';
import { trackEvent } from './utils';

const trackNudgeEvent = (eventName: AnalyticsEventName, context?: NudgeAnalyticsContext) => {
  if (context) {
    trackEvent({ eventName, parameters: { context: context } });
  }
};

export const trackViewNudgeEvent = (context?: NudgeAnalyticsContext) =>
  trackNudgeEvent(AnalyticsEventName.ViewNudge, context);

export const trackDismissNudgeEvent = (context?: NudgeAnalyticsContext) =>
  trackNudgeEvent(AnalyticsEventName.DismissNudge, context);

export const trackUpdateNudgeEvent = (context?: NudgeAnalyticsContext) =>
  trackNudgeEvent(AnalyticsEventName.UpdateNudge, context);
