import { Link } from 'react-router-dom';
import Icon from '../Icon';
import Shimmer from '../Shimmer';
import { transformIfPLIUrl } from '@/utils/helpers';

export interface ProgramCrossLinkProps {
  programDisplayName?: string;
  programUrl?: string;
  currentSegment?: number;
  totalSegments?: number;
  isLoading?: boolean;
}

export const ProgramCrossLink = ({
  programDisplayName,
  programUrl,
  currentSegment,
  totalSegments,
  isLoading
}: ProgramCrossLinkProps) => {
  if (isLoading)
    return (
      <div className="container py-6 md:py-10">
        <Shimmer className="h-[72px] w-full rounded  px-6 py-[18px]" />
      </div>
    );

  const eyebrow =
    currentSegment && totalSegments
      ? `Segment ${currentSegment} of ${totalSegments} from program`
      : 'From program';
  return (
    <section className="container py-6 md:py-10">
      <Link
        to={transformIfPLIUrl(programUrl)!}
        className="flex w-full rounded bg-gray-lightest px-6 py-[18px] hover:bg-gray-light"
      >
        <span className="flex grow flex-col gap-1">
          <span className="label text-gray-dark">{eyebrow}</span>
          <span className="text-1-medium text-black">{programDisplayName}</span>
        </span>
        <span className="flex flex-col justify-center">
          <Icon name="chevron-right" size="large" />
        </span>
      </Link>
    </section>
  );
};

export default ProgramCrossLink;
