import Snippet from '@/components/ui/Snippet/Snippet';
import type { ISearchResultItem } from '@/@types/client-api/models/ISearchResultItem';
import { StandaloneSegmentListHeader } from '@/components/ui/Snippet/SegmentList/Header';
import type { SegmentListHeaderProps } from '@/components/ui/Snippet/SegmentList/Header';

import useGetSearchResultRow from './useGetSearchResultRow';
import useGetSegmentList from './useGetSegmentList';
import { SearchModalPanelState } from './hooks/useSearchModalPanelState';

interface SearchResultRowProps {
  prevItem?: ISearchResultItem;
  resultItem: ISearchResultItem;
  isLoading: boolean;
  modalPanelState: SearchModalPanelState;
  shouldBeAllowedToAutoplayVideo?: boolean;
}

const SearchResultsRow = ({
  prevItem,
  resultItem,
  isLoading,
  modalPanelState,
  shouldBeAllowedToAutoplayVideo
}: SearchResultRowProps) => {
  const snippet = useGetSearchResultRow(resultItem, modalPanelState);
  const segmentList = useGetSegmentList({ resultItem, context: 'search' });

  // Ranking score, for debugging purposes
  let score;
  if (resultItem.score) {
    if (prevItem && prevItem.score) {
      const diff = resultItem.score - prevItem.score;
      const roundNum = 10 ** 4;
      const diffRounded = Math.round(diff * roundNum) / roundNum;
      score = `${resultItem.score} (${diffRounded})`;
    } else {
      score = String(resultItem.score);
    }
  }

  return (
    <>
      {!!snippet && (
        <Snippet
          {...snippet}
          explanation={resultItem.explanation}
          score={score}
          loading={isLoading}
          context="search"
          modalPanelState={modalPanelState}
          shouldBeAllowedToAutoplayVideo={shouldBeAllowedToAutoplayVideo}
          resultItem={resultItem}
        />
      )}
      {snippet?.variant === 'on-demand' && segmentList && (
        <StandaloneSegmentListHeader {...(segmentList as SegmentListHeaderProps)} />
      )}
    </>
  );
};

export default SearchResultsRow;
