import Block from '@/components/cms/Block';
import AvatarComponent from '../Avatar';
import { Link } from 'react-router-dom';

export interface AuthorsProps {
  authors?: AuthorBioProps[];
  className?: string;
}

export interface AuthorBioProps {
  name: string;
  image?: string;
  url: string;
  bio?: string;
}

const extractTextFromHTML = (htmlString: string) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  const simplifiedText = tempElement.textContent || tempElement.innerText || '';

  return simplifiedText == 'undefined' ? '' : simplifiedText;
};

const Author: React.FC<AuthorBioProps> = ({ name, url, image, bio }) => {
  const simplifiedBio = extractTextFromHTML(bio!);

  return (
    <Block className="mt-10 flex items-start gap-4 sm:mt-12 sm:items-center">
      <Link to={url!}>
        <AvatarComponent
          fullName={name}
          imageUrl={image}
          className="min-w-[70px] sm:min-w-[168px] lg:min-w-[155px]"
        />
      </Link>
      <div>
        <Link to={url!}>
          <p className="heading-6-medium mb-2  text-black">{name}</p>
        </Link>
        <div className="text-1 flex flex-col gap-2 text-gray-dark">
          <span className="line-clamp-[10] sm:line-clamp-5">{simplifiedBio}</span>
          <Link to={url!} className="underline">
            Read more
          </Link>
        </div>
      </div>
    </Block>
  );
};

const Authors: React.FC<AuthorsProps> = ({ authors }) => {
  const authorsId = 'authors';

  return (
    <Block id={authorsId} className="container mb-16 mt-10 flex flex-col gap-2 lg:mt-12 ">
      <h3 className="heading-5">Authors</h3>
      {authors!.map((author, index) => (
        <Author key={index} {...author} />
      ))}
    </Block>
  );
};

export default Authors;
