import type {
  BannerMessageBlock,
  ImportantUpdateBlock,
  JurisdictionCreditInfoPage
} from '@/@types/content';
import withNav from '@/components/hocs/withNav';
import { AnnouncementMessage } from '@/@types/client-api';
import {
  lazy,
  memo,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import useCreditInfo from '@/components/blocks/CreditInfo/hooks/useCreditInfoHook';
import { useGetCreditInfoExtendedQuery } from '@/redux/api/client/useCreditInfo';
import CreditInfoHeader from '@/components/blocks/CreditInfo/snippets/CreditInfoHeader';
import { Theme } from '@/styles/darkMode';
import Banner, { IBannerContentProps } from '@/components/ui/Banner';
import {
  getNewTagText,
  translateToTailwinds
} from '@/components/ui/CreditTracker/util/TrackerHelpers';
import Panel from '@/components/ui/Panel';
import Text from '@/components/cms/Text';
import RichText from '@/components/cms/RichText';
import GlobalTabs, { TabTrigger } from '@/components/ui/Tab';
import { CreditInfoContext } from '@/components/blocks/CreditInfo/context/CreditInfoContext';
import useCreditTracker from '@/components/ui/CreditTracker/hooks/useCreditTrackerHook';
import useQueryParams from '@/hooks/useQueryParams';
import {
  PreselectTabQueryKey,
  regionPageChildrenCDProps,
  RelatedPageMap
} from '@/components/blocks/CreditInfo/util/creditInfoConstants';
import { formatTcode } from '@/utils/helpers';
import { useAuth } from 'react-oidc-context';
import {
  HeaderLinksShimmer,
  LinkGridShimmer,
  SearchResultBlockLoadingShimmer
} from '@/components/blocks/CreditInfo/snippets/LoadingShimmers';
import { Helmet } from 'react-helmet';
const ShareButton = lazy(() => import('@/components/ui/Buttons/ShareButton'));

type JurisdictionCreditInfoPageProps = JurisdictionCreditInfoPage;
const JurisdictionCreditInfoPage: React.FC<JurisdictionCreditInfoPageProps> = memo(props => {
  const {
    regionName,
    hideInBreadcrumbs,
    name,
    relatedPages,
    regionTransitionOptions,
    keyCode,
    sourceCode,
    browserTitle
  } = props;
  const auth = useAuth();
  const params = useQueryParams();
  const [showImportantMessagePanel, setShowImportantMessagePanel] = useState(false);
  const [activeTab, setActiveTab] = useState<TabTrigger>();
  const [isFetching, setIsFetching] = useState<boolean>();
  const [activeContent, setActiveContent] = useState<JurisdictionCreditInfoPage>({ ...props });
  const [tabContent, setTabContent] = useState<RelatedPageMap>();

  const creditInfoContext = useContext(CreditInfoContext);
  const { trackerData, trackerLoading } = useCreditTracker({ skipQuery: !auth.isAuthenticated });

  const {
    renderMainContentArea,
    renderHeaderLinks,
    fetchRelatedPagesContent,
    getUserStatusPreferences,
    renderTopicLinks,
    getSidePanelMessages,
    getApprovalMessage,
    filteredBreadcrumbs
  } = useCreditInfo({
    ...activeContent,
    childrenProperties: regionPageChildrenCDProps
  });

  const {
    data: _extendedContent,
    isLoading: isExtendedContentLoading,
    isFetching: isExtendedContentFetching
  } = useGetCreditInfoExtendedQuery([regionName!], { skip: !regionName });

  const isLoading = useMemo(
    () => isExtendedContentLoading || isExtendedContentFetching || isFetching || trackerLoading,
    [isExtendedContentFetching, isExtendedContentLoading, isFetching, trackerLoading]
  );

  const selectUserPreferredTab = useMemo(
    () =>
      params?.[PreselectTabQueryKey] ? params[PreselectTabQueryKey].toLowerCase() == 'true' : true,
    [params]
  );

  useLayoutEffect(() => {
    const { hash, pathname } = window.location;
    if (!activeContent?.contentLink?.url) return;

    const activeContentPathname = new URL(activeContent?.contentLink?.url ?? '').pathname;
    if (activeContentPathname == pathname) return;

    const timeout = setTimeout(() => {
      window.history.replaceState(
        {},
        activeContent.browserTitle ?? '',
        `${activeContentPathname}${hash}`
      );
    }, 500);

    return () => clearTimeout(timeout);
  }, [activeContent.browserTitle, activeContent?.contentLink?.url]);

  const onTabClick = useCallback(
    (value: TabTrigger) => {
      if (tabContent) {
        setActiveTab(value);
        setActiveContent(tabContent[value.id]);
      }
    },
    [tabContent]
  );

  /** Fetch related page content. */
  useEffect(() => {
    if (!!relatedPages?.length && !tabContent) {
      const map: RelatedPageMap = {};
      regionTransitionOptions.map(
        (b: { heading: string; flag?: string }) => (map[b.heading] = props)
      );

      setIsFetching(true);
      const fetch = async () => {
        const data: JurisdictionCreditInfoPage[] | undefined =
          await fetchRelatedPagesContent(relatedPages);

        data?.map(a => {
          a.regionTransitionOptions.map(
            (b: { heading: string; flag?: string }) => (map[b.heading] = a)
          );
        });
        setTabContent(map);
      };
      fetch();
    }
    setIsFetching(false);
  }, [fetchRelatedPagesContent, props, regionTransitionOptions, relatedPages, tabContent]);

  /** Set the default active tab */
  useEffect(() => {
    if (!!tabContent && !activeTab && !trackerLoading) {
      const active = Object.keys(tabContent)?.[0];

      if (active) {
        setActiveTab({
          id: active,
          label: active
        });
      }
    }
  }, [activeTab, tabContent, trackerLoading]);

  /** Check for user-preferred license status */
  useEffect(() => {
    if (!selectUserPreferredTab) return;

    if (
      !!trackerData?.length &&
      Object.keys(creditInfoContext?.specialStatusPreferences ?? [])?.length == 0
    ) {
      creditInfoContext.specialStatusPreferences = getUserStatusPreferences(trackerData ?? []);

      if (regionName) {
        const userPreference = creditInfoContext.specialStatusPreferences?.[regionName];

        if (userPreference) {
          onTabClick({
            id: userPreference,
            label: userPreference
          });
        }
      }
    }
  }, [
    creditInfoContext,
    getUserStatusPreferences,
    onTabClick,
    regionName,
    selectUserPreferredTab,
    trackerData
  ]);

  const extendedContent = useMemo(() => {
    if (regionName) return _extendedContent?.[regionName];
  }, [_extendedContent, regionName]);

  const approvalMessage = useMemo(() => {
    return getApprovalMessage() ?? extendedContent?.creditClassSubheading;
  }, [extendedContent?.creditClassSubheading, getApprovalMessage]);

  const carouselMessages = useMemo(
    () =>
      activeContent.creditAnnouncements
        ?.filter((a: BannerMessageBlock) => a.showOnJurisdictionPageCarousel)
        ?.map((banner: BannerMessageBlock) => banner as AnnouncementMessage),
    [activeContent.creditAnnouncements]
  );

  const recentRuleChangeMessage = useMemo(() => {
    const banner = activeContent.creditAnnouncements?.find(
      (a: BannerMessageBlock) => a.showOnRecentRuleChanges
    ) as BannerMessageBlock;

    if (banner) {
      return {
        bannerType: 'secondary',
        body: banner.body,
        icon: banner.icon,
        headingText: banner.headingText,
        newTagText: getNewTagText(banner.expireNewStatus)
      } as IBannerContentProps;
    }
  }, [activeContent.creditAnnouncements]);

  const ImportantUpdateMessage = useMemo(() => {
    const banner = activeContent.importantUpdates?.find(
      (a: ImportantUpdateBlock) => a.currentlyActive
    ) as ImportantUpdateBlock;

    if (banner) {
      return {
        bannerType: 'secondary',
        body: banner.body,
        headingText: banner.headingText,
        newTagText: getNewTagText(banner.expireNewStatus),
        onSeeMore: () => setShowImportantMessagePanel(true)
      } as IBannerContentProps;
    }
  }, [activeContent.importantUpdates, setShowImportantMessagePanel]);

  const tabTriggers = useMemo(() => {
    if (tabContent) {
      return Object.keys(tabContent)?.map(key => {
        return {
          id: key,
          label: key
        } as TabTrigger;
      });
    }
  }, [tabContent]);

  const pageHeader = useMemo(
    () => activeContent.secondaryHeader ?? name ?? '',
    [activeContent.secondaryHeader, name]
  );

  const content = useMemo(() => {
    return (
      <>
        {recentRuleChangeMessage && (
          <Banner
            bannerType="secondary"
            contents={[recentRuleChangeMessage]}
            isLoadingData={isLoading}
          />
        )}
        {ImportantUpdateMessage && (
          <>
            <Banner
              bannerType="secondary"
              contents={[ImportantUpdateMessage]}
              isClamped={true}
              isLoadingData={isLoading}
            />
            <Panel
              isOpen={showImportantMessagePanel}
              onOpenChange={() => setShowImportantMessagePanel(false)}
            >
              <Suspense fallback={<></>}>
                <Panel.Content side="right" closeLabel="Close requirements">
                  <Panel.Header className="mb-5">
                    <Text element="h5" className="heading-5">
                      {ImportantUpdateMessage.headingText}
                    </Text>
                  </Panel.Header>
                  <Panel.Body>
                    <RichText
                      className="text-2 pb-6 pt-2 text-gray-dark [&_a]:underline [&_ol]:my-1 [&_p]:my-3 [&_ul]:my-1"
                      content={translateToTailwinds(ImportantUpdateMessage.body?.toString() ?? '')}
                    />
                  </Panel.Body>
                </Panel.Content>
              </Suspense>
            </Panel>
          </>
        )}
        {isLoading ? <LinkGridShimmer /> : renderTopicLinks(isLoading)}
        <Suspense fallback={<SearchResultBlockLoadingShimmer />}>
          <div className="[&>:first-child]:sm:mt-10 [&_.container]:!px-0">
            {isLoading ? (
              <SearchResultBlockLoadingShimmer />
            ) : (
              renderMainContentArea(formatTcode(sourceCode, keyCode))
            )}
          </div>
        </Suspense>
      </>
    );
  }, [
    recentRuleChangeMessage,
    isLoading,
    ImportantUpdateMessage,
    showImportantMessagePanel,
    renderTopicLinks,
    renderMainContentArea,
    sourceCode,
    keyCode
  ]);

  return (
    <>
      <Helmet>
        <title>{browserTitle}</title>
      </Helmet>
      <div className="container">
        <div className="flex flex-row flex-wrap items-end justify-between">
          <CreditInfoHeader
            isLoading={isLoading}
            header={pageHeader}
            carouselMessages={carouselMessages}
            panelHeader={pageHeader}
            sidePanelMessages={getSidePanelMessages()}
            subHeader={approvalMessage?.message}
            subHeadingIcon={approvalMessage?.icon}
            breadcrumbs={!hideInBreadcrumbs ? filteredBreadcrumbs : []}
          />
          <div className="my-6 flex w-full flex-row items-end justify-start md:my-0 md:w-fit md:justify-end">
            {isLoading ? (
              <HeaderLinksShimmer />
            ) : (
              <Suspense fallback={<HeaderLinksShimmer />}>
                <div className="mr-6">{renderHeaderLinks(extendedContent?.headerLinks)}</div>
                <ShareButton
                  className="text-link group !text-1 pb-0 pt-2 font-medium [&>button]:!pb-0"
                  shareUrl={activeContent.contentLink?.url ?? window.location.href}
                  theme={Theme.Light}
                  title={activeContent.secondaryHeader}
                />
              </Suspense>
            )}
          </div>
        </div>
        {activeTab ? (
          <div className="pt-10">
            <GlobalTabs
              tabsTriggers={tabTriggers ?? []}
              sticky={true}
              variant="page"
              selectedValue={activeTab}
              setSelectedValue={onTabClick}
              elementType="h2"
              key={`tab-group`}
            >
              {content}
            </GlobalTabs>
          </div>
        ) : (
          content
        )}
      </div>
    </>
  );
});

const JurisdictionCreditInfoPageComponent = withNav(JurisdictionCreditInfoPage);
JurisdictionCreditInfoPageComponent.displayName = 'JurisdictionCreditInfoPage';
export default JurisdictionCreditInfoPageComponent;
