import { Link } from 'react-router-dom';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import AvatarComponent from '@/components/ui/Avatar';
import Icon from '@/components/ui/Icon';
import { useCallback } from 'react';
import { useLazyGetFacultiesAnalyticsModelByItemPksQuery } from '@/redux/api/client/itemAnalyticsModel';
import { ConvertItemAnalyticsModelBase } from '@/analytics/analyticsUtils';
import { trackViewFacultyItem } from '@/analytics/faculty';

export interface FacultyLinkProps {
  displayName?: string;
  url?: string;
  imageUrl?: string;
  affiliation?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  showTitle?: boolean;
  hideAffiliation?: boolean;
  externalId?: number;
}

const FacultyLink: React.FC<FacultyLinkProps> = ({
  url,
  displayName,
  imageUrl,
  affiliation,
  firstName,
  lastName,
  title,
  showTitle,
  hideAffiliation,
  externalId
}) => {
  const isLarge = useMediaQuery(ScreenSizeQueries.lg);
  const [fetchFaculties] = useLazyGetFacultiesAnalyticsModelByItemPksQuery();

  const trackEvent = useCallback(
    async (contentCode: string) => {
      const { data } = await fetchFaculties({
        individualSks: [contentCode]
      });
      if (data && data.length > 0) {
        const item = data[0];
        const items = ConvertItemAnalyticsModelBase(item);

        trackViewFacultyItem(items);
      }
    },
    [fetchFaculties]
  );

  const handleClick = useCallback(async () => {
    if (externalId) {
      trackEvent(externalId?.toString());
    }
  }, [externalId, trackEvent]);

  return (
    <Link
      to={url ?? '#'}
      className="group flex content-between items-center gap-4"
      onClick={handleClick}
    >
      <div className="flex min-w-px grow items-center gap-4">
        <AvatarComponent
          fullName={displayName ?? ''}
          firstName={firstName}
          lastName={lastName}
          className="w-[70px] shrink-0 md:w-[76px] lg:w-[90px]"
          imageUrl={imageUrl}
          imageSizes={{
            xs: '70px',
            md: '76px',
            lg: '90px'
          }}
        />
        <div className="min-w-px">
          <p className="text-1 block font-medium transition group-hover:text-red">{displayName}</p>
          {showTitle && title && (
            <p className="text-2 block overflow-hidden truncate text-gray-dark">{title}</p>
          )}
          {affiliation && !hideAffiliation && (
            <p className="text-2 block overflow-hidden truncate text-gray-dark">{affiliation}</p>
          )}
        </div>
      </div>
      <Icon name="chevron-right" size={isLarge ? 'large' : 'medium'} className="shrink-0" />
    </Link>
  );
};

export default FacultyLink;
