import { AnalyticsContext, AnalyticsEventName } from '@/analytics/constants';
import { trackEvent } from '@/analytics/utils';
import Icon from '@/components/ui/Icon';
import { useCallback } from 'react';

const getURL = (type: number, productCode?: string) =>
  `${window.env?.CONTENT_DELIVERY_API}/api/client/add-to-calendar/${productCode}?calendarType=${type}`;

type AddToCalendarLinkProps = {
  type: number;
  productCode?: string;
  icon: string;
  label: string;
  context?: AnalyticsContext;
};

const AddToCalendarLink = ({
  type,
  productCode,
  icon,
  label,
  context
}: AddToCalendarLinkProps): JSX.Element | null => {
  const trackEventAddToCalendar = useCallback(() => {
    if (productCode && context) {
      trackEvent({
        eventName: AnalyticsEventName.AddToCalendar,
        parameters: { method: label, productCode, context }
      });
    }
  }, [context, label, productCode]);

  if (!productCode) {
    return null;
  }

  return (
    <a
      className="text-1 flex w-full items-center gap-x-4 overflow-hidden text-nowrap rounded-lg border border-gray bg-gray-feather px-4 py-6 enabled:hover:border-black enabled:active:border-2 disabled:border-gray disabled:bg-gray-light disabled:text-gray lg:px-8"
      target="_blank"
      href={getURL(type, productCode)}
      onClick={trackEventAddToCalendar}
    >
      <Icon name={icon} size="medium" />
      <p>{label}</p>
    </a>
  );
};

export default AddToCalendarLink;
