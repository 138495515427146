import { ClientAPI } from '@/redux/api';
import { DataSenderStatus, SASToken } from '@/@types/client-api';

const eventHubEndpoints = ClientAPI.injectEndpoints({
  endpoints: build => ({
    sendEvents: build.mutation<DataSenderStatus, string[]>({
      query: body => ({
        url: '/event-hub/events',
        method: 'POST',
        body
      })
    }),
    getEventHubSASToken: build.query<SASToken, void>({
      query: () => '/event-hub/sas-token'
    })
  })
});

export const { useSendEventsMutation, useLazyGetEventHubSASTokenQuery } = eventHubEndpoints;
export default eventHubEndpoints;
