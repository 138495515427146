import { useCallback, useMemo, useState } from 'react';
import ModalContent from '@/components/ui/Modal/ModalContent';
import { ModalEyebrowProps } from '@/components/ui/Modal/ModalEyebrow';
import LicenseForm, { LicenseFormProps } from './LicenseForm';
import { LicenseData, validateRequiredFields } from './utils/licenses';

export type AddMultipleLicensesToProfileModalProps = LicenseFormProps & {
  currentLicense: number;
  totalLicenses: number;
  onSave?: () => void;
  onBack?: () => void;
  onDismiss?: () => void;
  onClose?: () => void;
};

const AddMultipleLicensesToProfileModal = ({
  data,
  onDataChange,
  creditTypeOptions,
  jurisdictionOptions,
  licenseNumberSettings,
  currentLicense,
  totalLicenses,
  onSave,
  onBack,
  onDismiss,
  onClose
}: AddMultipleLicensesToProfileModalProps): JSX.Element => {
  const [validationFailed, setValidationFailed] = useState(false);

  const eyebrow = useMemo(
    (): ModalEyebrowProps => ({ text: `${currentLicense} of ${totalLicenses}` }),
    [currentLicense, totalLicenses]
  );

  const isLastLicense = currentLicense === totalLicenses;

  const validRequiredFields = useMemo(() => validateRequiredFields(data), [data]);

  const handleOnSave = useCallback(() => {
    if (validRequiredFields) {
      onSave?.();
    } else {
      setValidationFailed(true);
    }
  }, [validRequiredFields, onSave]);

  const handleOnDataChange = useCallback(
    (data: LicenseData) => {
      setValidationFailed(false);
      onDataChange?.(data);
    },
    [onDataChange]
  );

  return (
    <ModalContent
      title={data?.isTopLevelSection ? data?.creditClass : data?.creditRegion}
      saveButtonLabel={isLastLicense ? 'Add to my profile' : 'Continue'}
      dismissButtonLabel="Cancel"
      eyebrow={eyebrow}
      isMultiStep={!isLastLicense || !validRequiredFields}
      onSaveButtonPress={handleOnSave}
      onBackButtonPress={onBack}
      onDismissButtonPress={onDismiss}
      onCloseButtonPress={onClose}
    >
      <LicenseForm
        data={data}
        onDataChange={handleOnDataChange}
        creditTypeOptions={creditTypeOptions}
        jurisdictionOptions={jurisdictionOptions}
        licenseNumberSettings={licenseNumberSettings}
        creditTypeDisabled={true}
        jurisdictionDisabled={true}
        validationFailed={validationFailed}
      />
    </ModalContent>
  );
};

export default AddMultipleLicensesToProfileModal;
