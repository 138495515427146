import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { userLoaded, userUnloaded } from '@/redux/slices/authSlice';
import { useGetWarmUpSearchUserCacheQuery } from '@/redux/api/client/userSession';
import Router from './routes/Router';
import { usePLIPromoCookieExternalOrigin } from './hooks/usePLIPromoCookie';
import { RootState, store } from './redux/store';
import { invalidateUserData } from './redux/api';
import Awaiter from './components/ui/Awaiter/Awaiter';
import { useEventHubDataLayerConsumer } from './analytics/hooks/useEventHubDataLayerConsumer';
import { trackSetUserInfoEvent } from './analytics/user';

const App = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const auth = useAuth();
  const dispatch = useDispatch();
  const router = Router();
  const [isWarm, setIsWarm] = useState(false);
  const [hasCheckedConnect, setHasCheckedConnect] = useState(false);
  const [loggedInToConnect, setLoggedInToConnect] = useState(false);
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const { processEvents } = useEventHubDataLayerConsumer();

  usePLIPromoCookieExternalOrigin();

  useEffect(() => {
    if (!hasAuthParams() && !auth.isLoading && !auth.activeNavigator) {
      trackSetUserInfoEvent(auth);
      if (auth.isAuthenticated) {
        setHasCheckedConnect(true);
        dispatch(userLoaded({ token: auth.user?.access_token, name: auth.user?.profile.name }));
        if (!isWarm) {
          setIsWarm(true);
          invalidateUserData();
          (window as unknown as Record<string, unknown>).UserStore = auth.user?.profile; //Set for tracking side effects
          const e = new Event('UserStoreHasData');
          window.dispatchEvent(e);
        }
      } else {
        if (!auth?.user) {
          if (!hasCheckedConnect) {
            dispatch(userUnloaded());
            invalidateUserData(true);
            try {
              console.info('DIAG:Checking connect login ...');
              fetch(
                auth.settings.authority + '/isauthenticated?' + new Date().getTime().toString(),
                {
                  //check connect session
                  method: 'GET',
                  mode: 'cors',
                  cache: 'no-cache',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
              ).then(r => {
                if (r.ok) {
                  r.json().then(j => {
                    console.info('DIAG:Checking connect login ...', j);
                    setLoggedInToConnect(j?.isAuthenticated === true);
                    setHasCheckedConnect(true);
                  });
                } else setHasCheckedConnect(true);
              });
            } catch (error) {
              setHasCheckedConnect(true);
              console.error('DIAG:Checking connect login ...', error);
            }
          }

          if (loggedInToConnect && !hasTriedSignin) {
            setHasTriedSignin(true);
            console.info('DIAG:Trying connect signin ...');
            auth.signinSilent();
          }
        } else {
          setHasCheckedConnect(true);
          setIsWarm(false);
          dispatch(userUnloaded());
          invalidateUserData(true);
        }

        (window as unknown as Record<string, unknown>).UserStore = undefined;
        const e = new Event('UserStoreHasData');
        window.dispatchEvent(e);
      }
    }
    return () => {};
  }, [auth, dispatch, hasCheckedConnect, hasTriedSignin, isWarm, loggedInToConnect]);

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpired(() => {
      console.info('DIAG:Access token expired......');
      setHasTriedSignin(true);
      store.dispatch(userUnloaded());
      auth.signinSilent().then(data => {
        if (data) {
          //dispatch(userLoaded({ token: data.access_token, name: auth.user?.profile.name }));
          console.info('DIAG:Auth token renewed...');
        } else {
          console.info('DIAG:Refresh token invalid or expired...');
          auth?.removeUser().then(() => {
            console.info('DIAG:Attempt connect silent signin...');
            auth.signinSilent().then(data => {
              if (data) {
                console.info('DIAG:Silent signin success...');
                console.info('DIAG:Reloaded by app, expiration failed renew');
                router.navigate(0);
              } else {
                console.info('DIAG:Silent signin failed...');
              }
            });
          });
        }
      });
    });
  }, [auth, dispatch, router]);

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addUserUnloaded(() => {
      invalidateUserData(true);
      console.info('DIAG:You are signed out. Removing user');
      dispatch(userUnloaded());
    });
  }, [auth.events, dispatch]);

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addUserLoaded(user => {
      dispatch(userLoaded({ token: user.access_token, name: user.profile.name }));
      console.info(
        'DIAG:You are signed in. Loading user: ' + user.profile.name + ' ' + user.access_token
      );
    });
  }, [auth.events, dispatch]);

  useEffect(() => {
    window.onbeforeunload = () => {
      processEvents();
    };
  }, [processEvents]);

  useGetWarmUpSearchUserCacheQuery(false, {
    skip:
      !authState.token === undefined ||
      !authState.ready ||
      !auth?.isAuthenticated ||
      hasAuthParams() ||
      auth.isLoading ||
      auth.activeNavigator != undefined
  });

  //console.info(`DIAG:Render ${auth.activeNavigator} ${auth?.user?.expired} ${authState.ready} ${auth.isLoading} ${hasCheckedConnect}`);
  let _state: string = 'route';
  switch (auth.activeNavigator) {
    case 'signinRedirect':
      _state = 'redirect';
      break;
    case 'signoutRedirect':
      _state = 'redirect';
  }
  if (_state != 'redirect') {
    if (auth.activeNavigator == 'signinSilent' || auth?.user?.expired) {
      _state = 'loading';
    } else if (hasAuthParams()) {
      _state = 'signin';
    } else if (!authState.ready || auth.isLoading || !hasCheckedConnect) {
      _state = 'loading';
    }
  }

  if (_state === 'loading') {
    console.info('DIAG:Render loading');
    return <Awaiter content={['Loading...', 'Hang in there...', 'One moment...']} />;
  } else if (_state === 'signin') {
    console.info('DIAG:Render sgnin');
    return <Awaiter content={['Signing you in...', 'Hang in there...', 'One moment...']} />;
  } else if (_state === 'redirect') {
    console.info('DIAG:Render redirect');
    return <></>;
  } else {
    console.info('DIAG:Render router');
    return <RouterProvider router={router} />;
  }
};

export default App;
