import React from 'react';
import { Helmet } from 'react-helmet';
import { ContentReference } from '@/@types/cms';

export interface SEOProps {
  browserTitle?: string;
  navigationTitle?: string;
  name?: string;
  displayName?: string;
  url?: string;
  authorMetaData?: string;
  metaContentType?: string;
  pageDescription?: string;
  keywords?: string[] | string;
  openGraphImage?: ContentReference;
  openGraphTitle?: string;
  twitterCard?: string;
  twitterDescription?: string;
  twitterImage?: ContentReference;
  twitterSite?: string;
  twitterTitle?: string;
  robotsContent?: string;
  openGraphDescription?: string;
  openGraphSiteName?: string;
}

const Seo: React.FC<SEOProps> = ({
  browserTitle,
  navigationTitle,
  displayName,
  name,
  url,
  authorMetaData,
  metaContentType,
  pageDescription,
  keywords,
  openGraphImage,
  openGraphTitle,
  twitterDescription,
  twitterImage,
  twitterSite,
  twitterTitle,
  robotsContent,
  openGraphDescription,
  openGraphSiteName
}) => {
  return (
    <Helmet>
      <title>{browserTitle || navigationTitle || displayName || name}</title>
      <meta name="title" content={browserTitle || navigationTitle || displayName || name}></meta>
      <meta name="robots" content={robotsContent ?? window.env.ROBOTS_DEFAULT} />
      {url && <link rel="canonical" href={url} />}
      {pageDescription && <meta name="description" content={pageDescription} />}
      {keywords && (
        <meta name="keywords" content={Array.isArray(keywords) ? keywords.join(', ') : keywords} />
      )}
      {authorMetaData && <meta name="author" content={authorMetaData} />}
      {metaContentType && <meta name="content-type" content={metaContentType} />}
      {(openGraphTitle || navigationTitle) && (
        <meta property="og:title" content={openGraphTitle || navigationTitle} />
      )}
      {(openGraphDescription || pageDescription) && (
        <meta property="og:description" content={openGraphDescription || pageDescription} />
      )}
      {url && <meta property="og:url" content={url} />}
      {openGraphImage && (
        <meta property="og:image" content={`${window.location.origin}${openGraphImage.url}`} />
      )}
      <meta name="twitter:card" content={'summary_large_image'} />
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      {(twitterTitle || navigationTitle) && (
        <meta name="twitter:title" content={twitterTitle || navigationTitle} />
      )}
      {(twitterDescription || pageDescription) && (
        <meta name="twitter:description" content={twitterDescription || pageDescription} />
      )}
      {twitterImage && (
        <meta name="twitter:image" content={`${window.location.origin}${twitterImage.url}`} />
      )}
      {twitterImage && <meta name="twitter:image:alt" content={twitterImage?.altText as string} />}
      <meta property="og:type" content={'website'} />
      <meta property="og:site_name" content={openGraphSiteName || 'Practising Law Institute'} />
    </Helmet>
  );
};

export default Seo;
