import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { FC, useEffect, useState } from 'react';
import { Splide, Options } from '@splidejs/splide';
import { TimelineCarouselContainerBlock as TimelineCarouselContainerBlockProps } from '@/@types/content';
import Block from '@/components/cms/Block';
import classnames from 'classnames';
import RoundedButton from '@/components/ui/Buttons/RoundedButton';
import Text from '@/components/cms/Text';
import TimelineSideBlock from './TimelineSlideBlock';
import '@splidejs/splide/css';

type TimelineCarouselContainerBlockPropsExtended = TimelineCarouselContainerBlockProps & {
  slideTransitionTime?: number;
};

const TimelineCarouselContainerBlock: FC<TimelineCarouselContainerBlockPropsExtended> = ({
  contentLink,
  heading,
  name,
  slideTransitionTime = 2,
  timelineSlides
}) => {
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [splideRef, setSplideRef] = useState<Splide>();

  useEffect(() => {
    const options: Options = {
      arrows: false,
      autoWidth: true,
      drag: 'free',
      focus: 0,
      gap: '133px',
      pagination: false,
      type: 'loop',
      autoScroll: {
        speed: slideTransitionTime / 10,
        pauseOnHover: false,
        pauseOnFocus: false,
        rewind: false
      }
    };
    const splide = new Splide('.splide', options);
    splide.mount({ AutoScroll });
    setSplideRef(splide);
  }, [slideTransitionTime]);

  const togglePlay = () => {
    if (splideRef) {
      const { AutoScroll } = splideRef.Components;
      if (isPaused) {
        AutoScroll?.play();
      } else {
        AutoScroll?.pause();
      }
      setIsPaused(!isPaused);
    }
  };

  const changeSlide = (direction: '>' | '<') => {
    splideRef?.go(direction);
  };

  return (
    <Block
      element="div"
      contentLinkID={contentLink?.id}
      className={classnames('module-spacing overflow-x-clip bg-charcoal')}
    >
      <div className="container">
        <div className="mb-8 flex justify-end">
          <Text propertyName="Copy" element="h2" className="heading-5 flex-1 text-white">
            {heading}
          </Text>
          <div className="flex gap-1">
            <RoundedButton
              icon="chevron-left"
              color="gray-darker"
              onClick={() => changeSlide('<')}
            />
            <RoundedButton
              icon="chevron-right"
              color="gray-darker"
              onClick={() => changeSlide('>')}
            />
            <RoundedButton
              icon={isPaused ? 'play' : 'pause'}
              color="gray-darker"
              onClick={togglePlay}
            />
          </div>
        </div>
      </div>

      <div className="container px-0">
        <div className="relative left-0 top-[28px] h-0.5 w-full bg-gray-darker" />
        <section className="splide" aria-label={name}>
          <div className="splide__track">
            <ul className="splide__list">
              {timelineSlides?.map(slide => (
                <li className="splide__slide !w-auto" key={slide.contentLink?.id}>
                  <TimelineSideBlock {...slide} />
                </li>
              ))}
            </ul>
          </div>
        </section>
      </div>
    </Block>
  );
};

export default TimelineCarouselContainerBlock;
