import * as Toggle from '@radix-ui/react-toggle';
import TagButton from './TagButton';
import { ComponentProps, forwardRef } from 'react';
import classnames from 'classnames';

export type TFilterButtonProps = {
  isSelected?: boolean;
  fetching?: boolean;
} & Omit<ComponentProps<typeof TagButton>, 'isActive' | 'styleType'>;

const FilterButton = forwardRef<HTMLButtonElement, TFilterButtonProps>(
  ({ isSelected, fetching = false, disabled, ...props }, ref) => {
    return (
      <div className={classnames({ 'pointer-events-none': fetching })}>
        <Toggle.Root pressed={isSelected} asChild>
          <TagButton
            {...props}
            ref={ref}
            isActive={isSelected}
            icon={isSelected && !disabled ? 'close' : undefined}
            disabled={disabled}
            styleType="outline"
          />
        </Toggle.Root>
      </div>
    );
  }
);

export default FilterButton;
