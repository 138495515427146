import { useMemo } from 'react';
import { IContent, PdpLayoutBlock } from '@/@types/cms';
import {
  CatalogTestimonialsBlock,
  FacultyMember,
  LiveProgramGroupcastVariation,
  LiveProgramOhbVariation,
  LiveProgramPBRWebcastVariation,
  LiveProgramPbrSeminarVariation,
  LiveProgramProduct,
  LiveProgramSeminarVariation,
  LiveProgramWebcastVariation,
  MasterProgramNode,
  OnDemandMp3Variation,
  OnDemandMp4Variation,
  OnDemandOhbMp3Variation,
  OnDemandOhbMp4Variation,
  OnDemandProgramProduct,
  OnDemandSegmentProduct,
  OnDemandWebVariation,
  SegmentSetNode,
  SeriesNode,
  Venue
} from '@/@types/content';
import {
  getOnDemandProgramRuntime,
  getOnDemandSegmentRuntime,
  getPriceFromContent,
  getProductImageUrl,
  isValidForSale
} from '@/components/ui/PDP/helpers';
import { isContentType, isOfContentType } from '@/lib/helpers/contentType';
import {
  useGetAncestorsQuery,
  useGetContentByFriendlyUrlQuery
} from '@/redux/api/contentDeliveryAPI';
import { skipToken } from '@reduxjs/toolkit/query';
import { isDefined } from '@/lib/helpers/isDefined';
import { useGetAllChildren } from '../useGetAllChildren';

export type LiveVariation = (
  | LiveProgramSeminarVariation
  | LiveProgramWebcastVariation
  | LiveProgramGroupcastVariation
  | LiveProgramPbrSeminarVariation
  | LiveProgramPBRWebcastVariation
  | LiveProgramOhbVariation
) & { isLive?: boolean; liveSchedule?: LiveSegment[]; pdpLayoutBlock?: PdpLayoutBlock } & {
  typedVenue?: Venue;
} & { testimonials?: CatalogTestimonialsBlock[] };

export type LiveContentType =
  | 'LiveProgramSeminarVariation'
  | 'LiveProgramWebcastVariation'
  | 'LiveProgramGroupcastVariation'
  | 'LiveProgramPbrSeminarVariation'
  | 'LiveProgramPBRWebcastVariation'
  | 'LiveProgramOhbVariation';

export type LiveVariationFormat = {
  content: LiveVariation;
  contentType: LiveContentType;
};

export type ProgramVariation = LiveVariation | OnDemandWebVariation;

export const LiveProgramVariants = [
  'LiveProgramSeminarVariation',
  'LiveProgramWebcastVariation',
  'LiveProgramGroupcastVariation',
  'LiveProgramPbrSeminarVariation',
  'LiveProgramPBRWebcastVariation',
  'LiveProgramOhbVariation'
];

export const isLiveProgramVariation = (contentType: string): contentType is LiveContentType =>
  LiveProgramVariants.includes(contentType);

export function isLiveOhbVariation(contentType: string): contentType is LiveContentType {
  return contentType === 'LiveProgramOhbVariation';
}

interface CrosslinkProgramProps {
  program: ProgramVariation | undefined;
  isLoading: boolean;
}

export type LiveProgramCatalogRelations = {
  series?: SeriesNode;
  masterProgram?: MasterProgramNode;
  product?: LiveProgramProduct;
  variants: LiveVariationFormat[];
  ondemandProgramCrosslink: CrosslinkProgramProps;
};

type OnDemandExtendedProps = { imageUrl: string } & { runtime?: string } & { url?: string } & {
  canLaunch?: boolean;
} & { launchUrl?: string } & { formattedSpeakers?: string } & { transcriptUrl?: string } & {
  isOhb?: boolean;
} & {
  testimonials?: Array<CatalogTestimonialsBlock>;
};
export type WebSegmentVariation = OnDemandWebVariation &
  OnDemandExtendedProps & { pdpLayoutBlock?: PdpLayoutBlock };

export type OnDemandProgramVariation = OnDemandWebVariation &
  OnDemandExtendedProps & { segments?: WebSegmentVariation[]; pdpLayoutBlock?: PdpLayoutBlock } & {
    downloadVariants?: DownloadVariation[];
  }; // only populated for single-segment or OHB programs.

export type OnDemandProgramCatalogRelations = {
  series?: SeriesNode;
  masterProgram?: MasterProgramNode;
  programProduct?: OnDemandProgramProduct;
  programVariants: OnDemandProgramVariation[];
  initialVariantSelection: OnDemandProgramVariation;
  liveProgramCrosslink: CrosslinkProgramProps;
};

export type DownloadSegmentVariation = OnDemandMp3Variation | OnDemandMp4Variation;
export type DownloadOhbVariation = OnDemandOhbMp3Variation | OnDemandOhbMp4Variation;
export type DownloadVariation = (DownloadSegmentVariation | DownloadOhbVariation) & {
  launchUrl?: string;
};
export type OnDemandSegmentCatalogRelations = {
  series?: SeriesNode;
  masterProgram?: MasterProgramNode;
  segmentProduct?: OnDemandSegmentProduct;
  programProduct?: OnDemandProgramProduct;
  programVariant?: OnDemandWebVariation;
  segmentVariant: WebSegmentVariation;
  downloadVariants: DownloadSegmentVariation[];
  webSegmentsFromProgram: WebSegmentVariation[];
};

export type ProgramCatalogRelations =
  | LiveProgramCatalogRelations
  | OnDemandProgramCatalogRelations
  | OnDemandSegmentCatalogRelations;

// TODO: CMS type manifest json is specifying liveVariation.segments as 'any'
export type LiveSegment = {
  id: number;
  title?: string;
  start: string;
  end: string;
  description?: string; // html
  isBreak?: boolean;
  excludeWebcast?: boolean;
  faculty?: string[]; // these are faculty content IDs, can be used to retrieve faculty via ContentAPI
};

/*
 * Helper functions
 */
const getCrosslinkContentUrl = (
  requestProgramType: 'live' | 'ondemand',
  programVariantUrl?: string
) => {
  if (!programVariantUrl) return '';
  const url = new URL(programVariantUrl);
  const path = `${url.pathname.split('/').slice(0, -1).join('/')}/${requestProgramType}`;
  return path;
};

const useGetCrosslinkProps = (
  requestProgramType: 'live' | 'ondemand',
  programVariantUrl?: string
): CrosslinkProgramProps => {
  const crosslinkUrl = getCrosslinkContentUrl(requestProgramType, programVariantUrl);

  const { data, isLoading } = useGetContentByFriendlyUrlQuery(
    { friendlyUrl: crosslinkUrl, userId: 'ANON' },
    { skip: !crosslinkUrl?.length }
  );

  // Sometimes /live or /ondemand urls return series-default (which might not match the live or od parameter)
  // or stopSell content if there isn't a better result available, in those cases we would prefer not to show a crosslink.
  let isValid = true;
  if (data) {
    const validContentTypes =
      requestProgramType === 'live' ? LiveProgramVariants : OnDemandProgramVariants;
    isValid &&= isOfContentType(data, validContentTypes);
    isValid &&= isValidForSale(data);
  }

  return {
    program: isValid ? data : undefined,
    isLoading
  };
};

const getProgramTestimonials = (
  programVariant: LiveVariation,
  product: LiveProgramProduct,
  masterProgram: MasterProgramNode,
  series: SeriesNode
): CatalogTestimonialsBlock[] | undefined => {
  const sources = [
    programVariant?.catalogTestimonials,
    product?.catalogTestimonials,
    masterProgram?.catalogTestimonials,
    series?.catalogTestimonials
  ];

  for (const testimonials of sources) {
    if (testimonials?.length) {
      return testimonials.map(c => c.contentLink?.expanded as CatalogTestimonialsBlock);
    }
  }

  return undefined;
};

const getSegmentTestimonials = (
  webSegmentVariant: WebSegmentVariation,
  segmentProduct: OnDemandSegmentProduct
): CatalogTestimonialsBlock[] | undefined => {
  if (!webSegmentVariant || !segmentProduct) return undefined;

  const sources = [webSegmentVariant?.catalogTestimonials, segmentProduct?.catalogTestimonials];

  for (const testimonials of sources) {
    if (testimonials?.length) {
      return testimonials.map(c => c.contentLink?.expanded as CatalogTestimonialsBlock);
    }
  }

  return undefined;
};

// Helper for rendering variant before API call fetching all live product variants is complete.
export const enrichLiveVariantType = (
  variant: LiveVariation,
  contentType: string
): LiveVariationFormat | null => {
  if (!isLiveProgramVariation(contentType)) return null;
  return {
    content: {
      ...variant,
      liveSchedule: (variant.segments as LiveSegment[]) ?? []
    },
    contentType
  } as LiveVariationFormat;
};

export const getLiveVariants = (children?: LiveVariation[]): LiveVariationFormat[] =>
  (children ?? [])
    .map(v => {
      const contentType = v.contentType?.slice(-1)[0];
      if (!contentType || !isLiveProgramVariation(contentType)) return null;

      return {
        content: {
          ...v,
          typedVenue: v.venue?.expanded as Venue,
          liveSchedule: (v.segments as LiveSegment[]) ?? []
        },
        contentType
      } as LiveVariationFormat;
    })
    .filter(isDefined);

export const sortSegments = (a: OnDemandWebVariation, b: OnDemandWebVariation) => {
  // TODO: Eventually sort by TopicNo instead of segment time
  const aStartTime = a.segmentStartTime ? new Date(a.segmentStartTime) : undefined;
  const bStartTime = b.segmentStartTime ? new Date(b.segmentStartTime) : undefined;
  if (!aStartTime || !bStartTime) return 0;
  if (aStartTime < bStartTime) return -1;
  else if (aStartTime > bStartTime) return 1;
  return 0;
};

const enrichSegment = (seg: OnDemandWebVariation): WebSegmentVariation => {
  const mapFaculty = (s: OnDemandWebVariation) => {
    const roleKeys: Array<keyof typeof s> = [
      'chairpersons',
      'moderators',
      'instructors',
      'speakers'
    ];
    const segFaculty: string[] = [];
    roleKeys.forEach(key => {
      if (key in s && s[key] !== undefined) {
        s[key].forEach((f: IContent) => {
          const faculty = f.contentLink?.expanded as FacultyMember;
          !!faculty?.displayName && segFaculty.push(faculty.displayName);
        });
      }
    });
    return segFaculty.join(', ');
  };
  return {
    ...seg,
    imageUrl: getProductImageUrl(seg.code!),
    url: seg.contentLink?.url,
    formattedSpeakers: mapFaculty(seg),
    runtime: getOnDemandSegmentRuntime(seg.runTimeSeconds).thumbnail
  };
};

export const OnDemandProgramVariants = [
  'OnDemandWebVariation',
  'OnDemandPbrWebVariation',
  'OnDemandInteractiveVariation',
  'OnDemandOhbWebVariation'
];
const downloadSegmentVariants = ['OnDemandMp3Variation', 'OnDemandMp4Variation'];
const downloadOhbVariants = ['OnDemandOhbMp3Variation', 'OnDemandOhbMp4Variation'];

/*
 * This hook is used to fetch the ancestors and children of an on-demand program variation.
 */
export function useOnDemandProgramCatalogRelations(variation: OnDemandWebVariation) {
  const { data: ancestors, isLoading: isAncestorsLoading } = useGetAncestorsQuery(
    variation.contentLink?.guidValue ? { contentLink: variation.contentLink?.guidValue } : skipToken
  );

  const series = ancestors?.find(content => isContentType(content, 'SeriesNode')) as SeriesNode;
  const masterProgram = ancestors?.find(content =>
    isContentType(content, 'MasterProgramNode')
  ) as MasterProgramNode;

  const { children, isLoading: isMasterProgramChildrenLoading } = useGetAllChildren({
    contentLink: masterProgram?.contentLink?.guidValue,
    enableWMSPrice: 'ondemand'
  });

  const programProduct = children?.find(content =>
    isContentType(content, 'OnDemandProgramProduct')
  ) as OnDemandProgramProduct;

  const programVariants = (children?.filter(v =>
    OnDemandProgramVariants.includes(v.contentType?.slice(-1)[0] ?? '')
  ) ?? []) as OnDemandWebVariation[];

  const ohbDownloadVariants = (children?.filter(v =>
    downloadOhbVariants.includes(v.contentType?.slice(-1)[0] ?? '')
  ) ?? []) as DownloadVariation[];

  const segmentSet = children?.find(content => isContentType(content, 'SegmentSetNode'));

  const {
    children: segmentProductVariants,
    isLoading: isSegmentSetChildrenLoading,
    isSuccess: isSegmentSetChildrenSuccess
  } = useGetAllChildren({
    contentLink: segmentSet?.contentLink?.guidValue
  });

  const webSegmentsFromProgram = (segmentProductVariants?.filter(content =>
    isContentType(content, 'OnDemandWebVariation')
  ) ?? []) as OnDemandWebVariation[];

  const downloadSegmentsFromProgram = (segmentProductVariants.filter(seg =>
    downloadSegmentVariants.includes(seg.contentType?.slice(-1)[0] ?? '')
  ) ?? []) as DownloadVariation[];

  const enrichedProgramVariants: OnDemandProgramVariation[] = programVariants.map(pv => {
    const segments: WebSegmentVariation[] = webSegmentsFromProgram
      .filter(seg => seg.liveProgramId === pv.liveProgramId)
      .sort(sortSegments)
      .map(enrichSegment)
      .map(seg => {
        return {
          ...seg,
          canLaunch: !getPriceFromContent(seg).retail // Purchases are considered in useVariantSelection, not here
        };
      });

    // Use 1st segment for OD Program PDP Ecom box image as default. If Library info resolves for the program, it will be reflected in useVariantSelection.
    const imageUrl = segments.length ? segments[0].imageUrl : getProductImageUrl(pv.code!);

    const transcriptUrl = pv.plusUrl ?? programProduct?.plusUrl;

    const testimonials = getProgramTestimonials(pv, programProduct, masterProgram, series);

    const isOhb = (pv as IContent)?.contentType?.slice(-1)[0] === 'OnDemandOhbWebVariation';
    const isSingleSegment = segments.length === 1;
    const downloadsForSingleSegmentProgram: DownloadVariation[] | undefined = isSingleSegment
      ? downloadSegmentsFromProgram
          .filter(seg => seg.liveProgramId === pv.liveProgramId)
          .map(seg => {
            return {
              ...seg,
              canLaunch: !getPriceFromContent(seg).retail // Purchases are considered in useVariantSelection, not here
            };
          })
      : undefined;

    const downloadsForOhb: DownloadVariation[] | undefined = isOhb
      ? ohbDownloadVariants.map(ohbDownload => {
          return {
            ...ohbDownload,
            canLaunch: !getPriceFromContent(ohbDownload).retail // Purchases are considered in useVariantSelection, not here
          };
        })
      : undefined;

    return {
      ...pv,
      runtime: getOnDemandProgramRuntime(pv.runTimeSeconds).thumbnail,
      segments,
      imageUrl,
      transcriptUrl,
      isOhb,
      downloadVariants: downloadsForSingleSegmentProgram ?? downloadsForOhb,
      testimonials
    };
  });

  const initialVariantSelection =
    enrichedProgramVariants.find(v => v.code === variation.code) ?? enrichedProgramVariants[0];

  const liveProgramCrosslink = useGetCrosslinkProps(
    'live',
    initialVariantSelection?.contentLink?.url
  );

  const relations: OnDemandProgramCatalogRelations = {
    series,
    masterProgram,
    programProduct,
    programVariants: enrichedProgramVariants,
    initialVariantSelection: initialVariantSelection ?? variation,
    liveProgramCrosslink
  };

  return {
    relations,
    isLoading: isAncestorsLoading || isMasterProgramChildrenLoading || isSegmentSetChildrenLoading,
    isSeriesLoading: isAncestorsLoading,
    isMasterProgramLoading: isAncestorsLoading,
    isProgramProductLoading: isMasterProgramChildrenLoading,
    isProgramVariantsLoading: isMasterProgramChildrenLoading,
    isProgramSegmentsLoading: !isSegmentSetChildrenSuccess
  };
}

/*
 * This hook is used to fetch the ancestors and children of an on-demand segment variation.
 */
export function useOnDemandSegmentCatalogRelations(variation: OnDemandWebVariation) {
  const { data: ancestors, isLoading: isAncestorsLoading } = useGetAncestorsQuery(
    variation.contentLink?.guidValue ? { contentLink: variation.contentLink?.guidValue } : skipToken
  );

  const series = ancestors?.find(content => isContentType(content, 'SeriesNode')) as SeriesNode;
  const masterProgram = ancestors?.find(content =>
    isContentType(content, 'MasterProgramNode')
  ) as MasterProgramNode;

  const { children, isLoading: isMasterProgramChildrenLoading } = useGetAllChildren({
    contentLink: masterProgram?.contentLink?.guidValue
  });

  const programProduct = children?.find(content =>
    isContentType(content, 'OnDemandProgramProduct')
  ) as OnDemandProgramProduct;

  // Pick the program variation that is recorded from same live program as the segment
  const programVariant = children
    ?.filter(content => isContentType(content, 'OnDemandWebVariation'))
    ?.find(
      pv => (pv as OnDemandWebVariation)?.liveProgramId === variation.liveProgramId
    ) as OnDemandWebVariation;

  const segmentSet = children?.find(content =>
    isContentType(content, 'SegmentSetNode')
  ) as SegmentSetNode;
  const {
    children: segmentProductVariants,
    isLoading: isSegmentSetChildrenLoading,
    isSuccess: isSegmentSetChildrenSuccess
  } = useGetAllChildren({
    contentLink: segmentSet?.contentLink?.guidValue,
    enableWMSPrice: variation?.code ?? ''
  });
  const segmentProduct = (
    (segmentProductVariants?.filter(content => isContentType(content, 'OnDemandSegmentProduct')) ??
      []) as OnDemandSegmentProduct[]
  ).filter(c => c.code?.endsWith(variation.prgSegmentSK?.toString() ?? ''))[0];

  const transcriptUrl = segmentProduct?.plusAvailable ? segmentProduct?.plusUrl : undefined;

  const webSegmentsFromProgram: WebSegmentVariation[] = (
    (segmentProductVariants?.filter(seg => isContentType(seg, 'OnDemandWebVariation')) ??
      []) as OnDemandWebVariation[]
  )
    .filter(seg => seg.liveProgramId === variation.liveProgramId)
    .sort(sortSegments)
    .map(enrichSegment)
    .map(seg => {
      return {
        ...seg,
        canLaunch: !getPriceFromContent(seg).retail, // useVariantSelection will override canLaunch to reflect Library status
        transcriptUrl: seg.code === variation.code ? transcriptUrl : undefined,
        testimonials: getSegmentTestimonials(seg, segmentProduct)
      };
    });

  const segmentVariant: WebSegmentVariation =
    webSegmentsFromProgram.find(v => v.code === variation.code) ?? webSegmentsFromProgram[0];

  const downloadVariants = (
    (segmentProductVariants?.filter(seg =>
      downloadSegmentVariants.includes(seg.contentType?.slice(-1)[0] ?? '')
    ) ?? []) as DownloadSegmentVariation[]
  ).filter(seg => seg.prgSegmentSK === variation.prgSegmentSK);

  const relations: OnDemandSegmentCatalogRelations = {
    series,
    masterProgram,
    segmentProduct,
    programProduct,
    programVariant,
    segmentVariant: segmentVariant ?? variation,
    downloadVariants,
    webSegmentsFromProgram
  };

  return {
    relations,
    isLoading: isAncestorsLoading || isMasterProgramChildrenLoading || isSegmentSetChildrenLoading,
    isSeriesLoading: isAncestorsLoading,
    isMasterProgramLoading: isAncestorsLoading,
    isParentProgramLoading: isMasterProgramChildrenLoading,
    isSegmentsLoading: !isSegmentSetChildrenSuccess
  };
}

/*
 * This hook is used to fetch the ancestors and children of a live program variation.
 */
export const useLiveProgramCatalogRelations = (variation: LiveVariation) => {
  // Fetch ancestors
  const { data: ancestors, isLoading: isAncestorsLoading } = useGetAncestorsQuery(
    variation.contentLink?.guidValue ? { contentLink: variation.contentLink?.guidValue } : skipToken
  );

  const series = ancestors?.find(content => isContentType(content, 'SeriesNode')) as SeriesNode;
  const masterProgram = ancestors?.find(content =>
    isContentType(content, 'MasterProgramNode')
  ) as MasterProgramNode;

  // Fetch children
  const { children, isLoading: isChildrenLoading } = useGetAllChildren({
    contentLink: masterProgram?.contentLink?.guidValue,
    enableWMSPrice: 'live'
  });

  const product = children?.find(content =>
    isContentType(content, 'LiveProgramProduct')
  ) as LiveProgramProduct;

  const variants: LiveVariationFormat[] = useMemo(
    () =>
      getLiveVariants(children).map(lv => {
        const variant = {
          ...lv.content,
          testimonials: getProgramTestimonials(lv.content, product, masterProgram, series)
        };
        return {
          content: variant,
          contentType: lv.contentType
        };
      }),
    [children, masterProgram, product, series]
  );

  const ondemandProgramCrosslink = useGetCrosslinkProps('ondemand', variation?.contentLink?.url);

  const relations: LiveProgramCatalogRelations = {
    series,
    masterProgram,
    product,
    variants,
    ondemandProgramCrosslink
  };

  return {
    relations,
    isLoading: isAncestorsLoading || isChildrenLoading,
    isSeriesLoading: isAncestorsLoading,
    isMasterProgramLoading: isAncestorsLoading,
    isProgramVariantsLoading: isChildrenLoading,
    isProgramProductLoading: isChildrenLoading
  };
};
