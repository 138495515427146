import { SupplementToutLabels } from './labels';

export const SupplementTout = () => (
  <div className="container py-6 md:py-10">
    <div className="flex w-full flex-col gap-[10px] rounded bg-gray-lightest px-[25px] py-[27px] sm:flex-row sm:justify-between sm:px-[20px] sm:py-6">
      <span className="text-1-medium">{SupplementToutLabels.message}</span>
      <span className="text-1">
        To order,{' '}
        <a className="text-link text-1 underline" href={`mailto:${window.env?.APP_PLIPRESS_EMAIL}`}>
          email
        </a>{' '}
        or call {window.env?.APP_PLIPRESS_PHONE_NUMBER}
      </span>
    </div>
  </div>
);

export default SupplementTout;
