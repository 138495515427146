import RichText from '@/components/cms/RichText';
import Text from '@/components/cms/Text';
import classnames from 'classnames';
import Breadcrumbs, { BreadcrumbsProps } from '@/components/ui/Breadcrumbs';

type PracticeAreaMastheadProps = {
  pageTitle?: string;
  copy?: string;
  pageName?: string;
};

const PracticeAreaMasthead: React.FC<PracticeAreaMastheadProps & BreadcrumbsProps> = ({
  pageTitle,
  copy,
  breadcrumbs,
  pageName
}) => {
  const componentTitle = pageTitle || pageName;

  return (
    <div className={classnames('border-b border-b-gray-light bg-white pb-6 pt-8', 'lg:pb-10')}  data-component={"PracticeAreaMasthead"}>
      <div className="container">
        {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        <div className={classnames('max-w-[536px] pt-12', 'lg:max-w-[845px]')}>
          <Text propertyName="PageTitle" element="h1" className="heading-1 mb-6">
            {componentTitle}
          </Text>
          {copy && (
            <div className="text-1 mb-4 mt-6 max-w-[628px]">
              <RichText propertyName="Copy" content={copy} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PracticeAreaMasthead;
