import DropdownMenu from '@/components/ui/DropdownMenu';
import Divider from '@/components/ui/Divider';
import CircleButton from '@/components/ui/Buttons/CircleButton';
import AuthorsList, { AuthorProps } from './AuthorsList';
import Shimmer from '../Shimmer';

export type ActionItem = {
  icon: string;
  label: string;
  onClick: () => void;
  loading?: boolean;
};
export interface PublicationActionBarProps {
  authors?: AuthorProps[];
  date: string;
  actions: ActionItem[];
}

const PublicationActionBar = ({ date, actions, authors }: PublicationActionBarProps) => (
  <>
    <div className="mt-6 gap-2 max-sm:flex lg:mt-8">
      <div className="grow">
        {!!authors && <AuthorsList authors={authors} />}
        <div className="text-1-medium flex flex-row items-center justify-between sm:mt-8 lg:mt-16">
          <p>{date}</p>
          <div className="hidden flex-row gap-8 sm:flex lg:gap-10">
            {actions.map(value => {
              if (value.loading) {
                return (
                  <div key={value.label} className="flex items-center gap-2">
                    <Shimmer key={value.label} className="size-9 rounded-full" />
                    <Shimmer key={`${value.label} `} className="h-5 w-24" />
                  </div>
                );
              }

              return (
                <CircleButton
                  key={value.label}
                  icon={value.icon}
                  label={value.label}
                  onClick={value.onClick}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="shrink-0 sm:hidden">
        <DropdownMenu
          button={() => <CircleButton aria-label="More options" icon="more-ios" />}
          items={actions}
        />
      </div>
    </div>
    <Divider color="light" orientation="horizontal" className="mt-7 hidden lg:flex" />
  </>
);

export default PublicationActionBar;
