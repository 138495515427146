import DeleteProgramModal from '../LibraryPage/DeleteProgramModal';

export type DeleteProgramModalParams = {
  onConfirm: () => void;
};

interface DeleteProgramModalWrapperProps {
  params?: DeleteProgramModalParams;
  onClose: () => void;
}

const DeleteProgramModalWrapper = ({ params, onClose }: DeleteProgramModalWrapperProps) => {
  return (
    <DeleteProgramModal
      setOpen={onClose}
      open={params !== undefined}
      onConfirm={params?.onConfirm}
    />
  );
};

export default DeleteProgramModalWrapper;
