import Shimmer from '@/components/ui/Shimmer';

const LinkGridFacultyShimmer: React.FC = () => {
  return (
    <section className="gridPLI w-full gap-y-8 py-10">
      <div className="col-span-2 sm:col-span-3 md:col-span-4 lg:col-span-3">
        <Shimmer className="h-7 w-full" />
      </div>
      <div className="gridPLI col-span-full gap-y-4">
        {[...Array(4)].map((_, index) => (
          <div
            key={`link-grid-faculty-shimmer-${index}`}
            className="col-span-4 flex sm:col-span-4 md:col-span-6"
          >
            <Shimmer className="size-[70px] rounded-full sm:size-[76px] lg:size-[90px]" />
            <div className="flex-1 pl-4">
              <Shimmer className="h-[22px] w-1/3 lg:w-1/5" />
              <Shimmer className="mt-3 h-[22px] w-2/3 lg:w-2/5" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LinkGridFacultyShimmer;
