import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useDeletePaymentMethodMutation,
  useGetUserPaymentMethodsQuery,
  usePostPaymentMethodMutation,
  useUpdateDefaultPaymentMethodMutation
} from '@/redux/api/client/userPaymentMethods';
import CreditCardGrid from '@/components/ui/CreditCardGrid';
import { CreditCardProps } from '@/components/ui/CreditCardGrid/CreditCard';
import useToast from '@/hooks/useToast';
import {
  TCreditCardFormData,
  formatCardNumber,
  useCreateFlexToken
} from '@/components/ui/CreditCardGrid/CreditCard/helpers';
import DeleteCreditCardModal from '@/components/ui/CreditCardGrid/DeleteCreditCardModal';
import Shimmer from '../Shimmer';
import { CreditCardResponse } from '@/@types/client-api';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import useNudge from '@/hooks/shared/useNudge';
import NudgeTooltip from '../Tooltip/NudgeTooltip';
import {
  isExpiredBlockLevelNudge,
  isExpiredSingleCardNudge,
  ProfileNudgeSectionNames
} from '@/hooks/shared/useNudge/utils';
import { ExpiredBlockLevelNudge, ExpiredSingleCardNudge } from '@/hooks/shared/useNudge/types';
import { trackEditProfileEvent } from '@/analytics/profile';
import { ProfileSection, NudgeAnalyticsContext } from '@/analytics/constants';

const compareCards = (a: CreditCardResponse, b: CreditCardResponse): number => {
  if (a.isDefault) return -1;
  if (b.isDefault) return 1;
  return 0;
};

type CreditCardDisplaySetting = {
  key: string;
  displayName: string;
  image: string;
  imageUrl: string;
};

type PaymentCardsSectionProps = {
  creditCardDisplaySettings: CreditCardDisplaySetting[];
  messages?: string;
  heading?: string;
};

const PaymentCardsSection = ({
  creditCardDisplaySettings,
  messages,
  heading
}: PaymentCardsSectionProps): JSX.Element => {
  const { data, isLoading, isError, refetch } = useGetUserPaymentMethodsQuery();
  const [postPaymentMethod] = usePostPaymentMethodMutation();
  const [deletePaymentMethod] = useDeletePaymentMethodMutation();
  const [updateDefaultPaymentMethod] = useUpdateDefaultPaymentMethodMutation();
  const createFlexToken = useCreateFlexToken();

  const cardSelectedRef = useRef<CreditCardResponse>();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const { showSuccessMessage, showFailMessage } = useToast();

  const handleOnAddCard = useCallback(() => {
    trackEditProfileEvent(ProfileSection.Payment);
    cardSelectedRef.current = undefined;
    setIsEditing(true);
  }, []);

  const handleOnDeleteCard = useCallback((card: CreditCardResponse) => {
    trackEditProfileEvent(ProfileSection.Payment);
    cardSelectedRef.current = card;
    setIsDeleting(true);
  }, []);

  const handleOnSetAsDefaultCard = useCallback(
    async (card: CreditCardResponse) => {
      trackEditProfileEvent(ProfileSection.Payment);
      setIsProcessing(true);
      try {
        await updateDefaultPaymentMethod({ sk: card.sk }).unwrap();
        await refetch();
        showSuccessMessage(
          `${card.cardTypeName ?? ''} ${formatCardNumber(card.ccNumberMasked ?? '')} card has been set as your default method of payment`
        );
      } catch (error) {
        showFailMessage('Error updating default method of payment');
      } finally {
        setIsProcessing(false);
      }
    },
    [refetch, showFailMessage, showSuccessMessage, updateDefaultPaymentMethod]
  );

  const cards = useMemo(() => {
    if (!data || !data.items || isError) {
      return [];
    }
    const sortedCards = data.items ? [...data.items].sort(compareCards) : [];
    return sortedCards.map((card: CreditCardResponse): CreditCardProps => {
      const cardDisplaySetting = creditCardDisplaySettings.find(
        setting => setting.key.toLowerCase() === card.cardTypeName?.toLowerCase()
      );
      const brandName = cardDisplaySetting?.displayName ?? card.cardTypeName ?? '';
      const brandLogo = cardDisplaySetting?.imageUrl ?? '';
      return {
        id: card.sk || 0,
        name: card.cardHolderName ?? '',
        number: card.ccNumberMasked ?? '',
        isExpired: !!card.isExpired,
        expiration: `${card.expirationMonth ?? ''}/${card.expirationYear ?? ''}`,
        brandName,
        brandLogo,
        onSetAsDefaultCardPress: () => handleOnSetAsDefaultCard(card),
        onDeletePress: () => handleOnDeleteCard(card),
        isDefaultCard: card.isDefault,
        isDeleting
      };
    });
  }, [
    creditCardDisplaySettings,
    data,
    handleOnDeleteCard,
    handleOnSetAsDefaultCard,
    isDeleting,
    isError
  ]);

  const expiredCardsIds = useMemo(
    () =>
      cards
        .filter(card => card.id > 0 && card.isExpired)
        .sort((a, b) => a.id - b.id)
        .map(card => card.id),
    [cards]
  );

  const handleOnSave = useCallback(
    async (_data: TCreditCardFormData) => {
      try {
        const data = await createFlexToken(_data);
        await postPaymentMethod(data).unwrap();

        showSuccessMessage('Payment added');
        return async () => {
          setIsProcessing(true);
          await refetch();
          setIsProcessing(false);
        };
      } catch (error) {
        showFailMessage('Error saving card');
      } finally {
        cardSelectedRef.current = undefined;
      }
    },
    [postPaymentMethod, refetch, showFailMessage, showSuccessMessage, createFlexToken]
  );

  const handleOnDeleteCardConfirmation = useCallback(async () => {
    setIsProcessing(true);
    try {
      await deletePaymentMethod({ sk: cardSelectedRef.current?.sk }).unwrap();
      await refetch();
      showSuccessMessage('Payment removed');
    } catch (error) {
      showFailMessage('Error deleting payment');
    } finally {
      cardSelectedRef.current = undefined;
      setIsProcessing(false);
    }
  }, [deletePaymentMethod, refetch, showFailMessage, showSuccessMessage]);

  useEffect(() => {
    isError && showFailMessage('Error loading cards');
  }, [isError, showFailMessage]);

  const debouncedLoading = useDebounceLoading(isLoading);
  const debouncedProcessing = useDebounceLoading(isProcessing);

  const nudge = useNudge({
    messagesJSON: messages,
    sectionData: data,
    sectionName: ProfileNudgeSectionNames.Payment,
    context: NudgeAnalyticsContext.Payment,
    onAccept: handleOnAddCard,
    expiredCardsIds
  });

  const expiredBlockLevelNudge = isExpiredBlockLevelNudge(nudge)
    ? (nudge as ExpiredBlockLevelNudge)
    : undefined;

  const expiredSingleCardNudge = isExpiredSingleCardNudge(nudge)
    ? (nudge as ExpiredSingleCardNudge)
    : undefined;

  return (
    <div>
      <div className="py-10 lg:py-16">
        {debouncedLoading ? (
          <div className="gridPLI w-full pb-8">
            <div className="col-span-2 sm:col-span-3 md:col-span-4 lg:col-span-3">
              <Shimmer className="h-7 w-full" />
            </div>
          </div>
        ) : (
          <NudgeTooltip nudge={expiredBlockLevelNudge}>
            <h1 className="heading-6-medium">{heading}</h1>
          </NudgeTooltip>
        )}
        <div className="mt-8 lg:mt-12">
          <CreditCardGrid
            isPanelOpen={isEditing}
            setPanelOpen={setIsEditing}
            loading={debouncedLoading || debouncedProcessing}
            cards={cards}
            onAddCardPress={handleOnAddCard}
            onSave={handleOnSave}
            nudge={expiredSingleCardNudge}
          />
        </div>
      </div>
      {!debouncedLoading && !isError && data && (
        <DeleteCreditCardModal
          open={isDeleting}
          setOpen={setIsDeleting}
          onConfirm={handleOnDeleteCardConfirmation}
        />
      )}
    </div>
  );
};

export default PaymentCardsSection;
