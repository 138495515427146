import { AnalyticsContext, AnalyticsEventName } from './constants';
import { trackEvent } from './utils';

export const trackLoadMoreEvent = (context?: AnalyticsContext, pageNumber?: number) => {
  if (context && pageNumber) {
    trackEvent({
      eventName: AnalyticsEventName.LoadMore,
      parameters: { context, pageNumber }
    });
  }
};

export const trackPaginationEvent = (context?: AnalyticsContext, pageNumber?: number) => {
  if (context && pageNumber) {
    trackEvent({
      eventName: AnalyticsEventName.Pagination,
      parameters: { context, pageNumber }
    });
  }
};
