import { useCallback, useEffect, useState } from 'react';
import { useLazyGetChildrenQuery } from '@/redux/api/contentDeliveryAPI';
import { IContent } from '@/@types/cms';
import { useProductContentQuery } from './PDP/useProductContentQuery';

interface GetAllChildrenParams {
  contentLink?: string;
  enableWMSPrice?: string;
}
export const useGetAllChildren = ({ contentLink, enableWMSPrice }: GetAllChildrenParams) => {
  const [getChildren] = useLazyGetChildrenQuery();
  const [children, setChildren] = useState<IContent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const query = useProductContentQuery(enableWMSPrice);

  const getAllChildren = useCallback(
    async (token?: string) => {
      if (!contentLink) return;
      setIsLoading(true);
      const { data } = await getChildren({
        contentLink,
        query: !token ? query : { ...query, continuationToken: token },
        headers: token
          ? {
              'x-epi-continuation': token
            }
          : undefined
      });

      let allChildren = data?.children ?? [];

      if (data?.meta?.['x-epi-continuation']) {
        const nextChildren = await getAllChildren(data?.meta?.['x-epi-continuation']);
        allChildren = [...allChildren, ...(nextChildren ?? [])];
      }

      setIsLoading(false);
      setIsSuccess(true);
      return allChildren;
    },
    [contentLink, getChildren, query]
  );

  useEffect(() => {
    getAllChildren()
      .then(result => {
        if (result) {
          setChildren(result);
        }
      })
      .catch(console.error);
  }, [getAllChildren]);

  return { children, isLoading, isSuccess };
};
