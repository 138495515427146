import React, { ReactNode, useMemo, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import Icon from './Icon';
import classnames from 'classnames';
import Divider from './Divider';
import Text from '@/components/cms/Text';
import RichText from '../cms/RichText';
import Button from '@/components/ui/Buttons/Button';
import Pagination from './Pagination';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Shimmer from './Shimmer';
import Tag from './Tag';
import { transformIfPLIUrl } from '@/utils/helpers';

export interface IBannerContentProps extends React.HTMLAttributes<HTMLDivElement> {
  bannerType: 'primary' | 'secondary' | 'tertiary';
  icon?: JSX.Element | string | null;
  newTagText?: string | null;
  headingText?: string;
  body: ReactNode;
  onSeeMore?: React.MouseEventHandler<HTMLButtonElement>;
  showSeeMore?: boolean;
  isExpanded?: boolean;
}

const BannerContent: React.FC<IBannerContentProps> = ({
  bannerType,
  icon,
  headingText: title,
  newTagText,
  body,
  onSeeMore,
  showSeeMore,
  className,
  isExpanded = false
}) => {
  const isAboveSm = useMediaQuery(ScreenSizeQueries.sm);
  const ref = useRef<HTMLDivElement>(null);
  const isPrimaryBanner = useMemo(() => bannerType === 'primary', [bannerType]);
  const lineClampAmount = useMemo(() => {
    if (!showSeeMore) {
      return isAboveSm ? 9 : 16;
    }

    return isAboveSm ? 5 : 9;
  }, [isAboveSm, showSeeMore]);

  const [isClamped, setIsClamped] = useState<boolean>(showSeeMore ?? false);

  const renderedIcon = useMemo(() => {
    if (newTagText) {
      return (
        <div className="mr-2">
          <Tag variant="dark" size="small">
            {newTagText}
          </Tag>
        </div>
      );
    }

    if (!icon) {
      return null;
    }

    if (typeof icon === 'string') {
      return <Icon name={icon} className="mr-2" />;
    }

    return icon;
  }, [icon, newTagText]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    setIsClamped(ref.current.scrollHeight > ref.current.clientHeight);
  }, [body]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    // Make sure anchor tags that are overflowed are not focusable
    const anchors = ref.current.querySelectorAll('a');
    anchors.forEach(a => {
      const container = a.closest('div');
      if (container && a.offsetTop > container.clientHeight) {
        a.tabIndex = -1;
      }
    });
  }, [isClamped]);

  return (
    <div className={className}>
      <Text
        element="h5"
        propertyName="Banner"
        className={classnames('mb-4 flex items-center', {
          'heading-6-medium': isPrimaryBanner,
          'text-1 font-medium': !isPrimaryBanner
        })}
      >
        {renderedIcon}
        {parse(title || '')}
      </Text>
      {typeof body === 'string' ? (
        <>
          <RichText
            ref={ref}
            content={body}
            style={{
              display: isExpanded ? 'block' : '-webkit-box',
              WebkitBoxOrient: 'vertical' as const,
              WebkitLineClamp: isExpanded ? 'none' : lineClampAmount,
              overflow: isExpanded ? 'auto' : 'hidden'
            }}
            className={classnames('text-1 [&_a]:underline [&_ol]:my-1 [&_p]:my-3 [&_ul]:my-1', {
              'text-gray-light': isPrimaryBanner,
              'text-gray-dark': !isPrimaryBanner
            })}
          />
          {isClamped && (
            <button
              className="text-2-bold mt-4 inline-flex items-center hover:text-red-brand"
              onClick={onSeeMore}
            >
              {isExpanded ? 'See less' : 'See more'}
              <Icon size="medium" name={isExpanded ? 'chevron-up' : 'chevron-down'} />
            </button>
          )}
        </>
      ) : (
        body
      )}
    </div>
  );
};

export interface BannerProps {
  bannerType: 'primary' | 'secondary' | 'tertiary';
  contents: IBannerContentProps[];
  primaryButtonWrapper?: (button: JSX.Element) => JSX.Element;
  primaryButtonLabel?: string;
  primaryButtonIcon?: string;
  primaryButtonHref?: string;
  primaryButtonTarget?: string;
  contentClassNames?: string;
  bannerContainerClassNames?: string;
  isLoadingData?: boolean;
  isClamped?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  bannerType = 'primary',
  contents,
  primaryButtonWrapper = button => button,
  primaryButtonLabel,
  primaryButtonIcon,
  primaryButtonHref,
  primaryButtonTarget,
  contentClassNames,
  bannerContainerClassNames,
  isLoadingData,
  isClamped
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [currentPage]);

  const isPrimaryBanner = bannerType === 'primary';
  const bannerClasses = classnames(
    'flex flex-wrap py-6 md:py-8 lg:py-12',
    bannerContainerClassNames,
    { 'md:flex-nowrap': isPrimaryBanner }
  );

  const hasPagination = contents?.length > 1;

  const contentClasses = classnames('w-full min-w-px', contentClassNames, {
    'md:basis-3/5 lg:basis-3/4': hasPagination || isPrimaryBanner,
    'rounded-lg bg-gray-lightest p-6 md:p-8': bannerType === 'secondary',
    'bg-gray-darker p-6 text-white max-md:rounded-t md:rounded-l md:px-10 md:py-9': isPrimaryBanner,
    'h-72 sm:h-48': isClamped && !isExpanded,
    'h-auto': !isClamped || isExpanded
  });

  const secondaryContentClasses = classnames('flex basis-full justify-start md:justify-end', {
    'mt-6 md:mt-0 md:basis-2/5 lg:basis-1/4': hasPagination,
    'w-full bg-gray-darker p-6 text-white max-md:rounded-b md:basis-2/5 md:rounded-r md:px-10 md:py-9 lg:basis-1/4':
      isPrimaryBanner
  });

  const bannerShimmer = useMemo(() => {
    return (
      <div className="justify-between py-6 md:py-8">
        {bannerType === 'tertiary' && <Divider color="light" className="mb-6" />}
        <div
          className={`rounded-lg bg-gray-lightest p-6 md:p-8 ${hasPagination ? 'md:!basis-4/5' : ''}`}
        >
          <Shimmer className="mb-4 size-full h-[24px] max-w-full bg-gray-light sm:max-w-[40%]" />
          <Shimmer className="mb-3 size-full h-[22px] max-w-full bg-gray-light sm:max-w-[80%]" />
          <Shimmer className="mb-3 size-full h-[22px] max-w-full bg-gray-light sm:max-w-[85%]" />
          <Shimmer className="mb-3 size-full h-[22px] max-w-full bg-gray-light sm:max-w-[83%]" />
          <Shimmer className="mb-3 size-full h-[22px] max-w-full bg-gray-light sm:max-w-[83%]" />
          <Shimmer className="mb-3 size-full h-[22px] max-w-full bg-gray-light sm:max-w-[78%]" />
        </div>
        {hasPagination && (
          <div className="flex w-[140px] items-center justify-between self-start py-1">
            <Shimmer className="size-[32px] rounded-full bg-gray-light" />
            <Shimmer className="h-[20px] w-[40px] bg-gray-light" />
            <Shimmer className="size-[32px] rounded-full bg-gray-light" />
          </div>
        )}
      </div>
    );
  }, [bannerType, hasPagination]);

  const bannerContent: IBannerContentProps | undefined = useMemo(() => {
    if (!contents?.length) return;
    if (currentPage >= contents?.length) setCurrentPage(contents?.length - 1);

    return contents[currentPage];
  }, [contents, currentPage]);

  const isSingleContent = contents?.length == 1;

  return (
    <>
      {isLoadingData || !bannerContent ? (
        bannerShimmer
      ) : (
        <div className={bannerClasses}>
          {bannerType === 'tertiary' && <Divider color="light" className="mb-6" />}
          <BannerContent
            bannerType={bannerType}
            className={contentClasses}
            icon={bannerContent.icon}
            headingText={bannerContent.headingText}
            body={bannerContent.body}
            onSeeMore={() => setIsExpanded(!isExpanded)}
            newTagText={bannerContent.newTagText}
            showSeeMore={!isSingleContent || isClamped}
            isExpanded={isExpanded}
          />

          {(hasPagination || isPrimaryBanner) && (
            <div className={secondaryContentClasses}>
              {isPrimaryBanner ? (
                primaryButtonWrapper(
                  <Button
                    color="outline-white"
                    label={primaryButtonLabel}
                    iconRight={primaryButtonIcon}
                    target={primaryButtonTarget}
                    className="flex min-h-10 max-w-fit basis-full px-6 py-2.5 md:basis-auto"
                    // A workaround to deal with the fact the button component will render as a link when href is in the prop list
                    {...(primaryButtonHref && { href: transformIfPLIUrl(primaryButtonHref) })}
                  />
                )
              ) : (
                <div>
                  <Pagination
                    color="dark"
                    count={currentPage + 1}
                    totalCount={contents?.length}
                    onClickNext={() => setCurrentPage(currentPage + 1)}
                    onClickPrev={() => setCurrentPage(currentPage - 1)}
                    nextDisabled={currentPage === contents?.length - 1}
                    prevDisabled={currentPage === 0}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Banner;
