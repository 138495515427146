import { useCallback, useContext, useMemo } from 'react';
import { format } from 'date-fns';
import Button from '@/components/ui/Buttons/Button';
import { CreditTrackerContext } from '@/components/blocks/CreditTracker/CreditTrackerContext';
import { ICreditRequirement } from '@/@types/client-api/models/ICreditRequirement';
import {
  filterHiddenRequirements,
  formatUrl,
  getCreditTypeKeys,
  getFormatsForRequirement,
  getTemporalDates
} from '@/components/ui/CreditTracker/util/TrackerHelpers';

export interface IEmailTemplateProps {
  complianceDeadline?: string;
  emailAddress?: string;
  creditRegionName?: string;
  creditRegionShortDescription?: string;
  totalCreditsNeeded?: number;
}

const CHAR_LIMIT = 1000;

const EmailTemplate: React.FC<IEmailTemplateProps> = props => {
  const {
    complianceDeadline,
    emailAddress,
    creditRegionName,
    creditRegionShortDescription,
    totalCreditsNeeded = 0
  } = props;

  const {
    temporalRequirements = [],
    requirementsWithoutTemporal: requirements = [],
    isMissingCompliancePeriod,
    contentLink,
    adminDisplayName
  } = useContext(CreditTrackerContext);

  const hasTemporalReqs = useMemo(() => temporalRequirements?.length > 0, [temporalRequirements]);

  const openingText = `Hello${adminDisplayName ? ' ' + adminDisplayName : ''}, \r\n\r\nThis is a friendly reminder that you have ${totalCreditsNeeded > 0 ? 'not yet ' : ''}met the CLE credit requirements for ${creditRegionName}.`;
  const closingText = 'Let me know if you have any questions. Thank you!';

  const creditTrackerBaseUrl = useMemo(() => contentLink?.url, [contentLink]);

  const createRegionQueryString = (): string => {
    const query = new URLSearchParams();
    if (creditRegionShortDescription) {
      query.append('region', creditRegionShortDescription);
    }
    return `${formatUrl(`${creditTrackerBaseUrl}?${query?.toString()}`)}`;
  };

  const creditsNeededForRegion = `You need ${totalCreditsNeeded} ${creditRegionName} credits by ${complianceDeadline}. Click here for programs: ${createRegionQueryString()}${hasTemporalReqs ? '' : '\r\n'}`;

  const createSearchQuery = useCallback(
    (creditRegionShortName: string, requirement?: ICreditRequirement) => {
      const creditTypesTest = getCreditTypeKeys(requirement ? [requirement] : []);
      const requirementKeys = Object.entries(creditTypesTest)
        .map(([_key, value]) => value.requirementKey.key)
        .join(';');
      const programFormats = requirement ? getFormatsForRequirement(requirement).join(';') : '';
      const queryParams = new URLSearchParams();

      if (requirementKeys) {
        queryParams.append('creditTypes', requirementKeys);
      }
      queryParams.append('region', creditRegionShortName);
      if (programFormats) {
        queryParams.append('programFormats', programFormats);
      }
      return queryParams.toString();
    },
    []
  );

  const requirementHasSubRequirements = useCallback(
    (req: ICreditRequirement) => !!req?.subRequirements && req.subRequirements.length > 0,
    []
  );

  const requirementHasHeading = useCallback(
    (req: ICreditRequirement) => !!req?.heading && req?.heading?.length > 0,
    []
  );

  const formatTemporalDate = useCallback((sideNote?: string | null): string | null | undefined => {
    if (!sideNote) return null;

    const _args = getTemporalDates(sideNote) ?? [];

    return format(_args[_args.length - 1], 'MMM d, yyyy');
  }, []);

  const getCreditsNeededByCreditType = (req: ICreditRequirement): string | null | undefined => {
    const temporalEndDate = req.sideNote && formatTemporalDate(req.sideNote);
    const creditsNeeded = (req.required ?? 0) - (req.counted ?? 0);
    const earnMoreSearchRecord = creditRegionShortDescription
      ? createSearchQuery(creditRegionShortDescription, req)
      : {};
    const earnMoreLink = formatUrl(`${creditTrackerBaseUrl}?${earnMoreSearchRecord}`);
    const endDateString = hasTemporalReqs ? '' : ` by ${complianceDeadline}`;
    const creditsNeededText =
      temporalEndDate && creditsNeeded > 0 && req.heading?.includes('Year')
        ? `You need ${creditsNeeded} ${req.heading.replace(/:/g, '')} credit${creditsNeeded > 1 ? 's' : ''} by ${temporalEndDate}:\r\n`
        : `  \u2022 You need ${creditsNeeded} ${req.heading} credit${creditsNeeded > 1 ? 's' : ''}${endDateString}. Click here for programs: ${earnMoreLink}\r\n`;
    return requirementHasHeading(req) && creditRegionShortDescription
      ? creditsNeededText
      : undefined;
  };

  const iterateCreditTypes = (reqs?: ICreditRequirement[] | null): string[] => {
    if (!reqs) return [];
    reqs = filterHiddenRequirements(reqs) ?? [];
    const res: string[] = [];
    for (const req of reqs) {
      if (requirementHasSubRequirements(req) && !requirementHasHeading(req)) {
        res.push(...iterateCreditTypes(req.subRequirements));
      }
      const needCredits = (req.required ?? 0) > (req.counted ?? 0);
      const newType = needCredits && getCreditsNeededByCreditType(req);
      if (newType) res.push(newType);
    }
    return res;
  };

  const createEmailBodyText = (): string => {
    const temporalRequirementsText = temporalRequirements
      .map(req => {
        const subRequirementsText = req.subRequirements
          ?.map(subReq => getCreditsNeededByCreditType(subReq))
          .join('\r\n');
        return `${getCreditsNeededByCreditType(req)}\r\n${subRequirementsText}`;
      })
      .join('\r\n');
    const creditTypesText = iterateCreditTypes(requirements).join('\r\n');
    const bodyText =
      totalCreditsNeeded > 0
        ? `${totalCreditsNeeded > 0 ? creditsNeededForRegion : ''}\r\n${creditTypesText}\r\n${temporalRequirementsText ?? ''}`
        : '';
    if (bodyText.length > CHAR_LIMIT) {
      const truncatedBodyText =
        bodyText.substring(0, CHAR_LIMIT) +
        `\r\n\n Additional Requirements Clipped, See more on on PLI's Credit Tracker ${creditTrackerBaseUrl}`;
      return `${openingText}\r\n\n${truncatedBodyText}\r\n\n${closingText}`;
    } else {
      return `${openingText}\r\n\n${bodyText}${closingText}`;
    }
  };

  const mailToMember = `mailto:${emailAddress}?subject=CLE%20Credit%20Compliance%20Reminder%20for%20${creditRegionName}&body=${encodeURIComponent(createEmailBodyText())}`;

  return (
    <div className="">
      <Button
        size="large"
        label="Email compliance reminder"
        color="outline-black"
        className="mb-2 ml-5 w-full justify-center px-5 !font-bold *:max-w-full sm:mb-0 sm:w-auto"
        onClick={() => (window.location.href = mailToMember)}
        disabled={!emailAddress || isMissingCompliancePeriod}
      />
    </div>
  );
};

export default EmailTemplate;
