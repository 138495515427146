import classnames from 'classnames';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import { appendWebpFormat } from '@/lib/helpers/images';
import ResponsiveImage, { ResponsiveImageProps } from '../ui/ResponsiveImage';

interface ImageProps extends React.HTMLProps<HTMLImageElement> {
  propertyName?: string;
  onLoad?: () => void;
  responsive?: ResponsiveImageProps;
}

const Image: React.FC<ImageProps> = ({ propertyName, className, ...props }) => {
  const isEditMode = isEditOrPreviewMode();
  const conditionalProps =
    isEditMode && propertyName
      ? { ...props, 'data-epi-edit': propertyName, src: appendWebpFormat(props.src) }
      : { ...props, src: props.responsive ? props.src : appendWebpFormat(props.src) };

  if (!conditionalProps.src) {
    if (isEditMode) {
      return <div {...conditionalProps} className={classnames(className, 'relative')} />;
    }

    return null;
  }

  if (props.responsive) {
    return (
      <ResponsiveImage
        {...conditionalProps}
        propertyName={propertyName}
        className={classnames(className, 'relative')}
        aspectRatio={props.responsive?.aspectRatio}
        imageSizes={props.responsive?.imageSizes}
        skipWrapper={props.responsive?.skipWrapper}
        loading={props.responsive?.loading}
      />
    );
  } else {
    return <img {...conditionalProps} className={classnames(className, 'relative')} />;
  }
};
export default Image;
