import { ICertificateViewModel } from '@/@types/client-api';
import { useCallback, useState } from 'react';
import LoadingDots from '@/components/ui/Buttons/LoadingDots';
import Icon from '@/components/ui/Icon';
import Shimmer from '../Shimmer';

type DownloadAllCertificatesLinkProps = {
  isLoading?: boolean;
  pageNumber: number;
  certificates: ICertificateViewModel[];
  onDownload: (certificates: ICertificateViewModel[], fileName?: string) => Promise<void>;
};

const DownloadAllCertificatesLink = ({
  isLoading = false,
  pageNumber,
  certificates,
  onDownload
}: DownloadAllCertificatesLinkProps): JSX.Element => {
  const [isProcessing, setProcessing] = useState(false);

  const handleOnClick = useCallback(async () => {
    if (!certificates.length) {
      return;
    }
    try {
      setProcessing(true);
      await onDownload(certificates, `certificates-page-${pageNumber}.zip`);
    } finally {
      setProcessing(false);
    }
  }, [certificates, onDownload, pageNumber]);

  if (isLoading) {
    return <Shimmer className="h-6 w-[92px]" />;
  }

  if (isProcessing) {
    return <LoadingDots color="black" dotSize="small" />;
  }

  return (
    <a
    data-component={"DownloadAllCertificatesLink"}
      className="flex h-6 cursor-pointer items-center gap-1 text-black hover:text-red"
      onClick={handleOnClick}
    >
      Download
      <Icon name="download" size="small" />
    </a>
  );
};

export default DownloadAllCertificatesLink;
