import { useCallback, useEffect, useMemo, useState } from 'react';
import { MultiStepModalBlock as MultiStepModalBlockProps, StepBlock } from '@/@types/content';
import Modal from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';
import useToast from '@/hooks/useToast';
import AddLicenseStep from './AddLicenseStep';
import PracticeAreaStep from './PracticeAreaStep';
import RolesStep from './RolesStep';

type ExtendedMultiStepModalBlockProps = MultiStepModalBlockProps & {
  open?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MultiStepModalBlock: React.FC<ExtendedMultiStepModalBlockProps> = ({
  open = false,
  setOpen,
  successMessage,
  failMessage,
  stepsAndOptions
}) => {
  const { showSuccessMessage, showFailMessage } = useToast();
  const [currentStep, setCurrentStep] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStepSkipping, setIsStepSkipping] = useState(false);
  const totalSteps = stepsAndOptions?.length || 0;

  const onStepIncrease = useCallback(() => {
    setIsSubmitting(false);
    if (currentStep === totalSteps - 1) {
      try {
        setOpen(false);
        showSuccessMessage(successMessage!);
        window.setTimeout(() => {
          document.location.reload();
        }, 1000);
      } catch (error) {
        showFailMessage(failMessage!);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [
    currentStep,
    failMessage,
    setOpen,
    showFailMessage,
    showSuccessMessage,
    successMessage,
    totalSteps,
    setIsSubmitting
  ]);

  useEffect(() => {
    open && setCurrentStep(0);
  }, [open]);

  const onStepBack = () => {
    if (currentStep > 0) {
      const stepsBack =
        isStepSkipping && steps[currentStep - 1].stepType === 'SpecificPracticeAreasStep' ? 2 : 1;
      setCurrentStep(currentStep - stepsBack);
    }
  };

  const steps = useMemo(() => (stepsAndOptions as StepBlock[]) || [], [stepsAndOptions]);

  const onStepSubmit = useCallback(() => {
    if (!isSubmitting) {
      setIsSubmitting(true);
    }
  }, [isSubmitting, setIsSubmitting]);

  const stepContent = useMemo(() => {
    if (!steps) return null;
    const type = steps[currentStep].stepType;

    switch (steps[currentStep].stepType) {
      case 'AddLicenseStep':
        return (
          <AddLicenseStep
            setIsProcessing={setIsProcessing}
            setIsSubmitting={setIsSubmitting}
            onStepIncrease={onStepIncrease}
            isSubmitting={isSubmitting}
          />
        );
      case 'PracticeAreasStep':
      case 'SpecificPracticeAreasStep':
        return (
          <PracticeAreaStep
            setIsProcessing={setIsProcessing}
            setIsStepSkipping={setIsStepSkipping}
            onStepIncrease={onStepIncrease}
            type={type === 'PracticeAreasStep' ? 'parents' : 'children'}
            isSubmitting={isSubmitting}
          />
        );
      case 'RolesStep':
        return (
          <RolesStep
            setIsProcessing={setIsProcessing}
            onStepIncrease={onStepIncrease}
            isSubmitting={isSubmitting}
          />
        );
      default:
        break;
    }
  }, [setIsProcessing, onStepIncrease, currentStep, steps, isSubmitting]);

  if (!stepsAndOptions) return null;

  return (
    <Modal open={open} setOpen={setOpen}>
      <ModalContent
        eyebrow={{ text: `${currentStep + 1} of ${totalSteps}` }}
        title={steps[currentStep].stepHeading}
        subtitle={steps[currentStep].stepSubHeading}
        saveButtonLabel={currentStep === totalSteps - 1 ? 'Save and finish' : 'Save and continue'}
        dismissButtonLabel={'Skip'}
        onSaveButtonPress={onStepSubmit}
        onDismissButtonPress={onStepIncrease}
        onBackButtonPress={currentStep ? onStepBack : undefined}
        isMultiStep={true}
        shouldDismissButtonClose={false}
        isSubmitLoading={isProcessing}
      >
        <div
          className={
            steps[currentStep].stepType != 'AddLicenseStep' &&
            steps[currentStep].stepType != 'SpecificPracticeAreasStep'
              ? 'grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2'
              : ''
          }
        >
          {stepContent}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default MultiStepModalBlock;
