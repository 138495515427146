import type { RequestAQuoteModalBlock as RequestAQuoteModalBlockProps } from '@/@types/content';
import Panel from '@/components/ui/Panel';
import classnames from 'classnames';
import Button from '@/components/ui/Buttons/Button';
import * as Form from '@radix-ui/react-form';
import useToast from '@/hooks/useToast';
import { useState, memo, createRef, useMemo } from 'react';
import { v4 as uuid4 } from 'uuid';
import SelectorLarge from '@/components/ui/Form/SelectorLarge';
import TextInput from '@/components/ui/Form/TextInput';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import RichText from '../cms/RichText';
import DropdownSelect from '@/components/ui/DropdownSelect';
import DropdownButton from '@/components/ui/Buttons/DropdownButton';
import DateInput from '@/components/ui/Form/DateInput';
import { trackFormSubmitComplete } from '@/analytics/form';
import { FormContext } from '@/analytics/constants';

type BlockType = RequestAQuoteModalBlockProps & {
  triggerButton: React.ReactNode;
};

type FieldType = {
  fieldDescription?: string;
  fieldLabel?: string;
  fieldName?: string;
  fieldType?: string;
  isRequired?: boolean;
  placeholder?: string;
  rentalValue?: string;
  fieldOptions?: string[];
};

type StepType = {
  rows: {
    rowFields: FieldType[];
  }[];
};

const FormStep = memo(
  ({
    step,
    index,
    formId,
    customData,
    setCustomData,
    dropdownError
  }: {
    step: StepType;
    index: number;
    formId: string;
    customData: Record<string, unknown>;
    setCustomData: (data: Record<string, unknown>) => void;
    dropdownError: boolean;
  }) => {
    return (
      <div
        className={classnames('flex flex-col', {
          'gap-4': index === 0,
          'gap-12': index !== 0
        })}
      >
        {step?.rows?.map(({ rowFields }: { rowFields: FieldType[] }, indexRow: number) => (
          <div
            key={indexRow}
            className={classnames('flex flex-col', 'sm:flex-row sm:gap-6', {
              'gap-6': index === 0,
              'gap-12': index !== 0
            })}
          >
            {rowFields?.map(
              (
                {
                  fieldDescription,
                  fieldLabel,
                  fieldName,
                  fieldType,
                  isRequired,
                  placeholder,
                  rentalValue,
                  fieldOptions
                },
                indexField: number
              ) => {
                switch (fieldType) {
                  case 'checkbox':
                  case 'rental':
                    return (
                      <div className="relative w-full" key={indexField}>
                        <SelectorLarge
                          header={fieldLabel}
                          copy={fieldDescription}
                          asFormField={true}
                          name={fieldName}
                          required={isRequired}
                          rentalValue={rentalValue}
                        />
                      </div>
                    );
                  case 'text':
                  case 'email':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type={fieldType}
                        />
                      </div>
                    );
                  case 'hidden':
                    return (
                      <div className="hidden w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type={fieldType}
                          value={placeholder}
                        />
                      </div>
                    );
                  case 'tel':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type="tel"
                          onChange={(value: string) => {
                            let numericValue = value.replace(/\D/g, '');

                            if (numericValue.length > 3 && numericValue.length <= 6) {
                              numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
                            } else if (numericValue.length > 6) {
                              numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
                            } else if (numericValue.length > 0) {
                              numericValue = `(${numericValue}`;
                            }

                            if (numericValue.length > 14) {
                              numericValue = numericValue.slice(0, 14);
                            }

                            const form = document.getElementById(formId);
                            const field = form?.querySelector(`[name="${fieldName}"]`);

                            if (!field) return;

                            if (numericValue.length <= 14) {
                              (field as HTMLInputElement).value = numericValue;
                            }
                          }}
                        />
                      </div>
                    );
                  case 'textArea':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type="text"
                        />
                      </div>
                    );
                  case 'dropdown': {
                    const options = fieldOptions?.map(option => ({
                      disabled: false,
                      label: option,
                      value: option
                    })) as { disabled: boolean; label: string; value: string }[];

                    const value = customData[`${formId}-${fieldName}`] as string | undefined;

                    return (
                      <div className="w-full" key={indexField}>
                        <DropdownSelect
                          name={fieldName}
                          button={({ isOpen, value: selectedValue, ...props }) => (
                            <DropdownButton
                              state={isOpen ? 'open' : 'closed'}
                              label={selectedValue}
                              dropdownError={{
                                showError: dropdownError && !selectedValue && isRequired
                              }}
                              {...props}
                            />
                          )}
                          options={options}
                          label={fieldLabel || fieldName}
                          placeholder={placeholder}
                          required={isRequired}
                          menuStyle="form-field"
                          onValueChange={(newVal: string) => {
                            setCustomData({ ...customData, [`${formId}-${fieldName}`]: newVal });
                          }}
                          value={value || ''}
                        />
                      </div>
                    );
                  }
                  case 'date':
                    return (
                      <div className="w-full" key={indexField}>
                        <DateInput
                          label={fieldLabel || ''}
                          name={fieldName || ''}
                          id={`${formId}-${fieldName}`}
                          required={isRequired}
                          onChange={(value: string) => {
                            setCustomData({ ...customData, [`${formId}-${fieldName}`]: value });
                          }}
                          value={(customData[`${formId}-${fieldName}`] as string) || ''}
                          minDate={new Date()}
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              }
            )}
          </div>
        ))}
      </div>
    );
  }
);

const RequestAQuoteModalBlock: React.FC<BlockType> = ({
  successMessage,
  failMessage,
  triggerButton,
  steps = [],
  name,
  contentLink,
  blockNameForEventLogging
}) => {
  const { showSuccessMessage, showFailMessage } = useToast();

  const [stepNumber, setStepNumber] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [forceClose, setForceClose] = useState(false);
  const [allFormsData, setAllFormsData] = useState<Record<string, unknown>>({});
  const [showStepErrorMessage, setShowStepErrorMesages] = useState(false);
  const [customData, setCustomData] = useState<Record<string, unknown>>({});
  const [dropdownError, setDropdownError] = useState(false);

  const handleStepChange = (increment: number) => {
    const newStepNumber = stepNumber + increment;
    if (newStepNumber >= 0 && newStepNumber < steps.length) {
      setStepNumber(newStepNumber);
    }
  };

  const formIds = useMemo(() => steps.map(() => uuid4()), [steps]);
  const formRefs = useMemo(() => steps.map(() => createRef<HTMLFormElement>()), [steps]);
  const formName = useMemo(() => {
    return blockNameForEventLogging ?? `${name!} ${contentLink?.id}`;
  }, [blockNameForEventLogging, name, contentLink?.id]);

  const handleBack = () => handleStepChange(-1);

  const handleSubmitNext = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());

    if (Object.keys(data).length === 0) {
      setShowStepErrorMesages(true);
      return;
    }

    const formId = formIds[stepNumber];
    setAllFormsData(prev => ({ ...prev, [formId]: data }));

    setShowStepErrorMesages(false);
    handleStepChange(1);
  };

  const handleCustomInvalidField = () => {
    setDropdownError(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const action = event.currentTarget.action;
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());
    const formId = formIds[stepNumber];

    const mergedData = { ...allFormsData, [formId]: data };

    setAllFormsData(mergedData);

    const dataWithoutFormId = Object.entries(mergedData).reduce(
      (acc, [_key, value]) => {
        if (typeof value === 'object' && value !== null) {
          acc = { ...acc, ...value };
        }
        return acc;
      },
      {} as Record<string, string>
    );

    const currentStep = steps[stepNumber];
    let hasError = false;

    currentStep.rows.forEach((row: { rowFields: FieldType[] }) => {
      row.rowFields.forEach(field => {
        if (field.fieldType === 'dropdown' && field.isRequired) {
          const val = customData[`${formIds[stepNumber]}-${field.fieldName}`];
          if (!val) {
            hasError = true;
          }
        }
      });
    });

    if (hasError) {
      setDropdownError(true);
      event.preventDefault();
      return;
    } else {
      setDropdownError(false);
    }

    setIsSubmitting(true);

    try {
      await fetch(action, {
        method: 'POST',
        mode: 'no-cors',
        redirect: 'follow',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(
          Object.entries(dataWithoutFormId).reduce(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {} as Record<string, string>
          )
        ).toString()
      });

      setIsSuccess(true);
      showSuccessMessage(successMessage);
      setTimeout(() => {
        setForceClose(true);
      }, 1000);
      trackFormSubmitComplete({
        formName: formName,
        context: FormContext.RequestAQuote
      });
    } catch (error) {
      showFailMessage(failMessage);
      console.error(error);
    }

    setIsSubmitting(false);
  };

  const resetStates = (open: boolean) => {
    if (open) {
      setForceClose(false);
      setStepNumber(0);
      setIsSubmitting(false);
      setIsSuccess(false);
      setDropdownError(false);
      setCustomData({});
    }
  };

  if (!steps || steps.length === 0) {
    return null;
  }

  return (
    <Panel theme="light" onOpenChange={resetStates} forceClose={forceClose}>
      <Panel.Trigger asChild>{triggerButton}</Panel.Trigger>

      <Panel.Content
        onBackButtonPress={stepNumber >= 1 ? handleBack : undefined}
        miniPaddingHeader={true}
      >
        <Panel.Body scrollClassName={classnames('lg:pt-6')}>
          <div className="flex flex-col gap-2">
            {steps[stepNumber]?.stepIndicator && (
              <small className="text-2 label text-gray-dark">
                {steps[stepNumber]?.stepIndicator}
              </small>
            )}
            {steps[stepNumber]?.heading && (
              <h2 className="heading-4">{steps[stepNumber]?.heading}</h2>
            )}
            {steps[stepNumber]?.subheading && (
              <span className="text-1 text-gray-dark">{steps[stepNumber]?.subheading}</span>
            )}
            {steps[stepNumber]?.copy && (
              <RichText content={steps[stepNumber]?.copy || ''} className="text-1 text-gray-dark" />
            )}
          </div>

          <div className={classnames('my-10', 'lg:my-8')}>
            {steps.map((step: StepType, index: number) => (
              <Form.Root
                onSubmit={steps[stepNumber]?.submitButton ? handleSubmit : handleSubmitNext}
                id={formIds[index]}
                action={steps[stepNumber]?.submitButton?.href || undefined}
                key={index}
                className={classnames({ hidden: index !== stepNumber })}
                ref={formRefs[index]}
                onInvalid={handleCustomInvalidField}
              >
                <FormStep
                  step={step}
                  index={index}
                  formId={formIds[index]}
                  customData={customData}
                  setCustomData={setCustomData}
                  dropdownError={dropdownError}
                />
              </Form.Root>
            ))}
          </div>
        </Panel.Body>

        <Panel.Footer divider="line">
          {showStepErrorMessage && steps[stepNumber]?.stepErrorMessage ? (
            <div className="label relative flex items-center gap-1 text-red">
              <Icon name="alert" size="small" />
              <Text element="span">{steps[stepNumber]?.stepErrorMessage}</Text>
            </div>
          ) : null}

          <div className="flex flex-row justify-end gap-6">
            {steps[stepNumber]?.nextButton && (
              <Button
                form={formIds[stepNumber]}
                type="submit"
                label={steps[stepNumber]?.nextButton?.text}
                size="large"
                color="black"
              />
            )}
            {steps[stepNumber]?.submitButton && (
              <Button
                form={formIds[stepNumber]}
                type="submit"
                label={isSuccess ? undefined : steps[stepNumber]?.submitButton?.text}
                size="large"
                color={isSuccess ? 'green' : 'black'}
                iconRight={isSuccess ? 'check' : undefined}
                loading={isSubmitting}
                className={classnames('min-w-28 !justify-center', {
                  'pointer-events-none': isSubmitting || isSuccess
                })}
              />
            )}
          </div>
        </Panel.Footer>
      </Panel.Content>
    </Panel>
  );
};

export default RequestAQuoteModalBlock;
