import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';
import CreditLicenseCard from '@/components/ui/PDP/ProgramCredit/CreditLicenseCard';
import type { CreditLicenseCardProps } from '@/components/ui/PDP/ProgramCredit/CreditLicenseCard';

type ViewCreditsForYourLicensesProps = ModalProps & {
  modalHeader?: string;
  code: string;
  credits?: CreditLicenseCardProps[];
  focusedCreditRegionSk?: number;
  seeMoreLicenseModalId?: string;
};

const ViewCreditsForYourLicenses: React.FC<ViewCreditsForYourLicensesProps> = ({
  modalHeader,
  code,
  credits,
  focusedCreditRegionSk,
  open,
  setOpen,
  seeMoreLicenseModalId
}) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <ModalContent title={modalHeader} customModalId={seeMoreLicenseModalId}>
        <div className="flex flex-col gap-4">
          {credits!.map((credit: CreditLicenseCardProps) => {
            return (
              <CreditLicenseCard
                key={credit.regionSk}
                {...credit}
                seeMoreByDefault={credit.regionSk === focusedCreditRegionSk}
                code={code}
              />
            );
          })}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ViewCreditsForYourLicenses;
