import { useCallback, useEffect, useState } from 'react';
import EditPanel, { EditPanelProps } from '@/components/ui/Panels/EditPanel';
import Radio from '@/components/ui/Form/Radio';
import RadioButton from '@/components/ui/Form/Radio/RadioButton';

export type CertificatePreference = {
  key: number;
  text: string;
};

type CertificatePreferencesPanelProps = {
  selectedOption?: CertificatePreference;
  options: CertificatePreference[];
  onSave?: (selectedKey: string) => void;
  triggerButton: JSX.Element;
} & Pick<EditPanelProps, 'theme'>;

const CertificatePreferencesPanel: React.FC<CertificatePreferencesPanelProps> = ({
  theme,
  selectedOption,
  options,
  onSave,
  triggerButton
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      setSelectedKey(selectedOption?.key?.toString() ?? '');
    }
  }, [isOpen, selectedOption]);

  const handleOnSave = useCallback(() => {
    if (selectedKey) {
      setOpen(false);
      onSave?.(selectedKey);
    }
  }, [onSave, selectedKey]);

  return (
    <EditPanel
      title="Certificate preferences"
      isOpen={isOpen}
      onOpenChange={setOpen}
      theme={theme}
      onSave={handleOnSave}
      triggerButton={triggerButton}
    >
      <Radio asFormField value={selectedKey} onValueChange={setSelectedKey}>
        <div className="flex flex-col gap-8">
          {options.map(option => (
            <RadioButton
              value={option.key.toString() ?? ''}
              checked={option.key.toString() === selectedKey}
              key={option.key}
              aria-label={option.text}
            >
              {option.text}
            </RadioButton>
          ))}
        </div>
      </Radio>
    </EditPanel>
  );
};

export default CertificatePreferencesPanel;
