import { useState, memo, useMemo, createRef } from 'react';
import { CTAIconSubBlock as CTAIconSubBlockProps, ProBonoScholarshipModal } from '@/@types/content';
import RichText from '@/components/cms/RichText';
import Text from '@/components/cms/Text';
import Button from '@/components/ui/Buttons/Button';
import { IContent } from '@/@types/cms';
import Link from '@/components/ui/Link';
import RoundedIcon from '@/components/ui/RoundedIcon';
import useToast from '@/hooks/useToast';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import Panel from '@/components/ui/Panel';
import * as Form from '@radix-ui/react-form';
import SelectorLarge from '@/components/ui/Form/SelectorLarge';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import TextInput from '@/components/ui/Form/TextInput';
import Icon from '@/components/ui/Icon';
import { v4 as uuid4 } from 'uuid';
import classnames from 'classnames';
import DropdownSelect from '@/components/ui/DropdownSelect';
import DropdownButton from '@/components/ui/Buttons/DropdownButton';
import DateInput from '@/components/ui/Form/DateInput';
import TextArea from '@/components/ui/Form/TextArea';

type LinkButtonProps = Pick<CTAIconSubBlockProps, 'link1Style'>;

type FieldType = {
  fieldDescription?: string;
  fieldLabel?: string;
  fieldName?: string;
  fieldOptions?: string[];
  fieldType?: string;
  isRequired?: boolean;
  maxLength?: number;
  placeholder?: string;
};

type StepType = {
  rows: {
    rowFields: FieldType[];
  }[];
};

const FormStep = memo(
  ({
    step,
    index,
    formId,
    customData,
    setCustomData
  }: {
    step: StepType;
    index: number;
    formId: string;
    customData: Record<string, unknown>;
    setCustomData: (data: Record<string, unknown>) => void;
  }) => {
    return (
      <div
        className={classnames('flex flex-col', {
          'gap-[60px]': index === 1,
          'gap-9': index !== 1
        })}
      >
        {step?.rows?.map(({ rowFields }: { rowFields: FieldType[] }, indexRow: number) => (
          <div
            key={indexRow}
            className={classnames('flex flex-col gap-6', 'sm:flex-row sm:gap-6', {
              'gap-6': index === 0,
              'gap-12': index !== 0
            })}
          >
            {rowFields?.map(
              (
                {
                  fieldDescription,
                  fieldLabel,
                  fieldName,
                  fieldType,
                  isRequired,
                  placeholder,
                  maxLength,
                  fieldOptions
                },
                indexField: number
              ) => {
                switch (fieldType) {
                  case 'checkbox':
                    return (
                      <div className="relative w-full" key={indexField}>
                        <SelectorLarge
                          header={fieldLabel}
                          copy={fieldDescription}
                          asFormField={true}
                          name={fieldName}
                          required={isRequired}
                        />
                      </div>
                    );
                  case 'text':
                  case 'email':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type={fieldType}
                          maxLength={maxLength}
                        />
                      </div>
                    );
                  case 'hidden':
                    return (
                      <div className="hidden w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type={fieldType}
                          value={placeholder}
                        />
                      </div>
                    );
                  case 'tel':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type="tel"
                          maxLength={maxLength}
                          onChange={(value: string) => {
                            let numericValue = value.replace(/\D/g, '');

                            if (numericValue.length > 3 && numericValue.length <= 6) {
                              numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
                            } else if (numericValue.length > 6) {
                              numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
                            } else if (numericValue.length > 0) {
                              numericValue = `(${numericValue}`;
                            }

                            if (numericValue.length > 14) {
                              numericValue = numericValue.slice(0, 14);
                            }

                            const form = document.getElementById(formId);
                            const field = form?.querySelector(`[name="${fieldName}"]`);

                            if (!field) return;

                            if (numericValue.length <= 14) {
                              (field as HTMLInputElement).value = numericValue;
                            }
                          }}
                        />
                      </div>
                    );
                  case 'textArea':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextArea
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          maxLength={maxLength}
                        />
                      </div>
                    );
                  case 'dropdown': {
                    const options = fieldOptions?.map(option => ({
                      disabled: false,
                      label: option,
                      value: option
                    })) as { disabled: boolean; label: string; value: string }[];

                    return (
                      <div className="w-full" key={indexField}>
                        <DropdownSelect
                          name={fieldName}
                          button={({ isOpen, value, ...props }) => (
                            <DropdownButton
                              state={isOpen ? 'open' : 'closed'}
                              label={value}
                              {...props}
                            />
                          )}
                          options={options}
                          label={fieldLabel}
                          placeholder={placeholder}
                          required={isRequired}
                          menuStyle="form-field"
                          onValueChange={(value: string) => {
                            setCustomData({ ...customData, [`${formId}-${fieldName}`]: value });
                          }}
                          value={customData[`${formId}-${fieldName}`] as string}
                        />
                      </div>
                    );
                  }
                  case 'date':
                    return (
                      <div className="w-full" key={indexField}>
                        <DateInput
                          label={fieldLabel || ''}
                          name={fieldName || ''}
                          id={`${formId}-${fieldName}`}
                          required={isRequired}
                          onChange={(value: string) => {
                            setCustomData({ ...customData, [`${formId}-${fieldName}`]: value });
                          }}
                          value={(customData[`${formId}-${fieldName}`] as string) || ''}
                        />
                      </div>
                    );
                  case 'number':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type="text"
                          maxLength={maxLength}
                          onChange={(value: string) => {
                            const numericValue = value.replace(/\D/g, '');
                            const form = document.getElementById(formId);
                            const field = form?.querySelector(`[name="${fieldName}"]`);

                            if (!field || !maxLength) return;

                            if (numericValue.length <= maxLength) {
                              (field as HTMLInputElement).value = numericValue;
                            }
                          }}
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              }
            )}
          </div>
        ))}
      </div>
    );
  }
);

const CTAIconSubBlock: React.FC<
  CTAIconSubBlockProps & { layout: string; proBonoModal: Array<IContent> }
> = ({
  icon,
  heading,
  subheading,
  copy,
  link,
  link2,
  link1Style = 'primary',
  layout,
  proBonoModal
}) => {
  const proBonoData = proBonoModal?.at(0) as ProBonoScholarshipModal;
  const steps = useMemo(() => proBonoData?.steps || [], [proBonoData]);
  const successMessage = proBonoData?.successMessage;
  const failMessage = proBonoData?.failMessage;
  const editMode = isEditOrPreviewMode();
  const hasIcon = !!icon;

  const { showSuccessMessage, showFailMessage } = useToast();

  const sm = useMediaQuery(ScreenSizeQueries.sm);

  const [isOpen, setIsOpen] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [forceClose, setForceClose] = useState(false);
  const [allFormsData, setAllFormsData] = useState<Record<string, unknown>>({});
  const [showStepErrorMessage, setShowStepErrorMesages] = useState(false);
  const [customData, setCustomData] = useState({});

  const onChange = (value: boolean) => {
    setIsOpen(value);
  };

  const handleStepChange = (increment: number) => {
    const newStepNumber = stepNumber + increment;
    if (newStepNumber >= 0 && newStepNumber < steps.length) {
      setStepNumber(newStepNumber);
    }
  };
  const formIds = useMemo(() => steps?.map(() => uuid4()), [steps]);
  const formRefs = useMemo(() => steps?.map(() => createRef<HTMLFormElement>()), [steps]);
  const handleBack = () => handleStepChange(-1);

  const infoClasses = classnames('flex size-full flex-col flex-wrap p-8', {
    'gap-10': !hasIcon,
    'sm:p-8': hasIcon,
    'sm:p-12': !hasIcon,
    'sm:flex-row sm:items-center sm:justify-between sm:gap-0': hasIcon,
    'md:flex-col md:flex-nowrap md:items-start md:gap-0': hasIcon,
    'lg:justify-normal': hasIcon && !link2,
    'lg:p-12': hasIcon && layout !== '2-columns',
    'lg:p-[60px]': !hasIcon || layout === '2-columns'
  });

  const contentClasses = classnames('flex flex-col', {
    'gap-10': !hasIcon
  });

  const iconClasses = classnames('mb-8 w-16');

  const headClasses = classnames('flex flex-col gap-2', {
    'mb-6': hasIcon
  });

  const buttonClasses = classnames({
    'mt-8': hasIcon
  });

  const handleSubmitNext = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());

    if (Object.keys(data).length === 0 && steps[stepNumber]?.stepErrorMessage) {
      setShowStepErrorMesages(true);
      return;
    }

    const formId = formIds[stepNumber];
    setAllFormsData(prev => ({ ...prev, [formId]: data }));

    setShowStepErrorMesages(false);
    handleStepChange(1);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const action = event.currentTarget.action;
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());
    const formId = formIds[stepNumber];

    const mergedData = { ...allFormsData, [formId]: data };

    setAllFormsData(mergedData);

    const dataWithoutFormId = Object.entries(mergedData).reduce(
      (acc, [_key, value]) => {
        if (typeof value === 'object' && value !== null) {
          acc = { ...acc, ...value };
        }
        return acc;
      },
      {} as Record<string, string>
    );
    setIsSubmitting(true);

    try {
      await fetch(action, {
        method: 'POST',
        mode: 'no-cors',
        redirect: 'follow',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(
          Object.entries(dataWithoutFormId).reduce(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {} as Record<string, string>
          )
        ).toString()
      });

      setIsSuccess(true);
      showSuccessMessage(successMessage);
      setTimeout(() => {
        setForceClose(true);
        resetStates();
      }, 1000);
    } catch (error) {
      showFailMessage(failMessage);
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const resetStates = () => {
    onChange(!isOpen);
    if (isOpen) {
      setForceClose(false);
      setStepNumber(0);
      setIsSubmitting(false);
      setIsSuccess(false);
    }
  };

  const ButtonLink: React.FC<LinkButtonProps> = ({ link1Style }) => {
    const buttonProps = {
      ...(proBonoModal
        ? { onClick: () => setIsOpen(true) }
        : { href: link?.href, target: link?.target })
    };
    switch (link1Style) {
      case 'primary':
        return (
          <Button
            propertyName="Link"
            color="black"
            size="large"
            label={link?.text}
            title={link?.title}
            className={buttonClasses}
            {...buttonProps}
          />
        );
      case 'secondary':
        return (
          <Button
            propertyName="Link"
            color="outline-black"
            size="small"
            label={link?.text}
            title={link?.title}
            className={buttonClasses}
            {...buttonProps}
          />
        );
      default:
    }
  };
  return (
    <div className="size-full rounded-lg bg-gray-lightest">
      <div className={infoClasses}>
        <div className={contentClasses}>
          {icon && (
            <RoundedIcon color="red" className={iconClasses} icon={{ name: icon, size: 'large' }} />
          )}

          <div className={headClasses}>
            {heading || editMode ? (
              <Text propertyName="Heading" element={hasIcon ? 'h6' : 'h5'} className="heading-5">
                {heading}
              </Text>
            ) : null}

            {subheading || editMode ? (
              <Text propertyName="Subheading" element="h5" className="text-1 text-gray-dark">
                {subheading}
              </Text>
            ) : null}
          </div>

          {copy || editMode ? (
            <RichText
              className={classnames('[&>p]:m-0', {
                'rtf-tile-no-icon': !hasIcon,
                'rtf-tile-icon': hasIcon
              })}
              checkList={true}
              content={copy as string}
            />
          ) : null}
        </div>
        {link || editMode ? (
          <Panel isOpen={isOpen} onOpenChange={resetStates} forceClose={forceClose}>
            <Panel.Trigger className="" asChild>
              <div className="hidden sm:block lg:mt-auto lg:hidden">
                <ButtonLink link1Style={link1Style} />
              </div>
            </Panel.Trigger>
            <Panel.Content
              onBackButtonPress={stepNumber >= 1 ? handleBack : undefined}
              miniPaddingHeader={true}
            >
              <Panel.Body scrollClassName="">
                <div className="flex flex-col gap-4">
                  {steps?.[stepNumber]?.stepIndicator && (
                    <small
                      className={classnames('text-gray-dark', {
                        'text-2-fixed': !sm,
                        'text-2-fixed-medium': sm
                      })}
                    >
                      {steps[stepNumber]?.stepIndicator}
                    </small>
                  )}
                  {steps?.[stepNumber]?.heading && (
                    <h2 className="heading-4">{steps[stepNumber]?.heading}</h2>
                  )}
                  {steps?.[stepNumber]?.subheading && (
                    <span className="text-1 text-gray-dark">{steps[stepNumber]?.subheading}</span>
                  )}
                  {steps?.[stepNumber]?.copy && (
                    <RichText
                      content={steps[stepNumber]?.copy}
                      propertyName="Copy"
                      className="text-1 mt-4 text-gray-dark"
                    />
                  )}
                </div>

                <div className={classnames('my-10', 'lg:my-8')}>
                  {steps?.map((step: StepType, index: number) => {
                    return (
                      <Form.Root
                        key={index}
                        onSubmit={steps[stepNumber]?.submitButton ? handleSubmit : handleSubmitNext}
                        id={formIds[index]}
                        action={steps[stepNumber]?.submitButton?.href || undefined}
                        className={classnames({ hidden: index !== stepNumber })}
                        ref={formRefs[index]}
                      >
                        <FormStep
                          step={step}
                          index={index}
                          formId={formIds[index]}
                          customData={customData}
                          setCustomData={setCustomData}
                        />
                      </Form.Root>
                    );
                  })}
                </div>
              </Panel.Body>
              <Panel.Footer divider="gradient">
                {showStepErrorMessage && steps[stepNumber]?.stepErrorMessage ? (
                  <div className="label relative flex items-center gap-1 text-red">
                    <Icon name="alert" size="small" />
                    <Text element="span">{steps[stepNumber]?.stepErrorMessage}</Text>
                  </div>
                ) : null}

                <div className="flex flex-row justify-end gap-6">
                  {steps?.[stepNumber]?.nextButton && (
                    <Button
                      form={formIds[stepNumber]}
                      type="submit"
                      label={steps[stepNumber]?.nextButton?.text}
                      size="large"
                      color="black"
                    />
                  )}
                  {steps?.[stepNumber]?.submitButton && (
                    <Button
                      form={formIds[stepNumber]}
                      type="submit"
                      label={isSuccess ? undefined : steps[stepNumber]?.submitButton?.text}
                      size="large"
                      color={isSuccess ? 'green' : 'black'}
                      iconRight={isSuccess ? 'check' : undefined}
                      loading={isSubmitting}
                      className={classnames('min-w-28 !justify-center', {
                        'pointer-events-none': isSubmitting || isSuccess
                      })}
                    />
                  )}
                </div>
              </Panel.Footer>
            </Panel.Content>
          </Panel>
        ) : null}
        {link2 || editMode ? (
          <Link
            href={link2?.href}
            target={link2?.target}
            text={link2?.text}
            variant="text-link-1"
            propertyName="Link2"
            className={classnames(
              'hidden w-fit basis-full text-gray-darker sm:block lg:hidden lg:basis-auto',
              buttonClasses
            )}
          />
        ) : null}
        {link || link2 || editMode ? (
          <div className="flex flex-col flex-wrap gap-8 gap-y-0 sm:hidden lg:mt-auto lg:flex lg:flex-row">
            {link || editMode ? <ButtonLink link1Style={link1Style} /> : null}
            {link2 || editMode ? (
              <Link
                href={link2?.href}
                target={link2?.target}
                text={link2?.text}
                variant="text-link-1"
                propertyName="Link2"
                className={classnames('block w-fit text-gray-darker', buttonClasses)}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CTAIconSubBlock;
