import classnames from 'classnames';
import { memo, Suspense } from 'react';
import Shimmer from '@/components/ui/Shimmer';
import SearchResultsLoadingState from '@/components/ui/SearchResults/SearchResultsLoadingState';

export const HeaderLinksShimmer = memo(() => {
  return (
    <div className="flex flex-row flex-wrap items-center justify-start">
      {Array.from({ length: 2 }).map((_, i) => {
        const containerClasses = classnames(
          'group flex w-fit basis-auto flex-row items-center justify-between pt-2',
          {
            'mr-5': i != 1
          }
        );
        return (
          <div key={`link-${i}`} className={containerClasses}>
            <Shimmer className="mr-2 size-9 rounded-full" />
            <Shimmer className="h-6 w-24" />
          </div>
        );
      })}
    </div>
  );
});

export const LinkGridShimmer = memo(() => {
  return (
    <div className="module-spacing relative">
      <Shimmer className="my-2 h-[26px] w-[30%] rounded" />
      <div className="mt-8 flex w-full flex-row flex-wrap items-center justify-between sm:flex-nowrap">
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <Shimmer
              className="my-2 h-[64px] basis-full rounded sm:my-0 sm:basis-[30%]"
              key={`shimmer-${i}`}
            />
          ))}
      </div>
      <Shimmer className="mt-8 h-[20px] w-[10%] rounded" />
    </div>
  );
});

export const SearchResultBlockLoadingShimmer = memo(() => {
  return (
    <>
      <Shimmer className="mb-12 mt-10 h-[48px] w-1/2 rounded md:w-1/4" />
      <div className="flex w-full flex-nowrap items-center justify-between md:w-1/3">
        <Shimmer className="h-[56px] w-1/4 rounded md:w-[150px]" />
        <Shimmer className="h-[56px] w-1/4 rounded md:w-[150px]" />
        <Shimmer className="h-[56px] w-1/4 rounded md:w-[150px]" />
      </div>
      <div className="mb-8 mt-10 grid gap-6 sm:mt-14 lg:mb-20 lg:grid-cols-4">
        <SearchResultsLoadingState resultsCount={20} />
      </div>
      <div className="mb-12 flex flex-nowrap justify-center">
        <Shimmer className="h-[68px] w-[200px] rounded" />
      </div>
    </>
  );
});

export const HeaderShimmer = memo(() => {
  return (
    <>
      <div className="max-w-[536px] pt-12 lg:max-w-[845px]">
        <Shimmer className="mb-6 h-[72px] w-1/2" />
        <div className="my-6 flex w-full flex-nowrap">
          <Shimmer className="mr-2 size-[24px] rounded-full" />
          <Shimmer className="h-[24px] w-3/4" />
        </div>
      </div>
      <div className="max-w-[536px] lg:max-w-[628px]">
        <Shimmer className="h-[96px] w-full" />
        <Shimmer className="mt-4 h-[20px] w-1/3" />
      </div>
    </>
  );
});

export const MainBodyShimmer = memo(() => {
  return (
    <Suspense fallback={<></>}>
      <Shimmer className="my-6 h-[24px] w-full" />
      <Shimmer className="my-6 h-[24px] w-full" />
      <Shimmer className="my-6 h-[24px] w-1/2" />
    </Suspense>
  );
});

export const BreadcrumbsShimmer = memo(() => {
  return (
    <ol className="mt-8 flex items-center gap-4">
      <li className="flex items-center">
        <Shimmer className=" h-6 w-32" />
        <span className="ml-4 inline-flex self-center text-gray-light">/</span>
      </li>
      <li className="flex items-center">
        <Shimmer className=" h-6 w-32" />
      </li>
    </ol>
  );
});
