import { useCallback, useEffect, useState } from 'react';
import Thumbnail, { ThumbnailProps } from '@/components/ui/Thumbnail';
import Modal from '@/components/ui/Modal/Modal';
import ModalContentVideo from './Modal/ModalContentVideo';
import Player from '@vimeo/player';
import { ContentReference } from '@/@types/client-api';
import DotSeparatedContent from '@/components/ui/DotSeparatedContent';
import { ResponsiveImageProps } from './ResponsiveImage';

interface VimeoVideoProps {
  title?: string;
  videoId?: string;
  thumbnail?: ContentReference;
  showVideoInfo?: boolean;
  ratio?: ThumbnailProps['ratio'];
  hidePlayIcon?: boolean;
  className?: string;
  imageSizes?: ResponsiveImageProps['imageSizes'];
}

export interface VimeoThumbnail extends ContentReference {
  url: string;
}

const VimeoVideo = ({
  title,
  videoId,
  thumbnail,
  showVideoInfo = true,
  ratio,
  hidePlayIcon = false,
  className,
  imageSizes
}: VimeoVideoProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [videoTitle, setVideoTitle] = useState<string>(title || '');
  const [videoDuration, setVideoDuration] = useState<number>(0);

  const durationInMinutes = (duration: number): number => {
    return Math.max(1, Math.round(duration / 60));
  };

  const videoIframeGenerator = useCallback(() => {
    if (!videoId) {
      return '';
    }

    if (videoId.includes('player.vimeo.com')) {
      return videoId;
    }

    return `https://player.vimeo.com/video/${videoId}?title=false&autoplay=1&loop=1&controls=true&dnt=true`;
  }, [videoId]);

  const videoThumbnail = (thumbnail as VimeoThumbnail)?.url || '';
  const videoIframe = videoIframeGenerator();

  const videoInfoFromPlayer = useCallback(() => {
    const tmpIframe = document.createElement('iframe');
    tmpIframe.src = videoIframe;
    tmpIframe.style.display = 'none';
    document.body.appendChild(tmpIframe);

    const player = new Player(tmpIframe);

    player
      .ready()
      .then(async () => {
        const videoTitle = await player.getVideoTitle();
        const videoDuration = await player.getDuration();

        setVideoTitle(title || videoTitle);
        setVideoDuration(durationInMinutes(videoDuration));

        player.destroy();
      })
      .catch(() => {
        player.destroy();
      });
  }, [title, videoIframe]);

  useEffect(() => {
    if (showVideoInfo) {
      videoInfoFromPlayer();
    }
  }, [showVideoInfo, videoInfoFromPlayer]);

  return (
    <>
      <Thumbnail
        image={{
          url: videoThumbnail,
          alt: videoTitle
        }}
        icon={
          hidePlayIcon
            ? undefined
            : {
                name: 'play',
                label: 'Play Video'
              }
        }
        ratio={ratio}
        tag={
          videoTitle && videoDuration
            ? {
                children: <DotSeparatedContent content={[videoTitle, `${videoDuration} min`]} />
              }
            : undefined
        }
        onClick={() => {
          setIsOpen(true);
        }}
        className={className}
        imageSizes={imageSizes}
      />

      <Modal open={isOpen} setOpen={setIsOpen}>
        <ModalContentVideo>
          <iframe src={videoIframe} className="aspect-video w-full" allow="autoplay;" />
        </ModalContentVideo>
      </Modal>
    </>
  );
};

export default VimeoVideo;
