import {
  FacultyMember,
  PodcastSeriesNode as PodcastSeriesNodeProps,
  PodcastVariation
} from '@/@types/content';
import { MediaProviderUrl, PodcastEpisode, PodcastSeason } from '@/@types/client-api';
import PodcastListingContent from '@/components/blocks/PodcastListingBlock/PodcastListingContent';
import withNav from '@/components/hocs/withNav';
import Divider from '@/components/ui/Divider';
import PodcastHeader from '@/components/ui/PDP/PodcastHeader';
import Shimmer from '@/components/ui/Shimmer';
import SimpleSort from '@/components/ui/SimpleSort';
import SectionTitle from '@/components/ui/Titles/SectionTitle';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import Button from '@/components/ui/Buttons/Button';
import ContentArea from '@/components/cms/ContentArea';
import { useGetChildrenQuery } from '@/redux/api/contentDeliveryAPI';
import EditorialToutWithImageBlock from '@/components/blocks/EditorialToutWithImageBlock';
import { LinkItem } from '@/@types/cms';
import { useGetPodcastEpisodesQuery, useGetPodcastSeasonsQuery } from '@/redux/api/client/product';
import { PDPFooter } from '@/components/ui/PDP/PDPFooter';
import { BreadcrumbProps } from '@/components/ui/Breadcrumbs';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { transformIfPLIUrl } from '@/utils/helpers';

type sortOptionsType = {
  label: string;
  value: string;
};

type SeasonItemType = {
  label?: string | null;
  value?: string | null;
};

const DEFAULT_PAGE_SIZE = 20;

const PodcastSeriesNodeComponent = ({
  code,
  url,
  displayName,
  seriesServiceLinks,
  introContentArea,
  contentLink,
  podcastHosts,
  podcastCoHosts,
  bottomPageContent
}: PodcastSeriesNodeProps) => {
  const [sortOptions, setSortOptions] = useState<sortOptionsType[]>([]);
  const [sortValue, setSortValue] = useState('');
  const [content, setContent] = useState<PodcastEpisode[]>([]);
  const [seasons, setSeasons] = useState<SeasonItemType[]>([]);
  const [selectedSeason, setSelectedSeason] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pagesRequested, setPagesRequested] = useState(1);

  const [providerUrls, setProviderUrls] = useState<MediaProviderUrl[]>([]);
  const [hosts, setHosts] = useState<FacultyMember[]>([]);

  const {
    data: childrenResponse,
    isLoading: isChildItemsLoading,
    isFetching: isChildItemsFetching
  } = useGetChildrenQuery(
    { contentLink: contentLink?.guidValue ?? '' },
    { skip: !contentLink?.guidValue }
  );

  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

  useEffect(() => {
    if (seriesServiceLinks?.length > 0) {
      setProviderUrls(
        seriesServiceLinks.map(
          (x: { icon: string; serviceLink: string; serviceName: string }) =>
            ({ icon: x.icon, url: x.serviceLink, provider: x.serviceName }) as MediaProviderUrl
        )
      );
    } else if (!isChildItemsFetching || !isChildItemsLoading) {
      const lastSeason = childrenResponse?.children
        .filter(x => x.contentType?.includes('PodcastVariation'))
        .pop() as PodcastVariation;
      setProviderUrls(lastSeason?.providerUrls ?? []);
    }
  }, [childrenResponse?.children, isChildItemsFetching, isChildItemsLoading, seriesServiceLinks]);

  useEffect(() => {
    if (podcastHosts) {
      setHosts(podcastHosts.concat(podcastCoHosts ?? []).map(x => x as FacultyMember));
    } else if (!isChildItemsFetching || !isChildItemsLoading) {
      const lastSeason = childrenResponse?.children
        .filter(x => x.contentType?.includes('PodcastVariation'))
        .pop() as PodcastVariation;
      setHosts(
        (lastSeason.podcastHosts ?? [])
          .concat(lastSeason.podcastCoHosts ?? [])
          .map(x => x.contentLink?.expanded as FacultyMember)
      );
    }
  }, [
    childrenResponse?.children,
    isChildItemsFetching,
    isChildItemsLoading,
    podcastCoHosts,
    podcastHosts
  ]);

  const { data: podcastSeasons, isFetching: isSeasonsFetching } = useGetPodcastSeasonsQuery(code!);
  const { data: episodesData, isFetching: isEpisodesFetching } = useGetPodcastEpisodesQuery({
    podcastCode: code!.trim(),
    seasonCode: selectedSeason,
    pageNumber: pagesRequested,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: sortValue
  });

  const handleLoadMoreClick = () => {
    setIsLoadingMore(true);
    setPagesRequested(prevState => prevState + 1);
  };

  useEffect(() => {
    setIsLoadingMore(false);
  }, [episodesData]);

  useEffect(() => {
    if (episodesData) {
      const sortOptions =
        episodesData?.sortResults?.sortResultItems?.map(item => ({
          label: item.sortTitle || '',
          value: item.value || ''
        })) || [];

      setContent(episodesData?.items || []);
      setSortOptions(sortOptions);
      setSortValue(
        sortValue
          ? sortValue
          : episodesData?.sortResults?.sortResultItems?.find(item => item.isDefault)?.value || ''
      );
    }
  }, [episodesData, sortValue]);

  useEffect(() => {
    if (!isSeasonsFetching && podcastSeasons) {
      loadSeasons(podcastSeasons);
    }
  }, [podcastSeasons, isSeasonsFetching]);

  const loadSeasons = (children: PodcastSeason[]) => {
    const items: SeasonItemType[] = children.map(i => ({
      label: i.name,
      value: i.code
    }));
    items.unshift({ label: 'All seasons', value: 'all' });
    setSeasons(items || []);
  };

  return (
    <>
      <PodcastHeader
        title={displayName || ''}
        providerUrls={providerUrls}
        className="bg-gray-light"
        code={code}
        shareUrl={url}
        isSeries={true}
        breadcrumbs={[
          {
            name: 'Podcasts',
            url: transformIfPLIUrl(stateSiteLabels.podcastLandingPageReference?.url)
          } as BreadcrumbProps
        ]}
      />
      {introContentArea && (
        <ContentArea propertyName={'IntroContentArea'} components={introContentArea} />
      )}
      <div className="container my-8 flex flex-col"></div>
      <div className={classnames('container py-10', 'sm:py-12', 'lg:pb-16 lg:pt-0')}>
        <SectionTitle
          titleSize="h5"
          verticalAlignment="bottom"
          mobileGapSmall={false}
          title="Episodes"
        >
          {isEpisodesFetching || isSeasonsFetching ? (
            <Shimmer className="h-11 w-40" />
          ) : (
            <div className="relative z-dropdown flex flex-row gap-2">
              {seasons && seasons.length > 2 && (
                <SimpleSort
                  options={seasons}
                  value={
                    selectedSeason
                      ? selectedSeason
                      : seasons && seasons.length > 0
                        ? seasons[0].value
                        : null
                  }
                  onValueChange={v => {
                    if (v == 'all') v = '';
                    setPagesRequested(1);
                    setSelectedSeason(v);
                  }}
                  disabled={isSeasonsFetching || isLoadingMore}
                  avoidCollisions={false}
                />
              )}
              <SimpleSort
                options={sortOptions}
                value={sortValue}
                onValueChange={s => {
                  setPagesRequested(1);
                  setSortValue(s);
                }}
                disabled={isEpisodesFetching || isLoadingMore}
                avoidCollisions={false}
              />
            </div>
          )}
        </SectionTitle>
      </div>
      <div className="container">
        <Divider color="light" />
      </div>

      <PodcastListingContent
        shimerCount={5}
        isLoading={isEpisodesFetching}
        content={content}
        audioLabel="Listen on"
        videoLabel="Watch"
        saveToMyLibraryLabel="Save to my library"
        removeFromMyLibraryLabel="Remove from library"
        listenOnLabel="Listen On"
        hideLabel="Hide"
      />

      {episodesData?.summary?.hasMore && (
        <div className="container flex items-center justify-center py-10">
          <Button
            color="black"
            size="large"
            label="Load more"
            disabled={isEpisodesFetching || isLoadingMore}
            onClick={handleLoadMoreClick}
          />
        </div>
      )}
      {hosts.map((host, i) => {
        const link: LinkItem = {
          text: 'Find out more',
          href: host.contentLink?.url,
          title: 'Find out more'
        };

        return (
          <EditorialToutWithImageBlock
            key={i}
            smallImage={false}
            contentLink={host.contentLink}
            image={host.image?.expanded?.contentLink ?? host.image}
            copy={host.biography}
            heading={host.displayName}
            eyebrowText={`About the host${hosts.length > 1 ? 's' : ''}`}
            primaryLink={link}
            layout={i % 2 ? 'media-right' : 'media-left'}
          />
        );
      })}
      {bottomPageContent && (
        <PDPFooter isLoading={isEpisodesFetching} bContent={bottomPageContent} />
      )}
    </>
  );
};

const PodcastSeriesNode = withNav(PodcastSeriesNodeComponent);
export default PodcastSeriesNode;
