import { useCallback, useEffect, useState } from 'react';
import Link from '../../Link';
import Segment from './Segment';
import Shimmer from '../../Shimmer';
import { AnalyticsContext } from '@/analytics/constants';
import { LoggedOutLicenseStruct } from '@/hooks/PDP/useLoggedOutLicenses';

export interface ProgramSegmentsProps {
  labels: {
    expandAll: string;
    collapseAll: string;
    showMore: string;
    showLess: string;
    launch: string;
    cart: string;
  };
  heading: string;
  segments: SegmentProps[];
  segmentUserData: SegmentUserDataProps[];
  context: AnalyticsContext;
  loggedOutLicense: LoggedOutLicenseStruct;
  creditTrackerCode?: string;
}

export interface SegmentProps {
  code?: string; // only marked as '?' to match CMS generated models, but is always expected to be set
  url?: string;
  displayName?: string; // only marked as '?' to match CMS generated models, but is always expected to be set
  imageUrl: string;
  formattedSpeakers?: string;
  segmentDescription?: string;
  isExpanded?: boolean;
}

export interface SegmentUserDataProps {
  code: string;
  runtime?: string;
  canLaunch?: boolean;
  launchUrl?: string;
  progressPct?: number;
  credits?: string;
  specialCredits?: string;
  isCreditsLoading?: boolean;
  isExpiredFromSale?: boolean;
}

type SegmentState = SegmentProps & { isExpanded: boolean };

const ProgramSegments: React.FC<ProgramSegmentsProps> = ({
  labels,
  heading,
  segments,
  segmentUserData,
  context,
  loggedOutLicense,
  creditTrackerCode
}) => {
  const [allExpanded, setAllExpanded] = useState(false);
  const [segmentsState, setSegmentsState] = useState<SegmentState[]>([]);
  useEffect(() => {
    setSegmentsState(
      segments.map(segment => ({
        ...segment,
        isExpanded: segment.isExpanded ?? false
      }))
    );
  }, [segments]);

  const toggleSegment = useCallback((code: string, isExpanded: boolean) => {
    setSegmentsState(prevState =>
      prevState.map(segment => (segment.code === code ? { ...segment, isExpanded } : segment))
    );
  }, []);

  const toggleAllSegments = useCallback(() => {
    setAllExpanded(!allExpanded);
    setSegmentsState(prevState =>
      prevState.map(segment => ({ ...segment, isExpanded: !allExpanded }))
    );
  }, [allExpanded]);

  return (
    <section id="segments" className="container module-spacing-pdp">
      <div className="mb-12 flex items-center justify-between gap-4">
        <h2 className="heading-5">{heading}</h2>
        <Link
          as="button"
          text={allExpanded ? labels.collapseAll : labels.expandAll}
          onClick={toggleAllSegments}
          className="shrink-0"
        />
      </div>
      {!segments.length ? <Shimmer className="h-[400px] w-full" /> : <></>}
      {segmentsState.map((segment, index) => (
        <Segment
          key={segment.code}
          index={index + 1}
          {...segment}
          {...segmentUserData.find(sud => sud.code === segment.code)}
          labels={labels}
          expanded={!!segment.isExpanded}
          onToggle={open => toggleSegment(segment.code ?? '', open)}
          context={context}
          loggedOutLicense={loggedOutLicense}
          creditTrackerCode={creditTrackerCode}
        />
      ))}
    </section>
  );
};

export default ProgramSegments;
