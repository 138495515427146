import { InputError } from '@/components/ui/Form/Input';
import { DateFormat, parseDate } from '@/utils/helpers';
import { invalidDateError, maxDateError, minDateError } from '@/utils/validations';

export const getDateInputErrors = (
  minDate?: Date,
  maxDate?: Date,
  currentValue?: string
): InputError[] => {
  const errors: InputError[] = [];
  const parsedDate = currentValue ? parseDate(currentValue) : null;

  if (!parsedDate) {
    errors.push(invalidDateError(DateFormat));
  } else {
    const normalizedParsedDate = new Date(parsedDate.setHours(0, 0, 0, 0));
    const normalizedMinDate = minDate ? new Date(minDate.setHours(0, 0, 0, 0)) : null;

    if (normalizedMinDate && normalizedParsedDate < normalizedMinDate) {
      errors.push(minDateError(normalizedMinDate));
    }
    if (maxDate && normalizedParsedDate > maxDate) {
      errors.push(maxDateError(maxDate));
    }
  }

  return errors;
};
