import { getFormatedDateRange } from '@/lib/helpers/date';
import classnames from 'classnames';

interface VariantProps {
  selected?: boolean;
  location?: string;
  startDate?: string;
  endDate?: string;
  isLive?: boolean;
  timeZone?: string;
  subLabel?: string;
  onSelect?: () => void;
}

const Variant: React.FC<VariantProps> = ({
  location,
  startDate,
  endDate,
  isLive,
  subLabel,
  timeZone,
  selected,
  onSelect
}) => {
  const boxClasses = classnames(
    'w-full rounded border px-6 py-4 text-left transition-colors hover:border-black lg:px-5',
    { 'border-silver': !selected },
    { 'border-black bg-gray-lightest': selected }
  );

  return (
    <button className={boxClasses} onClick={onSelect}>
      <div className="flex items-center justify-between">
        <span className="text-2 font-medium">{location}</span>
        {isLive ? (
          <div className="label flex items-center gap-2 text-gray-dark">
            <span className="block size-3 rounded-full bg-red"></span>
            Live
          </div>
        ) : (
          <p className="label text-gray-dark">
            {!!startDate && timeZone && getFormatedDateRange(timeZone, startDate, endDate)}
          </p>
        )}
      </div>
      {subLabel && <p className="label mt-[8px] block opacity-60">{subLabel}</p>}
    </button>
  );
};

export default Variant;
