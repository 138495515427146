import { PersonalInfoContactInfoBlock as PersonalInfoContactInfoBlockProps } from '@/@types/content';
import ContactInfoSection from '@/components/ui/ProfileSections/ContactInfoSection';
import { IComponentProps } from '@/lib/ComponentFactory';

const PersonalInfoContactInfoBlock: React.FC<
  PersonalInfoContactInfoBlockProps & IComponentProps
> = props => {
  return <ContactInfoSection {...props} />;
};

export default PersonalInfoContactInfoBlock;
