import { AnnouncementMessage } from '@/@types/client-api';
import { FC, lazy, memo, Suspense, useCallback, useRef, useState } from 'react';
import { RequirementsPanel } from '@/components/ui/CreditTracker/Panels/RequirementsPanel';
import Breadcrumbs, { BreadcrumbProps } from '@/components/ui/Breadcrumbs';
import {
  BreadcrumbsShimmer,
  HeaderShimmer
} from '@/components/blocks/CreditInfo/snippets/LoadingShimmers';
import JurisdictionHeaderRequirementMessage from '@/components/blocks/CreditInfo/snippets/JurisdictionHeaderRequirementMessage';
const StandardWhiteHeaderBlock = lazy(() => import('@/components/blocks/StandardWhiteHeaderBlock'));

interface ICreditInfoHeaderProps {
  isLoading?: boolean;
  header: string;
  subHeader?: string | null;
  subHeadingIcon?: string | null;
  sidePanelMessages?: AnnouncementMessage[];
  carouselMessages?: AnnouncementMessage[];
  breadcrumbs?: BreadcrumbProps[];
  buttonText?: string;
  panelHeader?: string;
}

const CreditInfoHeader: FC<ICreditInfoHeaderProps> = memo(
  ({
    header,
    subHeader,
    subHeadingIcon,
    sidePanelMessages,
    carouselMessages,
    isLoading,
    breadcrumbs,
    panelHeader,
    buttonText = 'See all requirements'
  }) => {
    const panelTriggerRef = useRef<HTMLButtonElement>(null);
    const [scrollIndex, setScrollIndex] = useState<number | null>(null);

    const renderMessages = useCallback(() => {
      return (
        <section id="requirements-section">
          <JurisdictionHeaderRequirementMessage message={carouselMessages?.[0]} />
          <div className="mt-4">
            <RequirementsPanel
              announcements={sidePanelMessages ?? []}
              buttonClassName="text-2 font-bold"
              buttonType="link"
              creditRegionName={panelHeader ?? header}
              scrollIndex={scrollIndex}
              ref={panelTriggerRef}
              onOpenChange={open => !open && setScrollIndex(null)}
              buttonText={buttonText}
            />
          </div>
        </section>
      );
    }, [buttonText, carouselMessages, header, panelHeader, scrollIndex, sidePanelMessages]);

    return (
      <div className="w-full md:w-3/4 lg:max-w-[845px]">
        {isLoading ? (
          <BreadcrumbsShimmer />
        ) : (
          !!breadcrumbs?.length && (
            <div className="mt-8">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
          )
        )}
        {isLoading ? (
          <HeaderShimmer />
        ) : (
          <Suspense fallback={<HeaderShimmer />}>
            <div className="pt-3 md:pt-0 [&_.container]:!px-0 [&_h1]:!mb-0 [&_p]:!mb-6 [&_p]:!mt-4 [&_section]:!py-0">
              <StandardWhiteHeaderBlock
                pageTitle={header}
                hideDivider={true}
                copyIcon={subHeadingIcon}
                copy={subHeader ?? undefined}
              />
            </div>
            <div className="max-w-[536px] lg:max-w-[628px]">{renderMessages()}</div>
          </Suspense>
        )}
      </div>
    );
  }
);

CreditInfoHeader.displayName = 'CreditInfoHeader';
export default CreditInfoHeader;
