import type {
  BannerMessageBlock,
  BaseCreditInformation,
  CreditClassPage as CreditClassPageProps,
  LinkBlock as LinkBlockProps
} from '@/@types/content';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Shimmer from '@/components/ui/Shimmer';
import Divider from '@/components/ui/Divider';
import { CreditInfoContext } from '@/components/blocks/CreditInfo/context/CreditInfoContext';
import CreditInfoAccordionSection, {
  AccordionState,
  CreditClassLink
} from '@/components/blocks/CreditInfo/snippets/CreditInfoAccordionSection';
import useCreditInfo from '@/components/blocks/CreditInfo/hooks/useCreditInfoHook';
import CreditInfoOverview from '@/components/blocks/CreditInfo/snippets/CreditInfoOverview';
import { extractTextFromHTML, formatTcode } from '@/utils/helpers';
import { creditClassChildrenCDProps } from '@/components/blocks/CreditInfo/util/creditInfoConstants';
import { LinkItem } from '@/@types/client-api';
import LinkBlock from '@/components/blocks/LinkGridBlock/LinkBlock';
import { PreselectTabQueryKey } from '@/components/blocks/CreditInfo/util/creditInfoConstants';
import CreditClassPage from '@/components/pages/CreditClassPage';
import Link from '@/components/ui/Link';
import RichText from '@/components/cms/RichText';

type ChildPageData = BaseCreditInformation & {
  __type: string;
};

const CreditClassContent: React.FC<CreditClassPageProps> = props => {
  const {
    renderAsLinks,
    creditAnnouncements,
    mainBody,
    routeSegment,
    name: contentName,
    dropdownText,
    sourceCode,
    keyCode
  } = props;
  const { isLoading: isParentLoading } = useContext(CreditInfoContext);
  const [isLoading, setIsLoading] = useState<boolean>(!!isParentLoading);

  const {
    children: _children,
    isChildItemsLoading,
    isChildItemsFetching,
    search,
    renderMainContentArea
  } = useCreditInfo({
    ...props,
    childrenProperties: creditClassChildrenCDProps
  });

  const children = useMemo(() => {
    return [
      ...(_children?.map(a => {
        return { ...a, __type: a.contentType?.[1] } as ChildPageData;
      }) ?? [])
    ];
  }, [_children]);

  useEffect(() => {
    setIsLoading(isChildItemsLoading || isChildItemsFetching);
  }, [isChildItemsFetching, isChildItemsLoading]);

  const messages = useMemo(
    () =>
      (creditAnnouncements as BannerMessageBlock[])?.filter(
        a => a.showOnJurisdictionPageCarousel
      ) ?? [],
    [creditAnnouncements]
  );

  const shimmer = useMemo(() => {
    return (
      <>
        <Shimmer className="my-8 h-16 w-full" />
        <div className="flex w-full flex-row items-end justify-between">
          <Shimmer className="h-8 w-1/2" />
          <Shimmer className="h-6 w-28" />
        </div>
        <Divider color="light" className="mb-6 mt-3" />
        <div className="mb-16 flex w-full flex-row flex-wrap justify-center">
          {Array.from({ length: 10 }).map((_, i) => {
            return <Shimmer key={`shimmer-${i}`} className="mt-2 h-20 w-full" />;
          })}
        </div>
      </>
    );
  }, []);

  const mapChildren = useCallback(
    (children: ChildPageData[]) => {
      if (!children || children.length === 0) {
        return { accordionItems: [], creditClassLinks: [] };
      }

      const state: AccordionState[] = [];
      const links: CreditClassLink[] = [];
      children
        ?.filter(a => !a.hidePage)
        ?.map((item, i) => {
          const url = `${item.contentLink?.url}?${PreselectTabQueryKey}=false`;
          if (item.__type === (CreditClassPage.displayName ?? CreditClassPage.name)) {
            links.push({
              url,
              text: item.secondaryHeader ?? item.name
            } as CreditClassLink);
          } else {
            const _content = item?.creditAnnouncements?.filter(a => {
              const block = a.contentLink?.expanded as BannerMessageBlock;
              return !!block.showOnSidePanel;
            });
            const name = item.secondaryHeader ?? item?.name;
            item.pageLinkText ??= `Browse programs for ${`${name} `}${contentName}`;
            state.push({
              title: name ?? '',
              value: `${routeSegment ?? ''}-${item.name}-${i}`,
              content: (
                <div className="text-1 p-8 pt-0">
                  {!!_content?.length &&
                    _content?.map((a, i) => {
                      const block = a.contentLink?.expanded as BannerMessageBlock;
                      return (
                        <span
                          key={`${routeSegment ?? ''}-${item.name}-${i}`}
                          className="[&_a:hover]:text-red [&_a]:underline"
                        >
                          {!!block?.headingText && (
                            <RichText content={block?.headingText} className="font-bold" />
                          )}
                          {!!block?.body && <RichText content={block?.body} />}
                          {i != _content?.length - 1 && <br />}
                        </span>
                      );
                    })}
                  <Link className="!text-1 mt-8" href={url} text={item.pageLinkText} />
                </div>
              )
            });
          }
        });

      return {
        accordionItems: state,
        creditClassLinks: links.sort((a, b) => (a.text < b.text ? -1 : 1))
      };
    },
    [contentName, routeSegment]
  );

  const renderContents = useCallback(() => {
    if (children?.length === 0) {
      return null;
    }

    if (renderAsLinks) {
      const linkBlockProps = children.map(child => ({
        link: {
          text: child.name,
          href: new URL(child.url ?? '/').pathname,
          title: `Go to ${child.name}`
        } as LinkItem
      })) as LinkBlockProps[];

      return (
        <div className="flex flex-col space-y-4">
          {linkBlockProps.map(linkBlockProp => (
            <LinkBlock {...linkBlockProp} />
          ))}
        </div>
      );
    }

    const { accordionItems, creditClassLinks } = mapChildren(children);

    return (
      <CreditInfoAccordionSection
        identifier={routeSegment ?? ''}
        jurisdictionDropdownText={dropdownText}
        items={accordionItems}
        creditClassLinks={creditClassLinks}
        accordionSectionHeader={mainBody && extractTextFromHTML(mainBody)}
        search={search}
        parentName={contentName}
        showSectionHeader={!!accordionItems?.length}
      />
    );
  }, [
    children,
    renderAsLinks,
    mapChildren,
    routeSegment,
    dropdownText,
    mainBody,
    search,
    contentName
  ]);

  return (
    <>
      {isLoading ? (
        shimmer
      ) : (
        <>
          {!!messages?.length && <CreditInfoOverview messages={messages} />}
          {renderMainContentArea(formatTcode(sourceCode, keyCode))}
          {renderContents()}
        </>
      )}
    </>
  );
};

export default CreditClassContent;
