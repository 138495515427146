import type { LibraryItem } from '@/@types/client-api';

import renderOnDemandProgram from './Snippets/OnDemandProgram';
import renderOnDemandSegment from './Snippets/OnDemandSegment';
import renderLiveProgram from './Snippets/LiveProgram';
import renderPublication from './Snippets/Publication';
import renderFacultyAndAuthors from './Snippets/FacultyAndAuthors';
import renderPodcast from './Snippets/Podcast';

import type { RenderSnippetProps } from './Snippets/types';
import type { SnippetProps } from '../Snippet/Snippet';
import { getIsLiveProgramExpired } from './Snippets/helpers';
import renderLiveProgramExpired from './Snippets/LiveProgramExpired';

export type SearchableType =
  | 'ondemandprogram'
  | 'ondemandsegment'
  | 'liveprogram'
  | 'book'
  | 'faculty'
  | 'podcastepisode';

const variants = new Set<SearchableType>([
  'ondemandprogram',
  'ondemandsegment',
  'liveprogram',
  'book',
  'faculty',
  'podcastepisode'
]);

const searchableTypeToSnippetVariant = (searchableType: string): SearchableType | null => {
  let match: string | null = null;
  variants.forEach(variant => {
    const regexp = new RegExp(variant, 'g');
    if (searchableType.toLowerCase().match(regexp)?.length) {
      match = variant;
    }
  });
  return match;
};

export const getSnippetRenderer = (
  searchableType: string | null,
  context?: RenderSnippetProps['context'],
  data?: RenderSnippetProps['data']
): ((renderSnippetProps: RenderSnippetProps) => SnippetProps) | null => {
  try {
    if (typeof searchableType === 'string') {
      switch (searchableTypeToSnippetVariant(searchableType ?? '')) {
        case 'ondemandprogram': {
          return renderOnDemandProgram;
        }
        case 'ondemandsegment': {
          return renderOnDemandSegment;
        }
        case 'liveprogram': {
          if (context === 'library' && data) {
            if (getIsLiveProgramExpired(data as unknown as LibraryItem)) {
              return renderLiveProgramExpired;
            }
          }
          return renderLiveProgram;
        }
        case 'book': {
          return renderPublication;
        }
        case 'faculty': {
          return renderFacultyAndAuthors;
        }
        case 'podcastepisode': {
          return renderPodcast;
        }
        default:
          throw new Error(`Unknown SearchableType: ${searchableType}`);
      }
    }
  } catch (invalidTypeError) {
    console.error(invalidTypeError);
  }
  return null;
};

export default searchableTypeToSnippetVariant;
