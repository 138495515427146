import { useCallback, useMemo, useState } from 'react';
import { useGetCertificatesQuery } from '@/redux/api/client/userCertificates';
import { getCertificateFilters, initialFilters, prepareFiltersToSend } from '../utils/filters';
import { useGetCreditJurisdictionsQuery } from '@/redux/api/client/cle';
import { FiltersApplied } from '../utils/types';
import { getQuickFilters, onQuickFilterToggle } from '../utils/quick-filters';
import { TFilter, TFilterItem } from '../../Filter/FilterRow';
import { CertificateResponse, CreditJurisdiction } from '@/@types/client-api';
import { FilterProps } from '../../Filter/types';
import { trackClearAllFiltersEvent, trackSortEvent } from '@/analytics/certificates';
import { trackPaginationEvent } from '@/analytics/pagination';
import { AnalyticsContext } from '@/analytics/constants';

type UseCertificatesReturn = {
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  currentPage: number;
  certificates?: CertificateResponse;
  creditJurisdictions?: CreditJurisdiction[];
  filters: FilterProps[];
  quickFilters: TFilterItem[];
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  sortCertificates: (value?: string) => void;
  clearAllFilters: () => void;
  quickFilterToggle: (filter: TFilter) => void;
};

const useCertificates = (): UseCertificatesReturn => {
  const [queryFilters, setQueryFilters] = useState<FiltersApplied>(initialFilters);

  const currentPage = useMemo(() => queryFilters.pageNumber, [queryFilters.pageNumber]);

  const {
    data: certificates,
    isLoading: isLoadingCertificates,
    isError: isErrorCertificates,
    isFetching: isFetchingCertificates
  } = useGetCertificatesQuery(prepareFiltersToSend(queryFilters));

  const {
    data: creditJurisdictions,
    isLoading: isLoadingCreditJurisdictions,
    isError: isErrorCreditJurisdictions
  } = useGetCreditJurisdictionsQuery();

  const filters = useMemo(() => {
    return getCertificateFilters(
      certificates?.summary?.filterResultSummary ?? [],
      queryFilters,
      setQueryFilters,
      creditJurisdictions ?? []
    );
  }, [certificates, creditJurisdictions, queryFilters]);

  const quickFilters = useMemo(() => (filters ? getQuickFilters(filters) : []), [filters]);

  const goToNextPage = useCallback(() => {
    trackPaginationEvent(AnalyticsContext.Certificates, currentPage + 1);
    setQueryFilters(state => ({ ...state, pageNumber: state.pageNumber + 1 }));
  }, [currentPage]);

  const goToPreviousPage = useCallback(() => {
    trackPaginationEvent(AnalyticsContext.Certificates, currentPage - 1);
    setQueryFilters(state => ({ ...state, pageNumber: state.pageNumber - 1 }));
  }, [currentPage]);

  const sortCertificates = useCallback((value?: string) => {
    trackSortEvent(value);
    setQueryFilters(state => ({ ...state, sort: value }));
  }, []);

  const clearAllFilters = useCallback(() => {
    trackClearAllFiltersEvent({ facetGroup: '' });
    setQueryFilters(initialFilters);
  }, []);

  const quickFilterToggle = useCallback((filter: TFilter) => {
    onQuickFilterToggle(setQueryFilters, filter);
  }, []);

  const isLoading = useMemo(
    () => isLoadingCertificates || isFetchingCertificates || isLoadingCreditJurisdictions,
    [isFetchingCertificates, isLoadingCertificates, isLoadingCreditJurisdictions]
  );

  return {
    isFetching: isFetchingCertificates,
    isLoading,
    isError: isErrorCertificates || isErrorCreditJurisdictions,
    currentPage,
    certificates,
    creditJurisdictions,
    filters,
    quickFilters,
    goToNextPage,
    goToPreviousPage,
    sortCertificates,
    clearAllFilters,
    quickFilterToggle
  };
};

export default useCertificates;
