import Icon from '@/components/ui/Icon';
import { useMemo, useRef, useState, useEffect, useCallback, RefObject } from 'react';
import classnames from 'classnames';
import { Theme } from '@/styles/darkMode';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Credit, CreditJurisdiction, CreditRegionViewModel } from '@/@types/client-api';
import { UserLicense } from '@/@types/client-api';
import EditLicenseModal from '../../LicenseModals/EditLicenseModal';
import {
  findCreditJurisdiction,
  getEnrichedLicenseData,
  getLicenseNumberSettings,
  LicenseData
} from '../../SearchModals/utils/licenses';
import { getComplianceGroupOptions } from '../../SearchModals/utils/licenses';
import { extractTextFromHTML } from '@/utils/helpers';
import { AnalyticsContext } from '@/analytics/constants';
import { trackEditLicenseEvent } from '@/analytics/licenses';

enum InteractiveType {
  N_A = 0,
  YES = 1,
  NO = 2
}

export type CreditLicenseCardProps = CreditRegionViewModel & {
  labels?: {
    seeMore: string;
    seeLess: string;
    expirationDate: string;
    edit: string;
    remove: string;
    totalCredits: string;
    credits: string;
    notForCredit: string;
  };
  code: string;
  creditDetailSectionRef?: RefObject<HTMLElement>;
  variation?: Theme;
  seeMoreFunction?: () => void;
  license?: UserLicense;
  creditJurisdictions?: CreditJurisdiction[];
  handleOnSaveEditLicense?: (licenseData: LicenseData) => void;
  handleOnDeleteLicense?: (licenseData?: LicenseData) => void;
  isLoggedOut?: boolean;
  handleLoggedOutRemoveLicense?: (regionSK: number) => void;
  modalVariation?: boolean;
  seeMoreByDefault?: boolean;
  allowEdit?: boolean;
  context?: AnalyticsContext;
};

const CreditLicenseCard = ({
  code,
  creditDetailSectionRef,
  labels,
  notes,
  description,
  totalOffered,
  statusEnum,
  statusDescription,
  expirationDateFormatted,
  regionMessage,
  credits,
  interactiveType,
  variation = Theme.Light,
  seeMoreFunction,
  modalVariation,
  seeMoreByDefault,
  regionSk,
  license,
  creditJurisdictions,
  handleOnSaveEditLicense,
  handleOnDeleteLicense,
  allowEdit = true,
  isLoggedOut = false,
  context,
  handleLoggedOutRemoveLicense
}: CreditLicenseCardProps) => {
  const notesAndMessagesRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [isClamped, setIsClamped] = useState(false);
  const cardRef = useRef<HTMLDivElement | null>(null);

  //#region styles
  const cardLicenseClasses = classnames('rounded p-6', {
    'bg-gray-lightest': variation === Theme.Light,
    'bg-charcoal': variation === Theme.Dark
  });

  const editButtonClasses = classnames('text-2 hover:text-red', {
    'text-gray': variation === Theme.Light,
    'text-gray-medium': variation === Theme.Dark
  });

  const statusClasses = classnames('text-2-medium mt-4 flex items-center gap-2 lg:mt-6', {
    'text-white': variation === Theme.Dark
  });

  const descriptionClasses = classnames('text-1-medium', {
    'text-white': variation === Theme.Dark
  });

  const totalCreditClasses = classnames('text-2-medium', {
    'text-white': variation === Theme.Dark
  });

  const creditListClasses = classnames('text-2', {
    'text-gray': variation === Theme.Light,
    'text-gray-medium': variation === Theme.Dark
  });

  const expirationDateClasses = classnames('text-2', {
    'text-gray': variation === Theme.Light,
    'text-gray-medium': variation === Theme.Dark
  });

  const combinedNotesAndMessagesClasses = classnames(
    'text-2 transition-all',
    {
      'text-gray': variation === Theme.Light,
      'text-gray-medium': variation === Theme.Dark,
      'flex-col items-start': seeMore,
      'justify-between': !seeMore
    },
    'notes_and_region_message'
  );

  const seeMoreButtonClasses = classnames('text-2 min-w-fit pt-1 underline hover:text-red', {
    'text-gray': variation === Theme.Light,
    'text-gray-medium': variation === Theme.Dark,
    'mt-4': seeMore
  });

  const notesAndMessageClasses = classnames('line-clamp-3 overflow-hidden transition-all', {
    'line-clamp-none max-w-none': seeMore
  });
  //#endregion

  //#region Credit Details rendering helpers
  const CREDIT_STATES = {
    approved: ['Approved'],
    warning: ['Pending', 'CreditUponRequest', 'ApplicationSubmitted'],
    failed: ['NotApproved', 'Expired', 'NonMcleState', 'Undefined']
  };

  const statusIcon = useMemo(() => {
    if (CREDIT_STATES.warning.includes((statusEnum as unknown as string) ?? 'Undefined'))
      return <Icon size="small" name="pending" aria-label="Credit Status Warning" />;
    if (CREDIT_STATES.approved.includes((statusEnum as unknown as string) ?? 'Undefined'))
      return <Icon size="small" name="approved" aria-label="Credit Status Success" />;
    return <Icon size="small" name="not-approved" aria-label="Credit Status Danger" />;
  }, [CREDIT_STATES.approved, CREDIT_STATES.warning, statusEnum]);

  const formatCombinedNotesAndMessage = () => {
    const notesLabel =
      notes && (interactiveType as unknown as InteractiveType) !== InteractiveType.YES
        ? `<p>${notes.toString()}</p>`
        : '';
    const regionMessageLabel = regionMessage ? regionMessage.toString() : '';

    const combinedText =
      notesLabel && regionMessageLabel
        ? `${notesLabel}${regionMessageLabel}`
        : notesLabel || regionMessageLabel;

    return combinedText || '';
  };

  const combinedNotesAndMessage = formatCombinedNotesAndMessage();

  const isCreditStatusPending = (statusEnum as unknown as string) === 'Pending';

  const getModalVariationCreditSummaryText = () => {
    const hasCredits = (totalOffered ?? 0) > 0;
    if (hasCredits) {
      return `${totalOffered} ${labels?.totalCredits}`;
    } else if (isCreditStatusPending) {
      return statusDescription;
    }

    return labels?.notForCredit;
  };

  const totalOfferedFormatted = totalOffered ? (
    <h4 className={totalCreditClasses}>{`${totalOffered} ${labels?.totalCredits}`}</h4>
  ) : (
    <></>
  );

  const mapCreditsToListItems = (credit: Credit) => {
    if (!credit.typeTitle || !credit.typeSK || !credit.creditOffered) return <></>;
    const creditFormatted = `${credit.creditOffered} ${extractTextFromHTML(credit.typeTitle)}`;
    return <li key={credit.typeSK}>{creditFormatted}</li>;
  };
  //#endregion

  //#region See More feature
  useEffect(() => {
    if (seeMoreByDefault) cardRef.current?.scrollIntoView();
  }, [seeMoreByDefault]);

  const noteContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkClamp = () => {
      if (!noteContainerRef.current) {
        setIsClamped(false);
        return;
      }
      setIsClamped(noteContainerRef.current.scrollHeight > noteContainerRef.current.clientHeight);
    };

    checkClamp();
    window.addEventListener('resize', checkClamp);
    return () => window.removeEventListener('resize', checkClamp);
  }, []);

  useEffect(() => {
    if (seeMoreByDefault && isClamped) {
      setSeeMore(true);
    }
  }, [isClamped, seeMoreByDefault]);

  const showSeeMoreButton = useMemo(
    () => combinedNotesAndMessage && isClamped,
    [combinedNotesAndMessage, isClamped]
  );

  const seeMoreButton = useMemo(
    () => (
      <button
        onClick={seeMoreFunction ? seeMoreFunction : () => setSeeMore(!seeMore)}
        className={seeMoreButtonClasses}
        aria-controls={`see-more-license-modal-${code}-${regionSk}`}
      >
        {seeMore ? labels?.seeLess : labels?.seeMore}
      </button>
    ),
    [
      code,
      labels?.seeLess,
      labels?.seeMore,
      regionSk,
      seeMore,
      seeMoreButtonClasses,
      seeMoreFunction
    ]
  );

  useEffect(() => {
    if (seeMore) {
      notesAndMessagesRef.current?.focus();
    }
  }, [seeMore]);
  //#endregion

  //#region Edit License feature
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState<LicenseData | undefined>(license);

  const complianceGroupOptions = useMemo(
    () =>
      getComplianceGroupOptions(
        creditJurisdictions,
        editingData?.creditClass_SK,
        editingData?.creditRegion_SK
      ),
    [creditJurisdictions, editingData]
  );

  const creditJurisdiction = useMemo(
    () =>
      findCreditJurisdiction(
        creditJurisdictions,
        license?.creditClass_SK,
        license?.creditRegion_SK
      ),
    [creditJurisdictions, license]
  );

  const licenseNumberSettings = useMemo(
    () =>
      getLicenseNumberSettings(
        creditJurisdictions,
        editingData?.creditClass_SK,
        editingData?.creditRegion_SK
      ),
    [creditJurisdictions, editingData]
  );

  const enrichedData = useMemo(
    () => getEnrichedLicenseData(editingData, creditJurisdiction),
    [creditJurisdiction, editingData]
  );

  const handleOnSave = useCallback(() => {
    handleOnSaveEditLicense?.(enrichedData);
    creditDetailSectionRef?.current?.focus();
  }, [creditDetailSectionRef, enrichedData, handleOnSaveEditLicense]);

  const handleOnDelete = useCallback(() => {
    setIsEditing(false);
    handleOnDeleteLicense?.(editingData);
    creditDetailSectionRef?.current?.focus();
  }, [creditDetailSectionRef, editingData, handleOnDeleteLicense]);

  const handleLoggedOutRemove = useCallback(() => {
    if (!license?.creditRegion_SK) return;
    handleLoggedOutRemoveLicense?.(license.creditRegion_SK);
    creditDetailSectionRef?.current?.focus();
  }, [creditDetailSectionRef, handleLoggedOutRemoveLicense, license?.creditRegion_SK]);
  //#endregion

  let cardLayout;

  if (!modalVariation) {
    cardLayout = (
      <>
        <div className="flex items-center justify-between border-b border-gray-light pb-4 lg:pb-6">
          <h2 className={descriptionClasses}>{description}</h2>
          {allowEdit && (
            <>
              {!isLoggedOut ? (
                <EditLicenseModal
                  customModalId={`edit-license-modal-${regionSk}`}
                  open={isEditing}
                  setOpen={setIsEditing}
                  data={enrichedData}
                  onDataChange={setEditingData}
                  onDelete={handleOnDelete}
                  onSave={handleOnSave}
                  complianceGroupOptions={complianceGroupOptions}
                  licenseNumberSettings={licenseNumberSettings}
                  triggerButton={
                    <button
                      onClick={() =>
                        trackEditLicenseEvent({ context, value: license?.creditRegion ?? '' })
                      }
                      aria-label={`${labels?.edit} ${description}`}
                      className={editButtonClasses}
                      aria-controls={`edit-license-modal-${regionSk}`}
                    >
                      {labels?.edit}
                    </button>
                  }
                />
              ) : (
                <button
                  aria-label={`Remove ${description}`}
                  className={editButtonClasses}
                  onClick={() => handleLoggedOutRemove()}
                >
                  {labels?.remove}
                </button>
              )}
            </>
          )}
        </div>
        <div className="mb-4 lg:mb-6">
          <div className={statusClasses}>
            {statusIcon}
            <h3>{statusDescription}</h3>
          </div>

          <div className="my-2 lg:mt-[10px]">
            {totalOfferedFormatted}
            <ul className={creditListClasses} aria-label={labels?.credits}>
              {credits?.map(mapCreditsToListItems)}
            </ul>
          </div>
          {expirationDateFormatted && (
            <p
              className={expirationDateClasses}
            >{`${labels?.expirationDate} ${expirationDateFormatted}`}</p>
          )}
        </div>
        {(regionMessage || notes) && (
          <div tabIndex={-1} className={combinedNotesAndMessagesClasses}>
            {combinedNotesAndMessage && (
              <div
                ref={noteContainerRef}
                className={notesAndMessageClasses}
                dangerouslySetInnerHTML={{ __html: combinedNotesAndMessage }}
              />
            )}
            {showSeeMoreButton && seeMoreButton}
          </div>
        )}
      </>
    );
  } else {
    cardLayout = (
      <Collapsible.Root open={expanded} onOpenChange={setExpanded}>
        <Collapsible.Trigger
          className={classnames('flex w-full items-center justify-between', {
            'border-b border-gray-light pb-4 lg:pb-6': expanded
          })}
        >
          <div className="flex w-1/2 items-center gap-2">
            {statusIcon}
            <h2 className={classnames(descriptionClasses, 'line-clamp-1 w-3/4 text-start')}>
              {description}
            </h2>
          </div>
          <div
            className={classnames('text-2 flex w-1/2 items-center justify-end gap-2', {
              'text-gray-dark': variation === Theme.Light,
              'text-gray-medium': variation === Theme.Dark
            })}
          >
            <p>{getModalVariationCreditSummaryText()}</p>
            <Icon size="medium" name={expanded ? 'chevron-up' : 'chevron-down'} />
          </div>
        </Collapsible.Trigger>
        <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown">
          <div>
            <div className="mb-4 lg:mb-6">
              <div className={statusClasses}>
                <h3>{statusDescription}</h3>
              </div>
              <div className="my-2 lg:mt-[10px]">
                {totalOfferedFormatted}
                <ul className={creditListClasses} aria-label={labels?.credits}>
                  {credits?.map(mapCreditsToListItems)}
                </ul>
              </div>
              {expirationDateFormatted && (
                <p
                  className={expirationDateClasses}
                >{`${labels?.expirationDate} ${expirationDateFormatted}`}</p>
              )}
            </div>
            {(regionMessage || notes) && (
              <div tabIndex={-1} className={combinedNotesAndMessagesClasses}>
                {combinedNotesAndMessage && (
                  <div dangerouslySetInnerHTML={{ __html: combinedNotesAndMessage }} />
                )}
              </div>
            )}
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    );
  }

  return (
    <article ref={cardRef} className={cardLicenseClasses}>
      {cardLayout}
    </article>
  );
};

export default CreditLicenseCard;
