import { ILaunchRequestProps, useLaunchMutation } from '@/redux/api/client/launch';
import { useCallback } from 'react';
import { makeToastProblemMessage } from './util';
import { useLazyGetUpcomingRegistrationsQuery } from '@/redux/api/client/registration';
import useToast from '../useToast';
import { AnalyticsContext } from '@/analytics/constants';
import { useFetchAnalyticsItems } from '@/analytics/useFetchAnalyticsItems';
import { trackCardLaunchAProgramEvent } from '@/analytics/cards';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { useAppDispatch } from '..';
import libraryEndpoints from '@/redux/api/client/library';
import { useLibraryItems } from '../PDP/useLibraryItems';
import { evictCreditTrackerCode, setCreditTrackerCode } from '../useCreditTrackerCode';

export const useLaunch = (context?: AnalyticsContext, creditTrackerCode?: string) => {
  const dispatch = useAppDispatch();
  const [launchMutation, { isLoading, isSuccess, isError }] = useLaunchMutation();
  const { showFailMessage } = useToast();
  const [regTrigger] = useLazyGetUpcomingRegistrationsQuery();
  const { getAnalyticItem } = useFetchAnalyticsItems();
  const { hydrateLibrary, selectLibraryItem } = useLibraryItems();

  const trackEvent = useCallback(
    async (
      code: string,
      context?: AnalyticsContext,
      alreadyPurchasedLaunchUrl?: string,
      itemAnalyticsModel?: ItemAnalyticsModel
    ) => {
      const data = itemAnalyticsModel ?? (await getAnalyticItem(code));

      if (data) {
        const isZeroDollar = (!data.price || data.price === 0) && !alreadyPurchasedLaunchUrl;
        trackCardLaunchAProgramEvent(data, context, isZeroDollar);
      }
    },
    [getAnalyticItem]
  );

  const launch = useCallback(
    async (
      code?: string,
      isLiveUnregLaunch?: boolean,
      alreadyPurchasedLaunchUrl?: string,
      itemAnalyticsModel?: ItemAnalyticsModel
    ) => {
      if (!code) return;

      if (!alreadyPurchasedLaunchUrl) {
        const segmentLibraryData = selectLibraryItem(code);
        alreadyPurchasedLaunchUrl = segmentLibraryData?.watchUrl ?? undefined;
      }

      if (context && code) {
        trackEvent(code, context, alreadyPurchasedLaunchUrl, itemAnalyticsModel);
      }

      if (creditTrackerCode) {
        setCreditTrackerCode(code, creditTrackerCode);
      }

      if (alreadyPurchasedLaunchUrl) {
        window.open(alreadyPurchasedLaunchUrl, '_blank');
        return;
      }

      const windowRef = window.open('/launching', '_blank');
      const launchRequest: ILaunchRequestProps = {
        code,
        body: {
          // TODO: SIREHOME-1075 support sourceCode/keyCode
          sourcecode: '',
          keycode: ''
        }
      };
      const launchResponse = await launchMutation(launchRequest);
      if ('error' in launchResponse) {
        const toastContent = makeToastProblemMessage(
          'launch',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (launchResponse.error as any)?.originalStatus
        );
        showFailMessage(toastContent, 'alert');
        windowRef && windowRef.close();
      } else if ('data' in launchResponse && windowRef) {
        if (launchResponse.data.launchUrl) {
          windowRef.location = launchResponse.data.launchUrl;
          hydrateLibrary([code]);
          evictCreditTrackerCode(code);
        } else {
          const toastContent = makeToastProblemMessage('launch');
          showFailMessage(toastContent, 'alert');
          windowRef.close();
        }
        if (isLiveUnregLaunch) regTrigger();
        dispatch(
          libraryEndpoints.util.invalidateTags([
            { type: 'LibraryItem', id: code },
            { type: 'Library', id: 'List' }
          ])
        );
      }
    },
    [
      context,
      creditTrackerCode,
      launchMutation,
      selectLibraryItem,
      trackEvent,
      showFailMessage,
      regTrigger,
      dispatch,
      hydrateLibrary
    ]
  );

  return { launch, isLoading, isSuccess, isError };
};
