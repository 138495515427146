import type { CreditClassPage as CreditClassPageProps } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';
import withNav from '@/components/hocs/withNav';
import CreditClassContent from '@/components/blocks/CreditInfo/snippets/CreditClassContent';
const CreditClassPage: React.FC<CreditClassPageProps> = props => {
  const { mainContentArea, name } = props;

  return (
    <>
      <div className="mb-16">
        <ContentArea
          components={mainContentArea}
          componentsProps={{
            pageName: name
          }}
        />
      </div>
      <div className="container">
        <CreditClassContent {...props} />
      </div>
    </>
  );
};

const CreditClassPageComponent = withNav(CreditClassPage);
CreditClassPageComponent.displayName = 'CreditClassPage';
export default CreditClassPageComponent;
