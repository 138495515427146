import type { LiveProgramSeminarVariation as LiveProgramSeminarVariationProps } from '@/@types/content';
import withNav from '@/components/hocs/withNav';
import LiveProgramPDP from '@/components/ui/PDP/LiveProgramPDP';
import {
  enrichLiveVariantType,
  LiveVariationFormat,
  useLiveProgramCatalogRelations
} from '@/hooks/PDP/useProgramCatalogRelations';
import { AnalyticsContext } from '@/analytics/constants';

const LiveProgramSeminarVariationComponent = (variant: LiveProgramSeminarVariationProps) => {
  const { relations: catalogRelations, isLoading } = useLiveProgramCatalogRelations(variant);
  const initialVariant: LiveVariationFormat =
    catalogRelations.variants.find(v => v.content.code === variant.code) ??
    enrichLiveVariantType(variant, 'LiveProgramSeminarVariation')!;

  return (
    <LiveProgramPDP
      variant={initialVariant}
      catalogRelations={catalogRelations}
      loadingCatalogRelations={isLoading}
      context={AnalyticsContext.PDP}
    />
  );
};

const LiveProgramSeminarVariation = withNav(LiveProgramSeminarVariationComponent);
export default LiveProgramSeminarVariation;
