import * as Accordion from '@radix-ui/react-accordion';
import { forwardRef, memo, ReactNode } from 'react';
import Icon from '@/components/ui/Icon';
import Text from '@/components/cms/Text';

type CreditInfoAccordionItemProps = {
  title: ReactNode | string;
  value: string;
  content: JSX.Element;
  isExpanded?: boolean;
  onClick?: (value: string) => void;
};

const CreditInfoAccordionItem = memo(
  forwardRef<HTMLDivElement, CreditInfoAccordionItemProps>(
    ({ content, title, value, isExpanded, onClick }, ref) => {
      return (
        <Accordion.Item
          className="mt-2 overflow-hidden rounded-lg border border-gray-lightest bg-gray-lightest transition-colors hover:border-silver hover:bg-gray-light"
          value={value}
          key={`accordion-item-${value}`}
          ref={ref}
        >
          <Accordion.Header className="flex">
            <Accordion.Trigger
              className="flex w-full items-center justify-between gap-4 px-4 py-6 sm:px-6 lg:px-8"
              onClick={() => onClick && onClick(value)}
            >
              <Text element="span" propertyName="Title" className="text-1 font-medium">
                {title}
              </Text>
              <span className="flex size-8 shrink-0 items-center justify-center rounded-full border border-black/30">
                <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} size="medium" />
              </span>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="data-[state=closed]:animate-accordionSlideUp data-[state=open]:animate-accordionSlideDown">
            {content}
          </Accordion.Content>
        </Accordion.Item>
      );
    }
  )
);

CreditInfoAccordionItem.displayName = 'CreditInfoAccordionItem';
export default CreditInfoAccordionItem;
