import { AnalyticsContext } from '@/analytics/constants';
import { trackSelectItem } from '@/analytics/ecommerce';
import { useFetchAnalyticsItems } from '@/analytics/useFetchAnalyticsItems';
import Link from '@/components/ui/Link';
import { useCallback } from 'react';

export type BaseBoxPanelHeaderProps = {
  productCode?: string;
  context?: AnalyticsContext;
  title?: string;
  seeDetailsLink?: string;
};

const PanelHeader: React.FC<BaseBoxPanelHeaderProps> = ({
  productCode,
  title,
  seeDetailsLink,
  context
}) => {
  const { getAnalyticItem } = useFetchAnalyticsItems();

  const trackEvent = useCallback(
      async (code: string, context?: AnalyticsContext) => {
        const data = await getAnalyticItem(code)
        if (data) {
          trackSelectItem(data, context);
        }
    }, [getAnalyticItem])

  const trackSeeDetails = useCallback(() => {
    if(productCode && context)
      trackEvent(productCode, context)
  }, [context, productCode, trackEvent]);

  return (
    <div className="container flex flex-col gap-2 lg:px-10"
    data-component={"PanelHeader"}>
      {title && <h3 className="heading-6-medium">{title}</h3>}
      {!!seeDetailsLink && (
        <Link
          text="See details"
          variant="text-link-1"
          className="text-gray-dark"
          href={seeDetailsLink}
          onClick={trackSeeDetails}
        />
      )}
    </div>
  );
};

export default PanelHeader;
