import { NudgeAnalyticsContext } from '@/analytics/constants';
import { Messages, Nudge } from './types';
import { getIsDismissed } from '@/hooks/useDismissable';
import { addMonths, differenceInMonths, parseISO } from 'date-fns';

export const isMissingBlockLevelNudge = (nudge?: Nudge) => nudge?.type === 'missingBlockLevel';

export const isMissingFieldLevelNudge = (nudge?: Nudge) => nudge?.type === 'missingFieldLevel';

export const isExpiredBlockLevelNudge = (nudge?: Nudge) => nudge?.type === 'expiredBlockLevel';

export const isExpiredSingleCardNudge = (nudge?: Nudge) => nudge?.type === 'expiredSingleCard';

export const isStaleNudge = (nudge?: Nudge) => nudge?.type === 'stale';

export const isEmptyStateNudge = (nudge?: Nudge) => nudge?.type === 'emptyState';

export const getNudgeKey = (sectionName: string, field?: string) =>
  `nudge--${sectionName}${field ? `--${field}` : ''}`;

export const parseMessages = (messagesJSON?: string) => {
  try {
    return JSON.parse(messagesJSON || '{}') as Messages;
  } catch (e) {
    return {};
  }
};

export const getMissingFields = <T extends Record<string, unknown>>(
  messages: Messages,
  sectionData: T,
  skipField?: (field: string) => boolean
) => {
  if (!messages.missingFieldLevel) {
    return [];
  }
  const fieldsToCheck = Object.keys(messages.missingFieldLevel);
  return fieldsToCheck.filter(field => {
    if (skipField?.(field)) {
      return false;
    }
    const data = sectionData[field];
    if (Array.isArray(data)) {
      return data.length === 0;
    }
    return !data;
  });
};

export const getMissingBlockLevelNudge = (
  messages: Messages,
  sectionName: string,
  context: NudgeAnalyticsContext,
  onAccept?: () => void
): Nudge | undefined => {
  const key = getNudgeKey(sectionName);
  const message = messages.missingBlockLevel;
  if (!message || getIsDismissed(key)) {
    return undefined;
  }
  return { type: 'missingBlockLevel' as const, message, key, onAccept, context };
};

export const getMissingFieldLevelNudge = (
  messages: Messages,
  sectionName: string,
  context: NudgeAnalyticsContext,
  field: string,
  onAccept?: () => void
): Nudge | undefined => {
  const key = getNudgeKey(sectionName, field);
  const message = messages.missingFieldLevel?.[field];
  if (!message || getIsDismissed(key)) {
    return undefined;
  }
  return { type: 'missingFieldLevel' as const, message, field, key, onAccept, context };
};

export const getExpiredCardNudge = (
  messages: Messages,
  expiredCardsIds: number[],
  sectionName: string,
  context: NudgeAnalyticsContext,
  onAccept?: () => void
): Nudge | undefined => {
  if (
    !expiredCardsIds?.length ||
    (expiredCardsIds.length === 1 && !messages.expiredSingleCard) ||
    (expiredCardsIds.length > 1 && !messages.expiredBlockLevel)
  ) {
    return undefined;
  }
  const key = getNudgeKey(sectionName, expiredCardsIds.join('-'));
  if (getIsDismissed(key)) {
    return undefined;
  }
  if (expiredCardsIds.length > 1) {
    return {
      type: 'expiredBlockLevel' as const,
      message: messages.expiredBlockLevel!,
      key,
      onAccept,
      context
    };
  }
  return {
    type: 'expiredSingleCard' as const,
    message: messages.expiredSingleCard!,
    key,
    onAccept,
    cardId: expiredCardsIds[0],
    context
  };
};

export const getStaleNudge = <T extends Record<string, unknown>>(
  messages: Messages,
  sectionData: T,
  sectionName: string,
  context: NudgeAnalyticsContext,
  timeUntilStale: number,
  onAccept?: () => void
): Nudge | undefined => {
  const message = messages.stale;
  if (message && timeUntilStale > 0 && 'lastModifiedDate' in sectionData) {
    const lastModifiedDateStr = sectionData['lastModifiedDate'] as string;
    if (lastModifiedDateStr) {
      const lastModifiedDate = parseISO(lastModifiedDateStr);
      const currentDate = new Date();
      const monthsDiff = differenceInMonths(currentDate, lastModifiedDate);
      if (monthsDiff >= timeUntilStale) {
        const key = getNudgeKey(sectionName, `lastModifiedDate-${lastModifiedDateStr}`);
        if (!getIsDismissed(key)) {
          return {
            type: 'stale' as const,
            message,
            key,
            onAccept,
            cookieExpiration: addMonths(currentDate, timeUntilStale),
            context
          };
        }
      }
    }
  }
  return undefined;
};

export const getEmptyStateNudge = <T extends Record<string, unknown>>(
  messages: Messages,
  sectionData: T,
  sectionName: string,
  context: NudgeAnalyticsContext,
  onAccept?: () => void
): Nudge | undefined => {
  const key = getNudgeKey(sectionName, 'empty-state');
  const message = messages.emptyState;
  if (!message || getIsDismissed(key)) {
    return undefined;
  }
  if ('items' in sectionData) {
    const items = sectionData['items'];
    if (Array.isArray(items) && items.length === 0) {
      return { type: 'emptyState' as const, message, key, onAccept, context };
    }
  }
  return undefined;
};

export const ProfileNudgeSectionNames = {
  About: 'about',
  Contact: 'contact',
  Faculties: 'faculties',
  Address: 'address',
  Payment: 'payment',
  Industries: 'industries',
  PracticeAreas: 'practice-areas',
  Preferences: 'preferences',
  CertificatePreferences: 'certificate-preferences',
  License: 'license'
};
