import { useCallback, useEffect, useState } from 'react';
import { useLazyGetLicensesQuery } from '@/redux/api/client/userLicenses';
import Modal from '@/components/ui/Modal/Modal';
import {
  useGetCreditJurisdictionsQuery,
  useLazyGetProgramCreditsQuery
} from '@/redux/api/client/cle';
import ViewCreditsForYourLicenses from './ViewCreditsForYourLicenses';
import ViewCreditsForAllJurisdiction from './ViewCreditsForAllJurisdiction';
import { AnalyticsContext } from '@/analytics/constants';
import { useAuth } from 'react-oidc-context';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { trackLaunchCreditDetailsEvent } from '@/analytics/library';
import { skipToken } from '@reduxjs/toolkit/query';
import { useOnDeleteLicense, useOnSaveEditLicense } from '../LicensesPage/hooks';
import { trackViewAllJurisdictions } from '@/analytics/jurisdictions';

export type SearchCreditDetailsModalProps = {
  pk: string;
  triggerButton: JSX.Element;
  context?: AnalyticsContext;
  itemAnalyticsModel?: ItemAnalyticsModel;
};

const SearchCreditDetailsModal = ({
  pk,
  triggerButton,
  context,
  itemAnalyticsModel
}: SearchCreditDetailsModalProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const [viewAllJurisdictions, setViewAllJurisdictions] = useState(false);
  const auth = useAuth();

  const productKey = pk ?? '';
  const [isProcessingLicense, setIsProcessing] = useState(false);
  const [getLicenses, { data: licenses, isFetching: isLicenseFetching }] =
    useLazyGetLicensesQuery();
  const [getProgramCredits, { currentData: credits, isFetching: isCreditsFetching }] =
    useLazyGetProgramCreditsQuery();
  const { data: creditJurisdictions, isLoading: isLoadingJudisdictions } =
    useGetCreditJurisdictionsQuery(isOpen ? undefined : skipToken);

  const handleOnViewAllJurisdictions = useCallback(() => {
    trackViewAllJurisdictions();
    setViewAllJurisdictions(true);
  }, []);

  const handleOnBack = useCallback(() => {
    setViewAllJurisdictions(false);
  }, []);

  const handleOnSaveEditLicense = useOnSaveEditLicense({
    setIsProcessing,
    context: AnalyticsContext.Search
  });
  const handleOnDeleteLicense = useOnDeleteLicense({
    setIsProcessing,
    context: AnalyticsContext.Search
  });

  useEffect(() => {
    if (isOpen) {
      if (auth.isAuthenticated && productKey) getLicenses();

      getProgramCredits(productKey);
    }
  }, [isOpen, pk, getLicenses, getProgramCredits, productKey, auth.isAuthenticated]);

  useEffect(() => {
    if (isOpen) {
      if (context && itemAnalyticsModel) {
        trackLaunchCreditDetailsEvent(itemAnalyticsModel, context);
      }
      setViewAllJurisdictions(false);
    }
  }, [context, isOpen, pk, itemAnalyticsModel]);

  const hasLicenses = licenses?.items && licenses.items.length > 0;
  const isLoading =
    isLicenseFetching || isCreditsFetching || isLoadingJudisdictions || isProcessingLicense;
  const creditList = credits?.jurisdictions?.map(cr => ({ ...cr, code: pk })) ?? [];

  return (
    <Modal open={isOpen} setOpen={setOpen}>
      <Modal.Trigger asChild>{triggerButton}</Modal.Trigger>
      {!viewAllJurisdictions && hasLicenses ? (
        <ViewCreditsForYourLicenses
          credits={creditList}
          licenses={licenses?.items ?? []}
          onViewAllJurisdictions={handleOnViewAllJurisdictions}
          isLoading={isLoading}
          creditJurisdictions={creditJurisdictions ?? []}
          handleOnDeleteLicense={handleOnDeleteLicense}
          handleOnSaveEditLicense={handleOnSaveEditLicense}
          allowEdit={
            context !== AnalyticsContext.Search && context !== AnalyticsContext.SearchOverlay
          }
        />
      ) : (
        <ViewCreditsForAllJurisdiction
          code={pk}
          credits={credits?.jurisdictions ?? []}
          onBack={hasLicenses ? handleOnBack : undefined}
          isLoading={isLoading}
        />
      )}
    </Modal>
  );
};

export default SearchCreditDetailsModal;
