import Cookie from 'js-cookie';
import { PLIPromo } from './usePLIPromoCookie';
import { useSearchParams } from 'react-router-dom';

const CREDIT_TRACKER_CODE_COOKIE_KEY = 'creditTrackerCodes';

const isLocal = (hostname: string = window.location.hostname) =>
  ['localhost', '127.0.0.1', '', '::1'].includes(hostname);

const CREDIT_TRACKER_CODE_COOKIE_OPTS: Cookies.CookieAttributes = {
  domain: isLocal() ? '' : 'pli.edu',
  sameSite: 'lax',
  expires: 1
};

type CreditTrackerCodeCookie = { [itemSk: string]: PLIPromo };

export const useCreditTrackerCodeSearchParam = () => {
  const [searchParams] = useSearchParams();
  return searchParams.get('creditTrackerCode') ?? undefined;
};

const getCreditTrackerCodeCookie = (): CreditTrackerCodeCookie => {
  const creditTrackerCodeCookie = Cookie.get(CREDIT_TRACKER_CODE_COOKIE_KEY);
  if (!creditTrackerCodeCookie) {
    return {};
  }

  try {
    return JSON.parse(creditTrackerCodeCookie);
  } catch {
    console.error(`Failed to parse ${CREDIT_TRACKER_CODE_COOKIE_KEY} cookie`);
    return {};
  }
};

export const setCreditTrackerCode = (variationCode: string, creditTrackerCode: string) => {
  const creditTrackerCodeCookie = getCreditTrackerCodeCookie();

  // Check if valid/sanitize
  if (!creditTrackerCode.length) {
    return;
  }
  const splitTCode = creditTrackerCode.split('_');
  if (splitTCode.length != 2) {
    console.error('Invalid credit tracker code');
    return;
  }

  const [sourcecode, keycode] = splitTCode;
  creditTrackerCodeCookie[variationCode] = { sourcecode, keycode };

  Cookie.set(
    CREDIT_TRACKER_CODE_COOKIE_KEY,
    JSON.stringify(creditTrackerCodeCookie),
    CREDIT_TRACKER_CODE_COOKIE_OPTS
  );
};

export const evictCreditTrackerCode = (variationCode: string) => {
  const creditTrackerCodeCookie = getCreditTrackerCodeCookie();
  if (variationCode in creditTrackerCodeCookie) {
    delete creditTrackerCodeCookie[variationCode];
    Cookie.set(
      CREDIT_TRACKER_CODE_COOKIE_KEY,
      JSON.stringify(creditTrackerCodeCookie),
      CREDIT_TRACKER_CODE_COOKIE_OPTS
    );
  }
};
