import { useCallback, useEffect, useState } from 'react';
import SelectorButton from '../../Buttons/SelectorButton';
import Modal, { ModalProps } from '../../Modal/Modal';
import ModalContent from '../../Modal/ModalContent';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { AnalyticsContext } from '@/analytics/constants';
import { trackShareEvent } from '@/analytics/share';

export type ShareModalProps = ModalProps & {
  variantTitle: string;
  variantUrl: string;
  customModalId?: string;
  itemAnalyticsModel?: ItemAnalyticsModel;
  context?: AnalyticsContext;
};

const ShareModal = ({
  open,
  setOpen,
  variantTitle,
  variantUrl,
  customModalId,
  itemAnalyticsModel,
  context
}: ShareModalProps): JSX.Element => {
  const mailToString = `mailto:?subject=${variantTitle}&body=${variantTitle} ${variantUrl}`;
  const [copied, setCopied] = useState(false);
  const [clicked, setClicked] = useState(false);

  type methodTypes = 'email' | 'link';

  const pushShareAnalitics = useCallback(
    (method: methodTypes) => {
      if (itemAnalyticsModel?.id && context) trackShareEvent(context, method, itemAnalyticsModel);
    },
    [itemAnalyticsModel, context]
  );

  useEffect(() => {
    if (!open) setCopied(false);
  }, [open]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <ModalContent title="Share" fitContent customModalId={customModalId}>
        <div className={`flex flex-col ${clicked ? 'gap-y-3.5' : 'gap-y-4'}`}
              data-component={"ModalContent"}
        >
          <SelectorButton
            iconName={'copy'}
            label={'Copy link'}
            color="light-gray"
            onClick={() => {
              pushShareAnalitics('link');
              navigator.clipboard.writeText(variantUrl);
              setCopied(true);
            }}
            secondaryLabel={copied ? 'Copied' : undefined}
            onMouseUp={() => setClicked(false)}
            onMouseDown={() => setClicked(true)}
          />
          <SelectorButton
            iconName={'email'}
            label={'Send email'}
            color="light-gray"
            onClick={() => {
              pushShareAnalitics('email');
              window.location.href = mailToString;
            }}
            onMouseUp={() => setClicked(false)}
            onMouseDown={() => setClicked(true)}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
