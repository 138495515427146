import classnames from 'classnames';
import { useEffect, useState } from 'react';

export type IconSizes = 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'xx-large';

export interface IconProps {
  name: string;
  size?: IconSizes;
  className?: string;
  url?: string;
}

const Icon = ({ name, size = 'large', className, url }: IconProps) => {
  const [SvgIcon, setSvgIcon] = useState<React.ElementType | null>(null);

  const iconClasses = classnames(
    {
      'size-10': size === 'xx-large',
      'size-8': size === 'x-large',
      'size-6': size === 'large',
      'size-5': size === 'medium',
      'size-4 min-w-4': size === 'small',
      'size-3': size === 'x-small'
    },
    className
  );

  useEffect(() => {
    async function loadIcon() {
      try {
        // Dynamically import the SVG as a React component
        const importedIcon = await import(`../../assets/icons/${name}.svg?react`);
        setSvgIcon(() => importedIcon.default);
      } catch (error) {
        console.error(`No icon found for name: ${name}`, error);
        setSvgIcon(null);
      }
    }

    loadIcon();
  }, [name]);

  if (url) {
    return <img src={url} className={iconClasses} alt={name} />;
  }

  if (!SvgIcon) {
    // Optionally, return a default icon or null if the specified icon cannot be found
    return <span className={classnames('inline-block', iconClasses)} />;
  }

  return <SvgIcon className={iconClasses} role="presentation" />;
};

export default Icon;
