import { useEffect, useMemo, useState } from 'react';
import OrderHistoryTable from '@/components/ui/Tables/OrderHistoryTable';
import Button from '../Buttons/Button';
import TagButton from '@/components/ui/Buttons/TagButton';
import DropdownSelect from '@/components/ui/DropdownSelect';
import { useGetOrderHistoryQuery } from '@/redux/api/client/orderHistory';
import {
  FORMAT_VALUE,
  getOrderHistoryInfo,
  ORDER_HISTORY_FORMAT_OPTIONS,
  ORDER_HISTORY_SORT_OPTIONS,
  SORT_VALUE
} from './helpers';
import classnames from 'classnames';
import { OrderHistoryBlock as OrderHistoryBlockProps } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import Shimmer from '../Shimmer';
import { trackOrderHistoryFilterEvent, trackOrderHistorySortEvent } from '@/analytics/profile';

const DEFAULT_PAGE_SIZE = 20;

const OrderHistoryFiltersTable: React.FC<OrderHistoryBlockProps> = ({
  emptyStateContent,
  filteringEmptyState
}) => {
  const [pagesRequested, setPagesRequested] = useState(1);
  const [sortSelected, setSortSelected] = useState(SORT_VALUE.NEWEST);
  const [formatSelected, setFormatSelected] = useState(FORMAT_VALUE.ALL);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { data, isFetching, isError } = useGetOrderHistoryQuery({
    pageNumber: pagesRequested,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: sortSelected,
    format: formatSelected === FORMAT_VALUE.ALL ? undefined : formatSelected // pass empty string for "all" formats
  });

  const debouncedFetching = useDebounceLoading(isFetching) && !isLoadingMore;
  const debouncedLoadingMore = useDebounceLoading(isLoadingMore);

  useEffect(() => {
    setIsLoadingMore(false);
  }, [data]);

  useEffect(() => {
    if (isError) {
      throw new Error('Error fetching order history');
    }
  }, [isError]);

  const orderHistoryTableInfo = useMemo(
    () => getOrderHistoryInfo(data?.items ?? []),
    [data?.items]
  );
  const dropdownWrapperStyles = classnames(
    'order-1 flex gap-2 pb-8 sm:order-2 sm:pb-0 [&>*]:flex [&>button]:w-1/2 [&>button]:justify-between [&>button]:sm:w-fit'
  );

  const count = data?.summary?.count ?? 0;
  const transactionsNumber = `${count} transaction${count !== 1 ? 's' : ''}`;

  const handleLoadMoreClick = () => {
    setIsLoadingMore(true);
    setPagesRequested(prevState => prevState + 1);
  };

  const handleFilterOrSortChange = (
    value: string,
    setStateFn: React.Dispatch<React.SetStateAction<string>>
  ) => {
    // Reset the order history items and page number when changing the filter or sort
    setPagesRequested(1);

    setStateFn(value);
  };

  const hasResults = !!data?.items?.length;
  const hasNoResultsFromFilters = !hasResults && !!data?.summary?.totalCollectionSize;

  const showUIElements = hasResults || hasNoResultsFromFilters || isFetching;

  return (
    <>
      <section className="pb-12 pt-8 lg:pb-20 lg:pt-16"       data-component={"OrderHistoryFiltersTable"}      >
        {showUIElements && (
          <div className="flex flex-col pb-8 sm:flex-row sm:items-center sm:justify-between lg:pb-10">
            {debouncedFetching ? (
              <Shimmer className="h-[20px] w-[120px]" />
            ) : (
              <p className="label order-2 flex whitespace-nowrap uppercase text-gray-dark sm:order-1">
                {transactionsNumber}
              </p>
            )}

            <div className={dropdownWrapperStyles}>
              <DropdownSelect
                button={({ isOpen, value, ...props }) => (
                  <TagButton
                    aria-label="See more options"
                    isActive={isOpen}
                    icon={isOpen ? 'chevron-up' : 'chevron-down'}
                    styleType="solid"
                    {...props}
                    label={
                      value ? (
                        <div className="flex">
                          <span>View:</span>
                          <span className="pl-1">{value}</span>
                        </div>
                      ) : (
                        'View'
                      )
                    }
                  />
                )}
                menuStyle="standalone"
                options={ORDER_HISTORY_FORMAT_OPTIONS}
                value={formatSelected}
                onValueChange={value => {
                  trackOrderHistoryFilterEvent(value);
                  handleFilterOrSortChange(value, setFormatSelected);
                }}
              />

              <DropdownSelect
                button={({ isOpen, value, ...props }) => (
                  <TagButton
                    aria-label="See more options"
                    isActive={isOpen}
                    icon={isOpen ? 'chevron-up' : 'chevron-down'}
                    styleType="solid"
                    {...props}
                    label={value}
                  />
                )}
                menuStyle="standalone"
                options={ORDER_HISTORY_SORT_OPTIONS}
                value={sortSelected}
                onValueChange={value => {
                  trackOrderHistorySortEvent(value);
                  handleFilterOrSortChange(value, setSortSelected);
                }}
              />
            </div>
          </div>
        )}
        {hasResults || debouncedFetching ? (
          <OrderHistoryTable
            isLoading={debouncedFetching}
            isLoadingMore={debouncedLoadingMore}
            tableHeader={orderHistoryTableInfo?.tableHeader}
            rows={orderHistoryTableInfo?.rows}
          />
        ) : (
          <ContentArea
            components={emptyStateContent}
            componentsProps={{
              hasSidePadding: false,
              hasTopPadding: false,
              contentOverride: hasNoResultsFromFilters
                ? {
                    heading: filteringEmptyState,
                    subheading: undefined
                  }
                : undefined
            }}
          />
        )}
        {data?.summary?.hasMore ? (
          <Button
            onClick={handleLoadMoreClick}
            className="mx-auto mt-20 flex"
            color="black"
            size="large"
            label="Load more"
          />
        ) : null}
      </section>
    </>
  );
};

export default OrderHistoryFiltersTable;
