import classnames from 'classnames';
import * as Dialog from '@radix-ui/react-dialog';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

import Button, { ButtonColor } from '@/components/ui/Buttons/Button';
import Icon from '@/components/ui/Icon';
import ModalEyebrow, { ModalEyebrowProps } from './ModalEyebrow';
import IconButton from '../IconButton';

export interface ModalContentProps {
  isL2Modal?: boolean;
  title?: string | React.ReactNode;
  subtitle?: string;
  eyebrow?: ModalEyebrowProps;
  backButtonLabel?: string;
  onBackButtonPress?: () => void;
  saveButtonLabel?: string;
  onSaveButtonPress?: () => void;
  dismissButtonLabel?: string | null;
  shouldDismissButtonClose?: boolean;
  onDismissButtonPress?: () => void;
  closeButtonLabel?: string;
  onCloseButtonPress?: () => void;
  isMultiStep?: boolean;
  fitContent?: boolean;
  saveButtonRightIcon?: string;
  saveButtonColor?: ButtonColor;
  containerClassName?: string;
  hasTransition?: boolean;
  isSubmitLoading?: boolean;
  customModalId?: string;
  contentRelativelyPositioned?: boolean;
  isLarge?: boolean;
}

const ModalContent = ({
  isL2Modal = false,
  title,
  saveButtonLabel,
  onSaveButtonPress,
  dismissButtonLabel,
  onDismissButtonPress,
  children,
  shouldDismissButtonClose = true,
  subtitle,
  eyebrow,
  backButtonLabel = 'Back',
  onBackButtonPress,
  closeButtonLabel = 'Close',
  onCloseButtonPress,
  isMultiStep,
  fitContent,
  saveButtonRightIcon,
  saveButtonColor = 'black',
  containerClassName,
  isSubmitLoading,
  hasTransition = true,
  contentRelativelyPositioned = false,
  customModalId,
  isLarge = false,
  ...props
}: React.PropsWithChildren<ModalContentProps>): JSX.Element => {
  const isXSmall = useMediaQuery(ScreenSizeQueries.xsOnly);
  const hasFooter = saveButtonLabel || dismissButtonLabel;

  const mobileStylesWithoutOverlay = classnames('fixed inset-0 z-modal bg-white', {
    'h-full': hasFooter
  });
  const stylesWithOverlay = classnames(
    'z-modal flex h-full sm:fixed sm:inset-0',
    hasTransition &&
      'group-data-[state=closed]:animate-[fadeOut_150ms_ease_forwards] group-data-[state=open]:animate-[fadeIn_150ms_ease_100ms_forwards] group-data-[state=closed]:opacity-100 group-data-[state=open]:opacity-0'
  );

  const modalContainerClassName = classnames(
    'relative flex h-full flex-col justify-between bg-white sm:m-auto sm:w-[576px] sm:overflow-hidden sm:rounded-lg',
    {
      'sm:h-fit': fitContent,
      'sm:h-[min(740px,85vh)]': !fitContent && !isLarge,
      'pb-2': hasFooter,
      'md:size-[calc(100%-160px)]': isLarge,
      'sm:max-h-[min(740px,85vh)] md:w-[628px]': !isLarge
    },
    containerClassName
  );

  const modalContentClasses = classnames('flex h-full min-h-0 flex-col overflow-y-clip', {
    relative: contentRelativelyPositioned
  });

  const overlayClasses = classnames(
    'fixed inset-0 z-[99998] sm:bg-black/30',
    hasTransition &&
      'data-[state=closed]:animate-[fadeOut_200ms_ease] data-[state=open]:animate-[fadeIn_200ms_ease]'
  );

  const l2ModalClasses = classnames({
    'opacity-0 group-data-[state=closed]:animate-panelL2SlideOut group-data-[state=open]:animate-panelL2SlideIn':
      isL2Modal
  });

  return (
    <Dialog.Portal>
      {!isXSmall && <Dialog.Overlay className={overlayClasses} />}
      <Dialog.Content
        {...props}
        className="group !opacity-100 data-[state=closed]:animate-[radixOut_200ms_ease]"
        {...(customModalId
          ? {
              id: customModalId,
              onCloseAutoFocus(event) {
                const target = event.target as HTMLElement;
                const targetId = target.getAttribute('id');
                const triggerElement: HTMLElement | null = document.querySelector(
                  `[aria-controls="${targetId}"]`
                );

                if (triggerElement) {
                  triggerElement.focus();
                }
              }
            }
          : {})}
      >
        <div className={isXSmall ? mobileStylesWithoutOverlay : stylesWithOverlay}       data-component={"ModalContent"}        >
          <div className={modalContainerClassName}>
            <div className={modalContentClasses}>
              <div className="flex justify-end px-6 py-8 sm:min-h-[88px] sm:pr-8">
                {onBackButtonPress && (
                  <IconButton
                    name="chevron-left"
                    className="mr-auto !text-black"
                    onClick={onBackButtonPress}
                    size="large"
                    label={backButtonLabel}
                  />
                )}
              </div>
              {(!!eyebrow || !!title || !!subtitle) && (
                <Dialog.Title className={classnames('mb-8 px-6 sm:px-16', l2ModalClasses)}>
                  {!!eyebrow && <ModalEyebrow {...eyebrow} />}
                  {!!title && (
                    <p
                      className={classnames('heading-4', { 'pt-2': !!eyebrow, 'pb-2': !!subtitle })}
                    >
                      {title}
                    </p>
                  )}
                  {!!subtitle && <p className="label max-w-[70%] text-gray-dark">{subtitle}</p>}
                </Dialog.Title>
              )}
              <div
                className={classnames(
                  'modal-scrollbar mr-2 flex flex-col overflow-y-scroll pl-6 pr-3 sm:pl-16 sm:pr-[52px]',
                  l2ModalClasses
                )}
              >
                <div
                  className={classnames({
                    'mb-10': hasFooter,
                    'mb-16': !hasFooter
                  })}
                >
                  {children}
                </div>
              </div>
            </div>
            <div className="relative bottom-0 shrink-0 max-sm:absolute max-sm:w-full">
              {hasFooter && (
                <>
                  <div className="pointer-events-none absolute inset-x-6 -top-8 h-8 bg-gradient-to-t from-white/80 to-transparent sm:inset-x-16" />
                  <div className="relative rounded bg-white px-6 sm:px-16">
                    {!!saveButtonLabel && !isMultiStep ? (
                      <Dialog.Close className="flex w-full" asChild>
                        <Button
                          label={saveButtonLabel}
                          color={saveButtonColor}
                          size="large"
                          className="w-full"
                          aria-label={saveButtonLabel}
                          onClick={onSaveButtonPress}
                          iconRight={saveButtonRightIcon}
                        />
                      </Dialog.Close>
                    ) : (
                      <Button
                        label={saveButtonLabel}
                        color="black"
                        size="large"
                        className="w-full"
                        aria-label={saveButtonLabel}
                        loading={isSubmitLoading ?? false}
                        onClick={onSaveButtonPress}
                        iconRight={saveButtonRightIcon}
                      />
                    )}
                    <div
                      className={classnames('flex justify-center', {
                        'h-14': !dismissButtonLabel
                      })}
                    >
                      {dismissButtonLabel &&
                        (shouldDismissButtonClose ? (
                          <Dialog.Close
                            className="text-1 pb-7 pt-4 underline hover:text-red sm:pb-8"
                            aria-label={dismissButtonLabel}
                            onClick={onDismissButtonPress}
                          >
                            {dismissButtonLabel}
                          </Dialog.Close>
                        ) : (
                          <button
                            className="text-1 pb-7 pt-4 underline hover:text-red sm:pb-8"
                            aria-label={dismissButtonLabel}
                            onClick={onDismissButtonPress}
                          >
                            {dismissButtonLabel}
                          </button>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="absolute right-0 top-0 px-6 py-8 sm:pr-8">
              <Dialog.Close
                aria-label={closeButtonLabel}
                className="mr-auto !text-black"
                onClick={onCloseButtonPress}
              >
                <Icon name="close" size="large" />
              </Dialog.Close>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
};

ModalContent.displayName = 'ModalContent';
export default ModalContent;
