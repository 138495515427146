export interface Suggestion {
  category?: string;
  text: string;
  filterGroup?: FILTERS;
  filterValue?: string;
}

import classnames from 'classnames';
import Tag from '../Tag';
import { xPadding } from './utils';
import { FILTERS } from '../SearchResults/constants';
import { SUGGESTIONS_ID } from './constants';

interface SearchSuggestionProps {
  suggestionList?: Suggestion[];
  searchText: string;
  onSuggestionSelect: (suggestion: Suggestion) => void;
  onListKeyDown: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  selectedIndex: number | null;
  listRef: React.RefObject<HTMLUListElement>;
}

const SearchSuggestion: React.FC<SearchSuggestionProps> = ({
  suggestionList,
  searchText,
  onSuggestionSelect,
  onListKeyDown,
  selectedIndex,
  listRef
}) => {
  return (
    <ul ref={listRef} role="listbox" id={SUGGESTIONS_ID} aria-label="Search suggestions list">
      {searchText &&
        suggestionList?.map((item, index) => (
          <li
            role="option"
            key={item.text}
            className={classnames(
              xPadding,
              'flex h-14 cursor-pointer items-center gap-2 hover:bg-gray-light focus:bg-gray-light focus:outline-none',
              {
                'bg-gray-lightest': index !== selectedIndex,
                'bg-gray-light': index === selectedIndex
              }
            )}
            onClick={() => onSuggestionSelect(item)}
            onKeyDown={onListKeyDown}
            tabIndex={0}
            aria-selected={index === selectedIndex}
          >
            <span className="text-1 font-medium">
              {item.text.startsWith(searchText) ? (
                <>
                  <strong>{searchText}</strong>
                  <span className="font-thin">{item.text.substring(searchText.length)}</span>
                </>
              ) : (
                item.text
              )}
            </span>
            {item.category && (
              <Tag variant="light" size="small">
                {item.category}
              </Tag>
            )}
          </li>
        ))}
    </ul>
  );
};

export default SearchSuggestion;
