import { useSelector } from 'react-redux';
import Panel from '@/components/ui/Panel';
import { RootState } from '@/redux/store';
import { UpcomingLiveProps } from '..';
import RegistrationItem from './RegistrationItem';
import Divider from '@/components/ui/Divider';
import VariantList from './VariantList';
import { Variant } from '@/redux/slices/pdpSlice';
import { useCallback, useEffect, useState } from 'react';
import Button from '@/components/ui/Buttons/Button';
import Link from '@/components/ui/Link';
import Shimmer from '@/components/ui/Shimmer';
import { getDiscountAdviceMessage } from '../../../helpers';

interface RegistrationUpdatePanelProps {
  labels: UpcomingLiveProps['labels'];
  isOpen: boolean;
  isPhoneMode: boolean;
  isShimmering: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onChange: (variant: Variant) => void;
  onCancel: (variant: Variant) => void;
  isLoading?: boolean;
  withinPanel?: boolean;
}

enum Step {
  ChangeSelection,
  ChangeConfirmation,
  CancelConfirmation
}

const RegistrationUpdatePanel: React.FC<RegistrationUpdatePanelProps> = ({
  labels,
  isOpen,
  isPhoneMode,
  isShimmering,
  onOpenChange,
  onChange,
  onCancel,
  isLoading,
  withinPanel = false
}) => {
  const selectedVariant = useSelector((state: RootState) => state.pdp.selectedVariant);
  const [step, setStep] = useState<Step>(Step.ChangeSelection);
  const [updatedVariant, setUpdatedVariant] = useState<Variant>();
  const [showLoading, setShowLoading] = useState<boolean>(isLoading ?? false);

  const nonRegisteredVariants = useSelector(
    (state: RootState) => state.pdp.catalogRelations?.variants
  )?.filter(p => !p.registered && !p.isExpired);

  useEffect(() => {
    if (isLoading) {
      setShowLoading(true);
    }

    if (!isLoading) {
      setShowLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isOpen) {
      setStep(Step.ChangeSelection);
      setUpdatedVariant(undefined);
    }
  }, [isOpen]);

  const typeLabels = {
    online: labels.online,
    ['in-person']: labels.inPerson,
    groupcast: labels.groupcast
  };

  const getHeader = useCallback(() => {
    switch (step) {
      case Step.ChangeSelection:
        return 'Change Registration';
      case Step.ChangeConfirmation:
        return 'Are you sure you want to change your registration?';
      case Step.CancelConfirmation:
        return 'Are you sure you want to cancel your registration?';
    }
  }, [step]);

  if (!selectedVariant) return null;

  const displayedPhoneNumber = window.env?.APP_SERVICE_PHONE_NUMBER;
  const formatedPhoneNumber = displayedPhoneNumber?.replace(/\./g, '');

  return (
    <Panel isOpen={isOpen} onOpenChange={onOpenChange} size="small">
      {!isPhoneMode && !isShimmering && (
        <Panel.Content
          dimBackground={!withinPanel}
          onBackButtonPress={
            step === Step.ChangeConfirmation || step === Step.CancelConfirmation
              ? () => setStep(Step.ChangeSelection)
              : undefined
          }
        >
          <Panel.Body className="pb-6">
            <h2 className="heading-6-medium mb-8">{getHeader()}</h2>
            <RegistrationItem
              label={typeLabels[selectedVariant.type!]}
              type={selectedVariant.type!}
              location={selectedVariant.location}
              address1={selectedVariant.address1}
              address2={selectedVariant.address2}
              startDate={selectedVariant.eventStartDate!}
              endDate={selectedVariant.eventEndDate}
              timezone={selectedVariant.timeZoneIdentifier!}
              state={Step[step]}
            />
            {step === Step.ChangeSelection && nonRegisteredVariants?.length ? (
              <div className="mt-16">
                <p className="text-1 font-bold">Select other way to attend</p>
                <Divider color="light" className="my-6" />
                <VariantList
                  labels={labels}
                  handleSelect={setUpdatedVariant}
                  selectedVariant={updatedVariant}
                />
              </div>
            ) : (
              <></>
            )}

            {step === Step.ChangeConfirmation && updatedVariant && (
              <div className="mt-11">
                <RegistrationItem
                  label={typeLabels[updatedVariant.type!]}
                  type={updatedVariant.type!}
                  location={updatedVariant.location}
                  address1={updatedVariant.address1}
                  address2={updatedVariant.address2}
                  startDate={updatedVariant.eventStartDate!}
                  endDate={updatedVariant.eventEndDate}
                  timezone={updatedVariant.timeZoneIdentifier!}
                  state="ChangeNewSelection"
                />
              </div>
            )}
          </Panel.Body>
          <Panel.Footer divider="gradient">
            {step === Step.ChangeSelection && (
              <>
                {updatedVariant && (
                  <Button
                    label="Update Registration"
                    size="large"
                    className="mb-4 w-full"
                    onClick={() => setStep(Step.ChangeConfirmation)}
                  />
                )}
                <div className="flex justify-center">
                  <Link
                    as="button"
                    text="Cancel Registration"
                    onClick={() => setStep(Step.CancelConfirmation)}
                  />
                </div>
                {updatedVariant && getDiscountAdviceMessage(updatedVariant, labels) && (
                  <p className="label mt-4 text-center text-gray-dark">
                    {getDiscountAdviceMessage(updatedVariant, labels)}
                  </p>
                )}
              </>
            )}

            {step === Step.ChangeConfirmation && (
              <>
                <Button
                  label="Confirm"
                  size="large"
                  className="mb-4 w-full"
                  loading={showLoading}
                  onClick={() => onChange(updatedVariant!)}
                />
                <div className="flex justify-center">
                  <Link
                    as="button"
                    text="No, thanks"
                    onClick={() => setStep(Step.ChangeSelection)}
                  />
                </div>
              </>
            )}

            {step === Step.CancelConfirmation && (
              <>
                <Button
                  label="Cancel registration"
                  size="large"
                  className="mb-4 w-full"
                  loading={showLoading}
                  onClick={() => onCancel(updatedVariant!)}
                />
                <div className="flex justify-center">
                  <Link
                    as="button"
                    text="No, thanks"
                    onClick={() => setStep(Step.ChangeSelection)}
                  />
                </div>
              </>
            )}
          </Panel.Footer>
        </Panel.Content>
      )}
      {isPhoneMode && (
        <Panel.Content dimBackground={!withinPanel}>
          <Panel.Header>
            <p className="font-bold">Change Registration</p>
          </Panel.Header>
          <Panel.Body>
            <p className="text-2">
              If you wish to edit or cancel your registration, please call customer service at{' '}
              <a className="text-decoration-line: underline" href={`tel:${formatedPhoneNumber}`}>
                {displayedPhoneNumber}
              </a>
            </p>
          </Panel.Body>
          <Panel.Footer>
            <>
              <Button
                label="Call Customer Service"
                size="large"
                className="mb-4 w-full"
                onClick={() => window.open('tel:800-260-4754')}
              />
              <div className="flex justify-center">
                <Link as="button" text="No, thanks" onClick={() => onOpenChange(false)} />
              </div>
            </>
          </Panel.Footer>
        </Panel.Content>
      )}
      {isShimmering &&
        (nonRegisteredVariants == undefined || nonRegisteredVariants?.length == 0) && (
          <Panel.Content>
            <Panel.Header>
              <Shimmer className="h-6 w-48 rounded-md" />
            </Panel.Header>
            <Panel.Body>
              <Shimmer className="h-5 w-72 rounded-md" />
              <Shimmer className="mt-2 h-5 w-80 rounded-md" />
              <Shimmer className="mt-2 h-5 w-28 rounded-md" />
            </Panel.Body>
            <Panel.Footer className="justify-center">
              <Shimmer className="mb-6 h-14 w-[358px] rounded-md" />
              <Shimmer className="h-5 w-[115px] place-self-center rounded-md" />
            </Panel.Footer>
          </Panel.Content>
        )}
      {isShimmering && nonRegisteredVariants != undefined && nonRegisteredVariants?.length > 0 && (
        <Panel.Content>
          <Panel.Body>
            <Shimmer className="mb-16 mt-0 h-[104px] w-full rounded-md" />
            <div className="flex flex-row items-center gap-2">
              <Shimmer className="size-6 rounded-full" />
              <Shimmer className="h-4 w-[114px] lg:h-5" />
            </div>
            <Shimmer className="mt-6 h-12 w-full rounded-md" />
            <Shimmer className="mt-2 h-12 w-full rounded-md" />
            <div className="mt-8 flex flex-row items-center gap-2">
              <Shimmer className="size-6 rounded-full" />
              <Shimmer className="h-4 w-[114px] lg:h-5" />
            </div>
            <Shimmer className="mt-6 h-[52px] w-[360px] rounded-md" />
          </Panel.Body>
          <Panel.Footer className="justify-center">
            <Shimmer className="mb-6 h-14 w-[358px] rounded-md" />
            <Shimmer className="mb-6 h-5 w-[209px] place-self-center rounded-md" />
            <Shimmer className="h-5 w-[115px] place-self-center rounded-md" />
          </Panel.Footer>
        </Panel.Content>
      )}
    </Panel>
  );
};

export default RegistrationUpdatePanel;
