import BaseCard, { ThumbnailProps } from '@/components/ui/Cards/BaseCard';
import { MenuItemProps } from '@/components/ui/Menu/MenuItem';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Fragment } from 'react';
import BaseCardList from '@/components/ui/Cards/BaseCardList';
import moreLikeThis from '@/utils/moreLikeThis';
import { useSelector } from 'react-redux';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { useNavigate } from 'react-router-dom';
import { ItemAnalyticsModel } from '@/@types/client-api';


export interface PodcastCardProps {
  image?: {
    url: string;
    alt?: string;
  };
  size?: 'small' | 'medium' | 'large';
  heading?: string;
  labelDetail?: string;
  labelInfo?: string;
  href?: string;
  target?: string;
  onClickCard?: () => void;
  listPosition?: number;
  heightAuto?: boolean;
  widthAuto?: boolean;
  showAsList?: boolean;
  podcastLinks?: (MenuItemProps | { separator: true })[];
  productCode?: string | null;
  itemAnalyticsModel?: ItemAnalyticsModel;
}

export interface PodcastLabelsProps extends PodcastCardProps {
  labelDetailIcon?: string;
  labelDetailClassName?: string;
}

const PodcastCard: React.FC<PodcastCardProps> = ({
  image,
  showAsList,
  podcastLinks,
  productCode,
  itemAnalyticsModel,
  ...props
}) => {
  const pageLinkPaths = useSelector(selectPageLinkPaths);
  const searchPageUrl = pageLinkPaths?.Search;
  const navigate = useNavigate();
  const thumbnail: ThumbnailProps = {
    image,
    iconThumbnail: { name: 'play', label: 'Launch now' }
  };

  const buttonOptions: (MenuItemProps | { separator: true })[] = [
    ...(podcastLinks || []),
    {
      label: 'Show more like this',
      onClick: () => {
        moreLikeThis(navigate, productCode, searchPageUrl, itemAnalyticsModel);
      },
      icon: 'search'
    }
  ];

  let buttonOptionsVariation;

  const belowLg = useMediaQuery(ScreenSizeQueries.belowLg);

  if (belowLg) {
    buttonOptionsVariation = [
      {
        label: 'Launch',
        onClick: () => {},
        icon: 'play'
      },
      ...(buttonOptions ?? [])
    ];
  } else {
    buttonOptionsVariation = buttonOptions ?? [];
  }

  const podcastProps: PodcastLabelsProps = {
    ...props
  };

  return (
    <Fragment>
      {showAsList ? (
        <BaseCardList
          buttonOptions={buttonOptionsVariation}
          thumbnailProps={thumbnail}
          {...podcastProps}
        />
      ) : (
        <BaseCard
          thumbnailProps={thumbnail}
          buttonOptions={buttonOptionsVariation}
          {...podcastProps}
        />
      )}
    </Fragment>
  );
};

export default PodcastCard;
