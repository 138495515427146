import { ComponentProps, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Divider from '@/components/ui/Divider';
import ContentTabs from '../ContentTabs';
import SearchResultsQuickFilterRow from './SearchResultsQuickFilterRow';
import SearchSortMenu from './SearchSortMenu';
import SearchResultsFilterOverlay from './SearchResultsFilterOverlay';
import SearchResultsLoadingState from './SearchResultsLoadingState';
import SearchResultsLoadMore from './SearchResultsLoadMore';
import SearchResultsQuery from './SearchResultsQuery';
import SearchResultsBackToTopButton from './SearchResultsBackToTopButton';
import SearchResultsEmptyState from './SearchResultsEmptyState';
import CardBuilder from '../Cards/CardBuilder';
import { ISearchResultItem, SearchResponse } from '@/@types/client-api';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import classnames from 'classnames';
import { RecommendedSearchListBlock } from '@/@types/content';
import RecommendedSearchList from './RecommendedSearchList';
import useSearchModalPanelState, { SearchModalPanelState } from './hooks/useSearchModalPanelState';
import SearchModalPanel from './SearchModalPanel';
import SearchResultsMarketingModule from './SearchResultsMarketingModule';
import { FilterRowRefs } from '../Filter/FilterRow';
import { FilterContext, SearchContext } from '@/analytics/constants';
import { AnalyticsContext } from '@/analytics/constants';

type SearchResultsComponentProps<T> = {
  headerQueryText?: string;
  loading: boolean;
  fetching: boolean;
  isLoadingMore: boolean;
  emptyStateHeading?: string;
  emptyStateSubHeading?: string;
  items: T[];
  keyFn: (input: T) => string;
  RowComponent: React.FC<{
    prevItem?: T;
    resultItem: T;
    isLoading: boolean;
    modalPanelState: SearchModalPanelState;
    refetchData?: () => void;
    shouldBeAllowedToAutoplayVideo?: boolean;
  }>;
  bottomContent?: React.ReactNode;
  tabs: ComponentProps<typeof ContentTabs>['tabs'];
  activeTab: ComponentProps<typeof ContentTabs>['activeTab'];
  onTabChange: ComponentProps<typeof ContentTabs>['onTabChange'];
  marketingModules?: SearchResponse['marketingModules'];
  sortOptions?: ComponentProps<typeof SearchSortMenu>['options'];
  sortValue: ComponentProps<typeof SearchSortMenu>['value'];
  onSortChange: ComponentProps<typeof SearchSortMenu>['onValueChange'];
  onFilterToggle: ComponentProps<typeof SearchResultsQuickFilterRow>['onFilterToggle'];
  quickFilterLabel?: ComponentProps<typeof SearchResultsQuickFilterRow>['label'];
  quickFilters: ComponentProps<typeof SearchResultsQuickFilterRow>['filters'];
  filters: ComponentProps<typeof SearchResultsFilterOverlay>['filters'];
  resultCount: ComponentProps<typeof SearchResultsFilterOverlay>['resultCount'];
  onClear: ComponentProps<typeof SearchResultsFilterOverlay>['onClear'];
  remainingResultsCount: ComponentProps<typeof SearchResultsLoadMore>['remainingResultsCount'];
  onLoadMore: ComponentProps<typeof SearchResultsLoadMore>['onLoadMore'];
  displayStyle?: string;
  recommendedSearchList?: RecommendedSearchListBlock;
  refetchData?: () => void;
  showFilters?: boolean;
  invariantFilterTooltipText?: string;
  context?: AnalyticsContext;
  searchContext?: SearchContext;
  creditTrackerCode?: string;
};

// position 2 means it goes after item 2
const MARKETING_MODULE_POSITION = 2;

function SearchResultsComponent<T>({
  headerQueryText,
  loading,
  fetching,
  isLoadingMore,
  tabs,
  activeTab,
  onTabChange,
  sortOptions,
  sortValue,
  onSortChange,
  quickFilters,
  filters,
  bottomContent,
  resultCount,
  items,
  keyFn,
  RowComponent,
  remainingResultsCount,
  onLoadMore,
  onClear,
  onFilterToggle,
  quickFilterLabel,
  emptyStateHeading,
  emptyStateSubHeading,
  displayStyle,
  recommendedSearchList,
  showFilters = true,
  refetchData,
  marketingModules,
  invariantFilterTooltipText,
  creditTrackerCode,
  context,
  searchContext
}: SearchResultsComponentProps<T>) {
  const filterRowRef = useRef<FilterRowRefs>(null);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const modalPanelState = useSearchModalPanelState();

  const isLoadingOrFetching = loading || fetching;
  const isFirstLoadOrFilterSort = loading || (fetching && !isLoadingMore);
  const hasResults = !!items.length;
  const showEmptyState = !isLoadingOrFetching && !hasResults;

  const belowLg = useMediaQuery(ScreenSizeQueries.belowLg);
  const isCards = displayStyle === 'card';

  const isStackedContent = !isCards || belowLg || showEmptyState;

  const resultLinelist = classnames({
    'grid gap-6 lg:grid-cols-4': !isStackedContent,
    'flex flex-col': isStackedContent
  });
  const hasFiltersApplied = quickFilters?.some(filter => filter?.data?.checked);
  const isEmptyWithFiltersApplied = showEmptyState && hasFiltersApplied;
  const showQuickFilters = hasResults || isEmptyWithFiltersApplied;

  // position or the end of the list, whichever comes first
  const marketingModulePosition =
    items.length > MARKETING_MODULE_POSITION ? MARKETING_MODULE_POSITION : items.length;

  useEffect(() => {
    if (!isFilterPanelOpen && filterRowRef.current) {
      filterRowRef.current.focusOnAllFiltersButton();
    }
  }, [isFilterPanelOpen]);

  const handleOnFilterMenuOpen = useCallback(() => {
    setIsFilterPanelOpen(true);
  }, []);

  return (
    <div className="mb-12 mt-10 sm:mt-14 lg:mb-20" data-component={'SearchResultsComponent'}>
      <div className="container">
        {showEmptyState && (
          <SearchResultsEmptyState
            emptyStateHeading={emptyStateHeading ?? ''}
            emptyStateSubHeading={emptyStateSubHeading ?? ''}
          />
        )}
        {headerQueryText && (
          <SearchResultsQuery
            query={headerQueryText}
            className={classnames('mt-10', { 'md:mb-14': !isEmptyWithFiltersApplied })}
            dividerClassName="my-8 md:hidden"
          />
        )}

        <ContentTabs
          isVisible={hasResults || isLoadingOrFetching}
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={onTabChange}
          loading={loading || (fetching && tabs.length === 0)}
        >
          {showFilters !== false ? (
            <SearchResultsQuickFilterRow
              ref={filterRowRef}
              onFilterMenuOpen={handleOnFilterMenuOpen}
              invariantFilterTooltipText={invariantFilterTooltipText}
              showQuickFilters={showQuickFilters}
              className="my-8 sm:mb-10 md:mb-12 lg:mt-12"
              filters={quickFilters}
              sortMenu={
                sortOptions && (
                  <SearchSortMenu
                    options={sortOptions}
                    value={sortValue}
                    onValueChange={onSortChange}
                  />
                )
              }
              sortValue={sortValue ?? undefined}
              label={quickFilterLabel}
              onFilterToggle={onFilterToggle}
              canFilter={activeTab && activeTab.value !== 'Faculty'}
              loading={loading}
              fetching={fetching}
              showAllFiltersAndSortButtons={!showEmptyState}
            />
          ) : (
            <div className="pb-8" />
          )}

          {(loading || hasResults) && isStackedContent ? <Divider color="light" /> : null}

          <div className={resultLinelist}>
            {isFirstLoadOrFilterSort ? (
              <SearchResultsLoadingState />
            ) : !hasResults ? (
              recommendedSearchList && (
                <RecommendedSearchList recommendedSearchList={recommendedSearchList} />
              )
            ) : (
              <>
                {items.map((resultItem, index, arr) => {
                  return (
                    <Fragment key={keyFn(resultItem)}>
                      {isCards ? (
                        <div>
                          <CardBuilder
                            cardProps={resultItem as ISearchResultItem}
                            showAsList={belowLg}
                            heightAuto={true}
                            size={!belowLg ? 'small' : 'large'}
                            className={classnames('w-full !max-w-none basis-1/4')}
                            widthAuto={true}
                            creditTrackerCode={creditTrackerCode}
                            itemAnalyticsModel={
                              (resultItem as ISearchResultItem)?.itemAnalyticsModel
                            }
                          />
                          {index < arr.length - 1 && belowLg && <Divider color="light" />}
                        </div>
                      ) : (
                        <>
                          <div className="py-12 sm:py-16">
                            <RowComponent
                              resultItem={resultItem}
                              prevItem={arr[index - 1]}
                              isLoading={loading}
                              modalPanelState={modalPanelState}
                              refetchData={refetchData}
                              shouldBeAllowedToAutoplayVideo={true}
                            />
                          </div>
                          {marketingModulePosition === index + 1 && (
                            // @ts-expect-error CMS Data should be type BannerBlock
                            <SearchResultsMarketingModule {...marketingModules?.[0]} />
                          )}
                          {index < arr.length - 1 && <Divider color="light" />}
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </>
            )}
          </div>
          {(isLoadingMore || remainingResultsCount > 0) && (
            <>
              {isStackedContent && <Divider color="light" />}
              <SearchResultsLoadMore
                isLoadingMore={isLoadingMore}
                remainingResultsCount={remainingResultsCount}
                onLoadMore={onLoadMore}
              />
            </>
          )}
          <SearchResultsBackToTopButton />
        </ContentTabs>

        <SearchModalPanel state={modalPanelState} context={context} />

        <SearchResultsFilterOverlay
          isFilterPanelOpen={isFilterPanelOpen}
          setIsFilterPanelOpen={setIsFilterPanelOpen}
          filters={filters}
          sortValue={sortValue}
          sortOptions={sortOptions}
          onSortChange={onSortChange}
          resultCount={resultCount}
          onClear={onClear}
          fetching={fetching}
          context={FilterContext.AllFiltersPanel}
          searchContext={searchContext}
        />
      </div>
      {bottomContent}
    </div>
  );
}

export default SearchResultsComponent;
