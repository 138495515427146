import DropdownMenu, { TDropdownMenuItem } from '@/components/ui/DropdownMenu';
import IconButton from '@/components/ui/IconButton';
import { useEffect, useMemo, useRef } from 'react';

enum CreditCardMenuOption {
  SetAsDefaultCard = 'Set as default card',
  Delete = 'Delete'
}

export type CreditCardDropDownMenuProps = {
  onSetAsDefaultCardPress: () => void;
  onDeletePress: () => void;
  isDefaultCard?: boolean;
  isDeleting?: boolean;
};

const CreditCardDropDownMenu = ({
  onSetAsDefaultCardPress,
  onDeletePress,
  isDefaultCard = false,
  isDeleting
}: CreditCardDropDownMenuProps): JSX.Element => {
  const moreOptionsButtonRef = useRef<HTMLButtonElement>(null);
  const lastOptionTriggered = useRef<CreditCardMenuOption | undefined>(undefined);

  //Focus on the three-dots button after the delete confirmation modal closes.
  useEffect(() => {
    if (
      !isDeleting &&
      moreOptionsButtonRef.current &&
      lastOptionTriggered.current === CreditCardMenuOption.Delete
    ) {
      lastOptionTriggered.current = undefined;
      moreOptionsButtonRef.current.focus();
    }
  }, [isDeleting]);

  const items = useMemo(() => {
    const result: TDropdownMenuItem[] = [];

    const addOption = (option: CreditCardMenuOption, onClick: () => void) => {
      result.push({
        label: option,
        onClick: () => {
          lastOptionTriggered.current = option;
          onClick();
        }
      });
    };

    if (!isDefaultCard) {
      addOption(CreditCardMenuOption.SetAsDefaultCard, onSetAsDefaultCardPress);
    }
    addOption(CreditCardMenuOption.Delete, onDeletePress);

    return result;
  }, [isDefaultCard, onDeletePress, onSetAsDefaultCardPress]);

  return (
    <div>
      <DropdownMenu
        button={() => (
          <IconButton
            ref={moreOptionsButtonRef}
            label="More options"
            name="more-ios"
            size="large"
          />
        )}
        items={items}
      />
    </div>
  );
};

export default CreditCardDropDownMenu;
