import { trackEvent } from '@/analytics/utils';
import {
  AnalyticsEventName,
  ClearAllFiltersEventProperties,
  FilterContext,
  SearchContext
} from './constants';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FILTERS, QUERY_PARAMS } from '@/components/ui/SearchResults/constants';

type TrackFilterEventProps = {
  facetGroup: string;
  facetValue: string;
  context?: FilterContext;
  searchContext?: SearchContext;
};

export const trackFilterEvent = ({
  facetGroup,
  facetValue,
  context,
  searchContext
}: TrackFilterEventProps) => {
  if (context || searchContext) {
    trackEvent({
      eventName: AnalyticsEventName.Filter,
      parameters: {
        facet_group_name: facetGroup,
        facet_value: facetValue,
        context: context ?? '',
        search_context: searchContext ?? ''
      }
    });
  }
};

export const trackClearAllFiltersEvent = ({
  facetGroup,
  context,
  searchContext
}: Pick<TrackFilterEventProps, 'context' | 'searchContext' | 'facetGroup'>) => {
  trackFilterEvent({
    ...ClearAllFiltersEventProperties,
    ...(facetGroup ? { facetGroup } : {}),
    context,
    searchContext
  });
};

type TrackSortEventProps = {
  method: string;
  searchContext?: SearchContext;
};

export const trackSortEvent = ({ method, searchContext }: TrackSortEventProps) => {
  if (searchContext) {
    trackEvent({
      eventName: AnalyticsEventName.Sort,
      parameters: {
        method,
        search_context: searchContext
      }
    });
  }
};

export const getSearchEventParams = (searchParams: URLSearchParams) => {
  const searchTerm = searchParams.get(QUERY_PARAMS.QUERY);
  if (!searchTerm) {
    return undefined;
  }
  const parameters: Record<string, string> = { search_term: searchTerm };
  //adding additional search parameters
  const filters = Object.values(FILTERS) as string[];
  for (const [key, value] of searchParams.entries()) {
    if (filters.includes(key)) {
      parameters[`q_${key}`] = value;
    }
  }
  return parameters;
};

export const trackSearchEvent = (searchParams: URLSearchParams) => {
  const parameters = getSearchEventParams(searchParams);
  if (parameters) {
    trackEvent({ eventName: AnalyticsEventName.Search, parameters });
  }
};

type TrackNoResultsEventProps = {
  searchContext?: SearchContext;
};

export const trackNoResultsEvent = ({ searchContext }: TrackNoResultsEventProps) => {
  if (searchContext) {
    trackEvent({
      eventName: AnalyticsEventName.SearchNoResults,
      parameters: { search_context: searchContext }
    });
  }
};

type TrackExceptionEventProps = {
  searchContext?: SearchContext;
  error?: FetchBaseQueryError | SerializedError;
  fatal?: boolean;
};

export const trackExceptionEvent = ({
  searchContext,
  error,
  fatal = false
}: TrackExceptionEventProps) => {
  if (searchContext) {
    trackEvent({
      eventName: AnalyticsEventName.Exception,
      parameters: {
        search_context: searchContext,
        description: getErrorDescription(error),
        fatal: fatal.toString()
      }
    });
  }
};

const getErrorDescription = (error?: FetchBaseQueryError | SerializedError | Error): string => {
  if (!error) return '';
  try {
    if ('error' in error && error.error) return error.error;

    if ('message' in error && error.message) return error.message;

    if ('data' in error && typeof error.data === 'object' && error.data !== null) {
      const data = error.data as Record<string, unknown>;
      if ('message' in data && typeof data.message === 'string') {
        return data.message;
      }
    }
    return '';
  } catch {
    return '';
  }
};
