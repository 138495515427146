import { ComponentProps, ReactNode, forwardRef } from 'react';
import classnames from 'classnames';
import Icon from '../Icon';

type ITagButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'> & {
  iconLeft?: ComponentProps<typeof Icon>['name'];
  label?: ReactNode;
  icon?: ComponentProps<typeof Icon>['name'];
  styleType?: 'outline' | 'solid' | 'black' | 'black-on-black';
  isActive?: boolean;
  size?: 'small' | 'short' | 'large';
};

const TagButton = forwardRef<HTMLButtonElement, ITagButtonProps>(
  (
    {
      label,
      styleType = 'outline',
      icon,
      iconLeft,
      onClick,
      isActive,
      disabled,
      className,
      size = 'large',
      ...props
    },
    forwardedRef
  ) => {
    const isIconOnly = !label && icon;
    const buttonClassNames = classnames(
      'text-1 flex items-center gap-2 rounded border font-medium transition',
      {
        'bg-black text-white hover:border-white':
          styleType === 'black' || (styleType === 'black-on-black' && !isActive),
        'border-gray bg-black text-white': styleType === 'black-on-black' && !isActive,
        'hover:border-black': !disabled,
        'border-gray bg-white': !isActive && styleType === 'outline',
        'border-silver text-gray': disabled && styleType === 'outline',
        'border-transparent bg-gray-lightest disabled:text-gray':
          !isActive && styleType === 'solid',
        'border-gray disabled:text-gray': !isActive && styleType === 'black',
        'border-white': isActive && styleType === 'black',
        'border-white bg-white text-black hover:border-white':
          isActive && styleType === 'black-on-black',
        'border-transparent bg-black text-white': isActive && styleType !== 'black-on-black',
        'px-3 py-[11px] lg:px-5': !isIconOnly,
        'p-[13px] lg:p-[14px]': isIconOnly,
        'h-14': size === 'large',
        'h-11': size === 'short',
        'h-8': size === 'small'
      },
      className
    );

    return (
      <button
        className={buttonClassNames}
        onClick={onClick}
        type="button"
        disabled={disabled}
        {...props}
        ref={forwardedRef}
      >
        {iconLeft && <Icon size="small" name={iconLeft} />}
        {label && <span className="whitespace-nowrap">{label}</span>}
        {icon && <Icon size="small" name={icon} />}
      </button>
    );
  }
);

export default TagButton;
