import type { StartTrialFlow as StartTrialBlockProps } from '@/@types/content';
import Panel from '@/components/ui/Panel';
import classnames from 'classnames';
import Button from '@/components/ui/Buttons/Button';
import * as Form from '@radix-ui/react-form';
import useToast from '@/hooks/useToast';
import { useState, memo, createRef, useMemo } from 'react';
import { v4 as uuid4 } from 'uuid';
import TextInput from '@/components/ui/Form/TextInput';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import DropdownSelect from '@/components/ui/DropdownSelect';
import DropdownButton from '@/components/ui/Buttons/DropdownButton';
import { trackFormSubmitComplete } from '@/analytics/form';
import { FormContext } from '@/analytics/constants';

type BlockType = StartTrialBlockProps & {
  triggerButton: React.ReactNode;
};

type FieldType = {
  fieldDescription?: string;
  fieldLabel?: string;
  fieldName?: string;
  fieldType?: string;
  fieldOptions?: Array<string>;
  isRequired?: boolean;
  placeholder?: string;
};

type StepType = {
  rows: {
    rowFields: FieldType[];
  }[];
};

const FormStep = memo(({ step, formId }: { step: StepType; index: number; formId: string }) => {
  const [selected, setSelected] = useState('');

  return (
    <div className={classnames('flex flex-col', 'gap-12')}>
      {step?.rows?.map(({ rowFields }: { rowFields: FieldType[] }, indexRow: number) => (
        <div
          key={indexRow}
          className={classnames('flex flex-col', 'sm:flex-row sm:gap-6', 'gap-12')}
        >
          {rowFields?.map(
            (
              { fieldLabel, fieldName, fieldType, isRequired, placeholder, fieldOptions },
              indexField: number
            ) => {
              switch (fieldType) {
                case 'text':
                case 'email':
                  return (
                    <div className="w-full" key={indexField}>
                      <TextInput
                        label={fieldLabel ?? ''}
                        placeholder={placeholder}
                        name={fieldName}
                        required={isRequired}
                        type={fieldType}
                      />
                    </div>
                  );
                case 'hidden':
                  return (
                    <div className="hidden w-full" key={indexField}>
                      <TextInput
                        label={fieldLabel ?? ''}
                        placeholder={placeholder}
                        name={fieldName}
                        required={isRequired}
                        type={fieldType}
                        value={placeholder}
                      />
                    </div>
                  );
                case 'tel':
                  return (
                    <div className="w-full" key={indexField}>
                      <TextInput
                        label={fieldLabel ?? ''}
                        placeholder={placeholder}
                        name={fieldName}
                        required={isRequired}
                        type="tel"
                        onChange={(value: string) => {
                          let numericValue = value.replace(/\D/g, '');

                          if (numericValue.length > 3 && numericValue.length <= 6) {
                            numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
                          } else if (numericValue.length > 6) {
                            numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
                          } else if (numericValue.length > 0) {
                            numericValue = `(${numericValue}`;
                          }

                          if (numericValue.length > 14) {
                            numericValue = numericValue.slice(0, 14);
                          }

                          const form = document.getElementById(formId);
                          const field = form?.querySelector(`[name="${fieldName}"]`);

                          if (!field) return;

                          if (numericValue.length <= 14) {
                            (field as HTMLInputElement).value = numericValue;
                          }
                        }}
                      />
                    </div>
                  );
                case 'dropdown':
                  return (
                    <div className="w-full" key={indexField}>
                      <DropdownSelect
                        button={({ isOpen, value, ...props }) => (
                          <DropdownButton
                            state={isOpen ? 'open' : 'closed'}
                            label={value}
                            {...props}
                          />
                        )}
                        label={fieldLabel}
                        name={fieldName}
                        placeholder={placeholder}
                        options={fieldOptions!
                          .filter(str => str.trim() !== '')
                          .map(item => ({
                            label: item,
                            value: item.toLowerCase().replace(/[^\w]/g, '')
                          }))}
                        value={selected}
                        onValueChange={value => {
                          setSelected(value);
                        }}
                        menuStyle="form-field"
                        required={isRequired}
                      />
                    </div>
                  );
                default:
                  return null;
              }
            }
          )}
        </div>
      ))}
    </div>
  );
});

const StartTrialModalBlock: React.FC<BlockType> = ({
  successMessage,
  failMessage,
  triggerButton,
  steps = [],
  name,
  contentLink,
  blockNameForEventLogging
}) => {
  const { showSuccessMessage, showFailMessage } = useToast();

  const [stepNumber, setStepNumber] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [forceClose, setForceClose] = useState(false);
  const [allFormsData, setAllFormsData] = useState<Record<string, unknown>>({});
  const [showStepErrorMessage, setShowStepErrorMesages] = useState(false);

  const handleStepChange = (increment: number) => {
    const newStepNumber = stepNumber + increment;
    if (newStepNumber >= 0 && newStepNumber < steps.length) {
      setStepNumber(newStepNumber);
    }
  };

  const formIds = useMemo(() => steps.map(() => uuid4()), [steps]);
  const formRefs = useMemo(() => steps.map(() => createRef<HTMLFormElement>()), [steps]);
  const formName = useMemo(() => {
    return blockNameForEventLogging ?? `${name!} ${contentLink?.id}`;
  }, [blockNameForEventLogging, name, contentLink?.id]);
  const handleBack = () => handleStepChange(-1);

  const handleSubmitNext = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());

    if (Object.keys(data).length === 0) {
      setShowStepErrorMesages(true);
      return;
    }

    const formId = formIds[stepNumber];
    setAllFormsData(prev => ({ ...prev, [formId]: data }));

    setShowStepErrorMesages(false);
    handleStepChange(1);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const action = event.currentTarget.action;
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());
    const formId = formIds[stepNumber];

    const mergedData = { ...allFormsData, [formId]: data };

    setAllFormsData(mergedData);

    const dataWithoutFormId = Object.entries(mergedData).reduce(
      (acc, [_key, value]) => {
        if (typeof value === 'object' && value !== null) {
          acc = { ...acc, ...value };
        }
        return acc;
      },
      {} as Record<string, string>
    );

    setIsSubmitting(true);

    try {
      await fetch(action, {
        method: 'POST',
        mode: 'no-cors',
        redirect: 'follow',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(
          Object.entries(dataWithoutFormId).reduce(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {} as Record<string, string>
          )
        ).toString()
      });

      setIsSuccess(true);
      showSuccessMessage(successMessage);
      setTimeout(() => {
        setForceClose(true);
      }, 1000);
      trackFormSubmitComplete({
        formName: formName,
        context: FormContext.StartTrial
      });
    } catch (error) {
      showFailMessage(failMessage);
      console.error(error);
    }

    setIsSubmitting(false);
  };

  const resetStates = (open: boolean) => {
    if (open) {
      setForceClose(false);
      setStepNumber(0);
      setIsSubmitting(false);
      setIsSuccess(false);
    }
  };

  if (!steps || steps.length === 0) {
    return null;
  }

  return (
    <Panel theme="light" onOpenChange={resetStates} forceClose={forceClose}>
      <Panel.Trigger asChild>{triggerButton}</Panel.Trigger>

      <Panel.Content
        onBackButtonPress={stepNumber >= 1 ? handleBack : undefined}
        miniPaddingHeader={true}
      >
        <Panel.Body scrollClassName={classnames('lg:pt-6')}>
          <div className="flex flex-col gap-2">
            {steps[stepNumber]?.heading && (
              <h2 className="heading-4">{steps[stepNumber]?.heading}</h2>
            )}
            {steps[stepNumber]?.subheading && (
              <span className="text-1 text-gray-dark">{steps[stepNumber]?.subheading}</span>
            )}
          </div>

          <div className={classnames('my-10', 'lg:my-8')}>
            {steps.map((step: StepType, index: number) => (
              <Form.Root
                onSubmit={steps[stepNumber]?.submitButton ? handleSubmit : handleSubmitNext}
                id={formIds[index]}
                action={steps[stepNumber]?.submitButton?.href || undefined}
                key={index}
                className={classnames({ hidden: index !== stepNumber })}
                ref={formRefs[index]}
              >
                <FormStep step={step} index={index} formId={formIds[index]} />
              </Form.Root>
            ))}
          </div>
        </Panel.Body>

        <Panel.Footer divider="line">
          {showStepErrorMessage && steps[stepNumber]?.stepErrorMessage ? (
            <div className="label relative flex items-center gap-1 text-red">
              <Icon name="alert" size="small" />
              <Text element="span">{steps[stepNumber]?.stepErrorMessage}</Text>
            </div>
          ) : null}

          <div className="flex flex-row justify-end gap-6">
            {steps[stepNumber]?.nextButton && (
              <Button
                form={formIds[stepNumber]}
                type="submit"
                label={steps[stepNumber]?.nextButton?.text}
                size="large"
                color="black"
              />
            )}
            {steps[stepNumber]?.submitButton && (
              <Button
                form={formIds[stepNumber]}
                type="submit"
                label={isSuccess ? undefined : steps[stepNumber]?.submitButton?.text}
                size="large"
                color={isSuccess ? 'green' : 'black'}
                iconRight={isSuccess ? 'check' : undefined}
                loading={isSubmitting}
                className={classnames('min-w-28 !justify-center', {
                  'pointer-events-none': isSubmitting || isSuccess
                })}
              />
            )}
          </div>
        </Panel.Footer>
      </Panel.Content>
    </Panel>
  );
};

export default StartTrialModalBlock;
