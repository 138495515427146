import { forwardRef as ReactForwardRef } from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';

enum Variant {
  Color = 'color',
  BW = 'bw'
}

interface ToggleProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  variant?: `${Variant}`;
}

const Toggle = ReactForwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, ToggleProps>(
  ({ className, variant = Variant.Color, ...props }, ref) => {
    const switchRootClasses = classnames(
      'peer inline-flex h-5 w-10 shrink-0 cursor-pointer items-center rounded-full transition-colors',
      {
        'data-[state=unchecked]:bg-gray-darker': variant === Variant.Color,
        'data-[state=unchecked]:bg-gray': variant === Variant.BW
      },
      {
        'data-[state=checked]:bg-amethist': variant === Variant.Color,
        'data-[state=checked]:bg-black': variant === Variant.BW
      },
      'disabled:cursor-not-allowed disabled:bg-gray-darker',
      'data-[state=checked]:disabled:bg-gray-darker data-[state=unchecked]:disabled:bg-gray-darker',
      className
    );

    const switchThumbClasses = classnames(
      'pointer-events-none inline-flex size-3.5 items-center rounded-full bg-white ring-0 transition-transform',
      'data-[state=unchecked]:translate-x-[3px] data-[state=unchecked]:text-white',
      'data-[state=checked]:translate-x-[23px] data-[state=checked]:text-gray',
      'data-[disabled]:bg-gray-medium data-[disabled]:!text-gray-medium'
    );

    return (
      <SwitchPrimitives.Root className={switchRootClasses} {...props} ref={ref}>
        <SwitchPrimitives.Thumb className={switchThumbClasses}>
          <Icon name="checkmark" size="x-small" className="relative left-px" />
        </SwitchPrimitives.Thumb>
      </SwitchPrimitives.Root>
    );
  }
);

export default Toggle;
