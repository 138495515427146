import React from 'react';
import Link from '@/components/ui/Link';

export type BreadcrumbProps = {
  name: string;
  url: string;
  objectType: string;
};

export type BreadcrumbsProps = {
  breadcrumbs?: BreadcrumbProps[];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  let filteredBreadcrumbs;

  if (breadcrumbs) {
    filteredBreadcrumbs = breadcrumbs!.filter(breadcrumb => breadcrumb.objectType !== 'FolderPage');
  }

  return (
    <nav aria-label="Breadcrumbs" 
    data-component={"Breadcrumbs"}
    >
      <ol className="flex items-center gap-4">
        {filteredBreadcrumbs &&
          filteredBreadcrumbs.map((breadcrumb, index) => (
            <li key={index} className="flex items-center">
              <Link
                text={breadcrumb.name}
                href={breadcrumb.url}
                variant="text-link-2"
                className="mr-1 text-gray-dark"
              />
              {index < filteredBreadcrumbs.length - 1 && (
                <span className="ml-4 inline-flex self-center text-gray">/</span>
              )}
            </li>
          ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
