import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Button from '@/components/ui/Buttons/Button';
import classnames from 'classnames';
import Image from '@/components/cms/Image';
import {
  LiveProgramProduct,
  LiveProgramInteractiveVariation,
  LiveProgramGroupcastVariation,
  LiveProgramOhbVariation,
  LiveProgramInteractiveWebcastVariation,
  LiveProgramPbrSeminarVariation,
  LiveProgramPBRWebcastVariation,
  LiveProgramSeminarVariation,
  LiveProgramWebcastVariation
} from '@/@types/content';
import { useLaunch } from '@/hooks/shared/useLaunch';
import { useRef, useState, useEffect, useCallback } from 'react';
import Tag from '@/components/ui/Tag';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import {
  useGetImageUrlQuery,
  useGetMediaStreamsQuery,
  useGetProductByCodeQuery
} from '@/redux/api/client/product';
import { getProductImageUrl, isNotFreeForPMs } from '@/components/ui/PDP/helpers';
import { ISearchResultUserInfo, MediaStream } from '@/@types/client-api';
import DateTag, { DateType } from '@/components/ui/DateTag';
import { useAuth } from 'react-oidc-context';
import RichText from '@/components/cms/RichText';
import Thumbnail from '@/components/ui/Thumbnail';

const getCardColor = (url: string, isLive: boolean) => {
  if (isLive) {
    return 'live';
  }
  const arr: string[] = url?.split('_') || [];
  const colorArr = arr.pop()?.split('.') || [];
  const color = colorArr[0] || '';
  return color;
};

type LiveProgramVariations =
  | LiveProgramProduct
  | LiveProgramInteractiveVariation
  | LiveProgramGroupcastVariation
  | LiveProgramOhbVariation
  | LiveProgramInteractiveWebcastVariation
  | LiveProgramPbrSeminarVariation
  | LiveProgramPBRWebcastVariation
  | LiveProgramSeminarVariation
  | LiveProgramWebcastVariation;

export type LiveSlideProps = LiveProgramVariations & {
  ctaLabel?: string;
  parentProductCode?: string;
  isRegistered?: boolean;
  itemClassId?: number;
  isSummaryTextFromSellingPoints?: boolean;
};

export type ButtonColor =
  | 'black'
  | 'white'
  | 'outline-black'
  | 'outline-gray'
  | 'outline-white'
  | 'light-gray'
  | 'red'
  | 'green'
  | 'gradient';

export interface ButtonProps {
  color?: ButtonColor;
}

interface mediaStreamPk extends MediaStream {
  item_pk?: string | null;
}

const HomePageLiveSlideBlock: React.FC<LiveSlideProps> = ({
  code,
  displayName,
  ctaLabel = 'Join now',
  url,
  sellingPoints,
  routeSegment,
  parentProductCode,
  itemClassId,
  isSummaryTextFromSellingPoints
}) => {
  const auth = useAuth();

  const [slideTitle, setSlideTitle] = useState<string>(displayName || '');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [variationPk, setVariationPk] = useState<string>(code || '');
  const [userList, setUserList] = useState<ISearchResultUserInfo[]>([]);
  const [ctaLink, setCtaLink] = useState<string>(url || '');
  const [video, setVideo] = useState<string>('');
  const [isRegisteredProduct, setIsRegisteredProduct] = useState<boolean>(false);
  const [fallbackImage, setFallbackImage] = useState<string>();
  const [badgeText, setBadgeText] = useState<string>();
  const [backgroundColor, setBackgroundColor] = useState<string>();
  const [isLive, setIsLive] = useState<boolean>(false);
  const [datesFormatted, setDatesFormatted] = useState<DateType>();
  const [isEligibleForDirectJoin, setIsEligibleForDirectJoin] = useState(false);

  const { data: colorVariant } = useGetImageUrlQuery(variationPk || '');

  const { data } = useGetProductByCodeQuery(parentProductCode || code || '');

  const { data: mediaDataResponse } = useGetMediaStreamsQuery(variationPk, {
    skip: !variationPk
  });

  const mediaData: mediaStreamPk[] | undefined = mediaDataResponse;

  const parseDatesFormatted = (dateString: string | undefined | null): DateType | undefined => {
    if (!dateString) return undefined;

    const regex = /^(\w+)(?: (\d+(?: – \d+)?)(?:,))? (\d{4})$/;
    const match = dateString.match(regex);

    if (!match) return undefined;

    const [, month, day] = match;
    const dayPart = day ? ` ${day}` : '';

    return {
      month,
      day: `${dayPart}`
    };
  };

  const itemRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const itemElement = itemRef.current;
  const parentElement = itemElement?.parentElement;
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (parentElement?.classList.contains('swiper-slide-active')) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    });

    if (parentElement?.classList.contains('swiper-slide-active') && !firstLoad) {
      setIsActive(true);
      setFirstLoad(true);
    }

    if (parentElement) {
      observer.observe(parentElement, {
        attributes: true,
        attributeFilter: ['class']
      });
    }

    return () => observer.disconnect();
  }, [itemRef, itemElement, parentElement, firstLoad]);

  useEffect(() => {
    if (!auth.isLoading)
      setIsEligibleForDirectJoin(
        !!(auth.user?.profile.IsPM === 'true') && !isNotFreeForPMs({ itemClassId: itemClassId })
      );
  }, [auth, itemClassId]);

  useEffect(() => {
    if (data && data.length > 0) {
      const isLive = data[0].isLive || data[0].isOnAir || false;
      setIsLoading(false);
      setSlideTitle(data[0].title || '');
      setVariationPk(data[0].variationPk || '');
      setUserList(data[0].userList || []);
      setFallbackImage(getProductImageUrl(data[0].variationPk || ''));
      setCtaLink(data[0].url || '');
      setBackgroundColor(getCardColor(colorVariant || '', isLive));
      setIsLive(isLive);
      setDatesFormatted(parseDatesFormatted(data[0].datesFormatted));
    } else {
      setIsLoading(false);
    }

    if (mediaData && mediaData.length > 0 && mediaData[0].item_pk === variationPk) {
      setVideo(mediaData[0].playbackUrl || '');
    }

    if (userList && userList.length > 0 && data) {
      const isRegisteredUserList = !!data[0].userList?.find(ul => ul.isPurchased);

      setIsRegisteredProduct(isRegisteredUserList);

      if (isRegisteredUserList) {
        const registeredLiveOnline =
          data[0].userList?.find(ul => ul.isPurchased)?.registrationDetails?.format ===
          'LiveWebcast';

        setBadgeText(`Registered, ${registeredLiveOnline ? 'Online' : 'In-Person'}`);
      }
    }
  }, [data, isLoading, mediaData, userList, variationPk, colorVariant]);

  const { launch } = useLaunch();

  const handleSubmit = useCallback(async () => {
    if (
      (isRegisteredProduct && userList.some(userListItem => userListItem.isPurchased)) ||
      isEligibleForDirectJoin
    ) {
      launch(
        variationPk,
        true,
        userList?.find(userListItem => userListItem.isPurchased)?.watchUrl ?? undefined
      );
    }
  }, [isRegisteredProduct, userList, launch, variationPk, isEligibleForDirectJoin]);

  const isExtraSmall = useMediaQuery(ScreenSizeQueries.xsOnly);

  const badgeClasses = classnames('text-2 text-white');

  const headingClasses = classnames('heading-4 line-clamp-4 text-white lg:heading-3 sm:min-h-16');
  const copyClasses = classnames('text-1 line-clamp-3 text-white max-lg:hidden');

  const linkContainerClasses = classnames('pt-6');

  const CarouselItemClasses = classnames(
    'relative flex max-w-[100vw] grow flex-col lg:mx-auto lg:flex-row lg:items-start lg:pt-[128px]',
    {
      'bg-charcoal': !backgroundColor,
      'bg-green-100': backgroundColor === 'green',
      'bg-teal-100': backgroundColor === 'teal',
      'bg-blue-100': backgroundColor === 'blue',
      'bg-indigo-100': backgroundColor === 'indigo',
      'bg-purple-100': backgroundColor === 'purple',
      'bg-magenta-100': backgroundColor === 'magenta',
      'bg-black': backgroundColor == 'live'
    }
  );

  const contentLayoutClasses = classnames('flex flex-col justify-between lg:justify-start');

  const mediaLayoutClasses = classnames(
    'mx-auto size-full pb-[88px] pt-11 sm:pb-[112px] lg:mx-0 lg:ml-4 lg:size-fit lg:pb-20 lg:pt-9'
  );

  const idSlide = routeSegment;

  const tabIndex = isActive ? 2 : -1;

  const contentLayout = (
    <div className={contentLayoutClasses}>
      <div className="flex flex-col gap-4 pt-[204px] sm:max-w-[75%] lg:w-[630px] lg:max-w-[630px] lg:pt-0">
        <div className="flex h-5 items-center gap-2">
          {isRegisteredProduct && (
            <>
              <Icon size="medium" name="checkmark" className={badgeClasses} />
              <Text className={badgeClasses}>{badgeText}</Text>
            </>
          )}
        </div>
        <Text tabIndex={-1} element="h2" className={headingClasses} id={idSlide}>
          {slideTitle}
        </Text>
        {sellingPoints && isSummaryTextFromSellingPoints && (
          <RichText propertyName="BodyText" content={sellingPoints} className={copyClasses} />
        )}
      </div>
      {!isLoading && (
        <div className={classnames(linkContainerClasses, 'hidden lg:block')}>
          {isLive && (isRegisteredProduct || isEligibleForDirectJoin) ? (
            <Button
              color="gradient"
              tabIndex={tabIndex}
              onClick={handleSubmit}
              label={ctaLabel}
              size="large"
              className="focus-visible:outline-white"
              aria-label={ctaLabel}
            />
          ) : (
            <Button
              propertyName="Link"
              color={'white' as ButtonProps['color']}
              tabIndex={tabIndex}
              href={ctaLink}
              label="See details"
              size={isExtraSmall ? 'small' : 'large'}
              aria-label="See details"
            />
          )}
        </div>
      )}
    </div>
  );

  const mediaLayout = (
    <div className={mediaLayoutClasses}>
      {video && isLive ? (
        <div className="relative size-full">
          <div className={classnames('lg:w-screen lg:max-w-[520px]')}>
            <Thumbnail
              ratio={520 / 345}
              video={
                video
                  ? {
                      autoPlay: true,
                      playable: true,
                      url: video,
                      muted: true
                    }
                  : undefined
              }
              image={
                fallbackImage
                  ? {
                      url: fallbackImage
                    }
                  : undefined
              }
              imageSizes={{
                xs: '320px',
                sm: '520px'
              }}
              tag={{
                children: 'Live',
                variant: 'emphasis'
              }}
              icon={{
                name: 'play',
                label: 'Join now'
              }}
              onClick={() => {
                if (ctaLink) {
                  window.location.href = ctaLink;
                }
              }}
            />
          </div>
        </div>
      ) : (
        <div className="relative flex items-center justify-center">
          <Image
            className="aspect-video size-full min-h-[185px] rounded-lg object-cover sm:min-h-[405px] lg:h-[345px] lg:min-h-[345px] lg:max-w-[520px]"
            propertyName="BackGroundImage"
            src={fallbackImage}
            aria-label={idSlide}
            tabIndex={-1}
          />
          {isLive ? (
            <div tabIndex={-1} className="absolute bottom-4 left-4">
              <Tag children="Live" variant="emphasis" size="medium" />
            </div>
          ) : (
            <div className="absolute flex size-36 flex-col items-center justify-center text-white">
              {datesFormatted && <DateTag dates={datesFormatted} isLargeTag={true} />}
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div
      className={CarouselItemClasses}
      ref={itemRef}
      aria-labelledby={idSlide}
      data-component={'HomePageLiveSlideBlock'}
    >
      <div className="container flex flex-col justify-between lg:flex-row">
        {contentLayout}
        {mediaLayout}
      </div>
      {!isLoading && (
        <div className="absolute bottom-0 px-6 pb-6 pt-8 md:px-12 lg:hidden">
          {isLive && (isRegisteredProduct || isEligibleForDirectJoin) ? (
            <Button
              color="gradient"
              onClick={handleSubmit}
              label={ctaLabel}
              size={isExtraSmall ? 'small' : 'large'}
              className="focus-visible:outline-white"
            />
          ) : (
            <Button
              propertyName="Link"
              color={'white' as ButtonProps['color']}
              href={ctaLink}
              label={'See details'}
              size={isExtraSmall ? 'small' : 'large'}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default HomePageLiveSlideBlock;
