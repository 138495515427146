import classnames from 'classnames';
import * as Dialog from '@radix-ui/react-dialog';
import Icon from '@/components/ui/Icon';
import ScrollArea from '@/components/ui/ScrollArea';

export interface BaseBoxContentProps {
  children: React.ReactNode;
  className?: string;
  inModal?: boolean;
  withinPanel?: boolean;
  withinTabs?: boolean;
  hasPaddingBottom?: boolean;
}

const Content: React.FC<BaseBoxContentProps> = ({
  children,
  className,
  inModal,
  withinTabs,
  withinPanel = false,
  hasPaddingBottom = true
}) => {
  return (
    <ScrollArea className="container lg:px-10">
      {inModal && (
        <Dialog.Close className="mt-7 flex w-full justify-end">
          <Icon name="close" />
        </Dialog.Close>
      )}

      <div       data-component={"Content"}
        className={classnames(
          {
            'pt-6': !withinPanel,
            'pt-12': withinPanel,
            'pb-12': hasPaddingBottom && (!withinPanel || (withinPanel && withinTabs)),
            'pb-10': hasPaddingBottom && withinPanel && !withinTabs
          },
          className
        )}
      >
        {children}
      </div>
    </ScrollArea>
  );
};

export default Content;
