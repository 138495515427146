import classnames from 'classnames';
import { useMemo, useState, useCallback } from 'react';

import SegmentListHeader from '@/components/ui/Snippet/SegmentList/Header';
import SegmentListItem from '@/components/ui/Snippet/SegmentList/SnippetSegment';
import Accordion from '@/components/ui/Accordion';
import Divider from '@/components/ui/Divider';
import { useLazyGetLibrarySegmentsQuery } from '@/redux/api/client/library';
import usePrevious from '@/hooks/usePrevious';

import SegmentListLoadingState from './SegmentListLoadingState';
import { getSegmentFromLibraryItem } from './helpers';
import type { SnippetSegmentType } from './types';

export interface SegmentListProps {
  featuredSegments?: SnippetSegmentType[];
  segmentListHeaderTitle: string;
  isProgramSegmentList?: boolean;
  onLaunchMediaPlayer: ((segment: SnippetSegmentType) => void) | null;
  programLink?: string;
  onExpand?: (value: string) => void;
  programPk: string;
  totalSegments?: number | null;
  seeMoreSegments?: boolean;
}

const SegmentList = ({
  programPk,
  featuredSegments: _featuredSegments = [],
  segmentListHeaderTitle,
  isProgramSegmentList = false,
  programLink,
  onLaunchMediaPlayer,
  onExpand = () => {},
  totalSegments = 3,
  seeMoreSegments = true
}: SegmentListProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [segments, setSegments] = useState<SnippetSegmentType[]>([]);
  const [featuredSegments, setFeaturedSegments] = useState(_featuredSegments);
  const [isLoading, setIsLoading] = useState(false);
  const [getSegmentListTrigger] = useLazyGetLibrarySegmentsQuery();
  const previousData = usePrevious(segments);

  const handleExpand = useCallback(
    async (value: string) => {
      setIsLoading(true);
      setIsExpanded(prevExpanded => !prevExpanded);
      onExpand?.(value);
      const { data } = await getSegmentListTrigger(programPk, true);

      if (data && data.length && data !== previousData) {
        const remainingSegments = data.map((seg, index) =>
          getSegmentFromLibraryItem({ ...seg, topicNumber: index + 1 })
        );

        setFeaturedSegments(() =>
          value !== ''
            ? [...remainingSegments.slice(0, featuredSegments.length)]
            : [..._featuredSegments]
        );
        setSegments([...remainingSegments.slice(featuredSegments.length)]);
      }
      setIsLoading(false);
    },
    [onExpand, getSegmentListTrigger, programPk, previousData, featuredSegments, _featuredSegments]
  );

  const items = useMemo(
    () =>
      isLoading
        ? [<SegmentListLoadingState totalSegments={totalSegments ?? undefined} />]
        : segments.map((seg, index, arr) => {
            return (
              <div
                className={classnames('cursor-pointer rounded-lg px-8 hover:bg-gray-light', {
                  'hover:rounded-none': index < arr.length - 1,
                  'hover:rounded-t-none': index === arr.length - 1,
                  'pb-6': index < arr.length - 1 || !isProgramSegmentList
                })}
                onClick={() => (seg.playable ? onLaunchMediaPlayer?.(seg) : undefined)}
                key={seg.pk}
              >
                {!(index === 0 && isProgramSegmentList) ? (
                  <Divider
                    color="light"
                    className={classnames({
                      'mb-6': index < arr.length
                    })}
                  />
                ) : (
                  <div className="pb-6 hover:bg-gray-light" />
                )}
                <SegmentListItem {...seg} />
                {index === arr.length - 1 && isProgramSegmentList && (
                  <Divider
                    color="light"
                    className={classnames({
                      'mt-6': index < arr.length
                    })}
                  />
                )}
              </div>
            );
          }),
    [segments, isProgramSegmentList, onLaunchMediaPlayer, isLoading, totalSegments]
  );

  if (isProgramSegmentList) {
    return (
      <div className="rounded-lg bg-gray-feather">
        <div className=" hover:rounded-lg">
          {featuredSegments.map((featuredSegment, index) => (
            <>
              <div
                className={classnames('hover:bg-gray-light', {
                  'pointer-events-none': featuredSegment.playable === false,
                  'hover:rounded-t-lg': index === 0,
                  'hover:rounded-b-lg': segments?.length === 0
                })}
              >
                <div
                  className="flex flex-1 cursor-pointer flex-col items-start justify-start rounded-t-lg px-8 py-6 hover:!bg-transparent"
                  onClick={() =>
                    featuredSegment.playable ? onLaunchMediaPlayer?.(featuredSegment) : undefined
                  }
                >
                  <SegmentListItem {...featuredSegment} />
                </div>
              </div>
              {featuredSegments?.length > 0 &&
              !(index === featuredSegments.length - 1 && !seeMoreSegments) ? (
                <div className="px-8">
                  <Divider color="light" />
                </div>
              ) : null}
            </>
          ))}
        </div>
        <Accordion
          type="single"
          onValueChange={handleExpand}
          items={[
            {
              content: items,
              header: <SegmentListHeader isProgramSegmentList expanded={isExpanded} />
            }
          ]}
          reverseExpand
          headerClassNames={classnames(
            'pl-1 pr-8 hover:rounded-b-lg hover:bg-gray-light data-[state=open]:rounded-t-none',
            { hidden: !seeMoreSegments }
          )}
        />
      </div>
    );
  }

  return (
    <Accordion
      type="single"
      classNames="rounded-lg bg-gray-feather"
      onValueChange={handleExpand}
      headerClassNames="pl-1 pr-8 hover:rounded-lg hover:bg-gray-light data-[state=open]:hover:rounded-b-none"
      items={[
        {
          content: items,
          header: (
            <SegmentListHeader
              isProgramSegmentList={false}
              segmentListHeaderTitle={segmentListHeaderTitle}
              segmentLink={programLink}
            />
          )
        }
      ]}
    />
  );
};

export default SegmentList;
