import { OnDemandMp3Variation as OnDemandMp3VariationProps } from '@/@types/content';
import { AnalyticsContext } from '@/analytics/constants';
import withNav from '@/components/hocs/withNav';
import OnDemandSegmentPDP from '@/components/ui/PDP/OnDemandSegmentPDP';
import PDPShimmer from '@/components/ui/PDP/PDPShimmer';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import { useOnDemandSegmentCatalogRelations } from '@/hooks/PDP/useProgramCatalogRelations';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';

const OnDemandMp3VariationComponent = (variant: OnDemandMp3VariationProps) => {
  const authReady = useSelector((state: RootState) => state.auth.ready);
  const { isLoading: isLabelsLoading } = useCatalogLabels();

  const { relations, isLoading: isCatalogLoading } = useOnDemandSegmentCatalogRelations(variant);

  if (!authReady || !relations?.segmentVariant || isCatalogLoading || isLabelsLoading)
    return <PDPShimmer show={true} />;

  return (
    <OnDemandSegmentPDP
      catalogRelations={relations}
      loadingCatalogRelations={isCatalogLoading}
      selectDownloadByDefault={variant}
      context={AnalyticsContext.PDP}
    />
  );
};

const OnDemandMp3Variation = withNav(OnDemandMp3VariationComponent);
export default OnDemandMp3Variation;
