import classnames from 'classnames';
import Icon from './Icon';

type IconMessageProps = {
  iconClassName?: string;
  iconName?: string;
  text: string;
  fontType?: string;
};

const IconMessage = ({
  iconClassName,
  iconName = 'pending',
  text,
  fontType = 'font-medium'
}: IconMessageProps) => (
  <div className={classnames(fontType, 'text-2 flex items-center gap-2')} data-component={"IconMessage"}>
    <Icon name={iconName} size="small" className={classnames(iconClassName, 'shrink-0')} />
    {text}
  </div>
);

export default IconMessage;
