import { useState } from 'react';
import { TFilterItem } from '../Filter/FilterRow';
import ComplexTooltip from '../Tooltip/ComplexTooltip';
import FilterButton from '../Buttons/FilterButton';
import Link from '../Link';
import { useDispatch } from 'react-redux';
import { clearAllFilters, SemanticFilterTooltipData } from '@/redux/slices/searchSlice';
import { unshiftQuery } from './utils/semanticFilters';
import { useSearchParamsStable } from '@/hooks/useSearchParams';

interface SemanticFilterTooltipProps {
  fetching: boolean;
  filter: TFilterItem;
  onClick: () => void;
  tooltipData: SemanticFilterTooltipData;
}
const SemanticFilterTooltip = ({
  filter,
  onClick,
  fetching,
  tooltipData
}: SemanticFilterTooltipProps) => {
  const dispatch = useDispatch();
  const { setSearchParams } = useSearchParamsStable();
  const [isOpen, setIsOpen] = useState(true);

  const handleClearFilter = () => {
    dispatch(clearAllFilters());
    unshiftQuery(setSearchParams);
  };

  return (
    <ComplexTooltip isOpen={isOpen} onOpenChange={() => setIsOpen(false)}>
      <ComplexTooltip.Trigger>
        <FilterButton
          key={filter?.data?.value}
          label={filter?.data?.label}
          onClick={onClick}
          isSelected={filter?.data?.checked}
          disabled={!filter?.canDeselect && filter?.data?.checked}
          onFocus={e => {
            if (e.target.matches(':focus-visible')) {
              e.target.scrollIntoView({ behavior: 'smooth', inline: 'center' });
            }
          }}
          fetching={fetching}
        />
      </ComplexTooltip.Trigger>
      <ComplexTooltip.Content>
        <div className="text-2">
          <p className="mb-2 font-bold">{tooltipData.title}</p>
          <p>{tooltipData.description}</p>
          <Link
            as="button"
            variant="text-link-2"
            className="mt-3"
            text="Remove filter"
            onClick={handleClearFilter}
          />
        </div>
      </ComplexTooltip.Content>
    </ComplexTooltip>
  );
};

export default SemanticFilterTooltip;
