import { AnswerBookVariation as AnswerBookVariationProps } from '@/@types/content';
import { AnalyticsContext } from '@/analytics/constants';
import withNav from '@/components/hocs/withNav';
import ABTreatisePDP from '@/components/ui/PDP/ABTreatisePDP';
import PDPShimmer from '@/components/ui/PDP/PDPShimmer';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import { useABTreatiseCatalogRelations } from '@/hooks/PDP/usePublicationCatalogRelations';

const AnswerBookVariationComponent = (variant: AnswerBookVariationProps) => {
  const catalogRelations = useABTreatiseCatalogRelations(variant);
  const { isLoading: isLabelsLoading } = useCatalogLabels();

  if (!catalogRelations.printVariants?.length || catalogRelations.isLoading || isLabelsLoading)
    return <PDPShimmer show={true} />;

  const initialVariant =
    variant.code === catalogRelations?.stsVariant?.code
      ? catalogRelations.stsVariant
      : catalogRelations.printVariants.find(v => v.code === variant.code);

  if (initialVariant && !catalogRelations.isLoading)
    return (
      <ABTreatisePDP
        catalogRelations={catalogRelations}
        initialVariant={initialVariant}
        context={AnalyticsContext.PDP}
      />
    );

  // If the requested variant is not eligible to be shown in PDP (previous edition, or STS user cannot access):
  return (
    <ABTreatisePDP
      catalogRelations={catalogRelations}
      initialVariant={catalogRelations.printVariants[0]}
      context={AnalyticsContext.PDP}
    />
  );
};

const AnswerBookVariation = withNav(AnswerBookVariationComponent);
export default AnswerBookVariation;
