import { forwardRef as ReactForwardRef } from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import classnames from 'classnames';

enum Orientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal'
}

interface ScrollAreaProps extends React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> {
  children: React.ReactNode;
  orientation?: `${Orientation}`;
  barClassName?: string;
  thumbClassName?: string;
}

interface ScrollBarProps
  extends React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar> {
  orientation?: `${Orientation}`;
  barClassName?: string;
  thumbClassName?: string;
}

const ScrollArea = ({
  orientation = Orientation.Vertical,
  barClassName,
  thumbClassName,
  children,
  ...props
}: ScrollAreaProps) => {
  return (
    <ScrollAreaPrimitive.Root
      {...props}
      className={classnames('relative overflow-hidden', props.className)}
    >
      {/* !block is Workaround for radix scroll area display:table bug https://github.com/radix-ui/primitives/issues/2722#issuecomment-2404594842 */}
      <ScrollAreaPrimitive.Viewport className="size-full rounded-[inherit] [&>div]:!block">
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar
        orientation={orientation}
        barClassName={barClassName}
        thumbClassName={thumbClassName}
      />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  );
};

const ScrollBar = ReactForwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  ScrollBarProps
>(({ orientation = Orientation.Vertical, barClassName, thumbClassName, ...props }, ref) => {
  return (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      {...props}
      ref={ref}
      orientation={orientation}
      className={classnames(
        'flex touch-none select-none rounded p-0 transition-colors',
        {
          'mx-1 h-full w-1': orientation === Orientation.Vertical,
          'my-1 h-1 flex-col': orientation === Orientation.Horizontal
        },
        barClassName
      )}
    >
      <ScrollAreaPrimitive.ScrollAreaThumb
        className={classnames('relative flex-1 rounded-full bg-gray-dark', thumbClassName)}
      />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  );
});

ScrollArea.Bar = ScrollBar;

export default ScrollArea;
