import { IContent } from '@/@types/cms';
import {
  BookProduct,
  CourseHandbookVariation,
  LiveProgramProduct,
  MasterProgramNode,
  OnDemandProgramProduct,
  OnDemandSegmentProduct,
  OnDemandWebVariation,
  TreatiseVariation,
  VariationContent
} from '@/@types/content';
import { LiveContentType, ProgramVariation } from '@/hooks/PDP/useProgramCatalogRelations';
import { isCHBVariant } from '@/hooks/PDP/usePublicationCatalogRelations';
import { Helmet } from 'react-helmet';
import { chbOverviewFallback } from './labels';
import { getPDPBrowserTitle, getProductImageUrl } from './helpers';

interface ProductSEOProps {
  variant: IContent;
  programProduct?: LiveProgramProduct | OnDemandProgramProduct;
  segmentProduct?: OnDemandSegmentProduct;
  bookProduct?: BookProduct;
  masterProgram?: MasterProgramNode;
  liveContentType?: LiveContentType;
  // browserTitle: string,
}
export const ProductSEO = ({
  variant,
  programProduct,
  segmentProduct,
  bookProduct,
  masterProgram,
  liveContentType
}: ProductSEOProps) => {
  const product = bookProduct ?? segmentProduct ?? programProduct;
  if (!product) return <></>; // SEO dependencies not loaded yet

  // url
  const canonicalUrl = variant.url;

  // title
  const onDemandType = segmentProduct
    ? 'segment'
    : programProduct && !liveContentType
      ? 'program'
      : undefined;
  const browserTitle = getPDPBrowserTitle({ variant, liveContentType, onDemandType, bookProduct });

  // meta keywords
  const metaKeywords = product?.seoInformation?.keywords;

  // override product description (content-edit)
  const productSeoDescription = product?.seoInformation?.description;

  // default/fallback product description(s) from catalog
  const chbOverview = isCHBVariant(variant)
    ? chbOverviewFallback.replace('{0}', (variant as CourseHandbookVariation)?.displayName ?? '')
    : undefined;
  const bookOverviewFromContent =
    (variant as TreatiseVariation)?.enrichedDescription ??
    (variant as TreatiseVariation)?.description;
  const bookOverview =
    !bookOverviewFromContent && chbOverviewFallback ? chbOverview : bookOverviewFromContent;

  const programOverview =
    (variant as ProgramVariation)?.description ??
    (variant as OnDemandWebVariation)?.segmentDescription ??
    masterProgram?.overview;
  const sellingPoints =
    (variant as ProgramVariation)?.sellingPoints ??
    product?.sellingPoints ??
    masterProgram?.sellingPoints;

  // metaDescription should respect override content-edit description, and default to sellingPoints/overview otherwise
  const metaDescription = productSeoDescription ?? sellingPoints ?? programOverview ?? bookOverview;

  // og:type is 'event' for Live Programs, 'product' for OnDemand Programs and books
  const ogType = liveContentType ? 'event' : 'product';

  // twitter:title, og:title should respect override content-edit, and default to browserTitle
  const twitterOgTitle = product?.openGraphAndTwitterTitle ?? browserTitle;

  // twitter:description, og:description should respect override content-edit, and default to metaDescription
  const twitterOgDescription = product?.openGraphAndTwitterDescription ?? metaDescription;

  // og:image and twitter:image should respect override content-edit, and default to product image
  const setImageOverrideHost = (imgUrl?: string) =>
    imgUrl ? `${window.location.origin}${imgUrl}` : undefined;
  const defaultProductImage = getProductImageUrl((variant as VariationContent)?.code ?? '');
  const ogImage = setImageOverrideHost(product?.openGraphImage?.url) ?? defaultProductImage;
  const twitterImage = setImageOverrideHost(product?.twitterImage?.url) ?? defaultProductImage;
  const setMetaImageQueryParams = (imgUrl: string) => `${imgUrl}?format=webp&width=1600`;

  return (
    <Helmet>
      <title>{browserTitle}</title>
      <link rel="canonical" href={canonicalUrl} />
      <meta name="title" content={browserTitle} />
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={ogType} />
      {twitterOgTitle && <meta property="og:title" content={twitterOgTitle} />}
      {twitterOgTitle && <meta name="twitter:title" content={twitterOgTitle} />}
      {twitterOgDescription && <meta property="og:description" content={twitterOgDescription} />}
      {twitterOgDescription && <meta name="twitter:description" content={twitterOgDescription} />}
      {ogImage && <meta property="og:image" content={setMetaImageQueryParams(ogImage)} />}
      {twitterImage && (
        <meta name="twitter:image" content={setMetaImageQueryParams(twitterImage)} />
      )}
    </Helmet>
  );
};
