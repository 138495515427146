import OnDemand from '@/components/ui/PDP/ECommBox/OnDemand';
import {
  resetPDPContext,
  setCatalogRelations,
  setHardSelectedVariant
} from '@/redux/slices/pdpSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { OnDemandWebVariation } from '@/@types/content';
import {
  getExpiredStatus,
  getOnDemandProgramRuntime,
  getPriceFromContent,
  getProductImageUrl
} from '../PDP/helpers';
import useEcommPanelData, { EcommPanelParams } from './hooks/useEcommData';
import { transformIfPLIUrl } from '@/utils/helpers';
import { AnalyticsContext } from '@/analytics/constants';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import Loading from '../PDP/ECommBox/OnDemand/components/Loading';
import { isContentType } from '@/lib/helpers/contentType';

type EcommPanelOnDemandSegmentProps = {
  params: EcommPanelParams;
  onClose: () => void;
  context?: AnalyticsContext;
};

const EcommPanelOnDemandSegment = ({
  params,
  onClose,
  context
}: EcommPanelOnDemandSegmentProps) => {
  const dispatch = useDispatch();

  const { onDemandEcomLabels, isLoading: isLabelsLoading } = useCatalogLabels();

  const { data, isLoading } = useEcommPanelData(params);

  const segmentVariants = useMemo(
    () =>
      (data?.filter(
        seg =>
          isContentType(seg, 'OnDemandWebVariation') &&
          (seg as OnDemandWebVariation)?.code === params?.pk
      ) ?? []) as OnDemandWebVariation[],
    [data, params?.pk]
  );

  const variants = useMemo(
    () => ({
      variants: segmentVariants.map(variant => {
        const priceInfo = getPriceFromContent(variant);
        return {
          code: variant.code!,
          location: variant.location ?? '',
          publicationDate: variant.publicationDate,
          displayName: variant.displayName ?? '',
          itemClass: variant.itemClassId,
          retailPrice: priceInfo.rawRetailPrice,
          discountPrice: priceInfo.rawDiscountPrice,
          price: priceInfo.price,
          originalPrice: priceInfo.strikePrice,
          retail: priceInfo.retail,
          isExpired: getExpiredStatus(variant),
          thumbnail: getProductImageUrl(variant.code!),
          duration: getOnDemandProgramRuntime(variant.runTimeSeconds).thumbnail,
          purchased: params.action === 'StartWatching'
        };
      })
    }),
    [params, segmentVariants]
  );

  useEffect(() => {
    dispatch(resetPDPContext());
    return () => {
      dispatch(resetPDPContext());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const selected = variants.variants.find(variant => variant.code === params.pk)!;
    dispatch(setHardSelectedVariant(selected));
    dispatch(setCatalogRelations(variants));
  }, [dispatch, variants, params, data]);

  if (isLoading || isLabelsLoading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <OnDemand
      onClosePanel={onClose}
      labels={onDemandEcomLabels}
      title={params.title}
      seeDetailsLink={transformIfPLIUrl(params.url)}
      context={context}
      creditTrackerCode={params.creditTrackerCode}
    />
  );
};

export default EcommPanelOnDemandSegment;
