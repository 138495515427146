import Shimmer from '../Shimmer';

interface PDPShimmerProps {
  show?: boolean;
}

const PDPShimmer: React.FC<PDPShimmerProps> = ({ show }) => {
  if (!show) return null;

  return (
    <div className="container module-spacing">
      <div className="flex items-center gap-2">
        <Shimmer className="h-4 w-5 rounded-md" />
        <Shimmer className="h-5 w-24 rounded-md" />
      </div>
      <Shimmer className="mt-8 h-40 w-full rounded-lg md:h-60" />
      <div className="mt-8 flex items-center justify-between">
        <div className="flex gap-2">
          <Shimmer className="h-5 w-24 rounded-md" />
          <Shimmer className="h-5 w-px rounded-md" />
          <Shimmer className="h-5 w-24 rounded-md" />
        </div>
        <div className="hidden gap-4 md:flex">
          <div className="flex items-center gap-2">
            <Shimmer className="size-9 rounded-full" />
            <Shimmer className="h-5 w-28 rounded-md" />
          </div>
          <div className="flex items-center gap-2">
            <Shimmer className="size-9 rounded-full" />
            <Shimmer className="h-5 w-16 rounded-md" />
          </div>
        </div>
        <div className="md:hidden">
          <Shimmer className="size-9 rounded-full" />
        </div>
      </div>

      <div className="mt-10 hidden flex-col gap-8 lg:flex">
        <Shimmer className="h-10 w-5/6 rounded-lg" />
        <Shimmer className="h-28 w-full rounded-lg" />
        <Shimmer className="h-64 w-1/2 rounded-lg" />
      </div>
    </div>
  );
};

export default PDPShimmer;
