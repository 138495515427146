import { IFacetGroup } from '@/@types/client-api';
import { SearchFindAProgramButtonsBlock as SearchFindAProgramButtons } from '@/@types/content';
import { SearchContext } from '@/analytics/constants';
import FindAProgram from '@/components/ui/FindAProgram/FindAProgram';
import { colors } from '@/constants/tailwind';

interface SearchFindAProgramButtonsBlockProps extends SearchFindAProgramButtons {
  defaultFacetGroups?: IFacetGroup[];
}

const SearchFindAProgramButtonsBlock = ({
  title,
  tooltip,
  defaultFacetGroups
}: SearchFindAProgramButtonsBlockProps): JSX.Element => {
  return (
    <section className="bg-gray-lightest pt-12">
      <div className="container">
        <FindAProgram
          title={title}
          tooltip={tooltip}
          footerBackgroundColor={colors.gray.lightest}
          searchContext={SearchContext.FAPBlock}
          defaultFacetGroups={defaultFacetGroups}
        />
      </div>
    </section>
  );
};

export default SearchFindAProgramButtonsBlock;
