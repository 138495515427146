import { IContent, LinkItem } from '@/@types/cms';

export enum LargeCarouselColor {
  Charcoal = 'charcoal',
  Green = 'green',
  Teal = 'teal',
  Blue = 'blue',
  Indigo = 'indigo',
  Purple = 'purple',
  Magenta = 'magenta'
}

export enum LargeCarouselTheme {
  Fifty = '50',
  Full = 'full',
  Gallery = 'gallery'
}

export const LargeCarouselBgColorClasses = {
  [LargeCarouselColor.Charcoal]: 'bg-charcoal',
  [LargeCarouselColor.Green]: 'bg-green-100',
  [LargeCarouselColor.Teal]: 'bg-teal-100',
  [LargeCarouselColor.Blue]: 'bg-blue-100',
  [LargeCarouselColor.Indigo]: 'bg-indigo-100',
  [LargeCarouselColor.Purple]: 'bg-purple-100',
  [LargeCarouselColor.Magenta]: 'bg-magenta-100'
};

export const LargeCarouselSquareBgColorClasses = {
  [LargeCarouselColor.Charcoal]: 'bg-charcoal',
  [LargeCarouselColor.Green]: 'bg-green-200',
  [LargeCarouselColor.Teal]: 'bg-teal-200',
  [LargeCarouselColor.Blue]: 'bg-blue-200',
  [LargeCarouselColor.Indigo]: 'bg-indigo-200',
  [LargeCarouselColor.Purple]: 'bg-purple-200',
  [LargeCarouselColor.Magenta]: 'bg-magenta-200'
};

export interface LargeCarouselItemProps extends IContent {
  heading?: string;
  displayName?: string;
  copy?: string;
  sellingPoints?: string;
  slideImage?: {
    url?: string;
  };
  mobileImage?: {
    url?: string;
  };
  link?: LinkItem;
  itemVariant?: `${LargeCarouselColor}`;
  caption?: string;
  code?: string;
}
