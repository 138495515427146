import classnames from 'classnames';
import Text from '@/components/cms/Text';
import Image from '@/components/cms/Image';
import ContentArea from '@/components/cms/ContentArea';
import type { TwoUpBlock as TwoUpBlockProps } from '@/@types/content';
import Block from '@/components/cms/Block';

const TwoUpBlock: React.FC<TwoUpBlockProps> = ({
  contentLink,
  heading,
  icon,
  content = [],
  background
}) => {
  const twoUpBlockContentClassNames = classnames({
    'bg-gray-lightest': background === 'gray',
    'bg-gray-darker text-white': background === 'darker gray'
  });

  return (
    <Block contentLinkID={contentLink?.id} className={twoUpBlockContentClassNames}>
      <div className="container module-spacing"  data-component={"TwoUpBlock"}>
        <div className="flex items-center gap-3 lg:gap-4">
          <Image
            src={icon?.url}
            alt={icon?.altText as string}
            propertyName="Image"
            className="h-5 md:h-6"
          />
          <Text element="h2" propertyName="Heading" className="heading-5">
            {heading}
          </Text>
        </div>
        <ContentArea
          propertyName="GenericContentCardBlock"
          components={content}
          className={classnames('my-6 grid gap-4 sm:my-8 lg:gap-6', {
            'lg:grid-cols-2': content.length > 1
          })}
        />
      </div>
    </Block>
  );
};

export default TwoUpBlock;
