import { ClientAPI } from '@/redux/api';
import { LibraryFilterGroupResult, LibraryResponse } from '@/@types/client-api';
import { RootState } from '@/redux/store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { LibraryRequest } from './library';
import { queryStringBuilder } from '@/redux/helpers';
import { getOnlyActiveTabLabel, isItemChecked } from '@/utils/libraryUtils';
import { LibraryFilterKey } from '@/utils/libraryConstants';

const internalLibraryEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getLibraryResultsItems: builder.query<LibraryResponse, LibraryRequest>({
      query: request => ({
        url: `/user/library${queryStringBuilder(request)}`,
        method: 'GET'
      }),
      merge: (currentCache, newResponse, { arg }) => {
        if (currentCache.items && arg.pageNumber && arg.pageNumber !== 1) {
          // Add the previous results to the beginning of the new list from the API
          newResponse.items?.unshift(...(currentCache.items ?? []));
        }

        return newResponse;
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };

        // Remove pageNumber and pageSize from the query args
        // so that when user goes to a new page, we don't create a new cache entry.
        // (Instead, we update the previous entry in `merge` function above)
        if (newQueryArgs.pageNumber) {
          delete newQueryArgs.pageNumber;
        }

        if (newQueryArgs.pageSize) {
          delete newQueryArgs.pageSize;
        }

        return newQueryArgs;
      },
      transformResponse(data: LibraryResponse) {
        return {
          ...data,
          summary: {
            ...data.summary,
            filtersApplied: {
              ...data.summary?.filtersApplied,
              groupSearchableType:
                getOnlyActiveTabLabel(data) || data.summary?.filtersApplied?.groupSearchableType
            }
          }
        };
      }
    }),
    getLibraryResultsFiltersOnly: builder.query<LibraryResponse, LibraryRequest>({
      query: body => ({
        url: `/user/library${queryStringBuilder({
          ...body,
          pageSize: 0,
          pageNumber: 1
        })}`,
        method: 'GET'
      }),

      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };

        // Delete props that will not affect the filters

        if (newQueryArgs.pageNumber) {
          delete newQueryArgs.pageNumber;
        }

        if (newQueryArgs.sort) {
          delete newQueryArgs.sort;
        }

        if (newQueryArgs.pageSize) {
          delete newQueryArgs.pageSize;
        }

        return newQueryArgs;
      }
    })
  })
});

export const makePrimaryRequest = async ({
  requestBody,
  dispatch,
  forceRefetch
}: {
  requestBody: LibraryRequest;
  dispatch: ThunkDispatch<RootState, unknown, Action>;
  forceRefetch?: boolean;
}) =>
  dispatch(
    internalLibraryEndpoints.endpoints.getLibraryResultsItems.initiate(requestBody, {
      forceRefetch
    })
  );

export const makeDisjunctiveFilterRequest = async ({
  filter,
  requestBody,
  getState,
  dispatch,
  forceRefetch
}: {
  filter: { name: LibraryFilterKey; value: string };
  requestBody: LibraryRequest;
  getState: () => RootState;
  dispatch: ThunkDispatch<RootState, unknown, Action>;
  forceRefetch?: boolean;
}) => {
  const newRequest = {
    ...(requestBody as object),
    // Make a request *without* the current filter group selections
    // to prevent selections within the group from affecting the results.
    [filter.name]: undefined
  };

  const state = getState() as RootState;
  // Check to see if we already have an appropriate response in the cache
  const existingResponse =
    internalLibraryEndpoints.endpoints.getLibraryResultsItems.select(newRequest)(state);

  const filterRes =
    existingResponse.data && !forceRefetch
      ? existingResponse
      : // If not, make a new request just to get the filters
        await dispatch(
          internalLibraryEndpoints.endpoints.getLibraryResultsFiltersOnly.initiate(newRequest, {
            forceRefetch
          })
        );

  // Return data for current group
  return filterRes.data?.summary?.filterResultSummary?.find(f => f.name === filter.name);
};

export const updateGroupData = ({
  newGroup,
  dataToUpdate
}: {
  newGroup: LibraryFilterGroupResult | undefined;
  dataToUpdate: LibraryResponse;
}) => {
  const oldGroup = dataToUpdate.summary?.filterResultSummary?.find(
    oldGroup => oldGroup.name === newGroup?.name
  );
  if (!oldGroup || !newGroup?.filters) return;
  const oldData = dataToUpdate.summary?.filtersApplied?.[oldGroup.name as LibraryFilterKey];

  const facetsToIterateOver = [...newGroup.filters];
  oldGroup.filters?.forEach(oldFacet => {
    if (
      !facetsToIterateOver.some(facet => facet.name === oldFacet.name) &&
      isItemChecked(oldFacet.name, oldData)
    ) {
      facetsToIterateOver.unshift(oldFacet);
    }
  });

  oldGroup.filters = facetsToIterateOver
    .map(newFacet => {
      const oldFacet = oldGroup.filters?.find(oldFacet => oldFacet.name === newFacet.name);
      return {
        ...newFacet,
        checked: isItemChecked(oldFacet?.name, oldData) ?? false
      };
    })
    .sort((a, b) => {
      if (a.checked && !b.checked) return -1;
      if (!a.checked && b.checked) return 1;
      return 0;
    });
};
