import { screens } from './tailwind';

const minWidth = (breakpoint: string): string => `(min-width: ${breakpoint})`;

const maxWidth = (breakpoint: string): string =>
  `(max-width: ${parseInt(breakpoint.replace('px', '')) - 1}px)`;

const widthRange = (minBreakpoint: string, maxBreakpoint: string): string =>
  `${minWidth(minBreakpoint)} and ${maxWidth(maxBreakpoint)}`;

export type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export type BreakpointObjectType = {
  [key in Breakpoints]?: string;
};
export const ScreenSizesMinimums: BreakpointObjectType = {
  xs: '0px',
  sm: screens.sm,
  md: screens.md,
  lg: screens.lg,
  xl: screens.xl,
  '2xl': screens['2xl']
};

type ScreenSizeQueriesBreakpoints =
  | Breakpoints
  | 'xsOnly'
  | 'smOnly'
  | 'belowMd'
  | 'mdOnly'
  | 'belowLg'
  | 'lgOnly'
  | 'xlOnly';

type ScreenSizeQueriesType = {
  [key in ScreenSizeQueriesBreakpoints]: string;
};

export const ScreenSizeQueries: ScreenSizeQueriesType = {
  xs: minWidth('0px'),
  xsOnly: maxWidth(screens.sm),
  sm: minWidth(screens.sm),
  smOnly: widthRange(screens.sm, screens.md),
  belowMd: maxWidth(screens.md),
  md: minWidth(screens.md),
  mdOnly: widthRange(screens.md, screens.lg),
  belowLg: maxWidth(screens.lg),
  lg: minWidth(screens.lg),
  lgOnly: widthRange(screens.lg, screens.xl),
  xl: minWidth(screens.xl),
  xlOnly: widthRange(screens.xl, screens['2xl']),
  '2xl': minWidth(screens['2xl'])
};
