import classnames from 'classnames';

const weekDays: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

type WeekDayHeaderProps = {
  className?: string;
};

const WeekDayHeader = ({ className }: WeekDayHeaderProps): JSX.Element => {
  return (
    <div className={classnames('flex justify-between', className)}>
      {weekDays.map(weekDay => (
        <div
          key={weekDay}
          className="text-2 flex size-8 items-center justify-center font-medium text-gray-medium"
        >
          <span>{weekDay.charAt(0)}</span>
        </div>
      ))}
    </div>
  );
};

export default WeekDayHeader;
