import * as Dialog from '@radix-ui/react-dialog';

export interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal = ({ open, setOpen, children }: React.PropsWithChildren<ModalProps>) => {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      {children}
    </Dialog.Root>
  );
};

Modal.Trigger = Dialog.Trigger;

export default Modal;
