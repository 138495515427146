import Modal from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';
import CreditLicenseCard from '@/components/ui/PDP/ProgramCredit/CreditLicenseCard';
import { useEffect, useState } from 'react';
import type { CreditLicenseCardProps } from '@/components/ui/PDP/ProgramCredit/CreditLicenseCard';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import AllJurisdictionsTable from '@/components/ui/Tables/AllJurisdictionsTable';
import { trackViewAllJurisdictions } from '@/analytics/jurisdictions';
import { TransitionalStatus } from '@/@types/client-api';

type ViewCreditsForAllJurisdictionProps = {
  header?: string;
  modalHeader?: string;
  credits?: CreditLicenseCardProps[];
};

export interface CreditDetails {
  creditOffered: number;
  typeTitle: string;
}

export interface RowInfo {
  key: number;
  jurisdiction: string;
  statusDescription: string;
  statusEnum: string;
  total: number;
  creditDetails: CreditDetails[];
  isTransitional?: boolean;
  regionSk?: number;
}

export interface JurisdictionInfo {
  rowInfo: RowInfo[];
}

const ViewCreditsForAllJurisdiction: React.FC<ViewCreditsForAllJurisdictionProps> = ({
  header,
  modalHeader,
  credits
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) trackViewAllJurisdictions();
  }, [isOpen]);

  const belowMd = useMediaQuery(ScreenSizeQueries.belowMd);

  const renderTableRows = (credits: CreditLicenseCardProps[]) => {
    return (
      credits?.map((credit, i) => {
        return {
          key: i,
          jurisdiction: credit.description ?? '',
          statusDescription: credit.statusDescription ?? '',
          statusEnum: credit.statusEnum ?? '',
          total: credit.totalOffered ?? 0,
          creditDetails: credit.credits ?? '',
          isTransitional: credit.transitionalStatus === TransitionalStatus._1,
          regionSk: credit.regionSk
        } as RowInfo;
      }) ?? []
    );
  };

  return (
    <Modal open={isOpen} setOpen={setIsOpen}>
      <Modal.Trigger asChild>
        <button className="text-1 underline sm:text-2 hover:text-red">{header}</button>
      </Modal.Trigger>
      <ModalContent title={modalHeader} isLarge={true} containerClassName="max-w-[1278px]">
        <div className="flex flex-col gap-4">
          {belowMd ? (
            credits!.map((credit: CreditLicenseCardProps, index) => (
              <CreditLicenseCard key={credit.regionSk ?? index} {...credit} />
            ))
          ) : (
            <AllJurisdictionsTable
              tableHeader={['Jurisdiction', 'Status', 'Total', 'Credit details']}
              rows={renderTableRows(credits ?? [])}
            />
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ViewCreditsForAllJurisdiction;
