import {
  AdHocFilter,
  FILTERS,
  FilterTypes,
  InvariantFilter,
  PAGE_SIZE
} from '@/components/ui/SearchResults/constants';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { IFacetResultItem, SearchRequest, SearchResponse } from '@/@types/client-api';
import { SemanticFilter } from '@/components/ui/SearchResults/hooks/useGetSemanticFilters';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { trackClearAllFiltersEvent, trackFilterEvent } from '@/analytics/search';
import { formatDateFilter } from '@/components/ui/Filter/DateFilterUtils';
import { FilterContext, SearchContext } from '@/analytics/constants';

export type CommonSearchState = {
  isLoading: boolean;
  isFetching: boolean;
  data: SearchResponse | null;
  request: SearchRequest | null;
  error: FetchBaseQueryError | SerializedError | null;
  adHocFilters: AdHocFilter[];
  preselectedFiltersThatHaveBeenDeselected: string[];
};

// Example value CLE-Ethic[CLE-CA,CLE-GA,CLE-NY]
const JURISDICTION_CT_REGEX = /[^[]+\[([^\]]+)\]/;
const getJurisdictionsFromCreditType = (facet: IFacetResultItem) => {
  const jurisdictions = facet.value?.match(JURISDICTION_CT_REGEX);
  return jurisdictions ? jurisdictions[1].split(',') : [];
};
export const getCreditTypeNameFromCreditType = (facet: IFacetResultItem) => {
  return facet.value?.split('[')[0];
};

export const createToggleFilterHelper =
  (allowedAdHocFilters: FILTERS[]) =>
  (
    state: CommonSearchState,
    action: PayloadAction<{
      groupTypeId: string;
      value: string;
      type?: FilterTypes;
      name: string;
      context?: FilterContext;
      searchContext?: SearchContext;
    }>
  ) => {
    const { groupTypeId, value, type, name, context, searchContext } = action.payload;

    const filters = state.data?.facetResults?.facetGroups;

    let filter = filters?.find(filter => filter.groupTypeId === groupTypeId);
    if (!filter) {
      filter = {
        groupTypeId,
        facets: []
      };
      filters?.push(filter);
    }

    if (groupTypeId === FILTERS.DATERANGE) {
      const facet = filter.facets?.find(facet => facet.name === name);
      if (facet) {
        if (groupTypeId && value) {
          trackFilterEvent({
            facetGroup: groupTypeId,
            facetValue: formatDateFilter(name, value),
            context,
            searchContext
          });
        }
        facet.value = value;
      } else {
        filter.facets?.push({ value, name });
      }
    } else {
      if (type === FilterTypes.RADIO || type === FilterTypes.TABS) {
        filter.facets?.forEach(facet => (facet.checked = false));
      }

      const facet = filter.facets?.find(facet => facet.value === value);

      if (facet) {
        facet.checked = !facet.checked;
        if (facet.checked) {
          trackFilterEvent({ facetGroup: groupTypeId, facetValue: value, context, searchContext });
        }
        if (!facet.checked && facet.shouldBePreselected) {
          state.preselectedFiltersThatHaveBeenDeselected.push(facet.value!);
        }
      } else {
        trackFilterEvent({ facetGroup: groupTypeId, facetValue: value, context, searchContext });

        const newFacet = {
          value,
          checked: true,
          name: action.payload.name ?? ''
        };
        filter.facets?.push(newFacet);

        if (allowedAdHocFilters.includes(groupTypeId as FILTERS)) {
          state.adHocFilters.push({ value: newFacet.value, groupTypeId, name: newFacet.name });
        }
      }

      const newFacetState = !!facet?.checked;
      // If a jurisdiction is unchecked, uncheck all credit types that contain that jurisdiction
      if (!newFacetState && groupTypeId === FILTERS.JURISDICTIONS) {
        const filtersToUncheck = filters
          ?.find(filter => filter.groupTypeId === FILTERS.CREDIT_TYPE)
          ?.facets?.filter(facet => {
            const jurisdictionList = getJurisdictionsFromCreditType(facet);
            return facet.checked && jurisdictionList.includes(value);
          });
        filtersToUncheck?.forEach(facet => (facet.checked = false));
      }

      // If a credit type is checked, check its parent jurisdiction(s)
      if (groupTypeId === FILTERS.CREDIT_TYPE && newFacetState) {
        const jurisdiction = filters?.find(filter => filter.groupTypeId === FILTERS.JURISDICTIONS);
        const jurisdictionList = getJurisdictionsFromCreditType(facet);
        jurisdictionList.forEach(jurisdictionValue => {
          const jurisdictionFacet = jurisdiction?.facets?.find(
            facet => facet.value === jurisdictionValue
          );
          if (jurisdictionFacet) {
            jurisdictionFacet.checked = true;
          }
        });
      }
    }
    // reset to first page
    if (state.data?.searchResultSummary?.currentPage) {
      state.data.searchResultSummary.currentPage = 1;
      if (state.data.searchResults?.searchResultsItems) {
        state.data.searchResults.searchResultsItems =
          state.data.searchResults.searchResultsItems.slice(0, PAGE_SIZE);
      }
    }
  };

export const createSuccessHelper =
  (allowedAdHocFilters: FILTERS[]) =>
  (
    state: CommonSearchState,
    action: PayloadAction<{
      request: SearchRequest;
      response: SearchResponse;
      preselectedFiltersThatHaveBeenDeselected?: string[];
    }>
  ) => {
    state.error = null;
    state.isLoading = false;
    if ('isFetching' in state) state.isFetching = false;

    state.request = action.payload.request;
    if (action.payload.preselectedFiltersThatHaveBeenDeselected) {
      state.preselectedFiltersThatHaveBeenDeselected =
        action.payload.preselectedFiltersThatHaveBeenDeselected;
    }

    const facetGroups = action.payload.response.facetResults?.facetGroups?.map(group => {
      const facets = group.facets?.map(facet => {
        // Add ad hoc filters to the state
        if (allowedAdHocFilters.includes(group.groupTypeId as FILTERS)) {
          if (facet.checked && !state.adHocFilters.some(filter => filter.value === facet.value)) {
            state.adHocFilters.push({
              groupTypeId: group.groupTypeId ?? '',
              value: facet.value ?? '',
              name: facet.name ?? ''
            });
          }
        }

        return {
          ...facet,
          // Check preselected facets
          checked:
            facet.shouldBePreselected &&
            !state.preselectedFiltersThatHaveBeenDeselected.includes(facet.value!)
              ? true
              : facet.checked
        };
      });

      return {
        ...group,
        facets
      };
    });

    state.data = {
      ...action.payload.response,
      facetResults: {
        ...action.payload.response.facetResults,
        facetGroups
      }
    };
  };

export const clearFilterHelper = (
  state: { data: SearchResponse | null; invariantFilters?: InvariantFilter[] },
  action: PayloadAction<{
    groupId?: string;
    context?: FilterContext;
    searchContext?: SearchContext;
  }>
) => {
  const { context, searchContext, groupId } = action.payload;
  trackClearAllFiltersEvent({
    context,
    searchContext,
    facetGroup: groupId ?? ''
  });

  const filters = state.data?.facetResults?.facetGroups;

  filters?.forEach(group => {
    if (
      (action.payload.groupId && group.groupTypeId !== action.payload.groupId) ||
      group?.groupTypeId === FILTERS.CONTENT_TABS
    ) {
      return;
    }

    group?.facets?.forEach(facet => {
      // Set everything to false except invariant filters
      facet.checked = state?.invariantFilters?.some(item => facet.value === item.value);

      // If a jurisdiction is unchecked, uncheck all credit types that contain that jurisdiction
      if (action.payload.groupId === FILTERS.JURISDICTIONS) {
        const filtersToUncheck = filters
          ?.find(filter => filter.groupTypeId === FILTERS.CREDIT_TYPE)
          ?.facets?.filter(ctFacet => {
            const jurisdictionList = getJurisdictionsFromCreditType(ctFacet);
            return ctFacet.checked && facet.value && jurisdictionList.includes(facet.value);
          });
        filtersToUncheck?.forEach(facet => (facet.checked = false));
      }

      if (group.groupTypeId === FILTERS.DATERANGE) {
        facet.value = '';
      }
    });
  });
};

export const clearAllFiltersHelper = (state: {
  data: SearchResponse | null;
  invariantFilters?: InvariantFilter[];
  semanticFilters: SemanticFilter[] | null;
}) => {
  const filters = state.data?.facetResults?.facetGroups;

  filters?.forEach(group => {
    group?.facets?.forEach(facet => {
      // Set everything to false except invariant filters
      facet.checked = state?.invariantFilters?.some(item => facet.value === item.value);

      // If a jurisdiction is unchecked, uncheck all credit types that contain that jurisdiction
      if (group.groupTypeId === FILTERS.JURISDICTIONS) {
        const filtersToUncheck = filters
          ?.find(filter => filter.groupTypeId === FILTERS.CREDIT_TYPE)
          ?.facets?.filter(ctFacet => {
            const jurisdictionList = getJurisdictionsFromCreditType(ctFacet);
            return ctFacet.checked && facet.value && jurisdictionList.includes(facet.value);
          });
        filtersToUncheck?.forEach(facet => (facet.checked = false));
      }

      if (group.groupTypeId === FILTERS.DATERANGE) {
        facet.value = '';
      }
    });
  });

  state.semanticFilters = [];
};
