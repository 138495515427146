import { AnalyticsContext } from '@/analytics/constants';
import AddToCalendarModal from './AddToCalendarModal';
import DeleteProgramModalWrapper from './DeleteProgramModalWrapper';
import EcommPanel from './EcommPanel';
import { SearchModalPanelState } from './hooks/useSearchModalPanelState';
import SearchMaterialsModal from './SearchMaterialsModal';

type SearchModalPanelProps = {
  state: SearchModalPanelState;
  context?: AnalyticsContext;
};

const SearchModalPanel = ({ state, context }: SearchModalPanelProps): JSX.Element => {
  const { ecommPanel, materialsModal, addToCalendarModal, deleteModal } = state;
  return (
    <>
      <EcommPanel params={ecommPanel.params} onClose={ecommPanel.close} context={context} />
      <SearchMaterialsModal
        params={materialsModal.params}
        onClose={materialsModal.close}
        context={context}
      />
      <AddToCalendarModal
        params={addToCalendarModal.params}
        onClose={addToCalendarModal.close}
        context={context}
      />
      <DeleteProgramModalWrapper params={deleteModal.params} onClose={deleteModal.close} />
    </>
  );
};

export default SearchModalPanel;
