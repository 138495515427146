import Cookies from 'js-cookie';

const Separator = ',';

export const existsCookie = (cookieName: string): boolean => Cookies.get(cookieName) !== undefined;

export const getCookieValues = (cookieName: string): string[] => {
  const cookieValue = Cookies.get(cookieName) ?? '';
  return cookieValue.split(Separator);
};

export const addValuesToCookie = (
  cookieName: string,
  values: string[],
  maxLength: number,
  expirationInDays: number
): void => {
  const previousValues = getCookieValues(cookieName);
  const newCookieValue = [...values, ...previousValues];
  setCookieValues(cookieName, newCookieValue, maxLength, expirationInDays);
};

export const setCookieValues = (
  cookieName: string,
  values: string[],
  maxLength: number,
  expirationInDays: number
): void => {
  const newCookieValue = values
    .filter(value => !!value)
    .slice(0, maxLength)
    .join(Separator);
  try {
    Cookies.set(cookieName, newCookieValue, { expires: expirationInDays });
  } catch (error) {
    console.error(error);
  }
};

export const removeCookie = (cookieName: string) => Cookies.remove(cookieName);
