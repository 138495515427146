import { Fragment } from 'react';
import PodcastListingSingle from '@/components/blocks/PodcastListingBlock/PodcastListingSingle';
import Divider from '@/components/ui/Divider';
import { PodcastEpisode } from '@/@types/client-api';

interface PodcastListingContentProps {
  isLoading?: boolean;
  content?: PodcastEpisode[];
  shimerCount?: number;
  audioLabel?: string;
  videoLabel?: string;
  saveToMyLibraryLabel?: string;
  removeFromMyLibraryLabel?: string;
  listenOnLabel?: string;
  hideLabel?: string;
}

const PodcastListingContent = ({
  isLoading = false,
  content = [],
  shimerCount = 5,
  audioLabel,
  videoLabel,
  saveToMyLibraryLabel,
  removeFromMyLibraryLabel,
  listenOnLabel,
  hideLabel
}: PodcastListingContentProps): JSX.Element => {
  return (
    <Fragment>
      {content.map((item, index) => (
        <Fragment key={index}>
          <PodcastListingSingle
            isLoading={false}
            content={item}
            audioLabel={audioLabel}
            videoLabel={videoLabel}
            saveToMyLibraryLabel={saveToMyLibraryLabel}
            removeFromMyLibraryLabel={removeFromMyLibraryLabel}
            listenOnLabel={listenOnLabel}
            hideLabel={hideLabel}
          />
          {index < content.length - 1 && (
            <div className="container">
              <Divider color="light" />
            </div>
          )}
        </Fragment>
      ))}

      {isLoading &&
        Array.from({ length: shimerCount }).map((_, index) => (
          <Fragment key={index}>
            <PodcastListingSingle isLoading={true} />
            {index < shimerCount - 1 && (
              <div className="container">
                <Divider color="light" />
              </div>
            )}
          </Fragment>
        ))}
    </Fragment>
  );
};
export default PodcastListingContent;
