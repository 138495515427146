import SectionTitle from '@/components/ui/Titles/SectionTitle';
import PersonalInfoShimmer from '@/components/ui/ProfileShimmers/PersonalInfoShimmer';

import NudgeTooltip from './Tooltip/NudgeTooltip';
import {
  MissingBlockLevelNudge,
  MissingFieldLevelNudge,
  Nudge,
  StaleNudge
} from '@/hooks/shared/useNudge/types';
import {
  isMissingBlockLevelNudge,
  isMissingFieldLevelNudge,
  isStaleNudge
} from '@/hooks/shared/useNudge/utils';
import PersonalInfoSubSection, { SubSection } from './PersonalInfoSubSection';

type PersonalInfoProps = {
  button?: JSX.Element;
  sectionTitle: string;
  subSections: SubSection[];
  loading?: boolean;
  nudge?: Nudge;
  focusedAddressIndex?: number;
  setFocusedAddressIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const PersonalInfo: React.FC<PersonalInfoProps> = ({
  button,
  sectionTitle,
  subSections,
  loading = false,
  nudge,
  focusedAddressIndex,
  setFocusedAddressIndex
}) => {
  if (loading) return <PersonalInfoShimmer numItems={subSections.length} />;

  const blockLevelNudge = isMissingBlockLevelNudge(nudge)
    ? (nudge as MissingBlockLevelNudge)
    : undefined;

  const staleNudge = isStaleNudge(nudge) ? (nudge as StaleNudge) : undefined;

  const fieldNudge = isMissingFieldLevelNudge(nudge)
    ? (nudge as MissingFieldLevelNudge)
    : undefined;

  return (
    <section       data-component={"PersonalInfo"}
    className="container flex w-full flex-col items-start gap-6 py-10 lg:gap-10 lg:py-12">
      <div className="flex w-full flex-row items-center justify-between">
        <NudgeTooltip nudge={blockLevelNudge || staleNudge}>
          <SectionTitle elementType="h3" title={sectionTitle} />
        </NudgeTooltip>
        {focusedAddressIndex !== undefined && focusedAddressIndex >= 0 ? <></> : button}
      </div>
      <div className="grid w-full grid-cols-1 items-start gap-4 gap-y-11 align-top sm:grid-cols-2 lg:grid-cols-3 lg:gap-y-10">
        {subSections.map((subSection, idx) => (
          <PersonalInfoSubSection
            key={`${subSection.title}-${idx}`}
            fieldNudge={fieldNudge}
            subSection={subSection}
            focused={idx === focusedAddressIndex}
            setFocusedAddressIndex={setFocusedAddressIndex}
          />
        ))}
      </div>
    </section>
  );
};

export default PersonalInfo;
