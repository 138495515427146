import type { IndustryNode as IndustryNodeProps, SiteSettings } from '@/@types/content';
import ContentArea from '../cms/ContentArea';
import withNav from '@/components/hocs/withNav';
import { IContent } from '@/@types/cms';
import { Fragment, useCallback, useMemo } from 'react';
import { InvariantFilter } from '../ui/SearchResults/constants';
import { BreadcrumbProps } from '../ui/Breadcrumbs';

interface IndustryNodeComponentProps extends IndustryNodeProps, SiteSettings {
  basePageHeaderSettings: IContent[] | undefined;
  basePageTopSettings: IContent[] | undefined;
  basePageMiddleSettings: IContent[] | undefined;
  basePageBottomSettings: IContent[] | undefined;
  breadcrumbs?: BreadcrumbProps[];
}

interface BlockWithInvariantFilters extends IContent {
  invariantFilters: InvariantFilter[];
}

const IndustryNodeComponent: React.FC<IndustryNodeComponentProps> = ({
  overrideLayout,
  headerContentArea,
  topContentArea,
  middleContentArea,
  bottomContentArea,
  basePageHeaderSettings,
  basePageTopSettings,
  basePageMiddleSettings,
  basePageBottomSettings,
  overrideDescriptionText,
  overrideAditionalDescriptionText,
  category,
  overrideSearchResult,
  contentLink,
  name,
  hideInBreadcrumbs,
  breadcrumbs
}) => {
  const singleCategory = Array.isArray(category) ? category[0] : undefined;
  const singleCategoryId = overrideSearchResult || singleCategory?.name || undefined;
  const industryId = singleCategory?.id || overrideSearchResult;

  const headerComponents = overrideLayout
    ? headerContentArea || undefined
    : basePageHeaderSettings || undefined;
  const topComponents = overrideLayout
    ? topContentArea || undefined
    : basePageTopSettings || undefined;
  const middleComponents = overrideLayout
    ? middleContentArea || undefined
    : basePageMiddleSettings || undefined;
  const bottomComponents = overrideLayout
    ? bottomContentArea || undefined
    : basePageBottomSettings || undefined;

  const setInvariats = useCallback(
    (block: IContent) => {
      const hasInvariantFilters =
        Array.isArray((block as BlockWithInvariantFilters)?.invariantFilters) &&
        (block as BlockWithInvariantFilters).invariantFilters.length === 1;

      const newInvariantFilters: InvariantFilter[] = hasInvariantFilters
        ? [
            {
              ...((block as BlockWithInvariantFilters).invariantFilters as InvariantFilter[])[0],
              value: singleCategoryId
            }
          ]
        : [
            {
              name: '',
              facetGroupType: 'IndustryTaxonomy',
              value: singleCategoryId
            }
          ];

      return {
        ...block,
        invariantFilters: newInvariantFilters
      };
    },
    [singleCategoryId]
  );

  const memoizedMiddleComponents = useMemo(() => {
    return middleComponents?.map(setInvariats);
  }, [middleComponents, setInvariats]);

  const memoizedBottomComponents = useMemo(() => {
    return bottomComponents?.map(setInvariats);
  }, [bottomComponents, setInvariats]);

  const contentLinkId = contentLink?.id;

  return (
    <Fragment>
      {headerComponents && (
        <ContentArea
          propertyName={overrideLayout ? 'HeaderContentArea' : 'BasePageHeaderSettings'}
          components={headerComponents}
          componentsProps={{
            overrideCopy: overrideDescriptionText,
            overrideAditionalCopy: overrideAditionalDescriptionText,
            overridePageTitle: name,
            breadcrumbs: breadcrumbs,
            hideInBreadcrumbs: hideInBreadcrumbs,
            categoryId: industryId,
            isIndustryPage: true
          }}
        />
      )}

      {topComponents && (
        <ContentArea
          propertyName={overrideLayout ? 'TopContentArea' : 'BasePageTopSettings'}
          components={topComponents}
          componentsProps={{
            overrideCategory: singleCategoryId || undefined,
            pageContentLinkId: contentLinkId
          }}
        />
      )}

      {middleComponents && (
        <ContentArea
          propertyName={overrideLayout ? 'MiddleContentArea' : 'BasePageMiddleSettings'}
          components={memoizedMiddleComponents}
          componentsProps={{
            overrideCategory: singleCategoryId || undefined,
            overrideHeading: name
          }}
        />
      )}

      {bottomComponents && (
        <ContentArea
          propertyName={overrideLayout ? 'BottomContentArea' : 'BasePageBottomSettings'}
          components={memoizedBottomComponents}
        />
      )}
    </Fragment>
  );
};

const IndustryNode = withNav(IndustryNodeComponent);
export default IndustryNode;
