import Text from '@/components/cms/Text';
import ContentArea from '@/components/cms/ContentArea';
import Link from '@/components/ui/Link';
import Icon from '@/components/ui/Icon';
import classnames from 'classnames';
import {
  FacultyMember,
  FacultyRowContainerBlock as FacultyRowContainerBlockProps,
  FacultyMemberBlock
} from '@/@types/content';
import Block from '@/components/cms/Block';

const FacultyRowContainerBlock: React.FC<FacultyRowContainerBlockProps> = ({
  contentLink,
  title,
  facultyMembers,
  layout,
  link,
  format
}) => {
  facultyMembers = facultyMembers?.map(f => {
    if (f.contentType?.includes('FacultyMember')) {
      const fmb = {
        ...f,
        afilition: (f as FacultyMember).affiliation,
        bioFullName: (f as FacultyMember).displayName,
        pageUrl: (f as FacultyMember).url,
        bioImage: (f as FacultyMember).image,
        title: (f as FacultyMember).title,
        contentType: ['FacultyMemberBlock']
      } as FacultyMemberBlock;

      return fmb;
    }
    return f;
  });

  const headingClass = classnames({
    'heading-2': format === 'h2',
    'heading-3': format === 'h3',
    'heading-4': format === 'h4',
    'heading-6-medium': format === 'h6',
    'heading-5-medium': format === 'h5'
  });

  const facultyMembersClasses = classnames(
    'grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 lg:gap-x-6',
    {
      'gap-y-6 lg:grid-cols-3': layout !== '4-columns',
      'gap-y-12 lg:grid-cols-4': layout === '4-columns'
    }
  );

  return (
    <Block contentLinkID={contentLink?.id} aria-label={title} className={classnames("", {"container my-10 lg:my-16": format !== 'podcast'})}>
      <div className={classnames("mb-8 flex items-center justify-between", {'border-b border-b-gray-light pb-4': format !== 'podcast'})}>
        {format !== 'podcast' &&<Text element="h2" propertyName="Title" className={headingClass}>
          {title}
        </Text>}
        {link && (
          <div className="flex items-center gap-2">
            <Link {...link} className="text-2-bold no-underline" />
            <Icon name="chevron-right" size="small" />
          </div>
        )}
      </div>
      <ContentArea
        propertyName="FacultyMembers"
        components={facultyMembers}
        componentsProps={{ variant: layout }}
        className={facultyMembersClasses}
      />
    </Block>
  );
};

export default FacultyRowContainerBlock;
