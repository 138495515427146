import Divider from '@/components/ui/Divider';
import CreditCardHeader, { CreditCardHeaderProps } from './components/CreditCardHeader';
import CreditCardFooter, { CreditCardFooterProps } from './components/CreditCardFooter';

export type CreditCardProps = CreditCardHeaderProps & CreditCardFooterProps & { id: number };

const CreditCard = ({
  name,
  number,
  isExpired,
  expiration,
  brandName,
  brandLogo,
  onSetAsDefaultCardPress,
  onDeletePress,
  isDefaultCard,
  isDeleting
}: CreditCardProps) => {
  return (
    <div className="rounded bg-gray-lightest p-6 lg:px-8">
      <CreditCardHeader
        number={number}
        brandName={brandName}
        brandLogo={brandLogo}
        onSetAsDefaultCardPress={onSetAsDefaultCardPress}
        onDeletePress={onDeletePress}
        isDefaultCard={isDefaultCard}
        isDeleting={isDeleting}
      />
      <Divider color="silver" className="my-4 lg:my-6" />
      <CreditCardFooter name={name} expiration={expiration} isExpired={isExpired} />
    </div>
  );
};

export default CreditCard;
