import { useGetProductLinkAuthQuery } from '@/redux/api/client/product';
import ErrorPage from '@/routes/ErrorPage';
import PageLoading from '@/routes/PageLoading';
import { Navigate, useParams } from 'react-router-dom';

const ProductLinkAuthResolver = () => {
  const code = useParams()['code'];
  const { data: productLink, isLoading } = useGetProductLinkAuthQuery(code!);
  const url = productLink ? new URL(productLink) : undefined;
  if (isLoading) return <PageLoading />;
  if (url?.pathname) {
    const searchParams = window.location.search;
    return <Navigate to={`${url.pathname}${searchParams}`} replace />;
  }

  return <ErrorPage />;
};

export default ProductLinkAuthResolver;
