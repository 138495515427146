import { useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import {
  Certificate,
  CleRequirementKey,
  CreditType,
  ICertificateViewModel,
  ICLEPeriod,
  ICLEStateTransitionOption,
  ICreditClassData,
  ICreditRequirement,
  RequirementKeyType,
  UserTrackerData
} from '@/@types/client-api';
import { isRelativeUrl } from '@/utils/helpers';
import { CompliancePeriodValue } from '@credit-tracker/util/constants';
import { periodDateRangeIsInvalid } from '@credit-tracker/util/trackerDateHelpers';

export const SELECTED_REGION_COOKIE_KEY = 'selectedTrackerRegion';

export const translateToTailwinds = (str: string) => {
  str = str.replace('font-weight-bold', 'font-bold');
  return str;
};

export const filterHiddenRequirements = (reqs?: ICreditRequirement[]) => {
  return reqs?.filter(
    a =>
      !a.isHidden &&
      (!!a.required ||
        (!a.required && a.earned && a.earned > 0) ||
        (!!a.subRequirements && a.subRequirements?.length > 0))
  );
};

export const setTrackerCookieValue = <T>(key: string, value: T, expires: number = 365) => {
  const val = typeof value === 'string' ? value : JSON.stringify(value);
  Cookies.set(key, val, { expires });
};

export const getTrackerCookieValue = (key: string): string | undefined => {
  const val = Cookies.get(key);
  return val;
};

export const mapCreditTypeDescriptions = (
  types: CreditType[],
  typersFromRules: ICreditClassData[]
): CreditType[] => {
  if (!types || types.length == 0) return types;

  return types.map(type => {
    let _type = { ...type };
    if (!type.creditTypeDescription) {
      const desc =
        typersFromRules?.find(a => a.wmsCreditTypePK == type.creditType_PK)?.longDescription ??
        type.creditType_PK;
      _type = { ..._type, creditTypeDescription: desc };
    }
    return _type;
  });
};

export const getCreditsCounted = (requirement: ICreditRequirement) => {
  const counted = requirement.counted ?? 0;
  const earned = requirement.earned ?? 0;
  const required = requirement.required ?? 0;
  return required > 0 && earned > counted && counted < required ? counted : earned;
};

export const usePrevious = <T extends Certificate>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const getNewTagText = (expireDate?: string | null) => {
  const now = new Date();
  if (expireDate) {
    const parsedDate = new Date(expireDate);
    if (parsedDate instanceof Date && !isNaN(parsedDate.valueOf()))
      return parsedDate > now ? 'New' : null;
  }
};

const isObject = (obj: Certificate | CreditType) => obj && typeof obj === 'object';

export const deepCheckCertificateEquality = (
  original: Certificate | CreditType | ICertificateViewModel,
  updated: Certificate | CreditType | ICertificateViewModel
) => {
  if (original === updated) return true;

  if (!isObject(original) || !isObject(updated)) return false;

  const keys1 = Object.keys(original);
  const keys2 = Object.keys(updated);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key)) return false;
    if (
      !deepCheckCertificateEquality(
        (original as { [key: string]: Certificate | CreditType })[key],
        (updated as { [key: string]: Certificate | CreditType })[key]
      )
    )
      return false;
  }
  return true;
};

export const roundCredits = (value: number | string) => Math.round(Number(value) * 100) / 100;

export const formatOptions = (list: number[] | string[]) => {
  return list.map(item => ({
    label: item,
    value: item
  }));
};

export const getEmptyCertificate = (carryOver: boolean): Certificate => {
  return {
    carryOver,
    certificateSource: '',
    dateCompleted: '',
    creditTypes: [
      {
        creditTypeDescription: '',
        creditType_PK: '',
        creditType_SK: 0,
        creditIssued: undefined
      }
    ],
    isExternal: true,
    transitionLevel: 0
  };
};

export const mapProgramFormatToSearchFormat = (format: string) => {
  const _format = format.toLowerCase();
  switch (_format) {
    case 'live-groupcast_live':
      return 'Live Groupcast';
    case 'live-webcast':
      return 'Live Webcast';
    case 'live':
      return 'Live In-Person';
    case 'on-demand':
      return 'On-Demand';
  }
};

export const getActiveCompliancePeriodValue = (
  trackerData?: UserTrackerData | null
): 'current' | 'next' | 'previous' | null => {
  if (!trackerData) {
    return null;
  }

  const clePeriod = trackerData.jurisdiction?.clePeriod;
  if (!clePeriod) {
    return null;
  }
  if (clePeriod.isActivelySelected) {
    return 'current';
  }
  if (clePeriod.nextPeriod?.isActivelySelected) {
    return 'next';
  }
  if (clePeriod.previousPeriod?.isActivelySelected) {
    return 'previous';
  }

  return null;
};

export const nthNumber = (number: number) => {
  if (number > 3 && number < 21) return 'th';
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const convertToBoolean = (value: string | number) => {
  if (typeof value === 'number') return !(value as number);
  return value.toString().toLowerCase() == 'true';
};

export const formatTemporalHeadingText = (heading?: string | null) => heading?.replace(/:/g, '');

export const formatCreditTypeId = (heading: string) =>
  heading
    .toLowerCase()
    .replace(/[^A-Za-z0-9\s]/g, '')
    .replace(/\s+/g, '-');

export const getTransitionOptions = (data: UserTrackerData) => {
  return (
    data?.jurisdiction?.cleStateTransitionOptions?.filter(a => {
      return [...(a.activatesFlags ?? []), ...(a.removesFlags ?? [])]?.some(
        a => a.toLowerCase()?.indexOf('period') < 0
      );
    }) ?? []
  );
};

export const getActiveSpecialStatus = (data: UserTrackerData) =>
  getTransitionOptions(data)?.find(a => {
    return (
      a.regionSpecialStatusNames &&
      !!Object.keys(a.regionSpecialStatusNames as unknown as string[])?.length
    );
  })?.regionSpecialStatusNames?.['True'];

/**
 * Calculates the number of days between today and the passed in date.
 * @param date The date to subtract from today's date
 * @returns The difference in days between the given date and the current date. Rounds up to nearest day
 */
export const differenceInDays = (date: Date) => {
  const differenceInMilliseconds = date.getTime() - new Date().getTime();
  const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));
  return differenceInDays;
};

export const checkDeadlineApproaching = (date: Date | string) =>
  differenceInDays(new Date(date)) <= 30;

export const getTemporalDates = (sideNote?: string | null): string[] | null | undefined => {
  if (!sideNote) return null;

  return sideNote.split('-');
};

export const getTotalCreditsNeeded = (req: ICreditRequirement) => {
  return (req.required ?? 0) - (getCreditsCounted(req) ?? 0);
};

export const sortTrackerMiniBlocks = (selectedUserRegions: UserTrackerData[]) => {
  const allFullfilled = selectedUserRegions
    ?.filter(
      a =>
        !!a?.jurisdiction?.totalRequirement?.isFulfilled &&
        !a?.jurisdiction?.clePeriod?.isUserInfoIncomplete
    )
    ?.sort((a, b) => (a.baseSortOrder ?? 0) - (b.baseSortOrder ?? 0));

  const unfulfilledJurisdictions = selectedUserRegions
    ?.filter(
      x =>
        !x?.jurisdiction?.totalRequirement?.isFulfilled &&
        !x.jurisdiction?.clePeriod?.isUserInfoIncomplete
    )
    ?.sort((a, b) => {
      const dateA = new Date(a.jurisdiction?.clePeriod?.endDate ?? 0).getTime();
      const dateB = new Date(b.jurisdiction?.clePeriod?.endDate ?? 0).getTime();
      const aCreditsNeeded = getTotalCreditsNeeded(a.jurisdiction?.totalRequirement ?? {});
      const bCreditsNeeded = getTotalCreditsNeeded(b.jurisdiction?.totalRequirement ?? {});

      // sort by nearest end date ascending then by credits needed descending.
      return dateA - dateB || (aCreditsNeeded > bCreditsNeeded ? -1 : 1);
    });

  return [...unfulfilledJurisdictions, ...allFullfilled];
};

export const getRegionShortName = (
  regionShortName?: string | null,
  isTransitionalOrNewlyAdmitted?: boolean
) => (isTransitionalOrNewlyAdmitted ? `${regionShortName}-TR` : regionShortName);

export const linkToRegion = (pathname: string, region: string) => {
  const newUrl = new URL(pathname, window.location.origin);
  newUrl.searchParams.set('region', region ?? '');
  return newUrl.toString();
};

export const getScrollableParent = (element: HTMLElement | null): HTMLElement | null => {
  if (!element) {
    return null;
  }

  let parent = element?.parentNode as HTMLElement | null;
  while (parent && parent.nodeType === Node.ELEMENT_NODE) {
    const overflowY = window.getComputedStyle(parent).overflowY;
    const isScrollable = overflowY === 'auto' || overflowY === 'scroll';

    if (isScrollable && parent.scrollHeight > parent.clientHeight) {
      return parent;
    }

    parent = parent.parentNode as HTMLElement | null;
  }

  return null; // No scrollable parent found
};

export const formatUrl = (url?: string) => {
  const u = url ?? '/';
  return isRelativeUrl(u)
    ? new URL(
        `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${u}`
      )
    : new URL(u);
};

export const getPeriodFlags = (
  period?: CompliancePeriodValue,
  cleStateTransitionOptions?: Array<ICLEStateTransitionOption> | null
) => {
  if (!cleStateTransitionOptions) return;

  if (period?.toLowerCase() == 'previous') {
    return (
      cleStateTransitionOptions?.find(
        x => x.heading?.toLocaleLowerCase()?.indexOf('previous') === 0
      )?.activatesFlags ?? []
    );
  } else if (period?.toLowerCase() == 'next') {
    return (
      cleStateTransitionOptions?.find(x => x.heading?.toLocaleLowerCase()?.indexOf('next') === 0)
        ?.activatesFlags ?? []
    );
  }
};

export const findSelectedClePeriod = (data?: ICLEPeriod | null) => {
  if (!data) return;

  return [data, data?.previousPeriod, data?.nextPeriod]?.find(a => !!a?.isActivelySelected);
};

export const periodIsMissingComplianceInformation = (
  period?: ICLEPeriod | null,
  requiresSetup?: boolean
) => {
  return (
    !!period?.isUserInfoIncomplete ||
    !!requiresSetup ||
    periodDateRangeIsInvalid(period?.startDate ?? '', period?.endDate ?? '')
  );
};

/**
 * Recursively get credit type keys sorted by number of credits needed (asc),
 *  then by credit type priority score(asc: 0(highest) - n(lowest))
 * @param credits the requirement credits
 * @returns
 */
export const getCreditTypeKeys = (
  credits: ICreditRequirement[],
  priorityTypesConfig?: string[]
) => {
  const map: {
    [key: string]: {
      requirementKey: CleRequirementKey;
      requirement: ICreditRequirement;
      priorityIndex: number;
    };
  } = {};

  // Populate the map based on the credit type priority list.
  const populateMap = (requirementKeys: CleRequirementKey[], requirement: ICreditRequirement) => {
    requirementKeys
      ?.filter(
        a => a.requirementKeyType == RequirementKeyType._0 && (a.key?.indexOf(':') ?? -1) < 0
      )
      ?.map(a => {
        const key = a?.key?.toLowerCase();
        if (key) {
          const priorityIndex = priorityTypesConfig?.indexOf(a.key!.trim()) ?? -1;
          const currentRequirement = map[a.key!]?.requirement;

          if (currentRequirement) {
            if (getTotalCreditsNeeded(requirement) > getTotalCreditsNeeded(currentRequirement)) {
              map[a.key!] = {
                ...map[a.key!],
                requirement: requirement
              };
            }
          } else {
            map[a.key!] = {
              requirementKey: a,
              requirement: requirement,
              priorityIndex: priorityIndex >= 0 ? priorityIndex : (priorityTypesConfig?.length ?? 0)
            };
          }
        }
      });
  };

  // Iterate the requirements to get the credit type keys.
  for (const c of credits) {
    const subReqs = c.subRequirements?.filter(a =>
      a.requirementKeys?.some(b => b.requirementKeyType == RequirementKeyType._0)
    );
    if (subReqs?.length) {
      const required = c.subRequirements?.filter(a => getTotalCreditsNeeded(a) > 0);
      const subReqTypes = getCreditTypeKeys(required?.length ? required : subReqs);
      populateMap(
        Object.values(subReqTypes)?.map(a => a.requirementKey),
        c
      );
    } else {
      populateMap(c.requirementKeys ?? [], c);
    }
  }

  return map;
};

export const getFormatsForRequirement = (type: ICreditRequirement) => {
  return [
    ...new Set(
      [
        ...(type.requirementKeys?.filter(a => a.requirementKeyType == RequirementKeyType._2) ?? []),
        ...(type.eligibleFormatKeys ?? [])
      ]
        ?.filter(c => !!c.key)
        ?.map(c => c.key)
    )
  ];
};
