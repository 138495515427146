import * as ToastPrimitive from '@radix-ui/react-toast';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { removeToast } from '@/redux/slices/pageSlice';
import Toast from './Toast';
import { getToastElement } from '@/hooks/toastStorage';

const ToastContainer: React.FC = () => {
  const toasts = useSelector((state: RootState) => state.page.toasts);
  const dispatch = useDispatch();

  const handleClose = (id: string) => {
    dispatch(removeToast(id));
  };

  return (
    <ToastPrimitive.Provider swipeDirection="right">
      <ToastPrimitive.Viewport className="fixed inset-x-0 top-2 z-toast mx-auto flex max-w-[800px] flex-col gap-2 px-4">
        {toasts.map(toast => {
          return (
            <Toast
              key={toast.id}
              type={toast.type}
              icon={!!toast.icon}
              iconName={toast.icon}
              open={toast.isOpen}
              onOpenChange={() => handleClose(toast.id)}
            >
              {getToastElement(toast.toastId!)}
            </Toast>
          );
        })}
      </ToastPrimitive.Viewport>
    </ToastPrimitive.Provider>
  );
};

export default ToastContainer;
