import RichText from '@/components/cms/RichText';
import classnames from 'classnames';
import Breadcrumbs, { BreadcrumbsProps } from '@/components/ui/Breadcrumbs';
import {
  StandardGrayHeaderBlock as StandardGrayHeaderBlockProps,
  RequestAQuoteModalBlock as RequestAQuoteModalBlockType,
  ProductContent
} from '@/@types/content';
import ShareButton from '@/components/ui/Buttons/ShareButton';
import Block from '@/components/cms/Block';
import ResponsiveImage from '@/components/ui/ResponsiveImage';
import { Theme } from '@/styles/darkMode';
import { useDispatch } from 'react-redux';
import { setHeaderTheme } from '@/redux/slices/pageSlice';
import { useEffect, useMemo } from 'react';
import Button from '@/components/ui/Buttons/Button';
import { useAddToCart } from '@/hooks/shared/useAddToCart';
import RequestAQuoteModalBlock from '@/components/blocks/RequestAQuoteModalBlock';
import useToast from '@/hooks/useToast';
import { delayedActionMessageBuilder } from '@/redux/helpers/delayedActionsMapper';
import { useSearchParamsStable } from '../../../hooks/useSearchParams';
import { getPriceFromContent } from '@/components/ui/PDP/helpers';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import { isOfContentType } from '@/lib/helpers/contentType';

enum LinkStyle {
  Primary = 'primary',
  Secondary = 'secondary'
}

type StandarGrayHeaderBlockPropsExt = StandardGrayHeaderBlockProps &
  BreadcrumbsProps & {
    productToAddToCart?: ProductContent[];
    pageName?: string;
  };

const StandarGrayHeaderBlock: React.FC<StandarGrayHeaderBlockPropsExt> = ({
  contentLink,
  image,
  imageForm,
  contentLineOne,
  gradientStartLineOne = 'black',
  gradientEndLineOne = 'black',
  contentLineTwo,
  gradientStartLineTwo = 'black',
  gradientEndLineTwo = 'black',
  contentLineThree,
  gradientStartLineThree = 'black',
  gradientEndLineThree = 'black',
  breadcrumbs,
  addToCartCTA,
  cartLinkStyle = LinkStyle.Primary,
  productToAddToCart = [],
  requestMoreInfoBlock,
  requestInfoLinkStyle = LinkStyle.Primary,
  pageName,
  disableShareLink,
  ...props
}) => {
  let hideBreadcrumbs: boolean = false;

  const requestAQuoteModalBlockProps = requestMoreInfoBlock?.[0] as RequestAQuoteModalBlockType;

  const dispatch = useDispatch();
  const { addToCart } = useAddToCart();
  const { otherLabels, isLoading: isLoadingLabels } = useCatalogLabels();

  useEffect(() => {
    dispatch(setHeaderTheme(2));

    return () => {
      dispatch(setHeaderTheme(undefined));
    };
  }, [dispatch]);

  if ('hideInBreadcrumbs' in props) {
    hideBreadcrumbs = props.hideInBreadcrumbs as boolean;
  }

  const getLineTheme = (start?: string, end?: string) => {
    const gradientStartClasses = classnames({
      'from-black': start === 'black',
      'from-red': start === 'red',
      'from-gold': start === 'gold',
      'from-green-100': start === 'green-100',
      'from-green-200': start === 'green-200',
      'from-green-300': start === 'green-300',
      'from-teal-100': start === 'teal-100',
      'from-teal-200': start === 'teal-200',
      'from-teal-300': start === 'teal-300',
      'from-blue-100': start === 'blue-100',
      'from-blue-200': start === 'blue-200',
      'from-blue-300': start === 'blue-300',
      'from-indigo-100': start === 'indigo-100',
      'from-indigo-200': start === 'indigo-200',
      'from-indigo-300': start === 'indigo-300',
      'from-purple-100': start === 'purple-100',
      'from-purple-200': start === 'purple-200',
      'from-purple-300': start === 'purple-300',
      'from-magenta-100': start === 'magenta-100',
      'from-magenta-200': start === 'magenta-200',
      'from-magenta-300': start === 'magenta-300'
    });

    const gradientEndClasses = classnames({
      'to-black': end === 'black',
      'to-red': end === 'red',
      'to-gold': start === 'gold',
      'to-green-100': end === 'green-100',
      'to-green-200': end === 'green-200',
      'to-green-300': end === 'green-300',
      'to-green-400': end === 'green-400',
      'to-teal-100': end === 'teal-100',
      'to-teal-200': end === 'teal-200',
      'to-teal-300': end === 'teal-300',
      'to-teal-400': end === 'teal-400',
      'to-blue-100': end === 'blue-100',
      'to-blue-200': end === 'blue-200',
      'to-blue-300': end === 'blue-300',
      'to-blue-400': end === 'blue-400',
      'to-indigo-100': end === 'indigo-100',
      'to-indigo-200': end === 'indigo-200',
      'to-indigo-300': end === 'indigo-300',
      'to-indigo-400': end === 'indigo-400',
      'to-purple-100': end === 'purple-100',
      'to-purple-200': end === 'purple-200',
      'to-purple-300': end === 'purple-300',
      'to-purple-400': end === 'purple-400',
      'to-magenta-100': end === 'magenta-100',
      'to-magenta-200': end === 'magenta-200',
      'to-magenta-300': end === 'magenta-300',
      'to-magenta-400': end === 'magenta-400'
    });

    return classnames(
      'heading-1 w-fit bg-gradient-to-r bg-clip-text pb-[0.1rem] text-transparent',
      gradientStartClasses,
      gradientEndClasses
    );
  };

  const showCtaArea = addToCartCTA || requestAQuoteModalBlockProps;

  const standarGrayHeaderClasses = classnames(
    'container mt-[-160px] pb-6 pt-[192px] lg:mt-[-96px] lg:pt-[128px]'
  );

  const wrapperClasses = classnames('flex flex-col justify-between gap-8', 'lg:pb-10', {
    'lg:flex-row': !showCtaArea,
    'lg:flex-col': showCtaArea
  });

  const contentClasses = classnames('mt-12 sm:flex sm:items-center sm:gap-10 lg:gap-[60px]');

  const imageFormClasses = classnames(
    'aspect-square size-20 min-w-20 overflow-hidden object-cover sm:size-[150px] sm:min-w-[150px] lg:size-[200px] lg:min-w-[200px]',
    {
      'rounded-full ': imageForm === 'circle',
      'rounded-2xl': imageForm === 'squircle'
    }
  );

  const ctaAreaClasses = classnames({
    'flex flex-row flex-wrap items-center justify-between gap-8': showCtaArea,
    'lg:self-end': !showCtaArea
  });

  const lineOneTheme = getLineTheme(gradientStartLineOne, gradientEndLineOne);
  const lineTwoTheme = getLineTheme(gradientStartLineTwo, gradientEndLineTwo);
  const lineThreeTheme = getLineTheme(gradientStartLineThree, gradientEndLineThree);

  const handleAddToCard = () => {
    if (productToAddToCart?.length) {
      const { code, displayName } = productToAddToCart[0];
      addToCart(code, displayName);
    }
  };

  const getAddToCartCTALabel = useMemo(() => {
    if (productToAddToCart?.length) {
      const price = getPriceFromContent(productToAddToCart[0]);
      if (
        isOfContentType(productToAddToCart[0], [
          'IndividualMembershipVariation',
          'IndividualPlusSubscriptionVariation'
        ])
      ) {
        return otherLabels.membershipCartText.replace('{PRICE}', price.price);
      }

      return `${addToCartCTA?.text} • ${price.price}`;
    }

    return addToCartCTA?.text;
  }, [addToCartCTA?.text, otherLabels.membershipCartText, productToAddToCart]);

  const { showSuccessMessage } = useToast();
  const { searchParams, setSearchParams } = useSearchParamsStable();

  useEffect(() => {
    if (searchParams.has('successAction')) {
      const delayedAction = searchParams.get('successAction');
      const response = JSON.parse(searchParams.get('response') ?? '{}');
      const { cartUrl } = response;
      if (delayedAction === 'postCartItem' && cartUrl) {
        const successMessage = delayedActionMessageBuilder[delayedAction]?.({
          title: 'Individual Membership',
          url: cartUrl
        });
        showSuccessMessage(successMessage);
      }

      setSearchParams(
        existing => {
          existing.delete('successAction');
          existing.delete('program');
          existing.delete('response');
          return existing;
        },
        { replace: true, preventScrollReset: true }
      );
    }
  }, [searchParams, showSuccessMessage, setSearchParams]);

  return (
    <Block contentLinkID={contentLink?.id} className="bg-gray-lightest">
      <div className={standarGrayHeaderClasses} data-component={'StandarGrayHeaderBlock'}>
        {breadcrumbs && !hideBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        <div className={wrapperClasses}>
          <div className={contentClasses}>
            {image && (
              <div className={imageFormClasses}>
                <ResponsiveImage
                  propertyName="image"
                  src={image?.url}
                  alt={image?.alt as string}
                  imageSizes={{
                    xs: '80px',
                    sm: '150px',
                    lg: '200px'
                  }}
                />
              </div>
            )}
            <article className="mt-6 sm:mt-0 ">
              {contentLineOne && (
                <RichText
                  propertyName="ContentlineOne"
                  content={contentLineOne || ''}
                  className={lineOneTheme}
                />
              )}
              {contentLineTwo && (
                <RichText
                  propertyName="ContentLineTwo"
                  content={contentLineTwo || ''}
                  className={lineTwoTheme}
                />
              )}
              {contentLineThree && (
                <RichText
                  propertyName="ContentLineThree"
                  content={contentLineThree || ''}
                  className={lineThreeTheme}
                />
              )}
            </article>
          </div>

          <div className={ctaAreaClasses}>
            {showCtaArea && (
              <div className="flex flex-row flex-wrap gap-4">
                {addToCartCTA && (
                  <Button
                    size="large"
                    color={cartLinkStyle === LinkStyle.Primary ? 'black' : 'outline-black'}
                    label={getAddToCartCTALabel}
                    onClick={handleAddToCard}
                    loading={isLoadingLabels}
                  />
                )}
                {requestAQuoteModalBlockProps && (
                  <RequestAQuoteModalBlock
                    {...requestAQuoteModalBlockProps}
                    triggerButton={
                      <Button
                        size="large"
                        color={
                          requestInfoLinkStyle === LinkStyle.Primary ? 'black' : 'outline-black'
                        }
                        label={requestAQuoteModalBlockProps.heading}
                      />
                    }
                  />
                )}
              </div>
            )}
            {!disableShareLink && (
              <ShareButton
                shareUrl={window.location.href}
                theme={Theme.Light}
                title={pageName}
                buttonClassname={showCtaArea ? '!py-0' : '!py-[0.1rem]'}
              />
            )}
          </div>
        </div>
      </div>
    </Block>
  );
};

export default StandarGrayHeaderBlock;
