import { IFacetResultItem } from '@/@types/client-api';
import { AdHocFilter, FILTERS } from '../SearchResults/constants';

export const SECTIONS = [
  {
    id: FILTERS.JURISDICTIONS,
    title: 'Jurisdictions',
    addButtonLabel: 'Add a license',
    hasEmptyState: true,
    hasDisabledFilters: true,
    selectDefaultValues: true
  },
  { id: FILTERS.CREDIT_TYPE, title: 'Credit types', maxCount: 10 },
  {
    id: FILTERS.PRACTICE_AREA,
    title: 'Practice areas',
    addButtonLabel: 'Add a practice area',
    hasEmptyState: true,
    hasDisabledFilters: true,
    // Only display PAs that are followed by the user or are in the ad hoc filters
    filter: (facet: IFacetResultItem, adHocFilters: AdHocFilter[]) => {
      if (
        adHocFilters.some(
          filter => filter.groupTypeId === FILTERS.PRACTICE_AREA && filter.value === facet.value
        )
      ) {
        return true;
      }

      return facet.isFollowedByUser;
    }
  },
  {
    id: FILTERS.FORMAT,
    title: 'Program formats',
    // Filter out format filters not related to programs, like podcasts
    validFilters: ['LiveInPerson', 'LiveWebcast', 'OnDemand'],
    dependsOn: [FILTERS.JURISDICTIONS],
    hasDisabledFilters: true
  }
];

export const SECTION_IDS = SECTIONS.map(({ id }) => id);
