import { ClientAPI } from '@/redux/api';
import { AnnouncementMessage, CreditInfoPageExtendedContent } from '@/@types/client-api';

const userCreditInfoEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getCreditInfoExtended: builder.query<Record<string, CreditInfoPageExtendedContent>, string[]>({
      query: regions => ({
        url: '/credit-info/extended',
        method: 'GET',
        params: {
          ['regions']: regions
        }
      })
    }),
    getRuleChanges: builder.query<Record<string, AnnouncementMessage[]>, number>({
      query: ruleChangeType => ({
        url: '/credit-info/rule-changes',
        method: 'GET',
        params: {
          ['ruleChangeType']: ruleChangeType
        }
      })
    })
  })
});

export const {
  useGetCreditInfoExtendedQuery,
  useLazyGetCreditInfoExtendedQuery,
  useGetRuleChangesQuery,
  useLazyGetRuleChangesQuery
} = userCreditInfoEndpoints;

export default userCreditInfoEndpoints;
