import {
  LiveVariationFormat,
  OnDemandProgramVariation,
  WebSegmentVariation
} from '@/hooks/PDP/useProgramCatalogRelations';
import {
  Variant,
  setCatalogRelations,
  setHardSelectedVariant,
  setSoftSelectedVariant
} from '@/redux/slices/pdpSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import {
  determineLiveProgramStatus,
  getExpiredStatus,
  getOnDemandSegmentRuntime,
  getPriceFromContent,
  getAddressFromVenue,
  isWaitlist
} from '@/components/ui/PDP/helpers';
import { useLibraryItems } from './useLibraryItems';
import { LibraryItem } from '@/@types/client-api';

export const getLiveType = (variant: LiveVariationFormat): 'online' | 'groupcast' | 'in-person' => {
  switch (variant.contentType) {
    case 'LiveProgramPBRWebcastVariation':
    case 'LiveProgramOhbVariation':
    case 'LiveProgramWebcastVariation':
      return 'online';
    case 'LiveProgramPbrSeminarVariation':
    case 'LiveProgramSeminarVariation':
      return 'in-person';
    case 'LiveProgramGroupcastVariation':
      return 'groupcast';
  }
};

export const isLive = (variant: LiveVariationFormat): boolean => {
  return (
    determineLiveProgramStatus(
      variant.content.eventStartDate,
      variant.content.eventEndDate,
      variant.content.segments
    ) == 'Live'
  );
};

type OnDemandProgramVariantSelection = {
  variant: OnDemandProgramVariation;
  isHardSelected: boolean;
};
export const useOnDemandProgramVariantSelection = (
  variant: OnDemandProgramVariation,
  variants: OnDemandProgramVariation[]
) => {
  const dispatch = useDispatch();
  const { selectLibraryItem, selectItemMaterials } = useLibraryItems();

  const selectedVariant = useSelector((state: RootState) => state.pdp.selectedVariant);
  const hardSelected = useSelector((state: RootState) => state.pdp.hardSelected);

  const [currentVariant, setCurrentVariant] = useState<OnDemandProgramVariantSelection>({
    variant,
    isHardSelected: false
  });

  useEffect(() => {
    if (selectedVariant) {
      const variant = variants.find(v => v.code === selectedVariant.code);
      if (!variant) return;
      if (variant === currentVariant.variant) return;
      setCurrentVariant({ variant, isHardSelected: hardSelected });
    }
  }, [currentVariant.variant, hardSelected, selectedVariant, variants]);

  useEffect(() => {
    if (!currentVariant?.variant.contentLink?.url) return;
    const path = new URL(currentVariant?.variant.contentLink.url).pathname;
    const { hash } = window.location;
    window.history.replaceState({}, '', `${path}${hash}`);
  }, [currentVariant?.variant?.contentLink?.url]);

  useEffect(() => {
    const mapToEcomVariant = (variant: OnDemandProgramVariation): Variant => {
      const priceInfo = getPriceFromContent(variant);
      const libraryItem = selectLibraryItem(variant.code);

      const materialsForItem = selectItemMaterials(variant.code);
      const currentSegmentSk = libraryItem?.nextIncompletePlaybackProgressItemSk;
      const currentSegmentVariant = variant?.segments?.find(
        v => v.externalSystemKey === currentSegmentSk
      );
      const currentSegmentLibraryItem = selectLibraryItem(currentSegmentVariant?.code);

      const currentSegmentDuration = currentSegmentVariant
        ? getOnDemandSegmentRuntime(
            currentSegmentVariant?.runTimeSeconds,
            currentSegmentLibraryItem?.playbackProgressPct
          ).thumbnail
        : undefined;

      const ecomVariant: Variant = {
        code: variant.code!,
        location: variant.location ?? '',
        publicationDate: variant.publicationDate,
        displayName: variant.displayName ?? '',
        itemClass: variant.itemClassId,
        retailPrice: priceInfo.rawRetailPrice,
        discountPrice: priceInfo.rawDiscountPrice,
        price: priceInfo.price,
        originalPrice: priceInfo.strikePrice,
        retail: priceInfo.retail,
        isExpired: getExpiredStatus(variant),
        purchased: libraryItem?.isPurchased,
        hasMaterials: libraryItem?.isPurchased ? !!materialsForItem?.length : false,
        materials: libraryItem?.isPurchased ? materialsForItem : undefined,
        transcriptUrl: libraryItem?.isPurchased ? variant.transcriptUrl : undefined,
        thumbnail: currentSegmentVariant?.imageUrl ?? variant.imageUrl,
        duration: currentSegmentDuration ?? variant.runtime,
        progress: currentSegmentLibraryItem?.playbackProgressPct,
        launchUrl: libraryItem?.isPurchased ? variant.launchUrl : undefined
      };
      return ecomVariant;
    };

    dispatch(setCatalogRelations({ variants: variants.map(value => mapToEcomVariant(value)) }));

    const isCurrentVariantPurchased =
      selectLibraryItem(currentVariant.variant.code)?.isPurchased &&
      !getExpiredStatus(currentVariant.variant);
    const isCurrentVariantExpired = getExpiredStatus(currentVariant.variant);
    if (!isCurrentVariantExpired && (currentVariant.isHardSelected || isCurrentVariantPurchased)) {
      dispatch(setHardSelectedVariant(mapToEcomVariant(currentVariant.variant)));
    } else {
      dispatch(setSoftSelectedVariant(mapToEcomVariant(currentVariant.variant)));
    }
  }, [currentVariant, dispatch, selectItemMaterials, selectLibraryItem, variants]);

  return {
    selectedVariant: currentVariant.variant
  };
};

export const useOnDemandSegmentSelection = (variant: WebSegmentVariation) => {
  const dispatch = useDispatch();

  const { selectLibraryItem, selectItemMaterials } = useLibraryItems();

  const mapToEcomVariant = (variant: WebSegmentVariation): Variant => {
    const libraryItem = selectLibraryItem(variant.code);
    const materialsForItem = selectItemMaterials(variant.code);
    const priceInfo = getPriceFromContent(variant);
    const hasAccess = !!(libraryItem?.isPurchased || libraryItem?.isParentProgramPurchased);
    const ecomVariant: Variant = {
      code: variant.code!,
      location: variant.location ?? '',
      publicationDate: variant.publicationDate,
      displayName: variant.displayName ?? '',
      itemClass: variant.itemClassId,
      retailPrice: priceInfo.rawRetailPrice,
      discountPrice: priceInfo.rawDiscountPrice,
      price: priceInfo.price,
      originalPrice: priceInfo.strikePrice,
      retail: priceInfo.retail,
      isExpired: getExpiredStatus(variant),
      purchased: hasAccess,
      hasMaterials: hasAccess ? !!materialsForItem?.length : false,
      materials: hasAccess ? materialsForItem : undefined,
      transcriptUrl: hasAccess ? variant.transcriptUrl : undefined,
      thumbnail: variant.imageUrl,
      duration: libraryItem
        ? getOnDemandSegmentRuntime(variant.runTimeSeconds, libraryItem.playbackProgressPct)
            .thumbnail
        : variant.runtime,
      progress: libraryItem?.playbackProgressPct,
      launchUrl: hasAccess ? variant.launchUrl : undefined
    };
    return ecomVariant;
  };

  dispatch(setCatalogRelations({ variants: [mapToEcomVariant(variant)] }));
  dispatch(setHardSelectedVariant(mapToEcomVariant(variant)));
};

type LiveVariantSelection = {
  variant: LiveVariationFormat;
  isHardSelected: boolean;
};
export const useLiveProgramVariantSelection = (
  variant: LiveVariationFormat,
  variants: LiveVariationFormat[],
  registrations?: LibraryItem[]
) => {
  const dispatch = useDispatch();
  const { selectItemMaterials } = useLibraryItems();
  const selectedVariant = useSelector((state: RootState) => state.pdp.selectedVariant);
  const hardSelected = useSelector((state: RootState) => state.pdp.hardSelected);

  const [currentVariant, setCurrentVariant] = useState<LiveVariantSelection>({
    variant,
    isHardSelected: false
  });

  useEffect(() => {
    if (selectedVariant) {
      const variant = variants.find(v => v.content.code === selectedVariant.code);
      if (!variant) return;
      setCurrentVariant({ variant, isHardSelected: hardSelected });
    }
  }, [selectedVariant, variants, hardSelected]);

  useEffect(() => {
    if (!currentVariant?.variant?.content.contentLink?.url) return;
    const path = new URL(currentVariant?.variant?.content.contentLink.url).pathname;
    // Get hashes from the URL and append them to the new URL
    const { hash } = window.location;
    window.history.replaceState({}, '', `${path}${hash}`);
  }, [currentVariant?.variant?.content?.contentLink?.url]);

  useEffect(() => {
    const mapToEcomVariant = (variant: LiveVariationFormat): Variant => {
      const registration = registrations?.find(r => r.pk === variant.content.code);
      const materialsForItem = selectItemMaterials(variant.content.code);
      const priceInfo = getPriceFromContent(variant.content);
      const ecomVariant: Variant = {
        code: variant.content.code!,
        location: variant.content.location ?? '',
        eventStartDate: variant.content.eventStartDate ?? '',
        eventEndDate: variant.content.eventEndDate ?? '',
        timeZoneIdentifier: variant.content.timeZoneIdentifier ?? '',
        type: getLiveType(variant),
        displayName: variant.content.displayName ?? '',
        itemClass: variant.content.itemClassId,
        retailPrice: priceInfo.rawRetailPrice,
        discountPrice: priceInfo.rawDiscountPrice,
        price: priceInfo.price,
        originalPrice: priceInfo.strikePrice,
        retail: priceInfo.retail,
        isExpired: getExpiredStatus(variant.content),
        sk: variant.content.externalSystemKey,
        registered: !!registration,
        hasMaterials: registration ? !!materialsForItem?.length : false,
        materials: registration ? materialsForItem : undefined,
        isLive: isLive(variant),
        waitlist: isWaitlist(variant.content),
        ...getAddressFromVenue(variant.content.typedVenue),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        launchUrl: registration ? (variant as any)?.launchUrl : undefined
      };

      return ecomVariant;
    };

    dispatch(setCatalogRelations({ variants: variants.map(value => mapToEcomVariant(value)) }));

    if (currentVariant.isHardSelected) {
      dispatch(setHardSelectedVariant(mapToEcomVariant(currentVariant.variant)));
    } else {
      dispatch(setSoftSelectedVariant(mapToEcomVariant(currentVariant.variant)));
    }
  }, [
    currentVariant.isHardSelected,
    currentVariant.variant,
    dispatch,
    registrations,
    selectItemMaterials,
    variants
  ]);

  return {
    selectedVariant: currentVariant.variant
  };
};
