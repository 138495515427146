import { IContent } from '@/@types/cms';
import { BookProduct } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';

const generateContentArea = (contentAreaProps: IContent[]) => {
  if (contentAreaProps?.length)
    return <ContentArea components={contentAreaProps} componentsProps={{ isPDP: true }} />;
  return <></>;
};
export const usePublicationContent = (bookProduct?: BookProduct) => {
  if (!bookProduct) return undefined;

  const globalPushAreaContent = (
    bookProduct.suppressGlobalPushArea !== true
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (bookProduct as any)?.globalPushArea?.expandedValue ?? []
      : []
  ) as IContent[];

  let contentArea1Props = [...(bookProduct?.contentArea1 ?? [])];
  let contentArea2Props = [...(bookProduct?.contentArea2 ?? [])];
  let contentArea3Props = [...(bookProduct?.contentArea3 ?? [])];
  let contentArea4Props = [...(bookProduct?.contentArea4 ?? [])];
  let contentArea5Props = [...(bookProduct?.contentArea5 ?? [])];
  let contentArea6Props = [...(bookProduct?.contentArea6 ?? [])];
  let contentArea7Props = [...(bookProduct?.contentArea7 ?? [])];

  if (globalPushAreaContent?.length) {
    switch (
      bookProduct.bookProductContentAreaPosition // Where to insert global push area
    ) {
      case 1: // Above Overview
        contentArea1Props = [...globalPushAreaContent, ...contentArea1Props];
        break;
      case 2: // Between Overview and Details
        contentArea2Props = [...globalPushAreaContent, ...contentArea2Props];
        break;
      case 3: // Between Details and Topics&Industries
        contentArea3Props = [...globalPushAreaContent, ...contentArea3Props];
        break;
      case 4: // Between Topics&Industries and TOC
        contentArea4Props = [...globalPushAreaContent, ...contentArea4Props];
        break;
      case 5: // Between TOC and Authors
        contentArea5Props = [...globalPushAreaContent, ...contentArea5Props];
        break;
      case 6: // Between Authors and Testimonials
        contentArea6Props = [...globalPushAreaContent, ...contentArea6Props];
        break;
      case 7: // Below Testimonials
        contentArea7Props = [...globalPushAreaContent, ...contentArea7Props];
        break;
      default: // Default position is between Details/Topics&Industries
        contentArea3Props = [...globalPushAreaContent, ...contentArea3Props];
        break;
    }
  }

  return {
    contentArea1: generateContentArea(contentArea1Props),
    contentArea2: generateContentArea(contentArea2Props),
    contentArea3: generateContentArea(contentArea3Props),
    contentArea4: generateContentArea(contentArea4Props),
    contentArea5: generateContentArea(contentArea5Props),
    contentArea6: generateContentArea(contentArea6Props),
    contentArea7: generateContentArea(contentArea7Props)
  };
};
