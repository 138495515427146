import type { PodcastListingBlock as PodcastListingBlockProps } from '@/@types/content';
import SectionTitle from '@/components/ui/Titles/SectionTitle';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import Button from '@/components/ui/Buttons/Button';
import PodcastListingContent from '@/components/blocks/PodcastListingBlock/PodcastListingContent';
import { ISearchResultItem } from '@/@types/client-api';
import useSearch from '@/components/ui/SearchResults/hooks/useSearch';
import Divider from '@/components/ui/Divider';
import Shimmer from '@/components/ui/Shimmer';
import Block from '@/components/cms/Block';
import SimpleSort from '@/components/ui/SimpleSort';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

type sortOptionsType = {
  label: string;
  value: string;
};

const PodcastListingBlock = ({
  contentLink,
  heading,
  audioLabel,
  videoLabel,
  loadMoreLabel,
  saveToMyLibraryLabel,
  removeFromMyLibraryLabel,
  listenOnLabel,
  hideLabel,
  podcastSeries
}: PodcastListingBlockProps): JSX.Element => {
  const sm = useMediaQuery(ScreenSizeQueries.sm);

  const [sortOptions, setSortOptions] = useState<sortOptionsType[]>([]);
  const [sortValue, setSortValue] = useState('');
  const [loadMore, setLoadMore] = useState(false);
  const [content, setContent] = useState<ISearchResultItem[]>([]);

  const code = (podcastSeries?.code as string) || undefined;

  const { onLoadMore, loading, isLoadingMore, data } = useSearch({
    overrides: {
      pageSize: 10,
      facetGroups: [
        {
          groupTypeId: code ? 'ParentCode' : 'ContentTabs',
          facets: [
            {
              value: code ? code : 'Podcasts',
              checked: true
            }
          ]
        }
      ]
    },
    updateUrlParams: false
  });

  const handleSortChange = (value?: string) => {
    setSortValue(value || '');
  };

  useEffect(() => {
    if (data) {
      const currentPage = data?.searchResultSummary?.currentPage || 0;
      const totalPages = data?.searchResultSummary?.totalPages || 0;

      const sortOptions =
        data?.sortResults?.sortResultItems?.map(item => ({
          label: item.sortTitle || '',
          value: item.value || ''
        })) || [];

      setLoadMore(currentPage < totalPages);
      setContent(data?.searchResults?.searchResultsItems || []);
      setSortOptions(sortOptions);
      setSortValue(data?.sortResults?.sortResultItems?.find(item => item.isDefault)?.value || '');
    }
  }, [data]);

  return (
    <Block contentLinkID={contentLink?.id}>
      <div className={classnames('container py-10', 'sm:py-12', 'lg:pb-16 lg:pt-0')}  data-component={"PodcastListingBlock"}>
        <SectionTitle
          titleSize="h5"
          verticalAlignment="bottom"
          mobileGapSmall={false}
          title={heading || ''}
        >
          {loading ? (
            <Shimmer className="h-11 w-40" />
          ) : (
            <div className="relative z-dropdown">
              <SimpleSort
                options={sortOptions}
                value={sortValue}
                onValueChange={handleSortChange}
                disabled={loading || isLoadingMore}
                align={sm ? 'end' : 'start'}
                avoidCollisions={false}
              />
            </div>
          )}
        </SectionTitle>
      </div>

      <div className="container">
        <Divider color="light" />
      </div>

      <PodcastListingContent
        shimerCount={5}
        isLoading={loading || isLoadingMore}
        content={content}
        audioLabel={audioLabel}
        videoLabel={videoLabel}
        saveToMyLibraryLabel={saveToMyLibraryLabel}
        removeFromMyLibraryLabel={removeFromMyLibraryLabel}
        listenOnLabel={listenOnLabel}
        hideLabel={hideLabel}
      />

      {loadMore && (
        <div className="container flex items-center justify-center py-10">
          <Button
            color="black"
            size="large"
            label={loadMoreLabel}
            disabled={loading || isLoadingMore}
            onClick={onLoadMore}
          />
        </div>
      )}
    </Block>
  );
};
export default PodcastListingBlock;
