import { useState, memo, useMemo, createRef } from 'react';
import classnames from 'classnames';
import type { FeaturedContentWideBlock as FeaturedContentWideBlockProps } from '@/@types/content';
import type { ProBonoScholarshipModal } from '@/@types/content';
import useToast from '@/hooks/useToast';
import * as Form from '@radix-ui/react-form';
import Text from '../cms/Text';
import Block from '../cms/Block';
import Image from '@/components/cms/Image';
import Link from '@/components/ui/Link';
import RichText from '@/components/cms/RichText';
import ResponsiveImage from '../ui/ResponsiveImage';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Panel from '@/components/ui/Panel';
import Button from '@/components/ui/Buttons/Button';
import TextInput from '@/components/ui/Form/TextInput';
import SelectorLarge from '@/components/ui/Form/SelectorLarge';
import { v4 as uuid4 } from 'uuid';
import Icon from '@/components/ui/Icon';
import DropdownSelect from '@/components/ui/DropdownSelect';
import DropdownButton from '@/components/ui/Buttons/DropdownButton';
import TextArea from '@/components/ui/Form/TextArea';
import DateInput from '@/components/ui/Form/DateInput';

type Props = FeaturedContentWideBlockProps & {
  tilesBlock?: boolean;
};

type FieldType = {
  fieldDescription?: string;
  fieldOptions?: string[];
  fieldLabel?: string;
  fieldName?: string;
  fieldType?: string;
  isRequired?: boolean;
  placeholder?: string;
  maxLength?: number;
};

type StepType = {
  rows: {
    rowFields: FieldType[];
  }[];
};

const FormStep = memo(
  ({
    step,
    index,
    formId,
    customData,
    setCustomData
  }: {
    step: StepType;
    index: number;
    formId: string;
    customData: Record<string, unknown>;
    setCustomData: (data: Record<string, unknown>) => void;
  }) => {
    return (
      <div
        className={classnames('flex flex-col', {
          'gap-4': index === 0,
          'gap-12': index !== 0
        })}
      >
        {step?.rows?.map(({ rowFields }: { rowFields: FieldType[] }, indexRow: number) => (
          <div
            key={indexRow}
            className={classnames('flex flex-col', 'sm:flex-row sm:gap-6', {
              'gap-6': index === 0,
              'gap-12': index !== 0
            })}
          >
            {rowFields?.map(
              (
                {
                  fieldDescription,
                  fieldLabel,
                  fieldOptions,
                  fieldName,
                  fieldType,
                  isRequired,
                  placeholder,
                  maxLength
                },
                indexField: number
              ) => {
                switch (fieldType) {
                  case 'checkbox':
                    return (
                      <div className="relative w-full" key={indexField}>
                        <SelectorLarge
                          header={fieldLabel}
                          copy={fieldDescription}
                          asFormField={true}
                          name={fieldName}
                          required={isRequired}
                        />
                      </div>
                    );
                  case 'text':
                  case 'email':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type={fieldType}
                          maxLength={maxLength}
                        />
                      </div>
                    );
                  case 'hidden':
                    return (
                      <div className="hidden w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type={fieldType}
                          value={placeholder}
                        />
                      </div>
                    );
                  case 'tel':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type="tel"
                          maxLength={maxLength}
                          onChange={(value: string) => {
                            let numericValue = value.replace(/\D/g, '');

                            if (numericValue.length > 3 && numericValue.length <= 6) {
                              numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
                            } else if (numericValue.length > 6) {
                              numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
                            } else if (numericValue.length > 0) {
                              numericValue = `(${numericValue}`;
                            }

                            if (numericValue.length > 14) {
                              numericValue = numericValue.slice(0, 14);
                            }

                            const form = document.getElementById(formId);
                            const field = form?.querySelector(`[name="${fieldName}"]`);

                            if (!field) return;

                            if (numericValue.length <= 14) {
                              (field as HTMLInputElement).value = numericValue;
                            }
                          }}
                        />
                      </div>
                    );
                  case 'textArea':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextArea
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          maxLength={maxLength}
                        />
                      </div>
                    );
                  case 'dropdown': {
                    const options = fieldOptions?.map(option => ({
                      disabled: false,
                      label: option,
                      value: option
                    })) as { disabled: boolean; label: string; value: string }[];

                    return (
                      <div className="w-full" key={indexField}>
                        <DropdownSelect
                          name={fieldName}
                          button={({ isOpen, value, ...props }) => (
                            <DropdownButton
                              state={isOpen ? 'open' : 'closed'}
                              label={value}
                              {...props}
                            />
                          )}
                          options={options}
                          label={fieldLabel}
                          placeholder={placeholder}
                          required={isRequired}
                          menuStyle="form-field"
                          onValueChange={(value: string) => {
                            setCustomData({ ...customData, [`${formId}-${fieldName}`]: value });
                          }}
                          value={customData[`${formId}-${fieldName}`] as string}
                        />
                      </div>
                    );
                  }
                  case 'date':
                    return (
                      <div className="w-full" key={indexField}>
                        <DateInput
                          label={fieldLabel || ''}
                          name={fieldName || ''}
                          id={`${formId}-${fieldName}`}
                          required={isRequired}
                          onChange={(value: string) => {
                            setCustomData({ ...customData, [`${formId}-${fieldName}`]: value });
                          }}
                          value={(customData[`${formId}-${fieldName}`] as string) || ''}
                        />
                      </div>
                    );
                  case 'number':
                    return (
                      <div className="w-full" key={indexField}>
                        <TextInput
                          label={fieldLabel ?? ''}
                          placeholder={placeholder}
                          name={fieldName}
                          required={isRequired}
                          type="text"
                          maxLength={maxLength}
                          onChange={(value: string) => {
                            const numericValue = value.replace(/\D/g, '');
                            const form = document.getElementById(formId);
                            const field = form?.querySelector(`[name="${fieldName}"]`);

                            if (!field || !maxLength) return;

                            if (numericValue.length <= maxLength) {
                              (field as HTMLInputElement).value = numericValue;
                            }
                          }}
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              }
            )}
          </div>
        ))}
      </div>
    );
  }
);

const FeaturedContentWideBlock: React.FC<Props> = ({
  contentLink,
  copy,
  eyeBrowText,
  featuredImage,
  heading,
  logo,
  optinalLink,
  tilesBlock = false,
  format,
  proBonoModal
}) => {
  const proBonoData = proBonoModal?.at(0) as ProBonoScholarshipModal;
  const steps = useMemo(() => proBonoData?.steps || [], [proBonoData]);
  const successMessage = proBonoData?.successMessage;
  const failMessage = proBonoData?.failMessage;
  const tmpHiddenInputId = 'tmpHiddenInputId';

  const sm = useMediaQuery(ScreenSizeQueries.sm);
  const md = useMediaQuery(ScreenSizeQueries.md);

  const { showSuccessMessage, showFailMessage } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [forceClose, setForceClose] = useState(false);
  const [allFormsData, setAllFormsData] = useState<Record<string, unknown>>({});
  const [showStepErrorMessage, setShowStepErrorMesages] = useState(false);
  const [customData, setCustomData] = useState({});

  const onChange = (value: boolean) => {
    setIsOpen(value);
  };
  const handleStepChange = (increment: number) => {
    const newStepNumber = stepNumber + increment;
    if (newStepNumber >= 0 && newStepNumber < steps.length) {
      setStepNumber(newStepNumber);
    }
  };
  const formIds = useMemo(() => steps.map(() => uuid4()), [steps]);
  const formRefs = useMemo(() => steps.map(() => createRef<HTMLFormElement>()), [steps]);

  const handleBack = () => handleStepChange(-1);

  const handleSubmitNext = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());

    if (Object.keys(data).length === 0) {
      setShowStepErrorMesages(true);
      return;
    }

    const formId = formIds[stepNumber];
    setAllFormsData(prev => ({ ...prev, [formId]: data }));

    setShowStepErrorMesages(false);
    handleStepChange(1);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const action = event.currentTarget.action;
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());
    const formId = formIds[stepNumber];

    const mergedData = { ...allFormsData, [formId]: data };

    setAllFormsData(mergedData);

    const dataWithoutFormId = Object.entries(mergedData).reduce(
      (acc, [_key, value]) => {
        if (typeof value === 'object' && value !== null) {
          acc = { ...acc, ...value };
        }
        return acc;
      },
      {} as Record<string, string>
    );
    setIsSubmitting(true);

    if (dataWithoutFormId[tmpHiddenInputId]) {
      delete dataWithoutFormId[tmpHiddenInputId];
    }

    try {
      await fetch(action, {
        method: 'POST',
        mode: 'no-cors',
        redirect: 'follow',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(
          Object.entries(dataWithoutFormId).reduce(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {} as Record<string, string>
          )
        ).toString()
      });

      setIsSuccess(true);
      showSuccessMessage(successMessage);
      setTimeout(() => {
        setForceClose(true);
      }, 1000);
    } catch (error) {
      showFailMessage(failMessage);
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const resetStates = (open: boolean) => {
    onChange(!isOpen);
    if (open) {
      setForceClose(false);
      setStepNumber(0);
      setIsSubmitting(false);
      setIsSuccess(false);
    }
  };

  const blockClasses = classnames('container', {
    'p-0': tilesBlock,
    'px-6 py-10 md:px-20 md:py-16': !tilesBlock
  });
  const imageContainerClasses = classnames(
    {
      'flex-zero-auto featured-content-image h-[218px] object-cover': sm
    },
    'sm:h-auto sm:w-[48.75%]',
    'md:w-[411px]'
  );
  const contentWideClasses = classnames(
    'flex w-auto flex-col justify-start overflow-hidden rounded-lg bg-gray-feather',
    'sm:flex-row'
  );
  const contentContainerClasses = classnames(
    'flex w-full flex-col justify-center p-8 sm:min-h-[200px]',
    'md:px-16 md:py-[60px]'
  );
  const grayTextClasses = classnames('text-1 text-gray-dark');
  const getTitleSizeByFormat = (format?: string) => {
    switch (format) {
      case 'h6':
        return 'h6';
      case 'h5':
        return 'h5';
      case 'h4':
        return 'h4';
      case 'h3':
        return 'h3';
      case 'h2':
        return 'h2';
      case 'h1':
        return 'h1';
      default:
        return 'h5';
    }
  };
  const getTitleClassByFormat = (format?: string) => {
    switch (format) {
      case 'h6':
        return 'heading-6-medium';
      case 'h5':
        return 'heading-5';
      default:
        return 'heading-5';
    }
  };
  const titleSizeByFormat = getTitleSizeByFormat(format);
  const titleClassByFormat = getTitleClassByFormat(format);

  return (
    <Block element="div" contentLinkID={contentLink?.id} className={blockClasses}>
      <article className={contentWideClasses}>
        <div className={contentContainerClasses}>
          <div className={classnames('flex flex-col gap-4', 'xl:max-w-[90%]')}>
            <Text propertyName="EyeBrowText" className={grayTextClasses}>
              {eyeBrowText}
            </Text>
            {logo && (
              <div>
                <Image
                  src={logo?.url}
                  alt={logo?.altText as string}
                  propertyName="Logo"
                  className={classnames('h-[18px]')}
                />
              </div>
            )}
            <Text element={titleSizeByFormat} propertyName="Heading" className={titleClassByFormat}>
              {heading}
            </Text>
            {copy && (
              <RichText
                content={copy!}
                propertyName="Copy"
                className={grayTextClasses}
                checkList={true}
              />
            )}
            {!proBonoData && optinalLink?.href && (
              <Link
                href={optinalLink?.href}
                text={optinalLink?.text}
                variant={optinalLink?.target === '_blank' ? 'bold-link-with-icon' : 'text-link-1'}
                icon={optinalLink?.target === '_blank' ? 'link-out' : undefined}
                propertyName="OptionalLink"
                className="w-fit text-gray-darker"
              />
            )}
            {proBonoData && (
              <Panel isOpen={isOpen} onOpenChange={resetStates} forceClose={forceClose}>
                <Panel.Trigger asChild>
                  <Link
                    as="button"
                    text={optinalLink?.text}
                    variant={
                      optinalLink?.target === '_blank' ? 'bold-link-with-icon' : 'text-link-1'
                    }
                    icon={optinalLink?.target === '_blank' ? 'link-out' : undefined}
                    propertyName="OptionalLink"
                    className="w-fit text-gray-darker"
                  />
                </Panel.Trigger>
                <Panel.Content
                  onBackButtonPress={stepNumber >= 1 ? handleBack : undefined}
                  miniPaddingHeader={true}
                >
                  <Panel.Body scrollClassName="">
                    <div className="flex flex-col gap-2">
                      {steps[stepNumber]?.stepIndicator && (
                        <small
                          className={classnames('text-gray-dark', {
                            'text-2-fixed': !sm,
                            'text-2-fixed-medium': sm
                          })}
                        >
                          {steps[stepNumber]?.stepIndicator}
                        </small>
                      )}
                      {steps[stepNumber]?.heading && (
                        <h2 className="heading-4">{steps[stepNumber]?.heading}</h2>
                      )}
                      {steps[stepNumber]?.subheading && (
                        <span className="text-1 text-gray-dark">
                          {steps[stepNumber]?.subheading}
                        </span>
                      )}
                      {steps[stepNumber]?.copy && (
                        <RichText
                          content={steps[stepNumber]?.copy}
                          propertyName="Copy"
                          className={classnames(grayTextClasses, 'mt-6')}
                        />
                      )}
                    </div>

                    <div className={classnames('my-10', 'lg:my-8')}>
                      {steps.map((step: StepType, index: number) => {
                        return (
                          <Form.Root
                            key={index}
                            onSubmit={
                              steps[stepNumber]?.submitButton ? handleSubmit : handleSubmitNext
                            }
                            id={formIds[index]}
                            action={steps[stepNumber]?.submitButton?.href || undefined}
                            className={classnames({ hidden: index !== stepNumber })}
                            ref={formRefs[index]}
                          >
                            <input type="hidden" name={tmpHiddenInputId} value="value" />
                            <FormStep
                              step={step}
                              index={index}
                              formId={formIds[index]}
                              customData={customData}
                              setCustomData={setCustomData}
                            />
                          </Form.Root>
                        );
                      })}
                    </div>
                  </Panel.Body>
                  <Panel.Footer divider="line">
                    {showStepErrorMessage && steps[stepNumber]?.stepErrorMessage ? (
                      <div className="label relative flex items-center gap-1 text-red">
                        <Icon name="alert" size="small" />
                        <Text element="span">{steps[stepNumber]?.stepErrorMessage}</Text>
                      </div>
                    ) : null}

                    <div className="flex flex-row justify-end gap-6">
                      {steps[stepNumber]?.nextButton && (
                        <Button
                          form={formIds[stepNumber]}
                          type="submit"
                          label={steps[stepNumber]?.nextButton?.text}
                          size="large"
                          color="black"
                        />
                      )}
                      {steps[stepNumber]?.submitButton && (
                        <Button
                          form={formIds[stepNumber]}
                          type="submit"
                          label={isSuccess ? undefined : steps[stepNumber]?.submitButton?.text}
                          size="large"
                          color={isSuccess ? 'green' : 'black'}
                          iconRight={isSuccess ? 'check' : undefined}
                          loading={isSubmitting}
                          className={classnames('min-w-28 !justify-center', {
                            'pointer-events-none': isSubmitting || isSuccess
                          })}
                        />
                      )}
                    </div>
                  </Panel.Footer>
                </Panel.Content>
              </Panel>
            )}
          </div>
        </div>

        {featuredImage && (
          <div className={imageContainerClasses}>
            <ResponsiveImage
              propertyName="FeaturedImage"
              src={featuredImage?.url}
              alt={featuredImage?.alt as string}
              aspectRatio={sm && !md ? 1 : 3 / 2}
              imageSizes={{
                xs: '330px',
                sm: '360px',
                md: '420px'
              }}
            />
          </div>
        )}
      </article>
    </Block>
  );
};

export default FeaturedContentWideBlock;
