// This modal is triggered from the 'add to calendar' button within an upcoming live snippet
// we have two AddToCalendarModal components, one in the Search Results folder and one in the PDP folder
// because each triggers and uses the open/close state differently

import { useCallback, useState } from 'react';
import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import AddToCalendarModalContent from '../PDP/Modals/AddToCalendarModalContent';
import { AnalyticsContext } from '@/analytics/constants';

export type AddToCalendarParams = {
  pk: string;
};

export type AddToCalendarModalProps = {
  params?: AddToCalendarParams;
  onClose?: () => void;
  triggerButton?: JSX.Element;
  context?: AnalyticsContext;
};

const AddToCalendarModal: React.FC<AddToCalendarModalProps> = ({
  params,
  onClose,
  triggerButton,
  context
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleSetOpen = useCallback(
    (open: boolean) => {
      if (triggerButton) {
        setOpen(open);
      } else if (!open) {
        onClose?.();
      }
    },
    [onClose, triggerButton]
  );

  return (
    <Modal
      open={triggerButton ? isOpen : params !== undefined}
      setOpen={handleSetOpen as ModalProps['setOpen']}
    >
      <Modal.Trigger asChild>{triggerButton}</Modal.Trigger>
      <AddToCalendarModalContent
        modalHeader="Add To Calendar"
        productCode={params?.pk}
        context={context}
      />
    </Modal>
  );
};

export default AddToCalendarModal;
