import ContentArea from './ContentArea';
import {
  LiveProgramCatalogRelations,
  OnDemandProgramCatalogRelations,
  OnDemandSegmentCatalogRelations,
  ProgramVariation,
  WebSegmentVariation
} from '@/hooks/PDP/useProgramCatalogRelations';
import { IContent } from '@/@types/cms';
import { useOverview } from '@/hooks/PDP/useOverview';
import { LoggedOutLicenseStruct } from '@/hooks/PDP/useLoggedOutLicenses';

interface IPDPLayoutProps {
  layout?: { layout: IContent[]; variant: ProgramVariation | WebSegmentVariation };
  catalogRelations:
    | LiveProgramCatalogRelations
    | OnDemandProgramCatalogRelations
    | OnDemandSegmentCatalogRelations;
  className?: string;
  loggedOutLicenseProps?: LoggedOutLicenseStruct;
  isProgramSegmentsLoading?: boolean;
  creditTrackerCode?: string;
}

const PDPLayoutBlock: React.FC<IPDPLayoutProps> = ({
  layout,
  catalogRelations,
  className,
  loggedOutLicenseProps,
  creditTrackerCode,
  isProgramSegmentsLoading = false
}) => {
  const { overviewSegmentDict } = useOverview({ variant: layout?.variant, catalogRelations });

  return layout ? (
    <ContentArea
      components={layout.layout}
      componentsProps={{
        variant: layout.variant,
        catalogRelations,
        withinOverview: false,
        overviewData: overviewSegmentDict,
        isPDP: true,
        loggedOutLicense: loggedOutLicenseProps,
        isProgramSegmentsLoading: isProgramSegmentsLoading,
        creditTrackerCode
      }}
      className={className}
    />
  ) : null;
};

export default PDPLayoutBlock;
