import { IContent } from '@/@types/cms';
import ExpandableRichText from '../ExpandableRichText';
import ContentArea from '@/components/cms/ContentArea';
import {
  ProgramCatalogRelations,
  ProgramVariation,
  WebSegmentVariation
} from '@/hooks/PDP/useProgramCatalogRelations';
import Shimmer from '../Shimmer';

interface IProgramOverviewProps {
  heading: string;
  variant: ProgramVariation | WebSegmentVariation;
  catalogRelations: ProgramCatalogRelations;
  content: string;
  isSegment?: boolean;
  isLegacyOverview: boolean;
  defaultOrder: string[];
  overviewSegmentDict: object;
  mainContentArea?: Array<IContent>;
}

const ProgramOverview = ({
  heading,
  content,
  isLegacyOverview,
  isSegment,
  defaultOrder,
  overviewSegmentDict,
  mainContentArea,
  variant,
  catalogRelations
}: IProgramOverviewProps) => {
  const overviewId = 'overview';

  const overviewSectionBlockProps = {
    variant,
    catalogRelations,
    overviewData: overviewSegmentDict,
    withinOverview: true
  };

  const isOverviewDependenciesReady =
    isSegment || !!catalogRelations.masterProgram || variant.overviewMode === 2;
  const shimmerParagraph = (
    <div className="flex flex-col gap-2">
      {[...Array(5)].map(line => (
        <Shimmer key={line} className="h-4 w-full" />
      ))}
      <Shimmer className="h-4 w-3/4" />
    </div>
  );

  if (!isOverviewDependenciesReady)
    return (
      <div
        id={overviewId}
        className={'container module-spacing-pdp flex flex-col gap-8 max-sm:gap-6'}
      >
        <h2 className="heading-5 mb-[-16px] lg:mb-[-12px]">{heading}</h2>
        <div className="flex flex-col gap-6">
          {shimmerParagraph}
          {shimmerParagraph}
        </div>
      </div>
    );

  if (mainContentArea?.length && !isLegacyOverview && !isSegment)
    return (
      <div id={overviewId} className={'container module-spacing-pdp'}>
        <h2 className="heading-5 mb-[-16px] lg:mb-[-12px]">{heading}</h2>
        <ContentArea
          components={mainContentArea}
          propertyName="mainContentArea"
          componentsProps={overviewSectionBlockProps}
        />
      </div>
    );

  return (
    <div
      id={overviewId}
      className={'container module-spacing-pdp flex flex-col gap-8 max-sm:gap-6'}
      data-component={"ProgramOverview"}
    >
      {isLegacyOverview || isSegment ? (
        <>
          <h2 className="heading-5">{heading}</h2>
          <ExpandableRichText className="text-1" content={content} condensed={isSegment} />
        </>
      ) : (
        <>
          <h2 className="heading-5">{heading}</h2>
          {defaultOrder.map(segmentName => {
            if (Object.prototype.hasOwnProperty.call(overviewSegmentDict, segmentName))
              return (
                <>
                  <h2 className="heading-6-medium">
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                    {(overviewSegmentDict as any)[segmentName].header}
                  </h2>

                  <ExpandableRichText
                    className="text-1"
                    //eslint-disable-next-line @typescript-eslint/no-explicit-any
                    content={(overviewSegmentDict as any)[segmentName].content}
                    condensed={false}
                  />
                  <br />
                </>
              );
          })}
        </>
      )}
    </div>
  );
};
export default ProgramOverview;
