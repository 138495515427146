import { CTABlock as ICTABlock } from '@/@types/content';
import React from 'react';
import Block from '../cms/Block';
import Text from '../cms/Text';
import Button from '../ui/Buttons/Button';
import classnames from 'classnames';
import { translateToTailwinds } from '../ui/CreditTracker/util/TrackerHelpers';
import { LinkItem } from '@/@types/cms';

interface CTABlockProps extends ICTABlock {
  textColor?: 'white' | 'black';
  ctaLink?: LinkItem;
}

const CTABlock: React.FC<CTABlockProps> = ({ contentLink, heading, ctaLink, copy, textColor }) => {
  const buttonClasses = classnames('mt-5 border border-solid !bg-transparent sm:mt-6 lg:mt-10', {
    'border-black ': textColor === 'black',
    'border-white': textColor === 'white'
  });

  const headingClasses = classnames('heading-4 mb-3 sm:mb-4 lg:mb-6');

  const textClasses = classnames({
    'text-white': textColor === 'white',
    'text-black': textColor === 'black'
  });

  return (
    <Block
      contentLinkID={contentLink?.id}
      className="container module-spacing flex flex-col justify-between"
    >
      <div>
        <h2 className={classnames(headingClasses, textClasses)}>{heading}</h2>
        <Text element="p" className={classnames('text-2', textClasses)}>
          <span dangerouslySetInnerHTML={{ __html: translateToTailwinds(copy ?? '') }} />
        </Text>
      </div>
      {ctaLink ? (
        <Button
          propertyName="Link"
          color={textColor === 'black' ? 'white' : 'black'}
          size="large"
          href={ctaLink?.href}
          label={ctaLink?.text}
          title={ctaLink?.title}
          target={ctaLink?.target}
          className={buttonClasses}
        />
      ) : null}
    </Block>
  );
};

export default CTABlock;
