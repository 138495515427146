import Text from '@/components/cms/Text';
import Block from '@/components/cms/Block';
import { useState } from 'react';
import Image from '@/components/cms/Image';
import Button from '@/components/ui/Buttons/Button';
import Panel from '@/components/ui/Panel';
import { EditorialCalloutBlock as EditorialCalloutBlockProps } from '@/@types/content';

const EditorialCalloutBlock: React.FC<EditorialCalloutBlockProps> = ({
  contentLink,
  image,
  cTALabel,
  heading,
  copy,
  modalHeading,
  modalCopy,
  modalImage,
  modalLink
}) => {
  const [isPanelActive, setIsPanelActive] = useState(false);

  return (
    <Block contentLinkID={contentLink?.id}>
      {image?.url && (
        <Image
          className="aspect-square w-full rounded-lg object-cover"
          propertyName="Image"
          src={image?.url}
        />
      )}
      <div className="mt-8">
        {heading && (
          <Text element="h6" className="heading-6-medium" propertyName="Heading">
            {heading}
          </Text>
        )}
        {copy && (
          <Text element="p" className="text-1 mt-2.5 text-gray-dark lg:mt-4" propertyName="Copy">
            {copy}
          </Text>
        )}
        {cTALabel && (
          <Button
            size="small"
            label={cTALabel}
            color="outline-black"
            className="mt-6 lg:mt-8"
            onClick={() => setIsPanelActive(true)}
          />
        )}
      </div>
      <Panel isOpen={isPanelActive} onOpenChange={() => setIsPanelActive(false)}>
        <Panel.Content side="right" closeLabel="Close">
          <Panel.Header className="mb-5">
            {modalHeading && (
              <Text element="h4" className="heading-4" propertyName="ModalHeading">
                {modalHeading}
              </Text>
            )}
          </Panel.Header>
          <Panel.Body>
            {modalCopy && (
              <Text element="p" className="text-1 -mt-7 text-gray-dark" propertyName="ModalCopy">
                {modalCopy}
              </Text>
            )}
            {modalImage?.url && (
              <Image
                className="mt-8 rounded-lg bg-gray-feather object-cover lg:min-h-[471px]"
                propertyName="ModalImage"
                src={modalImage?.url}
              />
            )}
            {modalLink?.text && (
              <Button
                size="small"
                label={modalLink?.text || ''}
                color="outline-black"
                className="mb-28 mt-6 lg:mt-8"
                href={modalLink?.href || ''}
                target={modalLink?.target || ''}
              />
            )}
          </Panel.Body>
        </Panel.Content>
      </Panel>
    </Block>
  );
};

export default EditorialCalloutBlock;
