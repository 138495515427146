import classnames from 'classnames';
import { useCallback } from 'react';
import { format } from 'date-fns';

type CalendarDayProps = {
  date: Date;
  selected: boolean;
  onSelect?: (date: Date) => void;
  visible?: boolean;
  disabled?: boolean;
};

const CalendarDay = ({
  date,
  selected,
  onSelect,
  disabled = false,
  visible = true
}: CalendarDayProps): JSX.Element => {
  const handleOnSelect = useCallback(() => {
    onSelect?.(date);
  }, [date, onSelect]);

  if (!visible) {
    return <div className="size-8" />;
  }

  return (
    <button
      className={classnames(
        'text-2 size-8 rounded-2xl text-center font-medium enabled:hover:bg-gray-light enabled:hover:text-black disabled:text-gray-medium',
        {
          'enabled:bg-red enabled:text-white': selected
        }
      )}
      onClick={handleOnSelect}
      type="button"
      disabled={disabled}
      aria-label={format(date, 'MMMM do yyyy')}
    >
      {date.getDate()}
    </button>
  );
};

export default CalendarDay;
