import Link from '@/components/ui/Link';
import type { JavascriptLinkBlock as JavascriptLinkProps } from '@/@types/content';

const TextLinkBlock: React.FC<JavascriptLinkProps> = ({ onClick }) => (
  <Link
    propertyName="Link"
    onClick={Reflect.get(window, onClick || '')}
    variant="text-link-1"
    className="text-gray-dark"
  />
);

export default TextLinkBlock;
