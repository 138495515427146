import FilterRow, { FilterRowProps, FilterRowRefs } from '@/components/ui/Filter/FilterRow';
import { FILTERS, FilterTypes } from './constants';
import { useSelector } from 'react-redux';
import { selectSearchSemanticFilters } from '@/redux/selectors/searchSelectors';
import { getFilterForTooltip } from './utils/semanticFilters';
import { forwardRef } from 'react';

type TFilter = {
  label?: string | null;
  name?: string | null;
  value: string;
  checked?: boolean;
  groupTypeId?: string;
};

type SearchResultsQuickFilterRowProps = Pick<
  FilterRowProps,
  | 'onFilterMenuOpen'
  | 'label'
  | 'canFilter'
  | 'sortMenu'
  | 'sortValue'
  | 'className'
  | 'loading'
  | 'fetching'
  | 'showQuickFilters'
  | 'filters'
  | 'showAllFiltersAndSortButtons'
> & {
  onFilterToggle: (groupTypeId: string, value: string, type: FilterTypes, name: string) => void;
  invariantFilterTooltipText?: string;
};

const SearchResultsQuickFilterRow = forwardRef<FilterRowRefs, SearchResultsQuickFilterRowProps>(
  ({ onFilterToggle, ...props }, ref) => {
    const semanticFilters = useSelector(selectSearchSemanticFilters);

    const handleFilterToggle = (props: TFilter) => {
      const { groupTypeId, value, label, name, checked } = props;
      const type = FilterTypes.SELECT;
      const isDateRangeFilter = groupTypeId === FILTERS.DATERANGE;
      const filterName = (isDateRangeFilter ? name : label) || '';
      const filterValue = isDateRangeFilter && checked ? '' : value;
      groupTypeId && value && onFilterToggle(groupTypeId, filterValue, type, filterName);
    };
    const semanticFilterWithTooltip = getFilterForTooltip(props.filters, semanticFilters);

    return (
      <FilterRow
        ref={ref}
        onFilterToggle={handleFilterToggle}
        semanticFilterWithTooltip={semanticFilterWithTooltip}
        {...props}
      />
    );
  }
);

export default SearchResultsQuickFilterRow;
