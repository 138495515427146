import type { StandardWhiteHeaderBlock as StandardWhiteHeaderBlockProps } from '@/@types/content';
import CmsButton from '@/components/cms/Button';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import classnames from 'classnames';
import { useState, useCallback, useEffect, useMemo } from 'react';
import Block from '../cms/Block';
import { getOIDCState } from '@/auth/oidcConfig';
import CircleButton from '@/components/ui/Buttons/CircleButton';
import {
  useFollowPracticeAreasByCategoryIdMutation,
  useUnfollowPracticeAreasByCategoryIdMutation,
  useGetUserPracticeAreasQuery
} from '@/redux/api/client/practiceAreas';
import useToast from '@/hooks/useToast';
import ContentArea from '../cms/ContentArea';
import {
  useFollowIndustryByCategoryIdMutation,
  useGetUserIndustriesQuery,
  useUnfollowIndustryByCategoryIdMutation
} from '@/redux/api/client/industries';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAuth } from 'react-oidc-context';
import Breadcrumbs, { BreadcrumbProps } from '../ui/Breadcrumbs';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

interface BlockProps extends StandardWhiteHeaderBlockProps {
  overrideCopy?: string;
  overrideAditionalCopy?: string;
  overridePageTitle?: string;
  isBabyPool?: string;
  categoryId?: string;
  hideDivider?: boolean;
  copyIcon?: string | null;
  pageName?: string;
  isIndustryPage?: boolean;
  breadcrumbs?: BreadcrumbProps[];
  hideInBreadcrumbs?: boolean;
}

const StandardWhiteHeaderBlock: React.FC<BlockProps> = ({
  contentLink,
  pageTitle: propsPageTitle,
  copy: propsCopy,
  additionalCopy: propsAdditionalCopy,
  seeMoreLabel,
  seeLessLabel,
  overrideCopy,
  overrideAditionalCopy,
  overridePageTitle,
  isBabyPool,
  categoryId,
  hideDivider,
  copyIcon,
  pageName,
  isIndustryPage,
  tooltipInformationIndustries,
  tooltipInformationPracticeArea,
  breadcrumbs,
  hideInBreadcrumbs
}) => {
  const siteLabels = useSelector((state: RootState) => state.page.siteLabels);

  const componentTitle = propsPageTitle || pageName;

  const editMode = isEditOrPreviewMode();

  const [showMore, setShowMore] = useState(false);

  const pageTitle = componentTitle?.replace('{content-name}', overridePageTitle ?? '');
  const copy = propsCopy?.replace('{content-description}', overrideCopy ?? '');
  const additionalCopy = propsAdditionalCopy?.replace(
    '{content-description}',
    overrideAditionalCopy ?? ''
  );
  const auth = useAuth();
  const [followPracticeAreas, { isLoading: isLoadingFollow }] =
    useFollowPracticeAreasByCategoryIdMutation();
  const [unfollowPracticeAreas, { isLoading: isLoadingUnfollow }] =
    useUnfollowPracticeAreasByCategoryIdMutation();
  const [followIndustry, { isLoading: isLoadingFollowIndustry }] =
    useFollowIndustryByCategoryIdMutation();
  const [unfollowIndustry, { isLoading: isLoadingUnfollowIndustry }] =
    useUnfollowIndustryByCategoryIdMutation();
  const { data: dataPA } = useGetUserPracticeAreasQuery(
    auth.isAuthenticated ? undefined : skipToken
  );
  const { data: dataIndustry } = useGetUserIndustriesQuery(
    auth.isAuthenticated ? undefined : skipToken
  );

  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const { showSuccessMessage, showFailMessage } = useToast();

  useEffect(() => {
    if (dataPA && !isIndustryPage) {
      const followed: boolean | undefined = dataPA.items?.some(x => x.categoryId === categoryId);
      setIsFollowing(followed!);
    } else {
      const followed: boolean | undefined = dataIndustry?.items?.some(
        x => x.categoryId === categoryId
      );
      setIsFollowing(followed!);
    }
  }, [dataPA, dataIndustry, categoryId, isIndustryPage]);

  const handleUnfollowIndustry = useCallback(async () => {
    const dataResponse = await unfollowIndustry([categoryId!]);

    if ('error' in dataResponse) {
      return showFailMessage(siteLabels?.toastLabels?.industryToastError);
    } else {
      setIsFollowing(false);
      return showSuccessMessage(siteLabels?.toastLabels?.industryToastUnfollow);
    }
  }, [categoryId, showFailMessage, showSuccessMessage, unfollowIndustry, siteLabels]);

  const handleFollowIndustry = useCallback(async () => {
    const dataResponse = await followIndustry([categoryId!]);

    if ('error' in dataResponse) {
      return showFailMessage(siteLabels?.toastLabels?.industryToastError);
    } else {
      setIsFollowing(true);
      return showSuccessMessage(siteLabels?.toastLabels?.industryToastFollow);
    }
  }, [followIndustry, categoryId, showFailMessage, showSuccessMessage, siteLabels]);

  const handleFollowPA = useCallback(async () => {
    const dataResponse = await followPracticeAreas([categoryId!]);

    if ('error' in dataResponse) {
      return showFailMessage(siteLabels?.toastLabels?.practiceAreaToastError);
    } else {
      setIsFollowing(true);
      return showSuccessMessage(siteLabels?.toastLabels?.practiceAreaToastFollow);
    }
  }, [followPracticeAreas, categoryId, showFailMessage, showSuccessMessage, siteLabels]);

  const handleUnfollowPA = useCallback(async () => {
    const dataResponse = await unfollowPracticeAreas([categoryId!]);

    if ('error' in dataResponse) {
      return showFailMessage(siteLabels?.toastLabels?.practiceAreaToastError);
    } else {
      setIsFollowing(false);
      return showSuccessMessage(siteLabels?.toastLabels?.practiceAreaToastUnfollow);
    }
  }, [categoryId, showFailMessage, showSuccessMessage, unfollowPracticeAreas, siteLabels]);

  const handleFollowButton = () => {
    if (!isIndustryPage) {
      handleFollowPA();
    } else {
      handleFollowIndustry();
    }
  };

  const handleUnfollowButton = () => {
    if (!isIndustryPage) {
      handleUnfollowPA();
    } else {
      handleUnfollowIndustry();
    }
  };

  const showFollowButton = isIndustryPage || isBabyPool;

  const industriesFollowCookie = window.env?.YOU_CAN_FOLLOW_INDUSTRIES || 'youCanFollowIndustries';
  const practiceAreasFollowCookie =
    window.env?.YOU_CAN_FOLLOW_PRACTICE_AREAS || 'youCanFollowPracticeAreas';

  const FirstTimeTooltip = useMemo(
    () => (
      <ContentArea
        propertyName="TooltipBlock"
        components={isIndustryPage ? tooltipInformationIndustries : tooltipInformationPracticeArea}
        componentsProps={{
          cookieName: isIndustryPage ? industriesFollowCookie : practiceAreasFollowCookie
        }}
        className={classnames(
          'pointer-events-none !absolute bottom-0 right-0 z-sticky-header h-[120%] w-full',
          'sm:h-1/2 sm:w-[110%]'
        )}
      />
    ),
    [
      industriesFollowCookie,
      practiceAreasFollowCookie,
      isIndustryPage,
      tooltipInformationIndustries,
      tooltipInformationPracticeArea
    ]
  );

  return (
    <Block
      contentLinkID={contentLink?.id}
      className={classnames('bg-white pb-6 pt-8', 'lg:pb-10', {
        'border-b border-b-gray-light': !hideDivider
      })}
    >
      <div className="container">
        {breadcrumbs && breadcrumbs.length > 0 && !hideInBreadcrumbs && (
          <div className="mt-8">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
        <div className={classnames('max-w-[536px] pt-12', 'lg:max-w-[845px]')}>
          {pageTitle || editMode ? (
            <Text propertyName="PageTitle" element="h1" className="heading-1 mb-6">
              {pageTitle}
            </Text>
          ) : null}

          {(editMode || copy || additionalCopy) && (
            <p className="text-1 mb-4 mt-6 max-w-[628px]">
              {copy || editMode ? (
                <Text
                  propertyName="Copy"
                  element="span"
                  className="flex flex-row items-start justify-start"
                >
                  {copyIcon && <Icon name={copyIcon} className="mr-2" />}
                  {copy}
                </Text>
              ) : null}

              {additionalCopy || editMode ? (
                <Text propertyName="AdditionalCopy" element="span">
                  {showMore || editMode ? ` ${additionalCopy}` : null}
                </Text>
              ) : null}
            </p>
          )}

          {additionalCopy || editMode ? (
            <CmsButton
              type="button"
              className={classnames(
                'flex items-center gap-1 py-3 text-black transition-colors',
                'hover:text-red',
                'lg:py-0'
              )}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore || editMode ? (
                <Text propertyName="SeeMoreLabel" className="text-2 font-bold">
                  {seeLessLabel}
                </Text>
              ) : null}
              {!showMore || editMode ? (
                <Text propertyName="SeeLessLabel" className="text-2 font-bold">
                  {seeMoreLabel}
                </Text>
              ) : null}
              <Icon
                name={showMore ? 'chevron-up' : 'chevron-down'}
                size="small"
                className="lg:size-5"
              />
            </CmsButton>
          ) : null}
        </div>

        {(showFollowButton || isFollowing) && (
          <div className="h-[50px] w-full">
            <div
              className={classnames(
                'mt-6 flex flex-1 justify-end gap-8 self-start',
                'lg:mt-0 lg:self-end'
              )}
            >
              <div className="relative">
                {showFollowButton && FirstTimeTooltip}
                {dataPA || dataIndustry ? (
                  isFollowing ? (
                    <CircleButton
                      label={'Unfollow'}
                      icon="minus"
                      onClick={handleUnfollowButton}
                      disabled={isLoadingUnfollow || isLoadingUnfollowIndustry}
                    />
                  ) : (
                    <CircleButton
                      label={'Follow'}
                      icon="add"
                      onClick={handleFollowButton}
                      disabled={isLoadingFollow || isLoadingFollowIndustry}
                    />
                  )
                ) : (
                  <CircleButton
                    label={'Follow'}
                    icon="add"
                    onClick={() => auth?.signinRedirect({ state: getOIDCState() })}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Block>
  );
};

export default StandardWhiteHeaderBlock;
