import { AccordionItemBlock as AccordionItemBlockProps } from '@/@types/content';
import * as Accordion from '@radix-ui/react-accordion';
import Text from '@/components/cms/Text';
import RichText from '@/components/cms/RichText';
import Icon from '@/components/ui/Icon';

const AccordionItemBlock: React.FC<
  AccordionItemBlockProps & {
    expandedItems: string[];
    index: number;
  }
> = ({ contentLink, title, description, expandedItems, index }) => {
  const value = `${contentLink?.guidValue || index}`;

  return (
    <Accordion.Item
      className="overflow-hidden rounded-lg border border-gray-lightest bg-gray-lightest transition-colors hover:border-silver hover:bg-gray-light"
      value={value}
    >
      <Accordion.Header className="flex">
        <Accordion.Trigger className="flex w-full items-center justify-between gap-4 px-4 py-6 sm:px-6 lg:px-8">
          <Text element="span" propertyName="Title" className="text-1 font-medium">
            {title}
          </Text>
          <span className="flex size-8 shrink-0 items-center justify-center rounded-full border border-black/30">
            <Icon
              name={expandedItems.includes(value) ? 'chevron-up' : 'chevron-down'}
              size="medium"
            />
          </span>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className="data-[state=closed]:animate-accordionSlideUp data-[state=open]:animate-accordionSlideDown">
        <RichText
          propertyName="Description"
          content={description}
          className="text-1 max-w-[1100px] px-4 pb-6 sm:px-6 lg:px-8"
        />
      </Accordion.Content>
    </Accordion.Item>
  );
};

export default AccordionItemBlock;
