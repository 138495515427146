import { PodcastEpisode } from '@/@types/client-api';
import Shimmer from '@/components/ui/Shimmer';
import Thumbnail from '@/components/ui/Thumbnail';
import * as Collapsible from '@radix-ui/react-collapsible';
import classnames from 'classnames';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Link from '@/components/ui/Link';
import Button from '@/components/ui/Buttons/Button';
import DropdownMenu from '@/components/ui/DropdownMenu';
import { getThumbnailImageUrl } from '@/components/ui/SearchResults/Snippets/helpers';
import {
  useAddToLibraryMutation,
  useGetLibraryQuery,
  useRemoveFromLibraryMutation
} from '@/redux/api/client/library';
import useToast from '@/hooks/useToast';
import { SaveToLibraryToast } from '@/components/ui/Cards/SaveToLibraryToast';
import VimeoVideo, { VimeoThumbnail } from '@/components/ui/VimeoVimeo';
import { ConfirmRemoveButtonToast } from '@/components/ui/Cards/ConfirmRemoveButtonToast';
import { useAuth } from 'react-oidc-context';
import { skipToken } from '@reduxjs/toolkit/query';
import { Link as RouterLink } from 'react-router-dom';
import { getDomainFromUrl, transformIfPLIUrl } from '@/utils/helpers';

enum PodcastListingSingleType {
  Other = 'other',
  Video = 'video'
}

interface PodcastListingSingleProps {
  isLoading?: boolean;
  content?: PodcastEpisode;
  audioLabel?: string;
  videoLabel?: string;
  saveToMyLibraryLabel?: string;
  removeFromMyLibraryLabel?: string;
  listenOnLabel?: string;
  hideLabel?: string;
}

const PodcastListingSingle = ({
  isLoading,
  content,
  audioLabel,
  videoLabel,
  saveToMyLibraryLabel,
  removeFromMyLibraryLabel,
  listenOnLabel,
  hideLabel
}: PodcastListingSingleProps): JSX.Element => {
  const auth = useAuth();
  const md = useMediaQuery(ScreenSizeQueries.md);

  const { title, summaryText, parentProductText, code, playerUrl, podcastMediaUrls, url } =
    content || {};

  const [isExpanded, setIsExpanded] = useState(false);
  const [collapseLabel, setCollapseLabel] = useState<string | undefined>(undefined);
  const [isLoadingSavedInLibrary, setIsLoadingSavedInLibrary] = useState(false);
  const { data, isFetching } = useGetLibraryQuery(
    !auth.isAuthenticated ? skipToken : { pageSize: 1, byItemPks: code! }
  );

  const [isSavedInLibrary, setIsSavedInLibrary] = useState(false);
  const [validPlayerUrl, setValidPlayerUrl] = useState(false);

  const [addToLibrary] = useAddToLibraryMutation();
  const [removeFromLibrary] = useRemoveFromLibraryMutation();
  const { showSuccessMessage, showFailMessage } = useToast();

  const dropdownItems = podcastMediaUrls?.map(item => ({
    label: item.provider,
    onClick: () => handleOpenInNewTab(item.url || '')
  }));

  const podcastType = playerUrl?.includes('vimeo')
    ? PodcastListingSingleType.Video
    : PodcastListingSingleType.Other;

  useEffect(() => {
    const libraryRelations = data?.items?.map(x => x.pk) ?? [];
    setIsSavedInLibrary(libraryRelations?.includes(code!));
  }, [data, code]);

  useEffect(() => {
    let label = podcastType === PodcastListingSingleType.Other ? audioLabel : videoLabel;
    label = isExpanded ? hideLabel : label;
    setCollapseLabel(label);
  }, [audioLabel, hideLabel, isExpanded, podcastType, videoLabel]);

  useEffect(() => {
    if (!playerUrl) return;
    const urlDomain = getDomainFromUrl(playerUrl);

    if (urlDomain?.includes('vimeo') || urlDomain?.includes('podbean')) setValidPlayerUrl(true);
    else setValidPlayerUrl(false);
  }, [playerUrl]);

  const headingClasses = classnames('col-span-3 row-span-1', 'sm:col-span-7', 'md:col-span-8');
  const podcastClasses = classnames(
    'col-span-1 row-span-1',
    'sm:col-span-1',
    'md:col-span-4 md:row-span-4',
    'lg:col-start-10 lg:col-end-13'
  );
  const descriptionClasses = classnames('col-span-full row-span-1 mt-4', 'sm:col-span-8');

  const buttonClasses = classnames(
    'h-11 w-full border-silver !px-4',
    'sm:h-14',
    'md:h-[46px] md:w-auto md:!px-8'
  );

  const removeFromLibraryQuery = useCallback(
    async (pk: string, undoAction?: boolean) => {
      setIsLoadingSavedInLibrary(true);
      const dataResponse = await removeFromLibrary(pk);
      setIsLoadingSavedInLibrary(false);

      if ('error' in dataResponse || !dataResponse.data) {
        setIsSavedInLibrary(true);
        return { error: true };
      } else if ('data' in dataResponse && undoAction) {
        setIsSavedInLibrary(false);
        return showSuccessMessage('Item Removed from library');
      } else if ('data' in dataResponse) {
        setIsSavedInLibrary(false);
        return { data: true };
      }
    },
    [removeFromLibrary, showSuccessMessage]
  );

  const addToLibraryQuery = useCallback(
    async (pk: string, undoAction?: boolean) => {
      setIsLoadingSavedInLibrary(true);
      const dataResponse = await addToLibrary(pk);
      setIsLoadingSavedInLibrary(false);

      if ('error' in dataResponse || !dataResponse.data) {
        setIsSavedInLibrary(false);
        return { error: true };
      } else if ('data' in dataResponse && undoAction) {
        setIsSavedInLibrary(true);
        return showSuccessMessage('Item Added to library');
      } else if ('data' in dataResponse) {
        setIsSavedInLibrary(true);
        return { data: true };
      }
    },
    [addToLibrary, showSuccessMessage]
  );

  const confirmRemoveButtonFunction = useCallback(
    async (pk: string) => {
      const onClickFunction = () => {
        addToLibraryQuery(code!, true);
      };

      const toastNode = (
        <ConfirmRemoveButtonToast
          message="Thanks for the feedback, the program has been removed. "
          onClickFunction={onClickFunction}
        />
      );

      const removeResponse = await removeFromLibraryQuery(pk);

      if (removeResponse && 'error' in removeResponse) {
        showFailMessage('Error while removing item to library');
      } else if (removeResponse && 'data' in removeResponse) {
        showSuccessMessage(toastNode);
      }
    },
    [addToLibraryQuery, removeFromLibraryQuery, showSuccessMessage, showFailMessage, code]
  );

  const saveToLibraryFunction = useCallback(
    async (pk: string) => {
      const onClickFunction = () => {
        removeFromLibraryQuery(code!, true);
      };

      const toastNode = (
        <SaveToLibraryToast message="Saved item to library. " onClickFunction={onClickFunction} />
      );

      const addResponse = await addToLibraryQuery(pk);

      if (addResponse && 'error' in addResponse) {
        showFailMessage('Error while adding item to library');
      } else if (addResponse && 'data' in addResponse) {
        showSuccessMessage(toastNode);
      }
    },
    [addToLibraryQuery, removeFromLibraryQuery, showFailMessage, showSuccessMessage, code]
  );

  const handleThumbnailClick = () => {
    setIsExpanded(true);
  };

  const handleOpenInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const memoizedThumbnailUrl = useCallback(() => getThumbnailImageUrl(code), [code]);

  return (
    <div className={classnames('container py-10', 'sm:py-12', 'lg:py-16')}  data-component={"PodcastListingSingle"}>
      <div
        className={classnames(
          'gridPLI grid-rows-[repeat(3,auto)]',
          'sm:grid-rows-[repeat(4,auto)]'
        )}
      >
        <div className={headingClasses}>
          <div
            className={classnames(
              'flex h-full flex-col justify-center gap-2',
              'md:h-auto md:justify-start md:gap-6'
            )}
          >
            {isLoading ? (
              <Shimmer className="h-5 w-36" />
            ) : (
              <span className="label">{parentProductText}</span>
            )}

            {isLoading ? (
              <Shimmer className="h-6 w-2/4" />
            ) : (
              <h6 className="heading-6-medium">
                <RouterLink to={transformIfPLIUrl(url!)!} className="text-link">
                  {title}
                </RouterLink>
              </h6>
            )}
          </div>
        </div>

        {!isExpanded && (
          <div className={podcastClasses}>
            {isLoading || !playerUrl ? (
              <Shimmer className="aspect-[3/2]" />
            ) : (
              <Fragment>
                {podcastType === PodcastListingSingleType.Video ? (
                  <VimeoVideo
                    videoId={playerUrl}
                    thumbnail={{ url: memoizedThumbnailUrl() } as VimeoThumbnail}
                    hidePlayIcon={md ? false : true}
                    ratio={md ? undefined : 1}
                    showVideoInfo={false}
                    imageSizes={{
                      xs: '70px',
                      sm: '76px',
                      md: '302px'
                    }}
                  />
                ) : (
                  <Thumbnail
                    onClick={handleThumbnailClick}
                    image={{
                      alt: title || '',
                      url: memoizedThumbnailUrl()
                    }}
                    icon={
                      md
                        ? {
                            name: 'play',
                            label: 'Launch now'
                          }
                        : undefined
                    }
                    ratio={md ? undefined : 1}
                    imageSizes={{
                      xs: '70px',
                      sm: '76px',
                      md: '302px'
                    }}
                  />
                )}
              </Fragment>
            )}
          </div>
        )}

        <div className={descriptionClasses}>
          {isLoading || isFetching ? (
            <div className="flex flex-col gap-2">
              <Shimmer className="h-4 w-full" />
              <Shimmer className="h-4 w-full" />
              <Shimmer className="h-4 w-3/4" />
            </div>
          ) : (
            <Fragment>
              <div
                className="text-2 text-gray-dark"
                dangerouslySetInnerHTML={{
                  __html: summaryText || ''
                }}
              />

              <Collapsible.Root
                open={isExpanded}
                onOpenChange={setIsExpanded}
                className="mt-6"
                hidden={!validPlayerUrl}
              >
                <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown">
                  <div
                    className={classnames(
                      'mb-8 mt-2 flex flex-col gap-8',
                      'sm:mb-6 sm:mt-0 sm:gap-6',
                      'md:mb-10 md:mt-4 md:gap-10'
                    )}
                  >
                    {playerUrl && (
                      <Fragment>
                        {podcastType === PodcastListingSingleType.Video ? (
                          <VimeoVideo
                            videoId={playerUrl}
                            thumbnail={{ url: memoizedThumbnailUrl() } as VimeoThumbnail}
                            showVideoInfo={false}
                            className={classnames('max-w-[302px]', 'md:max-w-[420px]')}
                            imageSizes={{
                              xs: '302px',
                              md: '420px'
                            }}
                          />
                        ) : (
                          <iframe src={playerUrl} width="100%" height="auto" />
                        )}
                      </Fragment>
                    )}

                    <div className={classnames('flex flex-col gap-2', 'md:flex-row md:gap-3')}>
                      <Button
                        label={isSavedInLibrary ? removeFromMyLibraryLabel : saveToMyLibraryLabel}
                        size="short"
                        iconRight={isSavedInLibrary ? 'minus' : 'add'}
                        color="outline-black"
                        className={buttonClasses}
                        labelPadding={false}
                        onClick={() => {
                          if (!auth.isAuthenticated) {
                            alert('Not authenticated');
                            return;
                          }

                          isSavedInLibrary
                            ? confirmRemoveButtonFunction(code!)
                            : saveToLibraryFunction(code!);
                        }}
                        disabled={isLoadingSavedInLibrary}
                      />

                      {dropdownItems && dropdownItems.length > 0 && (
                        <DropdownMenu
                          button={({ isOpen }) => (
                            <Button
                              propertyName="ListenOnLabel"
                              size="short"
                              label={listenOnLabel}
                              iconRight={isOpen ? 'chevron-up' : 'chevron-down'}
                              className={buttonClasses}
                              color="outline-black"
                            />
                          )}
                          align="start"
                          items={dropdownItems}
                        />
                      )}
                    </div>
                  </div>
                </Collapsible.Content>

                <Collapsible.Trigger asChild>
                  <Link
                    as="button"
                    variant="bold-link-with-icon"
                    text={collapseLabel}
                    icon={isExpanded ? 'chevron-up' : 'chevron-down'}
                  />
                </Collapsible.Trigger>
              </Collapsible.Root>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default PodcastListingSingle;
