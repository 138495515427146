import { MouseEvent } from 'react';
import { format } from 'date-fns';

import type { SnippetExpandedContentProps } from '@/components/ui/Snippet/SnippetExpandedContent';
import Link from '@/components/ui/Link';
import type {
  SnippetProps,
  SnippetPrimaryStatus,
  SnippetButtonProps
} from '@/components/ui/Snippet/Snippet';

import type { RenderSnippetProps } from './types';
import {
  getOnDemandThumbnail,
  getCreditRequestDetails,
  aggregateUserData,
  getIsSavedToLibrary,
  getInlineListProps,
  hasMultipleRecordings
} from './helpers';
import { SnippetMetadataProps } from '../../Snippet/SnippetMetadata';
import type { EyebrowProps } from '../../Eyebrow/Eyebrow';
import {
  getAccessMaterialsButton,
  getCreditRequestedButton,
  getNotifyMeAction,
  getRequestCreditsButton,
  getViewCertificatesButton,
  getViewCreditDetailsButton,
  getViewTranscriptButton,
  renderEcommPanelButtonWrapper
} from './buttons';
import { SnippetButtonLabel } from './buttonsLabels';

const renderOnDemandProgram = ({
  data,
  actions,
  modalPanelState,
  context,
  links,
  actionStatus = 'idle'
}: RenderSnippetProps): SnippetProps => {
  const {
    title = '',
    creditDetailsFormatted = [],
    summaryText,
    practiceAreas,
    industries,
    userList = [],
    datesFormatted,
    runTimeSeconds = 0,
    variationPk = '',
    url,
    accessExpirationDate,
    creditRequestStatus,
    certificateRequestUrl,
    creditRequestDate,
    plusUrl,
    isPrerelease: _isPrerelease,
    isOdProgramInPreSaleState,
    notifyMeUrl,
    enableNotifyMe,
    notificationDetails,
    itemAnalyticsModel
  } = data;
  const firstCreditDetailsFormatted = (creditDetailsFormatted || [])[0];

  const metadata: SnippetMetadataProps = {
    items: []
  };

  if (firstCreditDetailsFormatted) {
    metadata.items.push(
      getViewCreditDetailsButton(
        context,
        variationPk ?? '',
        firstCreditDetailsFormatted.keyword ?? '',
        itemAnalyticsModel
      )
    );
  }

  const isPrerelease =
    (context === 'search' && _isPrerelease && enableNotifyMe) ||
    (context === 'library' &&
      (isOdProgramInPreSaleState || (!!notificationDetails && !!notificationDetails?.fullUrl)));

  const userAggregatedData = aggregateUserData({ userList, variant: 'ondemandprogram' });
  const { cleProgressPct = 0 } = userAggregatedData || {};

  let isAccessExpired = false;
  const creditsRequestState = getCreditRequestDetails({
    cleProgressPct,
    creditRequestStatus: creditRequestStatus as unknown as string
  });

  if (runTimeSeconds) {
    if (runTimeSeconds >= 3600) {
      metadata.items.push(`${Math.round(runTimeSeconds / 3600)}-hour program`);
    } else {
      metadata.items.push(`${Math.floor(runTimeSeconds / 60)}-minute program`);
    }
  }

  if (context === 'library') {
    if (accessExpirationDate) {
      const expirationDate = new Date(accessExpirationDate);
      const today = new Date();

      if (expirationDate < today) {
        isAccessExpired = true;
      }

      if (isAccessExpired) {
        metadata.items.push(
          <span className="inline-flex text-red">
            Access expired on {format(expirationDate, 'MMMM dd, yyyy')}
            {creditsRequestState === 'credits-available' && (
              <>
                &nbsp;- Please &nbsp;
                <Link text="contact us" href="#" className="text-red" as="a" /> &nbsp; to request
                credit.
              </>
            )}
          </span>
        );
      } else {
        metadata.items.push(`Access expires on ${format(expirationDate, 'MMMM dd, yyyy')}`);
      }
    }
  }

  if (context !== 'library' && datesFormatted) {
    metadata.items.push(datesFormatted);
  }

  const expandedContent: SnippetExpandedContentProps = { data: [], expandLink: url || '' };

  if (summaryText) {
    expandedContent.data.push({
      title: 'Overview',
      content: summaryText
    });
  }

  if (practiceAreas?.length) {
    expandedContent.data.push({
      title: 'Topics',
      inlineList: practiceAreas.map(practiceArea => (
        /* Ideally, if href is empty, the link should be omitted */
        <Link {...getInlineListProps(practiceArea)} />
      ))
    });
  }

  if (industries?.length) {
    expandedContent.data.push({
      title: 'Industries',
      inlineList: industries.map(industry => (
        /* Ideally, if href is empty, the link should be omitted */
        <Link {...getInlineListProps(industry)} />
      ))
    });
  }

  let userPrimaryStatus: SnippetPrimaryStatus | null = 'not-purchased';

  if (!userAggregatedData?.isPurchaseRequired) {
    userPrimaryStatus = null;
  }

  if (creditsRequestState) {
    userPrimaryStatus = creditsRequestState;
  }

  if (isAccessExpired) {
    userPrimaryStatus = 'expired';
  }

  const onSaveToLibrary = actions.addToLibrary;
  const onRemoveFromLibrary = (e?: MouseEvent<HTMLButtonElement>) => {
    const siblingVariantPks =
      context === 'search'
        ? userList?.filter(getIsSavedToLibrary).map(u => u.pk ?? '')
        : [variationPk ?? ''];
    actions.removeFromLibrary.bind(e, siblingVariantPks)();
  };

  let primaryCallback = !userAggregatedData?.isPurchaseRequired ? actions.launch : actions.buy;

  let primaryCtaWrapper: SnippetButtonProps['renderButtonWrapper'] =
    !userAggregatedData?.isPurchaseRequired
      ? hasMultipleRecordings(userList)
        ? renderEcommPanelButtonWrapper(context, data, 'StartWatching')
        : undefined
      : renderEcommPanelButtonWrapper(context, data, 'BuyNow');

  let primaryCTALabel = SnippetButtonLabel.buyNow;
  let primaryCTAIcon = 'chevron-right';

  if (isPrerelease) {
    primaryCTALabel = SnippetButtonLabel.notifyMe;
    primaryCTAIcon = 'email';
    primaryCallback = getNotifyMeAction(
      context,
      variationPk ?? '',
      context == 'library' ? (notificationDetails?.fullUrl ?? null) : (notifyMeUrl ?? null),
      itemAnalyticsModel
    );
    primaryCtaWrapper = undefined;
  } else if (!userAggregatedData?.isPurchaseRequired) {
    let watchProgressCtaLabel = SnippetButtonLabel.buyNow;
    let watchProgressCtaIcon = 'chevron-right';
    switch (userAggregatedData?.playbackProgressPct) {
      case 0:
        watchProgressCtaLabel = SnippetButtonLabel.startWatching;
        watchProgressCtaIcon = 'play';
        break;
      case 100:
        watchProgressCtaLabel = SnippetButtonLabel.watchAgain;
        watchProgressCtaIcon = 'redo';
        break;
      default:
        watchProgressCtaLabel = SnippetButtonLabel.continueWatching;
        watchProgressCtaIcon = 'play';
        break;
    }
    primaryCTALabel = watchProgressCtaLabel;
    primaryCTAIcon = watchProgressCtaIcon;
  }

  const thumbnail = getOnDemandThumbnail({
    variant: 'ondemandprogram',
    data,
    userInfo: { playbackProgressPct: userAggregatedData?.playbackProgressPct || 0 },
    isPurchaseRequired: userAggregatedData?.isPurchaseRequired ?? true,
    isAccessExpired,
    isPrerelease,
    action: primaryCallback,
    renderButtonWrapper: primaryCtaWrapper
  });

  const buttons: SnippetButtonProps[] = [
    {
      color:
        context === 'library' &&
        (userPrimaryStatus === 'certificates-available' ||
          userPrimaryStatus === 'credits-requested' ||
          (userPrimaryStatus === 'credits-available' && cleProgressPct === 100))
          ? 'outline-black'
          : 'black', // "View Certificates" as well as "Credits Requested" have higher priority as the Primary CTA
      label: primaryCTALabel,
      onClick: primaryCallback,
      renderButtonWrapper: primaryCtaWrapper,
      size: 'short',
      iconRight: primaryCTAIcon
    }
  ];

  const creditsRequestedEyebrows: EyebrowProps[] = [];

  if (context === 'search') {
    buttons.push({
      color: 'outline-black',
      label: userAggregatedData?.isSavedToLibrary
        ? SnippetButtonLabel.savedToLibrary
        : SnippetButtonLabel.saveToLibrary,
      onClick: userAggregatedData?.isSavedToLibrary ? onRemoveFromLibrary : onSaveToLibrary,
      actionStatus,
      size: 'short',
      iconRight: userAggregatedData?.isSavedToLibrary ? 'check' : 'add'
    });
  } else if (context === 'library') {
    if (userPrimaryStatus === 'credits-available') {
      const requestCreditsButton = getRequestCreditsButton(
        context,
        variationPk ?? '',
        certificateRequestUrl,
        cleProgressPct,
        itemAnalyticsModel,
      );
      if (requestCreditsButton) {
        buttons.push(requestCreditsButton);
      }
      creditsRequestedEyebrows.push({
        label: 'Credit for request',
        icon: 'credits',
        color: cleProgressPct < 100 ? undefined : 'green'
      });
    } else if (userPrimaryStatus === 'credits-requested') {
      buttons.push(getCreditRequestedButton(creditRequestDate));
      if (creditRequestDate) {
        const creditsRequestedDateFormatted = format(new Date(creditRequestDate), 'MMM d, yyyy');
        creditsRequestedEyebrows.push({
          label: `Credits requested on ${creditsRequestedDateFormatted}`,
          icon: 'credits'
        });
      }
    } else if (userPrimaryStatus === 'certificates-available') {
      buttons.push(getViewCertificatesButton(context, variationPk ?? '', links.Certificates, itemAnalyticsModel));
    }
    if (userAggregatedData?.isPurchased) {
      buttons.push(
        getAccessMaterialsButton(context, variationPk ?? '', title ?? '', actions.accessMaterials)
      );
      if (plusUrl) {
        buttons.push(getViewTranscriptButton(context, variationPk ?? '', plusUrl, itemAnalyticsModel));
      }
    }
    if (userPrimaryStatus === 'expired') {
      buttons.splice(0, buttons.length);
      if (creditsRequestState === 'certificates-available') {
        buttons.push(getViewCertificatesButton(context, variationPk ?? '', links.Certificates,itemAnalyticsModel));
      }
    }
  }

  const menuItems = [];
  if (context === 'library') {
    menuItems.push({
      label: SnippetButtonLabel.removeFromLibrary,
      onClick: () => {
        modalPanelState.deleteModal.show({ onConfirm: onRemoveFromLibrary });
      },
      icon: 'delete',
      menuStyle: 'standalone' as const
    });
  }

  return {
    variant: 'on-demand' as const,
    pk: variationPk ?? '',
    title: title ?? '',
    metadata,
    menuItems,
    buttons,
    expandedContent,
    thumbnail,
    link: url || '',
    primaryStatus: userPrimaryStatus,
    secondaryStatus: userAggregatedData?.isSavedToLibrary ? 'saved-to-library' : null,
    eyebrows: creditsRequestedEyebrows
  };
};

export default renderOnDemandProgram;
