import Button from '@/components/ui/Buttons/Button';
import classnames from 'classnames';
import RichText from '@/components/cms/RichText';
import {
  LargeCarouselBgColorClasses,
  LargeCarouselColor,
  LargeCarouselItemProps
} from '@/components/blocks/LargeCarousel/utils/constants';
import { getThumbnailImageUrl } from '@/components/ui/SearchResults/Snippets/helpers';
import { useGetImageUrlQuery, useGetProductByCodeQuery } from '@/redux/api/client/product';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useEffect, useMemo, useState } from 'react';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import ResponsiveImage from '@/components/ui/ResponsiveImage';
import { LargeCarouselGetCardColor } from './utils/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

interface LargeCarouselSlideFiftyProps {
  item: LargeCarouselItemProps;
  loadEagerly?: boolean;
}

const LargeCarouselSlideFifty: React.FC<LargeCarouselSlideFiftyProps> = ({
  item: itemData,
  loadEagerly
}) => {
  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

  const lg = useMediaQuery(ScreenSizeQueries.lg);
  const [isRegisteredProduct, setIsRegisteredProduct] = useState<boolean>(false);
  const [badgeText, setBadgeText] = useState<string>();

  const item = itemData as LargeCarouselItemProps;
  const heading = item?.heading || item?.displayName || undefined;
  const copy = item?.copy || item?.sellingPoints || undefined;
  let link = item?.link || undefined;
  const imageUrl = item?.slideImage?.url || getThumbnailImageUrl(item?.code);
  const mobileImageUrl = item?.mobileImage?.url;
  const code = item?.code || undefined;

  const imageSrc = useMemo(() => {
    if (lg) {
      return imageUrl || mobileImageUrl;
    }
    return mobileImageUrl || imageUrl;
  }, [lg, imageUrl, mobileImageUrl]);

  let parentProductCode: string = '';

  if ('parentProductCode' in item) {
    parentProductCode = item?.parentProductCode as string;
  }

  const { data } = useGetProductByCodeQuery(parentProductCode, { skip: code === undefined });

  useEffect(() => {
    if ('isRegistered' in itemData && itemData.isRegistered) {
      setIsRegisteredProduct(true);
    }

    if (isRegisteredProduct) {
      const registeredLiveOnline =
        data?.[0].userList?.find(ul => ul.isPurchased)?.registrationDetails?.format ===
        'LiveWebcast';

      setBadgeText(`Registered, ${registeredLiveOnline ? 'Online' : 'In-Person'}`);
    }
  }, [itemData, data, isRegisteredProduct]);

  const badgeClasses = classnames('text-2 text-white');

  let backgroundColor = 'bg-charcoal';

  const { data: colorVariant } = useGetImageUrlQuery(code || '', {
    skip: !code
  });

  if (item.contentType?.at(0)?.toLocaleLowerCase() === 'variation' && item.code) {
    if (!link) {
      link = {
        href: item.url,
        text: stateSiteLabels?.seeDetailsLabel
      };
    }

    if (colorVariant) {
      const color = LargeCarouselGetCardColor(colorVariant);
      const bgColor = LargeCarouselBgColorClasses[color as LargeCarouselColor];
      if (bgColor) {
        backgroundColor = bgColor;
      }
    }
  }

  return (
    <div
      className={classnames(
        'flex flex-col gap-8 overflow-hidden rounded-lg',
        backgroundColor,
        'sm:gap-3',
        'lg:flex-row'
      )}
    >
      <div className={classnames('flex w-full flex-col gap-6 px-6 pt-6', 'lg:p-10')}>
        <div
          className={classnames(
            'flex flex-col gap-4',
            'sm:max-w-[394px]',
            'lg:max-w-[412px] lg:p-[10px]'
          )}
        >
          {isRegisteredProduct && (
            <div className="flex h-5 items-center gap-2">
              <Icon size="medium" name="checkmark" className={badgeClasses} />
              <Text className={badgeClasses}>{badgeText}</Text>
            </div>
          )}
          {heading && (
            <h2 className={classnames('heading-5 text-white', 'lg:max-w-[310px]')}>{heading}</h2>
          )}
          {copy && <RichText className="text-1 text-white" content={copy} />}
        </div>

        {link && (
          <div className="mt-auto">
            <Button
              href={link?.href}
              label={link?.text}
              target={link?.target}
              color="outline-white"
              size="large"
            />
          </div>
        )}
      </div>

      <div className={classnames('w-full py-[34px]', 'sm:py-[75px]', 'lg:flex-[0_0_50%] lg:py-0')}>
        <ResponsiveImage
          className="size-full object-cover"
          src={imageSrc}
          alt={heading}
          loading={loadEagerly ? 'eager' : 'lazy'}
          aspectRatio={1.5}
          skipWrapper={true}
          imageSizes={{
            xs: '550px',
            sm: '840px',
            md: '1104px',
            lg: '760px'
          }}
        />
      </div>
    </div>
  );
};

export default LargeCarouselSlideFifty;
