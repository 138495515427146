import React, { useEffect } from 'react';
import classnames from 'classnames';
import Icon from '../Icon';
import FilterButton from '../Buttons/FilterButton';
import TagButton from '../Buttons/TagButton';
import ComplexTooltip from '../Tooltip/ComplexTooltip';
import SearchFooter from '../SearchOverlay/SearchFooter';
import { FilterButtonGroupProps, ToggleFilterPayload } from './types';
import { SECTIONS } from './constants';
import Shimmer from '../Shimmer';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import FindAProgramModal from './FindAProgramModal';
import { MappedFilterItem } from '../Filter/types';
import {
  preserveFiltersOrder,
  updatePreservedFilterGroupOrder
} from '../SearchResults/utils/filters';
import usePrevious from '@/hooks/usePrevious';
import { FILTERS } from '../SearchResults/constants';
import { FilterContext, SearchContext } from '@/analytics/constants';

const DefaultTitle = 'Refine your search using the filters below';
const DefaultToolTip =
  'You can select one or multiple filters.\n\nDoing so will help us further target your results.';

export interface FindAProgramButtonsProps {
  className?: string;
  footerBackgroundColor?: string;
  title?: string;
  tooltip?: string;
  filterGroups?: FilterButtonGroupProps[];
  onToggleFilter?: (payload: ToggleFilterPayload) => void;
  onClearAll?: () => void;
  onSearch: () => void;
  loading?: boolean;
  fetching?: boolean;
  suggestions?: JSX.Element;
  footerFixed?: boolean;
  context?: FilterContext;
  searchContext?: SearchContext;
}

const FindAProgramButtons: React.FC<FindAProgramButtonsProps> = ({
  className,
  footerBackgroundColor = 'white',
  title = DefaultTitle,
  tooltip = DefaultToolTip,
  filterGroups,
  onToggleFilter,
  onClearAll,
  onSearch,
  loading,
  fetching,
  suggestions,
  footerFixed,
  context,
  searchContext
}) => {
  const [contentContainer, setContentContainer] = React.useState<HTMLDivElement | null>(null);
  const largeAlignmentClassNames = classnames('lg:my-0 lg:ml-0', { 'lg:pl-20': footerFixed });

  const [preservedFilterGroupOrder, setPreservedFilterGroupOrder] = React.useState<
    Record<string, MappedFilterItem[]>
  >({});
  const prevGroups = usePrevious(filterGroups);
  useEffect(() => {
    filterGroups?.forEach(group => {
      setPreservedFilterGroupOrder(prev => {
        // Clear saved credit type order on jurisdiction data change
        if (group.groupTypeId === FILTERS.CREDIT_TYPE) {
          const prevJurisdictions = prevGroups?.find(
            group => group.groupTypeId === FILTERS.JURISDICTIONS
          );
          const currentJurisdictions = filterGroups?.find(
            group => group.groupTypeId === FILTERS.JURISDICTIONS
          );

          if (JSON.stringify(prevJurisdictions) !== JSON.stringify(currentJurisdictions)) {
            return {
              ...prev,
              [group.groupTypeId]: group.filters
            };
          }
        }

        return {
          ...prev,
          [group.groupTypeId]: updatePreservedFilterGroupOrder(
            prev[group.groupTypeId],
            group.filters
          )
        };
      });
    });
  }, [prevGroups, filterGroups]);

  const isMobile = useMediaQuery(ScreenSizeQueries.xsOnly);

  return (
    <div className={classnames('relative size-full', className)}>
      <div
        className={classnames({
          'absolute inset-x-0 bottom-[72px] top-0 overflow-auto md:bottom-[96px]': footerFixed
        })}
      >
        {suggestions}

        {title && (
          <div
            className={classnames('pb-12', largeAlignmentClassNames, {
              'pt-10 max-lg:container md:pt-14': footerFixed
            })}
          >
            {loading ? (
              <Shimmer className="h-10 max-sm:w-full sm:w-[410px]" />
            ) : (
              <>
                <h2 className="heading-6-medium mr-2 inline">{title}</h2>
                <ComplexTooltip>
                  <ComplexTooltip.Trigger>
                    <Icon name="info" size="medium" className="mb-[-2px] text-gray" />
                  </ComplexTooltip.Trigger>

                  <ComplexTooltip.Content
                    closeLabel="Close"
                    sideOffset={8}
                    customContainer={contentContainer}
                  >
                    <div className="text-2 flex flex-col gap-1">
                      {tooltip.split('\n\n').map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                    </div>
                  </ComplexTooltip.Content>
                </ComplexTooltip>
              </>
            )}

            <div ref={setContentContainer} />
          </div>
        )}

        <div
          className={classnames('mb-12 max-w-screen-2xl', largeAlignmentClassNames, {
            'max-lg:container': footerFixed
          })}
        >
          {filterGroups?.map((group: FilterButtonGroupProps, groupIndex: number) => {
            const maxCount = SECTIONS.find(section => section.id === group.groupTypeId)?.maxCount;
            const preservedFiltersOrder = preserveFiltersOrder(
              preservedFilterGroupOrder[group.groupTypeId],
              group.filters
            );
            const visibleFilters = maxCount
              ? preservedFiltersOrder.slice(0, maxCount)
              : preservedFiltersOrder;
            const hasMore = group?.filters?.length > visibleFilters.length;

            return (
              <div key={groupIndex} className="mb-10">
                {loading ? (
                  <Shimmer className="mb-3 h-5 w-44 rounded-full" />
                ) : (
                  <h3 className="text-1 mb-3 font-bold">{group.title}</h3>
                )}

                {loading ? (
                  <div className="flex gap-3">
                    {Array.from({ length: isMobile ? 1 : 2 }).map((_, index) => (
                      <Shimmer key={index} className="h-11 w-48 rounded-2xl" />
                    ))}
                  </div>
                ) : (
                  <>
                    <div
                      className="flex flex-wrap gap-2"
                      role="group"
                      aria-labelledby={`group-${groupIndex}`}
                    >
                      {visibleFilters.map(filter => (
                        <FilterButton
                          key={filter.value}
                          label={filter.label}
                          isSelected={filter.checked}
                          onClick={() =>
                            onToggleFilter?.({
                              groupTypeId: group.groupTypeId,
                              value: filter.value,
                              name: filter.label
                            })
                          }
                          aria-label={`${filter.label} ${filter.checked ? 'selected' : 'not selected'}`}
                          disabled={filter.disabled}
                          fetching={fetching}
                        />
                      ))}
                      {group.addButtonLabel && (
                        <FindAProgramModal
                          groupTypeId={group.groupTypeId}
                          fetching={fetching}
                          context={context}
                          searchContext={searchContext}
                          triggerButton={
                            visibleFilters.length ? (
                              <TagButton
                                icon="add"
                                aria-label={group.addButtonLabel}
                                aria-describedby={`group-${groupIndex}`}
                              />
                            ) : (
                              <TagButton iconLeft="add" label={group.addButtonLabel} />
                            )
                          }
                        />
                      )}
                    </div>
                    <FindAProgramModal
                      groupTypeId={group.groupTypeId}
                      fetching={fetching}
                      filterGroups={filterGroups}
                      context={context}
                      searchContext={searchContext}
                      triggerButton={
                        hasMore ? (
                          <button type="button" className="text-link text-2 mt-3 underline">
                            View all
                          </button>
                        ) : null
                      }
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <footer
        className={classnames('h-[72px] md:h-[96px]', {
          'absolute inset-x-0 bottom-0 max-lg:container lg:px-20': footerFixed
        })}
        style={{ backgroundColor: footerBackgroundColor }}
      >
        <SearchFooter filterGroups={filterGroups} onClearAll={onClearAll} onSearch={onSearch} />
      </footer>
    </div>
  );
};

export default FindAProgramButtons;
