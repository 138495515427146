import { ConfigureStoreOptions, configureStore } from '@reduxjs/toolkit';

import authSlice from '@/redux/slices/authSlice';
import pdpSlice from '@/redux/slices/pdpSlice';
import { ClientAPI } from '@/redux/api';
import { contentDeliveryApi } from '@/redux/api/contentDeliveryAPI';
import { setupListeners } from '@reduxjs/toolkit/query';
import searchSlice from './slices/searchSlice';
import pageSlice from './slices/pageSlice';
import librarySlice from './slices/librarySlice';
import findAProgramSlice from './slices/findAProgramSlice';
import creditTrackerSlice from './slices/creditTrackerSlice';
import userProfileSlice from './slices/userProfileSlice';
import creditTrackerAdminSlice from './slices/creditTrackerAdminSlice';

// Keep this in sync with the initial state
// We have to split them out for typescript to work properly
export const initialStoreForTesting: ConfigureStoreOptions = {
  reducer: {
    auth: authSlice,
    search: searchSlice,
    findAProgram: findAProgramSlice,
    library: librarySlice,
    pdp: pdpSlice,
    page: pageSlice,
    creditTracker: creditTrackerSlice,
    creditTrackerAdmin: creditTrackerAdminSlice,
    userProfile: userProfileSlice,
    [contentDeliveryApi.reducerPath]: contentDeliveryApi.reducer,
    [ClientAPI.reducerPath]: ClientAPI.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(contentDeliveryApi.middleware, ClientAPI.middleware)
};

export const store = configureStore({
  reducer: {
    auth: authSlice,
    search: searchSlice,
    findAProgram: findAProgramSlice,
    library: librarySlice,
    pdp: pdpSlice,
    page: pageSlice,
    creditTracker: creditTrackerSlice,
    creditTrackerAdmin: creditTrackerAdminSlice,
    userProfile: userProfileSlice,
    [contentDeliveryApi.reducerPath]: contentDeliveryApi.reducer,
    [ClientAPI.reducerPath]: ClientAPI.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(contentDeliveryApi.middleware, ClientAPI.middleware)
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
