import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Icon from '@/components/ui/Icon';
import { type SwiperOptions } from 'swiper/types';
import { SwiperContainer, register } from 'swiper/element/bundle';
import { setLogoColor } from '@/redux/slices/pageSlice';
import throttle from 'lodash/throttle';
import classnames from 'classnames';
import RoundedIcon from '@/components/ui/RoundedIcon';
import { useDispatch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import React from 'react';
import { appInsights } from '@/lib/ApplicationInsightsService';

interface HomeCarouselProps {
  children: React.ReactNode[];
  autoplayed?: boolean;
  autoplayedDuration?: number;
  itemLogos?: string[];
}

const HomeCarousel: React.FC<HomeCarouselProps> = ({
  children,
  autoplayed = true,
  autoplayedDuration = 5000,
  itemLogos
}) => {
  const dispatch = useDispatch();

  const autoplayDelay = autoplayedDuration * 1000;
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPrevVisible, setIsPrevVisible] = useState(false);
  const [isNextVisible, setIsNextVisible] = useState(false);
  const [sliderPaused, setSliderPaused] = useState(false);
  const [activePercentage, setActivePercentage] = useState(0);
  const autoplayTimeLeft = useRef(0);
  const autoplayTimeLeftMiliseconds = useRef(0);

  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  const navPrevId = `nav-prev-button`;
  const navNextId = `nav-next-button`;
  const childrenCount: number[] = [];

  const handleMouseMove = throttle((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const container = containerRef.current as HTMLDivElement | null;
    if (container) {
      const rect = container.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;

      if (mouseX < rect.width / 2) {
        setIsPrevVisible(true);
        setIsNextVisible(false);
      } else {
        setIsPrevVisible(false);
        setIsNextVisible(true);
      }
    }
  }, 100);

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsPrevVisible(false);
      setIsNextVisible(false);
    }, 250);
  };

  const navClasses = classnames(
    'group/navBtn',
    'absolute top-[55%] z-1 hidden w-[60px] -translate-y-1/2 overflow-hidden rounded-full opacity-0 backdrop-blur-[20px] transition-opacity',
    'disabled:pointer-events-none disabled:opacity-0',
    'hover:opacity-100',
    'lg:flex'
  );

  const navIconClasses = classnames(
    'w-[60px] bg-gray/40 transition-colors',
    'group-hover/navBtn:bg-gray-darker group-focus/navBtn:bg-gray-darker'
  );

  const prevClasses = classnames(navPrevId, navClasses, 'left-[30px]', {
    'opacity-0': !isPrevVisible,
    'opacity-100': isPrevVisible
  });

  const nextClasses = classnames(navNextId, navClasses, 'right-[30px]', {
    'opacity-0': !isNextVisible,
    'opacity-100': isNextVisible
  });

  const sliderBulletsClasses = classnames(
    'relative size-2 overflow-hidden rounded-full hover:cursor-pointer'
  );

  const bulletsBeforeClasses = classnames('absolute left-0 top-0 size-2 rounded-full bg-white');

  const bulletsAfterClasses = classnames(
    'absolute left-0 top-0 size-full rounded-full bg-white opacity-20 backdrop-blur-md'
  );

  useEffect(() => {
    return () => {
      dispatch(setLogoColor('red'));
    };
  }, [dispatch]);

  useLayoutEffect(() => {
    if (!swiperRef.current) return;
    register();

    const params: SwiperOptions = {
      slidesPerView: 1,
      speed: 800,
      effect: 'easy',
      loop: true,
      a11y: {
        slideLabelMessage: ''
      },
      navigation: {
        enabled: true,
        prevEl: `.${navPrevId}`,
        nextEl: `.${navNextId}`
      },
      on: {
        init: _ => {
          if (itemLogos![0] === 'white') {
            dispatch(setLogoColor('white'));
          } else {
            dispatch(setLogoColor('red'));
          }
        },
        autoplayTimeLeft: (_swiper, timeLeft, percentage) => {
          const percentagePassed = Math.floor((1 - percentage) * 80 + 20);
          if (autoplayTimeLeft.current !== percentagePassed) {
            autoplayTimeLeft.current = percentagePassed;
            autoplayTimeLeftMiliseconds.current = timeLeft;
            const percentageBar = percentagePassed;
            setActivePercentage(percentageBar);
          }
        },
        realIndexChange: swiper => {
          const currentSwiper = swiper;
          const currentSwiperUnknown = currentSwiper as unknown as Record<string, unknown>;
          const currentSlide = currentSwiper.realIndex;
          const videoSlidePosition = currentSwiper.activeIndex;
          const previousSlide = currentSwiperUnknown.previousRealIndex as number;
          setActiveIndex(currentSwiper.realIndex || 0);

          const previousVideo = currentSwiper?.slides[previousSlide]?.querySelector('video');
          if (previousVideo?.pause) {
            previousVideo.pause();
          }

          const currentVideo = currentSwiper?.slides[videoSlidePosition]?.querySelector('video');
          if (currentVideo?.play) {
            currentVideo.play().catch(e => appInsights.trackException(e));
          }

          const previousPosition = document.querySelector(`#nav-item-${previousSlide}`);
          const previousPositionBefore = document.querySelector(`#before-${previousSlide}`);
          if (previousPosition) {
            previousPosition?.classList.remove('w-10');
          }
          if (previousPositionBefore) {
            previousPositionBefore?.classList.add('hidden');
          }

          const currentPosition = document.querySelector(`#nav-item-${currentSlide}`);
          const currentPositionBefore = document.querySelector(`#before-${currentSlide}`);
          if (currentPosition) {
            currentPosition?.classList.add('w-10');
          }
          if (currentPositionBefore) {
            currentPositionBefore?.classList.remove('hidden');
          }

          if (itemLogos![currentSlide] === 'white') {
            dispatch(setLogoColor('white'));
          } else {
            dispatch(setLogoColor('red'));
          }
        }
      }
    };

    const handleResize = () => {
      currentSwiper.swiper.update();
    };

    window.addEventListener('resize', handleResize);

    if (autoplayed) {
      Object.assign(params, {
        autoplay: {
          delay: autoplayDelay,
          disableOnInteraction: false
        }
      });
    }

    Object.assign(swiperRef.current, params);

    const currentSwiper = swiperRef.current as SwiperContainer;
    currentSwiper.initialize();
    if (autoplayed && !sliderPaused) {
      currentSwiper.swiper.autoplay.start();
    } else {
      currentSwiper.swiper.autoplay.stop();
    }
    return () => {
      currentSwiper?.swiper?.autoplay?.stop();
      window.removeEventListener('resize', handleResize);
    };
  }, [autoplayed, autoplayDelay, navNextId, navPrevId, sliderPaused, itemLogos, dispatch]);

  const toggleSlider = () => {
    setSliderPaused(prevState => !prevState);
  };

  const goToSlide = (index: number) => {
    if (!swiperRef.current) return;
    const currentSwiper = swiperRef.current as SwiperContainer;

    currentSwiper?.swiper?.slideTo(index);
  };

  return (
    <div
      ref={containerRef}
      className="relative mt-[-161px] size-full md:mt-[-95px] lg:mt-[-95px]"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      tabIndex={0}
      data-component={"HomeCarousel"}
    >
      {children?.length > 1 ? (
        <>
          <swiper-container init={false} ref={swiperRef}>
            {children.map((item, index) => {
              childrenCount.push(index);
              return (
                <ErrorBoundary key={index} fallback={<h1>{`Slide {item??""} failed`}</h1>}>
                  <swiper-slide
                    key={index}
                    class="flex h-auto min-h-[600px] sm:min-h-[809px] lg:min-h-[550px]"
                    aria-live="polite"
                  >
                    {item}
                  </swiper-slide>
                </ErrorBoundary>
              );
            })}
          </swiper-container>
          <div className="absolute bottom-6 right-0 z-1 w-1/2 lg:w-full">
            <div className="container flex items-center justify-end gap-4 lg:justify-start">
              <button
                onClick={toggleSlider}
                className="relative flex size-8 items-center justify-center overflow-hidden rounded-full before:absolute before:left-0 before:top-0 before:size-full before:bg-white before:opacity-20 before:backdrop-blur-md focus-visible:outline-white"
                id="btn-control"
                aria-label={sliderPaused ? 'Play' : 'Pause' + 'Button'}
              >
                <Icon name={sliderPaused ? 'play' : 'pause'} size="small" className="text-white" />
              </button>
              <div className={classnames('flex gap-[11px]')}>
                {childrenCount.map(index => (
                  <div
                    className={classnames(sliderBulletsClasses, {
                      'w-10': index === activeIndex,
                      'before:opacity-20 before:backdrop-blur-md': index !== activeIndex
                    })}
                    key={index}
                    id={`nav-item-${index}`}
                    onClick={() => goToSlide(index)}
                  >
                    <div
                      id={`before-${index}`}
                      className={classnames(bulletsBeforeClasses, {
                        hidden: index !== activeIndex
                      })}
                      style={{ width: `${activePercentage}%`, transition: 'width 0.01s ease' }}
                    ></div>
                    <div className={classnames(bulletsAfterClasses)}></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button className={prevClasses}>
            <RoundedIcon
              color="black"
              aria-label="Back Button"
              icon={{ name: 'arrow-left', size: 'large' }}
              className={navIconClasses}
            />
          </button>
          <button className={nextClasses}>
            <RoundedIcon
              color="black"
              aria-label="Next Button"
              icon={{ name: 'arrow-right', size: 'large' }}
              className={navIconClasses}
            />
          </button>
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default HomeCarousel;
