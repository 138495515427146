import { useEffect } from 'react';

import { useSearchParamsStable } from '@/hooks/useSearchParams';
import useToast from '@/hooks/useToast';

import {
  delayedActionMessageBuilder,
  delayedActionsErrorMessages
} from '@/redux/helpers/delayedActionsMapper';

const useSearchDelayedActionToasts = () => {
  const { searchParams, setSearchParams } = useSearchParamsStable();
  const { showSuccessMessage, showFailMessage } = useToast();

  useEffect(() => {
    if (searchParams.has('successAction')) {
      const delayedAction = searchParams.get('successAction');
      const program = searchParams.get('program');
      let response = null;
      if (searchParams.has('response')) {
        try {
          response = JSON.parse(searchParams.get('response') ?? '{}');
        } catch {
          response = {};
        }
      }
      const { cartUrl = null } = response || {};
      if (delayedAction && program) {
        const { title, url } = JSON.parse(decodeURIComponent(program));
        const successMessage = delayedActionMessageBuilder[delayedAction]?.({
          title,
          url: delayedAction === 'postCartItem' ? cartUrl : url
        });
        showSuccessMessage(successMessage);
      }

      setSearchParams(
        existing => {
          existing.delete('successAction');
          existing.delete('program');
          existing.delete('response');
          return existing;
        },
        { replace: true, preventScrollReset: true }
      );
    } else if (searchParams.has('errorAction')) {
      const delayedAction = searchParams.get('errorAction');
      if (delayedAction) {
        const errorMessage = delayedActionsErrorMessages[delayedAction];
        showFailMessage(errorMessage);
      }
      setSearchParams(
        existing => {
          existing.delete('errorAction');
          return existing;
        },
        { replace: true, preventScrollReset: true }
      );
    }
  }, [searchParams, showSuccessMessage, showFailMessage, setSearchParams]);
};

export default useSearchDelayedActionToasts;
