import { useCallback, useMemo, useState } from 'react';
import {
  useDeleteLicenseMutation,
  useGetLicensesQuery,
  usePostLicenseMutation,
  useUpdateLicenseMutation
} from '@/redux/api/client/userLicenses';
import { useGetCreditJurisdictionsQuery } from '@/redux/api/client/cle';
import {
  findCreditJurisdiction,
  getAddLicenseBody,
  getComplianceGroupOptions,
  getCreditTypeOptions,
  getEnrichedLicenseData,
  getJurisdictionsOptions,
  getLicenseNumberSettings,
  LicenseData,
  validateRequiredFields
} from '../SearchModals/utils/licenses';
import useToast from '@/hooks/useToast';
import { useAuth } from 'react-oidc-context';
import { deleteLicenseNudgeCookies } from './hooks/useLicenseNudge';
import {
  trackAddLicenseEvent,
  trackEditLicenseEvent,
  trackRemoveLicenseEvent
} from '@/analytics/licenses';
import { AnalyticsContext } from '@/analytics/constants';

export const useGetLicenseData = () => {
  const [licenseData, setLicenseData] = useState<LicenseData | undefined>(undefined);
  const [validationFailed, setValidationFailed] = useState(false);

  const auth = useAuth();
  const {
    data: licenses,
    isLoading: isLoadingLicenses,
    isError: isErrorLicenses,
    refetch: refetchLicenses
  } = useGetLicensesQuery(undefined, { skip: !auth.isAuthenticated });

  const {
    data: creditJurisdictions,
    isLoading: isLoadingCreditJurisdictions,
    isError: isErrorCreditJurisdictions
  } = useGetCreditJurisdictionsQuery();

  const availableCreditJurisdictions = useMemo(() => {
    return creditJurisdictions?.filter(
      creditJurisdiction =>
        !licenses?.items?.find(
          license =>
            license.creditClass_SK === creditJurisdiction.creditClass_SK &&
            license.creditRegion_SK === creditJurisdiction.creditRegion_SK
        )
    );
  }, [creditJurisdictions, licenses]);

  const licenseNumberSettings = useMemo(
    () =>
      getLicenseNumberSettings(
        availableCreditJurisdictions,
        licenseData?.creditClass_SK,
        licenseData?.creditRegion_SK
      ),
    [availableCreditJurisdictions, licenseData]
  );

  const creditTypeOptions = useMemo(
    () => getCreditTypeOptions(availableCreditJurisdictions),
    [availableCreditJurisdictions]
  );

  const jurisdictionOptions = useMemo(
    () => getJurisdictionsOptions(availableCreditJurisdictions, licenseData?.creditClass_SK),
    [availableCreditJurisdictions, licenseData]
  );

  const complianceGroupOptions = useMemo(
    () =>
      getComplianceGroupOptions(
        availableCreditJurisdictions,
        licenseData?.creditClass_SK,
        licenseData?.creditRegion_SK
      ),
    [availableCreditJurisdictions, licenseData]
  );

  const creditJurisdiction = useMemo(
    () =>
      findCreditJurisdiction(
        availableCreditJurisdictions,
        licenseData?.creditClass_SK,
        licenseData?.creditRegion_SK
      ),
    [availableCreditJurisdictions, licenseData]
  );

  const enrichedData = useMemo(
    () => getEnrichedLicenseData(licenseData, creditJurisdiction),
    [creditJurisdiction, licenseData]
  );

  const useOnDataChange = (data: LicenseData) => {
    setValidationFailed(false);
    setLicenseData(data);
  };

  const resetLicenseData = () => {
    setLicenseData(undefined);
  };

  const useOnValidateLicense = () => {
    const result = validateRequiredFields(enrichedData);

    if (!result) {
      setValidationFailed(true);
    }

    return result;
  };

  return {
    licenses,
    isLoading: isLoadingLicenses || isLoadingCreditJurisdictions,
    isError: isErrorLicenses || isErrorCreditJurisdictions,
    validationFailed,
    enrichedData,
    creditJurisdictions,
    availableCreditJurisdictions,
    creditTypeOptions,
    jurisdictionOptions,
    complianceGroupOptions,
    licenseNumberSettings,
    refetchLicenses,
    resetLicenseData,
    useOnDataChange,
    useOnValidateLicense
  };
};

export const useOnSaveLicense = ({
  setIsProcessing,
  setIsLicenseSaved,
  shouldRefetch = true,
  context
}: {
  setIsProcessing?: (isProcessing: boolean) => void;
  setIsLicenseSaved?: (isLicenceSaved: boolean) => void;
  shouldRefetch?: boolean;
  context?: AnalyticsContext;
} = {}) => {
  const { showSuccessMessage, showFailMessage } = useToast();
  const { refetchLicenses } = useGetLicenseData();

  const [postLicense] = usePostLicenseMutation();
  const [updateLicense] = useUpdateLicenseMutation();

  return useCallback(
    async (licenseData: LicenseData, isAddingNewLicense = true) => {
      setIsProcessing?.(true);
      try {
        if (isAddingNewLicense) {
          trackAddLicenseEvent({ context, value: licenseData.creditRegion ?? '' });
          await postLicense(getAddLicenseBody(licenseData)).unwrap();
        } else {
          trackEditLicenseEvent({ context, value: licenseData.creditRegion ?? '' });
          await updateLicense(licenseData).unwrap();
        }
        setIsLicenseSaved?.(true);
        shouldRefetch && (await refetchLicenses());
        showSuccessMessage(`License ${isAddingNewLicense ? 'added' : 'updated'}`);
      } catch (error) {
        showFailMessage('Error saving license');
      } finally {
        setIsProcessing?.(false);
      }
    },
    [
      context,
      setIsProcessing,
      setIsLicenseSaved,
      shouldRefetch,
      refetchLicenses,
      showSuccessMessage,
      postLicense,
      updateLicense,
      showFailMessage
    ]
  );
};

export const useOnSaveEditLicense = ({
  setIsProcessing,
  context
}: {
  setIsProcessing?: (isProcessing: boolean) => void;
  context?: AnalyticsContext;
} = {}) => {
  const handleOnSaveLicense = useOnSaveLicense({ setIsProcessing, context });

  const handleOnSaveEditLicense = useCallback(
    (licenseData: LicenseData) => {
      handleOnSaveLicense(licenseData, false);
    },
    [handleOnSaveLicense]
  );

  return handleOnSaveEditLicense;
};

export const useOnDeleteLicense = ({
  setIsProcessing,
  context
}: {
  setIsProcessing?: (isProcessing: boolean) => void;
  context?: AnalyticsContext;
} = {}) => {
  const [deleteLicense] = useDeleteLicenseMutation();
  const { refetchLicenses } = useGetLicenseData();
  const { showSuccessMessage, showFailMessage } = useToast();

  const handleOnDeleteLicense = useCallback(
    async (licenseData?: LicenseData) => {
      if (!licenseData) {
        return;
      }
      setIsProcessing?.(true);
      try {
        trackRemoveLicenseEvent({ context, value: licenseData.creditRegion ?? '' });
        await deleteLicense({ sk: licenseData.sk }).unwrap();
        await refetchLicenses();
        deleteLicenseNudgeCookies(licenseData);
        showSuccessMessage('License has been deleted');
      } catch (error) {
        showFailMessage('Error deleting license');
      } finally {
        setIsProcessing?.(false);
      }
    },
    [context, deleteLicense, refetchLicenses, setIsProcessing, showFailMessage, showSuccessMessage]
  );

  return handleOnDeleteLicense;
};
