import type { PageIntroBlock as PageIntroBlockProps } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';
import Text from '@/components/cms/Text';
import RichText from '../cms/RichText';
import classnames from 'classnames';
import Block from '../cms/Block';

const PageIntroBlock: React.FC<PageIntroBlockProps> = ({
  contentLink,
  heading,
  copy,
  iconBlocks = [],
  iconsTextHeading
}) => {
  const iconBlocksClasses = classnames('lg:w-[300px]', {
    'max-lg:mt-10': !iconsTextHeading
  });

  return (
    <Block contentLinkID={contentLink?.id} className="container module-spacing">
      <Text element="h2" propertyName="Heading" className="heading-2 mb-5">
        {heading}
      </Text>
      <div className="lg:flex lg:justify-between lg:gap-10">
        <RichText
          content={copy as string}
          propertyName="Copy"
          className={classnames('heading-5-regular mt-2', {
            'lg:w-[737px]': iconBlocks.length,
            'lg:w-[954px]': !iconBlocks.length
          })}
        />
        <div className="flex flex-col">
          {iconsTextHeading && (
            <Text
              propertyName="IconsTextHeading"
              className={classnames(
                'label max-w-[300px] border-b border-gray-light pb-2 text-gray-dark max-lg:mt-10'
              )}
            >
              {iconsTextHeading}
            </Text>
          )}
          <ContentArea
            propertyName="IconBlock"
            components={iconBlocks}
            className={iconBlocksClasses}
          />
        </div>
      </div>
    </Block>
  );
};

export default PageIntroBlock;
