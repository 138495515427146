import { PodcastEpisodeVariation as PodcastEpisodeVariationProps } from '@/@types/content';
import withNav from '@/components/hocs/withNav';
import Text from '@/components/cms/Text';
import ContentArea from '@/components/cms/ContentArea';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PodcastHeader from '@/components/ui/PDP/PodcastHeader';
import PodcastMediaPlayer from '@/components/ui/PDP/PodcastMediaPlayer';
import FeaturedGuests from '@/components/blocks/FeaturedGuests';
import { getDomainFromUrl, transformIfPLIUrl } from '@/utils/helpers';
import { BreadcrumbProps } from '@/components/ui/Breadcrumbs';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { useTrackViewItem } from '@/analytics/pdp';
import { Helmet } from 'react-helmet';
import { getPDPBrowserTitle } from '@/components/ui/PDP/helpers';
import { useFetchAnalyticsItems } from '@/analytics/useFetchAnalyticsItems';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { AnalyticsContext } from '@/analytics/constants';

interface PodcastEpisodeVariationComponentProps extends PodcastEpisodeVariationProps {
  podcastSeriesUrl: string;
  podcastSeriesDisplayName: string;
}

const PodcastEpisodeVariationComponent = (content: PodcastEpisodeVariationComponentProps) => {
  const [validPlayerUrl, setValidPlayerUrl] = useState(false);
  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);
  const { getAnalyticItem } = useFetchAnalyticsItems();
  const [itemAnalyticsModel, setItemAnalyticsModel] = useState<ItemAnalyticsModel>();

  const podcastContentArea = useMemo(() => {
    const podcastContent = content.suppressGlobalPushArea
      ? content.podcastContentArea
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((content as any).globalPushArea?.expandedValue ?? []);

    return (
      <ContentArea
        components={podcastContent}
        propertyName="PodcastContentArea"
        componentsProps={{ isPDP: true }}
      />
    );
  }, [content]);

  useEffect(() => {
    if (!content.playerUrl) return;
    const urlDomain = getDomainFromUrl(content.playerUrl);

    if (urlDomain?.includes('vimeo') || urlDomain?.includes('podbean')) setValidPlayerUrl(true);
    else setValidPlayerUrl(false);
  }, [content]);

  const getItemAnalyticsModel = useCallback(
    async (code: string) => {
      const data = await getAnalyticItem(code);
      if (data) {
        setItemAnalyticsModel(data);
      }
    },
    [getAnalyticItem]
  );

  useEffect(() => {
    if (content?.code) getItemAnalyticsModel(content.code);
  }, [content?.code, getItemAnalyticsModel]);

  const context = useMemo(() => AnalyticsContext.PDP, []);

  useTrackViewItem(content.code);

  return (
    <>
      <Helmet>
        <title>{getPDPBrowserTitle({ variant: content })}</title>
      </Helmet>
      <PodcastHeader
        title={content.displayName || ''}
        providerUrls={content.providerUrls}
        episodeNumber={content.realEpisodeNumber}
        code={content.code}
        shareUrl={content.url}
        itemAnalyticsModel={itemAnalyticsModel}
        context={context}
        breadcrumbs={
          [
            {
              name: 'Podcasts',
              url: transformIfPLIUrl(stateSiteLabels.podcastLandingPageReference?.url)
            },
            {
              name: content.podcastSeriesDisplayName,
              url: transformIfPLIUrl(content.podcastSeriesUrl)
            }
          ] as BreadcrumbProps[]
        }
      />

      <div className="container my-8 flex flex-col space-y-16">
        {content.playerUrl && validPlayerUrl && (
          <PodcastMediaPlayer
            playerUrl={content.playerUrl}
            hasVideo={content.hasVideo}
            itemAnalyticsModel={itemAnalyticsModel}
            context={context}
          />
        )}
        {content.description && (
          <div className="flex flex-col gap-2">
            <h2 className="heading-5">Episode overview</h2>
            <Text className="text-1" element="span">
              <span
                className="[&>p]:mb-5"
                dangerouslySetInnerHTML={{ __html: content.description }}
              ></span>
            </Text>
          </div>
        )}
      </div>
      {
        (!content.podcastContentAreaPosition || content.podcastContentAreaPosition === 1) &&
          podcastContentArea /* Between Overview and Featured Hosts */
      }
      <div className="container my-8">
        <FeaturedGuests guestsContent={content.podcastGuests} />
      </div>
      {
        content.podcastContentAreaPosition === 2 &&
          podcastContentArea /* Between FeaturedHosts and Collection */
      }
      {
        content.podcastContentAreaPosition === 3 &&
          podcastContentArea /* Between Collection and Content Carousel */
      }
      {content.podcastContentAreaPosition === 4 && podcastContentArea /* Below Content Carousel */}
    </>
  );
};

const PodcastEpisodeVariation = withNav(PodcastEpisodeVariationComponent);
export default PodcastEpisodeVariation;
