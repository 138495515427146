import classnames from 'classnames';
import Icon from '@/components/ui/Icon';

type PriceProps = {
  price: string;
  strikePrice?: string;
};

interface SelectorButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  code?: string;
  secondaryLabel?: string;
  iconName?: string;
  price?: PriceProps;
}

const SelectorButton: React.FC<SelectorButtonProps> = ({
  label,
  code,
  secondaryLabel,
  className,
  iconName,
  price,
  ...props
}) => {
  const buttonClassNames = classnames(
    'text-1 flex w-full items-center justify-between gap-x-4 overflow-hidden text-nowrap rounded-lg border border-gray bg-gray-feather px-4 py-6 enabled:hover:border-black enabled:active:border-2 disabled:border-gray disabled:bg-gray-light disabled:text-gray lg:px-8',
    className
  );

  return (
    <button id={code} className={buttonClassNames} {...props}>
      <div className="flex items-center gap-x-4">
        {iconName && <Icon size="medium" name={iconName} />}
        {label}
      </div>
      {secondaryLabel && <span className="text-2 text-gray-dark">{secondaryLabel}</span>}
      {price && !secondaryLabel && (
        <span className="gap-0">
          {price.strikePrice && (
            <span className="text-2 mr-2 text-gray-dark line-through">{price.strikePrice}</span>
          )}
          {price.price && <span className="text-2 text-gray-dark">{price.price}</span>}
        </span>
      )}
    </button>
  );
};

export default SelectorButton;
