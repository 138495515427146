import { useEffect, useRef, useState } from 'react';
import {
  CollectionProduct,
  FeaturedCollectionsBlock as FeaturedCollectionsBlockProps
} from '@/@types/content';
import RoundedIcon from '@/components/ui/RoundedIcon';
import Block from '@/components/cms/Block';
import Text from '@/components/cms/Text';
import throttle from 'lodash/throttle';
import classnames from 'classnames';
import Link from '@/components/ui/Link';
import { type Swiper, type SwiperOptions } from 'swiper/types';
import { SwiperContainer, register } from 'swiper/element/bundle';
import { v4 as uuid4 } from 'uuid';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import FeaturedCardLayout from './FeaturedCardLayout';
import CollectionCard from '@/components/ui/Cards/CollectionCard';

const FeaturedCollectionsBlock: React.FC<FeaturedCollectionsBlockProps> = ({
  title,
  collections,
  seeAllLink,
  featuredlayout,
  removeComponentContainer
}) => {
  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  const navPrevId = `nav-prev-${uuid4()}`;
  const navNextId = `nav-next-${uuid4()}`;
  const sm = useMediaQuery(ScreenSizeQueries.sm);
  const md = useMediaQuery(ScreenSizeQueries.md);
  const [swiper, setSwiper] = useState<Swiper | null>(null);
  const [isPrevVisible, setIsPrevVisible] = useState(false);
  const [isNextVisible, setIsNextVisible] = useState(false);

  useEffect(() => {
    if (!swiperRef.current) return;
    register();

    const params: SwiperOptions = {
      slidesPerGroupAuto: true,
      maxBackfaceHiddenSlides: 1,
      spaceBetween: md ? 24 : 16,
      watchSlidesProgress: true,
      navigation: {
        enabled: true,
        prevEl: `#${navPrevId}`,
        nextEl: `#${navNextId}`
      },
      autoHeight: false,
      on: {
        init(swiper) {
          setSwiper(swiper);
        }
      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
          slidesPerGroup: 1
        },
        889: {
          slidesPerView: 3,
          slidesPerGroup: 3
        }
      }
    };

    Object.assign(swiperRef.current, params);

    const currentSwiper = swiperRef.current as SwiperContainer;
    currentSwiper.initialize();
  }, [navNextId, navPrevId, md]);

  const swiperSlideClasses = classnames('h-auto', {
    'w-3/4': !sm && !md,
    'w-[37.5%]': sm,
    'w-full': md
  });

  const navClasses = classnames(
    'group/navBtn',
    'absolute top-1/2 z-1 hidden w-[60px] -translate-y-1/2 overflow-hidden rounded-full opacity-0 backdrop-blur-md transition-opacity',
    'disabled:pointer-events-none disabled:opacity-0',
    'hover:opacity-100',
    'lg:flex'
  );

  const navIconClasses = classnames(
    'w-[60px] bg-black/30 transition-colors',
    'group-hover/navBtn:bg-black/40'
  );

  const displayNumber = 3;
  const isEven =
    ((collections?.length || 0) > displayNumber ? displayNumber : collections?.length || 0) % 2 ===
    0;

  const featuredCollection = (collection: CollectionProduct, isTop: boolean) => {
    const labels = [
      ...(collection.hasPrograms ? ['Programs'] : []),
      ...(collection.hasPodcasts ? ['Podcasts'] : []),
      ...(collection.hasPublications ? ['Publications'] : [])
    ];

    return (
      <CollectionCard
        heading={collection.displayName}
        headingSmall="Collection"
        href={collection.contentLink?.url}
        image={{ url: collection.backGroundImage?.url ?? '', alt: collection.displayName }}
        size={isTop ? 'large' : 'medium'}
        labels={labels}
      />
    );
  };

  const handleMouseMove = throttle((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const container = containerRef.current as HTMLDivElement | null;
    if (container) {
      const rect = container.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;

      if (swiper?.isBeginning) {
        setIsPrevVisible(false);
        setIsNextVisible(true);
      } else if (swiper?.isEnd) {
        setIsPrevVisible(true);
        setIsNextVisible(false);
      } else if (mouseX < rect.width / 2) {
        setIsPrevVisible(true);
        setIsNextVisible(false);
      } else {
        setIsPrevVisible(false);
        setIsNextVisible(true);
      }
    }
  }, 100);

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsPrevVisible(false);
      setIsNextVisible(false);
    }, 250);
  };

  return (
    collections && (
      <>
        {featuredlayout ? (
          <Block className="module-spacing overflow-x-clip">
            <div
              className={classnames(
                'container flex flex-col gap-y-8 overflow-x-clip',
                'md:gap-y-14'
              )}
            >
              <div
                className={classnames(
                  'flex flex-col items-start justify-between gap-y-4 sm:flex-row sm:items-center'
                )}
              >
                <Text
                  propertyName="Title"
                  element={sm ? 'h2' : 'h4'}
                  className={classnames('max-w-[491px] tracking-[-1px] lg:max-w-[676px]', {
                    'heading-4': !sm,
                    'heading-2': sm
                  })}
                >
                  {title}
                </Text>
                <div className="">
                  <Link
                    propertyName="SeeAllLink"
                    href={seeAllLink?.href}
                    text={seeAllLink?.text}
                    title={seeAllLink?.text}
                    target={seeAllLink?.target}
                    variant="bold-link-with-icon"
                    icon="chevron-right"
                  />
                </div>
              </div>
              <div ref={containerRef} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                <swiper-container class="swiper-no-overflow" init={false} ref={swiperRef}>
                  {collections.map((collection, index) => {
                    return (
                      <swiper-slide key={index} class={swiperSlideClasses}>
                        <FeaturedCardLayout collection={collection} />
                      </swiper-slide>
                    );
                  })}
                </swiper-container>
                <button
                  id={navPrevId}
                  className={classnames(navClasses, 'left-[50px]', {
                    'opacity-0': !isPrevVisible,
                    'opacity-100': isPrevVisible
                  })}
                >
                  <RoundedIcon
                    color="black"
                    icon={{ name: 'arrow-left', size: 'large' }}
                    className={navIconClasses}
                  />
                </button>
                <button
                  id={navNextId}
                  className={classnames(navClasses, 'right-[50px]', {
                    'opacity-0': !isNextVisible,
                    'opacity-100': isNextVisible
                  })}
                >
                  <RoundedIcon
                    color="black"
                    icon={{ name: 'arrow-right', size: 'large' }}
                    className={navIconClasses}
                  />
                </button>
              </div>
            </div>
          </Block>
        ) : (
          <div className={classnames({ container: !removeComponentContainer }, 'module-spacing')}>
            <h6 className="heading-6-medium lg:mt-8">{title}</h6>
            {!isEven && collections && collections[0] && (
              <div className="mt-6 lg:mt-8">{featuredCollection(collections[0], true)}</div>
            )}
            {collections && collections.length >= 2 && (
              <div className="mt-[20px] flex flex-col gap-[16px] sm:flex-row lg:mt-[24px] lg:gap-[24px]">
                {collections.slice(isEven ? 0 : 1, displayNumber).map(x => {
                  return featuredCollection(x, false);
                })}
              </div>
            )}
          </div>
        )}
      </>
    )
  );
};

export default FeaturedCollectionsBlock;
