import { createBrowserRouter } from 'react-router-dom';
import PageMapper from '@/lib/PageMapper';
import paths from '@/routes/paths';
import { AuthPostSignOut, AuthSignIn, AuthSignOut } from '@/auth/AuthControl';
import ProductLinkResolver from '@/pages/ProductLinkResolver';
import ProductContentResolver from '@/pages/ProductContentResolver';
import AnimationStage from '@/lib/AnimationStage';
import { useGetGlobalElementQuery } from '@/redux/api/contentDeliveryAPI';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { PLIPromoTracker } from '@/lib/PLIPromoTracker';
import Loader from '@/pages/Loader';
import ProductLinkAuthResolver from '@/pages/ProductLinkAuthResolver';

const BrowserRouter = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const userId = useSelector((state: RootState) => state?.auth?.name || 'ANON');

  userId;
  const { data: headerData } = useGetGlobalElementQuery(
    { name: 'header', userId },
    { skip: !authState.ready }
  );

  return createBrowserRouter([
    { path: paths.OIDC_SIGNIN, element: <AuthSignIn /> },
    { path: paths.OIDC_POST_SIGNOUT_CALLBACK, element: <AuthPostSignOut /> },
    { path: paths.OIDC_SIGNOUT_CALLBACK, element: <AuthSignOut /> },
    {
      path: paths.CMS_ROUTE,
      element: (
        <AnimationStage headerData={headerData}>
          <PageMapper />
          <PLIPromoTracker />
        </AnimationStage>
      )
    },
    {
      path: paths.PRODUCT_REDIRECT,
      element: (
        <AnimationStage headerData={headerData}>
          <ProductLinkResolver />
          <PLIPromoTracker />
        </AnimationStage>
      )
    },
    {
      path: paths.PRODUCT_REDIRECT_AUTH,
      element: (
        <AnimationStage headerData={headerData}>
          <ProductLinkAuthResolver />
          <PLIPromoTracker />
        </AnimationStage>
      )
    },
    {
      path: paths.PLUS_CONTENT_REDIRECT_AUTH,
      element: (
        <AnimationStage headerData={headerData}>
          <ProductContentResolver />
          <PLIPromoTracker />
        </AnimationStage>
      )
    },
    {
      path: paths.LAUNCHING,
      element: (
        <AnimationStage headerData={headerData}>
          <Loader />
        </AnimationStage>
      )
    }
  ]);
};

export default BrowserRouter;
