import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import EditPanel, { EditPanelProps } from '@/components/ui/Panels/EditPanel';
import { UserProfile } from '@/@types/client-api';
import TextInput from '@/components/ui/Form/TextInput';
import { SelectorItem } from '@/components/ui/Filter/types';
import SelectSubPanel from '@/components/ui/Panels/SelectSubPanel';
import ComplexTooltip from '../Tooltip/ComplexTooltip';
import React from 'react';

import useNudgeContext from '@/hooks/shared/useNudge/useNudgeContext';
import { ProfileNudgeSectionNames } from '@/hooks/shared/useNudge/utils';

type AboutPanelProps = {
  data: UserProfile;
  onSave?: (data: UserProfile) => void;
  messages?: string;
} & Omit<EditPanelProps, 'children' | 'onSave' | 'onDelete' | 'title'>;

const AboutPanel: React.FC<AboutPanelProps> = ({
  isOpen,
  onOpenChange,
  theme,
  data,
  onSave,
  triggerButton,
  messages
}) => {
  const [salutation, setSalutation] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [middleInitial, setMiddleInitial] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [suffix, setSuffix] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [roles, setRoles] = useState<string[]>([]);
  const { openPanel, registerPanel, unregisterPanel } = useNudgeContext();

  useEffect(() => {
    if (isOpen) {
      setSalutation(data.salutation ?? '');
      setFirstName(data.firstName ?? '');
      setMiddleInitial(data.middleInitial ?? '');
      setLastName(data.lastName ?? '');
      setSuffix(data.suffix ?? '');
      setTitle(data.title ?? '');
      setRoles(data.roles?.map(role => role.sk?.toString() ?? '') ?? []);
      registerPanel(ProfileNudgeSectionNames.About);
    }
  }, [data, isOpen, registerPanel]);

  useEffect(() => {
    if (!isOpen && openPanel == ProfileNudgeSectionNames.About)
      unregisterPanel(ProfileNudgeSectionNames.About);
  }, [isOpen, openPanel, unregisterPanel]);

  const handleOnClose = useCallback(() => {
    onOpenChange?.(false);
  }, [onOpenChange]);

  const handleValidateFields = useCallback(
    (): boolean => !!firstName && !!lastName,
    [firstName, lastName]
  );

  const handleOnSave = useCallback(() => {
    handleOnClose();
    onSave?.({
      salutation,
      firstName,
      middleInitial,
      lastName,
      suffix,
      title,
      roles: data?.professionalRoleLookup?.filter(
        role => role.sk && roles.includes(role.sk.toString())
      )
    });
  }, [
    data?.professionalRoleLookup,
    firstName,
    handleOnClose,
    lastName,
    middleInitial,
    onSave,
    roles,
    salutation,
    suffix,
    title
  ]);

  const suppressFields = useMemo(
    () => data.rosterManagmentEditingRestriction != null,
    [data.rosterManagmentEditingRestriction]
  );

  const salutationLookup = useMemo(
    () => data.salutationLookup?.map((item): SelectorItem => ({ value: item, label: item })),
    [data.salutationLookup]
  );

  const suffixLookup = useMemo(
    () => data.suffixLookup?.map((option): SelectorItem => ({ value: option, label: option })),
    [data.suffixLookup]
  );

  const roleLookup = useMemo(
    () =>
      data.professionalRoleLookup?.map(
        (role): SelectorItem => ({
          value: role.sk?.toString() ?? '',
          label: role.longDescription ?? ''
        })
      ),
    [data.professionalRoleLookup]
  );

  const [showDisabledTooltip, setShowDisabledTooltip] = useState<number | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{ left: number; top: number } | null>(
    null
  );

  const disabledTooltipMessage = messages ? JSON.parse(messages).tooltip.disabled : '';
  const [contentContainer, setContentContainer] = React.useState<HTMLElement | null>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const [mainPosition, setMainPosition] = React.useState<DOMRect | null>(null);
  const [panelPosition, setPanelPosition] = React.useState<DOMRect | null>(null);
  const handleDisabledFieldClick = (fieldId: number, event: React.MouseEvent<HTMLElement>) => {
    setShowDisabledTooltip(fieldId);

    const position = event?.currentTarget.getBoundingClientRect();
    const panelPosition = panelRef.current?.getBoundingClientRect();
    if (position && panelPosition) {
      setMainPosition(position);
      setPanelPosition(panelPosition);
    }
    setTooltipPosition({
      left: position.left - (panelPosition?.left ?? 0),
      top: position.top - (panelPosition?.top ?? 0)
    });
  };

  const handleDismiss = () => {
    setShowDisabledTooltip(null);
  };

  useEffect(() => {
    if (mainPosition != null) {
      setTooltipPosition({
        left: mainPosition?.width / 2,
        top: mainPosition.top - (panelPosition?.top ?? 0)
      });
    }

    const handleResize = () => {
      const updatedPosition = contentContainer?.getBoundingClientRect();
      if (updatedPosition?.width && updatedPosition.height) {
        setTooltipPosition({
          left: updatedPosition?.width,
          top: updatedPosition?.height
        });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [contentContainer, mainPosition, panelPosition?.top]);

  return (
    <EditPanel
      title="About"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      theme={theme}
      onSave={handleOnSave}
      onValidateFields={handleValidateFields}
      triggerButton={triggerButton}
      contentRef={panelRef}
    >
      <SelectSubPanel
        onClosePanel={handleOnClose}
        items={salutationLookup}
        label="Salutation"
        selectedItem={salutation}
        onSelectedItemChange={setSalutation}
        disabled={suppressFields}
        onTriggerClick={e => handleDisabledFieldClick(1, e)}
      />

      <TextInput
        label="First Name"
        value={firstName}
        onChange={setFirstName}
        maxLength={32}
        required
        acceptNumbers={false}
        acceptSpecialChars={false}
        disabledEffect={suppressFields}
        readOnly={suppressFields}
        onClick={e => handleDisabledFieldClick(2, e)}
      />

      <TextInput
        label="Middle initial"
        value={middleInitial}
        onChange={setMiddleInitial}
        maxLength={2}
        acceptNumbers={false}
        acceptSpecialChars={false}
        acceptSpaces={false}
        disabledEffect={suppressFields}
        readOnly={suppressFields}
        onClick={e => handleDisabledFieldClick(3, e)}
      />

      <TextInput
        label="Last Name"
        value={lastName}
        onChange={setLastName}
        maxLength={32}
        required
        acceptNumbers={false}
        acceptSpecialChars={false}
        disabledEffect={suppressFields}
        readOnly={suppressFields}
        onClick={e => handleDisabledFieldClick(4, e)}
      />

      <SelectSubPanel
        onClosePanel={handleOnClose}
        items={suffixLookup}
        label="Suffix"
        selectedItem={suffix}
        onSelectedItemChange={setSuffix}
        disabled={suppressFields}
        onTriggerClick={e => handleDisabledFieldClick(5, e)}
      />

      <TextInput
        label="Title"
        value={title}
        onChange={setTitle}
        maxLength={255}
        acceptNumbers={false}
        acceptSpecialChars={false}
        disabledEffect={suppressFields}
        readOnly={suppressFields}
        onClick={e => handleDisabledFieldClick(6, e)}
      />

      <SelectSubPanel
        onClosePanel={handleOnClose}
        isMultiSelect={true}
        items={roleLookup}
        label="Role(s)"
        title="Select role(s)"
        selectedItems={roles}
        onSelectedItemsChange={setRoles}
      />

      {suppressFields && (
        <div
          style={{
            position: 'absolute',
            display: 'inline-block',
            top: tooltipPosition?.top,
            width: 0,
            left: tooltipPosition?.left
          }}
        >
          <ComplexTooltip isOpen={showDisabledTooltip !== null} variant="light">
            <ComplexTooltip.Trigger>
              <div className="ml-2 inline-flex items-center justify-center hover:text-gray-darker focus:text-gray-darker"></div>
            </ComplexTooltip.Trigger>
            <ComplexTooltip.Content
              side="bottom"
              sideOffset={10}
              alignOffset={-75}
              align="center"
              avoidCollisions={true}
              onClose={handleDismiss}
              customContainer={contentContainer}
            >
              <div style={{ position: 'relative' }}>
                <p className="text-2">{disabledTooltipMessage}</p>
              </div>
            </ComplexTooltip.Content>
          </ComplexTooltip>
          <div ref={setContentContainer} />
        </div>
      )}
    </EditPanel>
  );
};

export default AboutPanel;
