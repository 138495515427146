import { SnippetButtonProps } from '../../Snippet/Snippet';
import AddToCalendarModal from '../AddToCalendarModal';
import EcommPanel from '../EcommPanel';
import { ActionType, EcommPanelParams } from '../hooks/useEcommData';
import searchableTypeToSnippetVariant from '../searchableTypeToSnippetVariant';
import SearchCreditDetailsModal from '../SearchCreditDetailsModal';
import SearchMaterialsModal from '../SearchMaterialsModal';
import { SnippetButtonLabel } from './buttonsLabels';
import { RenderSnippetProps, SnippetContext } from './types';
import { launchCreditRequestPopup, snippetToAnalyticsContext } from './helpers';
import SnippetCreditRequestedTooltip from '@/components/ui/Snippet/SnippetCreditRequestedTooltip';
import { launchNotifyMePopup } from '@/utils/helpers';
import { ItemAnalyticsModel } from '@/@types/client-api';
import {
  trackNotifyMeEvent,
  trackViewCertificatesEvent,
  trackReadTranscriptEvent
} from '@/analytics/library';
import { trackCardRequestCreditEvent } from '@/analytics/cards';

export const getViewTranscriptButton = (
  context: SnippetContext,
  pk: string,
  plusUrl: string,
  itemAnalyticsModel?: ItemAnalyticsModel
): SnippetButtonProps => {
  return {
    label: SnippetButtonLabel.viewTranscript,
    color: 'outline-black',
    iconRight: 'link-out',
    size: 'short',
    href: plusUrl,
    target: '_blank',
    onClick: () => {
      const analyticsContext = snippetToAnalyticsContext(context);
      if (analyticsContext && pk) {
        trackReadTranscriptEvent(itemAnalyticsModel, analyticsContext);
      }
    }
  };
};

export const getRequestCreditsButton = (
  context: SnippetContext,
  pk: string,
  certificateRequestUrl: string | null | undefined,
  cleProgressPct: number,
  itemAnalyticsModel?: ItemAnalyticsModel
): SnippetButtonProps | undefined => {
  if (certificateRequestUrl) {
    return {
      label: SnippetButtonLabel.requestCredits,
      color: cleProgressPct < 100 ? 'outline-black' : 'green',
      iconRight: 'credits',
      size: 'short',
      onClick: () => {
        const analyticsContext = snippetToAnalyticsContext(context);
        if (analyticsContext && pk) {
          trackCardRequestCreditEvent(itemAnalyticsModel, analyticsContext);
        }
        launchCreditRequestPopup(certificateRequestUrl);
      },
      target: '_blank'
    };
  } else if (cleProgressPct === 100) {
    return {
      label: SnippetButtonLabel.requestCredits,
      color: 'green',
      iconRight: 'credits',
      size: 'short',
      onClick: () => {},
      disabled: true,
      href: '',
      target: '_blank'
    };
  }
  return undefined;
};

export const getViewCertificatesButton = (
  context: SnippetContext,
  pk: string,
  link: string | undefined,
  itemAnalyticsModel?: ItemAnalyticsModel
): SnippetButtonProps => {
  return {
    label: SnippetButtonLabel.viewCertificates,
    color: 'black',
    iconRight: 'chevron-right',
    size: 'short',
    href: link,
    target: '_blank',
    onClick: () => {
      const analyticsContext = snippetToAnalyticsContext(context);
      if (analyticsContext && pk) {
        trackViewCertificatesEvent(itemAnalyticsModel);
      }
    }
  };
};

export const getCreditRequestedButton = (
  creditRequestDate: string | null | undefined
): SnippetButtonProps => {
  return {
    label: SnippetButtonLabel.creditRequested,
    color: 'light-gray',
    renderButtonWrapper: creditRequestDate
      ? button => (
          <SnippetCreditRequestedTooltip creditRequestDate={new Date(creditRequestDate)}>
            {button}
          </SnippetCreditRequestedTooltip>
        )
      : undefined,
    iconRight: 'info',
    size: 'short',
    onClick: () => {},
    target: '_blank'
  };
};

export const getNotifyMeAction = (
  context: SnippetContext,
  pk: string,
  url: string | null,
  itemAnalyticsModel?: ItemAnalyticsModel
) => {
  return () => {
    const analyticsContext = snippetToAnalyticsContext(context);
    if (analyticsContext && pk) {
      trackNotifyMeEvent(itemAnalyticsModel, analyticsContext);
    }

    if (url) {
      launchNotifyMePopup(url);
    }
  };
};

export const getAccessMaterialsButton = (
  context: SnippetContext,
  pk: string,
  title: string,
  onClick: () => void
): SnippetButtonProps => {
  return {
    label: SnippetButtonLabel.accessMaterials,
    color: 'outline-black',
    iconRight: 'publications',
    size: 'short',
    onClick,
    renderButtonWrapper: (button: JSX.Element) => (
      <SearchMaterialsModal
        params={{ pk, title }}
        context={snippetToAnalyticsContext(context)}
        triggerButton={button}
      />
    )
  };
};

export const renderAddToCalendarButtonWrapper = (context: SnippetContext, pk: string) => {
  return (button: JSX.Element) => (
    <AddToCalendarModal
      params={{ pk }}
      triggerButton={button}
      context={snippetToAnalyticsContext(context)}
    />
  );
};

export const getViewCreditDetailsButton = (
  context: SnippetContext,
  pk: string,
  text: string,
  itemAnalyticsModel?: ItemAnalyticsModel
): JSX.Element => {
  return (
    <span className="inline-block" key={text}>
      <SearchCreditDetailsModal
        pk={pk}
        context={snippetToAnalyticsContext(context)}
        itemAnalyticsModel={itemAnalyticsModel}
        triggerButton={
          <button type="button" className="text-link text-2 underline">
            {text}
          </button>
        }
      />
    </span>
  );
};

export const renderEcommPanelButtonWrapper = (
  context: SnippetContext,
  data: RenderSnippetProps['data'],
  action: ActionType,
  initialVariantSelector?: EcommPanelParams['initialVariantSelector']
) => {
  const params: EcommPanelParams = {
    pk: data.variationPk ?? '',
    url: data.url ?? '',
    title: data.title ?? '',
    guidValue: data.productGuidValue ?? null,
    searchableType: searchableTypeToSnippetVariant(data.searchableType),
    action: action,
    initialVariantSelector
  };
  return (button: JSX.Element) => (
    <EcommPanel
      params={params}
      triggerButton={button}
      context={snippetToAnalyticsContext(context)}
    />
  );
};
