import { format, parse } from 'date-fns';

export const DateFormat = 'MM/dd/yyyy';
export const BackendDateFormat = 'yyyy-MM-dd';

const siteBaseUrls = new Set<string>([
  'https://testfrontend.pli.edu',
  'https://stagingfrontend.pli.edu',
  'https://frontend.pli.edu',
  'https://testfrontdoor.pli.edu'
]);

export const parseDate = (value?: string, dateFormat = DateFormat): Date | undefined => {
  if (!value || value.length !== dateFormat.length) {
    return undefined;
  }
  const date = parse(value, dateFormat, new Date());
  const isValidDate = !isNaN(date.getTime());
  return isValidDate ? date : undefined;
};

export const parseBEDate = (value?: string): Date | undefined =>
  parseDate(value, BackendDateFormat);

export const formatDate = (date: Date, dateFormat = DateFormat): string => format(date, dateFormat);

export const formatBEDateToFEDate = (dateString?: string): string | undefined => {
  if (!dateString) return '';
  const date = parseBEDate(dateString);
  return date && formatDate(date);
};

export const formatString = (str?: string | null, ...args: string[]) => {
  if (!str || !args || args.length == 0) return str;

  return str.replace(/{(\d+)}/g, (_, index) => args[index] || '');
};

export const isExternalUrl = (url: string): boolean => {
  try {
    if (url == '') {
      return false;
    }
    const link = new URL(url);
    return link.origin !== window.location.origin;
  } catch (error) {
    return false;
  }
};

export const getDomainFromUrl = (url: string) => {
  try {
    const urlObj = new URL(url);
    return urlObj.hostname;
  } catch (error) {
    return null;
  }
};

export const isRelativeUrl = (url: string): boolean => url.startsWith('/');

export const isPLIUrl = (url: string): boolean => {
  try {
    const link = new URL(url);
    return link.origin === window.location.origin || siteBaseUrls.has(link.origin);
  } catch (error) {
    return false;
  }
};

export const isRelativeOrPLIUrl = (url: string): boolean => isRelativeUrl(url) || isPLIUrl(url);

export const transformIfPLIUrl = (value?: string): string | undefined => {
  // If the link is in a PLI environment we can use the pathname
  if (value && isPLIUrl(value)) {
    try {
      const url = new URL(value);
      const transformedLink = url.pathname + url.search;
      return transformedLink;
    } catch {
      return value;
    }
  } else {
    return value;
  }
};

export const extractTextFromHTML = (htmlString: string) => {
  const tempElement = document.createElement('span');
  tempElement.innerHTML = htmlString;
  const simplifiedText = tempElement.textContent || tempElement.innerText || '';
  tempElement.remove();
  return simplifiedText;
};

export const addClassesToLinks = (element: Element) => {
  const children = element.children;

  if (children.length) {
    for (const c of children) {
      if (c.children?.length) addClassesToLinks(c);

      if (c.localName === 'a') {
        c.classList.add('text-link', 'underline');
      }
    }
  }
};

export const extractTextFromParagraph = (htmlString: string) => {
  if (htmlString?.trim().startsWith('<p>')) {
    const tempElement = document.createElement('span');
    tempElement.innerHTML = htmlString;
    const paragraph = tempElement.children?.[0];
    addClassesToLinks(paragraph);
    const simplifiedText = paragraph?.innerHTML ?? '';
    tempElement.remove();
    return simplifiedText;
  }
};

export const compareArrayIndex = <T, V>(
  array: T[],
  a: V,
  b: V,
  checkerFn: (listItem: T, checkItem: V) => boolean
): number => {
  const aIndex = array.findIndex(i => checkerFn(i, a));
  const bIndex = array.findIndex(i => checkerFn(i, b));
  if (aIndex === -1 || bIndex === -1) {
    return 0;
  }
  return aIndex - bIndex;
};

export const mergeURLSearchParams = (
  params1: URLSearchParams,
  params2: URLSearchParams
): URLSearchParams => {
  const mergedParams = new URLSearchParams(params1);

  for (const [key, value] of params2.entries()) {
    mergedParams.append(key, value);
  }

  return mergedParams;
};

export const unknownToBoolean = (value: unknown) => {
  if (typeof value === 'string') {
    const strValue = value?.toLowerCase();
    if (strValue == 'true') return true;
    else if (strValue == 'false') return false;
  }
  if (typeof value === 'boolean') return value;
  return !!value;
};

export const formatTcode = (sourceCode?: string, keyCode?: string) =>
  sourceCode && keyCode ? `${sourceCode}_${keyCode}` : undefined;

export const launchNotifyMePopup = (notifyMeUrl: string) =>
  window.open(notifyMeUrl, 'popup', 'width=740,height=366,left=62,top=88');
