import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PDPSectionLabels } from '@/components/ui/PDP/labels';
import Testimonials from '@/components/ui/PDP/Testimonials';
import {
  ProgramCatalogRelations,
  LiveVariation,
  OnDemandProgramVariation,
  WebSegmentVariation
} from '@/hooks/PDP/useProgramCatalogRelations';
import {
  registerTestimonialsSection,
  unregisterTestimonialsSection
} from '@/redux/slices/pdpSlice';

interface IPDPTestimonialsLayoutBlockProps {
  variant: LiveVariation | OnDemandProgramVariation | WebSegmentVariation;
  catalogRelations: ProgramCatalogRelations;
  withinOverview?: boolean;
}
const IPDPTestimonialsLayoutBlock = ({
  variant,
  withinOverview
}: IPDPTestimonialsLayoutBlockProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (variant?.testimonials?.length) {
      // TODO: SIREHOME-216 when re-orderable overview is implemented, if testimonials is inside overview, suppress jumplink
      dispatch(registerTestimonialsSection());
    } else {
      dispatch(unregisterTestimonialsSection());
    }
  }, [dispatch, variant?.testimonials?.length]);

  if (!variant.testimonials) return null;

  const quotes = variant.testimonials.map(t => {
    return {
      content: t.testimonial!,
      author: t.author!,
      company: t.affiliation
    };
  });
  return (
    <Testimonials
      heading={PDPSectionLabels.testimonials}
      quotes={quotes}
      withinOverview={withinOverview}
    />
  );
};

export default IPDPTestimonialsLayoutBlock;
