import Eyebrow, { EyebrowProps } from '@/components/ui/Eyebrow/Eyebrow';
import Text from '@/components/cms/Text';
import ActionBar, { ActionBarProps } from '@/components/ui/PDP/ActionBar';
import JumpLinks from './JumpLinks';
import useInViewport from '@/hooks/useInViewport';
import { IContent } from '@/@types/cms';
import { Variant } from '@/redux/slices/pdpSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { useEffect, useState } from 'react';
import useSearchDelayedActionToasts from '../SearchResults/hooks/useSearchDelayedActionToasts';

type ProductDetailHeaderFormat = 'Upcoming Live' | 'On-Demand' | 'Live' | 'Live ended';

export interface ProductDetailHeaderProps {
  title: string;
  formatEyebrow?: ProductDetailHeaderFormat;
  actionBarProps: ActionBarProps;
  className?: string;
  layout?: {
    layout: IContent[];
    variant: Variant;
  };
  publicationFormat?: string; // Publications are not fixed, pending cross team proj
  shareModalId?: string;
  downloadModalId?: string;
  loading?: boolean;
}

const eyebrowIconMap = {
  ['Upcoming Live']: 'upcoming',
  ['Live ended']: 'upcoming',
  ['Live']: 'live-circle',
  ['On-Demand']: 'on-demand'
};

const ProductDetailHeader: React.FC<ProductDetailHeaderProps> = ({
  title,
  formatEyebrow,
  actionBarProps,
  className,
  layout,
  publicationFormat,
  loading
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const stickyHeader = useSelector((state: RootState) => state.page.stickyHeader);
  const [titleRef, isInView] = useInViewport<HTMLDivElement>({
    offset: stickyHeader.isSticky ? stickyHeader.headerHeight * -1 : 0
  });
  const eyebrowProps: EyebrowProps | undefined =
    publicationFormat || formatEyebrow
      ? {
          sizeFormat: 'pdp',
          label: (publicationFormat ?? formatEyebrow)!,
          icon: publicationFormat ? 'publications' : eyebrowIconMap[formatEyebrow!]
        }
      : undefined;

  useSearchDelayedActionToasts();

  useEffect(() => {
    if (loading) {
      setIsLoaded(false);
    } else {
      requestAnimationFrame(() => {
        setIsLoaded(true);
      });
    }
  }, [loading]);

  return (
    <section className={className}>
      {/* TODO SIREHOME-640: temporarily allow to be suppressed until Pub Jumplinks is implemented*/}
      {layout && <JumpLinks show={!isInView && isLoaded} layout={layout} />}
      <div className="container flex flex-col gap-6 pt-10 lg:gap-7 lg:pt-16">
        {eyebrowProps && <Eyebrow {...eyebrowProps} />}
        <div className="flex flex-col gap-7 pb-8 lg:gap-16 lg:pb-0">
          <div ref={titleRef}>
            <Text propertyName="DisplayName" element="h1" className="heading-2">
              {title}
            </Text>
          </div>
          <ActionBar {...actionBarProps} />
        </div>
      </div>
    </section>
  );
};

export default ProductDetailHeader;
