import { NavigateFunction } from 'react-router-dom';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { trackMoreLikeThisEvent } from '@/analytics/cards';

const moreLikeThis = async (
  navigate: NavigateFunction,
  productCode?: string | null,
  fallbackUrl: string = '/',
  itemAnalyticsModel?: ItemAnalyticsModel
) => {
  const baseUrl = `/api/client/search/more-like-this/`;
  let apiUrl = fallbackUrl;

  if (productCode) {
    apiUrl = `${baseUrl}${productCode}`;
  }

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      redirect: 'follow'
    });

    if (response.ok || (response.status >= 300 && response.status < 400)) {
      const destination = new URL(response.url);
      const finalUrl = destination.pathname + destination.search || fallbackUrl;
      navigate(finalUrl);
    } else {
      throw new Error(`Server returned an error ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error fetching the final URL:', error);
    navigate(fallbackUrl);
  } finally {
    trackMoreLikeThisEvent(itemAnalyticsModel);
  }
};

export default moreLikeThis;
