import { useEffect, useMemo, useState } from 'react';
import { DownloadVariation, ProgramVariation } from './useProgramCatalogRelations';
import { v4 as uuid4 } from 'uuid';
import { DownloadLabels } from '@/components/ui/PDP/labels';

export const useDownloadModal = (
  selectedWebVariant: ProgramVariation,
  selectDownloadByDefault?: DownloadVariation
) => {
  const downloadModalId = useMemo(() => `download-modal-${uuid4()}`, []);
  const [openDownloadModal, setOpenDownloadModal] = useState(!!selectDownloadByDefault);
  const [selectedDownloadVariant, setSelectedDownloadVariant] = useState<
    DownloadVariation | undefined
  >(selectDownloadByDefault);

  useEffect(() => {
    if (!openDownloadModal && !!selectedWebVariant.url) {
      const path = new URL(selectedWebVariant.url).pathname;
      const { hash } = window.location;
      window.history.replaceState({}, '', `${path}${hash}`);
    } else if (openDownloadModal && !!selectedDownloadVariant?.url) {
      const path = new URL(selectedDownloadVariant.url).pathname;
      const { hash } = window.location;
      window.history.replaceState({}, '', `${path}${hash}`);
    }
  }, [openDownloadModal, selectedDownloadVariant?.url, selectedWebVariant.url]);

  return {
    customModalId: downloadModalId,
    open: openDownloadModal,
    setOpen: setOpenDownloadModal,
    selectedDownloadVariant,
    setSelectedDownloadVariant,
    labels: DownloadLabels
  };
};
