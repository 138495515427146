import classnames from 'classnames';
import { ReactNode } from 'react';

type MenuTextProps = {
  value: ReactNode;
  eyebrow?: string;
  smallClassName?: string;
  valueClassName?: string;
};

const MenuText = ({ smallClassName, value, eyebrow, valueClassName }: MenuTextProps) => {
  return (
    <span className="flex min-w-0 grow flex-col items-start sm:flex-row">
      {eyebrow && <span className={classnames('sm:hidden', smallClassName)}>{eyebrow}</span>}
      <span className={valueClassName}>
        {eyebrow && <span className="max-sm:hidden">{eyebrow}: </span>}
        {value}
      </span>
    </span>
  );
};

export default MenuText;
