import { useEffect, useState, useRef, RefObject } from 'react';

interface UseInViewportOptions extends IntersectionObserverInit {
  offset: number;
}

type InViewPort = boolean | undefined;
type ElementPosition = 'above' | 'below' | 'inView' | undefined;

function useInViewport<T extends HTMLElement = HTMLElement>(
  options: UseInViewportOptions = { offset: 0 }
): [RefObject<T>, InViewPort, ElementPosition] {
  const [isInView, setIsInView] = useState<InViewPort>();
  const [position, setPosition] = useState<ElementPosition>();
  const elementRef = useRef<T | null>(null);

  useEffect(() => {
    const { offset, ...observerOptions } = options;
    const currentElement = elementRef.current;

    const adjustedOptions = {
      ...observerOptions,
      rootMargin: `${offset}px`
    };

    const observer = new IntersectionObserver(([entry]) => {
      const isInView = entry.isIntersecting;
      setIsInView(isInView);

      if (!isInView) {
        const rect = entry.boundingClientRect;
        if (rect.top < 0 - offset) {
          setPosition('above');
        } else if (rect.bottom > window.innerHeight + offset) {
          setPosition('below');
        }
      } else {
        setPosition('inView');
      }
    }, adjustedOptions);

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.disconnect();
      }
    };
  }, [options, options.offset]);

  return [elementRef, isInView, position];
}

export default useInViewport;
