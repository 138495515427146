import {
  ProfileSettingsPage,
  TabsContainerBlock as TabsContainerBlockProps
} from '@/@types/content';
import GlobalTabs, { TabTrigger } from '../../ui/Tab';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { IContent } from '@/@types/cms';
import ContentArea from '../../cms/ContentArea';

type Props = TabsContainerBlockProps & {
  parentRouteSegment: ProfileSettingsPage['routeSegment'];
};

type TabBlockTab = TabTrigger & {
  routeSegment: string;
  content: IContent;
};

const TabsContainerBlock: React.FC<Props> = ({ content, parentRouteSegment }) => {
  const tabs: TabBlockTab[] =
    content?.map(tab => ({
      label: tab.name ?? '',
      id: String(tab.contentLink?.id),
      // @ts-expect-error Gabriel Butron is looking into this
      routeSegment: tab.route,
      content: tab
    })) || [];
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const setSelectedValue = useCallback(
    (tab: TabBlockTab) => {
      navigate(`/${parentRouteSegment}/${tab.routeSegment}`);
    },
    [navigate, parentRouteSegment]
  );

  if (!tabs) return null;

  const selectedValue = tabs.find(tab => pathname.endsWith(tab.routeSegment)) ?? tabs[0];

  return (
    <GlobalTabs
      tabsTriggers={tabs}
      variant="browse"
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      elementType="h2"
    >
      <ContentArea propertyName="Item" components={[selectedValue.content]} />
    </GlobalTabs>
  );
};

export default TabsContainerBlock;
