import classnames from 'classnames';

import CreditProgressCard from '@/components/ui/CreditProgressCard';
import Thumbnail from '@/components/ui/Thumbnail';
import type { SnippetSegmentType } from './types';

interface SnippetSegmentProps extends SnippetSegmentType {
  containerClassNames?: string;
}

const SnippetSegment = ({
  segmentTitle,
  segmentLink,
  segmentImage,
  progressValue,
  cleProgressPct = 0,
  playable,
  containerClassNames,
  onSegmentLinkClick
}: SnippetSegmentProps) => {
  return (
    <div
      className={classnames(
        'flex w-full justify-between gap-8 md:items-center',
        containerClassNames
      )}
    >
      <div
        className="flex flex-col md:gap-6"
        onClick={e => {
          e.stopPropagation();
          onSegmentLinkClick(segmentLink);
        }}
      >
        <CreditProgressCard
          percentCompleted={cleProgressPct || 0}
          title={segmentTitle}
          segmentLink={segmentLink}
          playable={playable}
        />
      </div>
      <div className="min-w-[120px] max-sm:min-w-[90px]">
        <Thumbnail
          icon={{
            name: 'play',
            label: 'Play'
          }}
          mini
          image={segmentImage}
          video={{ url: '', playable }}
          progressValue={progressValue}
          imageSizes={{
            xs: '120px',
            sm: '90px'
          }}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

export default SnippetSegment;
