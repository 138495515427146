import { useCallback } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Icon from '@/components/ui/Icon';
import Link from '@/components/cms/Link';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import type { LinkBlock as LinkBlockProps } from '@/@types/content';
import { isExternalUrl } from '@/utils/helpers';
import Block from '@/components/cms/Block';
import ResponsiveImage from '@/components/ui/ResponsiveImage';

export type OnTrackItemViewEvent = (text: string) => void;

const LinkBlock: React.FC<
  LinkBlockProps & {
    onTrackItemViewEvent?: OnTrackItemViewEvent;
  }
> = ({ contentLink, link, image, isFeaturedLink, onTrackItemViewEvent }) => {
  const isLarge = useMediaQuery(ScreenSizeQueries.lg);
  const { text, ...linkProps } = link;

  if (linkProps.contentLink) {
    delete linkProps.contentLink;
  }

  const boxClasses = classnames(
    'group relative flex content-between items-center gap-4 overflow-hidden rounded-lg',
    {
      'border border-gray-light bg-gray-lightest transition hover:border-silver': !isFeaturedLink,
      'bg-black before:absolute before:inset-0 before:size-full before:bg-gradient before:transition before:hover:opacity-80':
        isFeaturedLink,
      'h-[70px] pr-6 md:h-20': !!image,
      'h-16 px-6': !image
    }
  );

  const handleOnClick = useCallback(() => {
    onTrackItemViewEvent?.(text);
  }, [onTrackItemViewEvent, text]);

  return (
    <Block
      onClick={handleOnClick}
      element={Link}
      contentLinkID={contentLink?.id}
      propertyName="Link"
      {...linkProps}
      className={boxClasses}
    >
      <div className="flex grow items-center gap-4"  data-component={"LinkBlock"}>
        {(image || isEditOrPreviewMode()) && (
          <div className="size-[70px] object-cover md:size-20">
            <ResponsiveImage
              src={image?.url}
              alt={`${image?.altText || image?.text}`}
              propertyName="Image"
              aspectRatio={1}
              imageSizes={{
                xs: '70px',
                md: '80px'
              }}
            />
          </div>
        )}
        <div>
          <span
            className={classnames('text-1 relative z-1 line-clamp-2 font-medium', {
              'text-white': isFeaturedLink
            })}
          >
            {text}
          </span>
        </div>
      </div>
      <Icon
        name={link?.target === '_blank' || isExternalUrl(link?.href) ? 'link-out' : 'chevron-right'}
        size={isLarge ? 'large' : 'medium'}
        className={isFeaturedLink ? 'z-1 text-white' : 'transition group-hover:text-red'}
      />
    </Block>
  );
};

export default LinkBlock;
