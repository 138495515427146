import React, { createContext, useReducer, useContext, ReactNode } from 'react';

type WalkthroughState = {
  currentTooltip: number;
  isCompleted: boolean;
  totalTooltips: number;
  isWalkthroughStarted: boolean;
  hasLibraryStep: boolean;
};

type WalkthroughAction =
  | { type: 'NEXT_TOOLTIP'; applyFor?: string }
  | { type: 'UPDATE_TOTAL_STEPS' }
  | { type: 'START_WALKTHROUGH' }
  | { type: 'DELETE_STEP'; applyFor?: string };

const initialState = (totalTooltips: number): WalkthroughState => ({
  currentTooltip: 0,
  isCompleted: false,
  isWalkthroughStarted: false,
  totalTooltips: totalTooltips,
  hasLibraryStep: true
});

const walkthroughReducer = (
  state: WalkthroughState,
  action: WalkthroughAction
): WalkthroughState => {
  switch (action.type) {
    case 'START_WALKTHROUGH':
      return { ...state, isWalkthroughStarted: true };
    case 'UPDATE_TOTAL_STEPS':
      return { ...state, totalTooltips: state.totalTooltips - 1 };
    case 'NEXT_TOOLTIP':
      if (action.applyFor === 'search' && !state.hasLibraryStep) {
        return { ...state, currentTooltip: 2 };
      }
      return { ...state, currentTooltip: state.currentTooltip + 1 };
    case 'DELETE_STEP':
      if (action.applyFor === 'library') {
        return { ...state, totalTooltips: state.totalTooltips - 1, hasLibraryStep: false };
      }
      return { ...state, totalTooltips: state.totalTooltips - 1 };
    default:
      return state;
  }
};

const WalkthroughContext = createContext<
  | {
      state: WalkthroughState;
      dispatch: React.Dispatch<WalkthroughAction>;
    }
  | undefined
>(undefined);

export const WalkthroughProvider = ({
  children,
  totalTooltips
}: {
  children: ReactNode;
  totalTooltips: number;
}) => {
  const [state, dispatch] = useReducer(walkthroughReducer, initialState(totalTooltips));

  return (
    <WalkthroughContext.Provider value={{ state, dispatch }}>
      {children}
    </WalkthroughContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useWalkthrough = () => {
  const context = useContext(WalkthroughContext);
  if (!context) {
    return;
  }
  return context;
};
