import Shimmer from '@/components/ui/Shimmer';
import BaseBox from '../../components/BaseBox';

interface LoadingProps {
  isMobile?: boolean;
}
export const Loading = ({ isMobile = false }: LoadingProps) => {
  return (
    <BaseBox sticky={!isMobile}>
      <BaseBox.Content>
        <Shimmer className="mb-10 mt-0 h-[342px] w-full rounded-md" />
        <div className="mb-10 mt-2 flex flex-row">
          <Shimmer className="h-5 w-[138px] lg:h-5" />
          <Shimmer className="ml-auto h-5 w-[73px]" />
        </div>
        <div className="flex size-full flex-col gap-10 pt-12 lg:gap-12 lg:pt-8">
          <div className="flex flex-col items-center gap-[25px] max-md:pt-2">
            <Shimmer className="h-[52px] w-full pb-1 lg:h-14" />
            <Shimmer className="h-[15px] w-[209px]" />
            <Shimmer className="h-[15px] w-[133px]" />
          </div>
        </div>
      </BaseBox.Content>
    </BaseBox>
  );
};

export default Loading;
