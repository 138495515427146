import { PodcastEpisodeProduct, PodcastEpisodeVariation } from '@/@types/content';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Button from '@/components/ui/Buttons/Button';
import classnames from 'classnames';

import Image from '@/components/cms/Image';
import React from 'react';
import Text from '@/components/cms/Text';
import RichText from '@/components/cms/RichText';
import { getProductImageUrl } from '@/components/ui/PDP/helpers';

type Props = PodcastEpisodeProduct & PodcastEpisodeVariation;

const PodcastEpisodeSlideBlock: React.FC<Props> = ({
  displayName,
  enrichedDescription,
  code,
  url,
  isNew
}) => {
  const isExtraSmall = useMediaQuery(ScreenSizeQueries.xsOnly);

  const badgeClasses = classnames(
    'text-2 w-fit rounded border border-white px-[10px] py-1 text-white'
  );

  const headingClasses = classnames('heading-4 line-clamp-4 text-white lg:heading-3 sm:min-h-16');

  const copyClasses = classnames('text-1 line-clamp-3 text-white max-lg:hidden');

  const linkContainerClasses = classnames('pt-8');

  const CarouselItemClasses = classnames(
    'relative flex max-w-[100vw] grow flex-col bg-teal-100 lg:mx-auto lg:flex-row lg:items-start lg:pt-[128px]'
  );

  const contentLayoutClasses = classnames('flex flex-col justify-between lg:justify-start');

  const mediaLayoutClasses = classnames(
    'mx-auto size-full pb-[88px] pt-11 sm:pb-[112px] lg:mx-0 lg:ml-4 lg:size-fit lg:pb-20 lg:pt-9'
  );

  return (
    <div className={CarouselItemClasses} data-component={'PodcastEpisodeSlideBlock'}>
      <div className="container flex flex-col justify-between lg:flex-row">
        <div className={contentLayoutClasses}>
          <div className="flex flex-col gap-4 pt-[204px] sm:max-w-[75%] lg:w-[630px] lg:max-w-[630px] lg:pt-0">
            {isNew && (
              <div className="flex h-5 items-center gap-2">
                <Text element="h2" propertyName="Heading" className={badgeClasses}>
                  New
                </Text>
              </div>
            )}
            {displayName && (
              <Text element="h2" propertyName="HeadlineText" className={headingClasses}>
                {displayName}
              </Text>
            )}
            {enrichedDescription && (
              <RichText content={enrichedDescription} className={copyClasses} />
            )}
          </div>
          {url && (
            <div className={classnames(linkContainerClasses, 'hidden lg:block')}>
              <Button
                propertyName="Link"
                color="white"
                href={url}
                target="_blank"
                label="See details"
                size="large"
              />
            </div>
          )}
        </div>
        <div className={mediaLayoutClasses}>
          <div className="relative size-full rounded-lg bg-gray-light">
            <Image
              className="aspect-video size-full min-h-[185px] rounded-lg object-cover sm:min-h-[405px] lg:h-[345px] lg:min-h-[345px] lg:max-w-[520px]"
              propertyName="BackGroundImage"
              src={getProductImageUrl(code!)}
              alt={displayName}
            />
          </div>
        </div>
      </div>

      {url && (
        <div className="absolute bottom-0 px-6 pb-6 pt-8 md:px-12 lg:hidden">
          <Button
            propertyName="Link"
            color="white"
            href={url}
            target="_blank"
            label="See details"
            size={isExtraSmall ? 'small' : 'large'}
          />
        </div>
      )}
    </div>
  );
};

export default PodcastEpisodeSlideBlock;
