import { useSearchParamsStable } from '@/hooks/useSearchParams';
import { useMemo } from 'react';
import { QUERY_PARAMS } from '../constants';

const useSearchSpecialParams = () => {
  const { searchParams } = useSearchParamsStable();

  const specialParams = useMemo(() => {
    const queryParams = Object.values(QUERY_PARAMS);
    const result: { [key: string]: string } = {};
    for (const [key, value] of searchParams.entries()) {
      if (!queryParams.includes(key)) {
        result[key] = value;
      }
    }
    return new URLSearchParams(result);
  }, [searchParams]);

  return {
    specialParams
  };
};

export default useSearchSpecialParams;
