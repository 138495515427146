import Modal from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';

type RemoveLibraryModalCardProps = {
  header?: string;
  modalHeader?: string;
  isOpen?: boolean;
  cardOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  onSaveButtonPress?: () => void;
  customModalId?: string;
};

const RemoveLibraryModalCard: React.FC<RemoveLibraryModalCardProps> = ({
  modalHeader,
  isOpen,
  cardOpenModal,
  onSaveButtonPress,
  customModalId
}) => {
  if (!isOpen) return null;

  return (
    <>
      {cardOpenModal && (
        <Modal open={isOpen} setOpen={cardOpenModal}>
          <ModalContent
            title={modalHeader}
            eyebrow={{ text: 'Remove from My Library', icon: 'delete', size: 'medium' }}
            saveButtonLabel="Yes, remove from My Library"
            onSaveButtonPress={onSaveButtonPress}
            dismissButtonLabel="Cancel"
            onDismissButtonPress={() => cardOpenModal(false)}
            customModalId={customModalId}
          />
        </Modal>
      )}
    </>
  );
};

export default RemoveLibraryModalCard;
