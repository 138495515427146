import Panel, { PanelFooterProps, PanelProps } from '@/components/ui/Panel';
import Button from '@/components/ui/Buttons/Button';
import { Submit } from '@radix-ui/react-form';

export type SubPanelProps = {
  titleSubPanel: React.ReactNode;
  triggerButton?: React.ReactNode;
  hasHorizontalPadding?: boolean;
  isL2Panel?: boolean;
  onBackButtonPress: () => void;
  onApplyPress?: () => void;
  footer?: React.ReactNode;
  panelDivider?: PanelFooterProps['divider'];
} & PanelProps;

const SubPanel: React.FC<SubPanelProps> = ({
  isL2Panel = false,
  isOpen,
  onOpenChange,
  triggerButton,
  titleSubPanel,
  children,
  theme,
  hasHorizontalPadding = true,
  onBackButtonPress,
  onApplyPress,
  size,
  footer,
  panelDivider = 'gradient'
}) => {
  return (
    <Panel isOpen={isOpen} onOpenChange={onOpenChange} theme={theme} size={size}>
      {triggerButton && <Panel.Trigger asChild>{triggerButton}</Panel.Trigger>}
      <div>
        <Panel.Content
          isL2Panel={isL2Panel}
          hasHorizontalPadding={hasHorizontalPadding}
          onBackButtonPress={onBackButtonPress}
        >
          <Panel.Body>
            {/* If the body has padding, the title doesn't need it */}
            <Panel.Title hasHorizontalPadding={!hasHorizontalPadding}>{titleSubPanel}</Panel.Title>

            {children}
          </Panel.Body>

          <Panel.Footer divider={panelDivider}>
            {footer || (
              <div className="flex h-full items-center">
                <Submit asChild>
                  <Button
                    onClick={onApplyPress}
                    label="Apply"
                    type="submit"
                    size="large"
                    className="w-full"
                  />
                </Submit>
              </div>
            )}
          </Panel.Footer>
        </Panel.Content>
      </div>
    </Panel>
  );
};

export default SubPanel;
