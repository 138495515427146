import classnames from 'classnames';

import Shimmer from '@/components/ui/Shimmer';

const SegmentListLoadingState = ({
  totalSegments = 3
}: {
  totalSegments?: number;
}): JSX.Element[] => {
  return Array.from({ length: totalSegments }).map((_, index: number, arr) => (
    <div
      key={index}
      className={classnames('flex w-full flex-row justify-between px-8 sm:items-center', {
        'mt-7': index === 0,
        'my-12': arr.length < 3 || (index > 0 && index < arr.length - 1),
        'mb-7': index === arr.length - 1
      })}
    >
      <div className="flex w-full flex-col items-start justify-start sm:flex-row sm:items-center">
        <div className="flex flex-row items-center">
          <Shimmer className="size-[20px] rounded-full sm:size-[36px]" />
          <Shimmer className="ml-3 h-[8px] w-[50px] sm:hidden" />
        </div>
        <div className="flex h-[40px] flex-col justify-evenly max-sm:mt-3 sm:ml-5 sm:h-[40px] sm:flex-col">
          <Shimmer className="w-[200px] max-sm:basis-1/3 sm:h-[10px] sm:w-[100px]" />
          <Shimmer className="w-[200px] basis-1/3 sm:h-[20px] sm:w-[500px]" />
        </div>
      </div>
      <div>
        <Shimmer className="min-w-[120px] max-sm:min-w-[90px] sm:h-[68px]" ratio={1} />
      </div>
    </div>
  ));
};

export default SegmentListLoadingState;
