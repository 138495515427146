import { SiteSettings } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';
import withNav from '@/components/hocs/withNav';
import Banner from '@/components/ui/Banner';
import CompliancePeriodMenu from '@credit-tracker/CompliancePeriodMenu';
import useCreditTracker from '@credit-tracker/hooks/useCreditTrackerHook';
import CertificatesSection from '@credit-tracker/Sections/CertificatesSection';
import LicenseHeader from '@credit-tracker/Sections/LicenseHeader';
import RequirementsSection from '@credit-tracker/Sections/RequirementsSection';
import TrackerHeader from '@credit-tracker/Sections/TrackerHeader';
import { CompliancePeriodValue, TrackerQueryParamKeys } from '@credit-tracker/util/constants';
import { getEmptyCertificate } from '@credit-tracker/util/TrackerHelpers';
import { creditTrackerLoading, creditTrackerFailure } from '@/redux/slices/creditTrackerSlice';
import React, { useState, useRef, useMemo, useCallback, useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import EmptyStateBlock from '@/components/blocks/EmptyStateBlock/EmptyStateBlock';
import { CreditTrackerContext } from '../blocks/CreditTracker/CreditTrackerContext';
import { CreditTrackerPage as CreditTrackerPageProps } from '@/@types/content';
import { useLocation } from 'react-router-dom';
import useQueryParams from '@/hooks/useQueryParams';
import { Helmet } from 'react-helmet';
import { trackAddCarryoverEvent, trackAddExternalEvent } from '@/analytics/creditTracker';
import { AnalyticsContext } from '@/analytics/constants';
const CreditModal = React.lazy(() => import('@credit-tracker/Modals/CreditModal'));
const SwitchExperienceModal = React.lazy(
  () => import('@credit-tracker/Modals/SwitchExperienceModal')
);
const AddNewLicenseModal = React.lazy(() => import('@credit-tracker/Modals/AddNewLicenseModal'));

const CreditTrackerPage: React.FC<CreditTrackerPageProps & SiteSettings> = ({
  disclaimer,
  emptyStateContent,
  name,
  carryoverDisclaimer,
  mainContentArea,
  totalCreditEarnedTooltip,
  deadlineMessageBanner,
  earnMoreCreditResultPage,
  searchPanelFailureMessage,
  searchPanelNoResultsMessage,
  apiFailureSection,
  contentLink,
  adminDashboardLink,
  browserTitle,
  dateCompletedOutsideOfRangeMessage
}) => {
  const { state: routerState } = useLocation();
  const dispatch = useDispatch();
  const headerElement = document.querySelector('header');
  const [showExternalCreditModal, setShowExternalCreditModal] = useState(false);
  const [showCarryoverCreditModal, setShowCarryoverCreditModal] = useState(false);
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);

  const [scrollToCertificates, setScrollToCertificates] = useState(false);
  const certificateSectionRef = useRef<HTMLDivElement>(null);

  const [regionShortDescription, setRegionShortDescription] = useState<string | null>(null);

  const params = useQueryParams();
  const individualSk = useMemo(() => params[TrackerQueryParamKeys.INDIVIDUAL_SK], [params]);
  const orgSk = useMemo(() => params[TrackerQueryParamKeys.ORG_SK], [params]);
  const region = useMemo(() => params[TrackerQueryParamKeys.REGION_KEY], [params]);

  const trackerHook = useCreditTracker(
    !!individualSk && !!orgSk
      ? {
          request: individualSk ? { individualSk: parseInt(individualSk) } : {},
          orgSk: parseInt(orgSk ?? '')
        }
      : {}
  );

  const {
    totalRequirement,
    trackerLoading,
    trackerError,
    isError,
    trackerData,
    setTrackerLoading,
    selectedClePeriod,
    earnMoreCreditPriority,
    selectedRegion,
    practiceAreas,
    transitionOptions,
    allSpecialStatuses,
    myCertificatesPageLink,
    updateSpecialStatus,
    setActiveRegion,
    isTransitionalOrNewlyAdmittedRegion,
    isLimitedModeLicense,
    showSwitchToExperienceModal,
    isMissingCompliancePeriod,
    experienceSwitchModalContent,
    selectedGlobalBanners,
    lastTrackerRequest,
    memsuiteUrl
  } = trackerHook;

  useEffect(() => {
    if (region && !trackerLoading) {
      setRegionShortDescription(region);
    }
  }, [region, setRegionShortDescription, trackerLoading]);

  const endDate = useMemo(
    () => selectedClePeriod?.endDate ?? selectedClePeriod?.defaultEndDate,
    [selectedClePeriod?.defaultEndDate, selectedClePeriod?.endDate]
  );

  const renderEmptyStateBlocks = useMemo(() => {
    return emptyStateContent?.map((a, i) => <EmptyStateBlock {...a} key={`empty-${i}`} />);
  }, [emptyStateContent]);

  const renderAPIErrorBlock = useMemo(() => {
    return apiFailureSection?.map((a, i) => <EmptyStateBlock {...a} key={`apiError-${i}`} />);
  }, [apiFailureSection]);

  const compliancePeriodValue: CompliancePeriodValue | null = useMemo(() => {
    const clePeriod = selectedRegion?.jurisdiction?.clePeriod;

    if (!clePeriod) return null;
    if (clePeriod.isActivelySelected) return 'current';
    if (clePeriod.nextPeriod?.isActivelySelected) return 'next';
    if (clePeriod.previousPeriod?.isActivelySelected) return 'previous';
    return null;
  }, [selectedRegion?.jurisdiction?.clePeriod]);

  const handleScrollToCertificates = useCallback(() => {
    const timeout = setTimeout(() => {
      const topPosition = certificateSectionRef.current
        ? certificateSectionRef.current.getBoundingClientRect().top + window.scrollY - 100
        : 0;

      window.scrollTo({
        top: topPosition,
        behavior: 'smooth'
      });

      setScrollToCertificates(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (scrollToCertificates && !trackerLoading) {
      handleScrollToCertificates();
    }
  }, [handleScrollToCertificates, scrollToCertificates, trackerLoading]);

  useEffect(() => {
    if (trackerLoading) {
      dispatch(creditTrackerLoading(true));
    } else {
      dispatch(creditTrackerLoading(false));
    }
  }, [dispatch, trackerLoading]);

  useEffect(() => {
    trackerError && dispatch(creditTrackerFailure(trackerError));
  }, [trackerError, dispatch]);

  useEffect(() => {
    // handle deep linking to a specific region via router state
    if (routerState?.region) {
      setRegionShortDescription(routerState.region);
    } else if (selectedRegion?.creditRegionShortDescription) {
      setRegionShortDescription(selectedRegion?.creditRegionShortDescription);
    }
  }, [routerState?.region, selectedRegion?.creditRegionShortDescription]);

  useEffect(() => {
    if (regionShortDescription) {
      setActiveRegion(regionShortDescription);
    }
  }, [regionShortDescription, setActiveRegion]);

  const onAddNewLicense = useCallback((region?: string) => {
    region && setRegionShortDescription(region);
  }, []);

  return (
    <CreditTrackerContext.Provider
      value={{
        ...trackerHook,
        lastTrackerRequest: [lastTrackerRequest],
        allTrackerData: trackerData,
        isLoading: trackerLoading,
        specialStatuses: allSpecialStatuses,
        earnMoreCreditResultPage,
        setIsLoading: setTrackerLoading,
        globalBanners: selectedGlobalBanners,
        isAdminRequest: !!trackerHook.isAdminRequest,
        contentLink: contentLink
      }}
    >
      <Helmet>
        <title>{browserTitle ?? name}</title>
      </Helmet>
      <TrackerHeader
        key={`${selectedRegion?.creditRegionName}-header`}
        setActiveRegion={setActiveRegion}
        setShowCarryoverCreditModal={setShowCarryoverCreditModal}
        setShowExternalCreditModal={setShowExternalCreditModal}
        setShowAddLicenseModal={setShowAddLicenseModal}
        name={name}
        isSetup={!trackerLoading && !selectedRegion?.jurisdiction && !isError}
        isError={isError}
        memsuiteUrl={memsuiteUrl}
        individualSk={individualSk}
      />
      <Suspense fallback={<></>}>
        {!!showAddLicenseModal && (
          <AddNewLicenseModal
            open={showAddLicenseModal}
            setOpen={setShowAddLicenseModal}
            onLicenseSave={onAddNewLicense}
          />
        )}
      </Suspense>
      {isError ? (
        renderAPIErrorBlock
      ) : !trackerLoading && !selectedRegion?.jurisdiction ? (
        renderEmptyStateBlocks
      ) : (
        <>
          <Suspense fallback={<div></div>}>
            <CreditModal
              key={`${selectedRegion?.creditRegionName}-externalCreditModal`}
              open={showExternalCreditModal}
              setOpen={() => setShowExternalCreditModal(false)}
              eyebrowText={selectedRegion?.creditRegionLongDescription ?? ''}
              isCarryover={false}
              data={getEmptyCertificate(false)}
              scrollToCertificates={() => setScrollToCertificates(true)}
              dateCompletedOutOfRangeMessage={dateCompletedOutsideOfRangeMessage}
              trackAddAnalyticsEvent={() => {
                trackAddExternalEvent(AnalyticsContext.CreditTracker);
              }}
            />
          </Suspense>
          <Suspense fallback={<div></div>}>
            <CreditModal
              key={`${selectedRegion?.creditRegionName}-carryoverModal`}
              open={showCarryoverCreditModal}
              setOpen={() => setShowCarryoverCreditModal(false)}
              eyebrowText={selectedRegion?.creditRegionLongDescription ?? ''}
              disclaimer={carryoverDisclaimer}
              data={getEmptyCertificate(true)}
              scrollToCertificates={() => setScrollToCertificates(true)}
              trackAddAnalyticsEvent={() => {
                trackAddCarryoverEvent(AnalyticsContext.CreditTracker);
              }}
              isCarryover
            />
          </Suspense>
          <Suspense fallback={<div></div>}>
            {!!showSwitchToExperienceModal && (
              <SwitchExperienceModal
                key={`${selectedRegion?.creditRegionName}-switchExpModal`}
                isOpen={true}
                onUpdateExperience={updateSpecialStatus}
                content={experienceSwitchModalContent ?? {}}
              />
            )}
          </Suspense>
          <div className="mb-6">
            <CompliancePeriodMenu
              isTransitionalOrNewlyAdmittedRegion={isTransitionalOrNewlyAdmittedRegion}
              headerHeight={headerElement?.clientHeight || 0}
              totalRequirement={totalRequirement}
              selectedClePeriod={selectedClePeriod}
            />
            <div className="container module-spacing !pt-6">
              <LicenseHeader
                key={`${selectedRegion?.creditRegionShortDescription}-header`}
                creditsEarned={totalRequirement?.earned ?? 0}
                creditsRequired={totalRequirement?.required ?? 0}
                creditsCounted={totalRequirement?.counted ?? 0}
                isLimitedMode={isLimitedModeLicense}
                transitionOptions={transitionOptions}
                onSpecialStatusChange={updateSpecialStatus}
                totalCreditEarnedTooltip={totalCreditEarnedTooltip}
                deadlineMessageBanner={deadlineMessageBanner?.[0]}
                isUserInfoIncomplete={!!selectedClePeriod?.isUserInfoIncomplete}
                adminMemberEmail={trackerHook?.adminMemberEmailAddress ?? ''}
                adminDashboardLink={adminDashboardLink ?? undefined}
              />
              {!trackerLoading && (
                <ContentArea
                  id="trackerMainContent"
                  components={mainContentArea}
                  className="[&>.container]:p-0"
                  propertyName="MainContentArea"
                />
              )}
              {(trackerLoading || selectedRegion?.jurisdiction) && !isMissingCompliancePeriod && (
                <>
                  <div className="module-spacing flex flex-col gap-y-6">
                    <RequirementsSection
                      key={`${selectedRegion?.creditRegionShortDescription}-requirements`}
                      complianceEndDate={endDate ? new Date(endDate) : undefined}
                      practiceAreas={practiceAreas}
                      earnMoreCreditPriority={earnMoreCreditPriority}
                      selectedCompliancePeriod={compliancePeriodValue}
                      failureMessage={searchPanelFailureMessage}
                      noResultsMessage={searchPanelNoResultsMessage}
                    />
                  </div>
                  <div ref={certificateSectionRef}>
                    <CertificatesSection
                      key={`${selectedRegion?.creditRegionShortDescription}-certificates`}
                      debounceLoading={trackerLoading}
                      carryoverDisclaimer={carryoverDisclaimer}
                      myCertificatesPageLink={myCertificatesPageLink ?? ''}
                      isTransitionalOrNewlyAdmitted={isTransitionalOrNewlyAdmittedRegion}
                      individualSk={individualSk}
                      dateCompletedOutOfRangeMessage={dateCompletedOutsideOfRangeMessage}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {!trackerLoading && disclaimer && (
        <div className="container">
          <Banner
            bannerType="tertiary"
            contents={[
              {
                icon: 'alert',
                headingText: 'Disclaimer',
                body: disclaimer,
                bannerType: 'tertiary'
              }
            ]}
          />
        </div>
      )}
      <ContentArea
        id="trackerMainContent"
        components={mainContentArea}
        propertyName="MainContentArea"
      />
    </CreditTrackerContext.Provider>
  );
};

const CreditTrackerPageComponent = withNav(CreditTrackerPage);
export default CreditTrackerPageComponent;
