import { useState } from 'react';

export const ConfirmRemoveButtonToast: React.FC<{
  onClickFunction: () => void;
  message: string;
}> = ({ onClickFunction, message }) => {
  const [isUndoDisabled, setIsUndoDisabled] = useState(false);
  const handleClick = () => {
    setIsUndoDisabled(true);
    onClickFunction();
  };

  return (
    <>
      {message}
      <button className="underline" onClick={handleClick} disabled={isUndoDisabled}>
        {' '}
        Undo
      </button>
    </>
  );
};
