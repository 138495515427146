// import type { PracticeAreaPage as PracticeAreaPage, SiteSettings } from '@/@types/content';
import type { SiteSettings } from '@/@types/content';
// import ContentArea from '../cms/ContentArea';
import withNav from '../hocs/withNav';
import { BreadcrumbProps } from '../ui/Breadcrumbs';
import SecondaryTaxonomyBlock from '../blocks/PracticeArea/SecondaryTaxonomyBlock';
import PracticeAreaMasthead from '../blocks/PracticeArea/PracticeAreaMasthead';

// TODO Commenting out to fix check build
type PracticeAreaPageProps = /*PracticeAreaPage &*/ {
  breadcrumbs: BreadcrumbProps[];
  pageContext: { contentLink: { id: number }[] };
};

const PracticeAreaPageComponent: React.FC<PracticeAreaPageProps & SiteSettings> = props => {
  let practiceAreaId, practiceAreaName, practiceAreaDescription;
  if ('pageContext' in props) {
    // TODO Commenting out to fix check build
    // practiceAreaId = props.pageContext[0].contentLink?.id;
    // practiceAreaName = props.pageContext[0].name;
    // if ('overview' in props.pageContext[0]) {
    //   practiceAreaDescription = (props.pageContext[0].overview as string) || '';
    // }
  }

  return (
    <>
      <PracticeAreaMasthead
        breadcrumbs={props.breadcrumbs}
        pageTitle={practiceAreaName}
        copy={practiceAreaDescription}
      />
      <SecondaryTaxonomyBlock
        pageContentLinkId={practiceAreaId}
        viewMoreLabel="See More"
        viewLessLabel="See Less"
      />
      {/* TODO Commenting out to fix check build */}
      {/* <ContentArea
        components={props.mainContentArea}
        componentsProps={{ breadcrumbs: [...props.breadcrumbs] }}
        propertyName="MainContentArea"
      /> */}
    </>
  );
};

const PracticeAreaPage = withNav(PracticeAreaPageComponent);
export default PracticeAreaPage;
