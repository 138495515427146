import { forwardRef } from 'react';
import classnames from 'classnames';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';

interface CmsLinkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  propertyName?: string;
}

const CmsButton = forwardRef<HTMLButtonElement, CmsLinkProps>(
  ({ propertyName, className, children, ...props }, ref) => {
    const isEditMode = isEditOrPreviewMode();

    if (isEditMode && propertyName) {
      return (
        <span data-epi-edit={propertyName} className={classnames(className, 'relative')}>
          {children}
        </span>
      );
    }

    if (!isEditMode && !children) return null;

    return (
      <button {...props} ref={ref} className={className}>
        {children}
      </button>
    );
  }
);

export default CmsButton;
