import { IFacetGroup, IFacetResultItem, SearchRequest, SearchResponse } from '@/@types/client-api';
import { AdHocFilter, FILTERS, FacetFilter, InvariantFilter, PAGE_SIZE } from '../constants';
import { isStartEndDateFilter } from '../../Filter/DateFilterUtils';
import { FacetGroupOverrides } from './searchParams';

export type SearchRequestOptions = {
  invariantFilters?: InvariantFilter[];
  facetFilters?: FacetFilter[];
  overrides?: Partial<SearchRequest>;
  facetOverrides?: FacetGroupOverrides;
  itemClasses?: number[];
  adHocFilters?: AdHocFilter[];
};

// Reduce payload to its minimal state so we can use prior cached requests
export const minimizeSearchRequest = ({ facetGroups, ...rest }: SearchRequest): SearchRequest => {
  const facetGroupsToSend = facetGroups
    ?.flatMap(group => {
      // We only need to send an empty group if it is unfolded
      if (!group.facets?.length && !group.unfolded) return [];

      return [
        {
          ...group,
          // sort to prevent cache issues
          facets: (group.facets ? [...group.facets] : []).sort(
            (a, b) => a.value?.localeCompare(b.value ?? '') ?? 0
          )
        }
      ];
    })
    // sort to prevent cache issues
    .sort((a, b) => a.groupTypeId?.localeCompare(b.groupTypeId ?? '') ?? 0);

  if (!rest.sort) {
    delete rest.sort;
  }

  if (!rest.returnMarketingModules) {
    delete rest.returnMarketingModules;
  }

  if (!rest.explain) {
    delete rest.explain;
  }

  return {
    ...(facetGroupsToSend?.length ? { facetGroups: facetGroupsToSend } : {}),
    ...rest
  };
};

const getFacet = ({
  groupTypeId,
  value,
  checked,
  name,
  overrideIsExclusive
}: {
  groupTypeId?: string | null;
  value?: string | null;
  checked?: boolean;
  name?: string | null;
  overrideIsExclusive?: boolean | null;
}) => {
  return name && groupTypeId === FILTERS.PRACTICE_AREA
    ? { value, checked, name }
    : groupTypeId === FILTERS.CREDIT_TYPE && overrideIsExclusive
      ? { value, checked, overrideIsExclusive }
      : { value, checked };
};

export const getFacetGroups = (
  facetGroups: SearchRequest['facetGroups'],
  { adHocFilters, invariantFilters, facetOverrides }: Omit<SearchRequestOptions, 'overrides'>
): IFacetGroup[] => {
  const facetGroupsToSend: IFacetGroup[] = [];

  const facetGroupsToIterateOver = new Set<string>(
    [
      ...(facetGroups?.map(group => group.groupTypeId) ?? []),
      ...(adHocFilters?.map(adHocFilter => adHocFilter.groupTypeId) ?? []),
      ...(invariantFilters?.map(invariantFilter => invariantFilter.facetGroupType) ?? [])
    ].filter((s): s is string => !!s)
  );

  facetGroupsToIterateOver.forEach(groupId => {
    const facetsToSend: IFacetResultItem[] = [];

    // Start with the group from the main facet data
    const group = facetGroups?.find(group => group.groupTypeId === groupId);
    if (group?.groupTypeId === FILTERS.SUGGESTED_FILTERS) return;
    group?.facets?.forEach(facet => {
      if (group.groupTypeId === FILTERS.DATERANGE) {
        if (facet.value && facet.name && isStartEndDateFilter(facet.name)) {
          facetsToSend.push({
            value: facet.value,
            name: facet.name
          });
        }
      } else if (facet.checked && !facet.isDefault) {
        facetsToSend.push(
          getFacet({
            groupTypeId: group.groupTypeId,
            value: facet.value,
            checked: facet.checked,
            name: facet.name,
            overrideIsExclusive: facet?.overrideIsExclusive
          })
        );
      }
    });

    // Add in any adhoc filters that are not in the facet list
    adHocFilters?.forEach(adHocFilter => {
      if (
        adHocFilter.groupTypeId !== groupId ||
        facetsToSend.some(facet => facet.value === adHocFilter.value)
      ) {
        return;
      }

      facetsToSend.push(
        getFacet({
          groupTypeId: adHocFilter.groupTypeId,
          value: adHocFilter.value,
          checked: false,
          name: adHocFilter.name
        })
      );
    });

    // Add in any invariant filters that are not in the facet list
    invariantFilters?.forEach(invariantFilter => {
      if (invariantFilter.facetGroupType !== groupId) {
        return;
      }

      const facet = facetsToSend.find(facet => facet.value === invariantFilter.value);
      if (facet && facet.value === invariantFilter.value) {
        facet.checked = true;
      } else {
        facetsToSend.push(
          getFacet({
            groupTypeId: invariantFilter.facetGroupType,
            value: invariantFilter.value,
            checked: true,
            name: invariantFilter.name
          })
        );
      }
    });

    facetGroupsToSend.push({
      groupTypeId: groupId,
      facets: facetsToSend,
      ...facetOverrides?.[groupId]
    });
  });

  return facetGroupsToSend;
};

const addQueryAndOverrides = (
  searchRequest: SearchRequest,
  overrides?: Partial<SearchRequest>
): SearchRequest => {
  const out = {
    ...searchRequest,
    ...overrides
  };

  const podcastType = 'PodcastAndRelatedMedia';

  const processFacetGroups = (
    facetGroups: IFacetGroup[] | undefined
  ): IFacetGroup[] | undefined => {
    return facetGroups?.map(facetGroup => {
      if (facetGroup.groupTypeId === FILTERS.FORMAT && facetGroup.facets) {
        return {
          ...facetGroup,
          facets: facetGroup.facets.map(facet => {
            if (facet.value === podcastType) {
              return {
                ...facet,
                checked: false
              };
            }
            return facet;
          })
        };
      }
      return facetGroup;
    });
  };

  // we need to remove podcast from the equation if jurisdictions are expected in the response
  if (
    overrides &&
    overrides?.filterGroupsToReturn?.includes(FILTERS.JURISDICTIONS) &&
    out.pageSize === 0 &&
    out.facetGroups?.some(
      facetGroup =>
        facetGroup.groupTypeId === FILTERS.FORMAT &&
        facetGroup.facets?.some(facet => facet.checked && facet.value === podcastType)
    )
  ) {
    out.facetGroups = processFacetGroups(out.facetGroups);
  }

  // BE will throw an error if we send returnMarketingModules without a query
  if (out.returnMarketingModules && !out.query) {
    delete out.returnMarketingModules;
  }

  return out;
};

export const processSearchRequest = (
  searchRequest: SearchRequest,
  { overrides, ...rest }: SearchRequestOptions
): SearchRequest => {
  const facetGroups = getFacetGroups(searchRequest.facetGroups, rest);
  return addQueryAndOverrides(minimizeSearchRequest({ ...searchRequest, facetGroups }), overrides);
};

export const searchDataToSearchRequestBody = ({
  searchResultSummary,
  facetResults,
  sortResults
}: SearchResponse): SearchRequest => {
  return {
    currentPage: searchResultSummary?.currentPage,
    pageSize: PAGE_SIZE,
    facetGroups: facetResults?.facetGroups,
    sort:
      sortResults?.sortResultItems?.find(sortItem => sortItem.selected && !sortItem.isDefault)
        ?.value ?? null
  };
};
