import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { RootState } from '@/redux/store';
import classnames from 'classnames';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export interface BaseBoxCardProps {
  children: React.ReactNode;
  className?: string;
}

const Card: React.FC<BaseBoxCardProps> = ({ children, className }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const header = useSelector((state: RootState) => state.page.header);
  const isLarge = useMediaQuery(ScreenSizeQueries.lg);

  // Due to the existing "workaround" that makes the ecom box not obscured by the header when the header is visible by shifting it down (see BaseBox.tsx),
  // this "workaround" is needed to shift the Card up when header is visible on screen, so the Card is visible and correctly positioned.
  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    if (header.isInView && isLarge) {
      container.style.marginBottom = `${header.inViewOffset}px`;
    } else {
      container.style.marginBottom = '0px';
    }
  }, [isLarge, header.inViewOffset, header.isInView]);

  return (
    <div
      ref={containerRef}
      className={classnames(
        'duration-[300ms] shrink-0 border-t border-gray-light transition-all',
        className
      )}
      data-component={"Card"}
    >
      {children}
    </div>
  );
};

export default Card;
