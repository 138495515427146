import React, { ComponentProps } from 'react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import MenuItem, { MenuItemProps } from './Menu/MenuItem';
import MenuContent from './Menu/MenuContent';
import MenuDrawer from './Menu/MenuDrawer';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

export type TDropdownMenuButtonProps = { isOpen: boolean };
export type TDropdownMenuItem = MenuItemProps | { separator: true };

type TDropdownMenuProps = {
  mobileFooterContent?: ComponentProps<typeof MenuContent>['mobileFooterContent'];
  items?: TDropdownMenuItem[];
  align?: ComponentProps<typeof RadixDropdownMenu.Content>['align'];
  button: (props: TDropdownMenuButtonProps) => JSX.Element;
  isModal?: boolean;
};

const MENU_STYLE = 'standalone';

const DropdownMenu = ({
  button,
  items,
  align = 'start',
  mobileFooterContent,
  isModal = false
}: TDropdownMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const isMobile = useMediaQuery(ScreenSizeQueries.xsOnly);

  return (
    <RadixDropdownMenu.Root
      modal={isMobile ? true : isModal}
      open={isOpen}
      onOpenChange={value => setIsOpen(value)}
    >
      <RadixDropdownMenu.Trigger asChild onPointerDown={() => setIsOpen(v => !v)}>
        {button({ isOpen })}
      </RadixDropdownMenu.Trigger>
      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content align={align} asChild>
          <MenuDrawer onClose={() => setIsOpen(false)} isOpen={isOpen}>
            <MenuContent
              menuStyle={MENU_STYLE}
              mobileFooterContent={mobileFooterContent}
              onClose={() => setIsOpen(false)}
            >
              {items?.map((item, i) => {
                if ('separator' in item)
                  return (
                    <RadixDropdownMenu.Separator asChild key={i}>
                      <div className="my-2 h-px bg-gray-light max-sm:hidden" />
                    </RadixDropdownMenu.Separator>
                  );

                return (
                  <RadixDropdownMenu.Item asChild key={item.key || i}>
                    <MenuItem menuStyle={MENU_STYLE} {...item} />
                  </RadixDropdownMenu.Item>
                );
              })}
            </MenuContent>
          </MenuDrawer>
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};

export default DropdownMenu;
