import { colors } from '@/constants/tailwind';
import classnames from 'classnames';

export type IPercentCircleProps = {
  percentCompleted?: number;
  playable?: boolean;
};

const size = 32;
const strokeWidth = 4;
const radius = size / 2 - strokeWidth / 2;
const circumference = 2 * Math.PI * radius;

const PercentCircle = ({ percentCompleted = 0, playable = true }: IPercentCircleProps) => {
  const isComplete = percentCompleted === 100;
  const notStarted = percentCompleted === 0;
  const altText = 'percent, play segment';

  const circlePath =
    'M16 2C17.8385 2 19.659 2.36212 21.3576 3.06569C23.0561 3.76925 24.5995 4.80049 25.8995 6.10051C27.1995 7.40053 28.2308 8.94387 28.9343 10.6424C29.6379 12.341 30 14.1615 30 16C30 17.8385 29.6379 19.659 28.9343 21.3576C28.2308 23.0561 27.1995 24.5995 25.8995 25.8995C24.5995 27.1995 23.0561 28.2307 21.3576 28.9343C19.659 29.6379 17.8385 30 16 30C14.1615 30 12.341 29.6379 10.6424 28.9343C8.94386 28.2307 7.40052 27.1995 6.1005 25.8995C4.80048 24.5995 3.76924 23.0561 3.06568 21.3576C2.36211 19.659 1.99999 17.8385 2 16C2 14.1615 2.36212 12.341 3.06569 10.6424C3.76925 8.94387 4.80049 7.40052 6.10051 6.1005C7.40053 4.80048 8.94388 3.76925 10.6424 3.06568C12.341 2.36212 14.1615 2 16 2L16 2Z';

  const playButtonPath = 'M13 11V21L21 16L13 11Z';

  const checkMarkPath = 'M10 15.6364L14.5882 20L23 12';

  const strokeDashArray = [(percentCompleted * circumference) / 100, circumference].join(',');

  return (
    <div       data-component={"PercentCircle"}
    className="size-5 fill-none sm:size-6 lg:size-8">
      <svg
        role="img"
        viewBox={`0 0 ${size} ${size}`}
        aria-label={`${percentCompleted.toFixed(0).toString()} ${altText}`}
      >
        <path
          d={circlePath}
          className="stroke-black/10"
          strokeWidth={strokeWidth}
          strokeDasharray="100,101"
        />
        <path
          d={circlePath}
          className={classnames('stroke-serpentine', { 'stroke-black/10': notStarted })}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDashArray}
        />

        {playable && (
          <>
            {isComplete ? (
              <path
                d={checkMarkPath}
                stroke={colors.serpentine.DEFAULT}
                strokeWidth={strokeWidth}
              />
            ) : (
              <path
                d={playButtonPath}
                fill={notStarted ? colors.gray.DEFAULT : colors.serpentine.DEFAULT}
              />
            )}
          </>
        )}
      </svg>
    </div>
  );
};

export default PercentCircle;
