import Text from '@/components/cms/Text';
import RichText from '../cms/RichText';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import classnames from 'classnames';
import { FacultyBioBlock as FacultyBioBlockProps } from '@/@types/content';
import Block from '../cms/Block';

const FacultyBioBlock: React.FC<FacultyBioBlockProps> = ({
  contentLink,
  heading,
  mainContent,
  workplace,
  title,
  location
}) => {
  const largeScreen = useMediaQuery(ScreenSizeQueries.belowLg);
  const hasDetails = workplace || title || location;
  let detailsBlock;

  const listItemClasses = classnames('flex flex-col');
  const listItemHeaderClasses = classnames('label text-gray-darker');
  const listItemContentClasses = classnames('text-1 text-charcoal');

  const mainContentClasses = classnames({
    'w-full': !hasDetails,
    'lg:w-[70%]': hasDetails
  });

  if (hasDetails) {
    detailsBlock = (
      <div className="mb-10">
        <Text element="h3" className="heading-5">
          Details
        </Text>
        <ul className="mt-8 flex flex-col gap-8">
          {workplace && (
            <li className={listItemClasses}>
              <Text className={listItemHeaderClasses}>Workplace</Text>
              <Text className={listItemContentClasses}>{workplace}</Text>
            </li>
          )}
          {title && (
            <li className={listItemClasses}>
              <Text className={listItemHeaderClasses}>Title</Text>
              <Text className={listItemContentClasses}>{title}</Text>
            </li>
          )}
          {location && (
            <li className={listItemClasses}>
              <Text className={listItemHeaderClasses}>Location</Text>
              <Text className={listItemContentClasses}>{location}</Text>
            </li>
          )}
        </ul>
      </div>
    );
  }

  return (
    <Block
      contentLinkID={contentLink?.id}
      className="container my-12 lg:my-16 lg:flex lg:justify-between"
    >
      {largeScreen && detailsBlock}
      <div className={mainContentClasses}>
        {heading && (
          <Text element="h2" className="heading-5 mb-8">
            {heading}
          </Text>
        )}
        {mainContent && <RichText content={mainContent} />}
      </div>
      {!largeScreen && detailsBlock}
    </Block>
  );
};

export default FacultyBioBlock;
