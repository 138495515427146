import { Variant } from '@/redux/slices/pdpSlice';
import VariantSelector from './Variant';
import Icon from '../../../../Icon';

interface VariantsGroupProps {
  icon: string;
  label: string;
  variants?: Array<Variant>;
  selectedVariant?: Variant;
  waitlistLabel?: string;
  onSelect: (variant: Variant) => void;
}

const VariantsGroup: React.FC<VariantsGroupProps> = ({
  icon,
  label,
  variants,
  selectedVariant,
  waitlistLabel,
  onSelect
}) => {
  if (variants?.length === 0) return null;

  return (
    <div>
      <p className="text-2  flex items-center gap-2 font-bold ">
        <Icon name={icon} />
        {label}
      </p>
      <div className="mt-6 flex flex-col gap-3 ">
        {variants?.map(variant => (
          <VariantSelector
            key={variant.code}
            location={variant.location}
            startDate={variant.eventStartDate}
            endDate={variant.eventEndDate}
            isLive={variant.isLive}
            timeZone={variant.timeZoneIdentifier}
            subLabel={variant.waitlist ? waitlistLabel : undefined}
            selected={variant.code === selectedVariant?.code}
            onSelect={() => onSelect(variant)}
          />
        ))}
      </div>
    </div>
  );
};

export default VariantsGroup;
