import Divider from '../Divider';
import SearchResultsLoadingState from './SearchResultsLoadingState';
import Button from '../Buttons/Button';
import { useEffect, useRef, useState } from 'react';
import { PAGE_SIZE } from './constants';

type SearchResultsLoadMoreProps = {
  isLoadingMore: boolean;
  remainingResultsCount: number;
  onLoadMore: () => void;
};

const SearchResultsLoadMore = ({
  isLoadingMore,
  remainingResultsCount,
  onLoadMore
}: SearchResultsLoadMoreProps): JSX.Element => {
  const loadingStateDiv = useRef<HTMLDivElement>(null);

  const [maxHeight, setMaxHeight] = useState<number>(0);

  useEffect(() => {
    const height = loadingStateDiv.current?.clientHeight ?? 0;
    setMaxHeight(isLoadingMore ? height : 0);
  }, [isLoadingMore]);

  return (
    <>
      <div
        className="max-h-0 overflow-hidden transition-[max-height] duration-short ease-out"
        style={{ maxHeight }}
      >
        {isLoadingMore && (
          <div ref={loadingStateDiv}>
            <SearchResultsLoadingState
              resultsCount={remainingResultsCount >= PAGE_SIZE ? PAGE_SIZE : remainingResultsCount}
            />
            <Divider color="light" />
          </div>
        )}
      </div>
      {remainingResultsCount > 0 && (
        <div className="flex justify-center pt-12">
          <Button onClick={onLoadMore} label="Load More" size="large" disabled={isLoadingMore} />
        </div>
      )}
    </>
  );
};

export default SearchResultsLoadMore;
