import Shimmer from '@/components/ui/Shimmer';

type PersonalInfoShimmerProps = {
  numItems?: number;
};

const PersonalInfoShimmer: React.FC<PersonalInfoShimmerProps> = ({ numItems = 6 }) => {
  return (
    <section className="container gridPLI w-full gap-y-8 py-10">
      <div className="col-span-full">
        <div className="gridPLI">
          <div className="col-span-2 sm:col-span-3 md:col-span-4 lg:col-span-3">
            <Shimmer className="h-7 w-full" />
          </div>
          <div className="col-span-2 flex justify-end sm:col-span-5 md:col-span-8 lg:col-span-9">
            <Shimmer className="h-7 w-[52px] sm:w-[87px]" />
          </div>
        </div>
      </div>
      {[...Array(numItems)].map((_, index) => (
        <div
          key={`personal-info-shimmer-${index}`}
          className="col-span-4 pb-12 sm:col-span-4 md:col-span-6 lg:col-span-4"
        >
          <Shimmer className="h-[22px] w-3/12 lg:w-[calc(23%-var(--grid-gap-half))]" />
          <Shimmer className="mt-3 h-[22px] w-7/12 lg:w-[calc(50%-var(--grid-gap-half))]" />
        </div>
      ))}
    </section>
  );
};

export default PersonalInfoShimmer;
