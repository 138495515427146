import { ICreditRequirement } from '@/@types/client-api';
import { ErrorState } from '@/utils/validations';

export const MAX_EARN_MORE_ITEMS = 10;
export const trackerCookieNameKey = 'CreditTrackerStoredData';

export const LIMIT_TEXT = '1 file (max. 5 MB), PDF, JPG, or PNG.';
export const ACCEPTABLE_MIME_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];
export const MAX_NUM_OF_FILES = 1;
export const MAX_BYTE_FILE_SIZE = 5242880;

export enum FieldNames {
  PROGRAM_NAME = 'programName',
  PROVIDER = 'provider',
  DATE_COMPLETED = 'dateCompleted',
  PROGRAM_FORMAT = 'programFormat',
  CREDIT_TYPE_FORMAT = 'creditTypeFormat',
  CREDIT_TYPE_EARNED = 'creditTypeEarned',
  TRANSITIONAL = 'transitional',
  CARRYOVER_PERIOD = 'carryoverPeriod'
}

export interface FieldErrors {
  [FieldNames.PROGRAM_NAME]?: ErrorState;
  [FieldNames.PROVIDER]?: ErrorState;
  [FieldNames.DATE_COMPLETED]?: ErrorState;
  [FieldNames.PROGRAM_FORMAT]?: ErrorState;
  [FieldNames.CREDIT_TYPE_FORMAT]?: ErrorState;
  [FieldNames.CREDIT_TYPE_EARNED]?: ErrorState;
  [FieldNames.TRANSITIONAL]?: ErrorState;
  [FieldNames.CARRYOVER_PERIOD]?: ErrorState;
}

export enum ComplianceFieldNames {
  COMPLIANCE_GROUP = 'complianceGroup',
  BIRTH_MONTH = 'birthMonth',
  BIRTH_DAY = 'birthDay',
  ADMIT_DATE = 'admitDate',
  ADMIT_YEAR = 'admitYear',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  DATE_INTERVAL = 'dateInterval'
}

export type ComplianceFieldErrors = {
  [ComplianceFieldNames.COMPLIANCE_GROUP]?: ErrorState;
  [ComplianceFieldNames.BIRTH_MONTH]?: ErrorState;
  [ComplianceFieldNames.BIRTH_DAY]?: ErrorState;
  [ComplianceFieldNames.ADMIT_DATE]?: ErrorState;
  [ComplianceFieldNames.ADMIT_YEAR]?: ErrorState;
  [ComplianceFieldNames.START_DATE]?: ErrorState;
  [ComplianceFieldNames.END_DATE]?: ErrorState;
  [ComplianceFieldNames.DATE_INTERVAL]?: ErrorState;
};

export type CompliancePeriodValue = 'previous' | 'current' | 'next';
export type TrackerRouterState = { regionSk?: number };
export enum CertificateLink {
  'Edit' = 'Edit',
  'DownloadCertificate' = 'Download certificate',
  'Requested' = 'Requested'
}
export type TrackerSearchQueryRecord = Record<string, Array<ICreditRequirement>>;
export type SpecialStatusSelector = {
  text: string;
  value: string;
};

export type TrackerAccordionMap = {
  [key: string]: boolean;
};

export const defaultDonutColors = {
  startColor: '#01C365',
  stopColor: '#026C39',
  circleColor: '#024D29'
};

export const warningDonutColors = {
  startColor: '#FF5C00',
  stopColor: '#CF4B00',
  circleColor: '#9A371D'
};

export type UserComplianceInformation = {
  userAdmitDate?: Date;
  userBirthDate?: Date;
};

export enum ComplianceModalMessage {
  CALCULATED_DATE = 'PLI calculates the start and end date of your compliance period according to the rules in your jurisdiction.',
  DISCLAIMER = 'The credits displayed may not be accurate if you do not enter your exact compliance period.'
}

export enum ComplianceMenuMessages {
  NO_COMPLIANCE_PERIOD_SETUP = 'No compliance period'
}

export enum TrackerQueryParamKeys {
  REGION_KEY = 'region',
  CREDIT_TYPES = 'creditTypes',
  FORMATS = 'formats',
  INDIVIDUAL_SK = 'individualSk',
  ORG_SK = 'orgSk'
}

export type CertificateActionTypes = 'add' | 'edit';
