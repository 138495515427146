import type { SnippetProps } from '@/components/ui/Snippet/Snippet';
import type { SnippetExpandedContentProps } from '@/components/ui/Snippet/SnippetExpandedContent';

import type { RenderSnippetProps } from './types';
import { getFullImageUrl } from './helpers';

const renderFacultyAndAuthors = ({ data }: RenderSnippetProps): SnippetProps => {
  const {
    productCode,
    fullName,
    firstName,
    lastName,
    imageUrl,
    affiliation,
    title,
    summaryText,
    url: _url
  } = data;
  const metadata = {
    items: [
      <span key={title} className="flex flex-col">
        <span className="font-bold">{affiliation}</span>
        <span>{title}</span>
      </span>
    ]
  };

  const url = `${_url || ''}`;

  const expandedContent: SnippetExpandedContentProps = { data: [], expandLink: url };

  if (summaryText) {
    expandedContent.data.push({ title: 'About', content: summaryText, truncateContent: false });
  }

  const thumbnail = {
    avatar: {
      fullName: `${fullName}` || '',
      firstName: firstName || '',
      lastName: lastName || '',
      imageUrl: getFullImageUrl(imageUrl)
    }
  };

  return {
    variant: 'faculty',
    pk: productCode ?? '',
    title: fullName || '',
    metadata,
    expandedContent: expandedContent.data.length ? expandedContent : undefined,
    thumbnail,
    link: url
  };
};

export default renderFacultyAndAuthors;
