import { ProfessionalRole } from '@/@types/client-api';
import Selector from '@/components/ui/Form/Selector';
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation
} from '@/redux/api/client/userProfile';
import { CheckedState } from '@radix-ui/react-checkbox';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface RolesStepProps {
  setIsProcessing: (isProcessing: boolean) => void;
  onStepIncrease: () => void;
  isSubmitting: boolean;
}

const RolesStep: React.FC<RolesStepProps> = ({ setIsProcessing, onStepIncrease, isSubmitting }) => {
  const [selectedList, setSelectedList] = useState<ProfessionalRole[]>([]);
  const [areRolesSaved, setAreRolesSaved] = useState(false);
  const [areRolesSaving, setAreRolesSaving] = useState(false);
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const {
    isLoading: isLoadingProfile,
    data: profile,
    refetch: refetchProfile
  } = useGetUserProfileQuery();

  const handleToggleSelect = useCallback(
    (checked: CheckedState, role: ProfessionalRole) => {
      if (checked) {
        setSelectedList([...selectedList, role]);
      } else {
        setSelectedList(selectedList.filter(x => x.sk !== role.sk));
      }
    },
    [selectedList, setSelectedList]
  );

  useEffect(() => {
    if (profile?.roles) {
      setSelectedList(profile.roles);
    }
  }, [profile?.roles]);

  const parentSelectors = useMemo(() => {
    return profile?.professionalRoleLookup?.map(role => {
      const checked = selectedList.map(x => x.sk).includes(role.sk);

      return (
        <Selector
          key={role.sk}
          size="small"
          title={role.longDescription || ''}
          label={role.longDescription || ''}
          checked={checked}
          onCheckedChange={checked => role.sk && handleToggleSelect(checked, role)}
        />
      );
    });
  }, [selectedList, profile?.professionalRoleLookup, handleToggleSelect]);

  useEffect(() => {
    const handleSubmit = async () => {
      if (profile) {
        const newProfile = { ...profile };
        newProfile.roles = selectedList;
        await updateUserProfile(newProfile);
        await refetchProfile();
        setAreRolesSaved(true);
      }
    };

    if (isSubmitting && !areRolesSaving) {
      setAreRolesSaving(true);
      handleSubmit();
    }
  }, [
    isSubmitting,
    areRolesSaving,
    setAreRolesSaved,
    selectedList,
    updateUserProfile,
    profile,
    refetchProfile
  ]);

  useEffect(() => {
    if (areRolesSaved) {
      onStepIncrease();
      setAreRolesSaved(false);
      setAreRolesSaving(false);
    }
  }, [areRolesSaved, onStepIncrease, setAreRolesSaved, setAreRolesSaving]);

  useEffect(() => {
    setIsProcessing(isLoadingProfile || areRolesSaving);
  }, [areRolesSaving, isLoadingProfile, setIsProcessing]);

  return !isLoadingProfile ? parentSelectors : null;
};

export default RolesStep;
