import { forwardRef } from 'react';
import Button from '@/components/ui/Buttons/Button';

export type PersonalInfoButtonProps = {
  label?: string;
  disabled?: boolean;
  onClick: () => void;
};

const PersonalInfoButton = forwardRef<HTMLButtonElement, PersonalInfoButtonProps>(
  ({ label = 'Edit', disabled, onClick }, ref): JSX.Element => {
    return (
      <Button
        ref={ref}
        aria-label={label}
        size="small"
        color="light-gray"
        label={label}
        onClick={onClick}
        disabled={disabled}
      />
    );
  }
);

export default PersonalInfoButton;
