import type { UserJurisdictionsBlock as UserJurisdictionsBlockProps } from '@/@types/content';
import Text from '@/components/cms/Text';
import Button from '@/components/ui/Buttons/Button';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { useAuth } from 'react-oidc-context';
import Shimmer from '@/components/ui/Shimmer';
import { UserTrackerData } from '@/@types/client-api';
import { RequirementsPanel } from '@/components/ui/CreditTracker/Panels/RequirementsPanel';
import Link from '@/components/ui/Link';
import { getActiveSpecialStatus } from '@/components/ui/CreditTracker/util/TrackerHelpers';
import AddNewLicenseButton from '@/components/ui/LicensesPage/AddNewLicenseButton';
import { useGetLicenseData, useOnSaveLicense } from '@/components/ui/LicensesPage/hooks';
import useRegionMessages from '@/components/ui/CreditTracker/hooks/useRegionMessagesHook';
import useCreditTracker from '@/components/ui/CreditTracker/hooks/useCreditTrackerHook';

const UserJurisdictionsBlock: React.FC<UserJurisdictionsBlockProps> = ({
  header: blockHeader,
  showAddLicenseLink,
  maxNumberOfItems = 2
}) => {
  const auth = useAuth();
  const { trackerData, trackerLoading } = useCreditTracker({ skipQuery: !auth.isAuthenticated });
  const [trackerRegions, setTrackerRegions] = useState<UserTrackerData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { sidePanelMessages, messagesLoading, messagesFetching } = useRegionMessages({
    requests:
      trackerRegions?.map(a => {
        return {
          region: a.creditRegionShortDescription ?? '',
          isTransitionalOrNewlyAdmitted: !!a.jurisdiction?.isTransitionalOrNewlyAdmitted
        };
      }) ?? []
  });

  useEffect(() => {
    setIsLoading(trackerLoading || messagesLoading || messagesFetching);
  }, [messagesFetching, messagesLoading, trackerLoading]);

  const screenSizeBelowLg = useMediaQuery(ScreenSizeQueries.belowLg);
  const screenSizeBelowMd = useMediaQuery(ScreenSizeQueries.belowMd);
  const isSm = useMediaQuery(ScreenSizeQueries.smOnly);
  const isXs = useMediaQuery(ScreenSizeQueries.xsOnly);
  const requirementsButtonSize = useMemo(() => {
    if (isSm) {
      return 'small';
    } else if (isXs) {
      return 'short';
    }
    return 'large';
  }, [isSm, isXs]);

  const { availableCreditJurisdictions } = useGetLicenseData();

  const [, setIsProcessing] = useState(false);
  const handleOnSaveLicense = useOnSaveLicense({ setIsProcessing });

  useEffect(() => {
    if (trackerData?.length) setTrackerRegions([...trackerData]?.splice(0, maxNumberOfItems));
  }, [maxNumberOfItems, trackerData]);

  const panelTriggerRef = useRef<HTMLButtonElement>(null);

  const openAddLicenseModal = useCallback(() => {}, []);

  const buttonClasses = classnames('w-fit', {
    'mr-4': !screenSizeBelowMd,
    'mb-4 w-full': screenSizeBelowMd
  });
  const licenseName = useCallback((data: UserTrackerData) => {
    return getActiveSpecialStatus(data)
      ? `${data?.creditRegionLongDescription} (${getActiveSpecialStatus(data)})`
      : (data?.creditRegionLongDescription ?? '');
  }, []);

  const renderCard = useCallback(
    (item: UserTrackerData) => {
      const messages = sidePanelMessages(item.creditRegionShortDescription ?? '');
      return (
        !!item && (
          <div
            className="flex flex-col items-start justify-center rounded-lg bg-gray-lightest p-10"
            key={item.creditRegionShortDescription}
          >
            {
              <>
                <Text propertyName="h6" property="h6" className="heading-4 mb-14">
                  {licenseName(item)}
                </Text>
                <div className="flex w-full flex-row flex-wrap items-center justify-start md:flex-nowrap">
                  <Button
                    label="Find approved programs"
                    iconRight="chevron-right"
                    size={screenSizeBelowLg ? 'small' : 'large'}
                    color="outline-black"
                    className={buttonClasses}
                    href={`${window.env?.CONTENT_DELIVERY_API}/api/client/credit-info/page-map/${item.creditRegionShortDescription}?isTransitionalOrNewlyAdmitted=${!!item.jurisdiction?.isTransitionalOrNewlyAdmitted}`}
                  />
                  {!!messages?.length && (
                    <RequirementsPanel
                      announcements={messages}
                      creditRegionName={item.creditRegionLongDescription ?? ''}
                      ref={panelTriggerRef}
                      buttonSize={requirementsButtonSize}
                    />
                  )}
                </div>
              </>
            }
          </div>
        )
      );
    },
    [sidePanelMessages, licenseName, screenSizeBelowLg, buttonClasses, requirementsButtonSize]
  );

  const renderHeader = useMemo(() => {
    return (
      <div className="flex flex-row items-end justify-between">
        {isLoading ? (
          <Shimmer className="h-10 w-96" />
        ) : (
          <Text propertyName="h5" property="h5" className="heading-4">
            {blockHeader}
          </Text>
        )}
        {showAddLicenseLink &&
          (isLoading ? (
            <Shimmer className="h-5 w-48" />
          ) : (
            <AddNewLicenseButton
              creditJurisdictions={availableCreditJurisdictions}
              onSave={handleOnSaveLicense}
              triggerButton={
                <Link text="Add License" as="button" onClick={() => openAddLicenseModal()} />
              }
            />
          ))}
      </div>
    );
  }, [
    availableCreditJurisdictions,
    blockHeader,
    handleOnSaveLicense,
    isLoading,
    openAddLicenseModal,
    showAddLicenseLink
  ]);

  const cardShimmer = useMemo(() => {
    return (
      <div className="flex w-full flex-row flex-wrap items-center justify-between">
        {Array.from({ length: maxNumberOfItems }).map((_, i) => {
          return (
            <div className="mb-6 basis-full md:mb-6 md:basis-[48%]" key={`shimmer-${i}`}>
              <div className="flex h-52 flex-col items-start justify-center rounded-lg bg-gray-lightest p-10">
                <Shimmer className="mb-14 h-10 w-96" />
                <div className="flex w-full flex-row flex-wrap items-center justify-start md:flex-nowrap">
                  <Shimmer className={`${buttonClasses} h-10 !w-60`} />
                  <Shimmer className={`${buttonClasses} h-10 !w-52`} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [buttonClasses, maxNumberOfItems]);

  return (
    auth.isAuthenticated && (
      <div className="w-full">
        {renderHeader}
        <div className="mt-8 flex flex-row flex-wrap items-center justify-between">
          {isLoading
            ? cardShimmer
            : !!trackerRegions?.length &&
              trackerRegions?.map(item => {
                return (
                  <div
                    key={`${item.customerId}-${item.creditRegionName}`}
                    className="mb-6 basis-full md:mb-6 md:basis-[48%]"
                  >
                    {renderCard(item)}
                  </div>
                );
              })}
        </div>
      </div>
    )
  );
};

export default UserJurisdictionsBlock;
