import { ComponentProps } from 'react';
import TagButton from '../Buttons/TagButton';
import DropdownSelect from '../DropdownSelect';

type SearchSortMenuProps = Pick<ComponentProps<typeof DropdownSelect>, 'value'> & {
  options: { value: string; label: string }[];
  onValueChange: (value?: string) => void;
  disabled?: boolean;
};

const SearchSortMenu = (props: SearchSortMenuProps) => {
  return (
    <DropdownSelect
      disabled={props.disabled}
      button={({ isOpen, value, ...buttonProps }) => (
        <TagButton
          isActive={isOpen}
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          styleType="solid"
          {...buttonProps}
          aria-label={`Click to ${isOpen ? 'close' : 'open'} the sorting options. Currently selected: ${value}.`}
          label={value}
        />
      )}
      {...props}
    />
  );
};

export default SearchSortMenu;
