import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchRequest, SearchResponse } from '@/@types/client-api';
import { selectSearch, selectSearchSemanticFilters } from '@/redux/selectors/searchSelectors';
import searchEndpoints, { useGetSearchResultsQuery } from '@/redux/api/client/search';
import {
  loadMore,
  searchFailure,
  searchLoading,
  searchSuccess,
  setFacetFilters,
  setInvariantFilters
} from '@/redux/slices/searchSlice';
import { SerializedError, UnknownAction } from '@reduxjs/toolkit';
import { useSearchParamsStable } from '@/hooks/useSearchParams';
import { QUERY_PARAMS } from '../constants';
import { getInitialSearchRequest, searchDataToSearchParams } from '../utils/searchParams';
import {
  SearchRequestOptions,
  processSearchRequest,
  searchDataToSearchRequestBody
} from '../utils/searchRequest';
import usePrevious from '@/hooks/usePrevious';
import { augmentFacetGroupsWithEmptySearchData } from '../utils/searchResponse';
import { useGetAllFiltersFromEmptySearch } from './useGetAllFiltersFromEmptySearch';
import { waitForObject } from '@/utils/promises';
import { useGetSemanticFilters } from './useGetSemanticFilters';
import { SearchSemanticFilterBlock } from '@/@types/content';
import { upsertSearchResultItemsQueryData } from '@/redux/api/client/searchInternal';
import { SearchContext } from '@/analytics/constants';
import useViewSearchResultsEvent from '@/analytics/hooks/useViewSearchResultsEvent';
import { trackExceptionEvent, trackNoResultsEvent } from '@/analytics/search';
import { ClientAPI } from '@/redux/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import useSearchDelayedActionToasts from './useSearchDelayedActionToasts';

export type UseSearchInput = Omit<SearchRequestOptions, 'adHocFilters'> & {
  semanticFilters?: SearchSemanticFilterBlock;
  updateUrlParams?: boolean;
  postQuery?: string;
  searchContext?: SearchContext;
};

const useSearch = ({
  invariantFilters: invariants,
  overrides,
  semanticFilters,
  facetFilters,
  updateUrlParams = true,
  postQuery,
  facetOverrides,
  searchContext
}: UseSearchInput) => {
  const dispatch = useDispatch();

  const { data: _storeData, loading: storeLoading, adHocFilters } = useSelector(selectSearch);
  const stateSemanticFilters = useSelector(selectSearchSemanticFilters);
  const { searchParams, setSearchParams } = useSearchParamsStable();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const lastSearchRequest = useRef<SearchRequest>();
  const lastSearchError = useRef<FetchBaseQueryError | SerializedError | undefined>();
  const [query, setQuery] = useState<string | undefined>();
  const [_isNewSearch, setIsNewSearch] = useState(true);
  const allFiltersFromEmptySearch = useGetAllFiltersFromEmptySearch({});
  const allFiltersFromEmptySearchRef = useRef<SearchResponse | undefined>();
  allFiltersFromEmptySearchRef.current = allFiltersFromEmptySearch.data;
  useSearchDelayedActionToasts();

  const isNewSearch = _isNewSearch || !!searchParams.get(QUERY_PARAMS.NEW_SEARCH);
  const isFromOverlay = !!searchParams.get(QUERY_PARAMS.SEARCH_OVERLAY);

  const trackViewSerachResultsEvent = useViewSearchResultsEvent();

  useEffect(() => {
    const handleBackButtonEvent = () => {
      setIsNewSearch(true);
    };
    window.addEventListener('popstate', handleBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', handleBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (isNewSearch) {
      // Only update query for new searches to avoid triggering an extra request on navigating away
      setQuery(searchParams.get(QUERY_PARAMS.QUERY) ?? undefined);
    }
  }, [searchParams, isNewSearch]);

  const { areSemanticFiltersLoading } = useGetSemanticFilters(
    isNewSearch ? semanticFilters : undefined
  );

  const explain = searchParams.get(QUERY_PARAMS.EXPLAIN) === 'true';
  const queryAndOverrides = useMemo(
    () => ({ query, explain, ...overrides }),
    [query, overrides, explain]
  );

  const storeData = isNewSearch ? null : _storeData;
  const invariantFilters = useMemo(() => invariants || [], [invariants]);

  useEffect(() => {
    dispatch(setInvariantFilters(invariantFilters));
  }, [invariantFilters, dispatch]);

  useEffect(() => {
    dispatch(setFacetFilters(facetFilters || null));
  }, [facetFilters, dispatch]);

  const searchRequest = processSearchRequest(
    storeData
      ? searchDataToSearchRequestBody(storeData)
      : getInitialSearchRequest(searchParams, stateSemanticFilters, facetFilters, facetOverrides),
    {
      adHocFilters,
      invariantFilters,
      overrides: queryAndOverrides,
      facetOverrides
    }
  );

  lastSearchRequest.current = searchRequest;

  const { isLoading, error, isFetching, data } = useGetSearchResultsQuery(
    { requestBody: searchRequest, postQuery },
    {
      skip: areSemanticFiltersLoading
    }
  );
  const lastData = usePrevious(data);

  const hasNoResults = useMemo(
    () => !!error || data?.searchResultSummary?.noOfHits === 0,
    [error, data]
  );

  const hasFilters = !!searchRequest.facetGroups?.length;
  let resultsStatus = hasNoResults
    ? hasFilters
      ? 'no-results-from-filters'
      : 'no-results-from-query'
    : 'has-results';
  const { isLoading: isLoadingQueryOnlyRequest, data: queryOnlyRequestData } =
    useGetSearchResultsQuery(
      {
        requestBody: { query, pageSize: 0, skipFacetGroupsCalculation: true },
        postQuery
      },
      {
        skip: !hasNoResults || !query || !hasFilters
      }
    );
  if (queryOnlyRequestData?.searchResultSummary?.noOfHits === 0) {
    resultsStatus = 'no-results-from-query';
  }

  const clearNewSearchFlag = useCallback(() => {
    setSearchParams(
      existing => {
        existing.delete(QUERY_PARAMS.NEW_SEARCH);
        return existing;
      },
      { replace: true, preventScrollReset: true }
    );
    setIsNewSearch(false);
  }, [setSearchParams]);

  // update url params
  useEffect(() => {
    if (storeData && updateUrlParams && data !== lastData) {
      setSearchParams(
        existing => searchDataToSearchParams(storeData, existing, invariantFilters, facetFilters),
        {
          preventScrollReset: true
        }
      );
    }
  }, [storeData, setSearchParams, invariantFilters, updateUrlParams, data, lastData, facetFilters]);

  useEffect(() => {
    if (isLoading) {
      dispatch(searchLoading('loading'));
    } else if (isFetching) {
      dispatch(searchLoading('fetching'));
    } else {
      dispatch(searchLoading('none'));
    }
  }, [isLoading, isFetching, dispatch]);

  useEffect(() => {
    setIsLoadingMore(false);
    if (error && error != lastSearchError.current) {
      lastSearchError.current = error;
      dispatch(searchFailure(error));
      dispatch(ClientAPI.util.invalidateTags([{ type: 'Search', id: 'Results' }]));
      trackExceptionEvent({ searchContext, error });
      clearNewSearchFlag();
    }
  }, [error, dispatch, searchContext, clearNewSearchFlag]);

  useEffect(() => {
    if (hasNoResults) {
      trackNoResultsEvent({ searchContext: isFromOverlay ? SearchContext.Overlay : searchContext });
    }
  }, [hasNoResults, isFromOverlay, searchContext]);

  useEffect(() => {
    trackViewSerachResultsEvent(searchParams);
  }, [searchParams, trackViewSerachResultsEvent]);

  useEffect(() => {
    async function handleDataUpdate() {
      setIsLoadingMore(false);
      if (data && lastSearchRequest.current && lastData !== data && !error) {
        let successResponse = data;

        // This is a little hacky, but allows us to wait for this request to finish without losing our place in the useEffect
        await waitForObject(() => allFiltersFromEmptySearchRef.current);

        const searchRequestFromNewData = processSearchRequest(searchDataToSearchRequestBody(data), {
          adHocFilters,
          invariantFilters,
          overrides: queryAndOverrides,
          facetOverrides
        });

        // The settings we got back are different from our request (eg, BE automatically changed content tabs)
        const hasDifferentSettings =
          JSON.stringify(lastSearchRequest.current) !== JSON.stringify(searchRequestFromNewData);

        const hasNoResults = data.searchResultSummary?.noOfHits === 0;

        if (hasDifferentSettings) {
          if (hasNoResults) {
            // When there are no results we don't get all the filters back, so we need to augment the list from the original request
            successResponse = {
              ...data,
              facetResults: {
                facetGroups: augmentFacetGroupsWithEmptySearchData({
                  facetGroups: lastSearchRequest.current.facetGroups ?? [],
                  allFiltersFromEmptySearch: allFiltersFromEmptySearchRef.current
                })
              }
            };
          } else {
            // Otherwise, to avoid a new extra request we should upsert a cache entry with the new data
            dispatch(
              searchEndpoints.util.upsertQueryData(
                'getSearchResults',
                { requestBody: searchRequestFromNewData, postQuery },
                data
              ) as unknown as UnknownAction
            );
            dispatch(
              upsertSearchResultItemsQueryData(
                'getSearchResultsItems',
                { requestBody: searchRequestFromNewData },
                data
              ) as unknown as UnknownAction
            );
          }
        }

        dispatch(
          searchSuccess({
            request: lastSearchRequest.current,
            response: successResponse
          })
        );

        // now that we have had a successful search, remove new search flag
        clearNewSearchFlag();
        lastSearchError.current = undefined;
      }
    }

    handleDataUpdate();
  }, [
    data,
    dispatch,
    adHocFilters,
    lastData,
    invariantFilters,
    isNewSearch,
    setSearchParams,
    postQuery,
    facetOverrides,
    queryAndOverrides,
    error,
    clearNewSearchFlag
  ]);

  const onLoadMore = useCallback(() => {
    dispatch(loadMore());
    setIsLoadingMore(true);
  }, [dispatch]);

  const isStoreLoadingWithNoStoreData = storeLoading && !storeData;

  return {
    data: storeData,
    loading: isNewSearch || isStoreLoadingWithNoStoreData || isLoadingQueryOnlyRequest,
    isLoadingMore,
    onLoadMore,
    query: queryAndOverrides.query ?? undefined,
    resultsStatus
  };
};

export default useSearch;
