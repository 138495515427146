import type { PressReleaseDetailPage, ReferencePageSettings } from '@/@types/content';
import withNav from '../hocs/withNav';
import { BreadcrumbProps } from '../ui/Breadcrumbs';
import PressReleaseDetailHeader from '../ui/PressRelease/PressReleaseDetailHeader';
import { transformIfPLIUrl } from '@/utils/helpers';

type PressReleaseDetailPageProps = PressReleaseDetailPage & {
  siteLabels: ReferencePageSettings;
  aboutPliCopy: string;
};

const PressReleaseDetailPageComponent: React.FC<PressReleaseDetailPageProps> = props => {
  return (
    <>
      <PressReleaseDetailHeader
        title={props.navigationTitle!}
        shareUrl={window.location.href}
        breadcrumbs={[
          {
            name: 'Media & Press',
            url: transformIfPLIUrl(props.siteLabels?.mediaAndPressReference?.url)
          } as BreadcrumbProps
        ]}
      />
      <div className="container mx-auto">
        {props.video && (
          <iframe
            src={`https://player.vimeo.com/video/${props.video}?title=false`}
            className={`my-16 aspect-video w-full`}
            width="100%"
            height="auto"
          />
        )}

        {props.image && <img src={props.image.url} className="my-16 w-full" />}

        <div
          className="my-10 max-w-5xl"
          dangerouslySetInnerHTML={{ __html: props.mainBody ?? '' }}
        />
        <div
          className="my-10 max-w-5xl"
          dangerouslySetInnerHTML={{ __html: props.aboutPliCopy ?? '' }}
        />
      </div>
    </>
  );
};

const PressReleaseDetailPage = withNav(PressReleaseDetailPageComponent);
export default PressReleaseDetailPage;
