import { CertificateFilterGroupResult, CreditJurisdiction } from '@/@types/client-api';
import { TFilter } from '../../Filter/FilterRow';
import { CERTIFICATES_FILTERS, CUSTOM_YEAR_VALUE, VALUE_DELIMITER } from './constants';
import { CertificateFilterItem, FiltersApplied, SortByLabelItem } from './types';
import { FilterProps } from '../../Filter/types';
import { BackendDateFormat, formatDate, parseBEDate, parseDate } from '@/utils/helpers';
import { isAfter, isBefore } from 'date-fns';

export const isCustomYear = (filtersApplied: FiltersApplied) =>
  filtersApplied.year === CUSTOM_YEAR_VALUE;

export const getDateRangeParts = (filtersApplied: FiltersApplied) =>
  filtersApplied.dateRange?.split(VALUE_DELIMITER) ?? [];

export const hasDateRange = (filtersApplied: FiltersApplied) =>
  getDateRangeParts(filtersApplied).filter(item => !!item).length === 2;

export const parseDateRange = (filtersApplied: FiltersApplied) => {
  const dataRange = getDateRangeParts(filtersApplied);
  return {
    startDate: getDateRangePart(dataRange, 0),
    endDate: getDateRangePart(dataRange, 1)
  };
};

export const getDateRangePart = (dataRange: string[], index: number) => {
  const date = parseDate(dataRange[index]);
  return date ? formatDate(date, BackendDateFormat) : '';
};

export const isJurisdictionsFilter = (filter?: TFilter | FilterProps) =>
  filter?.groupTypeId === CERTIFICATES_FILTERS.JURISDICTIONS;

export const isJurisdictionsFilterGroup = (filter: CertificateFilterGroupResult) =>
  filter.name === CERTIFICATES_FILTERS.JURISDICTIONS;

export const isSourceFilter = (filter: TFilter | FilterProps) =>
  filter.groupTypeId === CERTIFICATES_FILTERS.SOURCE;

export const isSourceFilterGroup = (filter: CertificateFilterGroupResult) =>
  filter.name === CERTIFICATES_FILTERS.SOURCE;

export const isYearFilter = (filter?: TFilter | FilterProps) =>
  filter?.groupTypeId === CERTIFICATES_FILTERS.YEAR;

export const isYearFilterGroup = (filter: CertificateFilterGroupResult) =>
  filter.name === CERTIFICATES_FILTERS.YEAR;

export const isDateRangeFilter = (filter?: TFilter | FilterProps) =>
  filter?.groupTypeId === CERTIFICATES_FILTERS.DATE_RANGE;

export const isDateRangeFilterGroup = (filter: CertificateFilterGroupResult) =>
  filter.name === CERTIFICATES_FILTERS.DATE_RANGE;

export const getFilterItems = (filter: CertificateFilterGroupResult): CertificateFilterItem[] => {
  const result: CertificateFilterItem[] = [];
  for (const item of filter.filters ?? []) {
    if ('name' in item) {
      const name = item.name as string;
      if (name) {
        result.push({ ...item, name });
      }
    }
  }
  return result;
};

export const getMinMaxDate = (filter: CertificateFilterGroupResult) => {
  const result: {
    minDate: Date | undefined;
    maxDate: Date | undefined;
  } = {
    minDate: undefined,
    maxDate: undefined
  };
  for (const item of filter.filters ?? []) {
    if (item.isAvailable) {
      if ('minDate' in item) {
        const minDate = parseBEDate(item.minDate as string);
        if (minDate && (!result.minDate || isBefore(minDate, result.minDate))) {
          result.minDate = minDate;
        }
      } else if ('maxDate' in item) {
        const maxDate = parseBEDate(item.maxDate as string);
        if (maxDate && (!result.maxDate || isAfter(maxDate, result.maxDate))) {
          result.maxDate = maxDate;
        }
      }
    }
  }
  return result;
};

export const getJurisdictionLabel = (
  id: string,
  creditJurisdictions: CreditJurisdiction[]
): string => {
  const creditJurisdiction = creditJurisdictions?.find(item => item.id === id);
  return creditJurisdiction?.name ?? id;
};

export const isFilterItemSelected = (
  item: CertificateFilterItem,
  selectedFilters?: string | null
) => selectedFilters?.split(VALUE_DELIMITER).includes(item.name) ?? false;

export const appendFilterValue = (currentValues?: string | null, value?: string) => {
  if (value) {
    return currentValues ? `${currentValues}${VALUE_DELIMITER}${value}` : value;
  }
};

export const removeFilterValue = (currentValues?: string | null, value?: string) =>
  currentValues
    ?.split(VALUE_DELIMITER)
    .filter(item => item !== value)
    .join(VALUE_DELIMITER);

export const sortByLabel = (a?: SortByLabelItem, b?: SortByLabelItem) => {
  const labelA = a?.label ?? '';
  const labelB = b?.label ?? '';
  return labelA.localeCompare(labelB);
};

export const sortByLabelDesc = (a?: SortByLabelItem, b?: SortByLabelItem) => sortByLabel(b, a);
