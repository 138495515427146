import { IContent } from '@/@types/cms';
import ContentArea from '@/components/cms/ContentArea';
import classnames from 'classnames';
import { OnTrackItemViewEvent } from './LinkBlock';

export type LinkGridBlockContentProps = {
  linkBlocks: IContent[];
  layout: string;
  onTrackItemViewEvent?: OnTrackItemViewEvent;
};

type LinkBlockWithImage = {
  backGroundImage: {
    url?: string;
    altText?: string;
  };
} & IContent;

const LinkGridBlockContent = ({
  linkBlocks,
  layout = '1',
  onTrackItemViewEvent
}: LinkGridBlockContentProps): JSX.Element => {
  const modifiedLinkBlocks = linkBlocks.map(linkBlock => {
    if (
      Array.isArray(linkBlock.contentType) &&
      linkBlock.contentType.includes('CollectionProduct')
    ) {
      return {
        ...linkBlock,
        contentType: ['Block', 'LinkBlock'],
        link: {
          href: linkBlock?.url || '',
          text: linkBlock?.name || ''
        },
        image: (linkBlock as LinkBlockWithImage)?.backGroundImage?.url
          ? {
              url: (linkBlock as LinkBlockWithImage)?.backGroundImage?.url || undefined,
              altText: (linkBlock as LinkBlockWithImage)?.backGroundImage?.altText || undefined
            }
          : undefined,
        isFeaturedLink: false
      };
    }
    return linkBlock;
  });

  return (
    <ContentArea
      propertyName="LinkBlocks"
      components={modifiedLinkBlocks}
      componentsProps={{ onTrackItemViewEvent }}
      className={classnames('grid gap-4 lg:gap-6', {
        'sm:grid-cols-2': parseInt(layout) === 2,
        'sm:grid-cols-2 lg:grid-cols-3': parseInt(layout) === 3
      })}
    />
  );
};

export default LinkGridBlockContent;
