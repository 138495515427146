import Divider from '@/components/ui/Divider';
import Filter from '../Filter/Filter';
import Panel from '../Panel';
import { ComponentProps, Fragment, useCallback } from 'react';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { FilterProps } from '../Filter/types';
import SearchSortMenu from './SearchSortMenu';

import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import * as Dialog from '@radix-ui/react-dialog';
import SearchResultsFilterOverlayFooter from './SearchResultsFilterOverlayFooter';
import useSearchFilterPanelL2 from './hooks/useSearchFilterPanelL2';
import SearchFilter from './SearchFilter';
import { FilterContext, SearchContext } from '@/analytics/constants';

type SearchResultsFilterOverlayProps = {
  isFilterPanelOpen: boolean;
  setIsFilterPanelOpen: (open: boolean) => void;
  resultCount?: number;
  filters?: FilterProps[];
  sortOptions?: ComponentProps<typeof SearchSortMenu>['options'];
  sortValue: ComponentProps<typeof SearchSortMenu>['value'];
  onSortChange: ComponentProps<typeof SearchSortMenu>['onValueChange'];
  onClear: (payload?: { groupId?: string }) => void;
  fetching: boolean;
  context?: FilterContext;
  searchContext?: SearchContext;
};

const SearchResultsFilterOverlay: React.FC<SearchResultsFilterOverlayProps> = ({
  isFilterPanelOpen,
  setIsFilterPanelOpen,
  resultCount,
  sortValue,
  sortOptions,
  onSortChange,
  filters,
  onClear,
  fetching,
  context,
  searchContext
}) => {
  const isAboveXS = useMediaQuery(ScreenSizeQueries.sm);
  const ariaHint =
    'This will dismiss the filter panel so you can see the results from your selections.';
  const submitButtonLabel = resultCount
    ? resultCount === 1
      ? `See ${resultCount.toLocaleString()} result`
      : `See ${resultCount.toLocaleString()} results`
    : 'See results';
  const submitButtonAriaLabel = `${submitButtonLabel}. ${ariaHint}`;

  const {
    isLastFilterExpanded,
    clearPanelCloseCounter,
    incrementPanelCloseCounter,
    filterKeyGenerator
  } = useSearchFilterPanelL2();

  const handleOnOpenChange = useCallback(
    (open: boolean) => {
      setIsFilterPanelOpen(open);
      if (!open) {
        clearPanelCloseCounter();
      }
    },
    [clearPanelCloseCounter, setIsFilterPanelOpen]
  );

  const handleOnClose = useCallback(() => {
    handleOnOpenChange(false);
  }, [handleOnOpenChange]);

  return (
    <Panel isOpen={isFilterPanelOpen} onOpenChange={handleOnOpenChange}>
      <Panel.Content aria-labelledby="all-filters-title" aria-describedby="all-filters-description">
        <Panel.Header id="all-filters-title">All Filters</Panel.Header>
        <VisuallyHidden.Root asChild>
          <Dialog.Description id="all-filters-description">
            Use the filter options within this panel to refine your search results.
          </Dialog.Description>
        </VisuallyHidden.Root>
        <Panel.Body>
          <div className="mb-8 flex flex-col gap-8">
            {!isAboveXS && sortOptions && sortValue && (
              <>
                <Filter
                  title="Sort"
                  items={sortOptions}
                  value={sortValue}
                  onValueChange={onSortChange}
                  type="radio"
                  groupTypeId={''}
                  fetching={fetching}
                />
                <Divider color="light" />
              </>
            )}
            {filters?.map(
              (filter, index) =>
                !(filter.items.length === 0 && !filter.expandText) && (
                  // This key rerenders each filter group when the panel is closed
                  // (used to memoize the filter order while panel is open)
                  <Fragment key={filterKeyGenerator(filter)}>
                    <SearchFilter
                      filter={filter}
                      fetching={fetching}
                      ariaHint={ariaHint}
                      onClose={handleOnClose}
                      onBack={() => incrementPanelCloseCounter(filter)}
                      onClear={onClear}
                      expandButtonFocused={isLastFilterExpanded(filter)}
                      context={context}
                      searchContext={searchContext}
                    />
                    {index < filters.length - 1 && <Divider color="light" />}
                  </Fragment>
                )
            )}
          </div>
        </Panel.Body>

        <Panel.Footer divider="line">
          <SearchResultsFilterOverlayFooter
            clearLabel="Clear all filters"
            onClear={() => onClear()}
            onSubmit={handleOnClose}
            submitAriaLabel={submitButtonAriaLabel}
            submitLabel={submitButtonLabel}
          />
        </Panel.Footer>
      </Panel.Content>
    </Panel>
  );
};

export default SearchResultsFilterOverlay;
