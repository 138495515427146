import { PushAnalyticsEvent } from './analyticsUtils';
import { AnalyticsEventName } from './constants';

type TrackEventProps = {
  eventName: AnalyticsEventName;
  parameters: Record<string, string | number | boolean | object[] | object | null>;
  skipBaseEventParameters?: boolean;
};

export const trackEvent = ({ eventName, parameters = {} }: TrackEventProps) => {
  const obj = {
    ...parameters
  };

  PushAnalyticsEvent(eventName, { ...obj });
};
