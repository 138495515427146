import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import classnames from 'classnames';
import Icon from './Icon';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useState, forwardRef, ReactNode, useEffect, useRef, useMemo } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import * as Collapsible from '@radix-ui/react-collapsible';
import Text from '@/components/cms/Text';
import useScrollDirection, { ScrollDirection } from '@/hooks/useScrollDirection';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

export type TabTrigger = {
  label: string;
  id: string;
};

interface TabsProps<T extends TabTrigger = TabTrigger> {
  children: ReactNode;
  selectedValue: T;
  setSelectedValue: (value: T) => void;
  sticky?: boolean;
  tabsTriggers: T[];
  variant: 'page' | 'browse';
  registerButton?: ReactNode;
  elementType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  container?: boolean;
}

type TabProps<T extends TabTrigger = TabTrigger> = TabsProps<T>;

const Tab = Tabs.Root;

const TabsList = forwardRef<
  React.ElementRef<typeof Tabs.List>,
  React.ComponentPropsWithoutRef<typeof Tabs.List>
>(({ className, ...props }, ref) => <Tabs.List ref={ref} className={className} {...props} />);

const TabsTrigger = forwardRef<
  React.ElementRef<typeof Tabs.Trigger>,
  React.ComponentPropsWithoutRef<typeof Tabs.Trigger>
>(({ className, ...props }, ref) => <Tabs.Trigger ref={ref} className={className} {...props} />);

const Dropdown = Collapsible.Root;

const DropdownTrigger = forwardRef<
  React.ElementRef<typeof Collapsible.Trigger>,
  React.ComponentPropsWithoutRef<typeof Collapsible.Trigger>
>(({ className, children, ...props }, ref) => (
  <Collapsible.Trigger ref={ref} className={className} {...props}>
    {children}
  </Collapsible.Trigger>
));

const DropdownList = forwardRef<
  React.ElementRef<typeof Collapsible.Content>,
  React.ComponentPropsWithoutRef<typeof Collapsible.Content>
>(({ className, ...props }, ref) => (
  <Collapsible.Content ref={ref} className={className} {...props} />
));

function GlobalTabs<T extends TabTrigger>({
  tabsTriggers,
  variant,
  selectedValue,
  setSelectedValue,
  children,
  sticky,
  registerButton,
  elementType = 'h3',
  container
}: TabProps<T>) {
  const { scrollDirection } = useScrollDirection();
  const stickyHeader = useSelector((state: RootState) => state.page.stickyHeader);
  const refTabs = useRef<HTMLDivElement>(null);

  const [tabsOverflowing, setTabsOverflowing] = useState(false);
  const [canCheckResize, setCanCheckResize] = useState(false);
  const [open, setOpen] = useState(false);
  const editMode = isEditOrPreviewMode();

  const xsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);
  const smOnly = useMediaQuery(ScreenSizeQueries.smOnly);
  const mdOnly = useMediaQuery(ScreenSizeQueries.mdOnly);

  useEffect(() => {
    if (!refTabs.current || !sticky) return;

    if (scrollDirection === ScrollDirection.Up)
      refTabs.current.style.top = `${stickyHeader.headerHeight - 1}px`;
    else refTabs.current.style.top = `0px`;
  }, [scrollDirection, stickyHeader, sticky]);

  const handleResize = () => {
    const tabsListElement = document.getElementById('tabs-list');
    if (tabsListElement) {
      const firstButton = tabsListElement.querySelector('button');
      const lastButton = tabsListElement.querySelector('button:last-of-type');

      if (firstButton && lastButton) {
        setTabsOverflowing(
          firstButton.getBoundingClientRect().top !== lastButton.getBoundingClientRect().top
        );
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 0);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [tabsOverflowing]);

  const doesNotHaveTabs = useMemo(() => {
    return xsOnly || tabsOverflowing;
  }, [xsOnly, tabsOverflowing]);

  useEffect(() => {
    if ((mdOnly || smOnly) && canCheckResize) {
      setTabsOverflowing(false);
    }
    setCanCheckResize(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdOnly, smOnly]);

  const tabsContainerClassNames = classnames(`flex flex-col justify-between`, {
    'bg-white': variant === 'page',
    'border-b border-gray-light bg-white': variant === 'browse',
    'sticky z-[997] transition-all': sticky,
    container: container,
    'sm:flex-row sm:items-center': !doesNotHaveTabs
  });

  const tabsTriggerClassNames = classnames(
    'heading-6-medium mx-4 border-b-4 border-transparent hover:text-red data-[state=active]:border-red data-[state=active]:text-red',
    {
      'py-[46px]': variant === 'page',
      'py-7': variant === 'browse'
    }
  );

  const dropdownTriggerClassNames = classnames(
    'heading-5 mb-5 w-full text-left data-[state=open]:text-red'
  );

  const dropdownSelectableClassNames = classnames('heading-5 my-5 w-full text-left font-bold');

  const handleButtonClick = (value: T) => {
    setSelectedValue(value);
    setOpen(false);
  };

  const onValueChangeTab = (value: string) => {
    const valueNumber = Number(value);
    const tab = tabsTriggers.find(tab => tab.id === tabsTriggers[valueNumber].id);
    handleButtonClick(tab!);
  };

  const selectedTabValue = tabsTriggers.findIndex(tab => tab.id === selectedValue.id).toString();

  return (
    <Tab value={selectedTabValue} onValueChange={onValueChangeTab}>
      <section ref={refTabs} className={tabsContainerClassNames}>
        {doesNotHaveTabs ? (
          <Dropdown open={open} onOpenChange={setOpen}>
            <DropdownTrigger asChild className={dropdownTriggerClassNames}>
              <div className="flex items-center justify-between pt-10">
                {(selectedValue || editMode) && (
                  <Text element={elementType} propertyName="Label" className="heading-5">
                    {selectedValue.label}
                  </Text>
                )}
                <Icon name={open ? 'chevron-up' : 'chevron-down'} />
              </div>
            </DropdownTrigger>
            <DropdownList className="flex flex-col">
              {tabsTriggers.map(tab => {
                if (selectedValue.label !== tab.label) {
                  return (
                    (tab || editMode) && (
                      <Text
                        onClick={() => handleButtonClick(tab)}
                        className={dropdownSelectableClassNames}
                        key={tab.id}
                        element={elementType}
                        propertyName="Label"
                      >
                        {tab.label}
                      </Text>
                    )
                  );
                }
              })}
            </DropdownList>
          </Dropdown>
        ) : (
          <div className="l:ml-16 flex items-center">
            <TabsList id="tabs-list">
              {tabsTriggers.map((tab, index) => (
                <TabsTrigger
                  key={tab.id}
                  value={index.toString()}
                  className={classnames(tabsTriggerClassNames, {
                    'ml-0': index === 0,
                    'mr-0': index == tabsTriggers.length - 1
                  })}
                >
                  {(tab || editMode) && (
                    <Text element={elementType} propertyName="Label">
                      {tab.label}
                    </Text>
                  )}
                </TabsTrigger>
              ))}
            </TabsList>
          </div>
        )}
        {variant === 'page' && registerButton}
      </section>
      {doesNotHaveTabs ? (
        children
      ) : (
        <Tabs.Content value={selectedTabValue}>{children}</Tabs.Content>
      )}
    </Tab>
  );
}

export default GlobalTabs;
