export function waitForObject<T>(
  checkExistence: () => T | null,
  timeout: number = 5000
): Promise<T> {
  return new Promise((resolve, reject) => {
    // If object already exists, return right away
    const obj = checkExistence();
    if (obj) {
      resolve(obj);
    }

    // Otherwise, check every 100ms
    const interval = 100;
    let elapsedTime = 0;

    const intervalId = setInterval(() => {
      const obj = checkExistence();
      if (obj) {
        clearInterval(intervalId);
        resolve(obj);
      } else if (elapsedTime >= timeout) {
        clearInterval(intervalId);
        reject(new Error('Timeout waiting for object to exist'));
      }
      elapsedTime += interval;
    }, interval);
  });
}
