import { NavigationItemBlock as NavigationItemBlockProps } from '@/@types/content';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';
import { getTextColorClass } from '@/utils/theme';

enum Device {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile'
}

interface BlockProps extends NavigationItemBlockProps {
  theme?: string;
  variant?: string;
  activePrimaryNav?: string;
  focusVisibleClasses?: string;
  primaryNavClickHandler?: () => void;
  handleMouseLeaveNavItem?: () => void;
}

const NavigationItemBlock: React.FC<BlockProps> = ({
  title,
  theme,
  variant = Device.Desktop,
  activePrimaryNav,
  focusVisibleClasses,
  primaryNavClickHandler,
  handleMouseLeaveNavItem
}) => {
  let componentClasses = '';

  const desktopClasses = classnames(
    'text-2 relative inline-flex h-full items-center whitespace-nowrap font-bold',
    'after:pointer-events-none after:absolute after:inset-x-0 after:-bottom-5 after:h-0 after:transition-all after:content-[""]',
    {
      'hover:after:h-[3px] hover:after:bg-red': activePrimaryNav !== title
    },
    {
      'after:h-[3px] after:bg-red': activePrimaryNav === title
    },
    getTextColorClass(theme),
    focusVisibleClasses
  );

  const tabletClasses = classnames(
    'heading-3 relative text-left transition-colors',
    'before:absolute before:-left-12 before:block before:h-full before:w-0 before:bg-red before:transition-[width] before:content-[""]',
    'hover:text-red hover:before:w-[3px]',
    {
      '!text-red before:!w-[3px]': activePrimaryNav === title
    },
    getTextColorClass(theme),
    focusVisibleClasses
  );

  const mobileClasses = classnames(
    'heading-3 relative flex w-full items-center text-left transition-colors',
    getTextColorClass(theme),
    focusVisibleClasses
  );

  switch (variant) {
    case Device.Desktop:
      componentClasses = desktopClasses;
      break;
    case Device.Tablet:
      componentClasses = tabletClasses;
      break;
    case Device.Mobile:
      componentClasses = mobileClasses;
      break;
    default:
      componentClasses = desktopClasses;
  }

  return (
    <button
      type="button"
      className={componentClasses}
      title={title}
      onClick={primaryNavClickHandler}
      onMouseEnter={primaryNavClickHandler}
      onMouseLeave={handleMouseLeaveNavItem}
      aria-controls={`Panel ID ${title}`}
    >
      {title}
      <Icon name="chevron-right" size="large" className={classnames('ml-auto md:hidden')} />
    </button>
  );
};

export default NavigationItemBlock;
