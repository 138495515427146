import { useCallback, useMemo, useState } from 'react';
import ModalContent, { ModalContentProps } from '@/components/ui/Modal/ModalContent';
import Selector from '@/components/ui/Form/Selector';
import { addToYourSearchEyebrow } from './AddToYourSearchEyebrow';

export type SelectItem = {
  id: string;
  label: string;
};

export type SelectAddToProfileModalProps = Pick<
  ModalContentProps,
  'title' | 'saveButtonLabel' | 'onDismissButtonPress' | 'isMultiStep'
> & {
  items: SelectItem[];
  onSave: (selectedItems: SelectItem[]) => void;
  onBack?: () => void;
  onClose?: () => void;
};

const SelectAddToProfileModal = ({
  title,
  items,
  saveButtonLabel,
  onSave,
  onBack,
  onClose,
  onDismissButtonPress,
  isMultiStep
}: SelectAddToProfileModalProps): JSX.Element => {
  const [selectedItems, setSelectedItems] = useState<SelectItem[]>(items);

  const handleOnSave = useCallback(() => {
    onSave(selectedItems);
  }, [onSave, selectedItems]);

  const selectors = useMemo(
    () =>
      items
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(item => (
          <Selector
            key={item.id}
            size="medium"
            title={item.label}
            label={item.label}
            checked={!!selectedItems.find(i => i.id === item.id)}
            onCheckedChange={checked =>
              setSelectedItems(state =>
                checked ? [...state, item] : state.filter(i => i.id !== item.id)
              )
            }
          />
        )),
    [items, selectedItems]
  );

  return (
    <ModalContent
      title={title}
      saveButtonLabel={saveButtonLabel}
      onSaveButtonPress={handleOnSave}
      dismissButtonLabel="No, thanks"
      onDismissButtonPress={onDismissButtonPress}
      onBackButtonPress={onBack}
      onCloseButtonPress={onClose}
      eyebrow={addToYourSearchEyebrow}
      isMultiStep={isMultiStep}
    >
      <div className="flex flex-col gap-4">{selectors}</div>
    </ModalContent>
  );
};

export default SelectAddToProfileModal;
