import { CourseHandbookSingleTitleSubscriptionVariation as CourseHandbookSingleTitleSubscriptionVariationProps } from '@/@types/content';
import { AnalyticsContext } from '@/analytics/constants';
import withNav from '@/components/hocs/withNav';
import CourseHandbookPDP from '@/components/ui/PDP/CourseHandbookPDP';
import PDPShimmer from '@/components/ui/PDP/PDPShimmer';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import { useCHBCatalogRelations } from '@/hooks/PDP/usePublicationCatalogRelations';

const CourseHandbookSingleTitleSubscriptionVariationComponent = (
  variant: CourseHandbookSingleTitleSubscriptionVariationProps
) => {
  const catalogRelations = useCHBCatalogRelations(variant);
  const { isLoading: isLabelsLoading } = useCatalogLabels();

  if (
    (!catalogRelations.printVariant && !catalogRelations.stsVariant) ||
    isLabelsLoading ||
    catalogRelations.isLoading
  )
    return <PDPShimmer show={true} />;

  const initialVariant =
    variant.code === catalogRelations.printVariant?.code
      ? catalogRelations.printVariant
      : catalogRelations.stsVariant;

  return (
    <CourseHandbookPDP
      catalogRelations={catalogRelations}
      initialVariant={initialVariant!}
      context={AnalyticsContext.PDP}
    />
  );
};

const CourseHandbookSingleTitleSubscriptionVariation = withNav(
  CourseHandbookSingleTitleSubscriptionVariationComponent
);
export default CourseHandbookSingleTitleSubscriptionVariation;
