import Shimmer from '../Shimmer';

const LinkGridShimmer: React.FC = () => {
  return (
    <section className="w-full py-10">
      <div className="gridPLI w-full pb-8">
        <div className="col-span-2 sm:col-span-3 md:col-span-4 lg:col-span-3">
          <Shimmer className="h-7 w-full" />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {[...Array(3)].map((_, index) => (
          <Shimmer
            key={`link-grid-shimmer-${index}`}
            className="flex h-[70px] w-full sm:h-[80px]"
          />
        ))}
      </div>
    </section>
  );
};

export default LinkGridShimmer;
