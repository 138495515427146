import * as Collapsible from '@radix-ui/react-collapsible';

import Icon from '../Icon';
import type {
  FilterProps,
  FilterHeaderProps,
  FilterContentProps,
  FilterGroupProps,
  RadioItem,
  CheckItem,
  SelectorItem,
  DateSelectorItem
} from './types';
import {
  RadioFilterGroup,
  CheckFilterGroup,
  SelectorFilterGroup,
  DateRangeFilterGroup,
  YearFilterGroup
} from './FilterGroups';
import { Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  groupFilterItemsIntoSections,
  preserveFiltersOrder,
  updatePreservedFilterGroupOrder
} from '../SearchResults/utils/filters';
import Divider from '../Divider';
import { FilterTypes } from '../SearchResults/constants';
import { isStartDateFilter } from './DateFilterUtils';
import ExpandFilterButton from './ExpandFilterButton';

const FilterHeader = ({ title, collapsible }: FilterHeaderProps) => {
  return (
    <Collapsible.Trigger
      disabled={!collapsible}
      className="text-1 flex w-full justify-between font-bold [&[data-state=open]>svg]:rotate-180"
    >
      <span>{title}</span>
      {collapsible && (
        <Icon
          name="chevron-down"
          size="large"
          className="shrink-0 transition-transform duration-short"
        />
      )}
    </Collapsible.Trigger>
  );
};

const FilterGroup = (props: FilterGroupProps) => {
  const {
    type,
    onValueChange,
    columnLayout,
    visibleCount,
    items,
    className,
    title,
    isL2Panel,
    isTopLevelSection
  } = props;

  const [savedOrder, setSavedOrder] = useState(items);
  useEffect(() => {
    setSavedOrder(prev => updatePreservedFilterGroupOrder(prev, items));
  }, [items]);

  const orderDateItems = <T extends { label: string }>(list: T[]) =>
    [...list].sort((a, b) => {
      return isStartDateFilter(a.label) || isStartDateFilter(b.label) ? -1 : 1;
    });

  const renderFilterGroupContent = () => {
    switch (type) {
      case FilterTypes.SELECT: {
        return (
          <SelectorFilterGroup
            items={preserveFiltersOrder(savedOrder, items)}
            onValueChange={onValueChange}
            visibleCount={visibleCount}
            columnLayout={columnLayout}
            className={className}
          />
        );
      }
      case FilterTypes.RADIO: {
        return (
          <RadioFilterGroup
            items={preserveFiltersOrder(savedOrder, items)}
            value={props.value!}
            onValueChange={onValueChange}
            canClear={props.canClear}
            onClear={props.onClear}
            className={className}
          />
        );
      }
      case FilterTypes.CHECK: {
        return (
          <CheckFilterGroup
            items={preserveFiltersOrder(savedOrder, items)}
            onValueChange={onValueChange}
            className={className}
          />
        );
      }

      case FilterTypes.DATE: {
        return (
          <DateRangeFilterGroup
            items={orderDateItems(items)}
            onValueChange={onValueChange}
            className={className}
            minDate={props.minDate}
            maxDate={props.maxDate}
          />
        );
      }

      case FilterTypes.YEAR_SELECT: {
        return (
          <YearFilterGroup
            items={preserveFiltersOrder(savedOrder, items)}
            value={props.value!}
            onValueChange={onValueChange}
            canClear={props.canClear}
            onClear={props.onClear}
            className={className}
            dateRange={props.dateRange}
          />
        );
      }

      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      {isL2Panel && title ? (
        <Collapsible.Root defaultOpen={isTopLevelSection} className="relative">
          <FilterHeader title={title} collapsible={isL2Panel && !isTopLevelSection} />
          <Collapsible.Content className="data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown">
            <div className="mt-6">{renderFilterGroupContent()}</div>
          </Collapsible.Content>
        </Collapsible.Root>
      ) : (
        renderFilterGroupContent()
      )}
    </>
  );
};

const FilterContent = ({
  onExpandFilter,
  expandText,
  groupTypeId,
  className,
  isCollapsible,
  allowSections,
  isL2Panel,
  expandFilterButton,
  ...props
}: FilterContentProps) => {
  const hasItems = !!props.items.length;

  const sections = allowSections ? groupFilterItemsIntoSections(props.items) : [];
  const hasMultipleSections = sections.length > 1;

  const expandFilterClassName = classnames({ 'mt-2': hasItems });

  return (
    <Collapsible.Content className="data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown">
      <div
        className={classnames(
          'flex flex-col gap-4',
          { 'mt-6': isCollapsible && !isL2Panel, 'gap-8': isL2Panel },
          className
        )}
      >
        {hasMultipleSections &&
          sections.map(({ title, items, isTopLevelSection }, index) => {
            return (
              <Fragment key={title}>
                {(!!title || isTopLevelSection) && !isL2Panel && (
                  <div className="text-2 text-gray-dark">{title || <>&nbsp;</>}</div>
                )}
                <FilterGroup
                  {...props}
                  className={classnames({
                    'font-bold': isTopLevelSection
                  })}
                  groupTypeId={groupTypeId}
                  items={items as (CheckItem & RadioItem & SelectorItem & DateSelectorItem)[]}
                  title={title}
                  isL2Panel={isL2Panel}
                  isTopLevelSection={isTopLevelSection || index === 0}
                />
                {isL2Panel && index < sections.length - 1 && <Divider color="light" />}
              </Fragment>
            );
          })}
        {!hasMultipleSections && <FilterGroup {...props} groupTypeId={groupTypeId} />}

        {expandFilterButton ? (
          <div className={expandFilterClassName}>{expandFilterButton}</div>
        ) : (
          !!onExpandFilter && (
            <ExpandFilterButton
              expandText={expandText}
              onExpandFilter={onExpandFilter}
              className={expandFilterClassName}
            />
          )
        )}
      </div>
    </Collapsible.Content>
  );
};

const Filter = ({
  title,
  expandText,
  hasMore,
  onExpandFilter,
  collapsible = true,
  defaultOpen = true,
  fetching = false,
  expandFilterButton,
  ...props
}: FilterProps) => {
  const isCollapsible = collapsible && !!title;
  return (
    <div className={classnames({ 'pointer-events-none': fetching })}>
      <Collapsible.Root defaultOpen={defaultOpen} className="relative">
        {!!title && <FilterHeader title={title} collapsible={isCollapsible} />}
        <FilterContent
          onExpandFilter={hasMore ? onExpandFilter : undefined}
          expandFilterButton={hasMore ? expandFilterButton : undefined}
          expandText={expandText}
          isCollapsible={isCollapsible || !!props.items.length}
          {...props}
        />
      </Collapsible.Root>
    </div>
  );
};

export default Filter;
