import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import classnames from 'classnames';
import Icon, { IconProps } from '../ui/Icon';

interface IconCMSProps extends Omit<IconProps, 'name'> {
  name?: string;
  propertyName?: string;
}

const Text: React.FC<IconCMSProps> = ({ name, propertyName, className, ...props }) => {
  const isEditMode = isEditOrPreviewMode();

  const conditionalProps =
    isEditMode && propertyName ? { ...props, 'data-epi-edit': propertyName } : props;

  if (!isEditMode && !name) return null;

  return name ? (
    <Icon name={name} {...conditionalProps} className={classnames(className, 'relative')} />
  ) : (
    <span {...conditionalProps} className={classnames(className, 'relative block size-6')}></span>
  );
};

export default Text;
