import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import classnames from 'classnames';
import { LinkItem } from '@/@types/cms';
import Link from '@/components/cms/Link';

export interface AccordionLinkItemProps {
  description?: string;
  rightText?: string;
  icon?: string;
  itemLink?: LinkItem;
}

const AccordionLinkItem: React.FC<AccordionLinkItemProps> = ({
  description,
  rightText,
  icon,
  itemLink
}) => {
  const itemContainerClasses = classnames(
    'relative flex w-full flex-col items-start justify-between gap-4 border-t border-gray-light px-4 py-6',
    'sm:flex-row sm:items-center sm:px-6',
    'lg:px-8'
  );

  return (
    <div className={itemContainerClasses}  data-component={"AccordionLinkItem"}>
      {description && itemLink && (
        <Link
          propertyName="itemLink"
          href={itemLink.href}
          target={itemLink.target}
          className="after:absolute after:inset-0 after:size-full"
        >
          {description}
        </Link>
      )}
      {description && !itemLink && (
        <Text propertyName="Description" element="p">
          {description}
        </Text>
      )}
      {rightText && (
        <Text propertyName="Right Text" element="p" className="text-gray-dark">
          {rightText}
        </Text>
      )}
      {icon && <Icon name={icon} size="medium" className="text-gray-dark" />}
    </div>
  );
};

export default AccordionLinkItem;
