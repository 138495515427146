import { EcommPanelParams } from './hooks/useEcommData';
import { isCHBVariant } from '@/hooks/PDP/usePublicationCatalogRelations';
import useGetEcommPanelContent from './hooks/useGetEcommPanelContent';
import EcommPanelCourseHandbook from './EcommPanelCourseHandbook';
import EcommPanelABTreatise from './EcommPanelABTreatise';
import { AnalyticsContext } from '@/analytics/constants';

type EcommPanelPublicationProps = {
  params: EcommPanelParams;
  onClose: () => void;
  context?: AnalyticsContext;
};

const EcommPanelPublication = ({ params, onClose, context }: EcommPanelPublicationProps) => {
  const { content, isLoadingContent } = useGetEcommPanelContent(params?.url);

  if (content && isCHBVariant(content)) {
    return (
      <EcommPanelCourseHandbook
        content={content}
        isLoadingContent={isLoadingContent}
        params={params}
        onClose={onClose}
        context={context}
      />
    );
  }

  return (
    <EcommPanelABTreatise
      content={content}
      isLoadingContent={isLoadingContent}
      params={params}
      onClose={onClose}
      context={context}
    />
  );
};

export default EcommPanelPublication;
