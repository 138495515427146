import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Button from '@/components/ui/Buttons/Button';
import classnames from 'classnames';
import Image from '@/components/cms/Image';
import { OnDemandProgramProduct } from '@/@types/content';
import { useLaunch } from '@/hooks/shared/useLaunch';
import { useState, useEffect, useCallback } from 'react';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import {
  useGetImageUrlQuery,
  useGetMediaStreamsQuery,
  useGetProductByCodeQuery
} from '@/redux/api/client/product';
import { getProductImageUrl } from '@/components/ui/PDP/helpers';
import { ISearchResultUserInfo, MediaStream } from '@/@types/client-api';
import RichText from '@/components/cms/RichText';
import Thumbnail from '@/components/ui/Thumbnail';

const getCardColor = (url: string) => {
  const arr: string[] = url?.split('_') || [];
  const colorArr = arr.pop()?.split('.') || [];
  const color = colorArr[0] || '';
  return color;
};

type OnDemandProgramVariations = OnDemandProgramProduct;

export type OnDemandSlideProps = OnDemandProgramVariations & {
  isRegistered?: boolean;
  ctaLabel?: string;
  sellingPoints?: string;
};

interface mediaStreamPk extends MediaStream {
  item_pk?: string | null;
}

export type ButtonColor =
  | 'black'
  | 'white'
  | 'outline-black'
  | 'outline-gray'
  | 'outline-white'
  | 'light-gray'
  | 'red'
  | 'green'
  | 'gradient';

export interface ButtonProps {
  color?: ButtonColor;
}

const HomePageOnDemandSlideBlock: React.FC<OnDemandSlideProps> = ({
  code,
  displayName,
  ctaLabel = 'Launch now',
  url,
  sellingPoints
}) => {
  const [slideTitle, setSlideTitle] = useState<string>(displayName || '');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [variationPk, setVariationPk] = useState<string>(code || '');
  const [userList, setUserList] = useState<ISearchResultUserInfo[]>([]);
  const [ctaLink, setCtaLink] = useState<string>(url || '');
  const [video, setVideo] = useState<string>('');
  const [isRegisteredProduct, setIsRegisteredProduct] = useState<boolean>(false);
  const [fallbackImage, setFallbackImage] = useState<string>('');
  const [badgeText, setBadgeText] = useState<string>();

  const [backgroundColor, setBackgroundColor] = useState<string>();

  const { data } = useGetProductByCodeQuery(code || '');

  const { data: colorVariant } = useGetImageUrlQuery(variationPk);

  const { data: mediaDataResponse } = useGetMediaStreamsQuery(variationPk, {
    skip: !variationPk
  });

  const mediaData: mediaStreamPk[] | undefined = mediaDataResponse;
  useEffect(() => {
    if (data && data.length > 0) {
      setIsLoading(false);
      setSlideTitle(data[0].title || '');
      setVariationPk(data[0].variationPk || '');
      setUserList(data[0].userList || []);
      setFallbackImage(getProductImageUrl(variationPk));
      setCtaLink(data[0].url || '');
      setBackgroundColor(getCardColor(colorVariant || ''));
    } else {
      setIsLoading(false);
    }

    if (mediaData && mediaData.length > 0 && mediaData[0].item_pk === variationPk) {
      setVideo(mediaData[0].playbackUrl || '');
    }

    if (userList && userList.length > 0 && data) {
      const isRegistered = !!data[0].userList?.find(ul => ul.isPurchased);

      setIsRegisteredProduct(isRegistered);

      if (isRegistered) setBadgeText(`Registered`);
    }
  }, [data, code, isLoading, mediaData, userList, variationPk, colorVariant]);

  const { launch } = useLaunch();

  const handleSubmit = useCallback(async () => {
    if (isRegisteredProduct) {
      if (
        userList.some(
          userListItem => userListItem.isPurchased || !!userListItem.isParentProgramPurchased
        )
      ) {
        launch(
          variationPk,
          false,
          userList?.find(
            userListItem => userListItem.isPurchased || !!userListItem.isParentProgramPurchased
          )?.watchUrl ?? undefined
        );
      }
    }
  }, [isRegisteredProduct, userList, launch, variationPk]);

  const isExtraSmall = useMediaQuery(ScreenSizeQueries.xsOnly);

  const badgeClasses = classnames('text-2 text-white');

  const copyClasses = classnames('text-1 line-clamp-3 text-white max-lg:hidden');

  const headingClasses = classnames('heading-4 line-clamp-4 text-white lg:heading-3 sm:min-h-16');

  const linkContainerClasses = classnames('pt-6');

  const CarouselItemClasses = classnames(
    'relative flex max-w-[100vw] grow flex-col lg:mx-auto lg:flex-row lg:items-start lg:pt-[128px]',
    {
      'bg-green-100': backgroundColor === 'green',
      'bg-teal-100': backgroundColor === 'teal',
      'bg-blue-100': backgroundColor === 'blue',
      'bg-indigo-100': backgroundColor === 'indigo',
      'bg-purple-100': backgroundColor === 'purple',
      'bg-magenta-100': backgroundColor === 'magenta'
    }
  );

  const contentLayoutClasses = classnames('flex flex-col justify-between lg:justify-start');

  const mediaLayoutClasses = classnames(
    'mx-auto size-full pb-[88px] pt-11 sm:pb-[112px] lg:mx-0 lg:ml-4 lg:size-fit lg:pb-20 lg:pt-9'
  );

  const contentLayout = (
    <div className={contentLayoutClasses}>
      <div className="flex flex-col gap-4 pt-[204px] sm:max-w-[75%] lg:w-[630px] lg:max-w-[630px] lg:pt-0">
        <div className="flex h-5 items-center gap-2">
          {isRegisteredProduct && (
            <>
              <Icon size="medium" name="checkmark" className={badgeClasses} />
              <Text className={badgeClasses}>{badgeText}</Text>
            </>
          )}
        </div>
        <Text tabIndex={-1} element="h2" className={headingClasses}>
          {slideTitle}
        </Text>
        {sellingPoints && (
          <RichText propertyName="BodyText" content={sellingPoints} className={copyClasses} />
        )}
      </div>
      {!isLoading && (
        <div className={classnames(linkContainerClasses, 'hidden lg:block')}>
          {isRegisteredProduct && video !== '' ? (
            <Button
              color="gradient"
              tabIndex={-1}
              onClick={handleSubmit}
              label={ctaLabel}
              size="large"
              className="focus-visible:outline-white"
            />
          ) : (
            <Button
              propertyName="Link"
              color={'white' as ButtonProps['color']}
              href={ctaLink}
              label={ctaLabel}
              size={isExtraSmall ? 'small' : 'large'}
            />
          )}
        </div>
      )}
    </div>
  );

  const mediaLayout = (
    <div className={mediaLayoutClasses}>
      {video ? (
        <div className="relative size-full">
          <div className={classnames('lg:w-screen lg:max-w-[520px]')}>
            <Thumbnail
              ratio={520 / 345}
              video={
                video
                  ? {
                      autoPlay: true,
                      playable: true,
                      url: video,
                      muted: true
                    }
                  : undefined
              }
              image={
                fallbackImage
                  ? {
                      url: fallbackImage
                    }
                  : undefined
              }
              imageSizes={{
                xs: '320px',
                sm: '520px'
              }}
              tag={{
                children: 'Live',
                variant: 'emphasis'
              }}
              icon={{
                name: 'play',
                label: 'Join now'
              }}
              onClick={() => {
                if (ctaLink) {
                  window.location.href = ctaLink;
                }
              }}
            />
          </div>
        </div>
      ) : (
        <div className="relative size-full">
          <Image
            className="aspect-video size-full min-h-[185px] rounded-lg object-cover sm:min-h-[405px] lg:h-[345px] lg:min-h-[345px] lg:max-w-[520px]"
            propertyName="BackGroundImage"
            src={fallbackImage}
            alt={slideTitle}
            tabIndex={-1}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className={CarouselItemClasses} data-component={'HomePageOnDemandSlideBlock'}>
      <div className="container flex flex-col justify-between lg:flex-row">
        {contentLayout}
        {mediaLayout}
      </div>
      {!isLoading && (
        <div className="absolute bottom-0 px-6 pb-6 pt-8 md:px-12 lg:hidden">
          <div className={classnames(linkContainerClasses, 'hidden lg:block')}>
            {isRegisteredProduct && video !== '' ? (
              <Button
                color="gradient"
                tabIndex={-1}
                onClick={handleSubmit}
                label={ctaLabel}
                size="large"
                className="focus-visible:outline-white"
              />
            ) : (
              <Button
                propertyName="Link"
                color={'white' as ButtonProps['color']}
                href={ctaLink}
                label={'See details'}
                size={isExtraSmall ? 'small' : 'large'}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePageOnDemandSlideBlock;
