import { DownloadVariation } from '@/hooks/PDP/useProgramCatalogRelations';
import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SelectorButton from '../../Buttons/SelectorButton';
import { getPriceFromContent, isValidForSale } from '../helpers';
import classnames from 'classnames';
import { CreditRegionViewModel, UserLicense } from '@/@types/client-api';
import { useGetProgramCreditsQuery } from '@/redux/api/client/cle';
import { skipToken } from '@reduxjs/toolkit/query';
import Shimmer from '../../Shimmer';
import CreditLicenseCard from '../ProgramCredit/CreditLicenseCard';
import { CreditLicenseCardLabels } from '../labels';
import { ButtonColor } from '../../Buttons/Button';
import { useAddToCart } from '@/hooks/shared/useAddToCart';
import { useLaunch } from '@/hooks/shared/useLaunch';
import { AnalyticsContext } from '@/analytics/constants';
import { useLibraryItems } from '@/hooks/PDP/useLibraryItems';

export type DownloadModalLabels = {
  heading: string;
  subheading: string;
  audioSelector: string;
  videoSelector: string;
  creditForYourLicensesVideo: string;
  creditForYourLicensesAudio: string;
  allJurisdictions: string;
  launchNow: string;
  addToCart: string;
};

export type DownloadModalProps = ModalProps & {
  selectedDownloadVariant?: DownloadVariation;
  setSelectedDownloadVariant: React.Dispatch<React.SetStateAction<DownloadVariation | undefined>>;
  labels: DownloadModalLabels;
  downloadVariants: DownloadVariation[];
  licenses: UserLicense[];
  isLicensesFetching?: boolean;
  customModalId?: string;
  context: AnalyticsContext;
};

enum OnDemandMediaFormat {
  Audio = 0,
  Video = 1
}

const DownloadModal = ({
  open,
  setOpen,
  selectedDownloadVariant,
  setSelectedDownloadVariant,
  labels,
  downloadVariants,
  licenses,
  isLicensesFetching,
  customModalId,
  context
}: DownloadModalProps) => {
  const audioVariant = useMemo(
    () =>
      downloadVariants.find(
        v => v.onDemandMediaType === OnDemandMediaFormat.Audio && isValidForSale(v)
      ),
    [downloadVariants]
  );
  const videoVariant = useMemo(
    () =>
      downloadVariants.find(
        v => v.onDemandMediaType === OnDemandMediaFormat.Video && isValidForSale(v)
      ),
    [downloadVariants]
  );

  const downloadVariantPks = useMemo(() => downloadVariants.map(v => v.code), [downloadVariants]);

  const { hydrateLibrary, selectLibraryItems, isLibraryItemsFetching } = useLibraryItems();

  useEffect(() => {
    if (open) {
      hydrateLibrary(downloadVariantPks);
    }
  }, [downloadVariantPks, hydrateLibrary, open]);

  const downloadLibraryItems = selectLibraryItems(downloadVariantPks);
  const isLibraryFetching = isLibraryItemsFetching(downloadVariantPks);

  useEffect(() => {
    if (!open) setSelectedDownloadVariant(undefined);
  }, [open, setSelectedDownloadVariant]);

  useEffect(() => {
    if (open && !selectedDownloadVariant && !isLibraryFetching) {
      downloadVariants.forEach(v => {
        const libraryItem = downloadLibraryItems.find(l => l.pk === v.code);
        if (libraryItem?.isPurchased) setSelectedDownloadVariant(v);
      });
    }
  }, [
    downloadLibraryItems,
    downloadVariants,
    isLibraryFetching,
    open,
    selectedDownloadVariant,
    setSelectedDownloadVariant
  ]);

  const [canLaunch, setCanLaunch] = useState<boolean | undefined>();

  useEffect(() => {
    if (!selectedDownloadVariant) return;
    const libraryRel = downloadLibraryItems.find(l => l.pk === selectedDownloadVariant.code);
    const price = getPriceFromContent(selectedDownloadVariant);
    if (!price.retail || libraryRel?.isPurchased) setCanLaunch(true);
    else setCanLaunch(false);
  }, [downloadLibraryItems, selectedDownloadVariant]);

  useEffect(() => {
    if (selectedDownloadVariant && open) {
      document.getElementById(selectedDownloadVariant.code!)?.focus();
    }
  }, [open, selectedDownloadVariant]);

  const { launch } = useLaunch(context);
  const { addToCart } = useAddToCart(context);

  const getFooterButtonProps = useCallback(() => {
    const buttonLabel = isLibraryFetching
      ? 'Loading...'
      : canLaunch
        ? labels.launchNow
        : labels.addToCart;
    const buttonIcon = buttonLabel == labels.addToCart ? 'cart' : undefined;
    const buttonColor: ButtonColor = buttonLabel === labels.addToCart ? 'red' : 'black';
    const libraryRel = downloadLibraryItems.find(l => l.pk === selectedDownloadVariant?.code);
    const onClick = canLaunch
      ? () =>
          launch(
            selectedDownloadVariant?.code,
            false,
            libraryRel?.isPurchased ? selectedDownloadVariant?.launchUrl : undefined
          )
      : () => addToCart(selectedDownloadVariant?.code, selectedDownloadVariant?.displayName);
    if (!selectedDownloadVariant) return {};
    return {
      saveButtonLabel: buttonLabel,
      saveButtonRightIcon: buttonIcon,
      saveButtonColor: buttonColor,
      onSaveButtonPress: onClick
    };
  }, [
    addToCart,
    canLaunch,
    downloadLibraryItems,
    isLibraryFetching,
    labels.addToCart,
    labels.launchNow,
    launch,
    selectedDownloadVariant
  ]);

  const getSelectorButtonProps = useCallback(
    (variant: DownloadVariation) => {
      const libraryRel = downloadLibraryItems.find(l => l.pk === variant.code);
      const price = getPriceFromContent(variant);
      const isAudio = variant.onDemandMediaType === OnDemandMediaFormat.Audio;
      return {
        secondaryLabel: libraryRel?.isPurchased ? 'Purchased' : undefined,
        price,
        iconName: isAudio ? 'volume-max' : 'media',
        label: isAudio ? labels.audioSelector : labels.videoSelector,
        onClick: () => setSelectedDownloadVariant(isAudio ? audioVariant : videoVariant),
        className: classnames(
          selectedDownloadVariant?.code === variant.code ? 'border-2 !border-black' : ''
        ),
        code: variant.code!
      };
    },
    [
      audioVariant,
      downloadLibraryItems,
      labels.audioSelector,
      labels.videoSelector,
      selectedDownloadVariant?.code,
      setSelectedDownloadVariant,
      videoVariant
    ]
  );

  const { data: audioProgramCredit, isFetching: isAudioCreditFetching } = useGetProgramCreditsQuery(
    !audioVariant?.code ? skipToken : audioVariant.code
  );
  const { data: videoProgramCredit, isFetching: isVideoCreditFetching } = useGetProgramCreditsQuery(
    !videoVariant?.code ? skipToken : videoVariant.code
  );
  const isLicensesOrCreditsFetching =
    isLicensesFetching || isAudioCreditFetching || isVideoCreditFetching;

  const showAllJurisdictions = !licenses.length && !isLicensesFetching;
  const selectedCredits = useMemo(() => {
    if (selectedDownloadVariant?.code === audioVariant?.code)
      return audioProgramCredit?.jurisdictions ?? undefined;
    if (selectedDownloadVariant?.code === videoVariant?.code)
      return videoProgramCredit?.jurisdictions ?? undefined;
    return undefined;
  }, [
    audioProgramCredit,
    audioVariant?.code,
    selectedDownloadVariant?.code,
    videoProgramCredit,
    videoVariant?.code
  ]);

  const licenseFilteredCredits = useMemo(() => {
    if (!selectedCredits) return undefined;
    const licenseFiltered = selectedCredits?.filter(c =>
      licenses.find(l => l.creditRegion_SK === c.regionSk)
    );

    // Ordered like this to highlight the one jurisdiction that will offer credit for MP4s but not MP3s (thanks Indiana).
    const notApprovedCredits = licenseFiltered?.filter(c => c.notApproved) ?? [];
    const otherCredits = licenseFiltered?.filter(c => !c.notApproved) ?? [];

    return [...notApprovedCredits, ...otherCredits];
  }, [licenses, selectedCredits]);

  const creditForYourLicensesLabel =
    selectedDownloadVariant?.onDemandMediaType === OnDemandMediaFormat.Audio
      ? labels.creditForYourLicensesAudio
      : labels.creditForYourLicensesVideo;

  return (
    <Modal open={open} setOpen={setOpen}>
      <ModalContent
        title={labels.heading}
        subtitle={labels.subheading}
        {...getFooterButtonProps()}
        customModalId={customModalId}
      >
        {!isLibraryFetching ? (
          <div className="flex flex-col gap-4">
            {videoVariant && <SelectorButton {...getSelectorButtonProps(videoVariant)} />}
            {audioVariant && <SelectorButton {...getSelectorButtonProps(audioVariant)} />}
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <Shimmer className="h-16 w-full" />
            <Shimmer className="h-16 w-full" />
          </div>
        )}

        {selectedDownloadVariant && isLicensesOrCreditsFetching && (
          <div className="mt-12 flex flex-col gap-6">
            <Shimmer className="h-4 w-1/2" />
            <Shimmer className="h-[400px] w-full" />
          </div>
        )}

        {selectedCredits && showAllJurisdictions && (
          <div className="mb-8 mt-12 flex flex-col gap-6">
            <span className="text-2-bold">{labels.allJurisdictions}</span>
            <div className="flex flex-col gap-4">
              {selectedCredits.map((credit: CreditRegionViewModel) => (
                <CreditLicenseCard
                  key={credit.regionSk}
                  {...credit}
                  modalVariation={true}
                  labels={CreditLicenseCardLabels}
                  code={selectedDownloadVariant?.code ?? ''}
                />
              ))}
            </div>
          </div>
        )}

        {licenseFilteredCredits && !showAllJurisdictions && (
          <div className="mb-8 mt-12 flex flex-col gap-6">
            <span className="text-2-bold">{creditForYourLicensesLabel}</span>
            <div className="flex flex-col gap-4">
              {licenseFilteredCredits.map((credit: CreditRegionViewModel) => (
                <CreditLicenseCard
                  key={credit.regionSk}
                  {...credit}
                  labels={CreditLicenseCardLabels}
                  code={selectedDownloadVariant?.code ?? ''}
                />
              ))}
            </div>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default DownloadModal;
