import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import {
  TaxonomyTabContainerBlock as TaxonomyTabContainerBlockProps,
  TaxonomyTabItemBlock as TaxonomyTabItemBlockProps,
  ReferencePageSettings
} from '@/@types/content';
import GlobalTabs, { TabTrigger } from '../../ui/Tab';
import Text from '@/components/cms/Text';
import { IContent } from '@/@types/cms';
import ContentArea from '../../cms/ContentArea';
import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { transformIfPLIUrl } from '@/utils/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

type Props = TaxonomyTabContainerBlockProps;

type tabValues = IContent & {
  multiLevel?: boolean;
  isPracticeArea?: boolean;
};

type TabBlockTab = TabTrigger & {
  content: tabValues;
};

type TaxonomyTabsProps = TaxonomyTabItemBlockProps;

type PracticeAreasLabels = {
  seeMoreLabel?: string;
  seeLessLabel?: string;
  practiceAreasFollowedLabel?: string;
};

const TaxonomyTabContainerBlock: React.FC<Props> = ({ tabs, heading, url }) => {
  const editMode = isEditOrPreviewMode();
  const tabsList = tabs as TaxonomyTabsProps[];
  const [practiceAreaLabels, setPracticeAreaLabels] = useState<PracticeAreasLabels>({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activetab = searchParams.get('activetab');
  const parentRouteSegment = transformIfPLIUrl(url)?.substring(1);

  const tabsBlocks: TabBlockTab[] =
    tabsList?.map(tab => ({
      label: tab.tabTitle ?? '',
      id: String(tab.contentLink?.id),
      content: {
        ...tab
      }
    })) || [];

  const selectedTabBlock =
    tabsBlocks.find(tab => tab.label.toUpperCase() === activetab?.toUpperCase()) ?? tabsBlocks[0];

  const handleTabChange = (tab: TabBlockTab) => {
    navigate(`/${parentRouteSegment}?activetab=${tab.label}`);
  };

  const siteLabels = useSelector((state: RootState) => state.page.siteLabels);
  if (siteLabels && Object.keys(practiceAreaLabels).length === 0) {
    const { seeMoreLabel, seeLessLabel, practiceAreasFollowedLabel } =
      siteLabels as ReferencePageSettings;
    setPracticeAreaLabels({ seeMoreLabel, seeLessLabel, practiceAreasFollowedLabel });
  }

  return (
    <div className="container--children-no-padding container"  data-component={"TaxonomyTabContainerBlock"}>
      {(heading || editMode) && (
        <Text
          element="h2"
          className="heading-1 pb-[68px] pt-16 sm:pb-12 md:pb-14"
          propertyName="Heading"
        >
          {heading}
        </Text>
      )}
      <GlobalTabs
        tabsTriggers={tabsBlocks}
        variant="browse"
        selectedValue={selectedTabBlock}
        setSelectedValue={handleTabChange}
      >
        <ContentArea
          propertyName="Item"
          components={[selectedTabBlock.content]}
          componentsProps={practiceAreaLabels}
        />
      </GlobalTabs>
    </div>
  );
};

export default TaxonomyTabContainerBlock;
