export enum Currency {
  USD = 'USD'
}

export enum CurrencySign {
  USD = '$'
}

export const removeCurrencySign = (
  currencySign: CurrencySign,
  value?: string
): string | undefined => {
  if (value?.startsWith(currencySign)) {
    return value.substring(currencySign.length).trim();
  }
  return value;
};

export const extractCurrencyValue = (currencySign: CurrencySign, value?: string) => {
  const valueStr = removeCurrencySign(currencySign, value);
  if (valueStr) {
    return parseInt(valueStr, 10);
  }
  return undefined;
};
