import { ComponentProps, ReactNode, useCallback } from 'react';
import SnippetMetadata from './SnippetMetadata';
import Eyebrow from '../Eyebrow/Eyebrow';
import Shimmer from '../Shimmer';
import { Link } from 'react-router-dom';
import { transformIfPLIUrl } from '@/utils/helpers';
import { trackSelectItem } from '@/analytics/ecommerce';

import { SnippetContext } from '../SearchResults/Snippets/types';
import { snippetToAnalyticsContext } from '../SearchResults/Snippets/helpers';
import { ISearchResultItem, ItemAnalyticsModel } from '@/@types/client-api';
import { useLazyGetFacultiesAnalyticsModelByItemPksQuery } from '@/redux/api/client/itemAnalyticsModel';
import { ConvertItemAnalyticsModelBase } from '@/analytics/analyticsUtils';
import { trackViewFacultyItem } from '@/analytics/faculty';

export type SnippetContentProps = {
  metadata: ComponentProps<typeof SnippetMetadata>;
  eyebrows?: ComponentProps<typeof Eyebrow>[];
  pk?: string;
  title: string;
  link: string;
  content?: ReactNode;
  loading?: boolean;
  context?: SnippetContext;
  itemAnalyticsModel?: ItemAnalyticsModel;
  resultItem?: ISearchResultItem;
};

const SnippetContent = ({
  metadata,
  eyebrows,
  pk,
  title,
  link,
  content,
  loading,
  context,
  itemAnalyticsModel,
  resultItem
}: SnippetContentProps) => {
  const [fetchFaculties] = useLazyGetFacultiesAnalyticsModelByItemPksQuery();
  const trackEvent = useCallback(
    async (contentCode: string) => {
      const { data } = await fetchFaculties({
        individualSks: [contentCode]
      });
      if (data && data.length > 0) {
        const item = data[0];
        const items = ConvertItemAnalyticsModelBase(item);
        trackViewFacultyItem(items);
      }
    },
    [fetchFaculties]
  );
  const trackOpenLink = useCallback(() => {
    if (resultItem && resultItem.productCode) {
      trackEvent(resultItem.productCode?.toString());
    }

    if (pk) {
      trackSelectItem(itemAnalyticsModel, snippetToAnalyticsContext(context));
    }
  }, [context, itemAnalyticsModel, pk, resultItem, trackEvent]);
  return (
    <div className="flex flex-col" data-component={'SnippetContent'}>
      <div className="mb-4 flex gap-4 lg:mb-6">
        {loading ? (
          <Shimmer className="h-5 w-36" />
        ) : (
          eyebrows?.map((eyebrow, index) => (
            <Eyebrow key={index} sizeFormat="snippet" {...eyebrow} />
          ))
        )}
      </div>
      {loading ? (
        <>
          <Shimmer className="mt-5 h-6 w-full lg:h-8" />
          <Shimmer className="mb-4 mt-1 h-6 w-3/4 lg:h-8" />
        </>
      ) : (
        <Link
          to={transformIfPLIUrl(link) || link}
          className="text-link heading-5-medium mb-3 block lg:mb-4"
          onClick={trackOpenLink}
        >
          {title}
        </Link>
      )}
      <SnippetMetadata {...metadata} loading={loading} />

      {content && !loading ? <div className="mt-6">{content}</div> : null}
    </div>
  );
};

export default SnippetContent;
