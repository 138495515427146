import { ItemAnalyticsModel } from '@/@types/client-api';
import { AnalyticsEventName, AnalyticsContext } from './constants';
import { trackEvent } from './utils';
import { ConvertItemAnalyticsModelBase, createTransactionId } from './analyticsUtils';
import {
  trackAddToCart,
  trackBeginCheckoutEvent,
  trackPurchaseEvent,
  trackZeroPurchaseValueEvent
} from './ecommerce';

export const trackMoreLikeThisEvent = (itemAnalyticsModel?: ItemAnalyticsModel) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.MoreLikeThis,
      parameters: {
        context: AnalyticsContext.Cards,
        items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
      },
      skipBaseEventParameters: true
    });
  }
};

export const trackCardLaunchAProgramEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext,
  isZeroDollar?: boolean
) => {
  if (itemAnalyticsModel) {
    if (isZeroDollar) {
      const transactionId = createTransactionId('WEBZERO');
      trackAddToCart(itemAnalyticsModel, context, transactionId);
      trackBeginCheckoutEvent(itemAnalyticsModel, context, transactionId);
      trackPurchaseEvent(itemAnalyticsModel, context, transactionId);
      trackZeroPurchaseValueEvent(itemAnalyticsModel, context);
    }

    trackEvent({
      eventName: AnalyticsEventName.LaunchAProgram,
      parameters: {
        context: context || '',
        items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
      },
      skipBaseEventParameters: true
    });
  }
};

export const trackCardReadNowEvent = (itemAnalyticsModel?: ItemAnalyticsModel) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.ReadNow,
      parameters: {
        context: AnalyticsContext.Cards,
        items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
      },
      skipBaseEventParameters: true
    });
  }
};

export const trackCardRequestCreditEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.RequestCreditStart,
      parameters: {
        context: context || '',
        items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
      },
      skipBaseEventParameters: true
    });
  }
};
