import { useContext } from 'react';
import { NudgeContext, NudgeContextType } from './context';

const useNudgeContext = (): NudgeContextType => {
  const context = useContext(NudgeContext);
  if (context === undefined) {
    throw new Error('useNudgeContext must be used within a NudgeProvider');
  }
  return context;
};

export default useNudgeContext;
