import { useCallback, useEffect } from 'react';
import { AnalyticsContext, AnalyticsEventName } from './constants';
import { useLazyGetItemAnalyticsModelByItemPksQuery } from '@/redux/api/client/itemAnalyticsModel';
import { ConvertItemAnalyticsModelBase } from './analyticsUtils';
import { trackEvent } from './utils';
import { ItemAnalyticsModel } from '@/@types/client-api';

export const useTrackViewItem = (contentCode?: string) => {
  const [fetchItems] = useLazyGetItemAnalyticsModelByItemPksQuery();

  useEffect(() => {
    if (!contentCode) return;
    const fetchAndTrack = async () => {
      const { data } = await fetchItems({
        itemPks: [contentCode],
        populatePrice: true,
        limitPriceCalls: true
      });

      if (data && data.length > 0) {
        const item = data[0];
        const items = ConvertItemAnalyticsModelBase(item);

        trackEvent({
          eventName: AnalyticsEventName.ViewItem,
          parameters: {
            ecommerce: {
              currency: item.currency,
              value: item.price,
              items: [items]
            }
          }
        });
      }
    };

    fetchAndTrack();
  }, [contentCode, fetchItems]);
};

const trackContentsEvent = (
  eventName: AnalyticsEventName,
  context?: AnalyticsContext,
  itemAnalyticsModel?: ItemAnalyticsModel
) => {
  if (context && itemAnalyticsModel) {
    trackEvent({
      eventName,
      parameters: {
        context: context || '',
        items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
      },
      skipBaseEventParameters: true
    });
  }
};

export const trackPdpDownloadContentsEvent = (
  context?: AnalyticsContext,
  itemAnalyticsModel?: ItemAnalyticsModel
) => trackContentsEvent(AnalyticsEventName.DownloadContents, context, itemAnalyticsModel);

export const trackPubUpdateInfoContentsEvent = (
  context?: AnalyticsContext,
  itemAnalyticsModel?: ItemAnalyticsModel
) => trackContentsEvent(AnalyticsEventName.PubUpdateInfo, context, itemAnalyticsModel);

export const trackSearchContentsContentsEvent = (
  context?: AnalyticsContext,
  itemAnalyticsModel?: ItemAnalyticsModel
) => trackContentsEvent(AnalyticsEventName.SearchContents, context, itemAnalyticsModel);
export const useTrackViewCrossLink = () => {
  return useCallback((context?: AnalyticsContext) => {
    trackEvent({
      eventName: AnalyticsEventName.ViewCrossLink,
      parameters: {
        context: context || ''
      }
    });
  }, []);
};

export const trackDownloadScheduleEvent = () => {
  trackEvent({
    eventName: AnalyticsEventName.DownloadSchedule,
    parameters: {},
    skipBaseEventParameters: true
  });
};
