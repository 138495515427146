import { TimedAgendaBlock as TimedAgendaBlockProps } from '@/@types/content';
import RichText from '@/components/cms/RichText';
import Text from '@/components/cms/Text';
import Divider from '@/components/ui/Divider';

const TimedAgendaBlock: React.FC<TimedAgendaBlockProps> = ({
  heading,
  description,
  tableLabel,
  agendaList
}) => {
  return (
    <div className="container module-spacing"  data-component={"TimedAgendaBlock"}>
      <Text element="h2" propertyName="Heading" className="heading-5 mb-12">
        {heading}
      </Text>
      <Text propertyName="Description" className="text-1 mb-8">
        {description}
      </Text>
      <Text propertyName="TableLabel" className="text-2 mb-4 text-gray-dark">
        {tableLabel}
      </Text>

      <ol>
        <Divider color="light" />
        {agendaList?.map((agenda, index) => (
          <li>
            <div key={index} className="flex flex-row items-center gap-5 py-8">
              <div className="text-2 w-[70px] shrink-0 font-medium text-gray lg:w-20">
                {agenda.time}
              </div>
              <div className="flex grow flex-col gap-1 sm:flex-row sm:items-center sm:gap-5">
                <div className="heading-6-medium flex-1 shrink-0 text-black">{agenda.title}</div>
                {!!agenda.description && (
                  <div className="text-1 w-full flex-1 shrink-0 text-gray-dark">
                    <RichText content={agenda.description} />
                  </div>
                )}
              </div>
            </div>
            <Divider color="light" />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default TimedAgendaBlock;
