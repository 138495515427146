import { useDispatch, useSelector } from 'react-redux';
import {
  ABTreatiseCatalogRelations,
  CHBCatalogRelations,
  DigitalAccessVariation,
  isABTreatisePrintContentType,
  isABTreatiseSTSContentType,
  isCHBPrintVariant,
  isCHBSTSVariant,
  isCHBVariant,
  isPrerelease,
  PublicationCatalogRelations,
  PublicationVariation
} from './usePublicationCatalogRelations';
import { RootState } from '@/redux/store';
import { useEffect, useMemo, useState } from 'react';
import { isDefined } from '@/lib/helpers/isDefined';
import {
  EcomBookVariantFormat,
  setCatalogRelations,
  setHardSelectedVariant,
  setSoftSelectedVariant,
  Variant
} from '@/redux/slices/pdpSlice';
import {
  formatPrice,
  getPriceFromContent,
  getPriorityCode,
  isBookPermitted,
  isFreeDigitalAccess
} from '@/components/ui/PDP/helpers';
import { LibraryItem } from '@/@types/client-api';

type PublicationVariantSelection = {
  variant: PublicationVariation;
  isHardSelected: boolean;
  isStsRenewalSelected: boolean;
};

const mapPrintBookMediaTypeEnum = (bookMediaType?: number): EcomBookVariantFormat => {
  switch (bookMediaType) {
    case 1:
      return 'hardcover';
    case 2:
      return 'softcover';
    case 3:
      return 'binder';
    default:
      return 'preorder'; // Not really sure if this is right but we'll find out later
  }
};

const mapFormat = (variant: PublicationVariation) => {
  const digitalVariantAccessSource = (variant as DigitalAccessVariation)?.plusAccessSource;
  if (digitalVariantAccessSource === 'program') return 'digital-program';
  if (digitalVariantAccessSource === 'membership') return 'digital-plusMember';

  if (isPrerelease(variant)) return 'preorder';

  if (isABTreatiseSTSContentType(variant) || isCHBSTSVariant(variant)) return 'sts';

  if (isABTreatisePrintContentType(variant) || isCHBPrintVariant(variant))
    return mapPrintBookMediaTypeEnum(variant.bookMediaType);
};

const getVariantFormatSortValue = (variant: Variant) => {
  if (['digital-plusMember', 'digital-program'].includes(variant.format!)) return 0;
  if (['sts', 'sts-renewal'].includes(variant.format!)) return 1;
  return 2;
};

const isStsEligibleForRenewal = (variant: PublicationVariation) => {
  if (isCHBVariant(variant)) return false;
  if (isBookPermitted(variant)) return true;
  return false;
};

export const mapToEcomVariant = (
  variant: PublicationVariation,
  catalogRelations: PublicationCatalogRelations,
  libraryItems?: LibraryItem[],
  isStsRenewalSelected?: boolean
) => {
  const libraryItem = libraryItems?.find(l => l.pk === variant.code);
  const priceInfo = getPriceFromContent(variant);
  const format = isStsRenewalSelected ? 'sts-renewal' : mapFormat(variant);
  const isFreeDigitalAccessVariant = isFreeDigitalAccess(format);
  const strikePrice =
    isFreeDigitalAccessVariant && catalogRelations.pseudoStrikePrice
      ? formatPrice(catalogRelations.pseudoStrikePrice)
      : priceInfo.strikePrice;
  const isSts = format === 'sts';
  const isPurchasedSts = isSts ? libraryItem?.isPurchased : false;
  const isStsRenewalPermitted = isPurchasedSts ? isStsEligibleForRenewal(variant) : undefined;
  const purchasedStsExpirationDate = isPurchasedSts
    ? (libraryItem?.accessExpirationDate ?? undefined)
    : undefined;
  const ecomVariant: Variant = {
    code: variant.code!,
    publicationDate: variant.publicationDate,
    displayName: variant.displayName,
    price: priceInfo.price,
    originalPrice: strikePrice,
    purchased: isPurchasedSts,
    format: format,
    hasUpkeep: variant.hasUpkeep,
    readOnPlusUrl:
      isPurchasedSts || isFreeDigitalAccessVariant
        ? catalogRelations.bookProduct?.plusUrl
        : undefined,
    contentLinkUrl: variant.contentLink?.url,
    isStsRenewalPermitted,
    purchasedStsExpirationDate,
    priorityCode: getPriorityCode(variant)
  };
  return ecomVariant;
};

export const sortPublicationVariants = (a: Variant, b: Variant) =>
  getVariantFormatSortValue(a) - getVariantFormatSortValue(b);

export const getPublicationVariants = (
  variant: PublicationVariation,
  catalogRelations: PublicationCatalogRelations,
  libraryItems: LibraryItem[]
): PublicationVariation[] => {
  const stsLibraryItem = libraryItems?.find(l => l.pk === catalogRelations?.stsVariant?.code);
  const isStsVariantPurchased = stsLibraryItem?.isPurchased ?? false;
  // Users who already purchased an STS are allowed to see it, even if they are not presently allowed to buy it
  const canViewStsVariant = isBookPermitted(catalogRelations.stsVariant) || isStsVariantPurchased;
  if (isCHBVariant(variant))
    return [
      (catalogRelations as CHBCatalogRelations)?.printVariant,
      canViewStsVariant ? catalogRelations.stsVariant : undefined,
      !isStsVariantPurchased ? catalogRelations.digitalAccessVariant : undefined
    ].filter(isDefined);
  return [
    ...((catalogRelations as ABTreatiseCatalogRelations)?.printVariants ?? []),
    canViewStsVariant ? catalogRelations.stsVariant : undefined,
    !isStsVariantPurchased ? catalogRelations.digitalAccessVariant : undefined
  ].filter(isDefined);
};

export const usePublicationVariantSelection = (
  variant: PublicationVariation,
  catalogRelations: PublicationCatalogRelations,
  libraryItems: LibraryItem[]
) => {
  const dispatch = useDispatch();
  const selectedVariant = useSelector((state: RootState) => state.pdp.selectedVariant);
  const hardSelected = useSelector((state: RootState) => state.pdp.hardSelected);

  const variants = useMemo(
    () => getPublicationVariants(variant, catalogRelations, libraryItems),
    [catalogRelations, libraryItems, variant]
  );

  const [currentVariant, setCurrentVariant] = useState<PublicationVariantSelection>({
    variant,
    isHardSelected: false,
    isStsRenewalSelected: false
  });

  useEffect(() => {
    if (selectedVariant) {
      let variant = undefined;
      if (isFreeDigitalAccess(selectedVariant.format))
        variant = variants.find(
          v =>
            v.code === selectedVariant.code &&
            !!(v as DigitalAccessVariation)?.plusAccessSource?.length
        );
      else variant = variants.find(v => v.code === selectedVariant.code);
      if (!variant) return;
      setCurrentVariant({
        variant,
        isHardSelected: hardSelected,
        isStsRenewalSelected: selectedVariant.format === 'sts-renewal'
      });
    }
  }, [selectedVariant, variants, hardSelected, libraryItems, catalogRelations?.stsVariant?.code]);

  useEffect(() => {
    if (!currentVariant?.variant.contentLink?.url) return;
    const url = new URL(currentVariant?.variant.contentLink.url);
    const { hash } = window.location;
    window.history.replaceState({}, '', `${url.pathname}${url.search}${hash}`);
  }, [currentVariant?.variant?.contentLink?.url]);

  useEffect(() => {
    const ecomVariants = variants.map(variant =>
      mapToEcomVariant(variant, catalogRelations, libraryItems)
    );
    const sortedEcomVariants = ecomVariants.sort(sortPublicationVariants);

    dispatch(setCatalogRelations({ variants: sortedEcomVariants }));
    const currentEcomVariant = mapToEcomVariant(
      currentVariant.variant,
      catalogRelations,
      libraryItems,
      currentVariant.isStsRenewalSelected
    );
    if (currentVariant.isHardSelected) {
      dispatch(setHardSelectedVariant(currentEcomVariant));
    } else {
      dispatch(setSoftSelectedVariant(currentEcomVariant));
    }
  }, [
    catalogRelations,
    currentVariant.isHardSelected,
    currentVariant.variant,
    currentVariant.isStsRenewalSelected,
    dispatch,
    variants,
    libraryItems
  ]);

  return {
    selectedVariant: currentVariant.variant
  };
};
