import { Address, AddressCategory } from '@/@types/client-api';

export const BillingAddressTitle = 'Billing address';
export const ShippingAddressTitle = 'Shipping address';

export const isBillingAddress = (address?: Address) => address?.isPrimaryBAddress;
export const isShippingAddress = (address?: Address) => address?.isPrimarySAddress;

export const getAddressCategoryDescription = (
  categories: AddressCategory[],
  address: Address
): string => {
  if (!address.addressCategory_SK) {
    return '';
  }
  const category = categories?.find(item => item.sk === address.addressCategory_SK);
  return category?.shortDescription ?? '';
};

export const getTitle = (categories: AddressCategory[], address?: Address): string => {
  if (!address) {
    return 'New address';
  }
  if (isBillingAddress(address)) {
    return BillingAddressTitle;
  }
  if (isShippingAddress(address)) {
    return ShippingAddressTitle;
  }
  return getAddressCategoryDescription(categories, address);
};
