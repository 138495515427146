import { OrganizationMember } from '@/@types/client-api';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface CreditTrackerAdminState {
  organization: Record<string, Array<OrganizationMember>>;
}

const initialState: CreditTrackerAdminState = {
  organization: {}
};

export const creditTrackerAdminSlice = createSlice({
  name: 'creditTrackerAdmin',
  initialState,
  reducers: {
    creditTrackerAdminGetMemberSuccess: (
      state,
      action: PayloadAction<{
        request: number;
        response: Record<string, Array<OrganizationMember>>;
      }>
    ) => {
      const orgSk = action.payload.request.toString();
      if (orgSk) {
        for (const key in action.payload.response) {
          state.organization[orgSk] = action.payload.response[key];
        }
      }
    }
  }
});

export const { creditTrackerAdminGetMemberSuccess } = creditTrackerAdminSlice.actions;

export default creditTrackerAdminSlice.reducer;
