interface Paths {
  [key: string]: string;
}

export default {
  CMS_ROUTE: '*',
  OIDC_SIGNIN: '/signin-oidc',
  OIDC_POST_SIGNOUT_CALLBACK: '/post-signout-callback-oidc',
  OIDC_SIGNOUT_CALLBACK: '/signout-callback-oidc',
  PRODUCT_REDIRECT: '/product/:code',
  PRODUCT_REDIRECT_AUTH: '/product-auth/:code',
  PLUS_CONTENT_REDIRECT_AUTH: '/plus-content-redirect/:code',
  LAUNCHING: '/launching'
} as Paths;
