import { ClientAPI } from '@/redux/api';
import { LibraryFilters, LibraryItem, LibraryResponse, ProgramMaterial } from '@/@types/client-api';
import { NonNullableObjectValues } from '@/utils/types';
import {
  makeDisjunctiveFilterRequest,
  makePrimaryRequest,
  updateGroupData
} from './libraryInternal';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { LibraryFilterKey, LibraryFilterTypeMap } from '@/utils/libraryConstants';
import { RootState } from '@/redux/store';
import { FilterTypes } from '@/components/ui/SearchResults/constants';

export type LibraryRequest = NonNullableObjectValues<LibraryFilters>;

export type LibraryResponseProp = {
  items?: Array<LibraryItemProp>;
};

interface LibraryItemProp extends LibraryItem {
  parentSellingPoints?: string;
}

const libraryEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getLibrary: builder.query<LibraryResponse, LibraryRequest>({
      queryFn: async (requestBody, { dispatch, getState, forced }) => {
        // The primary request to get the search results and available filters
        const primaryRes = await makePrimaryRequest({
          requestBody,
          dispatch,
          forceRefetch: forced
        });

        if (primaryRes.error) return { error: primaryRes.error as FetchBaseQueryError };

        let dataCopy: LibraryResponse;

        try {
          dataCopy = JSON.parse(JSON.stringify(primaryRes.data)) as LibraryResponse;
        } catch (e) {
          return { error: e as FetchBaseQueryError };
        }

        const filters = Object.entries(dataCopy.summary?.filtersApplied ?? []);

        // The list of disjunctive filters that need their own query
        const disjunctiveFiltersThatNeedRequests = filters.filter(([name, value]) => {
          const type = LibraryFilterTypeMap[name];
          return [FilterTypes.RADIO, FilterTypes.SELECT].includes(type) && value;
        });

        const newFilterGroups = await Promise.all(
          disjunctiveFiltersThatNeedRequests.map(([name, value]) =>
            makeDisjunctiveFilterRequest({
              filter: { name: name as LibraryFilterKey, value: value as string },
              requestBody,
              getState: getState as () => RootState,
              dispatch
            })
          )
        );

        newFilterGroups.forEach(newGroup =>
          updateGroupData({
            newGroup,
            dataToUpdate: dataCopy
          })
        );

        return { data: dataCopy };
      },
      providesTags: () => [{ type: 'Library', id: 'List' }]
    }),
    getFullLibrary: builder.query<LibraryResponse, void>({
      query: () => ({
        url: '/user/library?includeAllSegments=true&includeArchived=true',
        method: 'GET'
      })
    }),
    getLibraryItemsByPks: builder.query<LibraryResponse, string[]>({
      query: itemPks => ({
        url: '/user/library',
        method: 'GET',
        params: {
          includeAllSegments: true,
          includeArchived: true,
          byItemPks: itemPks,
          noCache: false
        }
      }),
      providesTags: (_, __, args) => args.map(itemPk => ({ type: 'LibraryItem', id: itemPk }))
    }),
    getLibraryUpcomingRegistrations: builder.query<void, { dateRange: string } | void>({
      query: params => ({
        url: `/search?MyLibrary=Upcoming&FilterGroupsToReturn=MyLibrary`,
        method: 'GET',
        params: params ? { dateRange: params.dateRange } : undefined
      })
    }),
    getLibrarySegments: builder.query<LibraryItem[], string>({
      query: code => ({
        url: `/user/library/${code}/segments`,
        method: 'GET'
      })
    }),
    getProgramMaterials: builder.query<ProgramMaterial[], string>({
      query: code => ({
        url: `/user/library/${code}/materials`,
        method: 'GET'
      })
    }),
    addToLibrary: builder.mutation<boolean, string>({
      query: code => ({
        url: `/user/library/${code}`,
        param: `${code}`,
        method: 'POST'
      })
    }),
    removeFromLibrary: builder.mutation<boolean, string>({
      query: code => ({
        url: `/user/library/${code}`,
        param: `${code}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetLibraryQuery,
  useGetFullLibraryQuery,
  useLazyGetFullLibraryQuery,
  useLazyGetLibraryItemsByPksQuery,
  useLazyGetLibrarySegmentsQuery,
  useGetLibrarySegmentsQuery,
  useGetProgramMaterialsQuery,
  useLazyGetProgramMaterialsQuery,
  useAddToLibraryMutation,
  useRemoveFromLibraryMutation,
  useGetLibraryUpcomingRegistrationsQuery
} = libraryEndpoints;

export default libraryEndpoints;
