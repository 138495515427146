import {
  ICertificateDownloadRequest,
  ICertificateFileRequest,
  useDeleteCertificateFileMutation,
  useDownloadBulkCertificateFilesMutation,
  useDownloadExternalCertificateFilesMutation,
  useUploadCertificateFileMutation
} from '@/redux/api/client/userCertificates';
import { useCallback } from 'react';

/**
 * Manage file attachment actions.
 * @param customerId the customer
 * @returns
 */
const useCertificateFileHook = (customerId?: number) => {
  const [
    downloadIndividualCertificate,
    {
      isLoading: isIndivDownloadLoading,
      isSuccess: isIndivDownloadSuccess,
      isError: isIndivDownloadError
    }
  ] = useDownloadExternalCertificateFilesMutation();

  const [
    downloadBulkCertificates,
    {
      isLoading: isBulkDownloadLoading,
      isSuccess: isBulkDownloadSuccess,
      isError: isBulkDownloadError
    }
  ] = useDownloadBulkCertificateFilesMutation();

  const [
    uploadCertificateFile,
    { isLoading: isFileUploading, isSuccess: isUploadSuccessful, isError: isUploadError }
  ] = useUploadCertificateFileMutation();

  const [
    deleteCertificateFile,
    { isLoading: isFileDeleting, isSuccess: isDeleteFileSuccessful, isError: isDeleteFileError }
  ] = useDeleteCertificateFileMutation();

  const downloadCertificateFile = async (
    blobName?: string,
    fileName?: string | null
  ): Promise<void> => {
    if (blobName) {
      const request: ICertificateFileRequest = {
        blobName: blobName,
        customerId: customerId ?? -1,
        fileName: fileName
      };
      await downloadIndividualCertificate(request);
    }
  };

  const downloadBulkCertificateFiles = async (
    keys?: number[],
    blobUris?: string[],
    fileName?: string | null
  ): Promise<void> => {
    if (keys && customerId) {
      const data: ICertificateDownloadRequest = {
        keys: keys,
        blobUris: blobUris,
        customerId: customerId,
        fileName: fileName
      };
      await downloadBulkCertificates(data);
    }
  };

  const onFileUpload = useCallback(
    async (files: File[]) => {
      const formData = new FormData();

      for (const file of files) {
        formData.append('files', file);
      }

      const res = await uploadCertificateFile({
        file: formData
      } as ICertificateFileRequest);

      return res.data;
    },
    [uploadCertificateFile]
  );

  const deleteFile = useCallback(
    async (name: string): Promise<boolean | undefined> => {
      try {
        await deleteCertificateFile({
          customerId: customerId,
          blobName: name
        } as ICertificateFileRequest);
      } catch (ex) {
        return false;
      }
      return true;
    },
    [customerId, deleteCertificateFile]
  );

  return {
    downloadBulkCertificateFiles,
    isBulkDownloadError,
    isBulkDownloadLoading,
    isBulkDownloadSuccess,
    downloadCertificateFile,
    isIndivDownloadError,
    isIndivDownloadLoading,
    isIndivDownloadSuccess,
    onFileUpload,
    isUploadError,
    isUploadSuccessful,
    isFileUploading,
    deleteFile,
    isDeleteFileError,
    isDeleteFileSuccessful,
    isFileDeleting
  };
};

export default useCertificateFileHook;
