import Image from '@/components/cms/Image';
import RichText from '@/components/cms/RichText';
import classnames from 'classnames';
import { SignatureHeaderBlock as SignatureHeaderBlockProps } from '@/@types/content';
import Breadcrumbs, { BreadcrumbsProps } from '@/components/ui/Breadcrumbs';
import ShareButton from '@/components/ui/Buttons/ShareButton';
import Block from '@/components/cms/Block';
import { Theme } from '@/styles/darkMode';
import ContentArea from '@/components/cms/ContentArea';
import useSearchDelayedActionToasts from '@/components/ui/SearchResults/hooks/useSearchDelayedActionToasts';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

interface BlockProps extends SignatureHeaderBlockProps, BreadcrumbsProps {
  pageName?: string;
}

const SignatureHeaderBlock: React.FC<BlockProps> = ({
  contentLink,
  backgroundImage,
  contentLine1,
  themeLine1 = 'black',
  contentLine2,
  themeLine2 = 'black',
  breadcrumbs,
  pageName,
  largeCarouselArea,
  disableShareLink,
  ...props
}) => {
  let hideBreadcrumbs: boolean = false;

  const hasLargeCarousel = largeCarouselArea && largeCarouselArea.length > 0;

  const lg = useMediaQuery(ScreenSizeQueries.lg);

  if ('hideInBreadcrumbs' in props) {
    hideBreadcrumbs = props.hideInBreadcrumbs as boolean;
  }

  useSearchDelayedActionToasts();

  const signatureBlockClasses = classnames(
    'relative mt-[-160px] pt-[192px] lg:mt-[-96px] lg:pt-[128px]',
    {
      'container bg-transparent': !backgroundImage,
      'pb-6': !hasLargeCarousel
    }
  );

  const contentClasses = classnames('relative z-1', {
    container: backgroundImage
  });

  const getLineTheme = (contentLine?: string) =>
    classnames('heading-1', {
      'text-black': contentLine === 'text-black',
      'text-white': contentLine === 'text-white',
      'text-red': contentLine === 'text-red'
    });

  const lineOneTheme = getLineTheme(themeLine1);

  const lineTwoTheme = getLineTheme(themeLine2);

  let shareButtonTheme: Theme;

  switch (themeLine1) {
    case 'text-black':
      shareButtonTheme = Theme.Light;
      break;
    case 'text-white':
      shareButtonTheme = Theme.Dark;
      break;
    case 'text-red':
      shareButtonTheme = themeLine2 === 'text-black' ? Theme.Light : Theme.Dark;
      break;
    default:
      shareButtonTheme = Theme.Dark;
  }

  return (
    <Block contentLinkID={contentLink?.id} className={signatureBlockClasses}>
      <div className="absolute left-0 top-0 z-0 size-full"  data-component={"SignatureHeaderBlock"}>
        {backgroundImage && (
          <Image
            propertyName="BackgroundImage"
            src={backgroundImage?.url}
            alt={backgroundImage?.alt as string}
            className="size-full object-cover"
            responsive={{
              aspectRatio: lg ? 3 : 1.2,
              skipWrapper: true,
              imageSizes: {
                xs: '400px',
                sm: '270px',
                md: '1201px',
                lg: '1440px',
                xl: '1680px',
                '2xl': '3840px'
              }
            }}
          />
        )}
      </div>
      <article className={contentClasses}>
        {breadcrumbs && !hideBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="mt-12 sm:max-w-[66%]">
            {contentLine1 && (
              <RichText
                propertyName="ContentLine1"
                content={contentLine1 || ''}
                className={lineOneTheme}
              />
            )}
            {contentLine2 && (
              <RichText
                propertyName="ContentLine2"
                content={contentLine2 || ''}
                className={lineTwoTheme}
              />
            )}
          </div>
          {!disableShareLink && (
            <ShareButton
              className="mt-8 lg:self-end"
              shareUrl={window.location.href}
              title={pageName}
              theme={shareButtonTheme}
            />
          )}
        </div>
        {hasLargeCarousel ? (
          <ContentArea
            components={largeCarouselArea}
            componentsProps={{
              isInHeader: true
            }}
            propertyName="LargeCarouselArea"
          />
        ) : null}
      </article>
    </Block>
  );
};

export default SignatureHeaderBlock;
