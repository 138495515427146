import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  processSearchRequest,
  searchDataToSearchRequestBody
} from '../../SearchResults/utils/searchRequest';
import { FIRST_PAGE } from '../../SearchResults/constants';
import {
  findAProgramFailure,
  findAProgramLoading,
  findAProgramSuccess
} from '@/redux/slices/findAProgramSlice';
import {
  selectFindAProgramAdHocFilters,
  selectFindAProgramRequestResponse
} from '@/redux/selectors/findAProgramSelectors';
import searchEndpoints, { useGetSearchResultsQuery } from '@/redux/api/client/search';
import { SECTION_IDS } from '../constants';
import { useGetAllFiltersFromEmptySearch } from '../../SearchResults/hooks/useGetAllFiltersFromEmptySearch';
import { IFacetGroup, IFacetResultItem, SearchRequest, SearchResponse } from '@/@types/client-api';
import usePrevious from '@/hooks/usePrevious';
import { UnknownAction } from '@reduxjs/toolkit';

type UseFindAProgramInput = {
  isOpen: boolean;
  defaultFacetGroups?: IFacetGroup[];
};

const OVERRIDES = {
  pageSize: 0,
  currentPage: FIRST_PAGE,
  filterGroupsToReturn: SECTION_IDS,
  sort: null
};

const useFindAProgram = ({ isOpen, defaultFacetGroups }: UseFindAProgramInput) => {
  const dispatch = useDispatch();
  const storeRequestResponse = useSelector(selectFindAProgramRequestResponse);
  const adHocFilters = useSelector(selectFindAProgramAdHocFilters);
  const lastSearchRequest = useRef<SearchRequest>();
  const storeRequestResponseRef = useRef<{
    response: SearchResponse | null;
    request: SearchRequest | null;
  }>();

  // Kick off request to get all filters from empty search
  const { isLoading: isEmptySearchLoading, data: emptySearchData } =
    useGetAllFiltersFromEmptySearch({ skip: !isOpen }, defaultFacetGroups);

  const searchRequest = processSearchRequest(
    storeRequestResponse.response
      ? searchDataToSearchRequestBody(storeRequestResponse.response)
      : {},
    { overrides: OVERRIDES, adHocFilters }
  );

  storeRequestResponseRef.current = storeRequestResponse;
  lastSearchRequest.current = searchRequest;

  const isEmptySearch = useMemo(
    () => !searchRequest.facetGroups?.length,
    [searchRequest.facetGroups?.length]
  );

  const {
    error,
    data: _data,
    isFetching
  } = useGetSearchResultsQuery(
    {
      requestBody: searchRequest
    },
    {
      skip: !isOpen || (isEmptySearch && (emptySearchData || isEmptySearchLoading))
    }
  );
  const data = isEmptySearch ? emptySearchData : _data;
  const lastData = usePrevious(data);

  useEffect(() => {
    if (isEmptySearchLoading) {
      dispatch(findAProgramLoading('loading'));
    } else if (isFetching) {
      dispatch(findAProgramLoading('fetching'));
    } else {
      dispatch(findAProgramLoading('none'));
    }
  }, [isEmptySearchLoading, dispatch, isFetching]);

  useEffect(() => {
    error && dispatch(findAProgramFailure(error));
  }, [error, dispatch]);

  useEffect(() => {
    if (!data || lastData === data) {
      return;
    }

    if (lastSearchRequest.current) {
      let response: SearchResponse = data;

      // This is some fancy logic to disable a practice area facet that was just added and leads to zero results. We need to do this for PAs because they come from the autocomplete which is unaware of other selected filters.
      if (
        data.facetResults?.facetGroups?.length === 0 &&
        storeRequestResponseRef.current?.request
      ) {
        const penultimateSearchRequest = storeRequestResponseRef.current.request;
        const penultimateSearchResponse = storeRequestResponseRef.current.response;
        // Compare facets from last search request to penultimate search request to find what has been added
        const facetsToDisable = lastSearchRequest.current.facetGroups?.flatMap(facetGroup => {
          const penultimateRequestFacetGroup = penultimateSearchRequest.facetGroups?.find(
            penultimateFacetGroup => penultimateFacetGroup.groupTypeId === facetGroup.groupTypeId
          );
          const penultimateResponseFacetGroup =
            penultimateSearchResponse?.facetResults?.facetGroups?.find(
              penultimateFacetGroup => penultimateFacetGroup.groupTypeId === facetGroup.groupTypeId
            );

          if (!penultimateRequestFacetGroup) {
            return facetGroup.facets;
          }

          return facetGroup.facets?.filter(facet => {
            return (
              !penultimateRequestFacetGroup.facets?.find(
                penultimateFacet => penultimateFacet.value === facet.value
              ) ||
              penultimateResponseFacetGroup?.facets?.find(
                facet => facet.value === facet.value && facet.disabled
              )
            );
          });
        }) as IFacetResultItem[];

        const prevResponse = storeRequestResponseRef.current.response;
        if (facetsToDisable.length && prevResponse) {
          response = {
            ...prevResponse,
            facetResults: {
              ...prevResponse.facetResults,
              facetGroups: prevResponse.facetResults?.facetGroups?.map(facetGroup => {
                return {
                  ...facetGroup,
                  facets: facetGroup.facets?.map(facet => {
                    const isDisabled = facetsToDisable.some(
                      disabledFacet =>
                        disabledFacet.value === facet.value && disabledFacet.group === facet.group
                    );
                    return {
                      ...facet,
                      checked: !isDisabled && facet.checked,
                      disabled: isDisabled
                    };
                  })
                };
              })
            }
          };

          const searchRequestFromNewResponse = processSearchRequest(
            searchDataToSearchRequestBody(response),
            { overrides: OVERRIDES, adHocFilters }
          );

          dispatch(
            searchEndpoints.util.upsertQueryData(
              'getSearchResults',
              { requestBody: searchRequestFromNewResponse },
              response
            ) as unknown as UnknownAction
          );
        }
      }
      dispatch(
        findAProgramSuccess({
          request: lastSearchRequest.current,
          response
        })
      );
    }
  }, [data, dispatch, adHocFilters, lastData]);
};

export default useFindAProgram;
