import { useMemo } from 'react';
import { ActionItem } from '@/components/ui/PDP/ActionBar';

export const useRegisterFromRosterAction = (registerFromRoster?: () => void) => {
  const registerFromRosterAction: ActionItem | undefined = useMemo(() => {
    if (!registerFromRoster) return;
    return {
      label: 'Register from Roster',
      icon: 'users',
      onClick: () => registerFromRoster()
    };
  }, [registerFromRoster]);

  return registerFromRosterAction;
};
