import ModalContent from '@/components/ui/Modal/ModalContent';
import Filter from '@/components/ui/Filter/Filter';
import { SelectorItem, FilterItem } from '@/components/ui/Filter/types';
import Shimmer from '../Shimmer';
import FilterShimmer from '../SearchShimmer/FilterShimmer';

export type PracticeAreaModalProps = {
  items: FilterItem<SelectorItem>[];
  onSave?: () => void;
  onClearAllFilters?: () => void;
  onValueChanged?: (value?: string, checked?: boolean) => void;
  isLoading?: boolean;
  fetching?: boolean;
};

const PracticeAreaModal = ({
  items,
  onSave,
  onClearAllFilters,
  onValueChanged,
  isLoading,
  fetching
}: PracticeAreaModalProps): JSX.Element => {
  return (
    <ModalContent
      title={isLoading ? <Shimmer className="h-8 w-2/3" /> : 'Practice areas'}
      saveButtonLabel="Save changes"
      dismissButtonLabel="Clear all filters"
      isMultiStep={true}
      shouldDismissButtonClose={false}
      onDismissButtonPress={onClearAllFilters}
      onSaveButtonPress={onSave}
    >
      {isLoading ? (
        <FilterShimmer type="select" />
      ) : (
        <Filter
          collapsible={false}
          type="select"
          items={items}
          groupTypeId=""
          onValueChange={onValueChanged}
          fetching={fetching}
          visibleCount
        />
      )}
    </ModalContent>
  );
};

export default PracticeAreaModal;
