import type {
  CalloutBlock as CalloutBlockProps,
  RequestAQuoteModalBlock as RequestAQuoteModalBlockType,
  StartTrialFlow as StartTrialBlockType,
  ProductContent
} from '@/@types/content';
import classnames from 'classnames';
import Block from '@/components/cms/Block';
import Text from '@/components/cms/Text';
import Button from '@/components/ui/Buttons/Button';
import RichText from '@/components/cms/RichText';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import Icon from '@/components/ui/Icon';
import RequestAQuoteModalBlock from '@/components/blocks/RequestAQuoteModalBlock';
import StartTrialModalBlock from '@/components/blocks/StartTrialModalBlock';
import { useAddToCart } from '@/hooks/shared/useAddToCart';

enum LinkStyle {
  Primary = 'primary',
  Secondary = 'secondary'
}

type CalloutBlockPropsExtended = CalloutBlockProps & {
  productToAddToCart: ProductContent[];
};

const CalloutBlock = ({
  calloutAddToCartLink,
  calloutCopy,
  calloutCopyText,
  calloutGenericLink,
  calloutHeadingText,
  calloutIcon,
  calloutQuoteFlowtLink,
  cartLinkStyle,
  contentLink,
  genericLinkStyle,
  productToAddToCart = [],
  quoteLinkStyle,
  startTrial,
  trialLinkStyle
}: CalloutBlockPropsExtended) => {
  const { addToCart } = useAddToCart();
  const isEditMode = isEditOrPreviewMode();

  const requestAQuoteModalBlockProps = calloutQuoteFlowtLink?.[0] as RequestAQuoteModalBlockType;
  const startTrialBlockProps = startTrial?.[0] as StartTrialBlockType;

  const handleAddToCard = () => {
    if (productToAddToCart?.length) {
      const { code, displayName } = productToAddToCart[0];
      addToCart(code, displayName);
    }
  };

  return (
    <Block
      contentLinkID={contentLink?.id}
      className={classnames('bg-gray-darker py-16 text-white', 'lg:py-24')}
    >
      <div className="container"  data-component={"CalloutBlock"}>
        <div
          className={classnames(
            'flex flex-col gap-12',
            'lg:flex-row lg:items-start lg:justify-between'
          )}
        >
          <div className={classnames('sm:max-w-[536px]', 'lg:max-w-[520px]')}>
            <Text propertyName="CalloutHeadingText" element="h2" className="heading-2">
              {calloutHeadingText}
            </Text>
            <Text
              propertyName="CalloutCopyText"
              element="p"
              className={classnames('text-1 mt-4', 'sm:mt-2', 'lg:mt-4')}
            >
              {calloutCopyText}
            </Text>
            {calloutGenericLink ||
            calloutAddToCartLink ||
            calloutQuoteFlowtLink ||
            startTrialBlockProps ||
            isEditMode ? (
              <div className={classnames('mt-8 flex flex-wrap gap-4', 'lg:mt-10')}>
                {startTrialBlockProps && (
                  <StartTrialModalBlock
                    {...startTrialBlockProps}
                    triggerButton={
                      <Button
                        size="large"
                        label={startTrialBlockProps.heading}
                        color={trialLinkStyle === LinkStyle.Primary ? 'white' : 'outline-white'}
                      />
                    }
                  />
                )}

                {requestAQuoteModalBlockProps ? (
                  <RequestAQuoteModalBlock
                    {...requestAQuoteModalBlockProps}
                    triggerButton={
                      <Button
                        label={requestAQuoteModalBlockProps.heading}
                        size="large"
                        color={quoteLinkStyle === LinkStyle.Primary ? 'white' : 'outline-white'}
                      />
                    }
                  />
                ) : null}

                {calloutAddToCartLink ? (
                  <Button
                    propertyName="CalloutAddToCartLink"
                    label={calloutAddToCartLink?.text}
                    title={calloutAddToCartLink?.title}
                    size="large"
                    color={cartLinkStyle === LinkStyle.Primary ? 'white' : 'outline-white'}
                    onClick={handleAddToCard}
                  />
                ) : null}

                {calloutGenericLink ? (
                  <Button
                    propertyName="CalloutGenericLink"
                    href={calloutGenericLink?.href}
                    label={calloutGenericLink?.text}
                    title={calloutGenericLink?.title}
                    target={calloutGenericLink?.target}
                    size="large"
                    color={genericLinkStyle === LinkStyle.Primary ? 'white' : 'outline-white'}
                  />
                ) : null}
              </div>
            ) : null}
          </div>

          {calloutIcon || calloutCopy || isEditMode ? (
            <div
              className={classnames(
                'flex flex-col gap-4 rounded-lg bg-black/30 p-6',
                'sm:w-full sm:max-w-[536px] sm:p-8',
                'lg:max-w-[410px] lg:gap-6 lg:p-10'
              )}
            >
              {calloutIcon ? <Icon name={calloutIcon} size="x-large" /> : null}
              {calloutCopy ? (
                <RichText
                  propertyName="CalloutCopy"
                  content={calloutCopy}
                  className="text-1 [&_p]:my-2"
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </Block>
  );
};

export default CalloutBlock;
