import { Fragment } from 'react';
import Shimmer from '../Shimmer';
import Divider from '../Divider';

const LicensesSnippetLoadingState = (): JSX.Element => {
  const results = Array.from({ length: 10 });
  return (
    <>
      {results.map((_, index) => (
        <Fragment key={index}>
          <div className="py-10 lg:py-12">
            <div className="flex flex-col md:flex-row">
              <div className="flex flex-col md:flex-1">
                <Shimmer className="h-7 w-[156px] md:w-[260px] lg:w-[302px]" />
                <Shimmer className="mt-8 h-[22px] w-[86px] md:w-[169px] lg:w-[193px]" />
              </div>
              <Shimmer className="mt-3 h-14 w-full md:mt-0 md:h-7 md:w-[86.5px] lg:w-[77px]" />
            </div>
            <div className="mt-2 flex flex-col gap-2 md:mt-6 md:flex-row lg:gap-6">
              <Shimmer className="h-12 w-full lg:w-[410px]" />
              <Shimmer className="h-12 w-full lg:w-[410px]" />
            </div>
          </div>
          {index < results.length - 1 && <Divider color="light" />}
        </Fragment>
      ))}
    </>
  );
};

export default LicensesSnippetLoadingState;
