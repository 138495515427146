import { CertificatesPage as CertificatesPageProps, SiteSettings } from '@/@types/content';
import ContentArea from '../cms/ContentArea';
import CertificateTable, { RowInfoAction } from '@/components/ui/Tables/CertificateTable';
import withNav from '../hocs/withNav';
import Pagination from '@/components/ui/Pagination';
import { renderCertificateRows } from '@/components/ui/Certificates/util/certificateHelpers';
import { ICertificateViewModel } from '@/@types/client-api';
import FilterRow, { FilterRowRefs } from '@/components/ui/Filter/FilterRow';
import EditCertificateModal from '../ui/CertificatesPage/EditCertificateModal';
import { useCallback, useEffect, useRef, useState } from 'react';
import useToast from '@/hooks/useToast';
import Link from '../ui/Link';
import useDownloadCertificates from '../ui/CertificatesPage/hooks/useDownloadCertificates';
import DownloadIndividualCertificateLink from '@/components/ui/CertificatesPage/DownloadIndividualCertificateLink';
import DownloadAllCertificatesLink from '@/components/ui/CertificatesPage/DownloadAllCertificatesLink';
import SearchResultsFilterOverlay from '../ui/SearchResults/SearchResultsFilterOverlay';
import SearchSortMenu from '../ui/SearchResults/SearchSortMenu';
import { CERTIFICATES_SORT_OPTIONS } from '../ui/CertificatesPage/utils/constants';
import useCertificates from '../ui/CertificatesPage/hooks/useCertificates';
import Shimmer from '../ui/Shimmer';

const CertificatesPageComponent: React.FC<CertificatesPageProps & SiteSettings> = ({
  mainContentArea,
  emptyStateContent,
  filteringEmptyState
}) => {
  const filterRowRef = useRef<FilterRowRefs>(null);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const { downloadCertificate, downloadCertificates } = useDownloadCertificates();

  const {
    certificates,
    creditJurisdictions,
    filters,
    quickFilters,
    currentPage,
    isError,
    isLoading,
    isFetching,
    goToNextPage,
    goToPreviousPage,
    sortCertificates,
    clearAllFilters,
    quickFilterToggle
  } = useCertificates();

  const { showFailMessage } = useToast();

  const { count, totalPages } = certificates?.summary || {};

  const getAction = (certificate: ICertificateViewModel): RowInfoAction => {
    if (certificate.isExternal) {
      const hasAttachment =
        (certificate.certificateBlobs?.length ?? 0) > 0 || certificate.attachment_SK != null;

      return {
        component: (
          <EditCertificateModal
            isCertificatesPage={true}
            creditJurisdictions={creditJurisdictions}
            certificate={certificate}
            triggerButton={
              hasAttachment ? (
                <Link as="button" variant="link-with-icon-small" text="Edit" icon="file" />
              ) : (
                <Link as="button" variant="text-link-2" text="Edit" />
              )
            }
          />
        )
      };
    } else if (certificate.isRequestPending) {
      return {
        text: 'Requested',
        callback: () => {}
      };
    } else if (certificate.certificateBlobs?.length || certificate.attachment_SK) {
      return {
        component: (
          <DownloadIndividualCertificateLink
            certificate={certificate}
            onDownload={downloadCertificate}
          />
        )
      };
    }

    return {};
  };

  const formattedRows = renderCertificateRows(certificates?.items, getAction);

  const hasResults = certificates?.items?.length;
  const hasNoResultsFromFilters = !hasResults && quickFilters.some(filter => filter.data?.checked);

  useEffect(() => {
    if (isError) {
      showFailMessage('Error loading certificates');
    }
  }, [isError, showFailMessage]);

  useEffect(() => {
    if (!isFilterPanelOpen && filterRowRef.current) {
      filterRowRef.current.focusOnAllFiltersButton();
    }
  }, [isFilterPanelOpen]);

  const handleOnFilterMenuOpen = useCallback(() => {
    setIsFilterPanelOpen(true);
  }, []);

  return (
    <>
      <ContentArea propertyName="StandardBlackHeaderBlock" components={mainContentArea} />

      <section className="container pt-8 lg:pt-12">
        <FilterRow
          ref={filterRowRef}
          filters={quickFilters}
          loading={isLoading}
          onFilterMenuOpen={handleOnFilterMenuOpen}
          onFilterToggle={quickFilterToggle}
          sortMenu={
            <SearchSortMenu
              options={CERTIFICATES_SORT_OPTIONS}
              value={certificates?.summary?.filtersApplied?.sort}
              onValueChange={sortCertificates}
            />
          }
        />
      </section>

      {!isLoading && !hasResults ? (
        <ContentArea
          components={emptyStateContent}
          componentsProps={{
            contentOverride: hasNoResultsFromFilters
              ? {
                  heading: filteringEmptyState,
                  subheading: undefined
                }
              : undefined
          }}
        />
      ) : null}

      <section className="container pb-8 lg:pb-12">
        {isLoading || hasResults ? (
          <div className="label my-8 flex items-center gap-4 font-bold uppercase lg:my-12">
            {isLoading ? (
              <Shimmer className="h-6 w-16" />
            ) : (
              <div className="text-gray">{count || 0} results</div>
            )}
            <div className="h-4 w-px bg-gray-medium" />
            <DownloadAllCertificatesLink
              isLoading={isLoading}
              pageNumber={currentPage}
              certificates={certificates?.items ?? []}
              onDownload={downloadCertificates}
            />
          </div>
        ) : null}

        {isLoading || hasResults ? (
          <CertificateTable
            isLoading={isLoading}
            tableHeader={['Date', 'Program', 'Jurisdiction', 'Credits', '']}
            rows={formattedRows}
          />
        ) : null}

        {totalPages && hasResults ? (
          <Pagination
            className="mt-12"
            count={currentPage}
            totalCount={totalPages}
            onClickPrev={goToPreviousPage}
            onClickNext={goToNextPage}
            prevDisabled={isLoading || currentPage === 1}
            nextDisabled={isLoading || currentPage === totalPages}
          />
        ) : null}
      </section>

      <SearchResultsFilterOverlay
        isFilterPanelOpen={isFilterPanelOpen}
        setIsFilterPanelOpen={setIsFilterPanelOpen}
        filters={filters}
        sortValue={certificates?.summary?.filtersApplied?.sort}
        sortOptions={CERTIFICATES_SORT_OPTIONS}
        onSortChange={sortCertificates}
        onClear={clearAllFilters}
        fetching={isFetching}
      />
    </>
  );
};

const CertificatesPage = withNav(CertificatesPageComponent);

export default CertificatesPage;
