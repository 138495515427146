import { LibraryItem } from '@/@types/client-api';
import { isDefined } from '@/lib/helpers/isDefined';
import { useLazyGetLibraryItemsByPksQuery } from '@/redux/api/client/library';
import { PDPLibraryItem, PDPMaterials } from '@/redux/slices/pdpSlice';
import { RootState } from '@/redux/store';
import { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';

const selectFromLibrary = (library: Record<string, PDPLibraryItem>, pk?: string) => {
  const item = pk && pk in library && library[pk].data ? library[pk] : undefined;
  return item?.data;
};

const selectFromLibraryWithFetchingState = (
  library: Record<string, PDPLibraryItem>,
  pk?: string
) => {
  const item = pk && pk in library ? library[pk] : { data: undefined, isFetching: false };
  return item;
};

const selectMultipleFromLibrary = (library: Record<string, PDPLibraryItem>, pks: string[]) => {
  let selection: LibraryItem[] = [];
  pks.forEach(code => {
    if (code in library && library[code].data) selection = [...selection, library[code].data];
  });
  return selection;
};

const selectMultipleFromLibraryWithFetchingState = (
  library: Record<string, PDPLibraryItem>,
  pks: string[]
) => {
  let selection: PDPLibraryItem[] = [];
  pks.forEach(code => {
    if (code in library && library[code]) selection = [...selection, library[code]];
  });
  return selection;
};

const selectMaterials = (materials: Record<string, PDPMaterials>, code?: string) => {
  const materialsForItem =
    code && code in materials && materials[code].data ? materials[code] : undefined;
  return materialsForItem?.data;
};

export const useLibraryItems = () => {
  const auth = useAuth();

  const library = useSelector((state: RootState) => state.pdp.library);

  const materials = useSelector((state: RootState) => state.pdp.materials);

  const [getLibraryItemsByPks] = useLazyGetLibraryItemsByPksQuery();

  const hydrateLibrary = useCallback(
    (pks?: (string | undefined)[]) => {
      if (!auth.isAuthenticated) return;
      const validPks = pks?.filter(isDefined);
      if (!validPks?.length) return;
      getLibraryItemsByPks(validPks, true);
    },
    [auth.isAuthenticated, getLibraryItemsByPks]
  );

  const selectLibraryItem = useCallback(
    (pk?: string) => {
      return selectFromLibrary(library, pk);
    },
    [library]
  );

  const selectLibraryItems = useCallback(
    (pks?: (string | undefined)[]) => {
      if (!pks) return [];
      const validPks = pks.filter(isDefined);
      return selectMultipleFromLibrary(library, validPks);
    },
    [library]
  );

  const selectLibraryItemWithFetchingState = useCallback(
    (pk?: string) => {
      return selectFromLibraryWithFetchingState(library, pk);
    },
    [library]
  );

  const selectLibraryItemsWithFetchingState = useCallback(
    (pks?: (string | undefined)[]) => {
      if (!pks) return [];
      const validPks = pks.filter(isDefined);
      return selectMultipleFromLibraryWithFetchingState(library, validPks);
    },
    [library]
  );

  const isLibraryItemsFetching = useCallback(
    (pks?: (string | undefined)[]) => {
      if (!pks) return false;
      const validPks = pks.filter(isDefined);
      const items = selectMultipleFromLibraryWithFetchingState(library, validPks);
      return !!items.find(i => i.isFetching);
    },
    [library]
  );

  const selectItemMaterials = useCallback(
    (pk?: string) => {
      return selectMaterials(materials, pk);
    },
    [materials]
  );

  return {
    library,
    hydrateLibrary,
    selectLibraryItem,
    selectLibraryItems,
    selectLibraryItemWithFetchingState,
    selectLibraryItemsWithFetchingState,
    isLibraryItemsFetching,
    materials,
    selectItemMaterials
  };
};
