import { useCallback } from 'react';
import { openToast } from '@/redux/slices/pageSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/redux/store';

type Message = string | React.ReactNode;

type UseToastReturn = {
  showSuccessMessage: (message: Message) => void;
  showFailMessage: (message: Message, icon?: string) => void;
};

const useToast = (): UseToastReturn => {
  const dispatch = useDispatch<AppDispatch>();

  const showSuccessMessage = useCallback(
    (message: Message) => {
      dispatch(
        openToast({
          type: 'confirmation',
          content: message,
          icon: 'check'
        })
      );
    },
    [dispatch]
  );

  const showFailMessage = useCallback(
    (message: Message, icon?: string) => {
      dispatch(
        openToast({
          type: 'alert',
          content: message,
          icon
        })
      );
    },
    [dispatch]
  );

  return {
    showSuccessMessage,
    showFailMessage
  };
};

export default useToast;
