import { useCallback, useRef } from 'react';
import { useSearchParams as useSearchParamsOrig } from 'react-router-dom';

// Workaround to make useSearchParams stable
// https://github.com/remix-run/react-router/issues/9991#issuecomment-1574119969
export function useSearchParamsStable() {
  const [searchParams, setSearchParams] = useSearchParamsOrig();
  const setSearchParamsRef = useRef(setSearchParams);
  setSearchParamsRef.current = setSearchParams;

  // Return a stable setter (which has no dep on searchParams)
  const setSearchParamsStable = useCallback(
    (...args: Parameters<typeof setSearchParams>) => setSearchParamsRef.current(...args),
    []
  );
  return { searchParams, setSearchParams: setSearchParamsStable };
}
