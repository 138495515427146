import { trackEvent } from '@/analytics/utils';
import {
  AnalyticsContext,
  AnalyticsEventName,
  ClearAllFiltersEventProperties,
  FilterContext
} from './constants';

type TrackFilterEventProps = {
  facetGroup: string;
  facetValue: string;
  context?: FilterContext;
};

export const trackFilterEvent = ({ facetGroup, facetValue, context }: TrackFilterEventProps) => {
  trackEvent({
    eventName: AnalyticsEventName.CertificatesFilter,
    parameters: {
      facet_group_name: facetGroup,
      facet_value: facetValue,
      context: context ?? ''
    }
  });
};

export const trackClearAllFiltersEvent = ({
  facetGroup
}: Pick<TrackFilterEventProps, 'facetGroup'>) => {
  trackFilterEvent({
    ...ClearAllFiltersEventProperties,
    ...(facetGroup ? { facetGroup } : {}),
    context: FilterContext.AllFiltersPanel
  });
};

export const trackSortEvent = (method?: string) => {
  if (method) {
    trackEvent({
      eventName: AnalyticsEventName.CertificatesSort,
      parameters: { method }
    });
  }
};

export const trackDownloadEvent = (context?: AnalyticsContext) => {
  if (context) {
    trackEvent({
      eventName: AnalyticsEventName.CertificatesDownload,
      parameters: { certificates_page_context: context }
    });
  }
};

export const trackDownloadMultiEvent = (context?: AnalyticsContext) => {
  if (context) {
    trackEvent({
      eventName: AnalyticsEventName.CertificatesDownloadMulti,
      parameters: { certificates_page_context: context }
    });
  }
};

export const trackEditEvent = (context?: AnalyticsContext) => {
  if (context) {
    trackEvent({
      eventName: AnalyticsEventName.CertificatesEdit,
      parameters: { certificates_page_context: context }
    });
  }
};
