import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useDeleteAddressMutation,
  useGetUserAddressesQuery,
  usePostAddressMutation,
  useUpdateAddressMutation,
  useUpdateDefaultBillingAddressMutation,
  useUpdateDefaultShippingAddressMutation
} from '@/redux/api/client/userAddresses';
import PersonalInfo from '@/components/ui/PersonalInfo';
import { SubSection } from '@/components/ui/PersonalInfoSubSection';
import { Address } from '@/@types/client-api';
import AddressPanel from '@/components/ui/ProfilePanels/AddressPanel';
import useToast from '@/hooks/useToast';
import { getAddressesSubSections } from './subSections';
import ProfileSectionWrapper from '../ProfileSectionWrapper';
import PersonalInfoButton from '@/components/ui/PersonalInfoButton';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import { PersonalInfoAddressesBlock as PersonalInfoAddressesBlockProps } from '@/@types/content';
import useNudge from '@/hooks/shared/useNudge';
import { IComponentProps } from '@/lib/ComponentFactory';
import { trackEditProfileEvent } from '@/analytics/profile';
import { ProfileSection, NudgeAnalyticsContext } from '@/analytics/constants';
import { ProfileNudgeSectionNames } from '@/hooks/shared/useNudge/utils';

const MaxAddresses = 6;

const AddressesSection = ({
  messages,
  index,
  timeUntilStale
}: PersonalInfoAddressesBlockProps & IComponentProps): JSX.Element => {
  const { data, isLoading, isError, refetch } = useGetUserAddressesQuery();

  const [postAddress] = usePostAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [deleteAddress] = useDeleteAddressMutation();
  const [updateDefaultBillingAddress] = useUpdateDefaultBillingAddressMutation();
  const [updateDefaultShippingAddress] = useUpdateDefaultShippingAddressMutation();

  const addressEditingIndexRef = useRef<number>();
  const addressEditingRef = useRef<Address>();
  const [isEditing, setIsEditing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [focusedAddressIndex, setFocusedAddressIndex] = useState<number | undefined>(undefined);

  const { showSuccessMessage, showFailMessage } = useToast();

  const handleOnAddNewAddress = useCallback(() => {
    trackEditProfileEvent(ProfileSection.Address);
    addressEditingRef.current = undefined;
    addressEditingIndexRef.current = undefined;
    setIsEditing(true);
  }, []);

  const handleOnEditAddress = useCallback((addressIndex: number, address: Address) => {
    trackEditProfileEvent(ProfileSection.Address);
    addressEditingRef.current = address;
    addressEditingIndexRef.current = addressIndex;
    setIsEditing(true);
  }, []);

  const handleOnSetAsDefaultBillingAddress = useCallback(
    async (address: Address) => {
      setIsProcessing(true);
      try {
        await updateDefaultBillingAddress({ sk: address.sk }).unwrap();
        await refetch();
        showSuccessMessage('Address updated to default billing');
      } catch (error) {
        showFailMessage('Error updating default billing address');
      } finally {
        setIsProcessing(false);
      }
    },
    [refetch, showFailMessage, showSuccessMessage, updateDefaultBillingAddress]
  );

  const handleOnSetAsDefaultShippingAddress = useCallback(
    async (address: Address) => {
      setIsProcessing(true);
      try {
        await updateDefaultShippingAddress({ sk: address.sk }).unwrap();
        await refetch();
        showSuccessMessage('Address updated to default shipping');
      } catch (error) {
        showFailMessage('Error updating default shipping address');
      } finally {
        setIsProcessing(false);
      }
    },
    [refetch, showFailMessage, showSuccessMessage, updateDefaultShippingAddress]
  );

  const handleOnDeleteAddress = useCallback(
    async (address: Address) => {
      setIsProcessing(true);
      try {
        await deleteAddress({ sk: address.sk }).unwrap();
        await refetch();
        showSuccessMessage('Address has been deleted');
      } catch (error) {
        showFailMessage('Error deleting address');
      } finally {
        setIsProcessing(false);
      }
    },
    [deleteAddress, refetch, showFailMessage, showSuccessMessage]
  );

  const subSections = useMemo(
    (): SubSection[] =>
      getAddressesSubSections(data, isError, {
        onEdit: handleOnEditAddress,
        onSetAsDefaultBilling: handleOnSetAsDefaultBillingAddress,
        onSetAsDefaultShipping: handleOnSetAsDefaultShippingAddress,
        onDelete: handleOnDeleteAddress
      }),
    [
      data,
      handleOnDeleteAddress,
      handleOnEditAddress,
      handleOnSetAsDefaultBillingAddress,
      handleOnSetAsDefaultShippingAddress,
      isError
    ]
  );

  const handleOnSave = useCallback(
    async (data: Address) => {
      const isAddingNewAddress = addressEditingRef.current === undefined;
      setIsProcessing(true);
      try {
        if (isAddingNewAddress) {
          await postAddress(data).unwrap();
        } else {
          await updateAddress(data).unwrap();
        }
        await refetch();
        showSuccessMessage(`Address ${isAddingNewAddress ? 'added' : 'updated'}`);
      } catch (error) {
        showFailMessage('Error saving address');
      } finally {
        addressEditingRef.current = undefined;
        setIsProcessing(false);
      }
    },
    [postAddress, refetch, showFailMessage, showSuccessMessage, updateAddress]
  );

  useEffect(() => {
    isError && showFailMessage('Error loading addresses');
  }, [isError, showFailMessage]);

  useEffect(() => {
    if (!isEditing && addressEditingIndexRef.current !== undefined) {
      setFocusedAddressIndex(addressEditingIndexRef.current);
    }
  }, [isEditing]);

  const debouncedLoading = useDebounceLoading(isLoading || isProcessing);

  const nudge = useNudge({
    messagesJSON: messages,
    sectionData: data,
    sectionIndex: index,
    sectionName: ProfileNudgeSectionNames.Address,
    context: NudgeAnalyticsContext.Address,
    onAccept: () => subSections?.[0].dropdownItems?.[0].onClick(),
    timeUntilStale
  });

  return (
    <ProfileSectionWrapper>
      <PersonalInfo
        sectionTitle="Addresses"
        loading={debouncedLoading}
        focusedAddressIndex={focusedAddressIndex}
        setFocusedAddressIndex={setFocusedAddressIndex}
        subSections={subSections}
        nudge={nudge}
        button={
          !isLoading && !isError && data ? (
            <AddressPanel
              isInitialAddress={!data.items?.length}
              isOpen={isEditing}
              onOpenChange={setIsEditing}
              data={addressEditingRef.current}
              onSave={handleOnSave}
              onDelete={handleOnDeleteAddress}
              addressCategoryLookup={data.addressCategoryLookup ?? []}
              addressStateLookup={data.addressStateLookup ?? []}
              addressCountryLookup={data.addressCountryLookup ?? []}
              triggerButton={
                <PersonalInfoButton
                  label="Add"
                  disabled={subSections.length === MaxAddresses}
                  onClick={handleOnAddNewAddress}
                />
              }
            />
          ) : undefined
        }
      />
    </ProfileSectionWrapper>
  );
};

export default AddressesSection;
