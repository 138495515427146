import { useState, forwardRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RegistrationItem from './RegistrationItem';
import { UpcomingLiveProps } from '..';
import BaseBox from '../../components/BaseBox';
import { Variant, selectVariant } from '@/redux/slices/pdpSlice';
import Button from '@/components/ui/Buttons/Button';
import OnDemandCard from './OnDemandCard';
import { AppDispatch, RootState } from '@/redux/store';
import Link from '@/components/ui/Link';
import AddToCalendarModal from '../../../Modals/AddToCalendarModal';
import classnames from 'classnames';
import { AnalyticsContext } from '@/analytics/constants';

interface RegistrationsProps {
  labels: UpcomingLiveProps['labels'];
  registrations: Array<Variant>;
  onModify: () => void;
  programCard?: UpcomingLiveProps['onDemandProgram'];
  openMaterials: () => void;
  launch: () => void;
  withinPanel?: boolean;
  withinTabs?: boolean;
  context?: AnalyticsContext;
}

const Registrations = forwardRef<HTMLButtonElement, RegistrationsProps>(
  (
    {
      labels,
      registrations,
      programCard,
      onModify,
      openMaterials,
      launch,
      withinPanel,
      withinTabs,
      context
    },
    ref
  ) => {
    const dispatch = useDispatch<AppDispatch>();
    const selectedVariant = useSelector((state: RootState) => state.pdp.selectedVariant);
    const materialsLoading = useSelector((state: RootState) => state.pdp.materialsLoading);
    const nonRegisteredVariants = useSelector(
      (state: RootState) => state.pdp.catalogRelations?.variants
    )?.filter(p => !p.registered && !p.isExpired);

    const typeLabels = {
      online: labels.online,
      ['in-person']: labels.inPerson,
      groupcast: labels.groupcast
    };

    const handleSelect = useCallback(
      (variant: Variant) => {
        dispatch(selectVariant(variant));
      },
      [dispatch]
    );

    const primaryButton = useMemo(() => {
      if (materialsLoading) {
        return (
          <Button
            ref={ref}
            label="Loading"
            color="red"
            size="large"
            className={classnames('w-full', { 'mt-4': !withinPanel })}
            loading
          />
        );
      }

      if (selectedVariant?.isLive && selectedVariant?.type === 'online') {
        return (
          <Button
            ref={ref}
            label={labels.launchButton}
            color="gradient"
            size="large"
            className="w-full"
            onClick={() => {
              launch();
            }}
          />
        );
      } else if (selectedVariant?.hasMaterials) {
        return (
          <Button
            ref={ref}
            label={labels.materials}
            color={selectedVariant?.isLive ? 'gradient' : 'red'}
            size="large"
            className="w-full"
            onClick={() => {
              openMaterials();
            }}
          />
        );
      }

      return null;
    }, [labels, ref, selectedVariant, openMaterials, launch, materialsLoading, withinPanel]);

    const [showCalendarModal, setShowCalendarModal] = useState(false);

    const secondaryButton = useMemo(() => {
      if (materialsLoading) return null;

      if (
        selectedVariant?.isLive &&
        selectedVariant?.type === 'online' &&
        selectedVariant?.hasMaterials
      ) {
        return (
          <Button
            label={labels.materials}
            color={'outline-black'}
            size="large"
            className={classnames('w-full', { 'mt-4': !withinPanel })}
            onClick={() => {
              openMaterials();
            }}
          />
        );
      }

      const addToCalendarFunction = () => {
        setShowCalendarModal(!showCalendarModal);
      };

      return (
        <>
          <Button
            ref={primaryButton ? null : ref}
            label={labels.addToCalendar}
            color={primaryButton ? 'outline-black' : 'red'}
            size="large"
            className={classnames('w-full', { 'mt-4': !withinPanel })}
            onClick={() => addToCalendarFunction()}
          />
          <AddToCalendarModal
            modalHeader={labels.addToCalendarModalHeader}
            productCode={selectedVariant?.code}
            open={showCalendarModal}
            setOpen={setShowCalendarModal}
            context={context}
          />
        </>
      );
    }, [
      materialsLoading,
      selectedVariant?.isLive,
      selectedVariant?.type,
      selectedVariant?.hasMaterials,
      selectedVariant?.code,
      primaryButton,
      ref,
      labels.addToCalendar,
      labels.addToCalendarModalHeader,
      labels.materials,
      showCalendarModal,
      openMaterials,
      withinPanel,
      context
    ]);

    if (!selectedVariant) return null;

    return (
      <>
        <BaseBox.Content withinPanel={withinPanel} withinTabs={withinTabs}>
          <div
            className={classnames('flex flex-col gap-4', {
              'pt-4 lg:pt-6': !withinPanel
            })}
          >
            {registrations?.map(variant => (
              <RegistrationItem
                key={variant.code}
                label={typeLabels[variant.type!]}
                type={variant.type!}
                location={variant.location}
                address1={variant.address1}
                address2={variant.address2}
                startDate={variant.eventStartDate!}
                endDate={variant.eventEndDate}
                timezone={variant.timeZoneIdentifier!}
                isLive={variant.isLive}
                onSelect={registrations.length > 1 ? () => handleSelect(variant) : undefined}
                selected={selectedVariant.code === variant.code}
              />
            ))}
          </div>
        </BaseBox.Content>
        <BaseBox.Footer
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          codeLabel={labels.programId}
          codeValue={selectedVariant.code!}
          lowerContainer={
            registrations.length === 1 ? (
              <div className="flex justify-center">
                <Link
                  as="button"
                  text={
                    nonRegisteredVariants?.length ? labels.changeOrCancelLabel : labels.cancelLabel
                  }
                  onClick={onModify}
                />
              </div>
            ) : null
          }
          withinPanel={withinPanel}
        ></BaseBox.Footer>
        {!!programCard && registrations.length === 1 && <OnDemandCard {...programCard} />}
        {registrations.length > 1 && (
          <BaseBox.Card className="flex h-[84px] items-center justify-center">
            <Link
              as="button"
              text={nonRegisteredVariants?.length ? labels.changeOrCancelLabel : labels.cancelLabel}
              onClick={onModify}
            />
          </BaseBox.Card>
        )}
      </>
    );
  }
);

export default Registrations;
