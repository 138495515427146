import { useOnDemandSegmentCatalogRelations } from '@/hooks/PDP/useProgramCatalogRelations';
import { AnalyticsContext } from '@/analytics/constants';
import type { OnDemandWebVariation as OnDemandWebVariationProps } from '@/@types/content';
import OnDemandSegmentPDP from './OnDemandSegmentPDP';

interface OnDemandWebVariationSegmentWrapperProps {
  variant: OnDemandWebVariationProps;
}
const OnDemandWebVariationSegmentWrapper = ({
  variant
}: OnDemandWebVariationSegmentWrapperProps) => {
  const { relations, isSegmentsLoading } = useOnDemandSegmentCatalogRelations(variant);

  return (
    <OnDemandSegmentPDP
      catalogRelations={relations}
      loadingCatalogRelations={false}
      isProgramSegmentsLoading={isSegmentsLoading}
      context={AnalyticsContext.PDP}
    />
  );
};

export default OnDemandWebVariationSegmentWrapper;
