import { Order } from '@/@types/client-api';
import { TableProps } from '@/components/ui/Tables/OrderHistoryTable';
import { Currency } from '@/constants/currency';
import { formatDate } from '@/utils/helpers';
import { format as formatDateWithComma } from 'date-fns';

const FORMAT_MAP = {
  Publication: 'Publication',
  LiveWebcast: 'Live Webcast',
  OnDemand: 'On-Demand',
  LiveInPerson: 'Live Seminar'
};
type FormatType = keyof typeof FORMAT_MAP;

export const getOrderHistoryInfo = (orderHistoryItems?: Order[]): TableProps | null => {
  const rowInfo = orderHistoryItems?.map(
    ({ order_PK, format, title, orderDate, totalPrice, url, location, startDate, lineStatus }) => {
      const orderDateFormatted = orderDate && formatDate(new Date(orderDate));
      const priceToShow =
        totalPrice === 0
          ? 'Free'
          : `${totalPrice?.toLocaleString('en-US', { style: 'currency', currency: Currency.USD })}`;
      const mappedFormat = format ? FORMAT_MAP[format as FormatType] : undefined;
      const validDate = startDate ? new Date(startDate) : null;
      const dateFormatted = validDate && formatDateWithComma(validDate, 'MMMM dd, yyyy');

      return {
        rowData: [
          { rowDetail: [`Order date:`, `${orderDateFormatted}`] },
          { rowDetail: [`Order no.:`, `${order_PK}`] },
          { rowDetail: [`Price:`, priceToShow] }
        ],
        itemInfo: [mappedFormat, `${title}`],
        href: `${url}`,
        location: `${location}`,
        programDate: `${dateFormatted}`,
        isCancelled: `${lineStatus}` === 'Cancelled'
      };
    }
  );

  if (!orderHistoryItems || !rowInfo) return null;

  return {
    tableHeader: ['Item', 'Order date', 'Order no.', 'Price'],
    rows: rowInfo
  };
};

export const SORT_VALUE = {
  NEWEST: 'NewestFirst',
  OLDEST: 'OldestFirst',
  TITLE: 'TitleAZ'
};

export const ORDER_HISTORY_SORT_OPTIONS = [
  { label: 'Newest first', value: SORT_VALUE.NEWEST },
  { label: 'Oldest first', value: SORT_VALUE.OLDEST },
  { label: 'A-Z', value: SORT_VALUE.TITLE }
];

export const FORMAT_VALUE = {
  ALL: 'all',
  PROGRAMS: 'programs',
  PUBLICATIONS: 'publications'
};

export const ORDER_HISTORY_FORMAT_OPTIONS = [
  { label: 'All', value: FORMAT_VALUE.ALL },
  { label: 'Programs', value: FORMAT_VALUE.PROGRAMS },
  { label: 'Publications', value: FORMAT_VALUE.PUBLICATIONS }
];
