import { useAuth } from 'react-oidc-context';
import { useLoggedOutLicenses } from './useLoggedOutLicenses';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetLicensesQuery } from '@/redux/api/client/userLicenses';
import useRelevantCredits from './useRelevantCredits';

export const useProgramCreditLicenses = (codes: string[]) => {
  const auth = useAuth();
  const loggedOutLicenseProps = useLoggedOutLicenses();

  const { data: licenses, isFetching: isLicenseFetching } = useGetLicensesQuery(
    !auth.isAuthenticated ? skipToken : undefined
  );

  const { relevantCredits, isLoading: isRelevantCreditsLoading } = useRelevantCredits(
    codes,
    auth.isAuthenticated
      ? licenses?.items?.map(l => l.creditRegion ?? '')
      : loggedOutLicenseProps.relevantCreditsRegionParam
  );

  return {
    licenses,
    isLicenseFetching,
    loggedOutLicenseProps,
    relevantCredits,
    isRelevantCreditsLoading
  };
};
