import type { SelectorItem } from '@/components/ui/Filter/types';

import classnames from 'classnames';
import Selector from '@/components/ui/Form/Selector';
import SubPanel from './SubPanel';
import SelectedOptionButton from '@/components/ui/Form/SelectedOptionButton';
import { useCallback, useEffect, useRef, useState } from 'react';

export interface SelectSubPanelProps {
  title?: string;
  label: string;
  items?: SelectorItem[];
  selectedItem?: string;
  selectedItems?: string[];
  isMultiSelect?: boolean;
  onClosePanel?: (open: boolean) => void;
  onSelectedItemChange?: (selectedItem: string) => void;
  onSelectedItemsChange?: (selectedItems: string[]) => void;
  onTriggerClick?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  required?: boolean;
  disabled?: boolean;
}

const SelectSubPanel: React.FC<SelectSubPanelProps> = ({
  items = [],
  title,
  label,
  selectedItem,
  selectedItems,
  isMultiSelect = false,
  onClosePanel,
  onSelectedItemChange,
  onSelectedItemsChange,
  onTriggerClick,
  className,
  required = false,
  disabled = false
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedItemsNotSaved, setSelectedItemsNotSaved] = useState<string[]>([]);
  const previousSelectedItems = useRef<string[]>();

  useEffect(() => {
    setSelectedItemsNotSaved(
      isMultiSelect ? selectedItems || [] : selectedItem ? [selectedItem] : []
    );
  }, [isMultiSelect, selectedItem, selectedItems]);

  const applyItemsChange = useCallback(
    (items: string[]) => {
      if (isMultiSelect) {
        onSelectedItemsChange?.(items);
      } else {
        onSelectedItemChange?.(items.length > 0 ? items[0] : '');
      }
    },
    [isMultiSelect, onSelectedItemChange, onSelectedItemsChange]
  );

  const handleOnOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        previousSelectedItems.current = selectedItemsNotSaved;
      } else {
        onClosePanel?.(!open);
      }
      setOpen(open);
    },
    [onClosePanel, selectedItemsNotSaved]
  );

  const handleOnApplyPress = useCallback(() => {
    applyItemsChange(selectedItemsNotSaved);
    setOpen(false);
  }, [applyItemsChange, selectedItemsNotSaved]);

  const handleOnBackButtonPress = useCallback(() => {
    if (previousSelectedItems.current) {
      setSelectedItemsNotSaved(previousSelectedItems.current);
    }
    setOpen(false);
  }, []);

  const handleGetItemLabel = useCallback(
    (item: string): string => items.find(i => i.value === item)?.label || '',
    [items]
  );

  const handleOnRemoveItem = useCallback(
    (item: string) => {
      const newItems = selectedItemsNotSaved.filter(i => i !== item);
      applyItemsChange(newItems);
    },
    [applyItemsChange, selectedItemsNotSaved]
  );

  return (
    <div>
      <SubPanel
        size="small"
        isOpen={isOpen}
        isL2Panel={true}
        onOpenChange={handleOnOpenChange}
        titleSubPanel={title || label}
        onApplyPress={handleOnApplyPress}
        onBackButtonPress={handleOnBackButtonPress}
        hasHorizontalPadding={false}
        triggerButton={
          <SelectedOptionButton
            isMultiSelect={isMultiSelect}
            onGetItemLabel={handleGetItemLabel}
            selectedItems={selectedItemsNotSaved}
            label={label}
            onRemoveItem={handleOnRemoveItem}
            className={className}
            required={required}
            disabled={disabled}
            onClick={onTriggerClick}
          />
        }
      >
        <div className="flex-1 overflow-auto">
          <section className="grid pb-6">
            {items.map((item: SelectorItem) => {
              const isSelected = selectedItemsNotSaved?.includes(item.value);
              return (
                <Selector
                  key={item.label}
                  label={item.label}
                  value={item.value}
                  checked={isSelected}
                  className={classnames('hover:bg-gray-lightest', { 'text-red': isSelected })}
                  size="medium"
                  variant="subpanel"
                  onCheckedChange={checked =>
                    setSelectedItemsNotSaved(state => {
                      if (!checked) {
                        return state.filter(i => i !== item.value);
                      }
                      if (isMultiSelect) {
                        return [...state, item.value];
                      }
                      return [item.value];
                    })
                  }
                />
              );
            })}
          </section>
        </div>
      </SubPanel>
    </div>
  );
};

export default SelectSubPanel;
