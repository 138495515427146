import type { TooltipBlock as TooltipBlockProps } from '@/@types/content';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import Button from '@/components/ui/Buttons/Button';
import ComplexTooltip, { ComplexTooltipAlign } from '@/components/ui/Tooltip/ComplexTooltip';
import { dismissElement } from '@/redux/slices/pageSlice';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useWalkthrough } from '@/components/blocks/WalkthroughExperienceModalBlock.tsx';
import classnames from 'classnames';

export type TooltipBlock = TooltipBlockProps & {
  asChild?: boolean;
  cookieName?: string;
  index?: number;
  isWalkthrought?: boolean;
  totalSteps?: number;
  walkthroughSteps?: number;
  sideOffset?: number;
};

enum Side {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right'
}

const TooltipBlock: React.FC<TooltipBlock> = ({
  applyFor,
  asChild = true,
  cookieName,
  copy,
  index,
  isWalkthrought = false,
  link,
  title,
  sideOffset,
  ...rest
}) => {
  const dispatch = useDispatch();
  const dismissedElements = useSelector((state: RootState) => state.page.dismissedElements);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const walkthroughtContext = isWalkthrought ? useWalkthrough()! : undefined;
  const { state: stateWalkthrought, dispatch: dispatchWalkthrought } = walkthroughtContext || {};

  const screenSizeXsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [contentContainer, setContentContainer] = useState<HTMLDivElement | null>(null);
  const [forceClose, setForceClose] = useState<boolean>(false);

  useEffect(() => {
    const headerOffset = 100;
    const elementPosition = contentContainer?.getBoundingClientRect().top ?? 0;
    let offsetPosition = elementPosition + window.scrollY - headerOffset;

    if (index === 0) {
      offsetPosition = 0;
    } else if (index === 1) {
      offsetPosition = offsetPosition - 100;
    }

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }, [contentContainer, index, isOpen]);

  if (!isWalkthrought && dismissedElements[cookieName!]) return null;

  if (isWalkthrought && !stateWalkthrought?.isWalkthroughStarted) return null;

  if (
    (isWalkthrought && stateWalkthrought?.currentTooltip !== index) ||
    stateWalkthrought?.isCompleted
  )
    return null;

  const onOpenChange = () => {
    setIsOpen(!isOpen);

    if (!isWalkthrought) {
      handleDismiss();
    }
  };

  const onOpenWalkthrought = () => {
    dispatchWalkthrought?.({ type: 'NEXT_TOOLTIP', applyFor: applyFor });
    setIsOpen(!isOpen);
  };

  const preventCloseOverlay = (e: Event) => {
    e.preventDefault();
  };

  const preventOpenAutoFocus = (e: Event) => {
    e.preventDefault();
  };

  const handleDismiss = () => {
    dispatch(dismissElement(cookieName!));
  };

  let alignment: ComplexTooltipAlign;

  if (isWalkthrought) {
    alignment = applyFor !== 'search' ? 'start' : 'center';
  } else {
    alignment = screenSizeXsOnly ? 'end' : 'center';
  }

  let side = isWalkthrought ? Side.BOTTOM : Side.LEFT;

  if (applyFor !== 'search' && isWalkthrought) {
    side = screenSizeXsOnly ? Side.BOTTOM : Side.TOP;
  }

  const triggerClasses = classnames('w-full', {
    'h-2': isWalkthrought && applyFor === 'search',
    'h-full': isWalkthrought && applyFor !== 'search'
  });

  const boxClasses = classnames('!pointer-events-auto outline-none');

  if (forceClose) {
    return null;
  }

  let btnLabel = 'Next';

  if (
    stateWalkthrought?.currentTooltip &&
    stateWalkthrought?.totalTooltips &&
    stateWalkthrought.currentTooltip >= stateWalkthrought.totalTooltips - 1
  ) {
    btnLabel = 'Finish';
  }

  return (
    <>
      <ComplexTooltip
        modal={!isWalkthrought}
        variant={isWalkthrought ? 'walkthrough' : 'gray'}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ComplexTooltip.Trigger asChild={asChild}>
          <div className={triggerClasses}></div>
        </ComplexTooltip.Trigger>
        <ComplexTooltip.Content
          align={alignment}
          side={screenSizeXsOnly ? Side.BOTTOM : side}
          customContainer={contentContainer}
          onPointerDownOutside={preventCloseOverlay}
          onOpenAutoFocus={preventOpenAutoFocus}
          className={boxClasses}
          sideOffset={sideOffset}
          onClose={() => {
            onOpenChange();
            setForceClose(true);
          }}
          {...rest}
        >
          <div className="w-full">
            {isWalkthrought && stateWalkthrought!.totalTooltips > 1 && (
              <p className="label mb-3 text-gray-medium">
                {Math.min(index! + 1, stateWalkthrought?.totalTooltips || 1)} of{' '}
                {stateWalkthrought?.totalTooltips}
              </p>
            )}
            <h2 className="heading-6-medium mb-3">{title}</h2>
            <p className="text-2">{copy}</p>
            {isWalkthrought ? (
              <Button
                onClick={onOpenWalkthrought}
                label={btnLabel}
                className="mt-6 !no-underline"
                color="white"
              />
            ) : (
              <Button
                onClick={onOpenChange}
                label={link.text}
                className="mt-6 !no-underline"
                color="white"
              />
            )}
          </div>
        </ComplexTooltip.Content>
      </ComplexTooltip>
      <div
        ref={setContentContainer}
        className={classnames({
          'pointer-events-auto fixed z-dropdown': !isWalkthrought,
          'pointer-events-auto': isWalkthrought
        })}
      />
    </>
  );
};

export default TooltipBlock;
