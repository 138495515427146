import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import EditPanel, { EditPanelProps } from '@/components/ui/Panels/EditPanel';
import { UserContactInfo } from '@/@types/client-api';
import TextInput from '@/components/ui/Form/TextInput';
import PhoneNumberInput from '@/components/ui/Form/PhoneNumberInput';
import { isValidBusinessEmail, isValidEmail, isValidPhoneNumber } from '@/utils/formHelpers';
import useNudgeContext from '@/hooks/shared/useNudge/useNudgeContext';
import { ProfileNudgeSectionNames } from '@/hooks/shared/useNudge/utils';
import { useGetUserProfileQuery } from '@/redux/api/client/userProfile';
import ComplexTooltip from '../Tooltip/ComplexTooltip';
import { useAuth } from 'react-oidc-context';

type ContactInfoPanelProps = {
  data: UserContactInfo;
  onSave?: (data: UserContactInfo) => void;
  messages?: string;
} & Omit<EditPanelProps, 'children' | 'onSave' | 'onDelete' | 'title'>;

const ContactInfoPanel: React.FC<ContactInfoPanelProps> = ({
  isOpen,
  onOpenChange,
  theme,
  data,
  onSave,
  triggerButton,
  messages
}) => {
  const [businessEmail, setBusinessEmail] = useState<string>('');
  const [secondaryEmail, setSecondaryEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [phoneExt, setPhoneExt] = useState<string>('');
  const { openPanel, registerPanel, unregisterPanel } = useNudgeContext();
  const { lmseMailDomains } = data || {};
  const { data: profile } = useGetUserProfileQuery();

  const auth = useAuth();
  const isPM = !!(auth.user?.profile.IsPM === 'true' && auth.user?.profile.IsIM !== 'true');

  useEffect(() => {
    if (isOpen) {
      setBusinessEmail(data.businessEmail ?? '');
      setSecondaryEmail(data.secondaryEmail ?? '');
      setPhone(data.phone ?? '');
      setPhoneExt(data.phoneExt ?? '');
      registerPanel(ProfileNudgeSectionNames.Contact);
    }
  }, [data, isOpen, registerPanel]);

  useEffect(() => {
    if (!isOpen && openPanel == ProfileNudgeSectionNames.Contact)
      unregisterPanel(ProfileNudgeSectionNames.Contact);
  }, [isOpen, openPanel, unregisterPanel]);

  const handleOnClose = useCallback(() => {
    onOpenChange?.(false);
  }, [onOpenChange]);

  const handleValidateFields = useCallback((): boolean => {
    return (
      (!isPM
        ? isValidEmail(businessEmail)
        : isValidBusinessEmail(businessEmail, lmseMailDomains)) &&
      (!secondaryEmail || isValidEmail(secondaryEmail)) &&
      (!phone || isValidPhoneNumber(phone))
    );
  }, [isPM, businessEmail, lmseMailDomains, secondaryEmail, phone]);

  const handleOnSave = useCallback(() => {
    handleOnClose();
    onSave?.({
      businessEmail,
      secondaryEmail,
      phone,
      phoneExt
    });
  }, [businessEmail, handleOnClose, onSave, phone, phoneExt, secondaryEmail]);

  const suppressFields = useMemo(
    () => profile?.rosterManagmentEditingRestriction != null,
    [profile?.rosterManagmentEditingRestriction]
  );

  const [showDisabledTooltip, setShowDisabledTooltip] = useState<number | null>(null);

  const disabledTooltipMessage = messages ? JSON.parse(messages).tooltip.disabled : '';
  const [contentContainer, setContentContainer] = useState<HTMLElement | null>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const handleDisabledFieldClick = (fieldId: number) => {
    setShowDisabledTooltip(fieldId);
  };

  const handleDismiss = () => {
    setShowDisabledTooltip(null);
  };

  return (
    <EditPanel
      title="Contact information"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      theme={theme}
      onSave={handleOnSave}
      onValidateFields={handleValidateFields}
      triggerButton={triggerButton}
      contentRef={panelRef}
    >
      <TextInput
        label="Business email"
        value={businessEmail}
        onChange={setBusinessEmail}
        required
        maxLength={100}
        type="email"
        errors={[
          {
            message: `Please enter a valid email`,
            match: (value: string) => !isValidEmail(value)
          },
          {
            message: `Your email address is not approved for use with your organization’s Privileged Membership. Please contact your account manager or PLI customer service to update`,
            match: (value: string) =>
              isPM && isValidEmail(value) && !isValidBusinessEmail(value, lmseMailDomains)
          }
        ]}
        className="pb-10"
        onClick={() => handleDisabledFieldClick(1)}
      />
      <TextInput
        label="Secondary email"
        value={secondaryEmail}
        onChange={setSecondaryEmail}
        maxLength={100}
        type="email"
        className="pb-10"
      />
      <div className="flex gap-2.5">
        <div className="flex-1">
          <PhoneNumberInput label="Telephone number" value={phone} onChange={setPhone} />
        </div>
        <div className="max-w-24">
          <TextInput
            label="Extension"
            value={phoneExt}
            onChange={setPhoneExt}
            maxLength={5}
            acceptLetters={false}
            acceptSpaces={false}
            acceptSpecialChars={false}
          />
        </div>
      </div>

      {suppressFields && (
        <div
          style={{
            position: 'absolute',
            display: 'inline-block',
            width: 0
          }}
        >
          <ComplexTooltip isOpen={showDisabledTooltip !== null} variant="light">
            <ComplexTooltip.Trigger>
              <div className="ml-2 inline-flex items-center justify-center hover:text-gray-darker focus:text-gray-darker"></div>
            </ComplexTooltip.Trigger>
            <ComplexTooltip.Content
              side="bottom"
              sideOffset={10}
              alignOffset={-75}
              align="center"
              avoidCollisions={true}
              onClose={handleDismiss}
              customContainer={contentContainer}
            >
              <div style={{ position: 'relative' }}>
                <p className="text-2">{disabledTooltipMessage}</p>
              </div>
            </ComplexTooltip.Content>
          </ComplexTooltip>
          <div ref={setContentContainer} />
        </div>
      )}
    </EditPanel>
  );
};

export default ContactInfoPanel;
