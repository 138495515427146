import { FILTERS } from '../SearchResults/constants';

export const AUTOCOMPLETE_CATEGORIES = {
  PRACTICE_AREA: 'Practice area',
  CREDIT_TYPE: 'Credit type',
  JURISDICTION: 'Jurisdiction'
};

export const API_TYPES_TO_AUTOCOMPLETE_CATEGORY: Record<string, string> = {
  Major: AUTOCOMPLETE_CATEGORIES.PRACTICE_AREA
};

export const API_TYPES_TO_FILTERS: Record<string, string> = {
  Major: FILTERS.PRACTICE_AREA
};

export const FE_FILTER_TO_AUTOCOMPLETE_CATEGORY: Record<string, string> = {
  [FILTERS.CREDIT_TYPE]: AUTOCOMPLETE_CATEGORIES.CREDIT_TYPE,
  [FILTERS.JURISDICTIONS]: AUTOCOMPLETE_CATEGORIES.JURISDICTION
};

export const AUTOCOMPLETE_CATEGORY_ORDER = [
  AUTOCOMPLETE_CATEGORIES.JURISDICTION,
  AUTOCOMPLETE_CATEGORIES.CREDIT_TYPE,
  AUTOCOMPLETE_CATEGORIES.PRACTICE_AREA
];

export const SUGGESTIONS_ID = 'suggestions';
