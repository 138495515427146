import { AnalyticsEventName, ProfileSection } from './constants';
import { trackEvent } from './utils';

export const trackEditProfileEvent = (section: ProfileSection) => {
  trackEvent({
    eventName: AnalyticsEventName.EditProfile,
    parameters: {
      profile_section: section
    }
  });
};

const trackViewItemEvent = (eventName: AnalyticsEventName, value: string) => {
  if (value) {
    trackEvent({ eventName, parameters: { value } });
  }
};

export const trackViewPracticeAreaEvent = (value: string) =>
  trackViewItemEvent(AnalyticsEventName.ViewPracticeAreaItem, value);

export const trackViewIndustryEvent = (value: string) =>
  trackViewItemEvent(AnalyticsEventName.ViewIndustryItem, value);

export const trackViewFacultyEvent = (value: string) =>
  trackViewItemEvent(AnalyticsEventName.ViewFacultyItem, value);

const trackOrderHistoryEvent = (eventName: AnalyticsEventName, value: string) => {
  if (value) {
    trackEvent({ eventName, parameters: { facet_value: value } });
  }
};

export const trackOrderHistorySortEvent = (value: string) =>
  trackOrderHistoryEvent(AnalyticsEventName.OrderHistorySort, value);

export const trackOrderHistoryFilterEvent = (value: string) =>
  trackOrderHistoryEvent(AnalyticsEventName.OrderHistoryFilter, value);
