import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignoutResponse, State } from 'oidc-client-ts';
import { OIDCState, userManager } from './oidcConfig';
import Awaiter from '@/components/ui/Awaiter/Awaiter';

export class UrlUtils {
  public static readParams(
    url: string,
    responseMode: 'query' | 'fragment' = 'query'
  ): URLSearchParams {
    if (!url) throw new TypeError('Invalid URL');
    // the base URL is irrelevant, it's just here to support relative url arguments
    const parsedUrl = new URL(url, 'http://127.0.0.1');
    const params = parsedUrl[responseMode === 'fragment' ? 'hash' : 'search'];
    return new URLSearchParams(params.slice(1));
  }
}

const readSignoutResponseState = async (
  url: string,
  removeState = false
): Promise<State | undefined> => {
  const response = new SignoutResponse(
    UrlUtils.readParams(url, userManager.settings.response_mode)
  );
  if (!response.state) {
    return undefined;
  }
  const storedStateString =
    (await userManager.settings.stateStore[removeState ? 'remove' : 'get'](response.state)) ?? '';
  if (!storedStateString) {
    return undefined;
  }
  const state = await State.fromStorageString(storedStateString);
  return state;
};

export const AuthPostSignOut = () => {
  const navigate = useNavigate();
  useEffect(() => {
    readSignoutResponseState(window.location.href, true).then(state => {
      console.info('DIAG:Post Logout state', state);
      if (state && state.data && (state.data as OIDCState).returnUrl)
        navigate((state.data as OIDCState).returnUrl);
      else navigate('/');
    });
  }, [navigate]);
  console.info('DIAG:Render post signing out ...');
  //return <Awaiter content={['Signing you out...', 'Hang in there...', 'One moment...']} />;
  return <></>;
};
export const AuthSignOut = () => {
  console.info('DIAG:Render signin out...');
  //return <Awaiter content={['Signing you out...', 'Hang in there...', 'One moment...']} />;
  return <></>;
};
export const AuthSignIn = () => {
  console.info('DIAG:Render signing in...');
  return <Awaiter content={['Signing you in...', 'Hang in there...', 'One moment...']} />;
};
