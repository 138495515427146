import { ComponentProps } from 'react';
import Link from '../Link';
import Icon from '../Icon';
import Shimmer from '../Shimmer';
import DotSeparatedContent from '../DotSeparatedContent';

type SnippetMetadataLinkProps = ComponentProps<typeof Link> & {
  to: string;
  label: string;
};

export const SnippetMetadataLink = ({ to, label, ...props }: SnippetMetadataLinkProps) => {
  return (
    <span className="inline-block">
      <Link href={to} text={label} variant="text-link-2" {...props} />
    </span>
  );
};

type SnippetMetadataIconProps = {
  name: string;
};

export const SnippetMetadataIcon = ({ name }: SnippetMetadataIconProps) => {
  return <Icon size="small" name={name} className="inline-block align-text-top" />;
};

export type SnippetMetadataProps = {
  items: React.ReactNode[];
  loading?: boolean;
};

const SnippetMetadata = ({ items = [], loading }: SnippetMetadataProps) => {
  return (
    <div className="text-2">
      {loading ? (
        <div className="flex h-4 lg:h-5">
          {Array.from({ length: 2 }).map((_, index) => (
            <Shimmer className="mr-4 h-3 w-1/4" key={index} />
          ))}
        </div>
      ) : (
        <DotSeparatedContent content={items} />
      )}
    </div>
  );
};

export default SnippetMetadata;
