import { useState } from 'react';
import { FILTERS } from '../SearchResults/constants';
import AddLicenseModalFlow from '../SearchModals/AddLicenseModalFlow';
import AddPracticeAreaFlow from '../SearchModals/AddPracticeAreaFlow';
import ViewAllCreditTypesModal from './ViewAllCreditTypesModal';
import { FilterButtonGroupProps } from './types';
import { FilterContext, SearchContext } from '@/analytics/constants';

type FindAProgramModalProps = {
  filterGroups?: FilterButtonGroupProps[];
  groupTypeId: string;
  fetching?: boolean;
  triggerButton: JSX.Element | null;
  context?: FilterContext;
  searchContext?: SearchContext;
};

const FindAProgramModal = ({
  filterGroups,
  groupTypeId,
  fetching,
  triggerButton,
  context,
  searchContext
}: FindAProgramModalProps): JSX.Element | null => {
  const [open, setOpen] = useState(false);

  if (groupTypeId === FILTERS.JURISDICTIONS) {
    return (
      <AddLicenseModalFlow
        open={open}
        setOpen={setOpen}
        fetching={fetching}
        triggerButton={triggerButton}
        context={context}
        searchContext={searchContext}
      />
    );
  }

  if (groupTypeId === FILTERS.PRACTICE_AREA) {
    return (
      <AddPracticeAreaFlow
        open={open}
        setOpen={setOpen}
        fetching={fetching}
        triggerButton={triggerButton}
        context={context}
        searchContext={searchContext}
      />
    );
  }

  if (groupTypeId === FILTERS.CREDIT_TYPE) {
    return (
      <ViewAllCreditTypesModal
        open={open}
        setOpen={setOpen}
        filterGroups={filterGroups}
        fetching={fetching}
        triggerButton={triggerButton}
        context={context}
        searchContext={searchContext}
      />
    );
  }

  return triggerButton;
};

export default FindAProgramModal;
