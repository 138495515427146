import { ItemAnalyticsModel } from '@/@types/client-api';
import { AnalyticsContext } from '@/analytics/constants';
import { trackStartListeningEvent } from '@/analytics/library';
import { useEffect } from 'react';

interface PodcastMediaPlayerProps {
  playerUrl: string;
  className?: string;
  hasVideo?: boolean;
  itemAnalyticsModel?: ItemAnalyticsModel;
  context?: AnalyticsContext;
}

const PodcastMediaPlayer: React.FC<PodcastMediaPlayerProps> = ({
  playerUrl,
  className,
  context,
  itemAnalyticsModel,
  hasVideo
}) => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin.includes('podbean.com')) {
        if (event?.data?.event === 'PB.Widget.Events.PLAY') {
          trackStartListeningEvent(itemAnalyticsModel, context);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [itemAnalyticsModel, context]);

  return (
    <section className={className}>
      <iframe
        src={playerUrl}
        className={`w-full ${hasVideo ? 'aspect-video' : 'aspect-auto'}`}
        width="100%"
        height="auto"
      />
    </section>
  );
};

export default PodcastMediaPlayer;
