import { OnDemandPbrWebVariation as OnDemandPbrWebVariationProps } from '@/@types/content';
import withNav from '@/components/hocs/withNav';
import OnDemandWebVariationProgramWrapper from '@/components/ui/PDP/OnDemandWebVariationProgramWrapper';

const OnDemandPbrWebVariationComponent = (variant: OnDemandPbrWebVariationProps) => {
  return <OnDemandWebVariationProgramWrapper variant={variant} />;
};

const OnDemandPbrWebVariation = withNav(OnDemandPbrWebVariationComponent);
export default OnDemandPbrWebVariation;
