import React, { useCallback } from 'react';

import FindAProgramButtons from '../FindAProgram/FindAProgramButtons';

import useFindAProgram from '../FindAProgram/hooks/useFindAProgram';
import { useDispatch, useSelector } from 'react-redux';

import { selectFindAProgram } from '@/redux/selectors/findAProgramSelectors';
import { toggleFindAProgramFilter } from '@/redux/slices/findAProgramSlice';
import { useBuildNewSearchLink } from '../SearchResults/utils/searchParams';
import { useNavigate } from 'react-router-dom';
import { ToggleFilterPayload } from './types';
import { trackSearchEvent } from '@/analytics/search';
import { setAdHocFilters } from '@/redux/slices/searchSlice';
import { FilterContext, SearchContext } from '@/analytics/constants';
import { IFacetGroup } from '@/@types/client-api';

export interface FindAProgramProps {
  isOpen?: boolean;
  className?: string;
  title?: string;
  tooltip?: string;
  onSearch?: () => void;
  onClearAll?: () => void;
  onNewParams?: (params: URLSearchParams) => URLSearchParams;
  footerBackgroundColor?: string;
  suggestions?: JSX.Element;
  footerFixed?: boolean;
  context?: FilterContext;
  searchContext?: SearchContext;
  defaultFacetGroups?: IFacetGroup[];
}

const FindAProgram: React.FC<FindAProgramProps> = ({
  isOpen = true,
  className,
  title,
  tooltip,
  onSearch,
  onClearAll,
  onNewParams = p => p,
  footerBackgroundColor,
  suggestions,
  footerFixed,
  context,
  searchContext,
  defaultFacetGroups
}) => {
  const { filterGroups, loading, fetching, data } = useSelector(selectFindAProgram);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useFindAProgram({ isOpen, defaultFacetGroups });
  const buildNewSearchLink = useBuildNewSearchLink();

  const handleToggleFilter = useCallback(
    (payload: ToggleFilterPayload) => {
      dispatch(toggleFindAProgramFilter({ ...payload, context, searchContext }));
      dispatch(setAdHocFilters(payload));
    },
    [context, dispatch, searchContext]
  );

  const handleSearch = useCallback(() => {
    if (data) {
      const { newSearchLink, newSearchParams } = buildNewSearchLink(data, onNewParams);
      trackSearchEvent(newSearchParams);
      navigate(newSearchLink);
      onSearch?.();
    }
  }, [data, onNewParams, navigate, onSearch, buildNewSearchLink]);

  return (
    <FindAProgramButtons
      title={title}
      tooltip={tooltip}
      filterGroups={filterGroups}
      className={className}
      onToggleFilter={handleToggleFilter}
      onClearAll={onClearAll}
      onSearch={handleSearch}
      loading={loading}
      fetching={fetching}
      footerBackgroundColor={footerBackgroundColor}
      suggestions={suggestions}
      footerFixed={footerFixed}
      context={context}
      searchContext={searchContext}
    />
  );
};

export default FindAProgram;
