import { useCallback, useEffect, useMemo, useState } from 'react';
import AddLicenseModal from '../LicenseModals/AddLicenseModal';
import {
  findCreditJurisdiction,
  getComplianceGroupOptions,
  getCreditTypeOptions,
  getEnrichedLicenseData,
  getJurisdictionsOptions,
  getLicenseNumberSettings,
  LicenseData
} from '../SearchModals/utils/licenses';
import { CreditJurisdiction, UserLicense } from '@/@types/client-api';

type AddNewLicenseButtonProps = {
  creditJurisdictions?: CreditJurisdiction[];
  onSave: (licenseData: LicenseData) => void;
  triggerButton: JSX.Element;
  isLoggedOut?: boolean;
  handleLoggedOutSave?: (license: UserLicense) => void;
};

const AddNewLicenseButton = ({
  creditJurisdictions,
  onSave,
  triggerButton,
  isLoggedOut = false,
  handleLoggedOutSave
}: AddNewLicenseButtonProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [licenseData, setLicenseData] = useState<LicenseData | undefined>(undefined);

  useEffect(() => {
    if (open) {
      setLicenseData(undefined);
    }
  }, [open]);

  const creditJurisdiction = useMemo(
    () =>
      findCreditJurisdiction(
        creditJurisdictions,
        licenseData?.creditClass_SK,
        licenseData?.creditRegion_SK
      ),
    [creditJurisdictions, licenseData]
  );

  const enrichedData = useMemo(
    () => getEnrichedLicenseData(licenseData, creditJurisdiction),
    [creditJurisdiction, licenseData]
  );

  const creditTypeOptions = useMemo(
    () => getCreditTypeOptions(creditJurisdictions),
    [creditJurisdictions]
  );

  const jurisdictionOptions = useMemo(
    () => getJurisdictionsOptions(creditJurisdictions, licenseData?.creditClass_SK),
    [creditJurisdictions, licenseData]
  );

  const licenseNumberSettings = useMemo(
    () =>
      getLicenseNumberSettings(
        creditJurisdictions,
        licenseData?.creditClass_SK,
        licenseData?.creditRegion_SK
      ),
    [creditJurisdictions, licenseData]
  );

  const complianceGroupOptions = useMemo(
    () =>
      getComplianceGroupOptions(
        creditJurisdictions,
        licenseData?.creditClass_SK,
        licenseData?.creditRegion_SK
      ),
    [creditJurisdictions, licenseData]
  );

  const handleOnSave = useCallback(() => {
    onSave(enrichedData);
  }, [enrichedData, onSave]);

  return (
    <AddLicenseModal
      open={open}
      setOpen={setOpen}
      data={enrichedData}
      onDataChange={setLicenseData}
      creditTypeOptions={creditTypeOptions}
      jurisdictionOptions={jurisdictionOptions}
      complianceGroupOptions={complianceGroupOptions}
      onSave={handleOnSave}
      licenseNumberSettings={licenseNumberSettings}
      triggerButton={triggerButton}
      isLoggedOut={isLoggedOut}
      handleLoggedOutSave={handleLoggedOutSave}
    />
  );
};

export default AddNewLicenseButton;
