import { OrderLineViewModel } from '@/@types/client-api';
import { usePostCartItemMutation } from '@/redux/api/client/cart';
import { useCallback } from 'react';
import { makeToastProblemMessage } from './util';
import useToast from '../useToast';
import { AnalyticsContext } from '@/analytics/constants';
import { useLazyGetItemAnalyticsModelByItemPksQuery } from '@/redux/api/client/itemAnalyticsModel';
import { trackAddToCart } from '@/analytics/ecommerce';
import { createTransactionId } from '@/analytics/analyticsUtils';
import { evictCreditTrackerCode, setCreditTrackerCode } from '../useCreditTrackerCode';

export const useAddToCart = (context?: AnalyticsContext, creditTrackerCode?: string) => {
  const [cartMutation, { isLoading, isSuccess, isError, reset }] = usePostCartItemMutation();
  const { showSuccessMessage, showFailMessage } = useToast();
  const [fetchItems] = useLazyGetItemAnalyticsModelByItemPksQuery();

  const getAnalyticsItems = useCallback(
    async (contentCode: string, transactionId?: string) => {
      const { data } = await fetchItems({
        itemPks: [contentCode],
        populatePrice: true,
        limitPriceCalls: true
      });

      if (data && data.length > 0) {
        trackAddToCart(data[0], context, transactionId);
      }
    },
    [context, fetchItems]
  );

  const addToCart = useCallback(
    async (code?: string, title?: string, priorityCode?: string, transactionId?: string) => {
      if (!code) return;

      if (!transactionId) transactionId = createTransactionId('WEBZERO');

      getAnalyticsItems(code, transactionId);

      if (creditTrackerCode) setCreditTrackerCode(code, creditTrackerCode);

      const cartRequest: OrderLineViewModel = { code: code, quantity: 1, priorityCode };
      const cartFailedMessage = makeToastProblemMessage('cart');
      const cartSuccessMessage = 'added to cart.';
      const cartResponse = await cartMutation(cartRequest);
      if ('error' in cartResponse) {
        showFailMessage(cartFailedMessage);
      } else if ('data' in cartResponse) {
        const node = (
          <span>
            "{title?.substring(0, 30)}"... {cartSuccessMessage}{' '}
            <a className="underline" href={cartResponse.data.cartUrl ?? ''}>
              View In Cart
            </a>
          </span>
        );
        showSuccessMessage(node);
        evictCreditTrackerCode(code);
      }
    },
    [getAnalyticsItems, creditTrackerCode, cartMutation, showFailMessage, showSuccessMessage]
  );

  return { addToCart, isLoading, isSuccess, isError, reset };
};
