import { FullSizeSlideBlock } from '@/@types/content';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import Button, { ButtonProps } from '@/components/ui/Buttons/Button';
import { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import Image from '@/components/cms/Image';
import Text from '@/components/cms/Text';

const HomePageFullSizeSlideBlock: React.FC<FullSizeSlideBlock> = ({
  backGroundImage,
  backgroundOverlay,
  headlineText,
  itemTheme,
  bodyText,
  oneLinerText,
  link,
  isNewItem,
  ctaGradientColor = 'white'
}) => {
  const editMode = isEditOrPreviewMode();
  const isExtraSmall = useMediaQuery(ScreenSizeQueries.xsOnly);
  const lg = useMediaQuery(ScreenSizeQueries.lg);

  const badgeClasses = classnames({
    'text-2 w-fit rounded border px-[10px] py-1': isNewItem,
    'text-black': itemTheme === 'dark',
    'text-white': itemTheme === 'light',
    'border-black': isNewItem && itemTheme === 'dark',
    'border-white': isNewItem && itemTheme === 'light',
    'text-2': !isNewItem
  });

  const headingClasses = classnames('heading-4 lg:heading-3', {
    'text-black': itemTheme === 'dark',
    'text-white': itemTheme === 'light'
  });

  const copyClasses = classnames('text-1 line-clamp-3', {
    'text-black': itemTheme === 'dark',
    'text-white': itemTheme === 'light'
  });

  const linkContainerClasses = classnames('pb-6 pt-8');

  const CarouselItemClasses = classnames(
    'relative flex max-w-[100vw] grow lg:items-start lg:pt-[128px]'
  );

  const itemRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  useEffect(() => {
    const itemElement = itemRef.current;
    const parentElement = itemElement?.parentElement; // Accede al padre <swiper-slide>

    const observer = new MutationObserver(() => {
      if (parentElement?.classList.contains('swiper-slide-active')) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    });

    if (parentElement) {
      observer.observe(parentElement, {
        attributes: true, // Observar cambios en atributos
        attributeFilter: ['class'] // Solo observar cambios en clases
      });
    }

    return () => observer.disconnect(); // Limpiar el observer al desmontar
  }, []);

  const overlayImage = (value: string) => {
    if (value) {
      switch (value) {
        case 'dark':
          return 'absolute left-0 top-0 size-full';
        case 'gradient':
          return 'absolute left-0 top-0 size-full bg-gradient opacity-85';
        default:
          return;
      }
    }
  };

  const tabIndex = isActive ? 2 : -1;

  return (
    <div className={CarouselItemClasses} ref={itemRef}  data-component={"HomePageFullSizeSlideBlock"}>
      <div className="absolute left-0 top-0 z-0 size-full">
        {(backGroundImage || editMode) && (
          <Image
            propertyName="BackGroundImage"
            src={backGroundImage?.url}
            alt={backGroundImage?.alt as string}
            className="size-full object-cover"
            responsive={{
              aspectRatio: lg ? 3 : 1.2,
              skipWrapper: true,
              imageSizes: {
                xs: '400px',
                sm: '270px',
                md: '1201px',
                lg: '1440px',
                xl: '1680px',
                '2xl': '3840px'
              }
            }}
          />
        )}
        {(backgroundOverlay || editMode) && (
          <div className={overlayImage(backgroundOverlay || '')}></div>
        )}
      </div>
      <div className="container flex flex-col justify-between">
        <div className="flex flex-col gap-4 pt-[184px] sm:max-w-[75%] lg:max-w-[70%] lg:pt-0">
          {isNewItem ? (
            <Text element="h2" propertyName="Heading" className={badgeClasses}>
              New
            </Text>
          ) : (
            (oneLinerText || editMode) && (
              <Text propertyName="OnLinerText" className={badgeClasses}>
                {oneLinerText}
              </Text>
            )
          )}
          {(headlineText || editMode) && (
            <Text element="h2" propertyName="HeadlineText" className={headingClasses}>
              {headlineText}
            </Text>
          )}
          {(bodyText || editMode) && (
            <Text element="p" propertyName="BodyText" className={copyClasses}>
              {bodyText}
            </Text>
          )}
        </div>

        {link && (
          <div className={linkContainerClasses}>
            {(link || editMode) && (
              <Button
                propertyName="Link"
                tabIndex={tabIndex}
                color={ctaGradientColor as ButtonProps['color']}
                href={link?.href}
                target={link?.target}
                label={link?.text}
                aria-label={link?.text}
                size={isExtraSmall ? 'small' : 'large'}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePageFullSizeSlideBlock;
