import { useMemo } from 'react';
import { CreditJurisdiction, UserLicense } from '@/@types/client-api';
import ModalContent from '@/components/ui/Modal/ModalContent';
import CreditLicenseCard, {
  CreditLicenseCardProps
} from '@/components/ui/PDP/ProgramCredit/CreditLicenseCard';
import { CreditLicenseCardLabels, ProgramCreditLabels } from '../PDP/labels';
import Shimmer from '../Shimmer';
import { LicenseData } from '../SearchModals/utils/licenses';

type ViewCreditsForYourLicensesProps = {
  credits: CreditLicenseCardProps[];
  licenses: UserLicense[];
  onViewAllJurisdictions: () => void;
  isLoading?: boolean;
  creditJurisdictions?: CreditJurisdiction[];
  allowEdit?: boolean;
  handleOnSaveEditLicense?: (licenseData: LicenseData) => void;
  handleOnDeleteLicense?: (licenseData?: LicenseData) => void;
};

const ViewCreditsForYourLicenses: React.FC<ViewCreditsForYourLicensesProps> = ({
  credits,
  licenses,
  onViewAllJurisdictions,
  isLoading,
  creditJurisdictions,
  allowEdit = true,
  handleOnDeleteLicense,
  handleOnSaveEditLicense
}) => {
  const validCreditsForUser = useMemo(() => {
    return credits.filter(
      credit =>
        credit.regionSk !== undefined &&
        licenses.some(license => license.creditRegion_SK === credit.regionSk)
    );
  }, [credits, licenses]);

  return (
    <ModalContent title={ProgramCreditLabels.viewAllLicensesModalHeader}>
      <>
        <div className="flex flex-col gap-4">
          {isLoading ? (
            <Shimmer className="h-[184px] w-full rounded " />
          ) : (
            validCreditsForUser?.map(credit => (
              <CreditLicenseCard
                key={credit.regionSk}
                {...credit}
                creditJurisdictions={creditJurisdictions}
                labels={CreditLicenseCardLabels}
                license={licenses?.find(license => license.creditRegion_SK === credit.regionSk)}
                allowEdit={allowEdit}
                handleOnDeleteLicense={handleOnDeleteLicense}
                handleOnSaveEditLicense={handleOnSaveEditLicense}
              />
            ))
          )}
        </div>
        <div className="mt-8">
          {isLoading ? (
            <Shimmer className="h-4 w-28" />
          ) : (
            <button className="text-2 underline hover:text-red" onClick={onViewAllJurisdictions}>
              {ProgramCreditLabels.viewAllJurisdictionsButton}
            </button>
          )}
        </div>
      </>
    </ModalContent>
  );
};

export default ViewCreditsForYourLicenses;
