import { useMemo } from 'react';
import { ISearchResultItem } from '@/@types/client-api';
import Divider from '@/components/ui/Divider';
import CardBuilder from '@/components/ui/Cards/CardBuilder';

interface SidePanelResultCollectionProps {
  items: ISearchResultItem[];
}

const SidePanelResultCollection: React.FC<SidePanelResultCollectionProps> = ({ items }) => {
  const resultItems = useMemo(() => {
    return items.map(a => {
      return {
        ...a,
        imageUrl:
          a.imageUrl ??
          `${window.env?.CONTENT_DELIVERY_API}/api/client/product/${a.variationPk}/image`
      };
    });
  }, [items]);
  return (
    !!resultItems?.length &&
    resultItems.map((res, i) => {
      return (
        <div key={`search-result-${i}`}>
          <CardBuilder
            cardProps={res as ISearchResultItem}
            key={`result-${i}`}
            useSidePanelCard={true}
            itemAnalyticsModel={res?.itemAnalyticsModel}
          />
          {i < resultItems.length - 1 && (
            <Divider
              color="silver"
              orientation="horizontal"
              className="my-5"
              key={`result-divider-${i}`}
            />
          )}
        </div>
      );
    })
  );
};

export default SidePanelResultCollection;
