import WeekDayHeader from './WeekDayHeader';
import CalendarMonth from './CalendarMonth';
import CalendarHeader from './CalendarHeader';
import { useState, CSSProperties, forwardRef } from 'react';

type SingleMonthCalendarProps = {
  initialMonth: Date;
  selectedDate?: Date;
  numOfMonths?: number;
  onSelect?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  style?: CSSProperties;
};

const SingleMonthCalendar = forwardRef<HTMLDivElement, SingleMonthCalendarProps>(
  ({ style, initialMonth, selectedDate, onSelect, minDate, maxDate }, ref): JSX.Element => {
    const [currentDate, setCurrentDate] = useState<Date>(initialMonth);

    return (
      <div
        ref={ref}
        style={style}
        className="fixed z-50 w-[368px] overflow-hidden rounded-xl border border-gray bg-white p-6"
        role="dialog"
      >
        <CalendarHeader
          currentDate={currentDate}
          onDateChanged={setCurrentDate}
          minDate={minDate}
          maxDate={maxDate}
        />
        <WeekDayHeader className="mb-4" />
        <CalendarMonth
          currentDate={currentDate}
          selectedDate={selectedDate}
          onSelect={onSelect}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    );
  }
);

export default SingleMonthCalendar;
