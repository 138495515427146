import IconButton from '../IconButton';
import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

const SearchResultsBackToTopButton = (): JSX.Element | null => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 0);
    };

    window.addEventListener('scroll', throttle(handleScroll, 500));

    return () => {
      window.removeEventListener('scroll', throttle(handleScroll, 500));
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <IconButton
      label="Back to top"
      name="chevron-up"
      onClick={handleScrollToTop}
      className="fixed bottom-5 right-5 z-10 flex size-10 items-center justify-center rounded-full bg-black text-white opacity-60 transition-opacity hover:opacity-40 max-lg:hidden"
    />
  );
};

export default SearchResultsBackToTopButton;
