import { isSameDay, isSameMonth } from 'date-fns';
import CalendarDay from './CalendarDay';
import { isDayBetweenCalendarRange } from './CalendarRange';
import classnames from 'classnames';

type CalendarWeekProps = {
  dates: Date[];
  selectedDate?: Date;
  onSelect?: (date: Date) => void;
  currentDate: Date;
  displayOnlyDaysOfCurrentMonth?: boolean;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
};

const CalendarWeek = ({
  dates,
  selectedDate,
  onSelect,
  currentDate,
  displayOnlyDaysOfCurrentMonth = true,
  minDate,
  maxDate,
  className
}: CalendarWeekProps): JSX.Element => {
  return (
    <div className={classnames('flex justify-between', className)}>
      {dates.map(date => {
        return (
          <CalendarDay
            key={date.toISOString()}
            date={date}
            selected={selectedDate ? isSameDay(selectedDate, date) : false}
            onSelect={onSelect}
            disabled={!isDayBetweenCalendarRange(date, minDate, maxDate)}
            visible={!displayOnlyDaysOfCurrentMonth || isSameMonth(currentDate, date)}
          />
        );
      })}
    </div>
  );
};

export default CalendarWeek;
