import Eyebrow from '@/components/ui/Eyebrow/Eyebrow';
import { AuthorProps } from './AuthorsList';
import Text from '@/components/cms/Text';
import PublicationActionBar, { ActionItem } from './PublicationActionBar';
import { PublicationVariation } from '@/hooks/PDP/usePublicationCatalogRelations';

import JumpLinksPublication from './JumpLinksPublication';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useInViewport from '@/hooks/useInViewport';
import { RootState } from '@/redux/store';

interface PublicationHeaderProps {
  title: string;
  formatEyebrow?: string;
  authors?: AuthorProps[];
  date: string;
  actions: ActionItem[];
  className?: string;
  loading?: boolean;
  variant?: PublicationVariation;
  hasTestimonials?: boolean;
}

const PublicationHeader: React.FC<PublicationHeaderProps> = ({
  title,
  formatEyebrow,
  authors,
  className,
  date,
  actions,
  variant,
  loading,
  hasTestimonials
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const stickyHeader = useSelector((state: RootState) => state.page.stickyHeader);
  const [titleRef, isInView] = useInViewport<HTMLDivElement>({
    offset: stickyHeader.isSticky ? stickyHeader.headerHeight * -1 : 0
  });

  useEffect(() => {
    if (loading) {
      setIsLoaded(false);
    } else {
      requestAnimationFrame(() => {
        setIsLoaded(true);
      });
    }
  }, [loading]);

  return (
    <section className={className}>
      {variant && (
        <JumpLinksPublication
          show={!isInView && isLoaded}
          variant={variant}
          hasTestimonials={hasTestimonials!}
        />
      )}
      <div className="container flex flex-col gap-6 pt-10 lg:gap-7 lg:pt-16">
        {/* <div className="container pb-8 pt-10 lg:pb-6 lg:pt-16"> */}
        {!!formatEyebrow && (
          <Eyebrow sizeFormat="pdp" label={formatEyebrow} icon="book" className="mb-6 lg:mb-7" />
        )}
        <div ref={titleRef}>
          <Text propertyName="DisplayName" element="h1" className="heading-2">
            {title}
          </Text>
        </div>
        <PublicationActionBar authors={authors} date={date} actions={actions} />
      </div>
    </section>
  );
};

export default PublicationHeader;
