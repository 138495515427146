import { useGetProductLinkQuery } from '@/redux/api/client/product';
import ErrorPage from '@/routes/ErrorPage';
import PageLoading from '@/routes/PageLoading';
import { Navigate, useParams } from 'react-router-dom';

const ProductLinkResolver = () => {
  const code = useParams()['code'];
  const { data: productLink, isLoading } = useGetProductLinkQuery(code!);
  const url = productLink ? new URL(productLink) : undefined;
  if (isLoading) return <PageLoading />;
  if (url?.pathname) return <Navigate to={url?.pathname} replace />;
  return <ErrorPage />;
};

export default ProductLinkResolver;
