import { useEffect, useRef, useState } from 'react';
import { SwiperContainer, register } from 'swiper/element/bundle';
import { v4 as uuid4 } from 'uuid';
import type { Swiper, SwiperOptions } from 'swiper/types';
import classnames from 'classnames';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import CircleButton from '../ui/Buttons/CircleButton';
import type {
  FacultyMember,
  QuoteCarousel as QuoteCarouselProps,
  QuoteSlideBlock as QuoteSlideBlockProps
} from '@/@types/content';
import Block from '../cms/Block';
import ResponsiveImage from '../ui/ResponsiveImage';
import AvatarComponent from '../ui/Avatar';
import Button from '@/components/ui/Buttons/Button';
import { LinkItem } from '@/@types/cms';

enum Layout {
  MediaLeft = 'media-left',
  MediaRight = 'media-right'
}

interface QuoteSlideProps extends QuoteSlideBlockProps {
  cta?: LinkItem;
}

const QuoteCarousel: React.FC<QuoteCarouselProps> = ({
  contentLink,
  quotes,
  layout = Layout.MediaRight,
  imageLayout,
  heading
}) => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(quotes?.length);

  const swiperImageRef = useRef<SwiperContainer>(null);
  const swiperInfoRef = useRef<SwiperContainer>(null);
  const navPrevId = `nav-prev-${uuid4()}`;
  const navNextId = `nav-next-${uuid4()}`;

  const updateSlideValues = (swiper: Swiper) => {
    setCurrentSlide(swiper.realIndex + 1);
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  useEffect(() => {
    if (!swiperImageRef.current || !swiperInfoRef.current) return;
    register();

    const params: SwiperOptions = {
      navigation: {
        enabled: true,
        prevEl: `#${navPrevId}`,
        nextEl: `#${navNextId}`
      },
      spaceBetween: 24,
      allowTouchMove: false,
      autoHeight: true,
      on: {
        init(swiper) {
          setTotalSlides(swiper.slides.length);
          updateSlideValues(swiper);
        },
        slideChangeTransitionStart(swiper) {
          updateSlideValues(swiper);
        }
      }
    };

    Object.assign(swiperImageRef.current, params);
    swiperImageRef.current.initialize();

    Object.assign(swiperInfoRef.current, params);
    swiperInfoRef.current.initialize();
  }, [navNextId, navPrevId]);

  const smallQuoteCarousel = imageLayout === 'roundImage';

  const authorQuote = (itemName?: string, organization?: string) => (
    <div className="flex flex-col gap-1">
      {itemName && (
        <Text element="span" className="text-2-medium block">
          {itemName}
        </Text>
      )}
      {organization && (
        <Text element="span" className="text-2 block text-gray-dark">
          {organization}
        </Text>
      )}
    </div>
  );

  const quoteCarousel = (
    <div
      className={classnames('relative order-1 flex w-full flex-col gap-8', {
        'lg:w-1/5 lg:max-w-[520px] lg:flex-[0_0_calc(50%-12px)]': !smallQuoteCarousel,
        'lg:w-4/5': smallQuoteCarousel,
        'lg:order-2': layout === Layout.MediaLeft,
        'lg:order-1': layout === Layout.MediaRight
      })}
    >
      <div
        className={classnames('flex h-full flex-col justify-center', {
          'lg:py-12': !smallQuoteCarousel,
          'lg:pb-12': smallQuoteCarousel
        })}
      >
        <swiper-container class="w-full" init={false} ref={swiperInfoRef}>
          {(quotes as QuoteSlideProps[])?.map((subBlock, index) => {
            const {
              itemName: getItemName,
              organization: getOrganization,
              quoteText,
              faculty,
              cta
            } = subBlock || {};

            const itemName = (faculty as FacultyMember)?.displayName || getItemName || '';
            const organization = (faculty as FacultyMember)?.affiliation || getOrganization || '';

            return (
              <swiper-slide key={index}>
                <div className={classnames('flex flex-col gap-8', 'lg:gap-10')}>
                  {quoteText && (
                    <div className="flex flex-col gap-6">
                      <Icon name="quote" className="!h-[26px] !w-[28px]" />
                      <Text propertyName="TmpPropertyName" element="h5" className="heading-5">
                        {quoteText}”
                      </Text>
                    </div>
                  )}
                  {!smallQuoteCarousel && authorQuote(itemName, organization)}
                  {!smallQuoteCarousel && cta && (
                    <Button
                      href={cta?.href}
                      title={cta?.title}
                      target={cta?.target}
                      size="large"
                      label={cta?.text}
                      color="outline-black"
                    />
                  )}
                </div>
              </swiper-slide>
            );
          })}
        </swiper-container>
      </div>
      {!smallQuoteCarousel && quotes && quotes.length > 1 ? (
        <div
          className={classnames(
            'relative flex items-center gap-4 py-1',
            'lg:absolute lg:bottom-0 lg:left-0 lg:z-1'
          )}
        >
          <CircleButton disabled={isBeginning} id={navPrevId} icon="chevron-left" color="dark" />
          <span className="text-2-medium">
            {currentSlide} of {totalSlides}
          </span>
          <CircleButton disabled={isEnd} id={navNextId} icon="chevron-right" color="dark" />
        </div>
      ) : null}
    </div>
  );

  const mediaCarousel = (
    <div
      className={classnames('order-2 overflow-hidden rounded-lg', {
        'w-full lg:w-1/5 lg:flex-[0_0_calc(50%-12px)]': !smallQuoteCarousel,
        'lg:w-1/5': smallQuoteCarousel,
        'lg:order-1': layout === Layout.MediaLeft,
        'lg:order-2': layout === Layout.MediaRight
      })}
    >
      <swiper-container class="size-full" init={false} ref={swiperImageRef}>
        {(quotes as QuoteSlideBlockProps[])?.map((subBlock, index) => {
          const {
            itemName: getItemName,
            organization: getOrganization,
            image: getImage,
            faculty
          } = subBlock || {};

          const itemName = (faculty as FacultyMember)?.displayName || getItemName || '';
          const organization = (faculty as FacultyMember)?.affiliation || getOrganization || '';
          const image = (faculty as FacultyMember)?.image || getImage;
          const { altText, url } = image || {};

          return (
            <swiper-slide key={index} lazy={!!url}>
              {url && !smallQuoteCarousel ? (
                <div className="aspect-square size-full bg-gray-light">
                  <ResponsiveImage
                    className="size-full object-cover"
                    src={url}
                    alt={altText as string}
                    loading="lazy"
                    aspectRatio={1}
                    imageSizes={{
                      xs: '230px',
                      sm: '530px',
                      md: '800px',
                      lg: '750px'
                    }}
                  />
                </div>
              ) : (
                <div className={classnames('flex flex-col gap-4 lg:gap-6')}>
                  <AvatarComponent
                    className="size-16 lg:size-20"
                    imageUrl={url}
                    fullName={itemName}
                    imageSizes={{
                      xs: '64px',
                      lg: '80px'
                    }}
                  />
                  {authorQuote(itemName, organization)}
                </div>
              )}
            </swiper-slide>
          );
        })}
      </swiper-container>
    </div>
  );

  return (
    <Block contentLinkID={contentLink?.id} className="module-spacing">
      <div className="container"  data-component={"QuoteCarousel"}>
        {smallQuoteCarousel && (
          <div className="w-full border-t border-gray-light py-10 lg:pb-16 lg:pt-8">
            <Text element="h2" className="heading-6-medium">
              {heading}
            </Text>
          </div>
        )}
        <div
          className={classnames(
            'flex flex-col gap-12',
            'sm:gap-10',
            'lg:flex-row lg:justify-between lg:gap-12'
          )}
        >
          {layout === Layout.MediaLeft ? (
            <>
              {mediaCarousel}
              {quoteCarousel}
            </>
          ) : (
            <>
              {quoteCarousel}
              {mediaCarousel}
            </>
          )}
        </div>
        {smallQuoteCarousel && quotes && quotes.length > 1 ? (
          <div className={classnames('relative flex items-center gap-4 pt-8 lg:pt-20')}>
            <CircleButton disabled={isBeginning} id={navPrevId} icon="chevron-left" color="dark" />
            <span className="text-2-medium">
              {currentSlide} of {totalSlides}
            </span>
            <CircleButton disabled={isEnd} id={navNextId} icon="chevron-right" color="dark" />
          </div>
        ) : null}
      </div>
    </Block>
  );
};

export default QuoteCarousel;
