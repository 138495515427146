import {
  Certificate,
  CertificateResponse,
  CertificateUploadBlob,
  ICertificateViewModel,
  XhtmlString
} from '@/@types/client-api';
import {
  GetCertificateRequest,
  useCreateExternalCertificateMutation,
  useDeleteExternalCertificateMutation,
  useGetCertificatesAdminAccessQuery,
  useGetCertificatesQuery,
  useUpdateExternalCertificateMutation
} from '@/redux/api/client/userCertificates';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { FetchBaseQueryError, SkipToken, skipToken } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveClePeriod,
  selectActiveUserTrackerData,
  selectTrackerIsLoading
} from '@/redux/selectors/creditTrackerSelectors';
import useToast from '@/hooks/useToast';
import { ICertificateRequest } from '@/redux/api/client/creditTracker';
import { creditTrackerFailure } from '@/redux/slices/creditTrackerSlice';
import { userCertificateSuccess } from '@/redux/slices/userProfileSlice';
import { mapCreditTypeDescriptions } from '../../CreditTracker/util/TrackerHelpers';
import { selectCertificatesByUserAndRegion } from '@/redux/selectors/userProfileSelectors';
import useCertificateFileHook from './useCertificateFileHook';

export interface UseCertificatesHookData {
  certsLoading?: boolean;
  certsError?: FetchBaseQueryError | SerializedError | undefined;
  certsData?: CertificateResponse;
  certsRefetching?: boolean;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  createCertificate: (certificate: Certificate) => Promise<void>;
  updateCertificate: (certificate: Certificate) => Promise<void>;
  deleteCertificate: (certificate: Certificate) => Promise<void>;
  showWarning?: 'edit' | 'delete' | false;
  setShowWarning: (showWarning: 'edit' | 'delete' | false) => void;
  carryoverInformationText?: XhtmlString;
  selectedInternalCertificates?: ICertificateViewModel[];
  selectedExternalCertificates?: ICertificateViewModel[];
  selectedCertificatesCarryover: Certificate[];
  fileManagement: {
    onFileUpload: (files: File[]) => Promise<CertificateUploadBlob[] | undefined>;
    isUploadSuccessful: boolean;
    isFileUploading: boolean;
    isUploadError: boolean;
    downloadCertificateFile: (blobName: string, fileName?: string | null) => void;
    downloadBulkCertificateFiles: (
      keys?: number[],
      blobUris?: string[],
      fileName?: string | null
    ) => Promise<void>;
    isDownloadLoading: boolean;
    isDownloadSuccess: boolean;
    isDownloadError: boolean;
    deleteFile: (name: string) => Promise<boolean | undefined>;
    isDeleteFileError: boolean;
    isDeleteFileSuccessful: boolean;
    isFileDeleting: boolean;
  };
}

const useCertificates = (
  isCarryover?: boolean,
  skipLoading?: boolean,
  customerId?: string,
  ignoreCreditRequestUrls: boolean = true,
  pageSize = -1
): UseCertificatesHookData => {
  const auth = useAuth();
  const authUser = auth.user?.profile?.sub;
  const [pageNumber, setPageNumber] = useState(1);
  const [showWarning, setShowWarning] = useState<'edit' | 'delete' | false>(false);

  const selectedRegion = useSelector(selectActiveUserTrackerData);
  const selectedClePeriod = useSelector(selectActiveClePeriod());
  const trackerIsLoading = useSelector(selectTrackerIsLoading);
  const _selectedUserCertificatesByRegion = useSelector(
    selectCertificatesByUserAndRegion(
      selectedRegion?.creditRegionShortDescription ?? '',
      selectedRegion?.customerId?.toString() ?? ''
    )
  );

  const [createExternalCertificate] = useCreateExternalCertificateMutation();
  const [updateExternalCertificate] = useUpdateExternalCertificateMutation();
  const [deleteExternalCertificate] = useDeleteExternalCertificateMutation();

  const {
    downloadBulkCertificateFiles,
    isBulkDownloadError,
    isBulkDownloadLoading,
    isBulkDownloadSuccess,
    downloadCertificateFile,
    isIndivDownloadError,
    isIndivDownloadLoading,
    isIndivDownloadSuccess,
    onFileUpload,
    isUploadError,
    isUploadSuccessful,
    isFileUploading,
    deleteFile,
    isDeleteFileError,
    isDeleteFileSuccessful,
    isFileDeleting
  } = useCertificateFileHook(selectedRegion?.customerId);
  const dispatch = useDispatch();
  const { showSuccessMessage, showFailMessage } = useToast();

  const carryoverInformationText = useMemo(
    () => selectedRegion?.carryoverInformationText,
    [selectedRegion?.carryoverInformationText]
  );

  const requestModel: GetCertificateRequest | SkipToken = useMemo(() => {
    if (!!customerId || !!authUser) {
      return {
        customerId: parseInt(customerId ?? authUser ?? ''),
        ignoreCreditRequestUrls: ignoreCreditRequestUrls,
        pageSize,
        jurisdictions: selectedRegion?.creditRegionShortDescription,
        pageNumber
      } as GetCertificateRequest;
    }
    return skipToken;
  }, [
    authUser,
    customerId,
    ignoreCreditRequestUrls,
    pageNumber,
    pageSize,
    selectedRegion?.creditRegionShortDescription
  ]);

  // #region Queries

  const skipTokenForCertificates = useMemo(
    () => !requestModel || trackerIsLoading || !!skipLoading || !!customerId,
    [customerId, requestModel, skipLoading, trackerIsLoading]
  );

  const skipTokenForCertificatesAdmin = useMemo(
    () => !requestModel || trackerIsLoading || !!skipLoading || !customerId,
    [customerId, requestModel, skipLoading, trackerIsLoading]
  );

  // Get certificates for user
  const getCertificates = useGetCertificatesQuery(requestModel, {
    skip: skipTokenForCertificates
  });

  // Get certificates for user via authorized supplicant
  const getCertificatesSupplicantAccess = useGetCertificatesAdminAccessQuery(requestModel, {
    skip: skipTokenForCertificatesAdmin
  });

  const {
    isLoading: certsLoading,
    error: certsError,
    data: certsData,
    isFetching: certsRefetching
  } = !customerId ? getCertificates : getCertificatesSupplicantAccess;

  const createCertificate = async (certificate: Certificate) => {
    if (certificate && selectedRegion?.customerId && selectedRegion.creditRegion_SK) {
      const data: ICertificateRequest = {
        customerId: selectedRegion.customerId,
        region: selectedRegion.creditRegion_SK,
        certificate: certificate
      };
      try {
        await createExternalCertificate(data)
          .unwrap()
          .then(() => showSuccessMessage(`${certificateType} added`));
      } catch (error) {
        showFailMessage(`There was an error adding ${certificateType.toLowerCase()}`);
      }
    }
  };

  const updateCertificate = async (certificate: Certificate) => {
    if (certificate && selectedRegion?.customerId && selectedRegion.creditRegion_SK) {
      const data: ICertificateRequest = {
        customerId: selectedRegion.customerId,
        region: selectedRegion.creditRegion_SK,
        certificate: certificate
      };
      try {
        await updateExternalCertificate(data)
          .unwrap()
          .then(() => showSuccessMessage(`${certificateType} updated`));
      } catch (error) {
        showFailMessage(`There was an error updating ${certificateType.toLowerCase()}`);
      }
    }
  };

  const deleteCertificate = async (certificate: Certificate) => {
    if (certificate && selectedRegion?.customerId && selectedRegion.creditRegion_SK) {
      const data: ICertificateRequest = {
        customerId: selectedRegion.customerId,
        region: selectedRegion.creditRegion_SK,
        certificate: certificate
      };
      try {
        await deleteExternalCertificate(data)
          .unwrap()
          .then(() => showSuccessMessage(`${certificateType} deleted`));
      } catch (error) {
        showFailMessage(`There was an error deleting ${certificateType.toLowerCase()}`);
      }
    }
  };

  // #endregion

  // #region Memoized variables

  const isDownloadLoading = useMemo(
    () => isIndivDownloadLoading || isBulkDownloadLoading,
    [isBulkDownloadLoading, isIndivDownloadLoading]
  );
  const isDownloadSuccess = useMemo(
    () => isIndivDownloadSuccess || isBulkDownloadSuccess,
    [isBulkDownloadSuccess, isIndivDownloadSuccess]
  );
  const isDownloadError = useMemo(
    () => isIndivDownloadError || isBulkDownloadError,
    [isBulkDownloadError, isIndivDownloadError]
  );

  const allCreditTypeDescriptionsFromRules = useMemo(
    () => selectedRegion?.jurisdiction?.availableCreditTypes,
    [selectedRegion?.jurisdiction?.availableCreditTypes]
  );

  const endDate = useMemo(
    () => selectedClePeriod?.endDate ?? selectedClePeriod?.defaultEndDate ?? '',
    [selectedClePeriod?.defaultEndDate, selectedClePeriod?.endDate]
  );
  const startDate = useMemo(
    () => selectedClePeriod?.startDate ?? selectedClePeriod?.defaultStartDate ?? '',
    [selectedClePeriod?.defaultStartDate, selectedClePeriod?.startDate]
  );
  const previousStartDate = useMemo(
    () =>
      selectedRegion?.jurisdiction?.clePeriod?.previousPeriod?.startDate ??
      selectedRegion?.jurisdiction?.clePeriod?.previousPeriod?.defaultStartDate ??
      '',
    [
      selectedRegion?.jurisdiction?.clePeriod?.previousPeriod?.defaultStartDate,
      selectedRegion?.jurisdiction?.clePeriod?.previousPeriod?.startDate
    ]
  );
  const currentEndDate = useMemo(
    () =>
      selectedRegion?.jurisdiction?.clePeriod?.endDate ??
      selectedRegion?.jurisdiction?.clePeriod?.defaultEndDate ??
      '',
    [
      selectedRegion?.jurisdiction?.clePeriod?.defaultEndDate,
      selectedRegion?.jurisdiction?.clePeriod?.endDate
    ]
  );
  const certificateType = useMemo(
    () => (isCarryover ? 'Carryover credit' : 'Certificate'),
    [isCarryover]
  );

  // #endregion

  // #region Helpers
  const creditTypeMapping = useCallback(
    (a: ICertificateViewModel) => {
      const types = mapCreditTypeDescriptions(
        a.creditTypes ?? [],
        allCreditTypeDescriptionsFromRules ?? []
      );
      return { ...a, creditTypes: types };
    },
    [allCreditTypeDescriptionsFromRules]
  );

  const filterCertificatesByDateRange = (
    certificates: ICertificateViewModel[],
    startDate: string,
    endDate: string
  ) => {
    const filteredCerts = certificates?.filter(
      x =>
        x.dateCompleted &&
        !!startDate &&
        new Date(x.dateCompleted) >= new Date(startDate) &&
        !!endDate &&
        new Date(x.dateCompleted) <= new Date(endDate)
    );

    return filteredCerts?.sort(
      (a, b) =>
        new Date(a.dateCompleted ?? '').getTime() - new Date(b.dateCompleted ?? '').getTime()
    );
  };

  const selectedUserCertificatesByRegion = filterCertificatesByDateRange(
    _selectedUserCertificatesByRegion,
    startDate,
    endDate
  );

  const selectedUserCertificatesPreviousPeriod = filterCertificatesByDateRange(
    _selectedUserCertificatesByRegion,
    previousStartDate,
    currentEndDate
  );

  const selectedInternalCertificates = useMemo(() => {
    return selectedUserCertificatesByRegion
      ?.filter(a => !a.isExternal)
      ?.map(a => creditTypeMapping(a));
  }, [creditTypeMapping, selectedUserCertificatesByRegion]);

  const selectedExternalCertificates = useMemo(() => {
    return selectedUserCertificatesByRegion
      ?.filter(a => a.isExternal)
      ?.map(a => creditTypeMapping(a));
  }, [creditTypeMapping, selectedUserCertificatesByRegion]);

  const selectedCertificatesCarryover = useMemo(() => {
    return selectedUserCertificatesPreviousPeriod
      ?.filter(a => !!a.isExternal)
      ?.map(a => creditTypeMapping(a));
  }, [creditTypeMapping, selectedUserCertificatesPreviousPeriod]);

  // #endregion

  // #region Effect Hooks

  useEffect(() => {
    if (!isDownloadLoading) {
      if (isDownloadSuccess || isDownloadError) {
        setShowWarning(false);
        return isDownloadSuccess
          ? showSuccessMessage(`${certificateType}${isBulkDownloadSuccess ? 's' : ''} downloaded`)
          : showFailMessage(`There was an error downloading ${certificateType.toLowerCase()}`);
      }
    }
  }, [
    certificateType,
    isBulkDownloadSuccess,
    isDownloadError,
    isDownloadLoading,
    isDownloadSuccess,
    showFailMessage,
    showSuccessMessage
  ]);

  useEffect(() => {
    if (!isFileDeleting) {
      setShowWarning(false);
      if (isDeleteFileSuccessful) {
        return isDeleteFileSuccessful
          ? showSuccessMessage(`File deleted`)
          : showFailMessage(`There was an error deleting your file.`);
      }
    }
  }, [isDeleteFileSuccessful, isFileDeleting, showFailMessage, showSuccessMessage]);

  useEffect(() => {
    if (!isFileUploading) {
      setShowWarning(false);
      if (isUploadSuccessful) {
        return isUploadSuccessful
          ? showSuccessMessage(`File uploaded`)
          : showFailMessage(`There was an error uploading your file.`);
      }
    }
  }, [isFileUploading, isUploadSuccessful, showFailMessage, showSuccessMessage]);

  useEffect(() => {
    certsError && dispatch(creditTrackerFailure(certsError));
  }, [certsError, dispatch]);

  useEffect(() => {
    if (certsData) {
      dispatch(userCertificateSuccess(certsData));
    }
  }, [dispatch, certsData, certsRefetching]);

  // #endregion

  return {
    createCertificate,
    updateCertificate,
    deleteCertificate,
    showWarning,
    setShowWarning,
    carryoverInformationText,
    selectedInternalCertificates,
    selectedExternalCertificates,
    certsLoading,
    certsError,
    certsData,
    certsRefetching,
    pageNumber,
    setPageNumber,
    selectedCertificatesCarryover,
    fileManagement: {
      onFileUpload,
      isUploadSuccessful,
      isFileUploading,
      isUploadError,
      downloadBulkCertificateFiles,
      downloadCertificateFile,
      isDownloadLoading,
      isDownloadSuccess,
      isDownloadError,
      deleteFile,
      isDeleteFileError,
      isDeleteFileSuccessful,
      isFileDeleting
    }
  };
};

export default useCertificates;
