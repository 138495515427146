import { ClientAPI } from '@/redux/api';
import type { CreditInfoResponseApiModel, CreditJurisdiction } from '@/@types/client-api';

export interface IRelevantProgramCreditProps {
  codes: string[];
  regions?: string[]; // Uses Region.Id, ex: "CLE-NY". If not provided, uses profile licenses
}
const cleEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getCreditJurisdictions: builder.query<CreditJurisdiction[], void>({
      query: () => ({
        url: '/cle/credit-jurisdictions',
        method: 'GET'
      })
    }),
    getProgramCredits: builder.query<CreditInfoResponseApiModel, string>({
      query: code => ({
        url: `/cle/credit-info/${code}/all`,
        method: 'GET'
      })
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getRelevantProgramCredits: builder.query<any, IRelevantProgramCreditProps>({
      query: ({ codes, regions }) => ({
        url: `/cle/relevant-credits?codes=${codes.join(',')}${regions?.length ? `&creditPreferences=${regions.join(',')}` : ''}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useGetCreditJurisdictionsQuery,
  useGetProgramCreditsQuery,
  useLazyGetProgramCreditsQuery,
  useGetRelevantProgramCreditsQuery
} = cleEndpoints;

export default cleEndpoints;
