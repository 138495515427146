import { IContent } from '@/@types/cms';
import type { PracticeAreaTickerBlock as PracticeAreaTickerBlockType } from '@/@types/content';
import Block from '@/components/cms/Block';
import { Fragment, useState } from 'react';
import Marquee from 'react-fast-marquee';
import classnames from 'classnames';
import RoundedButton from '@/components/ui/Buttons/RoundedButton';
import ResponsiveImage from '@/components/ui/ResponsiveImage';

type IContentWithBackgroundImage = IContent & {
  backgroundImage?: string;
};

const PracticeAreaTickerBlock: React.FC<PracticeAreaTickerBlockType> = ({
  contentLink,
  startLabel,
  pauseLabel,
  taxonomyItems = []
}) => {
  const [isPaused, setIsPaused] = useState(false);

  const maxNumberOfRows = 3;
  const numberOfArrays = Math.min(maxNumberOfRows, taxonomyItems.length);
  const marqueeItems: IContent[][] = taxonomyItems.reduce<IContent[][]>(
    (acc, item, index) => {
      acc[index % numberOfArrays].push(item);
      return acc;
    },
    Array.from({ length: numberOfArrays }, () => [])
  );

  if (!taxonomyItems.length) {
    return null;
  }

  return (
    <Block contentLinkID={contentLink?.id} className="container module-spacing">
      <div className="flex flex-col gap-8">
        {marqueeItems.map((items, indexMarquee) => (
          <Fragment key={indexMarquee}>
            <Marquee
              direction={indexMarquee % 2 === 0 ? 'left' : 'right'}
              autoFill={true}
              speed={10}
              play={!isPaused}
            >
              {items.map((item, indexItem) => (
                <div key={indexItem} className={classnames('px-5', 'sm:px-[14px]')}>
                  <a
                    className={classnames('flex flex-row items-center gap-3', 'sm:gap-4')}
                    href={item.url}
                    aria-label={item?.name}
                  >
                    <div
                      className={classnames(
                        'size-8 overflow-hidden rounded-sm bg-gray-light',
                        'sm:size-12 sm:rounded-[4px]'
                      )}
                    >
                      <ResponsiveImage
                        src={(item as IContentWithBackgroundImage)?.backgroundImage}
                        aspectRatio={1}
                        imageSizes={{
                          xs: '48px'
                        }}
                      />
                    </div>
                    <span className="heading-3-regular text-gray-darker">{item?.name}</span>
                  </a>
                </div>
              ))}
            </Marquee>
          </Fragment>
        ))}

        <RoundedButton
          icon={isPaused ? 'play' : 'pause'}
          aria-label={isPaused ? startLabel : pauseLabel}
          onClick={() => setIsPaused(!isPaused)}
          color="gray"
        />
      </div>
    </Block>
  );
};

export default PracticeAreaTickerBlock;
