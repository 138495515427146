import { CollectionProduct } from '@/@types/content';
import Block from '@/components/cms/Block';
import Text from '@/components/cms/Text';
import classnames from 'classnames';
import Link from '@/components/cms/Link';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { appendWebpFormat } from '@/lib/helpers/images';

const FeaturedCardLayout: React.FC<{ collection: CollectionProduct }> = ({ collection }) => {
  const md = useMediaQuery(ScreenSizeQueries.md);

  return (
    <Block
      key={collection.code}
      element={Link}
      href={collection.contentLink?.url}
      title={collection.displayName}
      contentLinkID={collection.contentLink?.id}
      style={{
        backgroundImage: `url(${appendWebpFormat(collection.backGroundImage?.url)}&height=700&width=700)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
      className={classnames(
        'flex h-[390px] flex-col justify-between rounded-lg lg:h-[615px]',
        'bg-gray-lightest p-4 text-white lg:p-10'
      )}
    >
      <div className="flex w-full flex-col gap-y-2 md:gap-y-3">
        <Text className={classnames('label')}>Collection</Text>
        <Text
          element={md ? 'h5' : 'h6'}
          className={classnames({
            'heading-5': md,
            'heading-6-medium': !md
          })}
        >
          {collection.displayName}
        </Text>
      </div>
      <div
        className={classnames('flex flex-col gap-y-1', {
          'text-2-medium': md,
          label: !md
        })}
      >
        {collection.hasPrograms && <Text>Programs</Text>}
        {collection.hasPodcasts && <Text>Podcasts</Text>}
        {collection.hasPublications && <Text>Publications</Text>}
      </div>
    </Block>
  );
};

export default FeaturedCardLayout;
