import { useGetContentByFriendlyUrlQuery } from '@/redux/api/contentDeliveryAPI';
import { IContentExtended } from '@/lib/helpers/expandChildren';

const Domain = '.pli.edu';

type UseGetEcommPanelContentReturn = {
  content?: IContentExtended;
  isLoadingContent: boolean;
};

const useGetEcommPanelContent = (url?: string): UseGetEcommPanelContentReturn => {
  const friendlyUrl = url?.includes(Domain) ? url.split(Domain)[1] : url;

  const { data: content, isFetching: isLoadingContent } = useGetContentByFriendlyUrlQuery(
    { friendlyUrl: friendlyUrl ?? '', userId: 'ANON' },
    { skip: !friendlyUrl }
  );

  return { content, isLoadingContent };
};

export default useGetEcommPanelContent;
