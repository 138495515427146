import { Component } from '@/lib/ComponentFactory';
import { FC, useEffect, useMemo, useState } from 'react';
import { addValuesToCookie, getCookieValues } from '@/utils/cookie-list-values';
import { LightboxModal as LightboxModalProps } from '@/@types/content';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { translateToTailwinds } from '../ui/CreditTracker/util/TrackerHelpers';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import * as Dialog from '@radix-ui/react-dialog';
import Button from '@/components/ui/Buttons/Button';
import classnames from 'classnames';
import Icon from '../ui/Icon';
import Modal from '../ui/Modal';
import { useWalkthrough } from '@/components/blocks/WalkthroughExperienceModalBlock.tsx';
import { ContentReference } from '@/@types/cms';
import Image from '../cms/Image';
import Link from '../ui/Link';

const LIGHTBOX_MODAL_COOKIES = 'lightboxModalCookiesPermanentDismissed';
const LIGHTBOX_MODAL_SESSION = 'lightboxModalSessionDismissed';

type ExtendedLightboxModalProps = LightboxModalProps & {
  mobileImage?: ContentReference;
};
const LightboxModal: FC<ExtendedLightboxModalProps> = ({
  backgroundTheme,
  contentLink,
  copy,
  headingLine1,
  headingLine1Theme = 'white',
  headingLine2,
  headingLine2Theme = 'white',
  image,
  lightboxPlacement,
  mobileImage,
  noThankYouButtonLabel,
  optionalLink,
  presentationOptions,
  showButtonNoThankYou,
  showButtonShowMe,
  showMeLink
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const isXSmall = useMediaQuery(ScreenSizeQueries.xsOnly);

  const { dispatch } = useWalkthrough()!;

  const isModalCentred = lightboxPlacement === 'center';
  const isLightTheme = backgroundTheme === 'light';

  useEffect(() => {
    if (presentationOptions?.trim().toLocaleLowerCase() === 'always') {
      setOpen(true);
    } else {
      const isModalDismissed = getCookieValues(LIGHTBOX_MODAL_COOKIES).includes(
        String(contentLink?.id)
      );

      const isSessionLightboxDismissed = JSON.parse(
        sessionStorage.getItem(LIGHTBOX_MODAL_SESSION) ?? '[]'
      )?.includes(String(contentLink?.id));

      setOpen(!isModalDismissed && !isSessionLightboxDismissed);
    }
  }, [contentLink?.id, presentationOptions]);

  const domain = useMemo(() => {
    if (image) {
      const url = new URL(image?.url ?? '');
      return url.origin;
    }
    return '';
  }, [image]);

  const mobileStylesWithoutOverlay = classnames(
    'fixed inset-0 z-modal flex flex-col overflow-y-auto',
    {
      'bg-white': isModalCentred,
      'w-[calc(100%-48px)] place-self-center': !isModalCentred
    }
  );
  const stylesWithOverlay = classnames('z-modal flex flex-col sm:fixed sm:w-[628px]', {
    'overflow-y-auto bg-white sm:left-1/2 sm:top-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2':
      isModalCentred,
    'sm:h-[min(800px,85vh)] sm:max-h-[min(800px,85vh)]': image && isModalCentred,
    'sm:bottom-0 sm:right-[50px] sm:-translate-x-0 sm:-translate-y-12': !isModalCentred,
    'rounded-lg': useMediaQuery('(min-height: 885px)')
  });
  const centeredBackgroundClasses = classnames(
    'flex h-auto items-center justify-center bg-charcoal px-28 py-14',
    'sm:bg-gray-darker sm:px-[99px] sm:py-[58px]',
    { 'rounded-t-lg': !isModalCentred && image }
  );
  const contentContainerClasses = classnames({
    'flex-1 p-6 sm:p-16 sm:pb-8 sm:pt-[55px]': isModalCentred,
    'p-12': !isModalCentred,
    'rounded-lg': !isModalCentred && !image,
    'rounded-b-lg': !isModalCentred && image,
    'bg-white': isLightTheme,
    'bg-black': !isLightTheme
  });
  const dismissButtonContainerClasses = classnames('mb-[60px] flex w-full justify-end', {
    'absolute right-4 top-4': image
  });
  const dismissButtonClasses = classnames('hover:cursor-pointer hover:text-red');
  const linkClasses = classnames('mt-4', { 'text-white hover:text-red': !isLightTheme });

  const whiteGradientClasses = classnames(
    'pointer-events-none absolute -top-8 h-8 w-full bg-gradient-to-t from-white/80 to-transparent'
  );

  const closeButtonColorClasses = useMemo(() => {
    if (image) {
      return 'text-white';
    }
    return isLightTheme ? 'text-black' : 'text-white';
  }, [image, isLightTheme]);

  const headingColor = useMemo(() => {
    const getColor = (color?: string) => {
      return classnames({
        'text-white': color === 'white',
        'text-black': color === 'black',
        'text-red': color === 'red',
        'text-gold': color === 'gold',
        'text-green-100': color === 'green-100',
        'text-green-200': color === 'green-200',
        'text-green-300': color === 'green-300',
        'text-teal-100': color === 'teal-100',
        'text-teal-200': color === 'teal-200',
        'text-teal-300': color === 'teal-300',
        'text-blue-100': color === 'blue-100',
        'text-blue-200': color === 'blue-200',
        'text-blue-300': color === 'blue-300',
        'text-indigo-100': color === 'indigo-100',
        'text-indigo-200': color === 'indigo-200',
        'text-indigo-300': color === 'indigo-300',
        'text-purple-100': color === 'purple-100',
        'text-purple-200': color === 'purple-200',
        'text-purple-300': color === 'purple-300',
        'text-magenta-100': color === 'magenta-100',
        'text-magenta-200': color === 'magenta-200',
        'text-magenta-300': color === 'magenta-300'
      });
    };
    const heading1 = getColor(headingLine1Theme);
    const heading2 = getColor(headingLine2Theme);

    return { heading1, heading2 };
  }, [headingLine1Theme, headingLine2Theme]);

  const handleDismiss = () => {
    if (presentationOptions?.trim().toLocaleLowerCase() === 'once-per-session') {
      const isSessionLightboxDismissed = JSON.parse(
        sessionStorage.getItem(LIGHTBOX_MODAL_SESSION) ?? '[]'
      );
      sessionStorage.setItem(
        LIGHTBOX_MODAL_SESSION,
        JSON.stringify([String(contentLink?.id), ...isSessionLightboxDismissed])
      );
    }
    if (presentationOptions?.trim().toLocaleLowerCase() === 'once-per-browser') {
      addValuesToCookie(LIGHTBOX_MODAL_COOKIES, [String(contentLink?.id)], 10, 365);
    }
    setOpen(false);
  };

  const showMeMore = () => {
    dispatch({ type: 'START_WALKTHROUGH' });
    handleDismiss();
  };

  const showOptionalLink = () => {
    window.open(optionalLink?.href, optionalLink?.target ?? '_blank');
    handleDismiss();
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/30" />
        <Dialog.Content>
          <div className={isXSmall ? mobileStylesWithoutOverlay : stylesWithOverlay}>
            {image && (
              <div className={centeredBackgroundClasses}>
                {isXSmall && mobileImage ? (
                  <Image
                    src={`${domain}${mobileImage.url}`}
                    alt={image?.altText as string}
                    propertyName="Image"
                    responsive={{
                      aspectRatio: 151 / 310,
                      imageSizes: {
                        xs: '400px'
                      }
                    }}
                  />
                ) : (
                  <Component {...image} />
                )}
              </div>
            )}
            <div className={contentContainerClasses}>
              <div className={dismissButtonContainerClasses}>
                <div onClick={handleDismiss} className={dismissButtonClasses}>
                  <Icon name="close" size="large" className={closeButtonColorClasses} />
                </div>
              </div>
              <div className="flex h-full flex-col justify-between">
                <div>
                  {headingLine1 && (
                    <h2
                      className={classnames('heading-4 mb-3', headingColor.heading1, {
                        '!mb-0': headingLine2
                      })}
                      dangerouslySetInnerHTML={{ __html: translateToTailwinds(headingLine1) }}
                    />
                  )}
                  {headingLine2 && (
                    <h2
                      className={classnames('heading-4 mb-3', headingColor.heading2)}
                      dangerouslySetInnerHTML={{ __html: translateToTailwinds(headingLine2) }}
                    />
                  )}
                  {copy && (
                    <span
                      className={classnames('text-1', {
                        'text-gray-dark': isLightTheme,
                        'text-white': !isLightTheme
                      })}
                      dangerouslySetInnerHTML={{ __html: translateToTailwinds(copy) }}
                    />
                  )}
                </div>

                {(showButtonShowMe && showMeLink) || showButtonNoThankYou || optionalLink ? (
                  <>
                    {showButtonShowMe && (
                      <div
                        className={classnames('relative mt-4 flex flex-col', {
                          'w-full': isModalCentred
                        })}
                      >
                        {showButtonShowMe && !optionalLink && (
                          <>
                            <div className={whiteGradientClasses} />
                            <Button
                              label={showMeLink?.text}
                              color={isLightTheme ? 'black' : 'white'}
                              size="large"
                              className="w-full"
                              aria-label={showMeLink?.text}
                              onClick={showMeMore}
                            />
                          </>
                        )}

                        <div className={classnames('flex flex-col items-center')}>
                          {optionalLink && (
                            <>
                              <div className={whiteGradientClasses} />
                              <Button
                                label={optionalLink.text}
                                color={isLightTheme ? 'black' : 'white'}
                                size="large"
                                className="w-full outline-transparent"
                                aria-label={optionalLink.text}
                                onClick={showOptionalLink}
                              />
                            </>
                          )}
                          {showButtonNoThankYou && (
                            <Link
                              className={linkClasses}
                              as="button"
                              text={noThankYouButtonLabel}
                              variant="text-link-1"
                              onClick={handleDismiss}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Modal>
  );
};

export default LightboxModal;
