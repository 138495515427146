import { forwardRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import Icon from '../../../Icon';
import LoadingDots from '@/components/ui/Buttons/LoadingDots';

interface PanelButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  strikePrice?: string;
  price?: string;
  copy?: string;
  isLive?: boolean;
  sticky?: boolean;
  ariaLabel?: string;
  isSuccess?: boolean;
  iconName?: string;
  isLoading?: boolean;
  isError?: boolean;
  purchased?: boolean;
}

const PanelButton = forwardRef<HTMLButtonElement, PanelButtonProps>(
  (
    {
      label,
      strikePrice,
      price,
      isLive,
      className,
      isSuccess,
      ariaLabel,
      iconName,
      isLoading,
      isError,
      purchased,
      ...props
    },
    ref
  ) => {
    const [showSuccess, setShowSuccess] = useState<boolean>(isSuccess ?? false);
    const [showLoading, setShowLoading] = useState<boolean>(isLoading ?? false);

    const buttonClasses = classnames(
      'relative h-[56px] w-full overflow-hidden rounded',
      {
        'bg-red text-white hover:bg-red-dark active:bg-red-dark active:text-opacity-70': !isLive,
        'before:absolute before:inset-0 before:size-full before:bg-gradient before:transition hover:bg-black before:hover:opacity-80':
          isLive
      },
      className
    );

    const buttonInnerClasses = classnames(
      'relative z-1 flex items-center rounded px-6 text-white transition lg:px-8',
      {
        'justify-between': (price || iconName) && !showSuccess && !showLoading,
        'justify-center': !(price && iconName) || showSuccess || showLoading || purchased
      },
      className
    );

    useEffect(() => {
      if (isSuccess) {
        setShowSuccess(isSuccess);
        setShowLoading(false);
        setTimeout(() => {
          setShowSuccess(false);
        }, 1000);
      }

      if (isLoading) {
        setShowLoading(isLoading);
      }

      if (isError) {
        setShowLoading(false);
      }
    }, [isSuccess, isLoading, isError]);

    return (
      <button
        onClick={showSuccess ? () => {} : props.onClick}
        {...props}
        className={buttonClasses}
        disabled={showSuccess || showLoading}
        aria-label={showLoading ? 'Loading' : ariaLabel}
        ref={ref}
        data-component={"PanelButton"}
      >
        <div className={buttonInnerClasses}>
          {!showSuccess && !showLoading && <span className="text-1 font-bold">{label}</span>}
          {(strikePrice || price) && !showSuccess && !showLoading && !purchased && (
            <div className="label">
              {strikePrice && <span className="mr-2 line-through">{strikePrice}</span>}
              {price && <span>{price}</span>}
            </div>
          )}
          {(showSuccess || iconName) && !isLoading && (
            <Icon name={showSuccess ? 'check' : iconName ?? ''} size="medium" />
          )}
          {showLoading && !showSuccess && <LoadingDots color="white" />}
        </div>
      </button>
    );
  }
);

export default PanelButton;
