import { DismissableBannerMessageBlock as DismissableBannerBlockProps } from '@/@types/content';
import RichText from '../cms/RichText';
import Icon from '../ui/Icon';
import { memo, useMemo, useState } from 'react';
import classnames from 'classnames';
import Link from '@/components/ui/Link';

export type DismissableBannerProps = Pick<DismissableBannerBlockProps, 'message' | 'icon'> & {
  color?: string;
  size?: 'small' | 'large';
  textColor?: string;
  canDismiss?: boolean;
  onUndo?: () => void;
};

const DismissableBannerBlock: React.FC<DismissableBannerProps> = memo(
  ({
    message,
    icon,
    color,
    onUndo,
    canDismiss = true,
    textColor = 'text-white',
    size = 'small'
  }) => {
    const [visible, setVisible] = useState(true);

    const containerClasses = useMemo(
      () =>
        classnames(
          'mb-10 flex justify-between scroll-smooth rounded-lg px-5 py-4 text-white sm:mb-6',
          color
        ),
      [color]
    );

    const inlineTextClasses = useMemo(
      () =>
        classnames('text-2 sm:inline-flex', textColor, {
          'flex-col': size == 'large'
        }),
      [size, textColor]
    );

    const iconClasses = useMemo(
      () =>
        classnames('content-center sm:mb-0', textColor, {
          'sm:mb-2': size == 'large'
        }),
      [size, textColor]
    );

    return (
      visible && (
        <div className={containerClasses}>
          <div className={inlineTextClasses}>
            {icon && (
              <div className={iconClasses}>
                <Icon name={icon} className="mr-2" size="small" />
              </div>
            )}
            <div className="inline-block w-fit">
              <RichText content={message} className="inline-block w-auto [&>a]:hover:text-white" />
              {!!onUndo && (
                <div className="ml-1 inline-block w-auto">
                  <Link onClick={onUndo} text="Undo" href="#" className="text-white" />
                </div>
              )}
            </div>
          </div>
          {canDismiss && (
            <button className="flex" onClick={() => setVisible(false)}>
              <Icon name="close" size="small" />
            </button>
          )}
        </div>
      )
    );
  }
);

export default DismissableBannerBlock;
