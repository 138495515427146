import { trackEvent } from '@/analytics/utils';
import { AnalyticsContext, AnalyticsEventName } from './constants';
import { ItemAnalyticsModel } from '@/@types/client-api/models/ItemAnalyticsModel';
import { ConvertItemAnalyticsModelBase } from './analyticsUtils';

export const trackViewAllJurisdictions = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => {
  const items = ConvertItemAnalyticsModelBase(itemAnalyticsModel);
  trackEvent({
    eventName: AnalyticsEventName.ViewAllJurisdictions,
    parameters: items
      ? {
          context: context || '',
          items: [items]
        }
      : {},
    skipBaseEventParameters: true
  });
};
