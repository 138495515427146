import { memo } from 'react';
import classnames from 'classnames';
import Text from '@/components/cms/Text';
import { BannerMessageBlock } from '@/@types/content';
import { getNewTagText } from '@/components/ui/CreditTracker/util/TrackerHelpers';

interface CreditOverviewProps {
  messages?: BannerMessageBlock[];
  heading?: string;
}

const CreditInfoOverview: React.FC<CreditOverviewProps> = memo(({ messages }) => {
  const flexClasses = classnames('flex flex-row items-center justify-between');

  return (
    <div className={flexClasses}>
      {messages?.map((message, index) => {
        const newLabel = getNewTagText(message?.expireNewStatus);
        return (
          <div className={flexClasses} key={`message-${index}`}>
            {message.body && (
              <span className="flex flex-col items-start justify-center">
                {
                  <span className={flexClasses}>
                    {!!newLabel && (
                      <span className="text-2 mr-2 flex w-fit flex-row flex-nowrap items-center rounded bg-black px-2 py-1 text-white">
                        {newLabel}
                      </span>
                    )}
                    {message.headingText && (
                      <Text element="h3" className="text-1 font-bold">
                        {message.headingText}
                      </Text>
                    )}
                  </span>
                }
                <Text element="span">
                  <span
                    className="[&>p]:mb-5"
                    dangerouslySetInnerHTML={{ __html: message.body?.toString() }}
                  ></span>
                </Text>
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
});

CreditInfoOverview.displayName = 'CreditInfoOverview';
export default CreditInfoOverview;
