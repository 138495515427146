import classnames from 'classnames';
import Text from '@/components/cms/Text';
import * as Collapsible from '@radix-ui/react-collapsible';
import Icon from '@/components/ui/Icon';

interface MembersRowProps {
  membershipGroup?: string[];
  groupClassification?: string;
  selectedIndex?: number;
  isExpanded?: boolean;
  groupIndexes?: string[];
  handleGroupIndexes?: (index: string) => void;
}
const MembersRowCollapsible: React.FC<MembersRowProps> = ({
  membershipGroup = [],
  groupClassification,
  selectedIndex,
  isExpanded,
  groupIndexes = [],
  handleGroupIndexes = () => {}
}) => {
  const secondColumnStartingIndex = Math.ceil(membershipGroup.length / 2);
  const leftColumn = membershipGroup.slice(0, secondColumnStartingIndex);
  const rightColumn = membershipGroup.slice(secondColumnStartingIndex);
  const indexIncluded = groupIndexes.includes(String(selectedIndex));

  const columnClasses = classnames('col-span-1');
  const memberClasses = classnames('text-2 block text-gray-dark');

  return (
    <div className={classnames('flex flex-col gap-6')}>
      <Text element="h6" className="heading-6-medium">
        {groupClassification}
      </Text>
      <div className="grid grid-cols-1 gap-x-5 sm:grid-cols-2">
        <div className={columnClasses}>
          {leftColumn &&
            leftColumn.slice(0, 14).map((member, index) => {
              return (
                <Text key={index} className={memberClasses}>
                  {member}
                </Text>
              );
            })}
          {isExpanded &&
            leftColumn &&
            leftColumn.slice(14).map((member, index) => {
              return (
                <Text key={index + 14} className={memberClasses}>
                  {member}
                </Text>
              );
            })}
        </div>
        <div className={columnClasses}>
          {rightColumn &&
            rightColumn.slice(0, 13).map((member, index) => {
              return (
                <Text key={index} className={memberClasses}>
                  {member}
                </Text>
              );
            })}
          {isExpanded &&
            rightColumn &&
            rightColumn.slice(13).map((member, index) => {
              return (
                <Text key={index + 13} className={memberClasses}>
                  {member}
                </Text>
              );
            })}
        </div>
      </div>
      <Collapsible.Trigger asChild>
        <button
          onClick={() => {
            handleGroupIndexes(String(selectedIndex));
          }}
          className={classnames('text-2 flex items-center gap-1 font-bold hover:text-red')}
          aria-expanded={isExpanded && indexIncluded}
        >
          {isExpanded ? 'See less' : 'See more'}
          <Icon size="small" name={isExpanded ? 'chevron-up' : 'chevron-down'} />
        </button>
      </Collapsible.Trigger>
    </div>
  );
};

export default MembersRowCollapsible;
