/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreditCardRequest } from '@/@types/client-api';
import { useLazyGetPaymentProviderPublicKeyQuery } from '@/redux/api/client/userPaymentMethods';

export const ExpirationFormat = 'MM/yyyy';
export interface flexCardInfoProxy {
  cardNumber: string;
  cardType: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
}
export interface flexOptionsProxy {
  kid: string;
  keystore: JwkProxy;
  cardInfo: flexCardInfoProxy;
  encryptionType: 'rsaoaep256';
  production?: boolean;
}

export interface JwkProxy {
  kid: string;
}

export interface flexResponseProxy {
  keyId: string;
  token: string;
  maskedPan: string;
  cardType: string;
  timestamp: number;
  signedFields: string;
  signature: string;
  error?: string;
}

export const createFlexToken = async (options: flexOptionsProxy): Promise<flexResponseProxy> => {
  const { FLEX } = window as any;
  return new Promise((resolve, reject) => {
    FLEX.createToken(options, (response: flexResponseProxy | PromiseLike<flexResponseProxy>) => {
      if ((response as any).error) {
        reject((response as any).error);
      } else {
        resolve(response);
      }
    });
  });
};

export type TCreditCardFormData = {
  cardNumber: string;
  securityCode: string;
  expirationMonth: string;
  expirationYear: string;
  cardTypeName: string;
  cyberSourceCardType: string;
  cardHolderName: string;
  isDefault: boolean;
};

export const useCreateFlexToken = () => {
  const [triggerPublicKeyQuery] = useLazyGetPaymentProviderPublicKeyQuery();

  return async ({
    cardNumber,
    securityCode,
    cyberSourceCardType,
    isDefault,
    ...data
  }: TCreditCardFormData): Promise<CreditCardRequest> => {
    const publicKeyRes = await triggerPublicKeyQuery(undefined).unwrap();
    const jwk = JSON.parse(publicKeyRes.jwt!).jwk as JwkProxy;

    const options = {
      kid: jwk.kid,
      keystore: jwk,
      encryptionType: 'rsaoaep256' as const,
      cardInfo: {
        cardNumber,
        securityCode,
        cardType: cyberSourceCardType,
        cardExpirationMonth: data.expirationMonth,
        cardExpirationYear: data.expirationYear
      },
      production: !!publicKeyRes.isInProduction
    };

    const flexRes = await createFlexToken(options);
    return {
      ...data,
      isDefault,
      cardHolderName: data.cardHolderName ?? '',
      token: flexRes.token,
      ccNumberMasked: flexRes.maskedPan
    };
  };
};

export const formatCardNumber = (cardNumber: string): string =>
  cardNumber.substring(cardNumber.length - 4);

const defaultFormat = /(\d{1,4})/g;
export const defaultMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export interface ICreditCardType {
  type: string;
  pattern: RegExp;
  format: RegExp;
  length: number[];
  mask: Array<string | RegExp>;
  cvcLength: number[];
  luhn: boolean;
  // PLI Specific
  tenderTypeId: number;
  accepted: boolean;
  cyberSourceCardType?: '000' | '001' | '002' | '003' | '004' | '005' | '006' | '007';
}
const cards: ICreditCardType[] = [
  {
    type: 'American_Express',
    pattern: /^3[47]/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    length: [15],
    cvcLength: [4],
    luhn: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ],
    // pli specific
    tenderTypeId: 6,
    accepted: true,
    cyberSourceCardType: '003'
  },
  {
    type: 'dankort',
    pattern: /^5019/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
    mask: defaultMask,
    // pli specific
    tenderTypeId: 0,
    accepted: false
  },
  {
    type: 'Diners_Club',
    pattern: /^(36|38|30[0-5])/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,

    length: [14],
    cvcLength: [3],
    luhn: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ],
    // pli specific
    tenderTypeId: 5,
    accepted: true,
    cyberSourceCardType: '005'
  },
  {
    type: 'Discover',
    pattern: /^(6011|65|64[4-9]|622)/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
    mask: defaultMask,
    // pli specific
    tenderTypeId: 37,
    accepted: true,
    cyberSourceCardType: '004'
  },
  {
    type: 'JCB',
    pattern: /^35/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
    mask: defaultMask,
    // pli specific
    tenderTypeId: 0,
    accepted: false,
    cyberSourceCardType: '007'
  },
  {
    type: 'laser',
    pattern: /^(6706|6771|6709)/,
    format: defaultFormat,
    length: [16, 17, 18, 19],
    cvcLength: [3],
    luhn: true,
    mask: defaultMask,
    // pli specific
    tenderTypeId: 0,
    accepted: false
  },
  {
    type: 'maestro',
    pattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
    format: defaultFormat,
    length: [12, 13, 14, 15, 16, 17, 18, 19],
    cvcLength: [3],
    luhn: true,
    mask: defaultMask,
    // pli specific
    tenderTypeId: 0,
    accepted: false
  },
  {
    type: 'MasterCard',
    pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
    mask: defaultMask,
    // pli specific
    tenderTypeId: 7,
    accepted: true,
    cyberSourceCardType: '002'
  },
  {
    type: 'unionpay',
    pattern: /^62/,
    format: defaultFormat,
    length: [16, 17, 18, 19],
    cvcLength: [3],
    luhn: false,
    mask: defaultMask,
    // pli specific
    tenderTypeId: 0,
    accepted: false
  },
  {
    type: 'visaelectron',
    pattern: /^4(026|17500|405|508|844|91[37])/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
    mask: defaultMask,
    // pli specific
    tenderTypeId: 0,
    accepted: false,
    cyberSourceCardType: '001'
  },
  {
    type: 'elo',
    pattern: /^(4011|438935|45(1416|76|7393)|50(4175|6699|67|90[4-7])|63(6297|6368))/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
    mask: defaultMask,
    // pli specific
    tenderTypeId: 0,
    accepted: false
  },
  {
    type: 'VISA',
    pattern: /^4/,
    format: defaultFormat,
    length: [13, 16, 19],
    cvcLength: [3],
    luhn: true,
    mask: defaultMask,
    // pli specific
    tenderTypeId: 8,
    accepted: true,
    cyberSourceCardType: '001'
  }
];

const luhnCheck = (num: string) => {
  let digit;
  let odd = true;
  let sum = 0;
  const digits = (num + '').split('').reverse();
  const len = digits.length;
  for (let j = 0; j < len; j++) {
    digit = digits[j];
    digit = parseInt(digit, 10);
    odd = !odd;
    if (odd) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }
  return sum % 10 === 0;
};

export const validateCardNumber = (num: string) => {
  num = (num + '').replace(/\D/g, '');
  if (!/^\d+$/.test(num)) {
    return false;
  }
  const card = cardFromNumber(num);
  if (!card) {
    return false;
  }
  const isValid = card.luhn === false || luhnCheck(num);
  return isValid;
};

export const validateCardCVC = (cvc: string, cardRule?: ICreditCardType) => {
  let ref;
  cvc = cvc.trim();
  if (!/^\d+$/.test(cvc)) {
    return false;
  }
  let isValid: boolean;
  if (cardRule) {
    ref = cvc.length;
    isValid = cardRule != null && cardRule.cvcLength.indexOf(ref) >= 0;
  } else {
    isValid = cvc.length >= 3 && cvc.length <= 4;
  }
  return isValid;
};

export const cardFromNumber = (num: string): ICreditCardType | undefined => {
  let card;
  num = (num + '').replace(/\D/g, '');
  const len = cards.length;
  for (let j = 0; j < len; j++) {
    card = cards[j];
    if (card.pattern.test(num)) {
      return card;
    }
  }
};

export const maskString = (inputString: string, mask: (RegExp | string)[]) => {
  let result = '';
  let inputIndex = 0;
  let currentlyOn: 'regex' | 'string' = 'regex';

  mask.forEach(maskElement => {
    if (maskElement instanceof RegExp) {
      while (inputIndex < inputString.length && !maskElement.test(inputString[inputIndex])) {
        inputIndex++;
      }
      if (inputIndex < inputString.length) {
        currentlyOn = 'regex';
        result += inputString[inputIndex++];
      }
    } else if (currentlyOn === 'regex') {
      currentlyOn = 'string';
      result += maskElement;
    }
  });

  return result.trim();
};
