import React from 'react';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import ComponentFactory from '@/lib/ComponentFactory';
import type { IContent } from '@/@types/cms';
import classnames from 'classnames';

interface ContentAreaProps extends React.HTMLProps<HTMLDivElement> {
  propertyName?: string;
  components?: Array<IContent>;
  componentsProps?: Record<string, unknown>;
  element?: keyof JSX.IntrinsicElements | React.ElementType;
  id?: string;
  noWrapper?: boolean;
}

const ContentArea: React.FC<ContentAreaProps> = ({
  propertyName,
  components,
  componentsProps,
  className,
  element,
  id,
  noWrapper,
  ...props
}) => {
  const Element = element || 'section';

  const conditionalProps =
    isEditOrPreviewMode() && propertyName ? { ...props, 'data-epi-edit': propertyName } : props;

  if (noWrapper) {
    return <ComponentFactory components={components} componentsProps={componentsProps} />;
  }

  return (
    <Element id={id} {...conditionalProps} className={classnames(className, 'relative')}>
      <ComponentFactory components={components} componentsProps={componentsProps} />
    </Element>
  );
};

export default ContentArea;
