import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  IAnnouncementsRequest,
  useGetManyAnnouncementMessagesQuery
} from '@/redux/api/client/creditTracker';
import {
  creditTrackerFailure,
  creditTrackerGetManyAnnouncementsSuccess
} from '@/redux/slices/creditTrackerSlice';

export type MessagesHookParams = {
  requests: IAnnouncementsRequest[];
  skip?: boolean;
};
const useRegionMessages = ({ requests, skip }: MessagesHookParams) => {
  const dispatch = useDispatch();

  const {
    isLoading: messagesLoading,
    isFetching: messagesFetching,
    error: messagesError,
    data: messagesData
  } = useGetManyAnnouncementMessagesQuery(requests, {
    skip: !requests?.length || !!skip
  });

  useEffect(() => {
    messagesError && dispatch(creditTrackerFailure(messagesError));
  }, [messagesError, dispatch]);

  useEffect(() => {
    if (messagesData) {
      dispatch(creditTrackerGetManyAnnouncementsSuccess(messagesData));
    }
  }, [dispatch, messagesData]);

  const allMessages = useMemo(() => {
    return messagesData;
  }, [messagesData]);

  const allMessagesForRegion = useCallback(
    (key: string) => {
      return messagesData?.[key];
    },
    [messagesData]
  );

  const sidePanelMessages = useCallback(
    (key: string) => {
      return allMessagesForRegion(key)?.filter(a => a.showOnSidePanel);
    },
    [allMessagesForRegion]
  );

  const trackerCarouselMessages = useCallback(
    (key: string) => {
      return allMessagesForRegion(key)?.filter(a => a.showOnTrackerCarousel);
    },
    [allMessagesForRegion]
  );

  const jurisdictionPageCarouselMessages = useCallback(
    (key: string) => {
      return allMessagesForRegion(key)?.filter(a => a.showOnJurisdictionPageCarousel);
    },
    [allMessagesForRegion]
  );

  const recentRuleChangeMessages = useCallback(
    (key: string) => {
      return allMessagesForRegion(key)?.filter(a => a.showOnRecentRuleChanges);
    },
    [allMessagesForRegion]
  );

  const currentlyActiveMessages = useCallback(
    (key: string) => {
      return allMessagesForRegion(key)?.filter(a => a.currentlyActive);
    },
    [allMessagesForRegion]
  );

  const covidRuleChangeMessages = useCallback(
    (key: string) => {
      return allMessagesForRegion(key)?.filter(a => a.covidRuleChange);
    },
    [allMessagesForRegion]
  );

  return {
    allMessages,
    messagesError,
    messagesLoading,
    messagesFetching,
    allMessagesForRegion,
    sidePanelMessages,
    trackerCarouselMessages,
    jurisdictionPageCarouselMessages,
    recentRuleChangeMessages,
    currentlyActiveMessages,
    covidRuleChangeMessages
  };
};
export default useRegionMessages;
