import type { SearchPage as SearchPageProps, SiteSettings } from '@/@types/content';
import SearchResultBlock from '../blocks/SearchResultBlock';
import withNav from '../hocs/withNav';
import { SearchContext } from '@/analytics/constants';

const SearchPageComponent: React.FC<SearchPageProps & SiteSettings> = ({
  recommendedSearchList,
  semanticFilters
}) => {
  return (
    <SearchResultBlock
      useQueryWhenPresent
      recommendedSearchList={recommendedSearchList}
      semanticFilters={semanticFilters}
      showMarketingModule
      shouldPassUrlParams
      searchContext={SearchContext.ResultsPage}
    />
  );
};

const SearchPage = withNav(SearchPageComponent);
export default SearchPage;
