import { ClientAPI } from '@/redux/api';
import {
  Certificate,
  CertificateDownloadRequest,
  CertificateRequest,
  CertificateResponse,
  CertificateUploadBlob
} from '@/@types/client-api';
import { trackerBaseUrl } from './creditTracker';
import { processDownloadedFile } from '@/redux/helpers/fileManagement';

export interface IModifyCertificateRequest {
  customerId: number;
  region: number;
  certificate?: Certificate | null;
}

export interface GetCertificateRequest extends CertificateRequest {
  customerId: number;
}

export interface ICertificateDownloadRequest extends CertificateDownloadRequest {
  fileName?: string | null;
  customerId: number;
}

export interface ICertificateFileRequest {
  file?: FormData;
  fileName?: string | null;
  customerId: number;
  blobName: string;
}

const certificatesBaseUrl = '/user/certificates';

const userCertificatesEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getCertificates: builder.query<CertificateResponse, CertificateRequest>({
      providesTags: [{ type: 'CreditTrackerData', id: 'Certificates' }],
      query: body => ({
        url: certificatesBaseUrl,
        method: 'POST',
        body
      })
    }),
    getCertificatesAdminAccess: builder.query<CertificateResponse, GetCertificateRequest>({
      providesTags: (_response, _error, req) => [
        { type: 'CreditTrackerData', id: `Certificates-${req?.customerId}` }
      ],
      query: body => ({
        url: `${certificatesBaseUrl}/supplicant-access?customerSK=${body.customerId}`,
        method: 'POST',
        body
      })
    }),
    createExternalCertificate: builder.mutation<void, IModifyCertificateRequest>({
      query: props => ({
        url: `${trackerBaseUrl}/external-Certificate/add/${props.customerId}/${props.region}`,
        method: 'POST',
        body: props.certificate,
        responseHandler: response => response.text()
      }),
      invalidatesTags: ['CreditTrackerData', { type: 'CreditTrackerData', id: 'Certificates' }]
    }),
    updateExternalCertificate: builder.mutation<void, IModifyCertificateRequest>({
      query: props => ({
        url: `${trackerBaseUrl}/external-Certificate/update/${props.customerId}/${props.region}`,
        method: 'POST',
        body: props.certificate,
        responseHandler: response => response.text()
      }),
      invalidatesTags: ['CreditTrackerData', { type: 'CreditTrackerData', id: 'Certificates' }]
    }),
    deleteExternalCertificate: builder.mutation<void, IModifyCertificateRequest>({
      query: props => ({
        url: `${trackerBaseUrl}/external-Certificate/${props.customerId}/${props.certificate?.id}`,
        method: 'DELETE',
        responseHandler: response => response.text()
      }),
      invalidatesTags: ['CreditTrackerData', { type: 'CreditTrackerData', id: 'Certificates' }]
    }),
    downloadExternalCertificateFiles: builder.mutation<void, ICertificateFileRequest>({
      query: request => ({
        url: `${certificatesBaseUrl}/download/${request.customerId}`,
        params: { blobName: encodeURIComponent(request.blobName) },
        method: 'GET',
        responseHandler: async response => {
          if (response.status <= 200) {
            const blob = await response.blob();
            processDownloadedFile(request.fileName ?? request.blobName, blob);
          }
        },
        cache: 'no-cache'
      })
    }),
    downloadBulkCertificateFiles: builder.mutation<void, ICertificateDownloadRequest>({
      query: request => ({
        url: `${certificatesBaseUrl}/download-bulk/${request.customerId}`,
        method: 'POST',
        responseHandler: async response => {
          if (response.status <= 200 && request?.fileName) {
            const blob = await response.blob();
            processDownloadedFile(request.fileName, blob);
          }
        },
        cache: 'no-cache',
        body: {
          keys: request.keys,
          blobUris: request.blobUris?.map(a => encodeURIComponent(a))
        } as unknown as CertificateDownloadRequest
      })
    }),
    uploadCertificateFile: builder.mutation<CertificateUploadBlob[], ICertificateFileRequest>({
      query: request => ({
        url: `${certificatesBaseUrl}/upload`,
        method: 'Post',
        cache: 'no-cache',
        body: request.file
      })
    }),
    deleteCertificateFile: builder.mutation<void, ICertificateFileRequest>({
      query: request => ({
        url: `${certificatesBaseUrl}/delete-file/${request.customerId}`,
        params: { blobName: request.blobName },
        method: 'DELETE',
        responseHandler: response => response.text()
      })
    })
  })
});

export const {
  useGetCertificatesQuery,
  useGetCertificatesAdminAccessQuery,
  useDownloadBulkCertificateFilesMutation,
  useDownloadExternalCertificateFilesMutation,
  useLazyGetCertificatesQuery,
  useCreateExternalCertificateMutation,
  useUpdateExternalCertificateMutation,
  useUploadCertificateFileMutation,
  useDeleteExternalCertificateMutation,
  useDeleteCertificateFileMutation
} = userCertificatesEndpoints;

export default userCertificatesEndpoints;
