import { FC, lazy, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatDate, parseISO } from 'date-fns';
import * as Collapsible from '@radix-ui/react-collapsible';
import { ICertificateViewModel } from '@/@types/client-api';
import Link from '@/components/ui/Link';
import Text from '@/components/cms/Text';
import CircleButton from '@/components/ui/Buttons/CircleButton';
import CertificateTable, { RowInfo, RowInfoAction } from '@/components/ui/Tables/CertificateTable';
import { ProgramInfo } from '@/components/ui/Tables/CertificateTable';
import Divider from '@/components/ui/Divider';
import useCertificates from '@/components/ui/Certificates/hooks/useCertificatesHook';
import Shimmer from '@/components/ui/Shimmer';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import { CertificateLink } from '@/components/ui/CreditTracker/util/constants';
import LoadingDots from '@/components/ui/Buttons/LoadingDots';
import {
  getItemInfo,
  getKeysAndBlobUris,
  getRequestedDate,
  getRowDataCertificates
} from '@/components/ui/Certificates/util/certificateHelpers';
import { creditTrackerLoading } from '@/redux/slices/creditTrackerSlice';
import { CreditTrackerContext } from '@/components/blocks/CreditTracker/CreditTrackerContext';
import { trackDownloadEvent, trackDownloadMultiEvent } from '@/analytics/certificates';
import { AnalyticsContext } from '@/analytics/constants';
const CreditModal = lazy(() => import('@/components/ui/CreditTracker/Modals/CreditModal'));

interface CertificatesSectionProps {
  debounceLoading?: boolean;
  isTransitionalOrNewlyAdmitted?: boolean;
  requestedTooltipMessage?: string | null;
  carryoverDisclaimer?: string;
  myCertificatesPageLink?: string;
  individualSk?: string;
  dateCompletedOutOfRangeMessage?: string;
}

const CertificatesSection: FC<CertificatesSectionProps> = props => {
  const { debounceLoading, myCertificatesPageLink, individualSk, dateCompletedOutOfRangeMessage } =
    props;
  const dispatch = useDispatch();
  const { selectedRegion, isLoading } = useContext(CreditTrackerContext);
  const [isDownloading, setIsDownloading] = useState<boolean>();
  const [showAllCertificates, setShowAllCertificates] = useState(true);
  const [showExternalCreditModal, setShowExternalCreditModal] = useState(false);
  const [activeExternalCertificate, setActiveExternalCertificate] =
    useState<ICertificateViewModel | null>(null);
  const [allCertsLoading, setAllCertsLoading] = useState<boolean>(false);
  const certificateHookData = useCertificates(false, !selectedRegion, individualSk);

  const regionName = useMemo(
    () => selectedRegion?.creditRegionName ?? '',
    [selectedRegion?.creditRegionName]
  );

  const {
    fileManagement,
    selectedInternalCertificates: certificates,
    selectedExternalCertificates: externalCertificates,
    certsLoading,
    certsRefetching
  } = certificateHookData;

  useEffect(() => {
    setAllCertsLoading(!!certsLoading || !!certsRefetching);
  }, [certsLoading, certsRefetching]);

  useEffect(() => {
    if (!certsLoading) {
      dispatch(creditTrackerLoading(true));
    } else {
      dispatch(creditTrackerLoading(false));
    }
  }, [certsLoading, certsRefetching, dispatch]);

  const allCertificates = useMemo(() => {
    const arr = [...(certificates ?? []), ...(externalCertificates ?? [])];
    if (arr.length) {
      return arr.sort((a, b) => {
        const dateA = a.dateCompleted ? new Date(a.dateCompleted).getTime() : 0;
        const dateB = b.dateCompleted ? new Date(b.dateCompleted).getTime() : 0;
        return dateB - dateA;
      });
    }
  }, [certificates, externalCertificates]);

  const certificatesWithAttachments = useMemo(() => {
    return allCertificates?.filter(
      certificate => !!certificate.attachment_SK || certificate.certificateBlobs?.length
    );
  }, [allCertificates]);

  const editCallback = useCallback(
    (id: number) => {
      const certificate = allCertificates?.find(certificate => certificate.id === id);
      if (certificate) {
        setActiveExternalCertificate(certificate);
        setShowExternalCreditModal(true);
      }
    },
    [allCertificates]
  );

  const downloadCallback = useCallback(
    async (certificate: ICertificateViewModel) => {
      if (certificate) {
        trackDownloadEvent(AnalyticsContext.CreditTracker);
        setIsDownloading(true);
        if (certificate.certificateBlobs?.length) {
          for (const blob of certificate.certificateBlobs) {
            if (blob?.blobUri)
              fileManagement?.downloadCertificateFile(blob?.blobUri, blob?.fileName);
          }
        } else if (certificate?.attachment_SK)
          await fileManagement?.downloadBulkCertificateFiles(
            [certificate?.attachment_SK],
            [],
            certificate.documentId
          );
      }
      setIsDownloading(false);
    },
    [fileManagement]
  );

  const numOfCertificates = allCertificates?.length ?? 0;

  const downloadAllCallback = useCallback(async () => {
    if (allCertificates) {
      setIsDownloading(true);
      const { keys, blobUris } = getKeysAndBlobUris(allCertificates);
      await fileManagement?.downloadBulkCertificateFiles(keys, blobUris, `${regionName}.zip`);
    }
    setIsDownloading(false);
  }, [allCertificates, fileManagement, regionName]);

  const fullShimmer = useMemo(() => {
    return (
      <>
        <div className="mb-10 flex w-full flex-row flex-nowrap items-center justify-between">
          <div className="flex w-full basis-1/2 flex-row flex-wrap items-center justify-between md:basis-auto">
            <Shimmer className="mb-3 h-[42px] w-[250px] rounded-lg md:mb-0 md:w-[300px]" />
            <Shimmer className="h-5 w-[163px] rounded-lg" />
          </div>
          <div className="ml-4">
            <Shimmer className="size-[32px] rounded-full bg-gray-light" />
          </div>
        </div>
        <div className="w-full">
          <Shimmer className="h-12 w-full rounded-lg" />
          {Array.from({ length: numOfCertificates }, (_, i) => (
            <Shimmer className="mt-3 h-28 w-full rounded-lg" key={`cert-${i}`} />
          ))}
        </div>
        <div className="float-end mt-6">
          <Shimmer className="h-5 w-[163px] rounded-lg" />
        </div>
      </>
    );
  }, [numOfCertificates]);

  const renderCertificateRows = (
    certificates: ICertificateViewModel[] | ICertificateViewModel[] | null
  ): ProgramInfo[] => {
    const res =
      certificates?.map((certificate, i) => {
        const requestedDate = getRequestedDate(certificate);

        const hasAttachment =
          (certificate.certificateBlobs?.length ?? 0) > 0 || certificate.attachment_SK != null;

        const getAction = (): CertificateLink | undefined => {
          if (certificate.isExternal) return CertificateLink.Edit;
          if (certificate.isRequestPending) return CertificateLink.Requested;
          if (hasAttachment) return CertificateLink.DownloadCertificate;
        };

        const _action = getAction();

        return {
          rowInfo: [
            {
              key: i,
              itemInfo: getItemInfo(certificate),
              rowDataCertificates: getRowDataCertificates(certificate),
              action: {
                icon: certificate.isExternal && hasAttachment && 'file',
                inactive: _action == CertificateLink.DownloadCertificate && isDownloading,
                text:
                  ((_action == CertificateLink.DownloadCertificate && !!certificate.documentId) ||
                    _action != CertificateLink.DownloadCertificate) &&
                  _action,
                callback:
                  _action == CertificateLink.Edit && certificate.id
                    ? () => editCallback(certificate.id!)
                    : _action == CertificateLink.DownloadCertificate
                      ? () => downloadCallback(certificate)
                      : undefined
              } as RowInfoAction,
              requestedDate,
              isCarryover: certificate.isCarryover,
              programUrl: certificate.productUrl
            } as RowInfo
          ] as unknown as RowInfo[]
        } as ProgramInfo;
      }) ?? [];

    if (res?.length > 0) {
      res.push({
        rowInfo: [
          {
            key: res.length + 1,
            action: {
              text: 'View all my certificates',
              href: myCertificatesPageLink
            } as RowInfoAction
          } as RowInfo
        ] as unknown as RowInfo[]
      } as ProgramInfo);
    }
    return res;
  };

  const debouncedLoading = useDebounceLoading(debounceLoading || isLoading || allCertsLoading);

  const accordionAriaLabel = useMemo(
    () => (showAllCertificates ? `Collapse certificates section.` : `Expand certificates section.`),
    [showAllCertificates]
  );

  return (
    <>
      <Divider color={'light'} className="mb-6" />
      {activeExternalCertificate && (
        <Suspense fallback={null}>
          <CreditModal
            open={showExternalCreditModal}
            setOpen={() => {
              setShowExternalCreditModal(false);
              setActiveExternalCertificate(null);
            }}
            eyebrowText={activeExternalCertificate.creditRegionDescription ?? ''}
            certificateActionType="edit"
            data={{
              ...activeExternalCertificate,
              dateCompleted: activeExternalCertificate.dateCompleted
                ? formatDate(parseISO(activeExternalCertificate.dateCompleted!), 'MM/dd/yyyy')
                : ''
            }}
            isCarryover={activeExternalCertificate.carryOver || false}
            disclaimer={props.carryoverDisclaimer}
            dateCompletedOutOfRangeMessage={dateCompletedOutOfRangeMessage}
          />
        </Suspense>
      )}
      {debouncedLoading || (allCertificates && allCertificates.length > 0) ? (
        <Suspense fallback={fullShimmer}>
          {debouncedLoading ? (
            fullShimmer
          ) : (
            <Collapsible.Root
              open={showAllCertificates === true}
              onOpenChange={() => setShowAllCertificates(!showAllCertificates)}
              className=""
            >
              <div className="mb-10 flex flex-row justify-between">
                <div className="flex w-full flex-col md:flex-row md:items-center">
                  <Text propertyName="h5" element="h5" className="heading-5-medium mb-2 mr-auto">
                    Certificates
                  </Text>
                  {certificatesWithAttachments &&
                    certificatesWithAttachments.length >= 2 &&
                    (isDownloading ? (
                      <span className="ml-8 self-start md:ml-0 md:mr-12 md:self-auto">
                        <LoadingDots color={'black'} dotSize="large" />
                      </span>
                    ) : (
                      <Link
                        href="#"
                        text="Download all certificates"
                        disabled={isDownloading}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();

                          if (!isDownloading) {
                            trackDownloadMultiEvent(AnalyticsContext.CreditTracker);
                            downloadAllCallback();
                          }
                        }}
                        variant="text-link-1"
                      />
                    ))}
                </div>
                <Collapsible.Trigger asChild>
                  <CircleButton
                    color="dark"
                    className="ml-4"
                    iconSize="large"
                    fixedSize={true}
                    icon={showAllCertificates ? 'chevron-up' : 'chevron-down'}
                    aria-label={accordionAriaLabel}
                  />
                </Collapsible.Trigger>
              </div>
              <Collapsible.Content>
                <CertificateTable
                  tableHeader={['Date', 'Program', 'Jurisdiction', 'Credits', '']}
                  rows={renderCertificateRows(allCertificates ?? [])}
                />
              </Collapsible.Content>
            </Collapsible.Root>
          )}
        </Suspense>
      ) : (
        <div className="module-spacing">
          <Text propertyName="Title" className="heading-6-medium mb-3">
            No certificates to display.
          </Text>
          <Text propertyName="SubTitle" className="text-1 text-gray-dark">
            You either haven’t earned any or have one being processed.
          </Text>
        </div>
      )}
    </>
  );
};

export default CertificatesSection;
