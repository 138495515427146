import classnames from 'classnames';
import { useEffect, useState } from 'react';

interface AwaiterProps {
  content: string[];
}

const Awaiter: React.FC<AwaiterProps> = ({ content }) => {
  const [index, setIndex] = useState(0);
  const [animation, setAnimation] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimation('animate-fadeInLong');
      setIndex(index + 1);
      setTimeout(() => {
        setAnimation('animate-fadeOutLong');
      }, 1500);
    }, 3000);

    return () => clearInterval(interval);
  }, [content, index]);

  return (
    <>
      <div className="size-full animate-slideAcrossLeft overflow-hidden rounded bg-[linear-gradient(45deg,white_40%,#f5f5f5_50%,white_60%)]"></div>
      <div className="container absolute top-1/2">
        {content.map((x, i) => {
          return (
            <div
              key={i}
              className={classnames(
                'heading-5',
                animation,
                i !== index % content.length ? 'hidden' : ''
              )}
            >
              {x}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Awaiter;
