import { ClientAPI } from '@/redux/api';
import { SearchResponse, TaxonomyCompletionResponse } from '@/@types/client-api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FILTERS, FilterBehavior } from '@/components/ui/SearchResults/constants';
import { RootState } from '@/redux/store';
import {
  makePrimaryRequest,
  makeDisjunctiveFilterRequest,
  updateGroupData,
  resetInternalSearchEndpoints,
  SearchRequestWithOptions
} from './searchInternal';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { isStartEndDateFilter } from '@/components/ui/Filter/DateFilterUtils';

const searchEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getSearchResults: builder.query<SearchResponse, SearchRequestWithOptions>({
      queryFn: async ({ requestBody, postQuery: _postQuery }, { dispatch, getState }) => {
        // remove empty strings that could throw off cache
        const postQuery = _postQuery || undefined;

        // The primary request to get the search results and available filters
        const primaryRes = await makePrimaryRequest({ requestBody, dispatch, postQuery });

        if (primaryRes.error) return { error: primaryRes.error as FetchBaseQueryError };

        let dataCopy: SearchResponse;

        try {
          dataCopy = JSON.parse(JSON.stringify(primaryRes.data)) as SearchResponse;
        } catch (e) {
          return { error: e as FetchBaseQueryError };
        }

        const adHocFilters = (getState() as RootState).search.adHocFilters;

        // The list of disjunctive filters that need their own query
        const disjunctiveFiltersThatNeedRequests =
          dataCopy.facetResults?.facetGroups?.filter(
            group =>
              ([FilterBehavior.CHECKBOX_OR, FilterBehavior.SINGLE_SELECT].includes(
                group.behavior as FilterBehavior
              ) &&
                group.groupTypeId !== FILTERS.CONTENT_TABS &&
                group.groupTypeId !== FILTERS.SUGGESTED_FILTERS &&
                group.facets?.some(
                  facet =>
                    // If the item is checked or if it's in the adHocFilters list, we need to make a follow-up request
                    facet.checked ||
                    adHocFilters.some(
                      f => f.value === facet.value && f.groupTypeId === group.groupTypeId
                    )
                )) ||
              // We also need to make a request if we have set the start and/or end date
              (group.behavior === FilterBehavior.DATE_PICKER &&
                group.facets?.some(f => f.value && isStartEndDateFilter(f.name)))
          ) ?? [];

        const newFilterGroups = await Promise.all(
          disjunctiveFiltersThatNeedRequests.map(facetGroup =>
            makeDisjunctiveFilterRequest({
              facetGroup,
              request: { requestBody, postQuery },
              getState: getState as () => RootState,
              dispatch
            })
          )
        );

        newFilterGroups.forEach(newGroup =>
          updateGroupData({
            newGroup,
            dataToUpdate: dataCopy
          })
        );

        return { data: dataCopy };
      },
      providesTags: result => [
        { type: 'Search', id: result?.searchResultSummary?.searchHash ?? 'Empty' }
      ]
    }),
    getAutocompleteSuggestions: builder.query<TaxonomyCompletionResponse, string>({
      query: query => ({
        url: `/search/autocomplete?q=${query}`,
        method: 'GET'
      }),
      providesTags: result => (result ? [{ type: 'Search', id: 'Autocomplete' }] : [])
    })
  })
});

export const resetSearchAPIs = (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  dispatch(searchEndpoints.util.resetApiState());
  dispatch(resetInternalSearchEndpoints());
};

export const {
  useGetSearchResultsQuery,
  useLazyGetSearchResultsQuery,
  useGetAutocompleteSuggestionsQuery,
  useLazyGetAutocompleteSuggestionsQuery
} = searchEndpoints;

export default searchEndpoints;
