import DropdownMenu from '@/components/ui/DropdownMenu';
import Divider from '@/components/ui/Divider';
import CircleButton from '@/components/ui/Buttons/CircleButton';
import Shimmer from '@/components/ui/Shimmer';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useEffect, useRef, useState } from 'react';

export type ActionItem = {
  icon: string;
  label: string;
  onClick: () => void;
  loading?: boolean;
};
export interface ActionBarProps {
  runtimeFormatted?: string;
  creditFormatted?: string;
  isCreditLoading?: boolean;
  actions: ActionItem[];
  shareModalId?: string;
  downloadModalId?: string;
  memsuiteModalId?: string;
}

const ActionBar = ({
  runtimeFormatted,
  creditFormatted,
  isCreditLoading,
  actions,
  shareModalId,
  downloadModalId,
  memsuiteModalId
}: ActionBarProps) => {
  const screenSizeBelowLg = useMediaQuery(ScreenSizeQueries.belowLg);
  const actionBarRef = useRef<HTMLDivElement>(null);
  const [actionBarWidth, setActionBarWidth] = useState(0);
  const [alwaysVisibleActions, setAlwaysVisibleActions] = useState<ActionItem[]>(actions);
  const [overflowActions, setOverflowActions] = useState<ActionItem[]>([]);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      setActionBarWidth(entries[0].target.clientWidth);
    });
    actionBarRef.current && observer.observe(actionBarRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => (actionBarRef.current ? observer.unobserve(actionBarRef.current) : undefined);
  }, []);

  useEffect(() => {
    if (screenSizeBelowLg || actionBarWidth > 1000) {
      setAlwaysVisibleActions([...actions]);
      setOverflowActions([]);
    } else {
      if (actionBarWidth > 850) {
        setAlwaysVisibleActions([...actions.slice(0, 3)]);
        setOverflowActions([...actions.slice(3)]);
      } else if (actionBarWidth > 750) {
        setAlwaysVisibleActions([...actions.slice(0, 2)]);
        setOverflowActions([...actions.slice(2)]);
      } else if (actionBarWidth > 500) {
        setAlwaysVisibleActions([...actions.slice(0, 1)]);
        setOverflowActions([...actions.slice(1)]);
      } else {
        setAlwaysVisibleActions([]);
        setOverflowActions([...actions]);
      }
    }
  }, [actionBarWidth, actions, screenSizeBelowLg]);

  return (
    <div ref={actionBarRef} className="relative flex w-full flex-col gap-7"       data-component={"ActionBar"}>
      <div className="text-1-medium flex flex-row items-center justify-between">
        <div className="flex flex-row items-center justify-between gap-3 lg:gap-[18px]">
          {runtimeFormatted && <span>{runtimeFormatted}</span>}
          {runtimeFormatted && (creditFormatted || isCreditLoading) ? (
            <Divider color="dark" orientation="vertical" length={16} />
          ) : (
            <></>
          )}
          {<span>{isCreditLoading ? <Shimmer className="h-4 w-32" /> : creditFormatted}</span>}
        </div>
        <div className="hidden flex-row gap-8 md:flex lg:gap-10">
          {alwaysVisibleActions.map((value, index) => {
            if (value.loading) {
              return (
                <div key={index} className="flex items-center gap-2">
                  <Shimmer key={value.label} className="size-9 rounded-full" />
                  <Shimmer key={`${value.label} `} className="h-5 w-24" />
                </div>
              );
            }

            let ariaControls = undefined;
            if (value.icon === 'share') {
              ariaControls = shareModalId;
            } else if (value.icon === 'download') {
              ariaControls = downloadModalId;
            } else if (value.label === 'Membership Suite') {
              ariaControls = memsuiteModalId;
            }

            return (
              <CircleButton
                key={value.label}
                icon={value.icon}
                label={value.label}
                onClick={value.onClick}
                aria-controls={ariaControls}
              />
            );
          })}
          {overflowActions?.length ? (
            <DropdownMenu
              button={() => <CircleButton aria-label="More options" icon="more-ios" />}
              items={overflowActions}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="flex md:hidden">
          <DropdownMenu
            button={() => <CircleButton aria-label="More options" icon="more-ios" />}
            items={actions}
          />
        </div>
      </div>
      <Divider color="light" orientation="horizontal" className="hidden lg:flex" />
    </div>
  );
};

export default ActionBar;
