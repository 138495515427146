import React from 'react';
import Icon from '@/components/ui/Icon';
import { Theme } from '@/styles/darkMode';
import classnames from 'classnames';
import { getTextColorClass } from '@/utils/theme';

interface SearchButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  theme?: Theme;
  focusVisibleClasses?: string;
}

const SearchButton: React.FC<SearchButtonProps> = React.forwardRef<
  HTMLButtonElement,
  SearchButtonProps
>(
  (
    {
      label = 'Find a program, publication, or credit',
      theme = 'light',
      focusVisibleClasses,
      ...props
    },
    ref
  ) => {
    const searchButtonClasses = classnames(
      'group pointer-events-auto flex h-12 w-full cursor-pointer items-center gap-3 rounded-full px-2 py-1 pr-10',
      'lg:py-2',
      {
        'bg-gray-lightest': theme === Theme.Light,
        'bg-white/20': theme === Theme.Dark
      },
      'md:h-14',
      focusVisibleClasses
    );

    const searchIconClasses = getTextColorClass(theme);

    const searchTextClasses = classnames('text-2 truncate transition-colors', {
      'text-gray-dark group-hover:text-black': theme === Theme.Light,
      'text-white group-hover:text-white': theme === Theme.Dark
    });

    return (
      <button ref={ref} className={searchButtonClasses} {...props}>
        <span className="basis-10 p-2">
          <Icon name="search" size="large" className={searchIconClasses} />
        </span>
        <span className={searchTextClasses}>{label}</span>
      </button>
    );
  }
);

SearchButton.displayName = 'SearchButton';
export default SearchButton;
