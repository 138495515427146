import { CertificateResponse, ICertificateViewModel } from '@/@types/client-api';
import { PayloadAction, SerializedError, createSlice } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ClientAPI } from '@/redux/api';

export type UserProfileState = {
  isLoading: boolean;
  certificates: Record<string, Array<ICertificateViewModel>>;
  uploadProgress?: number;
  error: FetchBaseQueryError | SerializedError | null;
};

const initialUserProfileState: UserProfileState = {
  certificates: {},
  isLoading: false,
  error: null
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: initialUserProfileState,
  reducers: {
    userProfileLoading: (state, payload: PayloadAction<boolean>) => {
      state.isLoading = payload.payload;
    },
    userCertificateSuccess: (state, action: PayloadAction<CertificateResponse>) => {
      const certificates = action?.payload?.items ?? [];
      const custID = certificates.find(a => !!a.customerId)?.customerId?.toString();
      if (!custID) return;

      const filterExisting =
        state.certificates[custID]?.filter(
          a => !certificates?.map(b => b.regionKey)?.some(c => c === a.regionKey)
        ) ?? [];

      state.certificates[custID] = [...filterExisting, ...certificates]?.sort((a, b) =>
        a < b ? -1 : 1
      );
      state.error = null;
      state.isLoading = false;
    },
    userProfileFailure: (state, action: PayloadAction<FetchBaseQueryError | SerializedError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    invalidateCertificateData: () => {
      ClientAPI.util.invalidateTags([{ type: 'CreditTrackerData', id: 'Certificates' }]);
    }
  }
});

export const {
  userCertificateSuccess,
  userProfileFailure,
  userProfileLoading,
  invalidateCertificateData
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
