import * as Dialog from '@radix-ui/react-dialog';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';

interface ModalContentVideoProps {
  children: React.ReactNode;
}

const ModalContentVideo = ({ children }: ModalContentVideoProps): JSX.Element => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 z-modal bg-black/30" />
      <Dialog.Content
        className={classnames(
          '!pointer-events-none fixed left-1/2 top-1/2 z-modal flex w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center px-4',
          'sm:px-[70px]',
          'lg:px-[208px]'
        )}
      >
        <div className="pointer-events-auto relative aspect-video size-full max-w-[1024px] overflow-auto rounded-lg bg-white"       data-component={"ModalContentVideo"}
        >
          <Dialog.Close className="absolute right-6 top-6">
            <Icon name="close" size="large" className="text-white" />
          </Dialog.Close>
          {children}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
};

export default ModalContentVideo;
