import classnames from 'classnames';
import DropdownMenu from '@/components/ui/DropdownMenu';
import MenuTextTriggerButton from './Menu/MenuTextTriggerButton';
import NudgeTooltip from './Tooltip/NudgeTooltip';
import { MissingFieldLevelNudge } from '@/hooks/shared/useNudge/types';
import { useEffect, useRef } from 'react';

export type DropDownItem = { label: string; onClick: () => void };

export type SubSection = {
  title: string;
  description: string;
  dropdownItems?: DropDownItem[];
  fields?: string[];
};

type PersonalInfoSubSectionProps = {
  fieldNudge?: MissingFieldLevelNudge;
  subSection: SubSection;
  focused?: boolean;
  setFocusedAddressIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const PersonalInfoSubSection = ({
  fieldNudge,
  subSection,
  focused,
  setFocusedAddressIndex
}: PersonalInfoSubSectionProps): JSX.Element => {
  const editButtonRef = useRef<HTMLButtonElement>(null);

  const subSectionNudge =
    fieldNudge && subSection.fields?.includes((fieldNudge as MissingFieldLevelNudge).field)
      ? fieldNudge
      : undefined;

  useEffect(() => {
    if (!!focused && editButtonRef.current) {
      setFocusedAddressIndex?.(undefined);
      editButtonRef.current.focus();
    }
  }, [focused, setFocusedAddressIndex]);

  return (
    <div
      className={classnames('flex flex-col items-start justify-between gap-2', {
        'min-h-[126px] lg:min-h-[128px]': subSection.dropdownItems
      })}
    >
      <div className="w-full">
        <div className="heading-6-regular text-2 block overflow-hidden text-ellipsis pb-2 text-gray-dark">
          <NudgeTooltip nudge={subSectionNudge}>{subSection.title}</NudgeTooltip>
        </div>
        <div className="text-1 block overflow-hidden text-ellipsis whitespace-pre-wrap">
          {subSection.description}
        </div>
      </div>
      {subSection.dropdownItems && (
        <div>
          <DropdownMenu
            button={({ isOpen }) => (
              <MenuTextTriggerButton ref={editButtonRef} isOpen={isOpen} label="Edit" />
            )}
            items={subSection.dropdownItems}
          />
        </div>
      )}
    </div>
  );
};

export default PersonalInfoSubSection;
