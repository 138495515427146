import ProductDetailHeader from '@/components/ui/PDP/ProductDetailHeader';
import {
  DownloadVariation,
  OnDemandSegmentCatalogRelations
} from '@/hooks/PDP/useProgramCatalogRelations';
import { useActions } from '@/hooks/PDP/useActions';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';
import PDPShimmer from './PDPShimmer';
import classnames from 'classnames';
import { useOnDemandSegmentSelection } from '@/hooks/PDP/useProgramVariantSelection';
import OnDemand from './ECommBox/OnDemand';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  buildProgramSegmentCrossLinkProps,
  getExpiredStatus,
  getOnDemandSegmentRuntime
} from './helpers';
import { MemsuiteModalLabels } from './labels';
import ShareModal from './Modals/ShareModal';
import ProgramCrossLink from './ProgramCrossLink';
import DownloadModal from './Modals/DownloadModal';
import usePDPLayout from '@/hooks/PDP/usePDPLayout';
import PDPLayout from '@/components/cms/PDPLayout';
import ProgramExpired from './ECommBox/ProgramExpired';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { PDPFooter } from './PDPFooter';
import { AnalyticsContext } from '@/analytics/constants';
import { useMemsuite } from '@/hooks/PDP/useMemsuite';
import MemsuiteModal from './Modals/MemsuiteModal';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import { useDownloadModal } from '@/hooks/PDP/useDownloadModal';
import { useShareModal } from '@/hooks/PDP/useShareModal';
import { useTrackViewItem } from '@/analytics/pdp';
import { useFetchProgramMaterials } from '@/hooks/PDP/useFetchProgramMaterials';
import { useLibraryItems } from '@/hooks/PDP/useLibraryItems';
import { useProgramCreditLicenses } from '@/hooks/PDP/useProgramCreditLicenses';
import { useCreditTrackerCodeSearchParam } from '@/hooks/useCreditTrackerCode';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { ProductSEO } from './ProductSEO';

interface OnDemandSegmentPDPProps {
  catalogRelations: OnDemandSegmentCatalogRelations;
  loadingCatalogRelations?: boolean;
  isProgramSegmentsLoading?: boolean;
  selectDownloadByDefault?: DownloadVariation;
  context: AnalyticsContext;
}
const OnDemandSegmentPDP = ({
  catalogRelations,
  loadingCatalogRelations,
  isProgramSegmentsLoading,
  selectDownloadByDefault,
  context
}: OnDemandSegmentPDPProps) => {
  const { onDemandEcomLabels, programExpiredLabels } = useCatalogLabels();

  const webVariant = catalogRelations.segmentVariant;
  const [itemAnalyticsModel, setItemAnalyticsModel] = useState<ItemAnalyticsModel>();

  const { hydrateLibrary, selectLibraryItemWithFetchingState, selectLibraryItem } =
    useLibraryItems();
  useEffect(() => {
    hydrateLibrary([webVariant.code]);
  }, [hydrateLibrary, webVariant.code]);
  const libraryItem = selectLibraryItemWithFetchingState(webVariant.code);
  useFetchProgramMaterials([libraryItem.data]);

  const {
    licenses,
    isLicenseFetching,
    loggedOutLicenseProps,
    relevantCredits,
    isRelevantCreditsLoading
  } = useProgramCreditLicenses([webVariant.code!]);

  useTrackViewItem(webVariant.code);
  const creditTrackerCode = useCreditTrackerCodeSearchParam();

  const isExpired = getExpiredStatus(webVariant);
  const runtimeFormatted = useMemo(
    () =>
      webVariant.customDuration?.length
        ? webVariant.customDuration
        : getOnDemandSegmentRuntime(webVariant.runTimeSeconds).actionBar,
    [webVariant.customDuration, webVariant.runTimeSeconds]
  );

  const shareModalProps = useShareModal(webVariant, itemAnalyticsModel, context);
  const downloadModalProps = useDownloadModal(webVariant, selectDownloadByDefault);

  const memsuiteProps = useMemsuite(webVariant, catalogRelations);
  const actions = useActions(
    webVariant,
    catalogRelations,
    libraryItem,
    shareModalProps.setOpen,
    downloadModalProps.setOpen,
    memsuiteProps.actionBarMode === 'memsuite' ? memsuiteProps.setOpen : undefined,
    memsuiteProps.actionBarMode === 'roster' ? memsuiteProps.registerFromRosterAction : undefined
  );
  const actionBarProps = {
    runtimeFormatted,
    creditFormatted: relevantCredits,
    isCreditLoading: isRelevantCreditsLoading,
    actions,
    shareModalId: shareModalProps.customModalId,
    downloadModalId: downloadModalProps.customModalId
  };

  useOnDemandSegmentSelection(webVariant);

  const pageLinkPaths = useSelector(selectPageLinkPaths);

  const generateEcomBox = useCallback(
    (isMobile?: boolean) => {
      return isExpired ? (
        <ProgramExpired
          isMobile={isMobile}
          programId={webVariant.code!}
          labels={programExpiredLabels}
          pageLinks={pageLinkPaths}
        />
      ) : (
        <OnDemand
          isMobile={isMobile}
          labels={onDemandEcomLabels}
          loadingCatalogRelations={loadingCatalogRelations}
          loadingProgramSegments={isProgramSegmentsLoading}
          fetchingLibrary={libraryItem.isFetching}
          context={context}
          creditTrackerCode={creditTrackerCode}
        />
      );
    },
    [
      isExpired,
      webVariant.code,
      programExpiredLabels,
      pageLinkPaths,
      onDemandEcomLabels,
      loadingCatalogRelations,
      isProgramSegmentsLoading,
      libraryItem.isFetching,
      context,
      creditTrackerCode
    ]
  );

  useEffect(() => {
    const itemLibrary = selectLibraryItem(webVariant.code);
    if (itemLibrary) setItemAnalyticsModel(itemLibrary.itemAnalyticsModel);
  }, [webVariant.code, selectLibraryItem]);

  const crossLinkProps = buildProgramSegmentCrossLinkProps(catalogRelations);
  const isLarge = useMediaQuery(ScreenSizeQueries.lg);
  const isLoading = useSelector((state: RootState) => state.pdp.loading);

  const layout = usePDPLayout({
    layout: webVariant.pdpLayoutBlock,
    contentType: 'OnDemandVariation',
    variant: webVariant,
    isOndemand: true
  });

  return (
    <>
      <ProductSEO variant={webVariant} segmentProduct={catalogRelations.segmentProduct} />
      <div className="container flex flex-col !p-0 lg:flex-row">
        <div className="min-w-px lg:grow">
          {/* Inside Scroll area */}
          <PDPShimmer show={isLoading} />

          <ProductDetailHeader
            title={webVariant.displayName ?? ''}
            formatEyebrow={'On-Demand'}
            actionBarProps={actionBarProps}
            className={classnames({ hidden: isLoading })}
            layout={layout}
          />

          {/* Mobile Ecomm-Box */}
          {!isLarge && generateEcomBox(true)}
          <div className={classnames({ hidden: isLoading })}>
            <ProgramCrossLink {...crossLinkProps} />
            <PDPLayout
              layout={layout}
              catalogRelations={catalogRelations}
              loggedOutLicenseProps={loggedOutLicenseProps}
              creditTrackerCode={creditTrackerCode}
            />
          </div>
        </div>
        {/* Desktop Ecomm-Box */}
        {isLarge && (
          <div className="container relative w-[422px] shrink-0 px-0">{generateEcomBox()}</div>
        )}
      </div>
      <PDPFooter product={catalogRelations.segmentProduct} isLoading={isLoading} />
      <ShareModal {...shareModalProps} />
      <DownloadModal
        {...downloadModalProps}
        downloadVariants={catalogRelations.downloadVariants}
        licenses={licenses?.items ?? []}
        isLicensesFetching={isLicenseFetching}
        context={context}
      />
      {memsuiteProps.isMemsuiteAllowed && (
        <MemsuiteModal
          {...memsuiteProps}
          labels={MemsuiteModalLabels}
          location={catalogRelations?.programVariant?.location}
        />
      )}
    </>
  );
};

export default OnDemandSegmentPDP;
