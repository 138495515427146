import classnames from 'classnames';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import DotSeparatedContent from '@/components/ui/DotSeparatedContent';
import Link from '@/components/ui/Link';
import Text from '@/components/cms/Text';
import CreditRequestedTooltip from '@/components/ui/Tooltip/CreditRequestedTooltip';
import Icon from '@/components/ui/Icon';
import IconButton from '@/components/ui/IconButton';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import Shimmer from '../Shimmer';
import { CreditTrackerContext } from '@/components/blocks/CreditTracker/CreditTrackerContext';

interface RowData {
  date?: (string | undefined)[];
  jurisdiction?: string[];
  credits?: string[];
}

export interface RowInfoAction {
  text?: string;
  href?: string;
  inactive?: boolean;
  callback?: (key: number) => void;
  component?: JSX.Element;
  icon?: string;
}

export type ItemInfo = string | JSX.Element;

export interface RowInfo {
  key: number;
  rowDataCertificates?: RowData;
  itemInfo?: ItemInfo[];
  action: RowInfoAction;
  requestedDate?: Date;
  isCarryover: boolean;
  programUrl?: string | null;
}
export interface ProgramInfo {
  rowInfo: RowInfo[];
}

type TableProps = {
  isLoading?: boolean;
  tableHeader: string[];
  rows: ProgramInfo[];
};

const Table: React.FC<TableProps> = ({ isLoading, tableHeader, rows }) => {
  const { setIsLoading } = useContext(CreditTrackerContext);
  const largeWindow = useMediaQuery(ScreenSizeQueries.lg);
  const mediumWindow = useMediaQuery(ScreenSizeQueries.md);
  const [tooltipOpen, setTooltipOpen] = useState<number>(-1);

  const onTooltipClick = useCallback((key: number) => {
    setTooltipOpen(key);
  }, []);
  const tdLargeClasses = classnames('text-2 lg:py-6 lg:pr-6');
  const tBodyClasses = classnames('divide-y divide-solid divide-gray-light align-top');

  const renderActionLink = useCallback((action: RowInfoAction, key: number) => {
    if (!action) return;

    const linkClassNames = 'whitespace-nowrap';

    if (action.component) {
      return <div className={linkClassNames}>{action.component}</div>;
    }

    return action.href || action.callback ? (
      <div className="inline-flex items-center">
        <Link
          className={linkClassNames}
          text={action.text}
          variant="text-link-2"
          href={action.href || '#'}
          inactive={action.inactive}
          onClick={
            action?.callback
              ? (e: React.MouseEvent<Element, MouseEvent>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  action?.callback && action.callback(key);
                }
              : () => {}
          }
        />
        {action.icon && <Icon name={action.icon} size="small" className="ml-2" />}
      </div>
    ) : (
      <Text element="span" propertyName="Span" className={linkClassNames}>
        {action.text}
      </Text>
    );
  }, []);

  const tooltip = useCallback(
    (requestedDate: Date, key: number) => {
      const openChange = (flag: boolean) => {
        onTooltipClick(flag ? key : -1);
      };
      return (
        <CreditRequestedTooltip
          key={key}
          isOpen={tooltipOpen == key}
          onOpenChange={openChange}
          creditRequestDate={requestedDate}
          align={mediumWindow ? 'end' : 'start'}
        >
          <IconButton label="tooltip button" name="info" size="small" />
        </CreditRequestedTooltip>
      );
    },
    [mediumWindow, onTooltipClick, tooltipOpen]
  );

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4">
        <Shimmer className="h-[52px] w-full" />
        {Array.from({ length: 6 }).map((_, index) => (
          <Shimmer className="h-[168px] w-full rounded lg:h-[100px]" key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="grid w-full rounded-lg border border-solid border-gray-light"       data-component={"Table"}>
      <table className="text-left">
        {largeWindow ? (
          <>
            <thead className="text-2 bg-gray-feather">
              <tr>
                {tableHeader.map((item, index) =>
                  !item?.length ? (
                    <td
                      className="py-4 font-medium lg:pr-6 lg:first:pl-8 lg:last:pr-8"
                      key={`header-${index}`}
                      aria-label="Download item"
                    />
                  ) : (
                    <th
                      className="py-4 font-medium lg:pr-6 lg:first:pl-8 lg:last:pr-8 lg:odd:w-1/6 lg:even:w-1/4"
                      key={`header-${index}`}
                      scope="col"
                    >
                      {item}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody className={tBodyClasses}>
              {rows.map(({ rowInfo }, rowIndex) => (
                <tr key={`rowLarge-${rowIndex}`}>
                  {rowInfo.map(
                    (
                      {
                        action,
                        itemInfo,
                        rowDataCertificates,
                        requestedDate,
                        isCarryover,
                        programUrl
                      },
                      index
                    ) => (
                      <Fragment key={`rowInfoLarge-${index}`}>
                        <th
                          scope="row"
                          className={classnames(
                            tdLargeClasses,
                            'text-1 font-normal text-gray-dark lg:pl-8'
                          )}
                        >
                          {isCarryover ? 'Carryover' : rowDataCertificates?.date?.join(', ')}
                        </th>
                        <td className={tdLargeClasses}>
                          <span className="font-normal text-gray-dark">
                            {itemInfo && itemInfo[0]}
                          </span>
                          {itemInfo?.length &&
                            (programUrl ? (
                              <Link
                                className="text-2 w-fit pt-2 font-medium no-underline"
                                text={itemInfo[1] as string}
                                href={new URL(programUrl).pathname}
                                variant="text-link-1"
                                onClick={() => setIsLoading && setIsLoading(true)}
                              />
                            ) : (
                              <Text
                                propertyName="span"
                                element="span"
                                className="group text-2 flex w-fit flex-row items-center pt-2 font-medium"
                              >
                                {itemInfo[1] as string}
                              </Text>
                            ))}
                        </td>

                        <td className={tdLargeClasses}>
                          {rowDataCertificates?.jurisdiction?.map((jurisdiction, index) => (
                            <div
                              className="w-fit whitespace-nowrap"
                              key={`jurisdictionLarge-${index}`}
                            >
                              {jurisdiction}
                            </div>
                          ))}
                        </td>
                        <td className={classnames(tdLargeClasses, 'space-y-2')}>
                          {rowDataCertificates?.credits?.map((credit, index) => (
                            <div key={`creditLarge-${index}`}>{credit}</div>
                          ))}
                        </td>

                        {requestedDate ? (
                          <td
                            className={classnames(tdLargeClasses, 'flex items-center justify-end')}
                          >
                            <p className="pr-2">{action.text}</p>
                            {tooltip(requestedDate, rowIndex)}
                          </td>
                        ) : (
                          <td
                            className={classnames(tdLargeClasses, 'flex items-center justify-end')}
                          >
                            {renderActionLink(action, index)}
                          </td>
                        )}
                      </Fragment>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </>
        ) : (
          // SMALL BP
          <>
            <thead className="text-2 bg-gray-feather">
              <tr>
                <th className="px-6 py-4 font-medium" scope="col">
                  {tableHeader[1]}
                </th>
              </tr>
            </thead>
            <tbody className={tBodyClasses}>
              {rows.map(({ rowInfo }, rowIndex) => (
                <tr
                  key={`row-${rowIndex}`}
                  className="grid w-full space-y-6 p-6 md:flex md:grid-cols-2 md:gap-4"
                >
                  {rowInfo.map(
                    (
                      { action, itemInfo, rowDataCertificates, requestedDate, programUrl },
                      index
                    ) => (
                      <td
                        key={`rowInfo-${index}`}
                        className="grid w-full md:flex md:justify-between"
                      >
                        <div className="text-2 flex flex-col gap-4 font-normal">
                          <div className="text-gray-dark">
                            <DotSeparatedContent
                              content={[rowDataCertificates?.date?.join(', '), itemInfo?.[0]]}
                            />
                            {itemInfo?.length &&
                              (programUrl ? (
                                <Link
                                  className="w-fit pt-2 font-medium text-black no-underline"
                                  text={itemInfo[1] as string}
                                  href={new URL(programUrl).pathname}
                                  variant="text-link-1"
                                  onClick={() => setIsLoading && setIsLoading(true)}
                                />
                              ) : (
                                <Text
                                  propertyName="span"
                                  element="span"
                                  className="group text-1 flex w-fit flex-row items-center pt-2 font-medium text-black"
                                >
                                  {itemInfo[1] as string}
                                </Text>
                              ))}
                          </div>

                          <div className="space-y-2">
                            {rowDataCertificates?.credits?.map((credit, index) => (
                              <p key={`creditSmall-${index}`} className="pr-1">
                                {credit}
                              </p>
                            ))}
                          </div>
                        </div>
                        <div className="flex items-start pt-6 font-normal md:pt-0">
                          {requestedDate ? (
                            <div className={classnames(tdLargeClasses, 'flex items-center')}>
                              <p className="pr-2">{action.text}</p>
                              {tooltip(requestedDate, rowIndex)}
                            </div>
                          ) : (
                            renderActionLink(action, index)
                          )}
                        </div>
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </>
        )}
      </table>
    </div>
  );
};

export default Table;
