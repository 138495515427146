import { useGetTaxonomyCatalogChildrenQuery } from '@/redux/api/client/taxonomy';
import { useEffect, useState } from 'react';
import LinkGridBlock from '../LinkGridBlock/LinkGridBlock';
import {
  TextLinkBlock as TextLinkBlockProps,
  SecondaryTaxonomyBlock as SecondaryTaxonomyBlockProps
} from '@/@types/content';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import SecondaryTaxonomyBlockShimmer from './SecondaryTaxonomyBlockShimmer';

interface SecondaryTaxonomyProps extends SecondaryTaxonomyBlockProps {
  pageContentLinkId?: number;
}

type TaxonomyItem = {
  id: number;
  name: string;
  url: string;
  reference: string;
};

const SecondaryTaxonomyBlock: React.FC<SecondaryTaxonomyProps> = ({ pageContentLinkId }) => {
  const taxonomyId: string = `${pageContentLinkId}`;

  const { data, isLoading, isSuccess, currentData } =
    useGetTaxonomyCatalogChildrenQuery(taxonomyId);
  const [textLinks, setTextLinks] = useState<TextLinkBlockProps[]>([]);
  const [numberOfRows, setNumberOfRows] = useState<number>(4);
  const [layout, setLayout] = useState<string>('1');

  const isExtraSmall = useMediaQuery(ScreenSizeQueries.xsOnly);
  const isSmall = useMediaQuery(ScreenSizeQueries.smOnly);
  const isMedium = useMediaQuery(ScreenSizeQueries.md);
  const isLarge = useMediaQuery(ScreenSizeQueries.lg);

  useEffect(() => {
    if (!isLoading && isSuccess && data && currentData) {
      const links = (data as TaxonomyItem[])
        ?.filter(item => item.url)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(item => ({
          contentType: ['TextLinkBlock'],
          link: {
            href: item.url,
            text: item.name,
            title: item.name
          }
        }));

      setTextLinks(() => {
        return links;
      });
    }
  }, [isLoading, isSuccess, data, currentData]);

  useEffect(() => {
    if (isExtraSmall) {
      setNumberOfRows(4);
      setLayout('1');
    }

    if (isSmall || isMedium) {
      setNumberOfRows(3);
      setLayout('2');
    }

    if (isLarge) {
      setNumberOfRows(3);
      setLayout('3');
    }
  }, [isSmall, isLarge, isExtraSmall, isMedium]);

  if (isLoading) return <SecondaryTaxonomyBlockShimmer />;

  if (textLinks.length === 0) return null;

  return (
    <LinkGridBlock linkBlocks={textLinks} numberOfRows={numberOfRows} layout={layout} order="COL" />
  );
};

export default SecondaryTaxonomyBlock;
