import { useEffect, useRef } from 'react';

type VisibilityObserverProps = {
  onVisible: () => void;
  children: React.ReactNode;
};

const VisibilityObserver: React.FC<VisibilityObserverProps> = ({ onVisible, children }) => {
  const observerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentRef = observerRef.current;

    if (!currentRef) return;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            onVisible();
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(currentRef);

    return () => {
      observer.unobserve(currentRef);
    };
  }, [onVisible]);

  return <div ref={observerRef}>{children}</div>;
};

export default VisibilityObserver;
