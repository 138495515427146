import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import HLS from 'hls.js/dist/hls.light.mjs';

import Tag, { TagProps } from './Tag';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import * as Progress from '@radix-ui/react-progress';
import IconTag from './IconTag';
import DateTag, { DateType } from '@/components/ui/DateTag';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Shimmer from './Shimmer';
import AvatarComponent, { AvatarProps } from './Avatar';
import { UserSettingsQueries } from '@/constants/settings';
import ResponsiveImage, { ResponsiveImageProps } from '@/components/ui/ResponsiveImage';
import useInViewport from '@/hooks/useInViewport';
import { appendWebpFormat } from '@/lib/helpers/images';
import { appInsights } from '@/lib/ApplicationInsightsService';

export interface ThumbnailProps {
  ratio?: number;
  rounded?: boolean;
  progressValue?: number;
  loading?: boolean;
  video?: {
    url: string;
    autoPlay?: boolean;
    muted?: boolean;
    className?: string;
    playable?: boolean;
  };
  image?: {
    url: string;
    alt?: string;
  };
  avatar?: AvatarProps;
  icon?: {
    name: string;
    label?: string;
  };
  tag?: TagProps;
  dates?: DateType | null;
  mini?: boolean;
  link?: { url?: string; target?: string; onClick?: () => void };
  className?: string;
  ariaLabel?: string;
  resumeFromProgress?: boolean;
  card?: boolean;
  listPosition?: number;
  showProgress?: boolean;
  onClick?: () => void;
  renderButtonWrapper?: (button: JSX.Element) => JSX.Element;
  isSuccess?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  imageSizes?: ResponsiveImageProps['imageSizes'];
  forceShowVideoPreview?: boolean;
  fitImage?: boolean;
  shouldBeAllowedToAutoplayVideo?: boolean;
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  image,
  video,
  tag,
  icon,
  className,
  ratio = null,
  card = false,
  mini,
  dates,
  link,
  ariaLabel,
  avatar,
  resumeFromProgress = true,
  rounded = true,
  progressValue,
  loading,
  listPosition,
  showProgress = true,
  isSuccess,
  isError,
  isLoading,
  onClick,
  renderButtonWrapper,
  imageSizes,
  forceShowVideoPreview,
  fitImage = true,
  shouldBeAllowedToAutoplayVideo
}) => {
  const thumbnailClasses = classnames(
    'group  relative',
    { 'bg-gray-light': !avatar },
    {
      'max-w-[144px]': mini
    },
    { rounded },
    { 'cursor-pointer': !!onClick },
    className
  );

  const isHoverableDevice = useMediaQuery(UserSettingsQueries.isHoverableDevice);
  const [showSuccess, setShowSuccess] = useState<boolean>(isSuccess ?? false);
  const [showLoading, setShowLoading] = useState<boolean>(isLoading ?? false);
  const [hasLoadedSource, setHasLoadedSource] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAutoplaying, setIsAutoplaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<HLS | null>(null);
  const xsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);
  const smOnly = useMediaQuery(ScreenSizeQueries.smOnly);
  const [titleRef, isInView] = useInViewport<HTMLDivElement>({ offset: 0 });
  const isReducedMotionEnabled = useMediaQuery(UserSettingsQueries.hasReducedMotionDisabled);
  const shouldAutoplay = video?.autoPlay && shouldBeAllowedToAutoplayVideo;

  useEffect(() => {
    if (isSuccess) {
      setShowSuccess(isSuccess);
      setShowLoading(false);
      setTimeout(() => {
        setShowSuccess(false);
      }, 1000);
    }

    if (isLoading) {
      setShowLoading(isLoading);
    }

    if (isError) {
      setShowLoading(false);
    }
  }, [isSuccess, isLoading, isError]);

  useEffect(() => {
    if (isReducedMotionEnabled) {
      if (hlsRef.current && videoRef.current) {
        try {
          videoRef.current.pause();
        } catch (e) {
          if (e instanceof Error) {
            appInsights.trackException({ exception: e, severityLevel: 3 });
          }
        }
        if (isPlaying) {
          setIsPlaying(false);
        }
        if (isAutoplaying) {
          setIsAutoplaying(false);
        }
      }
    }
  }, [isReducedMotionEnabled, isPlaying, isAutoplaying]);

  useEffect(() => {
    if (HLS.isSupported() && !hlsRef.current) {
      hlsRef.current = new HLS({
        enableWorker: true,
        autoStartLoad: false,
        lowLatencyMode: true,
        backBufferLength: 90
      });

      hlsRef.current.on(HLS.Events.MEDIA_ATTACHED, () => {
        if (videoRef.current && !isPlaying && !isAutoplaying) {
          videoRef.current.muted = true;
          videoRef.current.play().catch(x => appInsights.trackException(x));
        }
      });

      hlsRef.current.on(HLS.Events.MEDIA_DETACHED, () => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      });

      hlsRef.current.on(HLS.Events.ERROR, (_, data) => {
        switch (data.type) {
          case HLS.ErrorTypes.MEDIA_ERROR:
            if (hlsRef.current) {
              hlsRef?.current.recoverMediaError();
            }
            break;
          default:
            break;
        }
      });
    }
  }, [isPlaying, isAutoplaying]);

  useEffect(() => {
    if (!hasLoadedSource && hlsRef.current && video?.url) {
      hlsRef.current.loadSource(video?.url);
      setHasLoadedSource(true);
    }
  }, [video, hasLoadedSource]);

  useEffect(() => {
    const autoPlayTimerID = setTimeout(() => {
      if (videoRef.current && isAutoplaying) {
        videoRef.current.pause();
        setIsAutoplaying(false);
        if (hlsRef.current) {
          hlsRef.current.detachMedia();
        }
      }
    }, 5000);
    return () => {
      clearTimeout(autoPlayTimerID);
    };
  }, [isAutoplaying]);

  useEffect(() => {
    if (!isHoverableDevice && videoRef.current && !isReducedMotionEnabled) {
      if (shouldAutoplay) {
        if (hlsRef.current) {
          if (isInView) {
            hlsRef.current.startLoad(-1);
            hlsRef.current.attachMedia(videoRef.current);
            setIsAutoplaying(true);
          } else {
            hlsRef.current.detachMedia();
            setIsAutoplaying(false);
            setIsPlaying(false);
          }
        } else if (
          video?.url &&
          videoRef.current &&
          videoRef.current.canPlayType('application/vnd.apple.mpegurl')
        ) {
          videoRef.current.src = video?.url;
          videoRef.current.addEventListener('canplay', function () {
            if (videoRef.current) {
              videoRef.current.muted = true;
              videoRef.current.play().catch(x => appInsights.trackException(x));
            }
          });
        }
      }
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.detachMedia();
      }
    };
  }, [isHoverableDevice, video, isReducedMotionEnabled, shouldAutoplay, isInView]);

  let finalRatio = ratio;
  if (!finalRatio) {
    if (mini) {
      finalRatio = xsOnly ? 1 : 16 / 9;
    } else {
      finalRatio = 3 / 2;
    }
  }

  const handleMouseEnter = useCallback(async () => {
    setIsHovered(true);
    if (video && videoRef.current && video?.playable !== false) {
      if (resumeFromProgress && progressValue !== undefined) {
        const progressRatio = progressValue ? progressValue / 100 : 0;
        const newTime = videoRef.current.duration * progressRatio;
        videoRef.current.currentTime = newTime;
        setIsPlaying(true);
      }

      if (hlsRef.current) {
        hlsRef.current.startLoad(-1);
        hlsRef.current.attachMedia(videoRef.current);
      } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = video.url;
        videoRef.current.addEventListener('canplay', function () {
          if (videoRef.current) {
            videoRef.current.muted = true;
            videoRef.current.play().catch(x => appInsights.trackException(x));
            setIsPlaying(true);
          }
        });
      }
    }
  }, [video, videoRef, resumeFromProgress, progressValue]);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
    if (video && videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
    if (hlsRef.current) {
      hlsRef.current?.stopLoad();
      hlsRef.current.detachMedia();
    }
  }, [video, videoRef]);

  const handleLoadedMetadata = useCallback(() => {
    if (video && videoRef.current && !isPlaying && videoRef.current.duration) {
      if (resumeFromProgress && progressValue !== undefined) {
        const progressRatio = progressValue ? progressValue / 100 : 0;
        const newTime = videoRef.current.duration * progressRatio;
        videoRef.current.currentTime = newTime;
        videoRef.current.pause();
      }
    }
  }, [resumeFromProgress, isPlaying, video, progressValue]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (video && videoElement && !isPlaying && resumeFromProgress) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      return () => {
        videoElement?.removeEventListener('loadedmetadata', handleLoadedMetadata);
        if (hlsRef.current) {
          hlsRef.current.detachMedia();
        }
      };
    }
  }, [videoRef, video, isPlaying, resumeFromProgress, handleLoadedMetadata]);

  const TouchHandler = useMemo(() => {
    const commonProps = {
      className:
        'absolute inset-0 z-20 rounded focus-visible:outline-2 focus-visible:outline-offset-2',
      'aria-label': icon?.label || ariaLabel,
      onMouseEnter: isHoverableDevice ? handleMouseEnter : undefined,
      onMouseLeave: isHoverableDevice ? handleMouseLeave : undefined,
      onFocus: isHoverableDevice ? handleMouseEnter : undefined,
      onBlur: isHoverableDevice ? handleMouseLeave : undefined
    };

    if (link?.url) {
      return (
        <RouterLink
          to={link.url}
          target={link.target || '_self'}
          onClick={link.onClick}
          {...commonProps}
        />
      );
    }

    if (onClick || renderButtonWrapper) {
      const button = (
        <button
          onClick={renderButtonWrapper ? undefined : onClick}
          disabled={showSuccess || showLoading}
          {...commonProps}
        />
      );
      return renderButtonWrapper ? renderButtonWrapper(button) : button;
    }

    return <span {...commonProps} />;
  }, [
    link,
    onClick,
    renderButtonWrapper,
    icon,
    ariaLabel,
    handleMouseEnter,
    handleMouseLeave,
    showSuccess,
    showLoading,
    isHoverableDevice
  ]);

  const imageContainerClasses = classnames(
    'relative flex h-4/6 items-center justify-center rounded-none',
    { 'w-4/6': !fitImage }
  );

  const imageClasses = classnames('h-full', 'transition duration-short ease-out', {
    'group-hover:scale-110': onClick
  });

  const thumbnailIconSmall = classnames('ml-auto', {
    'max-h-[16px] max-w-[16px]': xsOnly,
    hidden: smOnly && !fitImage
  });

  const [error, setOnError] = useState(true);

  const isVideoHidden = useMemo(() => {
    if (isReducedMotionEnabled) return true;
    if (!isHoverableDevice) {
      return !isAutoplaying;
    } else return !isHovered;
  }, [isHovered, isHoverableDevice, isAutoplaying, isReducedMotionEnabled]);

  return (
    <div className={thumbnailClasses}  
    data-component={"Thumbnail"}
    ref={titleRef}>
      <AspectRatio.Root
        ratio={finalRatio}
        className={classnames(
          'flex items-center justify-center overflow-hidden',
          {
            'pointer-events-none': video?.playable === false
          },
          { rounded }
        )}
      >
        {loading ? (
          <Shimmer className="size-full" />
        ) : (
          <>
            {fitImage ? (
              <ResponsiveImage
                aspectRatio={finalRatio}
                src={image?.url}
                alt={image?.alt}
                imageSizes={imageSizes}
                onLoad={() => {
                  setOnError(false);
                }}
                className={classnames(
                  'text-transparent opacity-0 transition-transform',
                  { absolute: mini },
                  {
                    'group-hover:scale-110': onClick,
                    'hover:scale-110': isHovered && onClick
                  },
                  { 'opacity-100': !error }
                )}
              />
            ) : (
              <div className={imageContainerClasses}>
                <img src={appendWebpFormat(image?.url)} alt={image?.alt} className={imageClasses} />
              </div>
            )}

            {listPosition && (
              <div
                className={classnames(
                  'pointer-events-none absolute z-1 size-full',
                  'before:absolute before:left-0 before:top-0 before:size-full before:bg-gradient-to-r before:from-black/20 before:to-transparent'
                )}
              >
                <span className="heading-2 absolute left-6 top-4 text-white">{listPosition}</span>
              </div>
            )}

            <div
              className={classnames('z-1', {
                'absolute inset-x-0 bottom-0 flex w-full items-end justify-between': !mini,
                absolute: mini,
                'p-6': card,
                'p-8': !card,
                hidden: video?.playable === false
              })}
            >
              {tag && !xsOnly && (
                <Tag {...tag} variant={tag.variant || 'transparent'} size="medium" />
              )}
              {dates && !xsOnly && (
                <div className="size-[72px]">
                  <DateTag dates={dates} />
                </div>
              )}
              {icon && (
                <div className={thumbnailIconSmall}>
                  <IconTag
                    variant="transparent"
                    icon={icon?.name}
                    label={!mini ? icon?.label : undefined}
                    isHovered={isHovered}
                    size={mini ? 'small' : 'medium'}
                  />
                </div>
              )}
            </div>

            {video?.url && (
              <div className={classnames('absolute inset-0', { hidden: isVideoHidden })}>
                <video
                  className="h-full bg-black object-cover"
                  src={video?.url}
                  ref={videoRef}
                  crossOrigin="anonymous"
                  autoPlay={video?.autoPlay && video?.playable !== false}
                  muted={video?.muted || true}
                />
              </div>
            )}

            {avatar && (
              <div className="flex w-full items-center justify-center">
                <AvatarComponent
                  {...avatar}
                  className="w-2/3"
                  hovered={isHovered}
                  imageSizes={imageSizes}
                />
              </div>
            )}

            {showProgress && !!progressValue && (
              <Progress.Root
                className={classnames(
                  'absolute inset-x-0 bottom-0 z-1 h-1 w-full cursor-pointer bg-black/60',
                  { hidden: video?.playable === false }
                )}
                aria-label={`Watched progress: ${progressValue}%`}
                value={progressValue}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Progress.Indicator
                  className={`h-full bg-red transition-all`}
                  style={{ width: `${progressValue}%` }}
                />
              </Progress.Root>
            )}
          </>
        )}
      </AspectRatio.Root>
      {onClick || renderButtonWrapper || link?.url || forceShowVideoPreview ? TouchHandler : null}
    </div>
  );
};

export default Thumbnail;
