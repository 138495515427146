import classnames from 'classnames';
import { forwardRef, type PropsWithChildren, type ReactNode, useRef } from 'react';
import * as PrimitiveAccordion from '@radix-ui/react-accordion';
import Icon from './Icon';

export interface AccordionItem {
  id?: string;
  header: JSX.Element | ReactNode;
  content: JSX.Element[] | ReactNode[];
}

interface AccordionProps {
  items: AccordionItem[];
  classNames?: string;
  headerClassNames?: string;
  reverseExpand?: boolean;
  type: 'single' | 'multiple';
  defaultValue?: string[];
  separator?: JSX.Element | ReactNode;
  onValueChange?: (value: string) => void;
}

interface AccordionHeaderProps extends PrimitiveAccordion.AccordionHeaderProps {
  reverseExpand?: boolean;
  headerId: string;
}

const AccordionContent = ({
  children
}: PropsWithChildren<PrimitiveAccordion.AccordionContentProps>) => {
  return (
    <PrimitiveAccordion.Content className="overflow-hidden data-[state=closed]:animate-accordionSlideUp data-[state=open]:animate-accordionSlideDown">
      {children}
    </PrimitiveAccordion.Content>
  );
};

const AccordionHeader = ({
  children,
  className,
  reverseExpand,
  headerId
}: AccordionHeaderProps) => {
  const headerClassNames = classnames('flex', className);
  const headerRef = useRef<HTMLDivElement>(null);

  return (
    <PrimitiveAccordion.Header className={headerClassNames} ref={headerRef}>
      <div
        id={headerId}
        className="relative inset-0 flex w-full items-center justify-between rounded-t-lg py-6"
        data-component={"Accordion"}
      >
        <PrimitiveAccordion.Trigger
          aria-labelledby={headerId}
          data-expand-click-area
          className={classnames(
            'absolute inset-0 z-0 flex w-full flex-col items-center data-[state=closed]:rounded-b-lg [&[data-state=open]>svg]:rotate-180',
            { 'data-[state=open]:rounded-t-none data-[state=open]:rounded-bl-lg': reverseExpand }
          )}
        >
          <Icon
            name="chevron-down"
            size="large"
            aria-hidden
            className="relative top-1/3 shrink-0 self-end transition-transform"
          />
        </PrimitiveAccordion.Trigger>
        {children}
      </div>
    </PrimitiveAccordion.Header>
  );
};

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  (
    {
      items,
      type,
      classNames,
      headerClassNames,
      separator,
      defaultValue,
      reverseExpand = false,
      onValueChange
    }: AccordionProps,
    ref
  ) => {
    const rootClassNames = classnames('w-full', classNames);

    const accordionItems = items.map(({ id, header, content: children }, index) => {
      const headerId = `accordion-header-${index}`;

      return (
        <div key={index}>
          <PrimitiveAccordion.Item id={id} value={index.toString()} className="overflow-hidden">
            {reverseExpand ? (
              <>
                <AccordionContent>{children}</AccordionContent>
                <AccordionHeader
                  className={headerClassNames}
                  reverseExpand={reverseExpand}
                  headerId={headerId}
                >
                  {header}
                </AccordionHeader>
              </>
            ) : (
              <>
                <AccordionHeader
                  className={headerClassNames}
                  reverseExpand={reverseExpand}
                  headerId={headerId}
                >
                  {header}
                </AccordionHeader>
                <AccordionContent>{children}</AccordionContent>
              </>
            )}
          </PrimitiveAccordion.Item>
          {separator}
        </div>
      );
    });

    if (type === 'multiple') {
      return (
        <PrimitiveAccordion.Root
          ref={ref}
          type="multiple"
          className={rootClassNames}
          defaultValue={defaultValue}
        >
          {accordionItems}
        </PrimitiveAccordion.Root>
      );
    }

    return (
      <PrimitiveAccordion.Root
        type="single"
        collapsible
        className={rootClassNames}
        onValueChange={onValueChange}
      >
        {accordionItems}
      </PrimitiveAccordion.Root>
    );
  }
);

export default Accordion;
