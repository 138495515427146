import Shimmer from '@/components/ui/Shimmer';
import BaseBox from '../../components/BaseBox';

interface LoadingProps {
  isMobile?: boolean;
}
export const Loading = ({ isMobile = false }: LoadingProps) => {
  const generateVariantGroup = (variantCount: number) => {
    return (
      <div className="flex flex-col gap-6">
        <div className="flex flex-row items-center gap-2">
          <Shimmer className="size-6 rounded-full" />
          <Shimmer className="h-4 w-[114px] lg:h-5" />
        </div>
        <div className="flex flex-col gap-3">
          {[...Array(variantCount)].map((val, index) => (
            <Shimmer key={`${val}-${index}`} className="h-12 w-full lg:h-[52px]" />
          ))}
        </div>
      </div>
    );
  };
  return (
    <BaseBox sticky={!isMobile}>
      <BaseBox.Content>
        <div className="flex size-full flex-col gap-10 pt-6 lg:gap-12 lg:pt-8">
          <div className="flex flex-col gap-6">{generateVariantGroup(2)}</div>
          <div className="flex flex-col gap-6">{generateVariantGroup(1)}</div>
          <div className="flex flex-col items-center gap-[25px] max-md:pt-2">
            <Shimmer className="h-[52px] w-full pb-1 lg:h-14" />
            <Shimmer className="h-[15px] w-[209px]" />
            <Shimmer className="h-[15px] w-[133px]" />
          </div>
        </div>
      </BaseBox.Content>
    </BaseBox>
  );
};

export default Loading;
