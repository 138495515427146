import React, { ComponentProps, ReactNode } from 'react';
import * as Select from '@radix-ui/react-select';
import MenuDrawer from './Menu/MenuDrawer';
import MenuItem, { MenuItemText } from './Menu/MenuItem';
import MenuContent from './Menu/MenuContent';
import MenuText from './Menu/MenuText';

type TDropdownSelectProps = {
  mobileFooterContent?: ComponentProps<typeof MenuContent>['mobileFooterContent'];
  options: (MenuItemText & {
    disabled?: boolean;
    value?: string | null;
    icon?: string;
  })[];
  value?: string | null;
  name?: string | null;
  onValueChange?: (value: string) => void;
  align?: ComponentProps<typeof Select.Content>['align'];
  button: (props: {
    onClick: () => void;
    isOpen: boolean;
    value: ReactNode;
    eyebrow?: string;
    placeholder?: string;
  }) => JSX.Element;
  menuStyle?: 'standalone' | 'form-field' | 'standalone-dark' | 'standalone-dark-unsorted';
  placeholder?: string;
  label?: string;
  valueClassName?: string;
  disabled?: boolean;
  smallClassName?: string;
  required?: boolean;
  showError?: (show: boolean) => void; //error is shown on the nested button
};

const SELECTED_ICON_NAME = 'checkmark';

const DropdownSelect = ({
  button,
  align = 'end',
  menuStyle = 'standalone',
  label,
  name,
  options,
  onValueChange,
  value,
  mobileFooterContent,
  placeholder = 'Choose an option...',
  valueClassName,
  disabled = false,
  smallClassName,
  required,
  showError
}: TDropdownSelectProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const isClosedFromMouse = React.useRef(false);
  const selectedOption = options?.find(o => o.value === value);

  const sortedOptions =
    menuStyle === 'standalone-dark'
      ? [...options]?.sort((a, b) =>
          ((a.label ?? '') as string).localeCompare((b.label ?? '') as string)
        )
      : options;

  const buttonTitle =
    (menuStyle === 'standalone-dark' || menuStyle === 'standalone-dark-unsorted') && placeholder ? (
      `${placeholder}${selectedOption?.label}`
    ) : (
      <Select.Value />
    );

  const handleOnCloseAutoFocus = React.useCallback((e: Event) => {
    /* Known issue in Radix Select where it can't tell the difference between dismissing via keyboard or mouse click https://github.com/radix-ui/primitives/issues/1803 */
    if (isClosedFromMouse.current === true) {
      e.preventDefault();
      isClosedFromMouse.current = false;
    }
  }, []);

  return (
    <Select.Root
      value={value || ''}
      defaultOpen={isOpen}
      open={isOpen}
      name={name || ''}
      onOpenChange={open => setIsOpen(open)}
      onValueChange={onValueChange}
      required={required}
    >
      <Select.Trigger asChild disabled={disabled}>
        {button({
          placeholder,
          onClick: () => setIsOpen(v => !v),
          isOpen,
          eyebrow: `${label}${required ? ' *' : ''}`,
          value: selectedOption && (
            <MenuText
              value={buttonTitle}
              eyebrow={selectedOption?.eyebrow}
              valueClassName={valueClassName}
              smallClassName={smallClassName}
            />
          )
        })}
      </Select.Trigger>
      <MenuDrawer onClose={() => setIsOpen(false)} isOpen={isOpen} useManualTransition>
        <Select.Content
          onCloseAutoFocus={handleOnCloseAutoFocus}
          position="popper"
          align={align}
          onPointerDownOutside={() => {
            isClosedFromMouse.current = true;
            return showError && required && showError(!selectedOption);
          }}
        >
          <MenuContent
            mobileTitle={placeholder}
            menuStyle={menuStyle}
            onClose={() => setIsOpen(false)}
            mobileFooterContent={mobileFooterContent}
          >
            <Select.Viewport>
              {sortedOptions?.map(option => {
                const isSelected = value === option.value;
                return (
                  <Select.Item key={option.value} value={option.value || ''} asChild>
                    <MenuItem
                      menuStyle={menuStyle}
                      icon={option.icon}
                      rightIcon={isSelected ? SELECTED_ICON_NAME : undefined}
                      label={<Select.ItemText>{option.label}</Select.ItemText>}
                      eyebrow={option.shortEyebrow || option.eyebrow}
                      disabled={option.disabled}
                      onClick={() => onValueChange?.(option.value || '')}
                      smallClassName={smallClassName}
                    />
                  </Select.Item>
                );
              })}
            </Select.Viewport>
          </MenuContent>
        </Select.Content>
      </MenuDrawer>
    </Select.Root>
  );
};

export default DropdownSelect;
