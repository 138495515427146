import type {
  CreditClassPage,
  CreditInfoRiverPage as CreditInfoRiverPageProps
} from '@/@types/content';
import Text from '@/components/cms/Text';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Icon from '@/components/ui/Icon';
import GlobalTabs, { TabTrigger } from '@/components/ui/Tab';
import withNav from '@/components/hocs/withNav';
import useCreditInfo from '@/components/blocks/CreditInfo/hooks/useCreditInfoHook';
import { CreditInfoContext } from '@/components/blocks/CreditInfo/context/CreditInfoContext';
import CreditClassContent from '@/components/blocks/CreditInfo/snippets/CreditClassContent';
import useQueryParams from '@/hooks/useQueryParams';
import { useDispatch } from 'react-redux';
import { setHeaderTheme } from '@/redux/slices/pageSlice';
import {
  CreditClassPageMap,
  riverPageChildrenCDProps
} from '@/components/blocks/CreditInfo/util/creditInfoConstants';
import classnames from 'classnames';
import Shimmer from '@/components/ui/Shimmer';
import Divider from '@/components/ui/Divider';
import { Helmet } from 'react-helmet';

const CreditInfoRiverPage: React.FC<CreditInfoRiverPageProps> = memo(props => {
  const { pageHeader, browserTitle } = props;
  const dispatch = useDispatch();
  const params = useQueryParams();
  const [activeTab, setActiveTab] = useState<TabTrigger>();
  const [tabContents, setTabContents] = useState<CreditClassPageMap>();
  const context = useContext(CreditInfoContext);

  const {
    children: _children,
    renderHeaderLinks,
    getDocumentTitle,
    isChildItemsLoading
  } = useCreditInfo({
    ...props,
    skipChildrenQuery: !!context.riverPageChildren?.length,
    childrenProperties: riverPageChildrenCDProps
  });

  const children = useMemo(() => _children as CreditClassPage[], [_children]);
  const trimTabId = useCallback((id: string) => id?.toLowerCase()?.replaceAll(' ', ''), []);

  useEffect(() => {
    dispatch(setHeaderTheme(2));

    return () => {
      dispatch(setHeaderTheme(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (children?.length && !tabContents) {
      const contents: CreditClassPageMap = {};
      children
        ?.filter(a => !a.hidePage)
        ?.map(a => {
          contents[trimTabId(a.routeSegment ?? '')] = a;
        });

      if (contents) {
        setTabContents(contents);

        if (!activeTab) {
          const _default =
            contents?.[params['activeTab']?.toLowerCase() ?? ''] ?? Object.values(contents)?.[0];
          setActiveTab({
            id: trimTabId(_default.routeSegment ?? ''),
            label: _default.secondaryHeader ?? _default?.routeSegment ?? ''
          });
        }
      }
    }
  }, [activeTab, children, params, tabContents, trimTabId]);

  const tabTriggers = useMemo(() => {
    if (children?.length) {
      return children.map((item, _) => {
        return {
          id: trimTabId(item.routeSegment ?? ''),
          label: item.secondaryHeader ?? item.name
        } as TabTrigger;
      });
    }
  }, [children, trimTabId]);

  const activeContent = useMemo(
    () => tabContents?.[activeTab?.id ?? ''],
    [activeTab?.id, tabContents]
  );

  const renderTab = useMemo(() => {
    return (
      activeContent && (
        <GlobalTabs
          tabsTriggers={tabTriggers ?? []}
          variant="browse"
          selectedValue={activeTab!}
          setSelectedValue={setActiveTab}
          elementType="h2"
          key={`tab-group`}
          container
        >
          <div className="container my-10">
            <CreditClassContent {...activeContent} />
          </div>
        </GlobalTabs>
      )
    );
  }, [activeContent, activeTab, tabTriggers]);

  const renderHeader = useCallback(
    (header: string, subheader?: string, subHeaderIcon?: string): JSX.Element => {
      return (
        <div className="mt-20 flex flex-col items-start justify-center">
          <Text propertyName="h1" element="h1" className="heading-1 my-0 mr-auto w-full">
            {header}
          </Text>
          {!!subheader && (
            <Text propertyName="p" element="p" className="inline-block align-middle">
              {!!subHeaderIcon && (
                <Icon className="mr-2 inline-block align-middle" name={subHeaderIcon} />
              )}
              <span className="inline-block align-middle">{subheader}</span>
            </Text>
          )}
        </div>
      );
    },
    []
  );

  const riverPageShimmer = useMemo(() => {
    return (
      <>
        <div className="mt-8 flex w-full flex-row flex-nowrap items-center justify-start">
          {Array.from({ length: 3 }).map((_, i) => {
            const classes = classnames('mt-2 h-10 w-28', {
              'mr-10': i != 5
            });
            return <Shimmer key={`shimmer-${i}`} className={classes} />;
          })}
        </div>
        <Shimmer className="my-8 h-16 w-full" />
        <div className="flex w-full flex-row items-end justify-between">
          <Shimmer className="h-8 w-1/2" />
          <Shimmer className="h-6 w-28" />
        </div>
        <Divider color="light" className="mb-6 mt-3" />
        <div className="mb-16 flex w-full flex-row flex-wrap justify-center">
          {Array.from({ length: 10 }).map((_, i) => {
            return <Shimmer key={`shimmer-${i}`} className="mt-2 h-20 w-full" />;
          })}
        </div>
      </>
    );
  }, []);

  return (
    <CreditInfoContext.Provider
      value={{ isLoading: isChildItemsLoading, riverPageChildren: children ?? [] }}
    >
      <Helmet>
        <title>
          {browserTitle
            ? `${browserTitle} - ${activeContent?.browserTitle ?? activeContent?.name}`
            : getDocumentTitle(pageHeader)}
        </title>
      </Helmet>
      <section className="relative bg-gray-lightest">
        <div className="container mt-[-160px] flex flex-row flex-wrap items-end justify-between pb-10 pt-[192px] md:flex-nowrap lg:mt-[-96px] lg:pt-[128px]">
          {!!pageHeader && renderHeader(pageHeader)}
          <div className="my-6 md:my-0">{renderHeaderLinks()}</div>
        </div>
      </section>
      {isChildItemsLoading ? <div className="container">{riverPageShimmer}</div> : renderTab}
    </CreditInfoContext.Provider>
  );
});

const CreditInfoRiverPageComponent = withNav(CreditInfoRiverPage);
CreditInfoRiverPageComponent.displayName = 'CreditInfoRiverPage';
export default CreditInfoRiverPageComponent;
