import { useEffect, useMemo } from 'react';
import useNudgeContext from './useNudgeContext';
import { MissingFieldLevelNudge, Nudge } from './types';
import {
  getEmptyStateNudge,
  getExpiredCardNudge,
  getMissingBlockLevelNudge,
  getMissingFieldLevelNudge,
  getMissingFields,
  getStaleNudge,
  isMissingFieldLevelNudge,
  parseMessages
} from './utils';
import { KeyListItem, SortKey } from './context';
import { NudgeAnalyticsContext } from '@/analytics/constants';

export const defaultSortKey = (a: KeyListItem, b: KeyListItem) => a.order - b.order;

export type UseNudgeProps<T extends Record<string, unknown>> = {
  messagesJSON?: string;
  sectionIndex?: number;
  sectionData?: T;
  sectionName: string;
  context: NudgeAnalyticsContext;
  onAccept?: () => void;
  skipField?: (field: string) => boolean;
  expiredCardsIds?: number[];
  timeUntilStale?: number;
  sortKey?: SortKey;
};

const useNudge = <T extends Record<string, unknown>>({
  messagesJSON,
  sectionIndex,
  sectionData,
  sectionName,
  context,
  onAccept,
  skipField,
  expiredCardsIds = [],
  timeUntilStale = 0,
  sortKey = defaultSortKey
}: UseNudgeProps<T>): Nudge | undefined => {
  const messages = useMemo(() => parseMessages(messagesJSON), [messagesJSON]);

  const nudge = useMemo(() => {
    if (sectionData === undefined) {
      return;
    }
    const emptyStateNudge = getEmptyStateNudge(
      messages,
      sectionData,
      sectionName,
      context,
      onAccept
    );
    if (emptyStateNudge) {
      return emptyStateNudge;
    }

    const expiredCardNudge = getExpiredCardNudge(
      messages,
      expiredCardsIds,
      sectionName,
      context,
      onAccept
    );
    if (expiredCardNudge) {
      return expiredCardNudge;
    }

    const staleNudge = getStaleNudge(
      messages,
      sectionData,
      sectionName,
      context,
      timeUntilStale,
      onAccept
    );
    if (staleNudge) {
      return staleNudge;
    }

    const missingFields = getMissingFields(messages, sectionData, skipField);
    if (missingFields.length === 1) {
      return getMissingFieldLevelNudge(messages, sectionName, context, missingFields[0], onAccept);
    }
    if (missingFields.length > 1) {
      return getMissingBlockLevelNudge(messages, sectionName, context, onAccept);
    }
  }, [
    sectionData,
    messages,
    timeUntilStale,
    expiredCardsIds,
    sectionName,
    context,
    onAccept,
    skipField
  ]);

  const { addKey } = useNudgeContext();
  useEffect(() => {
    if (nudge) {
      addKey(
        {
          key: nudge.key,
          order: sectionIndex || 0,
          field: isMissingFieldLevelNudge(nudge)
            ? (nudge as MissingFieldLevelNudge).field
            : undefined
        },
        sortKey
      );
    }
  }, [nudge, addKey, sectionIndex, sortKey]);

  return nudge;
};

export default useNudge;
