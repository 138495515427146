import { CreditType, ICertificateViewModel } from '@/@types/client-api';
import { ProgramInfo, RowInfo, RowInfoAction } from '../../Tables/CertificateTable';
import { format } from 'date-fns';
import DotSeparatedContent from '@/components/ui/DotSeparatedContent';

export const getRequestedDate = (certificate: ICertificateViewModel) => {
  return !!certificate.isRequestPending && !!certificate.requestDate
    ? new Date(certificate.requestDate)
    : undefined;
};

export const renderCertificateRows = (
  certificates: ICertificateViewModel[] | null | undefined,
  getAction: (certificate: ICertificateViewModel) => RowInfoAction
): ProgramInfo[] => {
  return (
    certificates?.map((certificate, i) => {
      const requestedDate = getRequestedDate(certificate);

      const action = getAction(certificate);

      return {
        rowInfo: [
          {
            key: i,
            itemInfo: getItemInfo(certificate),
            rowDataCertificates: getRowDataCertificates(certificate),
            action,
            requestedDate,
            programUrl: certificate.productUrl
          } as RowInfo
        ] as unknown as RowInfo[]
      } as ProgramInfo;
    }) ?? []
  );
};

export const getItemInfo = (certificate: ICertificateViewModel) => [
  <DotSeparatedContent content={[certificate.formatDisplayText, certificate.certificateSource]} />,
  certificate.webShortDescription!
];

export const getRowDataCertificates = (certificate: ICertificateViewModel) => ({
  date: [format(new Date(certificate.dateCompleted!), 'M/d/yyyy')],
  jurisdiction: [certificate.creditRegionDescription!],
  credits: certificate.isRequestPending
    ? ['Pending']
    : certificate.creditTypes?.map(getCreditTypeDescription) ?? []
});

const getCreditTypeDescription = (creditType: CreditType) => {
  const creditIssued = creditType.creditIssued
    ? (Math.trunc(creditType.creditIssued * 100) / 100).toFixed(2)
    : '';
  const description = creditType.creditTypeDescription ?? '';
  const separator = creditIssued && description ? ' ' : '';
  return `${creditIssued}${separator}${description}`;
};

export const getKeysAndBlobUris = (certificates: ICertificateViewModel[]) => {
  const keys: number[] = [];
  let blobUris: string[] = [];

  for (const item of certificates) {
    if (item.attachment_SK) {
      keys.push(item.attachment_SK);
    } else if (item.certificateBlobs?.length)
      blobUris = [
        ...blobUris,
        ...(item.certificateBlobs.filter(a => !!a.blobUri)?.map(a => a.blobUri ?? '') ?? [])
      ];
  }

  return { keys, blobUris };
};
