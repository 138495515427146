import { useAuth } from 'react-oidc-context';
import { readTcodeFromPLIPromoCookie } from '../usePLIPromoCookie';
import { useMemo } from 'react';
import { GetContentQuery } from '@/redux/api/contentDeliveryAPI';

export const useProductContentQuery = (enableWMSPrice?: string) => {
  const auth = useAuth();

  const query: GetContentQuery = useMemo(() => {
    const queryObj = {} as GetContentQuery;
    if (enableWMSPrice) queryObj['enableWMSPrice'] = enableWMSPrice;
    if (auth.isAuthenticated) queryObj['userId'] = auth.user?.profile.sub;
    const tCode = readTcodeFromPLIPromoCookie();
    if (tCode) queryObj['tCode'] = tCode;
    return queryObj;
  }, [auth.isAuthenticated, auth.user?.profile.sub, enableWMSPrice]);

  return query;
};
