import WeekDayHeader from './WeekDayHeader';
import { CSSProperties, useEffect, useMemo, useRef } from 'react';
import { getMonthsBetweenDates } from './CalendarRange';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { isSameMonth } from 'date-fns';
import Divider from '../Divider';
import CalendarHeader from './CalendarHeader';
import CalendarMonth from './CalendarMonth';

const Constants = {
  MonthHeightEstimated: 375
};

type MultipleMonthCalendarProps = {
  initialMonth: Date;
  selectedDate?: Date;
  onSelect?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
};

const MultipleMonthCalendar = ({
  initialMonth,
  selectedDate,
  onSelect,
  minDate,
  maxDate
}: MultipleMonthCalendarProps): JSX.Element => {
  const listRef = useRef<FixedSizeList>(null);

  const months = useMemo(() => getMonthsBetweenDates(minDate, maxDate), [maxDate, minDate]);

  useEffect(() => {
    const initialIndex = months.findIndex(month => isSameMonth(month, initialMonth));

    const timer = setTimeout(() => {
      if (initialIndex > 0) {
        listRef.current?.scrollToItem(initialIndex, 'start');
      }
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, [initialMonth, months]);

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const month = months[index];
    return (
      <div key={month.toISOString()} style={style} className="px-4">
        <div className="h-full pt-8">
          <CalendarHeader currentDate={month} minDate={minDate} maxDate={maxDate} />
          <CalendarMonth
            currentDate={month}
            selectedDate={selectedDate}
            onSelect={onSelect}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
        <Divider color="light" />
      </div>
    );
  };

  return (
    <div className="absolute size-full pb-16">
      <div className="mt-4 px-4">
        <WeekDayHeader />
        <Divider color="light" className="mt-4" />
      </div>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            height={height}
            itemCount={months.length}
            itemSize={Constants.MonthHeightEstimated}
            width={width}
            ref={listRef}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
};

export default MultipleMonthCalendar;
