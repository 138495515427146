import { isPrerelease, PublicationVariation } from '@/hooks/PDP/usePublicationCatalogRelations';
import { getFormatedMonthYear } from '@/lib/helpers/date';
import { ReactNode } from 'react';
import Link from '../Link';

export interface IPublicationDetailLabels {
  publicationDate: string;
  lastUpdated: string;
  volumes: string;
  isbn: string;
  pageCount: string;
  itemNo: string;
  download: string;
}
interface IPublicationDetailsProps {
  heading: string;
  labels: IPublicationDetailLabels;
  variant: PublicationVariation;
  lastUpdatedFileUrl?: string;
  linkOnClick?: () => void;
}

interface IPublicationDetailProp {
  label: string;
  value: string;
  link?: ReactNode;
}
const PublicationDetail = ({ label, value, link }: IPublicationDetailProp) => {
  return (
    <span className="flex items-start gap-4">
      <span className="text-2-bold w-[110px]">{label}</span>
      <span className="flex flex-row flex-wrap gap-1">
        <span className="text-2">{value}</span>
        {link}
      </span>
    </span>
  );
};
const PublicationDetails = ({
  heading,
  labels,
  variant,
  linkOnClick,
  lastUpdatedFileUrl
}: IPublicationDetailsProps) => {
  const detailsId = 'details';
  const isPreorder = isPrerelease(variant);
  const publicationDateBaseStr = getFormatedMonthYear(variant.publicationDate ?? '', false);
  const link = lastUpdatedFileUrl ? (
    <Link onClick={linkOnClick} href={lastUpdatedFileUrl} target="_blank" text={labels.download} />
  ) : undefined;
  return (
    <div id={detailsId} className="container module-spacing-pdp flex flex-col gap-8 max-sm:gap-6"
      data-component={"PublicationDetail"}
    >
      <h3 className="heading-5">{heading}</h3>
      <div className="grid max-w-[639px] grid-cols-1 gap-[14px] sm:grid-cols-2 sm:gap-4 lg:gap-x-[22px] lg:gap-y-[14px]">
        <PublicationDetail
          label={labels.publicationDate}
          value={`${publicationDateBaseStr}${isPreorder ? ' (Estimated)' : ''}`}
        />

        <PublicationDetail label={labels.isbn} value={variant?.isbn ?? ''} />

        {isPreorder ? (
          <></>
        ) : (
          <PublicationDetail
            label={labels.lastUpdated}
            value={getFormatedMonthYear(variant.lastUpdatedDate ?? '', false)}
            link={link}
          />
        )}

        {variant?.pageCount?.toString() ? (
          <PublicationDetail
            label={labels.pageCount}
            value={variant?.pageCount?.toString() ?? ''}
          />
        ) : (
          <></>
        )}

        {variant?.volumeCount?.toString() ? (
          <PublicationDetail label={labels.volumes} value={variant.volumeCount?.toString() ?? ''} />
        ) : (
          <></>
        )}

        <PublicationDetail label={labels.itemNo} value={variant?.code ?? ''} />
      </div>
    </div>
  );
};

export default PublicationDetails;
