import Input, { InputError, InputProps } from '@/components/ui/Form/Input';
import { useCallback, useMemo } from 'react';
import { ValidationOptions, isValidValue } from '@/utils/validations';

export type TextInputProps = Omit<InputProps, 'id' | 'name'> &
  ValidationOptions & {
    errorValidationEnabled?: boolean;
    forceInvalid?: boolean;
    name?: string;
    readOnly?: boolean;
    disabledEffect?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  };

const TextInput = ({
  variant = 'line',
  value,
  label,
  placeholder,
  type,
  onChange,
  maxLength,
  errors,
  className,
  required = false,
  disabled = false,
  acceptLetters,
  acceptNumbers,
  acceptSpaces,
  acceptSpecialChars,
  allowedChars,
  errorValidationEnabled = true,
  forceInvalid = false,
  name,
  readOnly,
  disabledEffect,
  onClick
}: TextInputProps): JSX.Element => {
  const id = label.replace(/ /g, '').toLowerCase();

  const handleOnChange = useCallback(
    (newValue: string) => {
      if (
        !newValue ||
        isValidValue(newValue, {
          acceptLetters,
          acceptNumbers,
          acceptSpaces,
          acceptSpecialChars,
          allowedChars
        })
      ) {
        onChange?.(newValue);
      }
    },
    [acceptLetters, acceptNumbers, acceptSpaces, acceptSpecialChars, allowedChars, onChange]
  );
  const allErrors = useMemo((): InputError[] => {
    const result: InputError[] = [];
    if (errorValidationEnabled) {
      if (type !== 'number') {
        required && result.push({ message: 'Required', match: 'valueMissing' });
      }
      if (type === 'email') {
        result.push({ message: 'Please enter a valid email', match: 'typeMismatch' });
      }
      if (type === 'tel') {
        required &&
          result.push({ message: 'Please enter a valid phone', match: value => value.length < 14 });
      }
      errors && result.push(...errors);
    }
    return result;
  }, [errorValidationEnabled, errors, required, type]);

  return (
    <Input
      variant={variant}
      label={`${label}${required ? ' *' : ''}`}
      placeholder={placeholder ?? label}
      name={name ?? id}
      id={id}
      value={value}
      onChange={handleOnChange}
      maxLength={maxLength}
      required={required}
      disabled={disabled}
      type={type}
      errors={allErrors}
      className={className}
      forceInvalid={forceInvalid}
      readOnly={readOnly}
      onClick={onClick}
      disabledEffect={disabledEffect}
    />
  );
};

export default TextInput;
