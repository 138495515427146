import { CreditJurisdiction, UserLicense } from '@/@types/client-api';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useGetCreditJurisdictionsQuery } from '@/redux/api/client/cle';
import { isDefined } from '@/lib/helpers/isDefined';

const LO_LICENSE_KEY = 'LO_Licenses';
export interface LoggedOutLicenseStruct {
  licenses: UserLicense[];
  addLicense: (license: UserLicense) => void;
  removeLicense: (creditRegionSk: number) => void;
  availableCreditJurisdictions: CreditJurisdiction[];
  relevantCreditsRegionParam?: string[];
}
export const useLoggedOutLicenses = (): LoggedOutLicenseStruct => {
  const [licenses, setLicenses] = useState<UserLicense[]>([]);

  const relevantCreditsRegionParam = useMemo(() => {
    return licenses?.map(l => l.creditRegion)?.filter(isDefined);
  }, [licenses]);

  const { data: creditJurisdictions } = useGetCreditJurisdictionsQuery();

  const availableCreditJurisdictions = useMemo(() => {
    return (
      creditJurisdictions?.filter(
        creditJurisdiction =>
          !licenses?.find(license => license.creditRegion_SK === creditJurisdiction.creditRegion_SK)
      ) ?? []
    );
  }, [creditJurisdictions, licenses]);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // Read from cookie
    const cookieVal = Cookies.get(LO_LICENSE_KEY);
    if (!cookieVal) return;
    const licenses = JSON.parse(cookieVal);
    if ((licenses as UserLicense[])?.length) setLicenses(licenses);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!initialized) return;
    // Write to cookie
    const cookieVal = JSON.stringify(licenses);
    Cookies.set(LO_LICENSE_KEY, cookieVal, { expires: 30 });
  }, [initialized, licenses]);

  const addLicense = useCallback(
    (license: UserLicense) => {
      const licenseMinified: UserLicense = {
        creditRegion_SK: license.creditRegion_SK,
        // unfortunately the AddLicense code i am re-using is assigning/using creditRegion for the wrong intents,
        // relevant credits API expects the CreditRegion's CMS id / WMS PK (ex: "CLE-AL")
        creditRegion: creditJurisdictions?.find(
          cj => cj.creditRegion_SK === license.creditRegion_SK
        )?.id
      };
      setLicenses([...licenses, licenseMinified]);
    },
    [creditJurisdictions, licenses]
  );

  const removeLicense = useCallback(
    (creditRegionSk: number) => {
      const updatedLicenses = [
        ...(licenses?.filter(l => l.creditRegion_SK !== creditRegionSk) ?? [])
      ];
      setLicenses(updatedLicenses);
    },
    [licenses]
  );

  return {
    licenses,
    addLicense,
    removeLicense,
    availableCreditJurisdictions,
    relevantCreditsRegionParam
  };
};
