import { useState } from 'react';
import CreditRequestedTooltip, {
  CreditRequestedTooltipProps
} from '@/components/ui/Tooltip/CreditRequestedTooltip';

type SnippetCreditRequestedTooltip = Omit<CreditRequestedTooltipProps, 'isOpen' | 'onOpenChange'>;

const SnippetCreditRequestedTooltip = ({
  creditRequestDate,
  children
}: SnippetCreditRequestedTooltip) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <CreditRequestedTooltip
      creditRequestDate={creditRequestDate}
      isOpen={isOpen}
      onOpenChange={setOpen}
    >
      {children}
    </CreditRequestedTooltip>
  );
};

export default SnippetCreditRequestedTooltip;
