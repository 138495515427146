import classnames from 'classnames';

import SegmentList from '@/components/ui/Snippet/SegmentList/SegmentList';
import DotSeparatedContent from '../../DotSeparatedContent';
import type { SegmentListProps } from '@/components/ui/Snippet/SegmentList/SegmentList';

interface ComposedSegmentList {
  liveSegments?: SegmentListProps;
  segments?: SegmentListProps;
}

const ComposedSegmentList = ({ liveSegments, segments }: ComposedSegmentList) => {
  const { featuredSegments: watchedLiveSegments = [] } = liveSegments || {};
  const {
    featuredSegments: onDemandSegments = [],
    totalSegments: totalOnDemandSegments,
    onLaunchMediaPlayer = () => {}
  } = segments || {};

  return (
    <div>
      {!!onDemandSegments.length && (
        <>
          <div className="mb-6">
            <DotSeparatedContent
              marginX={0}
              content={[
                <span className="text-2 text-gray-dark">On-demand</span>,
                <span className="text-2 text-gray-dark">
                  {totalOnDemandSegments}&nbsp;
                  {totalOnDemandSegments && totalOnDemandSegments > 1 ? 'segments' : 'segment'}
                </span>
              ]}
            />
          </div>
          <SegmentList
            featuredSegments={onDemandSegments}
            programPk={segments?.programPk ?? ''}
            isProgramSegmentList
            onLaunchMediaPlayer={onLaunchMediaPlayer}
            segmentListHeaderTitle=""
          />
        </>
      )}
      {!!watchedLiveSegments?.length && (
        <div className={classnames({ 'mt-10': onDemandSegments.length })}>
          <div className="mb-6">
            <DotSeparatedContent
              marginX={0}
              content={[
                <span className="text-2 text-gray-dark">Live</span>,
                <span className="text-2 text-gray-dark">
                  You watched {watchedLiveSegments.length}&nbsp;
                  {watchedLiveSegments.length > 1 ? 'segments' : 'segment'} of this program while it
                  was live
                </span>
              ]}
            />
          </div>
          <SegmentList
            featuredSegments={watchedLiveSegments}
            programPk={liveSegments?.programPk ?? ''}
            isProgramSegmentList
            /* The segments from the 'watched live' segment list are not supposed to be launchable */
            onLaunchMediaPlayer={null}
            segmentListHeaderTitle=""
            seeMoreSegments={false}
          />
        </div>
      )}
    </div>
  );
};

export default ComposedSegmentList;
