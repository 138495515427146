import libraryEndpoints, { LibraryRequest, useGetLibraryQuery } from '@/redux/api/client/library';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParamsStable } from '@/hooks/useSearchParams';
import {
  libraryFailure,
  libraryLoading,
  librarySuccess,
  loadMore
} from '@/redux/slices/librarySlice';
import { selectLibrary } from '@/redux/selectors/librarySelectors';
import { getInitialLibraryRequest, libraryDataToSearchParams } from '../utils/libraryParams';
import { libraryDataToLibraryRequest } from '../utils/libraryRequest';
import { UnknownAction } from '@reduxjs/toolkit';

const useLibrary = () => {
  const dispatch = useDispatch();
  const { data: storeData } = useSelector(selectLibrary);
  const { searchParams, setSearchParams } = useSearchParamsStable();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const lastLibraryRequest = useRef<LibraryRequest>();

  const libraryRequest: LibraryRequest = {
    ...(storeData ? libraryDataToLibraryRequest(storeData) : getInitialLibraryRequest(searchParams))
  };

  lastLibraryRequest.current = libraryRequest;

  const { isLoading, error, isFetching, data } = useGetLibraryQuery(libraryRequest);

  // update url params
  useEffect(() => {
    if (storeData) {
      setSearchParams(existing => libraryDataToSearchParams(existing, storeData), {
        preventScrollReset: true
      });
    }
  }, [storeData, setSearchParams]);

  useEffect(() => {
    if (isLoading) {
      dispatch(libraryLoading('loading'));
    } else if (isFetching) {
      dispatch(libraryLoading('fetching'));
    } else {
      dispatch(libraryLoading('none'));
    }
  }, [isLoading, isFetching, dispatch]);

  useEffect(() => {
    setIsLoadingMore(false);
    error && dispatch(libraryFailure(error));
  }, [error, dispatch]);

  useEffect(() => {
    setIsLoadingMore(false);

    if (data && lastLibraryRequest.current) {
      const libraryRequestFromNewData = libraryDataToLibraryRequest(data);

      // The data we got back is different from our request (eg, BE automatically changed content tabs)
      // To avoid a new extra request we should upsert a cache entry with the new data
      if (
        JSON.stringify(lastLibraryRequest.current) !== JSON.stringify(libraryRequestFromNewData)
      ) {
        dispatch(
          libraryEndpoints.util.upsertQueryData(
            'getLibrary',
            libraryRequestFromNewData,
            data
          ) as unknown as UnknownAction
        );
      }

      dispatch(librarySuccess({ data }));
    }
  }, [data, dispatch]);

  const onLoadMore = useCallback(() => {
    dispatch(loadMore());
    setIsLoadingMore(true);
  }, [dispatch]);

  const refetchLibrary = useCallback(() => {
    if (lastLibraryRequest.current) {
      const action = libraryEndpoints.endpoints.getLibrary.initiate(lastLibraryRequest.current, {
        forceRefetch: true
      });
      dispatch(action as unknown as UnknownAction);
    }
  }, [dispatch]);

  return {
    isLoadingMore,
    onLoadMore,
    refetchLibrary
  };
};

export default useLibrary;
