import type { InteractiveEditorialBlockItem as InteractiveEditorialBlockItemProps } from '@/@types/content';
import Text from '@/components/cms/Text';
import * as Collapsible from '@radix-ui/react-collapsible';
import classnames from 'classnames';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';

interface InteractiveEditorialItemProps extends InteractiveEditorialBlockItemProps {
  isOpen?: boolean;
  onOpenChange?: () => void;
  isLastItem?: boolean;
}

const InteractiveEditorialItem: React.FC<InteractiveEditorialItemProps> = ({
  heading,
  copy,
  video,
  isOpen,
  onOpenChange,
  isLastItem
}) => {
  const collapsibleTriggerClasses = classnames(
    'heading-5 text-left text-gray hover:text-red focus:text-red data-[state=open]:mb-4 data-[state=open]:text-black data-[state=open]:hover:text-black',
    {
      'data-[state=open]:!pointer-events-none': !isEditOrPreviewMode()
    }
  );

  return (
    <Collapsible.Root
      open={isOpen || isEditOrPreviewMode()}
      onOpenChange={onOpenChange}
      className={classnames({
        'mb-8 border-b border-b-gray-light pb-8 lg:mb-10 lg:pb-10': !isLastItem
      })}
    >
      {isEditOrPreviewMode() ? (
        <div className="heading-5 mb-4 text-left text-black">
          <h2 data-epi-edit="Heading">{heading}</h2>
        </div>
      ) : (
        <Collapsible.Trigger className={collapsibleTriggerClasses}>
          <h2>{heading}</h2>
        </Collapsible.Trigger>
      )}
      <Collapsible.Content className="opacity-0 transition-opacity data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown data-[state=open]:opacity-100">
        <Text element="p" propertyName="Copy" className="text-1 font-normal text-gray-dark">
          {copy}
        </Text>
        {isEditOrPreviewMode() && (
          <p className="text-1 mt-2 text-gray-dark">
            <strong>Video Url: </strong>
            https://player.vimeo.com/video/
            <span data-epi-edit="Video">{video}</span>
          </p>
        )}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default InteractiveEditorialItem;
