import { AnalyticsEventName } from './constants';
import { trackEvent } from './utils';

interface TrackableItem {
  gA4EventType?: string;
  gA4EventParameters?: string;
}

export const trackViewFooterLinkEvent = (value: string) => {
  if (value) {
    trackEvent({
      eventName: AnalyticsEventName.ViewFooterLink,
      parameters: {
        value
      }
    });
  }
};

export const trackLinkEvent = (item: unknown): void => {
  if (typeof item === 'object' && item !== null && 'gA4EventType' in item) {
    const typedItem = item as TrackableItem;
    const { gA4EventType, gA4EventParameters } = typedItem;

    if (
      gA4EventType &&
      Object.values(AnalyticsEventName).includes(gA4EventType as AnalyticsEventName)
    ) {
      trackEvent({
        eventName: gA4EventType as AnalyticsEventName,
        parameters: { ...JSON.parse(gA4EventParameters || '{}') }
      });
    }
  }
};
