import { ComponentProps, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import * as Collapsible from '@radix-ui/react-collapsible';

import Thumbnail, { ThumbnailProps } from '@/components/ui/Thumbnail';
import Button, { ButtonProps } from '@/components/ui/Buttons/Button';
import SnippetContent, { SnippetContentProps } from '@/components/ui/Snippet/SnippetContent';
import MenuItem from '@/components/ui/Menu/MenuItem';
import Icon from '@/components/ui/Icon';
import { EyebrowProps } from '@/components/ui/Eyebrow/Eyebrow';
import SnippetButtons from '@/components/ui/Snippet/SnippetButtons';

import SnippetExpandedContent, { SnippetExpandedContentProps } from './SnippetExpandedContent';
import { variantMappings } from './snippetMapping';
import { ISearchResultItem, ItemAnalyticsModel } from '@/@types/client-api';
import { SearchModalPanelState } from '../SearchResults/hooks/useSearchModalPanelState';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { ActionStatus } from '../SearchResults/constants';
import { SnippetContext } from '../SearchResults/Snippets/types';

export type SnippetPrimaryStatus =
  | 'credits-available'
  | 'credits-requested'
  | 'certificates-available'
  | 'not-purchased'
  | 'not-registered'
  | 'registered'
  | 'expired';

export type SnippetSecondaryStatus = 'saved-to-library';

export type SnippetVariants =
  | 'on-demand'
  | 'upcoming-live'
  | 'live-expired'
  | 'publication'
  | 'course-handbook'
  | 'live'
  | 'podcast'
  | 'faculty';

export type SnippetButtonProps = Omit<ButtonProps, 'loading'> & {
  actionStatus?: ActionStatus;
  onClick: () => void;
  className?: string;
  href?: string;
  target?: string;
  renderButtonWrapper?: (button: JSX.Element) => JSX.Element;
  itemAnalyticsModel?: ItemAnalyticsModel;
};

export type SnippetActions = {
  launch: () => void;
  addToLibrary: () => void;
  removeFromLibrary: (variantPks?: string[]) => void;
  addToCalendar: () => void;
  buy: () => void;
  seeSchedule: () => void;
  register: () => void;
  viewRegistration: () => void;
  accessMaterials: () => void;
  changeOrCancelRegistration: () => void;
};

export interface SnippetProps extends SnippetContentProps {
  expandedContent?: SnippetExpandedContentProps;
  buttons?: SnippetButtonProps[];
  primaryStatus?: SnippetPrimaryStatus | null;
  secondaryStatus?: SnippetSecondaryStatus | null;
  context?: SnippetContext;
  menuItems?: (ComponentProps<typeof MenuItem> | { separator: true })[];
  thumbnail?: ThumbnailProps;
  variant?: SnippetVariants;
  actions?: SnippetActions;
  explanation?: ISearchResultItem['explanation'];
  score?: string;
  modalPanelState?: SearchModalPanelState;
  shouldBeAllowedToAutoplayVideo?: boolean;
  itemAnalyticsModel?: ItemAnalyticsModel;
  resultItem?: ISearchResultItem;
}

const Thumb = ({
  thumbnail,
  className,
  variant,
  loading,
  shouldBeAllowedToAutoplayVideo
}: {
  thumbnail?: ThumbnailProps;
  className?: string;
  variant?: SnippetVariants;
  loading?: boolean;
  shouldBeAllowedToAutoplayVideo?: boolean;
}) => {
  return (
    <Thumbnail
      {...thumbnail}
      className={className}
      loading={loading}
      fitImage={variant !== 'publication'}
      imageSizes={{ sm: '34vw', lg: '25vw' }}
      shouldBeAllowedToAutoplayVideo={shouldBeAllowedToAutoplayVideo}
    />
  );
};

const Snippet = ({
  pk,
  title,
  eyebrows: eyebrowsProp = [],
  metadata,
  content,
  link,
  thumbnail,
  expandedContent,
  primaryStatus,
  secondaryStatus,
  menuItems,
  context,
  variant,
  buttons,
  loading = false,
  actions,
  explanation,
  score,
  modalPanelState,
  shouldBeAllowedToAutoplayVideo,
  itemAnalyticsModel,
  resultItem
}: SnippetProps) => {
  const [expanded, setExpanded] = useState(false);
  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

  const eyebrows: EyebrowProps[] | undefined = useMemo(() => {
    if (variant === 'podcast') {
      return eyebrowsProp.map(eyebrow => {
        if (eyebrow.label.match(/video/i)) {
          return { ...eyebrow, label: stateSiteLabels?.videoPodcastLabel || eyebrow.label };
        } else {
          return { ...eyebrow, label: stateSiteLabels?.audioPodcastLabel || eyebrow.label };
        }
      });
    }
    return eyebrowsProp;
  }, [
    eyebrowsProp,
    stateSiteLabels?.audioPodcastLabel,
    stateSiteLabels?.videoPodcastLabel,
    variant
  ]);

  const mapEyebrows: EyebrowProps[] = useMemo(() => {
    const out = (
      (variant === 'publication' || variant === 'podcast') && eyebrows.length > 0
        ? []
        : variantMappings[variant as SnippetVariants] || []
    ).concat(eyebrows || []);

    if (score) {
      out.unshift({
        label: `Score: ${score}`,
        icon: 'info',
        className: 'bg-gold'
      });
    }

    return out;
  }, [variant, eyebrows, score]);

  const toggleExpanded = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  const handleSetExpanded = useCallback(
    (value: boolean) => {
      if (explanation && value) console.info(explanation);
      setExpanded(value);
    },
    [explanation]
  );

  return (
    <Collapsible.Root open={expanded} onOpenChange={handleSetExpanded}>
      <div
        className="gridPLI grid-rows-[repeat(3,auto)] sm:grid-rows-[repeat(2,auto)]"
        data-component={'Snippet'}
      >
        <div className="col-span-full row-span-1 sm:col-span-5 md:col-span-8">
          <SnippetContent
            pk={pk}
            title={title}
            eyebrows={mapEyebrows}
            metadata={metadata}
            link={link}
            content={content}
            loading={loading}
            context={context}
            itemAnalyticsModel={itemAnalyticsModel}
            resultItem={resultItem}
          />
          {expandedContent && context !== 'library' && !loading && (
            <>
              <Collapsible.Content className="overflow-hidden  data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown">
                <SnippetExpandedContent {...expandedContent} />
              </Collapsible.Content>

              <Collapsible.Trigger asChild>
                <button
                  onClick={toggleExpanded}
                  className={classnames('text-2 flex items-center gap-1 pt-6 font-bold md:hidden')}
                  aria-expanded={expanded}
                >
                  {expanded ? 'See less' : 'See more'}
                  <Icon size="small" name={expanded ? 'chevron-up' : 'chevron-down'} />
                </button>
              </Collapsible.Trigger>
            </>
          )}
        </div>
        <div className="col-span-full row-span-1 sm:col-span-3 md:col-span-4 md:row-span-2 lg:col-start-10 lg:col-end-13">
          <Thumb
            thumbnail={thumbnail}
            className="mt-6 w-full sm:mt-0"
            loading={loading}
            variant={variant}
            shouldBeAllowedToAutoplayVideo={shouldBeAllowedToAutoplayVideo}
          />
        </div>
        <div className="col-span-full row-span-1 sm:col-span-8 md:col-span-8">
          <div className="mt-10 flex flex-col gap-2 whitespace-nowrap md:flex-row md:gap-3">
            <SnippetButtons
              actions={actions}
              modalPanelState={modalPanelState}
              primaryStatus={primaryStatus}
              secondaryStatus={secondaryStatus}
              context={context}
              videoProgressValue={thumbnail?.progressValue}
              variant={variant}
              link={link}
              menuItems={menuItems}
              loading={loading}
              moreContentButton={
                expandedContent &&
                context !== 'library' && (
                  <Collapsible.Trigger asChild>
                    <Button
                      size="short"
                      color="outline-black"
                      label={expanded ? 'See less' : 'See more'}
                      iconRight={expanded ? 'chevron-up' : 'chevron-down'}
                      onClick={() => setExpanded(!expanded)}
                      className="max-md:hidden"
                      aria-expanded={expanded}
                    />
                  </Collapsible.Trigger>
                )
              }
              buttons={buttons}
            />
          </div>
        </div>
      </div>
    </Collapsible.Root>
  );
};

export default Snippet;
