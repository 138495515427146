import { IContent } from '@/@types/cms';
import ProgramOverview from '@/components/ui/PDP/ProgramOverview';
import { PDPSectionLabels } from '@/components/ui/PDP/labels';
import { useOverview } from '@/hooks/PDP/useOverview';
import {
  LiveProgramCatalogRelations,
  ProgramVariation,
  WebSegmentVariation
} from '@/hooks/PDP/useProgramCatalogRelations';

interface IPDPOverviewLayoutBlockProps {
  variant: ProgramVariation | WebSegmentVariation;
  catalogRelations: LiveProgramCatalogRelations;
  mainContentArea?: Array<IContent>;
}

const IPDPOverviewLayoutBlock: React.FC<IPDPOverviewLayoutBlockProps> = ({
  variant,
  catalogRelations,
  mainContentArea
}) => {
  const { isSegment, isLegacyOverview, defaultOrder, overviewSegmentDict, content } = useOverview({
    variant,
    catalogRelations
  });

  return (
    <ProgramOverview
      heading={isSegment ? PDPSectionLabels.segmentDescription : PDPSectionLabels.overview}
      content={content}
      isSegment={isSegment}
      overviewSegmentDict={overviewSegmentDict}
      defaultOrder={defaultOrder}
      isLegacyOverview={isLegacyOverview}
      mainContentArea={mainContentArea}
      variant={variant}
      catalogRelations={catalogRelations}
    />
  );
};

export default IPDPOverviewLayoutBlock;
