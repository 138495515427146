import { Fragment, useEffect, useRef, useState } from 'react';
import { TileCarouselBlock as TileCarouselBlockProps } from '@/@types/content';
import classnames from 'classnames';
import Block from '@/components/cms/Block';
import Text from '@/components/cms/Text';
import * as Collapsible from '@radix-ui/react-collapsible';
import { type Swiper, type SwiperOptions } from 'swiper/types';
import Link from '@/components/ui/Link';
import throttle from 'lodash/throttle';
import { SwiperContainer, register } from 'swiper/element/bundle';
import RoundedIcon from '@/components/ui/RoundedIcon';
import ContentArea from '@/components/cms/ContentArea';
import { v4 as uuid4 } from 'uuid';
import TileBlock from '@/components/blocks/Tiles/TileBlock';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Panel from '@/components/ui/Panel';
import MembersRowCollapsible from './MembersRowCollapsible';
import MembersRowNonCollapsible from './MembersRowNonCollapsible';
import Divider from '@/components/ui/Divider';

enum Layout {
  OneRow = '1-rows',
  TwoRows = '2-rows'
}

enum HeadingOption {
  Default = 'default',
  Small = 'small-heading'
}

interface IMembershipGroup {
  groupClassification: string;
  members: string;
}

const TileCarouselBlock: React.FC<TileCarouselBlockProps> = ({
  contentLink,
  layout = Layout.OneRow,
  heading,
  items = [],
  seeAllLink,
  membershipGroups,
  membershipGroupHeading,
  headingOption
}: TileCarouselBlockProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [groupIndexes, setGroupIndexes] = useState<string[]>([]);
  const [swiper, setSwiper] = useState<Swiper | null>(null);
  const [isPrevVisible, setIsPrevVisible] = useState(false);
  const [isNextVisible, setIsNextVisible] = useState(false);

  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  const navPrevId = `nav-prev-${uuid4()}`;
  const navNextId = `nav-next-${uuid4()}`;

  const sm = useMediaQuery(ScreenSizeQueries.sm);
  const md = useMediaQuery(ScreenSizeQueries.md);

  const handleGroupIndexes = (index: string) => {
    if (groupIndexes.includes(String(index))) {
      groupIndexes.splice(groupIndexes.indexOf(String(index)), 1);
      setGroupIndexes([...groupIndexes]);
    } else {
      setGroupIndexes([...groupIndexes, String(index)]);
    }
  };

  useEffect(() => {
    if (!swiperRef.current) return;
    register();

    const params: SwiperOptions = {
      slidesPerGroupAuto: true,
      maxBackfaceHiddenSlides: 1,
      spaceBetween: md ? 24 : 16,
      watchSlidesProgress: true,
      navigation: {
        enabled: true,
        prevEl: `#${navPrevId}`,
        nextEl: `#${navNextId}`
      },
      autoHeight: false,
      on: {
        init(swiper) {
          setSwiper(swiper);
        }
      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
          slidesPerGroup: 1
        },
        889: {
          slidesPerView: 3,
          slidesPerGroup: 3
        }
      }
    };

    Object.assign(swiperRef.current, params);

    const currentSwiper = swiperRef.current as SwiperContainer;
    currentSwiper.initialize();
  }, [navNextId, navPrevId, md]);

  const onChange = (value: boolean) => {
    setIsOpen(value);
  };

  const handleMouseMove = throttle((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const container = containerRef.current as HTMLDivElement | null;
    if (container) {
      const rect = container.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;

      if (swiper?.isBeginning) {
        setIsPrevVisible(false);
        setIsNextVisible(true);
      } else if (swiper?.isEnd) {
        setIsPrevVisible(true);
        setIsNextVisible(false);
      } else if (mouseX < rect.width / 2) {
        setIsPrevVisible(true);
        setIsNextVisible(false);
      } else {
        setIsPrevVisible(false);
        setIsNextVisible(true);
      }
    }
  }, 100);

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsPrevVisible(false);
      setIsNextVisible(false);
    }, 250);
  };

  const swiperSlideClasses = classnames('h-auto', {
    'w-3/4': !sm && !md,
    'w-[37.5%]': sm,
    'w-fit': md
  });

  const headingClass = classnames({
    'heading-5': headingOption !== HeadingOption.Small,
    'heading-6-medium': headingOption === HeadingOption.Small
  });

  const navClasses = classnames(
    'group/navBtn',
    'absolute top-1/2 z-1 hidden w-[60px] -translate-y-1/2 overflow-hidden rounded-full opacity-0 backdrop-blur-md transition-opacity',
    'disabled:pointer-events-none disabled:opacity-0',
    'hover:opacity-100',
    'lg:flex'
  );

  const navIconClasses = classnames(
    'w-[60px] bg-black/30 transition-colors',
    'group-hover/navBtn:bg-black/40'
  );

  return (
    <Block
      element="div"
      contentLinkID={contentLink?.id}
      className={classnames('container module-spacing overflow-x-clip')}
    >
      <div
        className={classnames(
          'flex flex-col items-start justify-between gap-4',
          'sm:flex-row sm:items-center',
          {
            'mb-6 lg:mb-8': !membershipGroups,
            'sm:pb-4': membershipGroups
          }
        )}
        data-component={"TileCarouselBlock"}
      >
        <Text
          propertyName="Heading"
          element={headingOption === HeadingOption.Small ? 'h6' : 'h5'}
          className={headingClass}
        >
          {heading}
        </Text>
        {membershipGroups ? (
          <Panel isOpen={isOpen} onOpenChange={() => onChange(!isOpen)}>
            <Panel.Trigger className="pb-4 sm:pb-0" asChild>
              <Link
                as="button"
                propertyName="SeeAllLink"
                text={seeAllLink?.text}
                title={seeAllLink?.title}
                variant="bold-link-with-icon"
                icon="chevron-right"
              />
            </Panel.Trigger>
            <Panel.Content>
              {membershipGroupHeading ? (
                <Panel.Header className="!mb-6">
                  <Text element="h5" className="heading-5">
                    {membershipGroupHeading}
                  </Text>
                </Panel.Header>
              ) : null}
              <Panel.Body scrollClassName="">
                <div className="flex flex-col gap-12">
                  {membershipGroups?.map((group: IMembershipGroup, index: number) => {
                    const members = group.members.split('\n');

                    return (
                      <Fragment key={index}>
                        {members.length > 28 ? (
                          <Collapsible.Root open={groupIndexes.includes(String(index))}>
                            <MembersRowCollapsible
                              membershipGroup={members}
                              groupClassification={group.groupClassification}
                              selectedIndex={index}
                              isExpanded={groupIndexes.includes(String(index))}
                              groupIndexes={groupIndexes}
                              handleGroupIndexes={handleGroupIndexes}
                            />
                          </Collapsible.Root>
                        ) : (
                          <MembersRowNonCollapsible
                            membershipGroup={members}
                            groupClassification={group.groupClassification}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </Panel.Body>
            </Panel.Content>
          </Panel>
        ) : null}
      </div>
      {membershipGroups && <Divider color="light" className="mb-6 bg-gray-light lg:mb-8" />}
      {layout === Layout.OneRow ? (
        <div
          ref={containerRef}
          className="relative"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <swiper-container class="swiper-no-overflow" init={false} ref={swiperRef}>
            {items.map((item, index) => {
              return (
                <swiper-slide key={index} class={swiperSlideClasses}>
                  <ContentArea
                    propertyName="Items"
                    components={[item]}
                    componentsProps={{ tabIndex: index }}
                    className="h-full"
                  />
                </swiper-slide>
              );
            })}
          </swiper-container>
          <button
            id={navPrevId}
            className={classnames(navClasses, 'left-[-30px]', {
              'opacity-0': !isPrevVisible,
              'opacity-100': isPrevVisible
            })}
          >
            <RoundedIcon
              color="black"
              icon={{ name: 'arrow-left', size: 'large' }}
              className={navIconClasses}
            />
          </button>
          <button
            id={navNextId}
            className={classnames(navClasses, 'right-[-30px]', {
              'opacity-0': !isNextVisible,
              'opacity-100': isNextVisible
            })}
          >
            <RoundedIcon
              color="black"
              icon={{ name: 'arrow-right', size: 'large' }}
              className={navIconClasses}
            />
          </button>
        </div>
      ) : (
        <TileBlock items={items} layout="3-columns" />
      )}
    </Block>
  );
};

export default TileCarouselBlock;
