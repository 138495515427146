import { AnalyticsContext, AnalyticsEventName } from './constants';
import { trackEvent } from './utils';

type TrackLicenseEventProps = {
  value?: string;
  context?: AnalyticsContext;
};

export const trackFindApprovedProgramsEvent = ({ value, context }: TrackLicenseEventProps) => {
  if (context && value) {
    trackEvent({
      eventName: AnalyticsEventName.FindApprovedPrograms,
      parameters: { context, value }
    });
  }
};

export const trackViewRequirementsEvent = ({ value, context }: TrackLicenseEventProps) => {
  if (context && value) {
    trackEvent({
      eventName: AnalyticsEventName.ViewRequirements,
      parameters: { context, value }
    });
  }
};

export const trackAddLicenseEvent = ({ value, context }: TrackLicenseEventProps) => {
  if (context && value) {
    trackEvent({
      eventName: AnalyticsEventName.AddLicense,
      parameters: { context, value }
    });
  }
};

export const trackEditLicenseEvent = ({ value, context }: TrackLicenseEventProps) => {
  if (context && value) {
    trackEvent({
      eventName: AnalyticsEventName.EditLicense,
      parameters: { context, value }
    });
  }
};

export const trackRemoveLicenseEvent = ({ value, context }: TrackLicenseEventProps) => {
  if (context && value) {
    trackEvent({
      eventName: AnalyticsEventName.RemoveLicense,
      parameters: { context, value }
    });
  }
};

export const trackEditCertificatePreferencesEvent = () => {
  trackEvent({
    eventName: AnalyticsEventName.EditCertificatePreferences,
    parameters: {}
  });
};
