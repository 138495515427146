import Icon, { IconSizes } from '@/components/ui/Icon';
import classnames from 'classnames';

enum ButtonColor {
  Black = 'black',
  White = 'white',
  Gray = 'gray',
  GreyDarker = 'gray-darker',
  OutlineBlack = 'outline-black',
  Red = 'red'
}

enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

type RoundedButtonProps = {
  icon: string;
  color?: `${ButtonColor}`;
  disabled?: boolean;
  buttonSize?: `${ButtonSize}`;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

const RoundedButton: React.FC<RoundedButtonProps> = ({
  icon,
  color = ButtonColor.Black,
  disabled,
  buttonSize = ButtonSize.Medium,
  ...props
}) => {
  const buttonClasses = classnames(
    'group flex items-center justify-center rounded-full transition',
    {
      'size-6': buttonSize === ButtonSize.Small,
      'size-8': buttonSize === ButtonSize.Medium,
      'size-10': buttonSize === ButtonSize.Large
    },
    {
      'bg-black': color === ButtonColor.Black,
      'hover:bg-gray-dark': color === ButtonColor.Black,
      'active:bg-gray-dark': color === ButtonColor.Black,
      'disabled:bg-gray': color === ButtonColor.Black
    },
    {
      'bg-white': color === ButtonColor.White,
      'hover:bg-gray-lightest': color === ButtonColor.White,
      'active:bg-gray-light': color === ButtonColor.White,
      'disabled:bg-gray-light': color === ButtonColor.White,
      'focus-visible:outline-white': color === ButtonColor.White
    },
    {
      'bg-gray-light': color === ButtonColor.Gray,
      'hover:bg-silver': color === ButtonColor.Gray,
      'active:bg-gray-light': color === ButtonColor.Gray,
      'disabled:bg-gray-light': color === ButtonColor.Gray
    },
    {
      'bg-gray-darker': color === ButtonColor.GreyDarker,
      'hover:bg-silver': color === ButtonColor.GreyDarker,
      'active:bg-gray-darker': color === ButtonColor.GreyDarker,
      'disabled:bg-gray-darker': color === ButtonColor.GreyDarker
    },
    {
      'border border-gray bg-white': color === ButtonColor.OutlineBlack,
      'hover:border-gray-dark': color === ButtonColor.OutlineBlack,
      'active:border-gray-dark': color === ButtonColor.OutlineBlack,
      'disabled:border-gray-light': color === ButtonColor.OutlineBlack
    },
    {
      'bg-red': color === ButtonColor.Red,
      'hover:bg-red-dark': color === ButtonColor.Red,
      'active:bg-red-dark': color === ButtonColor.Red,
      'disabled:bg-gray-light': color === ButtonColor.Red
    }
  );

  const iconCLasses = classnames(
    'opacity-100 transition',
    {
      'text-white': color === ButtonColor.Black,
      'group-disabled:opacity-70': color === ButtonColor.Black
    },
    {
      'text-black': color === ButtonColor.White,
      'group-disabled:opacity-30': color === ButtonColor.White
    },
    {
      'text-black': color === ButtonColor.Gray,
      'group-disabled:opacity-30': color === ButtonColor.Gray
    },
    {
      'text-white': color === ButtonColor.GreyDarker,
      'group-disabled:opacity-30': color === ButtonColor.GreyDarker
    },
    {
      'text-black': color === ButtonColor.OutlineBlack,
      'group-disabled:opacity-30': color === ButtonColor.OutlineBlack
    },
    {
      'text-white': color === ButtonColor.Red,
      'group-active:text-opacity-70': color === ButtonColor.Red,
      'group-disabled:text-white group-disabled:opacity-70': color === ButtonColor.Red
    }
  );

  let iconSize: IconSizes = 'medium';
  switch (buttonSize) {
    case ButtonSize.Small:
      iconSize = 'x-small';
      break;
    case ButtonSize.Medium:
      iconSize = 'small';
      break;
    case ButtonSize.Large:
      iconSize = 'large';
      break;
    default:
      break;
  }

  return (
    <button disabled={disabled} className={buttonClasses} {...props}>
      <span>
        <Icon className={iconCLasses} name={icon} size={iconSize} />
      </span>
    </button>
  );
};

export default RoundedButton;
