import {
  ICLEPeriod,
  ICreditRequirement,
  UserTrackerData,
  UserTrackerDataRequest
} from '@/@types/client-api';
import { ContentReference } from '@/@types/cms';
import {
  CompliancePeriodValue,
  SpecialStatusSelector,
  UserComplianceInformation
} from '@/components/ui/CreditTracker/util/constants';
import { createContext } from 'react';
import { DismissableBannerProps } from '@/components/blocks/DismissableBannerBlock';

interface CreditTrackerContextType {
  changeCompliancePeriod?: (period?: CompliancePeriodValue) => void;
  selectedRegion?: UserTrackerData | null;
  allTrackerData?: UserTrackerData[] | null;
  isLoading?: boolean;
  activeSpecialStatus?: string;
  selectedClePeriod?: ICLEPeriod;
  requirementsWithoutTemporal?: ICreditRequirement[];
  temporalRequirements?: ICreditRequirement[];
  isDeadlineApproaching?: boolean;
  isTransitionalOrNewlyAdmittedRegion?: boolean;
  setIsLoading: (x: boolean) => void;
  isMissingCompliancePeriod: boolean;
  previousDatesInvalid?: boolean;
  currentDatesInvalid?: boolean;
  nextDatesInvalid?: boolean;
  earnMoreCreditResultPage?: ContentReference;
  userComplianceInformation?: UserComplianceInformation;
  specialStatuses?: SpecialStatusSelector[];
  lastTrackerRequest: UserTrackerDataRequest[];
  globalBanners?: DismissableBannerProps[] | null;
  isAdminRequest?: boolean;
  adminDisplayName?: string | null;
  contentLink?: ContentReference | null;
}

export const CreditTrackerContext = createContext<CreditTrackerContextType>({
  isMissingCompliancePeriod: false,
  selectedRegion: null,
  allTrackerData: null,
  isLoading: false,
  isDeadlineApproaching: false,
  isTransitionalOrNewlyAdmittedRegion: false,
  specialStatuses: [],
  lastTrackerRequest: [],
  setIsLoading: () => {},
  contentLink: null
});
