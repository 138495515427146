import type { SiteSettings, StartPage as StartPageProps } from '@/@types/content';
import ContentArea from '../cms/ContentArea';
import withNav from '../hocs/withNav';
import { IContent } from '@/@types/cms';

const StartPageComponent: React.FC<StartPageProps & SiteSettings> = props => {
  let walkthroughSteps: Array<IContent> = [];

  if ('walkthroughSteps' in props) {
    walkthroughSteps = props.walkthroughSteps as Array<IContent>;
  }

  return (
    <ContentArea
      components={props.mainContentArea}
      componentsProps={{ walkthroughSteps: walkthroughSteps }}
      propertyName="MainContentArea"
    />
  );
};

const StartPage = withNav(StartPageComponent);
export default StartPage;
