import AvatarComponent from '@/components/ui/Avatar';
import Divider, { DividerProps } from '@/components/ui/Divider';
import classnames from 'classnames';
import ComplexTooltip from '@/components/ui/Tooltip/ComplexTooltip';
import Text from '@/components/cms/Text';
import { IContent } from '@/@types/cms';
import { IconProps } from '../Icon';
import Icon from '@/components/cms/Icon';
import TooltipBlockContainer from '@/components/blocks/Tooltip/TooltipBlock';
import { useMemo } from 'react';

enum TitleSize {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6'
}

enum VerticalAlignment {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom'
}

export interface IContentAvatar extends IContent {
  fullName: string;
  image?: {
    url?: string;
  };
  icon?: string;
}

export type SectionTitleProps = {
  propertyNameAvatar?: string;
  propertyNameIcon?: string;
  propertyNameTitle?: string;
  propertyNameSubcopy?: string;
  children?: React.ReactNode;
  className?: string;
  title?: string;
  titleSize?: `${TitleSize}`;
  icon?: IconProps;
  tooltip?: React.ComponentProps<typeof ComplexTooltip>;
  tooltipWalkthrough?: IContent & { applyFor?: string; index?: number; walkthroughSteps?: number };
  subcopy?: string;
  avatars?: Array<IContentAvatar>;
  divider?: DividerProps;
  mobileGapSmall?: boolean;
  verticalAlignment?: `${VerticalAlignment}`;
  elementType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
};

const SectionTitle: React.FC<SectionTitleProps> = ({
  propertyNameAvatar,
  propertyNameIcon,
  propertyNameTitle,
  propertyNameSubcopy,
  children,
  className,
  title,
  titleSize = TitleSize.H6,
  icon,
  tooltip,
  tooltipWalkthrough,
  subcopy,
  avatars,
  divider,
  mobileGapSmall = true,
  verticalAlignment = VerticalAlignment.Center,
  elementType = 'span'
}) => {
  const titleClasses = classnames({
    'heading-6-medium': titleSize === TitleSize.H6,
    'heading-5': titleSize === TitleSize.H5,
    'heading-4': titleSize === TitleSize.H4,
    'heading-3': titleSize === TitleSize.H3,
    'heading-2': titleSize === TitleSize.H2,
    'heading-1': titleSize === TitleSize.H1,
    'ml-[14px]': icon && !avatars,
    'mr-2': tooltip
  });

  const tooltipBlock = useMemo(() => {
    return tooltipWalkthrough ? (
      <TooltipBlockContainer
        {...tooltipWalkthrough}
        totalSteps={tooltipWalkthrough?.walkthroughSteps}
        isWalkthrought={true}
        sideOffset={20}
      />
    ) : null;
  }, [tooltipWalkthrough]);

  return (
    <div className={classnames('relative', className)}       data-component={"SectionTitle"}>
      <div
        className={classnames(
          'flex w-full flex-col items-start gap-8',
          {
            'gap-y-4': mobileGapSmall
          },
          'sm:flex-row sm:justify-between',
          {
            'sm:items-start': verticalAlignment === 'top',
            'sm:items-center': verticalAlignment === 'center',
            'sm:items-end': verticalAlignment === 'bottom'
          }
        )}
      >
        <div className="flex items-center gap-4">
          {avatars && (
            <div className="flex items-center">
              {avatars.map((avatar, index) => (
                <AvatarComponent
                  propertyName={propertyNameAvatar}
                  className={classnames(
                    'relative -ml-2.5 w-[46px] border-2 border-white !bg-gray-dark !text-gray-light first:ml-0',
                    'first:w-11 first:border-0 first:border-transparent'
                  )}
                  imageSizes={{
                    xs: '46px'
                  }}
                  key={index}
                  fullName={avatar?.fullName}
                  imageUrl={avatar?.image?.url}
                  icon={avatar?.image?.url ? undefined : avatar?.icon}
                />
              ))}
            </div>
          )}

          <div className="flex flex-col gap-2">
            <div className="relative flex items-center">
              {!avatars && <Icon size="large" {...icon} propertyName={propertyNameIcon} />}
              <Text element={elementType} propertyName={propertyNameTitle} className={titleClasses}>
                {title}
              </Text>
              {tooltipBlock && (
                <div className="pointer-events-none absolute h-full w-3/5">{tooltipBlock}</div>
              )}
              {tooltip && <ComplexTooltip {...tooltip} />}
            </div>
            <Text
              element="span"
              propertyName={propertyNameSubcopy}
              className="text-2 block text-gray-dark"
            >
              {subcopy}
            </Text>
          </div>
        </div>

        {children}
      </div>

      {divider && <Divider className="mt-4" {...divider} />}
    </div>
  );
};

export default SectionTitle;
