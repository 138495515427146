import classnames from 'classnames';
import Button from '../ui/Buttons/Button';

interface SkipToMainProps {
  label: string;
  href: string;
}

const SkipToMain: React.FC<SkipToMainProps> = ({ label, href }) => {
  return (
    <Button
      tabIndex={1}
      className={classnames(
        '!fixed left-4 top-0 z-[1000] bg-white',
        '-translate-y-8 transition-transform focus:translate-y-2'
      )}
      href={href}
      label={label}
      color="outline-black"
      size="small"
      data-component={"SkipToMain"}
    />
  );
};

export default SkipToMain;
