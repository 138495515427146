import InvalidFieldValueMessage from './InvalidFieldValueMessage';

type RequiredFieldMessageProps = {
  id: string;
  label: string;
};

const RequiredFieldMessage = ({ id, label }: RequiredFieldMessageProps): JSX.Element => {
  return (
    <InvalidFieldValueMessage
      id={id}
      message="Required"
      accessibilityMessage={`${label} required`}
    />
  );
};

export default RequiredFieldMessage;
