import { useEffect, useMemo } from 'react';
import { PersonalInfoPracticeAreasBlock as PersonalInfoPracticeAreasProps } from '@/@types/content';
import { useGetUserPracticeAreasQuery } from '@/redux/api/client/practiceAreas';
import { getProfileLinkInfo } from '@/utils/getProfileLinkInfo';
import LinkGridShimmer from '@/components/ui/ProfileShimmers/LinkGridShimmer';

import LinkGridBlock from '../../blocks/LinkGridBlock/LinkGridBlock';
import ProfileSectionWrapper from './ProfileSectionWrapper';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import useToast from '@/hooks/useToast';
import { IComponentProps } from '@/lib/ComponentFactory';
import useNudge from '@/hooks/shared/useNudge';
import { NudgeAnalyticsContext } from '@/analytics/constants';
import { trackViewPracticeAreaEvent } from '@/analytics/profile';
import { ProfileNudgeSectionNames } from '@/hooks/shared/useNudge/utils';

const PracticeAreaSection: React.FC<PersonalInfoPracticeAreasProps & IComponentProps> = ({
  messages,
  timeUntilStale,
  index,
  ...props
}) => {
  const { data, isError, isLoading } = useGetUserPracticeAreasQuery();
  const practiceAreaLinkBlockData = useMemo(() => getProfileLinkInfo(data), [data]);
  const debouncedLoading = useDebounceLoading(isLoading);
  const { showFailMessage } = useToast();

  useEffect(() => {
    if (isError) {
      showFailMessage('Something went wrong while loading the practice areas you follow');
    }
  }, [isError, showFailMessage]);

  const nudge = useNudge({
    messagesJSON: messages,
    sectionData: data,
    sectionName: ProfileNudgeSectionNames.PracticeAreas,
    context: NudgeAnalyticsContext.PracticeArea,
    sectionIndex: index,
    timeUntilStale
  });

  if (isError) {
    return null;
  }

  if (debouncedLoading) return <LinkGridShimmer />;

  return (
    <ProfileSectionWrapper>
      <LinkGridBlock
        {...practiceAreaLinkBlockData}
        {...props}
        elementType="h3"
        nudge={nudge}
        onTrackItemViewEvent={trackViewPracticeAreaEvent}
      />
    </ProfileSectionWrapper>
  );
};

export default PracticeAreaSection;
