import { useCallback, useEffect, useRef } from 'react';
import Panel, { PanelProps } from '@/components/ui/Panel';
import useToast from '@/hooks/useToast';

export type IFramePanelProps = Omit<PanelProps, 'size' | 'children'> & {
  title?: string;
  url: string;
  triggerButton: JSX.Element;
};

const IFramePanel = ({
  isOpen,
  onOpenChange,
  theme,
  title,
  url,
  triggerButton
}: IFramePanelProps): JSX.Element => {
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const { showSuccessMessage } = useToast();

  const handleOnLoad = useCallback(() => {
    setTimeout(() => {
      if (iFrameRef.current) {
        iFrameRef.current.classList.remove('opacity-0');
      }
    }, 500);
  }, []);

  useEffect(() => {
    const handleOnSuccess = function (event: {
      origin: string;
      data: { ok: boolean; message: string };
    }) {
      if (event.origin + '/identity' === window.env.CONNECT_AUTHORITY) {
        if (event.data.ok && onOpenChange) {
          showSuccessMessage(event.data.message);
          onOpenChange(false);
        }
      }
    };

    window.addEventListener('message', handleOnSuccess);

    return () => window.removeEventListener('message', handleOnSuccess);
  }, [onOpenChange, showSuccessMessage]);

  return (
    <Panel isOpen={isOpen} onOpenChange={onOpenChange} theme={theme} size="small">
      <Panel.Trigger asChild>{triggerButton}</Panel.Trigger>
      <Panel.Content side="right">
        {title && <Panel.Header>{title}</Panel.Header>}
        <Panel.Body>
          <div className="absolute inset-0 z-50"       data-component={"IFramePanel"}          >
            <iframe
              ref={iFrameRef}
              src={url}
              title={title}
              sandbox="allow-same-origin allow-scripts allow-top-navigation allow-forms"
              className="size-full p-6 opacity-0 transition-opacity md:p-8"
              onLoad={handleOnLoad}
            />
          </div>
        </Panel.Body>
      </Panel.Content>
    </Panel>
  );
};

export default IFramePanel;
