import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Thumbnail from '@/components/ui/Thumbnail';
import BaseBox from '../components/BaseBox';
import Variation from './components/Variation';
import Icon from '@/components/ui/Icon';
import PanelButton from '../components/PanelButton';
import useInViewport from '@/hooks/useInViewport';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { AppDispatch, RootState } from '@/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setHardSelectedVariant, Variant } from '@/redux/slices/pdpSlice';
import { useAddToCart } from '@/hooks/shared/useAddToCart';
import Button from '@/components/ui/Buttons/Button';
import { getProductImageUrl, isPrintFormat } from '../../helpers';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import classnames from 'classnames';
import ComplexTooltip from '@/components/ui/Tooltip/ComplexTooltip';
import { AnalyticsContext } from '@/analytics/constants';
import RichText from '@/components/cms/RichText';
import Loading from './components/Loading';
import useToast from '@/hooks/useToast';
import { useSearchParamsStable } from '../../../../../hooks/useSearchParams';
import { delayedActionMessageBuilder } from '@/redux/helpers/delayedActionsMapper';
import { useFetchAnalyticsItems } from '@/analytics/useFetchAnalyticsItems';
import { trackReadNowEvent } from '@/analytics/library';

interface PublicationProps {
  labels: {
    purchaseButton: string;
    preorderPurchaseButton: string;
    readButton: string;
    itemId: string;
    includedInPlus: string;
    purchased: string;
    stsAvailable: string;
    programAttendeeAccess: string;
    digitalAccess: string;
    printHardcover: string;
    printSoftcover: string;
    printBinder: string;
    printPreorder: string;
    sts: string;
    stsRenewal: string;
    upkeepAvailableTooltipTitle: string;
    upkeepAvailableTooltipContent: string;
    internationalShippingTooltipTitle: string;
    internationalShippingTooltipContent: string;
    plusToutRichText: string;
  };
  isMobile: boolean;
  onClosePanel?: () => void;
  isPlusSubscriber?: boolean;
  loadingCatalogRelations?: boolean;
  context?: AnalyticsContext;
  isLibraryFetching?: boolean;
  creditTrackerCode?: string;
}

const Publication: React.FC<PublicationProps> = ({
  labels,
  isMobile,
  onClosePanel,
  isPlusSubscriber,
  context,
  isLibraryFetching,
  creditTrackerCode
}) => {
  const withinPanel = !!onClosePanel;
  const dispatch = useDispatch<AppDispatch>();
  const [stickyButtonRef] = useInViewport<HTMLButtonElement>();
  const isMedium = useMediaQuery(ScreenSizeQueries.mdOnly);
  const isSmall = useMediaQuery(ScreenSizeQueries.smOnly);
  const pageLinkPaths = useSelector(selectPageLinkPaths);

  const selectedVariant = useSelector((state: RootState) => state.pdp.selectedVariant);
  const catalogRelations = useSelector((state: RootState) => state.pdp.catalogRelations);
  const isStsRenewalSelected = useSelector((state: RootState) => state.pdp.isStsRenewalSelected);

  const { showSuccessMessage } = useToast();
  const { searchParams, setSearchParams } = useSearchParamsStable();

  const variants = useMemo(() => {
    if (catalogRelations?.variants) {
      const variants = [...catalogRelations.variants];

      const purchasedSts = variants.find(variant => variant.format === 'sts' && variant.purchased);

      if (purchasedSts && purchasedSts.isStsRenewalPermitted) {
        // Insert after found variable a new variant
        variants.splice(1, 0, {
          ...purchasedSts,
          format: 'sts-renewal',
          purchased: false
        });
      }

      return variants;
    }
    return [];
  }, [catalogRelations]);

  const canReadOnPlus = (selectedVariant?: Variant) => {
    if (
      selectedVariant?.format === 'digital-program' ||
      selectedVariant?.format === 'digital-plusMember'
    )
      return true;
    if (selectedVariant?.format === 'sts' && selectedVariant?.purchased) return true;
    return false;
  };

  const isPreorderFormat = (selectedVariant?: Variant) => {
    if (selectedVariant?.format === 'preorder') return true;
    return false;
  };

  const {
    addToCart,
    isSuccess: isCartSuccess,
    reset: cartReset,
    isLoading: isCartLoading,
    isError: isCartError
  } = useAddToCart(context, creditTrackerCode);

  const [showButtonLoading, setShowButtonLoading] = useState(false);
  const [showButtonError, setShowButtonError] = useState(false);
  const [showButtonCheck, setShowButtonCheck] = useState(false);

  useEffect(() => {
    if (isCartSuccess && withinPanel) {
      onClosePanel?.();
      return;
    }
    if (isCartSuccess) {
      setShowButtonCheck(true);
      setShowButtonLoading(false);
      setShowButtonError(false);
      setTimeout(() => {
        setShowButtonCheck(false);
        if (isCartSuccess) cartReset();
      }, 1000);
    }
    if (isCartLoading) {
      setShowButtonLoading(true);
    }

    if (isCartError) {
      setShowButtonError(true);
      setShowButtonLoading(false);
    }
  }, [cartReset, isCartError, isCartLoading, isCartSuccess, onClosePanel, withinPanel]);

  useEffect(() => {
    if (searchParams.has('successAction')) {
      const delayedAction = searchParams.get('successAction');
      const program = searchParams.get('program');
      const response = JSON.parse(searchParams.get('response') ?? '{}');
      const { cartUrl } = response;

      if (delayedAction === 'postCartItem' && cartUrl && program) {
        const { title } = JSON.parse(decodeURIComponent(program));
        const successMessage = delayedActionMessageBuilder[delayedAction]?.({
          title,
          url: cartUrl
        });
        showSuccessMessage(successMessage);
      }

      if (delayedAction === 'addToLibrary') {
        showSuccessMessage('Saved to My Library');
      }

      setSearchParams(
        existing => {
          existing.delete('successAction');
          existing.delete('program');
          existing.delete('response');
          return existing;
        },
        { replace: true, preventScrollReset: true }
      );
    }
  }, [searchParams, showSuccessMessage, setSearchParams]);

  const { getAnalyticItem } = useFetchAnalyticsItems();
  const trackEvent = useCallback(
    async (code: string, context?: AnalyticsContext) => {
      const data = await getAnalyticItem(code);
      if (data) {
        trackReadNowEvent(data, context);
      }
    },
    [getAnalyticItem]
  );

  const button = useMemo(() => {
    const priorityCodeForCart =
      selectedVariant?.format === 'sts-renewal' ? selectedVariant.priorityCode : undefined;

    if (canReadOnPlus(selectedVariant)) {
      return (
        <Button
          color="red"
          iconRight="link-out"
          href={selectedVariant?.readOnPlusUrl}
          label={labels.readButton}
          target="_blank"
          ref={stickyButtonRef}
          size="large"
          className={classnames('w-full')}
          onClick={() => trackEvent}
        />
      );
    }

    return (
      <PanelButton
        ref={stickyButtonRef}
        onClick={() => {
          addToCart(selectedVariant?.code, selectedVariant?.displayName, priorityCodeForCart);
        }}
        label={
          isPreorderFormat(selectedVariant) ? labels.preorderPurchaseButton : labels.purchaseButton
        }
        iconName="cart"
        isSuccess={showButtonCheck}
        isLoading={showButtonLoading}
        isError={showButtonError}
      />
    );
  }, [
    addToCart,
    labels.preorderPurchaseButton,
    labels.purchaseButton,
    labels.readButton,
    selectedVariant,
    showButtonCheck,
    showButtonError,
    showButtonLoading,
    stickyButtonRef,
    trackEvent
  ]);

  const generateTooltip = (
    title: string,
    content: string,
    contentClass?: string,
    triggerNode?: ReactNode
  ) => {
    return (
      <div className="flex h-10 items-center justify-center gap-1 text-gray-dark">
        <ComplexTooltip>
          <ComplexTooltip.Trigger>
            <span className="flex flex-row gap-1 hover:text-black">
              <Icon name="info" size="small" />
              {triggerNode ? triggerNode : <span className="label">{title}</span>}
            </span>
          </ComplexTooltip.Trigger>
          <ComplexTooltip.Content side="top" sideOffset={8} className={contentClass}>
            <div className="flex flex-col gap-1">
              <h1 className="text-2-bold">{title}</h1>
              <RichText className="text-2" content={content} />
            </div>
          </ComplexTooltip.Content>
        </ComplexTooltip>
      </div>
    );
  };

  const shippingInfo = isPrintFormat(selectedVariant?.format) ? (
    generateTooltip(
      labels.internationalShippingTooltipTitle,
      labels.internationalShippingTooltipContent,
      '!ml-[-22px] lg:!ml-[-35px]',
      <span className="label">
        <a href={`mailto:${window.env?.APP_PLIPRESS_EMAIL}`} className="underline">
          Contact us
        </a>{' '}
        to ship outside the US & Canada
      </span>
    )
  ) : (
    <></>
  );

  const upkeepInfo =
    isPrintFormat(selectedVariant?.format) && selectedVariant?.hasUpkeep ? (
      generateTooltip(
        labels.upkeepAvailableTooltipTitle,
        labels.upkeepAvailableTooltipContent,
        '!ml-[-70px] lg:!ml-[-88px]'
      )
    ) : (
      <></>
    );

  const ecomMessages = (
    <>
      {shippingInfo}
      {upkeepInfo}
    </>
  );

  const isVariantSelected = (variant: Variant) => {
    if (isStsRenewalSelected) {
      return variant.format === 'sts-renewal' && selectedVariant?.code === variant.code;
    } else {
      return selectedVariant?.code === variant.code && selectedVariant?.format === variant.format;
    }
  };

  if (isLibraryFetching) {
    return <Loading isMobile={isMobile} />;
  }

  const footerComponent = (
    <BaseBox.Footer
      hideGradient={withinPanel}
      hasHorizontalPadding={!withinPanel}
      withinPanel={withinPanel}
      primaryButton={button}
      codeLabel={labels.itemId}
      codeValue={selectedVariant?.code ?? ''}
      lowerContainer={ecomMessages}
      className={classnames({ 'pt-12': withinPanel })}
    ></BaseBox.Footer>
  );

  return (
    <BaseBox sticky={!isMobile} withinPanel={withinPanel}>
      {withinPanel && (
        <BaseBox.PanelHeader
          productCode={selectedVariant?.code}
          title={selectedVariant?.displayName}
          seeDetailsLink={selectedVariant?.contentLinkUrl}
          context={context}
        />
      )}
      <BaseBox.Content withinPanel={withinPanel} hasPaddingBottom={!withinPanel}>
        {/* adding width to imageUrl because non-fitted Book Image thumbnail does not support imageSizes prop */}
        <Thumbnail
          image={{
            url: `${getProductImageUrl(selectedVariant?.code ?? '')}?width=${isMedium || isSmall ? 600 : 300}`,
            alt: `${selectedVariant?.displayName} cover art`
          }}
          fitImage={false}
          ratio={isMedium || isSmall ? 1.5 : 1}
          className="mb-14 lg:mb-10"
          imageSizes={{
            xs: '302px',
            sm: '496px',
            md: '768px',
            lg: '342px'
          }}
        />

        {variants.length === 1 && <Variation labels={labels} {...variants[0]} />}

        {variants.length > 1 && (
          <div className="flex flex-col gap-4">
            {variants.map(variant => (
              <Variation
                key={`${variant.code}-${variant.format}`}
                {...variant}
                labels={labels}
                isSelected={isVariantSelected(variant)}
                onClick={() => {
                  dispatch(setHardSelectedVariant(variant));
                }}
              />
            ))}
          </div>
        )}
        {withinPanel ? footerComponent : null}
      </BaseBox.Content>
      {withinPanel ? null : footerComponent}
      {!isPlusSubscriber ? (
        <BaseBox.Card>
          <a
            href={pageLinkPaths.PlusMembershipInfo}
            className="flex items-center justify-between px-8 py-[25px] text-gray-dark lg:px-10"
          >
            <RichText className="max-lg:text-2 lg:label" content={labels.plusToutRichText} />
            <Icon name="link-out" size="large" className="shrink-0" />
          </a>
        </BaseBox.Card>
      ) : (
        <></>
      )}
    </BaseBox>
  );
};

export default Publication;
