import { FC, ReactElement } from 'react';
import type { EmptyStateBlock } from '@/@types/content';
import { LinkBlock } from '@/@types/content';
import Divider from '@/components/ui/Divider';
import Link from '@/components/ui/Link';
import AddLicenseBlock from '@/components/blocks/EmptyStateBlock/AddLicenseBlock';
import classnames from 'classnames';

interface EmptyStateBlockProps extends EmptyStateBlock {
  onClick?: () => void;
  viewAllJurisdiction?: ReactElement;
  hasSidePadding?: boolean;
  hasTopPadding?: boolean;
  contentOverride?: {
    heading: string;
    subheading?: string;
  };
}

const EmptyStateBlock: FC<EmptyStateBlockProps> = ({
  linkBlocks,
  footerLink,
  hasSidePadding = true,
  hasTopPadding = true,
  heading,
  subheading,
  contentOverride,
  ...props
}) => {
  return (
    <>
      <div
        className={classnames('module-spacing pb-16 lg:pb-12', {
          container: hasSidePadding,
          '!pt-0': !hasTopPadding
        })}
      >
        {/* TODO This component should be feature agnostic */}
        <AddLicenseBlock
          heading={contentOverride ? contentOverride.heading : heading}
          subheading={contentOverride ? contentOverride.subheading : subheading}
          {...props}
        />
      </div>
      {(linkBlocks || footerLink) && (
        <div className={classnames('pb-8', { container: hasSidePadding })}>
          <Divider color="light" className="mb-12" />
          {linkBlocks?.map((block: LinkBlock, index) => (
            <Link
              key={index}
              text={block.link.text}
              href={block.link.href}
              target={block.link.target}
              className="w-max py-4 font-medium first-of-type:pt-0"
              variant="link-with-icon"
              icon="chevron-right"
            />
          ))}
          {footerLink && (
            <Link
              text={footerLink.text}
              href={footerLink.href}
              target={footerLink.target}
              className="w-max py-4"
              variant="bold-link-with-icon"
              icon="link-out"
            />
          )}
        </div>
      )}
    </>
  );
};

export default EmptyStateBlock;
