import { useMemo } from 'react';
import { ProgramCatalogRelations, ProgramVariation } from './useProgramCatalogRelations';
import { ActionItem } from '@/components/ui/PDP/ActionBar';
import {
  PublicationCatalogRelations,
  PublicationVariation
} from './usePublicationCatalogRelations';
import { useShareAction } from './useShareAction';
import { useLibraryAction } from './useLibraryAction';
import { useMemsuiteAction } from './useMemsuiteAction';
import { useRegisterFromRosterAction } from './useRegisterFromRosterAction';
import useDownloadAction from './useDownloadAction';
import { LibraryItem } from '@/@types/client-api';

export const useActions = (
  variant: ProgramVariation | PublicationVariation,
  catalogRelations: ProgramCatalogRelations | PublicationCatalogRelations,
  library: {
    data?: LibraryItem;
    isFetching?: boolean;
  },
  openShareModal: (bool: boolean) => void,
  openDownloadModal?: (bool: boolean) => void,
  openMemsuiteModal?: (bool: boolean) => void,
  registerFromRoster?: () => void
) => {
  const shareAction = useShareAction(openShareModal);
  const libraryAction = useLibraryAction(variant.code ?? '', library);
  const memsuiteAction = useMemsuiteAction(openMemsuiteModal);
  const registerFromRosterAction = useRegisterFromRosterAction(registerFromRoster);
  const downloadAction = useDownloadAction(variant, catalogRelations, openDownloadModal);

  const actions: ActionItem[] = useMemo(() => {
    const eligibleActions = [];

    memsuiteAction && eligibleActions.push(memsuiteAction);

    registerFromRosterAction && eligibleActions.push(registerFromRosterAction);

    // Add or remove from Library
    eligibleActions.push(libraryAction);

    // Share
    eligibleActions.push(shareAction);

    downloadAction && eligibleActions.push(downloadAction);

    return eligibleActions;
  }, [memsuiteAction, registerFromRosterAction, libraryAction, shareAction, downloadAction]);

  return actions;
};
