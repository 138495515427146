import { useState, useCallback, useEffect } from 'react';

const useAutofillListener = <T extends HTMLElement>(ref: React.RefObject<T>) => {
  const [isAutofilled, setIsAutofilled] = useState(false);

  const onAutoFillStart = useCallback(() => {
    setIsAutofilled(true);
  }, []);

  const onAutoFillEnded = useCallback(() => {
    setIsAutofilled(false);
  }, []);

  const onAnimationStart = useCallback(
    (e: AnimationEvent) => {
      switch (e.animationName) {
        case 'onAutoFillStart': {
          onAutoFillStart();
          break;
        }
        case 'onAutoFillCancel': {
          onAutoFillEnded();
          break;
        }
        default:
          break;
      }
    },
    [onAutoFillStart, onAutoFillEnded]
  );

  useEffect(() => {
    ref.current?.addEventListener('animationstart', onAnimationStart);
    const ReactNode = ref.current;
    return () => {
      if (ReactNode) {
        ReactNode.removeEventListener('animationstart', onAnimationStart);
      }
    };
  }, [onAutoFillStart, onAutoFillEnded, ref, onAnimationStart]);

  return isAutofilled;
};

export default useAutofillListener;
