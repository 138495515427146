import { useCallback, useEffect, useRef, useState } from 'react';
import RoundedIcon from '@/components/ui/RoundedIcon';
import Icon from '@/components/ui/Icon';
import { type Swiper, type SwiperOptions } from 'swiper/types';
import { v4 as uuid4 } from 'uuid';
import { SwiperContainer, register } from 'swiper/element/bundle';
import classnames from 'classnames';
import throttle from 'lodash/throttle';
import Shimmer from '@/components/ui/Shimmer';
import CardBuilder from '@/components/ui/Cards/CardBuilder';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { ISearchResultItem } from '@/@types/client-api';

enum Type {
  Standard = 'standard',
  Faculty = 'faculty',
  Library = 'library'
}

enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

interface ProductCarouselProps {
  type?: `${Type}`;
  cards: Record<string, unknown>[];
  labelFindMore: string;
  onClickFindMore: () => void;
  isLoading?: boolean;
  enumerateCards?: boolean;
  SetCarouselViewport?: (start: number, end: number) => void;
  createAnaliticsItem?: (card: Record<string, unknown>) => void;
}

const ProductCarousel: React.FC<ProductCarouselProps> = ({
  type = Type.Standard,
  cards,
  labelFindMore,
  onClickFindMore,
  isLoading = false,
  enumerateCards = false,
  SetCarouselViewport,
  createAnaliticsItem
}) => {
  const [swiper, setSwiper] = useState<Swiper | null>(null);
  const [isPrevVisible, setIsPrevVisible] = useState(false);
  const [isNextVisible, setIsNextVisible] = useState(false);

  const sm = useMediaQuery(ScreenSizeQueries.sm);
  const md = useMediaQuery(ScreenSizeQueries.md);

  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  const cardsCount = cards?.length || 0;
  const navPrevId = `nav-prev-${uuid4()}`;
  const navNextId = `nav-next-${uuid4()}`;
  let cardsSize: `${Size}` = Size.Small;

  const GetCarouselViewport = useCallback(
    (swiper: Swiper) => {
      const containerWidth = swiper?.el?.clientWidth;
      const cardWidth = swiper.slides[swiper?.activeIndex ?? 0]?.clientWidth;

      const firstVisibleSlideIndex = swiper.activeIndex;
      const visibleSlides = Math.max(Math.floor(containerWidth / Math.max(cardWidth ?? 0, 50)), 1);

      const lastVisibleSlideIndex = Math.min(
        firstVisibleSlideIndex + visibleSlides - 1,
        swiper.slides?.length - 1
      );

      if (SetCarouselViewport) {
        SetCarouselViewport(firstVisibleSlideIndex, lastVisibleSlideIndex);
      }
    },
    [SetCarouselViewport] // Dependencies
  );

  useEffect(() => {
    if (!swiperRef.current) return;
    register();

    const params: SwiperOptions = {
      slidesPerGroupAuto: true,
      maxBackfaceHiddenSlides: 1,
      spaceBetween: 24,
      watchSlidesProgress: true,
      navigation: {
        enabled: true,
        prevEl: `#${navPrevId}`,
        nextEl: `#${navNextId}`
      },
      autoHeight: false,
      on: {
        init(swiper) {
          setSwiper(swiper);
          GetCarouselViewport(swiper);
        },
        slideChange(swiper) {
          GetCarouselViewport(swiper);
        }
      },
      breakpoints: {
        0: {
          slidesPerView: 'auto'
        },
        889: {
          slidesPerView: 4,
          slidesPerGroup: 4
        }
      }
    };

    Object.assign(swiperRef.current, params);

    const currentSwiper = swiperRef.current as SwiperContainer;
    currentSwiper.initialize();
  }, [navNextId, navPrevId, GetCarouselViewport]);

  if (type !== Type.Library && cardsCount === 1) {
    cardsSize = Size.Large;
  } else if (type !== Type.Library && cardsCount === 2) {
    cardsSize = Size.Medium;
  }

  const cardClasses = classnames('w-full !max-w-none');

  const navClasses = classnames(
    'group/navBtn',
    'absolute top-1/2 z-1 hidden w-[60px] -translate-y-1/2 overflow-hidden rounded-full opacity-0 backdrop-blur-md transition-opacity',
    'disabled:pointer-events-none disabled:opacity-0',
    'hover:opacity-100',
    'lg:flex'
  );

  const navIconClasses = classnames(
    'w-[60px] bg-black/30 transition-colors',
    'group-hover/navBtn:bg-black/40'
  );

  const FindMoreCard = () => {
    return (
      <button
        className={classnames(
          'relative flex size-full h-[400px] max-w-[300px] items-center justify-center rounded-lg bg-gray-lightest p-6 transition',
          'hover:bg-gray-light focus:bg-gray-light',
          cardClasses
        )}
        onClick={onClickFindMore}
      >
        <div className="flex w-screen items-center justify-center gap-2">
          <span className="text-1 font-medium">{labelFindMore}</span>
          <Icon name="search" size="medium" />
        </div>
      </button>
    );
  };

  const handleMouseMove = throttle((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const container = containerRef.current as HTMLDivElement | null;
    if (container) {
      const rect = container.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;

      if (swiper?.isBeginning) {
        setIsPrevVisible(false);
        setIsNextVisible(true);
      } else if (swiper?.isEnd) {
        setIsPrevVisible(true);
        setIsNextVisible(false);
      } else if (mouseX < rect.width / 2) {
        setIsPrevVisible(true);
        setIsNextVisible(false);
      } else {
        setIsPrevVisible(false);
        setIsNextVisible(true);
      }
    }
  }, 100);

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsPrevVisible(false);
      setIsNextVisible(false);
    }, 250);
  };

  const swiperSlideClasses = classnames('h-auto', {
    'w-auto': !sm && !md,
    'w-[37.5%]': sm,
    'w-fit': md
  });

  return (
    <>
      {isLoading ? (
        <div className="flex gap-6 overflow-visible">
          {Array.from({ length: 5 }).map((_, index) => (
            <Shimmer
              key={index}
              className={classnames(
                'size-full h-[400px] rounded-lg',
                'min-w-[75%]',
                'sm:min-w-[37.5%]',
                'md:min-w-[calc(25%-18px)]'
              )}
            />
          ))}
        </div>
      ) : (
        <>
          {cardsCount > 0 ? (
            <>
              {cardsCount >= 3 || type === Type.Library ? (
                <div
                  ref={containerRef}
                  className="relative"
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                  data-component={"ProductCarousel"}
                >
                  <swiper-container init={false} class="swiper-no-overflow" ref={swiperRef}>
                    {cards.map((card, index) => (
                      <swiper-slide key={index} class={swiperSlideClasses}>
                        <CardBuilder
                          cardProps={card}
                          size={cardsSize}
                          listPosition={enumerateCards ? index + 1 : undefined}
                          widthAuto={md}
                          type={type}
                          heightAuto={true}
                          clickOnCardContent={createAnaliticsItem}
                          itemAnalyticsModel={(card as ISearchResultItem)?.itemAnalyticsModel}
                        />
                      </swiper-slide>
                    ))}
                    {cardsCount <= 1 && (
                      <swiper-slide class={swiperSlideClasses}>
                        <FindMoreCard />
                      </swiper-slide>
                    )}
                  </swiper-container>
                  <button
                    id={navPrevId}
                    className={classnames(navClasses, 'left-[-30px]', {
                      'opacity-0': !isPrevVisible,
                      'opacity-100': isPrevVisible
                    })}
                  >
                    <RoundedIcon
                      color="black"
                      icon={{ name: 'arrow-left', size: 'large' }}
                      className={navIconClasses}
                    />
                  </button>
                  <button
                    id={navNextId}
                    className={classnames(navClasses, 'right-[-30px]', {
                      'opacity-0': !isNextVisible,
                      'opacity-100': isNextVisible
                    })}
                  >
                    <RoundedIcon
                      color="black"
                      icon={{ name: 'arrow-right', size: 'large' }}
                      className={navIconClasses}
                    />
                  </button>
                </div>
              ) : (
                <div className={classnames('relative flex flex-col gap-4', 'smmiddle:flex-row')}  data-component={"ProductCarousel"}>
                  {cards.map((card, index) => (
                    <CardBuilder
                      className="*:!max-w-none"
                      key={index}
                      cardProps={card}
                      size={cardsSize}
                      listPosition={enumerateCards ? index + 1 : undefined}
                      widthAuto={true}
                      clickOnCardContent={createAnaliticsItem}
                      itemAnalyticsModel={(card as ISearchResultItem)?.itemAnalyticsModel}
                    />
                  ))}
                </div>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default ProductCarousel;
