import AvatarComponent from '@/components/ui/Avatar';
import Text from '@/components/cms/Text';
import classnames from 'classnames';
import { FacultyMemberBlock as FacultyMemberBlockProps } from '@/@types/content';
import Block from '@/components/cms/Block';
import Link from '@/components/ui/Link';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Icon from '@/components/ui/Icon';

type FacultyMemberBlockExtendedProps = FacultyMemberBlockProps & {
  pageUrl?: string;
  title?: string;
  variant?: string;
};

const FacultyMemberBlock: React.FC<FacultyMemberBlockExtendedProps> = ({
  afilition,
  bioFullName,
  bioImage,
  contentLink,
  location,
  pageUrl,
  position,
  variant,
  title
}) => {
  const screenSizeXsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);

  const infoContainerClasses = classnames({
    'relative flex items-center gap-4': variant !== '4-columns'
  });

  const avatarComponentClasses = classnames({
    'mb-6 w-[180px]': variant === '4-columns',
    'w-[90px] min-w-[90px]': variant !== '4-columns'
  });

  const containerClasses = classnames({
    'w-full': variant !== '4-columns'
  });

  const bioFullNameClasses = classnames({
    'heading-6-medium mb-2': variant === '4-columns',
    'text-1-medium mb-1': variant !== '4-columns'
  });

  const infoClasses = classnames('text-gray-dark', {
    'text-1': variant === '4-columns',
    'text-2': variant !== '4-columns'
  });

  return (
    <Block element="article" contentLinkID={contentLink?.id} className={infoContainerClasses}>
      <AvatarComponent
        propertyName="BioImage"
        imageUrl={bioImage?.url}
        fullName={bioFullName!}
        className={avatarComponentClasses}
        imageSizes={{
          xs: '180px'
        }}
      />
      <div className={containerClasses}>
        <Text element="h3" propertyName="BioFullName" className={bioFullNameClasses}>
          {bioFullName}
        </Text>
        <div className="max-w-[300px]">
          <Text element="p" propertyName="Position" className={infoClasses}>
            {position ? position : title}
          </Text>
          <Text element="p" propertyName="Afilition" className={infoClasses}>
            {afilition}
          </Text>
          <Text element="p" propertyName="Location" className={infoClasses}>
            {location}
          </Text>
          {pageUrl && (
            <Link
              href={pageUrl}
              title={pageUrl}
              text="View profile"
              variant="text-link-1"
              className={classnames(infoClasses, {
                'after:absolute after:inset-0 after:size-full':
                  variant !== '4-columns' && screenSizeXsOnly
              })}
            />
          )}
        </div>
      </div>
      {screenSizeXsOnly && variant !== '4-columns' && (
        <Icon name="chevron-right" size="medium" className="text-black" />
      )}
    </Block>
  );
};

export default FacultyMemberBlock;
