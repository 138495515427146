import { forwardRef } from 'react';
import Icon from '@/components/ui/Icon';

type AddCreditCardProps = {
  onPress?: () => void;
};

const AddCreditCard = forwardRef<HTMLButtonElement, AddCreditCardProps>(
  ({ onPress }, ref): JSX.Element => {
    return (
      <div className="flex size-full min-h-36 items-center justify-center rounded border border-gray-light lg:min-h-[10.5rem]"
      data-component={"AddCreditCard"}
>
        <button
          ref={ref}
          type="button"
          aria-label="Add a card"
          onClick={onPress}
          className="flex items-center gap-x-2.5"
        >
          <Icon name="add" size="small" />
          <span className="text-2 text-gray-dark">Add a card</span>
        </button>
      </div>
    );
  }
);

export default AddCreditCard;
