import type { MobileStoreRedirectPage } from '@/@types/content';
import { memo, useEffect } from 'react';
import Awaiter from '../ui/Awaiter/Awaiter';

interface MobileStoreRedirectPageExtended extends MobileStoreRedirectPage {
  redirectUrl: string | undefined;
}

type MobileStoreRedirectPageProps = MobileStoreRedirectPageExtended;
const MobileStoreRedirectPage: React.FC<MobileStoreRedirectPageProps> = memo(props => {
  const { redirectUrl } = props;

  useEffect(() => {
    window.location.href = redirectUrl ?? '/404';
  }, [redirectUrl]);

  return <Awaiter content={['Redirecting...', 'Hang in there...', 'One moment...']} />;
});

MobileStoreRedirectPage.displayName = 'MobileStoreRedirectPage';
export default MobileStoreRedirectPage;
