import { useGetProductRedirectContentQuery } from '@/redux/api/client/product';
import ErrorPage from '@/routes/ErrorPage';
import PageLoading from '@/routes/PageLoading';
import { useParams } from 'react-router-dom';

const ProductContentResolver = () => {
  const code = useParams()['code'];
  const { data: productLink, isLoading } = useGetProductRedirectContentQuery(code!);

  const url = productLink ? new URL(productLink) : undefined;
  if (isLoading) return <PageLoading />;
  if (url) {
    window.location.href = url.href;
    return null;
  }
  return <ErrorPage />;
};

export default ProductContentResolver;
