import { PersonalInfoPracticeAreasBlock as PersonalInfoPracticeAreasBlockProps } from '@/@types/content';
import PracticeAreaSection from '@/components/ui/ProfileSections/PracticeAreaSection';
import { IComponentProps } from '@/lib/ComponentFactory';

const PersonalInfoPracticeAreasBlock: React.FC<
  PersonalInfoPracticeAreasBlockProps & IComponentProps
> = props => {
  return <PracticeAreaSection {...props} />;
};

export default PersonalInfoPracticeAreasBlock;
