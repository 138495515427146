import Icon from '@/components/ui/Icon';
import classnames from 'classnames';
import ShareModal from '@/components/ui/PDP/Modals/ShareModal';
import { useState } from 'react';
import { Theme } from '@/styles/darkMode';
import { getTextColorClass } from '@/utils/theme';

interface ShareButtonProps {
  className?: string;
  shareUrl: string;
  title?: string;
  theme?: Theme;
  buttonClassname?: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({
  className,
  shareUrl = window.location.href,
  title,
  theme,
  buttonClassname
}) => {
  const [openShareModal, setOpenShareModal] = useState(false);

  const shareButtonFunction = () => {
    setOpenShareModal(!openShareModal);
  };
  const shareButtonClasses = classnames('relative size-fit', className);

  const iconClasses = classnames('rounded-full border  p-[7px]', {
    'border-black text-black': theme === Theme.Light,
    'border-white text-white': theme === Theme.Dark
  });

  const buttonClasses = classnames(
    'text-1-medium relative z-1 flex items-center gap-2 py-2',
    getTextColorClass(theme),
    buttonClassname
  );

  return (
    <>
      <div className={shareButtonClasses}>
        <button onClick={shareButtonFunction} className={buttonClasses}>
          <div className={iconClasses}>
            <Icon name="share" size="small" />
          </div>
          Share
        </button>
      </div>
      <ShareModal
        open={openShareModal}
        setOpen={setOpenShareModal}
        variantTitle={title || ''}
        variantUrl={shareUrl}
      />
    </>
  );
};

export default ShareButton;
