import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  ready?: boolean;
  token?: string | null;
  name?: string | null;
}

const initialState: AuthState = {
  ready: false,
  token: undefined,
  name: undefined
};

export interface UserState {
  token?: string | null;
  name?: string | null;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLoaded: (state, action: PayloadAction<UserState>) => {
      state.name = action.payload?.name;
      state.token = action.payload?.token;
      state.ready = true;
      window.env.IS_AUTHENTICATED = action.payload?.token ? 'true' : 'false';
      console.info('DIAG:User State loaded ...');
    },
    userUnloaded: state => {
      state.name = undefined;
      state.token = undefined;
      state.ready = true;
      window.env.IS_AUTHENTICATED = 'false';
      console.info('DIAG:User State unloaded ...');
    }
  }
});

// Action creators are generated for each case reducer function
export const { userLoaded, userUnloaded } = authSlice.actions;

export default authSlice.reducer;
