import { addDays, endOfMonth, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import CalendarWeek from './CalendarWeek';

const addDateToCalendar = (calendar: Date[][], date: Date): Date[][] => {
  if (calendar.length === 0) {
    calendar.push([date]);
    return calendar;
  }
  const lastWeek = calendar[calendar.length - 1];
  if (lastWeek.length === 7) {
    calendar.push([date]);
  } else {
    lastWeek.push(date);
  }
  return calendar;
};

const getCalendar = (defaultMonth: Date): Date[][] => {
  const calendar: Date[][] = [];
  //getting first and last day of month
  const firstMonthDay = startOfMonth(defaultMonth);
  const lastMonthDay = endOfMonth(defaultMonth);
  //complete calendar with dates from the previous month
  for (let i = firstMonthDay.getDay(); i > 0; i--) {
    addDateToCalendar(calendar, addDays(firstMonthDay, i * -1));
  }
  //add days from 1 to last day of month
  const currentDate = firstMonthDay;
  while (currentDate <= lastMonthDay) {
    addDateToCalendar(calendar, new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  //complete calendar with date from the next month
  const lastWeek = calendar[calendar.length - 1];
  for (let i = lastWeek.length + 1; i <= 7; i++) {
    addDateToCalendar(calendar, new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return calendar;
};

type CalendarMonthProps = {
  currentDate: Date;
  selectedDate?: Date;
  onSelect?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
};

const CalendarMonth = ({
  currentDate,
  selectedDate,
  onSelect,
  minDate,
  maxDate
}: CalendarMonthProps): JSX.Element => {
  const calendar = useMemo(() => getCalendar(currentDate), [currentDate]);

  return (
    <div       data-component={"CalendarWeek"}>
      {calendar.map((weekDates, index) => (
        <CalendarWeek
          key={weekDates[0].toISOString()}
          dates={weekDates}
          selectedDate={selectedDate}
          onSelect={onSelect}
          currentDate={currentDate}
          minDate={minDate}
          maxDate={maxDate}
          className={index > 0 ? 'mt-4' : undefined}
        />
      ))}
    </div>
  );
};

export default CalendarMonth;
