import { useDispatch } from 'react-redux';
import Filter from '../Filter/Filter';
import { translateStoreFilterToComponentProps } from './utils/translateStoreDataToComponentsProps';
import {
  ALLOWED_SECTIONED_FILTERS_PANEL_L2,
  FILTERS,
  FILTER_BEHAVIOR_TO_TYPE,
  FilterBehavior,
  FilterTypes
} from './constants';
import { toggleFilter } from '@/redux/slices/searchSlice';
import useUnfoldedFilterGroup from './hooks/useUnfoldedFilterGroup';
import useJurisdictonsWithSectionProps from './hooks/useJurisdictonsWithSectionProps';
import Shimmer from '../Shimmer';
import SubPanel, { SubPanelProps } from '../Panels/SubPanel';
import SearchResultsFilterOverlayFooter from './SearchResultsFilterOverlayFooter';
import { FilterProps } from '../Filter/types';
import FilterShimmer from '../SearchShimmer/FilterShimmer';
import { useCallback } from 'react';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import { FilterContext, SearchContext } from '@/analytics/constants';

type SearchResultsFilterOverlayProps = Pick<
  SubPanelProps,
  'isOpen' | 'onOpenChange' | 'triggerButton'
> & {
  filter: FilterProps;
  ariaHint: string;
  onClose: () => void;
  onBack: () => void;
  onClear: ({ groupId }: { groupId: string | undefined }) => void;
  fetching: boolean;
  context?: FilterContext;
  searchContext?: SearchContext;
};

const SearchResultsFilterOverlayL2: React.FC<SearchResultsFilterOverlayProps> = ({
  isOpen,
  filter,
  ariaHint,
  onOpenChange,
  onClose,
  onBack,
  onClear,
  fetching,
  triggerButton,
  context,
  searchContext
}) => {
  const dispatch = useDispatch();

  const subpanelSubmitButtonLabel = 'Confirm';
  const subpanelSubmitButtonAriaLabel = `${subpanelSubmitButtonLabel}. ${ariaHint}`;

  const groupId = filter?.groupTypeId;

  const { filterGroup } = useUnfoldedFilterGroup({
    groupId,
    skip: !isOpen
  });

  const { jurisdictionsWithSectionProps, isLoading: isJurisdictonsLoading } =
    useJurisdictonsWithSectionProps({
      skip: !isOpen || !filterGroup || groupId !== FILTERS.JURISDICTIONS,
      filterGroup
    });

  const mappedGroup = filterGroup ? translateStoreFilterToComponentProps(filterGroup) : null;

  const debouncedLoading = useDebounceLoading(isJurisdictonsLoading || !mappedGroup?.items.length);

  const handleOnBack = useCallback(() => {
    onOpenChange?.(false);
    onBack();
  }, [onBack, onOpenChange]);

  const handleOnOpenChange = useCallback(
    (open: boolean) => {
      onOpenChange?.(open);
      if (!open) {
        onClose();
      }
    },
    [onClose, onOpenChange]
  );

  if (groupId === FILTERS.JURISDICTIONS && mappedGroup) {
    if (!jurisdictionsWithSectionProps) return null;
    mappedGroup.items = jurisdictionsWithSectionProps;
  }

  const handleValueChange = (value?: string) => {
    if (!filterGroup) {
      return;
    }
    dispatch(
      toggleFilter({
        groupTypeId: filterGroup.groupTypeId || '',
        value: value || '',
        type:
          (filterGroup.behavior &&
            FILTER_BEHAVIOR_TO_TYPE[filterGroup.behavior as FilterBehavior]) ||
          FilterTypes.SELECT,
        name: filterGroup.facets?.find(facet => facet.value === value)?.name || '',
        context,
        searchContext
      })
    );
  };

  return (
    <SubPanel
      isOpen={isOpen}
      isL2Panel={true}
      titleSubPanel={debouncedLoading ? <Shimmer className="h-7 w-2/3" /> : filter?.title || ''}
      onBackButtonPress={handleOnBack}
      onOpenChange={handleOnOpenChange}
      triggerButton={triggerButton}
      footer={
        <SearchResultsFilterOverlayFooter
          clearLabel="Clear filters"
          onClear={() => {
            onClear({ groupId: filter?.groupTypeId });
          }}
          onSubmit={handleOnBack}
          submitLabel={subpanelSubmitButtonLabel}
          submitAriaLabel={subpanelSubmitButtonAriaLabel}
        />
      }
    >
      <div className="mb-8">
        {debouncedLoading && (
          <FilterShimmer
            type={filter?.type || 'check'}
            hasTitle={filter?.groupTypeId == FILTERS.JURISDICTIONS}
          />
        )}
        {mappedGroup && !debouncedLoading ? (
          <Filter
            {...mappedGroup}
            title={undefined}
            collapsible={false}
            onValueChange={handleValueChange}
            allowSections={!!groupId && ALLOWED_SECTIONED_FILTERS_PANEL_L2.includes(groupId)}
            isL2Panel
            fetching={fetching}
          />
        ) : null}
      </div>
    </SubPanel>
  );
};

export default SearchResultsFilterOverlayL2;
