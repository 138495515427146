import { FIRST_PAGE, PAGE_SIZE } from '@/components/ui/SearchResults/constants';

export const getCurrentPage = (
  apiCurrentPage: number = FIRST_PAGE,
  currentResultCount?: number,
  pageSize = PAGE_SIZE
) => {
  const currentPageSize = currentResultCount ?? 0;
  if (currentPageSize === 0) {
    return FIRST_PAGE;
  }
  if (apiCurrentPage !== FIRST_PAGE) {
    return apiCurrentPage;
  }
  return Math.ceil(currentPageSize / pageSize);
};
