import { LinkGridBlock } from '@/@types/content';
import LinkGridBlockContent, { LinkGridBlockContentProps } from './LinkGridBlockContent';
import Link from '@/components/ui/Link';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';

type LinkGridBlockNonCollapsibleProps = LinkGridBlockContentProps &
  Pick<LinkGridBlock, 'viewMoreLink'>;

const LinkGridBlockNonCollapsible = ({
  linkBlocks,
  layout,
  viewMoreLink,
  onTrackItemViewEvent
}: LinkGridBlockNonCollapsibleProps): JSX.Element => {
  const viewMoreLinkCopy = { ...viewMoreLink };

  if (viewMoreLinkCopy.contentLink) {
    delete viewMoreLinkCopy.contentLink;
  }

  const modeEdit = isEditOrPreviewMode();
  return (
    <>
      <LinkGridBlockContent
        linkBlocks={linkBlocks}
        layout={layout}
        onTrackItemViewEvent={onTrackItemViewEvent}
      />
      {(viewMoreLinkCopy || modeEdit) && (
        <Link
          onClick={() => {
            if (viewMoreLinkCopy.text && typeof viewMoreLinkCopy.text === 'string') {
              onTrackItemViewEvent?.(viewMoreLinkCopy.text as string);
            }
          }}
          {...viewMoreLinkCopy}
          propertyName="ViewMoreLink"
          variant="bold-link-with-icon"
          icon="chevron-right"
          className="mt-8"
        />
      )}
    </>
  );
};

export default LinkGridBlockNonCollapsible;
