import SectionTitle, { IContentAvatar } from '@/components/ui/Titles/SectionTitle';
import ScrollArea from '@/components/ui/ScrollArea';
import ProductCarousel from '@/components/ui/Carousel/ProductCarousel';
import ComplexTooltip from '@/components/ui/Tooltip/ComplexTooltip';
import Link from '@/components/ui/Link';
import type {
  BasicContentRowContainerBlock as BasicContentRowContainerBlockProps,
  FilterBlock,
  TooltipBlock
} from '@/@types/content';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetCardQuery } from '@/redux/api/client/card';
import TagButton from '@/components/ui/Buttons/TagButton';
import Icon from '@/components/ui/Icon';
import classnames from 'classnames';
import { IContent } from '@/@types/cms';
import { ISearchResultItem } from '@/@types/client-api/models/ISearchResultItem';
import ProductList from '@/components/ui/ProductList';
import Block from '@/components/cms/Block';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';
import Shimmer from '@/components/ui/Shimmer';
import { IFacetResults, ItemAnalyticsModel } from '@/@types/client-api';
import { aggregateUserData } from '../ui/SearchResults/Snippets/helpers';
import { SearchableType } from '../ui/SearchResults/searchableTypeToSnippetVariant';
import { useWalkthrough } from '@/components/blocks/WalkthroughExperienceModalBlock.tsx';
import { trackSelectItem, trackViewItemListEvent } from '@/analytics/ecommerce';
import { AnalyticsContext } from '@/analytics/constants';

interface FilterBlockExtended extends FilterBlock {
  editorOverridesPositive?: IContent[];
  editorOverridesNegative?: IContent[];
  editorExclusions?: IContent[];
}

interface IContentWithCode extends IContent {
  code?: string;
  productCode?: string;
  parentProductCode?: string;
}

interface ContainedCategory {
  id?: string;
  name?: string;
  description?: string;
}

interface DataFilter {
  facetResults?: IFacetResults;
  searchResults?: {
    searchResultsItems?: Record<string, unknown>[];
  };
}

interface BlockProps extends BasicContentRowContainerBlockProps {
  overrideCategory?: string | number | undefined;
  followedFacultyImages?: string[];
  overrideContainedCategories?: ContainedCategory[];
  overrideCollectionUrl?: string;
  overrideHeading?: string;
  walkthroughSteps?: Array<IContent & { applyFor?: string }>;
}

interface CarouselViewport {
  first: number;
  last: number;
}

enum Type {
  Standard = 'standard',
  Faculty = 'faculty',
  Library = 'library'
}

const facetGroupsToCheck = ['MyLibrary', 'MyProfile'];
const facultyParams = ['FacultyMembers', 'ProgramsFromFirmsColleague'];

const BasicContentRowContainerBlock: React.FC<BlockProps> = ({
  contentLink,
  type,
  enumerateCards = false,
  heading: propsHeading,
  subheading,
  icon,
  tooltip,
  seeAllLink,
  feeds: blockFeeds,
  findMoreLabel,
  avatarImageList,
  overrideCategory,
  displayContentAsList = false,
  followedFacultyImages,
  overrideContainedCategories,
  overrideCollectionUrl,
  fillFeedDynamically,
  format,
  overrideHeading,
  walkthroughSteps
}) => {
  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

  const walkthroughtContext = useWalkthrough();
  const dispatchWalkthrought = walkthroughtContext?.dispatch;

  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchResultsItems, setSearchResultsItems] = useState<Record<string, unknown>[]>([]);
  const [shouldFetchFilter, setShouldFetchFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterBlockExtended>({});
  const [shouldFetchOverride, setShouldFetchOverride] = useState(false);
  const [overrideUrl, setOverrideUrl] = useState<string | undefined>(undefined);
  const [overridePositiveCodes, setOverridePositiveCodes] = useState<string[]>([]);
  const [overrideNegativeCodes, setOverrideNegativeCodes] = useState<string[]>([]);
  const [isInView, setIsInView] = useState(false);

  const [carouselViewport, setCarouselViewport] = useState<CarouselViewport>(
    {} as CarouselViewport
  );

  const heading = propsHeading?.replace('{content-name}', overrideHeading ?? '');
  const avatarImagesLimit = 2;

  const blockRef = useRef<HTMLDivElement | null>(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(true);
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const {
    currentData: dataFilter,
    refetch: refetchFilter,
    isLoading: isLoadingFilter,
    isFetching: isFetchingFilter,
    isUninitialized: isUninitializedFilter
  } = useGetCardQuery(selectedFilter.value as string, {
    skip: !shouldFetchFilter || !isInView
  });

  const {
    currentData: dataOverride,
    refetch: refetchOverride,
    isLoading: isLoadingOverride,
    isFetching: isFetchingOverride,
    isUninitialized: isUninitializedOverride
  } = useGetCardQuery(overrideUrl as string, {
    skip: !shouldFetchOverride || !isInView
  });

  const feedHasFacetItems = (feedUrl?: string, facetResults?: IFacetResults) => {
    if (feedUrl) {
      const searchParams = new URLSearchParams(new URL(feedUrl, window.location.origin).search);
      for (const facetGroupName of facetGroupsToCheck) {
        if (searchParams.has(facetGroupName)) {
          const facetValue = searchParams.get(facetGroupName);
          const facet = facetResults?.facetGroups
            ?.find(fg => fg.groupTypeId == facetGroupName)
            ?.facets?.find(f => f.value == facetValue);

          return !(facet && facet.count == 0);
        }
      }
    }

    return true;
  };

  const hasEmptyFacultyQueryParam = (feedUrl?: string) => {
    if (feedUrl) {
      const searchParams = new URLSearchParams(new URL(feedUrl, window.location.origin).search);
      return facultyParams.some(param => searchParams.has(param) && !searchParams.get(param));
    }

    return false;
  };

  const feeds = useMemo(
    () => {
      if (
        fillFeedDynamically &&
        overrideContainedCategories &&
        overrideContainedCategories.length
      ) {
        return overrideContainedCategories.map(category => {
          const modifiedCollectionUrl = overrideCollectionUrl?.replace(
            /\[id\]/i,
            category.id as string
          );

          return {
            text: category.name || '',
            value: modifiedCollectionUrl || ''
          };
        });
      }

      return blockFeeds
        ?.filter(blockFeed => type !== 'faculty' || !hasEmptyFacultyQueryParam(blockFeed?.value))
        ?.filter(blockFeed =>
          feedHasFacetItems(blockFeed?.value, (dataFilter as unknown as DataFilter)?.facetResults)
        ) as FilterBlockExtended[];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fillFeedDynamically,
      overrideContainedCategories,
      overrideCollectionUrl,
      blockFeeds,
      isLoadingFilter,
      isUninitializedFilter
    ]
  );

  let dataTooltip: React.ComponentProps<typeof ComplexTooltip> | undefined = undefined;

  const SetCarouselViewport = useCallback((first: number, last: number) => {
    setCarouselViewport({
      first,
      last
    } as CarouselViewport);
  }, []);

  const getCodes = useCallback((codes: IContent[] | undefined, asArray = false) => {
    if (!codes) {
      return undefined;
    }

    const filteredCodes = codes
      .filter(override => (override.contentLink?.expanded as IContentWithCode).code)
      .map(override => {
        const code = (override.contentLink?.expanded as IContentWithCode).code;
        const parentProductCode = (override.contentLink?.expanded as IContentWithCode)
          .parentProductCode;
        return parentProductCode ? `${parentProductCode}|${code}` : code;
      });

    if (asArray) {
      return filteredCodes;
    } else {
      return filteredCodes.length > 0 ? filteredCodes.join(';') : undefined;
    }
  }, []);

  const fetchFeed = useCallback(
    (feed: FilterBlockExtended) => {
      const modifiedFilter = { ...feed };

      const {
        value,
        editorOverridesPositive,
        editorOverridesNegative,
        editorExclusions,
        maxNumberOfItems
      } = feed;

      setOverridePositiveCodes([]);
      setOverrideNegativeCodes([]);

      const editorOverridesPositiveCodes = getCodes(editorOverridesPositive, true) ?? [];
      const editorOverridesNegativeCodes = getCodes(editorOverridesNegative, true) ?? [];

      setOverridePositiveCodes(editorOverridesPositiveCodes as string[]);
      setOverrideNegativeCodes(editorOverridesNegativeCodes as string[]);

      const mergedOverrideCodes = [
        ...editorOverridesPositiveCodes,
        ...editorOverridesNegativeCodes
      ];
      const editorOverrideCodes = mergedOverrideCodes.join(';');

      if (editorOverrideCodes) {
        const editorOverrideUrl = `/search/product-code?ProductCodes=${editorOverrideCodes}`;
        setOverrideUrl(editorOverrideUrl);

        if (isUninitializedOverride) {
          setShouldFetchOverride(true);
        } else {
          refetchOverride();
        }
      }

      if (overrideCategory && value && value.toLowerCase().includes('[id]')) {
        modifiedFilter.value = value.replace(/\[id\]/i, overrideCategory as string);
      }

      if (value) {
        const editorExclusionCodes = getCodes(editorExclusions);

        let modifiedFilterValue = modifiedFilter.value;

        if (editorExclusionCodes) {
          const queryString = `ExcludeCode=${editorExclusionCodes}`;

          if (modifiedFilterValue?.includes('?')) {
            modifiedFilterValue += `&${queryString}`;
          } else {
            modifiedFilterValue += `?${queryString}`;
          }
        }

        const searchParams = new URLSearchParams(new URL(value, window.location.origin).search);
        if (!searchParams.has('PageSize') && maxNumberOfItems) {
          modifiedFilterValue += `${modifiedFilterValue!.includes('?') ? '&' : '?'}PageSize=${maxNumberOfItems}`;
        }

        modifiedFilter.value = modifiedFilterValue;

        if (isUninitializedFilter) {
          setShouldFetchFilter(true);
        } else {
          refetchFilter();
        }
      }

      setSelectedFilter(modifiedFilter);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCodes, refetchFilter, refetchOverride, setShouldFetchFilter]
  );

  useEffect(() => {
    if (feeds && feeds.length) {
      fetchFeed(feeds[0]);
    }
  }, [feeds, fetchFeed]);

  useEffect(() => {
    setLoading(isLoadingFilter || isFetchingFilter || isLoadingOverride || isFetchingOverride);

    if (!dataFilter && !dataOverride) return;

    const overridePositiveData =
      (dataOverride as unknown as IContentWithCode[])?.filter((override: IContentWithCode) => {
        const positiveCodes = overridePositiveCodes.flatMap(code => code.split('|'));
        return positiveCodes?.includes(override.productCode as string);
      }) || ([] as Record<string, unknown>[]);

    const overrideNegativeData =
      (dataOverride as unknown as IContentWithCode[])?.filter((override: IContentWithCode) => {
        const negativeCodes = overrideNegativeCodes.flatMap(code => code.split('|'));
        return negativeCodes?.includes(override.productCode as string);
      }) || ([] as Record<string, unknown>[]);

    const searchResultsItemsFeed = (dataFilter as unknown as DataFilter)?.searchResults
      ?.searchResultsItems;

    const filteredSearchResultsItemsFeed = searchResultsItemsFeed?.filter(
      (item: IContentWithCode) =>
        !overridePositiveCodes.includes(item.productCode as string) &&
        !overrideNegativeCodes.includes(item.productCode as string)
    );

    const newSearchResultsData = [
      ...((overridePositiveData ?? []) as Record<string, unknown>[]),
      ...((filteredSearchResultsItemsFeed ?? []) as Record<string, unknown>[]),
      ...((overrideNegativeData ?? []) as Record<string, unknown>[])
    ];

    setSearchResultsItems(newSearchResultsData);
    setFirstLoad(false);
  }, [
    dataFilter,
    isLoadingFilter,
    isFetchingFilter,
    dataOverride,
    isLoadingOverride,
    isFetchingOverride,
    overridePositiveCodes,
    overrideNegativeCodes,
    isInView
  ]);

  if (tooltip && tooltip.length) {
    const {
      title: tooltipTitle,
      copy: tooltipCopy,
      link: tooltipLink
    } = tooltip[0] as TooltipBlock;

    dataTooltip = {
      children: (
        <>
          <ComplexTooltip.Trigger>
            <Icon name="info" size="small" className="text-gray" />
          </ComplexTooltip.Trigger>

          <ComplexTooltip.Content>
            <div>
              {tooltipTitle && <p className="text-2 mb-1 font-bold">{tooltipTitle}</p>}
              {tooltipCopy && <p className="text-2 mb-2">{tooltipCopy}</p>}
              {tooltipLink && (
                <Link
                  href={tooltipLink?.href}
                  text={tooltipLink?.text}
                  title={tooltipLink?.title}
                  target={tooltipLink?.target}
                />
              )}
            </div>
          </ComplexTooltip.Content>
        </>
      )
    };
  }

  const handleFilterClick = (filter: FilterBlockExtended) => {
    fetchFeed(filter);
  };

  const handleClickFindMore = () => {
    document.getElementById('search-overlay-trigger')?.click();
  };

  const getTitleSizeByFormat = (format?: string) => {
    switch (format) {
      case 'h6':
        return 'h6';
      case 'h5':
        return 'h5';
      case 'h4':
        return 'h4';
      case 'h3':
        return 'h3';
      case 'h2':
        return 'h2';
      case 'h1':
        return 'h1';
      default:
        return 'h5';
    }
  };
  const titleSizeByFormat = getTitleSizeByFormat(format);

  const filteredItems = useMemo(() => {
    let items =
      (selectedFilter?.hidePurchased
        ? searchResultsItems?.filter(c => {
            const item = c?.value as ISearchResultItem;
            const userList = item?.userList;
            const variant = item?.searchableType?.toLowerCase();
            if (!userList || !variant) {
              return true;
            }

            const userAggregatedData = aggregateUserData({
              userList,
              variant: variant as SearchableType
            });

            return !userAggregatedData?.isPurchased;
          })
        : searchResultsItems) || [];

    if (selectedFilter?.maxNumberOfItems && selectedFilter?.maxNumberOfItems > 0) {
      items = items.slice(0, selectedFilter?.maxNumberOfItems);
    }

    return items;
  }, [selectedFilter, searchResultsItems]);

  const listId = useMemo(() => {
    const contentId = contentLink?.id ?? '';
    const formattedHeading = heading?.toLowerCase().replace(/\s+/g, '_') ?? '';
    return `${contentId}_${formattedHeading}` || 'BasicContentRow';
  }, [contentLink?.id, heading]);

  useEffect(() => {
    const targetElement = blockRef.current;

    if (!targetElement) return;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsInView(true);
            if (filteredItems.length > 0 && hasScrolled) {
              trackViewItemListEvent(
                listId,
                heading || '',
                filteredItems
                  .slice(carouselViewport?.first, carouselViewport?.last + 1)
                  .map(item => (item as ISearchResultItem).itemAnalyticsModel)
                  .filter((item): item is ItemAnalyticsModel => item !== undefined) // Filters undefined
              );
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(targetElement);

    return () => {
      observer.unobserve(targetElement);
    };
  }, [hasScrolled, carouselViewport, filteredItems, heading, listId]);

  const clickOnCard = useCallback(
    (card: Record<string, unknown>) => {
      trackSelectItem(
        (card as ISearchResultItem)?.itemAnalyticsModel,
        AnalyticsContext.Cards,
        listId,
        heading
      );
    },
    [listId, heading]
  );

  let avatarImages: IContentAvatar[] | undefined = undefined;
  if (followedFacultyImages && followedFacultyImages.length > 0) {
    avatarImages = followedFacultyImages.map(image => ({
      fullName: '',
      image: {
        url: image
      },
      icon: image ? undefined : 'profile'
    }));
  } else if (avatarImageList && avatarImageList.length > 0) {
    avatarImages = avatarImageList as IContentAvatar[];
  }

  if (avatarImages && avatarImages.length > avatarImagesLimit) {
    avatarImages = avatarImages.slice(0, avatarImagesLimit);
  }

  const [firstLoadLibrary, setFirstLoadLibrary] = useState<boolean>(false);

  if (
    !feeds ||
    feeds.length === 0 ||
    (feeds.length === 1 && !loading && !searchResultsItems.length && isInView)
  ) {
    if (
      type === 'library' &&
      !firstLoadLibrary &&
      !sessionStorage.getItem('lightboxModalSessionDismissed')
    ) {
      setFirstLoadLibrary(true);
      dispatchWalkthrought?.({ type: 'DELETE_STEP', applyFor: 'library' });
    }
    return null;
  }

  return (
    <Block contentLinkID={contentLink?.id} className="module-spacing w-full overflow-x-clip">
      <div
        ref={blockRef}
        className="container flex flex-col gap-8 overflow-x-clip"
        data-component={'BasicContentRowContainerBlock'}
      >
        {firstLoad || isLoadingFilter ? (
          <Shimmer className="h-14 w-1/3" />
        ) : (
          <SectionTitle
            propertyNameAvatar=""
            propertyNameIcon=""
            propertyNameTitle="Heading"
            propertyNameSubcopy=""
            title={heading || ''}
            subcopy={subheading}
            tooltip={dataTooltip}
            tooltipWalkthrough={
              type == Type.Library && walkthroughSteps
                ? {
                    index: walkthroughSteps.findIndex(step => step.applyFor === 'library'),
                    walkthroughSteps: walkthroughSteps.length,
                    ...walkthroughSteps.find(step => step.applyFor === 'library')
                  }
                : undefined
            }
            titleSize={titleSizeByFormat}
            icon={icon ? { name: icon } : undefined}
            avatars={avatarImages}
            elementType="h2"
          >
            {seeAllLink && (
              <Link variant="bold-link-with-icon" icon="chevron-right" {...seeAllLink} />
            )}
          </SectionTitle>
        )}

        {feeds && ((feeds.length > 1 && !displayContentAsList) || type == Type.Library) ? (
          <ScrollArea
            orientation="horizontal"
            className="relative left-1/2 ml-[-50vw] w-screen overflow-visible"
            barClassName="!-bottom-3 px-6"
          >
            <div className="container flex gap-2">
              {feeds.map((button, index) => {
                return (
                  <Fragment key={index}>
                    {firstLoad || isLoadingFilter ? (
                      <Shimmer className="h-14 w-40" />
                    ) : (
                      <TagButton
                        styleType={button.text === selectedFilter.text ? 'black' : 'outline'}
                        isActive={button.text === selectedFilter.text}
                        label={button.text}
                        disabled={loading}
                        onClick={() => {
                          if (button.text !== selectedFilter.text) {
                            handleFilterClick(button);
                          }
                        }}
                        type="button"
                        className={classnames({
                          '!border-black': button.text === selectedFilter.text
                        })}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          </ScrollArea>
        ) : null}

        {!loading && !searchResultsItems.length && !firstLoad ? (
          <div>
            <p className="text-1">{stateSiteLabels?.noResultsLabel}</p>
          </div>
        ) : null}

        {displayContentAsList ? (
          <ProductList
            cards={filteredItems}
            isLoading={loading}
            seeMoreLabel={findMoreLabel}
            listLimit={4}
          />
        ) : (
          <ProductCarousel
            type={type as Type}
            cards={filteredItems}
            labelFindMore={findMoreLabel || ''}
            onClickFindMore={handleClickFindMore}
            isLoading={loading}
            enumerateCards={enumerateCards}
            SetCarouselViewport={SetCarouselViewport}
            createAnaliticsItem={clickOnCard}
          />
        )}
      </div>
    </Block>
  );
};

export default BasicContentRowContainerBlock;
