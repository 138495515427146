import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import Button from '@/components/ui/Buttons/Button';
import classnames from 'classnames';
import Text from '@/components/cms/Text';
import type { GenericContentCardBlock as GenericContentCardBlockProps } from '@/@types/content';
import Image from '@/components/cms/Image';
import { isExternalUrl } from '@/utils/helpers';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMemo } from 'react';

const ContentCard: React.FC<GenericContentCardBlockProps> = ({
  theme = 'default',
  heading,
  copy,
  link,
  image
}) => {
  const editMode = isEditOrPreviewMode();

  const lg = useMediaQuery(ScreenSizeQueries.lg);
  const sm = useMediaQuery(ScreenSizeQueries.sm);

  const ratio = useMemo(() => {
    if (sm) return 1;
    if (lg) return 7 / 10;
    return 3 / 2;
  }, [lg, sm]);

  const cardClasses = classnames(
    'relative z-0 grid overflow-hidden rounded-lg bg-white sm:grid-cols-2 sm:items-center lg:items-start'
  );

  const imageContainerClasses = classnames(
    'aspect-[640/427] size-full object-cover',
    'sm:aspect-square',
    'sm:[&>div]:min-h-full'
  );

  const contentContainerClasses = classnames(
    'flex h-full flex-col justify-between p-8 sm:justify-center lg:justify-between lg:p-6'
  );

  const contentHeadingClasses = classnames('heading-5 ', {
    'mb-2 bg-gradient-to-r from-red-brand to-amethist bg-clip-text pb-2 text-transparent':
      theme === 'Probono',
    'mb-4 text-gold': theme === 'Gold',
    'mb-4 text-black': theme === 'Default',
    'mb-4 text-red': theme === 'Paralegal Center'
  });

  const contentCopyClasses = classnames('text-1 mb-8 text-left text-gray-dark');

  return (
    <article className={cardClasses} data-component={'ContentCard'}>
      <div className={contentContainerClasses}>
        <div>
          <Text element="h3" propertyName="Heading" className={contentHeadingClasses}>
            {heading}
          </Text>
          <Text element="p" propertyName="Copy" className={contentCopyClasses}>
            {copy}
          </Text>
        </div>
        <div>
          {(link || editMode) && (
            <Button
              target={link?.target}
              label={link?.text}
              color="outline-black"
              size="large"
              href={link?.href}
              iconRight={
                link.target === '_blank' || isExternalUrl(link.href!) ? 'link-out' : undefined
              }
            />
          )}
        </div>
      </div>
      <div className={imageContainerClasses}>
        <Image
          src={image?.url}
          alt={image?.altText as string}
          propertyName="Image"
          responsive={{
            loading: 'lazy',
            aspectRatio: ratio,
            imageSizes: {
              xs: '400px',
              sm: '270px',
              md: '400px'
            }
          }}
        />
      </div>
    </article>
  );
};

export default ContentCard;
