import { createContext, useContext } from 'react';

type EditPanelContextType = {
  validationFailed: boolean;
};

const initialValue: EditPanelContextType = { validationFailed: false };

const EditPanelContext = createContext<EditPanelContextType>(initialValue);

type EditPanelProviderProps = ComponentWithChildren & { validationFailed: boolean };

export const EditPanelProvider = ({ children, validationFailed }: EditPanelProviderProps) => (
  <EditPanelContext.Provider value={{ validationFailed }}>{children}</EditPanelContext.Provider>
);

// eslint-disable-next-line react-refresh/only-export-components
export const useEditPanelContext = (): EditPanelContextType => {
  const context = useContext(EditPanelContext);
  if (context === undefined) {
    throw new Error('useEditPanelContext must be used within a EditPanelProvider');
  }
  return context;
};

export default EditPanelProvider;
