import type { User } from 'oidc-client-ts';
import type { OIDCState } from '@/auth/oidcConfig';
import { store } from '@/redux/store';
import { internalSearchEndpoints } from '@/redux/api/client/searchInternal';
import { unknownToBoolean } from '@/utils/helpers';
import { getIsUserPurchaseRequired } from '@/components/ui/SearchResults/Snippets/helpers';
import searchableTypeToSnippetVariant from '@/components/ui/SearchResults/searchableTypeToSnippetVariant';

import { userActionsMapper, endpointArgs, PurchasedKeys, PMKeys } from './delayedActionsMapper';

const getSearchItem = async (pk: string) => {
  return store.dispatch(
    internalSearchEndpoints.endpoints.getSearchResultsItems.initiate({
      requestBody: { query: pk }
    })
  );
};

export const buildPostSignInAction = async (state: OIDCState, user: User) => {
  const isPM = unknownToBoolean(user?.profile.IsPM) ? PMKeys.PM : PMKeys.NonPM;
  let purchaseStatus: PurchasedKeys | null = null;

  const endpoint = state?.endpoint;
  const arg = state?.arg;
  let body = undefined;
  let param = undefined;
  let url = null;
  let message = null;

  if (endpoint && typeof arg === 'object') {
    body = 'body' in arg && typeof arg.body === 'object' ? arg.body : undefined;
    param = 'param' in arg && typeof arg.param === 'string' ? arg.param : undefined;
    url = arg.url;
  }

  if (endpoint && (body || param)) {
    const from = endpoint;
    let searchableType;
    let program;
    let code = null;

    if (body && 'code' in body) {
      code = body.code;
    } else if (param && !Number.isNaN(Number.parseInt(param))) {
      code = param;
    }

    try {
      if (code) {
        const { data } = await getSearchItem(code);
        const { searchResults: { searchResultsItems = [] } = {} } = data || {};
        const searchItem = searchResultsItems?.length && searchResultsItems[0];
        if (searchItem) {
          program = searchItem;
          const { title = '', url = '' } = program || {};
          message = { program: { title: encodeURIComponent(title ?? '') || '', url: url || '' } };
          const userList = searchItem.userList;
          searchableType = searchableTypeToSnippetVariant(searchItem.searchableType ?? '');
          const variant = userList?.find(u => u.pk === body.code);
          if (variant && searchableType) {
            const { isPurchased = false, price = 9999 } = variant;
            purchaseStatus = getIsUserPurchaseRequired(isPurchased, price, searchableType)
              ? PurchasedKeys.NotPurchased
              : PurchasedKeys.Purchased;
          }
        }
      }
    } catch (fetchError) {
      console.error('Failed fetching search item', JSON.stringify(fetchError));
    }

    try {
      if (searchableType && purchaseStatus) {
        const to = userActionsMapper[endpoint]?.[searchableType]?.[purchaseStatus][isPM];
        if (to === from) {
          return { endpoint: state.endpoint, body, url, message };
        } else {
          if (!to) {
            return { endpoint: null, message };
          }
          const toEndpoint = to;
          const toBody = endpointArgs[toEndpoint]?.args(body);
          const toUrl = endpointArgs[toEndpoint]?.url;
          return { endpoint: toEndpoint, body: toBody, url: toUrl, param, message };
        }
      }
    } catch (error) {
      console.error('Failed building post sign-in action', JSON.stringify(error));
    }
  }

  return {
    endpoint: state.endpoint,
    body,
    url,
    param,
    message
  };
};

export const buildParams = (
  params: { [key: string]: string | string[] | number | object } | null
): string => {
  if (!params) return '';
  return Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value))
        return `${encodeURIComponent(key)}=${encodeURIComponent((value as Array<string>).join(','))}`;
      if (typeof value === 'object') {
        return `${encodeURIComponent(key)}=${JSON.stringify(value)}`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
};

export const buildDelayedActionUrls = (
  actionType: 'success' | 'error' = 'success',
  action: string = 'noAction',
  params?: { [key: string]: string | string[] | number | object } | null
): string => {
  let basePath = '';
  switch (actionType) {
    case 'success':
      basePath += `successAction=${action}`;
      break;
    case 'error':
      basePath += `errorAction=${action}`;
      break;
    default:
      break;
  }
  if (params) {
    basePath += `&` + buildParams(params);
  }

  return basePath;
};

export default { buildPostSignInAction };
