import { useCallback, useEffect, useState } from 'react';
import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';
import LicenseForm, { LicenseFormProps } from '@/components/ui/SearchModals/LicenseForm';
import { LicenseData, validateRequiredFields } from '../SearchModals/utils/licenses';
import { UserLicense } from '@/@types/client-api';
import { useDispatch } from 'react-redux';
import { invalidateAllTrackerData } from '@/redux/slices/creditTrackerSlice';

export type AddLicenseModalProps = ModalProps &
  LicenseFormProps & {
    onSave: () => void;
    triggerButton?: JSX.Element;
    customModalId?: string;
    isLoggedOut?: boolean;
    handleLoggedOutSave?: (license: UserLicense) => void;
  };

const AddLicenseModal = ({
  open,
  setOpen,
  data,
  onDataChange,
  creditTypeOptions,
  jurisdictionOptions,
  complianceGroupOptions,
  licenseNumberSettings,
  onSave,
  triggerButton,
  customModalId,
  isLoggedOut = false,
  handleLoggedOutSave
}: AddLicenseModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const [validationFailed, setValidationFailed] = useState(false);

  useEffect(() => {
    if (open) {
      setValidationFailed(false);
    }
  }, [open]);

  const handleOnSave = useCallback(() => {
    if (isLoggedOut) {
      if (!data?.creditRegion_SK) return;
      handleLoggedOutSave?.({ ...data });
      setOpen(false);
      return;
    }
    if (validateRequiredFields(data)) {
      setOpen(false);
      onSave();
      dispatch(invalidateAllTrackerData());
    } else {
      setValidationFailed(true);
    }
  }, [data, dispatch, handleLoggedOutSave, isLoggedOut, onSave, setOpen]);

  const handleOnDataChange = useCallback(
    (data: LicenseData) => {
      setValidationFailed(false);
      onDataChange?.(data);
    },
    [onDataChange]
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      {triggerButton && <Modal.Trigger asChild>{triggerButton}</Modal.Trigger>}
      <ModalContent
        title="Add a license"
        subtitle="* Indicates required field"
        saveButtonLabel={!isLoggedOut ? 'Add to my profile' : 'Apply'}
        onSaveButtonPress={handleOnSave}
        isMultiStep={true}
        customModalId={customModalId}
      >
        <div className="mt-1.5">
          <LicenseForm
            data={data}
            onDataChange={handleOnDataChange}
            creditTypeOptions={creditTypeOptions}
            jurisdictionOptions={jurisdictionOptions}
            complianceGroupOptions={!isLoggedOut ? complianceGroupOptions : undefined}
            licenseNumberSettings={!isLoggedOut ? licenseNumberSettings : undefined}
            validationFailed={validationFailed}
            isLoggedOut={isLoggedOut}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default AddLicenseModal;
