import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Field, Message, Label, FormMessageProps, Control } from '@radix-ui/react-form';
import classnames from 'classnames';

import useAutofillListener from '@/hooks/useAutofillListener';
import Icon from '@/components/ui/Icon';

export type InputError = {
  message?: string;
  match?: FormMessageProps['match'];
  forceMatch?: FormMessageProps['forceMatch'];
};

export interface InputProps {
  label: string;
  id: string;
  name: string;
  placeholder?: string;
  success?: string;
  disabled?: boolean;
  variant?: 'line' | 'container';
  required?: boolean;
  type?: string;
  value?: string;
  iconName?: string;
  iconAccessibilityLabel?: string;
  onIconClick?: () => void;
  errors?: InputError[];
  onChange?: (value: string) => void;
  onFocus?: () => void;
  readOnly?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  maxLength?: number;
  className?: string;
  messageStatusContainerClassName?: string;
  forceInvalid?: boolean;
  inputContainerClassName?: string;
  isTextArea?: boolean;
  textAreaClasses?: string;
  disabledEffect?: boolean;
}

interface MessageStatusProps {
  text: string;
  icon: string;
  className: string;
  match: FormMessageProps['match'];
  forceMatch?: FormMessageProps['forceMatch'];
}

const MessageStatus = ({ text, icon, className, match, forceMatch }: MessageStatusProps) => {
  return (
    <Message
      className={classnames('label mt-1 flex items-start gap-1', className)}
      match={match}
      forceMatch={forceMatch}
    >
      <Icon name={icon} size="small" />
      {text}
    </Message>
  );
};

const Input: FC<InputProps> = ({
  label,
  id,
  errors,
  success,
  disabled,
  disabledEffect = disabled,
  placeholder,
  name,
  required,
  type,
  variant = 'container',
  value,
  iconName,
  iconAccessibilityLabel,
  onIconClick,
  onChange,
  onFocus,
  readOnly,
  onClick,
  maxLength,
  className,
  messageStatusContainerClassName,
  forceInvalid,
  inputContainerClassName,
  isTextArea = false,
  textAreaClasses
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(!!value);

  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const isAutofilled = useAutofillListener<HTMLInputElement>(inputRef);

  const containerStyles = classnames(
    'relative rounded-lg border bg-gray-feather px-4 pb-3 pt-2 transition-colors',
    {
      'border-gray': !isFocused,
      'hover:border-black': !disabledEffect,
      'cursor-not-allowed': disabledEffect
    },
    inputContainerClassName
  );

  // Using a pseudo element to prevent jumping when border bottom size changes
  const lineStyles = classnames(
    'relative border-b py-2',
    'after:absolute after:-bottom-px after:z-20 after:block after:h-[2px] after:w-full after:scale-y-0 after:bg-black after:transition-transform after:content-[""]',
    {
      'border-b-gray-dark': !isFocused && !disabledEffect,
      'after:scale-y-100': isFocused,
      'hover:border-b-black': !disabledEffect,
      'border-b-gray': disabledEffect
    }
  );

  const labelLineStyles = classnames('pointer-events-none absolute font-normal !transition-all', {
    'text-2 translate-y-[-18px]': isFocused || isFilled || isAutofilled,
    'text-1': !isFocused && !isFilled,
    'translate-y-0': !isFocused && !isFilled,
    'text-gray': disabledEffect,
    'text-gray-dark': !disabledEffect
  });

  const labelContainerStyles = classnames('text-2-fixed-medium', {
    'text-gray-dark': !disabledEffect,
    'text-gray': disabledEffect
  });

  const handleOnFocus = useCallback(() => {
    if (!disabledEffect) {
      setIsFocused(true);
      onFocus?.();
    }
  }, [disabledEffect, onFocus]);

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);

    const currentTarget = inputRef.current;
    const hasValue = currentTarget?.value;
    setIsFilled(!!hasValue);
  }, []);

  useEffect(() => {
    const timerID = setTimeout(() => {
      if (inputRef.current?.value) {
        setIsFilled(true);
      }
    }, 150);
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  const fieldClassnames = classnames('text-input z-10 w-full bg-transparent focus:outline-none', {
    'cursor-text': !disabledEffect,
    'cursor-not-allowed text-gray placeholder:text-gray': disabledEffect,
    'bg-gray-feather placeholder:text-gray': variant === 'container'
  });

  const inputCustomProps = {
    ...(type !== 'hidden' && placeholder ? { 'aria-label': placeholder } : {}),
    ...(type !== 'hidden' && required ? { 'aria-required': true } : {})
  };

  return (
    <div className={classnames('relative', className)}>
      <Field name={name} serverInvalid={forceInvalid}>
        <div className={variant === 'container' ? containerStyles : lineStyles}>
          <div className="flex items-center justify-between">
            <div className="flex-1">
              {type !== 'hidden' && (
                <Label
                  htmlFor={id}
                  className={classnames(
                    variant === 'container' ? labelContainerStyles : labelLineStyles,
                    // Using a pseudo element to prevent jumping when border size changes
                    'transition-opacity after:pointer-events-none after:absolute after:inset-[-2px] after:rounded-lg after:border-2 after:border-black after:opacity-0 after:content-[""]',
                    {
                      'data-[invalid=true]:after:!border-red data-[invalid=true]:after:opacity-100':
                        variant === 'container' && errors && errors?.length > 0,
                      'after:opacity-100': isFocused && variant === 'container'
                    }
                  )}
                >
                  {label}
                </Label>
              )}

              <Control asChild>
                {isTextArea ? (
                  <textarea
                    ref={textAreaRef}
                    className={classnames('resize-none', fieldClassnames, textAreaClasses)}
                    placeholder={variant === 'container' ? placeholder : ''}
                    aria-label={placeholder}
                    aria-required={required}
                    id={id}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onChange={e => {
                      onChange?.(e.target.value);
                    }}
                    onClick={onClick}
                    readOnly={readOnly}
                    disabled={disabled}
                    required={required}
                    value={value}
                    autoComplete="off"
                    maxLength={maxLength}
                  ></textarea>
                ) : (
                  <input
                    ref={inputRef}
                    className={fieldClassnames}
                    placeholder={type === 'hidden' || variant === 'container' ? placeholder : ''}
                    id={id}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onChange={e => {
                      onChange?.(e.target.value);
                    }}
                    onClick={onClick}
                    readOnly={readOnly}
                    disabled={disabled}
                    required={required}
                    type={type}
                    value={value}
                    autoComplete="off"
                    maxLength={maxLength}
                    {...inputCustomProps}
                  />
                )}
              </Control>
            </div>
            {iconName && (
              <button type="button" onClick={onIconClick} aria-label={iconAccessibilityLabel}>
                <Icon size="medium" name={iconName} />
              </button>
            )}
          </div>
        </div>
        <div className={classnames('absolute', messageStatusContainerClassName)}>
          {errors?.map(({ message, match, forceMatch }, i) => {
            return message ? (
              <MessageStatus
                key={i}
                text={message}
                icon="alert"
                className="text-red"
                match={match}
                forceMatch={forceMatch}
              />
            ) : null;
          })}
          {success && (
            <MessageStatus
              text={success}
              icon="checkmark"
              className="text-green-300"
              match="valid"
            />
          )}
        </div>
      </Field>
    </div>
  );
};

export default Input;
