import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isWithinInterval } from 'date-fns';
import { ICreditRequirement, UserPracticeAreaKeywordItem } from '@/@types/client-api';
import Divider from '@/components/ui/Divider';
import {
  filterHiddenRequirements,
  formatTemporalHeadingText,
  getTemporalDates,
  getNewTagText,
  getRegionShortName,
  getCreditTypeKeys,
  getFormatsForRequirement
} from '@/components/ui/CreditTracker/util/TrackerHelpers';
import Shimmer from '@/components/ui/Shimmer';
import CreditTypeCard from '@/components/ui/Cards/CreditTypeCard';
import { IEarnMoreCreditPanelProps } from '@/components/ui/CreditTracker/Panels/EarnMoreCreditPanel';
import {
  CompliancePeriodValue,
  TrackerQueryParamKeys
} from '@/components/ui/CreditTracker/util/constants';
import { formatDate } from '@/components/ui/CreditTracker/util/trackerDateHelpers';
import TrackerAccordion from '@/components/ui/CreditTracker/snippets/TrackerAccordion';
import Button from '@/components/ui/Buttons/Button';
import { CreditTrackerContext } from '@/components/blocks/CreditTracker/CreditTrackerContext';
import useQueryParams from '@/hooks/useQueryParams';
import { trackEarnCreditEvent } from '@/analytics/creditTracker';
import { AnalyticsContext } from '@/analytics/constants';
export type TrackerSearchQueryRecord = Record<string, Array<ICreditRequirement>>;

interface RequirementsSectionProps {
  complianceEndDate?: Date;
  practiceAreas?: UserPracticeAreaKeywordItem[] | null;
  earnMoreCreditPriority?: Array<string> | null;
  selectedCompliancePeriod: CompliancePeriodValue | null;
  failureMessage?: string | null;
  noResultsMessage?: string | null;
}

const RequirementsSection: React.FC<RequirementsSectionProps> = props => {
  const {
    complianceEndDate,
    practiceAreas,
    earnMoreCreditPriority,
    selectedCompliancePeriod,
    failureMessage,
    noResultsMessage
  } = props;
  const [isProgramsPanelOpen, setIsProgramsPanelOpen] = useState<boolean>(false);
  const [selectedCreditTypeKey, setSelectedCreditTypeKey] = useState<string | null>(null);
  const [scrollToCreditType, setScrollToCreditType] = useState<boolean>(false);
  const creditTypeButtonRef = useRef<HTMLButtonElement>(null);
  const creditTypeCardRef = useRef<HTMLDivElement>(null);
  const todaysDate = useMemo(() => new Date().setHours(0, 0, 0, 0), []);

  const params = useQueryParams();

  let requirementQuantity = 4;

  const earnMoreTypesQueryParam = useMemo(
    () => params[TrackerQueryParamKeys.CREDIT_TYPES]?.split(';')?.map(a => a.toLowerCase()) ?? [],
    [params]
  );
  const earnMoreFormatsQueryParam = useMemo(
    () => params[TrackerQueryParamKeys.FORMATS]?.split(';')?.map(a => a.toLowerCase()) ?? [],
    [params]
  );

  useEffect(
    () =>
      setScrollToCreditType(
        !!earnMoreTypesQueryParam?.length || !!earnMoreFormatsQueryParam?.length
      ),
    [earnMoreFormatsQueryParam?.length, earnMoreTypesQueryParam?.length]
  );

  const {
    temporalRequirements = [],
    requirementsWithoutTemporal: requirements = [],
    isLoading,
    isTransitionalOrNewlyAdmittedRegion,
    isDeadlineApproaching,
    earnMoreCreditResultPage,
    selectedRegion
  } = useContext(CreditTrackerContext);

  const handleScrollToCertificates = useCallback(() => {
    if (!!creditTypeButtonRef.current && !!creditTypeCardRef.current) {
      const refRect = creditTypeCardRef.current?.getBoundingClientRect();
      const topPosition =
        creditTypeCardRef.current && refRect ? refRect.top - refRect.height + window.scrollY : 0;
      window.scrollTo({
        top: topPosition,
        behavior: 'smooth'
      });

      const renderTimeout = () => {
        const timeoutId = setTimeout(() => {
          creditTypeButtonRef.current?.click();
        }, 800);

        return () => clearTimeout(timeoutId);
      };
      renderTimeout();
    }
    setScrollToCreditType(false);
  }, []);

  useEffect(() => {
    if (scrollToCreditType && !isLoading) {
      handleScrollToCertificates();
    }
  }, [handleScrollToCertificates, isLoading, scrollToCreditType]);

  const region = useMemo(
    () => selectedRegion?.creditRegionName,
    [selectedRegion?.creditRegionName]
  );
  const regionShortName = useMemo(
    () => selectedRegion?.creditRegionShortDescription,
    [selectedRegion?.creditRegionShortDescription]
  );

  const requirementHasHeading = useCallback(
    (req: ICreditRequirement) => !!req?.heading && req?.heading?.length > 0,
    []
  );

  const requirementHasSubRequirements = useCallback(
    (req: ICreditRequirement) => !!req?.subRequirements && req.subRequirements.length > 0,
    []
  );

  const expireNewStatus = useCallback((req: ICreditRequirement) => {
    return (
      req.expireNewStatus ?? req.subRequirements?.find(a => a.expireNewStatus)?.expireNewStatus
    );
  }, []);

  const earnCreditText = useCallback(
    (req?: ICreditRequirement) =>
      (req?.earned ?? 0) > 0 ? 'Earn more credits' : 'Start earning credits',
    []
  );

  const isDefaultOpened = useCallback(
    (req?: ICreditRequirement) => {
      if (earnMoreTypesQueryParam?.length) {
        const creditTypes = Object.keys(getCreditTypeKeys([req ?? {}]))?.map(a => a.toLowerCase());
        return earnMoreTypesQueryParam?.every(a => !!creditTypes.some(b => b == a));
      }

      if (earnMoreFormatsQueryParam?.length) {
        const formats = getFormatsForRequirement(req ?? {})?.map(a => a?.toLowerCase());
        return earnMoreFormatsQueryParam?.every(a => !!formats.some(b => b == a));
      }

      return false;
    },
    [earnMoreFormatsQueryParam, earnMoreTypesQueryParam]
  );

  const panelTriggerButton = useCallback(
    (req?: ICreditRequirement) => {
      return (
        <Button
          label={earnCreditText(req)}
          ref={isDefaultOpened(req) ? creditTypeButtonRef : null}
          color="outline-black"
          className="mt-6 h-auto w-full py-2.5 md:ml-auto md:mt-0 md:max-w-[164px] md:self-end"
          onClick={() => trackEarnCreditEvent(AnalyticsContext.CreditTracker, region || '')}
        />
      );
    },
    [earnCreditText, isDefaultOpened, region]
  );

  const earnMoreSettings = useCallback(
    (req: ICreditRequirement, key: string) => {
      return {
        creditRegionName: region,
        creditRegionShortName: getRegionShortName(
          regionShortName,
          isTransitionalOrNewlyAdmittedRegion
        ),
        requirement: req,
        button: panelTriggerButton(req),
        creditTypeLongDescription: req.heading,
        creditTypePriority: earnMoreCreditPriority,
        practiceAreas: practiceAreas,
        failureMessage: failureMessage,
        noResultsMessage: noResultsMessage,
        earnMoreResultPageUrl: earnMoreCreditResultPage?.url,
        onOpenChange: (isOpen: boolean) => {
          setSelectedCreditTypeKey(isOpen ? key : '');
          setIsProgramsPanelOpen(isOpen);
        }
      } as IEarnMoreCreditPanelProps;
    },
    [
      earnMoreCreditPriority,
      earnMoreCreditResultPage?.url,
      failureMessage,
      isTransitionalOrNewlyAdmittedRegion,
      noResultsMessage,
      panelTriggerButton,
      practiceAreas,
      region,
      regionShortName
    ]
  );

  const renderCreditType = (
    req: ICreditRequirement,
    index: number = 0
  ): JSX.Element | null | undefined => {
    const key = `${req.heading?.toLowerCase()?.replace(/\p{P}/gu, '')?.replace(' ', '-')}-${index}`;

    return requirementHasHeading(req) ? (
      <CreditTypeCard
        key={key}
        ref={isDefaultOpened(req) ? creditTypeCardRef : null}
        isDeadlineApproaching={isDeadlineApproaching}
        requirement={req}
        isBlurred={isProgramsPanelOpen && key !== selectedCreditTypeKey}
        complianceEndDate={complianceEndDate}
        earnMoreSettings={earnMoreSettings(req, key)}
        tagText={getNewTagText(expireNewStatus(req))}
        showEarnMore={selectedCompliancePeriod === 'current'}
      />
    ) : undefined;
  };

  const iterateCreditTypes = (reqs?: ICreditRequirement[] | null): JSX.Element[] => {
    if (!reqs) return [];

    reqs = filterHiddenRequirements(reqs) ?? [];

    const res: JSX.Element[] = [];
    for (let i = 0; i < reqs?.length; i++) {
      const req = reqs[i];
      if (requirementHasSubRequirements(req) && !requirementHasHeading(req))
        res.push(...iterateCreditTypes(req?.subRequirements));

      const newType = renderCreditType(req, i);
      newType && res.push(newType);
    }

    requirementQuantity = res.length;
    return res;
  };

  const isTemporalAccordionOpen = useCallback(
    (sideNote: string) => {
      const args = getTemporalDates(sideNote) ?? [];
      const isWithinFirstTemporal =
        args.length > 1 && isWithinInterval(todaysDate, { start: args[0], end: args[1] });
      if (isWithinFirstTemporal && temporalRequirements) {
        return temporalRequirements.findIndex(req => req.sideNote?.includes(args[0]));
      }
      return -1;
    },
    [temporalRequirements, todaysDate]
  );

  const formatSideNote = useCallback((sideNote?: string | null): string | null | undefined => {
    if (!sideNote) return null;

    const _args = getTemporalDates(sideNote) ?? [];

    if (_args.length > 0) {
      if (_args.length > 1) {
        return _args.map(a => formatDate(a)).join(' - ');
      }
      return formatDate(_args[0]) ?? _args[0];
    }

    return sideNote;
  }, []);

  return (
    <>
      {isLoading ? (
        Array.from({ length: requirementQuantity }, (_, i) => (
          <Shimmer className="h-96 w-full rounded-lg" key={`req-${i}`} />
        ))
      ) : (
        <div className="bg-white">
          {iterateCreditTypes(requirements)}
          {!!temporalRequirements?.length &&
            temporalRequirements.map((req, i) => {
              const _isOpen = isTemporalAccordionOpen(req?.sideNote ?? '') == i;
              return (
                <div key={i} className="py-4">
                  <Divider color={'light'} className="mb-6" />
                  <TrackerAccordion
                    key={`accordion-${i}`}
                    heading={formatTemporalHeadingText(req?.heading)}
                    subHeading={`${req.counted} counted / ${req.required} required`}
                    sideNote={formatSideNote(req.sideNote)}
                    isOpen={_isOpen}
                    contentItems={req.subRequirements?.map(
                      (subRequirement: ICreditRequirement, index: number) => {
                        return renderCreditType(subRequirement, index);
                      }
                    )}
                  />
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default RequirementsSection;
