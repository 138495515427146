import { ImageSubBlock as ImageSubBlockProps } from '@/@types/content';
import CmsLink from '@/components/cms/Link';
import { ResponsiveImageProps } from '@/components/ui/ResponsiveImage';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import classnames from 'classnames';
import Button from '@/components/ui/Buttons/Button';
import Text from '@/components/cms/Text';
import RichText from '@/components/cms/RichText';
import Image from '@/components/cms/Image';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

enum Layout {
  TwoColumns = '2-columns',
  ThreeColumns = '3-columns',
  FourColumns = '4-columns'
}

interface BlockProps extends ImageSubBlockProps {
  layout?: `${Layout}`;
  imageSizes?: ResponsiveImageProps['imageSizes'];
  overrideImageSizes?: ResponsiveImageProps['imageSizes'];
}

const ImageSubBlock: React.FC<BlockProps> = ({
  image,
  link,
  layout = Layout.FourColumns,
  heading,
  copy,
  buttonLink
}: BlockProps) => {
  const screenSizeBelowLg = useMediaQuery(ScreenSizeQueries.belowLg);

  const hasHeadingOrCopy = Boolean(heading || copy);
  const editMode = isEditOrPreviewMode();

  const container = classnames('size-full overflow-hidden rounded-lg bg-gray-lightest', {
    'aspect-square': !hasHeadingOrCopy
  });
  const imageWrapperClasses = classnames(
    'flex flex-col p-16',
    'border-4 border-solid border-transparent focus:border-black',
    {
      'h-full items-center justify-center !p-0': !hasHeadingOrCopy,
      'lg:p-[122px]': layout === Layout.TwoColumns,
      'lg:p-[80px]': layout === Layout.ThreeColumns && !hasHeadingOrCopy,
      'p-6': hasHeadingOrCopy,
      'lg:p-[40px]': hasHeadingOrCopy,
      'sm:p-[68px]': !hasHeadingOrCopy,
      'lg:p-[60px]': layout === Layout.FourColumns && !hasHeadingOrCopy
    }
  );

  const ImageComponent = () => (
    <Image
      propertyName="Image"
      src={image?.url}
      alt={image?.alt as string}
      title={image?.title as string}
      className="aspect-square size-7/12 !object-contain"
    />
  );

  const WithHeadingOrCopyComponent = () => (
    <section className={classnames('flex flex-col gap-y-2 lg:gap-y-5')}>
      <Image
        propertyName="Image"
        src={image?.url}
        alt={image?.alt as string}
        title={image?.title as string}
        className={classnames('mb-[7px] self-baseline !object-contain lg:mb-5', {
          'size-full': !hasHeadingOrCopy,
          'h-[57px] lg:h-[80px]': hasHeadingOrCopy
        })}
      />
      <section className="flex flex-col lg:gap-y-1">
        <Text
          propertyName="Heading"
          element="h6"
          className={classnames({
            'heading-6-medium': !screenSizeBelowLg,
            'text-1-medium': screenSizeBelowLg
          })}
        >
          {heading}
        </Text>
        <RichText
          propertyName="Copy"
          className={classnames('whitespace-pre-wrap text-gray-dark', {
            'text-1': !screenSizeBelowLg,
            'text-2': screenSizeBelowLg
          })}
          content={copy}
        />
      </section>

      {Boolean(buttonLink) || editMode ? (
        <Button
          propertyName="ButtonLink"
          color="outline-black"
          size="small"
          href={buttonLink?.href}
          label={buttonLink?.text}
          title={buttonLink?.title}
          target={buttonLink?.target}
          iconRight={buttonLink?.target?.includes('_blank') ? 'link-out' : undefined}
        />
      ) : null}
    </section>
  );

  return (
    <div className={container}>
      {link?.href || editMode ? (
        <CmsLink propertyName="Link" {...link} className={imageWrapperClasses}>
          {heading || copy || buttonLink || editMode ? (
            <WithHeadingOrCopyComponent />
          ) : (
            <ImageComponent />
          )}
        </CmsLink>
      ) : (
        <div className={imageWrapperClasses}>
          {heading || copy || buttonLink || editMode ? (
            <WithHeadingOrCopyComponent />
          ) : (
            <ImageComponent />
          )}
        </div>
      )}
    </div>
  );
};

export default ImageSubBlock;
