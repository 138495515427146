import {
  forwardRef as ReactForwardRef,
  ReactElement,
  Children as ReactChildren,
  isValidElement as ReactIsValidElement,
  cloneElement as ReactCloneElement
} from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as Form from '@radix-ui/react-form';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';

interface CheckboxFormFieldProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  name: string;
}

const CheckboxFormField = ReactForwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxFormFieldProps
>(({ children, className, id, name, ...props }, ref) => {
  return (
    <Form.Field
      name={name}
      className={classnames('inline-flex flex-col flex-wrap', {
        'gap-y-2': ReactChildren.count(children) > 1
      })}
    >
      <div className="flex items-center max-md:items-start">
        <CheckboxPrimitive.Root
          ref={ref}
          className={classnames(
            'peer relative inline-flex aspect-square size-[14px] rounded-sm border border-gray',
            'lg:size-4',
            'data-[state=checked]:border-black data-[state=checked]:bg-black data-[state=checked]:text-white',
            'disabled:cursor-not-allowed disabled:border-silver',
            'hover:border-red',
            'hover:data-[state=checked]:border-red hover:data-[state=checked]:bg-red',
            className
          )}
          id={id}
          name={name}
          {...props}
        >
          <CheckboxPrimitive.Indicator className="absolute size-full text-white">
            <Icon name="checkmark" size="x-small" className="relative lg:left-px lg:top-px" />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>

        {ReactChildren.map(children, child => {
          if (ReactIsValidElement(child) && child.type === Form.Label) {
            const labelChild = child as ReactElement<{ htmlFor: string; className?: string }>;

            return ReactCloneElement(labelChild, {
              htmlFor: id,
              className: classnames(
                'text-2 w-fit cursor-pointer pl-2 text-black lg:pl-3',
                'peer-disabled:cursor-not-allowed peer-disabled:text-gray',
                'peer-hover:text-red',
                'hover:text-red',
                labelChild.props.className
              )
            });
          }

          return null;
        })}
      </div>

      <div className="pl-[22px] lg:pl-[28px]">
        {ReactChildren.map(children, child => {
          if (ReactIsValidElement(child) && child.type === Form.Message) {
            const messageChild = child as ReactElement<{ className?: string }>;

            return ReactCloneElement(messageChild, {
              className: classnames('text-2 text-gray-dark', messageChild.props.className)
            });
          }

          return null;
        })}
      </div>
    </Form.Field>
  );
});

export default CheckboxFormField;
