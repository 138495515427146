import { getOIDCState, userManager } from '@/auth/oidcConfig';
import type { RootState } from '@/redux/store';
import { isEditMode, isEditOrPreviewMode } from '@/lib/editModeHelpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const prepareHeaders = (headers: Headers, { getState }: { getState: () => unknown }) => {
  const token = (getState() as RootState).auth.token;

  headers.set('Accept', 'application/json');
  headers.set('Accept-Language', '');
  headers.set('cms-head', 'web');

  if (token) {
    //console.info(`calling with token ${token}...`);
    headers.set('authorization', `Bearer ${token}`);
  }

  return headers;
};

export const responseHandler = async (response: Response) => {
  if (!response.ok) {
    if (response.status === 401) {
      return userManager.signinRedirect({ state: getOIDCState() });
    }

    const error = await response.json();
    return Promise.reject(error);
  }
  return response.json();
};

export const responseHandlerNoAuthRedirect = async (response: Response) => {
  if (!response.ok) {
    const error = await response.json();
    return error;
  }
  return response.json();
};

export const queryStringBuilder = (params: {
  [key: string]: string | number | Array<string> | boolean;
}) => {
  const query = Object.keys(params).map(key => {
    if (params[key] === undefined || params[key] === null) return null;

    if (Array.isArray(params[key])) {
      return `${encodeURIComponent(key)}=${encodeURIComponent((params[key] as Array<string>).join(','))}`;
    }

    return `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`;
  });

  if (isEditOrPreviewMode()) query.push(`epieditmode=${isEditMode()}`);

  return query.length ? `?${query.join('&')}` : '';
};

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof error.message === 'string'
  );
}
