import { formatCardNumber } from '../helpers';
import Image from '@/components/cms/Image';

export type CreditCardBrandAndNumberProps = {
  brandName: string;
  brandLogo: string;
  number: string;
};

const CreditCardBrandAndNumber = ({
  brandName,
  brandLogo,
  number
}: CreditCardBrandAndNumberProps): JSX.Element => {
  return (
    <div className="flex items-center gap-x-2">
      {brandLogo && <Image className="size-4 lg:size-6" src={brandLogo} alt={brandName} />}
      <span className="text-1-medium">{`${brandName} ${formatCardNumber(number)}`}</span>
    </div>
  );
};

export default CreditCardBrandAndNumber;
