import Shimmer from '@/components/ui/Shimmer';

const SecondaryTaxonomyBlockShimmer: React.FC = () => {
  return (
    <div className="container mt-16 grid grid-cols-3 gap-8 overflow-x-clip">
      <div className="flex flex-col">
        <Shimmer className="mb-6 h-5 w-[151px]" />
        <Shimmer className="mb-6 h-5 w-[185px]" />
        <Shimmer className="mb-6 h-5 w-[106px]" />
        <Shimmer className="mb-6 h-5 w-[90px]" />
      </div>

      <div className="flex flex-col">
        <Shimmer className="mb-6 h-5 w-[109px]" />
        <Shimmer className="mb-6 h-5 w-[194px]" />
        <Shimmer className="mb-6 h-5 w-[146px]" />
      </div>

      <div className="flex flex-col">
        <Shimmer className="mb-6 h-5 w-[162px]" />
        <Shimmer className="mb-6 h-5 w-[143px]" />
        <Shimmer className="mb-6 h-5 w-[184px]" />
      </div>
    </div>
  );
};

export default SecondaryTaxonomyBlockShimmer;
