import { useCallback, useState } from 'react';

type resultHashMap = Record<string, Array<string>>;

/** Knuth-Morris-Pratt search on an array of strings for matching substrings.
 *
 * @remarks
 * Uses a prebuilt table of indices to match against.
 */
const useKmpSearch = () => {
  const [resultMap, setResultMap] = useState<resultHashMap>({});

  /** Set up KMP array of the Longest Possible Substring to match. */
  const computeLps = useCallback((substring: string, lps: number[]) => {
    let i = 1,
      len = 0;
    while (i < substring.length) {
      if (substring[i] == substring[len]) {
        len++;
        lps[i] = len;
        i++;
      } else {
        if (len != 0) {
          len = lps[len - 1];
        } else {
          lps[i] = 0;
          i++;
        }
      }
    }
    return lps;
  }, []);

  /** Perform the KMP search based on LPS array. Will match on containing substring. */
  const kmp = useCallback(
    (substring: string, list: string[]) => {
      const lps = computeLps(substring, new Array(substring.length).fill(0));

      const results = [];
      for (const _text of list) {
        const text = _text.toLowerCase();
        let i = 0,
          j = 0;
        while (i < text.length) {
          if (substring[j] == text[i]) {
            j++;
            i++;
          }

          if (j == substring.length) {
            if (i - j == 0) results.unshift(_text);
            else results.push(_text);

            j = lps[j - 1];
            break;
          } else if (i < text.length && substring[j] != text[i]) {
            if (j != 0) j = lps[j - 1];
            else i = i + 1;
          }
        }
      }

      return results;
    },
    [computeLps]
  );

  /** searching function
   *
   * @remarks
   * The search function to match substrings in an array of strings.
   *
   * @param searchPhrase - the phrase to match
   * @param dataSet - the list of strings
   * @returns a string array of substring matches ordered by matched index of 0 first, followed by
   *      alphabetical sorting.
   */
  const kmpSearch = useCallback(
    (searchPhrase: string, dataSet: string[]): string[] => {
      if (!searchPhrase?.length || !dataSet?.length || searchPhrase?.trim() === '') return [];

      // If search results exist in hash map return those values.
      if (resultMap[searchPhrase]) return resultMap[searchPhrase];

      // Search
      const result = kmp(searchPhrase, dataSet);

      if (result?.length) {
        setResultMap({
          ...resultMap,
          [searchPhrase]: result
        });
      }

      return result;
    },
    [kmp, resultMap]
  );

  return {
    kmpSearch
  };
};
export default useKmpSearch;
