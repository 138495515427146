import ProductDetailHeader from '@/components/ui/PDP/ProductDetailHeader';
import {
  LiveProgramCatalogRelations,
  LiveVariationFormat,
  OnDemandProgramVariation,
  isLiveOhbVariation
} from '@/hooks/PDP/useProgramCatalogRelations';
import { useLiveProgramVariantSelection } from '@/hooks/PDP/useProgramVariantSelection';
import { useActions } from '@/hooks/PDP/useActions';
import UpcomingLive from './ECommBox/UpcomingLive';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import PDPShimmer from '@/components/ui/PDP/PDPShimmer';
import classnames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getNotifyPanelItems,
  determineLiveProgramStatus,
  getExpiredStatus,
  getLiveProgramRuntime
} from './helpers';
import { MemsuiteModalLabels } from './labels';
import ShareModal from './Modals/ShareModal';
import PDPLayout from '@/components/cms/PDPLayout';
import usePDPLayout from '@/hooks/PDP/usePDPLayout';
import ProgramExpired from './ECommBox/ProgramExpired';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { PDPFooter } from './PDPFooter';
import NotifyPanel from './ECommBox/NotifyPanel';
import { AnalyticsContext } from '@/analytics/constants';
import MemsuiteModal from './Modals/MemsuiteModal';
import { useMemsuite } from '@/hooks/PDP/useMemsuite';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import { useShareModal } from '@/hooks/PDP/useShareModal';
import { useTrackViewItem } from '@/analytics/pdp';
import { useLibraryItems } from '@/hooks/PDP/useLibraryItems';
import { useGetRegistrations } from '@/hooks/PDP/useGetRegistrations';
import { useFetchProgramMaterials } from '@/hooks/PDP/useFetchProgramMaterials';
import { useProgramCreditLicenses } from '@/hooks/PDP/useProgramCreditLicenses';
import { useCreditTrackerCodeSearchParam } from '@/hooks/useCreditTrackerCode';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { ProductSEO } from './ProductSEO';

interface ILiveProgramPDPProps {
  variant: LiveVariationFormat;
  catalogRelations: LiveProgramCatalogRelations;
  loadingCatalogRelations?: boolean;
  context: AnalyticsContext;
}

const LiveProgramPDP = ({
  variant,
  catalogRelations,
  loadingCatalogRelations,
  context
}: ILiveProgramPDPProps) => {
  const { liveEcomLabels, programExpiredLabels } = useCatalogLabels();
  const programVariants = catalogRelations.variants;
  const [itemAnalyticsModel, setItemAnalyticsModel] = useState<ItemAnalyticsModel>();

  const { registrations, isRegistrationsFetching } = useGetRegistrations();
  useFetchProgramMaterials(
    registrations?.filter(r => programVariants.find(pv => pv.content.code === r.pk))
  );

  const { selectedVariant: selectedVariantContentFormat } = useLiveProgramVariantSelection(
    variant,
    programVariants,
    registrations
  );
  const { content: selectedVariant, contentType } = selectedVariantContentFormat;
  useTrackViewItem(selectedVariant.code);
  const creditTrackerCode = useCreditTrackerCodeSearchParam();

  // Live PDP: Only uses Library API for Saved to library status, upcomingRegistrations is used elsewhere for less bloat
  const { hydrateLibrary, selectLibraryItemWithFetchingState, selectLibraryItem } =
    useLibraryItems();
  useEffect(() => {
    hydrateLibrary([selectedVariant.code]);
  }, [hydrateLibrary, selectedVariant.code]);
  const selectedVariantLibraryItem = selectLibraryItemWithFetchingState(selectedVariant.code);

  const { loggedOutLicenseProps, relevantCredits, isRelevantCreditsLoading } =
    useProgramCreditLicenses([selectedVariant.code!]);

  const liveStatus = useMemo(
    () =>
      determineLiveProgramStatus(
        selectedVariant.eventStartDate,
        selectedVariant.eventEndDate,
        selectedVariant.liveSchedule
      ),
    [selectedVariant.eventEndDate, selectedVariant.eventStartDate, selectedVariant.liveSchedule]
  );
  const isExpired = getExpiredStatus(selectedVariant);
  const runtimeFormatted = useMemo(
    () =>
      selectedVariant.customDuration?.length
        ? selectedVariant.customDuration
        : getLiveProgramRuntime(selectedVariant.eventStartDate, selectedVariant.eventEndDate),
    [selectedVariant.customDuration, selectedVariant.eventEndDate, selectedVariant.eventStartDate]
  );

  useEffect(() => {
    const itemLibrary = selectLibraryItem(selectedVariant.code);
    if (itemLibrary) setItemAnalyticsModel(itemLibrary.itemAnalyticsModel);
  }, [selectedVariant.code, selectLibraryItem]);

  const memsuiteProps = useMemsuite(selectedVariant, catalogRelations);
  const shareModalProps = useShareModal(selectedVariant, itemAnalyticsModel, context);

  const actions = useActions(
    selectedVariant,
    catalogRelations,
    selectedVariantLibraryItem,
    shareModalProps.setOpen,
    undefined, // Download
    memsuiteProps.isMemsuiteAllowed ? memsuiteProps.setOpen : undefined
  );
  const actionBarProps = {
    runtimeFormatted,
    creditFormatted: relevantCredits,
    isCreditLoading: isRelevantCreditsLoading,
    actions,
    shareModalId: shareModalProps.customModalId
  };

  const isLarge = useMediaQuery(ScreenSizeQueries.lg);
  const isLoading = useSelector((state: RootState) => state.pdp.loading);

  const layout = usePDPLayout({
    layout: selectedVariant.pdpLayoutBlock,
    contentType: selectedVariant?.contentType?.slice(-1)[0] ?? contentType,
    variant: selectedVariant,
    isOhb: isLiveOhbVariation(contentType)
  });

  const pageLinkPaths = useSelector(selectPageLinkPaths);

  const onDemandProgramCardProps = useMemo(() => {
    const { program, isLoading } = catalogRelations.ondemandProgramCrosslink;
    if (!program && !isLoading) return undefined;
    return {
      url: program?.contentLink?.url,
      title: program?.displayName,
      publicationDate: (program as OnDemandProgramVariation)?.publicationDate,
      label: liveEcomLabels.ondemandCrosslink,
      isLoading,
      context: AnalyticsContext.PDP
    };
  }, [catalogRelations.ondemandProgramCrosslink, liveEcomLabels.ondemandCrosslink]);

  const generateEcomBox = useCallback(
    (isMobile?: boolean) => {
      const notifyItems = getNotifyPanelItems(selectedVariant);
      return notifyItems.url != null && notifyItems.url != undefined && notifyItems.url != '' ? (
        <NotifyPanel isMobile={isMobile} notifyItems={notifyItems} />
      ) : isExpired ? (
        <ProgramExpired
          isMobile={isMobile}
          programId={selectedVariant.code!}
          labels={programExpiredLabels}
          pageLinks={pageLinkPaths}
          programCard={{ onDemandProps: onDemandProgramCardProps }}
        />
      ) : (
        <UpcomingLive
          isMobile={isMobile}
          labels={liveEcomLabels}
          loadingCatalogRelations={loadingCatalogRelations}
          fetchingRegistrations={isRegistrationsFetching}
          onDemandProgram={onDemandProgramCardProps}
          context={context}
          creditTrackerCode={creditTrackerCode}
        />
      );
    },
    [
      selectedVariant,
      isExpired,
      programExpiredLabels,
      pageLinkPaths,
      onDemandProgramCardProps,
      liveEcomLabels,
      loadingCatalogRelations,
      isRegistrationsFetching,
      context,
      creditTrackerCode
    ]
  );

  return (
    <>
      <ProductSEO
        variant={selectedVariant}
        programProduct={catalogRelations.product}
        masterProgram={catalogRelations.masterProgram}
        liveContentType={contentType}
      />
      <div className="container flex flex-col !p-0 lg:flex-row">
        <div className="relative min-w-px lg:grow">
          {/* Inside Scroll area */}
          <PDPShimmer show={isLoading} />

          <ProductDetailHeader
            title={selectedVariant.displayName ?? ''}
            formatEyebrow={liveStatus}
            actionBarProps={actionBarProps}
            className={classnames({ hidden: isLoading })}
            layout={layout}
            loading={isLoading}
          />
          {/* Mobile Ecomm-Box */}
          {!isLarge && generateEcomBox(true)}
          <PDPLayout
            layout={layout}
            catalogRelations={catalogRelations}
            className={classnames({
              hidden: isLoading
            })}
            loggedOutLicenseProps={loggedOutLicenseProps}
            creditTrackerCode={creditTrackerCode}
          />
        </div>
        {/* Desktop Ecomm-Box */}
        {isLarge && (
          <div className="container relative w-[422px] shrink-0 px-0">{generateEcomBox()}</div>
        )}
      </div>
      <PDPFooter product={catalogRelations.product} isLoading={isLoading} />
      <ShareModal {...shareModalProps} />
      {memsuiteProps.isMemsuiteAllowed && (
        <MemsuiteModal {...memsuiteProps} labels={MemsuiteModalLabels} />
      )}
    </>
  );
};

export default LiveProgramPDP;
