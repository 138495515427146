import { PersonalInfoIndustriesBlock as PersonalInfoIndustriesBlockProps } from '@/@types/content';
import PersonalInfoIndustriesSection from '@/components/ui/ProfileSections/IndustriesSection';
import { IComponentProps } from '@/lib/ComponentFactory';

const PersonalInfoIndustriesBlock: React.FC<
  PersonalInfoIndustriesBlockProps & IComponentProps
> = props => {
  return <PersonalInfoIndustriesSection {...props} />;
};

export default PersonalInfoIndustriesBlock;
