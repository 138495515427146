import { useMemo } from 'react';
import { FILTERS } from '../constants';
import { useGetSearchResultsQuery } from '@/redux/api/client/search';
import {
  SearchRequestOptions,
  processSearchRequest,
  searchDataToSearchRequestBody
} from '../utils/searchRequest';
import { ContentTab } from '../../ContentTabs';

type UseInvariantFiltersInput = Pick<
  SearchRequestOptions,
  'invariantFilters' | 'facetOverrides'
> & {
  tabsToProps: { tabs: ContentTab[]; activeTab: ContentTab };
};

export const useInvariantFilters = ({
  invariantFilters,
  tabsToProps,
  ...options
}: UseInvariantFiltersInput) => {
  const invariantsRequest = processSearchRequest(searchDataToSearchRequestBody({}), {
    invariantFilters,
    ...options
  });

  const { data: dataFromInvariantFilterRequest, isLoading } = useGetSearchResultsQuery(
    { requestBody: invariantsRequest },
    { skip: !invariantFilters?.length }
  );

  const invariantFiltersTabs = useMemo(() => {
    const tabs = tabsToProps.tabs;

    const tabsToRemove = new Set(
      dataFromInvariantFilterRequest?.facetResults?.facetGroups
        ?.find(item => item.groupTypeId === FILTERS.CONTENT_TABS)
        ?.facets?.filter(item => !item.count || item.disabled)
        ?.map(tab => tab.value)
    );

    return tabs.filter(tab => !tabsToRemove.has(tab.value));
  }, [tabsToProps.tabs, dataFromInvariantFilterRequest]);

  return {
    invariantFiltersTabs: isLoading ? [] : invariantFiltersTabs
  };
};
