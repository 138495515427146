import { ClientAPI } from '@/redux/api';
import { ItemAnalyticsModel } from '@/@types/client-api';

export interface IItemAnalyticsModelQSProps {
  itemPks: string[];
  populatePrice: boolean;
  limitPriceCalls: boolean;
}

export interface IFacultiesAnalyticsModelQSProps {
  individualSks: string[];
}

const itemAnalyticsModelEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getItemAnalyticsModelByItemPks: builder.query<ItemAnalyticsModel[], IItemAnalyticsModelQSProps>(
      {
        query: (props: IItemAnalyticsModelQSProps) => {
          const itemPksParams = new URLSearchParams();
          if (props && props.itemPks && props.itemPks.length > 0) {
            props.itemPks.forEach(value => itemPksParams.append('itemPks', value));
          }
          const queryPrefix = itemPksParams.toString() != '' ? '&' : '';
          const url = `/item-analytics-model/items?${itemPksParams.toString()}${queryPrefix}populatePrice=${props.populatePrice}&limitPriceCalls=${props.limitPriceCalls}`;

          return { url };
        },
        keepUnusedDataFor: 86400 // 1 day (seconds)
      }
    ),
    getFacultiesAnalyticsModelByItemPks: builder.query<
      ItemAnalyticsModel[],
      IFacultiesAnalyticsModelQSProps
    >({
      query: (props: IFacultiesAnalyticsModelQSProps) => {
        const individualSksParams = new URLSearchParams();
        if (props && props.individualSks && props.individualSks.length > 0) {
          props.individualSks.forEach(value => individualSksParams.append('individualSks', value));
        }
        const queryPrefix = individualSksParams.toString() != '' ? '?' : '';
        const url = `/item-analytics-model/faculties${queryPrefix}${individualSksParams.toString()}`;

        return { url };
      },
      keepUnusedDataFor: 86400 // 1 day (seconds)
    })
  })
});

export const {
  useLazyGetItemAnalyticsModelByItemPksQuery,
  useLazyGetFacultiesAnalyticsModelByItemPksQuery
} = itemAnalyticsModelEndpoints;
export default itemAnalyticsModelEndpoints;
