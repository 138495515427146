import { ScreenSizeQueries } from '@/constants/breakpoints';
import {
  ABTreatiseCatalogRelations,
  ABTreatiseVariation,
  getABTreatisePks,
  isPrerelease
} from '@/hooks/PDP/usePublicationCatalogRelations';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import {
  buildPublicationTestimonialQuotes,
  formatPublicationAuthors,
  formatPublicationAuthorsDescription,
  formatPublicationDate,
  getPublicationFormat,
  getTreatiseLastUpdatedFileUrl,
  hasPublishedTreatiseSupplements
} from './helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useActions } from '@/hooks/PDP/useActions';
import PDPShimmer from './PDPShimmer';
import classnames from 'classnames';
import {
  PDPSectionLabels,
  PublicationDetailsLabels,
  PublicationTableOfContentLabels
} from './labels';
import PublicationOverview from './PublicationOverview';
import IPDPTopicsAndIndustriesLayoutBlock from '@/components/blocks/PDP/IPDPTopicsAndIndustriesLayoutBlock';
import ShareModal from './Modals/ShareModal';
import PublicationHeader from './PublicationHeader';
import PublicationDetails from './PublicationDetails';
import Publication from './ECommBox/Publication';
import { usePublicationVariantSelection } from '@/hooks/PDP/usePublicationVariantSelection';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import Testimonials from './Testimonials';
import PublicationTableOfContent from '@/components/blocks/PublicationTableOfContent';
import Authors from './Authors';
import SupplementTout from './SupplementTout';
import { usePublicationContent } from '@/hooks/PDP/usePublicationContent';
import { PDPFooter } from './PDPFooter';
import { AnalyticsContext } from '@/analytics/constants';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import { useShareModal } from '@/hooks/PDP/useShareModal';
import { trackPubUpdateInfoContentsEvent, useTrackViewItem } from '@/analytics/pdp';
import { useLibraryItems } from '@/hooks/PDP/useLibraryItems';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { useFetchAnalyticsItems } from '@/analytics/useFetchAnalyticsItems';
import { useCreditTrackerCodeSearchParam } from '@/hooks/useCreditTrackerCode';
import { ProductSEO } from './ProductSEO';

interface IABTreatisePDPProps {
  initialVariant: ABTreatiseVariation;
  catalogRelations: ABTreatiseCatalogRelations;
  context: AnalyticsContext;
}

const ABTreatisePDP = ({ initialVariant, catalogRelations, context }: IABTreatisePDPProps) => {
  const isSelectionLoading = useSelector((state: RootState) => state.pdp.loading);
  const isLoading = catalogRelations.isLoading || isSelectionLoading;
  const { getAnalyticItem } = useFetchAnalyticsItems();
  const [itemAnalyticsModel, setItemAnalyticsModel] = useState<ItemAnalyticsModel>();

  const { publicationEcomLabels } = useCatalogLabels();

  const pubPks = useMemo(() => getABTreatisePks(catalogRelations), [catalogRelations]);

  const creditTrackerCode = useCreditTrackerCodeSearchParam();

  const {
    hydrateLibrary,
    selectLibraryItems,
    selectLibraryItemWithFetchingState,
    isLibraryItemsFetching
  } = useLibraryItems();

  useEffect(() => {
    hydrateLibrary(pubPks);
  }, [hydrateLibrary, pubPks]);

  const ecomLibraryItems = useMemo(() => selectLibraryItems(pubPks), [pubPks, selectLibraryItems]);
  const isLibraryFetching = isLibraryItemsFetching(pubPks);

  const { selectedVariant } = usePublicationVariantSelection(
    initialVariant,
    catalogRelations,
    ecomLibraryItems
  );

  const selectedVariantLibraryItem = useMemo(() => {
    const item = selectLibraryItemWithFetchingState(selectedVariant.code);
    return {
      ...item,
      data: {
        ...item?.data,
        itemAnalyticsModel: {
          ...(item?.data?.itemAnalyticsModel || itemAnalyticsModel)
        }
      }
    };
  }, [selectedVariant.code, itemAnalyticsModel, selectLibraryItemWithFetchingState]);

  useTrackViewItem(selectedVariant.code);

  const isLarge = useMediaQuery(ScreenSizeQueries.lg);

  const getItemAnalyticsModel = useCallback(
    async (code: string) => {
      const data = await getAnalyticItem(code);
      if (data) {
        setItemAnalyticsModel(data);
      }
    },
    [getAnalyticItem]
  );

  useEffect(() => {
    if (selectedVariant.code) getItemAnalyticsModel(selectedVariant.code);
  }, [selectedVariant.code, getItemAnalyticsModel]);

  const generateEcomBox = useCallback(
    (isMobile?: boolean) => {
      return (
        <Publication
          labels={publicationEcomLabels}
          isMobile={isMobile ?? false}
          isPlusSubscriber={catalogRelations.isPlusSubscriber}
          context={context}
          isLibraryFetching={isLibraryFetching}
          creditTrackerCode={creditTrackerCode}
        />
      );
    },
    [
      catalogRelations.isPlusSubscriber,
      context,
      creditTrackerCode,
      isLibraryFetching,
      publicationEcomLabels
    ]
  );
  const shareModalProps = useShareModal(selectedVariant, itemAnalyticsModel, context);

  const actions = useActions(
    selectedVariant,
    catalogRelations,
    selectedVariantLibraryItem,
    shareModalProps.setOpen
  );

  const bookProduct = catalogRelations.bookProduct;
  const testimonialQuotes = buildPublicationTestimonialQuotes(bookProduct);

  const isPreorder = isPrerelease(selectedVariant);

  const contentAreas = usePublicationContent(bookProduct);

  return (
    <>
      <ProductSEO variant={selectedVariant} bookProduct={bookProduct} />
      <div className="container flex flex-col !p-0 lg:flex-row">
        <div className="relative min-w-px lg:grow">
          {/* Inside Scroll area */}
          <PDPShimmer show={isLoading} />
          <PublicationHeader
            title={selectedVariant.displayName ?? ''}
            className={classnames({ hidden: isLoading })}
            variant={selectedVariant}
            hasTestimonials={testimonialQuotes?.length}
            formatEyebrow={getPublicationFormat(selectedVariant, bookProduct, isPreorder)}
            date={formatPublicationDate(selectedVariant, isPreorder)}
            actions={actions}
            authors={formatPublicationAuthors(selectedVariant.author)}
          />
          {/* Mobile Ecomm-Box */}
          {!isLarge && generateEcomBox(true)}
          {contentAreas?.contentArea1}
          <PublicationOverview heading={PDPSectionLabels.overview} variant={selectedVariant} />
          {hasPublishedTreatiseSupplements(selectedVariant) ? <SupplementTout /> : null}
          {contentAreas?.contentArea2}
          <PublicationDetails
            heading={PDPSectionLabels.publicationDetails}
            labels={PublicationDetailsLabels}
            variant={selectedVariant}
            linkOnClick={() => trackPubUpdateInfoContentsEvent(context, itemAnalyticsModel)}
            lastUpdatedFileUrl={getTreatiseLastUpdatedFileUrl(selectedVariant, bookProduct)}
          />
          {contentAreas?.contentArea3}
          <IPDPTopicsAndIndustriesLayoutBlock
            variant={selectedVariant}
            catalogRelations={catalogRelations}
          />
          {contentAreas?.contentArea4}
          {selectedVariant?.sortedTableOfContentListJsonString && (
            <PublicationTableOfContent
              title={PublicationTableOfContentLabels.title}
              searchPlaceholder={PublicationTableOfContentLabels.searchPlaceholder}
              searchBtnLabel={PublicationTableOfContentLabels.searchBtnLabel}
              searchUrl={catalogRelations?.bookProduct?.plusSearchUrl}
              seeAllLabel={PublicationTableOfContentLabels.seeAllLabel}
              seeLessLabel={PublicationTableOfContentLabels.seeLessLabel}
              volumeLabel={PublicationTableOfContentLabels.volumeLabel}
              downloadLabel={PublicationTableOfContentLabels.downloadLabel}
              downloadLink={catalogRelations?.bookProduct?.plusTOCUrl}
              itemAnalyticsModel={itemAnalyticsModel}
              context={context}
              tocItems={JSON.parse(selectedVariant.sortedTableOfContentListJsonString ?? '[]')}
            />
          )}
          {contentAreas?.contentArea5}
          {/*Authors */}
          {selectedVariant?.author && selectedVariant?.author.length > 0 && (
            <Authors authors={formatPublicationAuthorsDescription(selectedVariant?.author)} />
          )}
          {contentAreas?.contentArea6}
          {testimonialQuotes?.length && (
            <Testimonials heading={PDPSectionLabels.testimonials} quotes={testimonialQuotes} />
          )}
          {contentAreas?.contentArea7}
        </div>
        {/* Desktop Ecomm-Box */}
        {isLarge && (
          <div className="container relative w-[422px] shrink-0 px-0">{generateEcomBox()}</div>
        )}
      </div>
      <PDPFooter product={bookProduct} isLoading={isLoading} />
      <ShareModal {...shareModalProps} />
    </>
  );
};

export default ABTreatisePDP;
