import {
  CHBCatalogRelations,
  CHBVariation,
  getCHBPks,
  isPrerelease
} from '@/hooks/PDP/usePublicationCatalogRelations';
import PDPShimmer from './PDPShimmer';
import classnames from 'classnames';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import {
  buildPublicationTestimonialQuotes,
  formatPublicationAuthors,
  formatPublicationAuthorsDescription,
  formatPublicationDate,
  getPublicationFormat
} from './helpers';
import { useCallback, useEffect, useMemo } from 'react';
import ShareModal from './Modals/ShareModal';
import PublicationOverview from './PublicationOverview';
import {
  PDPSectionLabels,
  PublicationDetailsLabels,
  PublicationTableOfContentLabels
} from './labels';
import IPDPTopicsAndIndustriesLayoutBlock from '@/components/blocks/PDP/IPDPTopicsAndIndustriesLayoutBlock';
import { useActions } from '@/hooks/PDP/useActions';
import ProgramCrossLink from './ProgramCrossLink';
import PublicationHeader from './PublicationHeader';
import PublicationDetails from './PublicationDetails';
import Publication from './ECommBox/Publication';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { usePublicationVariantSelection } from '@/hooks/PDP/usePublicationVariantSelection';
import Testimonials from './Testimonials';
import Authors from './Authors';
import PublicationTableOfContent from '@/components/blocks/PublicationTableOfContent';
import { usePublicationContent } from '@/hooks/PDP/usePublicationContent';
import { PDPFooter } from './PDPFooter';
import { AnalyticsContext } from '@/analytics/constants';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import { useShareModal } from '@/hooks/PDP/useShareModal';
import { useTrackViewItem } from '@/analytics/pdp';
import { useLibraryItems } from '@/hooks/PDP/useLibraryItems';
import { useCreditTrackerCodeSearchParam } from '@/hooks/useCreditTrackerCode';
import { ProductSEO } from './ProductSEO';

interface ICourseHandbookPDPProps {
  initialVariant: CHBVariation;
  catalogRelations: CHBCatalogRelations;
  context: AnalyticsContext;
}

const CourseHandbokPDP = ({
  initialVariant,
  catalogRelations,
  context
}: ICourseHandbookPDPProps) => {
  const isSelectionLoading = useSelector((state: RootState) => state.pdp.loading);
  const isLoading = catalogRelations.isLoading || isSelectionLoading;

  const { publicationEcomLabels } = useCatalogLabels();

  const pubPks = useMemo(() => getCHBPks(catalogRelations), [catalogRelations]);

  const {
    hydrateLibrary,
    selectLibraryItems,
    selectLibraryItemWithFetchingState,
    isLibraryItemsFetching
  } = useLibraryItems();

  useEffect(() => {
    hydrateLibrary(pubPks);
  }, [hydrateLibrary, pubPks]);

  const ecomLibraryItems = useMemo(() => selectLibraryItems(pubPks), [pubPks, selectLibraryItems]);
  const isLibraryFetching = isLibraryItemsFetching(pubPks);

  const { selectedVariant } = usePublicationVariantSelection(
    initialVariant,
    catalogRelations,
    ecomLibraryItems
  );

  const selectedVariantLibraryItem = selectLibraryItemWithFetchingState(selectedVariant.code);

  useTrackViewItem(selectedVariant.code);

  const isLarge = useMediaQuery(ScreenSizeQueries.lg);

  const creditTrackerCode = useCreditTrackerCodeSearchParam();

  const generateEcomBox = useCallback(
    (isMobile?: boolean) => {
      return (
        <Publication
          labels={publicationEcomLabels}
          isMobile={isMobile ?? false}
          isPlusSubscriber={catalogRelations.isPlusSubscriber}
          context={context}
          isLibraryFetching={isLibraryFetching}
          creditTrackerCode={creditTrackerCode}
        />
      );
    },
    [
      catalogRelations.isPlusSubscriber,
      context,
      creditTrackerCode,
      isLibraryFetching,
      publicationEcomLabels
    ]
  );

  const shareModalProps = useShareModal(selectedVariant);

  const actions = useActions(
    selectedVariant,
    catalogRelations,
    selectedVariantLibraryItem,
    shareModalProps.setOpen
  );
  const relatedProgram = catalogRelations.relatedProgramVariant;
  const bookProduct = catalogRelations.bookProduct;
  const testimonialQuotes = buildPublicationTestimonialQuotes(bookProduct);

  const isPreorder = isPrerelease(selectedVariant);

  const contentAreas = usePublicationContent(bookProduct);

  return (
    <>
      <ProductSEO variant={selectedVariant} bookProduct={bookProduct} />
      <div className="container flex flex-col !p-0 lg:flex-row">
        <div className="relative min-w-px lg:grow">
          {/* Inside Scroll area */}
          <PDPShimmer show={isLoading} />
          <PublicationHeader
            title={selectedVariant.displayName ?? ''}
            className={classnames({ hidden: isLoading })}
            variant={selectedVariant}
            hasTestimonials={testimonialQuotes?.length}
            formatEyebrow={getPublicationFormat(selectedVariant, bookProduct, isPreorder)}
            date={formatPublicationDate(selectedVariant, isPreorder)}
            actions={actions}
            authors={formatPublicationAuthors(selectedVariant.author)}
          />
          {/* Mobile Ecomm-Box */}
          {!isLarge && generateEcomBox(true)}
          {contentAreas?.contentArea1}
          <PublicationOverview heading={PDPSectionLabels.overview} variant={selectedVariant} />
          {relatedProgram?.displayName?.length && relatedProgram?.url?.length && (
            <ProgramCrossLink
              programDisplayName={relatedProgram?.displayName}
              programUrl={relatedProgram?.url}
            />
          )}
          {contentAreas?.contentArea2}
          <PublicationDetails
            heading={PDPSectionLabels.publicationDetails}
            labels={PublicationDetailsLabels}
            variant={selectedVariant}
          />
          {contentAreas?.contentArea3}
          <IPDPTopicsAndIndustriesLayoutBlock
            variant={selectedVariant}
            catalogRelations={catalogRelations}
          />
          {contentAreas?.contentArea4}
          {selectedVariant?.sortedTableOfContentListJsonString && (
            <PublicationTableOfContent
              title={PublicationTableOfContentLabels.title}
              searchPlaceholder={PublicationTableOfContentLabels.searchPlaceholder}
              searchBtnLabel={PublicationTableOfContentLabels.searchBtnLabel}
              searchUrl={catalogRelations?.bookProduct?.plusSearchUrl}
              seeAllLabel={PublicationTableOfContentLabels.seeAllLabel}
              seeLessLabel={PublicationTableOfContentLabels.seeLessLabel}
              volumeLabel={PublicationTableOfContentLabels.volumeLabel}
              downloadLabel={PublicationTableOfContentLabels.downloadLabel}
              downloadLink={catalogRelations?.bookProduct?.plusTOCUrl}
              tocItems={JSON.parse(selectedVariant.sortedTableOfContentListJsonString ?? '[]')}
            />
          )}
          {contentAreas?.contentArea5}
          {/*Authors */}
          {selectedVariant?.author && selectedVariant?.author.length > 0 && (
            <Authors authors={formatPublicationAuthorsDescription(selectedVariant?.author)} />
          )}
          {contentAreas?.contentArea6}
          {testimonialQuotes?.length && (
            <Testimonials heading={PDPSectionLabels.testimonials} quotes={testimonialQuotes} />
          )}
          {contentAreas?.contentArea7}
        </div>
        {/* Desktop Ecomm-Box */}
        {isLarge && (
          <div className="container relative w-[422px] shrink-0 px-0">{generateEcomBox()}</div>
        )}
      </div>
      <PDPFooter product={bookProduct} isLoading={isLoading} />
      <ShareModal {...shareModalProps} />
    </>
  );
};

export default CourseHandbokPDP;
