import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import SectionTitle from '@/components/ui/Titles/SectionTitle';
import {
  AccordionContainerBlock as AccordionContainerBlockProps,
  IconLinkBlock
} from '@/@types/content';
import Block from '@/components/cms/Block';
import ContentArea from '@/components/cms/ContentArea';
import classnames from 'classnames';

type AccordionContainerBlockPropsExtended = AccordionContainerBlockProps & {
  callToActionListItems?: Array<IconLinkBlock>;
};

const AccordionContainerBlock: React.FC<AccordionContainerBlockPropsExtended> = ({
  accordionItems,
  callToActionListItems,
  contentLink,
  title
}) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const expandAll = useCallback(() => {
    setExpandedItems(
      (accordionItems || []).map((item, index) => `${item.contentLink?.guidValue || index}`)
    );
  }, [accordionItems]);

  const isItemBlock = useMemo(() => {
    if (accordionItems?.length) {
      const contentType = accordionItems[0].contentType as string[];
      return contentType[contentType.length - 1] === 'AccordionItemBlock';
    }
    return false;
  }, [accordionItems]);

  useEffect(() => {
    if (!isItemBlock) {
      expandAll();
    }
  }, [isItemBlock, expandAll]);

  return (
    <Block contentLinkID={contentLink?.id} className="container module-spacing">
      <SectionTitle
        title={title}
        propertyNameTitle="Title"
        elementType="h2"
        divider={isItemBlock ? { color: 'light' } : undefined}
      ></SectionTitle>
      <Accordion.Root
        className={classnames('mt-8 flex flex-col', {
          'gap-3': isItemBlock,
          'gap-8': !isItemBlock
        })}
        type="multiple"
        value={expandedItems}
        onValueChange={setExpandedItems}
      >
        <ContentArea components={accordionItems} componentsProps={{ expandedItems }} noWrapper />
        <ContentArea components={callToActionListItems} noWrapper />
      </Accordion.Root>
    </Block>
  );
};

export default AccordionContainerBlock;
