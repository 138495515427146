import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { trackPliPromoEvent } from '../analytics/user';

export interface PLIPromo {
  sourcecode: string;
  keycode: string;
}
// To be used in App.tsx, save to PLIPromo cookie when an external link (from email, another site, etc) includes tCode parameter
export const usePLIPromoCookieExternalOrigin = () => {
  useEffect(() => {
    const searchParams = new URL(document.location.toString()).searchParams;
    const tCode = searchParams.get('tCode');

    trackPliPromoEvent(tCode);

    writeTcodeToPLIPromoCookie(tCode);
  }, []);
};

// To be used within ReactRouter context, save to PLIPromo cookie when a link routed internally to SPA includes tCode parameter
export const usePLIPromoCookieInternalOrigin = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tCode = searchParams.get('tCode');
    writeTcodeToPLIPromoCookie(tCode);
  }, [searchParams]);
};

const isLocal = (hostname: string = window.location.hostname) =>
  ['localhost', '127.0.0.1', '', '::1'].includes(hostname);

const writeTcodeToPLIPromoCookie = (originalTCode?: string | null) => {
  // Check if valid/sanitize
  if (!originalTCode?.length) return;

  const tCode = originalTCode
    .toUpperCase()
    .replace('_', '')
    .replace(' ', '')
    .replace('-', '')
    .trim();
  if (tCode.length !== 9) return;

  // Write cookie
  const sourceCode = tCode.substring(0, 4);
  const keyCode = tCode.substring(4);
  const cookieValue: PLIPromo = {
    sourcecode: sourceCode,
    keycode: keyCode
  };

  Cookies.set('PLIPromo', JSON.stringify(cookieValue), {
    domain: isLocal() ? '' : 'pli.edu',
    sameSite: 'lax',
    expires: 14
  });
};

export const readTcodeFromPLIPromoCookie = () => {
  const cookieVal = Cookies.get('PLIPromo');
  if (!cookieVal) return;
  const sckc = JSON.parse(cookieVal) as PLIPromo;
  if (sckc.sourcecode.length !== 4 || sckc.keycode.length !== 5) return;
  const tCode = `${sckc.sourcecode}_${sckc.keycode}`;
  if (tCode.length === 10) return tCode;
};
