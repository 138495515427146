import { useEffect, useRef } from 'react';
import classnames from 'classnames';
import * as Dialog from '@radix-ui/react-dialog';
import Content, { BaseBoxContentProps } from './Content';
import Footer, { BaseBoxFooterProps } from './Footer';
import Card, { BaseBoxCardProps } from './Card';
import StickyButton, { BaseBoxStickyButtonProps } from './StickyButton';
import Modal, { BaseBoxModalProps } from './Modal';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import PanelHeader, { BaseBoxPanelHeaderProps } from './PanelHeader';

interface BaseBoxProps {
  children: React.ReactNode;
  sticky?: boolean;
  showModal?: boolean;
  onCloseModal?: () => void;
  withinPanel?: boolean;
  className?: string;
}

interface BaseBoxPrivateProps {
  Content: React.FC<BaseBoxContentProps>;
  Footer: React.FC<BaseBoxFooterProps>;
  Card: React.FC<BaseBoxCardProps>;
  StickyButton: React.FC<BaseBoxStickyButtonProps>;
  Modal: React.FC<BaseBoxModalProps>;
  PanelHeader: React.FC<BaseBoxPanelHeaderProps>;
}

const BaseBox: React.FC<BaseBoxProps> & BaseBoxPrivateProps = ({
  children,
  sticky,
  showModal,
  onCloseModal,
  withinPanel = false,
  className
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const stickyHeader = useSelector((state: RootState) => state.page.stickyHeader);

  useEffect(() => {
    if (withinPanel) {
      return;
    }
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal, withinPanel]);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    if (stickyHeader.isSticky) {
      container.style.top = `${stickyHeader.headerHeight}px`;
    } else {
      container.style.top = '0';
    }
  }, [stickyHeader.headerHeight, stickyHeader.isSticky]);

  if (withinPanel) {
    return (
      <div className="absolute inset-x-0 bottom-0 top-10">
        <div className="relative flex h-full flex-col">{children}</div>
      </div>
    );
  }

  return (
    <Dialog.Root open={showModal} onOpenChange={onCloseModal}>
      <div
        ref={containerRef}
        className={classnames(
          'top-0 z-1 flex flex-col border-gray-light pb-10 transition-all lg:h-screen lg:border-l lg:pb-0 xl:border-r',
          className,
          { sticky }
        )}
      >
        {children}
      </div>
    </Dialog.Root>
  );
};

BaseBox.Content = Content;
BaseBox.Footer = Footer;
BaseBox.Card = Card;
BaseBox.StickyButton = StickyButton;
BaseBox.Modal = Modal;
BaseBox.PanelHeader = PanelHeader;

export default BaseBox;
