import { EcomBookVariantFormat } from '@/redux/slices/pdpSlice';
import classnames from 'classnames';
import { useMemo } from 'react';
import {
  formatStsExpirationDate,
  formatStsRenewalDateFromExpirationDate,
  isFreeDigitalAccess
} from '../../../helpers';
import Shimmer from '@/components/ui/Shimmer';
import { CurrencySign } from '@/constants/currency';

interface PublicationProps {
  labels: {
    includedInPlus: string;
    purchased: string;
    stsAvailable: string;
    programAttendeeAccess: string;
    digitalAccess: string;
    printHardcover: string;
    printSoftcover: string;
    printBinder: string;
    printPreorder: string;
    sts: string;
    stsRenewal: string;
  };
  format?: EcomBookVariantFormat;
  price?: string;
  originalPrice?: string;
  isPlusSubscriber?: boolean;
  purchased?: boolean;
  purchasedStsExpirationDate?: string;
  isSelected?: boolean;
  onClick?: () => void;
  shimmer?: boolean;
}

const Variation: React.FC<PublicationProps> = ({
  labels,
  format,
  price,
  originalPrice,
  purchased,
  purchasedStsExpirationDate,
  isSelected,
  onClick,
  shimmer
}) => {
  const formats: { [key: string]: string } = useMemo(
    () => ({
      'digital-program': labels.digitalAccess,
      'digital-plusMember': labels.digitalAccess,
      hardcover: labels.printHardcover,
      softcover: labels.printSoftcover,
      binder: labels.printBinder,
      preorder: labels.printPreorder,
      sts: labels.sts,
      'sts-renewal': labels.stsRenewal
    }),
    [labels]
  );

  const infoText = useMemo(() => {
    if (format === 'digital-program') return labels.programAttendeeAccess;
    if (format === 'sts')
      return purchased
        ? `Expires ${formatStsExpirationDate(purchasedStsExpirationDate)}`
        : labels.stsAvailable;
    if (format === 'sts-renewal') {
      return `Start renewal ${formatStsRenewalDateFromExpirationDate(purchasedStsExpirationDate)} to avoid interruption in access`;
    }
    return undefined;
  }, [
    format,
    labels.programAttendeeAccess,
    labels.stsAvailable,
    purchased,
    purchasedStsExpirationDate
  ]);

  const displayZeroDollarDigitalAccess = isFreeDigitalAccess(format);
  const displayIncludedWithPlusMembership = format === 'digital-plusMember';

  if (!onClick) {
    return (
      <div className="flex justify-between">
        <p className="text-2 font-medium">{formats[format!]}</p>
        <div className="text-2 flex gap-[7px] font-medium text-gray">
          {originalPrice && <p className="line-through">{originalPrice}</p>}
          <p>{displayZeroDollarDigitalAccess ? `${CurrencySign.USD}0.00` : price}</p>
        </div>
      </div>
    );
  }

  if (shimmer)
    return <Shimmer className="h-[68px] w-full overflow-hidden rounded sm:h-[70px] lg:h-[72px]" />;
  return (
    <button
      className={classnames(
        'w-full overflow-hidden rounded border text-left transition-colors hover:border-black',
        {
          'border-gray': !isSelected,
          'border-black': isSelected
        }
      )}
      onClick={onClick}
    >
      {displayIncludedWithPlusMembership && (
        <p className="label bg-black px-5 py-1 text-white">{labels.includedInPlus}</p>
      )}
      <div
        className={classnames('flex items-center justify-between px-5 py-[18px]', {
          'bg-gray-lightest': isSelected
        })}
      >
        <div>
          <p className="text-2 font-medium">{formats[format!]}</p>
          {!!infoText && <p className="label text-gray">{infoText}</p>}
        </div>
        <div className="label flex w-[111px] shrink-0 justify-end gap-[7px] text-gray">
          {originalPrice && <p className="line-through">{originalPrice}</p>}
          <p>
            {displayZeroDollarDigitalAccess
              ? `${CurrencySign.USD}0.00`
              : purchased
                ? labels.purchased
                : price}
          </p>
        </div>
      </div>
    </button>
  );
};

export default Variation;
