import Divider from '@/components/ui/Divider';

type SearchResultsQueryProps = {
  query: string;
  className?: string;
  dividerClassName?: string;
};

const SearchResultsQuery = ({ query, className, dividerClassName }: SearchResultsQueryProps) => {
  return (
    <>
      <h1 className={className} data-testid="SearchResultsQuery">
        <div className="label mb-2 font-bold uppercase text-gray-dark">Search results for</div>
        <div className="heading-3">“{query}”</div>
      </h1>
      <Divider color="light" className={dividerClassName} />
    </>
  );
};

export default SearchResultsQuery;
