import { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import Link from './Link';
import RichText, { RichTextProps } from '../cms/RichText';

interface TextProps extends RichTextProps {}

const ExpandableRichText: React.FC<TextProps> = ({ className, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCollapsable, setIsCollapsable] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const isEditMode = isEditOrPreviewMode();

  const getMaxHeight = useCallback(() => {
    if (!contentRef.current) return 0;

    let maxHeight = 0;
    contentRef.current.classList.add('expandable-line-clamp');
    maxHeight = contentRef.current.clientHeight;
    contentRef.current.classList.remove('expandable-line-clamp');
    return maxHeight;
  }, []);

  const setMaxHeight = useCallback(() => {
    if (!wrapperRef.current || !contentRef.current || isEditMode) return;

    const maxHeight = getMaxHeight();
    setIsCollapsable(contentRef.current.clientHeight > maxHeight);

    if (!isExpanded) {
      wrapperRef.current.style.maxHeight = `${maxHeight}px`;
    } else {
      wrapperRef.current?.style.removeProperty('max-height');
    }
  }, [getMaxHeight, isEditMode, isExpanded]);

  useEffect(() => {
    setMaxHeight();

    window.addEventListener('resize', setMaxHeight);
    return () => window.removeEventListener('resize', setMaxHeight);
  }, [setMaxHeight]);

  return (
    <div className={classnames(className)}
    data-component={"ExpandableRichText"}
    >
      <div ref={wrapperRef} className="overflow-hidden">
        <RichText ref={contentRef} {...props} />
      </div>
      {isCollapsable && (
        <div className="mt-6 lg:mt-8">
          <Link
            as="button"
            text={isExpanded ? 'See less' : 'See more'}
            variant="expand-button"
            icon={isExpanded ? 'chevron-up' : 'chevron-down'}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
      )}
    </div>
  );
};

export default ExpandableRichText;
