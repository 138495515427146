import { useState } from 'react';
import classnames from 'classnames';
import * as Select from '@radix-ui/react-select';
import MenuItem, { MenuItemText } from './Menu/MenuItem';
import MenuText from './Menu/MenuText';
import TagButton from './Buttons/TagButton';

type TSimpleSortProps = {
  options: (MenuItemText & {
    disabled?: boolean;
    value?: string | null;
    icon?: string;
  })[];
  value?: string | null;
  onValueChange?: (value: string) => void;
  menuStyle?: 'standalone' | 'standalone-dark' | 'standalone-dark-unsorted';
  placeholder?: string;
  label?: string;
  valueClassName?: string;
  disabled?: boolean;
  smallClassName?: string;
  avoidCollisions?: boolean;
  align?: 'start' | 'end';
};

const SELECTED_ICON_NAME = 'checkmark';

const SimpleSort = ({
  menuStyle = 'standalone',
  options,
  onValueChange,
  value,
  placeholder = 'Choose an option...',
  valueClassName,
  disabled = false,
  smallClassName,
  avoidCollisions,
  align = 'end'
}: TSimpleSortProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption = options?.find(o => o.value === value);

  const sortedOptions =
    menuStyle === 'standalone-dark'
      ? [...options]?.sort((a, b) =>
          ((a.label ?? '') as string).localeCompare((b.label ?? '') as string)
        )
      : options;

  const buttonTitle =
    (menuStyle === 'standalone-dark' || menuStyle === 'standalone-dark-unsorted') && placeholder ? (
      `${placeholder}${selectedOption?.label}`
    ) : (
      <Select.Value />
    );

  const containerClasses = classnames(
    'mt-2 min-w-[210px] rounded border border-silver bg-white py-3',
    {
      'sm:z-20': menuStyle === 'standalone',
      'max-h-[250px] overflow-y-auto rounded-t-lg py-3':
        menuStyle === 'standalone-dark' || menuStyle === 'standalone-dark-unsorted'
    }
  );

  return (
    <Select.Root
      value={value || ''}
      defaultOpen={isOpen}
      open={isOpen}
      onOpenChange={open => setIsOpen(open)}
      onValueChange={onValueChange}
    >
      <Select.Trigger asChild disabled={disabled}>
        <TagButton
          label={
            selectedOption && (
              <MenuText
                value={buttonTitle}
                eyebrow={selectedOption?.eyebrow}
                valueClassName={valueClassName}
                smallClassName={smallClassName}
              />
            )
          }
          isActive={isOpen}
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          styleType="solid"
          onClick={() => setIsOpen(v => !v)}
          aria-label={`Click to ${isOpen ? 'close' : 'open'} the sorting options. Currently selected: ${value}.`}
        />
      </Select.Trigger>
      <Select.Content position="popper" align={align} avoidCollisions={avoidCollisions}>
        <div className={containerClasses}>
          <Select.Viewport>
            {sortedOptions?.map(option => {
              const isSelected = value === option.value;
              return (
                <Select.Item key={option.value} value={option.value || ''} asChild>
                  <MenuItem
                    menuStyle={menuStyle}
                    condensed={true}
                    icon={option.icon}
                    rightIcon={isSelected ? SELECTED_ICON_NAME : undefined}
                    label={<Select.ItemText>{option.label}</Select.ItemText>}
                    eyebrow={option.shortEyebrow || option.eyebrow}
                    disabled={option.disabled}
                    onClick={() => onValueChange?.(option.value || '')}
                    smallClassName={smallClassName}
                  />
                </Select.Item>
              );
            })}
          </Select.Viewport>
        </div>
      </Select.Content>
    </Select.Root>
  );
};

export default SimpleSort;
