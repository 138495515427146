/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApiEndpointMutation,
  BaseQueryExtraOptions,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  MutationDefinition
} from '@reduxjs/toolkit/query/react';
import { getOIDCStateAction, userManager } from '@/auth/oidcConfig';
import { prepareHeaders, responseHandlerNoAuthRedirect } from '../helpers';
import { Store, UnknownAction } from '@reduxjs/toolkit';
import { contentDeliveryApi } from './contentDeliveryAPI';

export const CLIENT_API_PATH = '/api/client/';

export type baseQueryExtraOptions = BaseQueryExtraOptions<BaseQueryFn> & {
  ignoreDelayedAction?: boolean;
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${window.env?.CONTENT_DELIVERY_API ?? ''}${CLIENT_API_PATH}`,
  prepareHeaders,
  responseHandler: responseHandlerNoAuthRedirect,
  credentials: 'include',
  mode: 'cors'
});

export const repeatAction = async (
  args: string | FetchArgs,
  endpoint: string,
  store: Store,
  extraOptions?: baseQueryExtraOptions
) => {
  const _args = args as FetchArgs;
  if (_args) {
    let promise: UnknownAction;
    if (_args.method === 'POST' && !extraOptions?.ignoreDelayedAction) {
      const { initiate } = (ClientAPI.endpoints as any)[endpoint] as unknown as ApiEndpointMutation<
        MutationDefinition<any, any, any, any, any>,
        any
      >;
      const param =
        (args as FetchArgs).body === undefined ? (args as any).param : (args as FetchArgs).body;
      promise = await store.dispatch(initiate(param, {}) as unknown as UnknownAction);
      if ('error' in promise) {
        throw new Error('Failed executing delayed action');
      }
    } else {
      /*  const {
          initiate
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = ((ClientAPI.endpoints as any)[endpoint] as unknown as ApiEndpointQuery<QueryDefinition<any, any, any, any, any>, any>);
        promise = store.dispatch(initiate({}, {}) as unknown as UnknownAction);*/
      return;
    }
    return promise;
  } else {
    return;
  }
};

const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions: baseQueryExtraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    await userManager.signinRedirect({
      state: getOIDCStateAction(args, api.endpoint, extraOptions)
    });
  }
  return result;
};

export const ClientAPI = createApi({
  reducerPath: 'api/client',
  tagTypes: ['Sample', 'CreditTrackerData', 'CartData', 'Search', 'LibraryItem', 'Library'],
  baseQuery: baseQueryWithAuth,
  endpoints: () => ({})
});

export const invalidateUserData = (isLogout: boolean = false) => {
  if (!isLogout) {
    //logout is handled in the mutation for CD api
    contentDeliveryApi.util.invalidateTags(['Global', 'Content']);
  }
  ClientAPI.util.invalidateTags(['CartData', 'CreditTrackerData', 'LibraryItem', 'Library']);
};
