import { ClientAPI } from '@/redux/api';
import type { LaunchElectronicItemRequest, LaunchResponse } from '@/@types/client-api';

export interface ILaunchRequestProps {
  code: string;
  body: LaunchElectronicItemRequest;
}

const launchEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    launch: builder.mutation<LaunchResponse, ILaunchRequestProps>({
      query: props => ({
        url: `/launch/${props.code}`,
        method: 'POST',
        body: props.body
      })
    })
  })
});

export const { useLaunchMutation } = launchEndpoints;

export default launchEndpoints;
