import { isValidElement } from 'react';
import classnames from 'classnames';
import * as ToastPrimitive from '@radix-ui/react-toast';
import ScrollArea from '../ScrollArea';
import Icon from '../Icon';

export interface ToastProps {
  type?: 'default' | 'confirmation' | 'alert' | 'credit-confirmation';
  icon?: boolean;
  iconName?: string;
  children?: React.ReactNode;
  open?: boolean;
  duration?: number;
  onOpenChange?: (open: boolean) => void;
}

const Toast: React.FC<ToastProps> = ({
  type = 'default',
  icon,
  iconName = 'info',
  children,
  open,
  duration,
  onOpenChange
}) => {
  let stackedIcon = false;
  if (type != 'alert' && duration == undefined) {
    duration = 8000;
  }
  const countCharacters = () => {
    if (!children) {
      return 0;
    } else if (typeof children === 'string') {
      return children.length;
    } else if (isValidElement(children)) {
      const toastElement = children.props;
      if (typeof toastElement === 'object' && toastElement?.message) {
        return toastElement.message.length ?? 0;
      }
      return children.props?.children?.length ?? 0;
    } else if (Array.isArray(children)) {
      let count = 0;
      children.forEach(child => {
        count += child.props.children.length;
      });
      return count;
    } else {
      return 0;
    }
  };

  if (countCharacters() > 85 || !countCharacters()) {
    stackedIcon = true;
  }

  const toastWrapperClasses = classnames(
    'flex justify-between rounded-lg px-[20px] py-[16px] data-[state=closed]:animate-fadeOut data-[state=open]:animate-fadeIn',
    {
      'items-center': !stackedIcon,
      grid: icon,
      'bg-gray-dark': type === 'default',
      'bg-gold': type === 'alert',
      'bg-serpentine': type === 'confirmation',
      'bg-black py-[12px] lg:py-[16px]': type === 'credit-confirmation'
    }
  );

  const toastContentClasses = classnames('text-2 inline-block', {
    'text-white': type === 'default' || type === 'confirmation',
    'text-black': type === 'alert',
    'col-start-1 row-start-1 ml-10': icon && !stackedIcon,
    'row-start-2 pr-4': stackedIcon,
    'text-emerald': type === 'credit-confirmation'
  });

  const scrollAreaClasses = classnames('grid max-h-[165px] lg:max-h-[245px]', {
    'col-start-1 row-start-1': icon && !stackedIcon,
    'col-start-1 col-end-3 mt-2': stackedIcon
  });

  const iconClasses = classnames({
    'text-white': type === 'default' || type === 'confirmation',
    'text-black': type === 'alert',
    'text-emerald': type === 'credit-confirmation',
    'col-start-1 row-start-1 mr-4': icon && !stackedIcon,
    'col-start-1 row-start-1': stackedIcon
  });

  const closeIconClasses = classnames({
    'text-white': type === 'default' || type === 'confirmation',
    'text-black': type === 'alert',
    'col-start-2 row-start-1 justify-self-end': icon
  });

  return (
    <ToastPrimitive.Root
      className={toastWrapperClasses}
      open={open}
      onOpenChange={onOpenChange}
      duration={duration}
    >
      {icon && <Icon name={iconName} className={iconClasses} />}
      <ScrollArea className={scrollAreaClasses} thumbClassName="!bg-gray">
        <ToastPrimitive.Title className={toastContentClasses}>{children}</ToastPrimitive.Title>
      </ScrollArea>
      {type === 'credit-confirmation' ? null : (
        <ToastPrimitive.Close className={closeIconClasses}>
          <Icon name="close" />
        </ToastPrimitive.Close>
      )}
    </ToastPrimitive.Root>
  );
};

export default Toast;
