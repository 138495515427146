import { Currency } from '@/constants/currency';
import { AnalyticsContext, AnalyticsEventName } from './constants';
import { trackEvent } from './utils';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { ConvertItemAnalyticsModel, ConvertItemAnalyticsModelBase } from './analyticsUtils';

const generateEcommerceObject = (data: object) => ({
  ecommerce: { ...data }
});

export const trackSelectItem = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext,
  listId?: string,
  listName?: string
) => {
  if (itemAnalyticsModel && context) {
    trackEvent({
      eventName: AnalyticsEventName.SelectItem,
      parameters: {
        context: context || '',
        ...generateEcommerceObject({
          item_list_id: listId || '',
          item_list_name: listName || '',
          ...ConvertItemAnalyticsModel(itemAnalyticsModel, 1)
        })
      },
      skipBaseEventParameters: true
    });
  }
};

type TrackAddToWishlistEventProps = {
  productCode?: string;
  context?: AnalyticsContext;
  title?: string;
  value?: number;
};

export const trackAddToWishlist = ({
  productCode,
  context,
  title = '',
  value = 0
}: TrackAddToWishlistEventProps) => {
  if (productCode && context) {
    trackEvent({
      eventName: AnalyticsEventName.AddToWishlist,
      parameters: {
        productCode,
        context,
        value,
        currency: Currency.USD,
        items: [
          {
            item_id: productCode,
            item_name: title
          }
        ]
      }
    });
  }
};

export const trackAddToCart = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext,
  transactionId?: string
) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.AddToCart,
      parameters: {
        ...generateEcommerceObject({
          transaction_id: transactionId || '',
          items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
        }),
        context: context || '',
        skipBaseEventParameters: true
      }
    });
  }
};

export const trackBeginCheckoutEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext,
  transactionId?: string
) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.BeginCheckout,
      parameters: {
        ...generateEcommerceObject({
          transaction_id: transactionId || '',
          items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
        }),
        context: context || '',
        skipBaseEventParameters: true
      }
    });
  }
};

export const trackPurchaseEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext,
  transactionId?: string
) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.Purchase,
      parameters: {
        ...generateEcommerceObject({
          transaction_id: transactionId || '',
          items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
        }),
        context: context || '',
        skipBaseEventParameters: true
      }
    });
  }
};

export const trackZeroPurchaseValueEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.ZeroPurchaseValue,
      parameters: {
        ...generateEcommerceObject({
          items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
        }),
        context: context || '',
        skipBaseEventParameters: true
      }
    });
  }
};

export const trackViewItemEvent = (itemAnalyticsModel?: ItemAnalyticsModel) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.ViewItem,
      parameters: generateEcommerceObject(ConvertItemAnalyticsModel(itemAnalyticsModel)),
      skipBaseEventParameters: true
    });
  }
};

export const trackViewItemListEvent = (
  listId: string,
  listName: string,
  itemsAnalyticsModel?: ItemAnalyticsModel[]
) => {
  if (itemsAnalyticsModel && itemsAnalyticsModel?.length > 0) {
    trackEvent({
      eventName: AnalyticsEventName.ViewItemList,
      parameters: generateEcommerceObject({
        item_list_id: listId,
        item_list_name: listName,
        items: itemsAnalyticsModel?.map(item => ConvertItemAnalyticsModelBase(item))
      }),
      skipBaseEventParameters: true
    });
  }
};

export const trackAddWishListEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.AddToWishlist,
      parameters: {
        ...generateEcommerceObject(ConvertItemAnalyticsModel(itemAnalyticsModel, 1)),
        context: context || ''
      },
      skipBaseEventParameters: true
    });
  }
};

export const trackRemoveWishListEvent = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => {
  if (itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.RemoveFromWishlist,
      parameters: {
        context: context || '',
        items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
      },
      skipBaseEventParameters: true
    });
  }
};
