import { forwardRef as ReactForwardRef } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import LoadingDots, { LoadingDotsColor } from '@/components/ui/Buttons/LoadingDots';

export type ButtonColor =
  | 'black'
  | 'white'
  | 'outline-black'
  | 'outline-gray'
  | 'outline-white'
  | 'light-gray'
  | 'red'
  | 'green'
  | 'gradient';

export type ButtonSize = 'small' | 'short' | 'large';
export interface ButtonProps {
  propertyName?: string;
  label?: string;
  labelPadding?: boolean;
  size?: ButtonSize;
  disabled?: boolean;
  iconLeft?: string;
  iconRight?: string;
  loading?: boolean;
  ariaLabel?: string;
  color?: ButtonColor;
}
const loadingDotsColorMap: { [key in ButtonColor]: LoadingDotsColor } = {
  ['black']: 'white',
  ['white']: 'black',
  ['outline-black']: 'black',
  ['outline-gray']: 'black',
  ['outline-white']: 'black',
  ['light-gray']: 'black',
  ['red']: 'white',
  ['green']: 'white',
  ['gradient']: 'white'
};

type ButtonOrAnchorProps = ButtonProps &
  (
    | Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>
    | Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'children'>
  );

const Button = ReactForwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonOrAnchorProps>(
  (
    {
      propertyName,
      label,
      labelPadding = true,
      className,
      size = 'small',
      color = 'black',
      iconLeft,
      iconRight,
      disabled,
      loading,
      ariaLabel,
      ...props
    },
    ref
  ) => {
    const sizeClasses = classnames({
      'h-14': size === 'large',
      'h-11': size === 'short',
      'h-8': size === 'small'
    });

    const buttonClassNames = classnames(
      'group relative flex items-center overflow-hidden whitespace-nowrap rounded px-6 font-bold transition',
      sizeClasses,
      {
        'bg-black text-white hover:bg-gray-dark active:text-opacity-70 disabled:bg-gray disabled:text-gray-lightest':
          color === 'black',
        'bg-white text-black hover:bg-gray-lightest focus-visible:outline-white active:bg-gray-light active:text-opacity-70 disabled:bg-gray-light disabled:text-gray-dark':
          color === 'white',
        'bg-gray-lightest text-black hover:bg-gray-lightest active:text-gray-dark disabled:bg-gray-lightest disabled:text-gray':
          color === 'light-gray',
        'border border-gray text-black hover:border-black active:border-gray active:text-gray disabled:border-gray disabled:text-gray':
          color === 'outline-black',
        'border border-silver text-white hover:border-white focus-visible:outline-white active:text-opacity-70 disabled:border-gray-light disabled:text-gray':
          color === 'outline-white',
        'border border-silver text-black hover:border-black active:border-gray active:text-gray disabled:border-gray disabled:text-gray':
          color === 'outline-gray',
        'bg-red text-white hover:bg-red-dark active:bg-red-dark active:text-opacity-70 disabled:bg-gray disabled:text-gray-lightest':
          color === 'red',
        'bg-serpentine text-white hover:bg-serpentine-dark active:text-opacity-70 disabled:bg-gray disabled:text-gray-lightest':
          color === 'green',
        'bg-black text-white before:absolute before:inset-0 before:size-full before:bg-gradient before:transition before:hover:opacity-80 active:text-white/80 disabled:bg-gray disabled:text-gray-lightest disabled:before:hidden':
          color === 'gradient',
        'justify-center': !iconRight,
        'justify-between': iconRight
      },
      className,
      {
        'w-fit': 'href' in props
      },
      {
        'pointer-events-none': loading
      }
    );

    const labelClassNames = classnames(
      'text-1 pointer-events-none relative',
      {
        'px-2': labelPadding,
        'pr-2': !labelPadding && iconRight,
        'pl-2': !labelPadding && iconLeft
      },
      {
        'text-1-fixed': size === 'small'
      }
    );

    const ButtonLabel = () => (
      <span data-epi-edit={!('href' in props) ? propertyName : null} className={labelClassNames}>
        {label}
      </span>
    );

    const children = (
      <>
        {!!iconLeft && !loading && <Icon size="small" name={iconLeft} />}
        {!!label && !loading && <ButtonLabel />}
        {!!iconRight && !loading && (
          <Icon
            size="small"
            name={iconRight}
            className={classnames('shrink-0', { 'z-1': color === 'gradient' })}
          />
        )}
        {!!loading && <LoadingDots color={loadingDotsColorMap[color]} />}
      </>
    );

    const isAnchorLink = (href: string) => /^#/.test(href);

    if (isEditOrPreviewMode() && propertyName) {
      return (
        <span data-epi-edit={'href' in props ? propertyName : null} className={buttonClassNames}>
          {children}
        </span>
      );
    }

    if ('href' in props && !disabled) {
      const { href } = props as React.AnchorHTMLAttributes<HTMLAnchorElement>;

      if (isAnchorLink(href as string)) {
        return (
          <a
            ref={ref as React.Ref<HTMLAnchorElement>}
            className={buttonClassNames}
            href={href}
            {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
          >
            {children}
          </a>
        );
      } else {
        return (
          <Link
            ref={ref as React.Ref<HTMLAnchorElement>}
            className={buttonClassNames}
            to={props.href || ''}
            {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
          >
            {children}
          </Link>
        );
      }
    }

    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        className={buttonClassNames}
        disabled={disabled}
        aria-label={loading ? 'Loading' : ariaLabel}
        {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        {children}
      </button>
    );
  }
);

export default Button;
