import type { Config } from 'tailwindcss';
import plugin from 'tailwindcss/plugin';
import tailwindCssAnimate from 'tailwindcss-animate';

export default {
  content: ['./index.html', './src/**/*.{ts,tsx,mdx}'],
  theme: {
    screens: {
      sm: '576px',
      smmiddle: '700px',
      md: '889px',
      lg: '1201px',
      xl: '1440px',
      '2xl': '1680px'
    },
    colors: {
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      charcoal: '#17181c',
      silver: '#ccc',
      gray: {
        darker: '#333',
        dark: '#53565a',
        DEFAULT: '#888',
        medium: '#b1b3b3',
        light: '#ececec',
        lightest: '#f5f5f5',
        feather: '#f7f7f7'
      },
      serpentine: {
        DEFAULT: '#01793f',
        dark: '#016132'
      },
      emerald: '#01c365',
      gold: {
        DEFAULT: '#947100',
        dark: '#b58d00'
      },
      red: {
        DEFAULT: '#ea0029',
        dark: '#bb0029',
        darker: '#AF001F',
        brand: '#e4002b',
        'brand-header': '#CD0027'
      },
      amethist: '#9b2c98',
      orange: '#cf4b00',
      'sherpa-blue': '#003a4d',
      dot: {
        DEFAULT: '#E3012D',
        dark: '#CB1051',
        darker: '#AA2482'
      },
      green: {
        DEFAULT: '#2D712A',
        100: '#003324',
        200: '#006647',
        300: '#008f64',
        400: '#00b881'
      },
      teal: {
        100: '#04303c',
        200: '#075c73',
        300: '#097b9a',
        400: '#0b99c1'
      },
      blue: {
        100: '#020664',
        200: '#03078c',
        300: '#040ab4',
        400: '#040cdc'
      },
      indigo: {
        100: '#1c0060',
        200: '#2b008f',
        300: '#3700b8',
        400: '#4300e0'
      },
      purple: {
        100: '#340147',
        200: '#52016b',
        300: '#6c018c',
        400: '#8701ac'
      },
      magenta: {
        100: '#4c012d',
        200: '#790247',
        300: '#a1025f',
        400: '#ca0277'
      }
    },
    fontFamily: {
      sans: ['Helvetica Neue', 'sans-serif']
    },
    transitionDuration: {
      DEFAULT: '0.4s',
      short: '0.2s',
      long: '0.8s'
    },
    transitionTimingFunction: {
      DEFAULT: 'cubic-bezier(0, 0, 0.1, 1)',
      out: 'cubic-bezier(0.9, 0, 1, 1)',
      easy: 'cubic-bezier(0.4, 0, 0.4, 1)',
      bounce: 'cubic-bezier(0.4, 0, 1, 0.78)'
    },
    extend: {
      aspectRatio: {
        '1.5': '1.5'
      },
      backgroundImage: {
        'collection-card-gradient': 'linear-gradient(151.88deg, #043441 45.83%, #2D712A 100%)',
        gradient: 'linear-gradient(103deg, #E4002B 17.23%, #9B2C98 100%)',
        'white-overflow-gradient':
          'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 19.26%)',
        'sticky-button-gradient':
          'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 26.04%)'
      },
      zIndex: {
        '1': '1',
        'sticky-subnav': '100',
        dropdown: '101',
        tooltip: '900',
        'sticky-header-overlay': '998',
        'sticky-header': '999',
        panel: '9999',
        modal: '99999',
        toast: '999999'
      },
      keyframes: {
        accordionSlideDown: {
          from: { height: '0px' },
          to: { height: 'var(--radix-accordion-content-height)' }
        },
        accordionSlideUp: {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0px' }
        },
        collapsibleSlideDown: {
          from: { height: '0px' },
          to: { height: 'var(--radix-collapsible-content-height)' }
        },
        collapsibleSlideUp: {
          from: { height: 'var(--radix-collapsible-content-height)' },
          to: { height: '0px' }
        },
        searchOverlaySlideIn: {
          from: { maxHeight: '72px', opacity: '0' },
          to: { maxHeight: '100%', opacity: '1' }
        },
        panelL2SlideIn: {
          from: { transform: 'translateX(40px)', opacity: '0' },
          to: { transform: 'translateX(0)', opacity: '1' }
        },
        panelL2SlideOut: {
          from: { transform: 'translateX(0px)', opacity: '1' },
          to: { transform: 'translateX(40)', opacity: '0' }
        },
        fadeIn: {
          from: { opacity: '0' },
          to: { opacity: '1' }
        },
        fadeOut: {
          from: { opacity: '1' },
          to: { opacity: '0' }
        },
        blurIn: {
          from: { filter: 'blur(0px)' },
          to: { filter: 'blur(7px)' }
        },
        blurOut: {
          from: { filter: 'blur(7px)' },
          to: { filter: 'blur(0px)' }
        },
        slideAcrossLeft: {
          from: { transform: 'translateX(100%)' },
          to: { transform: 'translateX(-100%)' }
        },
        slideInUp: {
          from: { transform: 'translateY(100%)' },
          to: { transform: 'translateY(0)' }
        },
        slideOutDown: {
          from: { transform: 'translateY(0)' },
          to: { transform: 'translateY(100%)', display: 'none' }
        },
        bounce: {
          '0%': { transform: 'translateY(0)' },
          '10%': { transform: 'translateY(-7px)' },
          '40%': { transform: 'translateY(0)' }
        },
        radixOut: {
          // We need an animation for Radix to keep components from unmounting, but we don't want it to actually animate anything
          from: { margin: '0px' },
          to: { margin: '1px' }
        }
      },
      animation: ({ theme }) => ({
        accordionSlideDown: `accordionSlideDown 300ms ${theme('transitionTimingFunction.easy')}`,
        accordionSlideUp: `accordionSlideUp 300ms ${theme('transitionTimingFunction.easy')}`,
        collapsibleSlideDown: `collapsibleSlideDown 300ms ${theme('transitionTimingFunction.easy')}`,
        collapsibleSlideUp: `collapsibleSlideUp 300ms ${theme('transitionTimingFunction.easy')}`,
        searchOverlaySlideIn: `searchOverlaySlideIn 400ms ${theme('transitionTimingFunction.easy')}`,
        searchOverlayFadeOut: `fadeOut ${theme('transitionDuration.short')} ${theme('transitionTimingFunction.easy')}`,
        fadeIn: `fadeIn 400ms ${theme('transitionTimingFunction.easy')}`,
        fadeOut: `fadeOut 400ms ${theme('transitionTimingFunction.easy')}`,
        fadeInShort: `fadeIn ${theme('transitionDuration.short')} ${theme('transitionTimingFunction.easy')}`,
        fadeOutShort: `fadeOut ${theme('transitionDuration.short')} ${theme('transitionTimingFunction.easy')}`,
        fadeInLong: `fadeIn 1500ms ${theme('transitionTimingFunction.easy')}`,
        fadeOutLong: `fadeOut 1500ms ${theme('transitionTimingFunction.easy')}`,
        blurIn: `blurIn 250ms ${theme('transitionTimingFunction.easy')}`,
        blurOur: `blurOut 250ms ${theme('transitionTimingFunction.easy')}`,
        slideAcrossLeft: `slideAcrossLeft 3000ms ${theme('transitionTimingFunction.easy')} infinite`,
        slideInUp: `slideInUp 400ms ${theme('transitionTimingFunction.easy')}`,
        slideOutDown: `slideOutDown 400ms ${theme('transitionTimingFunction.easy')} forwards`,
        slideInUpShort: `slideInUp ${theme('transitionDuration.short')} ${theme('transitionTimingFunction.easy')}`,
        slideOutDownShort: `slideOutDown ${theme('transitionDuration.short')} ${theme('transitionTimingFunction.easy')} forwards`,
        panelL2SlideIn: `panelL2SlideIn 400ms ${theme('transitionTimingFunction.DEFAULT')} 100ms forwards`,
        panelL2SlideOut: `panelL2SlideOut 400ms ${theme('transitionTimingFunction.DEFAULT')} forwards`,
        bounce: `bounce 1s ${theme('transitionTimingFunction.bounce')} infinite`
      })
    }
  },
  darkMode: ['selector', '[data-mode="dark"]'],
  corePlugins: {
    container: false,
    fontSize: false
  },
  plugins: [
    plugin(function ({ addComponents, addUtilities, addBase }) {
      const gridPLI = {
        display: 'grid',
        gridTemplateColumns: 'repeat(var(--grid-columns), 1fr)',
        columnGap: 'var(--grid-gap)'
      };

      addBase({
        ':root': {
          '--grid-columns': '4',
          '--grid-gap': '16px',
          '--grid-gap-half': '8px',
          '--grid-margin': '24px',
          '--grid-max-width': '1680px',
          '@screen sm': {
            '--grid-columns': '8'
          },
          '@screen md': {
            '--grid-columns': '12',
            '--grid-gap': '24px',
            '--grid-gap-half': '12px',
            '--grid-margin': '48px'
          },
          '@screen lg': {
            '--grid-margin': '80px'
          }
        }
      });

      addComponents({
        '.container': {
          width: '100%',
          maxWidth: '1440px',
          margin: '0 auto',
          paddingLeft: '24px',
          paddingRight: '24px',
          '@screen md': {
            paddingLeft: '48px',
            paddingRight: '48px'
          },
          '@screen lg': {
            paddingLeft: '80px',
            paddingRight: '80px'
          },
          '@screen 2xl': {
            maxWidth: '1680px'
          }
        },
        '.gridPLI': gridPLI,
        '.module-spacing': {
          paddingTop: '40px',
          paddingBottom: '40px',
          '@screen lg': {
            paddingTop: '64px',
            paddingBottom: '64px'
          }
        },
        '.module-spacing-pdp': {
          paddingTop: '40px',
          paddingBottom: '40px',
          '@screen lg': {
            paddingTop: '48px',
            paddingBottom: '48px'
          }
        },
        '.module-spacing-pdp--sm': {
          paddingTop: '48px',
          paddingBottom: '48px',
          '@screen lg': {
            paddingTop: '40px',
            paddingBottom: '40px'
          }
        },
        '.heading-1': {
          fontSize: '2.625rem',
          fontWeight: '700',
          lineHeight: '2.875rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '4rem',
            lineHeight: '4.5rem',
            letterSpacing: '-0.01rem'
          }
        },
        '.heading-1-regular': {
          fontSize: '2.625rem',
          lineHeight: '3.5rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '4rem',
            lineHeight: '4.5rem',
            letterSpacing: '-0.01rem'
          }
        },
        '.heading-2': {
          fontSize: '2.25rem',
          fontWeight: '700',
          lineHeight: '2.5rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '3.125rem',
            lineHeight: '3.25rem',
            letterSpacing: '-0.02rem'
          }
        },
        '.heading-3': {
          fontSize: '2rem',
          fontWeight: '700',
          lineHeight: '2.25rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '2.5rem',
            lineHeight: '2.625rem',
            letterSpacing: '-0.02rem'
          }
        },
        '.heading-3-regular': {
          fontSize: '2rem',
          lineHeight: '2.75rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '2.5rem',
            lineHeight: '3.625rem',
            letterSpacing: '-0.02rem'
          }
        },
        '.heading-4': {
          fontSize: '1.75rem',
          fontWeight: '700',
          lineHeight: '2rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '2rem',
            lineHeight: '2.125rem',
            letterSpacing: '-0.02rem'
          }
        },
        '.heading-5': {
          fontSize: '1.5rem',
          fontWeight: '700',
          lineHeight: '1.75rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '1.75rem',
            lineHeight: '1.75rem',
            letterSpacing: '-0.02rem'
          }
        },
        '.heading-5-medium': {
          fontSize: '1.5rem',
          fontWeight: '500',
          lineHeight: '2rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '1.75rem',
            lineHeight: '2.125rem',
            letterSpacing: '-0.02rem'
          }
        },
        '.heading-5-regular': {
          fontSize: '1.5rem',
          lineHeight: '2.25rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '1.75rem',
            lineHeight: '2.5rem',
            letterSpacing: '-0.02rem'
          }
        },
        '.heading-6-medium': {
          fontSize: '1.125rem',
          fontWeight: '500',
          lineHeight: '1.375rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '1.375rem',
            lineHeight: '1.625rem'
          }
        },
        '.heading-6-regular': {
          fontSize: '1.125rem',
          lineHeight: '1.875rem',
          letterSpacing: '-0.02rem',
          '@screen lg': {
            fontSize: '1.375rem',
            lineHeight: '2.125rem'
          }
        },
        '.text-1': {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          '@screen lg': {
            fontSize: '1rem',
            lineHeight: '1.375rem'
          }
        },
        '.text-1-medium': {
          fontSize: '0.875rem',
          fontWeight: '500',
          lineHeight: '1.25rem',
          '@screen lg': {
            fontSize: '1rem',
            lineHeight: '1.375rem'
          }
        },
        '.text-1-small': {
          fontSize: '1rem',
          fontWeight: '500',
          lineHeight: '1.375rem'
        },
        '.text-1-fixed': {
          fontSize: '0.875rem',
          lineHeight: '1.25rem'
        },
        '.text-2': {
          fontSize: '0.75rem',
          lineHeight: '1rem',
          '@screen lg': {
            fontSize: '0.875rem',
            lineHeight: '1.25rem'
          }
        },
        '.text-2-medium': {
          fontSize: '0.75rem',
          fontWeight: '500',
          lineHeight: '1rem',
          '@screen lg': {
            fontSize: '0.875rem',
            lineHeight: '1.25rem'
          }
        },
        '.text-2-bold': {
          fontSize: '0.75rem',
          fontWeight: '700',
          lineHeight: '1rem',
          '@screen lg': {
            fontSize: '0.875rem',
            lineHeight: '1.25rem'
          }
        },
        '.text-2-fixed': {
          fontSize: '0.75rem',
          lineHeight: '1rem'
        },
        '.text-2-fixed-medium': {
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: '500'
        },
        '.text-2-fixed-l': {
          fontSize: '0.875rem',
          lineHeight: '1.25rem'
        },
        '.text-3-fixed-l': {
          fontSize: '1.75rem',
          lineHeight: '2rem',
          fontWeight: '500'
        },
        '.text-input': {
          fontSize: '1rem',
          lineHeight: '1.375rem',
          fontWeight: '400'
        },
        '.label': {
          fontSize: '0.625rem',
          lineHeight: '1rem',
          fontWeight: '500',
          '@screen lg': {
            fontSize: '0.75rem'
          }
        },
        '.fixed-size-label': {
          fontSize: '0.75rem',
          fontWeight: '500',
          lineHeight: '1rem'
        },
        '.flex-zero-auto': {
          flex: '0 0 auto'
        },
        '.featured-content-image [data-radix-aspect-ratio-wrapper]': {
          height: '100%',
          padding: '0!important'
        }
      });

      addUtilities({
        '.scrollbar-hide': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none'
        },
        '.scrollbar-hide::-webkit-scrollbar': {
          display: 'none'
        }
      });
    }),
    tailwindCssAnimate
  ]
} satisfies Config;
