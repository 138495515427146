import { useState } from 'react';
import RichText from '@/components/cms/RichText';
import Link from '../Link';
import Modal from '../Modal/Modal';
import ModalContent from '../Modal/ModalContent';
import ResponsiveImage from '../ResponsiveImage';

interface LocationDetailsProps {
  labels: {
    heading: string;
    viewMap: string;
    hotelInformation: string;
  };
  name: string;
  address: string;
  image?: string;
  mapImage: string;
  mapLink: string;
  hotelInformation?: string;
}

const LocationDetails: React.FC<LocationDetailsProps> = ({
  labels,
  name,
  address,
  image,
  mapImage,
  mapLink,
  hotelInformation
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <section id="location-details" className="container module-spacing-pdp">
        <div className="mb-10 flex items-center justify-between md:mb-8">
          <h2 className="heading-5">{labels.heading}</h2>
          {hotelInformation && (
            <Link
              as="button"
              variant="text-link-2"
              text={labels.hotelInformation}
              className="max-md:hidden"
              onClick={() => setIsModalOpen(true)}
            />
          )}
        </div>
        <div className="md: flex flex-col-reverse overflow-hidden rounded bg-gray-lightest md:flex-row md:justify-between">
          <div className="flex flex-col justify-center gap-6 p-6 md:gap-4">
            <h3 className="heading-6-medium">{name}</h3>
            <RichText content={address} className="text-2 text-gray-dark" />
            <Link
              href={mapLink}
              target="_blank"
              text={labels.viewMap}
              variant="bold-link-with-icon"
            />
          </div>
          <div className="overflow-hidden max-md:aspect-1.5 md:h-[208px] md:w-[310px]">
            {image ? (
              <ResponsiveImage
                className="size-full object-cover"
                src={image}
                alt={name}
                aspectRatio={310 / 207}
                imageSizes={{
                  xs: '340px',
                  md: '320px'
                }}
              />
            ) : (
              <img src={mapImage} alt={name} />
            )}
          </div>
        </div>
        {hotelInformation && (
          <Link
            as="button"
            variant="text-link-2"
            text={labels.hotelInformation}
            className="mt-10 block md:hidden"
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </section>
      {hotelInformation && (
        <Modal setOpen={setIsModalOpen} open={isModalOpen}>
          <ModalContent title={labels.hotelInformation}>
            <RichText content={hotelInformation} className="text-2 text-gray-darker" />
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default LocationDetails;
