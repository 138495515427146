import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { FindAProgramBlock as FindAProgramBlockProps } from '@/@types/content';
import CmsButton from '@/components/cms/Button';
import Icon from '@/components/ui/Icon';
import Text from '@/components/cms/Text';
import { RootState } from '@/redux/store';
import { dismissElement } from '@/redux/slices/pageSlice';
import Block from '../cms/Block';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import SearchOverlay from '@/components/ui/SearchOverlay/SearchOverlay';
import { FilterContext, SearchContext } from '@/analytics/constants';

export type FindAProgramBlockType = FindAProgramBlockProps & { cookieName: string };

const FindAProgramBlock: React.FC<FindAProgramBlockType> = ({
  contentLink,
  heading,
  copy,
  searchLabel
}) => {
  const dispatch = useDispatch();
  const isEditMode = isEditOrPreviewMode();
  const cookieName = window.env?.FIND_A_PROGRAM_BLOCK_COOKIE_NAME || 'findAProgramBlock';
  const finishProfileCookieName =
    window.env?.FINISH_PROFILE_BLOCK_COOKIE_NAME || 'finishProfileBlock';
  const dismissedElements = useSelector((state: RootState) => state.page.dismissedElements);

  if (dismissedElements[cookieName] && !isEditMode) return null;

  const handleDismiss = () => {
    dispatch(dismissElement(cookieName));
  };

  return (
    <Block
      contentLinkID={contentLink?.id}
      className="flex h-full flex-col items-start justify-between rounded-lg bg-gray-lightest p-6 lg:p-8"
      data-component={"FindAProgramBlock"}
    >
      <div className="mb-8 w-full lg:mb-10">
        <div className="mb-4 flex items-start justify-between gap-4">
          <Text element="h3" propertyName="Heading" className="heading-5">
            {heading}
          </Text>
          <button aria-label="dismiss" onClick={handleDismiss}>
            <Icon name="close" size="large" />
          </button>
        </div>
        <Text
          element="p"
          propertyName="Copy"
          className={classnames('text-1 text-gray-dark', {
            'max-w-[468px]': !dismissedElements[finishProfileCookieName],
            'max-w-[922px]': dismissedElements[finishProfileCookieName]
          })}
        >
          {copy}
        </Text>
      </div>
      <SearchOverlay
        context={FilterContext.Overlay}
        searchContext={SearchContext.FAPBlock}
        triggerButton={
          <div>
            <CmsButton className="flex items-center rounded-md border border-gray bg-gray-lightest py-4 pl-4 pr-8 hover:border-black">
              <Icon name="search" size="large" className="mr-3" />
              <Text
                element="span"
                propertyName="SearchLabel"
                className="text-1 font-bold text-black"
              >
                {searchLabel}
              </Text>
            </CmsButton>
          </div>
        }
      />
    </Block>
  );
};

export default FindAProgramBlock;
