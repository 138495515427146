import type { LiveProgramWebcastVariation as LiveProgramWebcastVariationProps } from '@/@types/content';
import withNav from '@/components/hocs/withNav';
import LiveProgramPDP from '@/components/ui/PDP/LiveProgramPDP';
import {
  enrichLiveVariantType,
  LiveVariationFormat,
  useLiveProgramCatalogRelations
} from '@/hooks/PDP/useProgramCatalogRelations';
import { AnalyticsContext } from '@/analytics/constants';

const LiveProgramWebcastVariationComponent = (variant: LiveProgramWebcastVariationProps) => {
  const { relations: catalogRelations, isLoading } = useLiveProgramCatalogRelations(variant);
  const initialVariant: LiveVariationFormat =
    catalogRelations.variants.find(v => v.content.code === variant.code) ??
    enrichLiveVariantType(variant, 'LiveProgramWebcastVariation')!;
  return (
    <LiveProgramPDP
      variant={initialVariant}
      catalogRelations={catalogRelations}
      loadingCatalogRelations={isLoading}
      context={AnalyticsContext.PDP}
    />
  );
};

const LiveProgramGroupcastVariation = withNav(LiveProgramWebcastVariationComponent);
export default LiveProgramGroupcastVariation;
