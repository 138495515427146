import {
  startOfDay,
  endOfDay,
  isBefore,
  isAfter,
  startOfMonth,
  endOfMonth,
  addMonths
} from 'date-fns';

export const isBeforeMinDate = (date: Date, minDate?: Date): boolean =>
  !!minDate && isBefore(date, startOfDay(minDate));

export const isAfterMaxDate = (date: Date, maxDate?: Date): boolean =>
  !!maxDate && isAfter(date, endOfDay(maxDate));

export const isDayBetweenCalendarRange = (date: Date, minDate?: Date, maxDate?: Date): boolean =>
  !isBeforeMinDate(date, minDate) && !isAfterMaxDate(date, maxDate);

export const isMonthBeforeMinDate = (month: Date, minDate?: Date): boolean =>
  !!minDate && isBefore(endOfMonth(month), startOfDay(minDate));

export const isMonthAfterMaxDate = (month: Date, maxDate?: Date): boolean =>
  !!maxDate && isAfter(startOfMonth(month), endOfDay(maxDate));

export const isMonthBetweenCalendarRange = (month: Date, minDate?: Date, maxDate?: Date): boolean =>
  !isMonthBeforeMinDate(month, minDate) && !isMonthAfterMaxDate(month, maxDate);

export const getMonthsBetweenDates = (
  minDate?: Date,
  maxDate?: Date,
  defaultMonthsCount: number = 12 * 10
): Date[] => {
  const months: Date[] = [];
  let from = minDate
    ? startOfMonth(minDate)
    : addMonths(startOfMonth(new Date()), -defaultMonthsCount);
  const to = maxDate ? endOfMonth(maxDate) : addMonths(endOfMonth(new Date()), defaultMonthsCount);
  while (isBefore(from, to)) {
    months.push(from);
    from = addMonths(from, 1);
  }
  return months;
};
