import { FC, useMemo } from 'react';
import Block from '@/components/cms/Block';
import { IContent } from '@/@types/cms';
import ContentArea from '../cms/ContentArea';
import Divider from '../ui/Divider';

type MissionBlockProps = IContent & {
  heading?: string;
  missionItems: IContent[];
};

const MissionBlock: FC<MissionBlockProps> = ({
  contentLink,
  heading = '',
  missionItems = [],
  name
}) => {
  const formattedHeading: string = useMemo(() => {
    return heading.replace('</em>', '</span>').replace('<em>', '<span class="text-red">');
  }, [heading]);

  return (
    <Block contentLinkID={contentLink?.id} aria-label={name} className="container my-10 lg:my-16"  data-component={"MissionBlock"}>
      <h2
        className="heading-2 w-full sm:w-[90%] md:w-3/4"
        dangerouslySetInnerHTML={{ __html: formattedHeading }}
      />
      <Divider color="light" className="mt-10 sm:mt-16" />
      <ContentArea
        propertyName="MissionItems"
        components={missionItems}
        componentsProps={{ smallImage: true }}
      />
    </Block>
  );
};

export default MissionBlock;
