import ModalContent from '@/components/ui/Modal/ModalContent';
import AddToCalendarLink from './AddToCalendarLink';
import { AnalyticsContext } from '@/analytics/constants';

export type AddToCalendarModalContentProps = {
  modalHeader?: string;
  productCode?: string;
  context?: AnalyticsContext;
};

const AddToCalendarModalContent: React.FC<AddToCalendarModalContentProps> = ({
  modalHeader,
  productCode,
  context
}) => {
  return (
    <ModalContent
      title={modalHeader}
      fitContent={true}
      containerClassName="sm:pb-[70px] lg:pb-[62px]"
    >
      <div className="flex flex-col gap-4"       data-component={"AddToCalendarModalContent"}      >
        <AddToCalendarLink
          context={context}
          productCode={productCode}
          type={2}
          icon="ical"
          label="iCal"
        />
        <AddToCalendarLink
          context={context}
          productCode={productCode}
          type={0}
          icon="google"
          label="Google"
        />
        <AddToCalendarLink
          context={context}
          productCode={productCode}
          type={1}
          icon="outlook"
          label="Outlook"
        />
      </div>
    </ModalContent>
  );
};

export default AddToCalendarModalContent;
