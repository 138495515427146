import { IContent } from '@/@types/cms';
import ContentArea from '../cms/ContentArea';
import { isNumberNotEmpty } from '@/lib/helpers/isNumberNotEmpty';

interface LayoutSettingsProps extends React.HTMLProps<HTMLDivElement> {
  propertyName?: string;
  components?: Array<IContent> | undefined | unknown;
  headerThemeSelection?: number;
  noWrapper?: boolean;
}

const LayoutSettings: React.FC<LayoutSettingsProps> = ({
  propertyName,
  components,
  headerThemeSelection,
  noWrapper
}) => {
  if (!propertyName || !components || !Array.isArray(components)) {
    return null;
  }

  return (
    <ContentArea
      noWrapper={noWrapper}
      propertyName={propertyName}
      components={components}
      componentsProps={
        isNumberNotEmpty(headerThemeSelection)
          ? { themeVariation: headerThemeSelection }
          : undefined
      }
    />
  );
};

export default LayoutSettings;
