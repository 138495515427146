import { SnippetVariants } from '@/components/ui/Snippet/Snippet';
import { EyebrowProps } from '@/components/ui/Eyebrow/Eyebrow';

export const variantMappings: { [key in SnippetVariants]?: EyebrowProps[] } = {
  'on-demand': [
    {
      label: 'On-Demand',
      icon: 'on-demand'
    }
  ],
  'upcoming-live': [
    {
      label: 'Upcoming Live',
      icon: 'upcoming'
    }
  ],
  'live-expired': [
    {
      label: 'Live Webcast (Ended)',
      icon: 'upcoming'
    }
  ],
  live: [{ label: 'Live', icon: 'live-circle' }],
  publication: [
    {
      label: 'Publication',
      icon: 'publications'
    }
  ],
  podcast: [
    {
      label: 'Podcast',
      icon: 'microphone'
    }
  ],
  faculty: [
    {
      label: 'Faculty and Authors',
      icon: 'profile'
    }
  ]
};
