import { FC, lazy, memo, ReactNode, Suspense, useMemo } from 'react';
import classnames from 'classnames';
import { MiniTrackerBlock as MiniTrackerBlockProps } from '@/@types/content';
import Link from '@/components/ui/Link';
import useCreditTracker from '@/components/ui/CreditTracker/hooks/useCreditTrackerHook';
import {
  formatUrl,
  sortTrackerMiniBlocks
} from '@/components/ui/CreditTracker/util/TrackerHelpers';
import SectionTitle from '@/components/ui/Titles/SectionTitle';
import { IContent } from '@/@types/cms';
import { useAuth } from 'react-oidc-context';
import VisibilityObserver from '@/components/ui/VisibilityObserver';
import { trackSelectPromotionEvent, trackViewPromotionEvent } from '@/analytics/creditTracker';

const MiniTrackerCard = lazy(() => import('@/components/ui/Cards/MiniTrackerCard'));
const ErrorBoundary: FC<{ children: ReactNode }> = ({ children }) => {
  return <Suspense fallback={<></>}>{children}</Suspense>;
};

type MiniTrackerProps = MiniTrackerBlockProps & {
  walkthroughSteps: Array<IContent & { applyFor?: string }>;
};

const MiniTrackerBlock: FC<MiniTrackerProps> = memo(
  ({
    headingIcon,
    heading,
    trackerPageLink,
    maxNumOfJurisdictions,
    walkthroughSteps
    // noLicenses
  }) => {
    const auth = useAuth();
    const user = parseInt(auth.user?.profile?.IndividualSK as string);
    const {
      selectedUserRegions,
      trackerLoading,
      earnMoreResultPageUrl,
      earnMoreCreditPriority,
      practiceAreas,
      searchPanelFailureMessage
    } = useCreditTracker({
      skipQuery: !auth.isAuthenticated,
      request: { individualSk: user }
    });

    const sortedJurisdictions = useMemo(() => {
      return sortTrackerMiniBlocks(selectedUserRegions ?? [])
        ?.filter(
          x =>
            x.jurisdiction?.totalRequirement?.required &&
            x.jurisdiction?.totalRequirement?.required > 0
        )
        ?.slice(0, maxNumOfJurisdictions ?? 2);
    }, [maxNumOfJurisdictions, selectedUserRegions]);

    const isSingleCard = sortedJurisdictions.length === 1;

    const url = useMemo(() => formatUrl(trackerPageLink?.url ?? ''), [trackerPageLink?.url]);

    const tooltipsData = walkthroughSteps.find(step => step.applyFor === 'tracker');
    const tooltipIndex = walkthroughSteps.findIndex(step => step.applyFor === 'tracker');

    const shimmer = useMemo(() => {
      return Array.from({ length: maxNumOfJurisdictions ?? 0 }, (_, index) => (
        <MiniTrackerCard
          key={index}
          jurisdiction={{
            displayName: '',
            totalRequirement: { counted: 0, required: 0, earned: 0 },
            clePeriod: { endDate: '', defaultEndDate: '' },
            requirements: [
              { heading: 'shimmer', required: 3, earned: 1 },
              { heading: 'shimmer', required: 3, earned: 1 },
              { heading: 'shimmer', required: 3, earned: 1 }
            ]
          }}
          creditRegionName=""
          isSingleCard={isSingleCard}
          isLoading={true}
          url={url}
          isLimitedMode={false}
        />
      ));
    }, [isSingleCard, maxNumOfJurisdictions, url]);

    const cardContainerClasses = classnames('grid grid-cols-1 gap-x-6', {
      'md:grid-cols-1': isSingleCard,
      'md:grid-cols-2': !isSingleCard
    });

    return (
      !!sortedJurisdictions?.length && (
        <VisibilityObserver onVisible={trackViewPromotionEvent}>
          <div className="container module-spacing" data-component={'MiniTrackerBlock'}>
            <div className="mb-8">
              <SectionTitle
                propertyNameAvatar=""
                propertyNameIcon=""
                propertyNameTitle="Heading"
                propertyNameSubcopy=""
                title={heading || ''}
                tooltipWalkthrough={{
                  index: tooltipIndex,
                  walkthroughSteps: walkthroughSteps.length,
                  ...tooltipsData
                }}
                titleSize="h5"
                icon={headingIcon ? { name: headingIcon } : undefined}
                elementType="h5"
              >
                {trackerPageLink?.url && (
                  <Link
                    className="mt-4 sm:mt-0"
                    text="See my Credit Tracker"
                    variant="bold-link-with-icon"
                    href={trackerPageLink?.url ?? '#'}
                    icon="chevron-right"
                    onClick={trackSelectPromotionEvent}
                  />
                )}
              </SectionTitle>
            </div>
            <Suspense fallback={shimmer}>
              <div className={cardContainerClasses}>
                <ErrorBoundary>
                  {!trackerLoading
                    ? sortedJurisdictions.map((x, i) => {
                        return (
                          <MiniTrackerCard
                            key={i}
                            isSingleCard={isSingleCard}
                            jurisdiction={x.jurisdiction}
                            creditRegionName={x.creditRegionName ?? undefined}
                            earnMoreResultPageUrl={earnMoreResultPageUrl}
                            earnMoreCreditPriority={earnMoreCreditPriority}
                            practiceAreas={practiceAreas}
                            searchPanelFailureMessage={searchPanelFailureMessage}
                            url={url}
                            isLimitedMode={!x.jurisdiction?.totalRequirement?.required}
                          />
                        );
                      })
                    : shimmer}
                </ErrorBoundary>
              </div>
            </Suspense>
          </div>
        </VisibilityObserver>
      )
    );
  }
);

MiniTrackerBlock.displayName = 'MiniTrackerBlock';
export default MiniTrackerBlock;
