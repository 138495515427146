import { LinkItem } from '@/@types/cms';
import { NavigationItemBlock } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';
import Icon from '@/components/ui/Icon';
import Link from '@/components/ui/Link';
import Panel from '@/components/ui/Panel';
import { getTextColorClass } from '@/utils/theme';
import classnames from 'classnames';

enum Variant {
  Light = 'light',
  Dark = 'dark'
}

interface PanelNavTabletProps {
  theme: `${Variant}`;
  isSecondaryNavOpen: boolean;
  setIsSecondaryNavOpen: (value: boolean) => void;
  setActivePrimaryNav: (value: string | undefined) => void;
  primaryNavClickHandler: (Event: React.MouseEvent<HTMLAnchorElement>) => void;
  primaryNavMenuItems: NavigationItemBlock[];
  secondaryNavData: NavigationItemBlock[];
  pliLink: LinkItem;
  editMode: boolean;
  focusVisibleClasses: string;
  memoizedLogo: React.ReactNode;
  activePrimaryNav: string | undefined;
}

const PanelNavTablet = ({
  theme,
  isSecondaryNavOpen,
  setIsSecondaryNavOpen,
  setActivePrimaryNav,
  primaryNavClickHandler,
  primaryNavMenuItems,
  secondaryNavData,
  pliLink,
  editMode,
  focusVisibleClasses,
  memoizedLogo,
  activePrimaryNav
}: PanelNavTabletProps) => {
  const hamburgerClasses = classnames(
    'mr-3.5 size-6',
    'sm:mr-5',
    getTextColorClass(theme),
    focusVisibleClasses
  );

  const panelTabletClasses = classnames(
    '!max-w-[1024px]',
    'before:pointer-events-none before:absolute before:left-0 before:top-0 before:block before:h-full before:w-1/3 before:border-r before:content-[""]',
    'after:pointer-events-none after:absolute after:left-0 after:top-0 after:block after:h-full after:w-2/3 after:border-r after:content-[""]',
    {
      'before:border-gray-light': theme === Variant.Light,
      'before:border-gray-darker': theme === Variant.Dark
    },
    {
      'after:border-gray-light': theme === Variant.Light,
      'after:border-gray-darker': theme === Variant.Dark
    }
  );

  const tabletCol1Classes = classnames('flex w-[214px] flex-col gap-4 border-b pb-8', {
    'border-gray-light': theme === Variant.Light,
    'border-gray-darker': theme === Variant.Dark
  });

  const pliLinkClasses = classnames(getTextColorClass(theme), focusVisibleClasses);

  const handleItemClick = () => {
    setIsSecondaryNavOpen(false);
  };

  return (
    <div className="h-6"  data-component={"PanelNavTablet"}>
      <Panel theme={theme} isOpen={isSecondaryNavOpen} onOpenChange={setIsSecondaryNavOpen}>
        <Panel.Trigger asChild>
          <button
            className={hamburgerClasses}
            onClick={() => {
              setActivePrimaryNav((primaryNavMenuItems[0] as NavigationItemBlock)?.title);
            }}
          >
            <Icon name="menu" size="large" />
          </button>
        </Panel.Trigger>

        <Panel.Content
          hasHorizontalPadding={false}
          side="left"
          className={panelTabletClasses}
          leftHeaderContent={<div className="pl-6">{memoizedLogo}</div>}
        >
          <Panel.Body scrollClassName="pt-8" className="nav-panel-full-h !px-0">
            <div className="flex h-full pb-[34px] pt-7">
              <div className="relative flex w-1/3 flex-col items-start px-[26px] pl-12">
                <ContentArea
                  propertyName="PrimaryNavMenuItems"
                  components={primaryNavMenuItems}
                  componentsProps={{
                    theme,
                    focusVisibleClasses,
                    primaryNavClickHandler,
                    variant: 'tablet',
                    activePrimaryNav
                  }}
                  className={tabletCol1Classes}
                  element="nav"
                />
                {pliLink || editMode ? (
                  <Link
                    variant="bold-link-with-icon"
                    className={classnames(pliLinkClasses, 'mt-8')}
                    href={pliLink?.href}
                    text={pliLink?.text}
                    title={pliLink?.title}
                    target={pliLink?.target}
                  />
                ) : null}
              </div>

              <div className="flex w-2/3">
                <ContentArea
                  propertyName="SubnavItems"
                  components={secondaryNavData.slice(0, 2)}
                  className="flex w-1/2 flex-col gap-8 px-[26px]"
                  componentsProps={{
                    theme,
                    onItemClick: handleItemClick
                  }}
                  element="div"
                />

                <ContentArea
                  propertyName="SubnavItems"
                  components={secondaryNavData.slice(2)}
                  className="flex w-1/2 flex-col gap-8 px-[26px]"
                  componentsProps={{
                    theme,
                    onItemClick: handleItemClick
                  }}
                  element="div"
                />
              </div>
            </div>
          </Panel.Body>
        </Panel.Content>
      </Panel>
    </div>
  );
};

export default PanelNavTablet;
