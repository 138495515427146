import { MouseEvent, useCallback, useMemo, SetStateAction } from 'react';

import { useLaunch } from '@/hooks/shared/useLaunch';
import { useAddToLibraryMutation, useRemoveFromLibraryMutation } from '@/redux/api/client/library';
import useToast from '@/hooks/useToast';
import { SnippetActions } from '../../Snippet/Snippet';
import { SearchableType } from '../searchableTypeToSnippetVariant';
import { ActionType } from './useEcommData';
import { SearchModalPanelState } from './useSearchModalPanelState';
import { useDispatch } from 'react-redux';
import { ActionStatus } from '../constants';
import { AnalyticsContext } from '@/analytics/constants';
import { trackAddWishListEvent, trackRemoveWishListEvent } from '@/analytics/ecommerce';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { setOptimisticToggleForLibraryFavoriteItemUpdate } from '@/redux/slices/searchSlice';

const useSnippetActions = (
  pk: string,
  url: string,
  title: string,
  context: AnalyticsContext,
  searchableType: SearchableType | null,
  modalPanelState: SearchModalPanelState,
  hasMultipleRecordings: boolean,
  guidValue: string | null,
  setActionStatus?: React.Dispatch<SetStateAction<ActionStatus>>,
  refetchData?: () => void,
  launchUrl?: string, // only set if already purchased
  itemAnalyticsModel?: ItemAnalyticsModel
): SnippetActions => {
  const { ecommPanel, materialsModal, addToCalendarModal } = modalPanelState;
  const { launch } = useLaunch(context);
  const [addToLibrary] = useAddToLibraryMutation();
  const [removeFromLibrary] = useRemoveFromLibraryMutation();
  const { showSuccessMessage, showFailMessage } = useToast();
  const dispatch = useDispatch();

  const handleAddToLibrary = useCallback(async () => {
    setActionStatus?.('loading');
    try {
      if (context) trackAddWishListEvent(itemAnalyticsModel, context);
      await addToLibrary(pk).unwrap();
      setActionStatus?.('idle');
      showSuccessMessage('Saved item to library');
      dispatch(setOptimisticToggleForLibraryFavoriteItemUpdate([pk]));
    } catch (addToLibraryError) {
      setActionStatus?.('error');
      showFailMessage('Error while saving item to library');
    }
  }, [
    pk,
    addToLibrary,
    showSuccessMessage,
    showFailMessage,
    setActionStatus,
    dispatch,
    context,
    itemAnalyticsModel
  ]);

  const handleRemoveFromLibrary = useCallback(
    async (variantPks?: string[]) => {
      setActionStatus?.('loading');
      if (variantPks?.length) {
        try {
          await Promise.all(
            variantPks.map(async variantPk => {
              trackRemoveWishListEvent(itemAnalyticsModel, context);
              return removeFromLibrary(variantPk).unwrap();
            })
          );
          setActionStatus?.('idle');
          showSuccessMessage('Removed item from library');
          dispatch(setOptimisticToggleForLibraryFavoriteItemUpdate(variantPks));
          refetchData?.();
        } catch {
          setActionStatus?.('error');
          showFailMessage('Error while removing item from library');
        }
      }
    },
    [
      removeFromLibrary,
      showSuccessMessage,
      showFailMessage,
      setActionStatus,
      refetchData,
      dispatch,
      context,
      itemAnalyticsModel
    ]
  );

  const handleShowEcommPanel = useCallback(
    (action: ActionType) => {
      ecommPanel.show({ pk, guidValue, url, title, searchableType, action });
    },
    [pk, searchableType, ecommPanel, title, url, guidValue]
  );

  const handleShowMaterialsModal = useCallback(
    (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      e?.preventDefault();
      materialsModal.show({ pk, title });
    },
    [pk, materialsModal, title]
  );

  const handleShowAddToCalendarModal = useCallback(
    (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      e?.preventDefault();
      addToCalendarModal.show({ pk });
    },
    [pk, addToCalendarModal]
  );

  const handleSeeSchedule = useCallback(
    (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      e?.preventDefault();
      window.open(`${url}#schedule`);
    },
    [url]
  );

  const actions = useMemo(
    () => ({
      launch: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e?.preventDefault();
        if (hasMultipleRecordings) {
          handleShowEcommPanel('StartWatching');
        } else {
          launch(pk, false, launchUrl).then(refetchData);
        }
      },
      addToLibrary: handleAddToLibrary,
      removeFromLibrary: (variantPks?: string[]) => handleRemoveFromLibrary(variantPks),
      addToCalendar: handleShowAddToCalendarModal,
      accessMaterials: handleShowMaterialsModal,
      buy: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e?.preventDefault();
        handleShowEcommPanel('BuyNow');
      },
      seeSchedule: handleSeeSchedule,
      register: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e?.preventDefault();
        handleShowEcommPanel('Register');
      },
      viewRegistration: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e?.preventDefault();
        handleShowEcommPanel('ViewRegistration');
      },
      changeOrCancelRegistration: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e?.preventDefault();
        handleShowEcommPanel('ChangeOrCancelRegistration');
      }
    }),

    [
      handleAddToLibrary,
      handleShowAddToCalendarModal,
      handleShowMaterialsModal,
      handleSeeSchedule,
      hasMultipleRecordings,
      handleShowEcommPanel,
      launch,
      pk,
      launchUrl,
      refetchData,
      handleRemoveFromLibrary
    ]
  );

  return actions;
};

export default useSnippetActions;
