import classnames from 'classnames';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Link from '@/components/ui/Link';
import { Fragment } from 'react';
import Shimmer from '@/components/ui/Shimmer';
import DotSeparatedContent from '../DotSeparatedContent';
import IconMessage from '../IconMessage';

interface RowData {
  rowDetail?: string[];
}

interface RowInfo {
  rowData?: RowData[];
  itemInfo?: (string | undefined)[];
  href?: string;
  location?: string;
  programDate?: string;
  isCancelled?: boolean;
}

export type TableProps = {
  tableHeader?: string[];
  rows?: RowInfo[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
};

const TableLoading = ({ isLoadMore }: { isLoadMore?: boolean }) => (
  <div
    className={classnames('flex flex-col gap-4', {
      'mt-4': isLoadMore
    })}
  >
    {!isLoadMore && <Shimmer className="h-[52px] w-full" />}
    {Array.from({ length: 6 }).map((_, index) => (
      <Shimmer className="h-[168px] w-full rounded lg:h-[100px]" key={index} />
    ))}
  </div>
);

const Table: React.FC<TableProps> = ({ isLoading, isLoadingMore, tableHeader, rows }) => {
  const largeWindow = useMediaQuery(ScreenSizeQueries.lg);

  const tdLargeClasses = classnames('text-2 lg:py-6 lg:pr-6');

  if (isLoading) {
    return <TableLoading />;
  }

  return (
    <>
      <div className="grid w-full rounded-lg border border-solid border-gray-light"       data-component="OrderHistoryTable"      >
        <table className="w-full text-left">
          <thead className="text-2 bg-gray-feather">
            <tr>
              {largeWindow ? (
                tableHeader?.map((item, index) => (
                  <th
                    className="whitespace-nowrap py-4 font-medium lg:pr-6 lg:first:pl-8 lg:last:pr-8"
                    key={`headerLarge-${index}`}
                    scope="col"
                  >
                    {item}
                  </th>
                ))
              ) : (
                <th className="px-6 py-4 font-medium" scope="col">
                  {tableHeader?.[0]}
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-solid divide-gray-light">
            {largeWindow ? (
              rows?.map(
                ({ itemInfo, rowData, href, location, programDate, isCancelled }, index) => (
                  <tr key={`rowLarge-${index}`} className="align-top">
                    <Fragment key={`rowInfoLarge-${index}`}>
                      <td className={classnames(tdLargeClasses, 'lg:first:pl-8')}>
                        <span className="font-normal text-gray-dark">{itemInfo?.[0]}</span>
                        {itemInfo && itemInfo.length > 1 && (
                          <Link
                            className="text-2 pt-2 font-medium no-underline"
                            text={itemInfo[1]}
                            href={href ?? ''}
                            variant="text-link-1"
                            disabled={!href || href === ''}
                          />
                        )}
                        {itemInfo?.includes('Live Seminar') && (
                          <div className="text-2 pt-2 font-normal text-gray-dark">
                            <DotSeparatedContent content={[programDate, location]} />
                          </div>
                        )}
                        {isCancelled && (
                          <div className="mt-2 pt-2 text-gray-dark">
                            <IconMessage
                              iconName="info"
                              text="This registration has been cancelled."
                              fontType="font-normal"
                            />
                          </div>
                        )}
                      </td>
                      {rowData?.map(({ rowDetail }, index) => (
                        <td
                          key={`rowDataLarge-${index}`}
                          className={classnames(tdLargeClasses, 'lg:w-[219px]')}
                        >
                          {rowDetail?.[1]}
                        </td>
                      ))}
                    </Fragment>
                  </tr>
                )
              )
            ) : (
              // SMALL BP
              <>
                {rows?.map(
                  ({ rowData, itemInfo, href, location, programDate, isCancelled }, index) => (
                    <tr
                      key={`row-${index}`}
                      className="grid w-full space-y-4 p-6 md:justify-between"
                    >
                      <Fragment key={`rowInfo-${index}`}>
                        <td className="text-2 text-gray-dark">
                          {itemInfo?.[0]}
                          <Link
                            className="pt-2 font-medium text-black no-underline"
                            text={itemInfo?.[1]}
                            href={href}
                            variant="text-link-1"
                          />
                          {itemInfo?.includes('Live Seminar') && (
                            <div className="text-2 pt-2 font-normal text-gray-dark">
                              <DotSeparatedContent content={[programDate, location]} />
                            </div>
                          )}
                          {isCancelled && (
                            <div className="mt-2 pt-2 text-gray-dark">
                              <IconMessage
                                iconName="info"
                                text="This registration has been cancelled."
                                fontType="font-normal"
                              />
                            </div>
                          )}
                        </td>

                        {rowData?.map(({ rowDetail }, index) => (
                          <td
                            key={`rowData-${index}`}
                            className="text-2 font-normal text-gray-dark"
                          >
                            {rowDetail?.map((el, index) => (
                              <span
                                key={`rowDetail-${index}`}
                                className="pr-1 font-normal text-gray-dark"
                              >
                                {el}
                              </span>
                            ))}
                          </td>
                        ))}
                      </Fragment>
                    </tr>
                  )
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {isLoadingMore && <TableLoading isLoadMore />}
    </>
  );
};

export default Table;
