import classnames from 'classnames';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';

import RichText from '@/components/cms/RichText';
import Button from '@/components/ui/Buttons/Button';
import { ProductSubBlock as ProductSubBlockProps } from '@/@types/content';

const ProductSubBlock: React.FC<ProductSubBlockProps> = ({
  icon,
  header,
  register,
  trailingRTFCopy
}) => {
  const modeEdit = isEditOrPreviewMode();

  const ProductSubBlockClassNames = classnames('flex flex-col rounded-lg bg-gray-lightest p-8');

  return (
    <div className={ProductSubBlockClassNames}  data-component={"ProductSubBlock"}>
      <div className="text-red">{icon && <Icon name={icon} size="x-large" />}</div>
      <div className="mt-4 flex flex-col justify-between gap-6 sm:flex-row">
        <div className="lg:flex lg:flex-row lg:gap-6">
          {(header || modeEdit) && (
            <Text
              element="h3"
              propertyName="Header"
              className="heading-6-medium mb-5 min-w-[206px]"
            >
              {header}
            </Text>
          )}
          {(trailingRTFCopy || modeEdit) && (
            <RichText
              content={trailingRTFCopy!}
              propertyName="TrailingRTFCopy"
              className="text-2 text-gray-dark"
              checkList={true}
            />
          )}
        </div>
        {(register || modeEdit) && (
          <Button
            propertyName="cta"
            color="black"
            label={register?.text}
            href={register?.href}
            target={register?.target}
            size="large"
            className="w-fit"
          />
        )}
      </div>
    </div>
  );
};

export default ProductSubBlock;
