import React, { AnimationEventHandler, createContext, useContext } from 'react';
import classnames from 'classnames';
import * as Dialog from '@radix-ui/react-dialog';
import Icon from './Icon';
import ScrollArea from './ScrollArea';
import { getFocusOutlineClass, getTextColorClass } from '@/utils/theme';

export interface PanelProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  size?: 'small' | 'medium';
  theme?: 'light' | 'dark';
  children: React.ReactNode;
  forceClose?: boolean;
}

interface PanelContentProps {
  side?: 'left' | 'right';
  closeLabel?: string;
  children: React.ReactNode;
  className?: string;
  hasHorizontalPadding?: boolean;
  isL2Panel?: boolean;
  dimBackground?: boolean;
  onAnimationEnd?: AnimationEventHandler<HTMLDivElement>;
  onBackButtonPress?: () => void;
  leftHeaderContent?: React.ReactNode;
  centerHeaderContent?: React.ReactNode;
  isNestedModal?: boolean;
  customPanelId?: string;
  forceFocusOnTab?: boolean;
  miniPaddingHeader?: boolean;
  titleHeaderClassNames?: string;
}

interface PanelHeaderProps {
  children: React.ReactNode;
  className?: string;
  id?: string;
  isL2Panel?: boolean;
}

interface PanelTitleProps {
  children?: React.ReactNode;
  hasHorizontalPadding?: boolean;
}

interface PanelBodyProps {
  children?: React.ReactNode;
  className?: string;
  hasHorizontalPadding?: boolean;
  scrollClassName?: string;
  theme?: 'light' | 'dark';
}

export interface PanelFooterProps {
  divider?: 'line' | 'gradient';
  children: React.ReactNode;
  className?: string;
}

interface PanelPrivateProps {
  Content: React.FC<PanelContentProps>;
  Header: React.FC<PanelHeaderProps>;
  Title: React.FC<PanelTitleProps>;
  Body: React.FC<PanelBodyProps>;
  Footer: React.FC<PanelFooterProps>;
  Trigger: typeof Dialog.Trigger;
}

const getHorizontalPadding = ({
  hasHorizontalPadding = true,
  size
}: {
  hasHorizontalPadding: PanelBodyProps['hasHorizontalPadding'];
  size?: PanelProps['size'];
}) => {
  return classnames({
    'px-6': hasHorizontalPadding,
    'md:px-8': size === 'small' && hasHorizontalPadding,
    'lg:px-20': size === 'medium' && hasHorizontalPadding
  });
};

const PanelContext = createContext<{ size?: string; theme?: string }>({});

const Panel: React.FC<PanelProps> & PanelPrivateProps = ({
  children,
  size = 'medium',
  theme = 'light',
  isOpen,
  onOpenChange,
  forceClose = false
}) => {
  return (
    <PanelContext.Provider value={{ size, theme }}>
      <Dialog.Root open={forceClose === true ? false : isOpen} onOpenChange={onOpenChange}>
        {children}
      </Dialog.Root>
    </PanelContext.Provider>
  );
};

const PanelContent: React.FC<PanelContentProps> = ({
  isL2Panel = false,
  side = 'right',
  closeLabel = 'Close',
  children,
  className,
  hasHorizontalPadding = true,
  dimBackground = true,
  onAnimationEnd,
  onBackButtonPress,
  leftHeaderContent,
  centerHeaderContent,
  isNestedModal = false,
  customPanelId,
  forceFocusOnTab,
  miniPaddingHeader,
  titleHeaderClassNames,
  ...props
}) => {
  const { size, theme } = useContext(PanelContext);

  const contentClassName = classnames(
    'group fixed inset-y-0 size-full',
    {
      'data-[state=open]:duration data-[state=closed]:duration fixed inset-y-0 size-full transition data-[state=open]:animate-in data-[state=closed]:animate-out':
        !isL2Panel,
      'data-[state=closed]:animate-[fadeOut_400ms_ease_forwards] data-[state=open]:animate-[fadeIn_400ms_ease_forwards]':
        isL2Panel
    },
    {
      'left-0 data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left':
        side === 'left',
      'right-0 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right':
        side === 'right'
    },
    {
      'max-w-[422px]': size === 'small',
      'max-w-[768px] lg:max-w-[788px]': size === 'medium'
    },
    {
      'bg-white': theme === 'light',
      'bg-black': theme === 'dark'
    },
    {
      'z-modal': isNestedModal,
      'z-panel': !isNestedModal
    },
    className
  );

  const headerClassName = classnames('mb-10 flex items-center justify-between gap-6', {
    'px-6': !hasHorizontalPadding,
    'md:px-8': size === 'small' && !hasHorizontalPadding,
    'lg:px-20': size === 'medium' && !hasHorizontalPadding
  });

  const bodyClassName = classnames({
    'opacity-0 group-data-[state=closed]:animate-panelL2SlideOut group-data-[state=open]:animate-panelL2SlideIn':
      isL2Panel
  });

  const childrenArray = React.Children.toArray(children);
  const header = childrenArray.find(child => (child as React.ReactElement).type === Panel.Header);
  const body = childrenArray.find(child => (child as React.ReactElement).type === Panel.Body) || (
    <Panel.Body />
  );
  const footer = childrenArray.find(child => (child as React.ReactElement).type === Panel.Footer);

  const newBodyChildren = React.cloneElement(body as React.ReactElement, { hasHorizontalPadding }, [
    header ? (
      <div key="panel-header" className={headerClassName}>
        {header}
      </div>
    ) : null,
    <div key="panel-body" className={bodyClassName}>
      {...React.Children.toArray((body as React.ReactElement).props.children).map((child, index) =>
        React.isValidElement(child)
          ? React.cloneElement(child, { key: `panel-body-child-${index}` })
          : child
      )}
    </div>
  ]);

  const overlayClasses = classnames(
    'fixed inset-0 z-panel data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
    {
      'bg-black/30': dimBackground && !isL2Panel
    }
  );

  const hasOnlyCloseButton = !onBackButtonPress && !leftHeaderContent && !centerHeaderContent;

  const titleHeaderClassName = classnames(
    'mt-12 flex items-center',
    getHorizontalPadding({
      hasHorizontalPadding: true,
      size: size as PanelProps['size']
    }),
    {
      'justify-end': hasOnlyCloseButton,
      'justify-between': !hasOnlyCloseButton
    },
    titleHeaderClassNames,
    getTextColorClass(theme),
    getFocusOutlineClass(theme),
    { '!m-0 !p-8': miniPaddingHeader }
  );

  return (
    <Dialog.Portal>
      <Dialog.Overlay className={overlayClasses} />

      <Dialog.Content
        onAnimationEnd={onAnimationEnd}
        className={contentClassName}
        {...props}
        {...(customPanelId
          ? {
              id: customPanelId,
              onCloseAutoFocus(event) {
                const target = event.target as HTMLElement;
                const targetId = target.getAttribute('id');
                const triggerElement: HTMLElement | null = document.querySelector(
                  `[aria-controls="${targetId}"]`
                );

                if (triggerElement) {
                  triggerElement.focus();
                }
              }
            }
          : {})}
        {...(forceFocusOnTab
          ? {
              onFocusCapture: event => {
                const target = event.target as HTMLElement;

                if (target.getAttribute('role') === 'dialog') {
                  if (target.getAttribute('data-state') === 'open') {
                    const isTargetFocused = target.matches(':focus-visible');
                    const panelBody = target.querySelector('[data-panel-body]');

                    setTimeout(() => {
                      if (isTargetFocused && panelBody) {
                        if (customPanelId) {
                          const element = document.querySelector(
                            `[aria-controls="${customPanelId}"]`
                          ) as HTMLElement;

                          if (element) {
                            element.focus();
                          }
                        } else {
                          const firstFocusableElement = panelBody.querySelector(
                            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
                          ) as HTMLElement;

                          if (firstFocusableElement) {
                            firstFocusableElement.focus();
                          }
                        }
                      }
                    });
                  }
                }
              }
            }
          : {})}
      >
        <div className="relative flex h-full flex-col">
          <div className={titleHeaderClassName}>
            {onBackButtonPress && (
              <button
                type="button"
                onClick={onBackButtonPress}
                aria-label="previous"
                className={getFocusOutlineClass(theme)}
              >
                <Icon name="chevron-left" size="large" />
              </button>
            )}
            {leftHeaderContent}
            {centerHeaderContent}
            <Dialog.Close aria-label={closeLabel} className={getFocusOutlineClass(theme)}>
              <Icon name="close" size="large" />
            </Dialog.Close>
          </div>
          {newBodyChildren}
          {footer}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
};

const PanelHeader: React.FC<PanelHeaderProps> = ({ isL2Panel, children, className, id }) => {
  const { theme } = useContext(PanelContext);

  const headingClassName = classnames(
    'heading-6-medium flex h-6 items-center lg:h-[26px]',
    getTextColorClass(theme),
    {
      'group-data-[state=closed]:animate-[fadeOut_400ms_ease_forwards] group-data-[state=open]:animate-[fadeIn_400ms_ease_100ms_forwards]':
        isL2Panel
    },
    className
  );

  return (
    <div className={headingClassName} id={id}>
      {children}
    </div>
  );
};

const PanelTitle: React.FC<PanelTitleProps> = ({ children, hasHorizontalPadding = true }) => {
  const { size } = useContext(PanelContext);
  const headingClassName = classnames(
    'heading-6-medium mb-8 text-black',
    getHorizontalPadding({
      hasHorizontalPadding,
      size: size as PanelProps['size']
    })
  );
  return <div className={headingClassName}>{children}</div>;
};

const PanelBody: React.FC = ({
  children,
  hasHorizontalPadding = true,
  theme,
  className,
  scrollClassName
}: PanelBodyProps) => {
  const { size } = useContext(PanelContext);

  const scrollAreaClassName = classnames('h-full', getTextColorClass(theme), className);

  const scrollContentClassName = classnames(
    'pt-10',
    getHorizontalPadding({ hasHorizontalPadding, size: size as PanelProps['size'] }),
    scrollClassName
  );

  return (
    <div data-panel-body className={classnames('flex-1 overflow-auto')}
    data-component={"PanelBody"}>
      <ScrollArea className={scrollAreaClassName}>
        <div className={scrollContentClassName}>{children}</div>
      </ScrollArea>
    </div>
  );
};

const PanelFooter: React.FC<PanelFooterProps> = ({ divider, children, className }) => {
  const { size, theme } = useContext(PanelContext);

  let dividerStyles = '';

  if (divider === 'line') {
    dividerStyles = classnames(
      'before:absolute before:inset-x-6 before:top-0 before:block before:h-px before:bg-gray-light before:content-[""]',
      {
        'before:md:inset-x-8': size === 'small',
        'before:lg:inset-x-20': size === 'medium'
      }
    );
  }

  if (divider === 'gradient') {
    dividerStyles = classnames(
      'pt-0',
      'before:pointer-events-none before:absolute before:-top-6 before:left-0 before:h-6 before:w-full before:content-[""]',
      'before:bg-gradient-to-b before:from-transparent before:to-white'
    );
  }

  const footerClassName = classnames(
    'relative px-6 py-5',
    {
      'md:px-8': size === 'small',
      'lg:px-20': size === 'medium'
    },
    getTextColorClass(theme),
    dividerStyles,
    className
  );

  return <div className={footerClassName}>{children}</div>;
};

Panel.Content = PanelContent;
Panel.Header = PanelHeader;
Panel.Title = PanelTitle;
Panel.Body = PanelBody;
Panel.Footer = PanelFooter;
Panel.Trigger = Dialog.Trigger;

export default Panel;
