import { useAuth } from 'react-oidc-context';
import { SearchSemanticFilterBlock } from '@/@types/content';
import { FILTERS, QUERY_PARAMS } from '../constants';
import { useGetSearchResultsQuery } from '@/redux/api/client/search';
import { useCallback, useEffect, useState } from 'react';
import { IFacetGroup } from '@/@types/client-api';
import {
  getCreditTypeFacetGroup,
  getJurisdictionFacetGroup,
  getMatchingSemanticFilter,
  getFilterFromGroup,
  shiftQuery
} from '../utils/semanticFilters';
import { useSearchParamsStable } from '@/hooks/useSearchParams';
import { setSemanticFilters } from '@/redux/slices/searchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearchSemanticFilters } from '@/redux/selectors/searchSelectors';

export type SemanticFilter = IFacetGroup;

export type UseGetSemanticFilters = {
  areSemanticFiltersLoading: boolean;
};

export const useGetSemanticFilters = (
  semanticFiltersBlock?: SearchSemanticFilterBlock
): UseGetSemanticFilters => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const { searchParams, setSearchParams } = useSearchParamsStable();
  const searchQuery =
    searchParams.get(QUERY_PARAMS.QUERY) || searchParams.get(QUERY_PARAMS.OLD_QUERY);
  const [prevSearchQuery, setPrevSearchQuery] = useState<string | null>(searchQuery);
  const hasSearchFiltersApplied = Object.values(FILTERS).some(filter => searchParams.has(filter));
  const semanticFilters = useSelector(selectSearchSemanticFilters);
  const isDisabled =
    searchParams.has(QUERY_PARAMS.DISABLE_SEMANTIC_FILTERS) || !semanticFiltersBlock?.enable;
  const [isReady, setIsReady] = useState(isDisabled);

  const searchQueryFilterMatch = getMatchingSemanticFilter(
    semanticFiltersBlock?.filters,
    searchQuery || ''
  );
  const semanticJurisdictionFilter = hasSearchFiltersApplied
    ? undefined
    : searchQueryFilterMatch?.filters?.find(
        filter => filter.facetGroupType === FILTERS.JURISDICTIONS
      );
  const semanticCreditTypeFilter = hasSearchFiltersApplied
    ? undefined
    : searchQueryFilterMatch?.filters?.find(
        filter => filter.facetGroupType === FILTERS.CREDIT_TYPE
      );

  const {
    data: creditTypeAndJurisdictionLoggedInResults,
    isLoading: creditTypeAndJurisdictionLoggedInResultsLoading
  } = useGetSearchResultsQuery(
    {
      requestBody: {
        filterGroupsToReturn: [FILTERS.JURISDICTIONS, FILTERS.CREDIT_TYPE],
        pageSize: 0
      }
    },
    {
      skip: !auth.isAuthenticated || !semanticCreditTypeFilter || isDisabled
    }
  );
  const { data: jurisdictionFilterResults, isLoading: jurisdictionFilterResultsLoading } =
    useGetSearchResultsQuery(
      {
        requestBody: {
          filterGroupsToReturn: [FILTERS.JURISDICTIONS],
          pageSize: 0,
          facetGroups: [getJurisdictionFacetGroup(semanticJurisdictionFilter?.value || '')]
        }
      },
      {
        skip: !(semanticJurisdictionFilter && !semanticCreditTypeFilter) || isDisabled
      }
    );

  // Reset all applied semantic filters when the search query changes.
  useEffect(() => {
    if (searchQuery !== prevSearchQuery) {
      dispatch(setSemanticFilters({ filters: [], tooltip: null }));
      setPrevSearchQuery(searchQuery);
      setIsReady(false);
    }
  }, [prevSearchQuery, searchQuery, dispatch]);

  const applySemanticFilter = useCallback(
    (filters: IFacetGroup[]) => {
      dispatch(
        setSemanticFilters({
          filters,
          tooltip: {
            title: searchQueryFilterMatch?.tooltipTitle,
            description: searchQueryFilterMatch?.tooltipDescription
          }
        })
      );
      shiftQuery(setSearchParams);
      setIsReady(true);
    },
    [dispatch, searchQueryFilterMatch, setSearchParams]
  );

  useEffect(() => {
    if (
      semanticFilters?.length ||
      creditTypeAndJurisdictionLoggedInResultsLoading ||
      jurisdictionFilterResultsLoading ||
      isDisabled
    ) {
      return;
    }

    function applyFilter() {
      if (semanticCreditTypeFilter) {
        const matchingCreditTypeFilter = getFilterFromGroup(
          creditTypeAndJurisdictionLoggedInResults,
          FILTERS.CREDIT_TYPE,
          semanticCreditTypeFilter.value
        );

        const matchingJurisdictionFilter = getFilterFromGroup(
          creditTypeAndJurisdictionLoggedInResults,
          FILTERS.JURISDICTIONS,
          semanticJurisdictionFilter?.value || ''
        );

        if (!matchingCreditTypeFilter?.value) {
          return;
        }

        if (semanticJurisdictionFilter) {
          if (matchingJurisdictionFilter) {
            applySemanticFilter([getCreditTypeFacetGroup(matchingCreditTypeFilter.value)]);
          }
          return;
        }

        applySemanticFilter([getCreditTypeFacetGroup(matchingCreditTypeFilter.value)]);
        return;
      }

      const matchingJurisdictionFilter = getFilterFromGroup(
        jurisdictionFilterResults,
        FILTERS.JURISDICTIONS,
        semanticJurisdictionFilter?.value || ''
      );

      if (matchingJurisdictionFilter?.value) {
        applySemanticFilter([getJurisdictionFacetGroup(matchingJurisdictionFilter.value)]);
      }
    }

    applyFilter();
    setIsReady(true);
  }, [
    applySemanticFilter,
    semanticFilters,
    creditTypeAndJurisdictionLoggedInResults,
    creditTypeAndJurisdictionLoggedInResultsLoading,
    jurisdictionFilterResults,
    jurisdictionFilterResultsLoading,
    semanticCreditTypeFilter,
    semanticJurisdictionFilter,
    isDisabled
  ]);

  return {
    areSemanticFiltersLoading:
      !isDisabled &&
      (creditTypeAndJurisdictionLoggedInResultsLoading ||
        jurisdictionFilterResultsLoading ||
        !isReady)
  };
};
