export const SnippetButtonLabel = {
  removeFromLibrary: 'Remove from library',
  addToCalendar: 'Add to calendar',
  accessMaterials: 'Access materials',
  changeOrCancelRegistration: 'Change or cancel registration',
  notifyMe: 'Notify me',
  saveToLibrary: 'Save to library',
  savedToLibrary: 'Saved to library',
  viewTranscript: 'View transcript',
  startWatching: 'Start watching',
  watchAgain: 'Watch again',
  continueWatching: 'Continue watching',
  startListening: 'Start listening',
  viewCertificates: 'View certificates',
  buyNow: 'Buy now',
  readOnPLUS: 'Read on PLUS',
  creditRequested: 'Credits requested',
  requestCredits: 'Request credits'
};
