import { ICertificateViewModel } from '@/@types/client-api';
import { useCallback, useState } from 'react';
import LoadingDots from '@/components/ui/Buttons/LoadingDots';
import Link from '@/components/ui/Link';

type DownloadIndividualCertificateLinkProps = {
  certificate: ICertificateViewModel;
  onDownload: (certificate: ICertificateViewModel) => Promise<void>;
};

const DownloadIndividualCertificateLink = ({
  certificate,
  onDownload
}: DownloadIndividualCertificateLinkProps): JSX.Element => {
  const [isProcessing, setProcessing] = useState(false);

  const handleOnClick = useCallback(async () => {
    try {
      setProcessing(true);
      await onDownload(certificate);
    } finally {
      setProcessing(false);
    }
  }, [certificate, onDownload]);

  if (isProcessing) {
    return <LoadingDots color="black" dotSize="small" />;
  }

  return (
    <Link
      as="button"
      text="Download certificate"
      variant="text-link-2"
      onClick={handleOnClick}
      className="h-6"
    />
  );
};

export default DownloadIndividualCertificateLink;
