export const getFollowingPracticeAreasMessage = (practiceAreas: string[]): string => {
  if (practiceAreas.length > 4) {
    return "You're now following the selected practice areas";
  }
  let message = `You're now following ${practiceAreas[0]}`;
  for (let i = 1; i < practiceAreas.length; i++) {
    message += `${i === practiceAreas.length - 1 ? ' and ' : ', '} ${practiceAreas[i]}`;
  }
  return message;
};
