import { Fragment } from 'react';
import AddCreditCard from './AddCreditCard';
import CreditCard, { CreditCardProps } from './CreditCard';
import CreditCardLoadingState from './CreditCardLoadingState';
import CreditCardPanel, { OnSaveCreditCard } from '../ProfilePanels/CreditCardPanel';
import { ExpiredSingleCardNudge } from '@/hooks/shared/useNudge/types';
import NudgeTooltip from '../Tooltip/NudgeTooltip';

type CreditCardGridProps = {
  isPanelOpen: boolean;
  setPanelOpen: (open: boolean) => void;
  cards?: CreditCardProps[];
  onAddCardPress?: () => void;
  onSave?: OnSaveCreditCard;
  loading?: boolean;
  nudge?: ExpiredSingleCardNudge;
};

const CreditCardGrid = ({
  isPanelOpen,
  setPanelOpen,
  cards,
  onAddCardPress,
  onSave,
  loading = false,
  nudge
}: CreditCardGridProps) => {
  return (
    <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {loading ? (
        Array.from({ length: (cards?.length || 0) + 1 }).map((_, index) => (
          <CreditCardLoadingState key={index} />
        ))
      ) : (
        <>
          {cards?.map(card => (
            <Fragment key={card.number}>
              {nudge?.cardId === card.id ? (
                <NudgeTooltip nudge={nudge}>
                  <CreditCard {...card} />
                </NudgeTooltip>
              ) : (
                <CreditCard {...card} />
              )}
            </Fragment>
          ))}
          <CreditCardPanel
            isOpen={isPanelOpen}
            onOpenChange={setPanelOpen}
            onSave={onSave}
            triggerButton={<AddCreditCard onPress={onAddCardPress} />}
          />
        </>
      )}
    </div>
  );
};

export default CreditCardGrid;
