import type { LibraryItem } from '@/@types/client-api';
import { getThumbnailImageUrl } from '@/components/ui/SearchResults/Snippets/helpers';

import type { SnippetSegmentType } from './types';

export const getSegmentFromLibraryItem = (segment: LibraryItem): SnippetSegmentType => {
  const title = segment.title ?? '';
  return {
    pk: segment.pk ?? '',
    segmentTitle: segment.topicNumber ? `${segment.topicNumber}. ${title}` : title,
    segmentLink: segment.url ?? '#',
    segmentImage: {
      url: getThumbnailImageUrl(segment.pk),
      alt: segment.title || 'Segment thumbnail'
    },
    progressValue: segment.playbackProgressPct || 0,
    onSegmentLinkClick: () => {},
    cleProgressPct: segment.cleProgressPct,
    orderNumber: segment.topicNumber || 0,
    isPurchased: segment.isPurchased,
    isParentProgramPurchased: segment.isParentProgramPurchased ?? undefined,
    watchUrl: segment.watchUrl ?? undefined,
    playable: segment.isPurchased || !!segment.isParentProgramPurchased || segment.price === 0
  };
};
