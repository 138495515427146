import { IFacetGroup, IFacetResultItem, SearchResponse } from '@/@types/client-api';

export const getRemainingResultsCount = (response?: SearchResponse): number => {
  const totalResults = response?.searchResultSummary?.noOfHits ?? 0;
  const resultsCount = response?.searchResults?.searchResultsItems?.length ?? 0;
  return totalResults > resultsCount ? totalResults - resultsCount : 0;
};

export const addDisabledFacetsFromEmptySearch = ({
  hasDisabledFilters,
  facetGroup,
  allFiltersFromEmptySearch
}: {
  hasDisabledFilters?: boolean;
  facetGroup?: IFacetGroup;
  allFiltersFromEmptySearch: { data?: SearchResponse };
}) => {
  const disabledFacets =
    (hasDisabledFilters &&
      allFiltersFromEmptySearch?.data?.facetResults?.facetGroups
        ?.find(group => group.groupTypeId === facetGroup?.groupTypeId)
        ?.facets?.filter(facet => !facetGroup?.facets?.find(f => f.value === facet.value))
        .map(facet => ({ ...facet, disabled: true }))) ||
    [];

  return [...(facetGroup?.facets || []), ...disabledFacets];
};

export const augmentFacetGroupsWithEmptySearchData = ({
  facetGroups,
  allFiltersFromEmptySearch
}: {
  facetGroups: IFacetGroup[];
  allFiltersFromEmptySearch?: SearchResponse;
}) => {
  return (
    allFiltersFromEmptySearch?.facetResults?.facetGroups?.flatMap(group => {
      const groupToAugment = facetGroups?.find(f => f.groupTypeId === group.groupTypeId);
      if (!groupToAugment) return [];

      const facets: IFacetResultItem[] =
        group.facets?.flatMap(facet => {
          const mainFacet = groupToAugment.facets?.find(f => f.value === facet.value);

          if (!mainFacet) return [];

          return [
            {
              ...facet,
              checked: mainFacet?.checked
            }
          ];
        }) ?? [];

      groupToAugment.facets?.forEach(facet => {
        if (!facets?.some(f => f.value === facet.value) && facet.name) {
          facets?.push(facet);
        }
      });

      return [
        {
          ...group,
          facets
        }
      ];
    }) ?? []
  );
};
