import Input, { InputError, InputProps } from '@/components/ui/Form/Input';
import { isValidValue, ValidationOptions } from '@/utils/validations';
import { useCallback, useMemo } from 'react';

export type TextInputProps = Omit<InputProps, 'id' | 'name'> &
  ValidationOptions & {
    errorValidationEnabled?: boolean;
    forceInvalid?: boolean;
    name?: string;
  };

const TextArea = ({
  value,
  label,
  placeholder,
  type,
  onChange,
  maxLength,
  errors,
  className,
  required = false,
  disabled = false,
  acceptLetters,
  acceptNumbers,
  acceptSpaces,
  acceptSpecialChars,
  allowedChars,
  errorValidationEnabled = true,
  forceInvalid = false,
  name
}: TextInputProps): JSX.Element => {
  const id = label.replace(/ /g, '').toLowerCase();

  const handleOnChange = useCallback(
    (newValue: string) => {
      if (
        !newValue ||
        isValidValue(newValue, {
          acceptLetters,
          acceptNumbers,
          acceptSpaces,
          acceptSpecialChars,
          allowedChars
        })
      ) {
        onChange?.(newValue);
      }
    },
    [acceptLetters, acceptNumbers, acceptSpaces, acceptSpecialChars, allowedChars, onChange]
  );

  const allErrors = useMemo((): InputError[] => {
    const result: InputError[] = [];
    if (errorValidationEnabled) {
      if (type !== 'number') {
        required && result.push({ message: 'Required', match: 'valueMissing' });
      }
      errors && result.push(...errors);
    }
    return result;
  }, [errorValidationEnabled, errors, required, type]);

  return (
    <Input
      variant="container"
      label={`${label}${required ? ' *' : ''}`}
      placeholder={placeholder ?? label}
      name={name ?? id}
      id={id}
      value={value}
      onChange={handleOnChange}
      maxLength={maxLength}
      required={required}
      disabled={disabled}
      errors={allErrors}
      className={className}
      forceInvalid={forceInvalid}
      isTextArea={true}
      textAreaClasses="h-[148px]"
    />
  );
};

export default TextArea;
