import { SubjectResponse } from '@/@types/client-api';
import Selector from '@/components/ui/Form/Selector';
import {
  useFollowPracticeAreasMutation,
  useGetUserPracticeAreasQuery,
  useUnfollowPracticeAreasMutation
} from '@/redux/api/client/practiceAreas';
import { useGetTaxonomySubjectsQuery } from '@/redux/api/client/taxonomy';
import { CheckedState } from '@radix-ui/react-checkbox';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface PracticeAreaStepProps {
  setIsProcessing: (isProcessing: boolean) => void;
  setIsStepSkipping: (isStepSkipping: boolean) => void;
  onStepIncrease: () => void;
  type: 'parents' | 'children';
  isSubmitting: boolean;
}

const PracticeAreaStep: React.FC<PracticeAreaStepProps> = ({
  setIsProcessing,
  setIsStepSkipping,
  onStepIncrease,
  type,
  isSubmitting
}) => {
  const [followedSubjectList, setFollowedSubjectList] = useState<string[]>([]);
  const [areSubjectsSaved, setAreSubjectsSaved] = useState(false);
  const [areSubjectsSaving, setAreSubjectsSaving] = useState(false);
  const { isLoading: isLoadingSubjects, data: subjects } = useGetTaxonomySubjectsQuery(true);
  const {
    isLoading: isLoadingFollowed,
    data: followed,
    refetch: refetchFollowing
  } = useGetUserPracticeAreasQuery();
  const [followPracticeAreas, { isLoading: isLoadingFollowing }] = useFollowPracticeAreasMutation();
  const [unfollowPracticeAreas, { isLoading: isLoadingUnfollowing }] =
    useUnfollowPracticeAreasMutation();

  const handleFollowUnfollowSubject = useCallback(
    (checked: CheckedState, id: string) => {
      if (checked) {
        setFollowedSubjectList([...followedSubjectList, id]);
      } else {
        setFollowedSubjectList(followedSubjectList.filter(x => x !== id));
      }
    },
    [followedSubjectList, setFollowedSubjectList]
  );

  const parentSelectors = useMemo(() => {
    return subjects?.map(subject => {
      const checked = followedSubjectList.includes(subject.code || '');

      return (
        <Selector
          key={subject.code}
          size="small"
          title={subject.name || ''}
          label={subject.name || ''}
          checked={checked}
          onCheckedChange={checked =>
            subject.code && handleFollowUnfollowSubject(checked, subject.code)
          }
        />
      );
    });
  }, [subjects, followedSubjectList, handleFollowUnfollowSubject]);

  const childSelectors = useMemo(() => {
    const followedParentSubjects = subjects?.filter(subject =>
      followed?.items?.map(x => x.id).includes(subject.code || '')
    );

    return followedParentSubjects?.map(parent => {
      const children: SubjectResponse[] = [];
      parent.subjects?.forEach(child => {
        if (!children.map(x => x.code).includes(child.code)) {
          children.push(child);
        }
      });

      if (children.length === 0) return;

      return (
        <div>
          <h4 className="text-1-medium mb-4">{parent.name}</h4>
          <div className="mb-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
            {children.map(child => {
              const checked = followedSubjectList.includes(child.code || '');

              return (
                <Selector
                  key={child.code}
                  size="small"
                  title={child.name || ''}
                  label={child.name || ''}
                  checked={checked}
                  onCheckedChange={checked =>
                    child.code && handleFollowUnfollowSubject(checked, child.code)
                  }
                />
              );
            })}
          </div>
        </div>
      );
    });
  }, [subjects, followedSubjectList, followed?.items, handleFollowUnfollowSubject]);

  useEffect(() => {
    const handleSubmit = async () => {
      const followList = followedSubjectList.filter(
        x => !followed?.items?.map(s => s.id).includes(x)
      );
      if (followList.length > 0) {
        await followPracticeAreas(followList.map(x => x.replace('subject-', '')));
      }
      const unfollowList = followed?.items
        ?.map(x => x.id)
        .filter(x => x && !followedSubjectList.includes(x)) as string[];
      if (unfollowList && unfollowList.length > 0) {
        await unfollowPracticeAreas(unfollowList.map(x => x.replace('subject-', '')));
      }

      await refetchFollowing();
      setAreSubjectsSaved(true);
    };

    if (isSubmitting && !areSubjectsSaving) {
      setAreSubjectsSaving(true);
      handleSubmit();
    }
  }, [
    isSubmitting,
    areSubjectsSaving,
    setAreSubjectsSaved,
    refetchFollowing,
    followPracticeAreas,
    followed?.items,
    followedSubjectList,
    unfollowPracticeAreas
  ]);

  useEffect(() => {
    if (areSubjectsSaved) {
      onStepIncrease();
      setAreSubjectsSaved(false);
      setAreSubjectsSaving(false);
    }
  }, [areSubjectsSaved, onStepIncrease, setAreSubjectsSaved, setAreSubjectsSaving]);

  useEffect(() => {
    if (followed) {
      setFollowedSubjectList(
        followed.items?.filter(x => x.id != null || x.id != undefined).map(x => x.id || '') || []
      );
    }
  }, [followed]);

  useEffect(() => {
    if (type == 'children' && childSelectors?.filter(x => x != undefined)?.length === 0) {
      setIsStepSkipping(true);
      onStepIncrease();
    } else {
      setIsStepSkipping(false);
    }
  }, [type, childSelectors, onStepIncrease, setIsStepSkipping]);

  useEffect(() => {
    setIsProcessing(
      isLoadingSubjects || isLoadingFollowed || isLoadingFollowing || isLoadingUnfollowing
    );
  }, [
    isLoadingSubjects,
    isLoadingFollowed,
    isLoadingFollowing,
    isLoadingUnfollowing,
    setIsProcessing
  ]);

  return type === 'parents' ? parentSelectors : childSelectors;
};

export default PracticeAreaStep;
