export const CERTIFICATES_FILTERS = {
  SOURCE: 'source',
  JURISDICTIONS: 'jurisdictions',
  YEAR: 'year',
  DATE_RANGE: 'dateRange'
} as const;

export const CERTIFICATES_FILTERS_LABEL = {
  SOURCE: 'Source',
  JURISDICTIONS: 'Jurisdictions',
  YEAR: 'Date Range'
} as const;

export const CERTIFICATES_SOURCE_LABEL = {
  pli: 'PLI',
  external: 'External'
} as const;

export const CUSTOM_YEAR_LABEL = 'Custom';
export const CUSTOM_YEAR_VALUE = 9999;

export const VALUE_DELIMITER = ';';

export const CERTIFICATES_PAGE_SIZE = 20;
export const CERTIFICATES_SORT_DEFAULT = 'NewestFirst';

export const CERTIFICATES_SORT_OPTIONS: { value: string; label: string }[] = [
  { value: 'NewestFirst', label: 'Newest First' },
  { value: 'OldestFirst', label: 'Oldest First' },
  { value: 'Az', label: 'A-Z' },
  { value: 'Za', label: 'Z-A' }
];
