import { forwardRef, useCallback, useMemo } from 'react';
import { UpcomingLiveProps } from '..';
import BaseBox from '../../components/BaseBox';
import PanelButton from '../../components/PanelButton';
import { Variant } from '@/redux/slices/pdpSlice';
import Button from '@/components/ui/Buttons/Button';
import { getFormatedDateRangeParts } from '@/lib/helpers/date';
import Thumbnail from '@/components/ui/Thumbnail';
import RegistrationItem from './RegistrationItem';
import { useGetMediaStreamsQuery } from '@/redux/api/client/product';
import { getDiscountAdviceMessage, getProductImageUrl } from '../../../helpers';
import DotSeparatedContent from '@/components/ui/DotSeparatedContent';

interface LiveVariantProps {
  labels: UpcomingLiveProps['labels'];
  variant: Variant;
  onSubmit?: () => void;
  onSubmitReg?: (variant: Variant) => void;
  isModal?: boolean;
  openMaterials?: () => void;
  isSuccess?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  withinPanel?: boolean;
}

const LiveVariant = forwardRef<HTMLButtonElement, LiveVariantProps>(
  ({ variant, ...props }, ref) => {
    if (variant?.type === 'online') {
      return <OnlineVariant variant={variant} {...props} ref={ref} />;
    }

    return <InPersonVariant variant={variant} {...props} ref={ref} />;
  }
);

const OnlineVariant = forwardRef<HTMLButtonElement, LiveVariantProps>(
  (
    {
      labels,
      variant,
      onSubmit,
      onSubmitReg,
      isModal,
      openMaterials,
      isLoading,
      isSuccess,
      isError,
      withinPanel
    },
    ref
  ) => {
    const isPurchased = variant?.purchased || variant?.registered;
    const canLaunch = isPurchased || !variant.retail;
    const getButtonProps = useCallback(() => {
      const showPrice = !isPurchased;
      return {
        label: canLaunch ? labels.launchButton : labels.purchaseButton,
        price: showPrice ? variant?.price : undefined,
        strikePrice: showPrice && !variant.retail ? variant?.originalPrice : undefined,
        purchased: isPurchased
      };
    }, [labels, variant, canLaunch, isPurchased]);

    const { data: productMedia } = useGetMediaStreamsQuery(variant.code!, {
      skip: false
    });

    const thumbnailIcon = {
      label: canLaunch ? labels.launchButton : labels.purchaseButton,
      name: canLaunch ? 'play' : 'cart'
    };

    const secondaryButton = useMemo(() => {
      const isPurchased = variant?.purchased || variant?.registered;

      if (isPurchased && variant?.hasMaterials) {
        return (
          <Button
            label={labels.materials}
            color="outline-black"
            size="large"
            className="mt-4 w-full"
            onClick={openMaterials}
          />
        );
      }
      return undefined;
    }, [labels.materials, variant, openMaterials]);

    const dateRangeParts = variant.eventStartDate
      ? getFormatedDateRangeParts(
          variant.timeZoneIdentifier!,
          variant.eventStartDate,
          variant.eventEndDate
        )
      : undefined;

    return (
      <>
        <BaseBox.Content inModal={isModal} withinPanel={withinPanel}>
          {!variant.registered && (
            <h3 className="text-2 mb-6 font-bold">
              {withinPanel ? labels.joinNow : labels.watchNow}
            </h3>
          )}
          <Thumbnail
            icon={thumbnailIcon}
            image={{ url: getProductImageUrl(variant.code!) }}
            onClick={onSubmit}
            ratio={1.5}
            tag={{
              children: 'Live',
              variant: 'emphasis'
            }}
            video={
              productMedia?.length && productMedia?.length > 0 && productMedia[0].playbackUrl
                ? {
                    autoPlay: true,
                    playable: true,
                    url: productMedia[0].playbackUrl
                  }
                : undefined
            }
            imageSizes={{
              xs: '320px',
              sm: '496px',
              md: '768px',
              lg: '342px'
            }}
          />
          <div className="mt-6 flex items-center justify-between">
            <p className="text-2 font-medium text-gray-dark">{variant.location}</p>
            <p className="text-2 font-medium text-gray-dark">
              <DotSeparatedContent content={[dateRangeParts?.currentDay, dateRangeParts?.date]} />
            </p>
          </div>
        </BaseBox.Content>
        <BaseBox.Footer
          primaryButton={
            <PanelButton
              ref={ref}
              onClick={
                variant.type == 'in-person' && onSubmitReg ? () => onSubmitReg(variant) : onSubmit
              }
              {...getButtonProps()}
              isLive={variant.type != 'in-person'}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
            />
          }
          secondaryButton={secondaryButton}
          discountAdviceMessage={getDiscountAdviceMessage(variant, labels)}
          extraMessage={labels.extraMessage}
          codeLabel={labels.programId}
          codeValue={variant.code!}
          withinPanel={withinPanel}
        ></BaseBox.Footer>
      </>
    );
  }
);

const InPersonVariant = forwardRef<HTMLButtonElement, LiveVariantProps>(
  ({ labels, variant, isModal, openMaterials, withinPanel }, ref) => {
    const primaryButton = useMemo(() => {
      const isPurchased = variant?.purchased || variant?.registered;
      if (isPurchased && variant?.hasMaterials) {
        return (
          <Button
            ref={ref}
            label={labels.materials}
            color="red"
            size="large"
            className="w-full"
            onClick={() => {
              openMaterials;
            }}
          />
        );
      }

      return (
        <Button
          ref={ref}
          label={labels.addToCalendar}
          color="red"
          size="large"
          className="w-full"
          onClick={() => {}}
        />
      );
    }, [labels, ref, variant, openMaterials]);

    const secondaryButton = useMemo(() => {
      const isPurchased = variant?.purchased || variant?.registered;
      if (isPurchased && variant?.hasMaterials) {
        return (
          <Button
            ref={variant.hasMaterials ? null : ref}
            label={labels.addToCalendar}
            color={variant.hasMaterials ? 'outline-black' : 'red'}
            size="large"
            className="mt-4 w-full"
            onClick={() => {}}
          />
        );
      }

      return null;
    }, [labels, ref, variant]);

    return (
      <>
        <BaseBox.Content inModal={isModal} withinPanel={withinPanel}>
          <div className="pt-4 lg:pt-6">
            <RegistrationItem
              key={variant.code}
              label={labels.inPerson}
              type={variant.type!}
              location={variant.location}
              address1={variant.address1}
              address2={variant.address2}
              startDate={variant.eventStartDate!}
              endDate={variant.eventEndDate}
              timezone={variant.timeZoneIdentifier!}
            />
          </div>
        </BaseBox.Content>
        <BaseBox.Footer
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          discountAdviceMessage={getDiscountAdviceMessage(variant, labels)}
          codeLabel={labels.programId}
          codeValue={variant.code!}
          withinPanel={withinPanel}
        ></BaseBox.Footer>
      </>
    );
  }
);

export default LiveVariant;
