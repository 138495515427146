import type {
  ProfileSettingsPage as ProfileSettingsPageProps,
  SiteSettings
} from '@/@types/content';

import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import ContentArea from '../cms/ContentArea';
import withNav from '../hocs/withNav';
import { useAuth } from 'react-oidc-context';
import IconMessage from '../ui/IconMessage';
import NudgeProvider from '@/hooks/shared/useNudge/provider';
import { transformIfPLIUrl } from '@/utils/helpers';

const ProfileSettingsPageComponent: React.FC<ProfileSettingsPageProps & SiteSettings> = ({
  tabsContainer,
  url,
  mainContentArea
}) => {
  const parentRouteSegment = transformIfPLIUrl(url)?.substring(1);
  const modeEdit = isEditOrPreviewMode();
  const auth = useAuth();
  const isPM = !!(auth.user?.profile.IsPM === 'true' && auth.user?.profile.IsIM !== 'true');

  const rightContent = isPM && (
    <span className="text-gray-medium">
      <IconMessage
        iconClassName="text-white"
        iconName="approved-nocolor"
        text="Privileged member"
      />
    </span>
  );

  return (
    <>
      <ContentArea
        propertyName="StandardBlackHeaderBlock"
        components={mainContentArea}
        componentsProps={{ rightContent }}
      />
      {(!!tabsContainer?.length || modeEdit) && (
        <div className="container pt-10 md:pt-8">
          <NudgeProvider>
            <ContentArea
              propertyName="Item"
              components={tabsContainer}
              componentsProps={{ parentRouteSegment }}
            />
          </NudgeProvider>
        </div>
      )}
    </>
  );
};

const ProfileSettingsPage = withNav(ProfileSettingsPageComponent);
export default ProfileSettingsPage;
