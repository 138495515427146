import {
  DEFAULT_FILTER_VALUES,
  DEFAULT_SORT,
  FILTER_LIST,
  LibraryFilterValue
} from '@/utils/libraryConstants';
import { PAGE_SIZE } from '../constants';
import { LibraryRequest } from '@/redux/api/client/library';
import { LibraryResponse } from '@/@types/client-api';

export const libraryDataToLibraryRequest = ({ summary }: LibraryResponse): LibraryRequest => {
  const filterValues = FILTER_LIST.reduce<Record<string, LibraryFilterValue>>((acc, key) => {
    if (
      summary?.filtersApplied?.[key] &&
      DEFAULT_FILTER_VALUES[key] !== summary.filtersApplied[key]
    ) {
      acc[key] = summary.filtersApplied[key]!;
    }
    return acc;
  }, {});

  return {
    pageSize: PAGE_SIZE,
    pageNumber: summary?.currentPage,
    ...filterValues,
    sort: summary?.filtersApplied?.sort || DEFAULT_SORT
  };
};
