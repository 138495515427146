import { LibraryItem, ProgramMaterial, XhtmlString } from '@/@types/client-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import libraryEndpoints from '../api/client/library';

// digital-program: digital access granted from program access.
// digital-plusMember: digital access granted from plus membership
// these are not related to STS.
export type EcomBookVariantFormat =
  | 'digital-program'
  | 'digital-plusMember'
  | 'hardcover'
  | 'softcover'
  | 'binder'
  | 'preorder'
  | 'sts'
  | 'sts-renewal';

export type Variant = {
  code?: string;
  displayName?: string;
  thumbnail?: string;
  location?: string;
  address1?: string;
  address2?: string;
  duration?: string;
  progress?: number;
  publicationDate?: string;
  eventStartDate?: string;
  eventEndDate?: string;
  timeZoneIdentifier?: string;
  price?: string;
  originalPrice?: string;
  itemClass?: number;
  retailPrice?: number;
  discountPrice?: number;
  type?: 'online' | 'in-person' | 'groupcast';
  format?: EcomBookVariantFormat;
  waitlist?: boolean;
  retail?: boolean;
  hasMaterials?: boolean;
  transcriptUrl?: string;
  registered?: boolean; // Live
  isExpired?: boolean;
  sk?: number;
  materials?: ProgramMaterial[];
  purchased?: boolean; // On Demand
  isLive?: boolean;
  hasUpkeep?: boolean; // Publications
  readOnPlusUrl?: string;
  contentLinkUrl?: string;
  isStsRenewalPermitted?: boolean;
  purchasedStsExpirationDate?: string;
  priorityCode?: string; // For STS Publication renewal flow
  notifyForm?: NotifyPanelItems;
  launchUrl?: string;
};

export type NotifyPanelItems = {
  url?: string;
  buttonLabel?: string;
  messageLabel?: XhtmlString;
  headerLabel?: string;
};

export type CatalogRelations = {
  variants: Array<Variant>;
};

export type PDPLibraryItem = { data?: LibraryItem } & { isFetching?: boolean };
export type PDPMaterials = { data?: ProgramMaterial[] } & { isFetching?: boolean };

export interface PDPState {
  selectedVariant?: Variant;
  catalogRelations?: CatalogRelations;
  hardSelected: boolean;
  loading: boolean;
  materialsLoading?: boolean;
  registered?: boolean;
  registrationModalOpen: boolean;
  loadedComponents: number;
  totalComponents: number;
  hasSegmentsSection?: boolean;
  hasTestimonialsSection?: boolean;
  isStsRenewalSelected?: boolean;
  isFooterInView?: boolean;
  library: Record<string, PDPLibraryItem>;
  materials: Record<string, PDPMaterials>;
}

const initialState: PDPState = {
  hardSelected: false,
  loading: false,
  registrationModalOpen: false,
  materialsLoading: true,
  loadedComponents: 0,
  totalComponents: 0,
  hasSegmentsSection: false,
  isFooterInView: false,
  library: {},
  materials: {}
};

export const pdpSlice = createSlice({
  name: 'pdp',
  initialState,
  reducers: {
    resetPDPContext: state => {
      state.selectedVariant = undefined;
      state.catalogRelations = undefined;
      state.hardSelected = false;
      state.loading = false;
      state.materialsLoading = true;
      state.registrationModalOpen = false;
      state.loadedComponents = 0;
      state.totalComponents = 0;
      state.hasSegmentsSection = false;
      state.isFooterInView = false;
    },

    setSoftSelectedVariant: (state, action: PayloadAction<Variant>) => {
      state.selectedVariant = action.payload;
      state.hardSelected = false;
    },
    setHardSelectedVariant: (state, action: PayloadAction<Variant>) => {
      state.selectedVariant = action.payload;
      state.hardSelected = true;
      state.isStsRenewalSelected = action.payload?.format === 'sts-renewal';
    },
    setCatalogRelations: (state, action: PayloadAction<CatalogRelations>) => {
      state.catalogRelations = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOpenRegistrationModal: (state, action: PayloadAction<boolean>) => {
      state.registrationModalOpen = action.payload;
    },
    setMaterialsLoading: (state, action: PayloadAction<boolean>) => {
      state.materialsLoading = action.payload;
    },
    registerAsyncComponent: state => {
      state.loadedComponents += 1;
    },
    markComponentAsLoaded: state => {
      state.totalComponents += 1;
    },
    registerSegmentsSection: state => {
      state.hasSegmentsSection = true;
    },
    registerTestimonialsSection: state => {
      state.hasTestimonialsSection = true;
    },
    unregisterTestimonialsSection: state => {
      state.hasTestimonialsSection = false;
    },
    setIsFooterInView: (state, action: PayloadAction<boolean>) => {
      state.isFooterInView = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(libraryEndpoints.endpoints.getLibraryItemsByPks.matchPending, (state, action) => {
        const pendingItemPks = action.meta.arg.originalArgs;
        pendingItemPks.forEach(itemPk => {
          state.library[itemPk] = { data: undefined, isFetching: true };
        });
      })
      .addMatcher(
        libraryEndpoints.endpoints.getLibraryItemsByPks.matchFulfilled,
        (state, action) => {
          const requestedItemPks = action.meta.arg.originalArgs;

          const parentItems = action.payload?.items ?? [];
          const nestedSegmentItems =
            parentItems?.flatMap(p => (p.segments ?? []).map(s => s)) ?? [];

          const returnedItems = [...parentItems, ...nestedSegmentItems];
          const notReturnedItemPks = requestedItemPks.filter(
            requestedPk => !returnedItems.find(item => item.pk === requestedPk)
          );

          returnedItems.forEach(item => {
            if (item.pk) state.library[item.pk] = { data: item, isFetching: false };
          });
          notReturnedItemPks.forEach(itemPk => {
            // Clear the loading state for items removed from library
            state.library[itemPk] = { data: undefined, isFetching: false };
          });
        }
      )
      .addMatcher(libraryEndpoints.endpoints.getProgramMaterials.matchPending, (state, action) => {
        const itemPk = action.meta.arg.originalArgs;
        state.materials[itemPk] = { data: undefined, isFetching: true };
      })
      .addMatcher(
        libraryEndpoints.endpoints.getProgramMaterials.matchFulfilled,
        (state, action) => {
          const itemPk = action.meta.arg.originalArgs;
          state.materials[itemPk] = { data: action.payload, isFetching: false };
        }
      );
  }
});

export const selectVariant = createAsyncThunk(
  'pdp/setDelayedSelectedVariant',
  async (variant: Variant, { dispatch }) => {
    dispatch(pdpSlice.actions.setLoading(true));
    dispatch(pdpSlice.actions.setHardSelectedVariant(variant));

    setTimeout(() => {
      dispatch(pdpSlice.actions.setLoading(false));
    }, 1200);
  }
);

// Action creators are generated for each case reducer function
export const {
  resetPDPContext,
  setSoftSelectedVariant,
  setHardSelectedVariant,
  setCatalogRelations,
  setOpenRegistrationModal,
  setMaterialsLoading,
  registerAsyncComponent,
  markComponentAsLoaded,
  registerSegmentsSection,
  registerTestimonialsSection,
  unregisterTestimonialsSection,
  setIsFooterInView
} = pdpSlice.actions;

export default pdpSlice.reducer;
