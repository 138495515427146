import { ICreditRequirement, RequirementType } from '@/@types/client-api';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import ComplexTooltip from '@/components/ui/Tooltip/ComplexTooltip';
import RichText from '@/components/cms/RichText';
import classnames from 'classnames';
import { forwardRef, memo, useCallback, useMemo } from 'react';
import {
  filterHiddenRequirements,
  roundCredits,
  getCreditsCounted,
  translateToTailwinds,
  formatCreditTypeId
} from '@/components/ui/CreditTracker/util/TrackerHelpers';
import {
  EarnMoreCreditPanel,
  IEarnMoreCreditPanelProps
} from '@/components/ui/CreditTracker/Panels/EarnMoreCreditPanel';
import Tag from '@/components/ui/Tag';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

export interface CreditTypeCardProps {
  requirement: ICreditRequirement;
  isDeadlineApproaching?: boolean;
  complianceEndDate?: string | number | Date;
  earnMoreSettings?: IEarnMoreCreditPanelProps;
  isBlurred: boolean;
  tagText?: string | null;
  showEarnMore: boolean;
}

const CreditTypeCard = memo(
  forwardRef<HTMLDivElement, CreditTypeCardProps>(
    (
      {
        requirement,
        isBlurred,
        isDeadlineApproaching,
        tagText,
        earnMoreSettings,
        showEarnMore
      }: CreditTypeCardProps,
      ref
    ) => {
      const {
        heading,
        explanatoryText,
        counted,
        earned,
        required,
        requirementType,
        subRequirements
      } = requirement;

      const smallBreakpoint = useMediaQuery(ScreenSizeQueries.sm);

      const creditsCounted = useMemo(() => getCreditsCounted(requirement), [requirement]);

      const creditsNeeded = useMemo(
        () => Math.max((required ?? 0) - creditsCounted, 0),
        [required, creditsCounted]
      );

      const completedRequirements = useMemo(() => creditsNeeded === 0, [creditsNeeded]);

      const isOptionalCredit = useMemo(
        () => requirementType === RequirementType._1,
        [requirementType]
      );

      const subSectionClasses = classnames(
        'flex w-full flex-col flex-wrap justify-end md:flex-row md:items-center',
        {
          'justify-between': subRequirements && subRequirements.length
        }
      );

      const onEarnMoreOpen = useCallback(
        (flag: boolean) => {
          if (earnMoreSettings?.onOpenChange) earnMoreSettings.onOpenChange(flag);
        },
        [earnMoreSettings]
      );

      const filteredSubRequirements = useMemo(
        () => filterHiddenRequirements(subRequirements ?? []),
        [subRequirements]
      );

      const containerClassNames = classnames(
        'mb-6 flex w-full scroll-my-32 flex-col rounded-xl border-gray-light bg-gray-lightest p-6 sm:p-10',
        {
          'opacity-50 transition-opacity': isBlurred
        }
      );

      const tagClasses = classnames('flex flex-row flex-nowrap items-center', {
        'mb-5': !smallBreakpoint
      });

      const renderTag = useMemo(() => {
        return (
          tagText && (
            <div className={tagClasses}>
              <Tag variant="dark" size="small">
                {tagText}
              </Tag>
            </div>
          )
        );
      }, [tagClasses, tagText]);

      const bucketFraction = useMemo(() => {
        if (isOptionalCredit) return `${earned} earned`;

        const numerator =
          (counted ?? 0) >= (required ?? 0) && (earned ?? 0) > (counted ?? 0)
            ? `${earned} earned`
            : `${creditsCounted} counted`;

        if ((required ?? 0) <= 0) return numerator;

        return `${numerator} / ${required} required`;
      }, [creditsCounted, isOptionalCredit, counted, earned, required]);

      const subReqsClasses = classnames('mt-8 flex flex-row flex-wrap', {
        'items-end justify-between md:flex-nowrap': !!filteredSubRequirements?.length,
        'items-center justify-end': !filteredSubRequirements?.length
      });

      const isCreditRequired = useMemo(
        () => !isOptionalCredit && !completedRequirements,
        [completedRequirements, isOptionalCredit]
      );
      const isWarning = useMemo(
        () => isCreditRequired && isDeadlineApproaching,
        [isCreditRequired, isDeadlineApproaching]
      );

      const creditsNeededClasses = classnames(
        'order-first mb-4 flex min-w-fit items-center align-bottom sm:order-last sm:mb-0',
        {
          'text-serpentine': completedRequirements && !isOptionalCredit,
          'text-orange': isWarning,
          'text-gray-dark': !completedRequirements && !isWarning
        }
      );

      return (
        <div id={formatCreditTypeId(heading ?? '')} className={containerClassNames} ref={ref}       data-component={"CreditTypeCard"}>
          {!smallBreakpoint && renderTag}
          <div className="mb-2 flex flex-col justify-between sm:flex-row">
            <Text
              element="h5"
              propertyName="Heading"
              className="heading-5 break-words md:max-w-5xl"
            >
              {heading?.trim()}
              <div className="inline-block pl-2 align-top">{smallBreakpoint && renderTag}</div>
            </Text>
            <Text element="span" propertyName="Span" className={creditsNeededClasses}>
              {completedRequirements && !isOptionalCredit
                ? 'Complete'
                : isOptionalCredit
                  ? ''
                  : `${roundCredits(creditsNeeded)} credit${creditsNeeded === 1 ? '' : 's'} needed`}
              {!isOptionalCredit && (
                <Icon
                  name={
                    completedRequirements
                      ? 'approved'
                      : isWarning
                        ? 'pending-orange'
                        : 'pending-grayscale'
                  }
                  size="medium"
                  className="ml-2 flex sm:inline-flex"
                />
              )}
            </Text>
          </div>
          <Text
            element="h5"
            propertyName="SubHeading"
            className="heading-5 flex w-full text-gray-dark"
          >
            {bucketFraction}
          </Text>
          <Text propertyName="explanatoryText" element="h6" className="w-3/4 pt-4 text-gray-dark">
            {isWarning && (
              <Tag variant="warning" size="small" className="mr-2">
                Required
              </Tag>
            )}
            <RichText
              className="inline"
              content={translateToTailwinds(explanatoryText ?? '')}
            ></RichText>
          </Text>
          {isOptionalCredit && (
            <Text
              element="p"
              propertyName="optionalHeading"
              className="mb-4 text-gray-dark md:mb-0"
            >
              Not required, but counts toward your total.
            </Text>
          )}
          <div className={subReqsClasses}>
            {!!filteredSubRequirements?.length && (
              <div className={subSectionClasses}>
                {filteredSubRequirements?.map((subReq: ICreditRequirement, i: number) => {
                  const requiredCredit =
                    subReq.requirementType === RequirementType._2 && (subReq?.required ?? 0) > 0;
                  const creditsCounted = getCreditsCounted(subReq);
                  const smallBucketClass = classnames('basis-full md:basis-1/2', {
                    'md:mt-10': (filteredSubRequirements?.length ?? 0) > 1 && i > 1,
                    'mt-6 md:mt-0': (filteredSubRequirements?.length ?? 0) > 1 && i > 0
                  });
                  return (
                    <div className={smallBucketClass} key={i}>
                      <Text element="p" propertyName="subReqHeading" className="text-1-medium mb-2">
                        {subReq.heading ?? ''}
                      </Text>
                      <Text
                        element="span"
                        propertyName="subReqHeading"
                        className="text-1-medium flex items-center text-gray-dark"
                      >
                        {`${creditsCounted} earned ${
                          requiredCredit ? '/ ' + subReq.required + ' required' : ''
                        }`}
                        {(subReq.explanatoryText ||
                          subReq.maxInfoMessage ||
                          subReq.minEarnedSubRequirementsMessage) && (
                          <ComplexTooltip>
                            <ComplexTooltip.Trigger
                              className="ml-2 inline-flex items-center justify-center hover:text-gray-darker focus:text-gray-darker"
                              aria-label={`learn more about ${subReq.heading}`}
                            >
                              <Icon name="info" size="medium" className="flex sm:inline-flex" />
                            </ComplexTooltip.Trigger>
                            <ComplexTooltip.Content
                              side="top"
                              sideOffset={2}
                              alignOffset={-75}
                              className="flex gap-4"
                            >
                              {subReq.explanatoryText && (
                                <RichText content={subReq.explanatoryText} className="text-2" />
                              )}
                              {subReq.maxInfoMessage && (
                                <RichText content={subReq.maxInfoMessage} className="text-2" />
                              )}
                              {subReq.minEarnedSubRequirementsMessage && (
                                <RichText
                                  content={subReq.minEarnedSubRequirementsMessage}
                                  className="text-2"
                                />
                              )}
                            </ComplexTooltip.Content>
                          </ComplexTooltip>
                        )}
                        {subReq.maxInfoMessage && (
                          <Tag variant="warning" size="small" className="ml-2">
                            <span className="label">Maximum</span>
                          </Tag>
                        )}
                      </Text>
                    </div>
                  );
                })}
              </div>
            )}
            {showEarnMore && earnMoreSettings && (
              <EarnMoreCreditPanel {...earnMoreSettings} onOpenChange={onEarnMoreOpen} />
            )}
          </div>
        </div>
      );
    }
  )
);

export default CreditTypeCard;
