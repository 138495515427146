import parse from 'html-react-parser';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';

export interface BaseBoxFooterProps {
  primaryButton?: React.ReactNode;
  secondaryButton?: React.ReactNode;
  tertiaryButton?: React.ReactNode;
  className?: string;
  noSelectionError?: string | boolean;
  discountAdviceMessage?: string | boolean;
  extraMessage?: string;
  waitlistMessage?: string | boolean;
  codeLabel?: string;
  codeValue?: string;
  lowerContainer?: React.ReactNode;
  withinPanel?: boolean;
  hideGradient?: boolean;
  hasHorizontalPadding?: boolean;
}

const Footer: React.FC<BaseBoxFooterProps> = ({
  primaryButton,
  secondaryButton,
  tertiaryButton,
  noSelectionError,
  discountAdviceMessage: privilegeMembersMessage,
  extraMessage,
  waitlistMessage,
  codeLabel,
  codeValue,
  lowerContainer,
  className,
  withinPanel = false,
  hideGradient = false,
  hasHorizontalPadding = true
}) => {
  return (
    <div
      className={classnames(
        'container relative z-1 shrink-0 pb-6',
        { 'lg:px-10': hasHorizontalPadding },
        { 'px-0': !hasHorizontalPadding },
        { 'mt-auto': withinPanel, grow: !withinPanel },
        className
      )}
      data-component={'Footer'}
    >
      {hideGradient ? null : (
        <span
          className={classnames('absolute inset-0 -z-1 bg-white-overflow-gradient', {
            '-top-6': !withinPanel,
            '-top-12': withinPanel
          })}
        ></span>
      )}

      {typeof waitlistMessage === 'string' && (
        <p className="text-2 mb-10 h-10 text-black">{parse(waitlistMessage)}</p>
      )}

      {primaryButton}

      {secondaryButton}

      {tertiaryButton}

      {noSelectionError && (
        <div className="mt-4 flex items-center justify-center gap-1 text-red">
          <Icon name="info" size="small" />
          <p className="label">{noSelectionError}</p>
        </div>
      )}

      <div className="mt-4">
        {typeof privilegeMembersMessage === 'string' && !waitlistMessage && (
          <p className="label flex h-10 items-center justify-center gap-1 text-gray-dark">
            <Icon name="check" size="small" />
            {privilegeMembersMessage}
          </p>
        )}

        {typeof extraMessage === 'string' && !waitlistMessage && (
          <p className="label my-3 flex h-10 items-center justify-center gap-1 text-center text-gray-dark">
            {extraMessage}
          </p>
        )}

        <p className="label flex h-10 items-center justify-center gap-1 text-gray-dark">
          {codeLabel}
          {codeLabel && codeValue ? ':' : ''} {codeValue}
        </p>

        {lowerContainer}
      </div>
    </div>
  );
};

export default Footer;
