import { OnDemandOhbWebVariation as OnDemandOhbWebVariationProps } from '@/@types/content';
import withNav from '@/components/hocs/withNav';
import OnDemandWebVariationProgramWrapper from '@/components/ui/PDP/OnDemandWebVariationProgramWrapper';

const OnDemandOhbWebVariationComponent = (variant: OnDemandOhbWebVariationProps) => {
  return <OnDemandWebVariationProgramWrapper variant={variant} />;
};

const OnDemandOhbWebVariation = withNav(OnDemandOhbWebVariationComponent);
export default OnDemandOhbWebVariation;
