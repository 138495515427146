import { CertificateFilterGroupResult, CreditJurisdiction } from '@/@types/client-api';
import { FilterProps } from '../../Filter/types';
import { DATE_FACETS, FilterTypes } from '../../SearchResults/constants';
import {
  CERTIFICATES_FILTERS,
  CERTIFICATES_FILTERS_LABEL,
  CERTIFICATES_PAGE_SIZE,
  CERTIFICATES_SORT_DEFAULT,
  CERTIFICATES_SOURCE_LABEL,
  CUSTOM_YEAR_LABEL,
  CUSTOM_YEAR_VALUE
} from './constants';
import { FiltersApplied, OnFilterChange } from './types';
import {
  getFilterItems,
  getJurisdictionLabel,
  getMinMaxDate,
  hasDateRange,
  isCustomYear,
  isDateRangeFilterGroup,
  isFilterItemSelected,
  isJurisdictionsFilterGroup,
  isSourceFilterGroup,
  isYearFilterGroup,
  parseDateRange,
  sortByLabel,
  sortByLabelDesc
} from './utils';
import {
  onDateRangeFilterChange,
  onJurisdictionFilterChange,
  onSourceFilterChange,
  onYearFilterChange
} from './actions';
import { trackClearAllFiltersEvent } from '@/analytics/certificates';
import { FilterContext } from '@/analytics/constants';

export const initialFilters: FiltersApplied = {
  pageNumber: 1,
  pageSize: CERTIFICATES_PAGE_SIZE,
  sort: CERTIFICATES_SORT_DEFAULT
};

export const prepareFiltersToSend = (filtersApplied: FiltersApplied): FiltersApplied => {
  const customYear = isCustomYear(filtersApplied);
  return {
    ...filtersApplied,
    year: customYear ? undefined : filtersApplied.year,
    dateRange: customYear && hasDateRange(filtersApplied) ? filtersApplied.dateRange : undefined
  };
};

export const getCertificateFilters = (
  filters: CertificateFilterGroupResult[],
  filtersApplied: FiltersApplied,
  onFilterChange: OnFilterChange,
  creditJurisdictions: CreditJurisdiction[]
): FilterProps[] => {
  const result: FilterProps[] = [];
  //jurisdiction
  const jurisdictionFilter = filters.find(isJurisdictionsFilterGroup);
  if (jurisdictionFilter) {
    result.push(
      getJurisdictionsFilter(
        jurisdictionFilter,
        filtersApplied,
        onFilterChange,
        creditJurisdictions
      )
    );
  }
  //date range
  const yearFilter = filters.find(isYearFilterGroup);
  const dateRangeFilter = filters.find(isDateRangeFilterGroup);
  if (yearFilter && dateRangeFilter) {
    result.push(getDateRangeFilter(yearFilter, dateRangeFilter, filtersApplied, onFilterChange));
  }
  //source
  const sourceFilter = filters.find(isSourceFilterGroup);
  if (sourceFilter) {
    result.push(getSourceFilter(sourceFilter, filtersApplied, onFilterChange));
  }
  return result;
};

const getJurisdictionsFilter = (
  filter: CertificateFilterGroupResult,
  filtersApplied: FiltersApplied,
  onFilterChange: OnFilterChange,
  creditJurisdictions: CreditJurisdiction[]
): FilterProps => ({
  title: CERTIFICATES_FILTERS_LABEL.JURISDICTIONS,
  items: getFilterItems(filter)
    .map(item => ({
      label: getJurisdictionLabel(item.name, creditJurisdictions),
      value: item.name,
      checked: isFilterItemSelected(item, filtersApplied.jurisdictions),
      disabled: !item.isAvailable
    }))
    .sort(sortByLabel),
  groupTypeId: CERTIFICATES_FILTERS.JURISDICTIONS,
  type: FilterTypes.CHECK,
  onValueChange: (value, checked) =>
    onJurisdictionFilterChange(FilterContext.AllFiltersPanel, onFilterChange, value, checked)
});

const getSourceFilter = (
  filter: CertificateFilterGroupResult,
  filtersApplied: FiltersApplied,
  onFilterChange: OnFilterChange
): FilterProps => ({
  title: CERTIFICATES_FILTERS_LABEL.SOURCE,
  items: getFilterItems(filter)
    .map(item => ({
      label:
        CERTIFICATES_SOURCE_LABEL[item.name as keyof typeof CERTIFICATES_SOURCE_LABEL] ?? item.name,
      value: item.name,
      checked: isFilterItemSelected(item, filtersApplied.source),
      disabled: !item.isAvailable
    }))
    .sort(sortByLabel),
  groupTypeId: CERTIFICATES_FILTERS.SOURCE,
  type: FilterTypes.SELECT,
  onValueChange: (value, checked) =>
    onSourceFilterChange(FilterContext.AllFiltersPanel, onFilterChange, value, checked)
});

const getDateRangeFilter = (
  yearFilter: CertificateFilterGroupResult,
  dateRangeFilter: CertificateFilterGroupResult,
  filtersApplied: FiltersApplied,
  onFilterChange: OnFilterChange
): FilterProps => {
  const yearItems = getFilterItems(yearFilter)
    .map(item => ({
      label: item.name,
      value: item.name,
      disabled: !item.isAvailable
    }))
    .sort(sortByLabelDesc);

  yearItems.push({
    label: CUSTOM_YEAR_LABEL,
    value: CUSTOM_YEAR_VALUE.toString(),
    disabled: false
  });

  const filterProps: FilterProps = {
    title: CERTIFICATES_FILTERS_LABEL.YEAR,
    items: yearItems,
    groupTypeId: CERTIFICATES_FILTERS.YEAR,
    type: FilterTypes.YEAR_SELECT,
    canClear: true,
    onClear: () => {
      trackClearAllFiltersEvent({ facetGroup: CERTIFICATES_FILTERS.YEAR });
      onYearFilterChange(FilterContext.AllFiltersPanel, onFilterChange, '', false);
    },
    value: filtersApplied.year?.toString() ?? '',
    onValueChange: value =>
      onYearFilterChange(FilterContext.AllFiltersPanel, onFilterChange, value, true)
  };

  if (!isCustomYear(filtersApplied)) {
    return filterProps;
  }

  const dataRange = parseDateRange(filtersApplied);
  return {
    ...filterProps,
    dateRange: {
      items: [
        { label: DATE_FACETS.START_DATE, value: dataRange.startDate },
        { label: DATE_FACETS.END_DATE, value: dataRange.endDate }
      ],
      ...getMinMaxDate(dateRangeFilter),
      onValueChange: (value, name) =>
        onDateRangeFilterChange(FilterContext.AllFiltersPanel, onFilterChange, name, value)
    }
  };
};
