import { PersonalInfoAddressesBlock as PersonalInfoAddressesBlockProps } from '@/@types/content';
import AddressesSection from '@/components/ui/ProfileSections/AddressesSection';
import { IComponentProps } from '@/lib/ComponentFactory';

const PersonalInfoAddressesBlock: React.FC<
  PersonalInfoAddressesBlockProps & IComponentProps
> = props => {
  return <AddressesSection {...props} />;
};

export default PersonalInfoAddressesBlock;
