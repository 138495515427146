import { ClientAPI } from '@/redux/api';

const userSessionEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getWarmUpSearchUserCache: builder.query<void, boolean>({
      query: query => ({
        url: `/user/warmup?clean=${query}`,
        method: 'GET'
      }),
      onQueryStarted() {
        console.info('DIAG:Calling warm up ...');
      }
    }),
    getEndSession: builder.mutation<void, void>({
      query: () => ({
        url: `/user/endsession`,
        method: 'GET'
      })
    })
  })
});

export const { useGetWarmUpSearchUserCacheQuery, useGetEndSessionMutation } = userSessionEndpoints;

export default userSessionEndpoints;
