import React from 'react';
import type { IContent } from '@/@types/cms';
import { pageFactory } from './pages';
import { ErrorBoundary } from 'react-error-boundary';
import { componentFactory } from './components';

interface IComponentFactoryProps {
  components?: Array<IContent>;
  componentsProps?: Record<string, unknown>;
}

export interface IComponentProps extends IContent {
  [key: string]: unknown;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  index?: number;
}

export const Page: React.FC<IComponentProps> = React.memo(({ contentType, ...props }) => {
  const PageComponent = pageFactory(`${contentType?.slice(-1)[0]}`);
  if (!PageComponent) return null;
  return (
    <ErrorBoundary
      fallback={<p>Content Error, Please Try again - {contentType}</p>}
      onError={(error, info) => console.error(error, info)}
    >
      <PageComponent {...props} contentType={contentType} />
    </ErrorBoundary>
  );
});

export const Component: React.FC<IComponentProps> = React.memo(({ contentType, ...props }) => {
  const SingleComponent = componentFactory(`${contentType?.slice(-1)[0]}`);
  if (!SingleComponent) return null;
  return (
    <ErrorBoundary
      fallback={<p>Content Error, Please Try again - {contentType}</p>}
      onError={(error, info) => console.error(error, info)}
    >
      <SingleComponent {...props} />
    </ErrorBoundary>
  );
});

const ComponentFactory: React.FC<IComponentFactoryProps> = React.memo(
  ({ components, componentsProps }) => (
    <>
      {components?.map((component, index) => (
        <Component
          key={index}
          {...component}
          {...componentsProps}
          isFirstItem={index === 0}
          index={index}
          isLastItem={index === components.length - 1}
        />
      ))}
    </>
  )
);

export default ComponentFactory;
