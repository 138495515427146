import { UserLicense } from '@/@types/client-api';
import SelectAddToProfileModal, {
  SelectAddToProfileModalProps,
  SelectItem
} from './SelectAddToProfileModal';
import { useCallback, useMemo } from 'react';
import { getLicenseKey } from './utils/licenses';

export type SelectLicenseModalProps = Pick<
  SelectAddToProfileModalProps,
  'onBack' | 'onDismissButtonPress' | 'onClose'
> & {
  licenses: UserLicense[];
  onSave: (items: UserLicense[]) => void;
};

const SelectLicenseModal = ({
  licenses,
  onSave,
  onBack,
  onDismissButtonPress,
  onClose
}: SelectLicenseModalProps): JSX.Element => {
  const items = useMemo(
    (): SelectItem[] =>
      licenses.map(license => ({
        id: getLicenseKey(license),
        label: license.creditRegion ?? ''
      })),
    [licenses]
  );

  const handleOnSave = useCallback(
    (selectedItems: SelectItem[]) => {
      const selectedLicenses = licenses.filter(license => {
        const licenseKey = getLicenseKey(license);
        const isSelected = selectedItems.find(item => item.id === licenseKey);
        return isSelected;
      });
      onSave(selectedLicenses);
    },
    [licenses, onSave]
  );

  return (
    <SelectAddToProfileModal
      title="Do you want to add these licenses to your profile?"
      saveButtonLabel="Next"
      isMultiStep={true}
      items={items}
      onSave={handleOnSave}
      onBack={onBack}
      onDismissButtonPress={onDismissButtonPress}
      onClose={onClose}
    />
  );
};

export default SelectLicenseModal;
