import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import Divider from '../../Divider';
import Icon from '../../Icon';
import RichText from '@/components/cms/RichText';
import { ProgramSegmentsProps } from '.';
import { Link } from 'react-router-dom';
import SegmentThumbnail from './SegmentThumbnail';
import { useAddToCart } from '@/hooks/shared/useAddToCart';
import { useLaunch } from '@/hooks/shared/useLaunch';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Shimmer from '../../Shimmer';
import { AnalyticsContext } from '@/analytics/constants';
import IPDPProgramCreditLayoutBlock from '@/components/blocks/PDP/IPDPProgramCreditLayoutBlock';
import { LoggedOutLicenseStruct } from '@/hooks/PDP/useLoggedOutLicenses';
import { transformIfPLIUrl } from '@/utils/helpers';

export interface SegmentProps {
  index: number;
  code?: string; // only marked as '?' to match CMS generated models, but is always expected to be set
  url?: string;
  displayName?: string; // only marked as '?' to match CMS generated models, but is always expected to be set
  imageUrl: string;
  formattedSpeakers?: string;
  credits?: string;
  specialCredits?: string;
  segmentDescription?: string;
  runtime?: string;
  canLaunch?: boolean;
  launchUrl?: string;
  progressPct?: number;
  expanded: boolean;
  onToggle?: (isOpen: boolean) => void;
  labels: ProgramSegmentsProps['labels'];
  isCreditsLoading?: boolean;
  isExpiredFromSale?: boolean;
  context: AnalyticsContext;
  loggedOutLicense: LoggedOutLicenseStruct;
  creditTrackerCode?: string;
}

const Segment: React.FC<SegmentProps> = ({
  index,
  url,
  code,
  displayName,
  imageUrl,
  /*formattedSpeakers,*/
  credits,
  specialCredits,
  segmentDescription,
  runtime,
  canLaunch,
  launchUrl,
  progressPct,
  expanded,
  onToggle,
  labels,
  isCreditsLoading,
  isExpiredFromSale,
  context,
  loggedOutLicense,
  creditTrackerCode
}) => {
  const isMobile = useMediaQuery(ScreenSizeQueries.xsOnly);

  const [showCheck, setShowCheck] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const {
    addToCart,
    isSuccess: isCartSuccess,
    reset: cartReset,
    isLoading: isCartLoading,
    isError: isCartError
  } = useAddToCart(context, creditTrackerCode);

  const {
    launch,
    isLoading: isLaunchLoading,
    isSuccess: isLaunchSuccess,
    isError: isLaunchError
  } = useLaunch(context, creditTrackerCode);

  useEffect(() => {
    if (isCartSuccess || isLaunchSuccess) {
      setShowCheck(true);
      setShowLoading(false);
      setShowError(false);
      setTimeout(() => {
        setShowCheck(false);
        if (isCartSuccess) cartReset();
      }, 1000);
    }
    if (isCartLoading || isLaunchLoading) {
      setShowLoading(true);
    }

    if (isCartError || isLaunchError) {
      setShowError(true);
      setShowLoading(false);
    }
  }, [
    cartReset,
    isCartError,
    isCartLoading,
    isCartSuccess,
    isLaunchError,
    isLaunchLoading,
    isLaunchSuccess
  ]);

  const thumbnailMode = canLaunch ? 'launch' : 'cart';

  const handleClick = useCallback(() => {
    if (isExpiredFromSale) return;
    if (thumbnailMode === 'cart') addToCart(code, displayName);
    if (thumbnailMode === 'launch') launch(code, false, launchUrl);
  }, [addToCart, code, displayName, isExpiredFromSale, launch, launchUrl, thumbnailMode]);

  const segmentTitle = (
    <h3 className="heading-6-medium mb-4 transition-colors hover:text-red">{displayName ?? ''}</h3>
  );

  const segmentImageWidth = 302;
  const segmentImageClass = `shrink-0 w-[123px] sm:w-[302px]`;
  const segmentThumbnail = (
    <div className={segmentImageClass}>
      <SegmentThumbnail
        displayName={displayName ?? ''}
        imageUrl={imageUrl}
        launchLabel={labels.launch}
        cartLabel={labels.cart}
        thumbnailMode={thumbnailMode}
        runtime={runtime}
        onClick={handleClick}
        progressPct={progressPct}
        isSuccess={showCheck}
        isError={showError}
        isLoading={showLoading}
        isExpiredFromSale={isExpiredFromSale}
      />
    </div>
  );

  const segmentDivRef = useRef<HTMLDivElement>(null);
  const screenSizeBelowMd = useMediaQuery(ScreenSizeQueries.belowMd);
  const screenSizeXsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);
  const [creditContainerWidthRatio, setCreditContainerWidthRatio] = useState<number>();

  const calculateSegmentCreditContainerWidth = useCallback(() => {
    const segmentContainerWidth = segmentDivRef?.current?.getBoundingClientRect()?.width ?? 0;
    const offset = screenSizeBelowMd ? 20 : 52;
    if (screenSizeXsOnly) setCreditContainerWidthRatio(100);
    else
      setCreditContainerWidthRatio(
        Math.round(
          (1 +
            (segmentImageWidth + offset) / (segmentContainerWidth - segmentImageWidth - offset)) *
            100
        )
      );
  }, [screenSizeBelowMd, screenSizeXsOnly]);

  useEffect(() => {
    calculateSegmentCreditContainerWidth();
  }, [calculateSegmentCreditContainerWidth]);

  useEffect(() => {
    window.addEventListener('resize', calculateSegmentCreditContainerWidth);
    return () => window.removeEventListener('resize', calculateSegmentCreditContainerWidth);
  }, [calculateSegmentCreditContainerWidth, screenSizeBelowMd, screenSizeXsOnly]);

  const onCollapsibleStateChange = (open: boolean) => {
    onToggle?.(open);
    if (segmentDivRef?.current) {
      // Must scroll element into view with offset, because sticky Jumplinks & Header obstructs top of page content on scroll-up
      const y = segmentDivRef.current.getBoundingClientRect().top + window.scrollY - 250;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const urlWithParams = useMemo(() => {
    const urlBuilder = new URL(url ?? '/');
    if (creditTrackerCode) {
      urlBuilder.searchParams.append('creditTrackerCode', creditTrackerCode);
    }
    return transformIfPLIUrl(urlBuilder.toString());
  }, [creditTrackerCode, url]);

  return (
    <>
      <div ref={segmentDivRef} data-component={'Segment'}>
        <div className="flex items-start gap-[9px] md:gap-10 lg:gap-[46px]">
          <div className="flex grow">
            <span className="text-2 w-[27px] shrink-0 font-medium text-gray">{index}.</span>
            <div>
              {url ? <Link to={urlWithParams!}>{segmentTitle}</Link> : <>{segmentTitle}</>}
              {/*SIREHOME-4933*/}
              {/*<p className="text-2 text-gray">{formattedSpeakers}</p>*/}
              {isCreditsLoading ? (
                <Shimmer className="mt-[10px] h-4 w-32" />
              ) : (
                <p className="text-2 mt-[10px] flex flex-row gap-2">
                  <span>{credits}</span>
                  {credits && specialCredits && <span> &#xB7; </span>}
                  {specialCredits && <span>{specialCredits}</span>}
                  {(credits || specialCredits) && isExpiredFromSale && <span> &#xB7; </span>}
                  {isExpiredFromSale && <span className="text-red">Content has expired</span>}
                </p>
              )}

              {!isMobile && (
                <Collapsible.Root
                  className="mt-6"
                  open={expanded}
                  onOpenChange={onCollapsibleStateChange}
                >
                  <Collapsible.Content className="data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown data-[state=closed]:opacity-0 ">
                    <RichText content={segmentDescription ?? ''} className="text-2 pb-8" />
                    <IPDPProgramCreditLayoutBlock
                      variant={{ code: code }}
                      loggedOutLicense={loggedOutLicense}
                      isSegmentCreditDetail={true}
                      width={creditContainerWidthRatio}
                      context={context}
                    />
                  </Collapsible.Content>
                  <Collapsible.Trigger className="text-2 flex items-center font-bold transition-colors hover:text-red">
                    {expanded ? labels.showLess : labels.showMore}
                    {expanded ? (
                      <Icon name="chevron-up" size="small" className="md:!size-5" />
                    ) : (
                      <Icon name="chevron-down" size="small" className="md:!size-5" />
                    )}
                  </Collapsible.Trigger>
                </Collapsible.Root>
              )}
            </div>
          </div>
          {segmentThumbnail}
        </div>
        {isMobile && (
          <Collapsible.Root
            className="ml-[27px] mt-6"
            open={expanded}
            onOpenChange={onCollapsibleStateChange}
          >
            <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown">
              <RichText content={segmentDescription ?? ''} className="text-2 pb-6" />
              <IPDPProgramCreditLayoutBlock
                variant={{ code: code }}
                loggedOutLicense={loggedOutLicense}
                isSegmentCreditDetail={true}
                width={creditContainerWidthRatio}
                context={context}
              />
            </Collapsible.Content>
            <Collapsible.Trigger className="text-2 flex items-center font-bold">
              {expanded ? labels.showLess : labels.showMore}
              {expanded ? (
                <Icon name="chevron-up" size="small" />
              ) : (
                <Icon name="chevron-down" size="small" />
              )}
            </Collapsible.Trigger>
          </Collapsible.Root>
        )}
      </div>
      <Divider color="light" className="my-8" />
    </>
  );
};

export default Segment;
