import React from 'react';

export interface DateObject {
  month: string;
  day: string;
}

export interface DateRange {
  startDate: DateObject;
  endDate: DateObject;
}

interface MultipleDates {
  multipleDates: (DateObject | DateRange)[];
}

export type DateType = DateObject | DateRange | MultipleDates;

const Constants = {
  maxDisplayDates: 1
};

const DateTag: React.FC<{ dates: DateType; isLargeTag?: boolean | false }> = ({
  dates,
  isLargeTag
}) => {
  const renderSingleDate = (date: DateObject) => (
    <p>
      <span className={`block ${isLargeTag && 'text-2-fixed-l'}`}>{date.month}</span>
      <span className={`${isLargeTag && 'text-3-fixed-l'}`}>{date.day}</span>
    </p>
  );

  const renderDateRange = (range: DateRange) => (
    <>
      {range.startDate.month} {range.startDate.day} <br />- {range.endDate.month}{' '}
      {range.endDate.day}
    </>
  );

  const renderMultipleDates = (multipleDatesArg: MultipleDates) => {
    const { multipleDates } = multipleDatesArg;

    const firstDate = multipleDates.slice(0, Constants.maxDisplayDates);
    return (
      <>
        {firstDate.map((date, index) => {
          if ('month' in date && 'day' in date) {
            return (
              <p key={index}>
                {date.month} {date.day}
              </p>
            );
          }
          if ('startDate' in date && 'endDate' in date) {
            return (
              <p key={index}>
                {date.startDate.month} {date.startDate.day}
                <br />- {date.endDate.month} {date.endDate.day}
              </p>
            );
          }
        })}
        {multipleDates.length > Constants.maxDisplayDates && (
          <p className="text-white/60">+{multipleDates.length - Constants.maxDisplayDates} more</p>
        )}
      </>
    );
  };

  let content = null;

  if ('month' in dates) {
    content = renderSingleDate(dates);
  } else if ('startDate' in dates && 'endDate' in dates) {
    content = renderDateRange(dates);
  } else if ('multipleDates' in dates) {
    content = renderMultipleDates(dates);
  }

  return (
    <div
      className={`text-2-fixed-medium flex size-full min-h-[72px] min-w-[72px] flex-col items-center justify-center bg-black/30 text-center text-white backdrop-blur ${isLargeTag ? 'rounded-lg' : 'rounded'}`}
    >
      {content}
    </div>
  );
};

export default DateTag;
