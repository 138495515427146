import { launchNotifyMePopup } from '@/utils/helpers';
import Button from '../../Buttons/Button';
import BaseBox from './components/BaseBox';
import { NotifyPanelItems } from '@/redux/slices/pdpSlice';

export interface NotifyPanelProps {
  notifyItems?: NotifyPanelItems;
  isMobile?: boolean;
}

export const NotifyPanel = ({ notifyItems, isMobile = false }: NotifyPanelProps) => {
  return (
    <BaseBox sticky={!isMobile}>
      <BaseBox.Content>
        <div className="flex w-full flex-col gap-12 pt-6">
          <div className="flex flex-col gap-6">
            <h2 className="heading-6-medium">{notifyItems?.headerLabel}</h2>
            {
              <p
                className="text-1 text-charcoal"
                dangerouslySetInnerHTML={{ __html: notifyItems?.messageLabel ?? '' }}
              ></p>
            }
          </div>
          <div className="flex w-full flex-col items-center gap-4 ">
            <Button
              color="red"
              size="large"
              label={notifyItems?.buttonLabel}
              onClick={() => notifyItems?.url && launchNotifyMePopup(notifyItems?.url)}
              className="w-full !justify-center"
            />
          </div>
        </div>
      </BaseBox.Content>
    </BaseBox>
  );
};

export default NotifyPanel;
