import { ComponentProps, ReactNode, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import Button from '@/components/ui/Buttons/Button';
import {
  SnippetButtonProps,
  SnippetPrimaryStatus,
  SnippetSecondaryStatus,
  SnippetVariants,
  SnippetActions
} from './Snippet';
import DropdownMenu, { TDropdownMenuItem } from '../DropdownMenu';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Shimmer from '../Shimmer';
import { SearchModalPanelState } from '../SearchResults/hooks/useSearchModalPanelState';
import { SnippetButtonLabel } from '../SearchResults/Snippets/buttonsLabels';
import SnippetButton from './SnippetButton';
import { SnippetContext } from '../SearchResults/Snippets/types';

interface SnippetButtonsProps {
  primaryStatus?: SnippetPrimaryStatus | null;
  secondaryStatus?: SnippetSecondaryStatus | null;
  context?: SnippetContext;
  link?: string;
  variant?: SnippetVariants;
  videoProgressValue?: number;
  menuItems?: ComponentProps<typeof DropdownMenu>['items'];
  buttons?: SnippetButtonProps[];
  moreContentButton?: ReactNode;
  loading?: boolean;
  actions?: SnippetActions;
  modalPanelState?: SearchModalPanelState;
}

const sortPrimaryAndSecondaryButtons = (
  buttonA: SnippetButtonProps,
  buttonB: SnippetButtonProps
) => {
  if (buttonB.color === 'outline-black' && buttonA.color !== 'outline-black') {
    return -1;
  } else if (buttonA.color === 'outline-black' && buttonB.color !== 'outline-black') {
    return 1;
  }
  return 0;
};

const SnippetButtons = ({
  buttons,
  menuItems = [],
  moreContentButton,
  loading,
  modalPanelState
}: SnippetButtonsProps) => {
  const belowMd = useMediaQuery(ScreenSizeQueries.belowMd);
  const isAboveLg = useMediaQuery(ScreenSizeQueries.lg);
  const remainingButtons: SnippetButtonProps[] = [];
  const directButtons: SnippetButtonProps[] = [];
  const moreOptionsButtonRef = useRef<HTMLButtonElement>(null);
  const lastOptionTriggered = useRef<string | undefined>(undefined);

  const isEcommPanelOpen = modalPanelState?.ecommPanel.isOpen ?? false;
  const isMaterialsModalOpen = modalPanelState?.materialsModal.isOpen ?? false;
  const isAddToCalendarOpen = modalPanelState?.addToCalendarModal.isOpen ?? false;
  const isDeleteModalOpen = modalPanelState?.deleteModal.isOpen ?? false;

  useEffect(() => {
    if (
      !isEcommPanelOpen &&
      moreOptionsButtonRef.current &&
      lastOptionTriggered.current === SnippetButtonLabel.changeOrCancelRegistration
    ) {
      lastOptionTriggered.current = undefined;
      moreOptionsButtonRef.current.focus();
    }
  }, [isEcommPanelOpen]);

  useEffect(() => {
    if (
      !isMaterialsModalOpen &&
      moreOptionsButtonRef.current &&
      lastOptionTriggered.current === SnippetButtonLabel.accessMaterials
    ) {
      lastOptionTriggered.current = undefined;
      moreOptionsButtonRef.current.focus();
    }
  }, [isMaterialsModalOpen]);

  useEffect(() => {
    if (
      !isAddToCalendarOpen &&
      moreOptionsButtonRef.current &&
      lastOptionTriggered.current === SnippetButtonLabel.addToCalendar
    ) {
      lastOptionTriggered.current = undefined;
      moreOptionsButtonRef.current.focus();
    }
  }, [isAddToCalendarOpen]);

  useEffect(() => {
    if (
      !isDeleteModalOpen &&
      moreOptionsButtonRef.current &&
      lastOptionTriggered.current === SnippetButtonLabel.removeFromLibrary
    ) {
      lastOptionTriggered.current = undefined;
      moreOptionsButtonRef.current.focus();
    }
  }, [isDeleteModalOpen]);

  const maxDirectButtonsQnt = isAboveLg ? 3 : moreContentButton ? 3 : 2;

  if (buttons) {
    buttons?.forEach(buttonProps => {
      directButtons.push(buttonProps);
    });
  }

  directButtons.sort(sortPrimaryAndSecondaryButtons);

  // Filter out remaining buttons to fit only 3 direct buttons and render remaining in the dropdown
  const maxDirectButtons = directButtons.slice(0, maxDirectButtonsQnt);

  const remainingDirectButtons = directButtons.slice(maxDirectButtonsQnt);
  remainingButtons.push(...remainingDirectButtons);

  const shouldUseDropdownMenu = (directButtons: SnippetButtonProps[]) => {
    return directButtons.length >= maxDirectButtonsQnt && remainingDirectButtons.length;
  };

  const DropDownMenu = () => {
    if (shouldUseDropdownMenu(directButtons) || menuItems?.length) {
      const remainingButtonsToItems = remainingButtons?.map(({ iconRight, href, ...props }) =>
        href
          ? {
              icon: iconRight,
              href,
              to: href,
              Element: Link,
              ...props
            }
          : {
              icon: iconRight,
              ...props
            }
      );
      const mapMenuItems: TDropdownMenuItem[] = menuItems.map(menuItem => {
        if ('href' in menuItem && typeof menuItem.href === 'string') {
          return {
            ...menuItem,
            to: menuItem.href as unknown as string,
            Element: Link,
            isHighlighted: undefined
          };
        }
        return menuItem;
      });
      const combinedButtons = [...remainingButtonsToItems, ...mapMenuItems].map(button => {
        if ('onClick' in button) {
          const onClick = button.onClick;
          button.onClick = () => {
            lastOptionTriggered.current =
              typeof button.label === 'string' ? button.label : undefined;
            onClick();
          };
        }
        return button;
      });

      return (
        <DropdownMenu
          button={() => (
            <Button
              ref={moreOptionsButtonRef}
              aria-label="More options"
              size="short"
              iconRight="more-ios"
              label={belowMd ? 'More actions' : undefined}
              color="outline-black"
              key="dropdownButton"
            />
          )}
          items={combinedButtons}
        />
      );
    }
    return null;
  };

  if (loading) {
    return Array.from({ length: 3 }).map((_, index) => (
      <Shimmer key={index} className="h-12 w-40 max-md:w-full" />
    ));
  }

  return (
    <>
      {maxDirectButtons?.map((buttonProps, i) => <SnippetButton {...buttonProps} key={i} />)}
      <DropDownMenu />
      {moreContentButton}
    </>
  );
};

export default SnippetButtons;
