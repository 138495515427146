import type { CustomPressReleaseListBlock as CustomPressReleaseListBlockProps } from '@/@types/content';
import Block from '@/components/cms/Block';
import Text from '@/components/cms/Text';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Fragment, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { IContent } from '@/@types/cms';
import Divider from '../ui/Divider';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

type IContentExtended = IContent & {
  created?: string;
  navigationTitle?: string;
  url?: string;
  pressReleaseDate?: string;
};

const CustomPressReleaseListBlock: React.FC<CustomPressReleaseListBlockProps> = ({
  heading,
  items,
  itemsToDisplay,
  contentLink
}) => {
  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

  const lg = useMediaQuery(ScreenSizeQueries.lg);

  const [expanded, setExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const toggleExpanded = useCallback(() => {
    setExpanded(state => !state);
  }, []);

  useEffect(() => {
    if (!items || !items.length) {
      return;
    }

    if (itemsToDisplay && itemsToDisplay < items.length) {
      setShowMore(true);
    }
  }, [items, itemsToDisplay]);

  const RenderItems: React.FC<{ items?: IContentExtended[]; inExpanded: boolean }> = ({
    items,
    inExpanded
  }) => {
    return (
      <div>
        {items?.map((item, index) => {
          const { created, navigationTitle, url, pressReleaseDate } = item;
          const originalDate = pressReleaseDate || created;

          const formattedDate = originalDate
            ? new Date(originalDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })
            : '';

          return (
            <Fragment key={index}>
              {(inExpanded || index !== 0) && <Divider color="light" className="bg-silver" />}

              <div
                className={classnames({
                  'pt-8': index !== 0 || inExpanded,
                  'pb-8': showMore || index !== items.length - 1
                })}
              >
                {formattedDate && (
                  <span className="label mb-1 block text-gray-dark">{formattedDate}</span>
                )}

                {navigationTitle && (
                  <Link
                    to={url || ''}
                    className={classnames('heading-6-medium transition', 'hover:text-red')}
                  >
                    {navigationTitle}
                  </Link>
                )}
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <Block contentLinkID={contentLink?.id} className="container module-spacing">
      <Text propertyName="Heading" element="h4" className="heading-4">
        {heading}
      </Text>

      {items && items.length > 0 && (
        <Collapsible.Root open={expanded} onOpenChange={setExpanded} className="mt-8">
          <RenderItems items={items.slice(0, itemsToDisplay)} inExpanded={false} />

          <Collapsible.Content
            className={classnames(
              'overflow-hidden',
              'data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown'
            )}
          >
            <RenderItems items={items.slice(itemsToDisplay)} inExpanded={true} />
          </Collapsible.Content>
        </Collapsible.Root>
      )}

      {showMore && (
        <button
          onClick={toggleExpanded}
          className="text-2 flex items-center gap-1 font-bold"
          aria-expanded={expanded}
        >
          {expanded ? stateSiteLabels?.seeLessLabel : stateSiteLabels?.seeMoreLabel}
          <Icon size={lg ? 'medium' : 'small'} name={expanded ? 'chevron-up' : 'chevron-down'} />
        </button>
      )}
    </Block>
  );
};

export default CustomPressReleaseListBlock;
