import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearch } from '@/redux/selectors/searchSelectors';
import { ContentTab } from '../ContentTabs';
import useSearch, { UseSearchInput } from './hooks/useSearch';
import {
  clearFilters,
  setQueryFilters,
  sort,
  toggleFilter,
  toggleQueryFilter
} from '@/redux/slices/searchSlice';
import type { ISearchResultItem } from '@/@types/client-api/models/ISearchResultItem';
import SearchResultsRow from './SearchResultsRow';
import { FILTERS, FilterTypes, QUERY_FILTER_GROUP, QUERY_PARAMS, QueryFilter } from './constants';
import searchableTypeToSnippetVariant from './searchableTypeToSnippetVariant';
import SearchResultsComponent from './SearchResultsComponent';
import useSearchFilterPanelGroups from './hooks/useSearchFilterPanelGroups';
import { useGetAllFiltersFromEmptySearch } from './hooks/useGetAllFiltersFromEmptySearch';
import { useInvariantFilters } from './hooks/useInvariantFilters';
import { FilterGroupProps } from '../Filter/types';
import { getRemainingResultsCount } from './utils/searchResponse';

import useDebounceLoading from '@/hooks/useDebounceLoading';
import { RecommendedSearchListBlock } from '@/@types/content';
import { getCombinedQueryFilters } from './utils/filters';
import { mergeURLSearchParams } from '@/utils/helpers';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { useGetContentByFriendlyUrlQuery } from '@/redux/api/contentDeliveryAPI';
import { skipToken } from '@reduxjs/toolkit/query';
import useSearchSpecialParams from './hooks/useSearchSpecialParams';
import { trackSortEvent } from '@/analytics/search';
import { AnalyticsContext, FilterContext, SearchContext } from '@/analytics/constants';
import { RootState } from '@/redux/store';
import { trackViewItemListEvent } from '@/analytics/ecommerce';
import { getUID } from '@/analytics/analyticsUtils';
import { ItemAnalyticsModel } from '@/@types/client-api';

export type SearchResultsProps = Pick<
  UseSearchInput,
  | 'invariantFilters'
  | 'facetFilters'
  | 'semanticFilters'
  | 'facetOverrides'
  | 'updateUrlParams'
  | 'itemClasses'
> & {
  displayStyle?: string;
  recommendedSearchList?: RecommendedSearchListBlock;
  useQueryWhenPresent?: boolean;
  showFilters?: boolean;
  showMarketingModule?: boolean;
  invariantFilterTooltipText?: string;
  initialSearchQuery?: string;
  queryFilters?: QueryFilter[];
  shouldPassUrlParams?: boolean;
  context?: AnalyticsContext;
  searchContext?: SearchContext;
  creditTrackerCode?: string;
  name?: string;
};

const keyFn = (input: ISearchResultItem) =>
  input.productCode ?? input.fullName ?? input.title ?? '';

const SearchResults = ({
  displayStyle,
  invariantFilters,
  facetFilters,
  recommendedSearchList: _recommendedSearchList,
  semanticFilters,
  useQueryWhenPresent,
  showFilters,
  showMarketingModule,
  initialSearchQuery,
  queryFilters,
  name,
  facetOverrides,
  updateUrlParams,
  invariantFilterTooltipText,
  shouldPassUrlParams,
  creditTrackerCode,
  context,
  searchContext
}: SearchResultsProps) => {
  const dispatch = useDispatch();

  // Kick off request to get all filters from empty search
  useGetAllFiltersFromEmptySearch({});
  const userId = useSelector((state: RootState) => state?.auth?.name || 'ANON');

  const {
    fetching,
    filtersToProps,
    quickFiltersToProps,
    tabsToProps,
    sortToProps,
    queryFilters: queryFiltersFromStore
  } = useSelector(selectSearch);

  const { specialParams } = useSearchSpecialParams();

  const activeQueryParams = getCombinedQueryFilters(queryFiltersFromStore ?? undefined);
  const mergedQueryParams = mergeURLSearchParams(
    shouldPassUrlParams ? specialParams : new URLSearchParams(initialSearchQuery),
    new URLSearchParams(activeQueryParams)
  );
  const queryFromCMSSettings = mergedQueryParams.get(QUERY_PARAMS.QUERY);

  useEffect(() => {
    dispatch(setQueryFilters((queryFilters ?? []).map(item => ({ ...item, checked: true }))));
  }, [dispatch, queryFilters]);

  const { onLoadMore, isLoadingMore, query, loading, data, resultsStatus } = useSearch({
    overrides: {
      ...(!useQueryWhenPresent && {
        query: queryFromCMSSettings || undefined
      }),
      ...(showMarketingModule && { returnMarketingModules: true })
    },
    invariantFilters,
    facetFilters,
    semanticFilters,
    postQuery: mergedQueryParams.toString(),
    facetOverrides,
    updateUrlParams,
    searchContext
  });

  const { invariantFiltersTabs } = useInvariantFilters({
    invariantFilters,
    tabsToProps,
    facetOverrides
  });

  const onTabChange = (tab: ContentTab) => {
    dispatch(
      toggleFilter({
        groupTypeId: FILTERS.CONTENT_TABS,
        value: tab.value,
        type: FilterTypes.TABS,
        name: tab.label,
        searchContext
      })
    );
  };

  const onSortChange = (value?: string) => {
    if (value) {
      trackSortEvent({ method: value, searchContext });
    }
    dispatch(sort(value || ''));
  };

  const handleValueChange = (
    groupTypeId: string,
    value: string,
    type: FilterTypes,
    name: string,
    context: FilterContext
  ) => {
    if (groupTypeId === QUERY_FILTER_GROUP) {
      dispatch(toggleQueryFilter({ query: value }));
      return;
    }
    dispatch(toggleFilter({ groupTypeId, value, type, name, context, searchContext }));
  };

  const handleValueChangeQuickFilter = (
    groupTypeId: string,
    value: string,
    type: FilterTypes,
    name: string
  ) => handleValueChange(groupTypeId, value, type, name, FilterContext.QuickFilter);

  const resultCount = data?.searchResultSummary?.noOfHits;
  const quickFilterLabel = useQueryWhenPresent
    ? resultCount
      ? 'Quick filters'
      : 'Selected filters'
    : undefined;

  const { filters } = useSearchFilterPanelGroups({
    filters: filtersToProps,
    // TODO This could be further optimized - we don't need to make this request until the all filters panel is open
    skip: !resultCount
  });

  const filtersChangingMapping = filters?.map(group => ({
    ...group,
    canClear: group.groupTypeId === FILTERS.LOCATION,
    onValueChange: (value?: string, name?: string) => {
      handleValueChange(
        group.groupTypeId,
        value || '',
        group.type as FilterTypes,
        name || group.items.find(item => item.value === value)?.label || '',
        FilterContext.AllFiltersPanel
      );
    },
    onClear: () =>
      dispatch(
        clearFilters({
          groupId: group.groupTypeId,
          context: FilterContext.AllFiltersPanel,
          searchContext
        })
      )
  })) as FilterGroupProps[];

  const remainingResultsCount = getRemainingResultsCount(data || undefined);

  const searchResultsItems = useMemo(() => data?.searchResults?.searchResultsItems ?? [], [data]);

  const items = useMemo(() => {
    return (searchResultsItems || []).filter(
      (resultItem: ISearchResultItem) =>
        !!searchableTypeToSnippetVariant(resultItem.searchableType ?? '')
    );
  }, [searchResultsItems]);

  const clearFiltersAction = (payload: { groupId?: string } = {}) => {
    dispatch(clearFilters({ ...payload, context: FilterContext.AllFiltersPanel, searchContext }));
  };

  const debouncedFetching = useDebounceLoading(fetching);
  const debouncedLoadingMore = useDebounceLoading(isLoadingMore);

  const pageLinkPaths = useSelector(selectPageLinkPaths);

  let recommendedSearchList = _recommendedSearchList;
  const { data: searchPageData, isLoading: isSearchPageDataLoading } =
    useGetContentByFriendlyUrlQuery(
      recommendedSearchList
        ? skipToken
        : {
            friendlyUrl: pageLinkPaths.Search || '',
            userId
          }
    );
  if (!recommendedSearchList) {
    recommendedSearchList = searchPageData?.recommendedSearchList as RecommendedSearchListBlock;
  }

  // TODO: Investigate why this effect is running twice
  const prevItemsRef = useRef<string | null>(null);
  useEffect(() => {
    const currentItemsString = JSON.stringify(items);
    if (items && items?.length > 0 && prevItemsRef.current !== currentItemsString) {
      const title = name || 'Search Results';
      trackViewItemListEvent(
        `${getUID()}_${title}`.toLowerCase().replace(/\s+/g, '_'),
        `${title}`,
        items
          .map(x => x.itemAnalyticsModel)
          .filter((x): x is ItemAnalyticsModel => x !== undefined) || []
      );
      // Update reference to avoid unnecessary re-execution
      prevItemsRef.current = currentItemsString;
    }
  }, [items, name]);

  return (
    <SearchResultsComponent
      keyFn={keyFn}
      invariantFilterTooltipText={invariantFilterTooltipText}
      // This one isn't debounced because we need to know ASAP if the search is loading to avoid showing the empty state
      loading={loading || isSearchPageDataLoading}
      fetching={debouncedFetching}
      isLoadingMore={debouncedLoadingMore}
      quickFilterLabel={quickFilterLabel}
      items={items}
      headerQueryText={queryFromCMSSettings ? undefined : query}
      showFilters={showFilters}
      RowComponent={SearchResultsRow}
      tabs={invariantFilters?.length ? invariantFiltersTabs : tabsToProps.tabs}
      activeTab={tabsToProps.activeTab}
      onTabChange={onTabChange}
      sortOptions={sortToProps.options}
      sortValue={sortToProps.value}
      onSortChange={onSortChange}
      quickFilters={quickFiltersToProps}
      filters={filtersChangingMapping}
      marketingModules={data?.marketingModules}
      resultCount={resultCount}
      remainingResultsCount={remainingResultsCount}
      onLoadMore={onLoadMore}
      onClear={clearFiltersAction}
      onFilterToggle={handleValueChangeQuickFilter}
      context={context}
      searchContext={searchContext}
      displayStyle={displayStyle}
      recommendedSearchList={recommendedSearchList}
      emptyStateHeading={recommendedSearchList?.noResultsTitle}
      emptyStateSubHeading={
        resultsStatus === 'no-results-from-filters'
          ? recommendedSearchList?.noResultsSubheadTooManyFilters
          : recommendedSearchList?.noResultsSubhead
      }
      creditTrackerCode={creditTrackerCode}
    />
  );
};

export default SearchResults;
