import classnames from 'classnames';
import { Fragment, ReactNode } from 'react';

type DotSeparatedItem = string | ReactNode | undefined;

type DotSeparatedContentProps = {
  content: DotSeparatedItem[];
  marginX?: number;
};

const DotSeparator = ({ marginX = 4 }: { marginX?: number }): JSX.Element => (
  <span
    className={classnames('relative bottom-[1.5px] align-middle')}
    // Using an inline style here because tailwind config disables custom font sizes
    style={{ fontSize: 8, marginLeft: marginX, marginRight: marginX }}
    role="separator"
  >
    •
  </span>
);

const DotSeparatedContent = ({ content, marginX }: DotSeparatedContentProps): JSX.Element => {
  const items = content?.filter(item => item !== undefined && item !== null);
  return (
    <>
      {items.map((item, index) => (
        <Fragment key={index}>
          {item}
          {/* The leading space is non-breaking so dot will stay connected to last item */}
          {index < items.length - 1 && <>&nbsp;{<DotSeparator marginX={marginX} />} </>}
        </Fragment>
      ))}
    </>
  );
};

export default DotSeparatedContent;
