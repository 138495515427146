import {
  useCancelRegistrationMutation,
  useGetUpcomingRegistrationsQuery
} from '@/redux/api/client/registration';
import { useCallback } from 'react';
import { makeToastProblemMessage } from './util';
import libraryEndpoints from '@/redux/api/client/library';
import useToast from '../useToast';
import { useAuth } from 'react-oidc-context';
import { skipToken } from '@reduxjs/toolkit/query';
import { AnalyticsContext } from '@/analytics/constants';
import { useFetchAnalyticsItems } from '@/analytics/useFetchAnalyticsItems';
import { trackCancelReservationEvent } from '@/analytics/library';
import { useAppDispatch } from '..';
import { trackCardLaunchAProgramEvent } from '@/analytics/cards';
import { setOptimisticRegistrationDetailsUpdate } from '@/redux/slices/searchSlice';

export const useCancelRegistrations = (context?: AnalyticsContext) => {
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cancelRegistrationMutation, { isLoading, isSuccess, isError, reset, data }] =
    useCancelRegistrationMutation();
  const { showSuccessMessage, showFailMessage } = useToast();
  const { getAnalyticItem } = useFetchAnalyticsItems();

  const { refetch: regRefetch } = useGetUpcomingRegistrationsQuery(
    !auth.isAuthenticated ? skipToken : undefined
  );

  const trackEvent = useCallback(
    async (code: string, context: AnalyticsContext, isUpdate: boolean) => {
      const data = await getAnalyticItem(code);
      if (data) {
        trackCancelReservationEvent(data, context);
        if (isUpdate) trackCardLaunchAProgramEvent(data, context, isUpdate);
      }
    },
    [getAnalyticItem]
  );

  const cancelRegistration = useCallback(
    async (itemSk?: number, isUpdate: boolean = false, itemCode?: string) => {
      if (!itemSk) return;

      if (context && itemCode) {
        trackEvent(itemCode, context, isUpdate);
      }

      const regCancelFailedMessage = makeToastProblemMessage('register-cancel');
      const regUpdateFailedMessage = makeToastProblemMessage('register-update');
      const regCancelSuccessMessage = 'Registration canceled';
      const regResponse = await cancelRegistrationMutation(itemSk);
      if ('error' in regResponse) {
        showFailMessage(isUpdate ? regUpdateFailedMessage : regCancelFailedMessage);
      } else if ('data' in regResponse && !isUpdate) {
        showSuccessMessage(regCancelSuccessMessage);
        regRefetch();
        dispatch(
          libraryEndpoints.util.invalidateTags([
            { type: 'LibraryItem', id: itemCode },
            { type: 'Library', id: 'List' },
            { type: 'Search', id: 'Results' }
          ])
        );
        dispatch(
          setOptimisticRegistrationDetailsUpdate({
            pk: itemCode ?? '',
            isRegistered: false
          })
        );
      }
      return regResponse;
    },
    [
      context,
      cancelRegistrationMutation,
      trackEvent,
      showFailMessage,
      showSuccessMessage,
      regRefetch,
      dispatch
    ]
  );

  return {
    reset,
    cancelRegistration,
    isLoading,
    isSuccess,
    isError,
    data
  };
};
