import Text from '@/components/cms/Text';
import classnames from 'classnames';
import { BrandHeaderBlock as BrandHeaderBlockProps } from '@/@types/content';
import { setLogoColor } from '@/redux/slices/pageSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import ShareButton from '@/components/ui/Buttons/ShareButton';
import Block from '@/components/cms/Block';
import { Theme } from '@/styles/darkMode';

interface BlockProps extends BrandHeaderBlockProps {
  pageName?: string;
}

const BrandHeaderBlock: React.FC<BlockProps> = ({
  contentLink,
  pageTitle,
  pageName,
  backgroundTheme = 'red',
  disableShareLink
}) => {
  const componentTitle = pageTitle || pageName;

  const dispatch = useDispatch();
  const impactHeaderClasses = classnames(
    'container flex min-h-[144px] flex-col gap-8 pb-10 lg:flex-row lg:items-end lg:justify-between'
  );

  const backgroundThemeClasses = classnames(
    'mt-[-160px] size-full pt-[240px] lg:mt-[-96px] lg:pt-[176px]',
    {
      'bg-red-brand-header': backgroundTheme === 'red',
      'bg-black': backgroundTheme === 'black'
    }
  );

  useEffect(() => {
    if (backgroundTheme === 'red') {
      dispatch(setLogoColor('white'));
    } else {
      dispatch(setLogoColor('red'));
    }
  }, [backgroundTheme, dispatch]);

  return (
    <Block contentLinkID={contentLink?.id} className={backgroundThemeClasses}>
      <div className={impactHeaderClasses}  data-component={"BrandHeaderBlock"}>
        <Text
          element="h1"
          propertyName="PageTitle"
          className="heading-1 text-white sm:w-3/5 lg:w-[845px]"
        >
          {componentTitle}
        </Text>
        {!disableShareLink && (
          <ShareButton shareUrl={window.location.href} title={componentTitle} theme={Theme.Dark} />
        )}
      </div>
    </Block>
  );
};

export default BrandHeaderBlock;
