import { EcommPanelParams } from './hooks/useEcommData';
import Publication from '../PDP/ECommBox/Publication';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import {
  ABTreatiseCatalogRelations,
  getABTreatisePks,
  getCHBPks,
  PublicationCatalogRelations
} from '@/hooks/PDP/usePublicationCatalogRelations';
import { useEffect, useMemo } from 'react';
import {
  getPublicationVariants,
  mapToEcomVariant,
  sortPublicationVariants
} from '@/hooks/PDP/usePublicationVariantSelection';
import { useDispatch } from 'react-redux';
import {
  resetPDPContext,
  setCatalogRelations,
  setHardSelectedVariant
} from '@/redux/slices/pdpSlice';
import { IContent } from '@/@types/cms';
import { AnalyticsContext } from '@/analytics/constants';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import Loading from '../PDP/ECommBox/Publication/components/Loading';
import { useLibraryItems } from '@/hooks/PDP/useLibraryItems';

export type EcommPanelPublicationContentProps = {
  isLoadingContent: boolean;
  content?: IContent;
  catalogRelations: PublicationCatalogRelations;
  params: EcommPanelParams;
  onClose: () => void;
  context?: AnalyticsContext;
};

const EcommPanelPublicationContent = ({
  isLoadingContent,
  content,
  catalogRelations,
  params,
  onClose,
  context
}: EcommPanelPublicationContentProps) => {
  const dispatch = useDispatch();
  const isLarge = useMediaQuery(ScreenSizeQueries.lg);
  const { initialVariantSelector } = params;

  const pubPks = useMemo(
    () =>
      (catalogRelations as ABTreatiseCatalogRelations)?.printVariants
        ? getABTreatisePks(catalogRelations as ABTreatiseCatalogRelations)
        : getCHBPks(catalogRelations),
    [catalogRelations]
  );

  const { hydrateLibrary, selectLibraryItems, isLibraryItemsFetching } = useLibraryItems();

  useEffect(() => {
    hydrateLibrary(pubPks);
  }, [hydrateLibrary, pubPks]);

  const ecomLibraryItems = useMemo(() => selectLibraryItems(pubPks), [pubPks, selectLibraryItems]);
  const isLibraryFetching = isLibraryItemsFetching(pubPks);

  const variants = useMemo(() => {
    const publicationVariants = content
      ? getPublicationVariants(content, catalogRelations, ecomLibraryItems)
      : [];
    return {
      variants: publicationVariants
        .map(variant => mapToEcomVariant(variant, catalogRelations, ecomLibraryItems))
        .sort(sortPublicationVariants)
    };
  }, [catalogRelations, content, ecomLibraryItems]);

  const { publicationEcomLabels, isLoading: isLabelsLoading } = useCatalogLabels();

  const isLoading =
    isLoadingContent || catalogRelations.isLoading || isLibraryFetching || isLabelsLoading;

  useEffect(() => {
    dispatch(resetPDPContext());
    return () => {
      dispatch(resetPDPContext());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!content || isLoading) {
      return;
    }
    const initialSelection = variants.variants.find(
      initialVariantSelector ? initialVariantSelector : variant => variant.code === params.pk
    )!;
    dispatch(setHardSelectedVariant(initialSelection));
    dispatch(setCatalogRelations(variants));
  }, [content, dispatch, isLoading, params.pk, variants, initialVariantSelector]);

  if (isLoading) {
    return <Loading isMobile={false} />;
  }

  if (!content) {
    return null;
  }

  return (
    <Publication
      labels={publicationEcomLabels}
      isMobile={!isLarge}
      onClosePanel={onClose}
      isPlusSubscriber={catalogRelations.isPlusSubscriber}
      context={context}
      creditTrackerCode={params.creditTrackerCode}
    />
  );
};

export default EcommPanelPublicationContent;
