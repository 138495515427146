import { SearchRequest, SearchResponse } from '@/@types/client-api';
import { useGetSearchResultsQuery } from '@/redux/api/client/search';
import { selectSearchRequestResponse } from '@/redux/selectors/searchSelectors';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';
import { HAS_DISABLED_FILTERS } from '../constants';
import { addDisabledFacetsFromEmptySearch } from '../utils/searchResponse';

type UseSearchOverlayAllFiltersProps = {
  groupId?: string;
  dataSelector?: (state: RootState) => {
    request: SearchRequest | null;
    response: SearchResponse | null;
  };
  skip?: boolean;
};

const useUnfoldedFilterGroup = ({
  groupId,
  dataSelector = selectSearchRequestResponse,
  skip = false
}: UseSearchOverlayAllFiltersProps) => {
  const { request: searchRequest, response: mainSearchData } = useSelector(dataSelector);

  let foundGroup = false;

  const unfoldedFacetGroup = {
    groupTypeId: groupId,
    facets: [],
    unfolded: true
  };

  const facetGroups =
    // if we already have data for the group, unfold it
    searchRequest?.facetGroups?.map(group => {
      const unfolded = group.groupTypeId === groupId;
      if (unfolded) {
        foundGroup = true;
      }
      return {
        ...group,
        unfolded
      };
    }) || [];

  // if not, add it to our list unfolded
  if (!foundGroup) {
    facetGroups.push(unfoldedFacetGroup);
  }

  const request = {
    ...searchRequest,
    facetGroups,
    filterGroupsToReturn: groupId ? [groupId] : [],
    currentPage: 1,
    pageSize: 0,
    sort: null
  };

  const { isLoading: isLoadingEmptyUnfolded, data: emptyUnfolded } = useGetSearchResultsQuery(
    { requestBody: { ...request, query: undefined, facetGroups: [unfoldedFacetGroup] } },
    { skip: skip || !groupId }
  );
  const { data, isLoading: isSearchLoading } = useGetSearchResultsQuery(
    { requestBody: request },
    {
      skip: skip || !groupId
    }
  );

  const filterGroup = data?.facetResults?.facetGroups?.[0];

  const isLoading =
    isSearchLoading || filterGroup?.groupTypeId !== groupId || isLoadingEmptyUnfolded;

  if (!filterGroup) {
    return { isLoading, filterGroup: null };
  }

  const facets = addDisabledFacetsFromEmptySearch({
    hasDisabledFilters: HAS_DISABLED_FILTERS.includes(groupId!),
    facetGroup: filterGroup,
    allFiltersFromEmptySearch: { data: emptyUnfolded }
  });

  const filterGroupFromMainSearchData = mainSearchData?.facetResults?.facetGroups?.find(
    group => group.groupTypeId === groupId
  );

  return {
    isLoading,
    filterGroup: {
      ...filterGroup,
      facets: facets.map(facet => ({
        ...facet,
        // Check the main search data to optimistically check/uncheck facets
        checked: filterGroupFromMainSearchData?.facets?.find(f => f.value === facet.value)?.checked
      }))
    }
  };
};

export default useUnfoldedFilterGroup;
