import { useSelector } from 'react-redux';

import { LibraryItem } from '@/@types/client-api';
import { useGetMediaStreamsQuery } from '@/redux/api/client/product';

import searchableTypeToSnippetVariant, {
  SearchableType,
  getSnippetRenderer
} from './searchableTypeToSnippetVariant';
import useSnippetActions from './hooks/useSnippetActions';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { getIsProgramLive, hasMultipleRecordings, isLiveProgram } from './Snippets/helpers';
import { SearchModalPanelState } from './hooks/useSearchModalPanelState';
import { AnalyticsContext } from '@/analytics/constants';

const useGetLibraryItemRow = (
  libraryItem: LibraryItem,
  modalPanelState: SearchModalPanelState,
  refetchData?: () => void
) => {
  const pageLinkPaths = useSelector(selectPageLinkPaths);
  const {
    searchableType: _searchableType = null,
    pk,
    url,
    title,
    locations = [],
    productGuidValue,
    itemAnalyticsModel
  } = libraryItem;
  const searchableType = _searchableType as unknown as SearchableType;
  const snippetVariant = searchableTypeToSnippetVariant(searchableType);
  const userList = [
    {
      ...libraryItem,
      isPurchased: libraryItem.isPurchased || !!libraryItem.isParentProgramPurchased
    }
  ];

  const actions = useSnippetActions(
    pk ?? '',
    url ?? '',
    title ?? '',
    AnalyticsContext.Library,
    snippetVariant,
    modalPanelState,
    hasMultipleRecordings(userList),
    productGuidValue ?? null,
    undefined,
    refetchData,
    libraryItem?.isPurchased || !!libraryItem?.isParentProgramPurchased
      ? (libraryItem.watchUrl ?? undefined)
      : undefined,
    itemAnalyticsModel
  );

  const isLive = !!locations?.length && getIsProgramLive(locations);
  const isLiveProgramType = isLiveProgram(searchableType ?? '');
  const { data: productMedia, error: streamingError } = useGetMediaStreamsQuery(libraryItem.pk!, {
    skip: !isLive || !searchableType || !isLiveProgramType
  });
  let streamingUrl = null;
  if (isLiveProgramType) {
    if (isLive && productMedia?.length) {
      streamingUrl = productMedia[0].playbackUrl;
    }
    if (isLive && streamingError) {
      console.error('Failed to load streaming media', streamingError);
    }
  }

  const renderSnippetParams = {
    ...libraryItem,
    variationPk: pk,
    searchableType: searchableType as unknown as SearchableType,
    isLive,
    streamingUrl,
    userList
  };

  const renderer = getSnippetRenderer(
    searchableType as unknown as string,
    'library',
    renderSnippetParams
  );

  if (renderer) {
    return renderer({
      data: renderSnippetParams,
      actions,
      context: 'library',
      links: pageLinkPaths,
      modalPanelState,
      itemAnalyticsModel
    });
  }

  return null;
};

export default useGetLibraryItemRow;
