import { ClientAPI } from '@/redux/api';
import type {
  ElectronicItemInfo,
  LaunchElectronicItemRequest,
  LibraryItem,
  ProgramCancel
} from '@/@types/client-api';

const registrationEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getUpcomingRegistrations: builder.query<LibraryItem[], void>({
      query: () => '/registration/upcoming-registrations'
    }),
    getRegistrationCancellable: builder.query<ProgramCancel, number>({
      query: params => ({
        url: `/registration/program-cancel?itemSk=${params}`,
        method: 'GET'
      })
    }),
    placeRegistration: builder.mutation<ElectronicItemInfo, LaunchElectronicItemRequest>({
      query: body => ({
        url: '/registration',
        method: 'POST',
        body
      })
    }),
    cancelRegistration: builder.mutation<boolean, number>({
      query: body => ({
        url: '/registration/program-cancel',
        method: 'POST',
        body: `${body}`,
        headers: {
          'content-type': 'application/json'
        }
      })
    })
  })
});

export const {
  useGetUpcomingRegistrationsQuery,
  useLazyGetUpcomingRegistrationsQuery,
  useLazyGetRegistrationCancellableQuery,
  usePlaceRegistrationMutation,
  useCancelRegistrationMutation
} = registrationEndpoints;

export default registrationEndpoints;
