import { useMemo } from 'react';
import { BreadcrumbProps } from '../ui/Breadcrumbs';
import { UserFaculty } from '@/@types/client-api';
import ContentArea from '../cms/ContentArea';
import type { StartPage as AboutFacultyPage, SiteSettings } from '@/@types/content';
import withNav from '../hocs/withNav';
import { IContent } from '@/@types/cms';
import NudgeProvider from '@/hooks/shared/useNudge/provider';

type AboutFacultyPageProps = AboutFacultyPage & {
  breadcrumbs: BreadcrumbProps[];
  followedFaculty: (UserFaculty & { pageUrl: string })[];
};

const AboutFacultyPageComponent: React.FC<AboutFacultyPageProps & SiteSettings> = ({
  breadcrumbs,
  followedFaculty,
  hideInBreadcrumbs,
  mainContentArea,
  name
}) => {
  const followedFacultySorted: IContent[] = useMemo(
    () =>
      [...followedFaculty]
        .sort((a, b) => {
          const aName: string = String(a.name);
          const bName: string = String(b.name);
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        })
        .map(faculty => ({
          contentType: ['FacultyLinkBlock'],
          image: {
            url: faculty.thumbnailUrl
          },
          link: {
            href: faculty.pageUrl,
            text: faculty.name,
            title: faculty.name
          }
        })),
    [followedFaculty]
  );

  return (
    <NudgeProvider>
      <ContentArea
        components={mainContentArea}
        componentsProps={{
          breadcrumbs,
          pageName: name,
          hideInBreadcrumbs: hideInBreadcrumbs,
          linkBlocks: followedFacultySorted
        }}
        propertyName="MainContentArea"
      />
    </NudgeProvider>
  );
};

const AboutFacultyPage = withNav(AboutFacultyPageComponent);
export default AboutFacultyPage;
