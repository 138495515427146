import { useGetLicenseData, useOnSaveLicense } from '@/components/ui/LicensesPage/hooks';
import LicenseForm from '@/components/ui/SearchModals/LicenseForm';
import { useEffect, useState } from 'react';

interface AddLicenseStepProps {
  setIsProcessing: (isProcessing: boolean) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  onStepIncrease: () => void;
  isSubmitting: boolean;
}

const AddLicenseStep: React.FC<AddLicenseStepProps> = ({
  setIsProcessing,
  setIsSubmitting,
  onStepIncrease,
  isSubmitting
}) => {
  const [isLicenseSaved, setIsLicenseSaved] = useState(false);
  const [isLicenseSaving, setIsLicenseSaving] = useState(false);
  const {
    isLoading,
    validationFailed,
    enrichedData,
    creditTypeOptions,
    jurisdictionOptions,
    complianceGroupOptions,
    licenseNumberSettings,
    useOnValidateLicense,
    useOnDataChange,
    resetLicenseData
  } = useGetLicenseData();
  const handleOnSaveLicense = useOnSaveLicense({
    setIsProcessing: setIsLicenseSaving,
    setIsLicenseSaved
  });
  const handleOnValidateLicense = useOnValidateLicense;

  useEffect(() => {
    const handleSubmit = async () => {
      if (handleOnValidateLicense()) {
        await handleOnSaveLicense(enrichedData, true);
      } else {
        setIsSubmitting(false);
      }
    };

    if (isSubmitting && !isLicenseSaving) {
      handleSubmit();
    }
  }, [
    isSubmitting,
    isLicenseSaving,
    enrichedData,
    handleOnSaveLicense,
    handleOnValidateLicense,
    setIsSubmitting
  ]);

  useEffect(() => {
    if (isLicenseSaved) {
      onStepIncrease();
      setIsLicenseSaved(false);
      resetLicenseData();
    }
  }, [isLicenseSaved, resetLicenseData, onStepIncrease]);

  useEffect(() => {
    setIsProcessing(isLoading || isLicenseSaving);
  }, [isLoading, isLicenseSaving, setIsProcessing]);

  return (
    !isLoading && (
      <LicenseForm
        data={enrichedData}
        onDataChange={useOnDataChange}
        creditTypeOptions={creditTypeOptions}
        jurisdictionOptions={jurisdictionOptions}
        complianceGroupOptions={complianceGroupOptions}
        licenseNumberSettings={licenseNumberSettings}
        validationFailed={validationFailed}
      />
    )
  );
};

export default AddLicenseStep;
