import ComplexTooltip from '@/components/ui/Tooltip/ComplexTooltip';
import Button from '@/components/ui/Buttons/Button';
import useDismissable from '@/hooks/useDismissable';
import useNudgeContext from '@/hooks/shared/useNudge/useNudgeContext';
import { MessageCta, Nudge } from '@/hooks/shared/useNudge/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmptyStateNudge, isStaleNudge } from '@/hooks/shared/useNudge/utils';
import {
  trackDismissNudgeEvent,
  trackUpdateNudgeEvent,
  trackViewNudgeEvent
} from '@/analytics/nudge';

const VIEW_NUDGE_EVENT_DELAY = 2000;

interface NudgeTooltipProps extends ComponentWithChildren {
  nudge?: Nudge;
}

const NudgeTooltip = ({ children, nudge }: NudgeTooltipProps) => {
  const [, setIsDismissed] = useDismissable(nudge?.key || '', nudge?.cookieExpiration);
  const { closeTooltipForKey, currentKey, openPanel } = useNudgeContext();
  const [status, setStatus] = useState<'none' | 'dismissed' | 'overlapped'>('none');
  const viewNudgeTimeoutId = useRef<NodeJS.Timeout>();

  const navigate = useNavigate();

  const relatedPanelIsOpened = !!openPanel && nudge?.key && nudge?.key.includes(openPanel);

  const onCtaClick = ({ action, href }: MessageCta) => {
    if (action === 'accept') {
      trackUpdateNudgeEvent(nudge?.context);
      if (href) {
        navigate(href);
      } else {
        nudge?.onAccept?.();
      }
    } else if (action === 'dismiss') {
      trackDismissNudgeEvent(nudge?.context);
    }
    handleDismiss();
  };

  const handleOpenChange = useCallback(
    (isOpen: boolean, dismissed = false) => {
      if (!openPanel && !dismissed) return;
      if (nudge?.key && !isOpen && dismissed) {
        closeTooltipForKey(nudge?.key);
      }
      // this covers scenarios where the search overlay is open as well
      setStatus(isOpen ? 'none' : dismissed ? 'dismissed' : 'overlapped');
    },
    [openPanel, nudge?.key, closeTooltipForKey]
  );

  const handleDismiss = useCallback(() => {
    setIsDismissed(true);
    handleOpenChange(false, true);
  }, [setIsDismissed, handleOpenChange]);

  useEffect(() => {
    if (nudge?.key && nudge.key === currentKey && relatedPanelIsOpened) {
      closeTooltipForKey(nudge?.key);
    }
  }, [nudge?.key, relatedPanelIsOpened, currentKey, closeTooltipForKey]);

  useEffect(() => {
    if (!openPanel && status == 'overlapped') setStatus('none');
  }, [openPanel, status]);

  const nudgeContext = nudge?.context;
  const displayNudge = nudge && nudge.key === currentKey && !openPanel && status == 'none';

  /* This useEffect is responsible for tracking the view nudge event to analytics.
     We delay this tracking by a few seconds to ensure that only the visible nudge is tracked. */
  useEffect(() => {
    if (!displayNudge) {
      return;
    }
    const resetViewNudgeTimeout = () => {
      if (viewNudgeTimeoutId.current) {
        clearTimeout(viewNudgeTimeoutId.current);
        viewNudgeTimeoutId.current = undefined;
      }
    };

    resetViewNudgeTimeout();
    viewNudgeTimeoutId.current = setTimeout(() => {
      trackViewNudgeEvent(nudgeContext);
      viewNudgeTimeoutId.current = undefined;
    }, VIEW_NUDGE_EVENT_DELAY);

    return resetViewNudgeTimeout;
  }, [displayNudge, nudgeContext]);

  if (!displayNudge) {
    return children;
  }

  const { message } = nudge;

  const shouldDisplayAtTop = isStaleNudge(nudge) || isEmptyStateNudge(nudge);

  return (
    <ComplexTooltip variant="dark" isOpen onOpenChange={handleOpenChange}>
      <ComplexTooltip.Trigger>{children}</ComplexTooltip.Trigger>
      <ComplexTooltip.Content
        side={shouldDisplayAtTop ? 'top' : 'bottom'}
        avoidCollisions={shouldDisplayAtTop ? false : undefined}
        closeLabel="Close"
        onClose={() => {
          trackDismissNudgeEvent(nudge?.context);
          handleDismiss();
        }}
        ariaLabel={message.title}
      >
        <div>
          <p className="text-2 mb-1 font-bold">{message.title}</p>
          <p className="text-2">{message.description}</p>
        </div>
        <ComplexTooltip.Buttons>
          <Button
            label={message.cta1.label}
            color="outline-white"
            onClick={() => onCtaClick(message.cta1)}
          />
          <Button
            label={message.cta2.label}
            color="outline-white"
            onClick={() => onCtaClick(message.cta2)}
          />
        </ComplexTooltip.Buttons>
      </ComplexTooltip.Content>
    </ComplexTooltip>
  );
};

export default NudgeTooltip;
