import { useEffect, useState, useMemo, useRef } from 'react';
import { Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';
import classnames from 'classnames';
import Divider from '@/components/ui/Divider';
import Avatar from '@/components/ui/Avatar';
import Link from '@/components/ui/Link';
import RoundedIcon from '@/components/ui/RoundedIcon';
import useScrollDirection, { ScrollDirection } from '@/hooks/useScrollDirection';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { HeaderBlock as HeaderBlockProps, NavigationItemBlock } from '@/@types/content';
import { LogoColor, setHeader, setStickyHeader } from '@/redux/slices/pageSlice';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import Image from '@/components/cms/Image';
import { useAuth } from 'react-oidc-context';
import ContentArea from '@/components/cms/ContentArea';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import { IContent } from '@/@types/cms';
import PanelNavTablet from '@/components/blocks/Header/PanelNavTablet';
import PanelNavMobile from '@/components/blocks/Header/PanelNavMobile';
import PanelAccountNavTablet from '@/components/blocks/Header/PanelAccountNavTablet';
import PanelAccountNavMobile from '@/components/blocks/Header/PanelAccountNavMobile';
import { getOIDCState, getSingoutOIDCState } from '@/auth/oidcConfig';
import SearchOverlay from '@/components/ui/SearchOverlay/SearchOverlay';
import SearchButton from '@/components/ui/Buttons/SearchButton';
import { Theme } from '@/styles/darkMode';
import { QUERY_PARAMS } from '@/components/ui/SearchResults/constants';
import { getFocusOutlineClass, getTextColorClass } from '@/utils/theme';
import { ClientAPI } from '@/redux/api';
import { useGetLightCartQuery } from '@/redux/api/client/cart';
//import { contentDeliveryApi } from '@/redux/api/contentDeliveryAPI';
import { FilterContext, SearchContext } from '@/analytics/constants';
import TooltipBlockContainer from '../Tooltip/TooltipBlock';
import { useGetEndSessionMutation } from '@/redux/api/client/userSession';

type CartsDataType = {
  extendedQuantity: number;
};

const determineHeaderInViewAndOffset = (elem: HTMLElement) => {
  const headerRect = elem?.getBoundingClientRect();
  const top = headerRect?.top ?? 0;
  const bottom = headerRect?.bottom ?? 0;
  const isInView = top < window.innerHeight && bottom >= 0;
  return { isInView, inViewOffset: bottom };
};

export type HeaderProps = HeaderBlockProps & {
  tooltipsData?: IContent & { walkthroughSteps?: number };
  themeVariation?: number;
};

const HeaderBlock: React.FC<HeaderProps> = ({
  accountNavMenuItems = [],
  cartButtonDescription,
  logoLink,
  logoRed,
  logoWhite,
  pliLink,
  primaryNavMenuItems = [],
  redirectHomeOnSignoutRoutes,
  searchLabel = 'Find a program, publication, or credit',
  themeVariation: themeVariationProp = 0,
  tooltipsData,
  userButtonDescription
}) => {
  const auth = useAuth();

  const { data: cartsData } = useGetLightCartQuery('' as unknown as void, {
    skip: !auth?.isAuthenticated
  });

  const editMode = isEditOrPreviewMode();
  const refHeader = useRef<HTMLDivElement>(null);

  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

  const dispatch = useDispatch();
  const [isOpenByMouseHover, setIsOpenByMouseHover] = useState(false);
  const [isSecondaryNavOpen, setIsSecondaryNavOpen] = useState(false);
  const [isAccountNavOpen, setIsAccountNavOpen] = useState(false);
  const [activePrimaryNav, setActivePrimaryNav] = useState<string | undefined>(undefined);
  const [secondaryNavData, setSecondaryNavData] = useState<Record<string, unknown>[]>([]);
  const [logoutLink, setLogoutLink] = useState<Record<string, unknown>>({});
  const [cartItems, setCartItems] = useState<number>(0);

  const stateLogoColor = useSelector((state: RootState) => state.page.logoColor);
  const stateHeaderTheme = useSelector((state: RootState) => state.page.headerTheme);
  const stickyHeader = useSelector((state: RootState) => state.page.stickyHeader);
  const pageLinkPaths = useSelector(selectPageLinkPaths);
  const IsBelowMedium = useMediaQuery(ScreenSizeQueries.belowMd);
  const isMediumScreen = useMediaQuery(ScreenSizeQueries.md);
  const isLargeScreen = useMediaQuery(ScreenSizeQueries.lg);
  const { scrollDirection, scrollTop } = useScrollDirection();
  const scrollTopGap = isMediumScreen ? 120 : 185;
  const { pathname } = useLocation();
  const [params] = useSearchParams();

  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const themeVariation = stateHeaderTheme ?? themeVariationProp;
  const theme = themeVariation === 1 ? Theme.Dark : Theme.Light;

  useEffect(() => {
    const { extendedQuantity } = (cartsData as unknown as CartsDataType) || {};

    if (extendedQuantity) {
      setCartItems(extendedQuantity);
    }
  }, [cartsData]);

  const logo = useMemo(() => {
    let logoColor = stateLogoColor === LogoColor.White ? logoWhite : logoRed;

    if (themeVariation === 2) {
      logoColor = logoRed;
    }

    return {
      src: logoColor?.url,
      alt: logoColor?.name as string,
      title: logoColor?.name as string
    };
  }, [stateLogoColor, logoWhite, logoRed, themeVariation]);

  useEffect(() => {
    if (!primaryNavMenuItems || primaryNavMenuItems.length === 0) {
      return;
    }

    const selectedPrimaryNav = primaryNavMenuItems.find(
      item => (item as NavigationItemBlock).title === activePrimaryNav
    ) as NavigationItemBlock;

    if (selectedPrimaryNav) {
      const subnavItems = selectedPrimaryNav.subnavItems || ([] as IContent[]);
      setSecondaryNavData(subnavItems as Record<string, unknown>[]);
    } else {
      setSecondaryNavData([]);
    }
  }, [activePrimaryNav, primaryNavMenuItems]);

  useEffect(() => {
    if (isSecondaryNavOpen === false) {
      setActivePrimaryNav(undefined);
    }
  }, [isSecondaryNavOpen]);

  useEffect(() => {
    dispatch(
      setStickyHeader({
        isSticky: scrollDirection === ScrollDirection.Up && scrollTop > scrollTopGap,
        headerHeight: refHeader.current?.clientHeight || 0
      })
    );
    refHeader.current && setHeader(determineHeaderInViewAndOffset(refHeader.current));
  }, [dispatch, refHeader, scrollDirection, scrollTop, scrollTopGap]);

  useEffect(() => {
    dispatch(
      setStickyHeader({
        isSticky:
          !(scrollDirection === ScrollDirection.Down || scrollTop < scrollTopGap) ||
          isSecondaryNavOpen ||
          isAccountNavOpen,
        headerHeight: refHeader.current?.clientHeight || 0
      })
    );
    refHeader.current && dispatch(setHeader(determineHeaderInViewAndOffset(refHeader.current)));
  }, [
    dispatch,
    isAccountNavOpen,
    isSecondaryNavOpen,
    refHeader,
    scrollDirection,
    scrollTop,
    scrollTopGap
  ]);

  const focusVisibleClasses = getFocusOutlineClass(theme);

  const headerClasses = classnames(
    'sticky top-0 z-sticky-header transition-all',
    {
      'bg-transparent': !stickyHeader.isSticky,
      'bg-white': theme === Theme.Light && stickyHeader.isSticky,
      'bg-black': theme === Theme.Dark && stickyHeader.isSticky
    },
    {
      'top-[-165px] md:top-[-100px]': !stickyHeader.isSticky,
      'top-0': scrollDirection === ScrollDirection.Up
    }
  );

  const pliLinkClasses = classnames(getTextColorClass(theme), focusVisibleClasses);

  const avatarClasses = classnames('w-10', {
    '!bg-black text-white': theme === Theme.Light,
    '!bg-white text-black': theme === Theme.Dark
  });

  const dividerClasses = classnames('', {
    '!bg-gray-light': theme === Theme.Light,
    '!bg-white/20': theme === Theme.Dark
  });

  const secondaryNavClasses = classnames('absolute hidden w-full', 'lg:block', {
    'bg-white': theme === Theme.Light,
    'bg-black': theme === Theme.Dark
  });

  const btnLoginUserClasses = classnames(
    'relative',
    'after:pointer-events-none after:absolute after:inset-x-0 after:-bottom-7 after:hidden after:h-0 after:transition-all lg:after:content-[""]',
    'lg:after:block',
    {
      'hover:after:h-[3px] hover:after:bg-red': !isAccountNavOpen
    },
    {
      'after:h-[3px] after:bg-red': isAccountNavOpen
    },
    focusVisibleClasses
  );

  const searchButtonClasses = classnames(focusVisibleClasses, {
    'bg-white': themeVariation === 2 && !stickyHeader.isSticky
  });

  const existingQuery = pathname === pageLinkPaths.Search && params.get(QUERY_PARAMS.QUERY);

  const SearchButtonComponent = useMemo(
    () => (
      <SearchOverlay
        key={existingQuery || undefined} // necessary to reset the default internal state of the component
        defaultQuery={existingQuery || undefined}
        context={FilterContext.Overlay}
        searchContext={SearchContext.Overlay}
        triggerButton={
          <SearchButton
            label={existingQuery || searchLabel}
            focusVisibleClasses={searchButtonClasses}
          />
        }
      />
    ),
    [existingQuery, searchLabel, searchButtonClasses]
  );

  const memoizedLogo = useMemo(
    () => (
      <RouterLink
        className={classnames(focusVisibleClasses, 'block basis-[50px] md:basis-[66px]')}
        to={logoLink?.href}
        target={logoLink?.target || '_self'}
      >
        <Image className="h-6 md:h-8" {...logo} />
      </RouterLink>
    ),
    [focusVisibleClasses, logo, logoLink]
  );

  const memoizedRedLogo = useMemo(() => {
    const forceRedLogo = {
      src: logoRed?.url,
      alt: logoRed?.name as string,
      title: logoRed?.name as string
    };
    return (
      <RouterLink
        className={classnames(focusVisibleClasses, 'block basis-[50px] md:basis-[66px]')}
        to={logoLink?.href}
        target={logoLink?.target || '_self'}
      >
        <Image className="h-6 md:h-8" {...forceRedLogo} />
      </RouterLink>
    );
  }, [focusVisibleClasses, logoRed, logoLink]);

  const memoizedAvatar = useMemo(
    () => <Avatar fullName={auth?.user?.profile.name || ''} className={avatarClasses} />,
    [auth, avatarClasses]
  );

  const primaryNavClickHandler = (event: React.MouseEvent) => {
    event?.preventDefault();
    const targetText = event?.currentTarget?.getAttribute('title') || undefined;
    const isMouseEnterEvent = event.type === 'mouseenter';
    setIsAccountNavOpen(false);

    if (hoverTimeoutRef.current && isMouseEnterEvent) {
      clearTimeout(hoverTimeoutRef.current);
    }

    hoverTimeoutRef.current = setTimeout(
      () => {
        setIsSecondaryNavOpen(true);
        setActivePrimaryNav(targetText);
        // Check is was open by mouse event and not by tab/click
        if (isMouseEnterEvent) {
          setIsOpenByMouseHover(true);
        }
      },
      isMouseEnterEvent && !isSecondaryNavOpen && !isAccountNavOpen ? 250 : 0
    );
  };

  const handleMouseLeaveNavItem = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
  };

  const accountNavClickHandler = (event?: React.MouseEvent) => {
    setActivePrimaryNav(undefined);

    const expandedNavData = accountNavMenuItems;
    const isMouseEnterEvent = event?.type === 'mouseenter';

    if (hoverTimeoutRef.current && isMouseEnterEvent) {
      clearTimeout(hoverTimeoutRef.current);
    }

    const subnavItems =
      (expandedNavData[0] as IContent & { subnavItems?: IContent[] }).subnavItems ||
      ([] as IContent[]);
    if (subnavItems.length === 0) {
      setIsAccountNavOpen(false);
      return;
    }

    const logoutLink = (expandedNavData[0] as { logoutLink: Record<string, unknown> }).logoutLink;
    setLogoutLink(logoutLink);

    hoverTimeoutRef.current = setTimeout(
      () => {
        setSecondaryNavData(subnavItems as Record<string, unknown>[]);
        setIsAccountNavOpen(true);

        if (isMouseEnterEvent) {
          setIsOpenByMouseHover(true);
        }
      },
      isMouseEnterEvent && !isSecondaryNavOpen ? 250 : 0
    );
  };

  const closePrimaryNav = (overrideMouseEvent?: boolean) => {
    if (isOpenByMouseHover || overrideMouseEvent) {
      setIsSecondaryNavOpen(false);
      setIsAccountNavOpen(false);
      setIsOpenByMouseHover(false);
    }
  };

  const handleOpenCloseAccountPanel = (isOpen: boolean) => {
    if (isOpen === false) {
      setIsSecondaryNavOpen(false);
      setIsAccountNavOpen(false);
    }
  };

  //const [useLogout] = contentDeliveryApi.useLogOutMutation();
  const [getSessionEnd] = useGetEndSessionMutation();

  const handleSignout = () => {
    const routes = redirectHomeOnSignoutRoutes?.map(x => {
      const url = new URL(x.url || '');
      return url.pathname;
    });
    console.info('DIAG:Calling end session ...');
    getSessionEnd()
      .unwrap()
      .then(() => {
        auth.signoutRedirect({ state: getSingoutOIDCState(routes || []) });
      });
    //const action1 = userSessionEndpoints.endpoints.getEndSession.select();
    //dispatch(action1 as unknown as UnknownAction);
    return;
  };
  const handleScroll = (_: Event) => {
    closePrimaryNav(true);
  };
  useEffect(
    () => {
      const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          closePrimaryNav(true);
        }
      };

      document.addEventListener('scroll', handleScroll);
      window.addEventListener('keydown', handleEscape);

      // Cleanup function to remove the event listener on unmount
      return () => {
        window.removeEventListener('keydown', handleEscape);
        document.removeEventListener('scroll', handleScroll);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tooltipBlock = useMemo(() => {
    return (
      <TooltipBlockContainer
        {...tooltipsData}
        index={0}
        totalSteps={tooltipsData?.walkthroughSteps}
        isWalkthrought={true}
      />
    );
  }, [tooltipsData]);

  const handleItemClick = () => {
    setIsSecondaryNavOpen(false);
  };

  return (
    <>
      {(isSecondaryNavOpen || isAccountNavOpen) && isLargeScreen ? (
        <div
          className="fixed inset-0 z-sticky-header-overlay bg-black/30"
          onClick={() => closePrimaryNav()}
          onMouseEnter={() => closePrimaryNav()}
        />
      ) : null}
      <header ref={refHeader} className={headerClasses}>
        <div
          className={classnames('container relative flex flex-col gap-5 pb-6 pt-7', 'md:py-[19px]')}
        >
          <div className="flex items-center md:min-h-14 lg:items-stretch">
            {isMediumScreen && !isLargeScreen && (
              <PanelNavTablet
                theme={theme}
                isSecondaryNavOpen={isSecondaryNavOpen}
                setIsSecondaryNavOpen={setIsSecondaryNavOpen}
                setActivePrimaryNav={setActivePrimaryNav}
                primaryNavClickHandler={primaryNavClickHandler}
                primaryNavMenuItems={primaryNavMenuItems}
                secondaryNavData={secondaryNavData}
                pliLink={pliLink}
                editMode={editMode}
                focusVisibleClasses={focusVisibleClasses}
                memoizedLogo={memoizedLogo}
                activePrimaryNav={activePrimaryNav}
              />
            )}

            {IsBelowMedium && (
              <PanelNavMobile
                theme={theme}
                isSecondaryNavOpen={isSecondaryNavOpen}
                setIsSecondaryNavOpen={setIsSecondaryNavOpen}
                setActivePrimaryNav={setActivePrimaryNav}
                primaryNavClickHandler={primaryNavClickHandler}
                primaryNavMenuItems={primaryNavMenuItems}
                secondaryNavData={secondaryNavData}
                pliLink={pliLink}
                editMode={editMode}
                focusVisibleClasses={focusVisibleClasses}
                memoizedLogo={memoizedLogo}
                activePrimaryNav={activePrimaryNav}
              />
            )}

            <div className={classnames('mr-4 flex items-center gap-[43px]', 'lg:mr-auto')}>
              {scrollDirection === ScrollDirection.Up && scrollTop > scrollTopGap
                ? memoizedRedLogo
                : memoizedLogo}

              {isLargeScreen && (
                <ContentArea
                  propertyName="PrimaryNavMenuItems"
                  components={primaryNavMenuItems}
                  componentsProps={{
                    theme,
                    focusVisibleClasses,
                    primaryNavClickHandler,
                    activePrimaryNav,
                    handleMouseLeaveNavItem
                  }}
                  className={classnames('flex h-full items-center gap-8')}
                  element="nav"
                />
              )}
            </div>

            <div className={classnames('ml-auto flex items-center gap-4', 'lg:gap-6')}>
              <div
                className={classnames(
                  'pointer-events-none hidden w-[450px]',
                  'md:absolute md:left-1/2 md:top-auto md:flex md:-translate-x-1/2',
                  'lg:relative lg:left-auto lg:block lg:w-[370px] lg:translate-x-0'
                )}
              >
                <>
                  {SearchButtonComponent}
                  {tooltipBlock && (
                    <div className="absolute -bottom-2 h-2 w-full">{tooltipBlock}</div>
                  )}
                </>
              </div>

              {isLargeScreen && (pliLink || editMode) ? (
                <Link
                  variant="bold-link-with-icon"
                  className={pliLinkClasses}
                  href={pliLink?.href}
                  text={pliLink?.text}
                  title={pliLink?.title}
                  target={pliLink?.target}
                />
              ) : null}

              {cartItems ? (
                <button
                  onClick={e => {
                    e.preventDefault();
                    ClientAPI.util.invalidateTags(['CartData']);
                    if (stateSiteLabels?.cartUrl) {
                      document.location = stateSiteLabels?.cartUrl;
                    }
                  }}
                  className={classnames('relative', focusVisibleClasses)}
                  aria-label={
                    cartButtonDescription
                      ? cartButtonDescription.replace('{0}', cartItems.toString())
                      : undefined
                  }
                >
                  <span className="label absolute -top-1 right-0 z-1 flex h-4 min-w-4 items-center justify-center rounded-full bg-red px-1 leading-none text-white">
                    {cartItems}
                  </span>
                  <RoundedIcon
                    icon={{ name: 'cart', size: 'medium' }}
                    color={theme === Theme.Light ? 'black' : 'white'}
                  />
                </button>
              ) : null}

              {auth?.isAuthenticated ? (
                <>
                  {isLargeScreen && (
                    <button
                      onClick={accountNavClickHandler}
                      onMouseEnter={accountNavClickHandler}
                      onMouseLeave={handleMouseLeaveNavItem}
                      className={btnLoginUserClasses}
                      aria-label={userButtonDescription}
                    >
                      {memoizedAvatar}
                    </button>
                  )}

                  {isMediumScreen && !isLargeScreen && (
                    <PanelAccountNavTablet
                      theme={theme}
                      isAccountNavOpen={isAccountNavOpen}
                      setIsAccountNavOpen={handleOpenCloseAccountPanel}
                      accountNavClickHandler={accountNavClickHandler}
                      secondaryNavData={secondaryNavData}
                      logoutLink={logoutLink}
                      onSignout={handleSignout}
                      focusVisibleClasses={focusVisibleClasses}
                      memoizedAvatar={memoizedAvatar}
                      userAriaLabel={userButtonDescription}
                    />
                  )}

                  {IsBelowMedium && (
                    <PanelAccountNavMobile
                      theme={theme}
                      isAccountNavOpen={isAccountNavOpen}
                      setIsAccountNavOpen={handleOpenCloseAccountPanel}
                      accountNavClickHandler={accountNavClickHandler}
                      secondaryNavData={secondaryNavData}
                      logoutLink={logoutLink}
                      onSignout={handleSignout}
                      focusVisibleClasses={focusVisibleClasses}
                      memoizedAvatar={memoizedAvatar}
                      userAriaLabel={userButtonDescription}
                    />
                  )}
                </>
              ) : (
                <button
                  type="button"
                  className={btnLoginUserClasses}
                  onClick={() => auth?.signinRedirect({ state: getOIDCState() })}
                  aria-label={userButtonDescription}
                >
                  <RoundedIcon
                    icon={{ name: 'profile' }}
                    color={theme === Theme.Light ? 'black' : 'white'}
                    className="border border-silver"
                  />
                </button>
              )}
            </div>
          </div>

          <div className={classnames('flex flex-col', 'md:hidden')}>
            {SearchButtonComponent}
            {tooltipBlock}
          </div>
        </div>

        <Divider color={theme} className={dividerClasses} />

        {(isSecondaryNavOpen || isAccountNavOpen) && (
          <div className={secondaryNavClasses}>
            <div className="container h-full">
              <ContentArea
                propertyName="SubnavItems"
                components={secondaryNavData}
                className="flex flex-nowrap"
                componentsProps={{
                  theme,
                  onSignout: handleSignout,
                  logoutLink,
                  onItemClick: handleItemClick
                }}
                element="div"
              />
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default HeaderBlock;
