import * as Dialog from '@radix-ui/react-dialog';
import classnames from 'classnames';

export interface BaseBoxModalProps {
  children: React.ReactNode;
  className?: string;
}

const Modal: React.FC<BaseBoxModalProps> = ({ children, className }) => {
  return (
    <Dialog.Portal>
      <Dialog.Content
        className={classnames(
          'fixed inset-0 z-panel bg-white data-[state=closed]:animate-slideOutDown data-[state=open]:animate-slideInUp',
          className
        )}
      >
        {children}
      </Dialog.Content>
    </Dialog.Portal>
  );
};

export default Modal;
