import type { ContentGridBlock, FilterBlock } from '@/@types/content';
import Text from '@/components/cms/Text';
import classnames from 'classnames';
import Link from '@/components/ui/Link';
import CardBuilder from '@/components/ui/Cards/CardBuilder';
import Shimmer from '@/components/ui/Shimmer';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Block from '../cms/Block';
import { useEffect, useState } from 'react';
import { ISearchResultItem } from '@/@types/client-api';
import { useGetCardQuery } from '@/redux/api/client/card';
import { IContent } from '@/@types/cms';

export type ContentGridBlockProps = ContentGridBlock & {
  pageSize?: number;
  feeds?: Array<FilterBlock>;
};

interface IContentWithCode extends IContent {
  code?: string;
  productCode?: string;
  parentProductCode?: string;
}

interface DataFilter {
  searchResults?: {
    searchResultsItems?: Record<string, unknown>[];
  };
}

const ContentGridBlock: React.FC<ContentGridBlockProps> = ({
  contentLink,
  seeAllLink,
  title,
  hideSeeAllLink,
  multiCardItemList,
  manualOnly,
  feeds = [] as FilterBlock[]
}) => {
  const screenSizeSmall = useMediaQuery(ScreenSizeQueries.sm);
  const screenSizeXsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);
  const screenSizeBelowLg = useMediaQuery(ScreenSizeQueries.belowLg);

  const [searchResultsItems, setSearchResultsItems] = useState<ISearchResultItem[]>([]);

  const productCodesBaseUrl = '/search/product-code?ProductCodes=';
  let overrideUrl = '';
  if (multiCardItemList && multiCardItemList.length > 0) {
    const codes = multiCardItemList.map(item => {
      const code = (item as IContentWithCode).code;
      const parentProductCode = (item as IContentWithCode).parentProductCode;
      return parentProductCode ? `${parentProductCode}|${code}` : code;
    });

    overrideUrl = `${productCodesBaseUrl}${codes.join(';')}`;
  }

  let feedUrl = feeds && feeds.length && feeds[0].value ? feeds[0].value : '/search';

  const searchParams = new URLSearchParams(new URL(feedUrl, window.location.origin).search);
  if (!searchParams.has('PageSize')) {
    const maxNumberOfItems =
      feeds && feeds.length && feeds[0].maxNumberOfItems ? feeds[0].maxNumberOfItems : 5;
    feedUrl += `${feedUrl.includes('?') ? '&' : '?'}PageSize=${maxNumberOfItems}`;
  }

  const { data, isLoading: loading } = useGetCardQuery(feedUrl, {
    skip: manualOnly
  });

  const { currentData: dataOverride, isLoading: isLoadingOverride } = useGetCardQuery(
    overrideUrl as string,
    {
      skip: !overrideUrl
    }
  );

  useEffect(() => {
    if (loading || isLoadingOverride) return;
    const originalData = (data as unknown as DataFilter)?.searchResults?.searchResultsItems || [];
    const overrideData = dataOverride || [];

    const originalDataCopy = [...originalData];

    const filteredOriginalData = originalDataCopy.filter(
      item =>
        !overrideData.some(
          overrideItem => (overrideItem as IContentWithCode).productCode === item.productCode
        )
    );

    const combinedItems = [...overrideData, ...filteredOriginalData];

    setSearchResultsItems(combinedItems);
  }, [data, dataOverride, loading, isLoadingOverride]);

  if (!loading && !searchResultsItems.length) {
    return null;
  }

  return (
    <Block contentLinkID={contentLink?.id} className="container module-spacing">
      <div
        className={classnames(
          'flex flex-col items-start justify-between gap-4',
          'sm:flex-row sm:items-center'
        )}
        data-component={"ContentGridBlock"}
      >
        {loading ? (
          <Shimmer className="mb-6 h-7 w-1/5" />
        ) : (
          <>
            <Text element="h3" propertyName="Heading" className="heading-6-medium">
              {title}
            </Text>
            {!hideSeeAllLink && seeAllLink && (
              <Link
                propertyName="SeeAllLink"
                href={seeAllLink?.href}
                text={seeAllLink?.text}
                target={seeAllLink?.target}
                title={seeAllLink?.title}
                variant="bold-link-with-icon"
                icon="chevron-right"
              />
            )}
          </>
        )}
      </div>
      {loading ? (
        <div className="flex gap-6 overflow-visible">
          {Array.from({ length: 4 }).map((_, index) => (
            <Shimmer
              key={index}
              className={classnames(
                'size-full h-[400px] rounded-lg',
                'min-w-[241px]',
                'md:min-w-[260px]',
                'lg:min-w-[302px]'
              )}
            />
          ))}
        </div>
      ) : (
        <>
          {searchResultsItems && (
            <div
              className={classnames(
                'mt-6 grid grid-cols-1 gap-4',
                'sm:grid-cols-2',
                'lg:grid-cols-4 lg:gap-6'
              )}
            >
              {searchResultsItems.map((item, index) =>
                index === 0 ? (
                  <div
                    className={classnames(
                      'col-span-1 mb-2',
                      'sm:col-span-2',
                      'lg:col-span-4 lg:mb-0'
                    )}
                    key={index}
                  >
                    <CardBuilder
                      cardProps={item}
                      size="large"
                      heightAuto={screenSizeXsOnly}
                      widthAuto={true}
                    />
                  </div>
                ) : (
                  <CardBuilder
                    key={index}
                    cardProps={item}
                    size={screenSizeSmall ? 'small' : 'medium'}
                    heightAuto={screenSizeBelowLg && screenSizeSmall}
                    widthAuto={true}
                  />
                )
              )}
            </div>
          )}
        </>
      )}
    </Block>
  );
};

export default ContentGridBlock;
