import { useCallback, useMemo } from 'react';
import {
  ICreditRequirement,
  ISearchResultItem,
  RequirementKeyType,
  RequirementType,
  UserPracticeAreaKeywordItem
} from '@/@types/client-api';
import {
  getCreditTypeKeys,
  getTotalCreditsNeeded
} from '@/components/ui/CreditTracker/util/TrackerHelpers';
import { TrackerSearchQueryRecord } from '@/components/ui/CreditTracker/util/constants';
import { FILTERS, PROGRAM_FORMATS } from '@/components/ui/SearchResults/constants';

export type TrackerEarnMoreProps = {
  practiceAreas?: UserPracticeAreaKeywordItem[];
  formats?: string[] | null;
  priorityTypesConfig?: Array<string> | null;
};

const useTrackerEarnMore = ({
  practiceAreas,
  formats,
  priorityTypesConfig
}: TrackerEarnMoreProps) => {
  const nonCreditDecimalRegex = useMemo(() => /[^\d\\.]+/g, []);

  const getSearchFormats = useCallback((types: ICreditRequirement[]) => {
    const result: string[] = [];
    for (const t of types) {
      const keys = [
        ...(t.requirementKeys?.filter(a => a.requirementKeyType == RequirementKeyType._2) ?? []),
        ...(t.eligibleFormatKeys ?? [])
      ];

      keys.map(type => {
        if (type.key?.toLowerCase() === 'live-webcast') result.push(PROGRAM_FORMATS.LIVE_WEBCAST);
        else if ((type.key?.toLowerCase()?.indexOf('live') ?? -1) >= 0)
          result.push(PROGRAM_FORMATS.LIVE);
        else if (type.key?.toLowerCase() === 'on-demand') result.push(PROGRAM_FORMATS.ONDEMAND);
      });
    }

    return [...new Set(result)];
  }, []);

  const createSearchLink = useCallback(
    (
      requirements: TrackerSearchQueryRecord,
      usePracticeAreas: boolean = true,
      useCreditTypes: boolean = true
    ): URLSearchParams | undefined => {
      if (!Object.keys(requirements)?.length) return;

      const query = new URLSearchParams(),
        regions = [];

      for (const [region, _value] of Object.entries(requirements)) {
        regions.push(region);

        if (!usePracticeAreas && !useCreditTypes) break;

        const filteredReqs = _value.filter(a => a.requirementType != RequirementType._0);
        const formats = getSearchFormats(filteredReqs);

        if (formats?.length) {
          query.set(FILTERS.FORMAT, formats.join(';'));
        }

        const allRequirementKeys = filteredReqs?.flatMap(a =>
          a.requirementKeys?.filter(b => b.requirementKeyType == RequirementKeyType._0)
        );

        if (useCreditTypes) {
          const _creditTypes =
            getCreditTypeKeys(filteredReqs, priorityTypesConfig ?? []) ?? allRequirementKeys;

          const sortedResult =
            Object.entries(_creditTypes)
              ?.sort((a, b) => a?.[1]?.priorityIndex - b?.[1]?.priorityIndex)
              ?.map(a => a?.[1]?.requirementKey) ?? [];

          const creditTypes = [...new Set(sortedResult)];

          if (creditTypes?.length) {
            query.append(
              FILTERS.CREDIT_TYPE,
              creditTypes?.map(a => `${a?.key?.split(':')?.[0]}[${region}]`)?.join(';')
            );
          }
        }
      }

      query.append(FILTERS.JURISDICTIONS, `${regions.join(';')}`);

      if (!!usePracticeAreas && practiceAreas?.length) {
        query.append(
          FILTERS.PRACTICE_AREA,
          `${practiceAreas.map(pa => `${pa?.keyword}|${pa?.categoryId}`).join(';')}`
        );
      }

      return query;
    },
    [getSearchFormats, practiceAreas, priorityTypesConfig]
  );

  /** @private */
  const formatProgramDate = useCallback((date: string) => {
    const regex = new RegExp(' – .');
    return new Date(date.replace(regex, ''))?.getTime();
  }, []);

  // #region Sorting Search Results

  /** @private */
  const sortResultsByDateAsc = useCallback(
    (a: ISearchResultItem, b: ISearchResultItem) => {
      if (a.datesFormatted && b.datesFormatted) {
        const aDate = formatProgramDate(a.datesFormatted);
        const bDate = formatProgramDate(b.datesFormatted);

        return aDate - bDate;
      }
      return 1;
    },
    [formatProgramDate]
  );

  /** @private */
  const sortResultsByPriceAsc = useCallback((a: ISearchResultItem, b: ISearchResultItem) => {
    return (
      (a.userList?.find(b => a.variationPk === b.pk)?.price ?? 0) -
      (b.userList?.find(c => b.variationPk === c.pk)?.price ?? 0)
    );
  }, []);

  /** @private */
  const sortResultsByCreditsAsc = useCallback(
    (a: ISearchResultItem, b: ISearchResultItem) => {
      const aCredits = parseFloat(
        a.creditDetailsFormatted?.[0]?.keyword?.replaceAll(nonCreditDecimalRegex, '') ?? ''
      );
      const bCredits = parseFloat(
        b.creditDetailsFormatted?.[0]?.keyword?.replaceAll(nonCreditDecimalRegex, '') ?? ''
      );
      return aCredits - bCredits;
    },
    [nonCreditDecimalRegex]
  );

  /** @private */
  const sortResultsByFormatAsc = useCallback(
    (a: ISearchResultItem, b: ISearchResultItem) => {
      let res = 0;
      if (formats?.length) {
        res =
          +(a.formats?.some(c => formats?.includes(c)) ?? false) -
          +(b.formats?.some(c => formats?.includes(c) ?? 0) ?? false);
      } else {
        res = (a.formats?.indexOf('On-Demand') ?? 0) - (b.formats?.indexOf('On-Demand') ?? 0);
      }

      return res;
    },
    [formats]
  );

  const sortCreditRequirementsByCreditsNeededDesc = useCallback(
    (a: ICreditRequirement, b: ICreditRequirement) =>
      getTotalCreditsNeeded(a) - getTotalCreditsNeeded(b),
    []
  );

  //#endregion

  const fillSearchResults = useCallback(
    (searchResultsItems: ISearchResultItem[]) => {
      return searchResultsItems
        ?.filter(a => {
          return (
            a.searchableType &&
            (a.searchableType?.toLowerCase()?.indexOf('program') >= 0 ||
              a.searchableType?.toLowerCase()?.indexOf('segment') >= 0) &&
            !!a.creditDetailsFormatted?.length
          );
        })
        ?.sort((a, b) => {
          const price = sortResultsByPriceAsc(a, b);
          if (price != 0) return price;

          const credits = sortResultsByCreditsAsc(a, b);
          if (credits != 0) return credits;

          const date = sortResultsByDateAsc(a, b);
          if (date != 0) return date;

          const format = sortResultsByFormatAsc(a, b);
          if (format != 0) return format;

          return -1;
        });
    },
    [sortResultsByCreditsAsc, sortResultsByDateAsc, sortResultsByFormatAsc, sortResultsByPriceAsc]
  );

  return {
    getCreditTypeKeys,
    sortCreditRequirementsByCreditsNeededAsc: sortCreditRequirementsByCreditsNeededDesc,
    getSearchFormats,
    createSearchLink,
    fillSearchResults
  };
};

export default useTrackerEarnMore;
