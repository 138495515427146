import { FC } from 'react';
import { motion, Variants } from 'framer-motion';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';
import ExpandableRichText from '@/components/ui/ExpandableRichText';

type GlobalBannerHeaderProps = {
  dismissible: boolean;
  expandable: boolean;
  onDismiss: VoidFunction;
  onToggleOpen: VoidFunction;
  text?: string;
  theme?: string;
};

const GlobalBannerHeader: FC<GlobalBannerHeaderProps> = ({
  dismissible,
  expandable,
  onDismiss,
  onToggleOpen,
  text,
  theme
}) => {
  const bannerHeaderContentClasses = classnames(
    'container flex min-h-10 flex-1 items-center gap-1 py-1',
    { 'justify-center': expandable }
  );

  const bannerTextColors = {
    'text-black': theme === 'general',
    'text-white': theme === 'critical' || theme === 'probono'
  };

  const bannerHeaderTextClasses = classnames(
    'text-left',
    'sm:text-center',
    { 'w-full': !expandable },
    {
      ...bannerTextColors
    }
  );

  const header: Variants = {
    open: {
      opacity: 0,
      pointerEvents: 'none'
    },
    closed: {
      opacity: 1,
      pointerEvents: 'all'
    }
  };

  return (
    <motion.section className={bannerHeaderContentClasses} variants={header}>
      <ExpandableRichText className={bannerHeaderTextClasses} content={text} />
      {!expandable && dismissible && (
        <button aria-label="dismiss" onClick={onDismiss} tabIndex={2}>
          <Icon name="close" size="medium" className={classnames({ ...bannerTextColors })} />
        </button>
      )}
      {expandable && (
        <button aria-label="expand" onClick={onToggleOpen} tabIndex={2}>
          <Icon name="chevron-down" size="medium" className={classnames({ ...bannerTextColors })} />
        </button>
      )}
    </motion.section>
  );
};

export default GlobalBannerHeader;
