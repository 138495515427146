import Text from '@/components/cms/Text';
import { TileBlock as TileBlockProps } from '@/@types/content';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import Icon from '@/components/ui/Icon';
import ContentArea from '@/components/cms/ContentArea';
import { ResponsiveImageProps } from '@/components/ui/ResponsiveImage';
import Button from '@/components/ui/Buttons/Button';
import type { RequestAQuoteModalBlock as RequestAQuoteModalBlockType } from '@/@types/content';
import RequestAQuoteModalBlock from '@/components/blocks/RequestAQuoteModalBlock';

enum Variation {
  Image = 'image',
  Text = 'text'
}

enum Layout {
  TwoColumns = '2-columns',
  ThreeColumns = '3-columns',
  FourColumns = '4-columns'
}

const TileBlock: React.FC<TileBlockProps> = ({
  variation = Variation.Text,
  heading,
  copy,
  layout = Layout.FourColumns,
  items = [],
  ctaList = [],
  featuredItems = [],
  ctaListInLine,
  requestAQuote
}: TileBlockProps) => {
  const editMode = isEditOrPreviewMode();
  const requestAQuoteParams = requestAQuote?.[0] as RequestAQuoteModalBlockType;

  const layoutClasses = classnames(
    'grid grid-cols-1',
    {
      'gap-4': variation === Variation.Image,
      'gap-6': variation === Variation.Text
    },
    {
      'sm:grid-cols-2': variation === Variation.Image
    },
    'md:grid-cols-2',
    'lg:gap-6',
    {
      'lg:grid-cols-2': layout === Layout.TwoColumns,
      'lg:grid-cols-3': layout === Layout.ThreeColumns,
      'lg:grid-cols-4': layout === Layout.FourColumns
    }
  );

  let imageSizes: ResponsiveImageProps['imageSizes'] = {
    xs: '180px',
    sm: '110px',
    md: '240px',
    lg: '500px'
  };

  if (layout === Layout.ThreeColumns) {
    imageSizes = {
      xs: '180px',
      sm: '110px',
      md: '240px',
      lg: '340px'
    };
  }

  if (layout === Layout.FourColumns) {
    imageSizes = {
      xs: '180px',
      sm: '110px',
      md: '240px'
    };
  }

  const featuredItemsClasses = classnames(
    {
      'pt-4': variation === Variation.Image,
      'pt-6': variation === Variation.Text
    },
    'lg:pt-6'
  );

  return (
    <div className={classnames('container py-10', 'lg:py-16')}  data-component={"TileBlock"}>
      <div className="flex flex-col gap-8">
        <div
          className={classnames('flex flex-col gap-2', {
            'flex !flex-row flex-wrap items-center justify-between': requestAQuoteParams
          })}
        >
          <div
            className={classnames({
              'max-w-[845px]': requestAQuoteParams
            })}
          >
            {heading || ctaListInLine || editMode ? (
              <div className="flex flex-col justify-between gap-8 md:flex-row md:items-end md:gap-0">
                {heading && (
                  <Text propertyName="Heading" element="h2" className="heading-5">
                    {heading}
                  </Text>
                )}
                {ctaListInLine && (
                  <Button
                    propertyName="CallToAction"
                    color="outline-black"
                    label={ctaListInLine.text}
                    href={ctaListInLine?.href}
                    title={ctaListInLine?.title}
                    target={ctaListInLine?.target}
                    size="large"
                  />
                )}
              </div>
            ) : null}

            {copy || editMode ? (
              <Text
                propertyName="Copy"
                element="p"
                className={classnames('text-1 mt-2 text-gray-darker')}
              >
                {copy}
              </Text>
            ) : null}
          </div>

          {requestAQuoteParams ? (
            <RequestAQuoteModalBlock
              {...requestAQuoteParams}
              triggerButton={
                <Button
                  className="mt-8 lg:mt-0"
                  label={requestAQuoteParams.heading}
                  size="large"
                  color="outline-gray"
                />
              }
            />
          ) : null}
        </div>

        <ContentArea
          components={items}
          propertyName="Items"
          element="div"
          className={layoutClasses}
          componentsProps={{ layout, overrideImageSizes: imageSizes }}
        />

        {ctaList?.length || editMode ? (
          <div className={classnames('flex flex-col gap-2', 'sm:flex-row sm:gap-6')}>
            {ctaList.map((cta, index) => (
              <RouterLink
                key={index}
                to={cta.href as string}
                title={cta.title}
                target={cta.target}
                className={classnames(
                  'flex min-h-16 flex-1 items-center justify-between gap-6 rounded-lg border border-gray-light bg-gray-lightest px-4 py-2',
                  'sm:px-6'
                )}
              >
                <Text element="span" className="text-1">
                  {cta.text}
                </Text>
                <Icon name="chevron-right" size="large" />
              </RouterLink>
            ))}
          </div>
        ) : null}
      </div>
      <ContentArea
        components={featuredItems}
        propertyName="FeaturedItems"
        element="div"
        className={featuredItemsClasses}
        componentsProps={{ tilesBlock: true }}
      />
    </div>
  );
};

export default TileBlock;
