import { Certificate } from '@/@types/client-api';
import { FieldErrors, FieldNames } from '@credit-tracker/util/constants';
import { ErrorElementKeys, ErrorState } from '@/utils/validations';
import { isWithinInterval } from 'date-fns';

export const isDateWithinRange = (date: string, startDate?: string, endDate?: string): boolean => {
  const selectedDate = new Date(date);
  const start = startDate ? new Date(startDate).setHours(0, 0, 0, 0) : undefined;
  const end = endDate ? new Date(endDate) : undefined;

  return (
    !!start &&
    !!end &&
    isWithinInterval(selectedDate, {
      start: start,
      end: end
    })
  );
};

export const handleValidation = (
  certificate: Certificate,
  isCarryover: boolean = false,
  askTransitional: boolean = false,
  startDate?: string,
  endDate?: string,
  isCertificatesPage?: boolean
): FieldErrors => {
  const errorsFound: FieldErrors = {};

  if (!certificate.programName || certificate.programName.length < 3) {
    errorsFound[FieldNames.PROGRAM_NAME] = {
      message: 'Enter a Program name',
      showError: true,
      match: (value: string): boolean => !!value,
      forceMatch: true
    };
  }

  if (!certificate.certificateSource) {
    errorsFound[FieldNames.PROVIDER] = { message: 'Enter a Provider name', showError: true };
  }

  if (!certificate.dateCompleted) {
    const fieldName = isCarryover ? FieldNames.CARRYOVER_PERIOD : FieldNames.DATE_COMPLETED;
    const message = isCarryover ? 'Select a carryover period' : 'Enter a valid date';
    errorsFound[fieldName] = {
      message,
      showError: true,
      match: (value: string): boolean => !!value,
      forceMatch: true
    };
  } else if (
    !isCertificatesPage &&
    !isCarryover &&
    !isDateWithinRange(certificate.dateCompleted, startDate, endDate)
  ) {
    errorsFound[FieldNames.DATE_COMPLETED] = {
      message: 'Date outside compliance period',
      showError: true
    };
  }

  if (!certificate.format) {
    errorsFound[FieldNames.PROGRAM_FORMAT] = { message: 'Select a format', showError: true };
  }

  const invalidCreditFormatKeys: ErrorElementKeys = {},
    invalidCreditTypeKeys: ErrorElementKeys = {};
  const allFormatsInvalid = certificate.creditTypes?.every(a => !a.creditType_PK?.length);
  for (let i = 0; i < (certificate.creditTypes?.length ?? 0); i++) {
    const _type = certificate.creditTypes?.[i] ?? {};

    // format
    if (((_type.creditIssued ?? 0) > 0 && !_type.creditType_PK?.length) || allFormatsInvalid) {
      invalidCreditFormatKeys[i] = i;
    }
    //credit type
    if ((_type.creditIssued ?? 0) <= 0 && !!_type.creditType_PK) {
      invalidCreditTypeKeys[i] = _type.creditType_PK;
    }
  }

  if (Object.keys(invalidCreditFormatKeys).length) {
    errorsFound[FieldNames.CREDIT_TYPE_FORMAT] = {
      message: 'Select a credit type',
      elementKeys: invalidCreditFormatKeys,
      forceMatch: true,
      showError: true
    };
  }

  if (Object.keys(invalidCreditTypeKeys).length) {
    errorsFound[FieldNames.CREDIT_TYPE_EARNED] = {
      message: 'Enter Credits',
      showError: true,
      elementKeys: invalidCreditTypeKeys,
      forceMatch: true
    };
  }

  if (!!askTransitional && !certificate.transitionLevel && !isCarryover) {
    errorsFound[FieldNames.TRANSITIONAL] = { message: 'Select yes or no', showError: true };
  }

  return errorsFound;
};

export const handleErrors = (
  fieldName: FieldNames,
  fieldsWithErrors: FieldErrors,
  elementKeys?: ErrorElementKeys
): ErrorState[] => {
  const hasErrors = Object.keys(fieldsWithErrors?.[fieldName] || {}).length > 0;

  if (hasErrors) {
    const result = [
      {
        message: fieldsWithErrors?.[fieldName]?.message,
        match: fieldsWithErrors?.[fieldName]?.match,
        showError: true,
        forceMatch: fieldsWithErrors?.[fieldName]?.forceMatch
      }
    ];

    if (elementKeys) {
      const errorElementKeys = Object.keys(fieldsWithErrors?.[fieldName]?.elementKeys ?? {});
      const lookupKeys = Object.keys(elementKeys ?? {});

      for (const k of lookupKeys) {
        const found = errorElementKeys.find(a => a === k);
        if (found) {
          return result;
        }
      }
    } else {
      return result;
    }
  }
  return [];
};

export const validateCreditAmountInput = (num: string) => num.match(/^(\d*\.{0,1}\d{0,2}$)/);
