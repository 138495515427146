import { NavLink } from 'react-router-dom';
import Icon from '@/components/ui/Icon';
import BaseBox from '../../components/BaseBox';
import Shimmer from '@/components/ui/Shimmer';
import { getFormattedYear } from '@/lib/helpers/date';
import { useTrackViewCrossLink } from '@/analytics/pdp';
import { AnalyticsContext } from '@/analytics/constants';

interface OnDemandCardProps {
  url?: string;
  title?: string;
  publicationDate?: string;
  isLoading?: boolean;
  label?: string;
  context?: AnalyticsContext;
}

const OnDemandCard: React.FC<OnDemandCardProps> = ({
  url,
  title,
  publicationDate,
  isLoading,
  label,
  context
}) => {
  const trackViewCrossLink = useTrackViewCrossLink();

  if (isLoading) {
    return (
      <BaseBox.Card>
        <Shimmer className="size-full" />
      </BaseBox.Card>
    );
  }

  const handleClick = () => {
    trackViewCrossLink(context);
  };

  if (!url || !publicationDate || !title || !label) return <></>;

  const year = getFormattedYear(publicationDate);

  // NOTE: when moving to content edit (SIREHOME-1006) support {TOKEN} and {PROGRAMTITLE} tokens
  const cta = label.replace('{YEAR}', year).replace('{PROGRAMTITLE}', title);

  return (
    <BaseBox.Card>
      <NavLink
        to={url}
        className="flex h-[88px] w-full items-center justify-between gap-3 bg-gray-lightest px-8 py-6 hover:bg-gray-light"
        onClick={handleClick}
      >
        <div className="flex items-center gap-3">
          <Icon name="desktop" size="medium" />
          <p className="text-2 font-bold">{cta}</p>
        </div>
        <Icon name="chevron-right" size="medium" />
      </NavLink>
    </BaseBox.Card>
  );
};

export default OnDemandCard;
