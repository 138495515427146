import { CompliancePeriodValue } from '@/components/ui/CreditTracker/util/constants';
import { AnalyticsContext, AnalyticsEventName } from './constants';
import { trackEvent } from './utils';

export const trackViewCompliancePeriodEvent = (period?: CompliancePeriodValue) => {
  if (period) {
    trackEvent({
      eventName: AnalyticsEventName.ViewCompliancePeriod,
      parameters: {
        period
      }
    });
  }
};

export const trackEditCompliancePeriodEvent = (context?: AnalyticsContext) => {
  if (context) {
    trackEvent({
      eventName: AnalyticsEventName.EditCompliancePeriod,
      parameters: {
        context
      }
    });
  }
};

export const trackViewTrackerEvent = (context?: AnalyticsContext, license?: string) => {
  if (context && license) {
    trackEvent({
      eventName:
        context === AnalyticsContext.NonCompliance
          ? AnalyticsEventName.ViewTracker
          : AnalyticsEventName.ViewTrackerCompliance,
      parameters: {
        context,
        license
      }
    });
  }
};

export const trackViewRequirementsEvent = (context?: AnalyticsContext, license?: string) => {
  if (context && license) {
    trackEvent({
      eventName: AnalyticsEventName.ViewRequirements,
      parameters: {
        context,
        license
      }
    });
  }
};

export const trackEarnCreditEvent = (context?: AnalyticsContext, license?: string) => {
  if (context && license) {
    trackEvent({
      eventName: AnalyticsEventName.EarnCredit,
      parameters: {
        context,
        license
      }
    });
  }
};

export const trackAddExternalEvent = (context?: AnalyticsContext) => {
  if (context) {
    trackEvent({
      eventName: AnalyticsEventName.AddExternal,
      parameters: {
        context
      }
    });
  }
};

export const trackAddCarryoverEvent = (context?: AnalyticsContext) => {
  if (context) {
    trackEvent({
      eventName: AnalyticsEventName.AddCarryover,
      parameters: {
        context
      }
    });
  }
};

export const trackViewPromotionEvent = () => {
  trackEvent({
    eventName: AnalyticsEventName.ViewPromotion,
    parameters: {}
  });
};

export const trackSelectPromotionEvent = () => {
  trackEvent({
    eventName: AnalyticsEventName.SelectPromotion,
    parameters: {}
  });
};

export const trackViewAllCrediTrackerEvent = (region?: string, crediType?: string) => {
  if (region && crediType) {
    trackEvent({
      eventName: AnalyticsEventName.ViewAllClick,
      parameters: {
        region,
        crediType
      }
    });
  }
};

export const trackNavTrackerMiniEvent = (region: string | null | undefined) => {
  if (region) {
    trackEvent({
      eventName: AnalyticsEventName.NavTrackerMini,
      parameters: {
        region
      }
    });
  }
};
