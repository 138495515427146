import { useMemo } from 'react';
import { FilterProps } from '../../Filter/types';
import { translateStoreFilterToComponentProps } from '../utils/translateStoreDataToComponentsProps';
import useUnfoldedFilterGroup from './useUnfoldedFilterGroup';
import { FILTERS } from '../constants';
import { useSelector } from 'react-redux';
import { selectSearchInvariantFilters } from '@/redux/selectors/searchSelectors';

const useSearchFilterPanelGroups = ({
  filters: originalFilters,
  skip
}: {
  filters?: FilterProps[];
  skip: boolean;
}) => {
  const invariantFilters = useSelector(selectSearchInvariantFilters);
  // If we didn't get any jurisdiction info from the BE, it means either:
  // 1. user does not have any profile or ad-hoc jurisdictions
  // 2. there are no jurisdictions available for the user's search
  // In the case of #1 we want to still show the "See all" link leading to the L2 panel, so we execute another request here to get the full unfolded list of jurisdictions
  const shouldCheckForUnfoldedJurisdictions =
    originalFilters &&
    !originalFilters.some(filter => filter.groupTypeId === FILTERS.JURISDICTIONS) &&
    !invariantFilters.some(f => f.facetGroupType === FILTERS.JURISDICTIONS);

  const { filterGroup: unfoldedJurisdictions } = useUnfoldedFilterGroup({
    groupId: shouldCheckForUnfoldedJurisdictions ? FILTERS.JURISDICTIONS : undefined,
    skip
  });

  const filters = useMemo(() => {
    if (
      !shouldCheckForUnfoldedJurisdictions ||
      !unfoldedJurisdictions?.facets?.filter(f => !f.disabled).length
    ) {
      return originalFilters?.map(filter => ({
        ...filter,
        title:
          filter.title ||
          // There's a moment where the FE doesn't have the jurisdiction info yet from the main (L1 response) and we need to supplement with the unfolded L2 title
          (filter.groupTypeId === FILTERS.JURISDICTIONS ? unfoldedJurisdictions?.groupTitle : '')
      }));
    }

    return [
      translateStoreFilterToComponentProps({ ...unfoldedJurisdictions, facets: [] }),
      ...(originalFilters || [])
    ];
  }, [shouldCheckForUnfoldedJurisdictions, originalFilters, unfoldedJurisdictions]);

  return {
    filters
  };
};

export default useSearchFilterPanelGroups;
