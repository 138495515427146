import { UserCommunicationPreferences } from '@/@types/client-api';
import { ClientAPI } from '..';

const userCommunicationPreferencesEndpoint = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getUserCommunicationPreferences: builder.query<UserCommunicationPreferences, void>({
      query: () => ({
        url: '/user/communication-preferences',
        method: 'GET'
      })
    }),
    updateUserCommunicationPreferences: builder.mutation<
      UserCommunicationPreferences,
      UserCommunicationPreferences
    >({
      query: body => ({
        url: '/user/communication-preferences',
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useGetUserCommunicationPreferencesQuery,
  useUpdateUserCommunicationPreferencesMutation
} = userCommunicationPreferencesEndpoint;

export default userCommunicationPreferencesEndpoint;
