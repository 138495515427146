import { useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import Divider from '../../Divider';
import RoleGroup, { RoleGroupProps } from './RoleGroup';
import Icon from '../../Icon';

interface ProgramFacultyProps {
  labels: {
    showMore: string;
    showLess: string;
  };
  heading: string;
  memberGroups: RoleGroupProps[];
}

const ProgramFaculty: React.FC<ProgramFacultyProps> = ({ labels, heading, memberGroups }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <section id="faculty" className="container module-spacing-pdp">
      <h2 className="heading-5 mb-10">{heading}</h2>
      <Divider color="light" />
      {memberGroups[0] && <RoleGroup {...memberGroups[0]} />}

      {memberGroups.length > 1 && (
        <Collapsible.Root open={expanded} onOpenChange={setExpanded}>
          <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown">
            {memberGroups.slice(1).map((group, index) => (
              <RoleGroup key={index} {...group} />
            ))}
          </Collapsible.Content>
          <Collapsible.Trigger className="text-2-fixed mt-10 flex items-center font-bold">
            {expanded ? labels.showLess : labels.showMore}
            {expanded ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
          </Collapsible.Trigger>
        </Collapsible.Root>
      )}
    </section>
  );
};

export default ProgramFaculty;
