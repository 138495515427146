import type {
  CreditFormatPage as CreditFormatPageProps,
  JurisdictionCreditInfoPage
} from '@/@types/content';
import { Suspense, useMemo } from 'react';
import withNav from '@/components/hocs/withNav';
import useCreditInfo from '@/components/blocks/CreditInfo/hooks/useCreditInfoHook';
import { extractTextFromHTML, formatTcode } from '@/utils/helpers';
import CreditInfoHeader from '@/components/blocks/CreditInfo/snippets/CreditInfoHeader';
import { useGetAncestorsQuery } from '@/redux/api/contentDeliveryAPI';
import {
  creditInfoChildPageCDProps,
  creditTypePageCDProps
} from '@/components/blocks/CreditInfo/util/creditInfoConstants';
import JurisdictionCreditInfoPageComponent from '@/components/pages/JurisdictionCreditInfoPage';
import {
  HeaderLinksShimmer,
  SearchResultBlockLoadingShimmer
} from '@/components/blocks/CreditInfo/snippets/LoadingShimmers';
import ShareButton from '@/components/ui/Buttons/ShareButton';
import { Theme } from '@/styles/darkMode';
import { Helmet } from 'react-helmet';

const CreditFormatPage: React.FC<CreditFormatPageProps> = props => {
  const {
    hideInBreadcrumbs,
    sourceCode,
    keyCode,
    mainBody,
    name,
    key,
    contentLink,
    secondaryHeader,
    creditFormats,
    browserTitle
  } = props;
  const {
    renderMainContentArea,
    getApprovalMessage,
    renderHeaderLinks,
    getItemClassesForSearch,
    getAncestorHeaderLinks,
    getSidePanelMessages,
    getDocumentTitle,
    filteredBreadcrumbs,
    isChildItemsFetching,
    isChildItemsLoading
  } = useCreditInfo({
    ...props,
    childrenProperties: creditInfoChildPageCDProps
  });

  const {
    data: ancestors,
    isLoading: ancestorsLoading,
    isFetching: ancestorsFetching
  } = useGetAncestorsQuery({
    contentLink: contentLink?.guidValue ?? '',
    query: {
      select: creditTypePageCDProps
    }
  });

  const isLoading = useMemo(
    () => ancestorsLoading || ancestorsFetching || isChildItemsFetching || isChildItemsLoading,
    [ancestorsFetching, ancestorsLoading, isChildItemsFetching, isChildItemsLoading]
  );

  const { panelMessages, approvalMessage, iconLinks, parentPageName } = useMemo(() => {
    const parentRegionPage = ancestors?.find(a =>
      a.contentType?.some(b => b == JurisdictionCreditInfoPageComponent.displayName)
    ) as JurisdictionCreditInfoPage;
    if (!parentRegionPage) {
      return {};
    }
    const panelMessages = getSidePanelMessages(ancestors);
    const approvalMessage = getApprovalMessage(ancestors);
    const iconLinks = getAncestorHeaderLinks(ancestors);

    const parentPageName = parentRegionPage.secondaryHeader ?? parentRegionPage.name ?? '';

    return {
      panelMessages,
      iconLinks,
      approvalMessage,
      parentPageName
    };
  }, [ancestors, getAncestorHeaderLinks, getApprovalMessage, getSidePanelMessages]);

  const header = useMemo(() => {
    const text = extractTextFromHTML(secondaryHeader ?? name ?? mainBody ?? key ?? '');
    return `${text} for ${parentPageName}`;
  }, [key, mainBody, name, parentPageName, secondaryHeader]);

  return (
    <>
      <Helmet>
        <title>{browserTitle ?? getDocumentTitle(header)}</title>
        <meta name="title" content={browserTitle ?? getDocumentTitle(header)}></meta>
      </Helmet>
      <div className="container">
        <div className="flex flex-row flex-wrap items-end justify-between md:mb-16">
          <CreditInfoHeader
            isLoading={isLoading}
            header={header}
            breadcrumbs={!hideInBreadcrumbs ? filteredBreadcrumbs : []}
            subHeader={approvalMessage?.message}
            subHeadingIcon={approvalMessage?.icon}
            panelHeader={parentPageName}
            sidePanelMessages={panelMessages}
          />
          <div className="my-6 flex w-full flex-row items-end justify-start md:my-0 md:w-fit md:justify-end">
            {isLoading ? (
              <HeaderLinksShimmer />
            ) : (
              <Suspense fallback={<HeaderLinksShimmer />}>
                <div className="mr-6">{renderHeaderLinks(iconLinks ?? [])}</div>
                <ShareButton
                  className="text-link group text-2 pb-0 pt-2 font-medium [&>button]:!pb-0"
                  shareUrl={contentLink?.url ?? window.location.href}
                  theme={Theme.Light}
                  title={secondaryHeader}
                />
              </Suspense>
            )}
          </div>
        </div>
        {/* {renderTopicLinks(isLoading)} */}
        {isLoading ? (
          <SearchResultBlockLoadingShimmer />
        ) : (
          <div className="[&_.container]:!px-0">
            {renderMainContentArea(
              formatTcode(sourceCode, keyCode),
              false,
              false,
              getItemClassesForSearch(creditFormats)
            )}
          </div>
        )}
      </div>
    </>
  );
};

const CreditFormatPageComponent = withNav(CreditFormatPage);
CreditFormatPageComponent.displayName = 'CreditFormatPage';
export default CreditFormatPageComponent;
