import DateInput, { DateInputProps } from '@/components/ui/Form/DateInput';
import { Root } from '@radix-ui/react-form';
import { useCallback, useEffect, useState } from 'react';
import { formatDate, parseDate } from '@/utils/helpers';
import { isDayBetweenCalendarRange } from '../DatePicker/CalendarRange';

type DateFilterProps = Pick<DateInputProps, 'minDate' | 'maxDate' | 'isStartDate' | 'isEndDate'> & {
  label: string;
  value?: Date;
  disabled?: boolean;
  className?: string;
  onValueChange?: (value: string) => void;
};

const DateFilter = ({
  value,
  label,
  disabled,
  className,
  minDate,
  maxDate,
  isStartDate,
  isEndDate,
  onValueChange
}: DateFilterProps): JSX.Element => {
  const [dateInputValue, setDateInputValue] = useState<string>();

  useEffect(() => {
    setDateInputValue(value ? formatDate(value) : '');
  }, [value]);

  const handleOnValueChange = useCallback(
    (newValue: string) => {
      setDateInputValue(newValue);
      if (newValue === '') {
        onValueChange?.(newValue);
        return;
      }
      const date = parseDate(newValue);
      if (date && isDayBetweenCalendarRange(date, minDate, maxDate)) {
        onValueChange?.(newValue);
      }
    },
    [minDate, maxDate, onValueChange]
  );

  return (
    <Root>
      <DateInput
        id={label}
        name={label}
        className={className}
        minDate={minDate}
        maxDate={maxDate}
        label={label}
        value={dateInputValue}
        disabled={disabled}
        onChange={handleOnValueChange}
        isStartDate={isStartDate}
        isEndDate={isEndDate}
      />
    </Root>
  );
};

export default DateFilter;
