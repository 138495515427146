import { useEffect, useMemo, useRef, useState } from 'react';
import { SwiperContainer, register } from 'swiper/element/bundle';
import classnames from 'classnames';
import Pagination from '../Pagination';
import { SwiperOptions } from 'swiper/types';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

interface Quote {
  content: string;
  author: string;
  company?: string;
}

interface TestimonialsProps {
  heading: string;
  quotes: Quote[];
  withinOverview?: boolean;
}

const Testimonials: React.FC<TestimonialsProps> = ({ heading, quotes, withinOverview }) => {
  const testimonialsId = 'testimonials';
  const isMobile = useMediaQuery(ScreenSizeQueries.xsOnly);
  const swiperRef = useRef<SwiperContainer>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (!swiperRef.current) return;
    register();

    // Object with parameters
    const params: SwiperOptions = {
      allowTouchMove: false
    };

    Object.assign(swiperRef.current, params);
    swiperRef.current.initialize();

    swiperRef.current.addEventListener('swiperslidechange', () => {
      setCurrentSlide(swiperRef.current?.swiper.activeIndex || 0);
    });
  }, []);

  const groupedQuotes = useMemo(() => {
    const grouped = [];
    for (let i = 0; i < quotes.length; i += 2) {
      grouped.push(quotes.slice(i, i + 2));
    }
    return grouped;
  }, [quotes]);

  return (
    <section
      id={withinOverview ? '' : testimonialsId}
      className={classnames(
        'container module-spacing-pdp justify-between gap-[21px] bg-sherpa-blue text-white lg:flex lg:py-12',
        {
          'sm:flex': quotes.length === 1
        }
      )}
      data-component={"Testimonials"}
    >
      <div className="mb-10 flex shrink-0 items-center justify-between lg:mb-0 lg:flex-col lg:items-start">
        <h2
          className={classnames('heading-4 max-w-[273px] lg:max-w-[200px]', {
            'sm:max-w-[3520px]': quotes.length === 1,
            'sm:max-w-none': quotes.length > 1
          })}
        >
          {heading}
        </h2>
        {quotes.length > 2 && !isMobile && (
          <Pagination
            count={currentSlide + 1 > quotes.length ? quotes.length : currentSlide + 1}
            totalCount={Math.ceil(quotes.length / 2)}
            onClickPrev={() => {
              swiperRef.current?.swiper.slidePrev();
            }}
            onClickNext={() => {
              swiperRef.current?.swiper.slideNext();
            }}
            prevDisabled={currentSlide === 0}
            nextDisabled={currentSlide + 1 >= quotes.length / 2}
            color="light"
            className="mt-10 !w-auto !py-0"
          />
        )}
      </div>
      <div className="w-full min-w-px">
        <swiper-container ref={swiperRef} init={false}>
          {groupedQuotes.map((quoteGroup, index) => (
            <swiper-slide key={index}>
              <div
                className={classnames('gap-[21px]', {
                  'justify-end sm:flex': quotes.length === 1,
                  'sm:grid sm:grid-cols-2': quotes.length > 1
                })}
              >
                {quoteGroup.map((quote, subIndex) => (
                  <div
                    key={subIndex}
                    className={classnames({
                      'sm:max-w-[311px] md:max-w-[419px]': quotes.length === 1,
                      'max-sm:mt-10': subIndex % 2 !== 0
                    })}
                  >
                    <p
                      className="text-1-medium"
                      dangerouslySetInnerHTML={{ __html: quote.content }}
                    />
                    <div className="text-1 mt-6 flex gap-1 lg:text-2 lg:mt-10">
                      <span>–</span>
                      <div>
                        <p>{quote.author}</p>
                        <p className="label text-silver">{quote.company}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </swiper-slide>
          ))}
        </swiper-container>
      </div>
      {quotes.length > 2 && isMobile && (
        <div className="mt-10 flex justify-start">
          <Pagination
            count={currentSlide + 1 > quotes.length ? quotes.length : currentSlide + 1}
            totalCount={Math.ceil(quotes.length / 2)}
            onClickPrev={() => {
              swiperRef.current?.swiper.slidePrev();
            }}
            onClickNext={() => {
              swiperRef.current?.swiper.slideNext();
            }}
            prevDisabled={currentSlide === 0}
            nextDisabled={currentSlide + 1 >= quotes.length / 2}
            color="light"
            className="!w-auto !py-0"
          />
        </div>
      )}
    </section>
  );
};

export default Testimonials;
