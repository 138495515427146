import {
  BreakpointObjectType,
  Breakpoints,
  ScreenSizeQueries,
  ScreenSizesMinimums
} from '@/constants/breakpoints';

interface ImageParams {
  width?: number;
  ratio?: number;
}
const IMAGE_PARAM_DEFAULTS: ImageParams = { width: 400, ratio: 1 };

const srcSetWidths = [180, 360, 480, 640, 960, 1280, 1600, 1920, 2560, 3840];

export const srcSetFromWidths = (url: string, params?: ImageParams, widths = srcSetWidths) => {
  if (widths.length === 0 || !url) {
    return;
  }

  return widths.map(width => `${apiImageFor(url, { ...params, width })} ${width}w`).join(',\n');
};

const apiImageFor = (url: string, imageParams: ImageParams = IMAGE_PARAM_DEFAULTS) => {
  const newUrl = new URL(url, window.location.origin);

  if (imageParams.width) {
    newUrl.searchParams.append('width', imageParams.width.toString());

    const ratio = imageParams.ratio || 1;
    const height = Math.round(imageParams.width / ratio);
    newUrl.searchParams.append('height', height.toString());
  }
  //use webp format when possible
  if (!url.includes('.svg') && !url.includes('data:image') && !urlHasQueryString(url, 'format')) {
    newUrl.searchParams.append('format', 'webp');
  }

  return newUrl.toString();
};

const sizeForMinWidth = (minWidth: string, size: string) => {
  return `(min-width: ${minWidth}) ${size}`;
};

// We want to control the order here because the sizes are cascading
// and we want to make sure the smallest size is last.
const breakpoints: Breakpoints[] = ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'];

export const sizesFromBreakpoints = (sizes?: BreakpointObjectType) => {
  // If there are no sizes, or all sizes are 100vw, we don't need to specify
  if (
    !sizes ||
    Object.keys(sizes).length === 0 ||
    Object.values(sizes).every(size => !size || size === '100vw')
  ) {
    return;
  }

  const sizeAttr = breakpoints.flatMap(size => {
    const minWidth = ScreenSizesMinimums[size];
    const sizeForWidth = sizes[size];

    // Ignore missing values so we can respect the cascade
    // without needing to specify all sizes
    if (!minWidth || !sizeForWidth) {
      return [];
    }

    return sizeForMinWidth(minWidth, sizeForWidth);
  });

  // Add the default size (all screen sizes < xs)
  sizeAttr.push('100vw');

  return sizeAttr.join(', ');
};

export type ImagesSourceType = {
  [key in Breakpoints]?: {
    src: string;
    aspectRatio?: number;
  };
};

type SourcesFromBreakpointsType = {
  src: string;
  media: string;
  srcSet?: string;
  breakpoint: Breakpoints;
};

export const sourcesFromBreakpoints = (sources?: ImagesSourceType) => {
  if (!sources || Object.keys(sources).length === 0) {
    return;
  }

  const imageSources: SourcesFromBreakpointsType[] = [];

  breakpoints.forEach(bp => {
    const sourceValue = sources[bp];

    if (sourceValue) {
      const media = ScreenSizeQueries[bp];
      const srcSet = srcSetFromWidths(sourceValue.src, { ratio: sourceValue.aspectRatio });
      imageSources.push({ src: sourceValue.src, media, srcSet, breakpoint: bp });
    }
  });

  return imageSources;
};

export const appendWebpFormat = (src?: string): string => {
  try {
    if (!src) return '';
    if (src.includes('.svg') || src.includes('data:image')) return src;
    const newUrl = new URL(src, window.location.origin);
    if (!newUrl.searchParams.has('format')) {
      newUrl.searchParams.append('format', 'webp');
    }
    return newUrl.toString();
  } catch (e) {
    return src!;
  }
};

export const urlHasQueryString = (url: string, key: string) => {
  try {
    const urlObj = new URL(url, window.location.origin);
    return urlObj.searchParams.has(key);
  } catch (e) {
    return false;
  }
};

export type BreakpointFlags = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
};

export const getFallbackSrcWhenSrcSetUnsupported = (
  src: string,
  aspectRatio: number,
  imageSizes: BreakpointObjectType | undefined,
  breakpoints: BreakpointFlags
) => {
  const { xs, sm, md, lg, xl, xxl } = breakpoints;

  const availableSizes = Object.values(imageSizes || {}).map(size =>
    parseInt(size.replace(/\D/g, ''))
  );
  let selectedWidth = Math.max(...availableSizes);

  if (xxl && imageSizes?.['2xl']) selectedWidth = parseInt(imageSizes['2xl'].replace(/\D/g, ''));
  else if (xl && imageSizes?.xl) selectedWidth = parseInt(imageSizes.xl.replace(/\D/g, ''));
  else if (lg && imageSizes?.lg) selectedWidth = parseInt(imageSizes.lg.replace(/\D/g, ''));
  else if (md && imageSizes?.md) selectedWidth = parseInt(imageSizes.md.replace(/\D/g, ''));
  else if (sm && imageSizes?.sm) selectedWidth = parseInt(imageSizes.sm.replace(/\D/g, ''));
  else if (xs && imageSizes?.xs) selectedWidth = parseInt(imageSizes.xs.replace(/\D/g, ''));

  const selectedHeight = Math.round(selectedWidth / aspectRatio);
  return `${appendWebpFormat(src)}&width=${selectedWidth}&height=${selectedHeight}`;
};
