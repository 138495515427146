import { EcommPanelParams } from './useEcommData';
import { MaterialsModalParams } from '../SearchMaterialsModal';
import { AddToCalendarParams } from '../AddToCalendarModal';
import { DeleteProgramModalParams } from '../DeleteProgramModalWrapper';
import useModalPanelState from './useModalPanelState';

export type SearchModalPanelState = ReturnType<typeof useSearchModalPanelState>;

const useSearchModalPanelState = () => {
  const ecommPanel = useModalPanelState<EcommPanelParams>();
  const materialsModal = useModalPanelState<MaterialsModalParams>();
  const addToCalendarModal = useModalPanelState<AddToCalendarParams>();
  const deleteModal = useModalPanelState<DeleteProgramModalParams>();

  return {
    ecommPanel,
    materialsModal,
    addToCalendarModal,
    deleteModal
  };
};

export default useSearchModalPanelState;
