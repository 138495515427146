import { BreadcrumbProps } from '@/components/ui/Breadcrumbs';
import CreditClassPageComponent from '@/components/pages/CreditClassPage';
import CreditInfoRiverPageComponent from '@/components/pages/CreditInfoRiverPage';
import JurisdictionCreditInfoPageComponent from '@/components/pages/JurisdictionCreditInfoPage';
import { PreselectTabQueryKey } from './creditInfoConstants';

export const formatBreadCrumbs = (breadcrumbs: BreadcrumbProps[], hideCurrent: boolean = false) => {
  const temp = breadcrumbs?.filter(a => a.objectType != 'StartPage');

  if (!temp?.length) return [];

  if (temp[temp.length - 1].objectType == JurisdictionCreditInfoPageComponent.displayName)
    temp.splice(temp.length - 1);

  if (hideCurrent) {
    temp.splice(temp.length - 1, 1);
  }

  const firstRegionPageIndex = temp.findIndex(
    a => a.objectType == JurisdictionCreditInfoPageComponent.displayName
  );
  if (firstRegionPageIndex >= 0) {
    const item = temp[firstRegionPageIndex];
    temp[firstRegionPageIndex] = {
      ...item,
      url: `${item.url}?${PreselectTabQueryKey}=false`
    };
  }

  const riverPageIndex = temp.findIndex(
    a => a.objectType == CreditInfoRiverPageComponent.displayName
  );
  const firstCreditClassPageIndex = temp.findIndex(
    a => a.objectType == CreditClassPageComponent.displayName
  );
  if (riverPageIndex >= 0 && firstCreditClassPageIndex == riverPageIndex + 1) {
    const lastUrl = temp[firstCreditClassPageIndex]?.url;
    const classSegment = lastUrl?.substring(lastUrl?.lastIndexOf('/') + 1);

    temp.splice(firstCreditClassPageIndex, 1);

    temp[riverPageIndex] = {
      ...temp[riverPageIndex],
      url: `${temp[riverPageIndex].url}?activeTab=${classSegment}`
    };
  }

  return temp;
};
