import { useCallback, useMemo } from 'react';
import ViewAllModal from '../SearchModals/ViewAllModal';
import { FILTERS } from '../SearchResults/constants';
import { FilterButtonGroupProps } from './types';
import Modal, { ModalProps } from '../Modal';
import { groupFilterItemsIntoSections } from '../SearchResults/utils/filters';
import { useDispatch } from 'react-redux';
import {
  clearFindAProgramFilters,
  toggleFindAProgramFilter
} from '@/redux/slices/findAProgramSlice';
import { FilterContext, SearchContext } from '@/analytics/constants';

type ViewAllCreditTypesModalProps = ModalProps & {
  filterGroups?: FilterButtonGroupProps[];
  fetching?: boolean;
  triggerButton: JSX.Element | null;
  context?: FilterContext;
  searchContext?: SearchContext;
};

const ViewAllCreditTypesModal = ({
  open,
  setOpen,
  filterGroups,
  fetching,
  triggerButton,
  context,
  searchContext
}: ViewAllCreditTypesModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const creditTypeFilterGroup = useMemo(
    () => filterGroups?.find(group => group.groupTypeId === FILTERS.CREDIT_TYPE),
    [filterGroups]
  );

  const groups = useMemo(() => {
    return groupFilterItemsIntoSections(creditTypeFilterGroup?.filters ?? []);
  }, [creditTypeFilterGroup?.filters]);

  const handleClearAllFilters = useCallback(() => {
    dispatch(clearFindAProgramFilters({ groupId: FILTERS.CREDIT_TYPE, context, searchContext }));
  }, [context, dispatch, searchContext]);

  const handleOnValueChanged = useCallback(
    (value?: string) => {
      const item = creditTypeFilterGroup?.filters.find(filter => filter.value === value);
      if (item?.label && value) {
        dispatch(
          toggleFindAProgramFilter({
            groupTypeId: FILTERS.CREDIT_TYPE,
            value,
            name: item.label,
            context,
            searchContext
          })
        );
      }
    },
    [dispatch, creditTypeFilterGroup, context, searchContext]
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Trigger asChild>{triggerButton}</Modal.Trigger>
      <ViewAllModal
        title="Credit types"
        groups={groups}
        allDefaultOpen
        onClearAllFilters={handleClearAllFilters}
        onValueChange={handleOnValueChanged}
        fetching={fetching}
        hasTransition={true}
      />
    </Modal>
  );
};

export default ViewAllCreditTypesModal;
