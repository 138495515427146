import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import classnames from 'classnames';
import React from 'react';

interface BlockProps extends React.HTMLProps<HTMLDivElement> {
  element?: 'section' | 'div' | React.ElementType;
  contentLinkID?: number;
}

const Block: React.FC<BlockProps> = ({ element, contentLinkID, children, className, ...props }) => {
  const isEditMode = isEditOrPreviewMode();
  const Element = element || 'section';

  const conditionalProps =
    isEditMode && contentLinkID ? { ...props, 'data-epi-block-id': contentLinkID } : props;

  if (!isEditMode && !children) return null;

  return (
    <Element {...conditionalProps} className={classnames(className, 'relative')}>
      {children}
    </Element>
  );
};

export default Block;
