import { useGetUpcomingRegistrationsQuery } from '@/redux/api/client/registration';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAuth } from 'react-oidc-context';

export const useGetRegistrations = () => {
  const auth = useAuth();

  const { data, isFetching } = useGetUpcomingRegistrationsQuery(
    !auth.isAuthenticated ? skipToken : undefined
  );

  return {
    registrations: data,
    isRegistrationsFetching: isFetching
  };
};
