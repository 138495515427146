import * as Tabs from '@radix-ui/react-tabs';
import { useCallback, useEffect, useState } from 'react';
import { ProgramMaterial } from '@/@types/client-api';
import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';
import classnames from 'classnames';
import Button from '@/components/ui/Buttons/Button';
import Divider from '@/components/ui/Divider';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { AnalyticsContext } from '@/analytics/constants';
import { useFetchAnalyticsItems } from '@/analytics/useFetchAnalyticsItems';
import {
  trackDownloadMaterialsEvent,
  trackReadNowEvent,
  trackViewMaterialsEvent
} from '@/analytics/library';
import Shimmer from '../../Shimmer';

export type ProgramMaterialLabels = {
  courseMaterialsTab: string;
  presentationMaterialsTab: string;
  readOnPlus: string;
  downloadFile: string;
};

type ProgramMaterialItemProps = {
  programCode?: string;
  material: ProgramMaterial;
  labels: ProgramMaterialLabels;
  context?: AnalyticsContext;
};

export type ProgramMaterialsModalProps = ModalProps & {
  programCode?: string;
  programTitle: string;
  materials: ProgramMaterial[];
  labels: ProgramMaterialLabels;
  isLoading?: boolean;
  triggerButton?: JSX.Element;
  customModalId?: string;
  context?: AnalyticsContext;
};

enum MaterialType {
  File = 'File',
  Plus = 'PliPlus'
}

const EmptyState = ({ programTitle }: { programTitle: string }) => (
  <div className="text-1 flex flex-col gap-y-5 text-gray-dark">
    <p>{programTitle} does not have any materials associated to this program currently.</p>
    <p>
      If materials become available for this program, they will be provided closer to or during the
      event.
    </p>
    <Modal.Trigger asChild>
      <div className="absolute inset-x-5 bottom-8 sm:inset-x-16  sm:bottom-16 ">
        <Button label="Close" size="large" className="w-full" />
      </div>
    </Modal.Trigger>
  </div>
);

const useSharedTrackEvent = (isPlus: boolean, modalOpen?: boolean) => {
  const { getAnalyticItem } = useFetchAnalyticsItems();

  const trackEvent = useCallback(
    async (code: string, context?: AnalyticsContext) => {
      const data = await getAnalyticItem(code);
      if (data) {
        if (modalOpen) {
          trackViewMaterialsEvent(data, context);
        } else if (isPlus) {
          trackReadNowEvent(data, context);
        } else {
          trackDownloadMaterialsEvent(data, context);
        }
      }
    },
    [getAnalyticItem, isPlus, modalOpen]
  );

  return trackEvent;
};

const ProgramMaterialItem = ({
  material,
  labels,
  programCode,
  context
}: ProgramMaterialItemProps) => {
  const isXSmall = useMediaQuery(ScreenSizeQueries.xsOnly);
  const rowClass = classnames(isXSmall ? 'flex flex-col gap-4' : 'flex justify-between');
  const isPlus = Boolean(
    material.toPlus && (material.type as unknown as string) === MaterialType.Plus
  );
  const icon = isPlus ? 'link-out' : 'download';
  const label = isPlus ? labels.readOnPlus : labels.downloadFile;
  const isCompleteBook = material?.chapter === '999';
  const isPresentationMaterial = !material?.chapter;
  const materialTitleClass = classnames(
    isXSmall ? 'w-full' : 'w-[257px]',
    isCompleteBook ? 'text-2-medium' : 'text-2'
  );
  const materialTitle =
    isCompleteBook || isPresentationMaterial
      ? material.title
      : `(Ch.${material.chapter}) ${material.title}`;

  const trackEvent = useSharedTrackEvent(isPlus);

  const trackMaterialEvent = useCallback(() => {
    if (programCode && context) {
      trackEvent(programCode, context);
    }
  }, [context, programCode, trackEvent]);

  return (
    <span className="flex flex-col gap-8">
      <span className={rowClass}>
        <span className="flex flex-col">
          <span className={materialTitleClass}>{materialTitle}</span>
          {!isCompleteBook ? <span className={materialTitleClass}>{material.author}</span> : <></>}
        </span>
        <Button
          aria-label={`${materialTitle} - ${label}`}
          iconRight={icon}
          label={label}
          size="small"
          color="outline-black"
          onClick={trackMaterialEvent}
          href={material.url!}
          target="_blank"
        />
      </span>
      <Divider color={'light'} />
      <span />
    </span>
  );
};

const ProgramMaterialsModal = ({
  open,
  setOpen,
  programCode,
  programTitle,
  materials,
  labels,
  isLoading = false,
  triggerButton,
  customModalId,
  context
}: ProgramMaterialsModalProps): JSX.Element => {
  const courseMaterials = materials.filter(m => m.chapter?.length);
  const presentationMaterials = materials.filter(m => !m.chapter?.length);
  const tabTriggerClass = classnames(
    'text-2 border-b border-gray pb-[13px] pr-12 text-left font-medium text-gray hover:text-black data-[state=active]:border-black data-[state=active]:text-black'
  );
  const tabContentClass = classnames('hidden min-h-0 grow flex-col data-[state=active]:flex');
  const [selectedTab, setSelectedTab] = useState<string>(labels.courseMaterialsTab);
  const trackEventForModal = useSharedTrackEvent(false, open);

  useEffect(() => {
    if (open && programCode && context) {
      trackEventForModal(programCode, context);
    }
  }, [open, programCode, context, trackEventForModal]);

  useEffect(() => {
    if ((courseMaterials.length && presentationMaterials.length) || !presentationMaterials.length) {
      setSelectedTab(labels.courseMaterialsTab);
    } else if (!courseMaterials.length) {
      setSelectedTab(labels.presentationMaterialsTab);
    }
  }, [
    courseMaterials.length,
    labels.courseMaterialsTab,
    labels.presentationMaterialsTab,
    presentationMaterials.length
  ]);

  const programTitleLoadingState = (
    <div aria-label="loading materials">
      <Shimmer className="mb-2 h-10 w-4/5 rounded-md" />
      <Shimmer className="mb-2 h-10 w-[90%] rounded-md" />
      <Shimmer className="mb-4 h-10 w-[70%] rounded-md" />
    </div>
  );

  const contentLoadingState = (
    <div aria-label="loading materials">
      <div className="mb-12 flex gap-2">
        <Shimmer className="h-4 w-32 rounded-md" />
        <Shimmer className="h-4 w-32 rounded-md" />
      </div>
      <div className="flex gap-2">
        <div className="w-2/3">
          <Shimmer className="mb-4 h-4 w-[90%] rounded-md" />
          <Shimmer className="mb-4 h-4 w-[70%] rounded-md" />
          <Shimmer className="mb-4 h-4 w-4/5 rounded-md" />
        </div>
        <Shimmer className="h-6 w-44 rounded-md" />
      </div>
    </div>
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Trigger asChild>{triggerButton}</Modal.Trigger>
      <ModalContent
        customModalId={customModalId}
        title={
          materials.length
            ? programTitle
            : isLoading
              ? programTitleLoadingState
              : 'There are no materials at this time'
        }
        contentRelativelyPositioned={true}
      >
        {isLoading ? (
          contentLoadingState
        ) : materials.length ? (
          <Tabs.Root value={selectedTab} defaultValue="selector" onValueChange={setSelectedTab}>
            <Tabs.List className="z-1 flex items-stretch pb-8">
              {courseMaterials.length ? (
                <Tabs.Trigger
                  value={labels.courseMaterialsTab}
                  className={classnames(tabTriggerClass, { grow: !presentationMaterials.length })}
                >
                  {labels.courseMaterialsTab}
                </Tabs.Trigger>
              ) : (
                <></>
              )}

              {presentationMaterials.length ? (
                <Tabs.Trigger
                  value={labels.presentationMaterialsTab}
                  className={classnames(tabTriggerClass, 'grow')}
                >
                  {labels.presentationMaterialsTab}
                </Tabs.Trigger>
              ) : (
                <></>
              )}
            </Tabs.List>

            <Tabs.Content
              tabIndex={-1}
              value={labels.courseMaterialsTab}
              className={tabContentClass}
            >
              <ul>
                {courseMaterials.map(m => {
                  return (
                    <li key={m.url}>
                      <ProgramMaterialItem
                        material={m}
                        labels={labels}
                        programCode={programCode}
                        context={context}
                      />
                    </li>
                  );
                })}
              </ul>
            </Tabs.Content>
            <Tabs.Content value={labels.presentationMaterialsTab} className={tabContentClass}>
              <ul>
                {presentationMaterials.map(m => {
                  return (
                    <li key={m.url}>
                      <ProgramMaterialItem
                        material={m}
                        labels={labels}
                        programCode={programCode}
                        context={context}
                      />
                    </li>
                  );
                })}
              </ul>
            </Tabs.Content>
          </Tabs.Root>
        ) : (
          <EmptyState programTitle={programTitle} />
        )}
      </ModalContent>
    </Modal>
  );
};

export default ProgramMaterialsModal;
