import { CatalogSettings } from '@/@types/content';
import {
  LiveEcomLabels,
  MembershipCartText,
  OnDemandEcomLabels,
  ProgramExpiredLabels,
  PublicationEcomLabels
} from '@/components/ui/PDP/labels';
import { useGetGlobalElementQuery } from '@/redux/api/contentDeliveryAPI';

export const useCatalogLabels = () => {
  const { data: labelsData, isLoading } = useGetGlobalElementQuery({
    name: 'catalog-labels',
    userId: 'ANON'
  });
  const cmsLabels = labelsData as CatalogSettings;

  const cmsEnrichedLiveLabels = {
    ...LiveEcomLabels,
    registerButton: cmsLabels?.registerButtonText ?? LiveEcomLabels.registerButton,
    purchaseButton: cmsLabels?.programAddToCartButtonText ?? LiveEcomLabels.purchaseButton,
    launchButton: cmsLabels?.launchButtonText ?? LiveEcomLabels.launchButton,
    privilegedMembers:
      cmsLabels?.privilegedMembershipAdviceText ?? LiveEcomLabels.privilegedMembers,
    seciWsDiscountAdvice:
      cmsLabels?.seciWsDiscountAdviceText ?? LiveEcomLabels.seciWsDiscountAdvice,
    extraMessage: cmsLabels?.additionalAdviceText,
    materials: cmsLabels?.accessMaterialsButtonText ?? LiveEcomLabels.materials,
    addToCalendar: cmsLabels?.addToCalendarButtonText ?? LiveEcomLabels.addToCalendar,
    ondemandCrosslink: cmsLabels?.onDemandCrosslink ?? LiveEcomLabels.ondemandCrosslink
  };

  const cmsEnrichedOnDemandLabels = {
    ...OnDemandEcomLabels,
    launchButton: cmsLabels?.launchButtonText ?? OnDemandEcomLabels.launchButton,
    purchaseButton: cmsLabels?.programAddToCartButtonText ?? OnDemandEcomLabels.purchaseButton,
    privilegedMembers:
      cmsLabels?.privilegedMembershipAdviceText ?? OnDemandEcomLabels.privilegedMembers,
    seciWsDiscountAdvice:
      cmsLabels?.seciWsDiscountAdviceText ?? OnDemandEcomLabels.seciWsDiscountAdvice,
    extraMessage: cmsLabels?.additionalAdviceText,
    materials: cmsLabels?.accessMaterialsButtonText ?? OnDemandEcomLabels.materials,
    upcomingLiveCrosslink:
      cmsLabels?.upcomingLiveCrosslink ?? OnDemandEcomLabels.upcomingLiveCrosslink,
    liveNowCrosslink: cmsLabels?.liveNowCrosslink ?? OnDemandEcomLabels.liveNowCrosslink
  };

  const cmsEnrichedProgramExpiredLabels = {
    ...ProgramExpiredLabels,
    headingProgram: cmsLabels?.expiredProgramHeader ?? ProgramExpiredLabels.headingProgram,
    bodyProgram: cmsLabels?.expiredProgramBody ?? ProgramExpiredLabels.bodyProgram,
    exploreButtonLabel:
      cmsLabels?.expiredProgramExploreButtonText ?? ProgramExpiredLabels.exploreButtonLabel,
    exploreButtonUrl:
      cmsLabels?.expiredProgramExploreButtonUrl ?? ProgramExpiredLabels.exploreButtonUrl
  };

  const cmsEnrichedPublicationEcomLabels = {
    ...PublicationEcomLabels,
    purchaseButton:
      cmsLabels?.publicationAddToCartButtonText ?? PublicationEcomLabels.purchaseButton,
    preorderPurchaseButton:
      cmsLabels?.publicationPreorderButtonText ?? PublicationEcomLabels.preorderPurchaseButton,
    readButton: cmsLabels?.readOnPlusButtonText ?? PublicationEcomLabels.readButton,
    upkeepAvailableTooltipTitle:
      cmsLabels?.upkeepServiceAvailableTooltipLabel ??
      PublicationEcomLabels.upkeepAvailableTooltipTitle,
    upkeepAvailableTooltipContent:
      cmsLabels?.upkeepServiceAvailableTooltipText ??
      PublicationEcomLabels.upkeepAvailableTooltipContent,
    internationalShippingTooltipTitle:
      cmsLabels?.internationalShippingWarningTooltipLabel ??
      PublicationEcomLabels.internationalShippingTooltipTitle,
    internationalShippingTooltipContent:
      cmsLabels?.internationalShippingWarningTooltipText ??
      PublicationEcomLabels.internationalShippingTooltipContent,
    plusToutRichText: cmsLabels?.plusToutRichText ?? PublicationEcomLabels.plusToutRichText
  };

  const otherLabels = {
    membershipCartText: cmsLabels?.membershipCartLabel ?? MembershipCartText
  };

  return {
    liveEcomLabels: cmsEnrichedLiveLabels,
    onDemandEcomLabels: cmsEnrichedOnDemandLabels,
    programExpiredLabels: cmsEnrichedProgramExpiredLabels,
    publicationEcomLabels: cmsEnrichedPublicationEcomLabels,
    otherLabels,
    isLoading
  };
};

export default useCatalogLabels;
