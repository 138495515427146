import { ClientAPI } from '@/redux/api';
import { UserProfile } from '@/@types/client-api';

const userProfileEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getUserProfile: builder.query<UserProfile, void>({
      query: () => ({
        url: '/user/profile',
        method: 'GET'
      })
    }),
    updateUserProfile: builder.mutation<UserProfile, UserProfile>({
      query: body => ({
        url: '/user/profile',
        method: 'PUT',
        body
      })
    })
  })
});

export const { useGetUserProfileQuery, useUpdateUserProfileMutation } = userProfileEndpoints;

export default userProfileEndpoints;
