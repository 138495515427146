import { format, isValid } from 'date-fns';
import { ICLEPeriod, IEvaluatedJurisdictionView } from '@/@types/client-api';
import { ComplianceMenuMessages } from '@/components/ui/CreditTracker/util/constants';

const notAvailableLabel = 'Not Set';

export const formatDate = (date?: string | Date | null) => {
  if (!date) return null;

  const _date = new Date(date);

  if (_date instanceof Date && !isNaN(_date.valueOf()))
    return format(new Date(_date), 'MMM d, yyyy');

  return null;
};

export const formatDateLabel = (
  isIncomplete: boolean,
  start?: string | null,
  end?: string | null
) => {
  if (isIncomplete) return ComplianceMenuMessages.NO_COMPLIANCE_PERIOD_SETUP;

  const formattedStart = formatDate(start);
  const formattedEnd = formatDate(end);

  return formattedStart && formattedEnd ? `${formattedStart} - ${formattedEnd}` : notAvailableLabel;
};

export const compareDates = <T extends Date | string>(date1?: T, date2?: T): number => {
  let formatted1: Date | undefined;
  let formatted2: Date | undefined;

  if (typeof date1 === 'string' && typeof date2 === 'string') {
    formatted1 = new Date(date1 ?? '');
    formatted2 = new Date(date2 ?? '');
  } else if (date1 instanceof Date && date2 instanceof Date) {
    formatted1 = date1;
    formatted2 = date2;
  }

  if (!formatted1 && !formatted2) return -1;
  if (!isValid(formatted1) || !isValid(formatted2)) return -1;

  const d1 = formatted1!.toISOString();
  const d2 = formatted2!.toISOString();
  return d1.localeCompare(d2);
};

export const isUsingDefaultDates = (compliancePeriod?: ICLEPeriod) =>
  compareDates(compliancePeriod?.defaultEndDate ?? '', compliancePeriod?.endDate ?? '') == 0 &&
  compareDates(compliancePeriod?.defaultStartDate ?? '', compliancePeriod?.startDate ?? '') == 0;

export const endDateMisaligned = (
  jurisdiction: IEvaluatedJurisdictionView,
  activeClePeriod: ICLEPeriod
) => {
  const current = jurisdiction?.clePeriod;
  const currentEnd = current?.endDate ?? current?.defaultEndDate ?? '';
  const currentStart = current?.startDate ?? current?.defaultStartDate ?? '';

  const next = jurisdiction?.clePeriod?.nextPeriod;
  const nextStart = next?.endDate ?? next?.defaultEndDate ?? '';

  const previous = jurisdiction?.clePeriod?.previousPeriod;
  const previousEnd = previous?.endDate ?? previous?.defaultEndDate ?? '';

  if (activeClePeriod.id == current?.id) {
    return compareDates(nextStart, currentEnd) < 0;
  } else if (activeClePeriod.id == previous?.id) {
    return compareDates(previousEnd, currentStart) > 0;
  }
};

export const startDateMisaligned = (
  jurisdiction: IEvaluatedJurisdictionView,
  activeClePeriod: ICLEPeriod
) => {
  const current = jurisdiction?.clePeriod;
  const currentEnd = current?.endDate ?? current?.defaultEndDate ?? '';
  const currentStart = current?.startDate ?? current?.defaultStartDate ?? '';

  const next = jurisdiction.clePeriod?.nextPeriod;
  const nextStart = next?.endDate ?? next?.defaultEndDate ?? '';

  const previous = jurisdiction.clePeriod?.previousPeriod;
  const previousEnd = previous?.endDate ?? previous?.defaultEndDate ?? '';

  if (activeClePeriod.id == current?.id) {
    return compareDates(currentStart, previousEnd) < 0;
  } else if (activeClePeriod.id == next?.id) {
    return compareDates(nextStart, currentEnd) > 0;
  }
};

export const periodDateRangeIsInvalid = <T extends Date | string>(start?: T, end?: T): boolean => {
  if (typeof start === 'string' && typeof end === 'string') return compareDates(start, end) > 0;
  else if (start instanceof Date && end instanceof Date) return compareDates(start, end) > 0;

  return false;
};

export const endDate = (period?: ICLEPeriod) => period?.endDate ?? period?.defaultEndDate;
export const startDate = (period?: ICLEPeriod) => period?.startDate ?? period?.defaultStartDate;

export const getDateFromString = (date?: string | null) => {
  if (date) {
    const _date = new Date(date);
    if (isValid(new Date(date))) return _date;
  }
};
