import Text from '@/components/cms/Text';

type SearchResultsEmptyStateProps = {
  emptyStateHeading: string;
  emptyStateSubHeading: string;
};

const SearchResultsEmptyState: React.FC<SearchResultsEmptyStateProps> = ({
  emptyStateHeading,
  emptyStateSubHeading
}) => {
  return (
    <div className="-mt-4 flex w-[241px] flex-col sm:w-[444px] lg:w-[626px] lg:pt-6">
      <Text element="h2" propertyName="Heading" className="heading-5 mb-3 sm:mb-4">
        {emptyStateHeading}
      </Text>

      <Text element="p" propertyName="SubHeading" className="text-2 text-gray-dark">
        {emptyStateSubHeading}
      </Text>
    </div>
  );
};

export default SearchResultsEmptyState;
