import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { UpcomingLiveProps } from '..';
import VariantsGroup from './VariantsGroup';
import { RootState } from '@/redux/store';
import { Variant } from '@/redux/slices/pdpSlice';
import classnames from 'classnames';

interface VariantListProps {
  labels: UpcomingLiveProps['labels'];
  selectedVariant?: Variant;
  handleSelect: (variant: Variant) => void;
  withinPanel?: boolean;
}

const memoizedVariantsByType = (type: string) =>
  createSelector([(state: RootState) => state.pdp.catalogRelations?.variants], variants =>
    variants?.filter(p => p.type === type && !p.registered && !p.isExpired)
  );

const VariantList: React.FC<VariantListProps> = ({
  labels,
  selectedVariant,
  handleSelect,
  withinPanel = false
}) => {
  const onlineVariants = useSelector(memoizedVariantsByType('online'));
  const inPersonVariants = useSelector(memoizedVariantsByType('in-person'));
  const groupcastVariants = useSelector(memoizedVariantsByType('groupcast'));

  return (
    <div className={classnames('flex flex-col gap-10 lg:gap-12', { 'pt-4 lg:pt-6': !withinPanel })}>
      <VariantsGroup
        icon="global"
        label={labels.online}
        variants={onlineVariants}
        selectedVariant={selectedVariant}
        waitlistLabel={labels.waitlist}
        onSelect={variant => handleSelect(variant)}
      />
      <VariantsGroup
        icon="location-pin"
        label={labels.inPerson}
        variants={inPersonVariants}
        selectedVariant={selectedVariant}
        waitlistLabel={labels.waitlist}
        onSelect={variant => handleSelect(variant)}
      />
      <VariantsGroup
        icon="users"
        label={labels.groupcast}
        variants={groupcastVariants}
        selectedVariant={selectedVariant}
        waitlistLabel={labels.waitlist}
        onSelect={variant => handleSelect(variant)}
      />
    </div>
  );
};

export default VariantList;
