import { IContent, LinkItem } from '@/@types/cms';
import {
  JavascriptLinkBlock,
  SubnavigationItemBlock as SubnavigationItemBlockProps
} from '@/@types/content';
import { trackLinkEvent } from '@/analytics/link';
import Divider from '@/components/ui/Divider';
import Icon from '@/components/ui/Icon';
import Link from '@/components/ui/Link';
import { transformIfPLIUrl } from '@/utils/helpers';
import { getTextColorClass } from '@/utils/theme';
import classnames from 'classnames';
import { useCallback } from 'react';

enum Variant {
  Light = 'light',
  Dark = 'dark'
}

interface BlockProps extends SubnavigationItemBlockProps {
  theme?: `${Variant}`;
  divider?: boolean;
  onSignout?: () => void;
  logoutLink?: LinkItem;
  onItemClick?: () => void;
}

const SubnavigationItemBlock: React.FC<BlockProps> = ({
  theme = Variant.Light,
  divider = false,
  heading,
  copy,
  link,
  icon,
  items,
  displaySignoutLink,
  onSignout,
  logoutLink,
  gA4EventType,
  gA4EventParameters,
  onItemClick
}) => {
  const navColumnlinkClasses = classnames('text-2 block py-2 no-underline', {
    'text-gray-medium': theme === Variant.Dark,
    'text-gray-dark': theme === Variant.Light,
    'focus-visible:outline-black': theme === Variant.Light,
    'focus-visible:outline-white': theme === Variant.Dark
  });

  const navColumnClasses = classnames(
    'flex w-full flex-col items-start gap-2',
    'lg:min-h-[340px] lg:max-w-[33.33%] lg:border-r lg:px-6 lg:py-4',
    'lg:first:pl-0',
    'lg:last:border-r-0 lg:last:pr-0',
    {
      'border-r-gray-light': theme === Variant.Light,
      'border-r-gray-darker': theme === Variant.Dark
    },
    getTextColorClass(theme)
  );

  const iconClasses = classnames('lg:size-6', getTextColorClass(theme));
  const refreshServiceWorker = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (window.navigator.serviceWorker != null) {
      e.stopPropagation();
      navigator.serviceWorker.getRegistration().then(swr => {
        swr?.unregister()?.then(() => {
          document.location.reload();
        });
      });
    }
  };
  const copyClasses = classnames('text-2 my-2', 'lg:max-w-[302px]', getTextColorClass(theme));

  const linkClasses = classnames('text-2 mt-2', 'lg:mt-auto', {
    'text-gray-medium': theme === Variant.Dark,
    'text-gray-dark': theme === Variant.Light,
    'focus-visible:outline-black': theme === Variant.Light,
    'focus-visible:outline-white': theme === Variant.Dark
  });
  const versionDisplay = heading?.startsWith('Additional ') && window.env.BUILD_NUMBER;

  const handleItemClick = useCallback(
    (item: IContent) => {
      if (gA4EventType) trackLinkEvent({ gA4EventType, gA4EventParameters });
      trackLinkEvent(item);
      if ((item as JavascriptLinkBlock)?.onClick)
        Reflect.get(window, (item as JavascriptLinkBlock).onClick || '');

      onItemClick?.();
    },
    [onItemClick, gA4EventType, gA4EventParameters]
  );

  return (
    <>
      <div className={navColumnClasses} data-component={'SubnavigationItemBlock'}>
        <div className={classnames('flex items-center gap-2.5', 'lg:max-w-[302px]')}>
          {icon && <Icon name={icon} size="medium" className={iconClasses} />}
          <span className="text-2 block py-2 font-bold">{heading}</span>
        </div>

        {copy && <p className={copyClasses}>{copy}</p>}

        <nav className={classnames('block', 'lg:max-w-[302px]')}>
          {items?.map((item, index) => (
            <Link
              key={index}
              text={(item as SubnavigationItemBlockProps)?.link?.text ?? 'Untitled'}
              href={transformIfPLIUrl((item as SubnavigationItemBlockProps)?.link?.href)}
              onClick={() => handleItemClick(item)}
              title={(item as SubnavigationItemBlockProps)?.link?.title}
              target={(item as SubnavigationItemBlockProps)?.link?.target}
              variant={
                (item as SubnavigationItemBlockProps)?.link?.target === '_blank'
                  ? 'link-with-icon'
                  : undefined
              }
              className={navColumnlinkClasses}
            />
          ))}
        </nav>

        {displaySignoutLink && logoutLink ? (
          <Link
            as="button"
            onClick={onSignout}
            text={logoutLink?.text}
            title={logoutLink?.title}
            className={linkClasses}
          />
        ) : (
          link && (
            <Link
              text={link.text}
              href={link.href}
              title={link.title}
              target={link.target}
              className={linkClasses}
              variant={link.target === '_blank' ? 'link-with-icon' : undefined}
            />
          )
        )}
        {versionDisplay ? (
          <Link
            key="version"
            text={'Version ' + versionDisplay}
            href={window.location.toString()}
            rel="no-follow"
            className={classnames(linkClasses, 'w-full justify-end no-underline')}
            title="Version of frontend"
            target={'none'}
            onClick={refreshServiceWorker}
          ></Link>
        ) : (
          <></>
        )}
      </div>

      {divider && (
        <Divider
          color={theme}
          className={classnames('md:hidden', 'last:hidden', {
            'bg-gray-darker': theme === 'dark'
          })}
        />
      )}
    </>
  );
};

export default SubnavigationItemBlock;
