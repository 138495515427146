import { PDPSectionLabels } from '@/components/ui/PDP/labels';
import {
  ProgramCatalogRelations,
  ProgramVariation,
  WebSegmentVariation
} from './useProgramCatalogRelations';

interface UseOverviewProps {
  variant?: ProgramVariation | WebSegmentVariation;
  catalogRelations: ProgramCatalogRelations;
}

type OverviewData = {
  isLegacyOverview: boolean;
  isSegment: boolean;
  content: string;
  defaultOrder: string[];
  overviewSegmentDict: object;
};

export const useOverview = ({ variant, catalogRelations }: UseOverviewProps): OverviewData => {
  if (!variant)
    return {
      isLegacyOverview: false,
      isSegment: false,
      content: '',
      defaultOrder: [],
      overviewSegmentDict: {}
    };
  let programAnnouncements,
    additionalInfo1Header,
    additionalInfo1,
    whyYouShouldAttend,
    whatYouWillLearn,
    whoShouldAttend,
    specialFeatures,
    additionalInfo2Header,
    additionalInfo2,
    additionalInfo3Header,
    additionalInfo3,
    content: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isSegment = (variant as any)?.onDemandType === 1;
  const segmentDesc = (variant as WebSegmentVariation)?.segmentDescription;

  const isPopulated = (val?: string) => val?.length ?? 0 > 10;
  switch (variant.overviewMode) {
    //CASE: Combine: use variant if valid, else use masterprogram
    case 1:
      programAnnouncements = isPopulated(variant.programAnnouncements)
        ? variant.programAnnouncements
        : catalogRelations.masterProgram?.programAnnouncements;
      additionalInfo1Header = isPopulated(variant.additionalInfo1Header)
        ? variant.additionalInfo1Header
        : catalogRelations.masterProgram?.additionalInfo1Header;
      additionalInfo1 = isPopulated(variant.additionalInfo1)
        ? variant.additionalInfo1
        : catalogRelations.masterProgram?.additionalInfo1;
      whyYouShouldAttend = isPopulated(variant.whyYouShouldAttend)
        ? variant.whyYouShouldAttend
        : catalogRelations.masterProgram?.whyYouShouldAttend;
      whatYouWillLearn = isPopulated(variant.whatYouWillLearn)
        ? variant.whatYouWillLearn
        : catalogRelations.masterProgram?.whatYouWillLearn;
      whoShouldAttend = isPopulated(variant.whoShouldAttend)
        ? variant.whoShouldAttend
        : catalogRelations.masterProgram?.whoShouldAttend;
      specialFeatures = isPopulated(variant.specialFeatures)
        ? variant.specialFeatures
        : catalogRelations.masterProgram?.specialFeatures;
      additionalInfo2Header = isPopulated(variant.additionalInfo2Header)
        ? variant.additionalInfo2Header
        : catalogRelations.masterProgram?.additionalInfo2Header;
      additionalInfo2 = isPopulated(variant.additionalInfo2)
        ? variant.additionalInfo2
        : catalogRelations.masterProgram?.additionalInfo2;
      additionalInfo3Header = isPopulated(variant.additionalInfo3Header)
        ? variant.additionalInfo3Header
        : catalogRelations.masterProgram?.additionalInfo3Header;
      additionalInfo3 = isPopulated(variant.additionalInfo3)
        ? variant.additionalInfo3
        : catalogRelations.masterProgram?.additionalInfo3;
      content = isSegment
        ? segmentDesc ?? ''
        : (catalogRelations.masterProgram?.overview ?? '') +
          ('description' in variant && variant.description ? variant.description : '');
      break;
    //CASE: Use Variant Info
    case 2:
      programAnnouncements = variant.programAnnouncements;
      additionalInfo1Header = variant.additionalInfo1Header;
      additionalInfo1 = variant.additionalInfo1;
      whyYouShouldAttend = variant.whyYouShouldAttend;
      whatYouWillLearn = variant.whatYouWillLearn;
      whoShouldAttend = variant.whoShouldAttend;
      specialFeatures = variant.specialFeatures;
      additionalInfo2Header = variant.additionalInfo2Header;
      additionalInfo2 = variant.additionalInfo2;
      additionalInfo3Header = variant.additionalInfo3Header;
      additionalInfo3 = variant.additionalInfo3;
      content = isSegment
        ? segmentDesc ?? ''
        : ('description' in variant && variant.description ? variant.description : '').toString();
      break;
    //CASE: Use master program Info
    default:
      programAnnouncements = catalogRelations.masterProgram?.programAnnouncements;
      additionalInfo1Header = catalogRelations.masterProgram?.additionalInfo1Header;
      additionalInfo1 = catalogRelations.masterProgram?.additionalInfo1;
      whyYouShouldAttend = catalogRelations.masterProgram?.whyYouShouldAttend;
      whatYouWillLearn = catalogRelations.masterProgram?.whatYouWillLearn;
      whoShouldAttend = catalogRelations.masterProgram?.whoShouldAttend;
      specialFeatures = catalogRelations.masterProgram?.specialFeatures;
      additionalInfo2Header = catalogRelations.masterProgram?.additionalInfo2Header;
      additionalInfo2 = catalogRelations.masterProgram?.additionalInfo2;
      additionalInfo3Header = catalogRelations.masterProgram?.additionalInfo3Header;
      additionalInfo3 = catalogRelations.masterProgram?.additionalInfo3;
      content = isSegment ? segmentDesc ?? '' : catalogRelations.masterProgram?.overview ?? '';
      break;
  }

  const defaultOrder = [
    'programAnnouncements',
    'additionalInfo1',
    'whyYouShouldAttend',
    'whatYouWillLearn',
    'whoShouldAttend',
    'specialFeatures',
    'additionalInfo2',
    'additionalInfo3'
  ];

  const overviewSegmentDict = {
    programAnnouncements: {
      header: PDPSectionLabels.programAnnouncements,
      content: programAnnouncements
    },
    additionalInfo1: { header: additionalInfo1Header, content: additionalInfo1 },
    whyYouShouldAttend: {
      header: PDPSectionLabels.whyYouShouldAttend,
      content: whyYouShouldAttend
    },
    whatYouWillLearn: { header: PDPSectionLabels.whatYouWillLearn, content: whatYouWillLearn },
    whoShouldAttend: { header: PDPSectionLabels.whoShouldAttend, content: whoShouldAttend },
    specialFeatures: { header: PDPSectionLabels.specialFeatures, content: specialFeatures },
    additionalInfo2: { header: additionalInfo2Header, content: additionalInfo2 },
    additionalInfo3: { header: additionalInfo3Header, content: additionalInfo3 }
  };

  const filteredOverviewSegments = Object.fromEntries(
    Object.entries(overviewSegmentDict).filter(segment => (segment[1].content?.length ?? 0) > 10)
  );

  const isLegacyOverview = Object.keys(filteredOverviewSegments).length > 0 ? false : true;

  return {
    isLegacyOverview,
    isSegment,
    content,
    defaultOrder,
    overviewSegmentDict
  };
};
