import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export enum ScrollDirection {
  Up = 'up',
  Down = 'down'
}

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection | null>(null);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const scrollGap = 0;

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      setScrollTop(scrollY);

      const direction = scrollY > lastScrollY ? ScrollDirection.Down : ScrollDirection.Up;
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > scrollGap || scrollY - lastScrollY < -scrollGap)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener('scroll', throttle(updateScrollDirection, 500));

    return () => {
      window.removeEventListener('scroll', throttle(updateScrollDirection, 500));
    };
  }, [scrollDirection]);

  return { scrollDirection, scrollTop };
};

export default useScrollDirection;
