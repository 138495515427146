import { useCallback, useMemo, useState } from 'react';
import ModalContent from '@/components/ui/Modal/ModalContent';
import LicenseForm, { LicenseFormProps } from '@/components/ui/SearchModals/LicenseForm';
import { addToYourSearchEyebrow } from './AddToYourSearchEyebrow';
import { LicenseData, validateRequiredFields } from './utils/licenses';

export type AddSingleLicenseToProfileModalProps = LicenseFormProps & {
  onBack: () => void;
  onSave: () => void;
  onDismiss?: () => void;
  onClose?: () => void;
};

const AddSingleLicenseToProfileModal = ({
  data,
  onDataChange,
  creditTypeOptions,
  jurisdictionOptions,
  licenseNumberSettings,
  onSave,
  onBack,
  onDismiss,
  onClose
}: AddSingleLicenseToProfileModalProps): JSX.Element => {
  const [validationFailed, setValidationFailed] = useState(false);

  const validRequiredFields = useMemo(() => validateRequiredFields(data), [data]);

  const handleOnSave = useCallback(() => {
    if (validRequiredFields) {
      onSave();
    } else {
      setValidationFailed(true);
    }
  }, [validRequiredFields, onSave]);

  const handleOnDataChange = useCallback(
    (data: LicenseData) => {
      setValidationFailed(false);
      onDataChange?.(data);
    },
    [onDataChange]
  );

  return (
    <ModalContent
      title="Do you want to add this license to your profile?"
      saveButtonLabel="Add to my profile"
      dismissButtonLabel="No, thanks"
      eyebrow={addToYourSearchEyebrow}
      onSaveButtonPress={handleOnSave}
      onBackButtonPress={onBack}
      onDismissButtonPress={onDismiss}
      onCloseButtonPress={onClose}
      isMultiStep={!validRequiredFields}
    >
      <LicenseForm
        data={data}
        onDataChange={handleOnDataChange}
        creditTypeOptions={creditTypeOptions}
        jurisdictionOptions={jurisdictionOptions}
        licenseNumberSettings={licenseNumberSettings}
        creditTypeDisabled={true}
        jurisdictionDisabled={true}
        validationFailed={validationFailed}
      />
    </ModalContent>
  );
};

export default AddSingleLicenseToProfileModal;
