import classnames from 'classnames';
import * as Dialog from '@radix-ui/react-dialog';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

import Button from './Buttons/Button';
import Icon from './Icon';

export interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ModalContentProps {
  title: string | React.ReactNode;
  subtitle?: string;
  saveButtonLabel: string;
  dismissButtonLabel?: string | null;
  shouldDismissButtonClose?: boolean;
}

const Modal = ({ open, setOpen, children }: React.PropsWithChildren<ModalProps>) => {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      {children}
    </Dialog.Root>
  );
};

export const ModalContent = ({
  title,
  saveButtonLabel,
  dismissButtonLabel,
  children,
  shouldDismissButtonClose = true,
  subtitle,
  ...props
}: React.PropsWithChildren<ModalContentProps>): JSX.Element => {
  const isXSmall = useMediaQuery(ScreenSizeQueries.xsOnly);

  const mobileStylesWithoutOverlay = classnames('fixed inset-0 z-modal bg-white');
  const stylesWithOverlay = classnames(
    'z-modal sm:fixed sm:left-1/2 sm:top-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2'
  );

  return (
    <Dialog.Portal>
      {!isXSmall && <Dialog.Overlay className="fixed inset-0 sm:bg-black/30" />}
      <Dialog.Content {...props}>
        <div className={isXSmall ? mobileStylesWithoutOverlay : stylesWithOverlay}       data-component={"Modal"}        >
          <div className="flex flex-col justify-between bg-white pb-2 sm:h-[85vh] sm:w-[576px] sm:rounded-lg md:h-[85vh] md:max-w-[628px]">
            <div className="flex h-full flex-col overflow-y-clip">
              <Dialog.Close aria-label="Close" className="flex justify-end py-8 pr-6 sm:pr-8">
                <Icon name="close" size="large" />
              </Dialog.Close>
              <Dialog.Title className="mb-8 px-6 sm:px-16">
                <p className="heading-4 pb-2">{title}</p>
                {!!subtitle && <p className="label text-gray-dark">{subtitle}</p>}
              </Dialog.Title>
              <div className="modal-scrollbar mr-2 flex flex-col overflow-y-scroll pl-6 pr-3 max-sm:max-h-[65vh] sm:pl-16 sm:pr-[52px]">
                {children}
              </div>
            </div>
            <div className="relative bottom-0 justify-end max-sm:absolute max-sm:w-full">
              <div className="absolute inset-x-6 top-[-32px] h-8 bg-gradient-to-t from-white/80 to-transparent sm:inset-x-16" />
              <div className="relative bg-white px-6 sm:px-16">
                <Dialog.Close className="flex w-full">
                  <Button
                    label={saveButtonLabel}
                    color="black"
                    size="large"
                    className="w-full"
                    aria-label={saveButtonLabel}
                  />
                </Dialog.Close>
                <div className={classnames('flex justify-center', { 'h-14': !dismissButtonLabel })}>
                  {dismissButtonLabel &&
                    (shouldDismissButtonClose ? (
                      <Dialog.Close
                        className="text-1 pb-[28px] pt-4 underline sm:pb-8"
                        aria-label={dismissButtonLabel}
                      >
                        {dismissButtonLabel}
                      </Dialog.Close>
                    ) : (
                      <button
                        className="text-1 pb-[28px] pt-4 underline sm:pb-8"
                        aria-label={dismissButtonLabel}
                      >
                        {dismissButtonLabel}
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
};

Modal.Trigger = Dialog.Trigger;

export default Modal;
