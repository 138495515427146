import Shimmer from '@/components/ui/Shimmer';
import BaseBox from '../../components/BaseBox';

interface LoadingProps {
  isMobile?: boolean;
}
export const Loading = ({ isMobile = false }: LoadingProps) => {
  return (
    <BaseBox sticky={!isMobile}>
      <BaseBox.Content>
        <div className="flex size-full flex-col gap-12">
          <div className="flex flex-col gap-6 ">
            <Shimmer className="h-[20px] w-[75px]" />
            <Shimmer ratio={3 / 2} className="w-full" />
            <div className="flex h-5 flex-row justify-between">
              <Shimmer className="w-[138px]" />
              <Shimmer className="w-[89px]" />
            </div>
          </div>
          <div className="flex flex-col items-center gap-[25px]">
            <Shimmer className="h-[52px] w-full pb-1 lg:h-14" />
            <Shimmer className="h-[15px] w-[209px]" />
            <Shimmer className="h-[15px] w-[133px]" />
          </div>
        </div>
      </BaseBox.Content>
    </BaseBox>
  );
};

export default Loading;
