import { AnnouncementMessage } from '@/@types/client-api';
import Icon from '@/components/ui/Icon';
import { extractTextFromParagraph } from '@/utils/helpers';
import { CSSProperties, memo } from 'react';
import parse from 'html-react-parser';

const lineClampTextStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical' as const,
  WebkitLineClamp: 4,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
} as CSSProperties;

interface JurisdictionHeaderRequirementMessageProps {
  message?: AnnouncementMessage;
}

const JurisdictionHeaderRequirementMessage = memo(
  ({ message }: JurisdictionHeaderRequirementMessageProps) => {
    return (
      !!message && (
        <p style={lineClampTextStyle}>
          {!!message?.icon && (
            <span className="mr-2 inline-block align-middle">
              <Icon name={message?.icon} size="medium" key="firstMessageIcon" />
            </span>
          )}
          <span className="inline align-middle">
            {!!message?.headingText && (
              <span className="inline font-bold">{message.headingText}: </span>
            )}
            {!!message?.body && !message?.body?.isEmpty && (
              <span className="inline">
                {parse(extractTextFromParagraph(message.body.toString()) ?? '')}
              </span>
            )}
          </span>
        </p>
      )
    );
  }
);

JurisdictionHeaderRequirementMessage.displayName = 'JurisdictionHeaderRequirementMessage';
export default JurisdictionHeaderRequirementMessage;
