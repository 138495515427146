import { ItemAnalyticsModel } from '@/@types/client-api';
import { AnalyticsEventName } from './constants';
import { appInsights } from '@/lib/ApplicationInsightsService';

export const mapCategories = (categories: string[]): Record<string, string> => {
  return categories.reduce(
    (acc, category, index) => {
      acc[`item_category${index === 0 ? '' : index + 1}`] = category;
      return acc;
    },
    {} as Record<string, string>
  );
};

export const ConvertItemAnalyticsModelBase = (itemAnalyticsModel?: ItemAnalyticsModel) => {
  if (!itemAnalyticsModel) return undefined;
  return {
    item_id: itemAnalyticsModel?.id,
    item_name: itemAnalyticsModel?.name,
    affiliation: itemAnalyticsModel?.affiliation,
    coupon: itemAnalyticsModel?.coupon,
    index: itemAnalyticsModel?.id,
    item_brand: itemAnalyticsModel?.brand,
    price: itemAnalyticsModel?.price || 0,
    ...mapCategories(itemAnalyticsModel?.categories || []),
    parentId: itemAnalyticsModel?.parentId,
    parentName: itemAnalyticsModel?.parentName
  };
};

export const ConvertItemAnalyticsModel = (
  itemAnalyticsModel?: ItemAnalyticsModel,
  quantity?: number
) => {
  return {
    currency: itemAnalyticsModel?.currency || undefined,
    value: itemAnalyticsModel?.price || 0,
    items: [
      quantity
        ? {
            ...ConvertItemAnalyticsModelBase(itemAnalyticsModel),
            quantity
          }
        : ConvertItemAnalyticsModelBase(itemAnalyticsModel)
    ]
  };
};

export const getUID = () =>
  String(Date.now().toString(32) + Math.random().toString(16)).replace(/\./g, '');

export const createTransactionId = (transactionPrefix: string) => transactionPrefix + getUID();

export const PushAnalyticsEvent = (event: AnalyticsEventName, eventData: object) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null, items: null });

  window.dataLayer.push({
    event: event,
    ...eventData
  });
};

const logError = (errorMessage: string, severityLevel = 3) => {
  appInsights.trackException({
    exception: new Error(errorMessage),
    severityLevel, // 0-4 (Verbose, Information, Warning, Error, Critical)
    properties: {
      customMessage: errorMessage
    }
  });
};

export const syntheticEventHubEvent = async (event: object) => {
  try {
    window.eventHubDataLayer = window.eventHubDataLayer || [];

    const analyticsEventTimestamp = new Date().getTime();
    const uniqueEventId = getUID(); // synthetic gtm.uniqueEventId
    const newEvent = {
      ...event,
      analytics_event_timestamp: analyticsEventTimestamp,
      'gtm.uniqueEventId': uniqueEventId
    };

    window.eventHubDataLayer.push(newEvent);
  } catch (error) {
    logError('synthetic hub event error');
  }
};
