import Button from '@/components/ui/Buttons/Button';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { RequirementsPanel } from '../CreditTracker/Panels/RequirementsPanel';
import { AnnouncementMessage, CreditJurisdiction, UserLicense } from '@/@types/client-api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EditLicenseModal from '../LicenseModals/EditLicenseModal';
import {
  findCreditJurisdiction,
  getComplianceGroupOptions,
  getEnrichedLicenseData,
  getFindApprovedProgramsURL,
  getLicenseNumberSettings,
  LicenseData
} from '../SearchModals/utils/licenses';
import NudgeTooltip from '../Tooltip/NudgeTooltip';
import useLicenseNudge from './hooks/useLicenseNudge';
import { NudgeAnalyticsContext } from '@/analytics/constants';
import { trackFindApprovedProgramsEvent, trackViewRequirementsEvent } from '@/analytics/licenses';
import { AnalyticsContext } from '@/analytics/constants';
import useNudgeContext from '@/hooks/shared/useNudge/useNudgeContext';

export interface LicensesSnippetProps {
  className?: string;
  data?: UserLicense;
  creditJurisdictions?: CreditJurisdiction[];
  onSave: (data: LicenseData) => void;
  onDelete: (data?: LicenseData) => void;
  messages?: string;
  licenseIndex?: number;
  announcements?: AnnouncementMessage[];
}

const LicensesSnippet = ({
  className,
  data,
  creditJurisdictions,
  onSave,
  onDelete,
  messages,
  licenseIndex,
  announcements
}: LicensesSnippetProps): JSX.Element => {
  //const [isRequirementsPanelOpen, setRequirementsPanelOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState<LicenseData | undefined>(data);
  const isMediumAndAbove = useMediaQuery(ScreenSizeQueries.md);
  const { openPanel, registerPanel, unregisterPanel } = useNudgeContext();

  const creditJurisdiction = useMemo(
    () => findCreditJurisdiction(creditJurisdictions, data?.creditClass_SK, data?.creditRegion_SK),
    [creditJurisdictions, data]
  );

  const enrichedData = useMemo(
    () => getEnrichedLicenseData(editingData, creditJurisdiction),
    [creditJurisdiction, editingData]
  );

  const licenseNumberSettings = useMemo(
    () =>
      getLicenseNumberSettings(
        creditJurisdictions,
        editingData?.creditClass_SK,
        editingData?.creditRegion_SK
      ),
    [creditJurisdictions, editingData]
  );

  const complianceGroupOptions = useMemo(
    () =>
      getComplianceGroupOptions(
        creditJurisdictions,
        editingData?.creditClass_SK,
        editingData?.creditRegion_SK
      ),
    [creditJurisdictions, editingData]
  );

  const handleOnSave = useCallback(() => {
    onSave(enrichedData);
  }, [enrichedData, onSave]);

  const handleOnDelete = useCallback(() => {
    setIsEditing(false);
    onDelete(editingData);
  }, [editingData, onDelete]);

  const { sectionNudge, licenseNumberNudge, complianceGroupNudge } = useLicenseNudge({
    messagesJSON: messages,
    sectionData: data,
    sectionIndex: licenseIndex,
    context: NudgeAnalyticsContext.Licenses,
    onAccept: () => setIsEditing(true),
    licenseNumberSettings,
    hasComplianceGroup: complianceGroupOptions.length > 0
  });

  useEffect(() => {
    if (!sectionNudge) return;

    if (!isEditing && openPanel === sectionNudge.key) {
      unregisterPanel(sectionNudge.key);
    } else if (isEditing && openPanel !== sectionNudge.key) {
      registerPanel(sectionNudge.key);
    }
  }, [isEditing, openPanel, unregisterPanel, registerPanel, sectionNudge]);

  return (
    <div className={className}>
      <div className="flex flex-col gap-y-8 pb-2 md:flex-row md:pb-10">
        <div className="flex flex-col md:flex-1">
          <h3 className="heading-5-medium pb-4">
            <NudgeTooltip nudge={sectionNudge}>{creditJurisdiction?.name ?? ''}</NudgeTooltip>
          </h3>

          {data?.stateLicenseNumber || licenseNumberNudge ? (
            <span className="text-1">
              <NudgeTooltip nudge={licenseNumberNudge}>
                <span className="text-gray-dark">License Number: </span>
                {data?.stateLicenseNumber}
              </NudgeTooltip>
            </span>
          ) : null}

          {data?.complianceGroup || complianceGroupNudge ? (
            <span className="text-1 pt-2 md:pt-4">
              <NudgeTooltip nudge={complianceGroupNudge}>
                <span className="text-gray-dark">Compliance Group: </span>
                {data?.complianceGroup}
              </NudgeTooltip>
            </span>
          ) : null}
        </div>
        <EditLicenseModal
          open={isEditing}
          setOpen={setIsEditing}
          data={enrichedData}
          onDataChange={setEditingData}
          onDelete={handleOnDelete}
          onSave={handleOnSave}
          complianceGroupOptions={complianceGroupOptions}
          licenseNumberSettings={licenseNumberSettings}
          triggerButton={
            <Button
              color="light-gray"
              iconRight={isMediumAndAbove ? undefined : 'edit'}
              label={isMediumAndAbove ? 'Edit' : 'Edit license'}
              size={isMediumAndAbove ? 'small' : 'short'}
            />
          }
        />
      </div>
      <div className="flex flex-col gap-2 md:flex-row md:gap-3">
        <Button
          className="w-full md:w-auto"
          color="outline-black"
          href={getFindApprovedProgramsURL(data)}
          onClick={() =>
            trackFindApprovedProgramsEvent({
              value: creditJurisdiction?.id ?? '',
              context: AnalyticsContext.Licenses
            })
          }
          iconRight="chevron-right"
          label="Find approved programs"
          size="short"
        />
        <RequirementsPanel
          announcements={announcements ?? []}
          creditRegionName={creditJurisdiction?.longDescription ?? ''}
          buttonClassName="w-full md:w-auto"
          buttonSize="short"
          buttonIcon="chevron-right"
          onButtonClick={() =>
            trackViewRequirementsEvent({
              value: creditJurisdiction?.id ?? '',
              context: AnalyticsContext.Licenses
            })
          }
        />
      </div>
    </div>
  );
};

export default LicensesSnippet;
