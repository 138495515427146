import { forwardRef, useCallback, useEffect, useState } from 'react';
import { Variant } from '@/redux/slices/pdpSlice';
import { UpcomingLiveProps } from '..';
import BaseBox from '../../components/BaseBox';
import ActionButton from './ActionButton';
import OnDemandCard from './OnDemandCard';
import VariantList from './VariantList';
import { useAuth } from 'react-oidc-context';
import { getDiscountAdviceMessage } from '../../../helpers';

interface VariantsSelectorProps {
  labels: UpcomingLiveProps['labels'];
  softSelection: Variant;
  isHardSelection?: boolean;
  isModal?: boolean;
  programCard?: UpcomingLiveProps['onDemandProgram'];
  onVariantSelect?: (variant: Variant) => void;
  onSubmit: (variant: Variant) => void;
  isSuccess?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  withinPanel?: boolean;
  withinTabs?: boolean;
}

const VariantsSelector = forwardRef<HTMLButtonElement, VariantsSelectorProps>(
  (
    {
      labels,
      softSelection,
      isHardSelection,
      isModal,
      programCard,
      onVariantSelect,
      onSubmit,
      isSuccess,
      isLoading,
      isError,
      withinPanel,
      withinTabs
    },
    ref
  ) => {
    const [selectedVariant, setSelectedVariant] = useState<Variant | undefined>(
      isHardSelection ? softSelection : undefined
    );
    const [showError, setShowError] = useState(false);
    const [mailToString, setMailToString] = useState('');
    const auth = useAuth();

    const getMailToString = useCallback(
      async (variant: Variant) => {
        let firstName = '[FIRST NAME]';
        let lastName = '[LAST NAME]';
        const dateString = new Date(variant?.eventStartDate ?? new Date()).toLocaleDateString(
          'en-US',
          { timeZone: 'EST' }
        );
        if (auth.isAuthenticated) {
          firstName = auth?.user?.profile?.given_name ?? firstName;
          lastName = auth?.user?.profile?.family_name ?? lastName;
        }

        setMailToString(
          `mailto:${window.env?.APP_INFO_EMAIL}?subject=Waitlist Request for ${variant?.displayName}&body=Please add ${firstName} ${lastName} to the waitlist for ${variant?.displayName} (${variant?.code}) at ${variant?.location} on ${dateString}`
        );
      },
      [setMailToString, auth]
    );

    const handleSelect = useCallback(
      (variant: Variant) => {
        if (variant.waitlist) getMailToString(variant);
        setSelectedVariant(variant);
        if (onVariantSelect) onVariantSelect(variant);
      },
      [onVariantSelect, getMailToString]
    );

    const handleSubmit = useCallback(() => {
      setShowError(!selectedVariant);
      if (selectedVariant?.waitlist) window.location.href = mailToString;
      else if (selectedVariant) onSubmit(selectedVariant);
    }, [onSubmit, selectedVariant, mailToString]);

    useEffect(() => {
      if (selectedVariant) setShowError(false);
    }, [selectedVariant]);

    return (
      <>
        <BaseBox.Content withinPanel={withinPanel} withinTabs={withinTabs} inModal={isModal}>
          <VariantList
            labels={labels}
            selectedVariant={selectedVariant}
            handleSelect={handleSelect}
            withinPanel={withinPanel}
          />
        </BaseBox.Content>
        <BaseBox.Footer
          primaryButton={
            <ActionButton
              ref={ref}
              labels={labels}
              selectedVariant={selectedVariant ?? softSelection}
              onClick={handleSubmit}
              isSuccess={isSuccess}
              isLoading={isLoading}
              isError={isError}
            />
          }
          noSelectionError={showError && labels.noSelectionMsg}
          discountAdviceMessage={getDiscountAdviceMessage(selectedVariant ?? softSelection, labels)}
          extraMessage={labels.extraMessage}
          waitlistMessage={
            selectedVariant?.waitlist && labels.waitlistMessage.replace('mailto://', mailToString)
          }
          codeLabel={labels.programId}
          codeValue={selectedVariant ? selectedVariant.code! : softSelection.code!}
          withinPanel={withinPanel}
        ></BaseBox.Footer>
        {!!programCard && <OnDemandCard {...programCard} />}
      </>
    );
  }
);

export default VariantsSelector;
