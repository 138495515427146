import { Fragment, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import Button from '@/components/ui/Buttons/Button';
import { SnippetButtonProps } from './Snippet';
import usePrevious from '@/hooks/usePrevious';

const LOADING_TIMEOUT = 5000;

const SnippetButton: React.FC<SnippetButtonProps> = (
  { onClick, renderButtonWrapper, actionStatus, label, ...buttonProps },
  i
) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isLoading, setIsLoading] = useState(actionStatus === 'loading');
  const prevLabel = usePrevious(label);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const wasLoadingAndIsNowSuccess = isLoading && actionStatus === 'idle';
    const labelChanged = prevLabel !== label;
    if (wasLoadingAndIsNowSuccess) {
      // If the action (eg Add to Library) was successful,
      // wait for the label to change before disabling the loading dots.
      // The extra delay is from search data refetching,
      // which is what will cause the label to change.
      if (labelChanged) {
        setIsLoading(false);
      }

      // To avoid an infinite loading state,
      // disable the loading dots after a timeout no matter what
      if (!timeoutRef.current) {
        timeoutRef.current = window.setTimeout(() => {
          setIsLoading(false);
          timeoutRef.current = null;
        }, LOADING_TIMEOUT);
      }

      return;
    }

    setIsLoading(actionStatus === 'loading');

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isLoading, actionStatus, label, prevLabel]);

  let ButtonElement = (
    <Button
      {...buttonProps}
      loading={isLoading}
      label={label}
      ref={buttonRef}
      className={classnames('w-full md:w-fit', {
        'flex !items-center !justify-center': isLoading
      })}
      style={{
        width: isLoading ? buttonRef.current?.offsetWidth : undefined
      }}
      onClick={renderButtonWrapper ? undefined : onClick}
    />
  );
  if (renderButtonWrapper) {
    ButtonElement = renderButtonWrapper(ButtonElement);
  }
  return <Fragment key={i}>{ButtonElement}</Fragment>;
};

export default SnippetButton;
