import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';
import * as Select from '@radix-ui/react-select';
import MenuDrawer from './Menu/MenuDrawer';
import MenuItem from './Menu/MenuItem';
import MenuContent from './Menu/MenuContent';
import MenuText from './Menu/MenuText';
import { Command, useCommandState } from 'cmdk';
import * as Popover from '@radix-ui/react-popover';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import DropdownSelect from './DropdownSelect';
import DropdownButton from './Buttons/DropdownButton';
import { ErrorState } from '@/utils/validations';
import Panel from './Panel';

export type SelectOption = {
  label: string;
  value: string;
};

type TDropdownSelectAutocompleteProps = {
  options: SelectOption[];
  value?: string | null;
  label: string;
  onValueChange?: (value: string) => void;
  align?: ComponentProps<typeof Select.Content>['align'];
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  allowFreeText?: boolean;
  onSearchTextChange?: (value: string) => void;
  error?: ErrorState;
  enableMobileTypeahead?: boolean;
};

const SELECTED_ICON_NAME = 'checkmark';
const MENU_STYLE = 'form-field';

function emphasizeSearchText(label: string, searchText: string, highlightAll: boolean = false) {
  if (!searchText) return label;

  const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const parts = label.split(new RegExp(`(${escapedSearchText})`, 'i'));
  let didHighlight = false;
  return (
    <span>
      {parts.map((part, i) => {
        if (part.toLowerCase() === searchText.toLowerCase() && !didHighlight) {
          didHighlight = !highlightAll;
          return (
            <strong key={i} className="font-bold text-black">
              {part}
            </strong>
          );
        }

        return (
          <span key={i} className="text-gray-dark">
            {part}
          </span>
        );
      })}
    </span>
  );
}

const filter: ComponentProps<typeof Command>['filter'] = (value, search) => {
  if (value.toLowerCase().includes(search.toLowerCase())) return 1;
  return 0;
};

const Empty = () => {
  // Using this instead of Command.Empty to get a screen reader announcement
  const count = useCommandState(s => s.filtered.count);
  if (count !== 0) return null;

  return (
    <>
      <span aria-atomic aria-live="polite" role="status" className="sr-only">
        No results found
      </span>
      <span role="presentation" className="text-1 block p-4 text-center text-gray-darker">
        No results found
      </span>
    </>
  );
};

const DropdownSelectAutocomplete = (props: TDropdownSelectAutocompleteProps) => {
  const {
    align = 'end',
    label,
    options,
    onValueChange,
    value,
    placeholder = 'Choose an option...',
    disabled,
    required,
    allowFreeText = false,
    onSearchTextChange,
    error,
    enableMobileTypeahead = false
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const isAboveXS = useMediaQuery(ScreenSizeQueries.sm);
  const inputRef = useRef<HTMLInputElement>(null);
  const [highlightedItem, setHighlightedItem] = useState('');

  // This component contains a few DOM manipulations to improve keyboard accessibility

  useEffect(() => {
    if (!isOpen) {
      setHighlightedItem('');
      allowFreeText && value ? setSearchText(value) : setSearchText('');
    }
  }, [isOpen, allowFreeText, value]);

  // useEffect(() => {
  //   if (onSearchTextChange && onValueChange) {
  //     onValueChange(searchText);
  //   }
  // }, [onSearchTextChange, onValueChange, searchText]);

  useEffect(() => {
    if (isOpen) {
      setHighlightedItem('');
      inputRef.current?.removeAttribute('aria-activedescendant');
    }
  }, [isOpen, searchText]);

  const updateActiveDescendant = useCallback(() => {
    const highlightedItemEl = document.querySelector(
      '[cmdk-list] [cmdk-item][data-selected="true"]'
    );
    setHighlightedItem(highlightedItemEl?.getAttribute('data-value') || '');
    highlightedItemEl?.id
      ? inputRef.current?.setAttribute('aria-activedescendant', highlightedItemEl.id)
      : inputRef.current?.removeAttribute('aria-activedescendant');
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === 'ArrowDown' && !inputRef.current?.getAttribute('aria-activedescendant')) {
        updateActiveDescendant();
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        // Wait for the selected item to be updated before updating the active descendant
        setTimeout(updateActiveDescendant, 0);
        return;
      }

      if (e.key === 'Enter' && !highlightedItem) {
        // Prevent edge case where you press enter before selecting an item with the keyboard
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(false);
        return;
      }
    },
    [updateActiveDescendant, highlightedItem]
  );

  const selectedOption = options.find(o => o.value === value);
  const labelText = `${label}${required ? ' *' : ''}`;

  if (!isAboveXS) {
    if (enableMobileTypeahead) {
      return (
        <div>
          <DropdownButton
            state="closed"
            eyebrow={labelText}
            onClick={() => setIsOpen(true)}
            label={
              selectedOption ? (
                <MenuText value={selectedOption.label} valueClassName="line-clamp-2" />
              ) : allowFreeText && !selectedOption && searchText ? (
                <MenuText value={searchText} />
              ) : (
                ''
              )
            }
            placeholder={placeholder}
            required={required}
            dropdownError={error?.showError ? error : undefined}
          />
          <Panel isOpen={isOpen} onOpenChange={open => setIsOpen(open)}>
            <Command filter={filter}>
              <Panel.Content
                side="right"
                hasHorizontalPadding={false}
                className="border-t border-solid border-gray-light"
                isNestedModal={true}
                centerHeaderContent={
                  <div>
                    <label className="label mb-1 text-gray-dark">{label}</label>
                    <Command.Input
                      value={searchText}
                      placeholder={placeholder}
                      className="w-full bg-transparent focus:outline-none"
                      onValueChange={value => {
                        setSearchText(value);
                        onSearchTextChange && onSearchTextChange(value);
                      }}
                      ref={inputRef}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                }
                titleHeaderClassNames="text-1 !mt-0 border-b border-solid border-gray-light py-5 [&>button>svg]:size-4"
              >
                <Panel.Body scrollClassName="!pt-0">
                  <Command.List>
                    {options.map(option => {
                      const isSelected = value === option.value;
                      return (
                        <MenuItem
                          isHighlighted={highlightedItem === option.label}
                          menuStyle={MENU_STYLE}
                          key={option.value}
                          Element={Command.Item}
                          rightIcon={isSelected ? SELECTED_ICON_NAME : undefined}
                          label={emphasizeSearchText(option.label, searchText, allowFreeText)}
                          value={option.label}
                          onSelect={() => {
                            onValueChange?.(option.value);
                            setIsOpen(false);
                          }}
                        />
                      );
                    })}
                  </Command.List>
                  <Empty />
                </Panel.Body>
              </Panel.Content>
            </Command>
          </Panel>
        </div>
      );
    } else {
      return (
        <div>
          <DropdownSelect
            {...props}
            valueClassName="line-clamp-1 sm:line-clamp-2"
            button={({ isOpen, value, ...props }) => (
              <DropdownButton
                state={isOpen ? 'open' : 'closed'}
                label={value}
                {...props}
                disabled={disabled}
                required={required}
                dropdownError={error?.showError ? error : undefined}
              />
            )}
            menuStyle={MENU_STYLE}
          />
        </div>
      );
    }
  }

  return (
    <div>
      <Popover.Root open={isOpen} onOpenChange={open => setIsOpen(open)} modal>
        <Popover.Anchor />
        <Popover.Trigger asChild disabled={disabled}>
          <DropdownButton
            state="closed"
            eyebrow={labelText}
            onClick={() => setIsOpen(true)}
            label={
              selectedOption ? (
                <MenuText value={selectedOption.label} valueClassName="line-clamp-2" />
              ) : allowFreeText && !selectedOption && searchText ? (
                <MenuText value={searchText} valueClassName="line-clamp-2" />
              ) : (
                ''
              )
            }
            placeholder={placeholder}
            required={required}
            dropdownError={error?.showError ? error : undefined}
          />
        </Popover.Trigger>
        {isOpen && (
          <Popover.Content asChild align={align} aria-label={label}>
            <MenuDrawer onClose={() => setIsOpen(false)}>
              <Command filter={filter}>
                <DropdownButton
                  state="open-autocomplete"
                  onClick={() => setIsOpen(false)}
                  eyebrow={labelText}
                  label={
                    <Command.Input
                      value={searchText}
                      placeholder={placeholder}
                      className="w-full bg-transparent focus:outline-none"
                      onValueChange={value => {
                        setSearchText(value);
                        onSearchTextChange && onSearchTextChange(value);
                      }}
                      ref={inputRef}
                      onKeyDown={handleKeyDown}
                    />
                  }
                  placeholder={placeholder}
                  dropdownError={error?.showError ? error : undefined}
                />
                <MenuContent
                  mobileTitle={label}
                  menuStyle={MENU_STYLE}
                  onClose={() => setIsOpen(false)}
                >
                  <Command.List>
                    {options.map(option => {
                      const isSelected = value === option.value;
                      return (
                        <MenuItem
                          isHighlighted={highlightedItem === option.label}
                          menuStyle={MENU_STYLE}
                          key={option.value}
                          Element={Command.Item}
                          rightIcon={isSelected ? SELECTED_ICON_NAME : undefined}
                          label={emphasizeSearchText(option.label, searchText, allowFreeText)}
                          value={option.label}
                          onSelect={() => {
                            onValueChange?.(option.value);
                            setIsOpen(false);
                          }}
                        />
                      );
                    })}
                  </Command.List>
                  <Empty />
                </MenuContent>
              </Command>
            </MenuDrawer>
          </Popover.Content>
        )}
      </Popover.Root>
    </div>
  );
};

export default DropdownSelectAutocomplete;
