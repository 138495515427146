import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import {
  CLEPeriod,
  CreditTrackerUpdateRequest,
  ICLEPeriod,
  RequirementKeyType,
  UserTrackerData,
  UserTrackerDataRequest
} from '@/@types/client-api';
import {
  useGetCreditTrackerQuery,
  useSwitchStatusLazyMutation,
  useSwitchStatusMutation,
  useUpdateUserCleOptionsMutation
} from '@/redux/api/client/creditTracker';
import {
  selectActiveClePeriod,
  selectActiveUserTrackerData,
  selectGlobalBanners,
  selectTrackerById,
  selectUserRegions
} from '@/redux/selectors/creditTrackerSelectors';
import {
  getActiveSpecialStatus,
  getCreditsCounted,
  getPeriodFlags,
  getTransitionOptions,
  periodIsMissingComplianceInformation
} from '@credit-tracker/util/TrackerHelpers';
import {
  creditTrackerDataSuccess,
  creditTrackerSetSelectedRegion
} from '@/redux/slices/creditTrackerSlice';
import { existsCookie, getCookieValues, setCookieValues } from '@/utils/cookie-list-values';
import {
  CompliancePeriodValue,
  trackerCookieNameKey,
  UserComplianceInformation,
  TrackerRouterState,
  TrackerQueryParamKeys
} from '@credit-tracker/util/constants';
import {
  getDateFromString,
  periodDateRangeIsInvalid
} from '@credit-tracker/util/trackerDateHelpers';
import { useGetIndividualRosterMemberQuery } from '@/redux/api/client/creditTrackerAdmin';
import { creditTrackerAdminGetMemberSuccess } from '@/redux/slices/creditTrackerAdminSlice';
import { selectTrackerByMember } from '@/redux/selectors/creditTrackerAdminSelectors';
import { trackViewCompliancePeriodEvent } from '@/analytics/creditTracker';

type UseCreditTrackerProps = {
  skipQuery?: boolean;
  request?: UserTrackerDataRequest;
  isCertificatesPage?: boolean;
  orgSk?: number;
};

const useCreditTracker = (props?: UseCreditTrackerProps) => {
  const { skipQuery = false, request = {}, isCertificatesPage = false, orgSk } = props || {};
  const dispatch = useDispatch();
  const [lastTrackerRequest, setLastTrackerRequest] = useState<UserTrackerDataRequest>(request);
  const storedTrackerData = useSelector(selectTrackerById(lastTrackerRequest.individualSk));
  const selectedRegion = useSelector(selectActiveUserTrackerData);
  const selectedUserRegions = useSelector(selectUserRegions(lastTrackerRequest.individualSk));
  const selectedClePeriod = useSelector(selectActiveClePeriod());
  const selectedGlobalBanners = useSelector(
    selectGlobalBanners(selectedRegion?.creditRegionShortDescription)
  );
  const [updateUserOptions] = useUpdateUserCleOptionsMutation();
  const [switchStatus] = useSwitchStatusMutation();
  const [switchStatusLazy] = useSwitchStatusLazyMutation();
  const auth = useAuth();
  const _selectedMemberTracker = useSelector(
    selectTrackerByMember(orgSk ?? -1, lastTrackerRequest.individualSk ?? -1)
  );

  const { data: rosterMemberQueryResult } = useGetIndividualRosterMemberQuery(
    {
      orgSk: orgSk ?? -1,
      indSk: request.individualSk ?? -1
    },
    { skip: !request.individualSk || !orgSk }
  );

  const isAdminRequest = useMemo(
    () =>
      !!lastTrackerRequest?.individualSk &&
      lastTrackerRequest?.individualSk.toString() !== auth.user?.profile.sub,
    [auth.user?.profile.sub, lastTrackerRequest?.individualSk]
  );

  const adminDisplayName = useMemo(
    () => _selectedMemberTracker?.displayName,
    [_selectedMemberTracker]
  );

  const adminMemberEmailAddress = useMemo(
    () => _selectedMemberTracker?.primaryEmail,
    [_selectedMemberTracker]
  );

  const [trackerLoading, setTrackerLoading] = useState<boolean>(true);

  const trackerCookieName = useMemo(() => {
    if (!!request.individualSk || !!storedTrackerData?.[0]?.customerId)
      return `${trackerCookieNameKey}_${request.individualSk ?? storedTrackerData?.[0]?.customerId}`;
  }, [request.individualSk, storedTrackerData]);

  const {
    isLoading: _trackerLoading,
    isFetching: trackerFetching,
    error: trackerError,
    isError,
    data: trackerData
  } = useGetCreditTrackerQuery([lastTrackerRequest], { skip: skipQuery });

  const allTrackerLicenses = useMemo(() => {
    if (storedTrackerData) return storedTrackerData;
  }, [storedTrackerData]);

  const isLimitedModeLicense = useMemo(
    () => !selectedRegion?.jurisdiction?.totalRequirement?.required,
    [selectedRegion?.jurisdiction?.totalRequirement]
  );

  const totalRequirement = useMemo(
    () => selectedRegion?.jurisdiction?.totalRequirement,
    [selectedRegion?.jurisdiction?.totalRequirement]
  );

  const requirements = useMemo(
    () => selectedRegion?.jurisdiction?.requirements,
    [selectedRegion?.jurisdiction?.requirements]
  );

  const experienceSwitchModalContent = useMemo(() => {
    return selectedRegion?.experienceSwitchModal;
  }, [selectedRegion?.experienceSwitchModal]);

  const totalCreditsCounted = useMemo(() => {
    if (totalRequirement) return getCreditsCounted(totalRequirement);
  }, [totalRequirement]);

  const isTransitionalOrNewlyAdmittedRegion = useMemo(() => {
    return (
      selectedClePeriod?.ruleSetFlag?.some(a => a.toLowerCase().indexOf('transitional') >= 0) ??
      false
    );
  }, [selectedClePeriod?.ruleSetFlag]);

  const isDeadlineApproaching = useMemo(() => {
    const currentDate = new Date();
    const checkEndDate = (endDate: Date) => {
      const differenceInMilliseconds = endDate.getTime() - currentDate.getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
      return (
        differenceInDays <= 30 &&
        differenceInDays >= -90 &&
        !((totalCreditsCounted ?? 0) >= (totalRequirement?.required ?? 0))
      );
    };
    const selectedPeriodEndDate = selectedClePeriod?.endDate ?? selectedClePeriod?.defaultEndDate;
    if (selectedPeriodEndDate) {
      return checkEndDate(new Date(selectedPeriodEndDate));
    }

    return false;
  }, [
    selectedClePeriod?.defaultEndDate,
    selectedClePeriod?.endDate,
    totalCreditsCounted,
    totalRequirement?.required
  ]);

  // #region Get tracker data.

  const removeQueryParam = (param: string) => {
    const url = new URL(window.location.href);
    if (url.searchParams.has(param)) {
      url.searchParams.delete(param);
      window.history.pushState({}, '', url.toString());
    }
  };

  useEffect(() => {
    setTrackerLoading(_trackerLoading || trackerFetching);
    removeQueryParam(TrackerQueryParamKeys.REGION_KEY);
  }, [trackerFetching, _trackerLoading]);

  // #endregion

  const getDefaultSelectedRegion = (data: UserTrackerData[]) => {
    return data?.[0] ?? {};
  };

  const storeSelectedRegionInCookie = useCallback(
    (region: string) => {
      if (region && trackerCookieName) setCookieValues(trackerCookieName, [region], 1, 1);
    },
    [trackerCookieName]
  );

  const setActiveRegion = useCallback(
    (region: string) => {
      const newRegion = storedTrackerData?.find(a => a.creditRegionShortDescription == region);
      if (newRegion) {
        dispatch(creditTrackerSetSelectedRegion(newRegion));

        if (newRegion.creditRegionShortDescription)
          storeSelectedRegionInCookie(newRegion.creditRegionShortDescription);

        window.history.replaceState(
          { regionSk: newRegion.creditRegion_SK } as TrackerRouterState,
          window.location.pathname
        );
      }
    },
    [dispatch, storeSelectedRegionInCookie, storedTrackerData]
  );
  // #endregion

  const transitionOptions = useMemo(
    () => getTransitionOptions(selectedRegion ?? {}),
    [selectedRegion]
  );

  const activeSpecialStatus = useMemo(
    () => getActiveSpecialStatus(selectedRegion ?? {}),
    [selectedRegion]
  );

  const allSpecialStatuses = useMemo(
    () =>
      transitionOptions
        ?.filter(a => a.regionSpecialStatusNames)
        .map(a => {
          if (a.regionSpecialStatusNames) {
            const statuses = Object.keys(a.regionSpecialStatusNames).map(key => {
              return {
                text: a?.regionSpecialStatusNames?.[key] ?? '',
                value: key
              };
            });
            return statuses;
          }
        })?.[0],
    [transitionOptions]
  );

  const previousDatesInvalid = useMemo(() => {
    const prev = selectedRegion?.jurisdiction?.clePeriod?.previousPeriod;
    return periodDateRangeIsInvalid(prev?.startDate ?? '', prev?.endDate ?? '');
  }, [selectedRegion?.jurisdiction?.clePeriod?.previousPeriod]);

  const currentDatesInvalid = useMemo(() => {
    const prev = selectedRegion?.jurisdiction?.clePeriod;
    return periodDateRangeIsInvalid(prev?.startDate ?? '', prev?.endDate ?? '');
  }, [selectedRegion?.jurisdiction?.clePeriod]);

  const nextDatesInvalid = useMemo(() => {
    const prev = selectedRegion?.jurisdiction?.clePeriod?.nextPeriod;
    return periodDateRangeIsInvalid(prev?.startDate ?? '', prev?.endDate ?? '');
  }, [selectedRegion?.jurisdiction?.clePeriod?.nextPeriod]);

  const isMissingCompliancePeriod = useMemo(
    () =>
      periodIsMissingComplianceInformation(
        selectedClePeriod,
        selectedRegion?.jurisdiction?.requireSetup
      ),
    [selectedClePeriod, selectedRegion?.jurisdiction?.requireSetup]
  );

  const showSwitchToExperienceModal = useMemo(() => {
    if (selectedClePeriod?.endDate || selectedClePeriod?.defaultEndDate) {
      const currentDate = new Date();
      const endDate = new Date(
        selectedClePeriod?.endDate ?? selectedClePeriod?.defaultEndDate ?? ''
      );

      return (
        !isMissingCompliancePeriod &&
        isTransitionalOrNewlyAdmittedRegion &&
        currentDate.getTime() - endDate.getTime() >= 0
      );
    }
  }, [
    isMissingCompliancePeriod,
    isTransitionalOrNewlyAdmittedRegion,
    selectedClePeriod?.defaultEndDate,
    selectedClePeriod?.endDate
  ]);

  // #region Helpers
  const temporalRequirements = useMemo(
    () =>
      selectedRegion?.jurisdiction?.requirements?.filter(
        a =>
          !!a.requirementKeys &&
          a.requirementKeys.some(x => x.requirementKeyType == RequirementKeyType._1)
      ),
    [selectedRegion?.jurisdiction?.requirements]
  );

  const userAdmitDate = useMemo(
    () => getDateFromString(selectedRegion?.jurisdiction?.userComplianceInformation?.admitDate),
    [selectedRegion?.jurisdiction?.userComplianceInformation]
  );

  const userBirthDate = useMemo(
    () => getDateFromString(selectedRegion?.jurisdiction?.userComplianceInformation?.birthDate),
    [selectedRegion?.jurisdiction?.userComplianceInformation]
  );

  const allRequirements = useMemo(
    () => selectedRegion?.jurisdiction?.requirements,
    [selectedRegion?.jurisdiction?.requirements]
  );

  const requirementsWithoutTemporal = useMemo(() => {
    return selectedRegion?.jurisdiction?.requirements?.filter(
      x => !temporalRequirements?.includes(x)
    );
  }, [selectedRegion?.jurisdiction?.requirements, temporalRequirements]);

  const updateUserCleOptions = useCallback(
    async (period?: ICLEPeriod) => {
      setTrackerLoading(true);

      const currentPeriod: CLEPeriod = {
        ...selectedRegion?.jurisdiction?.clePeriod,
        ...(period ?? {})
      };
      const res = await updateUserOptions({
        customerId: selectedRegion?.customerId,
        jurisdictionId: selectedRegion?.creditRegion_SK,
        jurisdictionKey: selectedRegion?.jurisdiction?.key,
        period: currentPeriod
      } as CreditTrackerUpdateRequest);

      if (res.error) setTrackerLoading(false);
    },
    [
      selectedRegion?.jurisdiction?.clePeriod,
      selectedRegion?.jurisdiction?.key,
      selectedRegion?.customerId,
      selectedRegion?.creditRegion_SK,
      updateUserOptions
    ]
  );

  const updateSpecialStatus = useCallback(
    async (useLazy?: boolean) => {
      const flag = selectedRegion?.jurisdiction?.cleStateTransitionOptions?.find(a => {
        return ![...(a.activatesFlags ?? []), ...(a.removesFlags ?? [])]?.some(
          a => a.toLowerCase().indexOf('period') >= 0
        );
      });

      try {
        if (flag) setTrackerLoading(true);
        const currentPeriod: CLEPeriod = {
          ...selectedClePeriod,
          ruleSetFlag: flag?.activatesFlags
        };
        const request = {
          customerId: selectedRegion?.customerId,
          jurisdictionId: selectedRegion?.creditRegion_SK,
          jurisdictionKey: selectedRegion?.jurisdiction?.key,
          period: currentPeriod
        } as CreditTrackerUpdateRequest;

        const res = useLazy ? await switchStatusLazy(request) : await switchStatus(request);

        if (res.error) setTrackerLoading(false);
      } catch {
        setTrackerLoading(false);
      }
    },
    [
      selectedClePeriod,
      selectedRegion?.creditRegion_SK,
      selectedRegion?.customerId,
      selectedRegion?.jurisdiction?.cleStateTransitionOptions,
      selectedRegion?.jurisdiction?.key,
      switchStatus,
      switchStatusLazy
    ]
  );

  const changeCompliancePeriod = useCallback(
    (period?: CompliancePeriodValue) => {
      trackViewCompliancePeriodEvent(period);
      setLastTrackerRequest({
        flags: getPeriodFlags(period, selectedRegion?.jurisdiction?.cleStateTransitionOptions),
        individualSk: selectedRegion?.customerId
      });
    },
    [selectedRegion?.customerId, selectedRegion?.jurisdiction?.cleStateTransitionOptions]
  );

  // #endregion

  useEffect(() => {
    if (trackerData && lastTrackerRequest) {
      dispatch(
        creditTrackerDataSuccess({
          request: [lastTrackerRequest],
          response: trackerData?.trackerUsers ?? []
        })
      );
      orgSk &&
        dispatch(
          creditTrackerAdminGetMemberSuccess({
            request: orgSk,
            response: { [orgSk.toString()]: rosterMemberQueryResult?.members ?? [] }
          })
        );
    }
  }, [trackerData, dispatch, lastTrackerRequest, rosterMemberQueryResult?.members, orgSk]);

  useEffect(() => {
    if (
      (!selectedRegion || (selectedRegion && Object.keys(selectedRegion).length === 0)) &&
      !!storedTrackerData &&
      !isCertificatesPage &&
      !!trackerCookieName
    ) {
      let region: UserTrackerData | undefined;

      if (existsCookie(trackerCookieName)) {
        const cookieRegion = getCookieValues(trackerCookieName)?.[0];
        region = storedTrackerData.find(a => a.creditRegionShortDescription == cookieRegion);
      } else if (window.history?.state) {
        const trackerRegionInRoute = window.history?.state as TrackerRouterState;

        if (trackerRegionInRoute) {
          region = storedTrackerData.find(a => a.creditRegion_SK == trackerRegionInRoute?.regionSk);
        }
      }

      if (!region) region = getDefaultSelectedRegion(storedTrackerData);

      dispatch(creditTrackerSetSelectedRegion(region));
    }
  }, [isCertificatesPage, dispatch, selectedRegion, storedTrackerData, trackerCookieName]);

  const memsuiteUrl = useMemo(
    () => (trackerData?.memsuiteUrl ? new URL(trackerData.memsuiteUrl) : null),
    [trackerData?.memsuiteUrl]
  );

  return {
    setTrackerLoading,
    trackerLoading,
    trackerFetching,
    trackerError,
    isError,
    trackerData: allTrackerLicenses,
    myCertificatesPageLink: trackerData?.myCertificatesPageLink,
    practiceAreas: trackerData?.userPracticeAreaCategories,
    earnMoreCreditPriority: trackerData?.earnMoreCreditPriority,
    searchPanelFailureMessage: trackerData?.searchPanelFailureMessage,
    earnMoreResultPageUrl: trackerData?.earnMoreResultPageUrl,
    selectedClePeriod,
    isDeadlineApproaching,
    selectedRegion,
    totalRequirement,
    requirements,
    selectedUserRegions,
    allSpecialStatuses,
    transitionOptions,
    activeSpecialStatus,
    temporalRequirements,
    allRequirements,
    requirementsWithoutTemporal,
    isTransitionalOrNewlyAdmittedRegion,
    isLimitedModeLicense,
    showSwitchToExperienceModal,
    lastTrackerRequest,
    isMissingCompliancePeriod,
    previousDatesInvalid,
    currentDatesInvalid,
    nextDatesInvalid,
    experienceSwitchModalContent,
    selectedGlobalBanners,
    userComplianceInformation: {
      userAdmitDate,
      userBirthDate
    } as UserComplianceInformation,
    changeCompliancePeriod,
    setActiveRegion,
    updateUserOptions,
    updateUserCleOptions,
    updateSpecialStatus,
    isAdminRequest,
    adminDisplayName,
    adminMemberEmailAddress,
    memsuiteUrl
  };
};

export default useCreditTracker;
