import * as Collapsible from '@radix-ui/react-collapsible';
import { IContent } from '@/@types/cms';

interface PageDataProps {
  children: IContent | undefined;
}

const PageData: React.FC<PageDataProps> = ({ children }) => {
  if (import.meta.env.MODE !== 'development') return null;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(children, null, 2));
  };

  return (
    <div className="bg-gray-light p-4">
      <div className="container">
        <Collapsible.Root className="group">
          <p className="text-2">
            <Collapsible.Trigger>
              <p className="font-semibold underline">
                <span className="group-data-[state=open]:hidden">Show </span>
                <span className="hidden group-data-[state=open]:inline">Hide </span>
                page data
              </p>
            </Collapsible.Trigger>
            <span> / </span>
            <button className="font-semibold underline" onClick={handleCopyToClipboard}>
              Copy
            </button>
            <span> (displayed only during development)</span>
          </p>
          <Collapsible.Content>
            <pre className="sm:text-base text-2 mt-4 inline-flex w-full items-center space-x-4 overflow-auto rounded-lg bg-gray-darker p-4 pl-6 text-left text-white">
              {JSON.stringify(children, null, 2)}
            </pre>
          </Collapsible.Content>
        </Collapsible.Root>
      </div>
    </div>
  );
};

export default PageData;
