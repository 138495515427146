import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import { LinkItem } from '@/@types/cms';
import { forwardRef } from 'react';
import { isRelativeOrPLIUrl } from '@/utils/helpers';

interface CmsLinkProps extends Omit<LinkItem, 'text'> {
  text?: string;
  children: React.ReactNode;
  className?: string;
  propertyName?: string;
  reloadDocument?: boolean;
}

const CmsLink = forwardRef<HTMLAnchorElement, CmsLinkProps>(
  (
    { propertyName, href, className, children, text, title, reloadDocument, target, ...props },
    ref
  ) => {
    const isEditMode = isEditOrPreviewMode();

    if (isEditMode && propertyName) {
      return (
        <span data-epi-edit={propertyName} className={classnames(className, 'relative')}>
          {children}
        </span>
      );
    }

    if (!isEditMode && (!children || !href)) return null;

    // The CMS supports a target '_top' that doesn't agree with react router
    const linkTarget = target === '_top' && isRelativeOrPLIUrl(href ?? '') ? undefined : target;

    return (
      <Link
        ref={ref}
        to={href!}
        aria-label={text}
        title={title}
        className={className}
        reloadDocument={reloadDocument}
        target={linkTarget}
        {...props}
      >
        {children}
      </Link>
    );
  }
);
export default CmsLink;
