import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import * as Dialog from '@radix-ui/react-dialog';

import classnames from 'classnames';

import SearchOverlayHeader from './SearchOverlayHeader';
import SearchSuggestion, { Suggestion } from './SearchOverlaySuggestions';
import { getApiAndFeSuggestions, height, processSuggestions } from './utils';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FILTERS, QUERY_PARAMS } from '../SearchResults/constants';
import {
  searchDataToSearchParams,
  useBuildNewSearchLink
} from '../SearchResults/utils/searchParams';
import { selectSearchRequestResponse } from '@/redux/selectors/searchSelectors';
import { useLazyGetAutocompleteSuggestionsQuery } from '@/redux/api/client/search';
import { selectFindAProgram } from '@/redux/selectors/findAProgramSelectors';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import {
  clearFindAProgramFilters,
  findAProgramSuccess,
  toggleFindAProgramFilter
} from '@/redux/slices/findAProgramSlice';
import FindAProgram from '../FindAProgram/FindAProgram';
import { trackEvent } from '@/analytics/utils';
import { setPreselectedFiltersThatHaveBeenDeselected } from '@/redux/slices/searchSlice';
import { AnalyticsEventName, FilterContext, SearchContext } from '@/analytics/constants';
import { trackSearchEvent } from '@/analytics/search';

const numericRegex = /^\d+$/;

export interface SearchOverlayProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  closeLabel?: string;
  triggerButton: React.ReactNode;
  defaultQuery?: string;
  context?: FilterContext;
  searchContext?: SearchContext;
}

interface SearchOverlayPrivateProps {
  Trigger: typeof Dialog.Trigger;
}

const SearchOverlay: React.FC<SearchOverlayProps> & SearchOverlayPrivateProps = ({
  isOpen: isOpenDefault = false,
  closeLabel = 'Close',
  triggerButton,
  defaultQuery = '',
  context,
  searchContext
}) => {
  const [searchText, setSearchText] = useState(defaultQuery);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  const { filterGroups, data, preselectedFiltersThatHaveBeenDeselected } =
    useSelector(selectFindAProgram);
  const dispatch = useDispatch();
  const selectedSearchData = useSelector(selectSearchRequestResponse);
  const pageLinkPaths = useSelector(selectPageLinkPaths);
  const [params] = useSearchParams();
  const { pathname } = useLocation();
  const searchData = pathname === pageLinkPaths.Search ? selectedSearchData : null;
  const [filled, setFilled] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);

  const [getAutocompleteSuggestions] = useLazyGetAutocompleteSuggestionsQuery();
  const fetchSuggestionsRef = useRef<ReturnType<typeof getAutocompleteSuggestions>>();
  const [suggestions, setSuggestions] = useState<ReturnType<typeof processSuggestions> | undefined>(
    undefined
  );

  const resetSearchText = useCallback(() => {
    setSearchText(defaultQuery);
  }, [defaultQuery]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);

    if (searchData?.request && searchData?.response) {
      // On open, pass existing search data to the overlay
      dispatch(
        findAProgramSuccess({
          request: searchData.request,
          response: searchData.response,
          preselectedFiltersThatHaveBeenDeselected:
            searchData.preselectedFiltersThatHaveBeenDeselected
        })
      );
    }
  }, [dispatch, searchData]);

  const handleClose = useCallback(() => {
    setIsOpen(false);

    // TODO This could be a little nicer
    // Ideally we should:
    // - Fade out filters
    // - Clear filters
    // - Close overlay
    setTimeout(() => {
      dispatch(clearFindAProgramFilters({}));
      resetSearchText();
    }, 500);
  }, [dispatch, resetSearchText]);

  const handleSuggestionSelect = useCallback(
    (suggestion: Suggestion) => {
      // Handle select filter
      if (suggestion.filterGroup && suggestion.filterValue) {
        setSearchText('');
        dispatch(
          toggleFindAProgramFilter({
            groupTypeId: suggestion.filterGroup,
            value: suggestion.filterValue,
            name: suggestion.text
          })
        );
        // Handle select keyword
      } else {
        setSearchText(suggestion.text);
        inputRef.current?.focus();
      }

      trackEvent({
        eventName: AnalyticsEventName.AutoComplete,
        parameters: {
          term_group_name: suggestion.filterGroup || 'keyword',
          term_value:
            suggestion.filterValue && !numericRegex.test(suggestion.filterValue)
              ? suggestion.filterValue
              : suggestion.text
        }
      });

      setSuggestions(undefined);
    },
    [dispatch]
  );

  const suggestionListElement = listRef.current;

  const completionText = useMemo(
    () => suggestions?.suggestionWithExactMatch?.text.substring(searchText.length),
    [suggestions, searchText]
  );

  const fetchSuggestions = useCallback(
    async (newSearchText: string) => {
      //If a previous fetch is running, it will be aborted. This can happen if the user types too quickly.
      if (fetchSuggestionsRef.current) {
        fetchSuggestionsRef.current.abort();
      }
      fetchSuggestionsRef.current = getAutocompleteSuggestions(newSearchText);
      const data = await fetchSuggestionsRef.current.unwrap();
      fetchSuggestionsRef.current = undefined;
      // Get suggestions from both API and FE at the same time so results update in tandem
      const { suggestions } = getApiAndFeSuggestions({ data, filterGroups });
      const processedSuggestions = processSuggestions(suggestions, newSearchText);
      setSuggestions(processedSuggestions);
    },
    [filterGroups, getAutocompleteSuggestions]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchText = e.target.value;
      setSearchText(newSearchText);
      setFilled(false);
      if (newSearchText) {
        fetchSuggestions(newSearchText);
      }
    },
    [fetchSuggestions]
  );

  const handleFill = useCallback(() => {
    if (suggestions?.suggestionWithExactMatch) {
      setSearchText(searchText + completionText);
      setFilled(true);
      inputRef?.current?.setSelectionRange(searchText.length, searchText.length);
      handleSuggestionSelect(suggestions.suggestionWithExactMatch);
    }
  }, [suggestions, completionText, searchText, inputRef, handleSuggestionSelect]);

  const handleInputFocus = () => {
    setSelectedIndex(null);
    setTimeout(() => {
      // place cursor at end of input
      inputRef.current?.setSelectionRange(searchText.length, searchText.length);
    }, 0);
  };

  useEffect(() => {
    const firstSuggestion = suggestionListElement?.querySelectorAll('li');

    if (firstSuggestion && firstSuggestion.length > 0 && selectedIndex !== null) {
      firstSuggestion[selectedIndex]?.focus();
    }
  }, [selectedIndex, suggestionListElement]);

  const handleSuggestionListKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>) => {
      const filteredSuggestionList = suggestions?.filteredSuggestionList || [];
      if (
        e.key === 'ArrowDown' &&
        selectedIndex !== null &&
        selectedIndex < filteredSuggestionList.length - 1
      ) {
        e.preventDefault();
        setSelectedIndex(prevIndex => (prevIndex === null ? 0 : prevIndex + 1));
      } else if (e.key === 'ArrowUp' && selectedIndex !== null && selectedIndex > 0) {
        e.preventDefault();
        setSelectedIndex(prevIndex => (prevIndex === null ? 0 : prevIndex - 1));
      } else if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        if (selectedIndex !== null) {
          const selectedSuggestion = filteredSuggestionList[selectedIndex];
          handleSuggestionSelect(selectedSuggestion);
        }
      } else if (e.key === 'Escape') {
        e.preventDefault();
        resetSearchText();
        setSelectedIndex(null);
        setTimeout(() => inputRef.current?.focus(), 20);
      } else if (e.key === 'Tab') {
        setSelectedIndex(null);
      }
    },
    [suggestions, selectedIndex, inputRef, resetSearchText, handleSuggestionSelect]
  );

  const onNewParams = useCallback(
    (newParams: URLSearchParams) => {
      let isNewSearch = true;

      if (searchData?.response) {
        const oldParams = searchDataToSearchParams(searchData.response);
        oldParams.delete(FILTERS.CONTENT_TABS);

        if (
          // The user didn't make any changes - they just opened and pressed the search button
          oldParams.toString() === newParams.toString() &&
          searchText === (params.get(QUERY_PARAMS.QUERY) || '')
        ) {
          isNewSearch = false;
        }
      }

      if (searchText) {
        newParams.set(QUERY_PARAMS.QUERY, searchText);
      }

      newParams.set(QUERY_PARAMS.SEARCH_OVERLAY, '1');

      if (isNewSearch) {
        // Flag to reset search state in the search results page
        newParams.set(QUERY_PARAMS.NEW_SEARCH, '1');
      }

      if (preselectedFiltersThatHaveBeenDeselected.length) {
        dispatch(
          setPreselectedFiltersThatHaveBeenDeselected(preselectedFiltersThatHaveBeenDeselected)
        );
      }

      return newParams;
    },
    [preselectedFiltersThatHaveBeenDeselected, searchText, searchData, params, dispatch]
  );

  const navigate = useNavigate();

  const handleClearInput = useCallback(() => {
    setSearchText('');
    setSuggestions(undefined);
    setFilled(false);
  }, []);

  const handleClearAll = useCallback(() => {
    handleClearInput();

    dispatch(clearFindAProgramFilters({ context, searchContext }));
  }, [context, dispatch, handleClearInput, searchContext]);

  const buildNewSearchLink = useBuildNewSearchLink();
  const onSearch = useCallback(() => {
    if (data) {
      const { newSearchLink, newSearchParams } = buildNewSearchLink(data, onNewParams);
      trackSearchEvent(newSearchParams);
      navigate(newSearchLink);
    }
  }, [navigate, data, buildNewSearchLink, onNewParams]);

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onSearch();
        setSuggestions(undefined);
        handleClose();
      }

      if (e.key === 'Tab' && !filled && suggestions?.suggestionWithExactMatch) {
        e.preventDefault();
        handleFill();
      }
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        if (suggestionListElement) {
          setSelectedIndex(0);
        }
      }
    },
    [
      suggestions,
      filled,
      suggestionListElement,
      handleFill,
      setSelectedIndex,
      handleClose,
      onSearch
    ]
  );

  return (
    <Dialog.Root open={isOpen} onOpenChange={o => (o ? handleOpen() : handleClose())}>
      <Dialog.Portal>
        <Dialog.Content
          aria-label="Search overlay to find a program, publication, or credit"
          aria-description="Search field with suggestions and filter options dialog"
          aria-describedby={undefined}
          aria-labelledby={undefined}
          className="group fixed inset-0 z-sticky-header flex h-dvh flex-col bg-white transition-all data-[state=closed]:animate-searchOverlayFadeOut data-[state=open]:animate-searchOverlaySlideIn"
        >
          <div
            className={classnames(
              'bg-white group-data-[state=open]:animate-in group-data-[state=closed]:animate-out group-data-[state=closed]:fade-out-0 group-data-[state=open]:fade-in-0',
              height
            )}
          >
            <SearchOverlayHeader
              closeLabel={closeLabel}
              setSearchText={setSearchText}
              inputRef={inputRef}
              searchText={searchText}
              suggestionList={suggestions?.filteredSuggestionList}
              onClear={handleClearInput}
              onInputKeyDown={handleInputKeyDown}
              onInputChange={handleInputChange}
              completionText={completionText}
              onFill={handleFill}
              isFilled={filled}
              onFocus={handleInputFocus}
            />
          </div>

          <div className="relative size-full overflow-hidden">
            <FindAProgram
              title="Refine your search using the filters below"
              onSearch={handleClose}
              onClearAll={handleClearAll}
              onNewParams={onNewParams}
              footerFixed={true}
              context={context}
              searchContext={searchContext}
              suggestions={
                <SearchSuggestion
                  listRef={listRef}
                  suggestionList={suggestions?.filteredSuggestionList}
                  searchText={searchText}
                  onSuggestionSelect={handleSuggestionSelect}
                  onListKeyDown={handleSuggestionListKeyDown}
                  selectedIndex={selectedIndex}
                />
              }
            />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
      <SearchOverlay.Trigger asChild id="search-overlay-trigger">
        {triggerButton}
      </SearchOverlay.Trigger>
    </Dialog.Root>
  );
};

SearchOverlay.Trigger = Dialog.Trigger;

export default SearchOverlay;
