import { IFacetGroup } from '@/@types/client-api';
import { useGetCreditJurisdictionsQuery } from '@/redux/api/client/cle';
import { translateStoreFilterToComponentProps } from '../utils/translateStoreDataToComponentsProps';
import { FilterGroupProps } from '../../Filter/types';
import { orderByCreditClass } from '@/components/ui/SearchResults/utils/sortFunctions';

const ONLY_CHILD_CREDIT_CLASS = 'ONLY_CHILD_CREDIT_CLASS';

const useJurisdictonsWithSectionProps = ({
  skip,
  filterGroup
}: {
  skip?: boolean;
  filterGroup: IFacetGroup | null;
}): { jurisdictionsWithSectionProps: FilterGroupProps['items']; isLoading: boolean } => {
  const { isLoading, data: creditJurisdictions } = useGetCreditJurisdictionsQuery(undefined, {
    skip
  });

  const jurisdictionOrder = [...(creditJurisdictions ?? [])]
    .sort(orderByCreditClass)
    .reduce<number[]>((acc, jurisdiction) => {
      if (jurisdiction.creditClass_SK) {
        if (!acc.includes(jurisdiction.creditClass_SK)) {
          acc.push(jurisdiction.creditClass_SK ?? 0);
        }
      }
      return acc;
    }, []);

  if (!filterGroup || isLoading) {
    return { jurisdictionsWithSectionProps: [], isLoading: isLoading };
  }

  const mappedFilterGroup = translateStoreFilterToComponentProps(filterGroup);
  const onlyChildCreditClassSks: number[] = [];

  const sections =
    mappedFilterGroup.items.reduce<Record<string, FilterGroupProps['items']>>((acc, curr) => {
      const creditInfoForFilter =
        creditJurisdictions?.find(credit => credit.id === curr.value) ||
        // If we can't find the exact jurisdiction,
        // find another jurisdiction in the same class
        creditJurisdictions?.find(credit => credit.creditClass === curr.sectionId);

      if (!creditInfoForFilter?.creditClass_SK) {
        return acc;
      }

      const doesCreditClassHaveMultipleChildren =
        (mappedFilterGroup.items?.filter(c => c.sectionId === curr.sectionId).length ?? 0) > 1;

      const key = doesCreditClassHaveMultipleChildren
        ? String(creditInfoForFilter.creditClass_SK)
        : ONLY_CHILD_CREDIT_CLASS;

      if (!doesCreditClassHaveMultipleChildren) {
        onlyChildCreditClassSks.push(creditInfoForFilter.creditClass_SK);
      }

      const newItem = {
        ...curr,
        sectionId: key
      };

      if (doesCreditClassHaveMultipleChildren) {
        newItem.sectionTitle = creditInfoForFilter.creditClass ?? undefined;
      } else {
        newItem.sectionTitle = 'CE for Other Licenses';
        newItem.sectionIsTopLevel = true;
      }

      if (!acc[key]) {
        acc[key] = [newItem];
      } else {
        acc[key].push(newItem);
      }

      return acc;
    }, {}) || {};

  const onlyChildCreditClassPosition = onlyChildCreditClassSks.reduce((acc, curr) => {
    if (jurisdictionOrder.indexOf(curr) < acc) {
      return jurisdictionOrder.indexOf(curr);
    }

    return acc;
  }, 999);

  return {
    isLoading: isLoading,
    jurisdictionsWithSectionProps: Object.entries(sections).flatMap(([key, items]) =>
      items.map(item => {
        return {
          ...item,
          sectionIsTopLevel: key === ONLY_CHILD_CREDIT_CLASS,
          sectionOrder:
            key === ONLY_CHILD_CREDIT_CLASS
              ? onlyChildCreditClassPosition
              : jurisdictionOrder.indexOf(Number(key))
        };
      })
    )
  };
};

export default useJurisdictonsWithSectionProps;
