import classnames from 'classnames';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import {
  POECTabsContainerBlock as POECTabsContainerBlockProps,
  POECTabItemBlock as POECTabItemBlockProps
} from '@/@types/content';
import { useState } from 'react';
import Button from '@/components/ui/Buttons/Button';
import GlobalTabs, { TabTrigger } from '../../ui/Tab';
import Panel from '@/components/ui/Panel';
import Text from '@/components/cms/Text';
import ContentArea from '@/components/cms/ContentArea';
import { IContent } from '@/@types/cms';
import Block from '@/components/cms/Block';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { transformIfPLIUrl } from '@/utils/helpers';

type TabBlockTab = TabTrigger & {
  content?: IContent[];
};

export type POECTabItemProps = POECTabItemBlockProps;

const POECTabItemBlock: React.FC<POECTabsContainerBlockProps> = ({
  tabs,
  overlayHeading,
  overlayButtonText,
  overlayContent,
  url
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const tabsList = tabs as POECTabItemBlockProps[];
  const editMode = isEditOrPreviewMode();
  const [searchParams] = useSearchParams();
  const activetab = searchParams.get('activetab');
  const navigate = useNavigate();
  const parentRouteSegment = transformIfPLIUrl(url)?.substring(1);

  const tabsBlocks: TabBlockTab[] =
    tabsList?.map(tab => ({
      label: tab.tabTitle ?? '',
      id: tab.tabTitle || '',
      content: tab.content
    })) || [];

  const selectedTabBlock =
    tabsBlocks.find(tab => tab.id.toUpperCase() === activetab?.toUpperCase()) ?? tabsBlocks[0];

  const handleTabChange = (tab: TabBlockTab) => {
    navigate(`/${parentRouteSegment}?activetab=${tab.id}`);
  };

  const onChange = (value: boolean) => {
    setIsOpen(value);
  };

  const modalElement = (
    <Panel isOpen={isOpen} onOpenChange={() => onChange(!isOpen)}>
      <Panel.Trigger asChild>
        {(overlayButtonText || editMode) && (
          <Button
            propertyName="OverlayButtonText"
            color="black"
            size="large"
            label={overlayButtonText}
          />
        )}
      </Panel.Trigger>
      <Panel.Content>
        <Panel.Header className="!h-7">
          <Text className="heading-5">{overlayHeading}</Text>
        </Panel.Header>
        <Panel.Body>
          <ContentArea
            propertyName="ProductSubBlock"
            components={overlayContent}
            className={classnames('grid grid-rows-1 gap-4')}
          />
        </Panel.Body>
      </Panel.Content>
    </Panel>
  );

  return (
    <Block>
      <GlobalTabs
        tabsTriggers={tabsBlocks}
        variant="page"
        selectedValue={selectedTabBlock}
        setSelectedValue={handleTabChange}
        registerButton={modalElement}
        container={true}
        sticky={true}
      >
        <ContentArea className="mt-10" propertyName="Item" components={selectedTabBlock.content!} />
      </GlobalTabs>
    </Block>
  );
};

export default POECTabItemBlock;
