import { LiveProgramInteractiveWebcastVariation as LiveProgramInteractiveWebcastVariationProps } from '@/@types/content';
import withNav from '@/components/hocs/withNav';

const LiveProgramInteractiveWebcastVariationComponent = (
  variant: LiveProgramInteractiveWebcastVariationProps
) => {
  return (
    <>
      <p>Incomplete: refer to SIREHOME-553 LIVE ILC PDP</p>
      <p>code: {variant.code}</p>
      <p>format: LiveProgramInteractiveWebcastVariation</p>
    </>
  );
};

const LiveProgramInteractiveWebcastVariation = withNav(
  LiveProgramInteractiveWebcastVariationComponent
);
export default LiveProgramInteractiveWebcastVariation;
