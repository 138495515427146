import Shimmer from '@/components/ui/Shimmer';

interface PageShimmerProps {
  show?: boolean;
}

const PageLoading: React.FC<PageShimmerProps> = ({ show }) => {
  if (!show) return null;

  return (
    <>
      <header className="sticky top-0 z-sticky-header transition-all">
        <div className={'container relative flex flex-col gap-5 pb-6 pt-7 md:py-5'}>
          <div className="flex  w-full md:min-h-14 lg:items-stretch">
            <img className="h-6 md:h-8" src="/logo-red.svg" width="64" height="32" alt="PLI Logo" />
            <div className="h-5 w-24 flex-auto grow rounded-md" />
            <Shimmer className="h-5 w-24 flex-auto rounded-md" />
            <div className="h-5 w-24 flex-auto grow rounded-md" />
            <Shimmer className="h-5 w-24 flex-auto rounded-md" />
            <div className="h-5 w-24 flex-auto grow rounded-md" />
            <Shimmer className="h-5 w-24 flex-auto rounded-md" />
          </div>
        </div>
      </header>
      <div className="container module-spacing">
        <Shimmer className="mt-0 h-60 w-full rounded-lg md:h-60" />
        <div className="mt-8 flex items-center justify-between">
          <div className="flex gap-2">
            <Shimmer className="h-5 w-24 rounded-md" />
            <Shimmer className="h-5 w-px rounded-md" />
            <Shimmer className="h-5 w-24 rounded-md" />
          </div>
          <div className=" gap-4 md:flex">
            <div className="flex items-center gap-2">
              <Shimmer className="size-9 rounded-full" />
              <Shimmer className="h-5 w-28 rounded-md" />
            </div>
            <div className="flex items-center gap-2">
              <Shimmer className="size-9 rounded-full" />
              <Shimmer className="h-5 w-16 rounded-md" />
            </div>
          </div>
        </div>

        <div className="mt-10 hidden flex-col gap-8 lg:flex">
          <Shimmer className="h-10 w-5/6 rounded-lg" />
          <Shimmer className="h-28 w-full rounded-lg" />
          <Shimmer className="h-64 w-1/2 rounded-lg" />
          <Shimmer className="h-10 w-5/6 rounded-lg" />
          <Shimmer className="h-28 w-full rounded-lg" />
          <Shimmer className="h-64 w-1/2 rounded-lg" />
          <Shimmer className="h-10 w-5/6 rounded-lg" />
          <Shimmer className="h-28 w-full rounded-lg" />
          <Shimmer className="h-64 w-1/2 rounded-lg" />
        </div>

        <div className="mt-10 w-full flex-row gap-8">
          <div className="flex"></div>
          <Shimmer className="mt-0 h-60 w-1/2 rounded-lg md:h-60 " />
        </div>
        <div className="mt-0 flex h-60 w-1/2 rounded-lg md:h-60" />
        <Shimmer className="mt-0 h-60 w-1/2 rounded-lg md:h-60" />
      </div>
    </>
  );
};

export default PageLoading;
