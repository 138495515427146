import Icon, { IconProps } from '@/components/ui/Icon';
import classnames from 'classnames';

enum Color {
  White = 'white',
  Black = 'black',
  Red = 'red'
}

export interface RoundedIconProps {
  className?: string;
  color?: `${Color}`;
  icon: IconProps;
}

const RoundedIcon: React.FC<RoundedIconProps> = ({ className, color = Color.White, icon }) => {
  const componentClasses = classnames(
    'flex aspect-square w-10 items-center justify-center overflow-hidden rounded-full',
    {
      'bg-white': color === Color.White,
      'bg-black': color === Color.Black,
      'bg-red': color === Color.Red
    },
    className
  );

  const iconClasses = classnames({
    'text-black': color === Color.White,
    'text-white': color === Color.Black || color === Color.Red
  });

  return (
    <div className={componentClasses}>
      <Icon size="large" className={classnames(iconClasses, icon.className)} {...icon} />
    </div>
  );
};

export default RoundedIcon;
