import { IContent } from '@/@types/cms';
import ContentArea from '@/components/cms/ContentArea';
import classnames from 'classnames';
import Divider from '../Divider';
import useInViewport from '@/hooks/useInViewport';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setIsFooterInView } from '@/redux/slices/pdpSlice';

export const PDPBottomDividerId = 'pdp-bottom-divider';
interface PDPFooterProps {
  product?: IContent;
  isLoading: boolean;
  bContent?: IContent[];
}
export const PDPFooter = ({ product, isLoading, bContent }: PDPFooterProps) => {
  const dispatch = useDispatch();

  const [footerRef, isFooterInView] = useInViewport<HTMLDivElement>({
    offset: -100 // So Ecom Sticky button is hidden slightly after the dividing line enters view and not too soon
  });

  useEffect(() => {
    if (isFooterInView) {
      dispatch(setIsFooterInView(true));
    } else {
      dispatch(setIsFooterInView(false));
    }
  }, [isFooterInView, dispatch]);

  if (!product && !bContent) return;

  const content: IContent[] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bContent ?? (product as any)?.bottomOfPageContent?.expandedValue ?? [];

  const contentArea = content?.length ? <ContentArea components={content} /> : <></>;

  return (
    <div ref={footerRef}       data-component={"PDPFooter"}>
      <Divider id={PDPBottomDividerId} color="light" />
      <div className={classnames({ hidden: isLoading }, 'module-spacing-pdp')}>{contentArea}</div>
    </div>
  );
};
