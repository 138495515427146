import { useMemo } from 'react';
import { buildVenueProps } from '@/components/ui/PDP/helpers';
import LocationDetails from '@/components/ui/PDP/LocationDetails';
import { Variant } from '@/redux/slices/pdpSlice';

interface IPDPProgramLocationDetailsLayoutBlockProps {
  variant: Variant;
}

const IPDPProgramLocationDetailsLayoutBlock: React.FC<
  IPDPProgramLocationDetailsLayoutBlockProps
> = ({ variant }) => {
  const venueProps = useMemo(() => buildVenueProps(variant), [variant]);

  if (!venueProps) return null;

  return <LocationDetails {...venueProps} />;
};

export default IPDPProgramLocationDetailsLayoutBlock;
