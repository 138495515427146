import { IFacetResults, ISearchResultItem } from '@/@types/client-api';
import { IContent } from '@/@types/cms';
import type { HomePageCarousel } from '@/@types/content';
import HomeCarousel from '@/components/ui/HomeCarousel/HomeCarousel';
import { getExpiredStatus } from '@/components/ui/PDP/helpers';
import { Component } from '@/lib/ComponentFactory';
import { useGetLibraryUpcomingRegistrationsQuery } from '@/redux/api/client/library';
import { useCallback, useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { BackendDateFormat, formatDate } from '@/utils/helpers';
import useToast from '@/hooks/useToast';
import { delayedActionMessageBuilder } from '@/redux/helpers/delayedActionsMapper';
import { useSearchParamsStable } from '../../../hooks/useSearchParams';

interface UpcomingProgramItem extends IContent {
  upcomingLiveRegistrationsLimit?: number;
  upcomingDaysLimit?: number;
}

interface DataFilter {
  facetResults?: IFacetResults;
  searchResults?: {
    searchResultsItems?: Record<string, unknown>[];
  };
}

interface CarouselItem extends IContent {
  code?: string;
}

const podcastTypes = ['PodcastEpisodeProduct', 'PodcastEpisodeVariation'];

const liveVariationTypes = [
  'LiveProgramProduct',
  'LiveProgramInteractiveVariation',
  'LiveProgramGroupcastVariation',
  'LiveProgramOhbVariation',
  'LiveProgramInteractiveWebcastVariation',
  'LiveProgramPbrSeminarVariation',
  'LiveProgramPBRWebcastVariation',
  'LiveProgramSeminarVariation',
  'LiveProgramWebcastVariation'
];

const onDemandVariationTypes = ['OnDemandProgramVariation', 'OnDemandProgramProduct'];

const buildProductSlides = (
  carouselItems: IContent[],
  upcomingPrograms?: Record<string, unknown>[]
) => {
  const filteredCarouselItems = carouselItems.filter(item => {
    const { contentType } = item;

    if (contentType && liveVariationTypes.includes(contentType[1]) && getExpiredStatus(item)) {
      return false;
    }
    return true;
  });

  let items: IContent[] = filteredCarouselItems;

  if (
    upcomingPrograms &&
    carouselItems.find(x => x.contentType?.[1] === 'UpcomingLiveSlideBlock')
  ) {
    const index = carouselItems.findIndex(x => x.contentType?.[1] === 'UpcomingLiveSlideBlock');
    const objects = filteredCarouselItems.filter(
      x => x.contentType?.[1] !== 'UpcomingLiveSlideBlock'
    );

    items = [
      ...objects.slice(0, index),
      ...upcomingPrograms.map(upitem => ({
        ...upitem,
        code: upitem?.productCode,
        sellingPoints: upitem?.summaryText,
        isSummaryTextFromSellingPoints: upitem?.isSummaryTextFromSellingPoints,
        contentType: ['Product', 'LiveProgramProduct']
      })),
      ...objects.slice(index)
    ];
  } else if (
    !upcomingPrograms &&
    carouselItems.find(x => x.contentType?.[1] === 'UpcomingLiveSlideBlock')
  ) {
    items = filteredCarouselItems.filter(x => x.contentType?.[1] !== 'UpcomingLiveSlideBlock');
  }

  const slides: IContent[] = items.map(item => {
    const { contentType } = item;
    if (contentType && podcastTypes.includes(contentType[1])) {
      return {
        ...item,
        contentType: ['Block', 'PodcastProgramSlide']
      };
    }

    if (contentType && liveVariationTypes.includes(contentType[1])) {
      return {
        ...item,
        contentType: ['Block', 'LiveSliceBlock']
      };
    }

    if (contentType && onDemandVariationTypes.includes(contentType[1])) {
      return {
        ...item,
        contentType: ['Block', 'OnDemandSliceBlock']
      };
    }

    return item;
  });

  return slides;
};

const HomePageCarouselBlock: React.FC<HomePageCarousel> = ({ carouselItems, ...props }) => {
  const [slides, setSlides] = useState<IContent[]>();
  const [logoColors, setLogoColors] = useState<string[]>();
  const { showSuccessMessage } = useToast();
  const { searchParams, setSearchParams } = useSearchParamsStable();
  const [upcomingRegQueryParams, setUpcomingRegQueryParams] = useState<
    { dateRange: string } | undefined
  >(undefined);

  const upcomingRegistrationsConfig: UpcomingProgramItem | undefined = carouselItems?.find(
    i => i.contentType?.[1] === 'UpcomingLiveSlideBlock'
  );

  const {
    data: upcomingProgramsList,
    isFetching,
    isSuccess,
    refetch
  } = useGetLibraryUpcomingRegistrationsQuery(upcomingRegQueryParams, {
    skip: !upcomingRegistrationsConfig || !upcomingRegQueryParams
  });

  useEffect(() => {
    if (!upcomingRegistrationsConfig) return;

    const { upcomingDaysLimit } = upcomingRegistrationsConfig;

    const now = new Date();
    const minPossibleNow = addDays(now, -upcomingDaysLimit!);

    const fromDate = formatDate(minPossibleNow, BackendDateFormat);
    const toDate =
      upcomingDaysLimit && upcomingDaysLimit > 0
        ? formatDate(addDays(now, upcomingDaysLimit), BackendDateFormat)
        : null;

    setUpcomingRegQueryParams(toDate ? { dateRange: `${fromDate};${toDate}` } : undefined);
  }, [carouselItems, upcomingRegistrationsConfig]);

  useEffect(() => {
    if (!upcomingRegQueryParams) return;
    refetch();
  }, [upcomingRegQueryParams, refetch]);

  const isFinishedEvent = useCallback((item: ISearchResultItem) => {
    const now = new Date();

    const nowUtc = new Date(now.toISOString());

    const startDateTimesUtc = item.locations?.map(x => new Date(x.startTimeUtc!)) as Date[];
    const endDateTimesUtc = item.locations?.map(x => new Date(x.endTimeUtc!)) as Date[];

    const { minDate: minStartDate } = findMinMaxDates(startDateTimesUtc);
    const { maxDate: maxEndDate } = findMinMaxDates(endDateTimesUtc);
    const startDateUtc = minStartDate;
    const endDateUtc = maxEndDate;
    return startDateUtc < nowUtc && endDateUtc < nowUtc;
  }, []);

  const findMinMaxDates = (dates: Date[]) => {
    return dates.reduce(
      (acc, date) => {
        const d = new Date(date);
        acc.minDate = d < acc.minDate ? d : acc.minDate;
        acc.maxDate = d > acc.maxDate ? d : acc.maxDate;
        return acc;
      },
      { minDate: new Date(dates[0]), maxDate: new Date(dates[0]) }
    );
  };

  useEffect(() => {
    if (!carouselItems) return;
    if (upcomingRegistrationsConfig && (isFetching || !isSuccess)) return;

    if (upcomingProgramsList) {
      const upcomingItems =
        (upcomingProgramsList as unknown as DataFilter)?.searchResults?.searchResultsItems?.filter(
          r =>
            !carouselItems?.some(l => (l as CarouselItem).code === r.variationPk) &&
            !isFinishedEvent(r)
        ) || [];

      const maxUpcoming = Math.min(
        upcomingItems?.length || 0,
        upcomingRegistrationsConfig?.upcomingLiveRegistrationsLimit || 0
      );

      if (carouselItems) {
        setSlides(buildProductSlides(carouselItems, upcomingItems.slice(0, maxUpcoming)));
      }
    } else {
      setSlides(buildProductSlides(carouselItems));
    }
  }, [
    carouselItems,
    upcomingRegistrationsConfig,
    isFetching,
    upcomingProgramsList,
    isSuccess,
    isFinishedEvent
  ]);

  useEffect(() => {
    if (!slides) return;

    const logoColorsList = slides?.map(item =>
      'logoColor' in item && item.logoColor === 'Red' ? 'red' : 'white'
    ) as string[];

    setLogoColors(logoColorsList);
  }, [slides]);

  useEffect(() => {
    if (searchParams.has('successAction')) {
      const delayedAction = searchParams.get('successAction');
      const program = searchParams.get('program');
      let response = null;
      if (searchParams.has('response')) {
        response = JSON.parse(searchParams.get('response') ?? '{}');
      }
      const { cartUrl = null } = response || {};
      if (delayedAction) {
        if (program) {
          const { title, url } = JSON.parse(decodeURIComponent(program));
          const successMessage = delayedActionMessageBuilder[delayedAction]?.({
            title,
            url: delayedAction === 'postCartItem' ? cartUrl : url
          });
          showSuccessMessage(successMessage);
        } else {
          if (delayedAction === 'postCartItem') {
            const successMessage = delayedActionMessageBuilder[delayedAction]?.({
              title: 'Added to Cart',
              url: cartUrl
            });
            showSuccessMessage(successMessage);
          }
        }
      }

      setSearchParams(
        existing => {
          existing.delete('successAction');
          existing.delete('program');
          existing.delete('response');
          return existing;
        },
        { replace: true, preventScrollReset: true }
      );
    }
  }, [searchParams, showSuccessMessage, setSearchParams]);

  return (
    <>
      {slides && logoColors && (
        <HomeCarousel {...props} itemLogos={logoColors}>
          {slides?.map((component, idx) => (
            <Component key={component.contentLink?.guidValue || idx} {...component} />
          ))}
        </HomeCarousel>
      )}
    </>
  );
};

export default HomePageCarouselBlock;
