import { useCallback, useMemo, useState } from 'react';
import { CreditJurisdiction, ICertificateViewModel, UserTrackerData } from '@/@types/client-api';
import CreditModal from '@/components/ui/CreditTracker/Modals/CreditModal';
import { formatDate, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import { creditTrackerSetSelectedRegion } from '@/redux/slices/creditTrackerSlice';
import { ModalProps } from '@/components/ui/Modal/Modal';

type EditCertificateModalProps = {
  certificate: ICertificateViewModel;
  triggerButton: JSX.Element;
  isCertificatesPage?: boolean;
  creditJurisdictions?: CreditJurisdiction[];
};

const EditCertificateModal = ({
  certificate,
  triggerButton,
  isCertificatesPage,
  creditJurisdictions
}: EditCertificateModalProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();

  const region = useMemo((): UserTrackerData => {
    const creditJurisdiction = creditJurisdictions?.find(
      item => item.id === certificate.creditRegionShortDescription
    );
    return {
      customerId: certificate.customerId,
      creditRegion_SK: creditJurisdiction?.creditRegion_SK,
      creditRegionShortDescription: certificate.creditRegionShortDescription
    };
  }, [certificate, creditJurisdictions]);

  const handleSetOpen = useCallback(
    (open: boolean) => {
      setOpen(open);
      dispatch(creditTrackerSetSelectedRegion(open ? region : undefined));
    },
    [region, dispatch]
  );

  return (
    <CreditModal
      isCertificatesPage={isCertificatesPage}
      open={isOpen}
      setOpen={handleSetOpen as ModalProps['setOpen']}
      triggerButton={triggerButton}
      eyebrowText={certificate.creditRegionDescription ?? ''}
      isCarryover={false}
      certificateActionType="edit"
      data={{
        ...certificate,
        dateCompleted: certificate.dateCompleted
          ? formatDate(parseISO(certificate.dateCompleted!), 'MM/dd/yyyy')
          : ''
      }}
    />
  );
};

export default EditCertificateModal;
