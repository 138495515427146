import {
  OperationalSearchResultPage as OperationalSearchResultPageProps,
  SiteSettings
} from '@/@types/content';
import withNav from '../hocs/withNav';
import { useMemo } from 'react';
import ContentArea from '../cms/ContentArea';
import { FacetGroupOverrides } from '../ui/SearchResults/utils/searchParams';
import { FacetOperation } from '@/@types/client-api';
import { FILTERS, InvariantFilter } from '../ui/SearchResults/constants';
import { useSearchParamsStable } from '@/hooks/useSearchParams';

const OperationalSearchResultPage: React.FC<OperationalSearchResultPageProps & SiteSettings> = ({
  searchResultBlock,
  mainContentArea,
  facetOperation,
  facetGroupType,
  pageHeadingContent
}) => {
  const { searchParams } = useSearchParamsStable();

  const facetOverride: FacetGroupOverrides | undefined = useMemo(() => {
    let operation: FacetOperation = FacetOperation._2;
    if (facetGroupType) {
      const translated = FacetOperation[facetOperation as keyof typeof FacetOperation];
      switch (translated?.toString()) {
        case '_0':
          operation = FacetOperation._0;
          break;
        case '_1':
          operation = FacetOperation._1;
          break;
      }
      return { [facetGroupType]: { operation: operation } } as FacetGroupOverrides;
    }
  }, [facetGroupType, facetOperation]);

  const invariantFilterValue = useMemo(() => {
    let out: InvariantFilter[] | undefined = undefined;

    const queryValue = facetGroupType ? searchParams?.get(facetGroupType) : undefined;
    if (queryValue) {
      const values = queryValue.split(';');
      out = values.map(a => {
        return {
          facetGroupType: facetGroupType!,
          name: '',
          value: a
        };
      });

      // If credit type is selected, add jurisdictions as invariants as well
      if (facetGroupType === FILTERS.CREDIT_TYPE) {
        const jurisdictionQueryValue = searchParams?.get(FILTERS.JURISDICTIONS);

        if (jurisdictionQueryValue) {
          const values = jurisdictionQueryValue.split(';');
          out.unshift(
            ...values.map(a => {
              return {
                facetGroupType: FILTERS.JURISDICTIONS,
                name: '',
                value: a
              };
            })
          );
        }
      }
    }

    return out;
  }, [facetGroupType, searchParams]);

  const renderSearchResultBlock = useMemo(() => {
    if (searchResultBlock?.length) {
      return (
        <ContentArea
          components={searchResultBlock}
          componentsProps={{
            facetOverrides: facetOverride,
            showFilters: true,
            invariantFilters: invariantFilterValue,
            // Prevent search hook from overriding settings in url params
            updateUrlParams: false
          }}
        />
      );
    }
  }, [facetOverride, invariantFilterValue, searchResultBlock]);

  return (
    <>
      <ContentArea components={pageHeadingContent} />
      <ContentArea components={mainContentArea} />
      {renderSearchResultBlock}
    </>
  );
};

const OperationalSearchResultPageComponent = withNav(OperationalSearchResultPage);
OperationalSearchResultPageComponent.displayName = 'OperationalSearchResultPage';
export default OperationalSearchResultPageComponent;
