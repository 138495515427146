import { useCallback } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import AvatarComponent from '@/components/ui/Avatar';
import Icon from '@/components/ui/Icon';
import Link from '@/components/cms/Link';
import Text from '@/components/cms/Text';
import type { FacultyLinkBlock as FacultyLinkBlockProps } from '@/@types/content';
import Block from '@/components/cms/Block';
import { OnTrackItemViewEvent } from './LinkBlock';

const FacultyLinkBlock: React.FC<
  FacultyLinkBlockProps & {
    onTrackItemViewEvent?: OnTrackItemViewEvent;
  }
> = ({ contentLink, link, image, facultyLabel, onTrackItemViewEvent }) => {
  const isLarge = useMediaQuery(ScreenSizeQueries.lg);
  const { text, ...linkProps } = link;

  const handleOnClick = useCallback(() => {
    onTrackItemViewEvent?.(text);
  }, [onTrackItemViewEvent, text]);

  return (
    <Block
      onClick={handleOnClick}
      element={Link}
      contentLinkID={contentLink?.id}
      {...linkProps}
      className="group flex content-between items-center gap-4"
    >
      <div className="flex grow items-center gap-4">
        <AvatarComponent
          propertyName="Image"
          fullName={text}
          className="w-[70px] md:w-[76px] lg:w-[90px]"
          imageUrl={image?.url}
          imageSizes={{
            xs: '70px',
            md: '76px',
            lg: '90px'
          }}
        />
        <div>
          <Text
            propertyName="Text"
            className="text-1 block font-medium transition group-hover:text-red"
          >
            {text}
          </Text>
          <Text propertyName="FacultyLabel" className="text-2 block text-gray-dark">
            {facultyLabel}
          </Text>
        </div>
      </div>
      <Icon name="chevron-right" size={isLarge ? 'large' : 'medium'} />
    </Block>
  );
};

export default FacultyLinkBlock;
