import { AccordionLinkBlock as AccordionLinkBlockProps } from '@/@types/content';
import * as Accordion from '@radix-ui/react-accordion';
import classnames from 'classnames';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import AccordionLinkItem, { AccordionLinkItemProps } from './AccordionLinkItem';
import { LinkItem } from '@/@types/cms';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';

const AccordionLinkBlock: React.FC<
  AccordionLinkBlockProps & {
    expandedItems: string[];
    headingLink: LinkItem;
    index: number;
  }
> = ({ accordionItems, contentLink, expandedItems, heading, index }) => {
  const value = `${contentLink?.guidValue || index}`;
  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

  const headerClasses = classnames(
    'overflow-hidden rounded-lg border border-gray-light bg-gray-feather transition-colors hover:border-silver hover:bg-gray-light',
    { 'border-x border-t': expandedItems.includes(value) }
  );

  const triggerClasses = classnames(
    'flex w-full flex-col items-start justify-between gap-4 p-4',
    'sm:flex-row sm:px-6',
    'lg:px-8'
  );

  const contentClasses = classnames(
    'rounded-b-lg border-gray-light bg-white data-[state=closed]:animate-accordionSlideUp data-[state=open]:animate-accordionSlideDown'
  );

  return (
    <Accordion.Item value={value}>
      <Accordion.Header className={headerClasses}>
        <Accordion.Trigger className={triggerClasses}>
          <Text element="span" propertyName="Title" className="text-1 text-left font-medium">
            {heading}
          </Text>
          <span className="flex w-auto items-center justify-center">
            <Text element="p" className="text-2-medium mr-1">
              {expandedItems.includes(value)
                ? stateSiteLabels?.collapseAllLabel
                : stateSiteLabels?.expandAllLabel}
            </Text>
            <Icon
              name={expandedItems.includes(value) ? 'chevron-up' : 'chevron-down'}
              size="medium"
            />
          </span>
        </Accordion.Trigger>
        <Accordion.Content className={contentClasses}>
          {accordionItems.map((item: AccordionLinkItemProps, index: number) => (
            <AccordionLinkItem {...item} key={`${index}-${item.description}`} />
          ))}
        </Accordion.Content>
      </Accordion.Header>
    </Accordion.Item>
  );
};

export default AccordionLinkBlock;
