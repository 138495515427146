import ProgramMaterialsModal from '@/components/ui/PDP/Modals/ProgramMaterialsModal';
import { MaterialsLabels } from '@/components/ui/PDP/labels';
import { useCallback, useEffect, useState } from 'react';
import { ModalProps } from '../Modal';
import { useLazyGetProgramMaterialsQuery } from '@/redux/api/client/library';
import { ItemAnalyticsModel, ProgramMaterial } from '@/@types/client-api';
import { AnalyticsContext } from '@/analytics/constants';
import { trackViewMaterialsEvent } from '@/analytics/library';
import { useFetchAnalyticsItems } from '@/analytics/useFetchAnalyticsItems';

export type MaterialsModalParams = {
  pk: string;
  title: string;
  itemAnalyticsModel?: ItemAnalyticsModel;
};

type SearchMaterialsModalProps = {
  params?: MaterialsModalParams;
  onClose?: () => void;
  triggerButton?: JSX.Element;
  context?: AnalyticsContext;
};

const SearchMaterialsModal = ({
  params,
  onClose,
  triggerButton,
  context
}: SearchMaterialsModalProps) => {
  const [isOpen, setOpen] = useState(false);
  const [materials, setMaterials] = useState<ProgramMaterial[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [getMaterials] = useLazyGetProgramMaterialsQuery();
  const { getAnalyticItem } = useFetchAnalyticsItems();

  const open = triggerButton ? isOpen : params !== undefined;

  const handleSetOpen = useCallback(
    (open: boolean) => {
      if (triggerButton) {
        setOpen(open);
      } else if (!open) {
        onClose?.();
      }
    },
    [onClose, triggerButton]
  );

  const trackEvent = useCallback(
    async (code: string) => {
      const data = await getAnalyticItem(code);
      if (data) {
        trackViewMaterialsEvent(data, context);
      }
    },
    [getAnalyticItem, context]
  );

  useEffect(() => {
    if (params?.pk && open) {
      trackEvent(params.pk);
    }
  }, [params?.pk, open, trackEvent]);

  useEffect(() => {
    if (!params?.pk || !open) {
      return;
    }
    setLoading(true);
    getMaterials(params.pk, true)
      .then(response => setMaterials(response.data ?? []))
      .catch(() => setMaterials([]))
      .finally(() => setLoading(false));
  }, [getMaterials, params, open, context]);

  return (
    <ProgramMaterialsModal
      open={open}
      setOpen={handleSetOpen as ModalProps['setOpen']}
      labels={MaterialsLabels}
      programCode={params?.pk}
      programTitle={params?.title ?? ''}
      materials={materials}
      isLoading={isLoading}
      triggerButton={triggerButton}
      context={context}
    />
  );
};

export default SearchMaterialsModal;
