import classnames from 'classnames';
import {
  LargeCarouselBgColorClasses,
  LargeCarouselColor,
  LargeCarouselItemProps,
  LargeCarouselSquareBgColorClasses
} from '@/components/blocks/LargeCarousel/utils/constants';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import RichText from '@/components/cms/RichText';
import Button from '@/components/ui/Buttons/Button';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { getThumbnailImageUrl } from '@/components/ui/SearchResults/Snippets/helpers';
import {
  useGetImageUrlQuery,
  useGetMediaStreamsQuery,
  useGetProductByCodeQuery
} from '@/redux/api/client/product';
import { useEffect, useMemo, useState } from 'react';
import { LargeCarouselGetCardColor } from '@/components/blocks/LargeCarousel/utils/helpers';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import { ISearchResultUserInfo, MediaStream } from '@/@types/client-api';
import Thumbnail from '@/components/ui/Thumbnail';
import { getProductImageUrl } from '@/components/ui/PDP/helpers';
import Tag from '@/components/ui/Tag';
import DateTag, { DateType } from '@/components/ui/DateTag';

interface LargeCarouselSlideFullProps {
  item: LargeCarouselItemProps;
  productCTALabel?: string;
  loadEagerly?: boolean;
}

interface mediaStreamPk extends MediaStream {
  item_pk?: string | null;
}

const LargeCarouselSlideFull: React.FC<LargeCarouselSlideFullProps> = ({
  item: itemData,
  productCTALabel,
  loadEagerly: isActive
}) => {
  const lg = useMediaQuery(ScreenSizeQueries.lg);
  const [isRegisteredProduct, setIsRegisteredProduct] = useState<boolean>(false);
  const [badgeText, setBadgeText] = useState<string>();
  const [userList, setUserList] = useState<ISearchResultUserInfo[]>([]);
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const [isLive, setIsLive] = useState<boolean>(false);
  const [, setFallbackImage] = useState<string>();
  const [datesFormatted, setDatesFormatted] = useState<DateType>();

  const item = itemData as LargeCarouselItemProps;
  const heading = item?.heading || item?.displayName || undefined;
  const copy = item?.copy || item?.sellingPoints || undefined;
  let link = item?.link || undefined;
  const caption = item?.caption || undefined;
  const code = item?.code || undefined;
  const imageUrl = item?.slideImage?.url || getThumbnailImageUrl(item?.code);
  const mobileImageUrl = item?.mobileImage?.url;
  let parentProductCode: string = '';

  const [video, setVideo] = useState<string>('');
  const [variationPk, setVariationPk] = useState<string>(code || '');

  if ('parentProductCode' in item) {
    parentProductCode = item?.parentProductCode as string;
  }

  if (!link && item?.url) {
    link = {
      href: item.url,
      text: productCTALabel
    };
  }

  const { data } = useGetProductByCodeQuery(parentProductCode, { skip: code === undefined });

  const { data: mediaDataResponse } = useGetMediaStreamsQuery(variationPk, {
    skip: !variationPk
  });
  const mediaData: mediaStreamPk[] | undefined = mediaDataResponse;

  const parseDatesFormatted = (dateString: string | undefined | null): DateType | undefined => {
    if (!dateString) return undefined;

    const regex = /^(\w+)(?: (\d+(?: – \d+)?)(?:,))? (\d{4})$/;
    const match = dateString.match(regex);

    if (!match) return undefined;

    const [, month, day] = match;
    const dayPart = day ? ` ${day}` : '';

    return {
      month,
      day: `${dayPart}`
    };
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setVariationPk(data[0].variationPk || '');
      setUserList(data[0].userList || []);
      setIsLive(data[0].isLive || data[0].isOnAir || false);
      setFallbackImage(getProductImageUrl(data[0].variationPk || ''));
      setDatesFormatted(parseDatesFormatted(data[0].datesFormatted));
    }

    if (mediaData && mediaData.length > 0 && mediaData[0].item_pk === variationPk) {
      setVideo(mediaData[0].playbackUrl || '');
    }

    if ('isRegistered' in itemData && itemData.isRegistered) {
      setIsRegisteredProduct(true);
    }

    if (userList && userList.length > 0 && data) {
      const isRegisteredUserList = isRegisteredProduct
        ? isRegisteredProduct
        : !!data[0].userList?.find(ul => ul.isPurchased);

      setIsRegisteredProduct(isRegisteredUserList);

      if (isRegisteredUserList) {
        const registeredLiveOnline =
          data[0].userList?.find(ul => ul.isPurchased)?.registrationDetails?.format ===
          'LiveWebcast';

        setBadgeText(`Registered, ${registeredLiveOnline ? 'Online' : 'In-Person'}`);
      }
    }
  }, [itemData, data, isRegisteredProduct, mediaData, variationPk, userList]);

  const badgeClasses = classnames('text-2 text-white');

  const imageSrc = useMemo(() => {
    if (lg) {
      return imageUrl || mobileImageUrl;
    }
    return mobileImageUrl || imageUrl;
  }, [lg, imageUrl, mobileImageUrl]);

  const [color, setColor] = useState(item?.itemVariant || LargeCarouselColor.Charcoal);

  const { data: colorVariant } = useGetImageUrlQuery(code || '', {
    skip: !code
  });

  useEffect(() => {
    if (isLive) {
      setColor(LargeCarouselColor.Charcoal);
    } else if (colorVariant) {
      const newColor = LargeCarouselGetCardColor(colorVariant);

      if (newColor && Object.values(LargeCarouselColor).includes(newColor as LargeCarouselColor)) {
        setColor(newColor as LargeCarouselColor);
      }
    }
  }, [colorVariant, isLive]);

  useEffect(() => {
    setHasVideo(
      !!(video && mediaData?.length && mediaData?.length > 0 && mediaData[0].playbackUrl)
    );
  }, [mediaData, video]);

  return (
    <div
      className={classnames(
        'relative flex flex-col gap-8 overflow-hidden rounded-lg',
        'sm:gap-3',
        'lg:flex-row',
        LargeCarouselBgColorClasses[color]
      )}
    >
      {imageSrc && !hasVideo && !isLive ? (
        <img
          loading={isActive ? 'eager' : 'lazy'}
          src={imageSrc}
          alt={heading}
          className={classnames('absolute inset-0 size-full object-cover', {
            'object-right': lg,
            'object-bottom': !lg
          })}
          onError={e => (e.currentTarget.style.display = 'none')}
        />
      ) : null}

      <div className={classnames('flex w-full flex-col gap-6 px-6 pt-6', 'lg:p-10')}>
        <div
          className={classnames(
            'z-1 flex flex-col gap-4',
            'sm:max-w-[394px]',
            'lg:max-w-[412px] lg:p-[10px]'
          )}
        >
          {isRegisteredProduct && (
            <div className="flex h-5 items-center gap-2">
              <Icon size="medium" name="checkmark" className={badgeClasses} />
              <Text className={badgeClasses}>{badgeText}</Text>
            </div>
          )}
          {heading && (
            <h2 className={classnames('heading-5 text-white', 'lg:max-w-[368px]')}>{heading}</h2>
          )}
          {copy && <RichText className="text-1 text-white" content={copy} />}
        </div>

        {link && (
          <div className="mt-auto">
            <Button
              href={link?.href}
              label={link?.text}
              target={link?.target}
              color="outline-white"
              size="large"
            />
          </div>
        )}
      </div>

      <div
        className={classnames('z-1 w-full py-[34px]', 'sm:py-[75px]', 'lg:flex-[0_0_50%] lg:py-0')}
      >
        <AspectRatio.Root ratio={3 / 2} className="flex items-center justify-center">
          {caption && (
            <div
              className={classnames(
                'shadow-[50.88px_54.72px_21.12px_0px_rgba(0,0,0,0.00),32.64px_35.52px_19.2px_0px_rgba(0,0,0,0.04),18.24px_20.16px_16.32px_0px_rgba(0,0,0,0.13),8.64px_8.64px_12.48px_0px_rgba(0,0,0,0.22),1.92px_1.92px_6.72px_0px_rgba(0,0,0,0.25)]',
                'size-24 rounded-lg p-[10px]',
                'sm:size-[150px] sm:p-[15px]',
                LargeCarouselSquareBgColorClasses[color]
              )}
            >
              <RichText className={classnames('heading-6-medium text-white')} content={caption} />
            </div>
          )}
          {hasVideo ? (
            <div className="relative w-full max-w-full lg:w-auto">
              <div
                className={classnames(
                  'w-screen max-w-full rounded-lg bg-black',
                  'lg:max-w-[520px]'
                )}
              >
                <Thumbnail
                  ratio={520 / 345}
                  video={{
                    autoPlay: true,
                    playable: true,
                    url: video,
                    muted: true
                  }}
                  image={
                    imageUrl || mobileImageUrl
                      ? {
                          url: imageUrl || mobileImageUrl || '',
                          alt: heading || ''
                        }
                      : undefined
                  }
                  imageSizes={{
                    xs: '320px',
                    sm: '520px'
                  }}
                  tag={{
                    children: 'Live',
                    variant: 'emphasis'
                  }}
                  icon={{
                    name: 'play',
                    label: 'Join now'
                  }}
                  onClick={() => {
                    if (link?.href) {
                      window.location.href = link.href;
                    }
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="relative flex items-center justify-center">
              {isLive ? (
                <div tabIndex={-1} className="absolute bottom-4 left-4">
                  <Tag children="Live" variant="emphasis" size="medium" />
                </div>
              ) : (
                <div className="absolute flex size-36 flex-col items-center justify-center text-white">
                  {datesFormatted && <DateTag dates={datesFormatted} isLargeTag={true} />}
                </div>
              )}
            </div>
          )}
        </AspectRatio.Root>
      </div>
    </div>
  );
};

export default LargeCarouselSlideFull;
