import type { LiveProgramGroupcastVariation as LiveProgramGroupcastVariationProps } from '@/@types/content';
import withNav from '@/components/hocs/withNav';
import LiveProgramPDP from '@/components/ui/PDP/LiveProgramPDP';
import {
  enrichLiveVariantType,
  LiveVariationFormat,
  useLiveProgramCatalogRelations
} from '@/hooks/PDP/useProgramCatalogRelations';
import { AnalyticsContext } from '@/analytics/constants';

const LiveProgramGroupcastVariationComponent = (variation: LiveProgramGroupcastVariationProps) => {
  const { relations: catalogRelations, isLoading } = useLiveProgramCatalogRelations(variation);
  const initialVariant: LiveVariationFormat =
    catalogRelations.variants.find(v => v.content.code === variation.code) ??
    enrichLiveVariantType(variation, 'LiveProgramGroupcastVariation')!;

  return (
    <LiveProgramPDP
      variant={initialVariant}
      catalogRelations={catalogRelations}
      loadingCatalogRelations={isLoading}
      context={AnalyticsContext.PDP}
    />
  );
};

const LiveProgramGroupcastVariation = withNav(LiveProgramGroupcastVariationComponent);
export default LiveProgramGroupcastVariation;
