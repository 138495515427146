import { UserPracticeAreaResponse } from '@/@types/client-api';
import { UserFacultyResponse } from '@/@types/client-api';
import { UserIndustryResponse } from '@/@types/client-api';
import { LinkGridBlock as LinkGridBlockProps } from '@/@types/content';

export const getProfileLinkInfo = (
  data?: UserPracticeAreaResponse | UserIndustryResponse | UserFacultyResponse
): LinkGridBlockProps | null => {
  if (!data) return null;
  const isFaculty = data?.items?.find(el => Object.prototype.hasOwnProperty.call(el, 'lastName'));

  const linkInfo = data?.items?.map(({ url, name, thumbnailUrl }) => {
    if (thumbnailUrl)
      return {
        contentType: isFaculty !== undefined ? ['FacultyLinkBlock'] : ['LinkBlock'],
        link: { href: url ?? '#', text: name, title: name },
        image: {
          url: thumbnailUrl,
          alt: name
        }
      };
    return {
      contentType: isFaculty !== undefined ? ['FacultyLinkBlock'] : ['LinkBlock'],
      link: { href: url ?? '#', text: name, title: name }
    };
  });
  return {
    linkBlocks: linkInfo
  };
};
