import { useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import { useAuth } from 'react-oidc-context';
import { LinkItem } from '@/@types/client-api';

enum LegacySiteRedirectRule {
  NoRedirect = 1,
  RedirectBasedOnCookie = 2,
  RedirectIfNotRejected = 3,
  RedirectAlways = 4
}

const useSiteVersionPreference = (
  legacySiteRedirectRule?: LegacySiteRedirectRule,
  legacySiteRedirectWhitelistUrls?: LinkItem[]
) => {
  const auth = useAuth();
  const siteVersionPreferenceCookieName =
    `site-version-preference_${window.env.APP_ENVIRONMENT_NAME}`.toLowerCase();
  const newSiteOriginCookieName =
    `new-site-origin_${window.env.APP_ENVIRONMENT_NAME}`.toLowerCase();
  const rejectNewSiteCookieValue = 'reject-new-site';
  const rejectOldSiteCookieValue = 'reject-old-site';
  const hasRejectedNewSite = useMemo(
    () => Cookies.get(siteVersionPreferenceCookieName) === rejectNewSiteCookieValue,
    [siteVersionPreferenceCookieName, rejectNewSiteCookieValue]
  );

  const isWhiteListUrl = useMemo(() => {
    if (!legacySiteRedirectWhitelistUrls?.length) return false;

    const current = new URL(window.location.href);

    return legacySiteRedirectWhitelistUrls.some(a => {
      const url = new URL(a.href ?? '');
      const currentKeys = Array.from(current.searchParams?.keys());
      const queryKeys = Array.from(url.searchParams?.keys());

      const matchQuery =
        queryKeys.length == currentKeys.length &&
        queryKeys.every(a => currentKeys.some(b => b.toLowerCase() === a.toLowerCase()));
      const matchPathname = current.pathname.startsWith(url.pathname);
      return matchPathname && matchQuery;
    });
  }, [legacySiteRedirectWhitelistUrls]);

  useEffect(
    () => {
      if (auth.isAuthenticated && window.env.BLOCKED_ORGANIZATIONS) {
        const blockedOrganizations = window.env.BLOCKED_ORGANIZATIONS.split(',');
        const userOrganization = auth.user?.profile['OrganizationSK'] as string;
        if (
          blockedOrganizations.length > 0 &&
          blockedOrganizations.some(org => org.trim() === userOrganization.trim())
        ) {
          setRejectNewSiteCookie();
          window.location.href = window.env.APP_LEGACY_SITE_URL;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth]
  );

  useEffect(() => {
    if (isWhiteListUrl) return;

    const siteVersionPreferenceCookieValue = Cookies.get(siteVersionPreferenceCookieName);

    switch (legacySiteRedirectRule) {
      case LegacySiteRedirectRule.RedirectBasedOnCookie:
        if (hasRejectedNewSite) {
          window.location.href = window.env.APP_LEGACY_SITE_URL;
        }
        break;
      case LegacySiteRedirectRule.RedirectIfNotRejected:
        if (siteVersionPreferenceCookieValue !== rejectOldSiteCookieValue) {
          window.location.href = window.env.APP_LEGACY_SITE_URL;
        }
        break;
      case LegacySiteRedirectRule.RedirectAlways:
        window.location.href = window.env.APP_LEGACY_SITE_URL;
        break;
      case LegacySiteRedirectRule.NoRedirect:
      default:
        break;
    }
  }, [legacySiteRedirectRule, siteVersionPreferenceCookieName, hasRejectedNewSite, isWhiteListUrl]);

  useEffect(() => {
    if (!hasRejectedNewSite) {
      Cookies.set(newSiteOriginCookieName, 'true', {
        path: '/',
        domain: 'pli.edu'
      });
    }
  });

  const setRejectNewSiteCookie = () => {
    Cookies.set(siteVersionPreferenceCookieName, rejectNewSiteCookieValue, {
      expires: new Date('9999-12-31T23:59:59Z'),
      path: '/',
      domain: 'pli.edu'
    });

    Cookies.remove(newSiteOriginCookieName, { path: '/', domain: 'pli.edu' });
  };

  return { setRejectNewSiteCookie };
};

export default useSiteVersionPreference;
