import classnames from 'classnames';
import Icon from '@/components/ui/Icon';

export interface ModalEyebrowProps {
  text: string | React.ReactNode;
  icon?: string;
  size?: 'small' | 'medium' | 'large';
}

const ModalEyebrow = ({ text, icon, size = 'small' }: ModalEyebrowProps): JSX.Element => {
  return (
    <div className="inline-flex items-center gap-2"       data-component={"ModalEyebrow"}>
      {icon && <Icon size="small" name={icon} />}
      <span
        className={classnames(
          { 'heading-4 text-gray': size === 'large' },
          { 'text-2 text-gray-dark': size === 'small' }
        )}
      >
        {text}
      </span>
    </div>
  );
};

export default ModalEyebrow;
