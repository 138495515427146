import { StandardBlackHeaderBlock as StandardBlackHeaderBlockProps } from '@/@types/content';
import { ReactNode } from 'react';
import classnames from 'classnames';
import Block from '@/components/cms/Block';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';

export interface StandardBlackHeaderProps extends StandardBlackHeaderBlockProps {
  rightContent?: ReactNode;
  pageName?: string;
  membershipSinceText?: string;
  membershipThroughText?: string;
  isPM?: boolean;
  isIM?: boolean;
  membershipLabelUrl?: URL | string | null;
}

const StandardBlackHeaderBlock: React.FC<StandardBlackHeaderProps> = ({
  contentLink,
  rightContent,
  pageTitle,
  pageName,
  membershipLabel,
  membershipLabelUrl,
  membershipSinceText,
  membershipThroughText,
  isPM,
  isIM
}) => {
  const editMode = isEditOrPreviewMode();
  const componentTitle = pageTitle || pageName;

  return (
    <Block
      contentLinkID={contentLink?.id}
      className={classnames('mt-[-161px] bg-black', 'lg:mt-[-98px]')}
    >
      <div
        className={classnames(
          'container flex flex-col gap-8 pb-6 pt-56',
          'sm:flex-row sm:items-end sm:justify-between',
          'lg:pb-10 lg:pt-44'
        )}
        data-component={"StandardBlackHeaderBlock"}
      >
        {membershipLabel || membershipSinceText || membershipThroughText || editMode ? (
          <article className="flex flex-col gap-y-2 md:gap-y-4">
            {membershipLabel && (
              <section>
                <Text propertyName="MembershipLabel" element="h2" className="heading-4 text-gray">
                  {membershipLabelUrl ? (
                    <a href={membershipLabelUrl.toString()} title={membershipLabel} target="_blank">
                      {membershipLabel}
                    </a>
                  ) : (
                    membershipLabel
                  )}
                </Text>
              </section>
            )}
            <h1 className="heading-4 text-white">{componentTitle}</h1>
            {(membershipSinceText || membershipThroughText) && (
              <section className="flex items-center gap-x-2">
                {isIM || isPM ? (
                  <Icon
                    className="text-white md:size-5"
                    size="small"
                    name="approved-nocolor"
                    aria-label="Credit Status Success"
                  />
                ) : null}
                <Text propertyName="MembershipSinceText" element="p" className="text-1 text-white">
                  {isIM ? membershipSinceText : isPM && membershipThroughText}
                </Text>
              </section>
            )}
          </article>
        ) : (
          <h1 className="heading-4 text-white">{componentTitle}</h1>
        )}
        {rightContent}
      </div>
    </Block>
  );
};

export default StandardBlackHeaderBlock;
