import { SearchableType } from '@/components/ui/SearchResults/searchableTypeToSnippetVariant';
import { ReactNode } from 'react';

export enum PMKeys {
  PM = 'PM',
  NonPM = 'NonPM'
}

export enum PurchasedKeys {
  Purchased = 'purchased',
  NotPurchased = 'notPurchased'
}

export const userActionsMapper: {
  [key: string]: {
    [key in SearchableType]?: {
      [key in PurchasedKeys]: { [key in PMKeys]: string | null };
    };
  };
} = {
  postCartItem: {
    ondemandprogram: {
      [PurchasedKeys.NotPurchased]: {
        [PMKeys.NonPM]: 'postCartItem',
        [PMKeys.PM]: 'postCartItem'
      },
      [PurchasedKeys.Purchased]: {
        [PMKeys.PM]: null,
        [PMKeys.NonPM]: null
      }
    },
    ondemandsegment: {
      [PurchasedKeys.NotPurchased]: {
        [PMKeys.NonPM]: 'postCartItem',
        [PMKeys.PM]: 'postCartItem'
      },
      [PurchasedKeys.Purchased]: {
        [PMKeys.PM]: null,
        [PMKeys.NonPM]: null
      }
    },
    liveprogram: {
      [PurchasedKeys.NotPurchased]: {
        [PMKeys.PM]: 'postCartItem',
        [PMKeys.NonPM]: 'postCartItem'
      },
      [PurchasedKeys.Purchased]: {
        [PMKeys.PM]: 'placeRegistration',
        [PMKeys.NonPM]:
          null /* if a retail user purchased a live program, they had already registered for it at the time of purchase */
      }
    },
    book: {
      [PurchasedKeys.NotPurchased]: {
        [PMKeys.PM]: 'postCartItem',
        [PMKeys.NonPM]: 'postCartItem'
      },
      [PurchasedKeys.Purchased]: {
        [PMKeys.PM]: null,
        [PMKeys.NonPM]: null
      }
    }
  }
};

export const endpointArgs: {
  [key: string]: { args: (body: unknown) => unknown | undefined; url: string };
} = {
  placeRegistration: {
    args: (body: unknown) => {
      if (body && typeof body === 'object') {
        if ('code' in body) {
          return { itemPk: body.code };
        }
      }
    },
    url: 'registration'
  }
};

export const delayedActionMessageBuilder: {
  [key in string]: (param: { title: string; url?: string }) => string | ReactNode;
} = {
  placeRegistration: ({ title, url }) => (
    <span>
      You have registered for{' '}
      <a className="underline" href={url}>
        this program
      </a>{' '}
      "{title?.slice(0, 30)}..."
    </span>
  ),
  postCartItem: ({ title, url }): ReactNode => (
    <span>
      "{title?.slice(0, 30)}" ...added to cart.{' '}
      <a className="underline" href={url}>
        View In Cart
      </a>
    </span>
  ),
  addToLibrary: ({ title }) => (
    <span>
      This item "{title?.slice(0, 30)}..." has been saved to{' '}
      <a className="underline" href="/account/library">
        your library.
      </a>
    </span>
  ),
  noAction: ({ title, url }) => (
    <span>
      You have access to{' '}
      <a className="underline" href={url}>
        this product
      </a>{' '}
      "{title?.slice(0, 50)}..."
    </span>
  )
};

export const delayedActionsErrorMessages: { [key in string]: string } = {
  placeRegistration: `We're sorry, something went wrong with your registration. Please refresh and try again.`,
  postCartItem: `We're sorry, something went wrong while adding this program to your cart. Please refresh and try again.`,
  addToLibrary: 'Error while saving item to library'
};
