import Divider from '../Divider';
import Link from '../Link';
import Button from '../Buttons/Button';
import { FilterButtonGroupProps } from '../FindAProgram/types';

type SearchFooterProps = {
  filterGroups?: FilterButtonGroupProps[];
  onClearAll?: () => void;
  onSearch?: () => void;
};

const SearchFooter: React.FC<SearchFooterProps> = ({ filterGroups, onClearAll, onSearch }) => {
  return (
    <>
      <Divider color="light" />
      <div className="flex h-full items-center justify-between">
        {filterGroups?.some(item => item.filters.some(item => item.checked)) && (
          <Link variant="text-link-2" text="Clear all" onClick={onClearAll} as="button" />
        )}
        <Button color="red" label="Search" size="large" className="ml-auto" onClick={onSearch} />
      </div>
    </>
  );
};

export default SearchFooter;
