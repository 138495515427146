import { BannerMessageBlock as BannerMessageBlockProps } from '@/@types/content';
import { memo, useMemo } from 'react';
import Text from '@/components/cms/Text';
import {
  getNewTagText,
  translateToTailwinds
} from '@/components/ui/CreditTracker/util/TrackerHelpers';
import { extractTextFromHTML } from '@/utils/helpers';

const BannerMessageBlock: React.FC<BannerMessageBlockProps> = memo(
  ({ body, headingText, expireNewStatus }) => {
    const newLabel = useMemo(() => {
      const label = getNewTagText(expireNewStatus);
      return (
        !!label && (
          <span className="text-2 mr-2 flex w-fit flex-row flex-nowrap items-center rounded bg-black px-2 py-1 text-white">
            {label}
          </span>
        )
      );
    }, [expireNewStatus]);

    return (
      <div>
        <div className="flex flex-row items-center justify-start">
          {newLabel}
          {!!headingText && (
            <Text element="h2" className="font-medium">
              {extractTextFromHTML(headingText)}
            </Text>
          )}
        </div>
        <Text element="span">
          <span
            dangerouslySetInnerHTML={{
              __html: translateToTailwinds(body?.toString() ?? '')
            }}
          ></span>
        </Text>
      </div>
    );
  }
);

BannerMessageBlock.displayName = 'BannerMessageBlock';
export default BannerMessageBlock;
