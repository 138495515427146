import {
  AnnouncementMessage,
  ICreditClassData,
  UserTrackerData,
  UserTrackerDataRequest
} from '@/@types/client-api';
import { DismissableBannerProps } from '@/components/blocks/DismissableBannerBlock';
import { PayloadAction, SerializedError, createSlice } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ClientAPI } from '@/redux/api';

export interface CreditTrackerState {
  isLoading: boolean;
  request: UserTrackerDataRequest[] | null;
  error: FetchBaseQueryError | SerializedError | null;
  selectedRegion?: UserTrackerData;
  trackers: Record<number, Array<UserTrackerData>>;
  providers: Array<string>;
  announcements: Record<string, Array<AnnouncementMessage>>;
  creditTypes: Array<ICreditClassData>;
  globalBanners?: Record<string, DismissableBannerProps[] | null> | null;
}

const initialState: CreditTrackerState = {
  isLoading: false,
  error: null,
  request: null,
  selectedRegion: undefined,
  trackers: {},
  providers: [],
  creditTypes: [],
  announcements: {}
};

export const creditTrackerSlice = createSlice({
  name: 'creditTracker',
  initialState,
  reducers: {
    creditTrackerLoading: (state, payload: PayloadAction<boolean>) => {
      state.isLoading = payload.payload;
    },
    creditTrackerDataSuccess: (
      state,
      action: PayloadAction<{
        request: UserTrackerDataRequest[];
        response: Array<Record<string, Array<UserTrackerData>>> | null;
      }>
    ) => {
      state.isLoading = false;
      for (const trackers of action.payload.response ?? []) {
        for (const key in trackers) {
          const parsedKey = parseInt(key);
          state.trackers[parsedKey] = trackers[parsedKey];
        }
      }
      state.request = action.payload.request;
      state.error = null;
    },
    creditTrackerSetSelectedRegion: (state, action: PayloadAction<UserTrackerData | undefined>) => {
      const val = { ...action.payload } as UserTrackerData;
      state.selectedRegion = val;
      state.isLoading = false;
    },
    creditTrackerGetCreditTypesSuccess: (state, action: PayloadAction<Array<ICreditClassData>>) => {
      state.creditTypes = action.payload;
      state.isLoading = false;
    },
    creditTrackerGetAnnouncementsSuccess: (
      state,
      action: PayloadAction<Array<AnnouncementMessage>>
    ) => {
      const region = state.selectedRegion?.creditRegionShortDescription;
      if (!region || !action.payload?.length) return;

      state.announcements[region] = action.payload;
      state.isLoading = false;
    },
    creditTrackerGetManyAnnouncementsSuccess: (
      state,
      action: PayloadAction<Record<string, Array<AnnouncementMessage>>>
    ) => {
      for (const key in action.payload) {
        if (key.trim()?.length) {
          state.announcements[key] = action.payload[key];
        }
      }
      state.isLoading = false;
    },
    creditTrackerFailure: (state, action: PayloadAction<FetchBaseQueryError | SerializedError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    creditTrackerProvidersSuccess: (state, action: PayloadAction<Array<string>>) => {
      state.providers = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    creditTrackerSetGlobalBanners: (
      state,
      action: PayloadAction<Record<string, DismissableBannerProps[]>>
    ) => {
      state.globalBanners ??= {};

      for (const key in action.payload) {
        state.globalBanners[key] = action.payload[key];
      }
    },
    creditTrackerRemoveGlobalBanners: (state, action: PayloadAction<string>) => {
      delete state.globalBanners?.[action.payload];
    },
    invalidateAllTrackerData: () => {
      ClientAPI.util.invalidateTags(['CreditTrackerData']);
      ClientAPI.util.invalidateTags([{ type: 'CreditTrackerData', id: 'Certificates' }]);
    },
    invalidateTrackerUserData: () => {
      ClientAPI.util.invalidateTags(['CreditTrackerData']);
    }
  }
});

export const {
  creditTrackerLoading,
  creditTrackerDataSuccess,
  creditTrackerFailure,
  creditTrackerGetCreditTypesSuccess,
  creditTrackerSetSelectedRegion,
  creditTrackerGetAnnouncementsSuccess,
  creditTrackerGetManyAnnouncementsSuccess,
  creditTrackerProvidersSuccess,
  creditTrackerSetGlobalBanners,
  creditTrackerRemoveGlobalBanners,
  invalidateAllTrackerData,
  invalidateTrackerUserData
} = creditTrackerSlice.actions;

export default creditTrackerSlice.reducer;
