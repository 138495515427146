import classnames from 'classnames';
import Shimmer from '../Shimmer';

interface IFilterShimmer {
  type: 'select' | 'radio' | 'check' | 'dateRange' | 'yearSelect';
  hasTitle?: boolean;
  className?: string;
  amount?: number;
}

const FilterShimmer = ({ type, hasTitle, className, amount = 34 }: IFilterShimmer) => {
  const shimmerClass = type === 'select' ? 'h-16' : 'h-6';

  return (
    <div className={classnames('flex flex-col gap-4', className)}       data-component={"FilterShimmer"}>
      {hasTitle && <Shimmer className="h-5 w-2/5" />}
      <div className="flex flex-wrap gap-x-6 gap-y-4">
        {Array.from({ length: amount }).map((_, index) => (
          <Shimmer
            key={index}
            className={classnames('max-sm:w-full sm:w-[calc(50%-16px)]', shimmerClass)}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterShimmer;
