import { formatDateFilter, isStartDateFilter } from '../../Filter/DateFilterUtils';
import { TFilter, TFilterItem } from '../../Filter/FilterRow';
import { DateSelectorItem, FilterProps } from '../../Filter/types';
import { FilterTypes } from '../../SearchResults/constants';
import { CERTIFICATES_FILTERS, CUSTOM_YEAR_VALUE } from './constants';
import {
  isDateRangeFilter,
  isJurisdictionsFilter,
  isSourceFilter,
  isYearFilter,
  sortByLabel
} from './utils';
import { OnFilterChange } from './types';
import {
  onDateRangeFilterChange,
  onJurisdictionFilterChange,
  onSourceFilterChange,
  onYearFilterChange
} from './actions';
import { FilterContext } from '@/analytics/constants';

export const getQuickFilters = (filters: FilterProps[]): TFilterItem[] => {
  const result: TFilterItem[] = [];
  for (const filter of filters) {
    if (isYearFilter(filter)) {
      addYearQuickFilter(result, filter);
    } else if (isJurisdictionsFilter(filter)) {
      addJurisdictionQuickFilter(result, filter);
    } else {
      for (const item of filter.items) {
        if (item.checked) {
          addQuickFilter(result, filter, item);
        }
      }
    }
  }
  return result.sort(sortQuickFilters);
};

const addYearQuickFilter = (result: TFilterItem[], filter: FilterProps) => {
  if (filter.type === FilterTypes.YEAR_SELECT && filter.value) {
    if (filter.value === CUSTOM_YEAR_VALUE.toString()) {
      for (const item of filter.dateRange?.items ?? []) {
        if (item.value) {
          result.push({
            data: {
              name: item.label,
              label: formatDateFilter(item.label, item.value),
              value: item.value,
              checked: true,
              groupTypeId: CERTIFICATES_FILTERS.DATE_RANGE
            },
            canDeselect: true
          });
        }
      }
      return;
    }
    result.push({
      data: {
        label: filter.value,
        value: filter.value,
        checked: true,
        groupTypeId: filter.groupTypeId
      },
      canDeselect: true
    });
  }
};

const addJurisdictionQuickFilter = (result: TFilterItem[], filter: FilterProps) => {
  const jurisdictions = filter.items.filter(item => !item.disabled);
  if (jurisdictions.length > 1 || (jurisdictions.length === 1 && jurisdictions[0].checked)) {
    for (const item of jurisdictions) {
      addQuickFilter(result, filter, item);
    }
  }
};

const addQuickFilter = (result: TFilterItem[], filter: FilterProps, item: DateSelectorItem) => {
  result.push({
    data: {
      label: item.label,
      value: item.value,
      checked: item.checked,
      groupTypeId: filter.groupTypeId
    },
    canDeselect: true
  });
};

const sortQuickFilters = (filterItemA: TFilterItem, filterItemB: TFilterItem): number => {
  const a = filterItemA.data;
  const b = filterItemB.data;

  if (a?.checked && !b?.checked) return -1;
  if (!a?.checked && b?.checked) return 1;

  const isJurisdictionFilterA = isJurisdictionsFilter(a);
  const isJurisdictionFilterB = isJurisdictionsFilter(b);
  if (isJurisdictionFilterA && !isJurisdictionFilterB) return -1;
  if (!isJurisdictionFilterA && isJurisdictionFilterB) return 1;
  if (isJurisdictionFilterA && isJurisdictionFilterB) return sortByLabel(a, b);

  const isYearFilterA = isYearFilter(a);
  const isYearFilterB = isYearFilter(b);
  if (isYearFilterA && !isYearFilterB) return -1;
  if (!isYearFilterA && isYearFilterB) return 1;

  const isDateFilterA = isDateRangeFilter(a);
  const isDateFilterB = isDateRangeFilter(b);
  if (isDateFilterA && !isDateFilterB) return -1;
  if (!isDateFilterA && isDateFilterB) return 1;
  if (isDateFilterA && isDateFilterB) return isStartDateFilter(a?.name) ? -1 : 1;

  return sortByLabel(a, b);
};

export const onQuickFilterToggle = (onFilterChange: OnFilterChange, filter: TFilter) => {
  if (isJurisdictionsFilter(filter)) {
    onJurisdictionFilterChange(
      FilterContext.QuickFilter,
      onFilterChange,
      filter.value,
      !filter.checked
    );
  } else if (isSourceFilter(filter)) {
    onSourceFilterChange(FilterContext.QuickFilter, onFilterChange, filter.value);
  } else if (isYearFilter(filter)) {
    onYearFilterChange(FilterContext.QuickFilter, onFilterChange, filter.value);
  } else if (isDateRangeFilter(filter)) {
    onDateRangeFilterChange(FilterContext.QuickFilter, onFilterChange, filter.name ?? '');
  }
};
