import { useBuildNewSearchLink } from './utils/searchParams';
import Banner from '../Banner';
import { addFacetFiltersToFacetGroups } from './utils/filters';
import AddNewLicenseButton from '../LicensesPage/AddNewLicenseButton';
import { ReactNode } from 'react';
import Divider from '../Divider';
import { useGetLicenseData, useOnSaveLicense } from '../LicensesPage/hooks';
import { useAuth } from 'react-oidc-context';
import { BannerBlock } from '@/@types/content';
import { AnalyticsContext } from '@/analytics/constants';

type Action = 'ADD_LICENSE';
const ACTIONS: Record<string, Action> = {
  '1': 'ADD_LICENSE'
};

type SearchResultsMarketingModuleAddLicenseButtonProps = {
  button: JSX.Element;
};

const SearchResultsMarketingModuleAddLicenseButton: React.FC<
  SearchResultsMarketingModuleAddLicenseButtonProps
> = ({ button }) => {
  const { availableCreditJurisdictions } = useGetLicenseData();
  const onSave = useOnSaveLicense({ context: AnalyticsContext.MarketingModule });

  return (
    <AddNewLicenseButton
      creditJurisdictions={availableCreditJurisdictions}
      onSave={onSave}
      triggerButton={button}
    />
  );
};

type SearchResultsMarketingModuleProps = BannerBlock & {
  bannerBehavior: 'Link' | 'Search' | 'Action';
  searchFilters?: {
    facetGroupType: string;
    value: string;
    title: string;
  }[];
};

const SearchResultsMarketingModule: React.FC<SearchResultsMarketingModuleProps> = ({
  action,
  bannerBehavior,
  copy,
  heading,
  icon,
  link,
  linkLabel,
  searchFilters
}) => {
  const auth = useAuth();

  const commonProps = {
    bannerType: 'primary' as const,
    contents: [{ headingText: heading, body: copy, bannerType: 'primary' as const }],
    primaryButtonIcon: icon,
    primaryButtonLabel: linkLabel
  };
  const buildNewSearchLink = useBuildNewSearchLink();
  let module: ReactNode = null;

  switch (bannerBehavior) {
    case 'Link': {
      module = (
        <Banner {...commonProps} primaryButtonHref={link.href} primaryButtonTarget={link.target} />
      );
      break;
    }
    case 'Search': {
      const { newSearchLink } = buildNewSearchLink({
        facetResults: {
          facetGroups: addFacetFiltersToFacetGroups({
            facetFilters: searchFilters
          })
        }
      });

      module = <Banner {...commonProps} primaryButtonHref={newSearchLink} />;
      break;
    }
    case 'Action': {
      const mappedAction = action && ACTIONS[action];

      switch (mappedAction) {
        case 'ADD_LICENSE':
          module = auth.isAuthenticated ? (
            <Banner
              {...commonProps}
              primaryButtonHref={undefined}
              primaryButtonWrapper={button => (
                <SearchResultsMarketingModuleAddLicenseButton button={button} />
              )}
            />
          ) : null;
          break;
        default:
          console.error(`Unknown action: ${action}`);
      }
      break;
    }
    default:
      if (bannerBehavior) {
        console.error(`Unknown BannerBehavior: ${bannerBehavior}`);
      }
  }

  if (!module) {
    return null;
  }

  return (
    <>
      <Divider color="light" />
      {module}
    </>
  );
};

export default SearchResultsMarketingModule;
