import {
  LiveVariation,
  OnDemandProgramVariation,
  ProgramCatalogRelations,
  WebSegmentVariation
} from '@/hooks/PDP/useProgramCatalogRelations';
import ExpandableRichText from '../ExpandableRichText';
import classnames from 'classnames';
export interface IPDPOverviewSectionLayoutBlockProps {
  variant: LiveVariation | OnDemandProgramVariation | WebSegmentVariation;
  catalogRelations: ProgramCatalogRelations;
  overviewData: object;
  withinOverview: boolean;
  sectionName: string;
}

export const ProgramOverviewSection = ({
  overviewData,
  sectionName,
  withinOverview
}: IPDPOverviewSectionLayoutBlockProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const overviewDict = overviewData as any;

  if (!overviewDict[sectionName].header || !overviewDict[sectionName].content) return null;
  return (
    <div
      className={classnames('module-spacing-pdp flex flex-col gap-8 max-sm:gap-6', {
        container: !withinOverview
      })}
    >
      <h3 className="heading-6-medium">{overviewDict[sectionName].header}</h3>
      <ExpandableRichText
        className="text-1 text-gray-dark"
        content={overviewDict[sectionName].content}
      />
    </div>
  );
};

export default ProgramOverviewSection;
