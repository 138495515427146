const toastElements = new WeakMap();
let toastIdCounter = 0;

export const addToastElement = (element: React.ReactNode) => {
  const id = { id: toastIdCounter++ };
  toastElements.set(id, element);
  return id;
};

export const getToastElement = (key: { id: number }) => {
  return toastElements.get(key);
};
