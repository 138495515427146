import Icon from '../Icon';

type MonthNavigationButtonProps = {
  iconName: string;
  disabled: boolean;
  onClick: () => void;
  accessibilityLabel: string;
};

const MonthNavigationButton = ({
  iconName,
  onClick,
  disabled,
  accessibilityLabel
}: MonthNavigationButtonProps): JSX.Element => {
  return (
    <button
      type="button"
      className="flex size-8 items-center justify-center disabled:text-gray-light"
      onClick={onClick}
      disabled={disabled}
      aria-label={accessibilityLabel}
    >
      <Icon name={iconName} size="medium" />
    </button>
  );
};

export default MonthNavigationButton;
