import { FACET_DELIMITER } from '@/components/ui/SearchResults/constants';
import { LibraryFilterValue } from './libraryConstants';
import { LibraryResponse } from '@/@types/client-api';

export const isItemChecked = (
  itemValue?: LibraryFilterValue,
  selectedValue?: LibraryFilterValue
) => {
  if (typeof selectedValue === 'string') {
    return selectedValue.split(FACET_DELIMITER).includes(itemValue as string);
  }

  return selectedValue === itemValue;
};

export const getOnlyActiveTabLabel = (data: LibraryResponse | null): string | undefined => {
  const tabData = Object.entries(data?.summary?.headerGroupSearchableTypeCounters || []);
  const activeTabs = tabData.filter(([_, count]) => count);

  if (activeTabs.length === 2) {
    // length is 2 to account for "All" tab
    return activeTabs.find(([label]) => label !== 'All')?.[0];
  }
};
