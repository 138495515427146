import { memo, useCallback, useMemo, useState } from 'react';
import { Root, Trigger, Content } from '@radix-ui/react-collapsible';
import CircleButton from '@/components/ui/Buttons/CircleButton';
import Text from '@/components/cms/Text';
import { formatCreditTypeId } from '@/components/ui/CreditTracker/util/TrackerHelpers';

export type TrackerAccordionProps = {
  isOpen: boolean;
  heading?: string;
  subHeading?: string;
  sideNote?: string | null;
  contentItems?: (JSX.Element | null | undefined)[];
  handleOpenChange?: (open: boolean) => void;
};

const TrackerAccordion: React.FC<TrackerAccordionProps> = memo(
  ({ isOpen, heading, subHeading, sideNote, contentItems, handleOpenChange }) => {
    const [isSectionOpen, setIsSectionOpen] = useState<boolean>(isOpen);

    const handleToggle = useCallback(
      (open: boolean) => {
        setIsSectionOpen(open);
        handleOpenChange && handleOpenChange(open);
      },
      [handleOpenChange]
    );

    const accordionAriaLabel = useMemo(
      () => (isSectionOpen ? `Collapse ${heading} section.` : `Expand ${heading} section.`),
      [heading, isSectionOpen]
    );
    return (
      <Root open={isSectionOpen} onOpenChange={handleToggle} key={`tracker-accordion-${heading}`}>
        <div
          id={formatCreditTypeId(heading ?? '')}
          className="mb-0 flex basis-auto flex-row flex-nowrap items-start justify-between align-middle sm:mb-10"
        >
          <div className="mb-10 flex w-full flex-row flex-wrap justify-between sm:mb-0 lg:flex-nowrap">
            <div className="w-full flex-[1_1_auto] flex-row flex-wrap items-center md:w-auto">
              {!!heading && (
                <Text propertyName="h5" element="h5" className="heading-5-medium mr-auto w-full">
                  {heading}
                </Text>
              )}
              {!!subHeading && (
                <div className="my-2 inline-block w-auto max-w-full">
                  <Text
                    element="h5"
                    propertyName="SubHeading"
                    className="heading-5 flex w-full text-gray-dark"
                  >
                    {subHeading}
                  </Text>
                </div>
              )}
            </div>
            {!!sideNote && (
              <Text
                element="p"
                propertyName="p"
                className="text-1 mb-auto mt-0 text-gray-dark sm:mt-1"
              >
                {sideNote}
              </Text>
            )}
          </div>
          <Trigger asChild>
            <CircleButton
              color="dark"
              className="ml-4 cursor-pointer"
              iconSize="large"
              fixedSize={true}
              icon={isSectionOpen ? 'chevron-up' : 'chevron-down'}
              aria-label={accordionAriaLabel}
            />
          </Trigger>
        </div>
        {!!contentItems?.length && <Content className="flex flex-col">{contentItems}</Content>}
      </Root>
    );
  }
);

export default TrackerAccordion;
