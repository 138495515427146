import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Input, { InputError, InputProps } from '@/components/ui/Form/Input';
import DatePicker from '@/components/ui/DatePicker';
import Panel from '@/components/ui/Panel';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { formatDate, parseDate, DateFormat } from '@/utils/helpers';
import { getDateInputErrors } from './errors';
import { ValidationOptions, hasOnlyNumbers, isValidValue } from '@/utils/validations';
import YearChangeButton from '../../DatePicker/YearChangeButton';
import { endOfYear, startOfYear, parse, format, isValid } from 'date-fns';
import { RemoveScroll } from 'react-remove-scroll';
import { getScrollableParent } from '../../CreditTracker/util/TrackerHelpers';

const validationOptions: ValidationOptions = {
  acceptLetters: false,
  acceptNumbers: true,
  acceptSpaces: false,
  acceptSpecialChars: false,
  allowedChars: '/'
};

interface MinMaxDate {
  minDate?: Date;
  maxDate?: Date;
}

export interface DateInputProps extends InputProps {
  minDate?: Date;
  maxDate?: Date;
  isStartDate?: boolean;
  isEndDate?: boolean;
  required?: boolean;
  inheritedErrors?: InputError[];
  inputContainerClassName?: string;
  initialPickerDate?: Date | null;
  // hasYearPicker?: boolean;
}

const DateInput = ({
  minDate,
  maxDate,
  isStartDate,
  isEndDate,
  onChange,
  required = false,
  inheritedErrors,
  inputContainerClassName,
  initialPickerDate,
  ...inputProps
}: DateInputProps): JSX.Element => {
  const { value } = inputProps;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [minMaxDate, setMinMaxDate] = useState<MinMaxDate>({ minDate: minDate, maxDate: maxDate });
  const datePickerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const isExtraSmallScreen = useMediaQuery(ScreenSizeQueries.xsOnly);

  const selectedDate = useMemo(() => parseDate(value), [value]);

  const errors = useMemo(
    () => getDateInputErrors(minDate, maxDate, value),
    [minDate, maxDate, value]
  );

  useEffect(() => {
    if (isExtraSmallScreen) {
      setMinMaxDate({ minDate: undefined, maxDate: undefined });
    }

    if (isExtraSmallScreen || !expanded) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [expanded, isExtraSmallScreen]);

  const handleOnFocus = useCallback(() => {
    setExpanded(false);
  }, []);

  const isTwoDigitYear = (input: string) => {
    const parts = input.split('/');
    return (
      parts.length === 3 &&
      parts[2].length === 2 &&
      !(parts[2].length === 2 && parts[2].slice(-1) === '0')
    );
  };

  const isFourDigitYear = (input: string) => {
    const parts = input.split('/');
    return parts.length === 3 && parts[2].length === 4;
  };

  const previousValueRef = useRef<string>('');

  const handleOnChange = useCallback(
    (newValue: string) => {
      const previousValue = previousValueRef.current;
      const parsedDate = isFourDigitYear(newValue)
        ? parse(newValue, 'M/d/yyyy', new Date())
        : parse(newValue, 'M/d/yy', new Date());

      if (!isValidValue(newValue, validationOptions)) {
        return;
      }

      if (newValue.length === 8 && hasOnlyNumbers(newValue)) {
        onChange?.(
          `${newValue.substring(0, 2)}/${newValue.substring(2, 4)}/${newValue.substring(4, 8)}`
        );
        return;
      }

      if (
        isValid(parsedDate) &&
        (isTwoDigitYear(newValue) || isFourDigitYear(newValue)) &&
        newValue.length > previousValue.length
      ) {
        const formattedDate = format(parsedDate, 'MM/dd/yyyy');
        onChange?.(formattedDate);
      } else {
        onChange?.(newValue);
      }

      previousValueRef.current = newValue;
    },
    [onChange]
  );

  const handleOnSelect = useCallback(
    (date: Date) => {
      onChange?.(formatDate(date));
      setExpanded(false);
    },
    [onChange]
  );

  const handleDatePickerVisibility = useCallback(() => {
    setExpanded(state => !state);
  }, []);

  const [inputPosition, setInputPosition] = useState<DOMRect | undefined>();
  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const updatePosition = () => {
      const rect = inputRef.current?.getBoundingClientRect();
      setInputPosition(rect);
    };

    updatePosition();
    const scrollableParent = getScrollableParent(inputRef.current) ?? document.body;
    scrollableParent.addEventListener('scroll', updatePosition);
    scrollableParent.addEventListener('animationend', updatePosition);
    window.addEventListener('resize', updatePosition);

    return () => {
      scrollableParent.removeEventListener('scroll', updatePosition);
      scrollableParent.removeEventListener('animationend', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, []);

  const [datePickerStyle, setDatePickerStyle] = useState<CSSProperties | undefined>();
  useEffect(() => {
    if (!inputPosition) {
      return;
    }

    const { top, bottom, left } = inputPosition;
    const pxFromBottom = window.innerHeight - bottom;
    const pxFromTop = top;

    if (pxFromBottom > pxFromTop) {
      setDatePickerStyle({
        top: bottom + 10,
        left
      });
    } else {
      setDatePickerStyle({
        bottom: window.innerHeight - top + 10,
        left
      });
    }
  }, [inputPosition]);

  useEffect(() => {
    if (expanded && datePickerRef.current) {
      const { top, bottom, left } = datePickerRef.current.getBoundingClientRect();
      if (bottom > window.innerHeight) {
        setDatePickerStyle({ left, bottom: window.innerHeight });
      } else if (top < 0) {
        setDatePickerStyle({ left, top: 0 });
      }
    }
  }, [expanded]);

  return (
    <div>
      <div ref={inputRef}>
        <Input
          className="mb-3"
          {...inputProps}
          messageStatusContainerClassName="relative"
          placeholder={DateFormat.toUpperCase()}
          iconName="upcoming"
          iconAccessibilityLabel="Open calendar"
          onIconClick={handleDatePickerVisibility}
          maxLength={DateFormat.length}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          errors={
            inheritedErrors?.some(x => x.message !== undefined)
              ? errors.concat(inheritedErrors)
              : errors
          }
          required={required}
          inputContainerClassName={inputContainerClassName}
        />
      </div>
      {!isExtraSmallScreen && expanded && (
        <RemoveScroll>
          <DatePicker
            ref={datePickerRef}
            isMobile={false}
            initialMonth={selectedDate ?? initialPickerDate ?? undefined}
            selectedDate={selectedDate}
            onSelect={handleOnSelect}
            minDate={minDate}
            maxDate={maxDate}
            isStartDate={isStartDate}
            isEndDate={isEndDate}
            style={datePickerStyle}
          />
        </RemoveScroll>
      )}
      <Panel isOpen={isExtraSmallScreen && expanded} onOpenChange={setExpanded}>
        <Panel.Content
          side="right"
          hasHorizontalPadding={false}
          isNestedModal={true}
          centerHeaderContent={
            <YearChangeButton
              onYearChange={year =>
                setMinMaxDate({
                  minDate: startOfYear(
                    selectedDate?.setFullYear(year) ?? new Date().setFullYear(year)
                  ),
                  maxDate: endOfYear(
                    selectedDate?.setFullYear(year) ?? new Date().setFullYear(year)
                  )
                })
              }
              currentDate={selectedDate ?? new Date()}
            />
          }
          onBackButtonPress={() => setExpanded(false)}
        >
          <Panel.Body>
            <DatePicker
              isMobile={true}
              initialMonth={selectedDate ?? initialPickerDate ?? undefined}
              selectedDate={selectedDate}
              onSelect={handleOnSelect}
              minDate={minMaxDate.minDate}
              maxDate={minMaxDate.maxDate}
              isStartDate={isStartDate}
              isEndDate={isEndDate}
            />
          </Panel.Body>
        </Panel.Content>
      </Panel>
    </div>
  );
};

export default DateInput;
