import { IFacetGroup, SearchResponse } from '@/@types/client-api';
import { FILTERS, QUERY_PARAMS, SearchSemanticFilter } from '../constants';
import { TFilterItem } from '../../Filter/FilterRow';
import { getCreditTypeNameFromCreditType } from '@/redux/helpers/search';
import { removeTransitionalJurisdictionSuffix } from './filters';

export const getJurisdictionFacetGroup = (value: string) => ({
  groupTypeId: FILTERS.JURISDICTIONS,
  facets: [
    {
      value,
      checked: true
    }
  ]
});

export const getCreditTypeFacetGroup = (value: string) => ({
  groupTypeId: FILTERS.CREDIT_TYPE,
  facets: [
    {
      value,
      checked: true
    }
  ]
});

export const getFilterFromGroup = (
  searchResults: SearchResponse | undefined,
  groupId: FILTERS.JURISDICTIONS | FILTERS.CREDIT_TYPE,
  value: string
) => {
  const group = searchResults?.facetResults?.facetGroups?.find(
    group => group.groupTypeId === groupId
  );

  return group?.facets?.find(facet => {
    if (groupId === FILTERS.CREDIT_TYPE) {
      return getCreditTypeNameFromCreditType(facet) === value;
    }
    if (groupId === FILTERS.JURISDICTIONS) {
      return removeTransitionalJurisdictionSuffix(facet.value ?? '') === value;
    }
    return facet.value === value;
  });
};

// Get the semantic filter that matches the query
export const getMatchingSemanticFilter = (
  filters: SearchSemanticFilter[],
  query: string
): SearchSemanticFilter | undefined =>
  filters?.find(filter => {
    const hasMatchingKeyword = filter.keyword?.toLowerCase().trim() === query?.toLowerCase().trim();

    let hasMatchingRegex = false;
    if (filter.regex) {
      const regex = new RegExp(filter.regex, 'i');
      hasMatchingRegex = regex.test(query || '');
    }

    return hasMatchingKeyword || hasMatchingRegex;
  });

// Find the filter that should have tooltip applied
export const getFilterForTooltip = (
  filters: (TFilterItem | undefined)[] | undefined,
  semanticFilters: IFacetGroup[] | null
): TFilterItem | undefined => {
  if (!filters || !semanticFilters || semanticFilters?.length === 0) return;

  const numSemanticFilters = semanticFilters.reduce(
    (acc, filter) => acc + (filter.facets ? filter.facets.length : 0),
    0
  );

  if (numSemanticFilters !== 2) {
    const semanticFilter = semanticFilters?.[0].facets?.[0];
    const firstFilter = filters.find(
      filter => !!filter?.data?.value && filter.data?.value === semanticFilter?.value
    );
    return firstFilter;
  } else {
    const creditTypeGroupFilters = semanticFilters.find(
      group => group.groupTypeId === FILTERS.CREDIT_TYPE
    )?.facets;
    const firstCreditTypeFilterInList = filters.find(filter =>
      creditTypeGroupFilters?.map(f => f.value).includes(filter?.data?.value)
    );
    if (firstCreditTypeFilterInList) {
      return firstCreditTypeFilterInList;
    } else {
      const flatFilters = semanticFilters.flatMap(group => group.facets || []);
      const firstFilter = filters.find(filter =>
        flatFilters.map(f => f.value).includes(filter?.data?.value)
      );
      return firstFilter;
    }
  }
};

export const shiftQuery = (
  setSearchParams: (nextInit?: (prev: URLSearchParams) => URLSearchParams) => void
) => {
  setSearchParams(existing => {
    if (!existing.has(QUERY_PARAMS.OLD_QUERY)) {
      existing.set(QUERY_PARAMS.OLD_QUERY, existing.get(QUERY_PARAMS.QUERY) || '');
    }
    existing.delete(QUERY_PARAMS.QUERY);
    return existing;
  });
};

export const unshiftQuery = (
  setSearchParams: (nextInit?: (prev: URLSearchParams) => URLSearchParams) => void
) => {
  setSearchParams(existing => {
    existing.set(QUERY_PARAMS.QUERY, existing.get(QUERY_PARAMS.OLD_QUERY) || '');
    existing.set(QUERY_PARAMS.DISABLE_SEMANTIC_FILTERS, '1');
    existing.delete(QUERY_PARAMS.OLD_QUERY);
    return existing;
  });
};
