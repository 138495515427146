import { IconLinkBlock as IconLinkBlockProps } from '@/@types/content';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';
import classnames from 'classnames';

const IconLinkBlock: React.FC<IconLinkBlockProps> = ({ link, icon }) => {
  const { text, ...rest } = link;
  const buttonContainerClasses = classnames(
    'flex items-center gap-4 overflow-hidden rounded-lg border border-gray-light bg-gray-feather px-4 py-5 transition-colors hover:cursor-pointer hover:border-silver hover:bg-gray-light',
    'sm:px-6',
    'lg:px-8'
  );

  return (
    <a className={buttonContainerClasses} {...rest}>
      {icon && <Icon name={icon} size="medium" />}
      {link && <Text className="text-1 flex-1 font-medium">{text}</Text>}
      {icon && <Icon name="chevron-right" size="medium" />}
    </a>
  );
};

export default IconLinkBlock;
