import { getCurrentPage } from '@/utils/pagination';
import { PayloadAction, SerializedError, createSlice } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  CommonSearchState,
  clearAllFiltersHelper,
  clearFilterHelper,
  createSuccessHelper,
  createToggleFilterHelper
} from '../helpers/search';
import {
  AdHocFilter,
  FILTERS,
  FacetFilter,
  InvariantFilter,
  QueryFilter
} from '@/components/ui/SearchResults/constants';
import { SemanticFilter } from '@/components/ui/SearchResults/hooks/useGetSemanticFilters';
import { trackLoadMoreEvent } from '@/analytics/pagination';
import { AnalyticsContext } from '@/analytics/constants';

export type SemanticFilterTooltipData = {
  title?: string;
  description?: string;
};

export type SearchState = CommonSearchState & {
  invariantFilters: InvariantFilter[];
  semanticFilters: SemanticFilter[] | null;
  semanticFilterTooltip: SemanticFilterTooltipData | null;
  facetFilters: FacetFilter[] | null;
  queryFilters: QueryFilter[] | null;
  searchTermHistory: string[];
  lastSearchParams: string | undefined;
};

const initialSearchState: SearchState = {
  data: null,
  request: null,
  isLoading: false,
  isFetching: false,
  error: null,
  adHocFilters: [],
  invariantFilters: [],
  semanticFilters: [],
  semanticFilterTooltip: null,
  queryFilters: [],
  facetFilters: null,
  preselectedFiltersThatHaveBeenDeselected: [],
  searchTermHistory: [],
  lastSearchParams: undefined
};

const ALLOWED_AD_HOC_FILTERS = [FILTERS.JURISDICTIONS];

const searchSlice = createSlice({
  name: 'search',
  initialState: initialSearchState,
  reducers: {
    searchLoading: (state, payload: PayloadAction<'loading' | 'fetching' | 'none'>) => {
      state.isLoading = payload.payload === 'loading';
      state.isFetching = payload.payload === 'fetching';
    },
    searchSuccess: createSuccessHelper(ALLOWED_AD_HOC_FILTERS),
    searchFailure: (state, action: PayloadAction<FetchBaseQueryError | SerializedError>) => {
      state.isLoading = false;
      state.isFetching = false;
      state.error = action.payload;
      state.data = {
        searchResultSummary: {
          noOfHits: 0
        }
      };
    },
    setInvariantFilters: (state, action: PayloadAction<InvariantFilter[]>) => {
      state.invariantFilters = action.payload;
    },
    setAdHocFilters: (state, action: PayloadAction<AdHocFilter>) => {
      /* do this to avoid adding the same adhoc fitler over and over again to the state */
      state.adHocFilters = Array.from(
        new Map([...state.adHocFilters, action.payload].map(v => [v.value, v])).values()
      );
    },
    toggleQueryFilter: (state, action: PayloadAction<Pick<QueryFilter, 'query'>>) => {
      const queryFilter = state.queryFilters?.find(filter => filter.query === action.payload.query);

      if (queryFilter) {
        queryFilter.checked = !queryFilter.checked;
      }
    },
    setQueryFilters: (state, action: PayloadAction<QueryFilter[]>) => {
      state.queryFilters = action.payload;
    },
    setSemanticFilters: (
      state,
      action: PayloadAction<{
        filters: SemanticFilter[];
        tooltip: SemanticFilterTooltipData | null;
      }>
    ) => {
      state.semanticFilters = action.payload.filters;
      state.semanticFilterTooltip = action.payload.tooltip;
    },
    setFacetFilters: (state, action: PayloadAction<FacetFilter[] | null>) => {
      state.facetFilters = action.payload;
    },
    setPreselectedFiltersThatHaveBeenDeselected: (state, action: PayloadAction<string[]>) => {
      state.preselectedFiltersThatHaveBeenDeselected = Array.from(
        new Set([...state.preselectedFiltersThatHaveBeenDeselected, ...action.payload])
      );
    },
    setOptimisticToggleForLibraryFavoriteItemUpdate: (state, action: PayloadAction<string[]>) => {
      if (!state.data?.searchResults?.searchResultsItems) return;

      const searchItems = state.data?.searchResults?.searchResultsItems;
      searchItems?.forEach(result => {
        result.userList?.forEach(item => {
          if (item.pk && action.payload.includes(item.pk)) {
            item.isFavorite = !item.isFavorite;
          }
        });
      });

      state.data.searchResults.searchResultsItems = searchItems;
    },
    setOptimisticRegistrationDetailsUpdate: (
      state,
      action: PayloadAction<{
        pk: string;
        isRegistered: boolean;
        format?: string;
        location?: string;
        startDate?: string;
        endDate?: string;
      }>
    ) => {
      if (!state.data?.searchResults?.searchResultsItems) return;

      const searchItems = state.data?.searchResults?.searchResultsItems;
      searchItems?.forEach(result => {
        result.userList?.forEach(item => {
          if (item.pk && action.payload.pk === item.pk) {
            item.isPurchased = action.payload.isRegistered;

            if (item.isPurchased) {
              item.registrationDetails = {
                format: action.payload.format,
                location: action.payload.location,
                startDateUtc: action.payload.startDate,
                endDateUtc: action.payload.endDate
              };
            } else {
              item.registrationDetails = undefined;
            }
          }
        });
      });

      state.data.searchResults.searchResultsItems = searchItems;
    },
    toggleFilter: createToggleFilterHelper(ALLOWED_AD_HOC_FILTERS),
    clearFilters: clearFilterHelper,
    clearAllFilters: clearAllFiltersHelper,
    loadMore: state => {
      if (!state.data?.searchResultSummary?.currentPage) return;

      const nextPage =
        getCurrentPage(
          state.data.searchResultSummary.currentPage,
          state.data.searchResults?.searchResultsItems?.length
        ) + 1;
      trackLoadMoreEvent(AnalyticsContext.Search, nextPage);
      state.data.searchResultSummary.currentPage = nextPage;
    },
    sort: (state, action: PayloadAction<string>) => {
      const sortItems = state.data?.sortResults?.sortResultItems;
      sortItems?.forEach(sortItem => {
        sortItem.selected = sortItem.value === action.payload;
      });

      // reset to first page
      if (state.data?.searchResultSummary?.currentPage) {
        state.data.searchResultSummary.currentPage = 1;
      }
    },
    addSearchTermToHistory: (state, action: PayloadAction<string>) => {
      state.searchTermHistory.push(action.payload);
    },
    setLastSearchParams: (state, action: PayloadAction<string>) => {
      state.lastSearchParams = action.payload;
    }
  }
});

export const {
  searchLoading,
  searchSuccess,
  searchFailure,
  setInvariantFilters,
  setAdHocFilters,
  setQueryFilters,
  setSemanticFilters,
  setFacetFilters,
  toggleFilter,
  clearFilters,
  clearAllFilters,
  toggleQueryFilter,
  loadMore,
  sort,
  setPreselectedFiltersThatHaveBeenDeselected,
  setOptimisticToggleForLibraryFavoriteItemUpdate,
  setOptimisticRegistrationDetailsUpdate,
  addSearchTermToHistory,
  setLastSearchParams
} = searchSlice.actions;

export default searchSlice.reducer;
