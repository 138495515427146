import { ClientAPI } from '@/redux/api';
import type {} from '@/@types/client-api';

const redirectEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getRedirectForUrl: builder.query<string, string>({
      query: url => `/redirect/?uri=${url}`
    })
  })
});

export const { useGetRedirectForUrlQuery, useLazyGetRedirectForUrlQuery } = redirectEndpoints;

export default redirectEndpoints;
