import classnames from 'classnames';

import Icon from '@/components/ui/Icon';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

export interface EyebrowProps {
  label: string;
  color?: 'white' | 'green' | 'gradient' | 'inherit';
  icon?: string;
  sizeFormat?: 'snippet' | 'pdp';
  className?: string;
}

const Eyebrow = ({
  label,
  color = 'inherit',
  icon = '',
  sizeFormat = 'snippet',
  className
}: EyebrowProps) => {
  const classes = classnames('inline-flex gap-2', className, {
    'text-serpentine': color === 'green',
    'text-white': color === 'white'
  });

  /*
    The gradient overlay has to be applied directly to the text and can't be inherited from its parent.
    That is because the gradient starting point is absolute while the text starting position is relative to its container
    and thus can be affected by margins and paddings
  */
  const textGradientOverlay = classnames({
    'bg-gradient bg-clip-text text-transparent': color === 'gradient'
  });

  const isLarge = useMediaQuery(ScreenSizeQueries.lg);
  const isPDP = sizeFormat === 'pdp';
  const labelClass = classnames(isPDP ? 'text-2' : 'label');

  return (
    <div className={classes}       data-component={"Eyebrow"}>
      <Icon size={isLarge && isPDP ? 'medium' : 'small'} name={icon} />
      <p className={classnames(labelClass, textGradientOverlay)}>{label}</p>
    </div>
  );
};

export default Eyebrow;
