import { CreditJurisdiction } from '@/@types/client-api';

export const orderByCreditClass = (a: CreditJurisdiction, b: CreditJurisdiction) => {
  if (a.creditClassSortOrder !== undefined && b.creditClassSortOrder !== undefined) {
    return a.creditClassSortOrder - b.creditClassSortOrder;
  }
  return a.creditClassSortOrder !== undefined ? -1 : 1;
};

export const orderByRegion = (a: CreditJurisdiction, b: CreditJurisdiction) => {
  if (a.creditRegionSortOrder !== undefined && b.creditRegionSortOrder !== undefined) {
    return a.creditRegionSortOrder - b.creditRegionSortOrder;
  }
  return a.creditRegionSortOrder !== undefined ? -1 : 1;
};
