import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ErrorBoundary } from 'react-error-boundary';

type MenuDrawerProps = {
  useManualTransition?: boolean;
  children: ReactNode;
  isOpen?: boolean;
  onClose: () => void;
};

const MenuDrawerInner = ({ children, isOpen, onClose, useManualTransition }: MenuDrawerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Measure and set the height so the menu retains its size on close,
  // even after Radix removes the inner content
  const updateHeight = useCallback(() => {
    const height = ref.current?.getBoundingClientRect().height;
    if (height && height > 10) {
      setHeight(height);
    }
  }, []);

  // useEffect to manage body class and transition end
  useEffect(() => {
    const bodyClass = 'custom-menu-on-mobile';

    if (isOpen || isTransitioning) {
      document.body.classList.add(bodyClass);
      setIsTransitioning(true);
    } else {
      document.body.classList.remove(bodyClass);
    }

    return () => {
      document.body.classList.remove(bodyClass);
    };
  }, [isOpen, isTransitioning]);

  const menuClasses = classnames(
    'fixed bottom-0 z-panel w-full rounded-t bg-white group-data-[state=closed]:animate-slideOutDownShort group-data-[state=open]:animate-slideInUpShort',
    {
      'transition-transform duration-short': useManualTransition
    }
  );

  const overlayClasses = classnames(
    'fixed inset-0 z-panel group-data-[state=closed]:animate-fadeOutShort group-data-[state=open]:animate-fadeInShort',
    {
      'transition-opacity duration-short': useManualTransition,
      'pointer-events-none opacity-0': !isOpen && useManualTransition,
      'bg-black/30': isOpen
    }
  );

  return (
    <div className="relative h-full">
      <button
        className={overlayClasses}
        aria-label="Close"
        onMouseDown={() => {
          setIsTransitioning(true);
          onClose();
        }}
      />
      <div
        className={menuClasses}
        style={{ height }}
        onTransitionEnd={() => {
          updateHeight();
          setIsTransitioning(false);
        }}
        ref={ref}
      >
        {children}
      </div>
    </div>
  );
};

const MenuDrawer = React.forwardRef<HTMLDivElement, MenuDrawerProps>(
  ({ isOpen, useManualTransition, ...props }, forwardedRef) => {
    const isAboveXS = useMediaQuery(ScreenSizeQueries.sm);

    const menuDrawerClasses = classnames('z-modal sm:relative', {
      'group fixed inset-x-0 bottom-0 h-screen data-[state=closed]:animate-[radixOut_200ms_ease]':
        !isAboveXS,
      'pointer-events-none': !isOpen && useManualTransition
    });

    return (
      <ErrorBoundary fallback={<h4>Apologies, an error has occured with this menu</h4>}>
        <div {...props} ref={forwardedRef} className={menuDrawerClasses}       data-component={"MenuDrawer"}        >
          {isAboveXS ? props.children : <MenuDrawerInner {...props} isOpen={isOpen} />}
        </div>
      </ErrorBoundary>
    );
  }
);

export default MenuDrawer;
