import * as Collapsible from '@radix-ui/react-collapsible';
import LinkGridBlockContent, { LinkGridBlockContentProps } from './LinkGridBlockContent';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';
import { TextLinkBlock } from '@/@types/content';
import { IContent } from '@/@types/cms';

type LinkGridBlockCollapsibleProps = LinkGridBlockContentProps & {
  numberOfRows?: number;
  seeMoreLabel?: string;
  seeLessLabel?: string;
  order?: 'ROW' | 'COL';
};

const LinkGridBlockCollapsible = ({
  linkBlocks,
  layout,
  numberOfRows,
  seeMoreLabel,
  seeLessLabel,
  onTrackItemViewEvent,
  order = 'ROW'
}: LinkGridBlockCollapsibleProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const [alwaysVisibleListArray, setAlwaysVisibleListArray] = useState<IContent[]>([]);
  const [collapsableListArray, setCollapsableListArray] = useState<IContent[]>([]);

  useEffect(() => {
    if (order === 'COL') {
      const cols = parseInt(layout);
      const totalRows = Math.ceil(linkBlocks.length / cols);

      const newArr: TextLinkBlock[] = [];
      let acc = 0;
      const qtyItemsFullColumns = cols * totalRows;
      for (let i = 0; i < qtyItemsFullColumns; i++) {
        if (i > 0 && i % cols === 0) {
          acc += 1;
        }

        const nidx = (i % cols) * totalRows + acc;

        if (linkBlocks[nidx]) {
          const linkBlock = linkBlocks[nidx] as TextLinkBlock;

          newArr.push({
            ...linkBlock,
            link: { ...linkBlock.link, href: linkBlock?.link?.href || linkBlock?.url || '#' }
          });
        } else {
          newArr.push({
            contentType: ['TextLinkBlock'],
            link: {
              href: '#',
              text: '',
              title: ''
            }
          });
        }
      }

      const visibleLinkBlocksCount = Number(numberOfRows) * Number(layout);
      setAlwaysVisibleListArray(newArr.slice(0, visibleLinkBlocksCount));
      setCollapsableListArray(newArr.slice(visibleLinkBlocksCount, qtyItemsFullColumns));
    } else {
      const visibleLinkBlocksCount = numberOfRows ? Number(numberOfRows) * Number(layout) : 6;
      setAlwaysVisibleListArray(linkBlocks.slice(0, visibleLinkBlocksCount));
      setCollapsableListArray(linkBlocks.slice(visibleLinkBlocksCount, linkBlocks.length));
    }
  }, [linkBlocks, layout, numberOfRows, order]);

  const toggleExpanded = () => {
    setExpanded(state => !state);
  };

  return (
    <Collapsible.Root open={expanded} onOpenChange={setExpanded}>
      <>
        {alwaysVisibleListArray.length > 0 && (
          <LinkGridBlockContent
            linkBlocks={alwaysVisibleListArray}
            layout={layout}
            onTrackItemViewEvent={onTrackItemViewEvent}
          />
        )}
        {collapsableListArray.length > 0 && (
          <>
            <Collapsible.Content className="mt-4 overflow-hidden data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown lg:mt-6">
              <LinkGridBlockContent
                linkBlocks={collapsableListArray}
                layout={layout}
                onTrackItemViewEvent={onTrackItemViewEvent}
              />
            </Collapsible.Content>

            <Collapsible.Trigger asChild>
              <button
                onClick={toggleExpanded}
                className={classnames('text-2 mt-8 flex items-center gap-1 font-bold')}
                aria-expanded={expanded}
              >
                {expanded ? seeLessLabel || '' : seeMoreLabel || ''}
                <Icon size="small" name={expanded ? 'chevron-up' : 'chevron-down'} />
              </button>
            </Collapsible.Trigger>
          </>
        )}
      </>
    </Collapsible.Root>
  );
};

LinkGridBlockCollapsible.displayName = 'LinkGridBlockCollapsible';
export default LinkGridBlockCollapsible;
