import { LongTileSubBlock as LongTileSubBlockProps } from '@/@types/content';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import classnames from 'classnames';
import Text from '@/components/cms/Text';
import Image from '@/components/cms/Image';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Link from '@/components/ui/Link';
import Icon from '@/components/ui/Icon';

const LongTileSubBlock: React.FC<LongTileSubBlockProps> = ({
  heading,
  link,
  icon,
  quoteAuthorText,
  individualAuthorRol,
  displayQuotation,
  eyebrow
}: LongTileSubBlockProps) => {
  const editMode = isEditOrPreviewMode();

  const screenSizeBelowMd = useMediaQuery(ScreenSizeQueries.belowMd);
  const containerImageClass = classnames('flex items-center justify-center overflow-hidden');

  const imageClass = classnames('max-h-full max-w-[119px] !object-contain md:max-w-full');

  return (
    <div className="flex h-[390px] w-full flex-col justify-between rounded-lg bg-gray-lightest p-6 md:h-[616.5px] md:p-10">
      <section className="flex flex-col gap-y-2 lg:gap-y-4">
        {eyebrow && (
          <Text propertyName="Eyebrow" element="p" className="label text-gray-dark">
            {eyebrow}
          </Text>
        )}
        <Text
          propertyName="Heading"
          element="h6"
          className={classnames({
            'text-3-fixed-l': !screenSizeBelowMd,
            'heading-6-medium': screenSizeBelowMd
          })}
        >
          {heading}
        </Text>
        {link || editMode ? (
          <Link
            propertyName="Link"
            href={link?.href}
            target={link?.target}
            text={link?.text}
            variant="text-link-1"
            className="text-gray-dark"
          />
        ) : null}
      </section>
      <section className="flex h-14 w-auto items-center justify-between md:h-20">
        {icon || editMode ? (
          <Image
            propertyName="Icon"
            className={imageClass}
            src={icon?.url}
            alt={icon?.alt as string}
            title={icon?.title as string}
          />
        ) : null}
        <section>
          {quoteAuthorText || editMode ? (
            <Text
              propertyName="QuoteAuthorText"
              element="p"
              className={classnames('text-1 font-bold')}
            >
              {quoteAuthorText}
            </Text>
          ) : null}
          {individualAuthorRol || editMode ? (
            <Text propertyName="IndividualAuthorRol" element="p" className={classnames('text-1')}>
              {individualAuthorRol}
            </Text>
          ) : null}
        </section>
        {displayQuotation || editMode ? (
          <div className={classnames(containerImageClass, 'size-[22px]')}>
            <Icon name="close-quote" size="large" className="size-[22px]" />
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default LongTileSubBlock;
