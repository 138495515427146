import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MenuTextTriggerButton from '../Menu/MenuTextTriggerButton';
import * as Dialog from '@radix-ui/react-dialog';
import Button from '../Buttons/Button';
import Icon from '../Icon';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { formatOptions } from '@/components/ui/CreditTracker/util/TrackerHelpers';

interface YearChangeButtonProps {
  currentDate: Date;
  onYearChange: (year: number) => void;
}

const YearChangeButton: React.FC<YearChangeButtonProps> = ({ currentDate, onYearChange }) => {
  const [selectedYear, setSelectedYear] = useState<number>(currentDate.getFullYear());
  const [isOpen, setIsOpen] = useState(false);
  const [activeSelected, setActiveSelected] = useState<string>();
  const [shouldScroll, setShouldScroll] = useState(false);
  const isXsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);

  const handleSelect = useCallback(
    (selectedYear: number) => {
      setSelectedYear(selectedYear);
      onYearChange(selectedYear);
      setIsOpen(false);
    },
    [onYearChange]
  );

  const scrollToSelectedYear = useCallback(() => {
    const element = document.getElementById(activeSelected!);
    element?.scrollIntoView({ behavior: 'auto' });
    setShouldScroll(false);
  }, [activeSelected]);

  useEffect(() => {
    setShouldScroll(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (shouldScroll && !!activeSelected) {
      scrollToSelectedYear();
    }
  }, [activeSelected, scrollToSelectedYear, shouldScroll]);

  const yearInterval = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear + 6;
    const endYear = currentYear - 10;
    const yearsArray = Array.from(
      { length: startYear - endYear + 1 },
      (_, index) => startYear - index
    );
    return yearsArray.map(Number);
  }, []);

  const buttons = useMemo(() => {
    return formatOptions(yearInterval).map(option => {
      const isSelected = selectedYear === option.value;
      const id = `year_option_${option.value}`;
      if (isSelected) {
        setActiveSelected(id);
      }
      return (
        <button
          key={option.value}
          id={id}
          className="text-1-medium flex w-full justify-between border-b border-gray-light py-4 pr-1 text-left text-black first:border-t hover:bg-gray-light focus:bg-gray-light sm:border-0 sm:first:border-t-0"
          onClick={() => handleSelect(Number(option.value))}
        >
          {option.label}
          {isSelected && <Icon size="medium" className="ml-4" name="checkmark" />}
        </button>
      );
    });
  }, [handleSelect, selectedYear, yearInterval]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <MenuTextTriggerButton
          aria-label="Select a year"
          label={format(
            isXsOnly
              ? currentDate.setFullYear(selectedYear ?? new Date().getFullYear())
              : currentDate,
            'MMMM yyyy'
          )}
          className="mx-auto flex"
          iconSize="small"
          isOpen={isOpen}
          color="black"
        />
      </Dialog.Trigger>
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
      <Dialog.Content
        className="absolute left-0 top-0 z-modal flex
          size-full flex-col rounded-md bg-white"
      >
        <div className="mb-6 flex items-center justify-between">
          <Button
            iconLeft="chevron-left"
            size="small"
            color="white"
            onClick={() => setIsOpen(false)}
            className="pl-2"
          />
        </div>
        <div className="flex h-full flex-col overflow-auto px-6">{buttons}</div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default YearChangeButton;
