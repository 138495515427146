import { useEffect, useState } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import IconTag from '../../IconTag';
import Thumbnail from '../../Thumbnail';
import { ScreenSizeQueries } from '@/constants/breakpoints';

interface SegmentThumbnailProps {
  imageUrl: string;
  displayName: string;
  runtime?: string;
  thumbnailMode: 'cart' | 'launch';
  launchLabel: string;
  cartLabel: string;
  progressPct?: number;
  onClick: () => void;
  isSuccess?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  isExpiredFromSale?: boolean;
}

const SegmentThumbnail: React.FC<SegmentThumbnailProps> = ({
  imageUrl: imgSrc,
  displayName,
  runtime,
  thumbnailMode,
  launchLabel,
  cartLabel,
  progressPct,
  isSuccess,
  isError,
  isLoading,
  onClick,
  isExpiredFromSale
}) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(isSuccess ?? false);
  const [showLoading, setShowLoading] = useState<boolean>(isLoading ?? false);

  const isMobile = useMediaQuery(ScreenSizeQueries.xsOnly);

  const thumbnailIcon =
    thumbnailMode === 'launch'
      ? {
          label: launchLabel,
          name: 'play'
        }
      : {
          label: cartLabel,
          name: 'cart'
        };

  useEffect(() => {
    if (isSuccess) {
      setShowSuccess(isSuccess);
      setShowLoading(false);
      setTimeout(() => {
        setShowSuccess(false);
      }, 1000);
    }

    if (isLoading) {
      setShowLoading(isLoading);
    }

    if (isError) {
      setShowLoading(false);
    }
  }, [isSuccess, isLoading, isError]);

  const thumbnail = isMobile ? (
    <button
      className="block size-full"
      onClick={onClick}
      aria-label={thumbnailIcon.label}
      disabled={showSuccess || showLoading}
    >
      <div className="relative">
        <img
          src={imgSrc}
          alt={displayName}
          className="aspect-square size-full rounded-md object-cover"
        />

        {!isExpiredFromSale && (
          <span className="absolute bottom-4 right-4">
            <IconTag variant="transparent" icon={thumbnailIcon.name} size="small" />
          </span>
        )}
      </div>
    </button>
  ) : (
    <Thumbnail
      icon={!isExpiredFromSale ? thumbnailIcon : undefined}
      isSuccess={showSuccess}
      isLoading={showLoading}
      ariaLabel={thumbnailIcon.label}
      image={{
        alt: displayName,
        url: imgSrc
      }}
      onClick={onClick}
      tag={
        runtime && progressPct !== 100
          ? {
              children: runtime
            }
          : undefined
      }
      progressValue={progressPct}
      card
      imageSizes={{
        xs: '320px',
        sm: '496px',
        md: '768px',
        lg: '342px'
      }}
    />
  );

  return thumbnail;
};

export default SegmentThumbnail;
