import { baseQueryExtraOptions, ClientAPI } from '@/redux/api';
import type {
  AnnouncementMessage,
  Certificate,
  CreditTrackerUpdateRequest,
  CreditTrackerViewModel,
  ICreditClassData,
  UserTrackerDataRequest
} from '@/@types/client-api';

export interface ICertificateRequest {
  customerId: number;
  region: number;
  certificate?: Certificate | null;
}

export interface ICreditTypeByDate {
  customerId: number;
  cleKey: string;
  date: string;
}

export interface IAnnouncementsRequest {
  region: string;
  isTransitionalOrNewlyAdmitted: boolean;
}

export const trackerBaseUrl = '/credit-tracker';
const staticResponseCacheLifespan = 300; // 5 minutes

const creditTrackerEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getCreditTracker: builder.query<
      CreditTrackerViewModel,
      UserTrackerDataRequest[] | null | undefined
    >({
      providesTags: ['CreditTrackerData'],
      extraOptions: {
        ignoreDelayedAction: true
      } as baseQueryExtraOptions,
      query: body => ({
        url: trackerBaseUrl,
        method: 'POST',
        body
      }),
      keepUnusedDataFor: staticResponseCacheLifespan
    }),
    refetchCreditTracker: builder.mutation<
      CreditTrackerViewModel,
      UserTrackerDataRequest[] | null | undefined
    >({
      query: body => ({
        url: trackerBaseUrl,
        method: 'POST',
        body
      })
    }),
    updateUserCleOptions: builder.mutation<void, CreditTrackerUpdateRequest>({
      query: body => ({
        url: `${trackerBaseUrl}/set-options`,
        method: 'POST',
        body,
        responseHandler: response => response.text()
      }),
      invalidatesTags: ['CreditTrackerData', { type: 'CreditTrackerData', id: 'Messages' }]
    }),
    switchStatus: builder.mutation<void, CreditTrackerUpdateRequest>({
      query: body => ({
        url: `${trackerBaseUrl}/switch-status`,
        method: 'POST',
        body,
        responseHandler: response => response.text()
      }),
      invalidatesTags: ['CreditTrackerData', { type: 'CreditTrackerData', id: 'Messages' }]
    }),
    switchStatusLazy: builder.mutation<void, CreditTrackerUpdateRequest>({
      query: body => ({
        url: `${trackerBaseUrl}/switch-status`,
        method: 'POST',
        body,
        responseHandler: response => response.text()
      })
    }),
    updateComplianceDateRange: builder.mutation<void, CreditTrackerUpdateRequest>({
      query: body => ({
        url: `${trackerBaseUrl}/set-options`,
        method: 'POST',
        body,
        responseHandler: response => response.text()
      })
    }),
    getAnnouncementMessages: builder.query<AnnouncementMessage[], IAnnouncementsRequest>({
      providesTags: [{ type: 'CreditTrackerData', id: 'Messages' }],
      query: req => ({
        url: `${trackerBaseUrl}/announcements/${req.region}?isTransitionalOrNewlyAdmitted=${req.isTransitionalOrNewlyAdmitted}`,
        method: 'GET'
      })
    }),
    getManyAnnouncementMessages: builder.query<
      Record<string, AnnouncementMessage[]>,
      IAnnouncementsRequest[]
    >({
      providesTags: [{ type: 'CreditTrackerData', id: 'Messages' }],
      query: req => ({
        url: `${trackerBaseUrl}/announcements/many`,
        body: req,
        method: 'POST'
      })
    }),
    getProviders: builder.query<Array<string>, null>({
      query: () => ({
        url: `${trackerBaseUrl}/providers`,
        method: 'GET'
      }),
      keepUnusedDataFor: staticResponseCacheLifespan
    }),
    getCreditTypesByDate: builder.query<Array<ICreditClassData>, ICreditTypeByDate>({
      query: props => ({
        url: `${trackerBaseUrl}/credit-types/${props.customerId}/${props.cleKey}/${props.date}`,
        method: 'GET'
      }),
      keepUnusedDataFor: staticResponseCacheLifespan
    })
  })
});

export const {
  useGetCreditTrackerQuery,
  useRefetchCreditTrackerMutation,
  useSwitchStatusMutation,
  useSwitchStatusLazyMutation,
  useUpdateUserCleOptionsMutation,
  useLazyGetCreditTrackerQuery,
  useGetAnnouncementMessagesQuery,
  useLazyGetAnnouncementMessagesQuery,
  useGetManyAnnouncementMessagesQuery,
  useLazyGetManyAnnouncementMessagesQuery,
  useGetProvidersQuery,
  useLazyGetProvidersQuery,
  useGetCreditTypesByDateQuery,
  useLazyGetCreditTypesByDateQuery,
  useUpdateComplianceDateRangeMutation
} = creditTrackerEndpoints;

export default creditTrackerEndpoints;
