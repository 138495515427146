import { ClientAPI } from '@/redux/api';

const cardServiceEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getCard: builder.query<void, string>({
      query: url => ({
        url: `${url}`,
        method: 'GET'
      })
    })
  })
});

export const { useGetCardQuery } = cardServiceEndpoints;
export default cardServiceEndpoints;
