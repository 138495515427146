import { MouseEvent } from 'react';

import SnippetMetadata, { SnippetMetadataProps } from '@/components/ui/Snippet/SnippetMetadata';
import type { SnippetExpandedContentProps } from '@/components/ui/Snippet/SnippetExpandedContent';
import Link from '@/components/ui/Link';
import type {
  SnippetProps,
  SnippetPrimaryStatus,
  SnippetButtonProps
} from '@/components/ui/Snippet/Snippet';

import type { RenderSnippetProps } from './types';
import {
  getLiveProgramThumbnail,
  getAttendanceInfo,
  aggregateUserData,
  getFormattedLocationDates,
  getIsSavedToLibrary,
  getInlineListProps,
  hasMultipleRecordings,
  getIsRegistrationAvailable
} from './helpers';
import {
  getAccessMaterialsButton,
  getViewCreditDetailsButton,
  renderAddToCalendarButtonWrapper,
  renderEcommPanelButtonWrapper
} from './buttons';
import { SnippetButtonLabel } from './buttonsLabels';
import { getLiveProgramRuntime } from '../../PDP/helpers';

const renderLiveProgram = ({
  data,
  actions,
  context,
  modalPanelState,
  actionStatus = 'idle'
}: RenderSnippetProps): SnippetProps => {
  const {
    title = '',
    creditDetailsFormatted = [],
    summaryText,
    userList = [],
    locations: _locations = [],
    practiceAreas,
    industries,
    formats: _formats,
    runTimeSeconds = 0,
    variationPk = null,
    url,
    isLive = false,
    streamingUrl,
    itemAnalyticsModel
  } = data;
  const userAggregatedData = aggregateUserData({ userList, variant: 'liveprogram' });
  const firstCreditDetailsFormatted = (creditDetailsFormatted || [])[0];
  const formats = _formats?.map(f => (typeof f === 'object' ? f.displayName! : f));

  const metadata: SnippetMetadataProps = {
    items: []
  };

  if (firstCreditDetailsFormatted) {
    metadata.items.push(
      getViewCreditDetailsButton(
        context,
        variationPk ?? '',
        firstCreditDetailsFormatted.keyword ?? '',
        itemAnalyticsModel
      )
    );
  }

  const { registrationDetails = [], attendanceFormats } = userAggregatedData || {};

  if (!isLive) {
    const chosenOneLocation = _locations?.find(
      location =>
        ('variationPK' in location && location['variationPK'] === variationPk) ||
        (context == 'library' && location.pk === variationPk)
    );

    if (chosenOneLocation?.startTimeUtc && chosenOneLocation?.endTimeUtc) {
      const label = getLiveProgramRuntime(
        chosenOneLocation.startTimeUtc,
        chosenOneLocation.endTimeUtc
      );
      metadata.items.push(label);
    } else if (runTimeSeconds) {
      if (runTimeSeconds >= 3600) {
        metadata.items.push(`${Math.round(runTimeSeconds / 3600)}-hour program`);
      } else {
        metadata.items.push(`${Math.floor(runTimeSeconds / 60)}-minute program`);
      }
    }
  }

  if (formats?.length || attendanceFormats?.length) {
    const attendanceInfo = getAttendanceInfo(formats ?? [], attendanceFormats ?? []);
    if (attendanceInfo) {
      metadata.items.push(attendanceInfo);
    }
  }

  const userLocations = [
    ...new Map(
      registrationDetails?.map(registrationItem => [
        `${registrationItem.startDateUtc}${registrationItem.endDateUtc}`,
        registrationItem
      ])
    ).values()
  ];

  const locations = _locations
    ?.filter(location => location.name!)
    .map(location => ({
      name: location.name,
      date: getFormattedLocationDates(location)
    }));

  const isRegistrationAvailable = getIsRegistrationAvailable(_locations);
  const filteredOutLocationPlaces = [...new Set(locations?.map(location => location.name))];
  const filteredOutLocationDates = [...new Set(locations?.map(location => location.date))];

  if (userLocations.length) {
    metadata.items.push([
      `${userLocations[0].location}`,
      userLocations.length > 1 ? ` + ${userLocations.length - 1} more` : ''
    ]);
  } else if (filteredOutLocationPlaces?.length) {
    metadata.items.push([
      `${filteredOutLocationPlaces[0]}`,
      filteredOutLocationPlaces.length > 1 ? ` + ${filteredOutLocationPlaces.length - 1} more` : ''
    ]);
  }

  const userRegistrationDates = [
    ...new Map(
      registrationDetails
        .filter(registrationItem => registrationItem.location!)
        .map(registrationItem => [registrationItem.location, registrationItem])
    ).values()
  ].map(registrationItem => {
    return getFormattedLocationDates({
      startTimeUtc: registrationItem.startDateUtc,
      endTimeUtc: registrationItem.endDateUtc,
      timezoneIdentifier: registrationItem.timezoneIdentifier
    });
  });

  if (userRegistrationDates?.length) {
    const userRegistrationDate = userRegistrationDates[0];
    metadata.items.push([
      `${userRegistrationDate}`,
      userRegistrationDates?.length > 1 ? ` + ${userRegistrationDates.length - 1} more` : ''
    ]);
  } else if (filteredOutLocationDates?.length) {
    metadata.items.push([
      `${filteredOutLocationDates[0]}`,
      filteredOutLocationDates.length > 1 ? ` + ${filteredOutLocationDates.length - 1} more` : ''
    ]);
  }

  const expandedContent: SnippetExpandedContentProps = { data: [], expandLink: url || '' };

  if (summaryText) {
    expandedContent.data.push({
      title: 'Overview',
      content: summaryText
    });
  }

  const filteredOutLocations = [
    ...new Map(locations?.map(l => [`${l.name}${l.date}`, l])).values()
  ];

  if (locations?.length) {
    expandedContent.data.push({
      title: 'Dates',
      list: filteredOutLocations.map(location => (
        <SnippetMetadata items={[location?.date, location?.name]} />
      ))
    });
  }

  if (practiceAreas?.length) {
    expandedContent.data.push({
      title: 'Topics',
      inlineList: practiceAreas.map(practiceArea => (
        /* Ideally, if href is empty, the link should be omitted */
        <Link {...getInlineListProps(practiceArea)} />
      ))
    });
  }

  if (industries?.length) {
    expandedContent.data.push({
      title: 'Industries',
      inlineList: industries.map(industry => (
        /* Ideally, if href is empty, the link should be omitted */
        <Link {...getInlineListProps(industry)} />
      ))
    });
  }

  const onSaveToLibrary = actions.addToLibrary;
  const onRemoveFromLibrary = (e?: MouseEvent<HTMLButtonElement>) => {
    const siblingVariantPks =
      context === 'search'
        ? userList?.filter(getIsSavedToLibrary).map(u => u.pk ?? '')
        : [variationPk ?? ''];
    actions.removeFromLibrary.bind(e, siblingVariantPks)();
  };

  let userPrimaryStatus: SnippetPrimaryStatus | null = 'not-registered';

  if (
    !userAggregatedData?.isPurchaseRequired &&
    registrationDetails &&
    registrationDetails.length > 0
  ) {
    userPrimaryStatus = 'registered';
  }

  let primaryCtaLabel = 'Register';
  let primaryCtaIcon = 'chevron-right';
  let primaryCallback:
    | ((e?: MouseEvent<HTMLButtonElement>) => void)
    | ((pk: string) => void)
    | null = actions.register;
  let primaryCtaWrapper: SnippetButtonProps['renderButtonWrapper'] = renderEcommPanelButtonWrapper(
    context,
    data,
    'Register'
  );
  let secondaryCtaLabel = SnippetButtonLabel.saveToLibrary;
  let secondaryIcon = 'add';
  let secondaryCallback = onSaveToLibrary;
  let secondaryCtaWrapper = undefined;

  if (isLive) {
    if (!userAggregatedData?.isPurchaseRequired) {
      primaryCtaLabel = 'Join now';
      primaryCtaIcon = 'play';
      primaryCallback = actions.launch;
      primaryCtaWrapper = hasMultipleRecordings(userList)
        ? renderEcommPanelButtonWrapper(context, data, 'StartWatching')
        : undefined;
    } else {
      primaryCtaLabel = SnippetButtonLabel.buyNow;
      primaryCtaIcon = 'chevron-right';
      primaryCallback = actions.buy;
      primaryCtaWrapper = renderEcommPanelButtonWrapper(context, data, 'BuyNow');
    }
    if (userAggregatedData?.isSavedToLibrary) {
      secondaryCtaLabel = SnippetButtonLabel.savedToLibrary;
      secondaryIcon = 'check';
      secondaryCallback =
        context === 'search'
          ? onRemoveFromLibrary
          : () => {
              modalPanelState.deleteModal.show({ onConfirm: onRemoveFromLibrary });
            };
    }
  } else {
    if (!userAggregatedData?.isPurchaseRequired && registrationDetails.length) {
      primaryCtaLabel = 'View registration';
      primaryCtaIcon = 'chevron-right';
      primaryCallback = actions.viewRegistration;
      primaryCtaWrapper = renderEcommPanelButtonWrapper(context, data, 'ViewRegistration');
      secondaryCtaLabel = 'Add to calendar';
      secondaryIcon = 'calendar';
      secondaryCallback = actions.addToCalendar;
      secondaryCtaWrapper = renderAddToCalendarButtonWrapper(context, variationPk ?? '');
    } else if (userAggregatedData?.isSavedToLibrary) {
      secondaryCtaLabel = SnippetButtonLabel.savedToLibrary;
      secondaryIcon = 'check';
      secondaryCallback =
        context === 'search'
          ? onRemoveFromLibrary
          : () => {
              modalPanelState.deleteModal.show({ onConfirm: onRemoveFromLibrary });
            };
    }
  }

  const buttons: SnippetButtonProps[] = [
    {
      color: isLive ? 'gradient' : 'black',
      label: primaryCtaLabel,
      /* @ts-expect-error the type error should be fixed when the gate is removed */
      onClick: primaryCallback,
      renderButtonWrapper: primaryCtaWrapper,
      size: 'short',
      iconRight: primaryCtaIcon
    }
  ];

  if (context === 'search') {
    buttons.push({
      color: 'outline-black',
      label: secondaryCtaLabel,
      onClick: secondaryCallback,
      actionStatus,
      size: 'short',
      iconRight: secondaryIcon,
      renderButtonWrapper: secondaryCtaWrapper
    });
  }

  if (context === 'library') {
    if (registrationDetails.length) {
      if (!userAggregatedData?.isPurchaseRequired) {
        buttons.push(
          getAccessMaterialsButton(context, variationPk ?? '', title ?? '', actions.accessMaterials)
        );
      }
      buttons.push({
        label: 'See full schedule',
        color: 'outline-black',
        onClick: actions.seeSchedule,
        size: 'short',
        iconRight: 'calendar'
      });
      if (!isLive) {
        buttons.push({
          label: SnippetButtonLabel.addToCalendar,
          color: 'outline-black',
          onClick: actions.addToCalendar,
          renderButtonWrapper: renderAddToCalendarButtonWrapper(context, variationPk ?? ''),
          size: 'short',
          iconRight: 'calendar'
        });
      }
    } else if (!isRegistrationAvailable) {
      buttons.splice(
        buttons.findIndex(button => button.label === 'Register'),
        1
      );
      primaryCallback = null;
      primaryCtaWrapper = () => <></>;
    }
  }

  const menuItems = [];

  if (context === 'library') {
    if (userAggregatedData?.registrationDetails.length) {
      menuItems.push({
        label: SnippetButtonLabel.changeOrCancelRegistration,
        icon: 'global',
        key: 'chanceOrCancel',
        onClick: actions.changeOrCancelRegistration,
        menuStyle: 'standalone' as const
      });
    }
    menuItems.push({
      label: SnippetButtonLabel.removeFromLibrary,
      onClick: () => {
        modalPanelState.deleteModal.show({ onConfirm: onRemoveFromLibrary });
      },
      icon: 'delete',
      menuStyle: 'standalone' as const
    });
  }

  const thumbnail = getLiveProgramThumbnail({
    registrationDetails,
    title,
    isLive,
    locations: _locations, // pass correct untreated user locations or locations
    variationPk,
    isPurchaseRequired: userAggregatedData?.isPurchaseRequired ?? true,
    streamingUrl,
    /* @ts-expect-error the type error should be fixed when the gate is removed */
    action: primaryCallback,
    renderButtonWrapper: primaryCtaWrapper
  });

  return {
    variant: isLive ? 'live' : ('upcoming-live' as const),
    pk: variationPk ?? '',
    title: title ?? '',
    metadata,
    expandedContent,
    thumbnail,
    link: url || '',
    buttons,
    menuItems,
    primaryStatus: userPrimaryStatus,
    secondaryStatus: userAggregatedData?.isSavedToLibrary ? 'saved-to-library' : null
  };
};

export default renderLiveProgram;
