import { useEffect, useMemo } from 'react';
import { useGetUserIndustriesQuery } from '@/redux/api/client/industries';
import { PersonalInfoIndustriesBlock as PersonalInfoIndustriesProps } from '@/@types/content';
import { getProfileLinkInfo } from '@/utils/getProfileLinkInfo';
import LinkGridBlock from '@/components/blocks/LinkGridBlock/LinkGridBlock';
import LinkGridShimmer from '@/components/ui/ProfileShimmers/LinkGridShimmer';

import ProfileSectionWrapper from './ProfileSectionWrapper';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import useToast from '@/hooks/useToast';
import useNudge from '@/hooks/shared/useNudge';
import { IComponentProps } from '@/lib/ComponentFactory';
import { NudgeAnalyticsContext } from '@/analytics/constants';
import { trackViewIndustryEvent } from '@/analytics/profile';
import { ProfileNudgeSectionNames } from '@/hooks/shared/useNudge/utils';

const PersonalInfoIndustriesSection: React.FC<PersonalInfoIndustriesProps & IComponentProps> = ({
  messages,
  timeUntilStale,
  index,
  ...props
}) => {
  const { data, isError, isLoading } = useGetUserIndustriesQuery();
  const industriesData = useMemo(() => getProfileLinkInfo(data), [data]);
  const debouncedLoading = useDebounceLoading(isLoading);
  const { showFailMessage } = useToast();

  useEffect(() => {
    if (isError) {
      showFailMessage('Something went wrong while loading the industries you follow');
    }
  }, [isError, showFailMessage]);

  const nudge = useNudge({
    messagesJSON: messages,
    sectionData: data,
    sectionName: ProfileNudgeSectionNames.Industries,
    context: NudgeAnalyticsContext.Industry,
    sectionIndex: index,
    timeUntilStale
  });

  if (isError) {
    return null;
  }

  if (debouncedLoading) return <LinkGridShimmer />;

  return (
    <ProfileSectionWrapper>
      <LinkGridBlock
        {...industriesData}
        {...props}
        elementType="h3"
        nudge={nudge}
        onTrackItemViewEvent={trackViewIndustryEvent}
      />
    </ProfileSectionWrapper>
  );
};

export default PersonalInfoIndustriesSection;
