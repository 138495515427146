export const processDownloadedFile = async (fileName: string, blob: Blob) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);

  if (fileName) link.download = fileName;

  link.setAttribute('type', 'hidden');
  document.body.appendChild(link);
  link.click();
  link.remove();
  return;
};
