import { useEffect, useMemo, useState } from 'react';
import ProgramSegments from '@/components/ui/PDP/ProgramSegments';
import { buildProgramSegmentUserData } from '@/components/ui/PDP/helpers';
import { PDPSectionLabels, ProgramSegmentLabels } from '@/components/ui/PDP/labels';
import {
  OnDemandSegmentCatalogRelations,
  WebSegmentVariation
} from '@/hooks/PDP/useProgramCatalogRelations';
import useRelevantCredits from '@/hooks/PDP/useRelevantCredits';
import {
  markComponentAsLoaded,
  registerAsyncComponent,
  registerSegmentsSection,
  Variant
} from '@/redux/slices/pdpSlice';
import { useDispatch } from 'react-redux';
import { LoggedOutLicenseStruct } from '@/hooks/PDP/useLoggedOutLicenses';
import { useGetLicensesQuery } from '@/redux/api/client/userLicenses';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAuth } from 'react-oidc-context';
import { AnalyticsContext } from '@/analytics/constants';
import { useLibraryItems } from '@/hooks/PDP/useLibraryItems';

interface IPDPProgramSegmentsLayoutBlockProps {
  variant: Variant & { segments: WebSegmentVariation[] };
  catalogRelations: OnDemandSegmentCatalogRelations;
  loadingCatalogRelations?: boolean;
  loggedOutLicense: LoggedOutLicenseStruct;
  creditTrackerCode?: string;
}

const IPDPProgramSegmentsLayoutBlock: React.FC<IPDPProgramSegmentsLayoutBlockProps> = ({
  variant,
  catalogRelations,
  loggedOutLicense,
  creditTrackerCode
}) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [registered, setRegistered] = useState(false);

  const isSegment = 'onDemandType' in variant && variant.onDemandType === 1;
  const { segments } = variant;
  // Segment source property is different for Segment vs Program PDP
  const actualSegments = useMemo(
    () =>
      (isSegment
        ? (catalogRelations as OnDemandSegmentCatalogRelations)?.webSegmentsFromProgram
        : segments) ?? [],
    [catalogRelations, isSegment, segments]
  );

  const { data: licenses } = useGetLicensesQuery(!auth.isAuthenticated ? skipToken : undefined);

  const { relevantCredits, isLoading } = useRelevantCredits(
    actualSegments?.map(s => s.code ?? ''),
    auth.isAuthenticated
      ? licenses?.items?.map(l => l.creditRegion ?? '')
      : loggedOutLicense.relevantCreditsRegionParam
  );

  useEffect(() => {
    if (!registered) {
      dispatch(registerAsyncComponent());
      setRegistered(true);
    }

    if (!isLoading) {
      dispatch(markComponentAsLoaded());
    }
  }, [dispatch, isLoading, registered]);

  useEffect(() => {
    if (actualSegments?.length > 1) {
      dispatch(registerSegmentsSection());
    }
  }, [actualSegments?.length, dispatch]);

  // By excluding currentVariant.code when determing segments to hydrate(fetch) in Segment component, knowing that parent PDP page has already hydrated the currentVariant in redux LibraryItem store,
  // we can avoid some extraneous shimmers and refetches while still having correct/updated LibraryItem state.
  const segmentPksToRender = useMemo(() => actualSegments?.map(s => s.code), [actualSegments]);
  const segmentPksToHydrate = useMemo(
    () => segmentPksToRender.filter(pk => pk !== variant.code),
    [segmentPksToRender, variant.code]
  );

  const { hydrateLibrary, selectLibraryItems } = useLibraryItems();

  useEffect(() => {
    hydrateLibrary(segmentPksToHydrate);
  }, [hydrateLibrary, segmentPksToHydrate]);

  const segmentLibraryData = selectLibraryItems(segmentPksToRender);

  if (actualSegments?.length < 2) return null;

  const segmentUserData = actualSegments
    ? buildProgramSegmentUserData(actualSegments, segmentLibraryData, relevantCredits, isLoading)
    : undefined;

  if (!actualSegments || !segmentUserData) return null;
  return (
    <ProgramSegments
      labels={ProgramSegmentLabels}
      heading={PDPSectionLabels.segments}
      segments={actualSegments}
      segmentUserData={segmentUserData}
      context={AnalyticsContext.PDP}
      loggedOutLicense={loggedOutLicense}
      creditTrackerCode={creditTrackerCode}
    />
  );
};

export default IPDPProgramSegmentsLayoutBlock;
