import { ReferencePageSettings } from '@/@types/content';
import withNav from '@/components/hocs/withNav';
import { useGetGlobalElementQuery } from '@/redux/api/contentDeliveryAPI';
import { RootState } from '@/redux/store';
import PageLoading from '@/routes/PageLoading';
import { transformIfPLIUrl } from '@/utils/helpers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const IndividualMembershipVariationComponent = () => {
  const authReady = useSelector((state: RootState) => state.auth.ready);

  const { data: labelsData } = useGetGlobalElementQuery(
    { name: 'labels', userId: 'ANON' },
    { skip: !authReady }
  );

  const navigate = useNavigate();

  useEffect(() => {
    const membershipProductPageUrl = (labelsData as ReferencePageSettings)
      ?.individualMembershipProductPage?.url;
    if (membershipProductPageUrl) navigate(transformIfPLIUrl(membershipProductPageUrl)!);
  }, [labelsData, navigate]);

  return <PageLoading />;
};

const IndividualMembershipSubscriptionVariation = withNav(IndividualMembershipVariationComponent);
export default IndividualMembershipSubscriptionVariation;
