import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import ScrollArea from '../ScrollArea';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { PublicationVariation } from '@/hooks/PDP/usePublicationCatalogRelations';
import { PDPBottomDividerId } from './PDPFooter';

interface JumpLinksProps {
  show: boolean;
  hasTestimonials: boolean;
  variant: PublicationVariation;
}

const JumpLinks: React.FC<JumpLinksProps> = ({ show, variant, hasTestimonials }) => {
  const offset = 95;
  const containerRef = useRef<HTMLDivElement>(null);
  const linkListRef = useRef<HTMLUListElement>(null);
  const underlineRef = useRef<HTMLSpanElement>(null);
  const [activeLink, setActiveLink] = useState('overview');
  const stickyHeader = useSelector((state: RootState) => state.page.stickyHeader);
  const { loadedComponents, totalComponents } = useSelector((state: RootState) => state.pdp);

  const hasTableOfContent = JSON.parse(variant?.sortedTableOfContentListJsonString || '[]');

  const jumplinks = useMemo(() => {
    const modules: Array<{ id: string; label: string }> = [
      {
        id: 'overview',
        label: 'Overview'
      },
      {
        id: 'details',
        label: 'Details'
      },
      ...(hasTableOfContent && hasTableOfContent.length > 0
        ? [
            {
              id: 'content-table',
              label: 'Contents'
            }
          ]
        : []),
      ...(variant?.author && variant?.author.length > 0
        ? [
            {
              id: 'authors',
              label: 'Authors'
            }
          ]
        : []),
      ...(hasTestimonials
        ? [
            {
              id: 'testimonials',
              label: 'Testimonials'
            }
          ]
        : [])
    ];
    return modules;
  }, [variant, hasTestimonials, hasTableOfContent]);

  const handleClick = useCallback(
    (target: string) => {
      const el = document.getElementById(target);

      if (el) {
        const headerHeight = el.getBoundingClientRect().y < offset ? stickyHeader.headerHeight : 0;
        const y = el.getBoundingClientRect().top + window.scrollY - (offset + headerHeight);

        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });

        history.replaceState(null, '', `#${target}`);
      }
    },
    [stickyHeader]
  );

  useEffect(() => {
    const listElement = linkListRef.current;
    const activeElement = listElement?.querySelector('a[data-active=true');
    const parentElement = listElement?.parentElement;
    const line = underlineRef.current;

    if (parentElement && activeElement && line) {
      const left =
        activeElement.getBoundingClientRect().left - parentElement.getBoundingClientRect().left;
      const width = activeElement.getBoundingClientRect().width;

      line.style.transform = `translateX(${left}px)`;
      line.style.width = `${width}px`;
    }
  }, [activeLink]);

  useEffect(() => {
    const updateActiveJumpLink = () => {
      if (!linkListRef.current) return;

      const jumpLinksSections = jumplinks.map(jumplink => document.getElementById(jumplink.id));

      jumpLinksSections.forEach(section => {
        if (!section) return;

        const headerHeight = stickyHeader.isSticky ? stickyHeader.headerHeight : 0;

        if (section.getBoundingClientRect().y < offset + headerHeight + 20) {
          setActiveLink(section.id);
        }
      });
    };

    document.addEventListener('scroll', updateActiveJumpLink);

    return () => {
      document.removeEventListener('scroll', updateActiveJumpLink);
    };
  }, [jumplinks, offset, stickyHeader]);

  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    const hideJumpLinkBelowDivider = () => {
      const divider = document.getElementById(PDPBottomDividerId);
      if (!divider) return;
      const dividerY = divider.getBoundingClientRect()?.y;

      const headerHeight = stickyHeader.isSticky ? stickyHeader.headerHeight : 0;

      // extra offset to avoid jumpiness when scrolling up past divider and both site nav + jumplinks re-appear
      if (dividerY < offset + headerHeight + 160) {
        setHidden(true);
      } else {
        setHidden(false);
      }
    };

    document.addEventListener('scroll', hideJumpLinkBelowDivider);
    return () => {
      document.removeEventListener('scroll', hideJumpLinkBelowDivider);
    };
  }, [stickyHeader.headerHeight, stickyHeader.isSticky]);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    if (stickyHeader.isSticky) {
      container.style.paddingTop = `${stickyHeader.headerHeight}px`;
    } else {
      container.style.paddingTop = '0';
    }
  }, [stickyHeader]);

  useEffect(() => {
    if (loadedComponents === totalComponents && totalComponents > 0) {
      // All components are fully loaded!
      const hash = window.location.hash;
      if (hash) {
        const target = hash.replace('#', '');
        handleClick(target);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedComponents, totalComponents]);

  return (
    <div
      ref={containerRef}
      data-component={"JumpLinks"}
      className={classnames('fixed inset-x-0 top-0 z-30 transition-all', {
        'invisible opacity-0': !show || hidden,
        visible: show
      })}
    >
      <div className="container">
        <div className="pdp-jumplinks relative flex">
          <ScrollArea orientation="horizontal" className="flex-1" barClassName="hidden">
            <div className="relative shrink-0">
              <ul className="flex gap-6 lg:gap-8" ref={linkListRef}>
                {jumplinks.map(jumplink => (
                  <li key={jumplink.id}>
                    <a
                      href={`#${jumplink.id}`}
                      className="text-1-fixed inline-block !cursor-pointer whitespace-nowrap py-6 font-bold data-[active=true]:pointer-events-none data-[active=true]:text-red lg:py-[38px]"
                      data-active={activeLink === jumplink.id}
                      onClick={e => {
                        e.preventDefault();
                        handleClick(jumplink.id);
                      }}
                    >
                      {jumplink.label}
                    </a>
                  </li>
                ))}
              </ul>
              <span
                className="absolute bottom-0 left-0 h-1 bg-red transition-all"
                ref={underlineRef}
              ></span>
            </div>
          </ScrollArea>
        </div>
      </div>
      <div className="pdp-jumplinks-bg"></div>
    </div>
  );
};

export default JumpLinks;
