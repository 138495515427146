import { useLazyGetItemAnalyticsModelByItemPksQuery } from "@/redux/api/client/itemAnalyticsModel";
import { useCallback } from "react";

interface GetAnalyticsItemsOptions {
  populatePrice?: boolean;
  limitPriceCalls?: boolean;
}

export const useFetchAnalyticsItems = () => {
  const [fetchItems] = useLazyGetItemAnalyticsModelByItemPksQuery();

  const getAnalyticsItems = useCallback(
    async (
      contentCodes: string[], 
      options: GetAnalyticsItemsOptions = { populatePrice: true, limitPriceCalls: true }
    ) => {
      try {
        const { data } = await fetchItems({
          itemPks: contentCodes,
          populatePrice: options.populatePrice ?? true,
          limitPriceCalls: options.limitPriceCalls ?? true,
        });

        return data ?? undefined;
      } catch (error) {
        console.error("Error fetching analytics items:", error);
        throw error; 
      }
    },
    [fetchItems]
  );

  const getAnalyticItem = useCallback(
    async (contentCode: string) => {
      try {
        const items = await getAnalyticsItems([contentCode], {
          populatePrice: true,
          limitPriceCalls: true,
        });

        return items ? items[0] : undefined;
      } catch (error) {
        console.error("Error fetching single analytic item:", error);
        throw error;
      }
    },
    [getAnalyticsItems]
  );

  return { getAnalyticsItems, getAnalyticItem };
};
