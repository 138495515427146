import { ClientAPI } from '@/redux/api';
import { UserPracticeAreaResponse } from '@/@types/client-api';

const userPracticeAreasEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getUserPracticeAreas: builder.query<UserPracticeAreaResponse, void>({
      query: () => ({
        url: '/user/practice-areas',
        method: 'GET'
      }),
      providesTags: [{ type: 'Library', id: 'UserPracticeAreas' }]
    }),
    followPracticeAreas: builder.mutation<void, string[]>({
      query: body => ({
        url: `/user/practice-areas/follow`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Library', id: 'UserPracticeAreas' }]
    }),
    unfollowPracticeAreas: builder.mutation<void, string[]>({
      query: body => ({
        url: `/user/practice-areas/unfollow`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Library', id: 'UserPracticeAreas' }]
    }),
    followPracticeAreasByCategoryId: builder.mutation<void, string[]>({
      query: body => ({
        url: `/user/practice-areas/by-categoryid/follow`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Library', id: 'UserPracticeAreas' }]
    }),
    unfollowPracticeAreasByCategoryId: builder.mutation<void, string[]>({
      query: body => ({
        url: `/user/practice-areas/by-categoryid/unfollow`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Library', id: 'UserPracticeAreas' }]
    })
  })
});

export const {
  useGetUserPracticeAreasQuery,
  useFollowPracticeAreasMutation,
  useUnfollowPracticeAreasMutation,
  useFollowPracticeAreasByCategoryIdMutation,
  useUnfollowPracticeAreasByCategoryIdMutation
} = userPracticeAreasEndpoints;

export default userPracticeAreasEndpoints;
