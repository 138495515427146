import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import PracticeAreaModal from '../SearchModals/PracticeAreaModal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SelectPracticeAreaModal from './SelectPracticeAreaModal';
import useUnfoldedFilterGroup from '../SearchResults/hooks/useUnfoldedFilterGroup';
import { FILTERS } from '../SearchResults/constants';
import { selectFindAProgramRequestResponse } from '@/redux/selectors/findAProgramSelectors';
import { useDispatch } from 'react-redux';
import {
  clearFindAProgramFilters,
  toggleFindAProgramFilter
} from '@/redux/slices/findAProgramSlice';
import { translateStoreFilterToComponentProps } from '../SearchResults/utils/translateStoreDataToComponentsProps';
import { SelectorItem } from '../Filter/types';
import { SelectItem } from './SelectAddToProfileModal';
import useToast from '@/hooks/useToast';
import { useFollowPracticeAreasByCategoryIdMutation } from '@/redux/api/client/practiceAreas';
import { getFollowingPracticeAreasMessage } from './utils/practice-areas';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import { FilterContext, SearchContext } from '@/analytics/constants';

enum Step {
  selectPracticeAreas = 'selectPracticeAreas',
  followPracticeAreas = 'followPracticeAreas'
}

type AddPracticeAreaFlowProps = ModalProps & {
  fetching?: boolean;
  triggerButton: JSX.Element | null;
  context?: FilterContext;
  searchContext?: SearchContext;
};

const AddPracticeAreaFlow: React.FC<AddPracticeAreaFlowProps> = ({
  open,
  setOpen,
  fetching,
  triggerButton,
  context,
  searchContext
}) => {
  const [step, setStep] = useState<Step>(Step.selectPracticeAreas);
  const [practiceAreasAlreadyRequestedToFollow, setPracticeAreasAlreadyRequestedToFollow] =
    useState<string[]>([]);

  const [followPracticeAreas] = useFollowPracticeAreasByCategoryIdMutation();

  const dispatch = useDispatch();

  const { showSuccessMessage, showFailMessage } = useToast();

  const { filterGroup, isLoading } = useUnfoldedFilterGroup({
    groupId: FILTERS.PRACTICE_AREA,
    dataSelector: selectFindAProgramRequestResponse,
    skip: !open
  });

  const debouncedLoading = useDebounceLoading(isLoading);

  //This useEffect is necessary to reset the steps
  useEffect(() => {
    if (open) {
      setStep(Step.selectPracticeAreas);
    }
  }, [open]);

  const selectPracticeAreasItems = useMemo((): SelectorItem[] => {
    if (!filterGroup) {
      return [];
    }
    const mappedFilterGroup = translateStoreFilterToComponentProps(filterGroup);
    return mappedFilterGroup.items;
  }, [filterGroup]);

  const followPracticeAreasItems = useMemo((): SelectItem[] => {
    return selectPracticeAreasItems
      .filter(
        item =>
          !!item.checked &&
          !filterGroup?.facets?.find(facet => facet.value === item.value)?.isFollowedByUser &&
          !practiceAreasAlreadyRequestedToFollow.includes(item.value)
      )
      .map(item => ({ id: item.value, label: item.label }));
  }, [filterGroup, practiceAreasAlreadyRequestedToFollow, selectPracticeAreasItems]);

  const handleSelectPracticeAreasOnSave = useCallback(() => {
    if (followPracticeAreasItems.length === 0) {
      setOpen(false);
      return;
    }
    setStep(Step.followPracticeAreas);
  }, [followPracticeAreasItems, setOpen]);

  const handleClearAllFilters = useCallback(() => {
    dispatch(clearFindAProgramFilters({ groupId: FILTERS.PRACTICE_AREA, context, searchContext }));
  }, [context, dispatch, searchContext]);

  const handleOnValueChanged = useCallback(
    (value?: string) => {
      const item = selectPracticeAreasItems.find(item => item.value === value);
      if (value && item) {
        dispatch(
          toggleFindAProgramFilter({
            groupTypeId: FILTERS.PRACTICE_AREA,
            value,
            name: item.label,
            context,
            searchContext
          })
        );
      }
    },
    [context, dispatch, searchContext, selectPracticeAreasItems]
  );

  const handleAddPracticeAreasRequestedToFollow = useCallback(() => {
    setPracticeAreasAlreadyRequestedToFollow(state => [
      ...state,
      ...followPracticeAreasItems.map(item => item.id)
    ]);
  }, [followPracticeAreasItems]);

  const handleOnSaveFollowPracticeAreas = useCallback(
    async (selectedItems: SelectItem[]) => {
      handleAddPracticeAreasRequestedToFollow();
      if (selectedItems.length === 0) {
        return;
      }
      try {
        const selectedIds = selectedItems.map(item => item.id);
        await followPracticeAreas(selectedIds).unwrap();
        showSuccessMessage(
          getFollowingPracticeAreasMessage(
            followPracticeAreasItems
              .filter(item => selectedIds.includes(item.id))
              .map(item => item.label)
          )
        );
      } catch (error) {
        showFailMessage('Something went wrong');
      }
    },
    [
      followPracticeAreas,
      followPracticeAreasItems,
      handleAddPracticeAreasRequestedToFollow,
      showFailMessage,
      showSuccessMessage
    ]
  );

  const handleBackPress = useCallback(() => {
    setStep(Step.selectPracticeAreas);
  }, []);

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Trigger asChild>{triggerButton}</Modal.Trigger>
      {step === Step.selectPracticeAreas && (
        <PracticeAreaModal
          items={selectPracticeAreasItems}
          onSave={handleSelectPracticeAreasOnSave}
          onClearAllFilters={handleClearAllFilters}
          onValueChanged={handleOnValueChanged}
          isLoading={debouncedLoading}
          fetching={fetching}
        />
      )}
      {step === Step.followPracticeAreas && (
        <SelectPracticeAreaModal
          items={followPracticeAreasItems}
          onSave={handleOnSaveFollowPracticeAreas}
          onBack={handleBackPress}
          onDismissButtonPress={handleAddPracticeAreasRequestedToFollow}
          onClose={handleAddPracticeAreasRequestedToFollow}
        />
      )}
    </Modal>
  );
};

export default AddPracticeAreaFlow;
