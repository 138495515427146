import CreditCardBrandAndNumber, {
  CreditCardBrandAndNumberProps
} from './CreditCardBrandAndNumber';
import CreditCardDropDownMenu, { CreditCardDropDownMenuProps } from './CreditCardDropDownMenu';

export type CreditCardHeaderProps = CreditCardBrandAndNumberProps & CreditCardDropDownMenuProps;

const CreditCardHeader = ({
  brandName,
  brandLogo,
  number,
  onSetAsDefaultCardPress,
  onDeletePress,
  isDefaultCard,
  isDeleting
}: CreditCardHeaderProps): JSX.Element => {
  return (
    <div className="flex items-center justify-between">
      <CreditCardBrandAndNumber brandName={brandName} brandLogo={brandLogo} number={number} />
      <CreditCardDropDownMenu
        onSetAsDefaultCardPress={onSetAsDefaultCardPress}
        onDeletePress={onDeletePress}
        isDefaultCard={isDefaultCard}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default CreditCardHeader;
