import { DateTime } from 'luxon';

export const getFormatedDateRange = (timeZone: string, startDate: string, endDate?: string) => {
  const startDateTime = DateTime.fromISO(startDate, { zone: 'UTC' }).setZone(timeZone);
  const endDateTime = endDate
    ? DateTime.fromISO(endDate, { zone: 'UTC' }).setZone(timeZone)
    : undefined;

  // Initialize the formatted date part
  let formattedDate;

  // Check if endDateTime is defined and if it is the same day as startDateTime
  if (endDateTime && startDateTime.hasSame(endDateTime, 'day')) {
    // If the end date is the same day, format only the start date
    formattedDate = startDateTime.toFormat('LLL dd');
  } else if (endDateTime) {
    // If the end date is different, format both dates
    formattedDate = `${startDateTime.toFormat('LLL dd')} – ${endDateTime.toFormat('dd')}`;
  } else {
    // If there is no end date, format only the start date
    formattedDate = startDateTime.toFormat('LLL dd');
  }

  const formattedTime = startDateTime.toFormat('h:mm a');
  const timeZoneAbbreviation = startDateTime.toFormat('ZZZZ');

  // Construct the final output with conditional inclusion of end date
  return `${formattedDate}, ${formattedTime} ${timeZoneAbbreviation}`;
};

export const getFormatedDateRangeParts = (
  timeZone: string,
  startDate: string,
  endDate?: string
) => {
  const startDateTime = DateTime.fromISO(startDate, { zone: 'UTC' }).setZone(timeZone);
  const endDateTime = endDate
    ? DateTime.fromISO(endDate, { zone: 'UTC' }).setZone(timeZone)
    : undefined;

  // Initialize the formatted date part
  let formattedDate;
  let currentDay;

  // Check if endDateTime is defined and if it is the same day as startDateTime
  if (endDateTime && startDateTime.hasSame(endDateTime, 'day')) {
    // If the end date is the same day, format only the start date
    formattedDate = startDateTime.toFormat('LLL dd, yyyy');
    currentDay = undefined;
  } else if (endDateTime) {
    // If the end date is different, format both dates
    // Add year verifying if it's the same year or not
    if (startDateTime.year !== endDateTime.year) {
      formattedDate = `${startDateTime.toFormat('LLL dd, yyyy')} – ${endDateTime.toFormat('LLL dd, yyyy')}`;
    } else {
      formattedDate = `${startDateTime.toFormat('LLL dd')} – ${endDateTime.toFormat('dd, yyyy')}`;
    }
    const today = DateTime.local().setZone(timeZone).startOf('day');
    const startDateDiffDays = today.diff(startDateTime.startOf('day'), 'days').days;
    const endDateDiffDays = today.diff(endDateTime.startOf('day'), 'days').days;
    const isTodayWithinRange = startDateDiffDays >= 0 && endDateDiffDays <= 0;
    currentDay = isTodayWithinRange ? `Day ${startDateDiffDays + 1}` : undefined;
  } else {
    // If there is no end date, format only the start date
    formattedDate = startDateTime.toFormat('LLL dd, yyyy');
    currentDay = undefined;
  }

  const formattedTime = startDateTime.toFormat('h:mm a');
  const timeZoneAbbreviation = startDateTime.toFormat('ZZZZ');

  // Construct the final output with conditional inclusion of end date
  return {
    date: formattedDate,
    time: `${formattedTime} ${timeZoneAbbreviation}`,
    currentDay
  };
};

export const getFormattedYear = (date: string) => {
  // Convert the ISO string to a Luxon DateTime object
  const dateTime = DateTime.fromISO(date, { zone: 'UTC' });
  // Format the date as "Year"
  return dateTime.toFormat('yyyy');
};

export const getFormatedMonthYear = (date: string, includeComma: boolean = true) => {
  // Convert the ISO string to a Luxon DateTime object
  const dateTime = DateTime.fromISO(date, { zone: 'UTC' });
  // Format the date as "Month, Year"
  if (includeComma) return dateTime.toFormat('LLLL, yyyy');
  return dateTime.toFormat('LLLL yyyy');
};

export const getFormattedMonthDayYear = (date: string) => {
  // Convert the ISO string to a Luxon DateTime object
  const dateTime = DateTime.fromISO(date, { zone: 'UTC' });
  // Format the date as "Month Day, Year"
  return dateTime.toFormat('LLLL dd, yyyy');
};

export const getFormatedDateRangeString = (date: string) => {
  // Convert the ISO string to a Luxon DateTime object
  const targetDate = DateTime.fromISO(date, { zone: 'UTC' });
  // Get the current date and time
  const now = DateTime.now();
  // Calculate the difference in years, months, and days
  const diff = targetDate.diff(now, ['years', 'months', 'days']).toObject();

  // Construct a human-readable relative time string
  let years = Math.abs(diff.years || 0);
  const months = Math.abs(diff.months || 0);
  const days = Math.abs(diff.days || 0);
  const label = targetDate > now ? 'away' : 'ago';

  // Increment the years if months are greater than 11
  if (days && months >= 11) years++;

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} ${label}`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ${label}`;
  }

  return `${Math.floor(days)} day${Math.floor(days) > 1 ? 's' : ''} ${label}`;
};

export const getTime = (time: string, timeZone: string) => {
  const dateTime = DateTime.fromISO(time, { zone: 'UTC' }).setZone(timeZone);
  return dateTime.toFormat('h:mm a');
};
