import Text from '@/components/cms/Text';
import CircleButton from '@/components/ui/Buttons/CircleButton';
import Breadcrumbs from '../ui/Breadcrumbs';
import RichText from '../cms/RichText';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import {
  useFollowFacultyMutation,
  useGetUserFacultiesQuery,
  useUnfollowFacultyMutation
} from '@/redux/api/client/faculty';
import useToast from '@/hooks/useToast';
import classnames from 'classnames';
import { FacultyMember as FacultyMemberProps, SiteSettings } from '@/@types/content';
import Block from '../cms/Block';
import AvatarComponent from '../ui/Avatar';
import withNav from '../hocs/withNav';
import { useCallback, useEffect, useState } from 'react';
import { IContent } from '@/@types/cms';
import ContentArea from '../cms/ContentArea';
import { useAuth } from 'react-oidc-context';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { useSearchParamsStable } from '@/hooks/useSearchParams';
import ShareButton from '../ui/Buttons/ShareButton';

type FacultyPageProps = FacultyMemberProps &
  SiteSettings & {
    tooltipInformationFaculty?: Array<IContent> & { expandedValue: IContent[] };
    isUserFollowingThis: boolean;
    sharedContent: { expandedValue: IContent[] };
    trusteeDesignation?: boolean;
  };

const FacultyMemberComponent: React.FC<FacultyPageProps> = ({
  affiliation,
  biography,
  breadcrumbs,
  contentLink,
  displayName,
  image,
  individualId,
  isUserFollowingThis,
  resourceLocation,
  sharedContent,
  title,
  tooltipInformationFaculty,
  trusteeDesignation
}) => {
  const siteLabels = useSelector((state: RootState) => state.page.siteLabels);
  const auth = useAuth();
  const largeScreen = useMediaQuery(ScreenSizeQueries.belowLg);
  const [followFaculty, { isLoading: isLoadingFollow }] = useFollowFacultyMutation();
  const [unfollowFaculty, { isLoading: isLoadingUnfollow }] = useUnfollowFacultyMutation();
  const { data, isLoading } = useGetUserFacultiesQuery(
    !auth.isAuthenticated ? skipToken : undefined
  );
  const { searchParams, setSearchParams } = useSearchParamsStable();
  const { showSuccessMessage, showFailMessage } = useToast();
  const [isFollowing, setIsFollowing] = useState<boolean>(isUserFollowingThis);
  const hasDetails = affiliation || title || resourceLocation;
  let detailsBlock;

  const listItemClasses = classnames('flex flex-col');
  const listItemHeaderClasses = classnames('label text-gray-darker');
  const listItemContentClasses = classnames('text-1 text-charcoal');

  const mainContentClasses = classnames({
    'w-full': !hasDetails,
    'w-full lg:w-[70%]': hasDetails
  });

  useEffect(() => {
    if (data) {
      const following = data?.items?.some(f => f.id === individualId);
      setIsFollowing(following || false);
    }
  }, [data, individualId]);

  useEffect(() => {
    if (searchParams.has('successAction')) {
      const delayedAction = searchParams.get('successAction');

      let response = false;
      if (searchParams.has('response')) {
        response = JSON.parse(searchParams.get('response') ?? 'false');
      }

      if (delayedAction === 'followFaculty' && response) {
        showSuccessMessage(siteLabels?.toastLabels?.facultyToastFollow);
      } else {
        showFailMessage(siteLabels?.toastLabels?.facultyToastError);
      }

      setSearchParams(
        existing => {
          existing.delete('successAction');
          existing.delete('response');
          return existing;
        },
        { replace: true, preventScrollReset: true }
      );
    }
  }, [
    searchParams,
    showSuccessMessage,
    showFailMessage,
    setSearchParams,
    followFaculty,
    siteLabels
  ]);

  const handleFollowFaculty = useCallback(async () => {
    try {
      await followFaculty([individualId as number]).unwrap();
      setIsFollowing(true);
      return showSuccessMessage(siteLabels?.toastLabels?.facultyToastFollow);
    } catch {
      return showFailMessage(siteLabels?.toastLabels?.facultyToastError);
    }
  }, [followFaculty, individualId, showFailMessage, showSuccessMessage, siteLabels]);

  const handleUnfollowFaculty = useCallback(async () => {
    const dataResponse = await unfollowFaculty([individualId as number]);

    if ('error' in dataResponse) {
      return showFailMessage(siteLabels?.toastLabels?.facultyToastError);
    } else {
      setIsFollowing(false);
      return showSuccessMessage(siteLabels?.toastLabels?.facultyToastUnfollow);
    }
  }, [individualId, showFailMessage, showSuccessMessage, unfollowFaculty, siteLabels]);

  const facultyFollowCookie = window.env?.YOU_CAN_FOLLOW_FACULTIES || 'youCanFollowFaculties';

  const facultyTooltipProps = tooltipInformationFaculty?.expandedValue; // tooltipInformationFaculty!.expandedValue;

  const FirstTimeTooltip = (
    <ContentArea
      propertyName="TooltipBlock"
      components={facultyTooltipProps}
      componentsProps={{
        cookieName: facultyFollowCookie,
        asChild: false
      }}
    />
  );

  if (hasDetails) {
    detailsBlock = (
      <div className="mb-10 flex-1">
        <Text element="h3" className="heading-5">
          Details
        </Text>
        <ul className="mt-8 flex flex-col gap-8">
          {trusteeDesignation && (
            <li className={listItemClasses}>
              <Text className={listItemContentClasses}>{trusteeDesignation}</Text>
            </li>
          )}
          {affiliation && (
            <li className={listItemClasses}>
              <Text className={listItemHeaderClasses}>Workplace</Text>
              <Text className={listItemContentClasses}>{affiliation}</Text>
            </li>
          )}
          {title && (
            <li className={listItemClasses}>
              <Text className={listItemHeaderClasses}>Title</Text>
              <Text className={listItemContentClasses}>{title}</Text>
            </li>
          )}
          {location && (
            <li className={listItemClasses}>
              <Text className={listItemHeaderClasses}>Location</Text>
              <Text className={listItemContentClasses}>{resourceLocation}</Text>
            </li>
          )}
        </ul>
      </div>
    );
  }

  return (
    <>
      <section className="max-w-full bg-gray-lightest">
        <Block contentLinkID={contentLink?.id} className="container module-spacing">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <div className="mt-8 flex flex-col lg:flex-row">
            <div
              className={classnames(
                mainContentClasses,
                'flex flex-col gap-2',
                'sm:flex-row sm:gap-8',
                'lg:items-center'
              )}
            >
              <AvatarComponent
                propertyName="BioImage"
                imageUrl={image?.url}
                fullName={displayName!}
                className="w-[80px] sm:w-[150px] lg:w-[200px]"
                imageSizes={{
                  xs: '80px',
                  sm: '150px',
                  lg: '200px'
                }}
              />
              <Text element="h1" className="heading-1 flex-1 sm:self-center">
                {displayName}
              </Text>
            </div>
            <div
              className={classnames(
                'mt-6 flex flex-1 justify-end gap-8 self-start',
                'lg:mt-0 lg:self-end'
              )}
            >
              <ShareButton
                shareUrl={window.location.href}
                title={siteLabels?.shareLabel}
                buttonClassname={'!py-0'}
              />

              <div
                className={classnames(
                  'relative',
                  'pointer-events-none [&>section]:absolute [&>section]:left-1/2 [&>section]:top-1/2 [&>section]:size-full',
                  '[&>section]:sm:left-full [&>section]:sm:top-1',
                  '[&>section]:lg:left-0 [&>section]:lg:top-0',
                  '[&>button]:pointer-events-auto'
                )}
              >
                {FirstTimeTooltip}

                {!isLoading &&
                  (isFollowing ? (
                    <CircleButton
                      label={siteLabels?.unfollowLabel}
                      icon="minus"
                      onClick={handleUnfollowFaculty}
                      disabled={isLoadingUnfollow}
                    />
                  ) : (
                    <CircleButton
                      label={siteLabels?.followLabel}
                      icon="add"
                      onClick={handleFollowFaculty}
                      disabled={isLoadingFollow}
                    />
                  ))}
              </div>
            </div>
          </div>
        </Block>
      </section>
      <section
        className={classnames('container my-12', 'lg:my-16 lg:flex lg:justify-between lg:gap-16')}
      >
        {largeScreen && detailsBlock}
        <div className={mainContentClasses}>
          <Text element="h2" className="heading-5 mb-8">
            Biography
          </Text>
          {biography && <RichText content={biography} />}
        </div>
        {!largeScreen && detailsBlock}
      </section>
      <section>
        {sharedContent?.expandedValue && (
          <ContentArea
            components={sharedContent?.expandedValue}
            componentsProps={{
              useQueryWhenPresent: true,
              modules: sharedContent?.expandedValue
            }}
            propertyName="sharedContent"
          />
        )}
      </section>
    </>
  );
};

const FacultyMember = withNav(FacultyMemberComponent);
export default FacultyMember;
