import { ClientAPI } from '@/redux/api';
import { AddressResponse, Address } from '@/@types/client-api';

const userAddresesEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getUserAddresses: builder.query<AddressResponse, void>({
      query: () => ({
        url: '/user/addresses',
        method: 'GET'
      })
    }),
    postAddress: builder.mutation<void, Address>({
      query: body => ({
        url: '/user/addresses',
        method: 'POST',
        body
      })
    }),
    updateAddress: builder.mutation<void, Address>({
      query: body => ({
        url: '/user/addresses',
        method: 'PUT',
        body
      })
    }),
    updateDefaultBillingAddress: builder.mutation<void, Pick<Address, 'sk'>>({
      query: ({ sk }) => ({
        url: `/user/addresses/${sk}/set-as-default-billing`,
        method: 'PUT'
      })
    }),
    updateDefaultShippingAddress: builder.mutation<void, Pick<Address, 'sk'>>({
      query: ({ sk }) => ({
        url: `/user/addresses/${sk}/set-as-default-shipping`,
        method: 'PUT'
      })
    }),
    deleteAddress: builder.mutation<void, Pick<Address, 'sk'>>({
      query: ({ sk }) => ({
        url: `/user/addresses/${sk}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetUserAddressesQuery,
  usePostAddressMutation,
  useUpdateAddressMutation,
  useUpdateDefaultBillingAddressMutation,
  useUpdateDefaultShippingAddressMutation,
  useDeleteAddressMutation
} = userAddresesEndpoints;

export default userAddresesEndpoints;
