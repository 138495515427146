import Modal from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';
import SelectorButton from '@/components/ui/Buttons/SelectorButton';
import { useCallback, useState } from 'react';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { trackShareEvent } from '@/analytics/share';
import { AnalyticsContext } from '@/analytics/constants';

type ShareModalCardProps = {
  header?: string;
  modalHeader?: string;
  isOpen?: boolean;
  cardOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  pathShareLink?: string;
  programTitle?: string;
  customModalId?: string;
  itemAnalyticsModel?: ItemAnalyticsModel;
  context?: AnalyticsContext;
};

const ShareModalCard: React.FC<ShareModalCardProps> = ({
  modalHeader,
  isOpen,
  cardOpenModal,
  pathShareLink,
  programTitle,
  customModalId,
  itemAnalyticsModel,
  context
}) => {
  const buildUrl = (url: string) => {
    return `${window.location.origin}${url}`;
  };

  type methodTypes = 'email' | 'link';

  const mailToString = `mailto:?subject=${programTitle}&body=${programTitle} ${buildUrl(pathShareLink!)}`;
  const [copied, setCopied] = useState(false);

  const pushShareAnalitics = useCallback(
    (method: methodTypes) => {
      if (itemAnalyticsModel?.id && context)
        trackShareEvent(context, method, itemAnalyticsModel);
    },
    [itemAnalyticsModel, context]
  );

  if (!isOpen) return null;

  return (
    <>
      {cardOpenModal && (
        <Modal open={isOpen} setOpen={cardOpenModal}>
          <ModalContent
            fitContent={true}
            title={modalHeader}
            containerClassName="pb-[27px]"
            customModalId={customModalId}
          >
            <div className="flex w-full flex-col gap-4">
              <SelectorButton
                iconName={'copy'}
                label={'Copy link'}
                color="light-gray"
                onClick={() => {
                  pushShareAnalitics('link');
                  navigator.clipboard.writeText(buildUrl(pathShareLink!));
                  setCopied(true);
                }}
                secondaryLabel={copied ? 'Copied' : undefined}
              />
              <SelectorButton
                iconName={'email'}
                label={'Send email'}
                color="light-gray"
                onClick={() => {
                  pushShareAnalitics('email');
                  window.location.href = mailToString;
                }}
              />
            </div>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ShareModalCard;
