import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { FilterButtonGroupProps } from '@/components/ui/FindAProgram/types';
import { SECTIONS } from '@/components/ui/FindAProgram/constants';
import { FILTERS } from '@/components/ui/SearchResults/constants';
import { selectSearchAllFiltersFromEmptySearch } from '@/components/ui/SearchResults/hooks/useGetAllFiltersFromEmptySearch';
import { translateStoreFilterToComponentPropsItems } from '@/components/ui/SearchResults/utils/translateStoreDataToComponentsProps';
import { addDisabledFacetsFromEmptySearch } from '@/components/ui/SearchResults/utils/searchResponse';

// Selectors to access specific parts of the state
export const selectFindAProgramData = (state: RootState) => state.findAProgram.data;
const selectFindAProgramLoading = (state: RootState) => state.findAProgram.isLoading;
const selectFindAProgramFetching = (state: RootState) => state.findAProgram.isFetching;
export const selectFindAProgramAdHocFilters = (state: RootState) => state.findAProgram.adHocFilters;
const selectFindAProgramRequest = (state: RootState) => state.findAProgram.request;
const selectFindAProgramPreselectedFiltersThatHaveBeenDeselected = (state: RootState) =>
  state.findAProgram.preselectedFiltersThatHaveBeenDeselected;

// Selectors to process and transform state data
export const selectFilters = createSelector(
  selectFindAProgramData,
  data => data?.facetResults?.facetGroups
);

export const selectFindAProgramRequestResponse = createSelector(
  selectFindAProgramRequest,
  selectFindAProgramData,
  (request, response) => ({ request, response })
);

export const selectFindAProgramFilters = createSelector(
  selectFilters,
  selectSearchAllFiltersFromEmptySearch,
  selectFindAProgramAdHocFilters,
  (filters, allFiltersFromEmptySearch, adHocFilters) => {
    const out: FilterButtonGroupProps[] = [];

    SECTIONS.forEach(
      ({ id, title, validFilters, addButtonLabel, hasEmptyState, hasDisabledFilters, filter }) => {
        const facetGroup = filters?.find(group => group.groupTypeId === id);

        let list = addDisabledFacetsFromEmptySearch({
          hasDisabledFilters,
          facetGroup,
          allFiltersFromEmptySearch
        });

        if (filter) {
          list = list.filter(item => filter(item, adHocFilters));
        }

        adHocFilters.forEach(filter => {
          if (filter.groupTypeId === id && !list.find(facet => facet.value === filter.value)) {
            list.push({ ...filter, disabled: true });
          }
        });

        if (hasEmptyState || list.length) {
          out.push({
            title,
            addButtonLabel,
            groupTypeId: id,
            filters: translateStoreFilterToComponentPropsItems(
              list.filter(
                facet =>
                  facet.value &&
                  (!validFilters || validFilters.includes(facet.value)) &&
                  (!facet.disabled || hasDisabledFilters)
              )
            )
          });
        }
      }
    );

    return out.filter(({ groupTypeId }) => {
      const sectionConfig = SECTIONS.find(({ id }) => id === groupTypeId);
      if (!sectionConfig?.dependsOn) {
        return true;
      }
      const dependsOnGroup = out.find(({ groupTypeId }) =>
        sectionConfig.dependsOn.includes(groupTypeId as FILTERS)
      );

      return !!dependsOnGroup?.filters?.length;
    });
  }
);

// Combined selector to select multiple parts of the state
export const selectFindAProgram = createSelector(
  selectFindAProgramData,
  selectFindAProgramLoading,
  selectFindAProgramFetching,
  selectFindAProgramFilters,
  selectFindAProgramPreselectedFiltersThatHaveBeenDeselected,
  (data, loading, fetching, filterGroups, preselectedFiltersThatHaveBeenDeselected) => ({
    data,
    loading,
    fetching,
    filterGroups,
    preselectedFiltersThatHaveBeenDeselected
  })
);
