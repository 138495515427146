import SelectAddToProfileModal, { SelectAddToProfileModalProps } from './SelectAddToProfileModal';

export type SelectPracticeAreaModalProps = Omit<
  SelectAddToProfileModalProps,
  'title' | 'saveButtonLabel'
>;

const SelectPracticeAreaModal = (props: SelectPracticeAreaModalProps): JSX.Element => {
  const title = `Would you like to follow ${props.items.length > 1 ? 'these practice areas' : 'this practice area'}?`;
  return <SelectAddToProfileModal title={title} saveButtonLabel="Follow" {...props} />;
};

export default SelectPracticeAreaModal;
