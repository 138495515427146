import { LargeCarouselContainerBlock as LargeCarouselContainerBlockProps } from '@/@types/content';
import Block from '@/components/cms/Block';
import classnames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { register, SwiperContainer } from 'swiper/element/bundle';
import { v4 as uuid4 } from 'uuid';
import throttle from 'lodash/throttle';
import { type SwiperOptions } from 'swiper/types';
import RoundedIcon from '@/components/ui/RoundedIcon';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import RoundedButton from '@/components/ui/Buttons/RoundedButton';
import {
  LargeCarouselItemProps,
  LargeCarouselTheme
} from '@/components/blocks/LargeCarousel/utils/constants';
import LargeCarouselSlideFifty from '@/components/blocks/LargeCarousel/LargeCarouselSlideFifty';
import LargeCarouselSlideGallery from '@/components/blocks/LargeCarousel/LargeCarouselSlideGallery';
import LargeCarouselSlideFull from '@/components/blocks/LargeCarousel/LargeCarouselSlideFull';
import { getExpiredStatus } from '@/components/ui/PDP/helpers';
import { ProgramVariation } from '@/hooks/PDP/useProgramCatalogRelations';
import { PublicationVariation } from '@/hooks/PDP/usePublicationCatalogRelations';

interface BlockProps extends LargeCarouselContainerBlockProps {
  isInHeader?: boolean;
  productCTALabel?: string;
}

interface LargeCarouselItemPropsExtended extends LargeCarouselItemProps {
  theme?: string;
}

const filterExpiredItems = (carouselItems: ProgramVariation[] | PublicationVariation[]) => {
  return carouselItems.filter(
    item => !(item.contentType && (item.stopPublish || item.stopSell) && getExpiredStatus(item))
  ).length;
};

const isExpired = (carouselItem: ProgramVariation | PublicationVariation) => {
  const { contentType, stopPublish, stopSell } = carouselItem;
  if (contentType && (stopPublish || stopSell) && getExpiredStatus(carouselItem)) {
    return true;
  }
  return false;
};

const LargeCarouselContainerBlock: React.FC<BlockProps> = ({
  contentLink,
  isInHeader = false,
  autoplay = true,
  autoplayDuration = 10,
  carouselItems = [],
  productCTALabel
}) => {
  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

  const slideContainerRef = useRef(null);
  const swiperRef = useRef(null);
  const autoplayTimeLeft = useRef(0);
  const autoplayTimeLeftMiliseconds = useRef(0);

  const navPrevId = useMemo(() => `nav-prev-${uuid4()}`, []);
  const navNextId = useMemo(() => `nav-next-${uuid4()}`, []);

  const [isPrevVisible, setIsPrevVisible] = useState(false);
  const [isNextVisible, setIsNextVisible] = useState(false);
  const [sliderPaused, setSliderPaused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activePercentage, setActivePercentage] = useState(0);

  const childrenCount: number[] = useMemo(() => {
    return Array.from({ length: filterExpiredItems(carouselItems) }, (_, i) => i);
  }, [carouselItems]);

  useEffect(() => {
    if (!swiperRef.current) return;
    register();

    const params: SwiperOptions = {
      slidesPerView: 1,
      loop: true,
      navigation: {
        enabled: true,
        prevEl: `#${navPrevId}`,
        nextEl: `#${navNextId}`
      },
      speed: 1000,
      autoHeight: true,
      spaceBetween: 0,
      on: {
        autoplayTimeLeft: (_swiper, timeLeft, percentage) => {
          const percentagePassed = Math.floor((1 - percentage) * 80 + 20);
          if (autoplayTimeLeft.current !== percentagePassed) {
            autoplayTimeLeft.current = percentagePassed;
            autoplayTimeLeftMiliseconds.current = timeLeft;
            const percentageBar = percentagePassed;
            setActivePercentage(percentageBar);
          }
        },
        realIndexChange: swiper => {
          const currentSwiper = swiper;
          setActiveIndex(currentSwiper.realIndex || 0);
        }
      }
    };

    if (autoplay) {
      Object.assign(params, {
        autoplay: {
          delay: autoplayDuration * 1000,
          disableOnInteraction: false
        }
      });
    }

    Object.assign(swiperRef.current, params);

    const currentSwiper = swiperRef.current as SwiperContainer;
    currentSwiper.initialize();
    if (autoplay && !sliderPaused) {
      currentSwiper.swiper.autoplay.start();
    } else {
      currentSwiper.swiper.autoplay.stop();
    }

    const handleResize = () => {
      currentSwiper.swiper.update();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      currentSwiper?.swiper?.autoplay?.stop();
      window.removeEventListener('resize', handleResize);
    };
  }, [autoplay, autoplayDuration, navNextId, navPrevId, sliderPaused]);

  const handleMouseMove = throttle((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const container = slideContainerRef.current as HTMLDivElement | null;
    if (container) {
      const rect = container.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;

      if (mouseX < rect.width / 2) {
        setIsPrevVisible(true);
        setIsNextVisible(false);
      } else {
        setIsPrevVisible(false);
        setIsNextVisible(true);
      }
    }
  }, 100);

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsPrevVisible(false);
      setIsNextVisible(false);
    }, 250);
  };

  const toggleSlider = () => {
    setSliderPaused(prevState => !prevState);
  };

  const goToSlide = (index: number) => {
    if (!swiperRef.current) return;
    const currentSwiper = swiperRef.current as SwiperContainer;

    currentSwiper?.swiper?.slideTo(index);
  };

  const blockClasses = classnames('py-6', 'lg:py-16', {
    container: !isInHeader
  });

  const getTheme = (item: LargeCarouselItemPropsExtended): LargeCarouselTheme => {
    let theme = (item.theme as LargeCarouselTheme) || LargeCarouselTheme.Fifty;
    if (item.contentType?.at(0)?.toLocaleLowerCase() === 'variation' && item.code) {
      theme = LargeCarouselTheme.Fifty;
    }

    return theme;
  };

  const navClasses = classnames(
    'group/navBtn',
    'absolute top-1/2 z-1 hidden w-[60px] -translate-y-1/2 overflow-hidden rounded-full opacity-0 backdrop-blur-md transition-opacity',
    'disabled:pointer-events-none disabled:opacity-0',
    'hover:opacity-100',
    'lg:flex'
  );

  const navIconClasses = classnames(
    'w-[60px] bg-black/30 transition-colors',
    'group-hover/navBtn:bg-black/40'
  );

  const sliderBulletsClasses = classnames(
    'relative size-2 overflow-hidden rounded-full hover:cursor-pointer'
  );

  const bulletsBeforeClasses = classnames('absolute left-0 top-0 size-2 rounded-full bg-black');

  const bulletsAfterClasses = classnames(
    'absolute left-0 top-0 size-full rounded-full bg-black opacity-20 backdrop-blur-md'
  );

  const SwiperComponent = useMemo(() => {
    return (
      <swiper-container init={false} ref={swiperRef}>
        {carouselItems?.map((itemData, index) => {
          const item = itemData as LargeCarouselItemPropsExtended;
          if (isExpired(item)) return null;
          const theme = getTheme(item);

          switch (theme) {
            case LargeCarouselTheme.Fifty:
              return (
                <swiper-slide key={index}>
                  <LargeCarouselSlideFifty item={item} loadEagerly={index <= 1} />
                </swiper-slide>
              );

            case LargeCarouselTheme.Full:
              return (
                <swiper-slide key={index}>
                  <LargeCarouselSlideFull
                    item={item}
                    productCTALabel={productCTALabel}
                    loadEagerly={index <= 1}
                  />
                </swiper-slide>
              );

            case LargeCarouselTheme.Gallery:
              return (
                <swiper-slide key={index}>
                  <LargeCarouselSlideGallery item={item} />
                </swiper-slide>
              );

            default:
              return null;
          }
        })}
      </swiper-container>
    );
  }, [carouselItems, productCTALabel]);

  if (!carouselItems?.length) return null;

  return (
    <Block contentLinkID={contentLink?.id} className={blockClasses}>
      <div className="flex flex-col gap-6" data-component={'LargeCarouselContainerBlock'}>
        <div
          ref={slideContainerRef}
          className="relative"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          {SwiperComponent}

          <button
            id={navPrevId}
            className={classnames(navClasses, 'left-[-30px]', 'focus-visible:opacity-100', {
              'opacity-0': !isPrevVisible,
              'opacity-100': isPrevVisible
            })}
          >
            <RoundedIcon
              color="black"
              icon={{ name: 'arrow-left', size: 'large' }}
              className={navIconClasses}
            />
          </button>

          <button
            id={navNextId}
            className={classnames(navClasses, 'right-[-30px]', 'focus-visible:opacity-100', {
              'opacity-0': !isNextVisible,
              'opacity-100': isNextVisible
            })}
          >
            <RoundedIcon
              color="black"
              icon={{ name: 'arrow-right', size: 'large' }}
              className={navIconClasses}
            />
          </button>
        </div>

        <div className="flex items-center justify-start gap-4">
          <RoundedButton
            onClick={toggleSlider}
            color="gray"
            icon={sliderPaused ? 'play' : 'pause'}
            aria-label={sliderPaused ? stateSiteLabels?.playLabel : stateSiteLabels?.pauseLabel}
          />
          <div className={classnames('flex gap-[11px]')}>
            {childrenCount.map(index => (
              <div
                className={classnames(sliderBulletsClasses, {
                  'w-10': index === activeIndex,
                  'before:opacity-20 before:backdrop-blur-md': index !== activeIndex
                })}
                key={index}
                id={`nav-item-${index}`}
                onClick={() => goToSlide(index)}
              >
                <div
                  id={`before-${index}`}
                  className={classnames(bulletsBeforeClasses, {
                    hidden: index !== activeIndex
                  })}
                  style={{ width: `${activePercentage}%`, transition: 'width 0.01s ease' }}
                ></div>
                <div className={classnames(bulletsAfterClasses)}></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Block>
  );
};

export default LargeCarouselContainerBlock;
