import {
  ISearchResultItem,
  MediaStream,
  PodcastEpisodesResponse,
  PodcastSeason
} from '@/@types/client-api';
import { ClientAPI } from '@/redux/api';

export interface IGetPodcastEpisodesParams {
  podcastCode: string;
  seasonCode?: string;
  pageNumber?: number;
  pageSize: number;
  sort: string;
}

const productEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getProductLink: builder.query<string, string>({
      query: params => ({
        url: `/product-link/${params}`,
        method: 'GET'
      })
    }),
    getProductLinkAuth: builder.query<string, string>({
      query: params => ({
        url: `/product-link-auth/${params}`,
        method: 'GET'
      })
    }),
    getProductRedirectContent: builder.query<string, string>({
      query: params => ({
        url: `/plus-content-redirect-auth/${params}`,
        method: 'GET'
      })
    }),
    getMediaStreams: builder.query<Array<MediaStream>, string>({
      query: params => ({
        url: `/product/media-streams?codes=${params}`,
        method: 'GET'
      })
    }),
    getProductByCode: builder.query<Array<ISearchResultItem>, string>({
      query: params => ({
        url: `/search/product-code?ProductCodes=${params}`
      })
    }),
    getImageUrl: builder.query<string, string>({
      query: params => ({
        url: `/product/${params}/imageUrl`,
        method: 'GET'
      })
    }),
    getPodcastSeasons: builder.query<PodcastSeason[], string>({
      query: podcastCode => ({
        url: `/podcast/seasons/${podcastCode}`,
        method: 'GET'
      })
    }),
    getPodcastEpisodes: builder.query<PodcastEpisodesResponse, IGetPodcastEpisodesParams>({
      query: params => ({
        url: `/podcast/episodes/${params.podcastCode.trim()}${params.seasonCode ? '/' + params.seasonCode : ''}?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&sort=${params.sort}`,
        method: 'GET'
      }),
      merge: (currentCache, newResponse, { arg }) => {
        if (currentCache.items && arg.pageNumber && arg.pageNumber !== 1) {
          // Add the previous results to the beginning of the new list from the API
          newResponse.items?.unshift(...(currentCache.items ?? []));
        }

        return newResponse;
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };

        // Remove pageNumber from the query args
        // so that when user goes to a new page, we don't create a new cache entry.
        // (Instead, we update the previous entry in `merge` function above)
        if (newQueryArgs.pageNumber) {
          delete newQueryArgs.pageNumber;
        }

        return newQueryArgs;
      }
    })
  })
});

export const {
  useGetProductLinkQuery,
  useGetProductLinkAuthQuery,
  useGetProductRedirectContentQuery,
  useGetMediaStreamsQuery,
  useGetProductByCodeQuery,
  useGetImageUrlQuery,
  useGetPodcastSeasonsQuery,
  useGetPodcastEpisodesQuery
} = productEndpoints;

export default productEndpoints;
