import BaseCard, { ThumbnailProps } from '@/components/ui/Cards/BaseCard';
import { MenuItemProps } from '@/components/ui/Menu/MenuItem';
import { TagProps } from '@/components/ui/Tag';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useCallback, useState, useMemo } from 'react';
import { useAddToLibraryMutation, useRemoveFromLibraryMutation } from '@/redux/api/client/library';
import useToast from '@/hooks/useToast';
import ShareModalCard from '@/components/ui/Cards/ShareModalCard';
import RemoveLibraryModalCard from '@/components/ui/Cards/RemoveLibraryModal';
import { ConfirmRemoveButtonToast } from '@/components/ui/Cards/ConfirmRemoveButtonToast';
import { SaveToLibraryToast } from '@/components/ui/Cards/SaveToLibraryToast';
import BaseCardList from '@/components/ui/Cards/BaseCardList';
import ProgramMaterialsModal from '../PDP/Modals/ProgramMaterialsModal';
import { MaterialsLabels } from '@/components/ui/PDP/labels';
import { useLazyGetProgramMaterialsQuery } from '@/redux/api/client/library';
import { ItemAnalyticsModel, ProgramMaterial } from '@/@types/client-api';
import moreLikeThis from '@/utils/moreLikeThis';
import { useSelector } from 'react-redux';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { useLaunch } from '@/hooks/shared/useLaunch';
import { useAddToCart } from '@/hooks/shared/useAddToCart';
import { v4 as uuid4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { trackAddWishListEvent, trackRemoveWishListEvent } from '@/analytics/ecommerce';
import { AnalyticsContext } from '@/analytics/constants';
import { trackCardRequestCreditEvent } from '@/analytics/cards';

enum Type {
  Standard = 'standard',
  Faculty = 'faculty',
  Library = 'library'
}

export interface SegmentCardProps {
  image?: {
    url: string;
    alt?: string;
  };
  tag?: TagProps;
  variant?:
    | 'default'
    | 'watched'
    | 'continueWatching'
    | 'creditRequested'
    | 'creditReceived'
    | 'retail';
  size?: 'small' | 'medium' | 'large';
  heading?: string;
  labelDetail?: string;
  labelInfo?: string;
  href?: string;
  target?: string;
  onClickCard?: () => void;
  listPosition?: number;
  variationPk?: string;
  isSaved?: boolean;
  heightAuto?: boolean;
  widthAuto?: boolean;
  showAsList?: boolean;
  progressValue?: number;
  productCode?: string | null;
  certificateRequestUrl?: string | null;
  hasCreditsForRequest?: boolean;
  type?: `${Type}`;
  isPurchased?: boolean;
  isParentProgramPurchased?: boolean | null;
  watchUrl?: string | null;
  itemAnalyticsModel?: ItemAnalyticsModel;
  labelIcon?: string;
  clickOnCardContent?: () => void;
}

export interface SegmentLabelsProps extends SegmentCardProps {
  labelDetailIcon?: string;
  labelDetailClassName?: string;
}

const SegmentCard: React.FC<SegmentCardProps> = ({
  variant = 'default',
  image,
  tag,
  variationPk,
  isSaved,
  showAsList,
  progressValue,
  productCode,
  certificateRequestUrl,
  hasCreditsForRequest = false,
  type = Type.Standard,
  isPurchased,
  watchUrl,
  isParentProgramPurchased,
  itemAnalyticsModel,
  labelIcon,
  clickOnCardContent,
  ...props
}) => {
  const customModalId = useMemo(() => `program-card-modal-${uuid4()}`, []);

  const { launch } = useLaunch();
  const { addToCart } = useAddToCart();

  const pageLinkPaths = useSelector(selectPageLinkPaths);
  const searchPageUrl = pageLinkPaths?.Search;

  const belowLg = useMediaQuery(ScreenSizeQueries.belowLg);
  const navigate = useNavigate();

  let buttonOptionsVariation;
  let buttonOptions: (MenuItemProps | { separator: true })[];

  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [openProgramMaterials, setOpenProgramMaterials] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [materials, setMaterials] = useState<ProgramMaterial[]>([]);
  const [addToLibrary] = useAddToLibraryMutation();
  const [removeFromLibrary] = useRemoveFromLibraryMutation();
  const [getMaterials] = useLazyGetProgramMaterialsQuery();
  const { showSuccessMessage, showFailMessage } = useToast();
  const showRequestCreditOption = hasCreditsForRequest && type == Type.Library;

  let thumbnail: ThumbnailProps = {
    image,
    progressValue
  };

  let segmentProps: SegmentLabelsProps = {
    ...props
  };

  const buildButtonVariations = (
    label: string,
    icon: string,
    onClickButton: () => void,
    buttonOptions: (MenuItemProps | { separator: true })[]
  ) => {
    return [{ label: label, onClick: onClickButton, icon: icon }, ...(buttonOptions ?? [])];
  };

  const shareButtonFunction = () => {
    setIsOpenShareModal(!isOpenShareModal);
  };

  const accessMaterialButtonFunction = () => {
    handleLoadMaterials();
    setOpenProgramMaterials(!openProgramMaterials);
  };

  const removeButtonFunction = () => {
    setIsOpenRemoveModal(!isOpenRemoveModal);
  };

  const moreLikeThisFunc = useCallback(
    () => moreLikeThis(navigate, productCode, searchPageUrl, itemAnalyticsModel),
    [navigate, productCode, searchPageUrl, itemAnalyticsModel]
  );

  const removeFromLibraryQuery = useCallback(
    async (pk: string, undoAction?: boolean) => {
      const dataResponse = await removeFromLibrary(pk);
      trackRemoveWishListEvent(itemAnalyticsModel, AnalyticsContext.Cards);
      if ('error' in dataResponse) {
        return { error: true };
      } else if ('data' in dataResponse && undoAction) {
        return showSuccessMessage('Item Removed from library');
      } else if ('data' in dataResponse) {
        return { data: true };
      }
    },
    [removeFromLibrary, showSuccessMessage, itemAnalyticsModel]
  );

  const addToLibraryQuery = useCallback(
    async (pk: string, undoAction?: boolean) => {
      const dataResponse = await addToLibrary(pk);

      if ('error' in dataResponse) {
        return { error: true };
      } else if ('data' in dataResponse && undoAction) {
        return showSuccessMessage('Item Added to library');
      } else if ('data' in dataResponse) {
        return { data: true };
      }
    },
    [addToLibrary, showSuccessMessage]
  );

  const confirmRemoveButtonFunction = useCallback(
    async (pk: string) => {
      const onClickFunction = () => {
        addToLibraryQuery(variationPk!, true);
      };

      const toastNode = (
        <ConfirmRemoveButtonToast
          message="Thanks for the feedback, the program has been removed. "
          onClickFunction={onClickFunction}
        />
      );

      const removeResponse = await removeFromLibraryQuery(pk);

      if (removeResponse && 'error' in removeResponse) {
        showFailMessage('Error while removing item to library');
      } else if (removeResponse && 'data' in removeResponse) {
        showSuccessMessage(toastNode);
      }
    },
    [addToLibraryQuery, removeFromLibraryQuery, showSuccessMessage, showFailMessage, variationPk]
  );

  const saveToLibraryFunction = useCallback(
    async (pk: string) => {
      const onClickFunction = () => {
        removeFromLibraryQuery(variationPk!, true);
      };

      const toastNode = (
        <SaveToLibraryToast message="Saved item to library. " onClickFunction={onClickFunction} />
      );

      trackAddWishListEvent(itemAnalyticsModel, AnalyticsContext.Cards);

      const addResponse = await addToLibraryQuery(pk);

      if (addResponse && 'error' in addResponse) {
        showFailMessage('Error while adding item to library');
      } else if (addResponse && 'data' in addResponse) {
        showSuccessMessage(toastNode);
      }
    },
    [
      addToLibraryQuery,
      removeFromLibraryQuery,
      showFailMessage,
      showSuccessMessage,
      variationPk,
      itemAnalyticsModel
    ]
  );

  const handleWatchOrLaunch = useCallback(() => {
    if ((isPurchased || isParentProgramPurchased) && watchUrl) {
      launch(variationPk!, false, watchUrl, itemAnalyticsModel);
    } else {
      launch(variationPk!, undefined, undefined, itemAnalyticsModel);
    }
  }, [isPurchased, watchUrl, variationPk, isParentProgramPurchased, itemAnalyticsModel, launch]);

  const shareButtonOption = { label: 'Share', onClick: shareButtonFunction, icon: 'share' };

  const removeFromLibraryOption = {
    label: 'Remove from library',
    onClick: removeButtonFunction,
    icon: 'delete'
  };

  const saveToLibraryOption = {
    label: 'Save to my library',
    onClick: () => saveToLibraryFunction(variationPk!),
    icon: 'add'
  };

  const accessMaterials = {
    label: 'Access Materials',
    onClick: accessMaterialButtonFunction,
    icon: 'publications',
    visible: isPurchased || isParentProgramPurchased
  };

  const requestCreditOption = {
    label: 'Request credits',
    onClick: () => {
      trackCardRequestCreditEvent(itemAnalyticsModel, AnalyticsContext.Cards);
      certificateRequestUrl
        ? window.open(certificateRequestUrl ?? undefined, '_blank')
        : launch(variationPk!);
    },
    icon: 'credits',
    visible: showRequestCreditOption
  };

  const handleLoadMaterials = useCallback(() => {
    setLoading(true);
    getMaterials(variationPk!, true)
      .then(response => setMaterials(response.data ?? []))
      .catch(() => setMaterials([]))
      .finally(() => setLoading(false));
  }, [getMaterials, variationPk]);

  const saveToLibrary = isSaved ? removeFromLibraryOption : saveToLibraryOption;

  switch (variant) {
    case 'default':
      thumbnail = {
        ...thumbnail,
        tag,
        iconThumbnail: {
          name: showRequestCreditOption ? 'credits' : 'play',
          label: showRequestCreditOption ? 'Request credits' : 'Launch Now'
        }
      };

      buttonOptions = [
        accessMaterials,
        saveToLibrary,
        requestCreditOption,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'Launch now',
          'play',
          () => handleWatchOrLaunch(),
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      segmentProps = {
        ...segmentProps,
        onClickCard: () => {
          if (showRequestCreditOption) {
            trackCardRequestCreditEvent(itemAnalyticsModel, AnalyticsContext.Cards);
            window.open(certificateRequestUrl ?? undefined, '_blank');
          } else {
            handleWatchOrLaunch();
          }
        }
      };
      break;
    case 'continueWatching':
      thumbnail = {
        ...thumbnail,
        tag,
        iconThumbnail: {
          name: showRequestCreditOption ? 'credits' : 'play',
          label: showRequestCreditOption ? 'Request credits' : 'Launch Now'
        }
      };

      buttonOptionsVariation = [
        accessMaterials,
        requestCreditOption,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ] as (MenuItemProps | { separator: true })[];

      segmentProps = {
        ...segmentProps,
        onClickCard: () => {
          if (showRequestCreditOption) {
            trackCardRequestCreditEvent(itemAnalyticsModel, AnalyticsContext.Cards);
            window.open(certificateRequestUrl ?? undefined, '_blank');
          } else {
            handleWatchOrLaunch();
          }
        }
      };
      break;
    case 'watched':
      thumbnail = {
        ...thumbnail,
        iconThumbnail: {
          name: showRequestCreditOption ? 'credits' : 'play',
          label: showRequestCreditOption ? 'Request credits' : 'Launch Now'
        }
      };

      buttonOptionsVariation = [
        {
          label: 'Watch Again',
          onClick: () => {},
          icon: 'credits'
        },
        accessMaterials,
        requestCreditOption,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ] as (MenuItemProps | { separator: true })[];

      if (certificateRequestUrl) {
        segmentProps = {
          ...segmentProps,
          onClickCard: () => {
            trackCardRequestCreditEvent(itemAnalyticsModel, AnalyticsContext.Cards);
            window.open(certificateRequestUrl, '_self');
          }
        };
      }

      break;
    case 'creditRequested':
      thumbnail = {
        ...thumbnail,
        tag,
        iconThumbnail: { name: 'play', label: 'Launch now' }
      };

      buttonOptions = [accessMaterials, saveToLibrary, shareButtonOption];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'Launch now',
          'play',
          () => handleWatchOrLaunch(),
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      segmentProps = {
        ...segmentProps,
        labelDetail: 'Credit requested',
        labelDetailIcon: 'media-player'
      };

      segmentProps = {
        ...segmentProps,
        onClickCard: () => handleWatchOrLaunch()
      };
      break;
    case 'creditReceived':
      thumbnail = {
        ...thumbnail,
        tag,
        iconThumbnail: { name: 'play', label: 'Launch now' }
      };

      buttonOptions = [
        {
          label: 'Watch Again',
          onClick: () => handleWatchOrLaunch(),
          icon: 'credits'
        },
        accessMaterials,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'Launch now',
          'play',
          () => handleWatchOrLaunch(),
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      segmentProps = {
        ...segmentProps,
        labelDetail: 'Credit received',
        labelDetailIcon: 'media-player',
        labelDetailClassName: 'text-serpentine',
        onClickCard: () => handleWatchOrLaunch()
      };
      break;
    case 'retail':
      thumbnail = {
        ...thumbnail,
        tag,
        iconThumbnail: { name: 'cart', label: labelIcon || 'Add to cart' }
      };

      buttonOptions = [
        accessMaterials,
        saveToLibrary,
        shareButtonOption,
        { separator: true },
        {
          label: 'Show more like this',
          onClick: moreLikeThisFunc,
          icon: 'search'
        }
      ];

      if (belowLg) {
        buttonOptionsVariation = buildButtonVariations(
          'Add to cart',
          'cart',
          () => addToCart(variationPk, segmentProps.heading),
          buttonOptions
        );
      } else {
        buttonOptionsVariation = buttonOptions;
      }

      segmentProps = {
        ...segmentProps,
        onClickCard: () => addToCart(variationPk, segmentProps.heading)
      };
      break;
  }

  return (
    <>
      {showAsList ? (
        <BaseCardList
          buttonOptions={buttonOptionsVariation}
          thumbnailProps={thumbnail}
          {...segmentProps}
        />
      ) : (
        <BaseCard
          thumbnailProps={thumbnail}
          buttonOptions={buttonOptionsVariation}
          dropdownButtonAriaControls={customModalId}
          clickOnCardContent={clickOnCardContent}
          {...segmentProps}
        />
      )}

      <ShareModalCard
        modalHeader="Share"
        pathShareLink={`/product/${variationPk}`}
        programTitle={props.heading!}
        isOpen={isOpenShareModal}
        cardOpenModal={setIsOpenShareModal}
        customModalId={customModalId}
        itemAnalyticsModel={itemAnalyticsModel}
        context={AnalyticsContext.Cards}
      />

      <RemoveLibraryModalCard
        modalHeader="Do you want to remove this program from your Library?"
        isOpen={isOpenRemoveModal}
        onSaveButtonPress={() => confirmRemoveButtonFunction(variationPk!)}
        cardOpenModal={setIsOpenRemoveModal}
        customModalId={customModalId}
      />

      <ProgramMaterialsModal
        open={openProgramMaterials}
        setOpen={setOpenProgramMaterials}
        programTitle={props.heading ?? ''}
        labels={MaterialsLabels}
        materials={materials}
        isLoading={isLoading}
        customModalId={customModalId}
      />
    </>
  );
};

export default SegmentCard;
