import React, { Fragment } from 'react';
import classnames from 'classnames';
import * as Avatar from '@radix-ui/react-avatar';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import ResponsiveImage, { ResponsiveImageProps } from './ResponsiveImage';
import Icon from '@/components/ui/Icon';

export interface AvatarProps {
  propertyName?: string;
  imageUrl?: string;
  fullName: string;
  firstName?: string;
  lastName?: string;
  className?: string;
  imageSizes?: ResponsiveImageProps['imageSizes'];
  hovered?: boolean;
  icon?: string;
}

const AvatarComponent: React.FC<AvatarProps> = ({
  propertyName,
  imageUrl,
  fullName,
  firstName,
  lastName,
  className,
  imageSizes,
  hovered,
  icon
}) => {
  const trimmedFullName = fullName?.trim();
  const names = trimmedFullName?.split(' ');
  const first = firstName || (names && names[0]) || ' ';
  const last = lastName || (names && names[names.length - 1]) || ' ';

  const initials = `${first[0]}${last[0]}`;

  const avatarClasses = classnames(
    'label flex max-w-full items-center justify-center overflow-hidden rounded-full text-gray-dark',
    {
      'bg-gray-light': !icon,
      'bg-gray-dark': icon
    },
    className
  );

  const conditionalProps =
    isEditOrPreviewMode() && propertyName ? { 'data-epi-edit': propertyName } : {};

  return (
    <Avatar.Root {...conditionalProps} className={classnames(avatarClasses, 'relative')}>
      <AspectRatio.Root ratio={1} className="flex items-center justify-center">
        {icon ? (
          <Icon name={icon} size="medium" className="text-gray-medium" />
        ) : (
          <Fragment>
            {imageUrl ? (
              <ResponsiveImage
                aspectRatio={1}
                src={imageUrl}
                alt={fullName}
                className={classnames('size-full rounded-[inherit] transition-transform', {
                  'scale-110': hovered
                })}
                imageSizes={imageSizes}
              />
            ) : (
              <Avatar.Fallback delayMs={500}>
                <svg
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="size-full"
                  style={{ fontSize: 28, fontWeight: 500, letterSpacing: '-2%', lineHeight: 34 }}
                >
                  <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    dominantBaseline="central"
                    fontSize="100%"
                  >
                    {initials}
                  </text>
                </svg>
              </Avatar.Fallback>
            )}
          </Fragment>
        )}
      </AspectRatio.Root>
    </Avatar.Root>
  );
};

export default AvatarComponent;
