import { forwardRef as ReactForwardRef } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import classnames from 'classnames';
import * as Form from '@radix-ui/react-form';

interface RadioProps extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {
  asFormField?: boolean;
}

interface RadioComponent
  extends React.ForwardRefExoticComponent<RadioProps & React.RefAttributes<HTMLDivElement>> {
  Item: typeof RadioGroupPrimitive.Item;
}

interface FormFieldProps {
  className?: string;
  props: React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>;
  ref: React.Ref<HTMLDivElement>;
}

const FormField: React.FC<FormFieldProps> = ({ className, props, ref }) => (
  <Form.Field name="">
    <RadioGroupPrimitive.Root
      className={classnames('flex flex-col gap-2', className)}
      {...props}
      ref={ref}
    />
  </Form.Field>
);

const Radio = ReactForwardRef<React.ElementRef<typeof RadioGroupPrimitive.Root>, RadioProps>(
  ({ className, asFormField = true, ...props }, ref) => {
    if (asFormField) {
      return <FormField className={className} props={props} ref={ref} />;
    } else {
      return (
        <Form.Root>
          <FormField className={className} props={props} ref={ref} />
        </Form.Root>
      );
    }
  }
) as RadioComponent;

Radio.Item = RadioGroupPrimitive.Item;

export default Radio;
