import {
  BookProduct,
  FacultyMember,
  MasterProgramNode,
  VariationContent,
  Venue
} from '@/@types/content';
import {
  LiveContentType,
  LiveSegment,
  LiveVariation,
  OnDemandSegmentCatalogRelations,
  ProgramVariation,
  WebSegmentVariation
} from '@/hooks/PDP/useProgramCatalogRelations';
import { RoleGroupProps } from './ProgramFaculty/RoleGroup';
import { FacultyLinkProps } from './ProgramFaculty/FacultyLink';
import {
  FacultyRoleLabels,
  PDPSectionLabels,
  ProgramScheduleLabels,
  LocationLabels
} from './labels';
import { IContent } from '@/@types/cms';
import { DateTime } from 'luxon';
import { ProgramCrossLinkProps } from './ProgramCrossLink';
import {
  isAnswerBookVariant,
  isBookVariant,
  isCHBVariant,
  isTreatiseVariant,
  PublicationVariation
} from '@/hooks/PDP/usePublicationCatalogRelations';
import { getFormatedMonthYear, getFormattedMonthDayYear } from '@/lib/helpers/date';
import { AuthorProps } from './AuthorsList';
import { AuthorBioProps } from './Authors';
import { isDefined } from '@/lib/helpers/isDefined';
import { EcomBookVariantFormat, NotifyPanelItems, Variant } from '@/redux/slices/pdpSlice';
import { CurrencySign } from '@/constants/currency';
import { SegmentUserDataProps } from './ProgramSegments';
import { LibraryItem } from '@/@types/client-api';
import { isContentType } from '@/lib/helpers/contentType';
import { UpcomingLiveProps } from './ECommBox/UpcomingLive';
import { OnDemandProps } from './ECommBox/OnDemand';

export const getLiveProgramRuntime = (eventStartDateStr?: string, eventEndDateStr?: string) => {
  const eventStartDate = eventStartDateStr ? new Date(eventStartDateStr) : undefined;
  const eventEndDate = eventEndDateStr ? new Date(eventEndDateStr) : undefined;
  if (!eventStartDate || !eventEndDate) return undefined;
  const totalDays = (eventEndDate.getTime() - eventStartDate.getTime()) / (60 * 60 * 1000 * 24);
  const totalHours = (eventEndDate.getTime() - eventStartDate.getTime()) / (60 * 60 * 1000);
  const dayFormatStr = '-day program';
  if (totalDays > 1) return `${Math.ceil(totalDays)}${dayFormatStr}`;
  if (totalHours > 4) return `1${dayFormatStr}`;
  if (totalHours > 2) return `Half${dayFormatStr}`;
  return `${Math.ceil(totalHours)}-Hour program`;
};

export const getOnDemandProgramRuntime = (seconds?: number) => {
  const runTimeSeconds = seconds ?? 0;
  const runTimeHours = Math.round(runTimeSeconds / (60 * 60));
  return {
    actionBar: `${runTimeHours}-hour program`,
    thumbnail: `${runTimeHours} hour${runTimeHours > 1 ? 's' : ''}`
  };
};

export const getOnDemandSegmentRuntime = (seconds?: number, watchedPercent?: number) => {
  const runTimeSeconds = seconds ?? 0;
  const runTimeMinutes = Math.ceil(runTimeSeconds / 60);
  const remainingRuntimeMinutes = watchedPercent
    ? Math.round(runTimeMinutes - watchedPercent * 0.01 * runTimeMinutes)
    : undefined;
  return {
    actionBar: `${runTimeMinutes}-minutes`,
    thumbnail: `${remainingRuntimeMinutes ? remainingRuntimeMinutes : runTimeMinutes}${remainingRuntimeMinutes ? 'm left' : ' minutes'}`
  };
};

export const formatPrice = (price: number) => {
  return price.toString().includes('.')
    ? `${CurrencySign.USD}${price.toString()}`
    : `${CurrencySign.USD}${price.toString()}.00`;
};

export const getPriceFromContent = (variant: ProgramVariation) => {
  //TODO Fix CMS Model fot typing to incldue full and discount price
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const discountedPrice: number = (variant as any).discountedPrice;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fullPrice: number = (variant as any).fullPrice;
  const price = discountedPrice !== undefined ? discountedPrice : fullPrice;
  const strikePrice = discountedPrice !== fullPrice ? fullPrice : undefined;

  return {
    price: price ? formatPrice(price) : `${CurrencySign.USD}0.00`,
    strikePrice: strikePrice ? formatPrice(strikePrice) : undefined,
    retail: price !== 0,
    isValid: price !== undefined,
    rawRetailPrice: fullPrice,
    rawDiscountPrice: discountedPrice
  };
};

export const getAddressFromVenue = (venue?: Venue) => {
  return {
    address1: venue?.address1 ?? '' + (venue?.address2 ? ', ' + venue?.address2 : ''),
    address2:
      (venue?.city ?? '') +
      (venue?.state ? ', ' + venue?.state : '') +
      (venue?.zip ? ', ' + venue?.zip : '') +
      (venue?.address3 ? ', ' + venue?.address3 : '')
  };
};

export const isWaitlist = (variant: LiveVariation) => {
  const purchaseAvailableQuantity = // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (variant as any)?.inventoryRecord?.purchaseAvailableQuantity;
  return purchaseAvailableQuantity === 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNotifyPanelItems = (variant: any) => {
  const formItems: NotifyPanelItems = {
    url: variant?.notificationViewModel?.fullUrl,
    buttonLabel: variant?.notificationViewModel?.buttonLabel,
    messageLabel: variant?.notificationViewModel?.bodyText,
    headerLabel: variant?.notificationViewModel?.headerText
  };

  return formItems;
};

type Faculty = FacultyMember & { imageUrl?: string };
export const buildProgramFacultyGroups = (
  variant: ProgramVariation,
  masterProgram?: MasterProgramNode
): RoleGroupProps[] => {
  const mapFaculty = (f: IContent): Faculty => {
    const faculty = f.contentLink?.expanded as FacultyMember;
    return {
      ...faculty,
      imageUrl: faculty?.image?.url
    };
  };

  const notSuppressed = (f: Faculty) => !f?.suppressed;

  const groups: RoleGroupProps[] = [];

  // Order is: Chairperson(s), Moderator(s), Instructor(s), Speaker(s), Program Attorney (ref: DIGI-21735)
  const roleKeys: Array<keyof typeof variant> = [
    'chairpersons',
    'moderators',
    'instructors',
    'speakers'
  ];

  roleKeys.forEach(key => {
    if (key in variant && variant[key] !== undefined) {
      const facultyMembers: FacultyLinkProps[] = variant[key].map(mapFaculty).filter(notSuppressed);
      const makeRoleLabelSingular = (s: string) => s.slice(0, -1);
      const roleLabel = FacultyRoleLabels[key as keyof typeof FacultyRoleLabels];
      if (facultyMembers.length)
        groups.push({
          name: facultyMembers.length === 1 ? makeRoleLabelSingular(roleLabel) : roleLabel,
          members: facultyMembers
        });
    }
  });
  const programAttorney = masterProgram?.attorney?.expanded as FacultyMember;
  if (programAttorney && notSuppressed(programAttorney))
    groups.push({
      name: FacultyRoleLabels.attorney,
      members: [{ ...programAttorney, imageUrl: programAttorney.image?.url }]
    });

  return groups;
};

export const buildProgramSegmentUserData = (
  segments: WebSegmentVariation[],
  segmentLibraryItems: LibraryItem[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relevantCredits: any,
  isCreditsLoading?: boolean
): SegmentUserDataProps[] => {
  return segments.map(seg => {
    const libraryItem = segmentLibraryItems.find(item => item.pk === seg.code);
    const isPurchased = libraryItem?.isPurchased || libraryItem?.isParentProgramPurchased;
    const canLaunch = seg.canLaunch || !!isPurchased;
    const playbackProgressPct = libraryItem?.playbackProgressPct;
    const runtime = libraryItem
      ? getOnDemandSegmentRuntime(seg.runTimeSeconds, libraryItem?.playbackProgressPct).thumbnail
      : seg.runtime;
    const credits =
      segments.length === 1
        ? relevantCredits
        : relevantCredits && seg.code! in relevantCredits
          ? relevantCredits[seg.code!]
          : undefined;
    const isExpiredFromSale = !isValidForSale(seg);
    const launchUrl = isPurchased ? seg.launchUrl : undefined;
    return {
      code: seg.code!,
      progressPct: playbackProgressPct,
      canLaunch,
      runtime,
      credits,
      isCreditsLoading,
      isExpiredFromSale,
      launchUrl
    };
  });
};

export const buildProgramScheduleProps = (variant: LiveVariation) => {
  if (!variant.timeZoneIdentifier || !variant.liveSchedule) return;
  const dt = DateTime.local().setZone(variant.timeZoneIdentifier);
  const timezoneMessage = `All times are in ${dt.offsetNameLong} (${dt.offsetNameShort})`;
  const sessions =
    variant.liveSchedule?.map(s => {
      return {
        prgSegmentSk: s.id.toString(),
        date: s.start,
        timeZoneIdentifier: variant.timeZoneIdentifier!,
        title: s.title,
        description: s.description,
        speaker: s.faculty?.join(', '),
        isExpanded: false,
        canExpand: !s.isBreak
      };
    }) ?? [];
  return {
    labels: { ...ProgramScheduleLabels, timezoneMessage },
    heading: `${variant.location?.split(',')[0]} ${PDPSectionLabels.schedule}`,
    sessions: sessions,
    variantCode: variant?.code ?? ''
  };
};

export const buildVenueProps = (variant: LiveVariation) => {
  const data = (variant.venue?.expanded ?? variant.venue) as Venue;

  if (!data) return;

  const mapAddress = `${data.address1}, ${data.city}, ${data.state} ${data.zip}`;

  return {
    labels: LocationLabels,
    name: data.longDescription as string,
    address: [
      data.address1,
      data.address2,
      [[data.city, data.state].filter(Boolean).join(', '), data.zip].filter(Boolean).join(' ')
    ]
      .filter(Boolean)
      .join('<br />') as string,
    image: data.image?.url,
    mapLink: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(data.shortDescription ?? '')}+${encodeURIComponent(mapAddress)}&query=${data.longitude}%2C${data.latitude}`,
    mapImage: `https://maps.googleapis.com/maps/api/staticmap?center=${mapAddress}&zoom=13&size=310x208&maptype=roadmap&markers=color:red%7Clabel:%7C${mapAddress}&key=${window.env.GOOGLE_MAPS_API_KEY}`,
    hotelInformation: !variant.variationHotelInformation
      ? data.hotelInformation
      : variant.variationHotelInformation
  };
};

const fiveMinutes = 5 * 60 * 1000;
const thirtyMinutes = 30 * 60 * 1000;
const isSegmentCurrentlyLive = (
  s: LiveSegment,
  dateMillis: number,
  startBufferMillis: number = thirtyMinutes,
  endBufferMillis: number = fiveMinutes
) => {
  const startTime = new Date(s.start);
  const endTime = new Date(s.end);
  if (!startTime || !endTime) return false;

  const bufferedStartTime = startTime.getTime() - startBufferMillis;
  const bufferedEndTime = endTime.getTime() + endBufferMillis;

  if (bufferedStartTime <= dateMillis && bufferedEndTime >= dateMillis) {
    return true;
  }
  return false;
};

type LiveStatus = 'Upcoming Live' | 'Live' | 'Live ended' | undefined;
export const determineLiveProgramStatus = (
  eventStartDate?: string,
  eventEndDate?: string,
  segments?: LiveSegment[]
): LiveStatus => {
  if (!eventStartDate || !eventEndDate) return undefined;
  const start = eventStartDate ? new Date(eventStartDate) : undefined;
  const end = eventEndDate ? new Date(eventEndDate) : undefined;
  if (!start || !end) return undefined;
  const now = new Date().getTime();
  const bufferedStart = start.getTime() - thirtyMinutes;
  const bufferedEnd = end.getTime() + fiveMinutes;
  if (now < bufferedStart) {
    return 'Upcoming Live';
  } else if (now > bufferedEnd) {
    return 'Live ended';
  } else {
    // Only show "Live" when within 30 minutes of start / 5 minutes end of some segment
    if (segments?.find(s => isSegmentCurrentlyLive(s, now))) return 'Live';

    // If there is no ongoing schedule segment, we are in between days and show this state
    return 'Upcoming Live';
  }
};

export const getExpiredStatus = (variant: ProgramVariation | PublicationVariation) => {
  const now = new Date().getTime();
  if (!variant.stopSell && variant.stopPublish)
    return now >= new Date(variant.stopPublish).getTime();
  if (!variant.stopPublish && variant.stopSell) return now >= new Date(variant.stopSell).getTime();

  const stopSell = Math.min(
    new Date(variant.stopSell ?? 0).getTime(),
    new Date(variant.stopPublish ?? 0).getTime()
  );

  return now >= stopSell;
};

export const isValidForSale = (variant: ProgramVariation | PublicationVariation) => {
  return !getExpiredStatus(variant) && getPriceFromContent(variant).isValid;
};

const seciWsItemClasses = [184, 185, 186, 188, 199, 200, 203, 204, 205, 206];
const poecItemClasses = [1, 160, 255];
// Is not free for "most" PMs, some contracts are special and get 100% discount on some of these, so additional price-based logic should be considered
const notFreeForPMItemClassIds = [...seciWsItemClasses, ...poecItemClasses];
export const isNotFreeForPMs = (variant: ProgramVariation): boolean => {
  if (notFreeForPMItemClassIds.find(n => n === variant.itemClassId)) return true;
  return false;
};
const isSeciWsItemClass = (itemClassId?: number): boolean => {
  return !!seciWsItemClasses.find(i => i === itemClassId);
};
const isFreeForPMs = (itemClassId?: number): boolean => {
  return !notFreeForPMItemClassIds.find(i => i === itemClassId);
};

export const getDiscountAdviceMessage = (
  variant?: Variant,
  labels?: UpcomingLiveProps['labels'] | OnDemandProps['labels']
) => {
  if (!variant || !labels) return undefined;

  // Suppress PM Discount advice for truly free programs
  if (variant.retailPrice === 0) return undefined;

  // SIREHOME-4646
  // If SECI WS that is not a 100% or 0% discount, we assume user is eligible for the standard PM 20% discount and show that messaging.
  // This logic is sufficient current SECI WS discount pricing business rules (which is 0%, 20%, or 100%), but needs enhancement if new SECI WS contract discount rates are introduced.
  // This logic does NOT support distinguishing tCode discount price from a contract discount price (out of scope).
  if (
    isSeciWsItemClass(variant.itemClass) &&
    variant.discountPrice !== 0 &&
    variant.discountPrice !== variant.retailPrice
  )
    return labels.seciWsDiscountAdvice;

  // Program isn't free for retail users, program is an item class that PMs typically get 100% discount on, show standard "PMs attend at no cost" advice
  // TODO: SIREHOME-4843 will modify this logic for ContentOptOut users.
  if (isFreeForPMs(variant.itemClass) /* AND is not contentOptOut user getting retail price */)
    return labels.privilegedMembers;

  // If none of the above apply = it is SECI WS and 0% or 100% discount, or it is a POEC program -> don't show discount advice message.
  return undefined;
};

export const buildProgramSegmentCrossLinkProps = (
  relations: OnDemandSegmentCatalogRelations
): ProgramCrossLinkProps => {
  if (!relations.programVariant) return { isLoading: true };
  const programDisplayName = relations.programVariant.displayName!;
  const programUrl = relations.programVariant.url!;
  const currentSegment =
    relations.webSegmentsFromProgram.findIndex(s => s.code === relations.segmentVariant.code) + 1;
  const totalSegments = relations.webSegmentsFromProgram.length;
  return {
    programDisplayName,
    programUrl,
    currentSegment,
    totalSegments,
    isLoading: false
  };
};

export const getProductImageUrl = (code: string, isNodeContent?: boolean) => {
  const isNodeContentParam = isNodeContent ? '?isNodeContent=true' : '';
  return `${window.env?.CONTENT_DELIVERY_API}/api/client/product/${code}/image${isNodeContentParam}`;
};

export const formatPublicationDate = (variant: PublicationVariation, isPreorder?: boolean) => {
  if (isPreorder) return 'Publication Date: Coming Soon';
  if (!variant.publicationDate) return '';
  if (hasPublishedTreatiseSupplements(variant) && variant.lastUpdatedDate)
    return `Last Updated: ${getFormatedMonthYear(variant.lastUpdatedDate, false)}`;
  return `Publication Date: ${getFormatedMonthYear(variant.publicationDate, false)}`;
};

export const formatPublicationAuthors = (authors?: IContent[]) => {
  if (authors?.length) {
    return authors
      .map(author => {
        const member = author?.contentLink?.expanded as FacultyMember;
        if (!member) return undefined;
        return {
          name: member.displayName,
          url: member.url,
          image: member.image?.url
        };
      })
      .filter(isDefined) as AuthorProps[];
  }
};

export const formatPublicationAuthorsDescription = (authors?: IContent[]) => {
  if (authors?.length) {
    return authors
      .map(author => {
        const member = author?.contentLink?.expanded as FacultyMember;
        if (!member) return undefined;
        return {
          name: member.displayName,
          url: member.url,
          image: member.image?.url,
          bio: member.biography
        };
      })
      .filter(isDefined) as AuthorBioProps[];
  }
};

export const getPublicationFormat = (
  variant: PublicationVariation,
  product?: BookProduct,
  isPreorder?: boolean
) => {
  let baseFormat = '';
  if (product != null && product.publicationType != null) baseFormat = product.publicationType;
  else {
    if (isCHBVariant(variant)) baseFormat = 'Course handbook';
    if (isAnswerBookVariant(variant)) baseFormat = 'Answer book';
    if (isTreatiseVariant(variant)) baseFormat = 'Treatise';
  }
  return `${isPreorder ? 'Upcoming: ' : ''}${baseFormat}`;
};

export const isFreeDigitalAccess = (format?: string) =>
  format === 'digital-program' || format === 'digital-plusMember';
export const isPrintFormat = (format?: string) =>
  ['hardcover', 'softcover', 'binder', 'preorder'].includes(format ?? '');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isBookPermitted = (variant?: IContent) => (variant as any)?.isBookPermitted ?? false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPriorityCode = (variant?: IContent) => (variant as any)?.priorityCode;

export const buildPublicationTestimonialQuotes = (bookProduct?: BookProduct) => {
  if (!bookProduct?.testimonials?.length) return;
  return bookProduct.testimonials
    .map((t: { testimonial?: string; source?: string; sortOrder?: number }) => {
      return {
        content: t.testimonial ?? '',
        author: t.source ?? '',
        sortOrder: t.sortOrder ?? 999
      };
    })
    .sort((a: { sortOrder: number }, b: { sortOrder: number }) => a.sortOrder - b.sortOrder);
};

export const hasPublishedTreatiseSupplements = (variant: PublicationVariation) => {
  // This works b/c variant.LastUpdatedDate is set based on the dates of non-future supplements
  if (!variant.lastUpdatedDate || !variant.publicationDate) return false;
  const lastUpdated = new Date(variant.lastUpdatedDate);
  const published = new Date(variant.publicationDate);
  if (lastUpdated > published) return true;
  return false;
};

export const getTreatiseLastUpdatedFileUrl = (
  variant: PublicationVariation,
  bookProduct?: BookProduct
): string | undefined => {
  if (!isTreatiseVariant(variant)) return undefined;
  if (
    !bookProduct?.plusAvailable ||
    !bookProduct?.plusCode?.length ||
    !bookProduct?.plusReleaseInfoUrl?.length
  )
    return undefined;
  if (!variant.lastUpdatedDate || !variant.publicationDate) return undefined;
  const lastUpdated = new Date(variant.lastUpdatedDate);
  const published = new Date(variant.publicationDate);
  const plusCutoff = new Date(1990, 1, 1);
  if (lastUpdated <= published || lastUpdated <= plusCutoff) return undefined;
  return bookProduct.plusReleaseInfoUrl;
};

export const formatStsExpirationDate = (date?: string) => {
  if (!date) return;
  const expirationDate = new Date(date);
  return getFormattedMonthDayYear(expirationDate.toISOString());
};

export const formatStsRenewalDateFromExpirationDate = (date?: string) => {
  if (!date) return;
  const renewalDate = new Date(date);
  renewalDate.setUTCDate(renewalDate.getUTCDate() + 1);
  return getFormattedMonthDayYear(renewalDate.toISOString());
};

export const isDigitalPublicationFormat = (format?: EcomBookVariantFormat) => {
  if (!format) return false;
  switch (format) {
    case 'sts':
    case 'sts-renewal':
    case 'digital-plusMember':
    case 'digital-program':
      return true;
    default:
      return false;
  }
};

const pdpBrowserTitleFormat = '{DISPLAYNAME} | {FORMAT} - PLI';
interface PDPBrowserTitleProps {
  variant?: VariationContent;
  liveContentType?: LiveContentType;
  onDemandType?: 'program' | 'segment';
  bookProduct?: BookProduct;
}
export const getPDPBrowserTitle = ({
  variant,
  liveContentType,
  onDemandType,
  bookProduct
}: PDPBrowserTitleProps) => {
  if (!variant) return 'PLI';
  const displayName = variant.displayName ?? '';
  let format = '';
  if (liveContentType) {
    switch (liveContentType) {
      case 'LiveProgramSeminarVariation':
      case 'LiveProgramPbrSeminarVariation':
        format = 'In-Person Program';
        break;
      case 'LiveProgramWebcastVariation':
      case 'LiveProgramOhbVariation':
      case 'LiveProgramPBRWebcastVariation':
        format = 'Live Webcast Program';
        break;
      case 'LiveProgramGroupcastVariation':
        format = 'Groupcast Program';
        break;
    }
  } else if (onDemandType) {
    switch (onDemandType) {
      case 'program':
        format = 'On-Demand Program';
        break;
      case 'segment':
        format = 'On-Demand Segment';
        break;
    }
  } else if (isBookVariant(variant)) {
    format = getPublicationFormat(variant, bookProduct);
  } else if (isContentType(variant, 'PodcastEpisodeVariation')) {
    format = 'Podcast';
  }
  return pdpBrowserTitleFormat.replace('{DISPLAYNAME}', displayName).replace('{FORMAT}', format);
};
