import { ContentReference, IContent, LinkItem } from '@/@types/cms';
import {
  BannerMessageBlock,
  BaseCreditInformation,
  CreditFormatPage,
  CreditInfoTypePage,
  IconLinkBlock,
  LinkBlock,
  SearchResultBlock
} from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';
import Icon from '@/components/ui/Icon';
import Link from '@/components/ui/Link';
import useKmpSearch from '@/hooks/useKmpSearch';
import { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useGetChildrenQuery, useLazyGetContentQuery } from '@/redux/api/contentDeliveryAPI';
import { AnnouncementMessage, IconMessageLink, UserTrackerData } from '@/@types/client-api';
import { extractTextFromHTML } from '@/utils/helpers';
import SectionTitle from '@/components/ui/Titles/SectionTitle';
import { getActiveSpecialStatus } from '@/components/ui/CreditTracker/util/TrackerHelpers';
import { InvariantFilter } from '@/components/ui/SearchResults/constants';
import { IconBlockProps } from '@/components/blocks/IconBlock';
import { LinkGridShimmer } from '@/components/blocks/CreditInfo/snippets/LoadingShimmers';
import LinkGridBlock from '@/components/blocks/LinkGridBlock/LinkGridBlock';
import CreditFormatPageComponent from '@/components/pages/CreditFormatPage';
import {
  ApprovalMessage,
  CreditAnnouncements,
  HeaderLinks
} from '@/components/blocks/CreditInfo/util/creditInfoConstants';
import { formatBreadCrumbs } from '@/components/blocks/CreditInfo/util/creditInfoHelpers';
import { BreadcrumbProps } from '@/components/ui/Breadcrumbs';
import { isNumberNotEmpty } from '@/lib/helpers/isNumberNotEmpty';

type CreditInfoPageHookProps = BaseCreditInformation & {
  skipChildrenQuery?: boolean;
  childrenProperties?: string[];
};
type HeaderLink = { text?: string; href?: string; target?: string; icon?: string };

const useCreditInfo = ({
  headerLinks,
  mainContentArea,
  contentLink,
  skipChildrenQuery,
  mainBody,
  approvedMessaged,
  childrenProperties,
  breadcrumbs,
  hideInBreadcrumbs,
  creditAnnouncements
}: CreditInfoPageHookProps) => {
  const { kmpSearch } = useKmpSearch();

  const {
    data: childrenResponse,
    isLoading: isChildItemsLoading,
    isFetching: isChildItemsFetching
  } = useGetChildrenQuery(
    {
      contentLink: contentLink?.guidValue ?? '',
      query: childrenProperties
        ? {
            select: childrenProperties
          }
        : {}
    },
    { skip: !contentLink?.guidValue || !!skipChildrenQuery }
  );

  const [getTabContent] = useLazyGetContentQuery();

  const getUserStatusPreferences = useCallback((trackerData: UserTrackerData[]) => {
    const statuses: { [key: string]: string } = {};
    trackerData.map(a => {
      const active = getActiveSpecialStatus(a);

      if (!!a?.creditRegionShortDescription && !!active) {
        statuses[a.creditRegionShortDescription] = active;
      }
    });

    return statuses;
  }, []);

  const fetchRelatedPagesContent = useCallback(
    async (contentLinks?: ContentReference[]) => {
      if (!contentLinks?.length) return;

      const promises = contentLinks
        .filter(a => !!a.guidValue && a.guidValue?.length > 0)
        ?.map(link =>
          getTabContent(
            {
              contentLink: link.guidValue!
            },
            true
          ).unwrap()
        );

      return (await Promise.all(promises)) as IContent[];
    },
    [getTabContent]
  );

  const children = useMemo(() => childrenResponse?.children, [childrenResponse?.children]);

  const allChildrenAreFormats = useMemo(
    () =>
      !!children?.length &&
      children?.every(a => a.contentType?.some(b => b === CreditFormatPageComponent.displayName)),
    [children]
  );

  const getDocumentTitle = useCallback((title?: string) => `${title} - PLI`, []);

  const invariantFilters: InvariantFilter[] = useMemo(() => {
    const existingFilters =
      (
        mainContentArea?.find(item =>
          item.contentType?.includes('SearchResultBlock')
        ) as SearchResultBlock
      )?.invariantFilters ?? [];

    const creditFormatInvariantFilters =
      children
        ?.filter(c => c.contentType?.includes('CreditFormatPage'))
        .map((formatPage: CreditFormatPage) => ({
          name: formatPage.name,
          value: formatPage.shortDescription,
          facetGroupType: 'ProgramFormat'
        })) ?? [];

    return [...existingFilters, ...creditFormatInvariantFilters];
  }, [children, mainContentArea]);

  const formatSearchInitialQuery = useCallback(
    (itemClasses: number[] | undefined = undefined) => {
      const searchResultBlock = mainContentArea?.find(item =>
        item.contentType?.includes('SearchResultBlock')
      ) as SearchResultBlock;

      if (searchResultBlock) {
        const query = searchResultBlock.initialSearchQuery;

        const itemClassParam = itemClasses?.length
          ? `includeItemClass=${itemClasses?.sort((a, b) => a - b)?.join(';')}`
          : undefined;
        if (itemClassParam) {
          if (query) {
            return `${query}&${itemClassParam}`;
          } else {
            return `?ContentTabs=Programs&${itemClassParam}`;
          }
        }
        return query;
      }
    },
    [mainContentArea]
  );

  const renderMainContentArea = useCallback(
    (
      creditTrackerCode?: string,
      includeFormatInvariants?: boolean,
      renderSectionTitle: boolean = true,
      itemClasses: number[] | undefined = undefined
    ) => {
      if (!mainContentArea?.length) return;

      const contents = mainContentArea
        .map(content => (content.contentType ? content : content.contentLink?.expanded))
        .filter(content => !!content);

      const extraProps = {
        smallImage: true,
        creditTrackerCode,
        initialSearchQuery: formatSearchInitialQuery(itemClasses),
        ...(!!includeFormatInvariants && { invariantFilters })
      };

      return (
        <>
          {!!mainBody && renderSectionTitle && (
            <SectionTitle
              title={extractTextFromHTML(mainBody!)}
              titleSize="h6"
              divider={{ color: 'light' }}
            ></SectionTitle>
          )}
          <ContentArea
            components={contents}
            propertyName="mainContentArea"
            componentsProps={extraProps}
          />
        </>
      );
    },
    [formatSearchInitialQuery, invariantFilters, mainBody, mainContentArea]
  );

  /**@private */
  const formatLinks = useMemo(() => {
    if (children) {
      return {
        linkBlocks: (children as (CreditInfoTypePage | CreditFormatPage)[]).map(a => {
          const text = extractTextFromHTML(a?.secondaryHeader ?? a.mainBody ?? '');
          return {
            contentType: ['LinkBlock'],
            link: { href: a.url ?? '#', text: text, title: text }
          } as LinkBlock;
        })
      };
    }
  }, [children]);

  const renderTopicLinks = useCallback(
    (isLoading?: boolean) => {
      return (
        <div className="[&>:first-child]:!px-0">
          {isLoading ? (
            <LinkGridShimmer />
          ) : (
            !!formatLinks?.linkBlocks?.length && (
              <LinkGridBlock
                {...formatLinks}
                elementType="p"
                heading="Explore by credit types"
                layout="3"
                numberOfRows={1}
              />
            )
          )}
        </div>
      );
    },
    [formatLinks]
  );

  const renderHeaderLinks = useCallback(
    (parentLinks?: IconMessageLink[] | null) => {
      let allLinks: HeaderLink[] = [];
      if (headerLinks?.length) {
        allLinks =
          headerLinks?.map((a: IconLinkBlock) => {
            const _link = a.link as LinkItem;
            return {
              href: _link.href,
              target: _link.target,
              text: _link.text,
              icon: a.icon
            } as HeaderLink;
          }) ?? [];
      } else {
        allLinks =
          parentLinks?.map(a => {
            return {
              href: a.link,
              text: a.message,
              icon: a.icon
            } as HeaderLink;
          }) ?? [];
      }

      if (allLinks?.length) {
        return (
          <div className="flex flex-row flex-wrap items-center justify-start">
            {allLinks.map((block, i) => {
              const containerClasses = classnames(
                'group my-auto flex w-fit basis-auto flex-row items-center justify-between',
                {
                  'mr-5': i != allLinks?.length - 1,
                  'pt-2': !!block?.icon,
                  'mb-1': !block?.icon
                }
              );
              return (
                <div key={`link-${i}`} className={containerClasses}>
                  {!!block.icon && (
                    <span className="mr-2 rounded-full border border-black p-2 text-black group-hover:border-red-brand group-hover:text-red-brand">
                      <Icon name={block.icon} size="small" key={`icon-${i}`} />
                    </span>
                  )}
                  <Link
                    className="!text-1 font-medium no-underline"
                    text={block?.text}
                    href={block?.href}
                    key={`linkitem-${i}`}
                    target={block?.target}
                  />
                </div>
              );
            })}
          </div>
        );
      }
    },
    [headerLinks]
  );

  const getApprovalMessage = useCallback(
    (ancestors?: IContent[]) => {
      if (approvedMessaged?.length) {
        const first = approvedMessaged[0] as IconBlockProps;
        return {
          message: first?.copy,
          icon: first?.icon
        };
      }

      const ancestorMessages = ancestors
        ?.filter(a => !!(a as ApprovalMessage)?.approvedMessaged)
        ?.flatMap(b => (b as ApprovalMessage).approvedMessaged);
      for (const ancestorMessage of ancestorMessages ?? []) {
        const message = ancestorMessage?.contentLink?.expanded as IconBlockProps;
        if (message) {
          return {
            message: message?.copy,
            icon: message?.icon
          };
        }
      }
    },
    [approvedMessaged]
  );

  const getAncestorHeaderLinks = useCallback((ancestors?: IContent[]) => {
    for (const a of ancestors?.map(a => a as HeaderLinks) ?? []) {
      if (a?.headerLinks?.length) {
        return a?.headerLinks?.map(a => {
          const item = a.contentLink?.expanded as Omit<IconMessageLink, 'link'> & {
            link: LinkItem;
          };
          return {
            icon: item.icon,
            link: item.link?.href,
            message: item.link?.text
          } as IconMessageLink;
        });
      }
    }
  }, []);

  /** Search for matching CLE regions based on a search phrase. */
  const search = useCallback(
    (searchPhrase: string, items?: string[]) => {
      if (!items?.length) return;

      if (!searchPhrase?.length || searchPhrase?.trim() == '') return;

      searchPhrase = searchPhrase.toLowerCase();
      return kmpSearch(searchPhrase.toLowerCase(), items)?.sort(
        (a, b) => a.toLowerCase().indexOf(searchPhrase) - b.toLowerCase().indexOf(searchPhrase)
      );
    },
    [kmpSearch]
  );

  const renderCreditClassPage = useCallback(
    (excludePageHeader?: boolean) => {
      return <ContentArea components={children} componentsProps={{ excludePageHeader }} />;
    },
    [children]
  );

  const getItemClassesForSearch = useCallback((creditFormats?: string) => {
    const sourceFormats =
      creditFormats
        ?.split(',')
        ?.map(a => a.split(';')?.map(b => parseInt(b)))
        ?.flat()
        ?.filter(a => isNumberNotEmpty(a)) ?? [];
    const searchFormats: number[] = [];
    for (const itemClass of [...new Set(sourceFormats)]) {
      if (searchFormats.indexOf(itemClass) < 0) {
        searchFormats.push(itemClass);
      }
    }
    return searchFormats;
  }, []);

  const filteredBreadcrumbs = useMemo(() => {
    return formatBreadCrumbs(
      breadcrumbs as BreadcrumbProps[],
      !!hideInBreadcrumbs || !!allChildrenAreFormats
    );
  }, [allChildrenAreFormats, breadcrumbs, hideInBreadcrumbs]);

  const getSidePanelMessages = useCallback(
    (ancestors?: IContent[]) => {
      if (creditAnnouncements?.length) {
        return creditAnnouncements
          ?.filter((a: BannerMessageBlock) => a.showOnSidePanel)
          ?.map((banner: BannerMessageBlock) => banner as AnnouncementMessage);
      }

      for (const a of ancestors ?? []) {
        const ancestorMessages: CreditAnnouncements = a as CreditAnnouncements;
        if (ancestorMessages?.creditAnnouncements?.length) {
          return ancestorMessages?.creditAnnouncements
            ?.map(a => a?.contentLink?.expanded as BannerMessageBlock)
            ?.filter((a: BannerMessageBlock) => a.showOnSidePanel)
            ?.map((banner: BannerMessageBlock) => banner as AnnouncementMessage);
        }
      }
    },
    [creditAnnouncements]
  );

  return {
    isChildItemsLoading,
    isChildItemsFetching,
    children,
    allChildrenAreFormats,
    filteredBreadcrumbs,
    getDocumentTitle,
    getSidePanelMessages,
    renderCreditClassPage,
    renderMainContentArea,
    search,
    renderHeaderLinks,
    renderTopicLinks,
    fetchRelatedPagesContent,
    getUserStatusPreferences,
    getApprovalMessage,
    getItemClassesForSearch,
    getAncestorHeaderLinks
  };
};

export default useCreditInfo;
