import type { FeaturedContentBlock as FeaturedContentBlockProps } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';
import Text from '@/components/cms/Text';
import classnames from 'classnames';
import SectionTitle from '../ui/Titles/SectionTitle';
import Link from '../ui/Link';
import Block from '../cms/Block';

// eyebrowText?: string;

const FeaturedContentBlock: React.FC<FeaturedContentBlockProps> = ({
  contentLink,
  layout,
  heading,
  subheading,
  seeAllLink,
  items = []
}) => {
  return (
    <Block contentLinkID={contentLink?.id} className="container module-spacing">
      {subheading ? (
        <div className="mb-10 lg:mb-16">
          <div className="mb-2 flex items-center justify-between">
            <Text element="h2" propertyName="Heading" className="heading-6-medium">
              {heading}
            </Text>
            <Link
              href={seeAllLink?.href}
              target={seeAllLink?.target}
              text={seeAllLink?.text}
              variant="bold-link-with-icon"
              icon="chevron-right"
              propertyName="SeeAllLink"
            />
          </div>
          <Text propertyName="Subheading" className="heading-4 w-full lg:w-[55%]">
            {subheading}
          </Text>
        </div>
      ) : (
        <SectionTitle
          propertyNameTitle="Title"
          title={heading as string}
          divider={{ color: 'light' }}
          className="mb-10 lg:mb-16"
        >
          <Link
            href={seeAllLink?.href}
            target={seeAllLink?.target}
            text={seeAllLink?.text}
            variant="bold-link-with-icon"
            icon="chevron-right"
            propertyName="SeeAllLink"
          />
        </SectionTitle>
      )}

      <ContentArea
        propertyName="Items"
        components={items}
        className={classnames('grid grid-cols-1 gap-x-4 gap-y-12 md:grid-cols-2 lg:gap-x-6', {
          'lg:grid-cols-3': parseInt(layout as string) === 3
        })}
      />
    </Block>
  );
};

export default FeaturedContentBlock;
