import Divider from '@/components/ui/Divider';
import PLILogo from '../assets/pli-logo.svg?react';
import { useLazyGetRedirectForUrlQuery } from '@/redux/api/client/redirect';
import { useEffect, useRef } from 'react';
import { appInsights } from '@/lib/ApplicationInsightsService';
import withNav from '@/components/hocs/withNav';
import { trackPageExceptionEvent } from '@/analytics/pages';
import {
  FooterBlock as FooterBlockProps,
  HeaderBlock as HeaderBlockProps,
  ReferencePageSettings
} from '@/@types/content';

interface ErrorPageProps {
  title?: string;
  message: string;
  possibleRedirectTo?: string;
  headerBlock?: HeaderBlockProps;
  footerBlock?: FooterBlockProps;
  siteLabels?: ReferencePageSettings;
}

const ErrorPageComponent: React.FC<ErrorPageProps> = ({
  title = 'We’re sorry, our website is undergoing maintenance and is currently unavailable.',
  message,
  possibleRedirectTo
}) => {
  const [getReidrectForUrlQuery] = useLazyGetRedirectForUrlQuery();
  const firstLoad = useRef(true);
  useEffect(() => {
    if (possibleRedirectTo && firstLoad.current) {
      firstLoad.current = false;
      getReidrectForUrlQuery(possibleRedirectTo)
        .unwrap()
        .then(response => {
          if (response) {
            document.location.href = response;
          }
        })
        .catch(e => appInsights.trackException(e));
    }
    trackPageExceptionEvent();
  });

  return (
    <div className="flex h-[1319px] min-h-screen flex-col"       data-component={"ErrorPage"}>
      <header className="flex h-24 shrink-0 items-center bg-black text-white">
        <div className="container">
          <PLILogo className="h-8" aria-label="PLI Logo" />
        </div>
      </header>
      <main className="container grow py-[100px]">
        <h1 className="heading-2 mb-6 max-w-[998px]">{title}</h1>
        <div className="max-w-[633px]">
          <p className="text-1 mb-6">
            Please check back later. We apologize for any inconvenience.
          </p>
          <p className="text-1 mb-6">
            If you need immediate assistance accessing a program or other resource, please contact{' '}
            <a href="mailto:info@pli.edu" className="underline">
              info@pli.edu
            </a>{' '}
            or call us at{' '}
            <a href="tel:800.260.4754" className="underline">
              800.260.4PLI
            </a>
            .
          </p>
          <p className="text-1 font-bold">{message}</p>
        </div>
      </main>
      <footer className="bg-black py-16 text-white lg:py-20">
        <div className="container">
          <div className="max-w-[287px] md:max-w-[331px] lg:max-w-[414px]">
            <PLILogo className="mb-8 h-10" aria-label="PLI Logo" />
            <p className="heading-6-regular">
              PLI is a nonprofit learning organization dedicated to keeping attorneys and other
              professionals at the forefront of knowledge and expertise.
            </p>
          </div>
          <Divider color="dark" className="my-8" />
          <p className="text-2 font-medium">© 1996-2024 Practising Law Institute</p>
        </div>
      </footer>
    </div>
  );
};

const ErrorPage = withNav(ErrorPageComponent);
export default ErrorPage;
