import { formatBEDateToFEDate } from '@/utils/helpers';
import { formatDateFilter, isEndDateFilter, isStartDateFilter } from '../../Filter/DateFilterUtils';
import { CERTIFICATES_FILTERS, CUSTOM_YEAR_VALUE, VALUE_DELIMITER } from './constants';
import { OnFilterChange } from './types';
import { appendFilterValue, getDateRangeParts, removeFilterValue } from './utils';
import { trackFilterEvent } from '@/analytics/certificates';
import { FilterContext } from '@/analytics/constants';

export const onJurisdictionFilterChange = (
  context: FilterContext,
  onFilterChange: OnFilterChange,
  value?: string,
  checked?: boolean
) => {
  if (checked && value) {
    trackFilterEvent({
      facetGroup: CERTIFICATES_FILTERS.JURISDICTIONS,
      facetValue: value,
      context
    });
  }
  onFilterChange(state => ({
    ...state,
    jurisdictions: checked
      ? appendFilterValue(state.jurisdictions, value)
      : removeFilterValue(state.jurisdictions, value)
  }));
};

export const onSourceFilterChange = (
  context: FilterContext,
  onFilterChange: OnFilterChange,
  value?: string,
  checked?: boolean
) => {
  if (checked && value) {
    trackFilterEvent({
      facetGroup: CERTIFICATES_FILTERS.SOURCE,
      facetValue: value,
      context
    });
  }
  onFilterChange(state => ({
    ...state,
    source: checked
      ? appendFilterValue(state.source, value)
      : removeFilterValue(state.source, value)
  }));
};

export const onYearFilterChange = (
  context: FilterContext,
  onFilterChange: OnFilterChange,
  value?: string,
  checked?: boolean
) => {
  if (checked && value && value !== CUSTOM_YEAR_VALUE.toString()) {
    trackFilterEvent({
      facetGroup: CERTIFICATES_FILTERS.YEAR,
      facetValue: value,
      context
    });
  }
  onFilterChange(state => ({ ...state, year: !!checked && !!value ? parseInt(value) : undefined }));
};

export const onDateRangeFilterChange = (
  context: FilterContext,
  onFilterChange: OnFilterChange,
  name?: string,
  value?: string
) => {
  const isStartDate = isStartDateFilter(name);
  const isEndDate = isEndDateFilter(name);
  if (!isStartDate && !isEndDate) {
    return;
  }
  const dateFormatted = formatBEDateToFEDate(value) ?? '';
  if (value) {
    trackFilterEvent({
      facetGroup: CERTIFICATES_FILTERS.DATE_RANGE,
      facetValue: formatDateFilter(name, value),
      context
    });
  }
  onFilterChange(state => {
    const dataRange = getDateRangeParts(state);
    return {
      ...state,
      dateRange: isStartDate
        ? `${dateFormatted}${VALUE_DELIMITER}${dataRange[1] ?? ''}`
        : `${dataRange[0] ?? ''}${VALUE_DELIMITER}${dateFormatted}`
    };
  });
};
