import classnames from 'classnames';
import Text from '@/components/cms/Text';
import Button from '@/components/ui/Buttons/Button';
import DropdownMenu, { TDropdownMenuItem } from '@/components/ui/DropdownMenu';
import { Fragment, useEffect, useState } from 'react';
import Shimmer from '@/components/ui/Shimmer';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Icon from '@/components/ui/Icon';
import Divider from '../ui/Divider';
import type { PodcastSeriesBlock as PodcastSeriesBlockProps } from '@/@types/content';
import Block from '@/components/cms/Block';
import { useGetCardQuery } from '@/redux/api/client/card';
import CardBuilder from '@/components/ui/Cards/CardBuilder';

type ServiceLink = {
  icon: string;
  serviceName: string;
  serviceLink: string;
};

type LatestEpisode = {
  parentProductCode: string;
};

const PodcastSeriesBlock: React.FC<PodcastSeriesBlockProps> = ({
  contentLink,
  header,
  bodyCopy,
  viewAllEpisodesLink,
  moreLabel,
  listenOnLabel,
  podcastSeries
}) => {
  const maxServiceLinks = 3;

  const isXsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);
  const isLarge = useMediaQuery(ScreenSizeQueries.lg);
  const serviceLinks: ServiceLink[] = podcastSeries?.seriesServiceLinks as ServiceLink[];
  const latestEpisode: LatestEpisode[] = podcastSeries?.latestEpisode as LatestEpisode[];
  let podcastCode: string | undefined = undefined;
  podcastCode =
    latestEpisode && latestEpisode.length > 0 ? latestEpisode[0].parentProductCode : undefined;

  const [dropdownItems, setDropdownItems] = useState<TDropdownMenuItem[]>();
  const [podcastQueryUrl, setPodcastQueryUrl] = useState<string | undefined>(undefined);

  const { data: dataPodcast, isLoading: isLoadingPodcast } = useGetCardQuery(
    podcastQueryUrl as string,
    {
      skip: !podcastQueryUrl
    }
  ) as { data: Record<string, unknown>[]; isLoading: boolean };

  useEffect(() => {
    if (serviceLinks && serviceLinks.length > 0) {
      setDropdownItems(
        serviceLinks.map(link => ({
          label: link.serviceName,
          onClick: () => handleOpenInNewTab(link.serviceLink as string)
        }))
      );
    }
  }, [serviceLinks]);

  useEffect(() => {
    const podcastQueryString = `/search/product-code?ProductCodes=${podcastCode}`;
    setPodcastQueryUrl(podcastQueryString);
  }, [podcastCode]);

  const handleOpenInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const viewAllCtaClasses = classnames({
    'w-full !px-2': isXsOnly,
    '!h-[44px]': !isLarge
  });

  const listenOnClasses = classnames('border-silver', {
    'w-full !px-2': isXsOnly,
    '!h-[44px]': !isLarge
  });

  const ViewAllCta = () => (
    <Button
      propertyName="ViewAllEpisodesLink"
      iconRight={isXsOnly ? 'chevron-right' : undefined}
      size="short"
      target={viewAllEpisodesLink?.target}
      title={viewAllEpisodesLink?.title}
      href={viewAllEpisodesLink?.href}
      label={viewAllEpisodesLink?.text}
      className={viewAllCtaClasses}
      color="black"
    />
  );

  const ServiceCtas = ({ showLatestDivider = false }) => (
    <div className={classnames('flex items-center justify-start gap-4')}>
      {serviceLinks?.slice(0, maxServiceLinks)?.map((link, index) => {
        const numberOfItems = Math.min(serviceLinks.length, maxServiceLinks);
        let showDivider = showLatestDivider && index === numberOfItems - 1;
        if (index < numberOfItems - 1) {
          showDivider = true;
        }

        return (
          <Fragment key={index}>
            <button
              onClick={() => handleOpenInNewTab(link.serviceLink as string)}
              className={classnames('flex items-center justify-start gap-2')}
            >
              {link.icon && <Icon name={link.icon} size="large" />}
              <Text element="span" className="text-2 font-bold">
                {link.serviceName}
              </Text>
            </button>
            {showDivider && <Divider color="silver" orientation="vertical" className="!h-4" />}
          </Fragment>
        );
      })}
    </div>
  );

  const hasMoreServiceLinks = serviceLinks?.length > maxServiceLinks;

  return (
    <Block contentLinkID={contentLink?.id} className="module-spacing">
      <div
        className={classnames(
          'container flex flex-col gap-12',
          'lg:flex-row lg:items-center lg:gap-6'
        )}
      >
        <div className={classnames('w-full', 'lg:w-1/2')}>
          <div className={classnames('flex flex-col gap-8', 'lg:gap-14')}>
            <div className={classnames('flex flex-col gap-4', 'lg:max-w-[520px] lg:gap-6')}>
              <Text
                propertyName="Header"
                element="h4"
                className={classnames('heading-4', 'lg:max-w-[410px]')}
              >
                {header}
              </Text>

              <Text className="text-gray-dark" propertyName="BodyCopy" element="p">
                {bodyCopy}
              </Text>

              {isLarge && <ViewAllCta />}
            </div>

            {dropdownItems && (
              <Fragment>
                {isLarge ? (
                  <div className={classnames('flex items-center justify-start gap-4')}>
                    <ServiceCtas showLatestDivider={hasMoreServiceLinks} />
                    {hasMoreServiceLinks && (
                      <DropdownMenu
                        button={() => (
                          <button
                            className={classnames(
                              'flex cursor-pointer items-center justify-center gap-1',
                              'hover:text-red'
                            )}
                          >
                            <Icon name="more-ios" size="medium" />
                            <span className="text-2-bold">{moreLabel}</span>
                          </button>
                        )}
                        align="start"
                        items={dropdownItems?.slice(maxServiceLinks)}
                      />
                    )}
                  </div>
                ) : (
                  <div className={classnames('flex flex-col gap-2', 'sm:flex-row sm:gap-4')}>
                    <ViewAllCta />

                    <DropdownMenu
                      button={({ isOpen }) => (
                        <Button
                          propertyName="ListenOnLabel"
                          size={isXsOnly ? 'short' : 'small'}
                          label={listenOnLabel}
                          iconRight={isOpen ? 'chevron-up' : 'chevron-down'}
                          className={listenOnClasses}
                          color="outline-black"
                        />
                      )}
                      align="start"
                      items={dropdownItems}
                    />
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>

        <div className={classnames('w-full', 'lg:w-1/2')}>
          {isLoadingPodcast || !dataPodcast || dataPodcast.length < 1 ? (
            <Shimmer className={classnames('h-[352px] w-full', 'sm:h-[302px]')} />
          ) : (
            <CardBuilder
              cardProps={dataPodcast[0]}
              widthAuto={true}
              size={isXsOnly ? 'small' : isLarge ? 'medium' : 'large'}
            />
          )}
        </div>
      </div>
    </Block>
  );
};

export default PodcastSeriesBlock;
