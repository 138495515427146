import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageLoading from '@/routes/PageLoading';
import {
  contentDeliveryApi,
  useGetContentByFriendlyUrlQuery,
  useGetGlobalElementQuery
} from '@/redux/api/contentDeliveryAPI';
import { Page } from '@/lib/ComponentFactory';
import { isEditOrPreviewMode, subcribeContentSavedEvent } from '@/lib/editModeHelpers';
import ErrorPage from '@/pages/ErrorPage';
import { isErrorWithMessage, isFetchBaseQueryError } from '@/redux/helpers';
import useQueryParams from '@/hooks/useQueryParams';
import { expandChildren } from './helpers/expandChildren';
import { env } from '@/utils/types';
import { setPageLinks, setSiteLabels } from '@/redux/slices/pageSlice';
import { ReferencePageSettings } from '@/@types/content';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import { RootState } from '@/redux/store';

import PageData from './PageData';
import { useAuth } from 'react-oidc-context';
import { isContentType } from './helpers/contentType';

const PageMapper: React.FC = React.memo(() => {
  const authReady = useSelector((state: RootState) => state.auth.ready);
  const userId = useSelector((state: RootState) => state.auth.name || 'ANON');

  const params = useParams();
  const modeEdit = isEditOrPreviewMode();
  const dispatch = useDispatch();
  const firstLoad = useRef(useQueryParams());
  const auth = useAuth();
  const { data: footerData } = useGetGlobalElementQuery(
    { name: 'footer', userId: userId },
    { skip: !authReady }
  );
  const { data: headerData, isLoading: headerIsLoading } = useGetGlobalElementQuery(
    { name: 'header', userId: userId },
    { skip: !authReady }
  );
  const { data: labelsData } = useGetGlobalElementQuery(
    { name: 'labels', userId: userId },
    { skip: !authReady }
  );
  const { data, isError, isLoading, isSuccess, currentData, error, refetch } =
    useGetContentByFriendlyUrlQuery({
      friendlyUrl: params['*']!,
      query: {
        expand: ['mainContentArea'],
        ...(firstLoad.current ? firstLoad.current : {})
      },
      userId: userId
    });

  const debouncedLoading = useDebounceLoading(isLoading);

  useEffect(() => {
    if (modeEdit) {
      const communicationScript = document.createElement('script');
      const env = (window as unknown as Record<string, unknown>)?.env as env | null;
      communicationScript.src = `${env?.CONTENT_DELIVERY_API}/episerver/cms/latest/clientresources/communicationinjector.js`;
      document.head.appendChild(communicationScript);

      const deliveryPropertyRendererScript = document.createElement('script');
      deliveryPropertyRendererScript.src = `${env?.CONTENT_DELIVERY_API}/episerver/cms/latest/clientresources/deliverypropertyrenderer.js`;
      document.head.appendChild(deliveryPropertyRendererScript);

      subcribeContentSavedEvent(message => {
        console.info(message);
        dispatch(contentDeliveryApi.util.invalidateTags([{ type: 'Content', id: params['*'] }]));
        refetch();
      });
    }
  }, [dispatch, modeEdit, params, refetch]);

  useEffect(() => {
    if (labelsData) {
      const siteLabels = labelsData as ReferencePageSettings;
      dispatch(
        setPageLinks({
          Certificates: siteLabels?.myCertificatesPageReference?.url,
          Library: siteLabels.myLibraryPageReference?.url,
          Licenses: siteLabels.myLicensesPageReference?.url,
          Search: siteLabels.searchPageReference?.url,
          PlusMembershipInfo: siteLabels.plusMembershipInfoPageReference?.url,
          Profile: siteLabels.profilePageReference?.url
        })
      );
      dispatch(setSiteLabels(siteLabels));
    }
  }, [labelsData, dispatch]);
  useEffect(() => {
    if (data) {
      //DIAG: code added to prevent using cms auth cookies in request from fe
      // this code technically is not needed but i'll keep only log part
      // auth.signinSilent(); was causing infinite loop when refresh token has expired
      // because same operation was called by Expired event in oidc
      const customerId = (data as Record<string, number>)['customerId'];
      if (customerId != undefined) {
        if (customerId > 0) {
          if (auth?.user?.profile?.IndividualSK) {
            if (customerId != auth.user.profile.IndividualSK) {
              console.info(
                `DIAG:Local User State doesn't match to CMS Authorized identity ${customerId}-${auth.user.profile.IndividualSK}`
              );
              //should logout of CMS here
              //auth.signoutRedirect();
              /*if (navigator.serviceWorker != null) {
                  navigator.serviceWorker.getRegistration().then(swr => {
                    swr?.unregister()?.then(() => {
                      document.location.reload();
                    });
                  });
                }*/
            }
          } else {
            console.info(`DIAG:Local User is logged out but CMS authorized for ${customerId}`);
            //should logout of CMS here
            //auth.signoutRedirect();
          }
        } else if (auth?.isAuthenticated) {
          console.info(
            `DIAG:Token is unauthorized on CMS but user signed in locally as ${auth?.user?.profile?.IndividualSK}`
          );
          //const IndividualSK = auth?.user?.profile?.IndividualSK;
          //auth.isAuthenticated = false;
          //auth.revokeTokens(["access_token"]).then(() => {
          //  console.warn(`Trying renew for ${auth?.user?.profile?.IndividualSK}`);
          //  auth.startSilentRenew();
          //})
          //auth.revokeTokens().then(() => auth.removeUser().then(() => {
          //    auth.clearStaleState().then(() => {
          //        console.warn(`User removed ${IndividualSK}`);
          //        document.location.reload();
          //      }
          //    );
          //  }
          //));

          //auth.signoutRedirect();
          //auth.clearStaleState();
          //auth.signinSilent();
        }
      }
    }
  }, [data, auth, auth.user?.profile]);
  // Handle error state
  if (isError) {
    let content, message, status;

    if (isFetchBaseQueryError(error)) {
      if (error.data) {
        try {
          content = JSON.parse(error.data as string);

          if (content && isContentType(content, 'ErrorPage')) {
            return (
              <Page
                {...expandChildren(content)}
                headerBlock={headerData}
                footerBlock={footerData}
                siteLabels={labelsData}
              />
            );
          }
        } catch (e) {
          // couldn't get data from error
          console.error(e);
        }
      }

      if (!message && 'error' in error) message = error.error;
      status = 'originalStatus' in error ? error.originalStatus : error.status;

      if (status === 400) {
        return (
          <ErrorPage
            message={'400 Bad Request'}
            possibleRedirectTo={params['*']}
            headerBlock={headerData}
            footerBlock={footerData}
            siteLabels={labelsData}
          />
        );
      }
      if (status === 403)
        return (
          <ErrorPage
            message={'403 Access forbidden'}
            possibleRedirectTo={params['*']}
            headerBlock={headerData}
            footerBlock={footerData}
            siteLabels={labelsData}
          />
        );
      if (status === 404)
        return (
          <ErrorPage
            message={'404 Page not found'}
            possibleRedirectTo={params['*']}
            headerBlock={headerData}
            footerBlock={footerData}
            siteLabels={labelsData}
          />
        );
      return (
        <ErrorPage
          message={`${status} ${message}`}
          possibleRedirectTo={params['*']}
          headerBlock={headerData}
          footerBlock={footerData}
          siteLabels={labelsData}
        />
      );
    } else if (isErrorWithMessage(error)) {
      return (
        <ErrorPage
          message={error.message}
          possibleRedirectTo={params['*']}
          headerBlock={headerData}
          footerBlock={footerData}
          siteLabels={labelsData}
        />
      );
    }
  }

  // Handle loading/no data state
  if ((debouncedLoading && (!authReady || !isSuccess || !currentData)) || headerIsLoading)
    return <PageLoading show={true} />;

  return (
    <>
      {data && (
        <Page
          {...{
            ...data,
            headerBlock: headerData,
            footerBlock: footerData,
            siteLabels: labelsData
          }}
        />
      )}
      <PageData>{data}</PageData>
    </>
  );
});

export default PageMapper;
