import React, { useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';

import Radio from '../Form/Radio';
import Checkbox from '../Form/Checkbox';
import type {
  FilterItem,
  RadioItem,
  CheckItem,
  RadioFilterGroupProps,
  CheckFilterGroupProps,
  SelectFilterGroupProps,
  DateRangeFilterProps
} from './types';
import Selector from '../Form/Selector';
import classnames from 'classnames';
import { ColumnLayout } from '../SearchResults/constants';
import DateFilter from './DateFilter';
import {
  StartDateFilterLabel,
  EndDateFilterLabel,
  isEndDateFilter,
  isStartDateFilter
} from './DateFilterUtils';
import { BackendDateFormat, formatDate, parseDate } from '@/utils/helpers';
import RadioButton from '../Form/Radio/RadioButton';

const FilterGroupContainer = ({
  children,
  layout
}: {
  children: ReactNode | ReactNode[];
  layout?: ColumnLayout | null;
}) => {
  const colCount = Math.ceil(React.Children.count(children) / 2);

  return (
    <div
      className={classnames('grid gap-x-6 gap-y-4 sm:grid-flow-col sm:grid-cols-2', {
        'grid-cols-2': layout === ColumnLayout.STATIC,
        'grid-cols-1': layout === ColumnLayout.RESPONSIVE
      })}
      style={{ gridTemplateRows: `repeat(${colCount}, minmax(0, max-content))` }}
    >
      {children}
    </div>
  );
};

export const RadioFilterGroup = ({
  items,
  value,
  onValueChange,
  onClear,
  className,
  canClear = false
}: Pick<
  RadioFilterGroupProps,
  'items' | 'value' | 'onValueChange' | 'onClear' | 'className' | 'canClear'
>) => {
  Radio.Item = RadioButton;

  return (
    <>
      {canClear && value && (
        <button
          className="text-link text-2 absolute right-8 top-0 z-1 ml-auto underline"
          onClick={onClear}
        >
          Clear
        </button>
      )}
      <div className="relative flex w-full flex-col">
        <Radio asFormField={false} value={value} onValueChange={onValueChange}>
          <FilterGroupContainer>
            {items.map((item: FilterItem<RadioItem>) => (
              <Radio.Item
                value={item.value}
                checked={item.value === value}
                key={item.value}
                aria-label={item.value}
                className={className}
                disabled={item.disabled}
              >
                {item.label}
              </Radio.Item>
            ))}
          </FilterGroupContainer>
        </Radio>
      </div>
    </>
  );
};

export const CheckFilterGroup = ({
  items,
  onValueChange,
  className
}: Pick<CheckFilterGroupProps, 'items' | 'onValueChange' | 'className'>) => {
  return (
    <div className="w-full">
      <FilterGroupContainer>
        {items.map((item: FilterItem<CheckItem>) => (
          <Checkbox
            aria-label={item.label}
            asFormField={false}
            key={item.value}
            checked={item.checked}
            disabled={item.disabled}
            onCheckedChange={(checked: boolean) => onValueChange?.(item.value, checked)}
          >
            <Checkbox.Label aria-label={item.value} className={className}>
              {item.label}
            </Checkbox.Label>
          </Checkbox>
        ))}
      </FilterGroupContainer>
    </div>
  );
};

export const SelectorFilterGroup = ({
  items,
  onValueChange,
  visibleCount,
  columnLayout,
  className
}: Pick<
  SelectFilterGroupProps,
  'items' | 'onValueChange' | 'visibleCount' | 'columnLayout' | 'className'
>) => {
  return (
    <div className="w-full">
      <FilterGroupContainer layout={columnLayout}>
        {items.map(
          ({
            // pull these props out of the item so they don't get passed to the Selector component
            sectionId: _sectionId,
            sectionTitle: _sectionTitle,
            sectionIsTopLevel: _sectionIsTopLevel,
            ...item
          }) => {
            return (
              <Selector
                {...item}
                size="small"
                className={className}
                key={item.value}
                checked={item.checked}
                onCheckedChange={(checked: boolean) => onValueChange?.(item.value, checked)}
                count={visibleCount ? item.count : undefined}
                maxLines={2}
              />
            );
          }
        )}
      </FilterGroupContainer>
    </div>
  );
};

export const DateRangeFilterGroup = ({
  items,
  onValueChange,
  columnLayout,
  className,
  minDate,
  maxDate
}: DateRangeFilterProps) => {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const startDateFilter = useMemo(() => items.find(item => isStartDateFilter(item.label)), [items]);
  const endDateFilter = useMemo(() => items.find(item => isEndDateFilter(item.label)), [items]);

  useEffect(() => {
    if (!startDateFilter || !endDateFilter) {
      return;
    }
    setStartDate(parseDate(startDateFilter?.value, BackendDateFormat));
    setEndDate(parseDate(endDateFilter?.value, BackendDateFormat));
  }, [startDateFilter, endDateFilter]);

  const handleOnStartDateChange = useCallback(
    (value: string) => {
      const date = parseDate(value);
      if (date) {
        setStartDate(date);
        onValueChange?.(formatDate(date, BackendDateFormat), startDateFilter?.label);
      }
    },
    [startDateFilter, onValueChange]
  );

  const handleOnEndDateChange = useCallback(
    (value: string) => {
      const date = parseDate(value);
      if (date) {
        setEndDate(date);
        onValueChange?.(formatDate(date, BackendDateFormat), endDateFilter?.label);
      }
    },
    [endDateFilter, onValueChange]
  );

  if (!startDateFilter || !endDateFilter) {
    return null;
  }

  return (
    <div className="w-full">
      <FilterGroupContainer layout={columnLayout}>
        <DateFilter
          value={startDate}
          label={StartDateFilterLabel}
          disabled={startDateFilter.disabled}
          minDate={minDate}
          maxDate={endDate ? endDate : maxDate}
          className={className}
          onValueChange={handleOnStartDateChange}
          isStartDate={true}
        />
        <DateFilter
          value={endDate}
          label={EndDateFilterLabel}
          disabled={endDateFilter.disabled}
          minDate={startDate ? startDate : minDate}
          maxDate={maxDate}
          className={className}
          onValueChange={handleOnEndDateChange}
          isEndDate={true}
        />
      </FilterGroupContainer>
    </div>
  );
};

export const YearFilterGroup = ({
  dateRange,
  ...radioProps
}: Pick<
  RadioFilterGroupProps,
  'items' | 'value' | 'onValueChange' | 'onClear' | 'className' | 'canClear'
> & { dateRange?: DateRangeFilterProps }) => {
  return (
    <>
      <RadioFilterGroup {...radioProps} />
      {dateRange ? <DateRangeFilterGroup {...dateRange} /> : null}
    </>
  );
};
