import { ClientAPI } from '@/redux/api';
import type { OrderLineViewModel, CartResultApiModel } from '@/@types/client-api';

const cartServiceEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getCart: builder.query<void, void>({
      providesTags: [{ type: 'CartData' }],
      query: params => ({
        url: `/commerce/carts/${params}`,
        method: 'GET'
      })
    }),
    postCartItem: builder.mutation<CartResultApiModel, OrderLineViewModel>({
      invalidatesTags: [{ type: 'CartData' }],
      query: body => ({
        url: `/commerce/carts`,
        method: 'POST',
        body
      })
    }),
    getLightCart: builder.query<void, void>({
      providesTags: [{ type: 'CartData' }],
      query: params => ({
        url: `/commerce/carts/cart-items/${params}`,
        method: 'GET'
      })
    })
  })
});

export const { useGetCartQuery, usePostCartItemMutation, useGetLightCartQuery } =
  cartServiceEndpoints;

export default cartServiceEndpoints;
