import { ClientAPI } from '@/redux/api';
import { UserFacultyResponse } from '@/@types/client-api';

const userFacultyEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getUserFaculties: builder.query<UserFacultyResponse, void>({
      query: () => ({
        url: '/user/faculty',
        method: 'GET'
      })
    }),
    followFaculty: builder.mutation<boolean, number[]>({
      query: body => ({
        url: '/user/faculty/follow',
        method: 'POST',
        body
      })
    }),
    unfollowFaculty: builder.mutation<boolean, number[]>({
      query: body => ({
        url: '/user/faculty/unfollow',
        method: 'POST',
        body
      })
    })
  })
});

export const { useGetUserFacultiesQuery, useFollowFacultyMutation, useUnfollowFacultyMutation } =
  userFacultyEndpoints;

export default userFacultyEndpoints;
