import * as Checkbox from '@radix-ui/react-checkbox';
import * as Form from '@radix-ui/react-form';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';

interface SelectorLargeProps extends Checkbox.CheckboxProps {
  header?: string;
  copy?: string;
  asFormField?: boolean;
  rentalValue?: string;
}

const SelectorLarge: React.FC<SelectorLargeProps> = ({
  header,
  copy,
  asFormField,
  rentalValue,
  ...props
}) => {
  const { className } = props;

  const SelectorLargeField: React.FC<SelectorLargeProps> = ({ header, copy, ...props }) => (
    <Form.Field name={props.name || ''} className="[&_input]:!size-0">
      <Checkbox.Root
        {...props}
        className={classnames(
          'flex w-full cursor-pointer flex-col gap-2 rounded-lg border border-gray bg-gray-feather p-6 text-start transition-colors duration-short',
          'lg:p-8',
          'hover:border-black',
          'data-[state=checked]:border-2 data-[state=checked]:p-[23px] data-[state=checked]:lg:p-[31px]',
          className
        )}
      >
        <div
          className={classnames('relative my-[2px] size-4 rounded-sm border border-gray', {
            hidden: rentalValue
          })}
        >
          <Checkbox.Indicator className="absolute -left-px -top-px flex size-4 items-center justify-center rounded-sm bg-black text-white">
            <Icon name="checkmark" size="x-small" className="size-[14px]" />
          </Checkbox.Indicator>
        </div>
        {header && (
          <Form.Label
            className={classnames('text-1-medium cursor-pointer', {
              'flex w-full items-center justify-between': rentalValue
            })}
          >
            {header} <span>{rentalValue}</span>
          </Form.Label>
        )}
        {copy && (
          <Form.Message className="text-2 cursor-pointer text-gray-dark">{copy}</Form.Message>
        )}
      </Checkbox.Root>
    </Form.Field>
  );

  if (asFormField) {
    return <SelectorLargeField header={header} copy={copy} {...props} />;
  } else {
    return (
      <Form.Root>
        <SelectorLargeField header={header} copy={copy} {...props} />
      </Form.Root>
    );
  }
};

export default SelectorLarge;
