import { ClientAPI } from '@/redux/api';
import { IFacetGroup, SearchRequest, SearchResponse } from '@/@types/client-api';
import { RootState } from '@/redux/store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { FILTERS } from '@/components/ui/SearchResults/constants';
import { isStartEndDateFilter } from '@/components/ui/Filter/DateFilterUtils';
import { minimizeSearchRequest } from '@/components/ui/SearchResults/utils/searchRequest';

export type SearchRequestWithOptions = {
  requestBody: SearchRequest;
  postQuery?: string;
};

export const internalSearchEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getSearchResultsItems: builder.query<SearchResponse, SearchRequestWithOptions>({
      query: ({ requestBody, postQuery }) => {
        return {
          url: postQuery ? `/search?${postQuery}` : '/search',
          method: 'POST',
          body: requestBody
        };
      },
      merge: (currentCache, newResponse, { arg }) => {
        const requestBody = arg.requestBody;
        if (
          currentCache.searchResults?.searchResultsItems &&
          requestBody.currentPage &&
          requestBody.currentPage !== 1
        ) {
          // Add new items to the existing list
          currentCache.searchResults?.searchResultsItems?.push(
            ...(newResponse.searchResults?.searchResultsItems ?? [])
          );
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const requestBody = queryArgs.requestBody;
        const newQueryArgs = { ...requestBody, postQuery: queryArgs.postQuery };

        // Remove currentPage and pageSize from the query args
        // so that when user goes to a new page, we don't create a new cache entry.
        // (Instead, we update the previous entry in `merge` function above)
        if (newQueryArgs.currentPage) {
          delete newQueryArgs.currentPage;
        }

        if (newQueryArgs.pageSize) {
          delete newQueryArgs.pageSize;
        }

        if (newQueryArgs.explain !== undefined) {
          delete newQueryArgs.explain;
        }

        if (newQueryArgs.facetGroups?.length === 0) {
          delete newQueryArgs.facetGroups;
        }

        return newQueryArgs;
      },
      providesTags: result => [
        {
          type: 'Search',
          id: result?.searchResultSummary?.searchHash ?? 'EmptyInternalSearchResultsItems'
        }
      ]
    }),
    getSearchResultsFiltersOnly: builder.query<SearchResponse, SearchRequestWithOptions>({
      query: ({ requestBody, postQuery }) => ({
        url: postQuery ? `/search?${postQuery}` : '/search',
        method: 'POST',
        body: {
          ...requestBody,
          pageSize: 0,
          currentPage: 1,
          sort: undefined
        }
      }),

      serializeQueryArgs: ({ queryArgs }) => {
        const requestBody = queryArgs.requestBody;
        const newQueryArgs = { ...requestBody };

        // Delete props that will not affect the filters

        if (newQueryArgs.currentPage) {
          delete newQueryArgs.currentPage;
        }

        if (newQueryArgs.sort) {
          delete newQueryArgs.sort;
        }

        delete newQueryArgs.pageSize;

        if (newQueryArgs.facetGroups?.length === 0) {
          delete newQueryArgs.facetGroups;
        }

        return newQueryArgs;
      },
      providesTags: result => [
        {
          type: 'Search',
          id: result?.searchResultSummary?.searchHash ?? 'EmptySearchResultsFiltersOnly'
        }
      ]
    })
  })
});

export const makePrimaryRequest = async ({
  requestBody,
  postQuery,
  dispatch
}: {
  requestBody: SearchRequest;
  dispatch: ThunkDispatch<RootState, unknown, Action>;
  postQuery?: string;
}) =>
  dispatch(
    internalSearchEndpoints.endpoints.getSearchResultsItems.initiate({
      requestBody,
      postQuery
    })
  );

export const makeDisjunctiveFilterRequest = async ({
  facetGroup,
  request: { requestBody, ...rest },
  getState,
  dispatch
}: {
  facetGroup: IFacetGroup;
  request: SearchRequestWithOptions;
  getState: () => RootState;
  dispatch: ThunkDispatch<RootState, unknown, Action>;
}) => {
  const newRequest = minimizeSearchRequest({
    ...requestBody,
    // Make a request *without* the current filter group selections
    // to prevent selections within the group from affecting the results.
    // - We also send all unselected facets in case some are ad-hoc
    facetGroups: requestBody.facetGroups?.flatMap(group => {
      let facets = group.facets;

      if (group.groupTypeId === FILTERS.CONTENT_TABS) {
        // Content tabs don't influence filters so should never be sent
        return [];
      }

      if (group.groupTypeId === facetGroup.groupTypeId) {
        if (group.groupTypeId === FILTERS.DATERANGE) {
          // Remove selected start/end dates
          facets = group.facets?.filter(facet => !isStartEndDateFilter(facet.name));
        } else {
          // Remove selected items
          facets = group.facets?.filter(facet => !facet.checked);
        }
      }

      return [
        {
          ...group,
          facets
        }
      ];
    })
  });

  const state = getState() as RootState;
  // Check to see if we already have an appropriate response in the cache
  let existingResponse = internalSearchEndpoints.endpoints.getSearchResultsItems.select({
    ...rest,
    requestBody: newRequest
  })(state);

  if (!existingResponse.data) {
    // try one more time with an empty pageSize
    existingResponse = internalSearchEndpoints.endpoints.getSearchResultsItems.select({
      ...rest,
      requestBody: { ...newRequest, pageSize: 0 }
    })(state);
  }

  const filterRes = existingResponse.data
    ? existingResponse
    : // If not, make a new request just to get the filters
      await dispatch(
        internalSearchEndpoints.endpoints.getSearchResultsFiltersOnly.initiate({
          ...rest,
          requestBody: {
            ...newRequest,
            filterGroupsToReturn: [facetGroup.groupTypeId!]
          }
        })
      );

  // Return data for current group
  return filterRes.data?.facetResults?.facetGroups?.find(
    group => group.groupTypeId === facetGroup.groupTypeId
  );
};

export const updateGroupData = ({
  newGroup,
  dataToUpdate
}: {
  newGroup: IFacetGroup | undefined;
  dataToUpdate: SearchResponse;
}) => {
  const oldGroup = dataToUpdate.facetResults?.facetGroups?.find(
    oldGroup => oldGroup.groupTypeId === newGroup?.groupTypeId
  );
  if (!oldGroup || !newGroup?.facets) return;

  const facetsToIterateOver = [...newGroup.facets];
  oldGroup.facets?.forEach(oldFacet => {
    if (!facetsToIterateOver.some(facet => facet.value === oldFacet.value) && oldFacet.checked) {
      facetsToIterateOver.unshift(oldFacet);
    }
  });

  const isDateRange = newGroup.groupTypeId === FILTERS.DATERANGE;

  oldGroup.facets = facetsToIterateOver
    .map(newFacet => {
      const oldFacet = oldGroup.facets?.find(oldFacet =>
        isDateRange ? oldFacet.name === newFacet.name : oldFacet.value === newFacet.value
      );
      return {
        ...newFacet,
        checked: oldFacet?.checked ?? false,
        altValue: oldFacet?.altValue ?? newFacet.altValue,
        value:
          isDateRange && isStartEndDateFilter(newFacet.name) ? oldFacet?.value : newFacet.value,
        overrideIsExclusive: oldFacet?.overrideIsExclusive ?? false
      };
    })
    .sort((a, b) => {
      if (a.checked && !b.checked) return -1;
      if (!a.checked && b.checked) return 1;
      return 0;
    });

  oldGroup.showMoreLabel = newGroup.showMoreLabel;
};

export const upsertSearchResultItemsQueryData = internalSearchEndpoints.util.upsertQueryData;

export const resetInternalSearchEndpoints = internalSearchEndpoints.util.resetApiState;
