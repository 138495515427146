import classnames from 'classnames';
import { Suggestion } from './SearchOverlaySuggestions';
import {
  API_TYPES_TO_AUTOCOMPLETE_CATEGORY,
  AUTOCOMPLETE_CATEGORIES,
  AUTOCOMPLETE_CATEGORY_ORDER,
  FE_FILTER_TO_AUTOCOMPLETE_CATEGORY
} from './constants';
import { TaxanomySuggestion, TaxonomyCompletionResponse } from '@/@types/client-api';
import { FilterButtonGroupProps } from '../FindAProgram/types';
import { FILTERS } from '../SearchResults/constants';

export const xPadding = classnames('px-6 md:px-12 lg:px-20');
export const height = classnames('h-[72px] md:h-24');

export const getApiAndFeSuggestions = ({
  data,
  filterGroups
}: {
  data?: TaxonomyCompletionResponse;
  filterGroups: FilterButtonGroupProps[];
}) => {
  const apiSuggestions = (data?.suggestions ?? ([] as TaxanomySuggestion[])).flatMap(item => {
    const category = (item.type && API_TYPES_TO_AUTOCOMPLETE_CATEGORY[item.type]) ?? undefined;

    if (category === AUTOCOMPLETE_CATEGORIES.PRACTICE_AREA) {
      if (
        filterGroups
          .find(group => group.groupTypeId === FILTERS.PRACTICE_AREA)
          ?.filters.find(filter => filter.value === String(item.tagId))?.checked
      ) {
        return [];
      }

      return [
        {
          text: item.suggestion ?? '',
          category,
          filterGroup: FILTERS.PRACTICE_AREA,
          filterValue: String(item.tagId)
        }
      ];
    }

    return [
      {
        text: item.suggestion ?? '',
        category
      }
    ];
  });

  const feSuggestions = filterGroups.flatMap(group =>
    FE_FILTER_TO_AUTOCOMPLETE_CATEGORY[group.groupTypeId]
      ? group.filters.flatMap(filter =>
          filter.checked || filter.disabled
            ? []
            : [
                {
                  text: filter.label,
                  category: FE_FILTER_TO_AUTOCOMPLETE_CATEGORY[group.groupTypeId],
                  filterGroup: group.groupTypeId as FILTERS,
                  filterValue: filter.value
                }
              ]
        )
      : []
  );

  return { suggestions: [...apiSuggestions, ...feSuggestions] };
};

const filterOptionsByText = (options: Suggestion[], searchText: string) => {
  if (!searchText) return options;
  const filteredOptions = options.filter(
    option =>
      option?.text?.toLowerCase().includes(searchText.toLowerCase()) && option.text !== searchText
  );
  return filteredOptions;
};

export const processSuggestions = (suggestions: Suggestion[], searchText: string) => {
  // filterOptionsByText is most important for the FE suggestions but will also filter out API suggestions where the text === searchText
  const filteredSuggestionList = filterOptionsByText(suggestions, searchText)
    .sort((a, b) => {
      const aIndex = AUTOCOMPLETE_CATEGORY_ORDER.indexOf(a.category ?? '');
      const bIndex = AUTOCOMPLETE_CATEGORY_ORDER.indexOf(b.category ?? '');

      if (searchText && a.category === b.category) {
        const aIsMatch = a.text.toLowerCase().startsWith(searchText.toLowerCase());
        const bIsMatch = b.text.toLowerCase().startsWith(searchText.toLowerCase());

        if (aIsMatch && !bIsMatch) {
          return -1;
        }

        if (bIsMatch && !aIsMatch) {
          return 1;
        }
      }

      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;

      return aIndex - bIndex;
    })
    .slice(0, 5);

  const groupList: string[] = [];

  const suggestionWithExactMatch = filteredSuggestionList.find(suggestion => {
    if (suggestion.category && !groupList.includes(suggestion.category)) {
      groupList.push(suggestion.category);
    }
    const isFirstGroup = suggestion.category
      ? groupList[0] === suggestion.category
      : groupList.length === 0;
    return isFirstGroup && suggestion.text.toLowerCase().startsWith(searchText.toLowerCase());
  });
  return { filteredSuggestionList, suggestionWithExactMatch };
};
