import type { DebugPage as DebugPageProps, SiteSettings } from '@/@types/content';
import withNav from '../hocs/withNav';
import { getOIDCState } from '@/auth/oidcConfig';
import { useAuth } from 'react-oidc-context';
import Button from '@/components/ui/Buttons/Button';
interface DebugPagePropsExtended extends DebugPageProps {
  ip: string;
  iPs: string;
  authorization: string;
  profile: object;
  licenses: object;
  contractDiscounts: object;
  cad: object;
  profileSetup: object;
  cmsVersion: string;
  contentOptOut: object;
}
const DebugPageComponent: React.FC<DebugPagePropsExtended & SiteSettings> = props => {
  const {
    ip,
    iPs,
    authorization,
    profile,
    licenses,
    contractDiscounts,
    cad,
    cmsVersion,
    profileSetup,
    contentOptOut
  } = props;
  const auth = useAuth();

  return (
    <div className="container">
      <div className="my-6">
        <h1 className="heading-4 mb-4">!!! Refresh after login/logout to pull CMS values.</h1>
        <h1 className="heading-4 mb-4">Local values</h1>
        <div>
          {auth.isAuthenticated ? (
            <>
              <Button
                label="Logout"
                onClick={() => auth.signoutRedirect({ state: getOIDCState() })}
              />
              <br />
              <span className="text-1 px-4 font-bold">Logged in as {auth.user?.profile.email}</span>
              <br />
              <span className="text-1 px-4 font-bold">
                token {auth.user?.access_token}{' '}
                {(auth.user?.expires_in ?? -1) > 0
                  ? 'expires in:' + auth.user?.expires_in + ' sec'
                  : 'EXPIRED. Refresh Page to Renew.'}
              </span>
            </>
          ) : (
            <Button label="Login" onClick={() => auth.signinRedirect({ state: getOIDCState() })} />
          )}
        </div>
      </div>
      <div className="my-6">
        <h1 className="heading-4 mb-4">CMS values</h1>
        <div>
          <span className="text-1 px-4 font-bold">IP:</span>
          {ip}
        </div>
        <div>
          <span className="text-1 px-4 font-bold">IPS:</span>
          {iPs}
        </div>
        <div>
          <span className="text-1 px-4 font-bold">CMS Version:</span>
          {cmsVersion}
        </div>
        <div>
          <span className="text-1 px-4 font-bold">Authorized:</span>
          {authorization}
        </div>
        <div>
          <span className="text-1 px-4 font-bold">Profile:</span>
          <pre>{JSON.stringify(profile, null, 2)}</pre>
        </div>
        <div>
          <span className="text-1 px-4 font-bold">Licenses:</span>
          <pre>{JSON.stringify(licenses, null, 2)}</pre>
        </div>
        <div>
          <span className="text-1 px-4 font-bold">Contract Discounts:</span>
          <pre>{JSON.stringify(contractDiscounts, null, 2)}</pre>
        </div>
        <div>
          <span className="text-1 px-4 font-bold">CAD:</span>
          <pre>{JSON.stringify(cad, null, 2)}</pre>
        </div>
        <div>
          <span className="text-1 px-4 font-bold">ProfileSetup:</span>
          <pre>{JSON.stringify(profileSetup, null, 2)}</pre>
        </div>
        <div>
          <span className="text-1 px-4 font-bold">ContentOptOut:</span>
          <pre>{JSON.stringify(contentOptOut, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
};

const DebugPage = withNav(DebugPageComponent);
export default DebugPage;
