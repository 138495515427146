import { createContext, useContext } from 'react';
import classnames from 'classnames';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import Icon from '@/components/ui/Icon';

export type ComplexTooltipAlign = 'start' | 'center' | 'end';

interface ComplexTooltipProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  variant?: 'light' | 'dark' | 'gray' | 'walkthrough';
  modal?: boolean;
  children: React.ReactNode;
}
interface ComplexTooltipPrivateProps {
  Trigger: typeof PopoverPrimitive.Trigger;
  Content: React.FC<ComplexTooltipContentProps>;
  Buttons: React.FC<ComponentWithChildren>;
}

interface ComplexTooltipContentProps {
  side?: 'top' | 'bottom' | 'left' | 'right';
  sideOffset?: number;
  onClose?: () => void;
  closeLabel?: string;
  children: React.ReactNode;
  className?: string;
  alignOffset?: number;
  customContainer?: HTMLElement | null;
  ariaLabel?: string;
  align?: ComplexTooltipAlign;
  onPointerDownOutside?: (e: Event) => void;
  onOpenAutoFocus?: (e: Event) => void;
  avoidCollisions?: boolean;
  nestedInModal?: boolean;
}

const TooltipContext = createContext<{ variant?: string }>({});

const ComplexTooltip: React.FC<ComplexTooltipProps> & ComplexTooltipPrivateProps = ({
  isOpen,
  onOpenChange,
  children,
  variant = 'light',
  ...props
}) => (
  <TooltipContext.Provider value={{ variant }}>
    <PopoverPrimitive.Root open={isOpen} onOpenChange={onOpenChange} {...props}>
      {children}
    </PopoverPrimitive.Root>
  </TooltipContext.Provider>
);

const ComplexTooltipContent: React.FC<ComplexTooltipContentProps> = ({
  side,
  sideOffset,
  children,
  customContainer,
  closeLabel = 'Close',
  onClose,
  className,
  alignOffset,
  ariaLabel,
  onPointerDownOutside,
  onOpenAutoFocus,
  align = 'start',
  avoidCollisions,
  nestedInModal
}) => {
  const { variant } = useContext(TooltipContext);

  const contentClassNames = classnames(
    'flex w-[300px] flex-row items-start gap-x-6 rounded border border-solid border-silver p-5 lg:w-[335px]',
    {
      'bg-white text-black': variant === 'light',
      'bg-charcoal text-white': variant === 'dark',
      'rounded-lg bg-gray-darker p-6 text-white lg:w-[450px] lg:p-8': variant === 'gray',
      'rounded-lg border-none bg-gray-darker p-6 text-white lg:w-[450px] lg:p-8':
        variant === 'walkthrough',
      'z-modal': nestedInModal,
      'z-tooltip': !nestedInModal
    }
  );

  const closeClassNames = classnames('basis-4', {
    'text-black': variant === 'light',
    'text-white': variant === 'dark' || variant === 'gray'
  });

  const arrowClassNames = classnames('-translate-y-0.5 drop-shadow-[0_1px_0_#ccc]', {
    'fill-white': variant === 'light',
    'fill-charcoal': variant === 'dark',
    'fill-gray-darker': variant === 'gray',
    'fill-gray-darker drop-shadow-none': variant === 'walkthrough'
  });

  return (
    <PopoverPrimitive.Portal container={customContainer}>
      <PopoverPrimitive.Content
        align={align}
        alignOffset={alignOffset}
        side={side}
        avoidCollisions={avoidCollisions}
        sideOffset={sideOffset}
        className={classnames(contentClassNames, className)}
        arrowPadding={20}
        aria-label={ariaLabel}
        onPointerDownOutside={onPointerDownOutside}
        onOpenAutoFocus={onOpenAutoFocus}
      >
        <div className="tooltip text-1 flex w-full flex-col gap-y-4">{children}</div>

        <PopoverPrimitive.Close
          className={closeClassNames}
          aria-label={closeLabel}
          onClick={onClose}
          type="button"
        >
          <Icon name="close" size="small" />
        </PopoverPrimitive.Close>

        <PopoverPrimitive.Arrow width={20.5} height={12} className={arrowClassNames} />
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  );
};

const ComplexTooltipButtons: React.FC<ComponentWithChildren> = ({ children }) => (
  <div className="flex flex-nowrap gap-x-2">{children}</div>
);

ComplexTooltip.Trigger = PopoverPrimitive.Trigger;
ComplexTooltip.Content = ComplexTooltipContent;
ComplexTooltip.Buttons = ComplexTooltipButtons;

export default ComplexTooltip;
