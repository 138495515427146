import { validateCardNumber } from '@/components/ui/CreditCardGrid/CreditCard/helpers';
import { isAfterMaxDate, isBeforeMinDate } from '@/components/ui/DatePicker/CalendarRange';
import { InputError } from '@/components/ui/Form/Input';
import { formatDate, parseDate } from '@/utils/helpers';
import { FormMessageProps } from '@radix-ui/react-form';
import { endOfMonth, startOfMonth } from 'date-fns';

export type ErrorElementKeys = { [index: number]: string | number | null };
export interface ErrorState extends InputError {
  showError?: boolean;
  elementKeys?: ErrorElementKeys;
  forceMatch?: FormMessageProps['forceMatch'];
}

export const invalidCardError = {
  message: `Please enter a valid card number.`,
  match: (value: string): boolean => !!value && !validateCardNumber(value)
};

export const invalidDateError = (dateFormat: string): InputError => ({
  message: `Please enter a valid date (${dateFormat.toUpperCase()}).`,
  match: (value: string): boolean => !!value && !parseDate(value, dateFormat)
});

export const minMonthError = (minMonth: Date, dateFormat?: string): InputError =>
  minDateError(startOfMonth(minMonth), dateFormat);

export const maxMonthError = (maxMonth: Date, dateFormat?: string): InputError =>
  maxDateError(endOfMonth(maxMonth), dateFormat);

export const minDateError = (minDate: Date, dateFormat?: string): InputError => ({
  message: `Please enter a date greater than or equal to ${formatDate(minDate, dateFormat)}.`,
  match: (value: string): boolean => {
    const date = parseDate(value, dateFormat);
    return !!date && isBeforeMinDate(date, minDate);
  }
});

export const maxDateError = (maxDate: Date, dateFormat?: string): InputError => ({
  message: `Please enter a date less than or equal to ${formatDate(maxDate, dateFormat)}.`,
  match: (value: string): boolean => {
    const date = parseDate(value, dateFormat);
    return !!date && isAfterMaxDate(date, maxDate);
  }
});

export const invalidLenghtError = (allowedLengths: number[]): InputError => {
  if (allowedLengths.length === 0) return { message: '', match: (): boolean => false };

  let message = allowedLengths[0].toString();
  for (let i = 1; i < allowedLengths.length; i++) {
    message += (i === allowedLengths.length - 1 ? ' or ' : ', ') + allowedLengths[i].toString();
  }
  return {
    message: `This field must be ${message} digits long`,
    match: (value: string): boolean => !allowedLengths.includes(value.length)
  };
};

export const isInvalidZipFormat = (value: string) => {
  const zipRegex = /^\d{5}(?:[-]\d{4})?$/;

  return !zipRegex.test(value);
};

export const invalidZipFormat = (): InputError => {
  return {
    message: 'Zip format is invalid.',
    match: (value: string): boolean => isInvalidZipFormat(value)
  };
};

const numbers = '0123456789';
const specialChars = '!@#$%&*()-_+={}[]|\\;:"<>,./?';

export const hasOnlyNumbers = (value: string): boolean => {
  for (const char of value) {
    if (!numbers.includes(char)) return false;
  }
  return true;
};

export type ValidationOptions = {
  acceptLetters?: boolean;
  acceptNumbers?: boolean;
  acceptSpaces?: boolean;
  acceptSpecialChars?: boolean;
  allowedChars?: string;
};

export const isValidValue = (
  value: string,
  {
    acceptLetters = true,
    acceptNumbers = true,
    acceptSpaces = true,
    acceptSpecialChars = true,
    allowedChars = ''
  }: ValidationOptions
): boolean => {
  for (const char of value) {
    const isAllowedChar = allowedChars.includes(char);
    if (!isAllowedChar) {
      const isEspace = char === ' ';
      const isNumber = numbers.includes(char);
      const isSpecialChar = specialChars.includes(char);
      const isLetter = !isEspace && !isNumber && !isSpecialChar;
      if (
        (isEspace && !acceptSpaces) ||
        (isNumber && !acceptNumbers) ||
        (isSpecialChar && !acceptSpecialChars) ||
        (isLetter && !acceptLetters)
      ) {
        return false;
      }
    }
  }
  return true;
};
