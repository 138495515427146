import { useCallback, useEffect, useState } from 'react';
import { LicensesPage as LicensesPageProps, SiteSettings } from '@/@types/content';
import ContentArea from '../cms/ContentArea';
import withNav from '../hocs/withNav';
import { useUpdateCertificatePreferenceMutation } from '@/redux/api/client/userLicenses';
import LicensesSnippet from '../ui/LicensesPage/LicensesSnippet';
import Divider from '../ui/Divider';
import LicensesSnippetLoadingState from '../ui/LicensesPage/LicensesSnippetLoadingState';
import useToast from '@/hooks/useToast';
import CertificatesPreferencesSection from '../ui/LicensesPage/CertificatesPreferencesSection';
import AddNewLicenseButton from '../ui/LicensesPage/AddNewLicenseButton';
import Button from '../ui/Buttons/Button';
import {
  useGetLicenseData,
  useOnDeleteLicense,
  useOnSaveEditLicense,
  useOnSaveLicense
} from '../ui/LicensesPage/hooks';
import NudgeProvider from '@/hooks/shared/useNudge/provider';
import { AnalyticsContext } from '@/analytics/constants';
import useRegionMessages from '../ui/CreditTracker/hooks/useRegionMessagesHook';

const LicensesPageComponent: React.FC<LicensesPageProps & SiteSettings> = ({
  mainContentArea,
  emptyStateContent,
  certificatePreferences,
  messages
}) => {
  const { showSuccessMessage, showFailMessage } = useToast();

  const {
    creditJurisdictions,
    availableCreditJurisdictions,
    licenses,
    isError,
    isLoading,
    refetchLicenses
  } = useGetLicenseData();

  const { sidePanelMessages } = useRegionMessages({
    requests:
      licenses?.items?.map(a => ({
        region: a?.creditRegion ?? '',
        isTransitionalOrNewlyAdmitted: false
      })) ?? [],
    skip: !!isError || !!isLoading || !licenses?.items?.length
  });

  const [isProcessing, setIsProcessing] = useState(false);
  const handleOnSaveLicense = useOnSaveLicense({
    setIsProcessing,
    context: AnalyticsContext.Licenses
  });
  const handleOnSaveEditLicense = useOnSaveEditLicense({
    setIsProcessing,
    context: AnalyticsContext.Licenses
  });
  const handleOnDeleteLicense = useOnDeleteLicense({
    setIsProcessing,
    context: AnalyticsContext.Licenses
  });

  const [updateCertificatePreference] = useUpdateCertificatePreferenceMutation();

  const selectedCertificatePreference = licenses?.selectedCertificatePreference;

  const handleOnSaveCertificatePreferences = useCallback(
    async (selectedKey: string) => {
      if (selectedCertificatePreference?.toString() === selectedKey) {
        return;
      }
      const selectedCertificatePreferenceKey = parseInt(selectedKey);
      setIsProcessing(true);
      try {
        await updateCertificatePreference(selectedCertificatePreferenceKey).unwrap();
        await refetchLicenses();
        showSuccessMessage(`Updated`);
      } catch (error) {
        showFailMessage('Error saving certificate preference');
      } finally {
        setIsProcessing(false);
      }
    },
    [
      refetchLicenses,
      selectedCertificatePreference,
      showFailMessage,
      showSuccessMessage,
      updateCertificatePreference
    ]
  );

  useEffect(() => {
    if (isError) {
      showFailMessage('Error loading licenses');
    }
  }, [isError, showFailMessage]);

  return (
    <>
      <ContentArea
        propertyName="StandardBlackHeaderBlock"
        components={mainContentArea}
        componentsProps={{
          rightContent: (
            <AddNewLicenseButton
              creditJurisdictions={availableCreditJurisdictions}
              onSave={handleOnSaveLicense}
              triggerButton={
                <Button label="Add new license" color="outline-white" size="short" iconLeft="add" />
              }
            />
          )
        }}
      />
      <div className="container">
        {isLoading || isProcessing ? (
          <LicensesSnippetLoadingState />
        ) : (
          <>
            {licenses?.items?.length === 0 ? (
              <ContentArea
                components={emptyStateContent}
                componentsProps={{ hasSidePadding: false }}
              />
            ) : (
              <NudgeProvider>
                {licenses?.items?.map((item, index) => {
                  return (
                    <>
                      <LicensesSnippet
                        key={item.sk}
                        className="py-10 lg:py-12"
                        data={item}
                        onSave={handleOnSaveEditLicense}
                        onDelete={handleOnDeleteLicense}
                        creditJurisdictions={creditJurisdictions}
                        messages={messages}
                        licenseIndex={index}
                        announcements={sidePanelMessages(item.creditRegion ?? '')}
                      />
                      <Divider color="light" />
                    </>
                  );
                })}
              </NudgeProvider>
            )}
            <CertificatesPreferencesSection
              certificatePreferences={certificatePreferences}
              selectedKey={selectedCertificatePreference}
              onSave={handleOnSaveCertificatePreferences}
            />
          </>
        )}
      </div>
    </>
  );
};

const LicensesPage = withNav(LicensesPageComponent);

export default LicensesPage;
