import { useGetRelevantProgramCreditsQuery } from '@/redux/api/client/cle';
import { skipToken } from '@reduxjs/toolkit/query';

export const useRelevantCredits = (codes: string[], regions?: string[]) => {
  const { data, isFetching } = useGetRelevantProgramCreditsQuery(
    codes?.length ? { codes, regions } : skipToken
  );

  const relevantCredits = data && codes?.length === 1 && codes[0] in data ? data[codes[0]] : data;

  return {
    relevantCredits,
    isLoading: isFetching
  };
};

export default useRelevantCredits;
