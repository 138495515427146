import Image from '@/components/cms/Image';
import type { ImageMediaData as ImageMediaDataProps } from '@/@types/content';

interface IImageMediaDataProps extends ImageMediaDataProps {
  className?: string;
  propertyName?: string;
}

const ImageMediaData: React.FC<IImageMediaDataProps> = ({
  url,
  altText,
  className,
  propertyName
}) => {
  return <Image src={url} alt={altText} propertyName={propertyName} className={className} />;
};

export default ImageMediaData;
