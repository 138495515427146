import VimeoVideo from '@/components/ui/VimeoVimeo';
import type { GenericVideoBlock as GenericVideoBlockProps } from '@/@types/content';
import Block from '@/components/cms/Block';
import classnames from 'classnames';

type GenericVideoProps = GenericVideoBlockProps & { withinOverview?: boolean };
const GenericVideoBlock = ({
  contentLink,
  title,
  videoID,
  thumbnail,
  withinOverview
}: GenericVideoProps) => {
  return (
    <Block
      contentLinkID={contentLink?.id}
      className={classnames('module-spacing-pdp', { container: !withinOverview })}
    >
      <VimeoVideo
        title={title}
        videoId={videoID}
        thumbnail={thumbnail}
        className="max-w-[420px]"
        imageSizes={{
          xs: '420px'
        }}
      />
    </Block>
  );
};

export default GenericVideoBlock;
