import { ClientAPI } from '@/redux/api';
import { UserContactInfo } from '@/@types/client-api';

const userContactInfoEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getContactInfo: builder.query<UserContactInfo, void>({
      query: () => ({
        url: '/user/contact-info',
        method: 'GET'
      })
    }),
    updateContactInfo: builder.mutation<UserContactInfo, UserContactInfo>({
      query: body => ({
        url: '/user/contact-info',
        method: 'PUT',
        body
      })
    })
  })
});

export const { useGetContactInfoQuery, useUpdateContactInfoMutation } = userContactInfoEndpoints;

export default userContactInfoEndpoints;
