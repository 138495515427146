import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { AuthProvider } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import type { FetchArgs } from '@reduxjs/toolkit/query';
import { store } from '@/redux/store';
import { userManager, OIDCState } from '@/auth/oidcConfig';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '@/lib/ApplicationInsightsService';
import { userLoaded, userUnloaded } from '@/redux/slices/authSlice';
import App from './App';
import ToastContainer from '@/components/ui/Toast/ToastContainer';
import '@/styles/index.css';
import { contentDeliveryApi } from './redux/api/contentDeliveryAPI';
import { ClientAPI, repeatAction } from './redux/api';
import {
  buildPostSignInAction,
  buildParams,
  buildDelayedActionUrls
} from './redux/helpers/delayedUserActions';
import { registerSW } from 'virtual:pwa-register';
import { trackAccountLoginEvent } from './analytics/user';

const invalidateUserData = () => {
  contentDeliveryApi.util.invalidateTags(['Global', 'Content']);
  ClientAPI.util.invalidateTags(['CartData', 'CreditTrackerData', 'LibraryItem', 'Library']);
};
const onSigninCallback = async (user: void | User) => {
  console.info('DIAG:Excecute post sign in action');
  store.dispatch(userLoaded({ token: user?.access_token, name: user?.profile.name }));
  const state: OIDCState = user?.state as OIDCState;
  const returnUrl: string = state?.returnUrl ? state.returnUrl : '/';
  invalidateUserData();
  trackAccountLoginEvent(user?.profile);

  const postSignInAction = await buildPostSignInAction(state, user as User);

  let returnUrlWithSuccessToast = `${returnUrl}?${buildDelayedActionUrls(
    'success',
    postSignInAction?.endpoint || 'noAction',
    postSignInAction.message
  )}`;
  const returnUrlWithErrorToast = `${returnUrl}?${buildDelayedActionUrls('error', postSignInAction?.endpoint || 'noAction')}`;

  if (
    state.endpoint &&
    state.arg &&
    postSignInAction?.endpoint &&
    !state?.extraOptions?.ignoreDelayedAction
  ) {
    const { endpoint, body, url, param } = postSignInAction;

    if (state.arg && endpoint && (body || param)) {
      state.arg =
        typeof state.arg === 'object'
          ? ({ ...state.arg, body, url, param } as FetchArgs)
          : state.arg;
      repeatAction(state.arg, endpoint, store, state.extraOptions)
        .then(response => {
          if (response && response.data) {
            returnUrlWithSuccessToast += `&${buildParams({ response: response.data })}`;
          }
          window.history.replaceState({ response }, document.title, returnUrlWithSuccessToast);
          console.info('DIAG:Reloaded by main, repeat action');
          window.location.reload();
        })
        .catch(error => {
          console.error(error);
          window.history.replaceState({ error }, document.title, returnUrlWithErrorToast);
          console.info('DIAG:Reloaded by main, repeat action error');
          window.location.reload();
        });
    } else {
      window.history.replaceState({}, document.title, returnUrl);
      console.info('DIAG:Reloaded by main, 1');
      window.location.reload();
    }
  } else if (postSignInAction.endpoint === null && state.endpoint) {
    window.history.replaceState({}, document.title, returnUrlWithSuccessToast);
    console.info('DIAG:Reloaded by main, 2');
    window.location.reload();
  } else {
    window.history.replaceState({}, document.title, returnUrl);
    console.info('DIAG:Reloaded by main, 3');
    window.location.reload();
  }
};
const onRemoveUser = () => {
  store.dispatch(userUnloaded());
  userManager.clearStaleState();
  invalidateUserData();
  console.info('DIAG:Remove user. ');
  //window.history.replaceState({}, document.title, window.location.pathname);
};

const intervalMS = 60 * 60 * 1000;

registerSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    r &&
      setInterval(async () => {
        if (r.installing || !navigator) return;

        if ('connection' in navigator && !navigator.onLine) return;

        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache'
          }
        });

        if (resp?.status === 200) await r.update();
      }, intervalMS);
  }
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ReduxProvider store={store}>
    <AuthProvider
      userManager={userManager}
      onSigninCallback={onSigninCallback}
      onRemoveUser={onRemoveUser}
    >
      <AppInsightsContext.Provider value={reactPlugin}>
        <ToastContainer />
        <App />
      </AppInsightsContext.Provider>
    </AuthProvider>
  </ReduxProvider>
);

(window as unknown as Record<string, string>).assureLoading = window?.env?.BUILD_NUMBER;
