import React from 'react';
import classnames from 'classnames';
import Icon from '../Icon';

type MenuTextTriggerButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'children'
> & {
  label: React.ReactNode;
  onClick?: () => void;
  isOpen: boolean;
  isFullWidthAtXS?: boolean;
  iconSize?: 'small' | 'medium' | 'large';
  color?: 'black' | 'gray';
};

const MenuTextTriggerButton = React.forwardRef<HTMLButtonElement, MenuTextTriggerButtonProps>(
  (
    { isOpen, label, className, isFullWidthAtXS, iconSize = 'small', color = 'gray', ...props },
    forwardedRef
  ) => {
    const buttonClassNames = classnames(
      'text-1 flex items-center gap-1 font-medium transition-colors hover:text-black',
      { 'max-sm:w-full max-sm:justify-between': isFullWidthAtXS },
      { 'text-black': color === 'black' },
      { 'text-gray-dark': color === 'gray' },
      className
    );
    return (
      <button className={buttonClassNames} {...props} ref={forwardedRef}>
        {label}
        <Icon size={iconSize} name={isOpen ? 'chevron-up' : 'chevron-down'} />
      </button>
    );
  }
);

export default MenuTextTriggerButton;
