import BaseCard from '@/components/ui/Cards/BaseCard';
import { MenuItemProps } from '@/components/ui/Menu/MenuItem';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useCallback, useState, useEffect } from 'react';
import { useAddToLibraryMutation, useRemoveFromLibraryMutation } from '@/redux/api/client/library';
import useToast from '@/hooks/useToast';
import ShareModalCard from '@/components/ui/Cards/ShareModalCard';
import RemoveLibraryModalCard from '@/components/ui/Cards/RemoveLibraryModal';
import { ConfirmRemoveButtonToast } from '@/components/ui/Cards/ConfirmRemoveButtonToast';
import { SaveToLibraryToast } from '@/components/ui/Cards/SaveToLibraryToast';
import BaseCardList from '@/components/ui/Cards/BaseCardList';
import moreLikeThis from '@/utils/moreLikeThis';
import { useSelector } from 'react-redux';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { EcommPanelParams } from '@/components/ui/SearchResults/hooks/useEcommData';
import EcommPanel from '@/components/ui/SearchResults/EcommPanel';
import { SearchableType } from '@/components/ui/SearchResults/searchableTypeToSnippetVariant';
import { CardType } from './constants';
import { useNavigate } from 'react-router-dom';
import { AnalyticsContext } from '@/analytics/constants';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { trackCardReadNowEvent } from '@/analytics/cards';
import { trackAddWishListEvent, trackRemoveWishListEvent } from '@/analytics/ecommerce';

enum Variant {
  Normal = 'normal',
  Retail = 'retail'
}

enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

interface PublicationCardProps {
  className?: string;
  cardClassName?: string;
  variant?: `${Variant}`;
  size?: `${Size}`;
  image?: {
    url: string;
    alt?: string;
  };
  labelIcon?: string;
  headingSmall?: string;
  heading?: string;
  labelDetail?: string;
  labelInfo?: string;
  href?: string;
  target?: string;
  onClickCard?: () => void;
  listPosition?: number;
  variationPk?: string;
  isSaved?: boolean;
  heightAuto?: boolean;
  widthAuto?: boolean;
  showAsList?: boolean;
  productCode?: string | null;
  plusUrl?: string | null;
  searchableType?: string;
  hasPlusAccess?: boolean;
  context?: AnalyticsContext;
  guidValue: string | null;
  itemAnalyticsModel?: ItemAnalyticsModel;
}

const PublicationCard: React.FC<PublicationCardProps> = ({
  className,
  cardClassName,
  variant = Variant.Normal,
  size = Size.Small,
  image,
  labelIcon,
  headingSmall,
  heading,
  labelDetail,
  labelInfo,
  href,
  target,
  onClickCard,
  listPosition,
  variationPk,
  isSaved,
  heightAuto,
  widthAuto,
  showAsList,
  productCode,
  plusUrl,
  searchableType,
  hasPlusAccess,
  context,
  guidValue,
  itemAnalyticsModel
}) => {
  const pageLinkPaths = useSelector(selectPageLinkPaths);
  const searchPageUrl = pageLinkPaths?.Search;

  const thumbnailIcon = variant === Variant.Retail ? 'cart' : 'link-out';
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [openEcom, setOpenEcom] = useState(false);
  const [addToLibrary] = useAddToLibraryMutation();
  const [removeFromLibrary] = useRemoveFromLibraryMutation();
  const { showSuccessMessage, showFailMessage } = useToast();
  const [isSavedToLibrary, setIsSavedToLibrary] = useState(false);
  let params: EcommPanelParams | undefined;

  const belowLg = useMediaQuery(ScreenSizeQueries.belowLg);
  const navigate = useNavigate();
  let buttonOptionsVariation;

  const shareButtonFunction = () => {
    setIsOpenShareModal(!isOpenShareModal);
  };

  const removeButtonFunction = () => {
    setIsOpenRemoveModal(!isOpenRemoveModal);
  };

  const removeFromLibraryQuery = useCallback(
    async (pk: string, undoAction?: boolean) => {
      trackRemoveWishListEvent(itemAnalyticsModel);
      const dataResponse = await removeFromLibrary(pk);
      if ('error' in dataResponse) {
        return { error: true };
      } else if ('data' in dataResponse && undoAction) {
        setIsSavedToLibrary(false);
        return showSuccessMessage('Item Removed from library');
      } else if ('data' in dataResponse) {
        setIsSavedToLibrary(false);
        return { data: true };
      }
    },
    [removeFromLibrary, showSuccessMessage, itemAnalyticsModel]
  );

  const addToLibraryQuery = useCallback(
    async (pk: string, undoAction?: boolean) => {
      const dataResponse = await addToLibrary(pk);

      if ('error' in dataResponse) {
        return { error: true };
      } else if ('data' in dataResponse && undoAction) {
        setIsSavedToLibrary(true);
        return showSuccessMessage('Item Added to library');
      } else if ('data' in dataResponse) {
        setIsSavedToLibrary(true);
        return { data: true };
      }
    },
    [addToLibrary, showSuccessMessage]
  );

  const confirmRemoveButtonFunction = useCallback(
    async (pk: string) => {
      const onClickFunction = () => {
        addToLibraryQuery(variationPk!, true);
      };

      const toastNode = (
        <ConfirmRemoveButtonToast
          message="Thanks for the feedback, the program has been removed. "
          onClickFunction={onClickFunction}
        />
      );

      const removeResponse = await removeFromLibraryQuery(pk);

      if (removeResponse && 'error' in removeResponse) {
        showFailMessage('Error while removing item to library');
      } else if (removeResponse && 'data' in removeResponse) {
        setIsSavedToLibrary(false);
        showSuccessMessage(toastNode);
      }
    },
    [addToLibraryQuery, removeFromLibraryQuery, showSuccessMessage, showFailMessage, variationPk]
  );

  const saveToLibraryFunction = useCallback(
    async (pk: string) => {
      const onClickFunction = () => {
        removeFromLibraryQuery(variationPk!, true);
      };

      const toastNode = (
        <SaveToLibraryToast message="Saved item to library. " onClickFunction={onClickFunction} />
      );

      trackAddWishListEvent(itemAnalyticsModel, AnalyticsContext.Cards);

      const addResponse = await addToLibraryQuery(pk);

      if (addResponse && 'error' in addResponse) {
        showFailMessage('Error while adding item to library');
      } else if (addResponse && 'data' in addResponse) {
        setIsSavedToLibrary(true);
        showSuccessMessage(toastNode);
      }
    },
    [addToLibraryQuery, removeFromLibraryQuery, showFailMessage, showSuccessMessage, variationPk, itemAnalyticsModel]
  );

  const shareButtonOption = { label: 'Share', onClick: shareButtonFunction, icon: 'share' };

  const removeFromLibraryOption = {
    label: 'Remove from library',
    onClick: removeButtonFunction,
    icon: 'delete'
  };

  const saveToLibraryOption = {
    label: 'Save to my library',
    onClick: () => saveToLibraryFunction(variationPk!),
    icon: 'add'
  };

  useEffect(() => {
    setIsSavedToLibrary(Boolean(isSaved));
  }, [isSaved]);
  const saveToLibrary = isSavedToLibrary ? removeFromLibraryOption : saveToLibraryOption;

  const buttonOptions: (MenuItemProps | { separator: true })[] = [
    saveToLibrary,
    shareButtonOption,
    { separator: true },
    {
      label: 'Show more like this',
      onClick: () => {
        moreLikeThis(navigate, productCode, searchPageUrl, itemAnalyticsModel);
      },
      icon: 'search'
    }
  ];

  const thumbnailProps = {
    image,
    iconThumbnail: {
      name: thumbnailIcon,
      label: labelIcon
    }
  };

  if (belowLg) {
    buttonOptionsVariation = [
      {
        label: labelIcon,
        onClick: () => {},
        icon: thumbnailIcon
      },
      ...(buttonOptions ?? [])
    ];
  } else {
    buttonOptionsVariation = buttonOptions ?? [];
  }

  let cardProps = {
    className,
    cardClassName,
    size,
    headingSmall,
    heading,
    labelDetail,
    labelInfo,
    href,
    target,
    onClickCard,
    listPosition,
    heightAuto,
    widthAuto,
    cardType: CardType.Publication
  };

  cardProps = {
    ...cardProps,
    onClickCard: () => {
      trackCardReadNowEvent(itemAnalyticsModel);
      if (hasPlusAccess && plusUrl) window.open(plusUrl, '_blank');
      else setOpenEcom(true);
    }
  };

  if (searchableType) {
    params = {
      pk: variationPk!,
      url: href!,
      title: heading!,
      searchableType: searchableType as SearchableType,
      action: 'BuyNow',
      guidValue
    };
  }

  return (
    <>
      {showAsList ? (
        <BaseCardList
          buttonOptions={buttonOptionsVariation}
          thumbnailProps={thumbnailProps}
          {...cardProps}
        />
      ) : (
        <BaseCard
          thumbnailProps={thumbnailProps}
          buttonOptions={buttonOptionsVariation}
          {...cardProps}
        />
      )}

      <ShareModalCard
        modalHeader="Share"
        pathShareLink={`/product/${variationPk}`}
        programTitle={cardProps.heading!}
        isOpen={isOpenShareModal}
        cardOpenModal={setIsOpenShareModal}
        itemAnalyticsModel={itemAnalyticsModel}
        context={AnalyticsContext.Cards}
      />
      <RemoveLibraryModalCard
        modalHeader="Do you want to remove this program from your Library?"
        isOpen={isOpenRemoveModal}
        onSaveButtonPress={() => confirmRemoveButtonFunction(variationPk!)}
        cardOpenModal={setIsOpenRemoveModal}
      />
      {openEcom && params && (
        <EcommPanel params={params} onClose={() => setOpenEcom(false)} context={context} />
      )}
    </>
  );
};

export default PublicationCard;
