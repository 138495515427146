import { ClientAPI } from '@/redux/api';
import { OrderResponse } from '@/@types/client-api';
import { queryStringBuilder } from '@/redux/helpers';

type OrderHistoryParams = {
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
  format?: string;
};

const orderHistoryEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getOrderHistory: builder.query<OrderResponse, OrderHistoryParams>({
      query: params => ({
        url: `/user/orders${queryStringBuilder(params)}`,
        method: 'GET'
      }),
      merge: (currentCache, newResponse, { arg }) => {
        if (currentCache.items && arg.pageNumber && arg.pageNumber !== 1) {
          // Add the previous results to the beginning of the new list from the API
          newResponse.items?.unshift(...(currentCache.items ?? []));
        }

        return newResponse;
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };

        // Remove pageNumber from the query args
        // so that when user goes to a new page, we don't create a new cache entry.
        // (Instead, we update the previous entry in `merge` function above)
        if (newQueryArgs.pageNumber) {
          delete newQueryArgs.pageNumber;
        }

        return newQueryArgs;
      }
    })
  })
});

export const { useGetOrderHistoryQuery } = orderHistoryEndpoints;

export default orderHistoryEndpoints;
