import React, { ComponentProps } from 'react';
import Icon from './Icon';
import classnames from 'classnames';

type IconButtonProps = {
  label?: string;
  onClick?: () => void;
} & ComponentProps<typeof Icon>;

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ name, size = 'medium', label, className, ...props }, forwardedRef) => (
    <button
      aria-label={label}
      className={classnames('text-gray-dark', className)}
      type="button"
      ref={forwardedRef}
      {...props}
      data-component={"IconButton"}
    >
      <Icon name={name} size={size} />
    </button>
  )
);

const MemoizedIconButton = React.memo(IconButton);
export default MemoizedIconButton;
