import { IFacetGroup, IFacetResultItem, ISortResultItem } from '@/@types/client-api';
import { FilterProps, MappedFilterItem } from '../../Filter/types';
import { ContentTab } from '../../ContentTabs';
import {
  ColumnLayout,
  FILTERS,
  FILTER_BEHAVIOR_TO_TYPE,
  FilterBehavior,
  FilterTypes
} from '../constants';

export const translateStoreFilterToComponentPropsItems = (
  facets?: IFacetResultItem[] | null
): MappedFilterItem[] =>
  (facets || []).map(facet => ({
    id: facet.value || '',
    label: facet.name || '',
    value: facet.value || '',
    count: facet.count || 0,
    checked: facet.checked || false,
    sectionId: facet.group || '',
    sectionTitle: facet.group || '',
    disabled: !!facet.disabled
  }));

// Translate filters API data structure to component data structure
export const translateStoreFilterToComponentProps = (facetGroup: IFacetGroup): FilterProps => {
  let expandText = facetGroup?.showMoreLabel ?? '';
  let hasMore = !!facetGroup?.showMoreLabel;

  if (facetGroup?.groupTypeId === FILTERS.JURISDICTIONS) {
    hasMore = true;

    if (facetGroup?.facets?.length) {
      expandText = 'See other jurisdictions';
    } else {
      expandText = 'Select a jurisdiction';
    }
  }

  const commonProps = {
    title: facetGroup?.groupTitle || '',
    groupTypeId: facetGroup?.groupTypeId || '',
    items: translateStoreFilterToComponentPropsItems(facetGroup?.facets),
    columnLayout: facetGroup?.columnLayout as ColumnLayout,
    visibleCount: facetGroup?.visibleCount,
    expandText,
    hasMore,
    maxCount: facetGroup?.configMaxCount
  };

  const type =
    (facetGroup?.behavior && FILTER_BEHAVIOR_TO_TYPE[facetGroup?.behavior as FilterBehavior]) ||
    undefined;

  switch (type) {
    case FilterTypes.SELECT:
      return {
        ...commonProps,
        type: FilterTypes.SELECT
      };

    case FilterTypes.CHECK:
      return {
        ...commonProps,
        type: FilterTypes.CHECK
      };

    case FilterTypes.RADIO:
      return {
        ...commonProps,
        type: FilterTypes.RADIO,
        value: facetGroup?.facets?.find(facet => facet.checked)?.value || ''
      };

    case FilterTypes.DATE:
      return {
        ...commonProps,
        type: FilterTypes.DATE,
        value: facetGroup?.facets?.find(facet => facet.checked)?.value || ''
      };

    default:
      return { ...commonProps, type: FilterTypes.CHECK };
  }
};

export const translateStoreTabsToComponentProps = (
  tabs: IFacetGroup['facets']
): { tabs: ContentTab[]; activeTab: ContentTab } => {
  const formatTab = (contentTab?: IFacetResultItem) => ({
    value: contentTab?.value || '',
    label: contentTab?.name || '',
    resultsCount: contentTab?.count || 0,
    disabled: !contentTab?.count || !!contentTab?.disabled
  });

  const newTabs = tabs?.map(contentTab => formatTab(contentTab));

  const activeFacetValue =
    tabs?.find(contentTab => contentTab?.checked) ||
    tabs?.find(contentTab => contentTab?.isDefault);

  return { tabs: newTabs || [], activeTab: formatTab(activeFacetValue) };
};

export const translateStoreSortToComponentProps = (sortItems?: ISortResultItem[] | null) => {
  const transformedData = sortItems?.map(item => ({
    label: item.sortTitle || '',
    value: item.value || ''
  }));

  return {
    options: transformedData,
    value: sortItems?.find(item => item.selected)?.value ?? undefined
  };
};
