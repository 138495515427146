import { useMemo } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Variant } from '@/redux/slices/pdpSlice';
import { OnDemandProps } from '..';
import { getFormatedMonthYear } from '@/lib/helpers/date';
import { RootState } from '@/redux/store';

interface MultiplerecordingsProps {
  labels: OnDemandProps['labels'];
  onSelect: (variant: Variant) => void;
  loadingProgramSegments?: boolean;
}

const Multiplerecordings: React.FC<MultiplerecordingsProps> = ({ labels, onSelect }) => {
  const catalogRelations = useSelector((state: RootState) => state.pdp.catalogRelations);
  const nonExpiredVariants = useMemo(
    () => catalogRelations?.variants.filter(v => !v.isExpired),
    [catalogRelations?.variants]
  );
  const selectedVariant = useSelector((state: RootState) => state.pdp.selectedVariant);
  const hardSelected = useSelector((state: RootState) => state.pdp.hardSelected);

  return (
    <div className="flex flex-col gap-3">
      {nonExpiredVariants?.map(variant => (
        <button
          key={variant.code}
          onClick={() => onSelect(variant)}
          className={classnames(
            'flex w-full items-center rounded border px-5 py-4 text-left transition-colors hover:border-black lg:px-5',
            { 'border-silver': !hardSelected || selectedVariant?.code !== variant.code },
            {
              'border-black bg-gray-lightest':
                hardSelected && selectedVariant?.code === variant.code
            }
          )}
        >
          <div className="grow">
            <p className="text-2 font-medium">
              {labels.recorded}{' '}
              {!!variant.publicationDate && getFormatedMonthYear(variant.publicationDate)}
            </p>
            <p className="label mt-1 font-medium text-gray-dark">{variant.location}</p>
          </div>
          <img
            src={`${variant.thumbnail}?width=100&height=100&format=webp`}
            alt={variant.displayName}
            className="size-12 shrink-0 rounded-[4px] object-cover"
          />
        </button>
      ))}
    </div>
  );
};

export default Multiplerecordings;
