import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { format } from 'date-fns';
import { RequirementsPanel } from '@/components/ui/CreditTracker/Panels/RequirementsPanel';
import Shimmer from '@/components/ui/Shimmer';
import Banner, { IBannerContentProps } from '@/components/ui/Banner';
import { ICLEStateTransitionOption, RequirementType } from '@/@types/client-api';
import DropdownSelect from '@/components/ui/DropdownSelect';
import TagButton from '@/components/ui/Buttons/TagButton';
import { SelectOption } from '@/components/ui/DropdownSelectAutocomplete';
import {
  formatCreditTypeId,
  getNewTagText,
  nthNumber,
  translateToTailwinds
} from '@/components/ui/CreditTracker/util/TrackerHelpers';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { DismissableBannerMessageBlock as DismissableBannerBlockProps } from '@/@types/content';
import DismissableBannerBlock from '@/components/blocks/DismissableBannerBlock';
import { CreditTrackerContext } from '@/components/blocks/CreditTracker/CreditTrackerContext';
import Text from '@/components/cms/Text';
import useRegionMessages from '@/components/ui/CreditTracker/hooks/useRegionMessagesHook';
import CreditDonut from '@/components/ui/CreditTracker/snippets/CreditDonut';
import {
  defaultDonutColors,
  warningDonutColors
} from '@/components/ui/CreditTracker/util/constants';
import { formatDate } from '@/components/ui/CreditTracker/util/trackerDateHelpers';
import { creditTrackerRemoveGlobalBanners } from '@/redux/slices/creditTrackerSlice';
import ComplianceEmailTemplate from '@/components/ui/CreditTracker/snippets/ComplianceEmailTemplate';
import Link from '@/components/ui/Link';
import { trackViewRequirementsEvent, trackViewTrackerEvent } from '@/analytics/creditTracker';
import { AnalyticsContext } from '@/analytics/constants';

interface ILicenseHeaderProps {
  creditsEarned: number;
  creditsRequired: number;
  creditsCounted: number;
  isLimitedMode?: boolean;
  transitionOptions?: ICLEStateTransitionOption[] | null;
  deadlineMessageBanner?: DismissableBannerBlockProps;
  onSpecialStatusChange?: () => void;
  totalCreditEarnedTooltip?: string;
  isUserInfoIncomplete: boolean;
  adminMemberEmail?: string;
  adminDashboardLink?: { href: string; text: string };
}

const LicenseHeader: React.FC<ILicenseHeaderProps> = props => {
  const {
    creditsEarned,
    creditsCounted,
    creditsRequired,
    isLimitedMode,
    transitionOptions,
    onSpecialStatusChange,
    totalCreditEarnedTooltip,
    deadlineMessageBanner,
    isUserInfoIncomplete,
    adminMemberEmail,
    adminDashboardLink
  } = props;
  const dispatch = useDispatch();

  const {
    activeSpecialStatus,
    isLoading,
    selectedClePeriod,
    selectedRegion,
    requirementsWithoutTemporal,
    temporalRequirements,
    isDeadlineApproaching,
    isTransitionalOrNewlyAdmittedRegion,
    isMissingCompliancePeriod,
    globalBanners,
    isAdminRequest
  } = useContext(CreditTrackerContext);

  const shortDescription = useMemo(
    () => selectedRegion?.creditRegionShortDescription ?? '',
    [selectedRegion?.creditRegionShortDescription]
  );
  const longDescription = useMemo(
    () => selectedRegion?.creditRegionLongDescription ?? '',
    [selectedRegion?.creditRegionLongDescription]
  );
  const creditRegionName = useMemo(
    () => selectedRegion?.creditRegionName ?? '',
    [selectedRegion?.creditRegionName]
  );

  const totalCreditsNeeded = useMemo(
    () => (creditsRequired ?? 0) - (creditsCounted ?? 0),
    [creditsCounted, creditsRequired]
  );

  useEffect(() => {
    if (isDeadlineApproaching) trackViewTrackerEvent(AnalyticsContext.Compliance, creditRegionName);
    else trackViewTrackerEvent(AnalyticsContext.NonCompliance, creditRegionName);
  }, [isDeadlineApproaching, creditRegionName]);

  const { messagesLoading, messagesFetching, trackerCarouselMessages, sidePanelMessages } =
    useRegionMessages({
      requests: selectedRegion?.creditRegionShortDescription?.length
        ? [
            {
              region: selectedRegion.creditRegionShortDescription,
              isTransitionalOrNewlyAdmitted: !!isTransitionalOrNewlyAdmittedRegion
            }
          ]
        : []
    });

  const complianceEndDate = useMemo(
    () => selectedClePeriod?.endDate ?? selectedClePeriod?.defaultEndDate,
    [selectedClePeriod?.defaultEndDate, selectedClePeriod?.endDate]
  );

  const regionMessagesLoading = useMemo(
    () => !!messagesLoading || !!messagesFetching || !!isLoading,
    [messagesFetching, messagesLoading, isLoading]
  );

  const carouselAnnouncements = useMemo(
    () => trackerCarouselMessages(shortDescription) ?? [],
    [shortDescription, trackerCarouselMessages]
  );
  const sidePanelAnnouncements = useMemo(
    () => sidePanelMessages(shortDescription) ?? [],
    [shortDescription, sidePanelMessages]
  );

  const specialTransitionStatus = useMemo(() => {
    const res: SelectOption[] = [];
    if (transitionOptions?.length) {
      for (const a of transitionOptions) {
        Object.values(a.regionSpecialStatusNames ?? ({} as Record<string, string>)).map(entry => {
          res.push({
            value: entry,
            label: entry
          });
        });
      }
    }
    return res;
  }, [transitionOptions]);

  const deadlineMessage = useMemo(() => {
    if (isMissingCompliancePeriod) return;
    const date = formatDate(complianceEndDate);
    return `Compliance deadline: ${date}`;
  }, [complianceEndDate, isMissingCompliancePeriod]);

  const panelTriggerRef = useRef<HTMLButtonElement>(null);
  const [scrollIndex, setScrollIndex] = useState<number | null>(null);

  const newTag = useCallback((expireDate?: string | null) => {
    return getNewTagText(expireDate);
  }, []);

  const bannerContents: IBannerContentProps[] = useMemo(
    () =>
      carouselAnnouncements?.map(announcement => {
        const index = sidePanelAnnouncements.findIndex(
          a => a.body?.toString() === announcement.body?.toString()
        );

        return {
          bannerType: 'tertiary',
          icon: !newTag(announcement.expireNewStatus) ? announcement.icon : '',
          headingText: announcement.headingText ?? '',
          newTagText: newTag(announcement.expireNewStatus),
          body: translateToTailwinds(announcement.body?.toString() ?? ''),
          onSeeMore: announcement.showOnSidePanel
            ? () => {
                setScrollIndex(index);
                panelTriggerRef.current?.click();
              }
            : undefined
        };
      }),
    [carouselAnnouncements, newTag, sidePanelAnnouncements]
  );

  const onStatusChange = useCallback(
    (_?: string) => {
      if (selectedRegion?.creditRegionShortDescription)
        dispatch(creditTrackerRemoveGlobalBanners(selectedRegion?.creditRegionShortDescription));

      onSpecialStatusChange && onSpecialStatusChange();
    },
    [dispatch, onSpecialStatusChange, selectedRegion?.creditRegionShortDescription]
  );

  const isSm = useMediaQuery(ScreenSizeQueries.smOnly);
  const isXs = useMediaQuery(ScreenSizeQueries.xsOnly);
  const buttonSize = useMemo(() => {
    if (isSm) {
      return 'small';
    } else if (isXs) {
      return 'short';
    }
    return 'large';
  }, [isSm, isXs]);

  const deadlineBannerText = useMemo(() => {
    const incompleteRequirements = [
      ...(temporalRequirements || []),
      ...(requirementsWithoutTemporal || [])
    ]
      .map(r => (r.requirementType === RequirementType._0 ? (r.subRequirements ?? []) : r))
      .flat()
      .filter(r => r.requirementType === RequirementType._2 && !r.isFulfilled)
      .map(
        r => `<a href="#${formatCreditTypeId(r.heading ?? '')}">${r.heading?.toLowerCase()}</a>`
      );

    const requirementsText = [...new Set(incompleteRequirements)].reduce(
      (accum, text, index, arr) => {
        if (index === 0) {
          return text;
        } else if (index === arr.length - 1) {
          return accum + ` and ${text}`;
        }

        return accum + `, ${text}`;
      },
      ''
    );

    const endDate = new Date(complianceEndDate || 0);
    const day = endDate.getDate();
    const formattedDate = format(endDate, 'MMMM d') + nthNumber(day);

    return deadlineMessageBanner?.message
      ?.replace('{date}', formattedDate)
      .replace('{requirements}', requirementsText || '');
  }, [
    complianceEndDate,
    deadlineMessageBanner?.message,
    requirementsWithoutTemporal,
    temporalRequirements
  ]);

  const renderGlobalBanners = useMemo(() => {
    return (
      !!globalBanners?.length &&
      globalBanners?.map((a, i) => {
        const isFirst = i === 0;
        const isLast = i === globalBanners?.length - 1;
        const _classes = classnames({
          'mb-2': isFirst,
          'mt-2': isLast,
          'my-2': !isFirst && !isLast
        });
        return (
          <div className={_classes}>
            <DismissableBannerBlock {...a} onUndo={onStatusChange} />
          </div>
        );
      })
    );
  }, [globalBanners, onStatusChange]);

  const renderDeadlineApproachingBanner = useMemo(() => {
    return (
      !isLoading &&
      isDeadlineApproaching &&
      !isUserInfoIncomplete && (
        <DismissableBannerBlock
          color="bg-orange"
          message={deadlineBannerText}
          icon={deadlineMessageBanner?.icon}
        />
      )
    );
  }, [
    deadlineBannerText,
    deadlineMessageBanner?.icon,
    isDeadlineApproaching,
    isLoading,
    isUserInfoIncomplete
  ]);

  return (
    <>
      {renderGlobalBanners}
      {renderDeadlineApproachingBanner}
      {isAdminRequest && (
        <div className="mb-4 flex items-start">
          {isLoading ? (
            <Shimmer className="size-full h-[22px] w-[440px] max-w-full bg-gray-light" />
          ) : (
            <Link
              as="a"
              href={adminDashboardLink?.href}
              text={adminDashboardLink?.text}
              key={adminDashboardLink?.text}
              variant="link-with-icon"
              icon="chevron-left"
              iconPosition="left"
              className="text-gray-dark"
            />
          )}
        </div>
      )}
      <div className="mb-12 flex flex-col-reverse pt-0 sm:flex-row sm:items-center sm:pt-9 ">
        <div className="mt-8 flex min-h-[296px] w-full grow flex-col sm:mt-0 sm:inline-flex">
          {isLoading ? (
            <Shimmer className="mb-3 size-full h-[34px] w-[440px] max-w-full bg-gray-light" />
          ) : (
            complianceEndDate && (
              <Text
                element="span"
                className={classnames('heading-6-regular static mb-3 flex w-full', {
                  'text-orange': isDeadlineApproaching,
                  'text-gray-dark': !isDeadlineApproaching
                })}
              >
                {deadlineMessage}
              </Text>
            )
          )}
          {isLoading ? (
            <Shimmer className="mb-8 size-full h-[45px] max-w-full bg-gray-light sm:max-w-[90%] md:h-[52px] md:w-3/4" />
          ) : (
            <h2 className="heading-2 pb-8">{longDescription}</h2>
          )}
          <div className="flex flex-col sm:flex-row">
            {isLoading ? (
              <Shimmer className="mr-5 h-14 w-full rounded md:w-[225px]" />
            ) : (
              !!specialTransitionStatus?.length && (
                <DropdownSelect
                  button={({ isOpen, value, ...props }) => (
                    <TagButton
                      isActive={isOpen}
                      icon={isOpen ? 'chevron-up' : 'chevron-down'}
                      styleType="outline"
                      className="mb-2 mr-5 h-14 w-full justify-center px-5 !font-bold *:max-w-full sm:mb-0 sm:w-auto"
                      {...props}
                      label={value}
                      aria-label="See more options"
                      size={buttonSize}
                    />
                  )}
                  options={specialTransitionStatus}
                  value={activeSpecialStatus}
                  onValueChange={onStatusChange}
                  menuStyle="standalone"
                  valueClassName="w-full truncate"
                />
              )
            )}
            {isLoading ? (
              <Shimmer className="mb-0 size-full h-14 max-w-full bg-gray-light sm:max-w-[90%] md:mb-8 md:w-1/4" />
            ) : (
              <RequirementsPanel
                announcements={sidePanelAnnouncements}
                creditRegionName={longDescription}
                scrollIndex={scrollIndex}
                ref={panelTriggerRef}
                onOpenChange={open => !open && setScrollIndex(null)}
                buttonSize={buttonSize}
                onButtonClick={() => {
                  trackViewRequirementsEvent(AnalyticsContext.CreditTracker, creditRegionName);
                }}
              />
            )}
            {isLoading ? (
              <Shimmer className="mb-0 size-full h-14 max-w-full bg-gray-light sm:max-w-[90%] md:mb-8 md:w-1/4" />
            ) : (
              isAdminRequest &&
              totalCreditsNeeded > 0 && (
                <ComplianceEmailTemplate
                  complianceDeadline={formatDate(complianceEndDate) ?? ''}
                  creditRegionName={creditRegionName}
                  emailAddress={adminMemberEmail ?? ''}
                  creditRegionShortDescription={shortDescription}
                  totalCreditsNeeded={totalCreditsNeeded}
                />
              )
            )}
          </div>
        </div>
        {(!isLimitedMode || isLoading) && !isMissingCompliancePeriod && (
          <div className="flex w-full justify-center md:inline-flex md:w-min md:justify-end">
            <CreditDonut
              creditsCounted={creditsCounted}
              creditsEarned={creditsEarned}
              creditsRequired={creditsRequired}
              size={260}
              isLoading={!!isLoading}
              donutColors={
                isDeadlineApproaching && creditsCounted < creditsRequired
                  ? warningDonutColors
                  : defaultDonutColors
              }
              totalCreditEarnedTooltip={totalCreditEarnedTooltip}
            />
          </div>
        )}
      </div>
      {(carouselAnnouncements?.length > 0 || regionMessagesLoading) && (
        <Banner
          bannerType="tertiary"
          contents={bannerContents}
          bannerContainerClassNames="!pb-0 !pt-8"
          isLoadingData={regionMessagesLoading}
          isClamped={true}
        />
      )}
    </>
  );
};

export default LicenseHeader;
