import Text from '@/components/cms/Text';
import classnames from 'classnames';
import { FacultyGridBlock as FacultyGridBlockProps } from '@/@types/content';
import Block from '@/components/cms/Block';
import Link from '@/components/ui/Link';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';

export interface FacultyGridBlockTextProps extends FacultyGridBlockProps {
  contentLink?: {
    id: number;
  };
  heading?: string;
  items?: {
    displayName?: string;
    bioFullName?: string;
    affiliation?: string;
    afilition?: string;
    resourceLocation?: string;
    location?: string;
    pageUrl?: string;
    url?: string;
  }[];
}

const FacultyGridBlock: React.FC<FacultyGridBlockTextProps> = ({ contentLink, heading, items }) => {
  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

  const textClasses = classnames('text-1 text-gray-dark sm:text-2');

  return (
    <Block contentLinkID={contentLink?.id} className="container module-spacing">
      <div>
        {heading && (
          <Text element="h5" className="heading-5">
            {heading}
          </Text>
        )}
        {items && items.length > 0 && (
          <ul className="mt-10 grid gap-y-8 sm:grid-cols-2 sm:gap-x-36 lg:grid-cols-3 lg:gap-x-16">
            {items.map((item, index) => (
              <li key={index}>
                <Text element="p" className="text-1-small sm:text-1-medium">
                  {item?.displayName || item?.bioFullName}
                </Text>
                <div className="mt-1">
                  <Text element="p" className={textClasses}>
                    {item?.affiliation || item?.afilition}
                  </Text>
                  <Text element="p" className={textClasses}>
                    {item?.resourceLocation || item?.location}
                  </Text>
                  <Link
                    className={textClasses}
                    href={item?.url || item?.pageUrl}
                    text={stateSiteLabels?.viewProfileLabel}
                  />
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Block>
  );
};

export default FacultyGridBlock;
