import { createContext } from 'react';

export type KeyListItem = {
  key: string;
  order: number;
  field?: string;
  isOpen?: boolean;
};

export type SortKey = (a: KeyListItem, b: KeyListItem) => number;

export type NudgeContextType = {
  keyList: KeyListItem[];
  addKey: (data: KeyListItem, sortKey: SortKey) => void;
  currentKey?: string;
  closeTooltipForKey: (key: string) => void;
  openPanel: string | undefined;
  registerPanel: (panelId: string) => void;
  unregisterPanel: (panelId: string) => void;
};

export const NudgeContext = createContext<NudgeContextType | undefined>(undefined);
