import classnames from 'classnames';
import { IconOrStatsCardBlock as IconOrStatsCardBlockProps } from '@/@types/content';
import Text from '@/components/cms/Text';
import Icon from '@/components/cms/Icon';
import Block from '@/components/cms/Block';
import Link from '@/components/ui/Link';

const IconOrStatsCardBlock: React.FC<IconOrStatsCardBlockProps> = ({
  contentLink,
  header,
  copy,
  numericHeader,
  startTheme,
  icon,
  link
}) => {
  const statsClass = classnames('heading-1 mb-4 inline-block', {
    'text-red': startTheme === 'red',
    'text-black': startTheme === 'black',
    'bg-gradient-to-r from-red to-amethist bg-clip-text text-transparent': startTheme === 'gradient'
  });

  const iconClass = classnames('heading-1 mb-4 inline-block', {
    'text-red': startTheme === 'red',
    'text-black': startTheme === 'black',
    'gradient-path': startTheme === 'gradient'
  });

  return (
    <Block element="div" contentLinkID={contentLink?.id} className="pr-8 ">
      <Icon propertyName="Icon" name={icon} className={classnames('mb-3', iconClass)} />
      <Text propertyName="NumericHeader" className={statsClass}>
        {numericHeader}
      </Text>
      <Text element="h3" propertyName="Header" className="heading-6-medium mb-4">
        {header}
      </Text>
      <Text propertyName="Copy" className="text-1 text-gray-dark">
        {copy}
      </Text>
      {link && (
        <Link
          href={link.href}
          target={link.target}
          text={link.text}
          variant={link.target === '_blank' ? 'bold-link-with-icon' : 'text-link-2'}
          icon={link.target === '_blank' ? 'link-out' : undefined}
          className="mt-4 w-fit"
        />
      )}
    </Block>
  );
};

export default IconOrStatsCardBlock;
