import { useSearchParams } from 'react-router-dom';

const useQueryParams = () => {
  const [searchParams] = useSearchParams();
  const params: { [key: string]: string } = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
};

export default useQueryParams;
