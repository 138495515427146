import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

interface IStickyHeaderProps {
  children: React.ReactNode;
  headerIsSticky(sticky: boolean): void;
  className?: string;
  styles?: React.CSSProperties;
}

const StickyHeader: React.FC<IStickyHeaderProps> = props => {
  const { children, headerIsSticky, className, styles } = props;
  const targetRef = useRef<HTMLDivElement>(null);
  const [stickToTop, setStickToTop] = useState(false);

  useEffect(() => {
    headerIsSticky(stickToTop);
  }, [headerIsSticky, stickToTop]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '50px',
      threshold: 0
    };

    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        setStickToTop(!entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(callback, options);
    const currentTarget = targetRef.current;

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, []);

  const stickyHeaderClass = classnames(`w-full ${className}`, {
    'fixed top-0 z-sticky-header-overlay': stickToTop,
    'container flex justify-between py-4 text-center': !stickToTop
  });

  return (
    <div ref={targetRef}>
      <div className={stickyHeaderClass} style={styles}>
        {children}
      </div>
    </div>
  );
};

export default StickyHeader;
