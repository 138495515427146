import { forwardRef as ReactForwardRef, useCallback, useState } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import classnames from 'classnames';
import * as Form from '@radix-ui/react-form';
import { v4 as uuidv4 } from 'uuid';

const RadioButton = ReactForwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ children, className, ...props }, ref) => {
  const [hover, setHover] = useState(false);
  const id = props.id || `radio-${uuidv4()}`;

  const handleOnMouseOver = useCallback(() => {
    setHover(true);
  }, []);

  const handleOnMouseOut = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div className="flex has-[input:disabled]:opacity-30">
      <div className="flex" onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>
        <RadioGroupPrimitive.Item
          ref={ref}
          className={classnames(
            'group peer mt-px aspect-square size-[14px] min-h-[14px] min-w-[14px] rounded-full border border-gray text-black disabled:cursor-not-allowed lg:mt-[2px] lg:size-4 lg:min-h-4 lg:min-w-4',
            'hover:border-red',
            'disabled:hover:border-gray',
            className
          )}
          id={id}
          {...props}
        >
          <RadioGroupPrimitive.Indicator id={id} className="flex items-center justify-center">
            <div
              className={classnames('size-2 rounded-full bg-black', {
                'group-enabled:bg-red': hover
              })}
            />
          </RadioGroupPrimitive.Indicator>
        </RadioGroupPrimitive.Item>
        {children ? (
          <Form.Label
            htmlFor={id}
            className={classnames(
              'text-2 cursor-pointer pl-2 text-black lg:pl-3',
              'peer-hover:text-red',
              'peer-disabled:cursor-not-allowed peer-disabled:text-black'
            )}
          >
            {children}
          </Form.Label>
        ) : null}
      </div>
    </div>
  );
});

export default RadioButton;
