import classnames from 'classnames';

import { Link } from 'react-router-dom';
import Icon from '@/components/ui/Icon';
import type { SnippetSegmentType } from './types';

export interface SegmentListHeaderProps {
  isProgramSegmentList: boolean;
  segmentListHeaderTitle?: string;
  segmentLink?: string;
  featuredSegments?: SnippetSegmentType[];
  expanded?: boolean;
  onLaunchMediaPlayer?: (segment: SnippetSegmentType) => void;
}

const SegmentListHeader = ({
  segmentListHeaderTitle,
  segmentLink,
  isProgramSegmentList,
  expanded = false
}: SegmentListHeaderProps) => {
  if (!isProgramSegmentList && !segmentListHeaderTitle) return null;

  return isProgramSegmentList ? (
    <span className="text-2 px-8 text-gray-dark" id="segmentListHeaderTitle">
      {expanded ? 'See less segments in this program' : 'See more segments in this program'}
    </span>
  ) : (
    <Link
      tabIndex={0}
      to={segmentLink || '#'}
      className={classnames(
        'relative flex h-fit cursor-pointer flex-row items-center justify-between gap-1 px-8'
      )}
      aria-label="Segment from program"
      id="segmentListHeaderTitle"
    >
      <div className="flex flex-col">
        <span className="text-2 relative top-0">
          <p className="text-gray-dark">Segment from program</p>
        </span>
        <div className="text-2 relative bottom-0 text-left">{segmentListHeaderTitle}</div>
      </div>
    </Link>
  );
};

export const StandaloneSegmentListHeader = ({
  segmentListHeaderTitle,
  segmentLink
}: SegmentListHeaderProps) => (
  <Link
    tabIndex={0}
    to={segmentLink || '#'}
    className={classnames(
      'relative mt-10 flex h-fit cursor-pointer flex-row items-center justify-between gap-1 rounded-lg bg-gray-feather px-8 py-6 hover:bg-gray-light'
    )}
    aria-label="Segment from program"
    id="segmentListHeaderTitle"
  >
    <div className="flex flex-col">
      <span className="text-2 relative top-0">
        <p className="text-gray-dark">Segment from program</p>
      </span>
      <div className="text-2 relative bottom-0 text-left">{segmentListHeaderTitle}</div>
    </div>
    <Icon className="flex" name="chevron-right" size="large" />
  </Link>
);

export default SegmentListHeader;
