import type { CollectionProduct as CollectionProductProps, SiteSettings } from '@/@types/content';
import ContentArea from '../cms/ContentArea';
import withNav from '@/components/hocs/withNav';
import { IContent } from '@/@types/cms';
import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface CollectionProductComponentProps extends CollectionProductProps, SiteSettings {
  basePageHeaderSettings: IContent[] | undefined;
  basePageTopSettings: IContent[] | undefined;
  basePageMiddleSettings: IContent[] | undefined;
  basePageBottomSettings: IContent[] | undefined;
  collectionUrl?: string;
}

const CollectionProductComponent: React.FC<CollectionProductComponentProps> = ({
  overrideLayout,
  headerContentArea,
  topContentArea,
  middleContentArea,
  bottomContentArea,
  basePageHeaderSettings,
  basePageTopSettings,
  basePageMiddleSettings,
  basePageBottomSettings,
  containedCategories,
  collectionUrl,
  alternatePageContent
}) => {
  const headerComponents = overrideLayout
    ? headerContentArea || undefined
    : basePageHeaderSettings || undefined;
  const topComponents = overrideLayout
    ? topContentArea || undefined
    : basePageTopSettings || undefined;
  const middleComponents = overrideLayout
    ? middleContentArea || undefined
    : basePageMiddleSettings || undefined;
  const bottomComponents = overrideLayout
    ? bottomContentArea || undefined
    : basePageBottomSettings || undefined;
  const navigate = useNavigate();
  const alternateUrl = alternatePageContent?.url || '';

  useEffect(() => {
    if (alternateUrl) {
      const u = new URL(`${window.location.origin}${alternateUrl}`);
      navigate(u.pathname + (u.search ?? ''), { replace: true });
    }
  }, [navigate, alternateUrl]);

  return alternatePageContent?.url ? (
    <></>
  ) : (
    <Fragment>
      {headerComponents && (
        <ContentArea
          propertyName={overrideLayout ? 'HeaderContentArea' : 'BasePageHeaderSettings'}
          components={headerComponents}
        />
      )}

      {topComponents && (
        <ContentArea
          propertyName={overrideLayout ? 'TopContentArea' : 'BasePageTopSettings'}
          components={topComponents}
          componentsProps={{
            overrideContainedCategories: containedCategories,
            overrideCollectionUrl: collectionUrl
          }}
        />
      )}

      {middleComponents && (
        <ContentArea
          propertyName={overrideLayout ? 'MiddleContentArea' : 'BasePageMiddleSettings'}
          components={middleComponents}
          componentsProps={{
            overrideContainedCategories: containedCategories,
            overrideCollectionUrl: collectionUrl
          }}
        />
      )}

      {bottomComponents && (
        <ContentArea
          propertyName={overrideLayout ? 'BottomContentArea' : 'BasePageBottomSettings'}
          components={bottomComponents}
        />
      )}
    </Fragment>
  );
};

const CollectionProduct = withNav(CollectionProductComponent);
export default CollectionProduct;
