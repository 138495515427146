import { forwardRef, memo, Suspense, useCallback, useState } from 'react';
import parse from 'html-react-parser';
import Button, { ButtonProps } from '@/components/ui/Buttons/Button';
import Panel from '@/components/ui/Panel';
import { AnnouncementMessage } from '@/@types/client-api';
import Accordion from '@/components/ui/Accordion';
import Icon from '@/components/ui/Icon';
import RichText from '@/components/cms/RichText';
import Text from '@/components/cms/Text';
import { translateToTailwinds } from '@/components/ui/CreditTracker/util/TrackerHelpers';
import Link from '@/components/ui/Link';

interface IRequirementsPanelProps {
  creditRegionName: string;
  announcements: AnnouncementMessage[];
  scrollIndex?: number | null;
  onOpenChange?: (open: boolean) => void;
  buttonType?: 'button' | 'link';
  buttonSize?: ButtonProps['size'];
  buttonIcon?: string;
  buttonClassName?: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

export const RequirementsPanel = memo(
  forwardRef<HTMLButtonElement, IRequirementsPanelProps>((props, ref) => {
    const {
      creditRegionName,
      announcements,
      scrollIndex,
      onOpenChange,
      buttonType = 'button',
      buttonSize = 'large',
      buttonIcon,
      buttonClassName,
      buttonText,
      onButtonClick
    } = props;

    const getAccordionId = useCallback((index: number) => `requirement-${index}`, []);

    const accordionItems =
      announcements &&
      announcements.map((announcement, index) => {
        const expireNewStatusDate = announcement.expireNewStatus
          ? new Date(announcement.expireNewStatus)
          : null;

        const today = new Date();
        const shouldDisplayNewBadge = expireNewStatusDate
          ? expireNewStatusDate.getTime() > today.getTime()
          : false;

        const newBadge = expireNewStatusDate && (
          <span className="text-2 mr-2 flex flex-row flex-nowrap items-center rounded bg-black px-2 py-1 text-white">
            New
          </span>
        );

        const iconBadge = announcement.icon && (
          <Icon name={announcement.icon} size="large" className="mr-2" />
        );

        const header = (
          <div className="flex items-center">
            {(shouldDisplayNewBadge && newBadge) || iconBadge}
            <h6 className="heading-6-medium">{parse(announcement.headingText ?? '')}</h6>
          </div>
        );

        const content = (
          <RichText
            className="text-2 pb-6 pt-2 text-gray-dark [&_a]:underline [&_ol]:my-1 [&_p]:my-3 [&_ul]:my-1"
            content={translateToTailwinds(announcement.body?.toString() ?? '')}
            key={getAccordionId(index)}
          />
        );

        return {
          id: getAccordionId(index),
          header,
          content: [content]
        };
      });

    // Use this to only scroll on open, not on other animations
    const [shouldScroll, setShouldScroll] = useState(true);
    const scrollToRequirement = () => {
      if (scrollIndex != null && shouldScroll) {
        const id = getAccordionId(scrollIndex);
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: 'smooth' });
        setShouldScroll(false);
      }
    };

    const handleOpenChange = (open: boolean) => {
      setShouldScroll(open);
      onOpenChange && onOpenChange(open);
    };

    const button = () => {
      return buttonType == 'link' ? (
        <Link
          onClick={onButtonClick}
          className={buttonClassName}
          variant="link-with-icon"
          icon="chevron-right"
          text={buttonText ?? 'View requirements'}
          as="button"
          ref={ref}
        />
      ) : (
        <Button
          onClick={onButtonClick}
          className={buttonClassName}
          color="outline-black"
          iconRight={buttonIcon}
          label={buttonText ?? 'View requirements'}
          size={buttonSize}
          ref={ref}
        />
      );
    };

    return (
      <Panel onOpenChange={handleOpenChange}>
        <Panel.Trigger asChild>{button()}</Panel.Trigger>
        <Suspense fallback={null}>
          <Panel.Content
            onAnimationEnd={scrollToRequirement}
            side="right"
            closeLabel="Close requirements"
          >
            <Panel.Header className="mb-5">
              <Text element="h5" className="heading-5">
                {creditRegionName} Requirements
              </Text>
            </Panel.Header>
            <Panel.Body>
              {accordionItems.length > 0 && (
                <Accordion
                  type="multiple"
                  items={accordionItems || []}
                  separator={<hr className="my-6 text-gray-light" />}
                  defaultValue={scrollIndex == null ? ['0'] : [scrollIndex.toString()]}
                />
              )}
            </Panel.Body>
          </Panel.Content>
        </Suspense>
      </Panel>
    );
  })
);
