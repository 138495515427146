import { useCallback, useState } from 'react';
import { FilterProps } from '../../Filter/types';

const useSearchFilterPanelL2 = () => {
  const [panelCloseCounter, setPanelCloseCounter] = useState(0);
  const [lastFilterExpanded, setLastFilterExpanded] = useState<string>();

  const filterKeyGenerator = useCallback(
    (filter: FilterProps) => `${filter.groupTypeId}-${panelCloseCounter}`,
    [panelCloseCounter]
  );

  // Whenever the panel closes, increment the counter so we can use this as a key to reset filter order
  const incrementPanelCloseCounter = useCallback((filter: FilterProps) => {
    setPanelCloseCounter(count => count + 1);
    setLastFilterExpanded(filter.groupTypeId);
  }, []);

  const isLastFilterExpanded = useCallback(
    (filter: FilterProps) => lastFilterExpanded === filter.groupTypeId,
    [lastFilterExpanded]
  );

  const clearPanelCloseCounter = useCallback(() => {
    setPanelCloseCounter(0);
    setLastFilterExpanded(undefined);
  }, []);

  return {
    isLastFilterExpanded,
    clearPanelCloseCounter,
    incrementPanelCloseCounter,
    filterKeyGenerator
  };
};

export default useSearchFilterPanelL2;
