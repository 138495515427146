import Thumbnail from '@/components/ui/Thumbnail';
import { Variant } from '@/redux/slices/pdpSlice';
import { OnDemandProps } from '..';
import { getFormatedMonthYear } from '@/lib/helpers/date';

interface SingleRecordingProps {
  labels: OnDemandProps['labels'];
  variant: Variant;
  onClick?: () => void;
  isSuccess?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  loadingProgramSegments?: boolean;
}

const SingleRecording: React.FC<SingleRecordingProps> = ({
  labels,
  variant,
  onClick,
  loadingProgramSegments,
  ...props
}) => {
  const canLaunch = variant.purchased || !variant.retail;
  const thumbnailIcon = {
    label: canLaunch ? labels.launchButton : labels.purchaseButton,
    name: canLaunch ? 'play' : 'cart'
  };

  const Image = (
    <Thumbnail
      loading={loadingProgramSegments}
      icon={thumbnailIcon}
      image={{
        alt: variant.displayName,
        url: variant.thumbnail ?? ''
      }}
      progressValue={variant.progress}
      tag={
        variant.progress !== 100
          ? {
              children: variant.duration
            }
          : undefined
      }
      card
      onClick={onClick}
      {...props}
      imageSizes={{
        xs: '320px',
        sm: '496px',
        md: '768px',
        lg: '342px'
      }}
    />
  );

  return (
    <>
      {Image}
      <div className="mt-6 flex items-center justify-between">
        <p className="text-2 font-medium">
          {labels.recorded}{' '}
          {!!variant.publicationDate && getFormatedMonthYear(variant.publicationDate)}
        </p>
        <p className="text-2 font-medium text-gray-dark">{variant.location}</p>
      </div>
    </>
  );
};

export default SingleRecording;
