import { useSelector } from 'react-redux';

import type { ISearchResultItem } from '@/@types/client-api/models/ISearchResultItem';
import { useGetMediaStreamsQuery } from '@/redux/api/client/product';

import searchableTypeToSnippetVariant, {
  SearchableType,
  getSnippetRenderer
} from './searchableTypeToSnippetVariant';
import useSnippetActions from './hooks/useSnippetActions';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { useState } from 'react';
import { SearchModalPanelState } from './hooks/useSearchModalPanelState';
import { ActionStatus } from './constants';
import { hasMultipleRecordings } from './Snippets/helpers';
import { AnalyticsContext } from '@/analytics/constants';

const useGetSearchResultRow = (
  searchResultItem: ISearchResultItem,
  modalPanelState: SearchModalPanelState
) => {
  const pageLinkPaths = useSelector(selectPageLinkPaths);
  const { searchableType = null, variationPk = '', productGuidValue = null } = searchResultItem;
  const snippetVariant = searchableTypeToSnippetVariant(searchableType ?? '');
  const { data: productMedia, error: streamingError } = useGetMediaStreamsQuery(
    searchResultItem.variationPk!,
    {
      skip: !searchResultItem?.isLive || !searchableType || snippetVariant !== 'liveprogram'
    }
  );
  let streamingUrl = null;
  if (searchableType && snippetVariant === 'liveprogram') {
    if (searchResultItem?.isLive && productMedia?.length) {
      streamingUrl = productMedia[0].playbackUrl;
    }
    if (searchResultItem.isLive && streamingError) {
      console.error('Failed to load streaming media', streamingError);
    }
  }
  const { userList } = searchResultItem || {};
  const userListItem = userList?.find(
    userListItem => userListItem.registrationDetails || userListItem
  );

  const chosenOneWithPurchaseDetailsFromSearch = userList?.find(
    userListItem =>
      (userListItem?.registrationDetails || userListItem) && userListItem?.pk == variationPk
  );

  const pk = chosenOneWithPurchaseDetailsFromSearch ? variationPk : userListItem?.pk;
  const [actionStatus, setActionStatus] = useState<ActionStatus>('idle');

  const actions = useSnippetActions(
    pk ?? variationPk ?? '',
    searchResultItem.url ?? '',
    searchResultItem.title ?? '',
    AnalyticsContext.Search,
    snippetVariant,
    modalPanelState,
    snippetVariant !== 'ondemandsegment' && hasMultipleRecordings(searchResultItem.userList),
    productGuidValue,
    setActionStatus,
    undefined,
    userListItem?.isPurchased || !!userListItem?.isParentProgramPurchased
      ? (userListItem.watchUrl ?? undefined)
      : undefined,
    searchResultItem?.itemAnalyticsModel
  );

  const renderSnippetParams = {
    ...searchResultItem,
    streamingUrl,
    searchableType: searchResultItem.searchableType as unknown as SearchableType,
    locations: searchResultItem.schedule?.map(scheduleItem => ({
      ...scheduleItem,
      startTimeUtc: scheduleItem.startDateUtc,
      endTimeUtc: scheduleItem.endDateUtc,
      name: scheduleItem.location
    })),
    userList: userList?.map(userListItem => ({
      ...userListItem,
      isPurchased: userListItem.isPurchased || !!userListItem.isParentProgramPurchased
    })),
    variationPk: pk || variationPk
  };

  const renderer = getSnippetRenderer(searchableType);
  if (renderer) {
    return renderer({
      data: renderSnippetParams,
      actions,
      context: 'search',
      links: pageLinkPaths,
      modalPanelState,
      actionStatus,
      itemAnalyticsModel: renderSnippetParams?.itemAnalyticsModel
    });
  }

  return null;
};

export default useGetSearchResultRow;
