import { CreditClassPage } from '@/@types/content';
import { createContext } from 'react';

interface CreditInfoContextType {
  isLoading?: boolean;
  riverPageChildren: Array<CreditClassPage>;
  specialStatusPreferences?: { [key: string]: string };
}

export const CreditInfoContext = createContext<CreditInfoContextType>({
  isLoading: false,
  riverPageChildren: []
});
