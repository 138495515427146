import { useMemo } from 'react';
import { IContentExtended, expandChildren } from '@/lib/helpers/expandChildren';
import { IContent } from '@/@types/cms';
import { PDPLayoutBlock as PDPLayoutBlockProps } from '@/@types/content';
import { LiveVariation, ProgramVariation } from './useProgramCatalogRelations';
import { determineLiveProgramStatus } from '@/components/ui/PDP/helpers';

interface PDPLayoutProps {
  layout?: PDPLayoutBlockProps;
  contentType: string;
  variant: ProgramVariation;
  isOndemand?: boolean;
  isOhb?: boolean;
}

const getLayoutKey = (contentType: string, isLive: boolean, useOverride: boolean): string => {
  const layoutMap: Record<string, string> = {
    LiveProgramGroupcastVariation: isLive ? 'liveProductSeminarLayout' : 'upcomingLiveLayout',
    LiveProgramSeminarVariation: isLive ? 'liveProductSeminarLayout' : 'upcomingLiveLayout',
    LiveProgramWebcastVariation: isLive ? 'liveProductWebcastLayout' : 'upcomingLiveLayout',
    LiveProgramPbrSeminarVariation: isLive ? 'liveProductSeminarLayout' : 'upcomingLiveLayout',
    LiveProgramPBRWebcastVariation: isLive ? 'liveProductWebcastLayout' : 'upcomingLiveLayout',
    LiveProgramOhbVariation: isLive ? 'liveProductWebcastLayout' : 'upcomingLiveLayout',
    OnDemandVariation: 'onDemandProductLayout'
  };
  const overrideLayoutMap: Record<string, string> = {
    LiveProgramGroupcastVariation: isLive ? 'liveNowLayoutOverride' : 'productLayoutOverride',
    LiveProgramSeminarVariation: isLive ? 'liveNowLayoutOverride' : 'productLayoutOverride',
    LiveProgramWebcastVariation: isLive ? 'liveNowLayoutOverride' : 'productLayoutOverride',
    LiveProgramPbrSeminarVariation: isLive ? 'liveNowLayoutOverride' : 'productLayoutOverride',
    LiveProgramPBRWebcastVariation: isLive ? 'liveNowLayoutOverride' : 'productLayoutOverride',
    LiveProgramOhbVariation: isLive ? 'liveNowLayoutOverride' : 'productLayoutOverride',
    OnDemandVariation: 'productLayoutOverride'
  };
  return useOverride ? overrideLayoutMap[contentType] : layoutMap[contentType];
};

const getValidationArray = (isOndemand: boolean, isOhb: boolean): string[] => {
  const sharedBaseBlocks = [
    'IPDPOverviewLayoutBlock',
    'IPDPProgramCreditLayoutBlock',
    'IPDPProgramFacultyLayoutBlock',
    'IPDPContentLayoutBlock',
    'IPDPTopicsAndIndustriesLayoutBlock',
    'IPDPTestimonialsLayoutBlock',
    'IPDPAdditionalInfo1LayoutBlock',
    'IPDPAdditionalInfo2LayoutBlock',
    'IPDPAdditionalInfo3LayoutBlock',
    'IPDPProgramAnnouncementsLayoutBlock',
    'IPDPSpecialFeaturesLayoutBlock',
    'IPDPWhatYouWillLearnLayoutBlock',
    'IPDPWhoShouldAttendLayoutBlock',
    'IPDPWhyYouShouldAttendLayoutBlock'
  ];
  // On-Demand PDP
  const onDemandBaseBlocks = sharedBaseBlocks;
  const nonOhbOnDemandBlocks = ['IPDPProgramSegmentsLayoutBlock'];
  if (isOndemand) {
    if (isOhb) return onDemandBaseBlocks;
    else return [...onDemandBaseBlocks, ...nonOhbOnDemandBlocks];
  }

  // Live PDP
  const liveBaseBlocks = sharedBaseBlocks;
  const nonOhbLiveBlocks = [
    'IPDPProgramScheduleLayoutBlock',
    'IPDPProgramLocationDetailsLayoutBlock'
  ];
  if (isOhb) return liveBaseBlocks;
  else return [...liveBaseBlocks, ...nonOhbLiveBlocks];
};

const usePDPLayout = ({
  layout,
  contentType,
  variant,
  isOndemand = false,
  isOhb = false
}: PDPLayoutProps) => {
  const layoutDefinition = useMemo<IContent[]>(() => {
    const expandedLayout = expandChildren(layout as IContentExtended);
    const useOverride = 'productLayoutOverride' in variant;
    const isLive = (variant as LiveVariation)?.segments
      ? determineLiveProgramStatus(
          variant.eventStartDate,
          variant.eventEndDate,
          (variant as LiveVariation)?.segments
        ) == 'Live'
      : false;
    const layoutKey = getLayoutKey(contentType, isLive, useOverride);
    if (useOverride) {
      const expandedVariant = expandChildren(variant as IContentExtended);
      return layoutKey ? (expandedVariant[layoutKey as keyof ProgramVariation] as IContent[]) : [];
    } else {
      return layoutKey ? (expandedLayout[layoutKey] as IContent[]) : [];
    }
  }, [contentType, layout, variant]);

  const filteredLayout = useMemo<IContent[]>(() => {
    const layoutValidation = getValidationArray(isOndemand, isOhb);

    return layoutDefinition?.filter(item => {
      return (
        item?.contentType &&
        layoutValidation.includes(item?.contentType[item?.contentType.length - 1])
      );
    });
  }, [isOndemand, isOhb, layoutDefinition]);

  return { layout: filteredLayout, variant };
};

export default usePDPLayout;
