import classnames from 'classnames';
import { Fragment, useCallback } from 'react';
import Shimmer from '@/components/ui/Shimmer';
import {
  CreditDetails,
  RowInfo
} from '@/components/ui/PDP/ProgramCredit/ViewCreditsForAllJurisdiction';
import Icon from '@/components/ui/Icon';
import RichText from '@/components/cms/RichText';

export type TableProps = {
  tableHeader?: string[];
  rows?: RowInfo[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
};

const TableLoading = ({ isLoadMore }: { isLoadMore?: boolean }) => (
  <div
    className={classnames('flex flex-col gap-4', {
      'mt-4': isLoadMore
    })}
  >
    {!isLoadMore && <Shimmer className="h-[52px] w-full" />}
    {Array.from({ length: 6 }).map((_, index) => (
      <Shimmer className="h-[168px] w-full rounded md:h-[100px]" key={index} />
    ))}
  </div>
);

const AllJurisdictionsTable: React.FC<TableProps> = ({
  isLoading,
  isLoadingMore,
  tableHeader,
  rows
}) => {
  const tdLargeClasses = classnames('text-2 md:py-6 md:pr-6');

  const renderCreditDetails = useCallback((details: CreditDetails[]) => {
    return (
      details &&
      details.map(({ creditOffered, typeTitle }, index) => (
        <div className="mb-3" key={index}>
          <span className="mr-3">{creditOffered}</span>
          <span>{typeTitle}</span>
        </div>
      ))
    );
  }, []);

  const CREDIT_STATES = {
    approved: ['Approved'],
    warning: ['Pending', 'CreditUponRequest', 'ApplicationSubmitted'],
    failed: ['NotApproved', 'Expired', 'NonMcleState', 'Undefined']
  };

  const renderStatusIcon = useCallback(
    (statusEnum: string) => {
      if (CREDIT_STATES.warning.includes((statusEnum as unknown as string) ?? 'Undefined'))
        return (
          <Icon
            size="small"
            name="pending"
            aria-label="Credit Status Warning"
            className="lg:mt-0.5"
          />
        );
      if (CREDIT_STATES.approved.includes((statusEnum as unknown as string) ?? 'Undefined'))
        return (
          <Icon
            size="small"
            name="approved"
            aria-label="Credit Status Success"
            className="lg:mt-0.5"
          />
        );
      return (
        <Icon
          size="small"
          name="not-approved"
          aria-label="Credit Status Danger"
          className="lg:mt-0.5"
        />
      );
    },
    [CREDIT_STATES.approved, CREDIT_STATES.warning]
  );

  const renderSpecialMessage = (statusDesc: string, isTransitional: boolean) => {
    return (
      <RichText
        propertyName="Copy"
        content={
          isTransitional
            ? `${statusDesc} for experienced and newly admitted (no ethics/cyber-ethics or skills on-demand for newly admitted). <a href="/credit-info/new-york-transitional" target="_blank">Read More</a>`
            : `${statusDesc} for Experienced Only`
        }
      />
    );
  };

  const renderStatus = useCallback(
    (statusDesc: string, statusEnum: string, regionSk?: number, isTransitional?: boolean) => {
      return (
        <div className="flex items-start">
          {renderStatusIcon(statusEnum)}
          <span className="ml-2">
            {regionSk === 35 ? renderSpecialMessage(statusDesc, !!isTransitional) : statusDesc}
          </span>
        </div>
      );
    },
    [renderStatusIcon]
  );

  if (isLoading) {
    return <TableLoading />;
  }

  return (
    <>
      <div className="grid w-full rounded-md border border-solid border-gray-light">
        <table className="w-full text-left">
          <thead className="text-2 bg-gray-feather">
            <tr>
              {tableHeader?.map((item, index) => (
                <th
                  className="whitespace-nowrap py-4 font-medium md:pr-6 md:first:pl-8 md:last:pr-8"
                  key={`headerLarge-${index}`}
                  scope="col"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-solid divide-gray-light">
            {rows?.map(
              (
                {
                  jurisdiction,
                  statusDescription,
                  statusEnum,
                  total,
                  creditDetails,
                  isTransitional,
                  regionSk
                },
                index
              ) => (
                <tr key={`rowLarge-${index}`} className="align-top">
                  <Fragment key={`rowInfoLarge-${index}`}>
                    <td
                      className={classnames(
                        tdLargeClasses,
                        'text-2-bold w-1/4 max-w-72 md:first:pl-8'
                      )}
                    >
                      <span className="font-medium text-gray-dark">{jurisdiction}</span>
                    </td>
                    <td className={classnames(tdLargeClasses, 'w-1/4 max-w-64')}>
                      {renderStatus(statusDescription, statusEnum, regionSk, isTransitional)}
                    </td>
                    <td className={classnames(tdLargeClasses, 'w-24')}>
                      {total > 0.0 ? total.toFixed(1) : total}
                    </td>
                    <td className={classnames(tdLargeClasses, 'flex grow flex-col')}>
                      {renderCreditDetails(creditDetails)}
                    </td>
                  </Fragment>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {isLoadingMore && <TableLoading isLoadMore />}
    </>
  );
};

AllJurisdictionsTable.displayName = 'AllJurisdictionsTable';
export default AllJurisdictionsTable;
