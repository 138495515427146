import * as Collapsible from '@radix-ui/react-collapsible';
import Divider from '../../Divider';
import Icon from '../../Icon';
import { getTime } from '@/lib/helpers/date';
import { ProgramScheduleProps } from '.';
import RichText from '@/components/cms/RichText';

interface ScheduleItemProps {
  labels: ProgramScheduleProps['labels'];
  date: string;
  timeZoneIdentifier: string;
  title?: string;
  description?: string;
  speaker?: string;
  expanded: boolean;
  onToggle?: (isOpen: boolean) => void;
  canExpand: boolean;
}

const ScheduleItem: React.FC<ScheduleItemProps> = ({
  labels,
  date,
  timeZoneIdentifier,
  title,
  description,
  /*speaker,*/
  expanded,
  onToggle,
  canExpand
}) => {
  return (
    <Collapsible.Root open={expanded} onOpenChange={onToggle}>
      <div className="flex items-start py-8" data-component={'ScheduleItem'}>
        <p className="text-2 mr-[30px] w-[70px] shrink-0 font-medium text-gray lg:w-20">
          {getTime(date, timeZoneIdentifier)}
        </p>
        <div className="grow">
          <h3 className="heading-6-medium">{title ?? ''}</h3>
          <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown">
            {description ? (
              <RichText
                content={description}
                className="text-1-fixed pt-6 text-gray-dark"
                condensed
              />
            ) : (
              <></>
            )}
            {/*SIREHOME-4933*/}
            {/*speaker ? <p className="label pt-6 text-gray-dark">{speaker}</p> : <></>*/}
          </Collapsible.Content>
        </div>
        {canExpand && description?.length ? (
          <Collapsible.Trigger
            className="ml-6 shrink-0"
            aria-label={expanded ? labels.collapse : labels.expand}
          >
            {expanded ? (
              <Icon name="chevron-up" size="large" />
            ) : (
              <Icon name="chevron-down" size="large" />
            )}
          </Collapsible.Trigger>
        ) : (
          <></>
        )}
      </div>
      <Divider color="light" />
    </Collapsible.Root>
  );
};

export default ScheduleItem;
