import {
  AnswerBookSingleTitleSubscriptionVariation,
  AnswerBookVariation,
  BookProduct,
  CourseHandbookSingleTitleSubscriptionVariation,
  CourseHandbookVariation,
  TreatiseSingleTitleSubscriptionVariation,
  TreatiseVariation
} from '@/@types/content';
import { ProgramVariation } from './useProgramCatalogRelations';
import { useGetContentQuery } from '@/redux/api/contentDeliveryAPI';
import { skipToken } from '@reduxjs/toolkit/query';
import { IContent } from '@/@types/cms';
import { isOfContentType } from '@/lib/helpers/contentType';
import { isValidForSale } from '@/components/ui/PDP/helpers';
import { useGetAllChildren } from '../useGetAllChildren';
import { useProductContentQuery } from './useProductContentQuery';
import { isDefined } from '@/lib/helpers/isDefined';

type ABTreatisePrintVariation = AnswerBookVariation | TreatiseVariation;
type ABTreatiseSTSVariation =
  | AnswerBookSingleTitleSubscriptionVariation
  | TreatiseSingleTitleSubscriptionVariation;
export type ABTreatiseVariation = ABTreatisePrintVariation | ABTreatiseSTSVariation;

export type PublicationCatalogRelations = ABTreatiseCatalogRelations | CHBCatalogRelations;
export type PublicationVariation = ABTreatiseVariation | CHBVariation;
export type DigitalAccessVariation = PublicationVariation & {
  plusAccessSource?: 'program' | 'membership';
};
export type ABTreatiseCatalogRelations = {
  bookProduct?: BookProduct;
  printVariants: ABTreatisePrintVariation[];
  stsVariant?: ABTreatiseSTSVariation;
  digitalAccessVariant?: DigitalAccessVariation;
  pseudoStrikePrice?: number;
  isPlusSubscriber?: boolean;
  isLoading?: boolean;
};

enum BOOK_CONTENT_TYPES {
  AnswerBookVariation = 'AnswerBookVariation',
  TreatiseVariation = 'TreatiseVariation',
  AnswerBookSTSVariation = 'AnswerBookSingleTitleSubscriptionVariation',
  TreatiseSTSVariation = 'TreatiseSingleTitleSubscriptionVariation',
  CourseHandbookVariation = 'CourseHandbookVariation',
  CourseHandbookSTSVariation = 'CourseHandbookSingleTitleSubscriptionVariation'
}

const ABTreatisePrintContentTypes = [
  BOOK_CONTENT_TYPES.AnswerBookVariation,
  BOOK_CONTENT_TYPES.TreatiseVariation
];
export const isABTreatisePrintContentType = (content: IContent) =>
  isOfContentType(content, ABTreatisePrintContentTypes);
const ABTreatiseSTSContentTypes = [
  BOOK_CONTENT_TYPES.AnswerBookSTSVariation,
  BOOK_CONTENT_TYPES.TreatiseSTSVariation
];
export const isABTreatiseSTSContentType = (content: IContent) =>
  isOfContentType(content, ABTreatiseSTSContentTypes);

const ABContentTypes = [
  BOOK_CONTENT_TYPES.AnswerBookVariation,
  BOOK_CONTENT_TYPES.AnswerBookSTSVariation
];
const TreatiseContentTypes = [
  BOOK_CONTENT_TYPES.TreatiseVariation,
  BOOK_CONTENT_TYPES.TreatiseSTSVariation
];
export const isAnswerBookVariant = (content: IContent) => isOfContentType(content, ABContentTypes);
export const isTreatiseVariant = (content: IContent) =>
  isOfContentType(content, TreatiseContentTypes);
export const isCHBPrintVariant = (c: IContent) =>
  isOfContentType(c, [BOOK_CONTENT_TYPES.CourseHandbookVariation]);
export const isCHBSTSVariant = (c: IContent) =>
  isOfContentType(c, [BOOK_CONTENT_TYPES.CourseHandbookSTSVariation]);
export const isCHBVariant = (c: IContent) => isCHBPrintVariant(c) || isCHBSTSVariant(c);

export const isBookVariant = (content: IContent) =>
  isAnswerBookVariant(content) || isTreatiseVariant(content) || isCHBVariant(content);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getBookProductLink = (c: CHBVariation | ABTreatiseVariation) => (c as any)?.bookProductLink;

const sortByLastUpdatedDateDesc = (a: ABTreatiseVariation, b: ABTreatiseVariation) => {
  const dateA = a.lastUpdatedDate ? new Date(a.lastUpdatedDate).getTime() : 0;
  const dateB = b.lastUpdatedDate ? new Date(b.lastUpdatedDate).getTime() : 0;
  if (dateA < dateB) return 1;
  if (dateA > dateB) return -1;
  return 0;
};

export const isPrerelease = (v: PublicationVariation) => {
  if (v.publicationDate && new Date(v.publicationDate) > new Date()) return true;
  return false;
};

const isSuppressedPreviousEdition = (
  latestUpdatedVariant: PublicationVariation,
  variant: PublicationVariation
) => {
  // New editions are variants under same product as old editions.
  // They do not expire the old edition variants, but they should not be shown in PDP
  const dateToCompare = new Date(variant.lastUpdatedDate!);
  dateToCompare.setDate(dateToCompare.getDate() + 335);
  if (dateToCompare < new Date(latestUpdatedVariant.lastUpdatedDate!)) return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getVariantPrice = (variant?: IContent) => (variant as any)?.fullPrice;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getIsPlusSubscriber = (product?: IContent) => (product as any)?.isPlusSubscriber ?? false;

export const getABTreatisePks = (catalogRelations: ABTreatiseCatalogRelations) =>
  [
    ...(catalogRelations.printVariants?.map(v => v.code) ?? []),
    catalogRelations.stsVariant?.code
  ].filter(isDefined);
export const getCHBPks = (catalogRelations: CHBCatalogRelations) =>
  [catalogRelations.printVariant?.code, catalogRelations.stsVariant?.code].filter(isDefined);

const createDigitalAccessVariantIfEligible = (
  bookProduct?: BookProduct,
  printVariants?: PublicationVariation[],
  stsVariant?: PublicationVariation
): DigitalAccessVariation | undefined => {
  if (!bookProduct || (!printVariants && !stsVariant)) return undefined;

  if (!bookProduct.plusAvailable || !bookProduct.plusCode || !bookProduct.plusUrl) return undefined;
  // If user does not have digital access, do not create digital access variant
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hasDigitalAccess = (bookProduct as any)?.hasPlusAccessFromProgramOrMembership === true;
  if (!hasDigitalAccess) return undefined;

  // If user has digital access, match the bookProduct plusCode to the variant
  // if plusCode can't be matched with the printVariants, fall back to sts variant, to support sts chb standalone.
  const plusCode = bookProduct.plusCode;
  const sourceVariant = printVariants?.find(v => v.code === plusCode) ?? stsVariant;
  if (!sourceVariant) return undefined;
  const hasDigitalAccessFromProgram =
    isCHBVariant(sourceVariant) && (bookProduct as any)?.hasPlusAccessFromProgram === true; // eslint-disable-line @typescript-eslint/no-explicit-any
  const digitalAccessUrl = `${sourceVariant.contentLink?.url}?plus`;
  const digitalAccessVariant = {
    ...sourceVariant,
    contentLink: { ...sourceVariant.contentLink, url: digitalAccessUrl },
    url: digitalAccessUrl,
    plusAccessSource: hasDigitalAccessFromProgram ? 'program' : 'membership'
  } as DigitalAccessVariation;
  return digitalAccessVariant;
};

export const useABTreatiseCatalogRelations = (
  variant?: ABTreatiseVariation
): ABTreatiseCatalogRelations => {
  const isPresaleVariant = variant ? isPrerelease(variant) : false;
  const query = useProductContentQuery();
  const { data: bookProduct, isFetching: isBookProductFetching } = useGetContentQuery(
    variant ? { contentLink: getBookProductLink(variant), query } : skipToken
  );
  const { children, isLoading: isBookProductChildrenFetching } = useGetAllChildren({
    contentLink: bookProduct?.contentLink?.guidValue,
    enableWMSPrice: 'all'
  });
  const allPublishedPrintVariants = children?.filter(
    c => isABTreatisePrintContentType(c) && !isPrerelease(c)
  ) as ABTreatisePrintVariation[];
  const presaleVariants = children?.filter(
    c => isABTreatisePrintContentType(c) && isPrerelease(c)
  ) as ABTreatisePrintVariation[];

  const bestPrintVariant = allPublishedPrintVariants?.sort(sortByLastUpdatedDateDesc)[0];
  const activePrintVariants = allPublishedPrintVariants?.filter(
    v => !isSuppressedPreviousEdition(bestPrintVariant, v)
  );

  const baseStsVariant = children?.find(isABTreatiseSTSContentType) as ABTreatiseSTSVariation;
  const stsVariant =
    baseStsVariant && bestPrintVariant
      ? {
          ...baseStsVariant,
          author: bestPrintVariant.author,
          sortedTableOfContentListJsonString: bestPrintVariant.sortedTableOfContentListJsonString,
          publicationDate: bestPrintVariant.publicationDate,
          lastUpdatedDate: bestPrintVariant.lastUpdatedDate,
          volumeCount: bestPrintVariant.volumeCount,
          isbn: bestPrintVariant.isbn,
          pageCount: bestPrintVariant.pageCount
        }
      : undefined;
  const pseudoStrikePrice = stsVariant
    ? getVariantPrice(stsVariant)
    : getVariantPrice(bestPrintVariant);

  const digitalAccessVariant =
    bookProduct && children
      ? createDigitalAccessVariantIfEligible(bookProduct, activePrintVariants, stsVariant)
      : undefined;

  if (isPresaleVariant) {
    return {
      bookProduct,
      printVariants: presaleVariants,
      stsVariant: undefined,
      isLoading: isBookProductFetching
    };
  }
  return {
    bookProduct,
    printVariants: activePrintVariants,
    stsVariant,
    digitalAccessVariant,
    pseudoStrikePrice,
    isPlusSubscriber: getIsPlusSubscriber(bookProduct),
    isLoading: isBookProductFetching || isBookProductChildrenFetching
  };
};

export type CHBVariation = CourseHandbookVariation | CourseHandbookSingleTitleSubscriptionVariation;

export type CHBCatalogRelations = {
  bookProduct?: BookProduct;
  printVariant?: CourseHandbookVariation;
  stsVariant?: CourseHandbookSingleTitleSubscriptionVariation;
  digitalAccessVariant?: DigitalAccessVariation;
  pseudoStrikePrice?: number;
  relatedProgramVariant?: ProgramVariation;
  isPlusSubscriber?: boolean;
  isLoading?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRelatedProgramLink = (c: CHBVariation) => (c as any)?.relatedProgramLink;

export const useCHBCatalogRelations = (variant?: CHBVariation): CHBCatalogRelations => {
  const query = useProductContentQuery();
  const { data: bookProduct, isFetching: isBookProductFetching } = useGetContentQuery(
    variant ? { contentLink: getBookProductLink(variant), query } : skipToken
  );
  const { data: relatedProgramVariant, isFetching: isRelatedProgramFetching } = useGetContentQuery(
    variant ? { contentLink: getRelatedProgramLink(variant) } : skipToken
  );
  const { children, isLoading: isBookProductChildrenFetching } = useGetAllChildren({
    contentLink: bookProduct?.contentLink?.guidValue,
    enableWMSPrice: 'all'
  });
  const printVariant = children?.find(v => isCHBPrintVariant(v) && isValidForSale(v));
  const stsVariant = children?.find(v => isCHBSTSVariant(v) && isValidForSale(v));

  const pseudoStrikePrice = stsVariant
    ? getVariantPrice(stsVariant)
    : getVariantPrice(printVariant);

  const digitalAccessVariant =
    bookProduct &&
    children &&
    createDigitalAccessVariantIfEligible(
      bookProduct,
      printVariant ? [printVariant] : undefined,
      stsVariant
    );
  return {
    bookProduct,
    printVariant,
    stsVariant,
    digitalAccessVariant,
    relatedProgramVariant,
    pseudoStrikePrice,
    isPlusSubscriber: getIsPlusSubscriber(bookProduct),
    isLoading: isBookProductFetching || isRelatedProgramFetching || isBookProductChildrenFetching
  };
};
