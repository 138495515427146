import { ClientAPI } from '@/redux/api';
import { UserIndustryResponse } from '@/@types/client-api';

const userIndustriesEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getUserIndustries: builder.query<UserIndustryResponse, void>({
      query: () => ({
        url: '/user/industries',
        method: 'GET'
      }),
      providesTags: [{ type: 'Library', id: 'UserIndustries' }]
    }),
    followIndustries: builder.mutation<void, string[]>({
      query: body => ({
        url: `/user/industries/follow`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Library', id: 'UserIndustries' }]
    }),
    unfollowIndustries: builder.mutation<void, string[]>({
      query: body => ({
        url: `/user/industries/unfollow`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Library', id: 'UserIndustries' }]
    }),
    followIndustryByCategoryId: builder.mutation<void, string[]>({
      query: body => ({
        url: `/user/industries/by-categoryid/follow`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Library', id: 'UserIndustries' }]
    }),
    unfollowIndustryByCategoryId: builder.mutation<void, string[]>({
      query: body => ({
        url: `/user/industries/by-categoryid/unfollow`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Library', id: 'UserIndustries' }]
    })
  })
});

export const {
  useGetUserIndustriesQuery,
  useFollowIndustriesMutation,
  useUnfollowIndustriesMutation,
  useFollowIndustryByCategoryIdMutation,
  useUnfollowIndustryByCategoryIdMutation
} = userIndustriesEndpoints;

export default userIndustriesEndpoints;
