import FooterNav from './FooterNav';
import Divider from '@/components/ui/Divider';
import { FooterBlock as FooterBlockProps, JavascriptLinkBlock } from '@/@types/content';
import Text from '@/components/cms/Text';
import Image from '@/components/cms/Image';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import useInViewport from '@/hooks/useInViewport';
import { useDispatch } from 'react-redux';
import { setFooterVisible } from '@/redux/slices/pageSlice';
import { trackLinkEvent, trackViewFooterLinkEvent } from '@/analytics/link';
import useSiteVersionPreference from '@/hooks/useSiteVersionPreference';

const FooterBlock: React.FC<FooterBlockProps> = ({
  footerLogo,
  footerLogoLink,
  footerDescription,
  linkList,
  bottomLinks,
  copyright,
  legacySiteLinkVisibility,
  legacySiteRedirectRule,
  legacySiteRedirectWhitelistUrls
}) => {
  const { setRejectNewSiteCookie } = useSiteVersionPreference(
    legacySiteRedirectRule,
    legacySiteRedirectWhitelistUrls
  );

  const handleLinkClick = (
    link: JavascriptLinkBlock,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (link.onClick) {
      Reflect.get(window, link.onClick || '')(e);
    }
  };

  useEffect(() => {
    document.getElementById('teconsent')?.removeAttribute('style');
    return () => {
      document.getElementById('teconsent')?.setAttribute('style', 'visibility: hidden;');
    };
  }, []);

  const dispatch = useDispatch();

  const [footerRef, isFooterInView] = useInViewport<HTMLElement>();

  useEffect(() => {
    if (isFooterInView) {
      dispatch(setFooterVisible(true));
    } else {
      dispatch(setFooterVisible(false));
    }
  }, [isFooterInView, dispatch]);

  return (
    <footer ref={footerRef} className="bg-charcoal py-16 text-white lg:py-20">
      <div className="container">
        {/* Top */}
        <div className="pb-6 md:flex md:justify-between md:gap-[145px] md:pb-16">
          <div className="max-w-[287px] shrink-0 sm:max-w-[497px] md:max-w-[331px] lg:max-w-[414px]">
            {!!footerLogo && (
              <div className="mb-8">
                <Link to={footerLogoLink}>
                  <Image
                    propertyName="FooterLogo"
                    src={footerLogo.url}
                    alt="Practising Law Institute"
                    className="h-10"
                  />
                </Link>
              </div>
            )}
            <Text propertyName="FooterDescription" className="heading-6-regular">
              {footerDescription}
            </Text>
          </div>
          {linkList && <FooterNav footerLinks={linkList} className="md:w-[451px] lg:w-[629px]" />}
        </div>

        {legacySiteLinkVisibility ? (
          <div className="mb-6">
            <Link
              to={`${window.env.APP_LEGACY_SITE_URL}?optOutNewSite=true`}
              onClick={setRejectNewSiteCookie}
              className="text-2 hover:underline"
            >
              Switch to the legacy site
            </Link>
          </div>
        ) : null}

        <Divider color="darker" />

        {/* Bottom */}
        <div className="flex flex-col gap-8 pt-8 text-silver md:flex-row md:items-center md:justify-between">
          <nav className="text-2 flex flex-wrap gap-3 font-medium md:justify-end md:gap-x-8">
            <ul
              className="flex flex-wrap gap-3 md:gap-x-8"
              data-component={'FooterBlock-bottomLinks'}
            >
              {(bottomLinks as JavascriptLinkBlock[])?.map((link, index) => {
                if (!link) return null;

                return (
                  <li key={index}>
                    <Link
                      to={link.link?.href}
                      onClick={e => {
                        trackLinkEvent(link);
                        trackViewFooterLinkEvent(link.link?.text);
                        handleLinkClick(link, e);
                      }}
                      className="flex items-center gap-2 hover:underline"
                    >
                      {link.link?.text}
                      {link.image && (
                        <Image
                          propertyName="Image"
                          src={link.image.url}
                          alt={link.link?.text}
                          className="max-h-4"
                        />
                      )}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <Text propertyName="Copyright">{copyright}</Text>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default FooterBlock;
