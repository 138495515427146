const problemReplace = '<problem>';
const refreshReplace = 'refresh';
const baseProblemMessage = `We're sorry, something went wrong ${problemReplace}. Please ${refreshReplace} and try again. `;

type ToastCategory = 'launch' | 'cart' | 'register' | 'register-update' | 'register-cancel';
const problemMessageMap = new Map<ToastCategory, string>([
  ['launch', 'while launching this program'],
  ['cart', 'while adding this program to your cart'],
  ['register', 'with your registration.'],
  ['register-update', 'while updating your registration'],
  ['register-cancel', 'while canceling your registration']
]);
export const makeToastProblemMessage = (category: ToastCategory, code?: number) => {
  const message = baseProblemMessage
    .replace(problemReplace, problemMessageMap.get(category)!)
    .replace(
      refreshReplace,
      '<a class="underline" href="javascript:window.location.reload(true)">refresh<a>'
    )
    .concat(code ? `Error code:${code}` : '');
  return <span dangerouslySetInnerHTML={{ __html: message }} />;
};
