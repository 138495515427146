import { useSelector } from 'react-redux';
import classnames from 'classnames';
import type { ProgressiveOnboardingExperienceContainerBlock } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';
import Text from '@/components/cms/Text';
import { RootState } from '@/redux/store';
import { useMemo } from 'react';
import Block from '../cms/Block';

export type ProgressiveOnboardingExperienceContainerBlockProps =
  ProgressiveOnboardingExperienceContainerBlock;

const ProgressiveOnboardingExperienceContainerBlock: React.FC<
  ProgressiveOnboardingExperienceContainerBlockProps
> = ({ contentLink, heading, finishProfileItem, findProgramItem }) => {
  const dismissedElements = useSelector((state: RootState) => state.page.dismissedElements);

  const showFinishProfile = useMemo(() => {
    if (!finishProfileItem?.length) {
      return false;
    }
    return !dismissedElements[window.env?.FINISH_PROFILE_BLOCK_COOKIE_NAME || 'finishProfileBlock'];
  }, [dismissedElements, finishProfileItem?.length]);

  const showFindAProgram = useMemo(() => {
    if (!findProgramItem?.length) {
      return false;
    }
    return !dismissedElements[window.env?.FIND_A_PROGRAM_BLOCK_COOKIE_NAME || 'findAProgramBlock'];
  }, [dismissedElements, findProgramItem?.length]);

  return (
    <>
      {showFindAProgram || showFinishProfile ? (
        <Block contentLinkID={contentLink?.id} className="container module-spacing">
          <Text element="h2" propertyName="Heading" className="heading-5 mb-8">
            {heading}
          </Text>
          <div className={classnames('flex flex-col max-md:gap-y-6 md:flex-row md:gap-x-6')}>
            {finishProfileItem?.length ? (
              <ContentArea
                propertyName="FinishProfileItem"
                components={finishProfileItem}
                className={classnames('flex-1', {
                  hidden: !showFinishProfile
                })}
              />
            ) : null}

            {findProgramItem?.length ? (
              <ContentArea
                propertyName="FindProgramItem"
                components={findProgramItem}
                className={classnames('flex-1', {
                  hidden: !showFindAProgram
                })}
              />
            ) : null}
          </div>
        </Block>
      ) : null}
    </>
  );
};

export default ProgressiveOnboardingExperienceContainerBlock;
