import withNav from '@/components/hocs/withNav';
import { IContentExtended } from '@/lib/helpers/expandChildren';

type PageLoadingStateProps = {
  headerBlock?: IContentExtended;
};

const PageLoadingStateComponent: React.FC<PageLoadingStateProps> = () => {
  return <div className="flex h-screen flex-col"></div>;
};

const PageLoadingState = withNav(PageLoadingStateComponent);
export default PageLoadingState;
