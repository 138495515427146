import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { PageLinks } from '../slices/pageSlice';
import { transformIfPLIUrl } from '@/utils/helpers';

export const selectPageLinks = (state: RootState) => state.page.pageLinks;

export const selectPageLinkPaths = createSelector(selectPageLinks, pageLinks => {
  const transformedLinks = Object.entries(pageLinks).reduce<PageLinks>((acc, [key, value = '']) => {
    acc[key as keyof PageLinks] = transformIfPLIUrl(value);
    return acc;
  }, {} as PageLinks);
  return transformedLinks;
});

export const selectPage = createSelector(selectPageLinks, pageLinks => ({
  pageLinks
}));
