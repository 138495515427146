import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Fragment, useRef, useState } from 'react';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import InteractiveEditorialItem from '../../blocks/ILC/InteractiveEditorialBlockItem';
import classnames from 'classnames';
import Player from '@vimeo/player';

import type {
  InteractiveEditorialBlock as InteractiveEditorialBlockProps,
  InteractiveEditorialBlockItem as InteractiveEditorialBlockItemProps
} from '@/@types/content';
import Icon from '@/components/ui/Icon';

const InteractiveEditoriaBlock: React.FC<InteractiveEditorialBlockProps> = ({ items, layout }) => {
  const accordionItems = items as InteractiveEditorialBlockItemProps[];
  const videoItems = accordionItems.map(item => item.video);

  const conditionalProps = isEditOrPreviewMode() ? { 'data-epi-edit': 'Items' } : {};

  const constructVimeoUrl = (id: string) =>
    `https://player.vimeo.com/video/${id}?title=false&autoplay=1&loop=1&background=1`;

  const [playStatus, setPlayStatus] = useState<boolean[]>(
    new Array(videoItems.length).fill(false).map((_value, index) => index === 0)
  );
  const [openIndex, setOpenIndex] = useState<number | null>(0);
  const videoRefs = useRef<HTMLIFrameElement[]>([]);
  const isLargeScreen = useMediaQuery(ScreenSizeQueries.lg);
  const smallScreen = useMediaQuery(ScreenSizeQueries.sm);

  let layoutSelection;

  const handleCollapsibleClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);

    videoRefs.current.forEach((videoRef, idx) => {
      if (idx !== index && playStatus[idx]) {
        const player = new Player(videoRef);
        player.pause();
        setPlayStatus(prevStatus => {
          const newStatus = [...prevStatus];
          newStatus[idx] = false;
          return newStatus;
        });
      }
    });

    if (openIndex === null || openIndex !== index) {
      const videoRef = videoRefs.current[index];
      if (videoRef && !playStatus[index]) {
        const player = new Player(videoRef);
        player.play();
        setPlayStatus(prevStatus => {
          const newStatus = [...prevStatus];
          newStatus[index] = true;
          return newStatus;
        });
      }
    }
  };

  const handlePlayPause = (index: number) => {
    const videoRef = videoRefs.current[index];
    if (videoRef) {
      const player = new Player(videoRef);
      if (playStatus[index]) {
        player.pause();
      } else {
        player.play();
      }
      setPlayStatus(prevStatus => {
        const newStatus = [...prevStatus];
        newStatus[index] = !prevStatus[index];
        return newStatus;
      });
    }
  };

  const interactiveEditorialClasses = classnames(
    'container flex flex-col items-center justify-between px-6 py-10 lg:flex-row lg:gap-[133px] lg:px-20 lg:py-16'
  );

  const videoBlockClassess = classnames(
    'block aspect-square size-full rounded-lg bg-black lg:h-[628px]'
  );

  const videoControlsClasses = classnames(
    'absolute bottom-6 left-6 flex items-center justify-center overflow-hidden rounded-full p-3 before:absolute before:left-0 before:top-0 before:size-full before:bg-white before:opacity-20 before:backdrop-blur-md hover:before:opacity-30 sm:p-6'
  );

  const videoItemsMap = videoItems.map((item, index) => (
    <Fragment key={index}>
      <div
        className={classnames(videoBlockClassess, {
          hidden: openIndex !== index
        })}
      >
        <iframe
          className="size-full rounded-lg object-fill"
          src={constructVimeoUrl(item!)}
          ref={videoRef => {
            videoRefs.current[index] = videoRef as HTMLIFrameElement;
          }}
          allow="autoplay"
        />
      </div>
      <button
        onClick={() => handlePlayPause(index)}
        className={classnames(videoControlsClasses, {
          hidden: openIndex !== index
        })}
      >
        <Icon
          name={playStatus[index] ? 'pause' : 'play'}
          size={smallScreen ? 'large' : 'medium'}
          className="text-white"
        />
      </button>
    </Fragment>
  ));

  const collapsibleBlock = items!.map((item, index) => (
    <InteractiveEditorialItem
      key={item.contentLink?.guidValue}
      {...item}
      isOpen={index === openIndex}
      onOpenChange={() => handleCollapsibleClick(index)}
      isLastItem={index === items!.length - 1}
    />
  ));

  if (!isLargeScreen) {
    layoutSelection = (
      <>
        <div className="relative w-full">{videoItemsMap}</div>
        <div className="relative mt-12 w-full" {...conditionalProps}>
          {collapsibleBlock}
        </div>
      </>
    );
  } else {
    if (layout === 'video-left') {
      layoutSelection = (
        <>
          <div className="relative w-full lg:max-w-[628px]">{videoItemsMap}</div>
          <div className="relative w-full" {...conditionalProps}>
            {collapsibleBlock}
          </div>
        </>
      );
    } else if (layout === 'video-right') {
      layoutSelection = (
        <>
          <div className="relative w-full" {...conditionalProps}>
            {collapsibleBlock}
          </div>
          <div className="relative w-full lg:max-w-[628px]">{videoItemsMap}</div>
        </>
      );
    }
  }

  return <section className={interactiveEditorialClasses}  data-component={"InteractiveEditoriaBlock"}>{layoutSelection}</section>;
};

export default InteractiveEditoriaBlock;
