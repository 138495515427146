import * as Accordion from '@radix-ui/react-accordion';
import { FooterLinkListBlock as FooterLinkListBlockProps, TextLinkBlock } from '@/@types/content';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Text from '@/components/cms/Text';
import Divider from '@/components/ui/Divider';
import Icon from '@/components/ui/Icon';
import { Link } from 'react-router-dom';
import { trackLinkEvent, trackViewFooterLinkEvent } from '@/analytics/link';

const FooterLinkListBlock: React.FC<FooterLinkListBlockProps> = props => {
  const isDesktop = useMediaQuery(ScreenSizeQueries.md);

  return isDesktop ? <FooterLinksDesktop {...props} /> : <FooterLinksMobile {...props} />;
};

const FooterLinksMobile: React.FC<FooterLinkListBlockProps> = ({ heading, links }) => {
  return (
    <Accordion.Item value={`${heading}`} className="flex-1 shrink-0">
      <Divider color="darker" />
      <Accordion.Trigger className="text-2 flex w-full items-center justify-between py-6 font-bold">
        <Text element="h3" propertyName="Heading">
          {heading}
        </Text>
        <Icon name="chevron-down" size="large" />
      </Accordion.Trigger>
      <Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordionSlideUp data-[state=open]:animate-accordionSlideDown">
        <LinksColumn links={links} />
      </Accordion.Content>
    </Accordion.Item>
  );
};

const FooterLinksDesktop: React.FC<FooterLinkListBlockProps> = ({ heading, links }) => {
  return (
    <li className="flex-1 shrink-0">
      <Text element="h3" propertyName="Heading" className="text-2 mb-6 font-bold">
        {heading}
      </Text>
      <LinksColumn links={links} />
    </li>
  );
};

interface LinksColumnProps {
  links?: TextLinkBlock[];
}

const LinksColumn: React.FC<LinksColumnProps> = ({ links }) => (
  <ul className="text-2 flex flex-col gap-4 font-medium text-silver max-md:pb-6">
    {links?.map(link =>
      link && link.link ? (
        <li key={link.contentLink?.guidValue}>
          <Link
            onClick={() => {
              trackLinkEvent(link);
              trackViewFooterLinkEvent(link.link.text);
            }}
            to={link.link.href}
            title={link.link.title}
            target={link.link.target}
            className="hover:underline"
          >
            {link.link.text}
          </Link>
        </li>
      ) : null
    )}
  </ul>
);

export default FooterLinkListBlock;
