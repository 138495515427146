import { useLazyGetProgramMaterialsQuery } from '@/redux/api/client/library';
import { setMaterialsLoading } from '@/redux/slices/pdpSlice';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useAppDispatch } from '..';
import { LibraryItem } from '@/@types/client-api';
import { isDefined } from '@/lib/helpers/isDefined';

export const useFetchProgramMaterials = (libraryItems?: (LibraryItem | undefined)[]) => {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const [fetchMaterials] = useLazyGetProgramMaterialsQuery();

  useEffect(() => {
    if (!auth.isAuthenticated) return;
    if (!libraryItems?.length) return;

    const purchasedPks = libraryItems
      .filter(i => i?.isPurchased || i?.isParentProgramPurchased)
      .map(i => i?.pk)
      .filter(isDefined);

    const fetchAllMaterials = async () => {
      dispatch(setMaterialsLoading(true));
      await Promise.all(purchasedPks.map(pk => fetchMaterials(pk, true)));
      dispatch(setMaterialsLoading(false));
    };

    fetchAllMaterials();
  }, [auth.isAuthenticated, dispatch, fetchMaterials, libraryItems]);
};
