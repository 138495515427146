import { useMemo } from 'react';
import ProgramSchedule from '@/components/ui/PDP/ProgramSchedule';
import { buildProgramScheduleProps } from '@/components/ui/PDP/helpers';
import { Variant } from '@/redux/slices/pdpSlice';

interface IPDPProgramScheduleLayoutBlockProps {
  variant: Variant;
}

const IPDPProgramScheduleLayoutBlock: React.FC<IPDPProgramScheduleLayoutBlockProps> = ({
  variant
}) => {
  const scheduleProps = useMemo(() => buildProgramScheduleProps(variant), [variant]);

  if (!scheduleProps) return null;

  return <ProgramSchedule {...scheduleProps} />;
};

export default IPDPProgramScheduleLayoutBlock;
