import React, { useMemo } from 'react';
import {
  FooterBlock as FooterBlockProps,
  HeaderBlock as HeaderBlockProps,
  ReferencePageSettings,
  SiteSettings
} from '@/@types/content';
import SkipToMain from '../ui/SkipToMain';
import Seo, { SEOProps } from '../cms/Seo';
import FooterBlock from '../blocks/Footer/FooterBlock';
import GlobalBannerBlock from '../blocks/GlobalBanner/GlobalBannerBlock';
import HeaderBlock from '../blocks/Header/HeaderBlock';

type HeaderThemeOption = Array<IContent & { headerThemeSelection?: number }>;

type WithNavProps = {
  headerBlock?: HeaderBlockProps;
  footerBlock?: FooterBlockProps;
  headerThemeOption?: HeaderThemeOption;
  headerThemeOptionPDP?: HeaderThemeOption;
  headerThemeOptionCollection?: HeaderThemeOption;
  headerThemeOptionTaxonomySubject?: HeaderThemeOption;
  headerThemeOptionTaxonomyIndustry?: HeaderThemeOption;
  headerThemeOptionFaculty?: HeaderThemeOption;
  siteLabels?: ReferencePageSettings;
} & SEOProps &
  SiteSettings;
import { WalkthroughProvider } from '../blocks/WalkthroughExperienceModalBlock';
import { IContent } from '@/@types/cms';

function withNav<P extends WithNavProps>(WrappedComponent: React.ComponentType<P>) {
  const WithNav: React.FC<P> = props => {
    let hasLightboxModal = false;
    let walkthroughSteps: Array<IContent> = [];

    if ('mainContentArea' in props) {
      const lightBoxModal =
        Array.isArray(props.mainContentArea) &&
        props.mainContentArea.find(
          content =>
            Array.isArray(content.contentType) && content.contentType.includes('LightboxModal')
        );

      hasLightboxModal = !!lightBoxModal;

      walkthroughSteps =
        hasLightboxModal && lightBoxModal.walkthroughExperienceModal
          ? lightBoxModal.walkthroughExperienceModal[0].walkthroughSteps
          : [];
    }

    const propsPage = {
      ...(hasLightboxModal ? { walkthroughSteps } : {}),
      ...props
    };

    const headerBlockTheme = useMemo(() => {
      let headerThemeProp:
        | 'headerThemeOption'
        | 'headerThemeOptionPDP'
        | 'headerThemeOptionCollection'
        | 'headerThemeOptionTaxonomySubject'
        | 'headerThemeOptionTaxonomyIndustry'
        | 'headerThemeOptionFaculty' = 'headerThemeOption';

      if (
        'pdpLayoutBlock' in props &&
        Array.isArray(props.pdpLayoutBlock) &&
        props.pdpLayoutBlock.find(
          content =>
            Array.isArray(content.contentType) && content.contentType.includes('PDPLayoutBlock')
        )
      ) {
        headerThemeProp = 'headerThemeOptionPDP';
      } else {
        switch (props.contentType?.at(-1)) {
          case 'CollectionProduct': // Collection Pages
            headerThemeProp = 'headerThemeOptionCollection';
            break;
          case 'SubjectNode': // Subject Pages
            headerThemeProp = 'headerThemeOptionTaxonomySubject';
            break;
          case 'IndustryNode': // Industry Pages
            headerThemeProp = 'headerThemeOptionTaxonomyIndustry';
            break;
          case 'FacultyMember': // Faculty Pages
            headerThemeProp = 'headerThemeOptionFaculty';
            break;
          default: // Generic Pages
            headerThemeProp = 'headerThemeOption';
            break;
        }
      }

      return headerThemeProp in props && props[headerThemeProp]?.length
        ? props[headerThemeProp]?.at(-1)?.headerThemeSelection
        : (props.headerBlock?.themeVariationOption as HeaderThemeOption)?.at(-1)
            ?.headerThemeSelection;
    }, [props]);

    const globalBanner = props?.siteLabels?.globalBanner?.[0];
    const seoProps = {
      ...props,
      ...(props.siteLabels && { openGraphSiteName: props.siteLabels.openGraphSiteName }),
      ...(props.siteLabels && { twitterSite: props.siteLabels.twitterSite })
    };

    const content = (
      <>
        <GlobalBannerBlock
          {...globalBanner}
          logoRed={props.headerBlock?.logoRed}
          logoWhite={props.headerBlock?.logoWhite}
        />
        <Seo {...seoProps} />
        {props?.siteLabels?.skipLinkLabel && (
          <SkipToMain href="#main" label={props.siteLabels.skipLinkLabel} />
        )}
        <HeaderBlock
          {...props.headerBlock}
          themeVariation={headerBlockTheme}
          tooltipsData={{ walkthroughSteps: walkthroughSteps?.length, ...walkthroughSteps[0] }}
        />
        <main id="main">
          <WrappedComponent {...propsPage} />
        </main>
        <FooterBlock {...props.footerBlock} />
      </>
    );

    return hasLightboxModal ? (
      <WalkthroughProvider totalTooltips={walkthroughSteps?.length}>{content}</WalkthroughProvider>
    ) : (
      content
    );
  };

  return WithNav;
}

export default withNav;
