import { useMemo } from 'react';
import ComplexTooltip, { ComplexTooltipAlign } from '@/components/ui/Tooltip/ComplexTooltip';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { format } from 'date-fns';

const formatRequestedDate = (creditRequestDate: Date): string => {
  return format(creditRequestDate, 'MM/d/yyyy');
};

const formatRequestedTime = (creditRequestDate: Date): string => {
  const timezoneMarker =
    new Intl.DateTimeFormat('en-US', {
      timeZoneName: 'short'
    })
      .formatToParts(creditRequestDate)
      .find(part => part.type === 'timeZoneName')?.value || '';

  return `${format(creditRequestDate, 'h:mm aa')} ${timezoneMarker}`;
};

const replaceCreditRequestDate = (message: string, creditRequestDate: Date) => {
  return message
    .replace('{date}', formatRequestedDate(creditRequestDate))
    .replace('{time}', formatRequestedTime(creditRequestDate));
};

export interface CreditRequestedTooltipProps extends ComponentWithChildren {
  creditRequestDate: Date;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  align?: ComplexTooltipAlign;
}

const CreditRequestedTooltip = ({
  children,
  creditRequestDate,
  isOpen,
  onOpenChange,
  align
}: CreditRequestedTooltipProps): JSX.Element => {
  const { creditRequestedTooltipTitle, creditRequestedTooltipText } = useSelector(
    (state: RootState) => state.page.siteLabels
  );

  const message = useMemo(() => {
    if (!creditRequestedTooltipText) {
      return '';
    }
    const doc = new DOMParser().parseFromString(creditRequestedTooltipText, 'text/html');
    return replaceCreditRequestDate(doc.documentElement.textContent ?? '', creditRequestDate);
  }, [creditRequestedTooltipText, creditRequestDate]);

  return (
    <ComplexTooltip isOpen={isOpen} onOpenChange={onOpenChange}>
      <ComplexTooltip.Trigger asChild>{children}</ComplexTooltip.Trigger>
      <ComplexTooltip.Content align={align}>
        <div className="text-1">
          <p className="mb-1 font-bold">{creditRequestedTooltipTitle}</p>
          <span dangerouslySetInnerHTML={{ __html: message }}></span>
        </div>
      </ComplexTooltip.Content>
    </ComplexTooltip>
  );
};

export default CreditRequestedTooltip;
