import { OnDemandInteractiveVariation as OnDemandInteractiveVariationProps } from '@/@types/content';
import withNav from '@/components/hocs/withNav';
import OnDemandWebVariationProgramWrapper from '@/components/ui/PDP/OnDemandWebVariationProgramWrapper';

const OnDemandInteractiveVariationComponent = (variant: OnDemandInteractiveVariationProps) => {
  return <OnDemandWebVariationProgramWrapper variant={variant} />;
};

const OnDemandInteractiveVariation = withNav(OnDemandInteractiveVariationComponent);
export default OnDemandInteractiveVariation;
