import { useOnDemandProgramCatalogRelations } from '@/hooks/PDP/useProgramCatalogRelations';
import OnDemandProgramPDP from './OnDemandProgramPDP';
import { AnalyticsContext } from '@/analytics/constants';
import type { OnDemandWebVariation as OnDemandWebVariationProps } from '@/@types/content';

interface OnDemandWebVariationProgramWrapperProps {
  variant: OnDemandWebVariationProps;
}
const OnDemandWebVariationProgramWrapper = ({
  variant
}: OnDemandWebVariationProgramWrapperProps) => {
  const { relations, isProgramVariantsLoading, isProgramSegmentsLoading } =
    useOnDemandProgramCatalogRelations(variant);

  return (
    <OnDemandProgramPDP
      catalogRelations={relations}
      loadingCatalogRelations={isProgramVariantsLoading}
      context={AnalyticsContext.PDP}
      isProgramSegmentsLoading={isProgramSegmentsLoading}
    />
  );
};

export default OnDemandWebVariationProgramWrapper;
