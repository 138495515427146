import DropdownMenu, { TDropdownMenuItem } from '@/components/ui/DropdownMenu';
import Divider from '@/components/ui/Divider';
import CircleButton from '@/components/ui/Buttons/CircleButton';
import Shimmer from '../Shimmer';
import { ActionItem } from './ActionBar';
import { MediaProviderUrl } from '@/@types/client-api';
import Text from '@/components/cms/Text';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';
import { Fragment, useEffect, useState } from 'react';

export interface PublicationActionBarProps {
  actions: ActionItem[];
  providerUrls: MediaProviderUrl[];
}

const PublicationActionBar = ({ actions, providerUrls }: PublicationActionBarProps) => {
  const maxServiceLinks = 2;
  const hasMoreServiceLinks = providerUrls?.length > maxServiceLinks;
  const [dropdownItems, setDropdownItems] = useState<TDropdownMenuItem[]>();

  useEffect(() => {
    if (providerUrls && providerUrls.length > 0) {
      setDropdownItems(
        providerUrls.map(link => ({
          label: link.provider,
          onClick: () => handleOpenInNewTab(link.url as string)
        }))
      );
    }
  }, [providerUrls]);

  const handleOpenInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const ServiceCtas = ({ showLatestDivider = false }) => (
    <div className={classnames('flex items-center justify-start gap-4')}>
      {providerUrls?.slice(0, maxServiceLinks)?.map((link, index) => {
        const numberOfItems = Math.min(providerUrls.length, maxServiceLinks);
        let showDivider = showLatestDivider && index === numberOfItems - 1;
        if (index < numberOfItems - 1) {
          showDivider = true;
        }

        return (
          <Fragment key={index}>
            <button
              onClick={() => handleOpenInNewTab(link.url as string)}
              className={classnames('flex items-center justify-start gap-2')}
            >
              {link.icon && <Icon name={link.provider ?? ''} size="large" url={link.icon} />}
              <Text element="span" className="text-2 hidden font-bold sm:block">
                {link.provider}
              </Text>
            </button>
            {showDivider && (
              <Divider color="silver" orientation="vertical" className="hidden !h-4 sm:block" />
            )}
          </Fragment>
        );
      })}
    </div>
  );

  return (
    <>
      <div className="gap-2 max-sm:flex">
        <div className="grow">
          <div className="text-1-medium flex flex-row items-center justify-between">
            <div className={classnames('flex items-center justify-start gap-4')}>
              <Text className="text-2-bold sm:hidden">Listen on</Text>
              <ServiceCtas showLatestDivider={hasMoreServiceLinks} />
              {hasMoreServiceLinks && (
                <DropdownMenu
                  button={() => (
                    <button
                      className={classnames(
                        'flex cursor-pointer items-center justify-center gap-1',
                        'hover:text-red'
                      )}
                    >
                      <Icon name="more-ios" size="medium" />
                      <span className="text-2-bold">More</span>
                    </button>
                  )}
                  align="start"
                  items={dropdownItems?.slice(maxServiceLinks)}
                />
              )}
            </div>
            <div className="hidden flex-row gap-8 sm:flex lg:gap-10">
              {actions.map(value => {
                if (value.loading) {
                  return (
                    <div key={value.label} className="flex items-center gap-2">
                      <Shimmer key={value.label} className="size-9 rounded-full" />
                      <Shimmer key={`${value.label} `} className="h-5 w-24" />
                    </div>
                  );
                }

                return (
                  <CircleButton
                    key={value.label}
                    icon={value.icon}
                    label={value.label}
                    onClick={value.onClick}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className="shrink-0 sm:hidden">
          <DropdownMenu
            button={() => <CircleButton aria-label="More options" icon="more-ios" />}
            items={actions}
          />
        </div>
      </div>
    </>
  );
};

export default PublicationActionBar;
