import Block from '@/components/cms/Block';
import Text from '@/components/cms/Text';
import { useGetPressReleaseListQuery } from '@/redux/api/client/usePressReleaseList';
import { Fragment, useEffect, useState } from 'react';
import Shimmer from '@/components/ui/Shimmer';
import * as Collapsible from '@radix-ui/react-collapsible';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';
import Link from '@/components/ui/Link';
import type { PressReleaseListBlock as PressReleaseListBlockProps } from '@/@types/content';

export type UsePressReleaseListBlockResponse = {
  date?: string;
  navigationTitle?: string;
  url?: string;
};

type SortedData = {
  year: string;
  items: UsePressReleaseListBlockResponse[];
};

const PressReleaseListBlock: React.FC<PressReleaseListBlockProps> = ({
  heading,
  contentLink,
  readArticleLabel
}) => {
  const { isLoading, data } = useGetPressReleaseListQuery();

  const [sortedData, setSortedData] = useState<SortedData[]>([]);

  useEffect(() => {
    if (!data || !data.length) return;

    const groupedAndSorted = Object.entries(
      data.reduce(
        (acc, item) => {
          if (item.date) {
            const year = new Date(item.date).getFullYear();
            if (!acc[year]) {
              acc[year] = [];
            }
            acc[year].push(item);
          }
          return acc;
        },
        {} as Record<string, UsePressReleaseListBlockResponse[]>
      )
    )
      .map(([year, items]) => ({
        year,
        items: items.sort((a, b) => {
          if (a.date && b.date) {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          }
          return 0;
        })
      }))
      .sort((a, b) => Number(b.year) - Number(a.year));

    setSortedData(groupedAndSorted);
  }, [data]);

  return (
    <Block contentLinkID={contentLink?.id} className="container module-spacing">
      <div className="flex flex-col gap-8">
        <Text propertyName="Heading" element="h6" className="heading-6-medium">
          {heading}
        </Text>

        <div className="flex flex-col gap-3">
          {isLoading ? (
            <Fragment>
              <Shimmer className="h-64 w-full" />

              {Array.from({ length: 3 }).map((_, index) => (
                <Shimmer key={index} className="h-20 w-full" />
              ))}
            </Fragment>
          ) : (
            <Fragment>
              {sortedData?.map((group, index) => (
                <Collapsible.Root
                  key={index}
                  defaultOpen={index === 0}
                  className={classnames('group rounded-lg bg-gray-lightest p-6', 'lg:px-8')}
                >
                  <Collapsible.Trigger asChild>
                    <button className="flex w-full items-center justify-between">
                      <span className="text-1-medium">{group.year}</span>
                      <div className="flex size-8 items-center justify-center rounded-full border border-black/30 ">
                        <Icon
                          size="medium"
                          name="chevron-up"
                          className={classnames('group-data-[state=closed]:hidden')}
                        />
                        <Icon
                          size="medium"
                          name="chevron-down"
                          className={classnames('group-data-[state=open]:hidden')}
                        />
                      </div>
                    </button>
                  </Collapsible.Trigger>

                  <Collapsible.Content
                    className={classnames(
                      'overflow-hidden',
                      'data-[state=closed]:animate-collapsibleSlideUp data-[state=open]:animate-collapsibleSlideDown'
                    )}
                  >
                    <div className={classnames('flex flex-col gap-6 pt-4', 'lg:pt-2')}>
                      {group.items.map((item, index) => {
                        const { date, navigationTitle, url } = item;
                        const formattedDate = date
                          ? new Date(date).toLocaleDateString('en-US', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric'
                            })
                          : '';

                        return (
                          <p key={index}>
                            {date && (
                              <span className="text-2 block text-gray-dark">{formattedDate}</span>
                            )}
                            {navigationTitle && (
                              <span className="text-1 block">{navigationTitle}</span>
                            )}
                            {url && (
                              <Link
                                href={url}
                                text={readArticleLabel}
                                className="text-2 inline w-fit text-gray-dark"
                              />
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </Collapsible.Content>
                </Collapsible.Root>
              ))}
            </Fragment>
          )}
        </div>
      </div>
    </Block>
  );
};

export default PressReleaseListBlock;
