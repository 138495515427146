import classnames from 'classnames';

export type LoadingDotsColor = 'black' | 'white';
interface LoadingDotsProps {
  color?: LoadingDotsColor;
  transparent?: boolean;
  dotSize?: 'small' | 'large';
}

const LoadingDots: React.FC<LoadingDotsProps> = ({ color = 'white', dotSize = 'large' }) => {
  const dotsContainerClasses = classnames('flex justify-center');

  const dotClasses = classnames('mx-[2px] animate-bounce rounded-full', {
    'my-[9px] size-[7px]': dotSize === 'large',
    'my-[10px] size-1': dotSize === 'small',
    'bg-white': color === 'white',
    'bg-gray-darker': color === 'black'
  });

  const firstDotClasses = classnames(dotClasses, {
    'bg-dot': color === 'white'
  });
  const secondDotClasses = classnames(dotClasses, {
    'bg-dot-dark': color === 'white'
  });
  const thirdDotClasses = classnames(dotClasses, {
    'bg-dot-darker': color === 'white'
  });

  return (
    <span role="presentation" className={dotsContainerClasses}>
      <span className={firstDotClasses}></span>
      <span style={{ animationDelay: '200ms' }} className={secondDotClasses}></span>
      <span style={{ animationDelay: '400ms' }} className={thirdDotClasses}></span>
    </span>
  );
};

export default LoadingDots;
