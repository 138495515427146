import classnames from 'classnames';
import AvatarComponent from '../Avatar';
import { Link } from 'react-router-dom';

export interface AuthorProps {
  name: string;
  image?: string;
  url: string;
}

export interface AuthorsListProps {
  authors: AuthorProps[];
  className?: string;
}

const Author: React.FC<AuthorProps> = ({ name, image, url }) => (
  <Link to={url} className="inline-flex items-center gap-2">
    <AvatarComponent
      fullName={name}
      imageUrl={image}
      className="size-5 max-md:hidden"
      imageSizes={{ xs: '20px' }}
    />
    <p className="text-1 font-medium">{name}</p>
  </Link>
);

const AuthorsList: React.FC<AuthorsListProps> = ({ authors, className }) => (
  <ul className={classnames(className, 'flex-wrap md:flex md:items-center md:gap-[18px]')}>
    {authors.map((author, index) => (
      <div className="inline flex-wrap md:flex md:items-center md:gap-[18px]" key={index}       data-component={"AuthorsList"}>
        <Author {...author} />
        {index != authors.length - 1 && (
          <span className="md:block md:h-4 md:w-px md:bg-silver">
            <span className="md:hidden">, </span>
          </span>
        )}
      </div>
    ))}
  </ul>
);

export default AuthorsList;
