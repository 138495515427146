import { ItemAnalyticsModel } from '@/@types/client-api';
import { AnalyticsEventName, AnalyticsContext } from './constants';
import { trackEvent } from './utils';
import { ConvertItemAnalyticsModelBase } from './analyticsUtils';

export const trackShareEvent = (context: AnalyticsContext, method: string, itemAnalyticsModel?: ItemAnalyticsModel) => {
  if (method && itemAnalyticsModel) {
    trackEvent({
      eventName: AnalyticsEventName.Share,
      parameters: { 
        context: context,
        method: method, 
        currency: itemAnalyticsModel?.currency || '',
        items: [ConvertItemAnalyticsModelBase(itemAnalyticsModel)]
      },
      skipBaseEventParameters: true
    });
  }
};
