import { IContent } from '@/@types/cms';
import ContentArea from '@/components/cms/ContentArea';

interface IPDPContentLayoutBlockProps {
  mainContentArea: Array<IContent>;
  withinOverview?: boolean;
}

const IPDPContentLayoutBlock: React.FC<IPDPContentLayoutBlockProps> = ({
  mainContentArea,
  withinOverview
}) => (
  <ContentArea
    components={mainContentArea}
    propertyName="mainContentArea"
    componentsProps={{ withinOverview: withinOverview }}
  />
);

export default IPDPContentLayoutBlock;
