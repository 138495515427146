import { SearchableType } from '../searchableTypeToSnippetVariant';
import { IContent } from '@/@types/cms';
import { Variant } from '@/redux/slices/pdpSlice';
import { useGetAllChildren } from '@/hooks/useGetAllChildren';

export type ActionType =
  | 'BuyNow'
  | 'StartWatching'
  | 'Register'
  | 'ViewRegistration'
  | 'ChangeOrCancelRegistration';

export type EcommPanelParams = {
  pk: string;
  url: string;
  title: string;
  searchableType: SearchableType | null;
  guidValue: string | null;
  action: ActionType;
  initialVariantSelector?: (variants: Variant) => boolean;
  creditTrackerCode?: string;
};

type UseEcommPanelDataReturn = {
  data?: IContent[];
  isLoading: boolean;
};

const useEcommPanelData = (params?: EcommPanelParams): UseEcommPanelDataReturn => {
  const { guidValue } = params || {};

  const { children, isLoading: isLoadingChildren } = useGetAllChildren({
    contentLink: guidValue ?? undefined,
    enableWMSPrice: 'all'
  });

  const isLoading = isLoadingChildren;

  return { data: children, isLoading };
};

export default useEcommPanelData;
