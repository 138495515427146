import { useEffect, useState } from 'react';

/**
 * Hook that delays setting the loading state to true.
 *
 * @param loading - The current loading state.
 * @param delay - The delay in milliseconds before setting loading to true.
 * @returns The debounced loading state.
 */

function useDebounceLoading(loading?: boolean, delay = 300): boolean {
  const [debouncedLoading, setDebouncedLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      const handler = setTimeout(() => {
        setDebouncedLoading(true);
      }, delay);

      // Cleanup timeout if loading changes before delay is completed
      return () => clearTimeout(handler);
    } else {
      setDebouncedLoading(false);
    }
  }, [loading, delay]);

  return debouncedLoading;
}

export default useDebounceLoading;
