// PDP label helpers.
// READ BEFORE USING: Ecommerce labels should be accessed using the useCatalogLabels hook, because they may be overriden by content edit.

import { ProgramMaterialLabels } from './Modals/ProgramMaterialsModal';
import { DownloadModalLabels } from './Modals/DownloadModal';
import { IPublicationDetailLabels } from './PublicationDetails';

export const LiveEcomLabels = {
  registrations: 'Your registration',
  variantsSelector: 'Other ways to attend',
  online: 'Attend Online',
  inPerson: 'Attend In-Person',
  groupcast: 'Attend Groupcast',
  registerButton: 'Register',
  purchaseButton: 'Add to cart',
  launchButton: 'Launch now',
  privilegedMembers: 'Privileged Members attend at no cost',
  seciWsDiscountAdvice: 'Privileged Members get 20% off on SECI Workshops',
  programId: 'Program ID',
  waitlist: 'Waitlist',
  waitlistMessage:
    'This format has reached our sitting capacity. If you with to be added to our waitlist, please contact <a href="mailto://">Customer Service</a>.',
  waitlistButton: 'Join Waitlist',
  noSelectionMsg: 'Please select a location to register',
  changeOrCancelLabel: 'Change or cancel registration',
  cancelLabel: 'Cancel Registration',
  materials: 'Access materials',
  addToCalendar: 'Add to your calendar',
  addToCalendarModalHeader: 'Add to calendar',
  registrationSuccessMessage: 'Registered',
  cartSuccessMessage: 'added to cart.',
  cartFailedMessage: 'failed to add to cart',
  changeSuccessMessage: 'Registration updated',
  cancelSuccessMessage: 'Registration canceled',
  watchNow: 'Watch now',
  joinNow: 'Join now',
  ondemandCrosslink: 'Watch {YEAR} On-Demand program'
};

export const OnDemandEcomLabels = {
  watchNow: 'Watch now',
  recorded: 'Recorded',
  launchButton: 'Launch now',
  purchaseButton: 'Add to cart',
  noSelectionMsg: 'Please select a recording',
  privilegedMembers: 'Privileged Members attend at no cost',
  seciWsDiscountAdvice: 'Privileged Members get 20% off on SECI Workshops',
  programId: 'Program ID',
  upcomingLiveLabel: 'Attend Upcoming Live',
  materials: 'Access materials',
  transcript: 'Read Transcript',
  upcomingLiveCrosslink: 'Attend Upcoming Live',
  liveNowCrosslink: 'Watch the {LOCATION} program Live right now'
};

export const ProgramExpiredLabels = {
  programId: 'Program ID',
  headingProgram: 'This program has expired',
  bodyProgram:
    'This program is no longer available for purchase. If you have already purchased it, you may still have access from your Library.',
  exploreButtonLabel: 'Explore more programs',
  myLibraryLabel: 'Go to My Library',
  exploreButtonUrl: '/search'
};

export const PublicationEcomLabels = {
  purchaseButton: 'Add to cart',
  preorderPurchaseButton: 'Pre order',
  readButton: 'Read now on PLI PLUS',
  itemId: 'PLI Item',
  includedInPlus: 'Included in your PLI PLUS subscription',
  purchased: 'Purchased',
  stsAvailable: 'Subscription available on PLI PLUS',
  programAttendeeAccess: 'Access to PLI PLUS for program attendees',
  digitalAccess: 'Digital access',
  printHardcover: 'Print book (hardcover)',
  printSoftcover: 'Print book (softcover)',
  printBinder: 'Print book (Loose-leaf binder)',
  printPreorder: 'Print book (Pre-Order)',
  sts: '1-year ebook subscription',
  stsRenewal: 'Renew 1-year ebook subscription',
  upkeepAvailableTooltipTitle: 'Upkeep service available',
  upkeepAvailableTooltipContent:
    'With the purchase of this book, you have the option to sign up for “Upkeep Service,” whereby you will receive future automatic shipments of updates, new editions and supplements to this edition, as they become available, for a 30-day preview. Updates will be subject to an additional charge if kept beyond the preview period, invoiced at the time of delivery. This service will continue until canceled by you at any time.',
  internationalShippingTooltipTitle: 'Contact us to ship outside the US & Canada',
  internationalShippingTooltipContent:
    '<p>PLI is unable to ship books outside of the United States or Canada. If you have any questions, or want us to research the availability of this book in your region, please write us at <a href="mailto:plipress@pli.edu">plipress@pli.edu</a></p>',
  plusToutRichText:
    '<p>Learn more about <strong style="color: red;">PLI PLUS</strong> member offerings</p>'
};

export const ProgramSegmentLabels = {
  expandAll: 'Expand all',
  collapseAll: 'Collapse all',
  showMore: 'Show details',
  showLess: 'Show less',
  launch: 'Launch now',
  cart: 'Add to cart'
};

export const ProgramScheduleLabels = {
  expand: 'Expand',
  collapse: 'Collapse',
  expandAll: 'Expand all',
  collapseAll: 'Collapse all',
  downloadSchedule: 'Download full schedule'
};

export const FacultyRoleLabels = {
  attorney: 'Program Attorney',
  chairpersons: 'Chairpersons',
  speakers: 'Speakers',
  moderators: 'Moderators',
  instructors: 'Instructors'
};

export const FacultyModuleLabels = {
  showMore: 'See all',
  showLess: 'See less'
};

export const PDPSectionLabels = {
  segments: 'Segments from this program',
  overview: 'Overview',
  programAnnouncements: 'Special Announcements',
  whyYouShouldAttend: 'Why You Should Attend',
  whatYouWillLearn: 'What You Will Learn',
  whoShouldAttend: 'Who Should Attend',
  specialFeatures: 'Special Features',
  sellingPoints: 'Selling Points',
  segmentDescription: 'Segment details',
  faculty: 'Faculty',
  schedule: 'program schedule',
  testimonials: 'What others have said',
  publicationDetails: 'Details'
};

export const MaterialsLabels: ProgramMaterialLabels = {
  readOnPlus: 'Read on PLI PLUS',
  downloadFile: 'Download File',
  courseMaterialsTab: 'Course Materials',
  presentationMaterialsTab: 'Slides / Other Materials'
};

export const ProgramCreditLabels = {
  heading: 'Credit details',
  addLicenseButton: 'Add license',
  viewAllJurisdictionsButton: 'View credit for all jurisdictions',
  viewAllJurisdictionsModalHeader: 'Credit details for all jurisdictions',
  viewAllLicensesModalHeader: 'Credit details for your licenses',
  addLicenseBlockHeading: 'Access your credit details',
  addLicenseBlockSubheading:
    'You must add a license in order to access your credit details and see approval status for this program.',
  addLicenseBlockButtonText: 'Add license',
  showAllLicenses: 'Show all licenses from profile',
  showLessLicenses: 'Show fewer licenses from profile'
};

export const CreditLicenseCardLabels = {
  seeMore: 'See more',
  seeLess: 'See less',
  expirationDate: 'Earn credits through',
  edit: 'Edit',
  remove: 'Remove',
  totalCredits: 'Total Credits',
  credits: 'Credits',
  notForCredit: 'Not for Credit'
};

export const LocationLabels = {
  heading: 'Location Details',
  hotelInformation: 'Hotel information',
  viewMap: 'View map'
};

export const DownloadLabels: DownloadModalLabels = {
  heading: 'Select a download format',
  subheading:
    'Please note that downloaded content may offer different credits based on jurisdiction and format.',
  audioSelector: 'Audio download',
  videoSelector: 'Video download',
  creditForYourLicensesVideo: 'Video credit details for your licenses',
  creditForYourLicensesAudio: 'Audio credit details for your licenses',
  allJurisdictions: 'All jurisdictions',
  launchNow: 'Launch now',
  addToCart: 'Add to cart'
};

export const TopicsAndIndustriesLabels = {
  topics: 'Topics',
  industries: 'Industries'
};

export const PublicationDetailsLabels: IPublicationDetailLabels = {
  publicationDate: 'Publication date',
  lastUpdated: 'Last updated',
  volumes: 'Volumes',
  isbn: 'ISBN',
  pageCount: 'Page count',
  itemNo: 'Item #',
  download: 'Download'
};

export const chbOverviewFallback =
  '<p>This Course Handbook was compiled for the program, {0}.</p><p>PLI’s nationally acclaimed course handbooks represent the definitive thinking of the nation’s finest legal minds on timely topics and are considered the standard reference in the field. This course handbook is prepared specifically for the program and stands alone as a permanent reference.';

export const PublicationTableOfContentLabels = {
  title: 'Contents',
  searchPlaceholder: 'Search this book on PLI PLUS',
  searchBtnLabel: 'Go',
  seeAllLabel: 'See all contents',
  seeLessLabel: 'See less',
  volumeLabel: 'Volume',
  downloadLabel: 'Download detailed contents'
};

export const SupplementToutLabels = {
  message: 'Looking for the latest supplement to this treatise?'
};

export const MemsuiteModalLabels = {
  modalTitle: 'Select an option for this program',
  toChooseAnotherOptionLive:
    'To choose another live option for this program, please close this panel and select your intended live program.',
  toChooseAnotherOptionOd:
    'To choose another recording of this program, please close this panel and select your intended recording.',
  registerFromRosterButton: 'Register from roster',
  requestGroupcastButton: 'Request Groupcast',
  groupcastUnavailableAdvice:
    'Looking to schedule a Groupcast? Groupcasts are not available for In-Person events. Please select an online program to request a Groupcast.',
  online: 'Attend Online',
  inPerson: 'Attend In-Person',
  groupcast: 'Attend Groupcast',
  recorded: 'Recorded'
};

export const MembershipCartText = 'Add to cart • {PRICE} /yr';
