import { addMonths, format } from 'date-fns';
import MonthNavigationButton from './MonthNavigationButton';
import { useCallback } from 'react';
import { isMonthBetweenCalendarRange } from './CalendarRange';
import YearChangeButton from './YearChangeButton';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

const getButtonAccessibilityLabel = (month: Date): string =>
  `Navigate to ${format(month, 'MMMM yyyy')}`;

type CalendarHeaderProps = {
  currentDate: Date;
  onDateChanged?: (newDate: Date) => void;
  minDate?: Date;
  maxDate?: Date;
};

const CalendarHeader = ({
  currentDate,
  onDateChanged,
  minDate,
  maxDate
}: CalendarHeaderProps): JSX.Element => {
  const previousMonth = addMonths(currentDate, -1);
  const nextMonth = addMonths(currentDate, 1);

  const isXsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);

  const navigateToPreviousMonth = useCallback(() => {
    onDateChanged?.(previousMonth);
  }, [previousMonth, onDateChanged]);

  const navigateToNextMonth = useCallback(() => {
    onDateChanged?.(nextMonth);
  }, [nextMonth, onDateChanged]);

  const navigateToYear = useCallback(
    (year: number) => {
      onDateChanged?.(new Date(currentDate.setFullYear(year)));
    },
    [currentDate, onDateChanged]
  );

  return (
    <div className="mb-6 flex items-center justify-between">
      <>
        {onDateChanged && (
          <MonthNavigationButton
            accessibilityLabel={getButtonAccessibilityLabel(previousMonth)}
            iconName="chevron-left"
            onClick={navigateToPreviousMonth}
            disabled={!isMonthBetweenCalendarRange(previousMonth, minDate, maxDate)}
          />
        )}
        {isXsOnly ? (
          <span className="text-1 font-bold">{format(currentDate, 'MMMM yyyy')}</span>
        ) : (
          <YearChangeButton currentDate={currentDate} onYearChange={year => navigateToYear(year)} />
        )}
        {onDateChanged && (
          <MonthNavigationButton
            accessibilityLabel={getButtonAccessibilityLabel(nextMonth)}
            iconName="chevron-right"
            onClick={navigateToNextMonth}
            disabled={!isMonthBetweenCalendarRange(nextMonth, minDate, maxDate)}
          />
        )}
      </>
    </div>
  );
};

export default CalendarHeader;
