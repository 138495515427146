import * as Accordion from '@radix-ui/react-accordion';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import ContentArea from '@/components/cms/ContentArea';
import { IContent } from '@/@types/cms';

interface FooterNavProps {
  footerLinks: IContent[];
  className?: string;
}

const FooterNav: React.FC<FooterNavProps> = ({ footerLinks, className }) => {
  const isDesktop = useMediaQuery(ScreenSizeQueries.md);

  return (
    <nav className={className}  data-component={"FooterNav"}>
      {isDesktop ? (
        <ContentArea
          element="ul"
          propertyName="FooterLinks"
          components={footerLinks}
          className="flex gap-6"
        />
      ) : (
        <MobileNav footerLinks={footerLinks} />
      )}
    </nav>
  );
};

const MobileNav: React.FC<FooterNavProps> = ({ footerLinks }) => (
  <Accordion.Root type="multiple" className="mt-12">
    <ContentArea propertyName="FooterLinks" components={footerLinks} noWrapper />
  </Accordion.Root>
);

export default FooterNav;
