import { ComponentProps, forwardRef } from 'react';
import classnames from 'classnames';
import CmsLink from '../cms/Link';
import CmsButton from '../cms/Button';
import Icon from './Icon';
import type { LinkItem } from '@/@types/cms';

interface BaseLinkProps {
  propertyName?: string;
  as?: 'a' | 'button';
  text?: string;
  title?: string;
  variant?:
    | 'text-link-1'
    | 'text-link-2'
    | 'expand-button'
    | 'bold-link-with-icon'
    | 'link-with-icon'
    | 'link-with-icon-small';
  className?: string;
  icon?: ComponentProps<typeof Icon>['name'];
  iconPosition?: 'left' | 'right';
  inactive?: boolean;
  reloadDocument?: boolean;
}

export type LinkProps = BaseLinkProps &
  (LinkItem | Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>);

const Link = forwardRef<HTMLButtonElement | HTMLAnchorElement, LinkProps>(
  (
    {
      as,
      text,
      variant = 'text-link-2',
      className,
      icon = 'link-out',
      iconPosition = 'right',
      inactive,
      reloadDocument,
      ...props
    },
    ref
  ) => {
    const linkClasses = classnames(
      'text-link group flex items-center',
      {
        'flex-row-reverse': iconPosition === 'left',
        'flex-row': iconPosition === 'right'
      },
      {
        'text-1 underline': variant === 'text-link-1',
        'text-2 underline': variant === 'text-link-2' || variant === 'link-with-icon-small',
        'text-2-fixed-l font-bold': variant === 'expand-button',
        'text-2 font-bold': variant === 'bold-link-with-icon',
        'text-1': variant === 'link-with-icon',
        'pointer-events-none text-gray-medium': inactive
      },
      className
    );

    const iconMarginClasses = classnames({
      'mr-1': iconPosition === 'left',
      'ml-1': iconPosition === 'right',
      'ml-2': variant === 'link-with-icon-small'
    });

    const LinkContent = (
      <>
        <span>{text}</span>
        {(variant === 'link-with-icon' ||
          variant === 'bold-link-with-icon' ||
          variant === 'expand-button' ||
          variant === 'link-with-icon-small') && (
          <>
            <div className={classnames(iconMarginClasses, 'max-lg:hidden')}>
              <Icon
                name={icon}
                size={variant === 'link-with-icon-small' ? 'small' : 'medium'}
                className="transition"
              />
            </div>
            <div className={classnames(iconMarginClasses, 'lg:hidden')}>
              <Icon name={icon} size="small" className="transition" />
            </div>
          </>
        )}
      </>
    );

    if (as === 'button') {
      return (
        <CmsButton className={linkClasses} ref={ref as React.Ref<HTMLButtonElement>} {...props}>
          {LinkContent}
        </CmsButton>
      );
    }

    return (
      <CmsLink
        ref={ref as React.Ref<HTMLAnchorElement>}
        {...props}
        className={linkClasses}
        reloadDocument={reloadDocument}
      >
        {LinkContent}
      </CmsLink>
    );
  }
);

export default Link;
