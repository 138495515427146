import { forwardRef as ReactForwardRef } from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as Form from '@radix-ui/react-form';
import { v4 as uuidv4 } from 'uuid';
import CheckboxFormField from './Checkbox/CheckboxFormField';

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  asFormField?: boolean;
}

interface CheckboxComponent
  extends React.ForwardRefExoticComponent<CheckboxProps & React.RefAttributes<HTMLButtonElement>> {
  Label: typeof Form.Label;
  Message: typeof Form.Message;
}

const Checkbox = ReactForwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ children, asFormField = true, ...props }, ref) => {
    const id = props.id || `checkbox-${uuidv4()}`;
    const name = props.name || id;

    if (asFormField) {
      return (
        <CheckboxFormField name={name} id={id} ref={ref} {...props}>
          {children}
        </CheckboxFormField>
      );
    } else {
      return (
        <Form.Root className="leading-none">
          <CheckboxFormField name={name} id={id} ref={ref} {...props}>
            {children}
          </CheckboxFormField>
        </Form.Root>
      );
    }
  }
) as CheckboxComponent;

const CheckboxLabel = Form.Label;
const CheckboxMessage = Form.Message;

Checkbox.Label = CheckboxLabel;
Checkbox.Message = CheckboxMessage;

export default Checkbox;
