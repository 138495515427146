import type { CustomJsScript } from '@/@types/content';
import { useEffect } from 'react';

export type CustomJsScriptProps = CustomJsScript;

const CustomJsScript: React.FC<CustomJsScriptProps> = ({ customJs }) => {
  useEffect(() => {
    if (!customJs) return;
    const fx = new Function(customJs);
    fx();
  }, [customJs]);

  return null;
};

export default CustomJsScript;
