import { useCallback, useEffect, useMemo, useState } from 'react';
import EditPanel, { EditPanelProps } from '@/components/ui/Panels/EditPanel';
import TextInput from '@/components/ui/Form/TextInput';
import Checkbox from '@/components/ui/Form/Checkbox';
import {
  ExpirationFormat,
  ICreditCardType,
  TCreditCardFormData,
  cardFromNumber,
  maskString,
  validateCardCVC,
  validateCardNumber
} from '@/components/ui/CreditCardGrid/CreditCard/helpers';
import { invalidCardError, invalidDateError, minMonthError } from '@/utils/validations';
import { parseDate } from '@/utils/helpers';
import { isBeforeMinDate } from '@/components/ui/DatePicker/CalendarRange';
import { startOfMonth } from 'date-fns';
import { InputError } from '../Form/Input';
import useNudgeContext from '@/hooks/shared/useNudge/useNudgeContext';
import { ProfileNudgeSectionNames } from '@/hooks/shared/useNudge/utils';

const isValidExpirationDate = (expiration: string): boolean => {
  const expirationDate = parseDate(expiration, ExpirationFormat);
  return !!expirationDate && !isBeforeMinDate(expirationDate, startOfMonth(new Date()));
};

const expirationDateErrors = [
  invalidDateError(ExpirationFormat),
  minMonthError(new Date(), ExpirationFormat)
];

const cardErrors = [invalidCardError];

export type OnSaveCreditCard = (data: TCreditCardFormData) => Promise<void | (() => void)>;

type CreditCardPanelProps = {
  onSave?: OnSaveCreditCard;
} & Omit<EditPanelProps, 'children' | 'onSave' | 'onDelete' | 'title'>;

const CreditCardPanel: React.FC<CreditCardPanelProps> = ({
  isOpen,
  onOpenChange,
  theme,
  onSave,
  triggerButton
}) => {
  const [name, setName] = useState<string>('');
  const [cardNumber, setCardNumber] = useState<string>('');
  const [expiration, setExpiration] = useState<string>('');
  const [cvv, setCVV] = useState<string>('');
  const [defaultCard, setDefaultCard] = useState<boolean>(false);
  const [cardType, setCardType] = useState<ICreditCardType | undefined>();
  const [isProcessing, setIsProcessing] = useState(false);
  const { openPanel, registerPanel, unregisterPanel } = useNudgeContext();

  const cvvErrors: InputError[] = useMemo(() => {
    if (!cardType) return [];

    return [
      {
        message: 'Please enter a valid CVV.',
        key: cardType.type,
        match: value => !validateCardCVC(value, cardType)
      }
    ];
  }, [cardType]);

  useEffect(() => {
    if (isOpen) {
      setName('');
      setCardNumber('');
      setExpiration('');
      setCVV('');
      setDefaultCard(true);
      registerPanel(ProfileNudgeSectionNames.Payment);
    }
  }, [isOpen, registerPanel]);

  useEffect(() => {
    if (!isOpen && openPanel == ProfileNudgeSectionNames.Payment)
      unregisterPanel(ProfileNudgeSectionNames.Payment);
  }, [isOpen, openPanel, unregisterPanel]);

  const handleOnClose = useCallback(() => {
    onOpenChange?.(false);
  }, [onOpenChange]);

  const handleNumberChange = useCallback((value: string) => {
    const card = cardFromNumber(value);
    const maskedNumber = card?.mask ? maskString(value, card.mask) : value;
    setCardNumber(maskedNumber);
    setCardType(card);
  }, []);

  const handleValidateFields = useCallback(
    (): boolean =>
      !!name &&
      validateCardNumber(cardNumber) &&
      isValidExpirationDate(expiration) &&
      (cardType ? !!validateCardCVC(cvv, cardType) : !!cvv),
    [cardNumber, cvv, expiration, name, cardType]
  );

  const handleOnSave = useCallback(async () => {
    const [expirationMonth, expirationYear] = expiration.split('/');
    setIsProcessing(true);
    const callback = await onSave?.({
      cardHolderName: name,
      cardNumber,
      expirationMonth,
      expirationYear,
      securityCode: cvv,
      isDefault: defaultCard,
      cyberSourceCardType: cardType?.cyberSourceCardType ?? '',
      cardTypeName: cardType?.type ?? ''
    });
    setIsProcessing(false);
    callback?.();
    handleOnClose();
  }, [cardNumber, cvv, defaultCard, expiration, name, cardType, onSave, handleOnClose]);

  const toggleDefaultCard = useCallback(() => setDefaultCard(state => !state), []);

  return (
    <EditPanel
      title="Add a new card"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      theme={theme}
      onValidateFields={handleValidateFields}
      onSave={handleOnSave}
      loading={isProcessing}
      triggerButton={triggerButton}
    >
      <TextInput
        label="Cardholder name"
        value={name}
        onChange={setName}
        acceptNumbers={false}
        acceptSpecialChars={false}
        required
      />
      <TextInput
        label="Card number"
        value={cardNumber}
        onChange={handleNumberChange}
        acceptLetters={false}
        acceptSpecialChars={false}
        maxLength={100}
        errors={cardErrors}
        required
      />
      <TextInput
        label={`Expiration date (${ExpirationFormat.toUpperCase()})`}
        value={expiration}
        onChange={setExpiration}
        acceptLetters={false}
        acceptSpaces={false}
        acceptSpecialChars={false}
        allowedChars="/"
        maxLength={ExpirationFormat.length}
        errors={expirationDateErrors}
        required
      />
      <TextInput
        label="CVV"
        value={cvv}
        onChange={setCVV}
        maxLength={4}
        key={cardType?.type}
        type="password"
        acceptLetters={false}
        acceptSpaces={false}
        acceptSpecialChars={false}
        errors={cvvErrors}
        required
      />
      <Checkbox checked={defaultCard} onCheckedChange={toggleDefaultCard}>
        <Checkbox.Label>Make this card my default method of payment</Checkbox.Label>
      </Checkbox>
    </EditPanel>
  );
};

export default CreditCardPanel;
