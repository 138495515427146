export type CreditCardFooterProps = {
  name: string;
  isExpired: boolean;
  expiration?: string;
};

const CreditCardFooter = ({ name, isExpired, expiration }: CreditCardFooterProps): JSX.Element => {
  return (
    <>
      <div className="text-1 flex">{name}</div>
      <div className="mt-1 flex items-center justify-between">
        {expiration && <span className="text-1 text-gray-dark">{`Expires on ${expiration}`}</span>}
        {isExpired && <span className="label text-gray-dark">Expired</span>}
      </div>
    </>
  );
};

export default CreditCardFooter;
