import Text from '@/components/cms/Text';
import { useSelector } from 'react-redux';
import useInViewport from '@/hooks/useInViewport';
import { RootState } from '@/redux/store';
import { useShareAction } from '@/hooks/PDP/useShareAction';
import { useState } from 'react';
import Breadcrumbs, { BreadcrumbsProps } from '@/components/ui/Breadcrumbs';
import ActionBar from '../PDP/ActionBar';
import ShareModal from '../PDP/Modals/ShareModal';

interface PressReleaseDetailHeaderProps {
  title: string;
  className?: string;
  shareUrl?: string;
}

const PressReleaseDetailHeader: React.FC<PressReleaseDetailHeaderProps & BreadcrumbsProps> = ({
  title,
  className,
  shareUrl,
  breadcrumbs
}) => {
  const stickyHeader = useSelector((state: RootState) => state.page.stickyHeader);
  const [titleRef] = useInViewport<HTMLDivElement>({
    offset: stickyHeader.isSticky ? stickyHeader.headerHeight * -1 : 0
  });
  const [openShareModal, setOpenShareModal] = useState(false);
  const shareAction = useShareAction(setOpenShareModal);

  return (
    <>
      <section className={className}>
        <div className="container flex flex-col gap-6 pt-6 lg:gap-12 lg:pt-12">
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <div className="flex flex-col lg:gap-4">
            <Text element="span" className="text-2-bold">
              Press Release
            </Text>
            <div
              className="flex flex-col items-start gap-3 sm:flex-row sm:items-center sm:gap-[40px] lg:gap-[60px]"
              ref={titleRef}
            >
              <Text
                propertyName="DisplayName"
                element="h1"
                className="heading-2 h-full align-middle"
              >
                {title}
              </Text>
            </div>
          </div>
          <ActionBar actions={[shareAction]} />
        </div>
      </section>
      <ShareModal
        open={openShareModal}
        setOpen={setOpenShareModal}
        variantTitle={title ?? ''}
        variantUrl={shareUrl ?? ''}
      />
    </>
  );
};

export default PressReleaseDetailHeader;
