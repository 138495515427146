import { ReferencePageSettings } from '@/@types/content';
import { addToastElement } from '@/hooks/toastStorage';
import { PayloadAction, createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export enum LogoColor {
  White = 'white',
  Red = 'red'
}

export type PageLinks = {
  Certificates: string | undefined;
  Library: string | undefined;
  Licenses: string | undefined;
  Search: string | undefined;
  PlusMembershipInfo: string | undefined;
  Profile: string | undefined;
};

type Toast = {
  id: string;
  type: 'default' | 'confirmation' | 'alert' | 'credit-confirmation';
  content: React.ReactNode;
  icon?: string;
  isOpen?: boolean;
  toastId?: { id: number };
};

interface pageState {
  logoColor: `${LogoColor}`;
  headerTheme?: number;
  toasts: Array<Toast>;
  dismissedElements: { [key: string]: string };
  stickyHeader: {
    isSticky: boolean;
    headerHeight: number;
  };
  header: {
    isInView: boolean;
    inViewOffset: number;
  };
  pageLinks: PageLinks;
  siteLabels: ReferencePageSettings;
  footerVisible: boolean;
}

const initialState: pageState = {
  logoColor: LogoColor.Red,
  headerTheme: undefined,
  toasts: [],
  dismissedElements: (() => {
    const cookies = Cookies.get();
    const dismissed: { [key: string]: string } = {};
    Object.keys(cookies).forEach(key => {
      dismissed[key] = cookies[key];
    });
    return dismissed;
  })(),
  stickyHeader: {
    isSticky: false,
    headerHeight: 0
  },
  header: {
    isInView: false,
    inViewOffset: 0
  },
  footerVisible: false,
  pageLinks: {
    Certificates: undefined,
    Library: undefined,
    Licenses: undefined,
    Search: undefined,
    PlusMembershipInfo: undefined,
    Profile: undefined
  },
  siteLabels: {}
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setLogoColor: (state, action: PayloadAction<`${LogoColor}`>) => {
      state.logoColor = action.payload;
    },
    setHeaderTheme: (state, action: PayloadAction<number | undefined>) => {
      state.headerTheme = action.payload;
    },
    addToast: (state, action: PayloadAction<Toast>) => {
      state.toasts.push({ isOpen: true, ...action.payload });
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.map(toast =>
        toast.id === action.payload ? { ...toast, isOpen: false } : toast
      );
    },
    dismissElement: (state, action: PayloadAction<string>) => {
      const elementId = action.payload;
      const currentDate = new Date().toISOString();
      Cookies.set(elementId, currentDate, { expires: 365 });
      state.dismissedElements[elementId] = currentDate;
    },
    setStickyHeader: (
      state,
      action: PayloadAction<{ isSticky: boolean; headerHeight: number }>
    ) => {
      state.stickyHeader = action.payload;
    },
    setHeader: (state, action: PayloadAction<{ isInView: boolean; inViewOffset: number }>) => {
      state.header = action.payload;
    },
    setPageLinks: (state, action: PayloadAction<PageLinks>) => {
      state.pageLinks = {
        ...state.pageLinks,
        ...action.payload
      };
    },
    setSiteLabels: (state, action: PayloadAction<ReferencePageSettings>) => {
      state.siteLabels = action.payload;
    },
    setFooterVisible: (state, action: PayloadAction<boolean>) => {
      state.footerVisible = action.payload;
    }
  }
});

export const openToast = createAsyncThunk(
  'page/openToast',
  async (toast: Omit<Toast, 'id'>, { dispatch }) => {
    const toastId = nanoid();
    const contentId = addToastElement(toast.content);
    dispatch(
      pageSlice.actions.addToast({ ...toast, id: toastId, toastId: contentId, content: undefined })
    );
    if (toast.type !== 'alert')
      setTimeout(() => {
        dispatch(pageSlice.actions.removeToast(toastId));
      }, 8000);
  }
);

export const {
  setLogoColor,
  setHeaderTheme,
  addToast,
  removeToast,
  dismissElement,
  setStickyHeader,
  setHeader,
  setPageLinks,
  setSiteLabels,
  setFooterVisible
} = pageSlice.actions;

export default pageSlice.reducer;
