import searchEndpoints, { useGetSearchResultsQuery } from '@/redux/api/client/search';
import { FIRST_PAGE } from '../constants';
import { createSelector } from '@reduxjs/toolkit';
import { translateStoreFilterToComponentProps } from '../utils/translateStoreDataToComponentsProps';
import { IFacetGroup } from '@/@types/client-api';

const GET_ALL_FILTERS_REQUEST = {
  pageSize: 0,
  currentPage: FIRST_PAGE
};

export const selectSearchAllFiltersFromEmptySearch =
  searchEndpoints.endpoints.getSearchResults.select({ requestBody: GET_ALL_FILTERS_REQUEST });

export const selectSearchAllFiltersFromEmptySearchMapped = createSelector(
  selectSearchAllFiltersFromEmptySearch,
  response => {
    const facetGroups = response?.data?.facetResults?.facetGroups;
    if (!facetGroups) return [];

    return facetGroups.map(facetGroup => translateStoreFilterToComponentProps(facetGroup));
  }
);

export const useGetAllFiltersFromEmptySearch = (
  options: Parameters<typeof useGetSearchResultsQuery>[1],
  facetGroups?: IFacetGroup[]
) =>
  useGetSearchResultsQuery(
    {
      requestBody: {
        ...GET_ALL_FILTERS_REQUEST,
        facetGroups: facetGroups ?? undefined
      }
    },
    options
  );
