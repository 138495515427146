import Text from '@/components/cms/Text';
import { useSelector } from 'react-redux';
import useInViewport from '@/hooks/useInViewport';
import { RootState } from '@/redux/store';
import { ItemAnalyticsModel, MediaProviderUrl } from '@/@types/client-api';
import PodcastActionBar from './PodcastActionBar';
import Divider from '../Divider';
import { getProductImageUrl } from './helpers';
import ShareModal from './Modals/ShareModal';
import { useShareAction } from '@/hooks/PDP/useShareAction';
import { useEffect, useState } from 'react';
import { useLibraryAction } from '@/hooks/PDP/useLibraryAction';
import Breadcrumbs, { BreadcrumbsProps } from '@/components/ui/Breadcrumbs';
import Image from '@/components/cms/Image';
import { useLibraryItems } from '@/hooks/PDP/useLibraryItems';
import { AnalyticsContext } from '@/analytics/constants';
import { PDPLibraryItem } from '@/redux/slices/pdpSlice';

interface PodcastHeaderProps {
  title: string;
  providerUrls: MediaProviderUrl[];
  episodeNumber?: string;
  className?: string;
  code?: string;
  shareUrl?: string;
  isSeries?: boolean;
  itemAnalyticsModel?: ItemAnalyticsModel;
  context?: AnalyticsContext;
}

const PodcastHeader: React.FC<PodcastHeaderProps & BreadcrumbsProps> = ({
  title,
  providerUrls,
  episodeNumber,
  className,
  code,
  shareUrl,
  isSeries,
  itemAnalyticsModel,
  context,
  breadcrumbs
}) => {
  const stickyHeader = useSelector((state: RootState) => state.page.stickyHeader);
  const [titleRef] = useInViewport<HTMLDivElement>({
    offset: stickyHeader.isSticky ? stickyHeader.headerHeight * -1 : 0
  });
  const [openShareModal, setOpenShareModal] = useState(false);
  const shareAction = useShareAction(setOpenShareModal);

  const { hydrateLibrary, selectLibraryItemWithFetchingState } = useLibraryItems();
  useEffect(() => {
    hydrateLibrary([code]);
  }, [code, hydrateLibrary]);
  const libraryItem = selectLibraryItemWithFetchingState(code);

  const libraryAction = useLibraryAction(code ?? '', {
    ...libraryItem,
    data: {
      ...libraryItem.data,
      itemAnalyticsModel: {
        ...(libraryItem?.data?.itemAnalyticsModel || itemAnalyticsModel)
      }
    }
  } as PDPLibraryItem);

  return (
    <>
      <section className={className}>
        <div className="container flex flex-col gap-6 pt-6 lg:gap-12 lg:pt-12">
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <div className="flex flex-col lg:gap-4">
            {!!episodeNumber && (
              <Text element="span" className="text-2-bold">
                Episode {episodeNumber}
              </Text>
            )}
            <div
              className="flex flex-col items-start gap-3 sm:flex-row sm:items-center sm:gap-[40px] lg:gap-[60px]"
              ref={titleRef}
            >
              {isSeries && code && (
                <Image
                  className="size-24 rounded-lg sm:size-32 lg:size-64"
                  src={getProductImageUrl(code, true)}
                  responsive={{
                    skipWrapper: true,
                    aspectRatio: 1,
                    imageSizes: {
                      xs: '96px',
                      sm: '128px',
                      lg: '256px'
                    }
                  }}
                />
              )}
              <Text
                propertyName="DisplayName"
                element="h1"
                className="heading-2 h-full align-middle"
              >
                {title}
              </Text>
            </div>
          </div>
          <PodcastActionBar
            providerUrls={providerUrls}
            actions={[...(isSeries ? [] : [libraryAction]), shareAction]}
          />
        </div>
        <Divider className="mt-6 lg:mt-8" color="light" />
      </section>
      <ShareModal
        open={openShareModal}
        setOpen={setOpenShareModal}
        variantTitle={title ?? ''}
        variantUrl={shareUrl ?? ''}
        itemAnalyticsModel={itemAnalyticsModel}
        context={context}
      />
    </>
  );
};

export default PodcastHeader;
