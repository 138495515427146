import classnames from 'classnames';
import * as Separator from '@radix-ui/react-separator';

export interface DividerProps {
  color: 'light' | 'dark' | 'darker' | 'silver';
  length?: number;
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  id?: string;
}

const Divider = ({
  color = 'dark',
  length,
  className,
  orientation = 'horizontal',
  id
}: DividerProps) => {
  const baseClassNames = classnames(
    `${orientation === 'horizontal' ? 'h-px w-full' : 'h-full w-px'}`,
    {
      'bg-gray-darker': color === 'darker',
      'bg-gray-dark': color === 'dark',
      'bg-gray-light': color === 'light',
      'bg-silver': color === 'silver'
    },
    className
  );
  const styleProps = length
    ? orientation === 'horizontal'
      ? { width: length }
      : { height: length }
    : undefined;
  return (
    <Separator.Root
      className={baseClassNames}
      style={styleProps}
      decorative
      orientation={orientation}
      id={id}
    />
  );
};

export default Divider;
