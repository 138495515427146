import React, {
  lazy,
  memo,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import StickyHeader from '@/components/ui/StickyHeader/StickyHeader';
import { ICLEPeriod, ICreditRequirement } from '@/@types/client-api';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import DropdownSelect from '@/components/ui/DropdownSelect';
import Link from '@/components/ui/Link';
import MenuTextTriggerButton from '@/components/ui/Menu/MenuTextTriggerButton';
import useScrollDirection, { ScrollDirection } from '@/hooks/useScrollDirection';
import { getActiveCompliancePeriodValue } from '@/components/ui/CreditTracker/util/TrackerHelpers';
import Shimmer from '@/components/ui/Shimmer';
import Text from '@/components/cms/Text';
import classnames from 'classnames';
import {
  ComplianceMenuMessages,
  CompliancePeriodValue
} from '@/components/ui/CreditTracker/util/constants';
import {
  endDate,
  formatDate,
  formatDateLabel,
  getDateFromString,
  periodDateRangeIsInvalid,
  startDate
} from '@/components/ui/CreditTracker/util/trackerDateHelpers';
import { format } from 'date-fns';
import { CreditTrackerContext } from '@/components/blocks/CreditTracker/CreditTrackerContext';
import { ComplianceFormFields } from '@/components/ui/CreditTracker/Forms/ComplianceForm';
import { trackEditCompliancePeriodEvent } from '@/analytics/creditTracker';
import { AnalyticsContext } from '@/analytics/constants';
const ComplianceModal = lazy(() => import('@/components/ui/CreditTracker/Modals/ComplianceModal'));

interface ICompliancePeriodMenuProps {
  headerHeight?: number;
  isTransitionalOrNewlyAdmittedRegion?: boolean;
  totalRequirement?: ICreditRequirement;
  selectedClePeriod?: ICLEPeriod;
}

const CompliancePeriodMenu: React.FC<ICompliancePeriodMenuProps> = memo(props => {
  const { headerHeight, isTransitionalOrNewlyAdmittedRegion, totalRequirement, selectedClePeriod } =
    props;

  const {
    selectedRegion,
    userComplianceInformation,
    changeCompliancePeriod: onSelect,
    isLoading,
    isMissingCompliancePeriod,
    previousDatesInvalid,
    currentDatesInvalid,
    nextDatesInvalid
  } = useContext(CreditTrackerContext);

  const selected = useMemo(() => getActiveCompliancePeriodValue(selectedRegion), [selectedRegion]);

  const [headerIsSticky, setHeaderIsSticky] = useState(false);
  const [enableAnimation, setEnableAnimation] = useState(false);
  const [secondaryHeaderVisible, setSecondaryHeaderVisible] = useState(false);
  const [showComplianceModal, setShowComplianceModal] = useState(false);
  const isMediumScreen = useMediaQuery(ScreenSizeQueries.md);
  const { scrollDirection, scrollTop } = useScrollDirection();
  const scrollTopGap = isMediumScreen ? 120 : 185;

  const jurisdiction = useMemo(() => selectedRegion?.jurisdiction, [selectedRegion?.jurisdiction]);

  const openComplianceModal = useCallback(() => {
    trackEditCompliancePeriodEvent(AnalyticsContext.CreditTracker);
    setShowComplianceModal(true);
  }, []);

  const cleStateTransitionOptions = useMemo(
    () => jurisdiction?.cleStateTransitionOptions,
    [jurisdiction?.cleStateTransitionOptions]
  );

  const clePeriod = useMemo(
    () => selectedRegion?.jurisdiction?.clePeriod,
    [selectedRegion?.jurisdiction?.clePeriod]
  );

  const activelySelectedPeriod = useMemo(
    () =>
      [clePeriod, clePeriod?.previousPeriod, clePeriod?.nextPeriod]?.find(
        a => !!a?.isActivelySelected
      ),
    [clePeriod]
  );

  const selectedCompliancePeriod = useMemo(
    () => getActiveCompliancePeriodValue(selectedRegion),
    [selectedRegion]
  );

  const checkAdjacentPeriodEligibility = useCallback(
    (str: string) => {
      return (
        cleStateTransitionOptions &&
        cleStateTransitionOptions.some(x => x.heading?.toLocaleLowerCase().indexOf(str) === 0)
      );
    },
    [cleStateTransitionOptions]
  );

  const prevDisabled = useMemo(
    () => !checkAdjacentPeriodEligibility('previous'),
    [checkAdjacentPeriodEligibility]
  );

  const nextDisabled = useMemo(
    () => !checkAdjacentPeriodEligibility('next'),
    [checkAdjacentPeriodEligibility]
  );

  const currentDisabled = useMemo(
    () => isMissingCompliancePeriod && selectedCompliancePeriod === 'current',
    [isMissingCompliancePeriod, selectedCompliancePeriod]
  );

  const disableDropdown = useMemo(
    () => (isTransitionalOrNewlyAdmittedRegion && nextDisabled && prevDisabled) || currentDisabled,
    [currentDisabled, isTransitionalOrNewlyAdmittedRegion, nextDisabled, prevDisabled]
  );

  const compliancePeriodOptions = useMemo(() => {
    const currentPeriodIncomplete =
      selected === 'current'
        ? !!isMissingCompliancePeriod
        : !!clePeriod?.isUserInfoIncomplete || !!currentDatesInvalid;
    const previousPeriodIncomplete =
      !!clePeriod?.previousPeriod?.isUserInfoIncomplete || !!previousDatesInvalid;
    const nextPeriodIncomplete =
      !!clePeriod?.nextPeriod?.isUserInfoIncomplete || !!nextDatesInvalid;
    return [
      {
        eyebrow: 'Previous compliance period',
        shortEyebrow: 'Previous period',
        label: formatDateLabel(
          previousPeriodIncomplete,
          startDate(clePeriod?.previousPeriod),
          endDate(clePeriod?.previousPeriod)
        ),
        disabled: prevDisabled,
        value: 'previous'
      },
      {
        eyebrow: 'Current compliance period',
        shortEyebrow: 'Current period',
        label: formatDateLabel(currentPeriodIncomplete, startDate(clePeriod), endDate(clePeriod)),
        disabled: false,
        value: 'current'
      },
      {
        eyebrow: 'Next compliance period',
        shortEyebrow: 'Next period',
        label: formatDateLabel(
          nextPeriodIncomplete,
          startDate(clePeriod?.nextPeriod),
          endDate(clePeriod?.nextPeriod)
        ),
        disabled: nextDisabled,
        value: 'next'
      }
    ];
  }, [
    selected,
    isMissingCompliancePeriod,
    clePeriod,
    currentDatesInvalid,
    previousDatesInvalid,
    nextDatesInvalid,
    prevDisabled,
    nextDisabled
  ]);

  const selectedValueText = useMemo(
    () => compliancePeriodOptions.find(a => a.value === selected)?.label,
    [compliancePeriodOptions, selected]
  );

  const selectedValueEyebrow = useMemo(
    () => compliancePeriodOptions.find(a => a.value === selected)?.eyebrow,
    [compliancePeriodOptions, selected]
  );

  useEffect(() => {
    const showHeader = scrollDirection === ScrollDirection.Up && scrollTop > scrollTopGap;
    setSecondaryHeaderVisible(showHeader);
  }, [scrollDirection, scrollTop, scrollTopGap]);

  useEffect(() => {
    if (headerIsSticky) {
      setTimeout(() => {
        setEnableAnimation(true);
      }, 500);
    } else {
      setEnableAnimation(false);
    }
  }, [headerIsSticky]);

  useEffect(() => {
    if (isMissingCompliancePeriod) {
      setShowComplianceModal(true);
    }
  }, [isMissingCompliancePeriod, selectedRegion]);

  const stickyHeaderClass = classnames('min-h-[57px] border-b border-gray-light bg-white', {
    'transition-all': enableAnimation
  });

  const pxHeaderHeight = useMemo(() => `${headerHeight}`, [headerHeight]);

  const headerTransformStyle: React.CSSProperties = {
    transform: enableAnimation
      ? `translateY(${secondaryHeaderVisible && headerIsSticky ? pxHeaderHeight : 0}px)`
      : 'none'
  };

  const editLinkClasses = classnames({
    flex: isTransitionalOrNewlyAdmittedRegion,
    'hidden sm:flex': !isTransitionalOrNewlyAdmittedRegion
  });

  const periodStartDate = useMemo(
    () => getDateFromString(startDate(selectedClePeriod)),
    [selectedClePeriod]
  );

  const periodEndDate = useMemo(
    () => getDateFromString(endDate(selectedClePeriod)),
    [selectedClePeriod]
  );

  const periodAdmitDate = useMemo(
    () => getDateFromString(selectedClePeriod?.admitDate),
    [selectedClePeriod]
  );

  const complianceModalFormFields = useMemo((): ComplianceFormFields => {
    if (isMissingCompliancePeriod) {
      if (periodDateRangeIsInvalid(periodStartDate, periodEndDate)) {
        if (selected == 'previous') {
          return {
            endDate: periodEndDate ? format(periodEndDate, 'MM/dd/yyyy') : ''
          };
        }

        if (selected == 'next') {
          return {
            startDate: periodStartDate ? format(periodStartDate, 'MM/dd/yyyy') : ''
          };
        }
      }
      return {
        ruleSetFlag: selectedClePeriod?.ruleSetFlag
      };
    }

    return {
      ...selectedClePeriod,
      complianceGroup: selectedRegion?.complianceGroups?.find(
        x => x.sk === selectedRegion?.complianceGroup_SK
      ),
      birthMonth: (userComplianceInformation?.userBirthDate?.getMonth() ?? 0) + 1,
      birthDay: userComplianceInformation?.userBirthDate?.getDate() ?? 0,
      admitDate: periodAdmitDate ? format(periodAdmitDate, 'MM/dd/yyyy') : undefined,
      startDate: periodStartDate ? format(periodStartDate, 'MM/dd/yyyy') : '',
      endDate: periodEndDate ? format(periodEndDate, 'MM/dd/yyyy') : ''
    };
  }, [
    isMissingCompliancePeriod,
    periodAdmitDate,
    periodEndDate,
    periodStartDate,
    selected,
    selectedClePeriod,
    selectedRegion?.complianceGroup_SK,
    selectedRegion?.complianceGroups,
    userComplianceInformation?.userBirthDate
  ]);

  return (
    <StickyHeader
      headerIsSticky={sticky => setHeaderIsSticky(sticky)}
      className={stickyHeaderClass}
      styles={headerTransformStyle}
    >
      {headerIsSticky ? (
        <div className="container flex w-full justify-between py-4 text-center">
          <div className="inline-flex flex-wrap">
            {isLoading ? (
              <Shimmer className="size-full min-h-6 min-w-96 bg-gray-light md:max-w-md" />
            ) : (
              <>
                <span className="text-1 mr-4 font-bold text-black">
                  {selectedRegion?.creditRegionLongDescription}
                </span>
                <span className="text-1 text-gray-dark">
                  Compliance deadline:{' '}
                  {isMissingCompliancePeriod
                    ? ComplianceMenuMessages.NO_COMPLIANCE_PERIOD_SETUP
                    : formatDate(endDate(activelySelectedPeriod))}
                </span>
              </>
            )}
          </div>
          {isLoading ? (
            <Shimmer className="size-full min-h-6 max-w-40 bg-gray-light" />
          ) : (
            !isMissingCompliancePeriod && (
              <span className="text-1 min-w-12 font-bold text-black">
                {`${totalRequirement ? totalRequirement.counted : ''} ${isMediumScreen ? 'counted' : ''} / ${totalRequirement ? totalRequirement.required : ''} ${isMediumScreen ? 'required' : ''}`}
              </span>
            )
          )}
        </div>
      ) : (
        <div className="flex w-full flex-row flex-wrap items-center justify-between sm:flex-nowrap">
          {isLoading ? (
            <Shimmer className="size-full min-h-[40px] bg-gray-light md:min-h-[22px] md:max-w-md" />
          ) : disableDropdown ? (
            <Text element="span" className="text-2 font-medium text-gray-dark sm:text-1">
              {selectedValueEyebrow}: {selectedValueText}
            </Text>
          ) : (
            <DropdownSelect
              button={({ value, ...props }) => (
                <MenuTextTriggerButton
                  aria-label="Select a compliance period"
                  label={value}
                  isFullWidthAtXS
                  className="*:grow-0 md:min-w-[438px]"
                  iconSize="large"
                  {...props}
                />
              )}
              align="start"
              options={compliancePeriodOptions}
              value={selected}
              onValueChange={value => onSelect && onSelect(value as CompliancePeriodValue)}
              smallClassName="text-2 font-normal sm:text-1 sm:font-bold"
              mobileFooterContent={() => (
                <Link
                  onClick={openComplianceModal}
                  text="Edit compliance period"
                  variant="text-link-1"
                  as="button"
                />
              )}
            />
          )}
          <Suspense fallback={null}>
            <ComplianceModal
              key={`${selectedRegion?.creditRegionShortDescription}-complianceModal`}
              open={showComplianceModal}
              setOpen={() => setShowComplianceModal(false)}
              eyebrowText={selectedRegion?.creditRegionLongDescription ?? ''}
              jurisdiction={selectedRegion?.jurisdiction}
              complianceGroups={selectedRegion?.complianceGroups}
              complianceFormFields={complianceModalFormFields}
              selectedPeriod={selected}
            />
          </Suspense>
          {isLoading ? (
            <Shimmer className="hidden size-full min-h-[22px] max-w-40 bg-gray-light sm:block" />
          ) : (
            <Link
              onClick={openComplianceModal}
              className={editLinkClasses}
              text="Edit compliance period"
              variant="text-link-1"
              as="button"
            />
          )}
        </div>
      )}
    </StickyHeader>
  );
});

CompliancePeriodMenu.displayName = 'CompliancePeriodMenu';
export default CompliancePeriodMenu;
