import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { DateTime } from 'luxon';
import Link from '../../Link';
import ScheduleHeader from './ScheduleHeader';
import ScheduleItem from './ScheduleItem';
import { trackDownloadScheduleEvent } from '@/analytics/pdp';

export interface ProgramScheduleProps {
  labels: {
    timezoneMessage: string;
    expand: string;
    collapse: string;
    expandAll: string;
    collapseAll: string;
    downloadSchedule: string;
  };
  heading: string;
  sessions: SessionProps[];
  variantCode: string;
}

interface SessionProps {
  prgSegmentSk: string;
  date: string;
  timeZoneIdentifier: string;
  title?: string;
  description?: string;
  speaker?: string;
  isExpanded?: boolean;
  canExpand: boolean;
}

const ProgramSchedule: React.FC<ProgramScheduleProps> = ({
  labels,
  heading,
  sessions,
  variantCode
}) => {
  const [allExpanded, setAllExpanded] = useState(false);
  const [sessionState, setSessionsState] = useState(
    sessions.map(session => ({
      ...session,
      isExpanded: false
    }))
  );

  const getGroupedSessions = useCallback(() => {
    return sessionState.reduce((acc: Record<string, SessionProps[]>, segment) => {
      const date = DateTime.fromISO(segment.date).toISODate();
      if (date) {
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(segment);
      }
      for (const key in acc) {
        acc[key].sort((a, b) => {
          return DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis();
        });
      }
      return acc;
    }, {});
  }, [sessionState]);

  const groupedSessions = getGroupedSessions();

  const sortedDates = useMemo(() => {
    return Object.keys(groupedSessions).sort((a, b) => {
      return DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis();
    });
  }, [groupedSessions]);

  const [currentTab, setCurrentTab] = useState(sortedDates[0]);

  useEffect(() => {
    setSessionsState(
      sessions.map(session => ({
        ...session,
        isExpanded: false
      }))
    );
    setCurrentTab('-1');
  }, [sessions]);

  useEffect(() => {
    if (currentTab == '-1') setCurrentTab(sortedDates[0]);
  }, [sortedDates, currentTab]);

  const toggleSession = useCallback((code: string, isExpanded: boolean) => {
    setSessionsState(prevState =>
      prevState.map(session =>
        session.prgSegmentSk === code ? { ...session, isExpanded } : session
      )
    );
  }, []);

  const toggleAllSessions = useCallback(() => {
    setAllExpanded(!allExpanded);
    setSessionsState(prevState =>
      prevState.map(session => ({ ...session, isExpanded: !allExpanded }))
    );
  }, [allExpanded]);

  return (
    <section id="schedule" className="container module-spacing-pdp">
      <Tabs.Root
        defaultValue={currentTab}
        activationMode="automatic"
        value={currentTab}
        onValueChange={value => setCurrentTab(value)}
      >
        <ScheduleHeader heading={heading} dates={sortedDates} />
        <div className="flex items-center justify-between border-b border-gray-light pb-4">
          <p className="text-2 text-gray-dark">{labels.timezoneMessage}</p>
          <Link
            as="button"
            text={allExpanded ? labels.collapseAll : labels.expandAll}
            onClick={toggleAllSessions}
          />
        </div>
        {sortedDates.map(date => {
          const tabId = `tab-trigger-${date}`;
          return (
            <>
              <span id={tabId} className="sr-only">
                {date}
              </span>
              <Tabs.Content value={date} key={date} aria-labelledby={tabId}>
                {groupedSessions[date].map(session => (
                  <ScheduleItem
                    key={session.prgSegmentSk}
                    labels={labels}
                    date={session.date}
                    timeZoneIdentifier={session.timeZoneIdentifier}
                    title={session.title}
                    description={session.description}
                    speaker={session.speaker}
                    expanded={!!session.isExpanded}
                    canExpand={session.canExpand}
                    onToggle={() => toggleSession(session.prgSegmentSk, !session.isExpanded)}
                  />
                ))}
              </Tabs.Content>
            </>
          );
        })}
      </Tabs.Root>
      <div className="mt-8">
        <Link
          as="button"
          text={labels.downloadSchedule}
          variant="bold-link-with-icon"
          icon="download"
          onClick={() => {
            trackDownloadScheduleEvent();
            window.location.href = `${window.env?.CONTENT_DELIVERY_API}/api/client/product/${variantCode}/print-schedule`;
          }}
        />
      </div>
    </section>
  );
};

export default ProgramSchedule;
