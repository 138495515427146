import type { CreditInfoTypePage as CreditInfoTypePageProps } from '@/@types/content';
import { useMemo } from 'react';
import withNav from '@/components/hocs/withNav';
import useCreditInfo from '@/components/blocks/CreditInfo/hooks/useCreditInfoHook';
import CreditInfoHeader from '@/components/blocks/CreditInfo/snippets/CreditInfoHeader';
import { extractTextFromHTML, formatTcode } from '@/utils/helpers';
import { useGetAncestorsQuery } from '@/redux/api/contentDeliveryAPI';
import ShareButton from '@/components/ui/Buttons/ShareButton';
import { Theme } from '@/styles/darkMode';
import {
  HeaderLinksShimmer,
  SearchResultBlockLoadingShimmer
} from '@/components/blocks/CreditInfo/snippets/LoadingShimmers';
import {
  CreditTypeAncestor,
  creditInfoChildPageCDProps,
  creditTypePageCDProps
} from '@/components/blocks/CreditInfo/util/creditInfoConstants';
import { Helmet } from 'react-helmet';

const CreditInfoTypePage: React.FC<CreditInfoTypePageProps> = props => {
  const {
    mainBody,
    hideInBreadcrumbs,
    sourceCode,
    keyCode,
    contentLink,
    creditFormats,
    browserTitle
  } = props;
  const {
    renderMainContentArea,
    renderHeaderLinks,
    getApprovalMessage,
    getItemClassesForSearch,
    getAncestorHeaderLinks,
    getSidePanelMessages,
    getDocumentTitle,
    isChildItemsLoading,
    isChildItemsFetching,
    filteredBreadcrumbs
  } = useCreditInfo({
    ...props,
    childrenProperties: creditInfoChildPageCDProps
  });

  const { data: ancestors, isLoading: ancestorsLoading } = useGetAncestorsQuery(
    {
      contentLink: contentLink?.guidValue ?? '',
      query: {
        select: creditTypePageCDProps
      }
    },
    { skip: !contentLink?.guidValue }
  );

  const isLoading = useMemo(
    () => !!isChildItemsLoading || !!isChildItemsFetching || !!ancestorsLoading,
    [ancestorsLoading, isChildItemsFetching, isChildItemsLoading]
  );

  const { parentSecondaryHeader, panelMessages, approvalMessage, iconLinks, parentPageName } =
    useMemo(() => {
      const parentData = (ancestors as CreditTypeAncestor[])?.[0];

      if (!parentData) {
        return {};
      }

      const panelMessages = getSidePanelMessages(ancestors);
      const approvalMessage = getApprovalMessage(ancestors);
      const iconLinks = getAncestorHeaderLinks(ancestors);
      const parentPageName = parentData.secondaryHeader ?? undefined;

      return {
        regionName: parentData.regionName,
        parentSecondaryHeader: parentData.secondaryHeader,
        panelMessages,
        iconLinks,
        approvalMessage,
        parentPageName
      };
    }, [getSidePanelMessages, getApprovalMessage, ancestors, getAncestorHeaderLinks]);

  const header = useMemo(() => {
    const text = extractTextFromHTML(mainBody ?? '');
    return `${text} for ${parentSecondaryHeader}`;
  }, [mainBody, parentSecondaryHeader]);

  return (
    <>
      <Helmet>
        <title>{browserTitle ?? getDocumentTitle(header)}</title>
        <meta name="title" content={browserTitle ?? getDocumentTitle(header)}></meta>
      </Helmet>
      <div className="container min-h-screen">
        <div className="flex flex-row flex-wrap items-end justify-between md:mb-16">
          <CreditInfoHeader
            isLoading={isLoading}
            header={header}
            breadcrumbs={!hideInBreadcrumbs ? filteredBreadcrumbs : []}
            subHeader={approvalMessage?.message}
            subHeadingIcon={approvalMessage?.icon}
            sidePanelMessages={panelMessages}
            panelHeader={parentPageName ?? parentSecondaryHeader}
          />
          <div className="my-6 flex w-full flex-row items-end justify-start md:my-0 md:w-fit md:justify-end">
            {isLoading ? (
              <HeaderLinksShimmer />
            ) : (
              <>
                <div className="mr-6">{renderHeaderLinks(iconLinks ?? [])}</div>
                <ShareButton
                  className="text-link group text-2 pb-0 pt-2 font-medium [&>button]:!pb-0"
                  shareUrl={contentLink?.url ?? window.location.href}
                  theme={Theme.Light}
                  title={parentSecondaryHeader}
                />
              </>
            )}
          </div>
        </div>
        {isLoading ? (
          <SearchResultBlockLoadingShimmer />
        ) : (
          <div className="[&_.container]:!px-0">
            {renderMainContentArea(
              formatTcode(sourceCode, keyCode),
              true,
              false,
              getItemClassesForSearch(creditFormats)
            )}
          </div>
        )}
      </div>
    </>
  );
};

const CreditInfoTypePageComponent = withNav(CreditInfoTypePage);
CreditInfoTypePageComponent.displayName = 'CreditInfoTypePage';
export default CreditInfoTypePageComponent;
