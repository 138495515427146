import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import useUnfoldedFilterGroup from '../SearchResults/hooks/useUnfoldedFilterGroup';
import { FILTERS } from '../SearchResults/constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddLicenseModal from './AddLicenseModal';
import { selectFindAProgramRequestResponse } from '@/redux/selectors/findAProgramSelectors';
import useJurisdictonsWithSectionProps from '../SearchResults/hooks/useJurisdictonsWithSectionProps';
import {
  groupFilterItemsIntoSections,
  removeTransitionalJurisdictionSuffix
} from '../SearchResults/utils/filters';
import { useDispatch } from 'react-redux';
import {
  clearFindAProgramFilters,
  toggleFindAProgramFilter
} from '@/redux/slices/findAProgramSlice';
import SelectLicenseModal from './SelectLicenseModal';
import AddSingleLicenseToProfileModal from './AddSingleLicenseToProfileModal';
import AddMultipleLicensesToProfileModal from './AddMultipleLicensesToProfileModal';
import useToast from '@/hooks/useToast';
import { useGetLicensesQuery, usePostLicenseMutation } from '@/redux/api/client/userLicenses';
import { UserLicense } from '@/@types/client-api';
import {
  LicenseData,
  findCreditJurisdiction,
  getAddLicenseBody,
  getCreditTypeOptions,
  getEnrichedLicenseData,
  getJurisdictionsOptions,
  getLicenseKey,
  getLicenseNumberSettings
} from './utils/licenses';
import { useGetCreditJurisdictionsQuery } from '@/redux/api/client/cle';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAuth } from 'react-oidc-context';
import { contentDeliveryApi } from '@/redux/api/contentDeliveryAPI';
import { ClientAPI } from '@/redux/api';
import { FilterContext, SearchContext } from '@/analytics/constants';
import { trackAddLicenseEvent } from '@/analytics/licenses';
import { AnalyticsContext } from '@/analytics/constants';

enum Step {
  addLicense = 'addLicense',
  addSingleLicense = 'addSingleLicense',
  selectMultipleLicenses = 'selectMultipleLicenses',
  addMultipleLicenses = 'addMultipleLicenses'
}

type AddLicenseModalFlowProps = ModalProps & {
  fetching?: boolean;
  triggerButton: JSX.Element | null;
  context?: FilterContext;
  searchContext?: SearchContext;
};

const AddLicenseModalFlow: React.FC<AddLicenseModalFlowProps> = ({
  open,
  setOpen,
  fetching,
  triggerButton,
  context,
  searchContext
}) => {
  const [step, setStep] = useState(Step.addLicense);
  const [currentLicenseIndex, setCurrentLicenseIndex] = useState(0);
  const [licensesData, setLicensesData] = useState<UserLicense[]>([]);
  const [licensesAlreadyRequestedToAddToProfile, setLicensesAlreadyRequestedToAddToProfile] =
    useState<string[]>([]);
  const auth = useAuth();

  const isAuthenticated = auth?.isAuthenticated;

  const { data: userProfileLicenses, isLoading: isLoadingUserProfileLicenses } =
    useGetLicensesQuery(open && isAuthenticated ? undefined : skipToken);

  const { data: creditJurisdictions, isLoading: isLoadingCreditJurisdictions } =
    useGetCreditJurisdictionsQuery(open ? undefined : skipToken);

  const hasMoreLicensesToAddToTheProfile = currentLicenseIndex < licensesData.length - 1;

  const { showSuccessMessage, showFailMessage } = useToast();
  const dispatch = useDispatch();
  const [postLicense] = usePostLicenseMutation();

  //This useEffect is necessary to reset the steps
  useEffect(() => {
    if (open) {
      setStep(Step.addLicense);
    }
  }, [open]);

  const currentLicenseData = useMemo(
    () => licensesData[currentLicenseIndex],
    [currentLicenseIndex, licensesData]
  );

  const { filterGroup } = useUnfoldedFilterGroup({
    groupId: FILTERS.JURISDICTIONS,
    dataSelector: selectFindAProgramRequestResponse,
    skip: !open
  });
  const { jurisdictionsWithSectionProps, isLoading } = useJurisdictonsWithSectionProps({
    skip: !open,
    filterGroup
  });

  const creditTypeOptions = useMemo(
    () => getCreditTypeOptions(creditJurisdictions),
    [creditJurisdictions]
  );

  const jurisdictionOptions = useMemo(
    () => getJurisdictionsOptions(creditJurisdictions, currentLicenseData?.creditClass_SK),
    [creditJurisdictions, currentLicenseData]
  );

  const licenseNumberSettings = useMemo(
    () =>
      getLicenseNumberSettings(
        creditJurisdictions,
        currentLicenseData?.creditClass_SK,
        currentLicenseData?.creditRegion_SK
      ),
    [creditJurisdictions, currentLicenseData]
  );

  const licensesAlreadyAddedToProfile = useMemo(() => {
    const result: string[] = [];
    userProfileLicenses?.items?.forEach(item => {
      if (!!item.creditClass_SK && !!item.creditRegion_SK) {
        const creditJurisdiction = findCreditJurisdiction(
          creditJurisdictions,
          item.creditClass_SK,
          item.creditRegion_SK
        );
        if (creditJurisdiction) {
          result.push(getLicenseKey(creditJurisdiction));
        }
      }
    });
    return result;
  }, [creditJurisdictions, userProfileLicenses?.items]);

  const selectedItems = useMemo((): UserLicense[] => {
    const result: UserLicense[] = [];
    const facets = filterGroup?.facets?.filter(
      item => !!item.value && !!item.name && !!item.checked
    );
    facets?.forEach(facet => {
      const facetValue = removeTransitionalJurisdictionSuffix(facet.value ?? '');
      const creditJurisdiction = creditJurisdictions?.find(item => item.id === facetValue);
      if (creditJurisdiction) {
        const isTopLevelSection = !!jurisdictionsWithSectionProps?.find(
          item => item.value === facetValue
        )?.sectionIsTopLevel;
        const license: LicenseData = {
          creditClass_SK: creditJurisdiction.creditClass_SK,
          creditRegion_SK: creditJurisdiction.creditRegion_SK,
          creditClass: facet.group,
          creditRegion: facet.name,
          stateLicenseNumber: '',
          yearAdmitted: '',
          isTopLevelSection
        };
        const licenseKey = getLicenseKey(license);
        if (
          !licensesAlreadyAddedToProfile.includes(licenseKey) &&
          !licensesAlreadyRequestedToAddToProfile.includes(licenseKey)
        ) {
          result.push(license);
        }
      }
    });
    return result.sort((a, b) => (a.creditRegion ?? '').localeCompare(b.creditRegion ?? ''));
  }, [
    creditJurisdictions,
    filterGroup?.facets,
    jurisdictionsWithSectionProps,
    licensesAlreadyAddedToProfile,
    licensesAlreadyRequestedToAddToProfile
  ]);

  const handleAddLicensesToProfile = useCallback((items: UserLicense[], step: Step) => {
    setLicensesData(items);
    setCurrentLicenseIndex(0);
    contentDeliveryApi.util.invalidateTags(['Content']);
    ClientAPI.util.invalidateTags(['CreditTrackerData']);
    setStep(step);
  }, []);

  const handleAddModalOnSave = useCallback(() => {
    if (selectedItems.length === 0 || !isAuthenticated) {
      setOpen(false);
      return;
    }
    if (selectedItems.length === 1) {
      handleAddLicensesToProfile(selectedItems, Step.addSingleLicense);
      return;
    }
    setStep(Step.selectMultipleLicenses);
  }, [handleAddLicensesToProfile, selectedItems, setOpen, isAuthenticated]);

  const handleMultipleLicensesOnSave = useCallback(
    (items: UserLicense[]) => {
      if (items.length > 0) {
        handleAddLicensesToProfile(items, Step.addMultipleLicenses);
      }
    },
    [handleAddLicensesToProfile]
  );

  const navigateToNextLicense = useCallback(() => {
    if (hasMoreLicensesToAddToTheProfile) {
      setCurrentLicenseIndex(state => state + 1);
    }
  }, [hasMoreLicensesToAddToTheProfile]);

  const navigateToPreviousLicense = useCallback(() => {
    if (currentLicenseIndex > 0) {
      setCurrentLicenseIndex(state => state - 1);
    } else {
      setStep(Step.selectMultipleLicenses);
    }
  }, [currentLicenseIndex]);

  const handleClearAllFilters = useCallback(() => {
    dispatch(clearFindAProgramFilters({ groupId: FILTERS.JURISDICTIONS, context, searchContext }));
  }, [context, dispatch, searchContext]);

  const handleFilterValueChange = useCallback(
    (value?: string) => {
      const item = filterGroup?.facets?.find(item => item.value === value);
      if (item?.name && value) {
        dispatch(
          toggleFindAProgramFilter({
            groupTypeId: FILTERS.JURISDICTIONS,
            value,
            name: item.name,
            context,
            searchContext
          })
        );
      }
    },
    [dispatch, filterGroup?.facets, context, searchContext]
  );

  const handleOnBackPress = useCallback(() => {
    setStep(Step.addLicense);
  }, []);

  const handleOnLicenseDataChanged = useCallback(
    (data: UserLicense) => {
      setLicensesData(state => [
        ...state.slice(0, currentLicenseIndex),
        data,
        ...state.slice(currentLicenseIndex + 1)
      ]);
    },
    [currentLicenseIndex]
  );

  const handleAddLicensesRequestedToAddToProfile = useCallback(() => {
    setLicensesAlreadyRequestedToAddToProfile(state => [
      ...state,
      ...selectedItems.map(getLicenseKey)
    ]);
  }, [selectedItems]);

  const handleAddLicensesToProfileRequest = useCallback(async () => {
    handleAddLicensesRequestedToAddToProfile();
    try {
      await Promise.all(
        licensesData.map(data => {
          const creditJurisdiction = findCreditJurisdiction(
            creditJurisdictions,
            data.creditClass_SK,
            data.creditRegion_SK
          );
          const enrichedData = getEnrichedLicenseData(data, creditJurisdiction);
          trackAddLicenseEvent({
            context: AnalyticsContext.SearchOverlay,
            value: creditJurisdiction?.id ?? ''
          });
          return postLicense(getAddLicenseBody(enrichedData)).unwrap();
        })
      );
      showSuccessMessage('Your profile has been updated');
    } catch (error) {
      showFailMessage('Something went wrong');
    }
  }, [
    creditJurisdictions,
    handleAddLicensesRequestedToAddToProfile,
    licensesData,
    postLicense,
    showFailMessage,
    showSuccessMessage
  ]);

  const loading = isLoading || isLoadingUserProfileLicenses || isLoadingCreditJurisdictions;
  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Trigger asChild>{triggerButton}</Modal.Trigger>
      {step === Step.addLicense && (
        <AddLicenseModal
          sections={groupFilterItemsIntoSections(jurisdictionsWithSectionProps)}
          onValueChange={handleFilterValueChange}
          onClearAllFilters={handleClearAllFilters}
          onSave={handleAddModalOnSave}
          isLoading={loading}
          fetching={fetching}
        />
      )}
      {step === Step.addSingleLicense && (
        <AddSingleLicenseToProfileModal
          data={currentLicenseData}
          onDataChange={handleOnLicenseDataChanged}
          creditTypeOptions={creditTypeOptions}
          jurisdictionOptions={jurisdictionOptions}
          licenseNumberSettings={licenseNumberSettings}
          onSave={handleAddLicensesToProfileRequest}
          onBack={handleOnBackPress}
          onDismiss={handleAddLicensesRequestedToAddToProfile}
          onClose={handleAddLicensesRequestedToAddToProfile}
        />
      )}
      {step === Step.selectMultipleLicenses && (
        <SelectLicenseModal
          licenses={selectedItems}
          onSave={handleMultipleLicensesOnSave}
          onBack={handleOnBackPress}
          onDismissButtonPress={handleAddLicensesRequestedToAddToProfile}
          onClose={handleAddLicensesRequestedToAddToProfile}
        />
      )}
      {step === Step.addMultipleLicenses && (
        <AddMultipleLicensesToProfileModal
          currentLicense={currentLicenseIndex + 1}
          totalLicenses={licensesData.length}
          data={currentLicenseData}
          onDataChange={handleOnLicenseDataChanged}
          creditTypeOptions={creditTypeOptions}
          jurisdictionOptions={jurisdictionOptions}
          licenseNumberSettings={licenseNumberSettings}
          onSave={
            hasMoreLicensesToAddToTheProfile
              ? navigateToNextLicense
              : handleAddLicensesToProfileRequest
          }
          onBack={navigateToPreviousLicense}
          onDismiss={handleAddLicensesRequestedToAddToProfile}
          onClose={handleAddLicensesRequestedToAddToProfile}
        />
      )}
    </Modal>
  );
};

export default AddLicenseModalFlow;
