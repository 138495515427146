import { ClientAPI } from '@/redux/api';
import { UserLicense, UserLicenseResponse } from '@/@types/client-api';

const userLicensesEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getLicenses: builder.query<UserLicenseResponse, void>({
      query: () => ({
        url: '/user/licenses',
        method: 'GET'
      })
    }),
    postLicense: builder.mutation<void, UserLicense>({
      query: body => ({
        url: '/user/licenses',
        method: 'POST',
        body
      }),
      invalidatesTags: ['CreditTrackerData', { type: 'CreditTrackerData', id: 'Certificates' }]
    }),
    updateLicense: builder.mutation<void, UserLicense>({
      query: body => ({
        url: '/user/licenses',
        method: 'PUT',
        body
      }),
      invalidatesTags: ['CreditTrackerData', { type: 'CreditTrackerData', id: 'Certificates' }]
    }),
    deleteLicense: builder.mutation<void, Pick<UserLicense, 'sk'>>({
      query: ({ sk }) => ({
        url: `/user/licenses/${sk}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CreditTrackerData', { type: 'CreditTrackerData', id: 'Certificates' }]
    }),
    updateCertificatePreference: builder.mutation<void, number>({
      query: body => ({
        url: '/user/licenses/certificate-preference',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body
      })
    })
  })
});

export const {
  useGetLicensesQuery,
  useLazyGetLicensesQuery,
  usePostLicenseMutation,
  useUpdateLicenseMutation,
  useDeleteLicenseMutation,
  useUpdateCertificatePreferenceMutation
} = userLicensesEndpoints;

export default userLicensesEndpoints;
