import { ReactNode, forwardRef, memo } from 'react';
import classnames from 'classnames';
import Icon from '../Icon';
import IconButton from '../IconButton';
import { ErrorState } from '@/utils/validations';
import InvalidFieldValueMessage from '../Form/InvalidFieldValueMessage';

type DropdownButtonProps = {
  label?: ReactNode;
  placeholder?: string;
  eyebrow?: string;
  state: 'closed' | 'open' | 'open-autocomplete';
  onClick: () => void;
  required?: boolean;
  dropdownError?: ErrorState;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

const ArrowIcon = memo(
  ({
    state,
    isOpen,
    disabled,
    onClick
  }: {
    state: string;
    isOpen: boolean;
    disabled?: boolean;
    onClick: () => void;
  }) => {
    const iconClasses = classnames(
      'shrink-0',
      { '!text-black': !disabled },
      { '!text-silver': disabled }
    );

    if (state === 'open-autocomplete') {
      // When the autocomplete is open, the chevron icon should be clickable

      return (
        <IconButton
          aria-hidden
          className={iconClasses}
          size="large"
          onClick={onClick}
          name={isOpen ? 'chevron-up' : 'chevron-down'}
        />
      );
    }

    return (
      <Icon className={iconClasses} size="large" name={isOpen ? 'chevron-up' : 'chevron-down'} />
    );
  }
);

const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (
    { label, placeholder, state, className, eyebrow, onClick, disabled, required, dropdownError },
    forwardedRef
  ) => {
    const isOpen = state !== 'closed';
    const buttonClassNames = classnames(
      'group flex w-full items-center justify-between gap-4 rounded-lg border border-gray bg-gray-feather px-4 py-3 text-gray-dark',
      {
        'border-silver': disabled,
        'hover:border-gray-darker': !isOpen && !disabled,
        'border-gray-medium sm:rounded-b-none': isOpen,
        'border-2 border-red': dropdownError?.showError ?? false
      },
      className
    );

    const labelClasses = classnames('text-input block text-gray [&>input]:placeholder:text-gray', {
      'text-black': !!label
    });

    const children = (
      <>
        <span className="grow text-left">
          {eyebrow && <span className="text-2-fixed-medium block">{eyebrow}</span>}
          <span className={labelClasses} aria-hidden={!label}>
            {label || placeholder}
          </span>
        </span>
        <ArrowIcon state={state} isOpen={isOpen} disabled={disabled} onClick={onClick} />
      </>
    );

    if (state === 'open-autocomplete') {
      return <label className={buttonClassNames}>{children}</label>;
    }

    return (
      <>
        <button
          className={buttonClassNames}
          onClick={onClick}
          type="button"
          ref={forwardedRef}
          aria-required={required}
          disabled={disabled}
        >
          {children}
        </button>
        {dropdownError?.showError && dropdownError?.message && (
          <InvalidFieldValueMessage
            id={placeholder?.replace(/ /g, '') || 'dropdownError'}
            message={dropdownError.message}
            accessibilityMessage={dropdownError.message}
          />
        )}
      </>
    );
  }
);

export default DropdownButton;
