import { useMemo } from 'react';
import { ActionItem } from '@/components/ui/PDP/ActionBar';

export const useShareAction = (openShareModal: (bool: boolean) => void) => {
  const shareAction: ActionItem = useMemo(() => {
    return {
      label: 'Share',
      icon: 'share',
      onClick: () => openShareModal(true)
    };
  }, [openShareModal]);

  return shareAction;
};
