import {
  useCallback,
  useEffect,
  Suspense,
  useState,
  useContext,
  useMemo,
  lazy,
  useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import { Certificate, CertificateBlobData, CreditType } from '@/@types/client-api';
import {
  deepCheckCertificateEquality,
  findSelectedClePeriod,
  getEmptyCertificate,
  usePrevious
} from '@credit-tracker/util/TrackerHelpers';
import { CertificateActionTypes, FieldErrors, FieldNames } from '@credit-tracker/util/constants';
import { CreditTrackerContext } from '@/components/blocks/CreditTracker/CreditTrackerContext';
import useCertificates from '@/components/ui/Certificates/hooks/useCertificatesHook';
import { selectActiveUserTrackerData } from '@/redux/selectors/creditTrackerSelectors';
import { trackEditEvent } from '@/analytics/certificates';
import { AnalyticsContext } from '@/analytics/constants';
import { invalidateAllTrackerData } from '@/redux/slices/creditTrackerSlice';
import { FormFieldRefs } from '@credit-tracker/Forms/CreditForm';
import { handleValidation } from '@credit-tracker/util/creditFormValidationHelpers';
import { parseDate } from '@/utils/helpers';
const ModalContent = lazy(() => import('@/components/ui/Modal/ModalContent'));
const CreditForm = lazy(() => import('@credit-tracker/Forms/CreditForm'));

export interface CreditModalProps extends ModalProps {
  eyebrowText?: string;
  certificateActionType?: CertificateActionTypes;
  data?: Certificate;
  isCarryover: boolean;
  disclaimer?: string;
  triggerButton?: JSX.Element;
  isCertificatesPage?: boolean;
  scrollToCertificates?: () => void;
  dateCompletedOutOfRangeMessage?: string;
  trackAddAnalyticsEvent?: () => void;
}

const CreditModal = ({
  open,
  setOpen,
  eyebrowText,
  certificateActionType = 'add',
  isCarryover,
  disclaimer,
  data,
  triggerButton,
  scrollToCertificates,
  isCertificatesPage = false,
  dateCompletedOutOfRangeMessage,
  trackAddAnalyticsEvent
}: CreditModalProps): JSX.Element => {
  const context = useMemo(
    () => (isCertificatesPage ? AnalyticsContext.Certificates : AnalyticsContext.CreditTracker),
    [isCertificatesPage]
  );
  const formFieldRefs = useRef<FormFieldRefs>(null);
  const dispatch = useDispatch();
  const { changeCompliancePeriod, selectedClePeriod } = useContext(CreditTrackerContext);
  const selectedRegion = useSelector(selectActiveUserTrackerData);

  const [certificate, setCertificate] = useState<Certificate | undefined>(
    data ? { ...data, carryOver: isCarryover } : getEmptyCertificate(isCarryover)
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>();
  const [fieldsWithErrors, setFieldsWithErrors] = useState<FieldErrors>({});

  const {
    createCertificate,
    updateCertificate,
    deleteCertificate,
    showWarning = false,
    setShowWarning,
    carryoverInformationText,
    fileManagement
  } = useCertificates(false, true);

  useEffect(() => {
    if (certificate?.creditTypes && !isCertificatesPage) {
      //TODO: figure out why creditType_SK isn't propogating to certificate state
      certificate.creditTypes.forEach(creditType => {
        if (!creditType.creditType_SK) {
          creditType.creditType_SK = 0;
        }
      });
    }
  }, [certificate, isCertificatesPage]);

  const prevCert = usePrevious(certificate ?? {});

  useEffect(() => {
    if (isCarryover && certificate && !deepCheckCertificateEquality(prevCert ?? {}, certificate)) {
      setCertificate(certificate);
    }
  }, [certificate, isCarryover, prevCert]);

  useEffect(() => {
    const prevDate = parseDate(prevCert?.dateCompleted ?? '');
    const newDate = parseDate(certificate?.dateCompleted ?? '');
    if (
      certificate &&
      prevCert &&
      !deepCheckCertificateEquality(prevCert, certificate) &&
      !!prevDate &&
      !!newDate
    ) {
      setCertificate(certificate);
    }
  }, [certificate, prevCert]);

  const deleteAttachments = useCallback(
    (certificateBlobs?: CertificateBlobData[]) => {
      if (certificateBlobs?.length) {
        Promise.all(
          certificateBlobs
            ?.filter(a => !!a.blobUri)
            ?.map(a => fileManagement?.deleteFile(a.blobUri!))
        );
      }
    },
    [fileManagement]
  );

  const handleScrollToTopError = useCallback((errors: FieldErrors) => {
    if (formFieldRefs.current && !!errors) {
      switch (true) {
        case !!errors[FieldNames.PROGRAM_NAME]:
          formFieldRefs.current.scrollToProgramNameInput();
          break;
        case !!errors[FieldNames.PROVIDER] || !!errors[FieldNames.DATE_COMPLETED]:
          formFieldRefs.current.scrollToProviderAndDateInput();
          break;
        case !!errors[FieldNames.PROGRAM_FORMAT]:
          formFieldRefs.current.scrollToProgramFormatInput();
          break;
        case !!errors[FieldNames.CREDIT_TYPE_FORMAT] || !!errors[FieldNames.CREDIT_TYPE_EARNED]:
          formFieldRefs.current.scrollToCreditDetailsInput();
          break;
        case !!errors[FieldNames.TRANSITIONAL]:
          formFieldRefs.current.scrollToTransitionalRefInput();
          break;
      }
    }
  }, []);

  const handleClose = useCallback(() => {
    const noChanges = certificate && data && deepCheckCertificateEquality(data, certificate);
    if (noChanges && isCertificatesPage) {
      setOpen(false);
      setFieldsWithErrors({});
      return;
    }

    if (noChanges || showWarning) {
      setOpen(false);

      if (!noChanges && showWarning) {
        deleteAttachments(
          certificate?.certificateBlobs?.filter(
            a => !data?.certificateBlobs?.some(b => b.fileName == a.fileName)
          )
        );
      }

      setCertificate(data || getEmptyCertificate(isCarryover));
      setTimeout(() => setShowWarning && setShowWarning(false), 150);
      setFieldsWithErrors({});
    } else {
      setShowWarning && setShowWarning('edit');
    }
  }, [
    certificate,
    data,
    isCertificatesPage,
    showWarning,
    setOpen,
    setFieldsWithErrors,
    isCarryover,
    deleteAttachments,
    setShowWarning
  ]);

  const handleSetOpen = useCallback(
    (open: boolean) => {
      if (open && triggerButton) {
        setOpen(true);
        return;
      }
      handleClose();
    },
    [handleClose, setOpen, triggerButton]
  );

  const clePeriod = useMemo(
    () => selectedRegion?.jurisdiction?.clePeriod,
    [selectedRegion?.jurisdiction?.clePeriod]
  );

  const startDate = useMemo(
    () => new Date(clePeriod?.startDate ?? clePeriod?.defaultStartDate ?? 0),
    [clePeriod]
  );
  const endDate = useMemo(
    () => new Date(clePeriod?.endDate ?? clePeriod?.defaultEndDate ?? 0),
    [clePeriod]
  );

  const handleCertificateAction = useCallback(
    async (cert: Certificate, action: 'add' | 'update' | 'delete') => {
      if (!createCertificate || !updateCertificate || !deleteCertificate) {
        return;
      }

      if (action === 'add') trackAddAnalyticsEvent?.();

      if (isCarryover && action !== 'delete') {
        cert.carryOver = isCarryover;
        if (!cert.id) {
          await createCertificate(cert);
        } else {
          await updateCertificate(cert);
        }

        const dateCompleted = new Date(cert.dateCompleted ?? 0);
        const period =
          dateCompleted < startDate ? 'previous' : dateCompleted > endDate ? 'next' : 'current';
        !!changeCompliancePeriod && changeCompliancePeriod(period);
      } else if (action === 'add') {
        await createCertificate(cert);
      } else if (action === 'update') {
        await updateCertificate(cert);
      } else {
        await deleteCertificate(cert);
        deleteAttachments(cert.certificateBlobs ?? []);
      }
      setFieldsWithErrors({});
      scrollToCertificates && scrollToCertificates();
      setCertificate(getEmptyCertificate(isCarryover));
      dispatch(invalidateAllTrackerData());
    },
    [
      createCertificate,
      updateCertificate,
      deleteCertificate,
      trackAddAnalyticsEvent,
      isCarryover,
      scrollToCertificates,
      dispatch,
      startDate,
      endDate,
      changeCompliancePeriod,
      deleteAttachments
    ]
  );

  const creditTypeString = useMemo(() => {
    return isCarryover ? 'carryover' : 'external';
  }, [isCarryover]);

  const titleText = useMemo(() => {
    return showWarning
      ? 'Are you sure?'
      : certificateActionType === 'add'
        ? `Add ${creditTypeString} credit`
        : `Edit ${creditTypeString} credit`;
  }, [showWarning, certificateActionType, creditTypeString]);

  const filterIncompleteCreditTypes = useCallback((certificate: Certificate) => {
    // removes creditTypes without creditIssued entered
    let creditTypes: CreditType[] = certificate.creditTypes ?? [];
    if (creditTypes) {
      creditTypes = creditTypes.filter(
        creditType => creditType.creditIssued !== undefined && creditType.creditIssued > 0
      );
    }
    return { ...certificate, creditTypes: creditTypes };
  }, []);

  const getPeriodStartDate = useCallback(() => {
    const period = findSelectedClePeriod(selectedClePeriod);
    return period?.startDate ?? startDate.toISOString();
  }, [selectedClePeriod, startDate]);

  const getPeriodEndDate = useCallback(() => {
    const period = findSelectedClePeriod(selectedClePeriod);
    return period?.endDate ?? endDate.toISOString();
  }, [endDate, selectedClePeriod]);

  const handleFormValidation = useCallback(
    (certificate: Certificate, isCertificatesPage: boolean) => {
      const errors = handleValidation(
        certificate,
        isCarryover,
        selectedRegion?.jurisdiction?.askTransitional,
        getPeriodStartDate(),
        getPeriodEndDate(),
        isCertificatesPage
      );
      const errorsToSet = { ...fieldsWithErrors, ...errors };
      setFieldsWithErrors(errorsToSet);
      if (!Object.keys(errors).length) {
        return true;
      } else {
        handleScrollToTopError(errorsToSet);
        return false;
      }
    },
    [
      fieldsWithErrors,
      getPeriodEndDate,
      getPeriodStartDate,
      handleScrollToTopError,
      isCarryover,
      selectedRegion?.jurisdiction?.askTransitional
    ]
  );

  const handleSubmit = useCallback(
    async (certificate: Certificate) => {
      const updatedCertificate = certificate.creditTypes
        ? filterIncompleteCreditTypes(certificate)
        : certificate;

      if (handleFormValidation(certificate, isCertificatesPage)) {
        certificateActionType === 'add'
          ? await handleCertificateAction(updatedCertificate, 'add')
          : await handleCertificateAction(updatedCertificate, 'update');
        setOpen(false);
      }
    },
    [
      certificateActionType,
      filterIncompleteCreditTypes,
      handleCertificateAction,
      handleFormValidation,
      isCertificatesPage,
      setOpen
    ]
  );

  const handleSaveButton = useCallback(
    async (certificate?: Certificate) => {
      setIsSubmitting(true);
      if (certificate && showWarning === 'delete') {
        await handleCertificateAction(certificate, 'delete');
        setOpen(false);
      } else if (certificate) {
        trackEditEvent(context);
        await handleSubmit(certificate);
      } else {
        handleClose();
      }
      setIsSubmitting(false);
    },
    [handleCertificateAction, handleClose, handleSubmit, setOpen, showWarning, context]
  );

  const dismissButtonLabel = useMemo(() => {
    return showWarning
      ? 'No, go back'
      : certificateActionType === 'edit'
        ? 'Delete this certificate'
        : '';
  }, [certificateActionType, showWarning]);

  const onDismissButtonPress = useMemo(
    () =>
      !showWarning && certificateActionType === 'edit'
        ? () => setShowWarning('delete')
        : () => setTimeout(() => !!setShowWarning && setShowWarning(false), 200),
    [certificateActionType, setShowWarning, showWarning]
  );

  return (
    <Modal open={open} setOpen={handleSetOpen as ModalProps['setOpen']}>
      <Modal.Trigger asChild>{triggerButton}</Modal.Trigger>
      <Suspense fallback={null}>
        <ModalContent
          eyebrow={{
            size: 'large',
            text: showWarning ? '' : eyebrowText
          }}
          title={titleText}
          subtitle={showWarning ? '' : '* Indicates required field'}
          saveButtonLabel={
            showWarning === 'edit' ? 'Yes' : showWarning === 'delete' ? 'Yes, delete' : 'Save'
          }
          isSubmitLoading={isSubmitting}
          onSaveButtonPress={() =>
            handleSaveButton(showWarning === 'edit' || !certificate ? undefined : certificate)
          }
          shouldDismissButtonClose={false}
          dismissButtonLabel={dismissButtonLabel}
          onDismissButtonPress={onDismissButtonPress}
          onBackButtonPress={
            showWarning ? () => setShowWarning && setShowWarning(false) : undefined
          }
          isMultiStep
          containerClassName="pb-24 sm:!overflow-visible sm:pb-2"
        >
          {showWarning ? (
            <div>
              {showWarning === 'edit'
                ? 'You have unsaved changes. If you close this panel, your changes will be lost. Are you sure you want to leave without saving?'
                : 'Deleting this credit will delete it permanently from your profile. You can add this credit back to your PLI account at any time through the credit tracker.'}
            </div>
          ) : (
            !!selectedRegion && (
              <>
                <Suspense fallback={<></>}>
                  <CreditForm
                    certificate={certificate || null}
                    onDataChange={cert => setCertificate(cert)}
                    isCarryover={isCarryover}
                    information={carryoverInformationText?.toString()}
                    disclaimer={disclaimer}
                    errors={fieldsWithErrors}
                    updateErrors={error => setFieldsWithErrors(error)}
                    ref={formFieldRefs}
                    context={context}
                    selectedRegion={selectedRegion}
                    creditRegionShortDescription={selectedRegion.creditRegionShortDescription}
                    customerId={selectedRegion.customerId}
                    selectedPeriod={selectedClePeriod}
                    isCertificatesPage={isCertificatesPage}
                    dateCompletedOutOfRangeMessage={dateCompletedOutOfRangeMessage}
                  />
                </Suspense>
              </>
            )
          )}
        </ModalContent>
      </Suspense>
    </Modal>
  );
};

export default CreditModal;
