import { CreditOverviewBlock as CreditOverviewBlockProps } from '@/@types/content';
import RichText from '@/components/cms/RichText';
import useCreditTracker from '@/components/ui/CreditTracker/hooks/useCreditTrackerHook';
import CreditDonut from '@/components/ui/CreditTracker/snippets/CreditDonut';
import Shimmer from '@/components/ui/Shimmer';
import { useMemo } from 'react';
import classnames from 'classnames';
import { getTextColorClass } from '@/utils/theme';
import { Link as LinkRouter } from 'react-router-dom';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import {
  defaultDonutColors,
  warningDonutColors
} from '@/components/ui/CreditTracker/util/constants';
import {
  checkDeadlineApproaching,
  formatUrl,
  getRegionShortName,
  linkToRegion,
  sortTrackerMiniBlocks
} from '@/components/ui/CreditTracker/util/TrackerHelpers';
import { useAuth } from 'react-oidc-context';
import { trackNavTrackerMiniEvent } from '@/analytics/creditTracker';

export interface CreditOverviewProps extends CreditOverviewBlockProps {
  theme?: 'light' | 'dark';
}

const CreditOverviewBlock = ({
  heading,
  theme,
  trackerPageLink,
  maxNumOfJurisdictions,
  copy: noLicensesCopy,
  noCompliancePeriodCopy
}: CreditOverviewProps) => {
  const auth = useAuth();
  const user = parseInt(auth.user?.profile?.IndividualSK as string);
  const { trackerLoading, selectedUserRegions } = useCreditTracker({
    request: { individualSk: user },
    skipQuery: !auth.isAuthenticated
  });
  const belowMd = useMediaQuery(ScreenSizeQueries.belowMd);
  const copyClasses = classnames('text-2 my-2', 'lg:max-w-[302px]', getTextColorClass(theme));
  const url = useMemo(() => formatUrl(trackerPageLink?.url ?? ''), [trackerPageLink?.url]);

  /** sorted regions */
  const sortedRegions = useMemo(() => {
    if (!!selectedUserRegions && !trackerLoading) {
      return sortTrackerMiniBlocks(selectedUserRegions);
    }
    return [];
  }, [selectedUserRegions, trackerLoading]);

  /** regions only with total requirements are eligible to show */
  const regions = useMemo(
    () =>
      sortedRegions
        ?.filter(
          x =>
            x.jurisdiction?.totalRequirement?.required &&
            x.jurisdiction?.totalRequirement?.required > 0
        )
        ?.slice(0, maxNumOfJurisdictions ?? 2),
    [sortedRegions, maxNumOfJurisdictions]
  );

  /** all regions in profile are ineligible to show */
  const allRegionsIneligible = useMemo(
    () => !!sortedRegions?.every(x => !x.jurisdiction?.totalRequirement?.required),
    [sortedRegions]
  );

  const hasNoLicenses = useMemo(() => !regions?.length, [regions.length]);

  const isSomeRequired = useMemo(
    () =>
      regions.some(
        region =>
          region.jurisdiction?.totalRequirement?.required != null &&
          region.jurisdiction?.totalRequirement?.required > 0 &&
          !region.jurisdiction?.clePeriod?.isUserInfoIncomplete
      ),
    [regions]
  );

  const cards = useMemo(() => {
    const shimmer = (
      <div className="flex w-full flex-row-reverse items-center md:flex-row">
        <CreditDonut
          className="ml-auto md:ml-0 md:mr-6"
          donutSize="sm"
          size={260}
          showCounter={false}
          creditsCounted={0}
          creditsEarned={0}
          creditsRequired={0}
          isLoading={!!trackerLoading}
          donutColors={{
            startColor: '#01C365',
            stopColor: '#026C39',
            circleColor: '#024D29'
          }}
        />
        <div className="flex flex-col content-center justify-center space-y-2">
          <Shimmer className="h-2 w-16" />
          <Shimmer className="h-2 w-24" />
        </div>
      </div>
    );

    if (trackerLoading) {
      return (
        <>
          {shimmer}
          {shimmer}
        </>
      );
    }

    if (hasNoLicenses) {
      return <RichText content={noLicensesCopy} className={copyClasses} />;
    }

    return (
      <>
        {regions.map(
          ({ jurisdiction, creditRegionShortDescription, creditRegionLongDescription }) => {
            const key = `credit-overview-${creditRegionShortDescription}`;
            const linkTitle = `Go to your ${jurisdiction?.displayName} Credit Tracker`;
            const regionShortName = getRegionShortName(
              jurisdiction?.key,
              jurisdiction?.isTransitionalOrNewlyAdmitted
            );

            if (jurisdiction?.clePeriod?.isUserInfoIncomplete) {
              const noComplianceClasses = classnames('group/no-compliance inline-flex flex-col', {
                'mr-[86px] md:ml-[86px] md:mr-0': isSomeRequired
              });
              const copyClassNames = classnames(
                copyClasses,
                'transition-colors group-hover/no-compliance:text-red-brand'
              );
              return (
                <LinkRouter
                  key={key}
                  to={linkToRegion(url.pathname, jurisdiction?.key ?? '')}
                  state={{ region: regionShortName }}
                  className={noComplianceClasses}
                  title={linkTitle}
                >
                  <h2 className="text-2-medium transition-colors group-hover/no-compliance:text-red-brand">
                    {creditRegionLongDescription}
                  </h2>
                  <RichText content={noCompliancePeriodCopy} className={copyClassNames} />
                </LinkRouter>
              );
            }

            const haveRequirements =
              jurisdiction?.totalRequirement?.counted != null &&
              jurisdiction?.totalRequirement?.required != null;
            const required = jurisdiction?.totalRequirement?.required || 0;
            const counted = jurisdiction?.totalRequirement?.counted || 0;
            const isFulfilled = jurisdiction?.totalRequirement?.isFulfilled || false;
            const earned = jurisdiction?.totalRequirement?.earned || 0;
            const creditsNeeded = required ? Math.round((required - counted) * 100) / 100 : counted;
            const creditsNeededText = isFulfilled
              ? 'Completed'
              : `${creditsNeeded} credit${creditsNeeded === 1 ? '' : 's'} ${haveRequirements ? 'needed' : 'earned'}`;
            const itemClasses = classnames('flex w-full items-center md:flex-row', {
              'pl-[88px]': !required && isSomeRequired && !belowMd,
              'flex-row-reverse': required
            });

            const endDate = new Date(
              jurisdiction?.clePeriod?.endDate ?? jurisdiction?.clePeriod?.defaultEndDate ?? 0
            );
            const deadlineWarning = checkDeadlineApproaching(endDate) && counted < required;

            const licenseHeaderClasses = classnames(
              'text-2-medium transition-colors group-hover/overview-link:text-red-brand',
              {
                'text-white': theme === 'dark',
                'text-black': theme === 'light'
              }
            );

            const creditsNeededClasses = classnames(
              'text-2 transition-colors group-hover/overview-link:text-red-brand',
              {
                'text-gray-medium': theme === 'dark',
                'text-gray-dark': theme === 'light'
              }
            );

            return (
              <div key={key} className={itemClasses}>
                {!!required && (
                  <CreditDonut
                    className="ml-auto md:ml-0 md:mr-6"
                    donutSize={regions.length > 1 || belowMd ? 'sm' : 'md'}
                    donutBackgroundColor={theme === 'dark' ? '333' : 'F5F5F5'}
                    showCounter={false}
                    creditsCounted={counted || 0}
                    creditsEarned={earned || 0}
                    creditsRequired={required || 0}
                    donutColors={deadlineWarning ? warningDonutColors : defaultDonutColors}
                  />
                )}
                <LinkRouter
                  key={key}
                  to={linkToRegion(url.pathname, jurisdiction?.key ?? '')}
                  state={{ region: regionShortName }}
                  title={linkTitle}
                  className="group/overview-link"
                  onClick={() => trackNavTrackerMiniEvent(regionShortName)}
                >
                  <div className="flex flex-col content-center justify-center">
                    <h2 className={licenseHeaderClasses}>{creditRegionLongDescription}</h2>
                    <p className={creditsNeededClasses}>{creditsNeededText}</p>
                  </div>
                </LinkRouter>
              </div>
            );
          }
        )}
      </>
    );
  }, [
    trackerLoading,
    hasNoLicenses,
    regions,
    noLicensesCopy,
    copyClasses,
    isSomeRequired,
    belowMd,
    theme,
    url.pathname,
    noCompliancePeriodCopy
  ]);

  const linkClasses = classnames(
    'text-2 mt-2 w-full underline transition-colors hover:text-red sm:w-fit lg:mt-auto',
    {
      'text-gray-medium': theme === 'dark',
      'text-gray-dark': theme === 'light'
    }
  );

  const bottomLink = useMemo(() => {
    if (trackerLoading) {
      return <Shimmer className="mt-auto h-4 w-full md:w-2/3" />;
    }

    const region = regions.find(r => r.jurisdiction?.clePeriod?.isUserInfoIncomplete);
    return (
      <LinkRouter
        to={url.pathname}
        state={{ region: region?.creditRegionShortDescription }}
        className={linkClasses}
      >
        See My Credit Tracker
      </LinkRouter>
    );
  }, [linkClasses, regions, trackerLoading, url.pathname]);

  const navColumnClasses = classnames(
    'flex w-full flex-col items-start gap-2',
    'lg:min-h-[280px] lg:max-w-[33.33%] lg:border-r lg:px-6 lg:py-4',
    'lg:first:pl-0',
    'lg:last:border-r-0 lg:last:pr-0',
    {
      'border-r-gray-light': theme === 'light',
      'border-r-gray-darker': theme === 'dark'
    },
    getTextColorClass(theme)
  );

  return (
    (!allRegionsIneligible || !!trackerLoading) && (
      <div id="credit-overview" className={navColumnClasses} data-component={'CreditOverviewBlock'}>
        <div className={classnames('flex items-center md:gap-2.5', 'lg:max-w-[302px]')}>
          <span className="text-2 block font-bold md:py-2">{heading}</span>
        </div>
        <section className="w-full space-y-8 py-4 md:pb-6">{cards}</section>
        {bottomLink}
      </div>
    )
  );
};

export default CreditOverviewBlock;
