import ModalContent, { ModalContentProps } from '@/components/ui/Modal/ModalContent';
import Filter from '@/components/ui/Filter/Filter';
import { CheckItem, FilterItem } from '@/components/ui/Filter/types';
import { Fragment } from 'react';
import Divider from '../Divider';

type ViewAllModalGroup = {
  title?: string;
  items: FilterItem<CheckItem>[];
};

export type ViewAllModalProps = Pick<ModalContentProps, 'title' | 'isMultiStep' | 'isL2Modal'> & {
  subTitle?: string;
  groups: ViewAllModalGroup[];
  allDefaultOpen?: boolean;
} & {
  onSave?: () => void;
  onBack?: () => void;
  onClose?: () => void;
  onValueChange: (value?: string) => void;
  onClearAllFilters: () => void;
  fetching?: boolean;
  hasTransition?: boolean;
};

const ViewAllModal = ({
  isL2Modal,
  title,
  isMultiStep,
  groups,
  allDefaultOpen,
  onSave,
  onBack,
  onClose,
  onValueChange,
  onClearAllFilters,
  fetching,
  hasTransition = true
}: ViewAllModalProps): JSX.Element => {
  return (
    <ModalContent
      isL2Modal={isL2Modal}
      hasTransition={hasTransition}
      title={title}
      saveButtonLabel="Save changes"
      dismissButtonLabel="Clear all filters"
      isMultiStep={isMultiStep}
      shouldDismissButtonClose={false}
      onDismissButtonPress={onClearAllFilters}
      onSaveButtonPress={onSave}
      onBackButtonPress={onBack}
      onCloseButtonPress={onClose}
    >
      <div className="flex flex-col gap-8">
        {groups.map((group, index) => (
          <Fragment key={group.title}>
            {groups.length > 1 && <Divider color="light" />}
            <Filter
              type="check"
              title={groups.length > 1 ? group.title : undefined}
              defaultOpen={index === 0 || allDefaultOpen}
              items={group.items}
              groupTypeId=""
              onValueChange={onValueChange}
              collapsible={groups.length > 1}
              fetching={fetching}
            />
          </Fragment>
        ))}
      </div>
    </ModalContent>
  );
};

export default ViewAllModal;
