import React from 'react';
import classnames from 'classnames';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

export interface ShimmerProps {
  className?: string;
  ratio?: number;
}

const Shimmer: React.FC<ShimmerProps> = ({ className, ratio }) => {
  return (
    <div className={classnames('animate-pulse rounded bg-gray-light', className)}
    data-component={"Shimmer"}
    >
      {ratio ? <AspectRatio.Root ratio={ratio} /> : null}
    </div>
  );
};

export default Shimmer;
