import React, { useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { GlobalBannerBlock as GlobalBannerBlockType } from '@/@types/content';
import { getCookieValues, addValuesToCookie } from '@/utils/cookie-list-values';
import { motion, useCycle } from 'framer-motion';
import { useDimensions } from '@/hooks/useDimensions';
import GlobalBannerHeader from './GlobalBannerHeader';
import GlobalBannerPanel from './GlobalBannerPanel';
import { ContentReference } from '@/@types/cms';

const GLOBAL_BANNER_COOKIES = 'globalBannerCookiesPermanentDismissed';
const GLOBAL_BANNER_SESSION = 'globalBannerSessionDismissed';

type GlobalBannerBlockProps = GlobalBannerBlockType & {
  logoRed?: ContentReference;
  logoWhite?: ContentReference;
};

const GlobalBannerBlock: React.FC<GlobalBannerBlockProps> = ({
  collapsedText,
  content,
  contentLink,
  dismissability,
  logoRed = {},
  logoWhite = {},
  theme
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isOpen, toggleOpen] = useCycle<boolean>(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  const expandable: boolean = useMemo(
    () => Array.isArray(content) && content.length > 0,
    [content]
  );

  useEffect(() => {
    const isGlobalBannerDismissed = getCookieValues(GLOBAL_BANNER_COOKIES).includes(
      String(contentLink?.id)
    );

    const isSessionBannerDismissed = JSON.parse(
      sessionStorage.getItem(GLOBAL_BANNER_SESSION) ?? '[]'
    )?.includes(String(contentLink?.id));

    setIsVisible(!isGlobalBannerDismissed && !isSessionBannerDismissed);
  }, [contentLink?.id]);

  useEffect(() => {
    return () => {
      if (isOpen) {
        document.body.style.overflow = 'auto';
      }
    };
  }, [isOpen]);

  const handleDismiss = () => {
    if (dismissability === 'Single Session') {
      const sessionBannersDismissed = JSON.parse(
        sessionStorage.getItem(GLOBAL_BANNER_SESSION) ?? '[]'
      );
      sessionStorage.setItem(
        GLOBAL_BANNER_SESSION,
        JSON.stringify([String(contentLink?.id), ...sessionBannersDismissed])
      );
    }
    if (dismissability === 'Permanent Dismissal') {
      addValuesToCookie(GLOBAL_BANNER_COOKIES, [String(contentLink?.id)], 0, 365);
    }
    setIsVisible(false);
  };

  const toggleIsOpen = () => {
    if (isOpen) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
    toggleOpen();
  };

  const bannerHeaderWrapperClasses = classnames('flex', {
    hidden: !isVisible,
    'bg-white': theme === 'general',
    'bg-black': theme === 'critical',
    'bg-gradient': theme === 'probono'
  });

  return (
    <section className={bannerHeaderWrapperClasses}  data-component={"GlobalBannerBlock"}>
      <motion.nav
        className="flex-1"
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        custom={height}
        ref={containerRef}
      >
        <GlobalBannerPanel
          content={content}
          isOpen={isOpen}
          logos={{ logoRed, logoWhite }}
          onToggleOpen={toggleIsOpen}
          theme={theme}
        />
        <GlobalBannerHeader
          dismissible={dismissability !== 'Not Dismissable'}
          expandable={expandable}
          onDismiss={handleDismiss}
          onToggleOpen={toggleIsOpen}
          text={collapsedText}
          theme={theme}
        />
      </motion.nav>
    </section>
  );
};

export default GlobalBannerBlock;
