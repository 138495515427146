import { PropsWithChildren, useEffect, useState } from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';
import PageLoadingState from '@/routes/PageLoadingState';
import { IContentExtended } from './helpers/expandChildren';

type PageMapperProps = {
  headerData?: IContentExtended;
};

export const AnimationStage = ({ children, headerData }: PropsWithChildren & PageMapperProps) => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (location?.pathname !== displayLocation?.pathname) {
      setIsVisible(false);
      setDisplayLocation(location);

      setTimeout(() => {
        setIsVisible(true);
      }, 600);
    }
  }, [location, displayLocation]);

  return !isVisible ? (
    <PageLoadingState headerBlock={headerData} />
  ) : (
    <div>
      {children}
      <ScrollRestoration></ScrollRestoration>
    </div>
  );
};

export default AnimationStage;
