import { Theme } from '@/styles/darkMode';
import classnames from 'classnames';

export const getTextColorClass = (theme?: string) =>
  classnames({
    'text-black': theme === Theme.Light,
    'text-white': theme === Theme.Dark
  });

export const getFocusOutlineClass = (theme?: string) =>
  classnames({
    'focus-visible:outline-black': theme === Theme.Light,
    'focus-visible:outline-white': theme === Theme.Dark
  });
