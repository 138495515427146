import PanelButton from '@/components/ui/PDP/ECommBox/components/PanelButton';
import { forwardRef, useCallback } from 'react';
import { UpcomingLiveProps } from '..';
import { RootState } from '@/redux/store';

interface ActionButtonProps {
  labels: UpcomingLiveProps['labels'];
  selectedVariant: RootState['pdp']['selectedVariant'];
  onClick: () => void;
  isSuccess?: boolean;
  isLoading?: boolean;
  isError?: boolean;
}

const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ labels, selectedVariant, isSuccess, isLoading, isError, onClick }, ref) => {
    const getButtonProps = useCallback(() => {
      if (selectedVariant?.waitlist) {
        return { label: labels.waitlistButton };
      }
      return {
        label: selectedVariant?.retail
          ? labels.purchaseButton
          : selectedVariant?.isLive && selectedVariant?.type != 'in-person'
            ? labels.launchButton
            : labels.registerButton,
        price: selectedVariant?.price || undefined,
        strikePrice: selectedVariant?.originalPrice || undefined
      };
    }, [labels, selectedVariant]);

    return selectedVariant ? (
      <PanelButton
        ref={ref}
        onClick={onClick}
        isSuccess={isSuccess}
        isLoading={isLoading}
        isError={isError}
        isLive={
          selectedVariant.isLive && !selectedVariant.retail && selectedVariant?.type != 'in-person'
        }
        purchased={selectedVariant.purchased || selectedVariant.registered}
        {...getButtonProps()}
      />
    ) : null;
  }
);

export default ActionButton;
