import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import classnames from 'classnames';

interface TextProps extends React.HTMLProps<HTMLParagraphElement & HTMLHeadingElement> {
  element?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  propertyName?: string;
}

const Text: React.FC<TextProps> = ({ element, children, propertyName, className, ...props }) => {
  const isEditMode = isEditOrPreviewMode();
  const Element = element || 'p';

  const conditionalProps =
    isEditMode && propertyName ? { ...props, 'data-epi-edit': propertyName } : props;

  if (!isEditMode && !children) return null;

  return (
    <Element {...conditionalProps} className={classnames(className, 'relative')}>
      {children}
    </Element>
  );
};

export default Text;
