import classnames from 'classnames';
import PercentCircle from './PercentCircle';
import { Link } from 'react-router-dom';

type ICreditProgressCardProps = {
  title: string;
  percentCompleted: number;
  segmentLink: string;
  playable?: boolean;
};

const CreditProgressCard = ({
  title,
  percentCompleted,
  segmentLink,
  playable = true
}: ICreditProgressCardProps) => {
  const headingText =
    percentCompleted === 100 ? 'Watched' : percentCompleted ? 'Partially complete' : 'Not started';

  const baseHeadingStyles = classnames('label text-gray-dark', {
    'text-serpentine': percentCompleted === 100
  });

  const titleLink = (
    <Link className="text-link text-1 block font-medium" to={segmentLink}>
      {title}
    </Link>
  );

  return (
    <>
      <div className="flex flex-col items-start gap-2 max-sm:gap-1 sm:flex-row sm:items-center sm:gap-6">
        <div className="flex flex-row items-center gap-2">
          <PercentCircle percentCompleted={percentCompleted} playable={playable} />
          <div className={classnames(baseHeadingStyles, 'sm:hidden')}>{headingText}</div>
        </div>
        <div className="flex flex-col justify-center gap-[2px]">
          <div className={classnames(baseHeadingStyles, 'max-sm:hidden')}>{headingText}</div>
          <div className="line-clamp-2 max-sm:hidden">{titleLink}</div>
        </div>
      </div>
      <div className="mt-1 line-clamp-4 sm:hidden">{titleLink}</div>
    </>
  );
};

export default CreditProgressCard;
