import { LibraryResponse } from '@/@types/client-api';
import { FACET_DELIMITER, FilterTypes } from '@/components/ui/SearchResults/constants';
import {
  FILTER_LIST,
  LIBRARY_FILTERS,
  LIBRARY_FILTER_GROUPS,
  LibraryFilterKey,
  LibraryFilterTypeMap,
  LibraryFilterValue
} from '@/utils/libraryConstants';
import { isItemChecked } from '@/utils/libraryUtils';
import { getCurrentPage } from '@/utils/pagination';
import { PayloadAction, SerializedError, createSlice } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { LibraryRequest } from '../api/client/library';
import { trackFilterEvent } from '@/analytics/library';
import { trackLoadMoreEvent } from '@/analytics/pagination';
import { AnalyticsContext, FilterContext } from '@/analytics/constants';

type LibraryState = {
  isLoading: boolean;
  isFetching: boolean;
  data: LibraryResponse | null;
  request: LibraryRequest | null;
  error: FetchBaseQueryError | SerializedError | null;
};

const initialLibraryState: LibraryState = {
  data: null,
  request: null,
  isLoading: false,
  isFetching: false,
  error: null
};

const librarySlice = createSlice({
  name: 'library',
  initialState: initialLibraryState,
  reducers: {
    libraryLoading: (state, payload: PayloadAction<'loading' | 'fetching' | 'none'>) => {
      state.isLoading = payload.payload === 'loading';
      state.isFetching = payload.payload === 'fetching';
    },
    librarySuccess: (
      state,
      action: PayloadAction<{
        data: LibraryResponse;
      }>
    ) => {
      state.isLoading = false;
      state.isFetching = false;
      state.data = action.payload.data;
      state.error = null;
    },
    libraryFailure: (state, action: PayloadAction<FetchBaseQueryError | SerializedError>) => {
      state.isLoading = false;
      state.isFetching = false;
      state.error = action.payload;
    },
    toggleLibraryFilter: (
      state,
      action: PayloadAction<{
        filterId: LibraryFilterKey;
        value: LibraryFilterValue;
        context?: FilterContext;
      }>
    ) => {
      const { filterId, value: _value, context } = action.payload;

      const itemInfo = LIBRARY_FILTER_GROUPS[
        filterId as keyof typeof LIBRARY_FILTER_GROUPS
      ]?.filters.find(filter => {
        return filter.value === _value;
      });
      // There will usually only be one value, but we'll check for multiple
      // for the case of podcasts where we map 2 BE values to 1 FE value
      const values = LIBRARY_FILTER_GROUPS[filterId as keyof typeof LIBRARY_FILTER_GROUPS]?.filters
        .filter(filter => {
          return filter.label === itemInfo?.label;
        })
        .map(filter => filter.value) ?? [_value];

      values.forEach(value => {
        const filters = state?.data?.summary?.filtersApplied;
        if (!filters) return;

        const config = LibraryFilterTypeMap[filterId];
        const type = config;
        if (type === FilterTypes.RADIO || type === FilterTypes.TABS) {
          trackFilterEvent({ facetGroup: filterId, facetValue: value, context });
          state.data!.summary!.filtersApplied = {
            ...filters,
            [filterId]: value
          };
        } else {
          const isTrue = isItemChecked(value, filters[filterId]);
          if (isTrue) {
            let newValue = undefined;
            if (typeof state.data!.summary!.filtersApplied![filterId] === 'string') {
              newValue = (state.data!.summary!.filtersApplied![filterId] as string)
                .split(FACET_DELIMITER)
                .filter((v: string) => v !== value)
                .join(FACET_DELIMITER);
            }
            state.data!.summary!.filtersApplied = {
              ...filters,
              [filterId]: newValue
            };
          } else {
            trackFilterEvent({ facetGroup: filterId, facetValue: value, context });
            state.data!.summary!.filtersApplied = {
              ...filters,
              [filterId]: [...(filters[filterId] ? [filters[filterId]] : []), value].join(
                FACET_DELIMITER
              )
            };
          }
        }
      });

      // reset to first page
      if (state.data?.summary?.currentPage) {
        state.data.summary.currentPage = 1;
      }
    },
    loadMore: state => {
      if (!state.data?.summary?.currentPage) return;

      const nextPage = getCurrentPage(state.data.summary.currentPage, state.data.items?.length) + 1;
      trackLoadMoreEvent(AnalyticsContext.Library, nextPage);
      state.data.summary.currentPage = nextPage;
    },
    clearLibraryFilters: (state, action: PayloadAction<{ groupId?: string }>) => {
      if (!state.data?.summary?.filtersApplied) return;

      FILTER_LIST.forEach(key => {
        if (action.payload.groupId && key !== action.payload.groupId) return;
        if (key === LIBRARY_FILTERS.GROUP_SEARCHABLE_TYPE) return;

        state.data!.summary!.filtersApplied![key as LibraryFilterKey] = undefined;
      });
    },
    librarySort: (state, action: PayloadAction<string>) => {
      if (state.data?.summary?.filtersApplied?.sort) {
        state.data.summary.filtersApplied.sort = action.payload;
      }

      // reset to first page
      if (state.data?.summary?.currentPage) {
        state.data.summary.currentPage = 1;
      }
    }
  }
});

export const {
  libraryLoading,
  librarySuccess,
  libraryFailure,
  librarySort,
  toggleLibraryFilter,
  loadMore,
  clearLibraryFilters
} = librarySlice.actions;

export default librarySlice.reducer;
