import ModalContent from '@/components/ui/Modal/ModalContent';
import AddLicenseSection, { AddLicenseSectionProps } from './AddLicenseSection';
import { useCallback, useEffect, useRef, useState } from 'react';
import ViewAllModal from './ViewAllModal';
import classnames from 'classnames';
import { CheckItem } from '../Filter/types';
import FilterShimmer from '../SearchShimmer/FilterShimmer';
import Shimmer from '../Shimmer';

export type AddLicenseModalSection = Omit<
  AddLicenseSectionProps,
  'defaultOpen' | 'onViewAllClick' | 'expandButtonFocused'
> & { isTopLevelSection?: boolean };

export type AddLicenseModalProps = {
  sections: AddLicenseModalSection[];
  onSave: () => void;
  onValueChange: (value?: string) => void;
  onClearAllFilters: () => void;
  isLoading?: boolean;
  fetching?: boolean;
};

const AddLicenseModal = ({
  sections,
  onSave,
  onValueChange,
  onClearAllFilters,
  isLoading,
  fetching
}: AddLicenseModalProps): JSX.Element => {
  const [viewAllIndex, setViewAllIndex] = useState<number | null>(null);
  const lastIndexExpandedRef = useRef<number>();
  const section = viewAllIndex !== null && sections[viewAllIndex];

  useEffect(() => {
    if (viewAllIndex === null) {
      lastIndexExpandedRef.current = undefined;
    }
  }, [viewAllIndex]);

  const handleClearViewAllIndex = useCallback(() => {
    setViewAllIndex(null);
  }, []);

  if (!section) {
    return (
      <ModalContent
        title={isLoading ? <Shimmer className="h-8 w-2/3" /> : 'Licenses and Jurisdictions'}
        saveButtonLabel="Save changes"
        dismissButtonLabel="Clear all filters"
        onDismissButtonPress={onClearAllFilters}
        onSaveButtonPress={onSave}
        shouldDismissButtonClose={false}
        isMultiStep={true}
        hasTransition={lastIndexExpandedRef.current === undefined}
      >
        <div className="flex flex-col gap-8">
          {isLoading
            ? Array.from({ length: 2 }).map((_, index) => (
                <FilterShimmer
                  key={index}
                  amount={10}
                  type="check"
                  hasTitle
                  className="my-8 gap-y-8"
                />
              ))
            : sections.map((section, index) => {
                const expandButtonFocused = index === lastIndexExpandedRef.current;
                return (
                  <AddLicenseSection
                    key={section.title}
                    defaultOpen={index === 0 || section.isTopLevelSection || expandButtonFocused}
                    className={classnames({
                      'font-bold': section.isTopLevelSection
                    })}
                    {...section}
                    title={section.title}
                    onValueChange={onValueChange}
                    onViewAllClick={() => {
                      lastIndexExpandedRef.current = index;
                      setViewAllIndex(index);
                    }}
                    isTopLevel={section.isTopLevelSection}
                    fetching={fetching}
                    expandButtonFocused={expandButtonFocused}
                  />
                );
              })}
        </div>
      </ModalContent>
    );
  }

  return (
    <ViewAllModal
      title={<p className="text-1">{section.title}</p>}
      groups={[{ items: section.items as CheckItem[] }]}
      onSave={onSave}
      isMultiStep={true}
      onValueChange={onValueChange}
      onBack={handleClearViewAllIndex}
      onClose={handleClearViewAllIndex}
      onClearAllFilters={onClearAllFilters}
      fetching={fetching}
      hasTransition={false}
      isL2Modal={true}
    />
  );
};

export default AddLicenseModal;
