import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  DATE_FACETS,
  EXCLUDE_FROM_FILTER_PANEL,
  FILTERS,
  FilterTypes,
  HAS_DISABLED_FILTERS,
  QUERY_FILTER_GROUP
} from '@/components/ui/SearchResults/constants';
import {
  translateStoreFilterToComponentProps,
  translateStoreSortToComponentProps,
  translateStoreTabsToComponentProps
} from '@/components/ui/SearchResults/utils/translateStoreDataToComponentsProps';
import { selectSearchAllFiltersFromEmptySearch } from '@/components/ui/SearchResults/hooks/useGetAllFiltersFromEmptySearch';
import { formatDateFilter, isStartEndDateFilter } from '@/components/ui/Filter/DateFilterUtils';
import { BackendDateFormat, parseDate, compareArrayIndex } from '@/utils/helpers';
import { addYears, subYears } from 'date-fns';
import { addDisabledFacetsFromEmptySearch } from '@/components/ui/SearchResults/utils/searchResponse';

// Selectors to access specific parts of the state
export const selectSearchState = (state: RootState) => state.search;
export const selectSearchData = (state: RootState) => state.search.data;
const selectSearchRequest = (state: RootState) => state.search.request;
export const selectSearchLoading = (state: RootState) => state.search.isLoading;
export const selectSearchFetching = (state: RootState) => state.search.isFetching;
export const selectSearchError = (state: RootState) => state.search.error;
const selectSearchAdHocFilters = (state: RootState) => state.search.adHocFilters;
export const selectSearchInvariantFilters = (state: RootState) => state.search.invariantFilters;
export const selectSearchSemanticFilters = (state: RootState) => state.search.semanticFilters;
export const selectSearchSemanticFilterTooltip = (state: RootState) =>
  state.search.semanticFilterTooltip;
const selectSearchPreselectedFiltersThatHaveBeenDeselected = (state: RootState) =>
  state.search.preselectedFiltersThatHaveBeenDeselected;
const selectSearchFacetFilters = (state: RootState) => state.search.facetFilters;
const selectSearchQueryFilters = (state: RootState) => state.search.queryFilters;
export const selectSearchTermHistory = (state: RootState) => state.search.searchTermHistory;
export const selectLastSearchParams = (state: RootState) => state.search.lastSearchParams;

export const selectSearchRequestResponse = createSelector(
  selectSearchRequest,
  selectSearchData,
  selectSearchPreselectedFiltersThatHaveBeenDeselected,
  (request, response, preselectedFiltersThatHaveBeenDeselected) => {
    return {
      request,
      response,
      preselectedFiltersThatHaveBeenDeselected
    };
  }
);

const selectFilters = createSelector(selectSearchData, data => data?.facetResults?.facetGroups);

export const selectSort = createSelector(
  selectSearchData,
  data => data?.sortResults?.sortResultItems
);

export const selectSearchFilters = createSelector(
  selectFilters,
  selectSearchAllFiltersFromEmptySearch,
  (filters, allFiltersFromEmptySearch) => {
    return filters
      ?.filter(group => !EXCLUDE_FROM_FILTER_PANEL.includes(group.groupTypeId ?? ''))
      .map(group => {
        const facets = addDisabledFacetsFromEmptySearch({
          hasDisabledFilters: HAS_DISABLED_FILTERS.includes(group.groupTypeId ?? ''),
          facetGroup: group,
          allFiltersFromEmptySearch
        });

        return {
          ...group,
          facets
        };
      });
  }
);

export const selectQuickFiltersToProps = createSelector(
  [
    selectSearchState,
    selectFilters,
    selectSearchFilters,
    selectSearchFacetFilters,
    selectSearchQueryFilters
  ],
  (search, allFilters, searchFilters, facetFilters, queryFilters) => {
    const invariantFilters = search?.invariantFilters;

    if (!allFilters) return [];

    const suggestions =
      allFilters.find(facetGroup => facetGroup.groupTypeId === FILTERS.SUGGESTED_FILTERS)?.facets ||
      [];

    const output = (searchFilters || [])
      .flatMap(
        group =>
          group?.facets
            ?.filter(
              facet =>
                // Quick filters are a mix of selected filters...
                facet?.checked ||
                (!!facet?.value && isStartEndDateFilter(facet?.name)) ||
                // ...and suggested filters
                suggestions
                  //hide any suggested filters that are in the same group as the invariant filter
                  .filter(
                    s =>
                      !invariantFilters.some(
                        invariant => invariant.facetGroupType === s.refGroupTypeId
                      )
                  )
                  .some(s => s.value === facet.value && s.refGroupTypeId === group.groupTypeId)
            )
            .map(facet => {
              const isDateFilterFacet = facet?.value && isStartEndDateFilter(facet?.name);
              return {
                data: {
                  name: facet?.name,
                  label: isDateFilterFacet
                    ? formatDateFilter(facet?.name, facet?.value)
                    : facet.name || '',
                  checked: isDateFilterFacet || facet.checked,
                  altValue: facet.altValue || '',
                  value: facet.value || '',
                  groupTypeId: group.groupTypeId || ''
                },
                canDeselect: !invariantFilters.some(invariant => invariant.value === facet.value)
              };
            }) ?? []
      )
      .filter(Boolean);

    facetFilters?.forEach(facetFilter => {
      const existingFilter = output.find(
        f =>
          (f.data.value === facetFilter.value || f.data.altValue === facetFilter.value) &&
          f.data.groupTypeId === facetFilter.facetGroupType
      );
      if (existingFilter) {
        if (facetFilter.title) {
          existingFilter.data.label = facetFilter.title;
        }
      } else {
        output.push({
          data: {
            name: facetFilter?.title,
            label: facetFilter.title || '',
            checked: false,
            value: facetFilter.value || '',
            altValue: '',
            groupTypeId: facetFilter.facetGroupType || ''
          },
          canDeselect: true
        });
      }
    });

    invariantFilters.forEach(invariant => {
      const existingInvariant = output.find(
        f => f.data.value === invariant.value && f.data.groupTypeId === invariant.facetGroupType
      );
      if (existingInvariant) {
        existingInvariant.data.label = invariant.title || existingInvariant.data.label;
      }
    });

    queryFilters?.forEach(queryFilter => {
      output.push({
        data: {
          name: '',
          label: queryFilter.title || '',
          checked: queryFilter.checked,
          value: queryFilter.query,
          altValue: '',
          groupTypeId: QUERY_FILTER_GROUP
        },
        canDeselect: true
      });
    });

    return output.sort((a, b) => {
      // Invariant items go first
      if (a.canDeselect !== b.canDeselect) {
        return a.canDeselect ? 1 : -1;
      }

      // Then checked items
      if (a.data.checked !== b.data.checked) {
        return a.data.checked ? -1 : 1;
      }

      // Then facet filters
      const aIsFacetFilter = facetFilters?.some(
        f => f.value === a.data.value && f.facetGroupType === a.data.groupTypeId
      );
      const bIsFacetFilter = facetFilters?.some(
        f => f.value === b.data.value && f.facetGroupType === b.data.groupTypeId
      );
      if (aIsFacetFilter !== bIsFacetFilter) {
        return aIsFacetFilter ? -1 : 1;
      }

      // Then query filters
      const aIsQueryFilter = queryFilters?.some(f => f.query === a.data.value);
      const bIsQueryFilter = queryFilters?.some(f => f.query === b.data.value);
      if (aIsQueryFilter !== bIsQueryFilter) {
        return aIsQueryFilter ? -1 : 1;
      }

      // Break ties
      return (
        // Position in invariant filters
        compareArrayIndex(
          invariantFilters,
          a,
          b,
          (s, i) => s.value === i.data.value && s.facetGroupType === i.data.groupTypeId
        ) ||
        // Position in facet filters
        compareArrayIndex(
          facetFilters ?? [],
          a,
          b,
          (s, i) => s.value === i.data.value && s.facetGroupType === i.data.groupTypeId
        ) ||
        // Position in query filters
        compareArrayIndex(queryFilters ?? [], a, b, (s, i) => s.query === i.data.value) ||
        // Position in suggestions
        compareArrayIndex(
          suggestions,
          a,
          b,
          (s, i) => s.value === i.data.value && s.refGroupTypeId === i.data.groupTypeId
        ) ||
        // Position of group
        compareArrayIndex(allFilters, a, b, (s, i) => s.groupTypeId === i.data.groupTypeId) ||
        // Position of item in group
        compareArrayIndex(
          allFilters.find(
            facetGroup =>
              facetGroup.groupTypeId === a.data.groupTypeId &&
              facetGroup.groupTypeId === b.data.groupTypeId
          )?.facets ?? [],
          a,
          b,
          (s, i) => s.value === i.data.value
        ) ||
        a.data.label.localeCompare(b.data.label)
      );
    });
  }
);

export const selectProcessedQuickFilters = createSelector(
  [selectSearchState, selectSearchFilters, selectQuickFiltersToProps],
  (search, searchFilters, quickFiltersToProps) => {
    if (!quickFiltersToProps) return [];
    const invariantFilters = search?.invariantFilters;

    return quickFiltersToProps.map(item => {
      const possibleInvariantFilter = invariantFilters?.find(
        iv => iv.facetGroupType == item.data.groupTypeId && iv.value == item.data.value
      );

      if (possibleInvariantFilter) {
        const existingFacetFromFilter = searchFilters
          ?.find(ft => ft.groupTypeId == possibleInvariantFilter.facetGroupType)
          ?.facets?.find(i => i.value == possibleInvariantFilter.value);

        if (
          existingFacetFromFilter &&
          item.data.label == item.data.value &&
          !possibleInvariantFilter?.name
        ) {
          return {
            canDeselect: item.canDeselect,
            data: {
              ...item.data,
              label: existingFacetFromFilter.name
            }
          };
        }
      }

      return item;
    });
  }
);

export const selectAvailableDateRange = createSelector(selectFilters, filters => {
  const dateRangeFacetGroup = filters?.find(
    facetGroup => facetGroup.groupTypeId === FILTERS.DATERANGE
  );
  const minDateFacet = dateRangeFacetGroup?.facets?.find(
    facet => facet.name === DATE_FACETS.MIN_DATE
  );
  const maxDateFacet = dateRangeFacetGroup?.facets?.find(
    facet => facet.name === DATE_FACETS.MAX_DATE
  );
  const minDate = minDateFacet?.value
    ? parseDate(minDateFacet.value, BackendDateFormat)
    : undefined;
  const maxDate = maxDateFacet?.value
    ? parseDate(maxDateFacet.value, BackendDateFormat)
    : undefined;
  const defaultYearRange = 4;
  return {
    minDate: minDate || subYears(new Date(), defaultYearRange),
    maxDate: maxDate || addYears(new Date(), defaultYearRange)
  };
});

export const selectSearchFiltersToProps = createSelector(
  [selectSearchState, selectSearchFilters, selectAvailableDateRange],
  (search, filters, selectAvailableDateRange) => {
    const invariantFilters = search?.invariantFilters;
    return filters
      ?.filter(
        filter =>
          !invariantFilters.some(invariant => invariant.facetGroupType === filter.groupTypeId)
      )
      ?.map(filter => {
        const result = translateStoreFilterToComponentProps(filter);
        if (result.type === FilterTypes.DATE) {
          result.minDate = selectAvailableDateRange.minDate;
          result.maxDate = selectAvailableDateRange.maxDate;
        }
        return result;
      });
  }
);

export const selectSearchTabs = createSelector(
  selectFilters,
  filters => filters?.find(facetGroup => facetGroup?.groupTypeId === FILTERS.CONTENT_TABS)?.facets
);

export const selectSearchTabsToProps = createSelector(
  selectSearchTabs,
  translateStoreTabsToComponentProps
);

export const selectSearchSortToProps = createSelector(
  selectSort,
  translateStoreSortToComponentProps
);

// Combined selector to select multiple parts of the state
export const selectSearch = createSelector(
  selectSearchData,
  selectSearchLoading,
  selectSearchFetching,
  selectSearchError,
  selectSearchFilters,
  selectProcessedQuickFilters,
  selectSearchFiltersToProps,
  selectSearchTabs,
  selectSearchTabsToProps,
  selectSearchSortToProps,
  selectSearchAdHocFilters,
  selectSearchQueryFilters,
  (
    data,
    loading,
    fetching,
    error,
    filters,
    quickFiltersToProps,
    filtersToProps,
    tabs,
    tabsToProps,
    sortToProps,
    adHocFilters,
    queryFilters
  ) => ({
    data,
    loading,
    fetching,
    error,
    filters,
    quickFiltersToProps,
    filtersToProps,
    tabs,
    tabsToProps,
    sortToProps,
    adHocFilters,
    queryFilters
  })
);
