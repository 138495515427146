import type {
  StartPage as GenericPage,
  SiteSettings,
  ReferencePageSettings
} from '@/@types/content';
import ContentArea from '../cms/ContentArea';
import withNav from '../hocs/withNav';
import { BreadcrumbProps } from '../ui/Breadcrumbs';
import useToast from '@/hooks/useToast';
import { useSearchParamsStable } from '../../hooks/useSearchParams';
import { useEffect } from 'react';

type GenericPageProps = GenericPage & {
  breadcrumbs: BreadcrumbProps[];
  siteLabels: ReferencePageSettings;
};

const GenericPageComponent: React.FC<GenericPageProps & SiteSettings> = props => {
  const { showSuccessMessage, showFailMessage } = useToast();
  const { searchParams, setSearchParams } = useSearchParamsStable();

  useEffect(() => {
    const delayedAction = searchParams.get('successAction');
    const errorAction = searchParams.get('errorAction');

    if (errorAction === 'addToLibrary') {
      showFailMessage('Error while adding item to library');
    }

    if (delayedAction === 'addToLibrary') {
      showSuccessMessage('Saved to My Library');
    }

    setSearchParams(
      existing => {
        existing.delete('successAction');
        existing.delete('program');
        existing.delete('response');
        existing.delete('errorAction');
        return existing;
      },
      { replace: true, preventScrollReset: true }
    );
  }, [searchParams, showSuccessMessage, showFailMessage, setSearchParams]);

  return (
    <ContentArea
      components={props.mainContentArea}
      componentsProps={{
        breadcrumbs: [...props.breadcrumbs],
        pageName: props.name,
        siteLabels: props.siteLabels,
        hideInBreadcrumbs: props.hideInBreadcrumbs,
        url: props.url
      }}
      propertyName="MainContentArea"
    />
  );
};

const GenericPage = withNav(GenericPageComponent);
export default GenericPage;
