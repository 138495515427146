import { ItemAnalyticsModel } from '@/@types/client-api';
import { VariationContent } from '@/@types/content';
import { AnalyticsContext } from '@/analytics/constants';
import { ShareModalProps } from '@/components/ui/PDP/Modals/ShareModal';
import { useMemo, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

export const useShareModal = (
  variant: VariationContent,
  itemAnalyticsModel?: ItemAnalyticsModel,
  context?: AnalyticsContext
) => {
  const shareModalId = useMemo(() => `share-modal-${uuid4()}`, []);

  const [openShareModal, setOpenShareModal] = useState(false);

  return {
    customModalId: shareModalId,
    open: openShareModal,
    setOpen: setOpenShareModal,
    variantTitle: variant.displayName,
    variantUrl: variant.url,
    itemAnalyticsModel,
    context
  } as ShareModalProps;
};
