import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import Text from '@/components/cms/Text';
import Button from '@/components/ui/Buttons/Button';
import type { LinkGridBlock as LinkGridBlockProps } from '@/@types/content';
import Link from '@/components/ui/Link';
import LinkGridBlockCollapsible from './LinkGridBlockCollapsible';
import LinkGridBlockNonCollapsible from './LinkGridBlockNonCollapsible';

import Block from '@/components/cms/Block';
import { Nudge } from '@/hooks/shared/useNudge/types';
import NudgeTooltip from '@/components/ui/Tooltip/NudgeTooltip';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import classnames from 'classnames';
import { OnTrackItemViewEvent } from './LinkBlock';

export interface LinkGridProps extends LinkGridBlockProps {
  elementType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  nudge?: Nudge;
  onTrackItemViewEvent?: OnTrackItemViewEvent;
  order?: 'ROW' | 'COL';
}

const LinkGridBlock: React.FC<LinkGridProps> = ({
  contentLink,
  heading,
  subheading,
  layout = '1',
  linkBlocks = [],
  viewAllLink,
  viewMoreLink,
  noItemsTitle,
  noItemsText,
  noItemsButton: { text, contentLink: _contentLink, ...noItemsProps } = {},
  numberOfRows,
  elementType = 'h2',
  format = 'h6',
  nudge,
  onTrackItemViewEvent,
  removeComponentContainer,
  order = 'ROW'
}) => {
  const modeEdit = isEditOrPreviewMode();
  const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

  const headingClass = classnames({
    'heading-6-medium': format === 'h6',
    'heading-5-medium': format === 'h5'
  });

  const headingContainerClass = classnames({
    'flex flex-col items-start justify-between sm:flex-row': viewAllLink
  });

  return (
    <Block
      contentLinkID={contentLink?.id}
      className={classnames({ container: !removeComponentContainer }, 'module-spacing')}
    >
      <div className={headingContainerClass}>
        <div>
          <Text element={elementType} propertyName="Heading" className={headingClass}>
            {nudge ? <NudgeTooltip nudge={nudge}>{heading}</NudgeTooltip> : heading}
          </Text>
          {!!linkBlocks.length && (
            <Text propertyName="Subheading" className="text-2 mt-2 text-gray-dark">
              {subheading}
            </Text>
          )}
        </div>
        {viewAllLink && (
          <Link
            {...viewAllLink}
            propertyName="ViewAllLink"
            variant="bold-link-with-icon"
            icon="chevron-right"
            className="mt-8 sm:mt-0"
          />
        )}
      </div>

      {(linkBlocks.length || modeEdit) && (
        <div className="mt-8">
          {viewMoreLink ? (
            <LinkGridBlockNonCollapsible
              linkBlocks={linkBlocks}
              layout={layout}
              viewMoreLink={viewMoreLink}
              onTrackItemViewEvent={onTrackItemViewEvent}
            />
          ) : (
            <LinkGridBlockCollapsible
              linkBlocks={linkBlocks}
              layout={layout}
              numberOfRows={numberOfRows}
              seeMoreLabel={stateSiteLabels?.seeMoreLabel}
              seeLessLabel={stateSiteLabels?.seeLessLabel}
              onTrackItemViewEvent={onTrackItemViewEvent}
              order={order}
            />
          )}
        </div>
      )}

      {(!linkBlocks.length || modeEdit) && (
        <div className="my-8 flex flex-col gap-4 rounded-lg bg-gray-feather p-8 md:flex-row md:items-center md:justify-between"  data-component={"LinkGridBlock-items"}>
          <div className="flex flex-col gap-1">
            <Text propertyName="NoItemsTitle" className="text-1 font-bold">
              {noItemsTitle}
            </Text>
            <Text propertyName="NoItemsText" className="text-1 text-gray-darker">
              {noItemsText}
            </Text>
          </div>
          <Button
            onClick={() => onTrackItemViewEvent?.(text)}
            {...noItemsProps}
            propertyName="NoItemsButton"
            label={text}
            color="outline-black"
            size="large"
            className="shrink-0"
          />
        </div>
      )}
    </Block>
  );
};

export default LinkGridBlock;
