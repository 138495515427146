import { ComponentProps, useEffect, useMemo, useRef } from 'react';
import Filter from '@/components/ui/Filter/Filter';
import { CheckItem, FilterProps } from '@/components/ui/Filter/types';
import Divider from '@/components/ui/Divider';
import { CheckFilterGroup } from '../Filter/FilterGroups';
import ExpandFilterButton from '../Filter/ExpandFilterButton';

export type AddLicenseSectionProps = Pick<FilterProps, 'defaultOpen' | 'title' | 'className'> &
  Pick<ComponentProps<typeof CheckFilterGroup>, 'onValueChange'> & {
    items: FilterProps['items'];
    onViewAllClick: () => void;
    visibleItemsCount?: number;
    isTopLevel?: boolean;
    fetching?: boolean;
    expandButtonFocused: boolean;
  };

const AddLicenseSection = ({
  className,
  title,
  defaultOpen,
  items,
  onViewAllClick,
  onValueChange,
  visibleItemsCount = 10,
  isTopLevel,
  fetching,
  expandButtonFocused
}: AddLicenseSectionProps): JSX.Element => {
  const expandButtonRef = useRef<HTMLButtonElement>(null);
  const visibleItems = useMemo(() => items.slice(0, visibleItemsCount), [items, visibleItemsCount]);

  useEffect(() => {
    if (!expandButtonRef.current || !expandButtonFocused) {
      return;
    }
    const timer = setTimeout(() => {
      expandButtonRef.current?.focus();
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, [expandButtonFocused]);

  return (
    <>
      <Divider color="light" />
      <Filter
        type="check"
        className={className}
        title={title}
        defaultOpen={defaultOpen}
        items={visibleItems as CheckItem[]}
        groupTypeId=""
        hasMore={items.length > visibleItemsCount}
        onValueChange={onValueChange}
        collapsible={!isTopLevel}
        fetching={fetching}
        expandFilterButton={
          <ExpandFilterButton
            ref={expandButtonRef}
            expandText={`See all ${title}`}
            onExpandFilter={onViewAllClick}
          />
        }
      />
    </>
  );
};

export default AddLicenseSection;
