import { forwardRef } from 'react';
import parse from 'html-react-parser';
import classnames from 'classnames';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';

export interface RichTextProps extends React.HTMLProps<HTMLDivElement> {
  content?: string;
  propertyName?: string;
  condensed?: boolean;
  className?: string;
  checkList?: boolean;
  checklistRed?: boolean;
}

const RichText = forwardRef<HTMLDivElement, RichTextProps>(
  ({ content, propertyName, condensed, className, checkList, checklistRed, ...props }, ref) => {
    const isEditMode = isEditOrPreviewMode();
    const conditionalProps =
      isEditMode && propertyName ? { ...props, 'data-epi-edit': propertyName } : props;

    if (!isEditMode && !content) return null;

    return (
      <div
        {...conditionalProps}
        ref={ref}
        className={classnames(
          className,
          'rtf',
          {
            'rtf-condensed': condensed,
            'rtf-checkmark-list': checkList,
            'rtf-checkmark-red': checklistRed
          },
          'relative'
        )}
      >
        {!!content && parse(content)}
      </div>
    );
  }
);

export default RichText;
