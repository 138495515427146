import Image from '@/components/cms/Image';
import classnames from 'classnames';
import { useRef, useState, useEffect } from 'react';
import Player from '@vimeo/player';
import Icon from '@/components/ui/Icon';
import {
  ImpactHeaderBlock as ImpactHeaderBlockProps,
  RequestAQuoteModalBlock as RequestAQuoteModalBlockType,
  StartTrialFlow as StartTrialBlockType
} from '@/@types/content';
import { setLogoColor } from '@/redux/slices/pageSlice';
import { useDispatch } from 'react-redux';
import ShareButton from '@/components/ui/Buttons/ShareButton';
import Block from '@/components/cms/Block';
import { Theme } from '@/styles/darkMode';
import RichText from '@/components/cms/RichText';
import Button from '@/components/ui/Buttons/Button';
import RequestAQuoteModalBlock from '@/components/blocks/RequestAQuoteModalBlock';
import StartTrialModalBlock from '@/components/blocks/StartTrialModalBlock';
import Text from '@/components/cms/Text';
import { IContent } from '@/@types/cms';
import useSearchDelayedActionToasts from '@/components/ui/SearchResults/hooks/useSearchDelayedActionToasts';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

enum LinkStyle {
  Primary = 'primary',
  Secondary = 'secondary'
}

interface BlockProps extends ImpactHeaderBlockProps {
  pageName?: string;
  logoStyleOption: (IContent & { headerThemeSelection: number })[];
}

const ImpactHeaderBlock: React.FC<BlockProps> = ({
  backgroundColor,
  backgroundImage,
  backgroundVideo,
  contentLink,
  copy,
  disableShareLink,
  eyeBrowText,
  generalLink,
  genericLinkStyle = LinkStyle.Primary,
  gradientEndLineOne = 'white',
  gradientEndLineThree = 'white',
  gradientEndLineTwo = 'white',
  gradientStartLineOne = 'white',
  gradientStartLineThree = 'white',
  gradientStartLineTwo = 'white',
  heading,
  heading2,
  heading3,
  logoStyleOption,
  pageName,
  quoteLinkStyle = LinkStyle.Primary,
  requestAQuote,
  startTrial,
  trialLinkStyle = LinkStyle.Primary
}) => {
  const requestAQuoteModalBlockProps = requestAQuote?.[0] as RequestAQuoteModalBlockType;
  const startTrialBlockProps = startTrial?.[0] as StartTrialBlockType;

  const dispatch = useDispatch();
  const videoRef = useRef<HTMLIFrameElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [videoError, setVideoError] = useState<boolean>(!backgroundVideo);
  const videoBackground = `https://player.vimeo.com/video/${backgroundVideo}?title=false&autoplay=1&loop=1&background=1`;
  const lg = useMediaQuery(ScreenSizeQueries.lg);

  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const player = new Player(videoElement);
      if (isPlaying) {
        player.pause();
      } else {
        player.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useSearchDelayedActionToasts();

  useEffect(() => {
    if (logoStyleOption && logoStyleOption.at(-1)?.headerThemeSelection === 0) {
      dispatch(setLogoColor('white'));
    } else {
      dispatch(setLogoColor('red'));
    }
  }, [logoStyleOption, dispatch]);

  useEffect(() => {
    if (videoRef.current) {
      const player = new Player(videoRef.current);
      player.ready().catch(() => setVideoError(true));
    }
  }, []);

  const getLineTheme = (start?: string, end?: string) => {
    const gradientStartClasses = classnames({
      'from-white': start === 'white',
      'from-black': start === 'black',
      'from-red': start === 'red',
      'from-gold': start === 'gold',
      'from-green-100': start === 'green-100',
      'from-green-200': start === 'green-200',
      'from-green-300': start === 'green-300',
      'from-teal-100': start === 'teal-100',
      'from-teal-200': start === 'teal-200',
      'from-teal-300': start === 'teal-300',
      'from-blue-100': start === 'blue-100',
      'from-blue-200': start === 'blue-200',
      'from-blue-300': start === 'blue-300',
      'from-indigo-100': start === 'indigo-100',
      'from-indigo-200': start === 'indigo-200',
      'from-indigo-300': start === 'indigo-300',
      'from-purple-100': start === 'purple-100',
      'from-purple-200': start === 'purple-200',
      'from-purple-300': start === 'purple-300',
      'from-magenta-100': start === 'magenta-100',
      'from-magenta-200': start === 'magenta-200',
      'from-magenta-300': start === 'magenta-300'
    });

    const gradientEndClasses = classnames({
      'to-white': end === 'white',
      'to-black': end === 'black',
      'to-red': end === 'red',
      'to-gold': start === 'gold',
      'to-green-100': end === 'green-100',
      'to-green-200': end === 'green-200',
      'to-green-300': end === 'green-300',
      'to-green-400': end === 'green-400',
      'to-teal-100': end === 'teal-100',
      'to-teal-200': end === 'teal-200',
      'to-teal-300': end === 'teal-300',
      'to-teal-400': end === 'teal-400',
      'to-blue-100': end === 'blue-100',
      'to-blue-200': end === 'blue-200',
      'to-blue-300': end === 'blue-300',
      'to-blue-400': end === 'blue-400',
      'to-indigo-100': end === 'indigo-100',
      'to-indigo-200': end === 'indigo-200',
      'to-indigo-300': end === 'indigo-300',
      'to-indigo-400': end === 'indigo-400',
      'to-purple-100': end === 'purple-100',
      'to-purple-200': end === 'purple-200',
      'to-purple-300': end === 'purple-300',
      'to-purple-400': end === 'purple-400',
      'to-magenta-100': end === 'magenta-100',
      'to-magenta-200': end === 'magenta-200',
      'to-magenta-300': end === 'magenta-300',
      'to-magenta-400': end === 'magenta-400'
    });

    return classnames(
      'heading-1 w-fit bg-gradient-to-r bg-clip-text pb-[0.1rem] text-transparent',
      gradientStartClasses,
      gradientEndClasses
    );
  };

  const showCtaArea = requestAQuoteModalBlockProps || startTrialBlockProps || generalLink;

  const impactHeaderClasses = classnames(
    'container flex size-full flex-col justify-end gap-8 pb-6 pt-12 lg:pb-10',
    {
      'lg:flex-row lg:items-end lg:justify-between': !showCtaArea && !copy
    }
  );

  const imageFormClasses = classnames('size-full object-cover');

  const mediaContainerClasses = classnames('absolute left-0 top-0 z-0 size-full', {
    'before:absolute before:inset-0 before:size-full before:bg-black before:opacity-50':
      backgroundVideo
  });

  const backgroundColorClasses = classnames('size-full', {
    'bg-charcoal': backgroundColor === 'charcoal',
    'bg-green-100': backgroundColor === 'green',
    'bg-teal-100': backgroundColor === 'teal',
    'bg-blue-100': backgroundColor === 'blue',
    'bg-indigo-100': backgroundColor === 'indigo',
    'bg-purple-100': backgroundColor === 'purple',
    'bg-magenta-100': backgroundColor === 'magenta',
    'bg-red-dark': backgroundColor === 'dark red'
  });

  const contentClasses = classnames('flex items-center gap-6 lg:gap-10');

  const ctaAreaClasses = classnames({
    'flex w-full flex-col flex-wrap justify-between gap-8 md:flex-row md:items-center': showCtaArea,
    'flex flex-1 md:justify-end': !showCtaArea
  });

  const lineOneTheme = getLineTheme(gradientStartLineOne, gradientEndLineOne);
  const lineTwoTheme = getLineTheme(gradientStartLineTwo, gradientEndLineTwo);
  const lineThreeTheme = getLineTheme(gradientStartLineThree, gradientEndLineThree);

  return (
    <Block
      contentLinkID={contentLink?.id}
      className="relative mt-[-161px] flex min-h-[500px] items-end overflow-hidden pt-[192px] md:mt-[-191px] lg:mt-[-95px] lg:min-h-[540px]"
    >
      <>
        {backgroundVideo && !videoError && (
          <div className={classnames(mediaContainerClasses, 'bg-black')}>
            <div className="container size-full">
              <iframe
                className="absolute left-1/2 top-1/2 aspect-video w-[1680px] -translate-x-1/2 -translate-y-1/2 bg-black opacity-50"
                src={videoBackground}
                ref={videoRef}
                allow="autoplay;"
              />
            </div>
          </div>
        )}
        {backgroundImage && videoError && (
          <div className={mediaContainerClasses}>
            <Image
              propertyName="BackgroundImage"
              src={backgroundImage?.url}
              alt={backgroundImage?.alt as string}
              className={imageFormClasses}
              responsive={{
                aspectRatio: lg ? 3 : 1.2,
                skipWrapper: true,
                imageSizes: {
                  xs: '400px',
                  sm: '270px',
                  md: '1201px',
                  lg: '1440px',
                  xl: '1680px',
                  '2xl': '3840px'
                }
              }}
            />
          </div>
        )}
        {!backgroundImage && !backgroundVideo && backgroundColor && (
          <div className={classnames(mediaContainerClasses, backgroundColorClasses)}></div>
        )}
      </>
      <div className={impactHeaderClasses}  data-component={"ImpactHeaderBlock"}>
        <article>
          {eyeBrowText && (
            <Text
              propertyName="EyebrowText"
              element="p"
              className={classnames('text-1 mb-4 text-silver')}
            >
              {eyeBrowText}
            </Text>
          )}
          {heading && (
            <RichText propertyName="Heading" content={heading} className={lineOneTheme} />
          )}
          {heading2 && (
            <RichText propertyName="Heading2" content={heading2} className={lineTwoTheme} />
          )}
          {heading3 && (
            <RichText propertyName="Heading3" content={heading3} className={lineThreeTheme} />
          )}
        </article>

        {copy && (
          <RichText propertyName="Copy" content={copy} className="heading-5-regular text-white" />
        )}

        <div className={contentClasses}>
          {backgroundVideo && !videoError && (
            <div className="relative z-1 size-fit overflow-hidden rounded-full before:absolute before:inset-0 before:z-1 before:size-full before:bg-white before:opacity-20 before:blur">
              <button
                onClick={handlePlayPause}
                className="relative z-1 flex gap-2 px-[23px] py-2 text-white"
              >
                <Icon name={isPlaying ? 'pause' : 'play'} size="large" className="text-white" />
                {isPlaying ? 'Pause' : 'Play'}
              </button>
            </div>
          )}

          <div className={ctaAreaClasses}>
            {showCtaArea && (
              <div className="flex flex-row flex-wrap gap-4">
                {startTrialBlockProps && (
                  <StartTrialModalBlock
                    {...startTrialBlockProps}
                    triggerButton={
                      <Button
                        size="large"
                        label={startTrialBlockProps.heading}
                        color={trialLinkStyle === LinkStyle.Primary ? 'white' : 'outline-white'}
                      />
                    }
                  />
                )}

                {requestAQuoteModalBlockProps && (
                  <RequestAQuoteModalBlock
                    {...requestAQuoteModalBlockProps}
                    triggerButton={
                      <Button
                        size="large"
                        color={quoteLinkStyle === LinkStyle.Primary ? 'white' : 'outline-white'}
                        label={requestAQuoteModalBlockProps.heading}
                      />
                    }
                  />
                )}

                {generalLink && (
                  <Button
                    size="large"
                    label={generalLink?.text}
                    href={generalLink?.href}
                    target={generalLink?.target}
                    title={generalLink?.title}
                    color={genericLinkStyle === LinkStyle.Primary ? 'white' : 'outline-white'}
                  />
                )}
              </div>
            )}

            {!disableShareLink && (
              <ShareButton
                shareUrl={window.location.href}
                title={pageName}
                theme={Theme.Dark}
                buttonClassname={showCtaArea ? '!py-0' : ''}
              />
            )}
          </div>
        </div>
      </div>
    </Block>
  );
};

export default ImpactHeaderBlock;
