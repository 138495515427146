import { TwoUpSlideBlock } from '@/@types/content';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Button, { ButtonProps } from '@/components/ui/Buttons/Button';
import { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';

import Image from '@/components/cms/Image';
import React from 'react';
import Text from '@/components/cms/Text';
import Icon from '@/components/ui/Icon';

const HomePageTwoUpSlideBlock: React.FC<TwoUpSlideBlock> = ({
  backGroundImage,
  itemVariant,
  headlineText,
  itemTheme,
  link,
  bodyText,
  ctaGradientColor = 'white',
  isRegistered,
  inPerson,
  contentAlignment = 'content-left'
}) => {
  const editMode = isEditOrPreviewMode();
  const isLargeScreen = useMediaQuery(ScreenSizeQueries.lg);
  const isExtraSmall = useMediaQuery(ScreenSizeQueries.xsOnly);
  let badgeText;
  let twoUpSlideComponent;

  const badgeClasses = classnames('text-2', {
    'text-black': itemTheme === 'dark',
    'text-white': itemTheme === 'light'
  });

  const headingClasses = classnames('heading-4 line-clamp-4 lg:heading-3 sm:min-h-16', {
    'text-black': itemTheme === 'dark',
    'text-white': itemTheme === 'light'
  });

  const copyClasses = classnames('text-1 line-clamp-3 max-lg:hidden', {
    'text-black': itemTheme === 'dark',
    'text-white': itemTheme === 'light'
  });

  const linkContainerClasses = classnames('pt-8');

  const CarouselItemClasses = classnames(
    'relative flex max-w-[100vw] grow flex-col lg:mx-auto lg:flex-row lg:items-start lg:pt-[128px]',
    {
      'bg-green-100': itemVariant === 'green',
      'bg-teal-100': itemVariant === 'teal',
      'bg-blue-100': itemVariant === 'blue',
      'bg-indigo-100': itemVariant === 'indigo',
      'bg-purple-100': itemVariant === 'purple',
      'bg-magenta-100': itemVariant === 'magenta'
    }
  );

  const contentLayoutClasses = classnames('flex flex-col justify-between lg:justify-start');

  const mediaLayoutClasses = classnames(
    'mx-auto size-full pb-[88px] pt-11 sm:pb-[112px] lg:mx-0 lg:size-fit lg:pb-20 lg:pt-9',
    {
      'lg:ml-4': contentAlignment === 'content-left',
      'lg:mr-4': contentAlignment === 'content-right'
    }
  );

  if (inPerson && isRegistered) {
    badgeText = 'Registered, In Person';
  } else if (inPerson) {
    badgeText = 'In Person';
  } else if (isRegistered) {
    badgeText = 'Registered';
  }

  const itemRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  useEffect(() => {
    const itemElement = itemRef.current;
    const parentElement = itemElement?.parentElement; // Accede al padre <swiper-slide>

    const observer = new MutationObserver(() => {
      if (parentElement?.classList.contains('swiper-slide-active')) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    });

    if (parentElement) {
      observer.observe(parentElement, {
        attributes: true, // Observar cambios en atributos
        attributeFilter: ['class'] // Solo observar cambios en clases
      });
    }

    return () => observer.disconnect(); // Limpiar el observer al desmontar
  }, []);

  const tabIndex = isActive ? 2 : -1;

  const contentLayout = (
    <div className={contentLayoutClasses} data-component={'HomePageTwoUpSlideBlock'}>
      <div className="flex flex-col gap-4 pt-[204px] sm:max-w-[75%] lg:w-[630px] lg:max-w-[630px] lg:pt-0">
        <div className="flex h-5 items-center gap-2">
          {isRegistered && <Icon size="medium" name="checkmark" className={badgeClasses} />}
          <Text className={badgeClasses}>{badgeText}</Text>
        </div>
        {(headlineText || editMode) && (
          <Text element="h2" propertyName="HeadlineText" className={headingClasses}>
            {headlineText}
          </Text>
        )}
        {(bodyText || editMode) && (
          <Text element="p" propertyName="BodyText" className={copyClasses}>
            {bodyText}
          </Text>
        )}
      </div>
      {link && (
        <div className={classnames(linkContainerClasses, 'hidden lg:block')}>
          {(link || editMode) && (
            <Button
              propertyName="Link"
              tabIndex={tabIndex}
              color={ctaGradientColor as ButtonProps['color']}
              href={link?.href}
              aria-label={link?.text}
              target={link?.target}
              label={link?.text}
              size="large"
            />
          )}
        </div>
      )}
    </div>
  );

  const mediaLayout = (
    <div className={mediaLayoutClasses}>
      {(backGroundImage || editMode) && (
        <div className="relative size-full">
          <Image
            className="aspect-video size-full min-h-[185px] rounded-lg object-cover sm:min-h-[405px] lg:h-[345px] lg:min-h-[345px] lg:max-w-[520px]"
            propertyName="BackGroundImage"
            src={backGroundImage?.url}
            alt={backGroundImage?.alt as string}
          />
        </div>
      )}
    </div>
  );

  if (isLargeScreen) {
    if (contentAlignment === 'content-left') {
      twoUpSlideComponent = (
        <>
          <div className="container flex flex-col justify-between lg:flex-row">
            {contentLayout}
            {mediaLayout}
          </div>
        </>
      );
    } else {
      twoUpSlideComponent = (
        <>
          <div className="container flex flex-col justify-between lg:flex-row">
            {mediaLayout}
            {contentLayout}
          </div>
        </>
      );
    }
  } else {
    twoUpSlideComponent = (
      <>
        <div className="container flex flex-col justify-between lg:flex-row">
          {contentLayout}
          {mediaLayout}
        </div>
      </>
    );
  }

  return (
    <div className={CarouselItemClasses} ref={itemRef}>
      {twoUpSlideComponent}
      {link && (
        <div className="absolute bottom-0 px-6 pb-6 pt-8 md:px-12 lg:hidden">
          {(link || editMode) && (
            <Button
              propertyName="Link"
              color={ctaGradientColor as ButtonProps['color']}
              href={link?.href}
              target={link?.target}
              label={link?.text}
              aria-label={link?.text}
              size={isExtraSmall ? 'small' : 'large'}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default HomePageTwoUpSlideBlock;
