import { useCallback, useMemo, useState } from 'react';
import PersonalInfo from '@/components/ui/PersonalInfo';
import IFramePanel from '@/components/ui/Panels/IFramePanel';
import { useAuth } from 'react-oidc-context';
import ProfileSectionWrapper from './ProfileSectionWrapper';
import PersonalInfoButton from '@/components/ui/PersonalInfoButton';
import { trackEditProfileEvent } from '@/analytics/profile';
import { ProfileSection } from '@/analytics/constants';

const changePasswordPageURL = window.env?.CONNECT_AUTHORITY + '/webappaccount/changepassword';

const LoginDetailsSection = (): JSX.Element => {
  const { user } = useAuth();

  const email = user?.profile.email;

  const [isEditing, setIsEditing] = useState(false);

  const subSections = useMemo(() => {
    return [
      { title: 'Business email', description: email ?? '-' },
      { title: 'Password', description: email ? '••••••••••••' : '-' }
    ];
  }, [email]);

  const handleOnEditClick = useCallback(() => {
    trackEditProfileEvent(ProfileSection.LoginDetails);
    setIsEditing(true);
  }, []);

  return (
    <ProfileSectionWrapper>
      <PersonalInfo
        sectionTitle="Login details"
        subSections={subSections}
        button={
          <IFramePanel
            isOpen={isEditing}
            onOpenChange={setIsEditing}
            url={changePasswordPageURL}
            triggerButton={<PersonalInfoButton onClick={handleOnEditClick} />}
          />
        }
      />
    </ProfileSectionWrapper>
  );
};

export default LoginDetailsSection;
