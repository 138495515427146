import { IContent } from '@/@types/cms';
import Block from '@/components/cms/Block';
import classnames from 'classnames';
import Text from '../cms/Text';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import Icon from '@/components/ui/Icon';
import * as Tabs from '@radix-ui/react-tabs';
import Divider from '../ui/Divider';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { AnalyticsContext } from '@/analytics/constants';
import { trackPdpDownloadContentsEvent, trackSearchContentsContentsEvent } from '@/analytics/pdp';

interface TocItem {
  title?: string;
  chapterNumber?: string;
  chapterOrder?: number;
  volumeNumber?: number;
  chapterType?: string;
  authors?: Record<string, unknown>[];
}

interface TabLogic {
  showExpanded: boolean;
  isExpanded: boolean;
}

interface BlockProps extends IContent {
  title?: string;
  searchPlaceholder?: string;
  searchBtnLabel?: string;
  searchUrl?: string;
  seeAllLabel?: string;
  seeLessLabel?: string;
  volumeLabel?: string;
  downloadLabel?: string;
  downloadLink?: string;
  itemAnalyticsModel?: ItemAnalyticsModel;
  context?: AnalyticsContext;
  tocItems?: TocItem[];
}

const PublicationTableOfContent: React.FC<BlockProps> = ({
  contentLink,
  title,
  searchPlaceholder,
  searchBtnLabel,
  searchUrl,
  seeAllLabel,
  seeLessLabel,
  volumeLabel,
  downloadLabel,
  itemAnalyticsModel,
  context,
  downloadLink,
  tocItems = []
}) => {
  const maxItemsToShow = 10;
  const tableContentId = 'content-table';

  const xsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);
  const lg = useMediaQuery(ScreenSizeQueries.lg);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [tabsLogic, setTabsLogic] = useState<TabLogic[]>([]);

  const groupedItems = useMemo(() => {
    return tocItems.reduce(
      (acc, item) => {
        const volumeNumber = item.volumeNumber || 0;
        if (!acc[volumeNumber]) {
          acc[volumeNumber] = [];
        }
        acc[volumeNumber].push(item);
        return acc;
      },
      {} as Record<number, TocItem[]>
    );
  }, [tocItems]);

  Object.keys(groupedItems).forEach(volumeNumber => {
    groupedItems[Number(volumeNumber)] = groupedItems[Number(volumeNumber)].sort(
      (a, b) => (a.chapterOrder || 0) - (b.chapterOrder || 0)
    );
  });

  useEffect(() => {
    const newTabsLogic = Object.keys(groupedItems).map(volumeNumber => ({
      showExpanded: groupedItems[Number(volumeNumber)].length > maxItemsToShow,
      isExpanded: false
    }));

    setTabsLogic(prevTabsLogic => {
      if (JSON.stringify(prevTabsLogic) !== JSON.stringify(newTabsLogic)) {
        return newTabsLogic;
      }
      return prevTabsLogic;
    });
  }, [groupedItems, maxItemsToShow]);

  const handleOpenSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setIsSearchOpen(true);
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    trackSearchContentsContentsEvent(context, itemAnalyticsModel);
    event?.preventDefault();
    const searchWithQuery = searchUrl?.replace(/%7B%7BQUERY%7D%7D|{{QUERY}}/g, searchValue);
    window?.open(searchWithQuery, '_blank');
  };

  const handleToggleExpand = (index: number) => {
    setTabsLogic(prevState => {
      const newTabsLogic = [...prevState];
      newTabsLogic[index].isExpanded = !newTabsLogic[index].isExpanded;
      return newTabsLogic;
    });
  };

  const getTabLogic = useCallback(
    (index: number) => {
      return tabsLogic[Number(index)];
    },
    [tabsLogic]
  );

  if (!tocItems || tocItems.length === 0) {
    return null;
  }

  return (
    <Block
      id={tableContentId}
      contentLinkID={contentLink?.id}
      className="container module-spacing-pdp--sm"
    >
      <div
        className={classnames(
          'flex flex-col gap-[30px]',
          'sm:flex-row sm:items-center sm:justify-between sm:gap-6'
        )}
      >
        <Text element="h5" propertyName="title" className="heading-5">
          {title}
        </Text>

        {searchUrl && (
          <div>
            {xsOnly || isSearchOpen ? (
              <form onSubmit={handleOnSubmit} className="relative flex items-center">
                <Icon
                  name="search"
                  size="large"
                  className={classnames(
                    'pointer-events-none absolute left-4',
                    'sm:left-4 sm:top-4'
                  )}
                />
                <input
                  type="text"
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  className={classnames(
                    'text-2 h-14 w-full rounded-full bg-gray-lightest pl-[60px] pr-[70px]',
                    'sm:w-[371px]',
                    'placeholder:text-gray-dark'
                  )}
                  placeholder={searchPlaceholder}
                  autoFocus
                />
                <button
                  type="submit"
                  disabled={!searchValue}
                  className={classnames(
                    'absolute right-4 flex items-center gap-1 transition-opacity',
                    'lg:gap-px',
                    'disabled:opacity-50'
                  )}
                >
                  <span className="text-2-bold">{searchBtnLabel}</span>
                  <Icon name="link-out" size="medium" />
                </button>
              </form>
            ) : (
              <button
                type="button"
                aria-label={searchPlaceholder}
                className={classnames(
                  'flex size-14 items-center justify-center rounded-full bg-gray-lightest transition-colors',
                  'hover:bg-gray-light'
                )}
                onClick={handleOpenSearch}
              >
                <Icon name="search" size="large" />
              </button>
            )}
          </div>
        )}
      </div>

      {Object.keys(groupedItems).length > 0 && (
        <Tabs.Root defaultValue="tabId-1" className="mt-10">
          {Object.keys(groupedItems).length > 1 && (
            <Tabs.List
              aria-label="tabs example"
              className={classnames(
                'relative mb-10 flex flex-nowrap overflow-x-auto whitespace-nowrap',
                'before:absolute before:bottom-0 before:left-0 before:-z-1 before:h-px before:w-full before:bg-silver before:content-[""]'
              )}
            >
              {Object.keys(groupedItems).map((volumeNumber, index) => (
                <Tabs.Trigger
                  key={index}
                  value={`tabId-${index + 1}`}
                  className={classnames(
                    'text-2-medium flex-zero-auto min-w-[84px] border-b border-b-silver pb-[13px] pr-4 text-left text-gray-dark transition-colors',
                    'focus:outline-offset-[-3px]',
                    'sm:min-w-[119px]',
                    'data-[state=active]:cursor-default data-[state=active]:border-b-black data-[state=active]:text-black',
                    'hover:text-black',
                    'last:pr-0'
                  )}
                >
                  {`${volumeLabel} ${volumeNumber}`}
                </Tabs.Trigger>
              ))}
            </Tabs.List>
          )}
          {Object.keys(groupedItems).map((volumeNumber, index) => (
            <Tabs.Content key={index} value={`tabId-${index + 1}`}>
              {groupedItems[Number(volumeNumber)].length > 0 && (
                <div className="flex flex-col gap-5">
                  {groupedItems[Number(volumeNumber)]
                    .slice(
                      0,
                      getTabLogic(index)?.isExpanded
                        ? groupedItems[Number(volumeNumber)].length
                        : maxItemsToShow
                    )
                    .map((item, index) => (
                      <div key={index}>
                        {item.chapterType?.toLowerCase() === 'part' ? (
                          <div
                            className={classnames({
                              'pt-5': index !== 0
                            })}
                          >
                            <Text element="span" className="text-1-medium">
                              {`${item?.chapterType || ''} ${item?.chapterNumber || ''}`.trim()}:{' '}
                              {item?.title}
                            </Text>
                            <Divider color="light" className="mt-[26px]" />
                          </div>
                        ) : (
                          <Fragment>
                            <div className={classnames('flex gap-[17px]', 'lg:gap-[30px]')}>
                              <Text
                                element="span"
                                className="text-2-medium w-20 min-w-20 text-gray"
                              >
                                {`${item?.chapterType || ''} ${item?.chapterNumber || ''}`.trim()}
                              </Text>
                              <Text element="span" className="text-2-medium">
                                {item?.title}
                              </Text>
                            </div>
                            <Divider color="light" className="mt-5" />
                          </Fragment>
                        )}
                      </div>
                    ))}
                </div>
              )}

              {getTabLogic(index) && getTabLogic(index).showExpanded && (
                <button
                  type="button"
                  onClick={() => handleToggleExpand(index)}
                  className={classnames(
                    'text-2-bold mt-5 flex items-center gap-1 text-black transition-colors',
                    'hover:text-red'
                  )}
                >
                  <span>{getTabLogic(index)?.isExpanded ? seeLessLabel : seeAllLabel}</span>
                  <Icon
                    name={getTabLogic(index)?.isExpanded ? 'chevron-up' : 'chevron-down'}
                    size={lg ? 'medium' : 'small'}
                  />
                </button>
              )}
            </Tabs.Content>
          ))}
        </Tabs.Root>
      )}

      {downloadLink && (
        <a
          href={downloadLink}
          target="_blank"
          onClick={() => trackPdpDownloadContentsEvent(context, itemAnalyticsModel)}
          className={classnames(
            'text-2-bold mt-11 inline-flex items-center gap-1 text-black transition-colors',
            'lg:mt-[52px]',
            'hover:text-red'
          )}
        >
          <span>{downloadLabel}</span>
          <Icon name="download" size={lg ? 'medium' : 'small'} />
        </a>
      )}
    </Block>
  );
};

export default PublicationTableOfContent;
