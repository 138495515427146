import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProgramCreditDetails from '@/components/ui/PDP/ProgramCredit/ProgramCreditDetails';
import { ProgramCreditLabels } from '@/components/ui/PDP/labels';
import { useGetProgramCreditsQuery } from '@/redux/api/client/cle';

import { markComponentAsLoaded, registerAsyncComponent, Variant } from '@/redux/slices/pdpSlice';
import {
  useGetLicenseData,
  useOnDeleteLicense,
  useOnSaveEditLicense,
  useOnSaveLicense
} from '@/components/ui/LicensesPage/hooks';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import { LoggedOutLicenseStruct } from '@/hooks/PDP/useLoggedOutLicenses';
import { useAuth } from 'react-oidc-context';
import { AnalyticsContext } from '@/analytics/constants';

interface IPDPProgramCreditLayoutBlockProps {
  variant: Variant;
  loggedOutLicense: LoggedOutLicenseStruct;
  isSegmentCreditDetail?: boolean;
  width?: number;
  context?: AnalyticsContext;
}

const IPDPProgramCreditLayoutBlock: React.FC<IPDPProgramCreditLayoutBlockProps> = ({
  variant,
  loggedOutLicense,
  isSegmentCreditDetail,
  width,
  context = AnalyticsContext.PDP
}) => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    if (!registered) {
      dispatch(registerAsyncComponent());
      dispatch(markComponentAsLoaded());
      setRegistered(true);
    }
  }, [dispatch, registered]);

  const [isProcessing, setIsProcessing] = useState(false);
  const handleOnSaveLicense = useOnSaveLicense({ setIsProcessing, context: AnalyticsContext.PDP });
  const handleOnSaveEditLicense = useOnSaveEditLicense({
    setIsProcessing,
    context: AnalyticsContext.PDP
  });
  const handleOnDeleteLicense = useOnDeleteLicense({
    setIsProcessing,
    context: AnalyticsContext.PDP
  });

  const {
    licenses,
    isLoading: isLicensesLoading,
    creditJurisdictions,
    availableCreditJurisdictions
  } = useGetLicenseData();

  const { data: credits, isLoading: isCreditsLoading } = useGetProgramCreditsQuery(variant.code!);

  const debouncedLoading = useDebounceLoading(
    isLicensesLoading || isCreditsLoading || isProcessing
  );

  return (
    <ProgramCreditDetails
      labels={ProgramCreditLabels}
      credits={credits?.jurisdictions ?? []}
      isLoggedOut={!auth.isAuthenticated}
      licenses={auth.isAuthenticated ? (licenses?.items ?? []) : loggedOutLicense.licenses}
      isLoading={debouncedLoading}
      handleOnSaveLicense={handleOnSaveLicense}
      handleOnSaveEditLicense={handleOnSaveEditLicense}
      handleOnDeleteLicense={handleOnDeleteLicense}
      handleLoggedOutAddLicense={loggedOutLicense.addLicense}
      handleLoggedOutRemoveLicense={loggedOutLicense.removeLicense}
      allCreditJurisdictions={creditJurisdictions}
      availableCreditJurisdictions={
        auth.isAuthenticated
          ? availableCreditJurisdictions
          : loggedOutLicense.availableCreditJurisdictions
      }
      isSegmentCreditDetail={isSegmentCreditDetail}
      width={width}
      code={variant.code!}
      context={context}
    />
  );
};

export default IPDPProgramCreditLayoutBlock;
