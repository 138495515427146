import { useCallback, useEffect, useMemo, useState } from 'react';
import EditPanel, { EditPanelProps } from '@/components/ui/Panels/EditPanel';
import { Address, AddressCategory, AddressCountry, AddressState } from '@/@types/client-api';
import { getTitle, isBillingAddress, isShippingAddress } from './utils';
import SelectSubPanel from '@/components/ui/Panels/SelectSubPanel';
import { SelectorItem } from '@/components/ui/Filter/types';
import TextInput from '@/components/ui/Form/TextInput';
import Checkbox from '@/components/ui/Form/Checkbox';
import { invalidZipFormat, isInvalidZipFormat } from '@/utils/validations';

const zipErrors = [invalidZipFormat()];

type AddressPanelProps = {
  data?: Address;
  isInitialAddress?: boolean;
  onSave?: (data: Address) => void;
  onDelete?: (data: Address) => void;
  addressCategoryLookup: AddressCategory[];
  addressStateLookup: AddressState[];
  addressCountryLookup: AddressCountry[];
} & Omit<EditPanelProps, 'children' | 'onSave' | 'onDelete' | 'title'>;

const AddressPanel: React.FC<AddressPanelProps> = ({
  isInitialAddress,
  isOpen,
  onOpenChange,
  theme,
  data,
  onSave,
  onDelete,
  addressCategoryLookup,
  addressStateLookup,
  addressCountryLookup,
  triggerButton
}) => {
  const [addressCategory, setAddressCategory] = useState<string>('');
  const [attention, setAttention] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [address1, setAddress1] = useState<string>('');
  const [address2, setAddress2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [addressState, setAddressState] = useState<string>('');
  const [addressCountry, setAddressCountry] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [billingAddress, setBillingAddress] = useState<boolean>(false);
  const [shippingAddress, setShippingAddress] = useState<boolean>(false);

  const isBillingOrShippingAddress =
    isInitialAddress || isBillingAddress(data) || isShippingAddress(data);

  const isUnitedStatesOrCanada = ['USA', 'CAN'].includes(addressCountry.toUpperCase());
  const isUnitedStates = addressCountry.toUpperCase() === 'USA';

  const toggleBillingAddress = useCallback(() => setBillingAddress(state => !state), []);

  const toggleShippingAddress = useCallback(() => setShippingAddress(state => !state), []);

  useEffect(() => {
    if (isOpen) {
      setAddressCategory(data?.addressCategory_SK?.toString() ?? '');
      setAttention(data?.attention ?? '');
      setCompany(data?.company ?? '');
      setAddress1(data?.address1 ?? '');
      setAddress2(data?.address2 ?? '');
      setCity(data?.city ?? '');
      setAddressState(data?.state ?? '');
      setAddressCountry(data?.country ?? 'USA'); // default to USA
      setZip(data?.zip ?? '');
      setBillingAddress(isInitialAddress || !!data?.isPrimaryBAddress);
      setShippingAddress(isInitialAddress || !!data?.isPrimarySAddress);
    }
  }, [data, isInitialAddress, isOpen]);

  const handleOnClose = useCallback(() => {
    onOpenChange?.(false);
  }, [onOpenChange]);

  const handleOnDelete = useCallback(() => {
    if (data) {
      handleOnClose();
      onDelete?.(data);
    }
  }, [data, handleOnClose, onDelete]);

  const handleValidateFields = useCallback(
    (): boolean =>
      !!addressCategory &&
      !!address1 &&
      !!city &&
      (!isUnitedStatesOrCanada || !!addressState) &&
      (!isUnitedStates || !isInvalidZipFormat(zip)) &&
      !!addressCountry,
    [
      address1,
      addressCategory,
      addressCountry,
      addressState,
      city,
      isUnitedStates,
      isUnitedStatesOrCanada,
      zip
    ]
  );

  const handleOnSave = useCallback(() => {
    handleOnClose();
    onSave?.({
      sk: data?.sk,
      addressCategory_SK: parseInt(addressCategory),
      attention,
      company,
      address1,
      address2,
      city,
      state: isUnitedStatesOrCanada ? addressState : undefined,
      zip: isUnitedStates ? zip.split('-')[0] : zip,
      plus4: isUnitedStates ? zip.split('-')[1] : undefined,
      country: addressCountry,
      isPrimaryBAddress: billingAddress,
      isPrimarySAddress: shippingAddress
    });
  }, [
    address1,
    address2,
    addressCategory,
    addressCountry,
    addressState,
    attention,
    billingAddress,
    city,
    company,
    data?.sk,
    handleOnClose,
    isUnitedStates,
    isUnitedStatesOrCanada,
    onSave,
    shippingAddress,
    zip
  ]);

  const addressCategories = useMemo(
    () =>
      addressCategoryLookup.map(
        (item): SelectorItem => ({
          value: item.sk?.toString() ?? '',
          label: item.shortDescription ?? ''
        })
      ),
    [addressCategoryLookup]
  );

  const addressStates = useMemo(() => {
    const countryStates = addressCountry
      ? addressStateLookup.filter(item => item.countryId === addressCountry)
      : addressStateLookup;
    const filteredStates = countryStates.filter(item => item.pk !== '0');
    return filteredStates.map(
      (item): SelectorItem => ({
        value: item.pk ?? '',
        label: item.stateName ?? ''
      })
    );
  }, [addressCountry, addressStateLookup]);

  const addressCountries = useMemo(
    () =>
      addressCountryLookup.map(
        (item): SelectorItem => ({
          value: item.pk ?? '',
          label: item.countryName ?? ''
        })
      ),
    [addressCountryLookup]
  );

  return (
    <EditPanel
      title={getTitle(addressCategoryLookup, data)}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      theme={theme}
      onSave={handleOnSave}
      onValidateFields={handleValidateFields}
      onDelete={data && !isBillingOrShippingAddress ? handleOnDelete : undefined}
      triggerButton={triggerButton}
    >
      <SelectSubPanel
        onClosePanel={handleOnClose}
        items={addressCategories}
        label="Type"
        selectedItem={addressCategory}
        onSelectedItemChange={setAddressCategory}
        required
      />
      <TextInput
        label="Attention"
        value={attention}
        onChange={setAttention}
        maxLength={44}
        acceptNumbers={false}
        acceptSpecialChars={false}
      />
      <TextInput label="Company" value={company} onChange={setCompany} maxLength={100} />
      <TextInput label="Address" value={address1} onChange={setAddress1} maxLength={75} required />
      <TextInput label="Address 2" value={address2} onChange={setAddress2} maxLength={75} />
      <TextInput label="City" value={city} onChange={setCity} maxLength={40} required />
      <SelectSubPanel
        onClosePanel={handleOnClose}
        items={addressStates}
        label="State"
        selectedItem={addressState}
        onSelectedItemChange={setAddressState}
        required={isUnitedStatesOrCanada}
        disabled={!!addressCountry && !isUnitedStatesOrCanada}
      />
      <TextInput
        label="ZIP/Postal code"
        value={zip}
        onChange={setZip}
        maxLength={10}
        required={isUnitedStatesOrCanada}
        acceptLetters={true}
        acceptSpaces={true}
        acceptSpecialChars={true}
        errors={isUnitedStates ? zipErrors : []}
      />
      <SelectSubPanel
        onClosePanel={handleOnClose}
        items={addressCountries}
        label="Country"
        selectedItem={addressCountry}
        onSelectedItemChange={setAddressCountry}
        required
      />
      <div className="flex flex-col gap-6">
        <Checkbox
          checked={billingAddress}
          disabled={isInitialAddress || data?.isPrimaryBAddress}
          onCheckedChange={toggleBillingAddress}
        >
          <Checkbox.Label>Default Billing Address</Checkbox.Label>
        </Checkbox>
        <Checkbox
          checked={shippingAddress}
          disabled={isInitialAddress || data?.isPrimarySAddress}
          onCheckedChange={toggleShippingAddress}
        >
          <Checkbox.Label>Default Shipping Address</Checkbox.Label>
        </Checkbox>
      </div>
    </EditPanel>
  );
};

export default AddressPanel;
