import { MouseEvent } from 'react';
import type {
  SnippetProps,
  SnippetPrimaryStatus,
  SnippetButtonProps
} from '@/components/ui/Snippet/Snippet';
import type { SnippetExpandedContentProps } from '@/components/ui/Snippet/SnippetExpandedContent';
import Link from '@/components/ui/Link';
import {
  SnippetMetadataIcon,
  type SnippetMetadataProps
} from '@/components/ui/Snippet/SnippetMetadata';
import IconMessage from '@/components/ui/IconMessage';

import {
  getThumbnailImageUrl,
  mapPublicationEyebrows,
  aggregateUserData,
  getIsSavedToLibrary,
  getInlineListProps,
  snippetToAnalyticsContext
} from './helpers';
import type { RenderSnippetProps } from './types';
import { renderEcommPanelButtonWrapper } from './buttons';
import { SnippetButtonLabel } from './buttonsLabels';
import { transformIfPLIUrl } from '@/utils/helpers';
import type { Variant } from '@/redux/slices/pdpSlice';
import { isPrintFormat } from '@/components/ui/PDP/helpers';
import { trackReadNowEvent } from '@/analytics/library';

const renderPublication = ({
  data,
  actions,
  context,
  modalPanelState,
  actionStatus,
  links,
  itemAnalyticsModel
}: RenderSnippetProps): SnippetProps => {
  const {
    authors,
    title = '',
    lastUpdatedOnLabel,
    datesFormatted,
    summaryText,
    practiceAreas,
    industries,
    plusUrl,
    variationPk,
    url,
    userList = [],
    formats,
    parentProductLink,
    parentProductLinkText,
    isPrerelease: _isPrerelease,
    publicationType
  } = data;

  const trackReadNowAnalyticsEvent = () => {
    const analyticsContext = snippetToAnalyticsContext(context);
    if (itemAnalyticsModel && analyticsContext) {
      trackReadNowEvent(itemAnalyticsModel, analyticsContext);
    }
  };

  const eyebrows = context === 'search' ? (formats as string[]) : [publicationType ?? ''];
  const metadata: SnippetMetadataProps = { items: [] };

  if (context === 'library') {
    if (parentProductLink && parentProductLinkText) {
      const href = transformIfPLIUrl(parentProductLink);
      metadata.items.push(
        <span className="inline-flex">
          from&nbsp;
          <Link as="a" href={href} text={parentProductLinkText} key={parentProductLinkText} />
        </span>
      );
    }
  }
  if (datesFormatted) {
    if (context === 'search' && _isPrerelease) {
      metadata.items.push('Publication Date: Coming Soon');
    } else {
      metadata.items.push(`${lastUpdatedOnLabel || 'Published on'} ${datesFormatted}`);
    }
  }

  const userAggregatedData = aggregateUserData({ userList, variant: 'book' });

  /* Has to be determined separately from userAggregatedData.isPurchaseRequired because the latter also factors in the user's PLUS subscription */
  const isRetailPurchase = userAggregatedData?.isPurchased;
  const isPurchaseRequired =
    userAggregatedData?.isPurchaseRequired || (context === 'search' && _isPrerelease);

  if (isRetailPurchase) {
    metadata.items.push(
      <>
        Purchased <SnippetMetadataIcon name="checkmark" />
      </>
    );
  }

  const expandedContent: SnippetExpandedContentProps = { data: [], expandLink: url || '' };

  if (summaryText) {
    expandedContent.data.push({
      title: '',
      content: summaryText
    });
  } else if (
    !summaryText &&
    eyebrows.some(eyebrow => eyebrow.toLowerCase().includes('course handbooks'))
  ) {
    expandedContent.data.push({
      title: 'Overview',
      content: [
        parentProductLinkText
          ? `<p>This Course Handbook was compiled for the program, ${parentProductLinkText}.</p>`
          : null,
        `<p>PLI's nationally acclaimed course handbooks represent the definitive thinking of the nation's finest legal minds on timely topics and are considered the standard reference in the field. This course handbook is prepared specifically for the program and stands alone as a permanent reference.</p>`
      ].join('')
    });
  }

  if (authors?.length) {
    expandedContent.data.push({
      title: 'Authors',
      inlineList: authors.map(author => <Link {...getInlineListProps(author)} />)
    });
  }

  if (practiceAreas?.length) {
    expandedContent.data.push({
      title: 'Topics',
      inlineList: practiceAreas.map(practiceArea => (
        /* Ideally, if href is empty, the link should be omitted */
        <Link {...getInlineListProps(practiceArea)} />
      ))
    });
  }

  if (industries?.length) {
    expandedContent.data.push({
      title: 'Industries',
      inlineList: industries.map(industry => (
        /* Ideally, if href is empty, the link should be omitted */
        <Link {...getInlineListProps(industry)} />
      ))
    });
  }

  let userPrimaryStatus: SnippetPrimaryStatus | null = 'not-purchased';

  if (!isPurchaseRequired) {
    userPrimaryStatus = null;
  }

  const thumbnail = {
    icon: {
      name: isPurchaseRequired ? 'cart' : 'link-out',
      label: isPurchaseRequired ? 'Add to cart' : SnippetButtonLabel.readOnPLUS
    },
    image: {
      url: getThumbnailImageUrl(variationPk),
      alt: title || ''
    },
    link: {
      url: !isPurchaseRequired && plusUrl ? plusUrl : undefined,
      target: '_blank',
      onClick: !isPurchaseRequired && plusUrl ? trackReadNowAnalyticsEvent : undefined
    },
    onClick: isPurchaseRequired ? actions.buy : undefined,
    renderButtonWrapper: isPurchaseRequired
      ? renderEcommPanelButtonWrapper(context, data, 'BuyNow')
      : undefined
  };

  let subscriptionExpirationWarning;
  if (userAggregatedData?.isPlusExpirationSoon && userAggregatedData?.plusExpirationDateFormatted) {
    subscriptionExpirationWarning = (
      <IconMessage
        text={`Your current subscription expires ${userAggregatedData?.plusExpirationDateFormatted}. Renew now to avoid interruption in access.`}
        iconName="pending"
      />
    );
  }

  const onSaveToLibrary = actions.addToLibrary;
  const onRemoveFromLibrary = (e?: MouseEvent<HTMLButtonElement>) => {
    const siblingVariantPks =
      context === 'search'
        ? userList?.filter(getIsSavedToLibrary).map(u => u.pk ?? '')
        : [variationPk ?? ''];
    actions.removeFromLibrary.bind(e, siblingVariantPks)();
  };

  const buttons: SnippetButtonProps[] = [
    {
      label: isPurchaseRequired ? SnippetButtonLabel.buyNow : SnippetButtonLabel.readOnPLUS,
      color: 'black',
      size: 'short',
      iconRight: isPurchaseRequired ? 'chevron-right' : 'link-out',
      onClick: isPurchaseRequired ? actions.buy : trackReadNowAnalyticsEvent,
      ...(isPurchaseRequired
        ? {
            renderButtonWrapper: renderEcommPanelButtonWrapper(context, data, 'BuyNow')
          }
        : {
            href: plusUrl ?? undefined,
            target: '_blank'
          })
    }
  ];

  if (context === 'search') {
    buttons.push({
      label: userAggregatedData?.isSavedToLibrary
        ? SnippetButtonLabel.savedToLibrary
        : SnippetButtonLabel.saveToLibrary,
      iconRight: userAggregatedData?.isSavedToLibrary ? 'check' : 'add',
      color: 'outline-black',
      size: 'short',
      onClick: userAggregatedData?.isSavedToLibrary ? onRemoveFromLibrary : onSaveToLibrary,
      actionStatus
    });
  } else if (context === 'library') {
    if (
      !isPurchaseRequired &&
      formats?.find(
        f => typeof f === 'object' && 'name' in f && f.name !== 'singletitlesubscription'
      )
    ) {
      buttons.push({
        label: 'Other buying options',
        iconRight: 'chevron-right',
        color: 'outline-black',
        size: 'short',
        onClick: actions.buy,
        renderButtonWrapper: renderEcommPanelButtonWrapper(
          context,
          data,
          'BuyNow',
          (variant: Variant): boolean => isPrintFormat(variant.format)
        )
      });
    }
  }

  const menuItems = [];
  if (context === 'library') {
    if (isRetailPurchase) {
      menuItems.push({
        label: 'Order details',
        href: `${links.Profile}/order-history`,
        target: '_blank',
        onClick: () => {},
        icon: 'info',
        menuStyle: 'standalone' as const
      });
    }
    menuItems.push({
      label: SnippetButtonLabel.removeFromLibrary,
      onClick: () => {
        modalPanelState.deleteModal.show({ onConfirm: onRemoveFromLibrary });
      },
      icon: 'delete',
      menuStyle: 'standalone' as const
    });
  }

  return {
    variant: 'publication',
    pk: variationPk ?? '',
    title: title || '',
    metadata,
    expandedContent,
    thumbnail,
    buttons,
    menuItems,
    link: url || '',
    primaryStatus: userPrimaryStatus ?? undefined,
    secondaryStatus: userAggregatedData?.isSavedToLibrary ? 'saved-to-library' : null,
    content: subscriptionExpirationWarning,
    eyebrows: mapPublicationEyebrows(eyebrows)
  };
};

export default renderPublication;
