import { useEffect, useMemo } from 'react';
import { useGetUserFacultiesQuery } from '@/redux/api/client/faculty';
import { PersonalInfoFacultyBlock as PersonalInfoFacultyProps } from '@/@types/content';
import { getProfileLinkInfo } from '@/utils/getProfileLinkInfo';
import LinkGridBlock from '@/components/blocks/LinkGridBlock/LinkGridBlock';
import LinkGridFacultyShimmer from '@/components/ui/ProfileShimmers/LinkGridFacultyShimmer';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import useToast from '@/hooks/useToast';
import { IComponentProps } from '@/lib/ComponentFactory';
import useNudge from '@/hooks/shared/useNudge';
import { useSelector } from 'react-redux';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { useLocation } from 'react-router-dom';
import { NudgeAnalyticsContext } from '@/analytics/constants';
import { trackViewFacultyEvent } from '@/analytics/profile';
import { ProfileNudgeSectionNames } from '@/hooks/shared/useNudge/utils';
import { useAuth } from 'react-oidc-context';

const PersonalInfoFacultySection: React.FC<PersonalInfoFacultyProps & IComponentProps> = ({
  messages,
  timeUntilStale,
  index,
  ...props
}) => {
  const auth = useAuth();
  const { data, isError, isLoading } = useGetUserFacultiesQuery('' as unknown as void, {
    skip: !auth.isAuthenticated
  });
  const facultiesData = useMemo(() => getProfileLinkInfo(data), [data]);
  const debouncedLoading = useDebounceLoading(isLoading);
  const { showFailMessage } = useToast();

  const pageLinkPaths = useSelector(selectPageLinkPaths);
  const { pathname } = useLocation();

  const isProfilePage = pageLinkPaths.Profile && pathname.startsWith(pageLinkPaths.Profile);

  useEffect(() => {
    if (isError) {
      showFailMessage('Something went wrong while loading the people you follow');
    }
  }, [isError, showFailMessage]);

  const nudge = useNudge({
    messagesJSON: messages,
    sectionData: data,
    sectionName: ProfileNudgeSectionNames.Faculties,
    context: NudgeAnalyticsContext.Faculty,
    sectionIndex: index,
    timeUntilStale
  });

  if (isError || (!data?.items?.length && !isProfilePage)) {
    return null;
  }

  if (debouncedLoading) return <LinkGridFacultyShimmer />;

  return (
    <LinkGridBlock
      {...facultiesData}
      {...props}
      elementType="h3"
      nudge={isProfilePage ? nudge : undefined}
      onTrackItemViewEvent={trackViewFacultyEvent}
    />
  );
};

export default PersonalInfoFacultySection;
