import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';
import paths from '@/routes/paths';
import { FetchArgs } from '@reduxjs/toolkit/query';
import { baseQueryExtraOptions } from '@/redux/api';

const oidcConfig: AuthProviderProps = {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: window.env?.CONNECT_AUTHORITY,
  client_id: window.env?.CONNECT_CLIENT_ID,
  client_secret: window.env?.CONNECT_CLIENT_SECRET,
  response_type: 'code',
  scope: 'openid client_id profile roles email pli_profile offline_access promotions content.api',
  redirect_uri: `${window.location.origin}${paths.OIDC_SIGNIN}`,
  //silent_redirect_uri: `${window.location.origin}/signin-oidc-silent`,
  post_logout_redirect_uri: `${window.location.origin}${paths.OIDC_POST_SIGNOUT_CALLBACK}`,
  silentRequestTimeoutInSeconds: 60,
  automaticSilentRenew: true,
  loadUserInfo: true,
  validateSubOnSilentRenew: true,
  monitorAnonymousSession: true,
  filterProtocolClaims: true,
  revokeTokensOnSignout: true
};

export interface OIDCState {
  returnUrl: string;
  arg: string | FetchArgs | undefined;
  endpoint: string | undefined;
  extraOptions?: baseQueryExtraOptions;
}
export const getOIDCState = () => {
  const x: OIDCState = { returnUrl: window.location.pathname, arg: undefined, endpoint: undefined };
  return x;
};
export const getOIDCStateAction = (
  arg: string | FetchArgs,
  endpoint: string,
  extraOptions?: baseQueryExtraOptions
) => {
  const x: OIDCState = {
    returnUrl: window.location.pathname,
    arg: arg,
    endpoint: endpoint,
    extraOptions: extraOptions
  };
  return x;
};
export const getSingoutOIDCState = (securedRoutes: string[]) => {
  const x: OIDCState = {
    returnUrl: securedRoutes.some(x => window.location.pathname.includes(x))
      ? '/'
      : window.location.pathname,
    arg: undefined,
    endpoint: undefined
  };
  return x;
};
export const userManager: UserManager = new UserManager(oidcConfig as UserManagerSettings);
export default oidcConfig;
