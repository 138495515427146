import type { LibraryPage as LibraryPageProps, SiteSettings } from '@/@types/content';
import { ItemAnalyticsModel, LibraryItem } from '@/@types/client-api';
import withNav from '../hocs/withNav';
import { useDispatch, useSelector } from 'react-redux';
import { selectLibrary } from '@/redux/selectors/librarySelectors';
import useLibrary from '../ui/SearchResults/hooks/useLibrary';
import SearchResultsComponent from '../ui/SearchResults/SearchResultsComponent';
import LibraryRow from '../ui/SearchResults/LibraryRow';
import { useCallback, useEffect, useMemo } from 'react';
import { ContentTab } from '../ui/ContentTabs';
import ContentArea from '../cms/ContentArea';
import { clearLibraryFilters, librarySort, toggleLibraryFilter } from '@/redux/slices/librarySlice';
import {
  DEFAULT_SORT,
  LIBRARY_FILTERS,
  LIBRARY_SORT_OPTIONS,
  LibraryFilterKey
} from '@/utils/libraryConstants';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import Divider from '../ui/Divider';
import classnames from 'classnames';
import { trackClearAllFiltersEvent, trackSortEvent } from '@/analytics/library';
import { AnalyticsContext, FilterContext } from '@/analytics/constants';
import { trackViewItemListEvent } from '@/analytics/ecommerce';
import { getUID } from '@/analytics/analyticsUtils';

const keyFn = (input: LibraryItem) => input.pk ?? '';

const MAX_SPARSE_STATE_ITEMS = 5;

const LibraryPageComponent: React.FC<LibraryPageProps & SiteSettings> = ({
  mainContentArea,
  emptyStateHeading,
  emptyStateSubHeading,
  emptyStateFilterAppliedHeading,
  emptyStateFilterAppliedSubHeading,
  sparseStateContent,
  pageDescription,
  metaTitle
}) => {
  const { onLoadMore, isLoadingMore, refetchLibrary } = useLibrary();
  const { data, loading, fetching, tabProps, filters, quickFilters } = useSelector(selectLibrary);
  const dispatch = useDispatch();

  const remainingResultsCount = (data?.summary?.count ?? 0) - (data?.items?.length || 0);

  const onSortChange = useCallback(
    (value?: string) => {
      trackSortEvent(value);
      dispatch(librarySort(value || ''));
    },
    [dispatch]
  );

  const onTabChange = useCallback(
    (tab: ContentTab) => {
      dispatch(
        toggleLibraryFilter({
          filterId: LIBRARY_FILTERS.GROUP_SEARCHABLE_TYPE,
          value: tab.value
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (data?.items && data?.items?.length > 0) {
      const title = 'My Library';
      trackViewItemListEvent(
        `${getUID()}_${metaTitle || title}`.toLowerCase().replace(/\s+/g, '_'),
        `${pageDescription || title}`,
        data?.items
          .map(x => x.itemAnalyticsModel)
          .filter((x): x is ItemAnalyticsModel => x !== undefined) || []
      );
    }
  }, [data?.items, pageDescription, metaTitle]);

  const handleValueChange = (filterId: string, value: string, context: FilterContext) => {
    dispatch(toggleLibraryFilter({ filterId: filterId as LibraryFilterKey, value, context }));
  };

  const handleValueChangeQuickFilter = (filterId: string, value: string) =>
    handleValueChange(filterId, value, FilterContext.QuickFilter);

  const filtersChangingMapping = filters?.map(item => ({
    ...item,
    onValueChange: (value?: string) =>
      handleValueChange(item.groupTypeId, value || '', FilterContext.AllFiltersPanel)
  }));

  const clearFiltersAction = (payload: { groupId?: string } = {}) => {
    trackClearAllFiltersEvent({ facetGroup: payload.groupId ?? '' });
    dispatch(clearLibraryFilters(payload));
  };

  const debouncedLoading = useDebounceLoading(loading);
  const debouncedFetching = useDebounceLoading(fetching);
  const debouncedLoadingMore = useDebounceLoading(isLoadingMore);

  const hasSparseState =
    (data?.summary?.totalCollectionSize || 0) <= MAX_SPARSE_STATE_ITEMS && !loading;
  const hasNoResults = data?.items?.length === 0;

  const { emptyStateHeadingText, emptyStateSubHeadingText } = useMemo(
    () => ({
      emptyStateHeadingText: data?.summary?.filtersApplied
        ? emptyStateFilterAppliedHeading
        : emptyStateHeading,
      emptyStateSubHeadingText: data?.summary?.filtersApplied
        ? emptyStateFilterAppliedSubHeading
        : emptyStateSubHeading
    }),
    [
      data?.summary?.filtersApplied,
      emptyStateFilterAppliedHeading,
      emptyStateFilterAppliedSubHeading,
      emptyStateHeading,
      emptyStateSubHeading
    ]
  );

  return (
    <>
      <ContentArea propertyName="StandardBlackHeaderBlock" components={mainContentArea} />
      <SearchResultsComponent
        loading={debouncedLoading}
        fetching={debouncedFetching}
        isLoadingMore={debouncedLoadingMore}
        items={data?.items || []}
        keyFn={keyFn}
        RowComponent={LibraryRow}
        refetchData={refetchLibrary}
        tabs={tabProps.tabs}
        activeTab={tabProps.activeTab}
        onTabChange={onTabChange}
        bottomContent={
          hasSparseState && (
            <>
              <div className={classnames('container', { 'mt-12 lg:mt-10': hasNoResults })}>
                <Divider color="light" />
              </div>
              <ContentArea components={sparseStateContent} />
            </>
          )
        }
        sortOptions={LIBRARY_SORT_OPTIONS}
        sortValue={data?.summary?.filtersApplied?.sort || DEFAULT_SORT}
        onSortChange={onSortChange}
        quickFilters={quickFilters}
        filters={filtersChangingMapping}
        context={AnalyticsContext.Library}
        resultCount={0}
        remainingResultsCount={remainingResultsCount}
        onLoadMore={onLoadMore}
        onClear={clearFiltersAction}
        onFilterToggle={handleValueChangeQuickFilter}
        emptyStateHeading={!loading ? emptyStateHeadingText : undefined}
        emptyStateSubHeading={!loading ? emptyStateSubHeadingText : undefined}
      />
    </>
  );
};

const LibraryPage = withNav(LibraryPageComponent);
export default LibraryPage;
