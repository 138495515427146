import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchEventParams } from '../search';
import { selectLastSearchParams, selectSearchTermHistory } from '@/redux/selectors/searchSelectors';
import { trackEvent } from '../utils';
import { AnalyticsEventName } from '../constants';
import { addSearchTermToHistory, setLastSearchParams } from '@/redux/slices/searchSlice';

const paramsToStr = (params: Record<string, string>): string =>
  Object.entries(params)
    .sort(([key1], [key2]) => key1.localeCompare(key2))
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

const useViewSearchResultsEvent = () => {
  const dispatch = useDispatch();
  const searchTermHistory = useSelector(selectSearchTermHistory);
  const lastSearchParams = useSelector(selectLastSearchParams);

  const trackViewSerachResultsEvent = useCallback(
    (searchParams: URLSearchParams) => {
      const params = getSearchEventParams(searchParams);
      if (!params) {
        return;
      }
      /* Checking if the event has already been sent for these parameters. 
         This avoids sending duplicate events in case the component re-renders. */
      const paramsStr = paramsToStr(params);
      if (lastSearchParams === paramsStr) {
        return;
      }
      dispatch(setLastSearchParams(paramsStr));
      //Sending the event and adding the search term to the history
      const searchTerm = params.search_term.toLowerCase();
      const isUniqueSearchTerm = !searchTermHistory.includes(searchTerm);
      params['unique_search_term'] = isUniqueSearchTerm ? '1' : '0';
      trackEvent({ eventName: AnalyticsEventName.ViewSearchResults, parameters: params });
      if (isUniqueSearchTerm) {
        dispatch(addSearchTermToHistory(searchTerm));
      }
    },
    [dispatch, lastSearchParams, searchTermHistory]
  );

  return trackViewSerachResultsEvent;
};

export default useViewSearchResultsEvent;
