import { UserLicense } from '@/@types/client-api';
import useNudge, { defaultSortKey, UseNudgeProps } from '@/hooks/shared/useNudge';
import {
  getNudgeKey,
  isMissingBlockLevelNudge,
  isMissingFieldLevelNudge,
  ProfileNudgeSectionNames
} from '@/hooks/shared/useNudge/utils';
import { getLicenseKey, LicenseNumberSettings } from '../../SearchModals/utils/licenses';
import { removeCookie } from '@/utils/cookie-list-values';
import { MissingBlockLevelNudge, MissingFieldLevelNudge } from '@/hooks/shared/useNudge/types';
import { NudgeAnalyticsContext } from '@/analytics/constants';

const LicenseNumberField = 'stateLicenseNumber';
const LicenseComplianceGroupField = 'complianceGroup_SK';

const getLicenseSectionName = (license: UserLicense) =>
  `${ProfileNudgeSectionNames.License}-${getLicenseKey(license)}`;

export const deleteLicenseNudgeCookies = (license: UserLicense) => {
  const sectionName = getLicenseSectionName(license);
  removeCookie(getNudgeKey(sectionName));
  removeCookie(getNudgeKey(sectionName, LicenseNumberField));
  removeCookie(getNudgeKey(sectionName, LicenseComplianceGroupField));
};

type UseLicenseNudgeProps = Omit<UseNudgeProps<UserLicense>, 'skipField' | 'sectionName'> & {
  licenseNumberSettings?: LicenseNumberSettings;
  hasComplianceGroup: boolean;
};

const useLicenseNudge = ({
  messagesJSON,
  sectionData,
  sectionIndex,
  onAccept,
  licenseNumberSettings,
  hasComplianceGroup
}: UseLicenseNudgeProps) => {
  const nudge = useNudge({
    messagesJSON,
    sectionData,
    sectionIndex,
    sectionName: sectionData ? getLicenseSectionName(sectionData) : '',
    context: NudgeAnalyticsContext.Licenses,
    onAccept,
    skipField: field =>
      (field === LicenseNumberField && !licenseNumberSettings?.isRequired) ||
      (field === LicenseComplianceGroupField && !hasComplianceGroup),
    sortKey: (a, b) => {
      if (
        (a.field === undefined || a.field === LicenseNumberField) &&
        b.field === LicenseComplianceGroupField
      ) {
        return -1;
      }
      if (
        a.field === LicenseComplianceGroupField &&
        (b.field === undefined || b.field === LicenseNumberField)
      ) {
        return 1;
      }
      return defaultSortKey(a, b);
    }
  });

  const sectionNudge = isMissingBlockLevelNudge(nudge)
    ? (nudge as MissingBlockLevelNudge)
    : undefined;

  const fieldNudge = isMissingFieldLevelNudge(nudge)
    ? (nudge as MissingFieldLevelNudge)
    : undefined;

  const licenseNumberNudge = fieldNudge?.field === LicenseNumberField ? fieldNudge : undefined;

  const complianceGroupNudge =
    fieldNudge?.field === LicenseComplianceGroupField ? nudge : undefined;

  return {
    sectionNudge,
    licenseNumberNudge,
    complianceGroupNudge
  };
};

export default useLicenseNudge;
