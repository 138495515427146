import Panel from '@/components/ui/Panel';
import classnames from 'classnames';
import ContentArea from '@/components/cms/ContentArea';
import Divider from '@/components/ui/Divider';
import Link from '@/components/ui/Link';
import { NavigationItemBlock } from '@/@types/content';
import { LinkItem } from '@/@types/cms';

enum Variant {
  Light = 'light',
  Dark = 'dark'
}

interface PanelAccountNavMobileProps {
  theme: `${Variant}`;
  isAccountNavOpen: boolean;
  setIsAccountNavOpen: (value: boolean) => void;
  accountNavClickHandler: () => void;
  secondaryNavData: NavigationItemBlock[];
  logoutLink?: LinkItem;
  onSignout?: () => void;
  focusVisibleClasses: string;
  memoizedAvatar: React.ReactNode;
  userAriaLabel?: string;
}

const PanelAccountNavMobile = ({
  theme,
  isAccountNavOpen,
  setIsAccountNavOpen,
  accountNavClickHandler,
  secondaryNavData,
  logoutLink,
  onSignout,
  focusVisibleClasses,
  memoizedAvatar,
  userAriaLabel
}: PanelAccountNavMobileProps) => {
  const btnLoginUserClasses = classnames(
    'relative',
    'after:pointer-events-none after:absolute after:inset-x-0 after:-bottom-7 after:hidden after:h-0 after:transition-all lg:after:content-[""]',
    'lg:after:block',
    {
      'hover:after:h-[3px] hover:after:bg-red': !isAccountNavOpen
    },
    {
      'after:h-[3px] after:bg-red': isAccountNavOpen
    },
    focusVisibleClasses
  );

  const panelMobileClasses = classnames('!max-w-[375px]');

  const panelMobileBodyClasses = classnames(
    'px-6 pb-8 pt-5',
    'before:relative before:-left-6 before:top-[-32px] before:block before:h-px before:w-[150%] before:content-[""]',
    {
      'before:bg-gray-light': theme === Variant.Light,
      'before:bg-gray-darker': theme === Variant.Dark
    }
  );

  const logoutLinkClasses = classnames({
    'text-gray-dark': theme === Variant.Light,
    'text-gray-medium': theme === Variant.Dark,
    'focus-visible:outline-black': theme === Variant.Light,
    'focus-visible:outline-white': theme === Variant.Dark
  });

  return (
    <Panel theme={theme} isOpen={isAccountNavOpen} onOpenChange={setIsAccountNavOpen}>
      <Panel.Trigger asChild>
        <button
          className={btnLoginUserClasses}
          onClick={accountNavClickHandler}
          aria-label={userAriaLabel}
          data-component={"PanelAccountNavMobile-button"}
        >
          {memoizedAvatar}
        </button>
      </Panel.Trigger>

      <Panel.Content
        hasHorizontalPadding={false}
        side="left"
        className={panelMobileClasses}
        leftHeaderContent={memoizedAvatar}
      >
        <Panel.Body scrollClassName="mt-7" className="!px-0">
          <div className={classnames(panelMobileBodyClasses, 'before:top-6')}  data-component={"PanelAccountNavMobile-body"}>
            <ContentArea
              propertyName="SubnavItems"
              components={secondaryNavData}
              className="flex flex-col gap-8"
              componentsProps={{
                theme,
                divider: true
              }}
              element="div"
            />

            <div className="pt-8">
              <Divider
                color={theme}
                className={classnames({
                  'bg-gray-darker': theme === 'dark'
                })}
              />
              {logoutLink ? (
                <Link
                  as="button"
                  onClick={onSignout}
                  text={logoutLink.text as string}
                  title={logoutLink.title as string}
                  variant="text-link-2"
                  className={classnames(logoutLinkClasses, 'mt-8 inline-flex')}
                />
              ) : null}
            </div>
          </div>
        </Panel.Body>
      </Panel.Content>
    </Panel>
  );
};

export default PanelAccountNavMobile;
