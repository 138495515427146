import classnames from 'classnames';
import type { IconBlock } from '@/@types/content';
import Icon from '@/components/ui/Icon';
import { IComponentProps } from '@/lib/ComponentFactory';
import Block from '../cms/Block';

export type IconBlockProps = IconBlock;

const IconBlock: React.FC<IconBlockProps & IComponentProps> = ({
  contentLink,
  icon,
  copy,
  isLastItem
}) => {
  return (
    <Block
      element="div"
      contentLinkID={contentLink?.id}
      className={classnames('flex items-center gap-4 py-3', {
        'border-b border-gray-light': !isLastItem
      })}
      data-component={"IconBlock"}
    >
      {icon && <Icon name={icon} size="large" />}
      <p className="text-1 font-medium">{copy}</p>
    </Block>
  );
};

export default IconBlock;
