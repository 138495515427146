import SingleMonthCalendar from './SingleMonthCalendar';
import MultipleMonthCalendar from './MultipleMonthCalendar';
import { isMonthBetweenCalendarRange } from './CalendarRange';
import { useMemo, CSSProperties, forwardRef } from 'react';

export type DatePickerProps = {
  initialMonth?: Date;
  selectedDate?: Date;
  isMobile: boolean;
  onSelect?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  isStartDate?: boolean;
  isEndDate?: boolean;
  style?: CSSProperties;
};

const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
  ({ isMobile, initialMonth, isStartDate, isEndDate, ...props }, ref): JSX.Element => {
    const month = useMemo(() => {
      if (initialMonth && isMonthBetweenCalendarRange(initialMonth, props.minDate, props.maxDate)) {
        return initialMonth;
      }
      const currentMonth = new Date();
      if (isMonthBetweenCalendarRange(currentMonth, props.minDate, props.maxDate)) {
        return currentMonth;
      }
      if (isStartDate && props.minDate) {
        return props.minDate;
      }
      if (isEndDate && props.maxDate) {
        return props.maxDate;
      }
      return currentMonth;
    }, [initialMonth, isEndDate, isStartDate, props.maxDate, props.minDate]);

    if (isMobile) {
      return <MultipleMonthCalendar {...props} initialMonth={month} />;
    }
    return <SingleMonthCalendar {...props} ref={ref} initialMonth={month} />;
  }
);

export default DatePicker;
