import { UserCommunicationPreferences } from '@/@types/client-api';
import EditPanel, { EditPanelProps } from '@/components/ui/Panels/EditPanel';
import Toggle from '@/components/ui/Form/Toggle';
import { useCallback, useEffect, useState } from 'react';
import PhoneNumberInput from '@/components/ui/Form/PhoneNumberInput';
import { isValidPhoneNumber } from '@/utils/formHelpers';
import Link from '../Link';
import classnames from 'classnames';

const termsAndConditionsURL = 'https://www.pli.edu/terms-of-use';
const privacyPolicyURL = 'https://www.pli.edu/privacy-policy';
const emailToggleId = 'emails-toggle';
const smsToggleId = 'sms-toggle';

type PreferencesPanelProps = {
  data?: UserCommunicationPreferences;
  onSave?: (data: UserCommunicationPreferences) => void;
} & Pick<EditPanelProps, 'isOpen' | 'onOpenChange' | 'triggerButton'>;

const PreferencesPanel: React.FC<PreferencesPanelProps> = ({
  data,
  isOpen,
  onOpenChange,
  onSave,
  triggerButton
}) => {
  const [allowPromotionalMails, setAllowPromotionalMails] = useState(false);
  const [allowSMSContact, setAllowSMSContact] = useState(false);
  const [mobilePhone, setMobilePhone] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      const initialAllowSMSContact = !!data?.allowSMSContact;
      setAllowPromotionalMails(!!data?.allowPromotionalMails);
      setAllowSMSContact(initialAllowSMSContact);
      setMobilePhone(initialAllowSMSContact ? data?.mobilePhone || '' : '');
    }
  }, [data, isOpen]);

  const handleValidateFields = useCallback(
    () => (allowSMSContact ? isValidPhoneNumber(mobilePhone, true) : true),
    [allowSMSContact, mobilePhone]
  );

  const handleOnSave = useCallback(() => {
    onOpenChange?.(false);
    onSave?.({
      allowPromotionalMails,
      allowSMSContact,
      mobilePhone: allowSMSContact ? mobilePhone : undefined
    });
  }, [allowPromotionalMails, allowSMSContact, mobilePhone, onOpenChange, onSave]);

  const handleOnChangeAllowPromotionalMails = useCallback(() => {
    setAllowPromotionalMails(state => !state);
  }, []);

  const handleOnChangeAllowSMSContact = useCallback(() => {
    setAllowSMSContact(state => !state);
  }, []);

  return (
    <EditPanel
      title="Communication preferences"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onSave={handleOnSave}
      onValidateFields={handleValidateFields}
      triggerButton={triggerButton}
    >
      <div className="flex flex-col gap-y-10">
        {/* Personalized emails */}
        <div>
          <Toggle
            className="mb-3"
            variant="bw"
            checked={allowPromotionalMails}
            onCheckedChange={handleOnChangeAllowPromotionalMails}
            id={emailToggleId}
          />
          <label className="text-2 mb-2 block" htmlFor={emailToggleId}>
            Send me personalized emails
          </label>
          <p className="text-2 text-gray-dark">
            If checked, PLI will send emails about programs and content related to your profile
            preferences
          </p>
        </div>
        {/* SMS contact */}
        <div>
          <Toggle
            className="mb-3"
            variant="bw"
            checked={allowSMSContact}
            onCheckedChange={handleOnChangeAllowSMSContact}
            id={smsToggleId}
          />
          <label className="text-2 mb-2 block" htmlFor={smsToggleId}>
            Text me with account notifications and updates
          </label>
          <PhoneNumberInput
            className={classnames('my-8', { hidden: !allowSMSContact })}
            label="Phone number"
            value={mobilePhone}
            onChange={setMobilePhone}
            onlyUnitedStatesNumber={true}
            required
          />
          <p className="text-2 text-gray-dark">
            By entering my mobile number I agree to receive updates related to my PLI account and
            registrations/purchases. US phone number only. Message frequency varies. Text HELP to
            53631 for help, Text STOP to 53631 to end. Message and Data Rates May Apply. By opting
            in, I authorize Practising Law Institute to deliver SMS messages using an automatic
            telephone dialing system and I understand that I am not required to opt in as a
            condition of purchasing any property, goods, or services. By leaving this box unchecked
            you will not be opted in for SMS messages at this time. Click to read{' '}
            <Link
              href={termsAndConditionsURL}
              target="_blank"
              text="Terms and Conditions"
              variant="text-link-2"
              className="inline-flex text-gray-dark"
            />
            ,{' '}
            <Link
              href={privacyPolicyURL}
              target="_blank"
              text="Privacy Policy"
              variant="text-link-2"
              className="inline-flex text-gray-dark"
            />
            .
          </p>
        </div>
      </div>
    </EditPanel>
  );
};

export default PreferencesPanel;
