import TextInput, { TextInputProps } from '@/components/ui/Form/TextInput';
import { isValidPhoneNumber } from '@/utils/formHelpers';
import { ValidationOptions } from '@/utils/validations';
import { useCallback, useMemo } from 'react';
import { InputError } from './Input';

const maskPhoneNumber = (value: string, onlyUnitedStatesNumber: boolean): string => {
  const cleaned = value.replace(/\D/g, '');

  if (onlyUnitedStatesNumber) {
    return cleaned
      .slice(0, 10)
      .replace(/^(\d{3})(\d{0,3})?(\d{0,4})?$/, (_, p1, p2, p3) =>
        [p1, p2, p3].filter(Boolean).join('-')
      );
  }

  const extraDigits = Math.max(0, cleaned.length - 10);

  const countryCode = cleaned.slice(0, extraDigits);
  const part1 = cleaned.slice(extraDigits, extraDigits + 3);
  const part2 = cleaned.slice(extraDigits + 3, extraDigits + 6);
  const part3 = cleaned.slice(extraDigits + 6, extraDigits + 10);
  const formattedNumber = [part1, part2, part3].filter(Boolean).join('-');
  return countryCode ? `${countryCode} ${formattedNumber}` : formattedNumber;
};

type PhoneNumberInput = Omit<TextInputProps, keyof ValidationOptions | 'maxLength' | 'errors'> & {
  onlyUnitedStatesNumber?: boolean;
};

const PhoneNumberInput = ({
  value,
  onChange,
  onlyUnitedStatesNumber = false,
  ...props
}: PhoneNumberInput): JSX.Element => {
  const handleOnChange = useCallback(
    (newValue: string) => onChange?.(maskPhoneNumber(newValue, onlyUnitedStatesNumber)),
    [onChange, onlyUnitedStatesNumber]
  );

  const phoneNumberErrors = useMemo((): InputError[] => {
    return [
      {
        message: `Please enter a valid number.`,
        match: (value: string): boolean =>
          !!value && !isValidPhoneNumber(value, onlyUnitedStatesNumber)
      }
    ];
  }, [onlyUnitedStatesNumber]);

  return (
    <TextInput
      {...props}
      maxLength={onlyUnitedStatesNumber ? 14 : 16}
      acceptLetters={false}
      acceptNumbers={true}
      acceptSpaces={true}
      acceptSpecialChars={false}
      allowedChars="-"
      errors={phoneNumberErrors}
      value={value}
      onChange={handleOnChange}
    />
  );
};

export default PhoneNumberInput;
