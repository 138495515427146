import { LibraryFilters, LibraryResponse } from '@/@types/client-api';
import { FIRST_PAGE, PAGE_SIZE, QUERY_PARAMS } from '../constants';
import { LibraryRequest } from '@/redux/api/client/library';
import {
  DEFAULT_FILTER_VALUES,
  DEFAULT_SORT,
  FILTER_LIST,
  LibraryFilterTypeMap
} from '@/utils/libraryConstants';
import { getCurrentPage } from '@/utils/pagination';

export const getInitialLibraryRequest = (urlQueryParams: URLSearchParams): LibraryRequest => {
  const pageParam = urlQueryParams.get(QUERY_PARAMS.PAGE);
  const page = pageParam ? parseInt(pageParam) : FIRST_PAGE;
  const sort = urlQueryParams.get(QUERY_PARAMS.SORT) || DEFAULT_SORT;

  const filtersApplied: Record<string, string | null> = {};

  FILTER_LIST.forEach(key => {
    if (urlQueryParams.has(key)) {
      filtersApplied[key] = urlQueryParams.get(key);
    }
  });

  return {
    // If we need to load multiple pages, we do this via the pageSize prop
    pageSize: PAGE_SIZE * page,
    // From the API's perspective we are on the first page
    pageNumber: FIRST_PAGE,
    sort,
    ...filtersApplied
  };
};

export const libraryDataToSearchParams = (
  existingParameters: URLSearchParams,
  data: LibraryResponse
): URLSearchParams => {
  const { summary, items } = data;

  const page = getCurrentPage(summary?.currentPage, items?.length);

  if (page === FIRST_PAGE) {
    existingParameters.delete(QUERY_PARAMS.PAGE);
  } else {
    existingParameters.set(QUERY_PARAMS.PAGE, page.toString());
  }

  data.summary?.filtersApplied &&
    Object.entries(data.summary.filtersApplied).forEach(([key, value]) => {
      if (!LibraryFilterTypeMap[key as keyof LibraryFilters]) return;

      if (value && DEFAULT_FILTER_VALUES[key] !== value) {
        existingParameters.set(key, String(value));
      } else {
        existingParameters.delete(key);
      }
    });

  const sort = data.summary?.filtersApplied?.sort;
  if (sort && sort !== DEFAULT_SORT) {
    existingParameters.set(QUERY_PARAMS.SORT, sort);
  } else {
    existingParameters.delete(QUERY_PARAMS.SORT);
  }

  return existingParameters;
};
