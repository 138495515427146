export const isValidEmail = (email: string): boolean => {
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailPattern.test(email);
};

export const isValidBusinessEmail = (
  email: string,
  organizationDomains: string | null = null
): boolean => {
  if (!organizationDomains || !organizationDomains.length) {
    return isValidEmail(email);
  }
  if (isValidEmail(email)) {
    const [, domain] = email.split('@');
    const allowedDomains = organizationDomains.split(',');
    return allowedDomains.includes(domain);
  }
  return false;
};

export const isValidPhoneNumber = (
  phoneNumber: string,
  onlyUnitedStatesNumber = false
): boolean => {
  return onlyUnitedStatesNumber
    ? /^(1 )?[2-9]\d{2}-\d{3}-\d{4}$/.test(phoneNumber)
    : /^(\d{1,3}\s)?\d{3}-\d{3}-\d{4}$/.test(phoneNumber);
};
