import ContentArea from '@/components/cms/ContentArea';
import Icon from '@/components/ui/Icon';
import Panel from '@/components/ui/Panel';
import classnames from 'classnames';
import { NavigationItemBlock } from '@/@types/content';
import { LinkItem } from '@/@types/cms';
import Link from '@/components/ui/Link';
import { getTextColorClass } from '@/utils/theme';
import { useEffect, useState } from 'react';

enum Variant {
  Light = 'light',
  Dark = 'dark'
}

interface PanelNavMobileProps {
  theme: `${Variant}`;
  isSecondaryNavOpen: boolean;
  setIsSecondaryNavOpen: (value: boolean) => void;
  setActivePrimaryNav: (value: string | undefined) => void;
  primaryNavClickHandler: (Event: React.MouseEvent<HTMLAnchorElement>) => void;
  primaryNavMenuItems: NavigationItemBlock[];
  secondaryNavData: NavigationItemBlock[];
  pliLink: LinkItem;
  editMode: boolean;
  focusVisibleClasses: string;
  memoizedLogo: React.ReactNode;
  activePrimaryNav: string | undefined;
}

const PanelNavMobile = ({
  theme,
  isSecondaryNavOpen,
  setIsSecondaryNavOpen,
  setActivePrimaryNav,
  primaryNavClickHandler,
  primaryNavMenuItems,
  secondaryNavData,
  pliLink,
  editMode,
  focusVisibleClasses,
  memoizedLogo,
  activePrimaryNav
}: PanelNavMobileProps) => {
  const [latestActivePrimaryNav, setLatestActivePrimaryNav] = useState<string | undefined>(
    undefined
  );

  const hamburgerClasses = classnames(
    'mr-3.5 size-6',
    'sm:mr-5',
    getTextColorClass(theme),
    focusVisibleClasses
  );

  const panelMobileClasses = classnames('!max-w-[375px]');

  const panelMobileBodyClasses = classnames(
    'px-6 pb-8 pt-3',
    'before:relative before:-left-6 before:top-[-32px] before:block before:h-px before:w-[150%] before:content-[""]',
    {
      'before:bg-gray-light': theme === Variant.Light,
      'before:bg-gray-darker': theme === Variant.Dark
    }
  );

  const mobileCol1Classes = classnames('flex w-full flex-col gap-8 border-b pb-8', {
    'border-gray-light': theme === Variant.Light,
    'border-gray-darker': theme === Variant.Dark
  });

  const pliLinkClasses = classnames(getTextColorClass(theme), focusVisibleClasses);

  useEffect(() => {
    if (isSecondaryNavOpen === false) {
      setLatestActivePrimaryNav(undefined);
    } else if (activePrimaryNav) {
      setLatestActivePrimaryNav(`Panel ID ${activePrimaryNav}`);
    }
  }, [activePrimaryNav, isSecondaryNavOpen]);

  const handleItemClick = () => {
    setIsSecondaryNavOpen(false);
  };

  return (
    <div className="h-6"  data-component={"PanelNavMobile"}>
      <Panel theme={theme} isOpen={isSecondaryNavOpen} onOpenChange={setIsSecondaryNavOpen}>
        <Panel.Trigger asChild>
          <button
            className={hamburgerClasses}
            onClick={() => {
              setActivePrimaryNav(undefined);
            }}
          >
            <Icon name="menu" size="large" />
          </button>
        </Panel.Trigger>

        <Panel.Content
          hasHorizontalPadding={false}
          side="left"
          className={panelMobileClasses}
          onBackButtonPress={activePrimaryNav ? () => setActivePrimaryNav(undefined) : undefined}
          leftHeaderContent={activePrimaryNav ? undefined : memoizedLogo}
          centerHeaderContent={
            activePrimaryNav ? (
              <span className="text-2 font-bold">{activePrimaryNav}</span>
            ) : undefined
          }
          forceFocusOnTab={true}
          customPanelId={activePrimaryNav ? undefined : latestActivePrimaryNav}
        >
          <Panel.Body scrollClassName="mt-6" className="!px-0">
            <div className={panelMobileBodyClasses}>
              {activePrimaryNav ? (
                <ContentArea
                  propertyName="SubnavItems"
                  components={secondaryNavData}
                  className="flex flex-col gap-8"
                  componentsProps={{
                    theme,
                    divider: true,
                    onItemClick: handleItemClick
                  }}
                  element="div"
                />
              ) : (
                <div className="relative flex flex-col items-start">
                  <ContentArea
                    propertyName="PrimaryNavMenuItems"
                    components={primaryNavMenuItems}
                    componentsProps={{
                      theme,
                      focusVisibleClasses,
                      primaryNavClickHandler,
                      variant: 'mobile',
                      activePrimaryNav
                    }}
                    className={mobileCol1Classes}
                    element="nav"
                  />
                  {pliLink || editMode ? (
                    <Link
                      variant="bold-link-with-icon"
                      className={classnames(pliLinkClasses, 'mt-8')}
                      href={pliLink?.href}
                      text={pliLink?.text}
                      title={pliLink?.title}
                      target={pliLink?.target}
                    />
                  ) : null}
                </div>
              )}
            </div>
          </Panel.Body>
        </Panel.Content>
      </Panel>
    </div>
  );
};

export default PanelNavMobile;
