import { useCallback, useState } from 'react';
import Modal, { ModalProps } from '@/components/ui/Modal/Modal';
import ModalContent from '@/components/ui/Modal/ModalContent';

export type DeleteLicenseModalProps = {
  onConfirm: () => void;
  onClose: () => void;
  triggerButton: JSX.Element;
};

const DeleteLicenseModal = ({
  onConfirm,
  onClose,
  triggerButton
}: DeleteLicenseModalProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleOnOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open);
      if (!open) {
        onClose();
      }
    },
    [onClose, setOpen]
  );

  const handleOnBackButtonPress = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Modal open={open} setOpen={handleOnOpenChange as ModalProps['setOpen']}>
      <Modal.Trigger asChild>{triggerButton}</Modal.Trigger>
      <ModalContent
        title="Are you sure?"
        saveButtonLabel="Remove from my profile"
        onSaveButtonPress={onConfirm}
        onBackButtonPress={handleOnBackButtonPress}
      >
        <div>
          Removing this license will delete it permanently from your profile. You can add this
          license back to your PLI account at any time through your profile.
        </div>
      </ModalContent>
    </Modal>
  );
};

export default DeleteLicenseModal;
