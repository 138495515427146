import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders, queryStringBuilder, responseHandler } from '@/redux/helpers';
import type { IContent } from '@/@types/cms';
import { type IContentExtended, expandChildren } from '@/lib/helpers/expandChildren';

export type GetContentQuery = {
  expand?: Array<string>;
  select?: Array<string>;
  userId?: string;
  tCode?: string;
  continuationToken?: string;
  enableWMSPrice?: string;
};

type GetContentResponse = IContent & {
  [key: string]: unknown;
};

type GetChildrenQuery = GetContentQuery & {
  top?: number;
};

export type GetChildrenResponse = {
  children: Array<IContent>;
  meta?: {
    ['x-epi-continuation']?: string | null;
  };
};

export const contentDeliveryApi = createApi({
  reducerPath: 'contentDeliveryApi',
  tagTypes: ['Content', 'Global'],
  baseQuery: fetchBaseQuery({
    baseUrl: window.env?.CONTENT_DELIVERY_API,
    prepareHeaders,
    responseHandler,
    credentials: 'include',
    mode: 'cors'
  }),
  endpoints: builder => ({
    getContent: builder.query<GetContentResponse, { contentLink: string; query?: GetContentQuery }>(
      {
        query: ({ contentLink, query = {} }) =>
          `api/episerver/v3.0/content/${contentLink}${queryStringBuilder(query)}`,
        transformResponse: (response: IContentExtended) => expandChildren(response)
      }
    ),

    getChildren: builder.query<
      GetChildrenResponse,
      { contentLink: string; query?: GetChildrenQuery; headers?: { [key: string]: string } }
    >({
      query: ({ contentLink, query = {}, headers = {} }) => ({
        url: `api/episerver/v3.0/content/${contentLink}/children${queryStringBuilder(query)}`,
        headers
      }),
      transformResponse: (response: Array<IContent>, meta) => {
        const headers: { [key: string]: string } = {};
        const epiContinuation = meta?.response?.headers.get('x-epi-continuation');

        if (epiContinuation) {
          headers['x-epi-continuation'] = epiContinuation;
        }

        return {
          children: response,
          meta: headers
        };
      }
    }),

    getAncestors: builder.query<Array<IContent>, { contentLink: string; query?: GetContentQuery }>({
      query: ({ contentLink, query = {} }) =>
        `api/episerver/v3.0/content/${contentLink}/ancestors${queryStringBuilder(query)}`
    }),

    getContentByFriendlyUrl: builder.query<
      IContentExtended,
      { friendlyUrl: string; query?: GetContentQuery; userId: string }
    >({
      query: ({ friendlyUrl, query = {} }) => `${friendlyUrl}${queryStringBuilder(query)}`,
      transformResponse: (response: IContentExtended) => expandChildren(response),
      providesTags: (_, __, { friendlyUrl, userId }) => [
        { type: 'Content', id: friendlyUrl + userId }
      ]
    }),
    getGlobalElementDirect: builder.query<
      IContentExtended | undefined,
      { name: string; query?: GetContentQuery; userId: string }
    >({
      query: ({ name, query = {} }) => `api/client/content/${name}${queryStringBuilder(query)}`
    }),

    getGlobalElement: builder.query<
      IContentExtended,
      { name: string; query?: GetContentQuery; userId: string }
    >({
      queryFn: ({ name, query }, _, __, baseQuery) => {
        const p = baseQuery(`api/client/content/${name}${queryStringBuilder(query || {})}`);
        const rp = Promise.resolve(p);
        return rp.then(queryReturnValue => {
          if (
            (queryReturnValue.error?.status == 'PARSING_ERROR' ||
              queryReturnValue.error?.status == 404) &&
            queryReturnValue.meta?.response?.url
          ) {
            return Promise.resolve(baseQuery(queryReturnValue.meta?.response?.url)).then(
              newReturnvalue => {
                return { data: expandChildren(newReturnvalue.data as IContentExtended) };
              }
            );
          }
          return { data: expandChildren(queryReturnValue.data as IContentExtended) };
        });
      },
      providesTags: (_, __, { name, userId }) => [{ type: 'Global', id: name + userId }]
    }),

    logOut: builder.mutation<void, void>({
      query: () => '/util/logout',
      invalidatesTags: ['Content', 'Global']
    })
  })
});

export const {
  useGetContentQuery,
  useLazyGetContentQuery,
  useGetChildrenQuery,
  useLazyGetChildrenQuery,
  useGetAncestorsQuery,
  useGetContentByFriendlyUrlQuery,
  useGetGlobalElementQuery,
  useLogOutMutation
} = contentDeliveryApi;
