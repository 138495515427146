import React, { useCallback, useMemo } from 'react';
import Thumbnail from '@/components/ui/Thumbnail';
import Text from '@/components/cms/Text';
import Link from '@/components/ui/Link';
import classnames from 'classnames';
import { useLaunch } from '@/hooks/shared/useLaunch';
import DotSeparatedContent from '@/components/ui/DotSeparatedContent';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { AnalyticsContext } from '@/analytics/constants';

export interface SidePanelResultCardProps {
  heading?: string;
  labelDetail?: string;
  labelInfo?: string;
  image?: {
    url: string;
    alt?: string;
  };
  formats?: Array<string> | null;
  productLink?: { url?: string; target?: string };
  userCanLaunch?: boolean;
  launchUrl?: string;
  variationPk: string;
  itemAnalyticsModel?: ItemAnalyticsModel;
}

const SidePanelResultCard: React.FC<SidePanelResultCardProps> = ({
  heading,
  labelDetail,
  labelInfo,
  image,
  productLink,
  formats,
  variationPk,
  userCanLaunch,
  launchUrl,
  itemAnalyticsModel
}) => {
  const containerClasses = classnames('flex items-start justify-between');
  const headingClasses = classnames('text-2-medium mb-3 block !no-underline lg:mb-4', {
    'text-link cursor-pointer': productLink?.url
  });
  const imageSizes = useMemo(() => {
    return formats?.every(a => a.toLowerCase() === 'on-demand')
      ? {
          xs: '327px',
          md: '352px',
          lg: '628px'
        }
      : undefined;
  }, [formats]);
  const imageIcon = useMemo(() => {
    return userCanLaunch && productLink?.url
      ? {
          name: 'play'
        }
      : undefined;
  }, [productLink?.url, userCanLaunch]);

  const { launch } = useLaunch(AnalyticsContext.CreditTracker);

  const onThumbnailClick = useCallback(
    () => userCanLaunch && launch(variationPk, false, launchUrl, itemAnalyticsModel),
    [launch, launchUrl, userCanLaunch, variationPk, itemAnalyticsModel]
  );

  return (
    <div className={containerClasses}       data-component={"SidePanelResultCard"}>
      <div className="mr-3 w-3/4">
        {heading &&
          (productLink?.url ? (
            <Link
              href={productLink.url}
              target={productLink.target ?? '__blank'}
              className={headingClasses}
              text={heading}
            />
          ) : (
            <Text propertyName="h5" element="h5" className={headingClasses}>
              {heading}
            </Text>
          ))}
        <div className="text-2 mt-2 w-full text-gray-dark">
          {formats?.length && labelDetail ? (
            <DotSeparatedContent content={[formats?.join(', '), labelDetail]} />
          ) : (
            <Text propertyName="span" element="span">
              {formats?.join(', ') ?? labelDetail}
            </Text>
          )}
        </div>
        <Text propertyName="p" element="p" className="text-2 w-full text-gray-dark">
          {labelInfo}
        </Text>
      </div>
      <div className="w-1/4">
        <Thumbnail
          ratio={1}
          image={image}
          icon={imageIcon}
          mini={true}
          imageSizes={imageSizes}
          onClick={userCanLaunch && productLink?.url ? onThumbnailClick : undefined}
          link={!userCanLaunch && productLink?.url ? productLink : undefined}
        />
      </div>
    </div>
  );
};

export default SidePanelResultCard;
