import { AnalyticsEventName } from './constants';
import { trackEvent } from './utils';
import { ItemAnalyticsModel } from '@/@types/client-api';

export const trackViewFacultyItem = (itemAnalyticsModel?: ItemAnalyticsModel) => {
  trackEvent({
    eventName: AnalyticsEventName.ViewFacultyItem,
    parameters: {
      items: [itemAnalyticsModel]
    }
  });
};
