import { useCallback, useState } from 'react';
import { getIsDismissed } from '@/hooks/useDismissable';
import { KeyListItem, NudgeContext, SortKey } from './context';

const NudgeProvider = ({ children }: ComponentWithChildren) => {
  const [keyList, setKeyList] = useState<KeyListItem[]>([]);

  const [openPanel, setOpenPanel] = useState<string | undefined>();

  const registerPanel = (panelId: string) => setOpenPanel(panelId);
  const unregisterPanel = (panelId: string) =>
    setOpenPanel(prev => (prev == panelId ? undefined : prev));

  const addKey = useCallback((data: KeyListItem, sortKey: SortKey) => {
    setKeyList(prev => {
      if (prev.some(item => item.key === data.key)) {
        return prev;
      }
      return [...prev, { ...data, isOpen: !getIsDismissed(data.key) }].sort(sortKey);
    });
  }, []);

  const closeTooltipForKey = useCallback((key: string) => {
    setKeyList(prev => {
      const existing = prev.find(({ key: existingKey }) => existingKey === key);
      if (!existing?.isOpen) return prev;
      return prev.map(item => {
        if (item.key === key) {
          item.isOpen = false;
        }
        return item;
      });
    });
  }, []);

  return (
    <NudgeContext.Provider
      value={{
        keyList,
        addKey,
        closeTooltipForKey,
        currentKey: keyList.find(({ isOpen }) => isOpen)?.key,
        openPanel,
        registerPanel,
        unregisterPanel
      }}
    >
      {children}
    </NudgeContext.Provider>
  );
};

export default NudgeProvider;
