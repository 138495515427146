import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import classnames from 'classnames';
import Cookies from 'js-cookie';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import TagButton from '@/components/ui/Buttons/TagButton';
import FilterButton from '@/components/ui/Buttons/FilterButton';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

import Shimmer from '@/components/ui/Shimmer';
import ComplexTooltip from '../Tooltip/ComplexTooltip';
import Link from '../Link';
import { DEFAULT_INVARIANT_TOOLTIP_TEXT, FilterTypes } from '../SearchResults/constants';
import { useSelector } from 'react-redux';
import SemanticFilterTooltip from '../SearchResults/SemanticFilterTooltip';
import { useAuth } from 'react-oidc-context';
import { PageLinks } from '@/redux/slices/pageSlice';
import { selectSearchSemanticFilterTooltip } from '@/redux/selectors/searchSelectors';

export type TFilter = {
  name?: string | null;
  label?: string | null;
  value: string;
  checked?: boolean;
  groupTypeId?: string;
  type?: FilterTypes;
};

export type TFilterItem = { data?: TFilter; canDeselect?: boolean };

type FilterRowItemProps = {
  filter?: TFilterItem;
  handleFilterChange: (arg: TFilter) => void;
  showFirstTimeTooltip: boolean;
  index: number;
  auth?: { isAuthenticated: boolean };
  fetching: boolean;
  onTooltipClick?: (value: string) => void;
  handleCloseFirstTimeTooltip?: () => void;
  tooltipOpen?: string;
  setTooltipOpen?: (value: string) => void;
  searchParams?: string;
  pageLinkPaths?: PageLinks;
  semanticFilterWithTooltip?: TFilterItem;
  invariantFilterTooltipText?: string;
};

const FilterRowItem = ({
  filter,
  handleFilterChange,
  showFirstTimeTooltip,
  index,
  auth,
  fetching,
  onTooltipClick = () => {},
  handleCloseFirstTimeTooltip = () => {},
  tooltipOpen = '',
  setTooltipOpen = () => {},
  searchParams,
  pageLinkPaths,
  semanticFilterWithTooltip,
  invariantFilterTooltipText
}: FilterRowItemProps) => {
  const semanticFilterTooltip = useSelector(selectSearchSemanticFilterTooltip);

  if (!filter?.data) return null;

  const filterItem = (
    <FilterButton
      key={filter?.data?.value}
      label={filter?.data?.label}
      onClick={() => {
        setTooltipOpen('');
        onFilterButtonClick();
      }}
      isSelected={filter?.data?.checked}
      disabled={!filter?.canDeselect && filter?.data?.checked}
      onFocus={e => {
        if (e.target.matches(':focus-visible')) {
          e.target.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
      }}
      fetching={fetching}
    />
  );

  const openChange = (flag: boolean) => {
    onTooltipClick(flag ? filter?.data?.value || '' : '');
  };
  const onFilterButtonClick = () => filter?.data && handleFilterChange(filter?.data);

  const shouldRenderSemanticFilterTooltip =
    semanticFilterWithTooltip?.data?.value === filter?.data?.value &&
    semanticFilterTooltip?.title &&
    semanticFilterTooltip.description;

  const isFirstFilterWithTooltip = index === 0 && showFirstTimeTooltip && auth?.isAuthenticated;

  if (isFirstFilterWithTooltip) {
    return (
      <ComplexTooltip
        isOpen={showFirstTimeTooltip}
        onOpenChange={handleCloseFirstTimeTooltip}
        key={filter?.data?.value}
      >
        <ComplexTooltip.Trigger asChild>{filterItem}</ComplexTooltip.Trigger>
        <ComplexTooltip.Content side="bottom" sideOffset={4}>
          <div>
            <p className="text-2 mb-1 font-bold">Find exactly what you’re looking for</p>
            <p className="text-2">
              Use the filters above to refine your results and get a more tailored browsing
              experience.
            </p>
          </div>
        </ComplexTooltip.Content>
      </ComplexTooltip>
    );
  }

  return filter?.canDeselect ? (
    shouldRenderSemanticFilterTooltip ? (
      <SemanticFilterTooltip
        filter={filter}
        onClick={onFilterButtonClick}
        fetching={fetching}
        tooltipData={semanticFilterTooltip}
      />
    ) : (
      filterItem
    )
  ) : (
    <ComplexTooltip
      isOpen={tooltipOpen === filter?.data?.value}
      onOpenChange={openChange}
      key={filter?.data?.value}
    >
      <ComplexTooltip.Trigger aria-label="tooltip button" asChild>
        <TagButton
          key={filter?.data?.value}
          isActive={true}
          label={filter?.data?.label}
          onClick={() => setTooltipOpen(filter?.data?.value || '')}
          onFocus={e => {
            if (e.target.matches(':focus-visible')) {
              e.target.scrollIntoView({ behavior: 'smooth', inline: 'center' });
            }
          }}
        />
      </ComplexTooltip.Trigger>
      <ComplexTooltip.Content side="top">
        <div className="text-2">
          <span>{invariantFilterTooltipText ?? DEFAULT_INVARIANT_TOOLTIP_TEXT}</span>
          <div className="mt-6">
            Go to{' '}
            <Link
              text="full site search"
              href={`${pageLinkPaths?.Search}${searchParams}`}
              className="!inline"
              reloadDocument
            />{' '}
            instead
          </div>
        </div>
      </ComplexTooltip.Content>
    </ComplexTooltip>
  );
};

export type FilterRowProps = {
  filters?: (TFilterItem | undefined)[];
  canFilter?: boolean;
  onFilterMenuOpen?: () => void;
  onFilterToggle?: (filter: TFilter) => void;
  sortMenu: ReactNode;
  sortValue?: string;
  className?: string;
  loading?: boolean;
  fetching?: boolean;
  label?: string;
  showQuickFilters?: boolean;
  showAllFiltersAndSortButtons?: boolean;
  semanticFilterWithTooltip?: TFilterItem;
  invariantFilterTooltipText?: string;
};

const HORIZONTAL_PADDING = 8;

export interface FilterRowRefs {
  allFiltersButtonRef: React.RefObject<HTMLButtonElement>;
  focusOnAllFiltersButton: () => void;
}

const FilterRow = forwardRef<FilterRowRefs, FilterRowProps>(
  (
    {
      filters,
      canFilter = true,
      label,
      onFilterMenuOpen,
      onFilterToggle,
      sortMenu,
      sortValue,
      className,
      loading = false,
      fetching = false,
      showQuickFilters = true,
      showAllFiltersAndSortButtons = true,
      semanticFilterWithTooltip,
      invariantFilterTooltipText
    },
    ref
  ) => {
    const pageLinkPaths = useSelector(selectPageLinkPaths);
    const scrollDiv = useRef<HTMLDivElement>(null);
    const allFiltersButtonRef = useRef<HTMLButtonElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [isCheckingInitialScrollAbility, setIsCheckingInitialScrollAbility] = useState(false);
    const [showFirstTimeTooltip, setShowFirstTimeTooltip] = useState(false);

    const FIRST_TIME_TOOLTIP_KEY = 'first-time-tooltip-dismissed';
    const auth = useAuth();

    const isAboveXS = useMediaQuery(ScreenSizeQueries.sm);
    const isMedium = useMediaQuery(ScreenSizeQueries.md);
    const isLarge = useMediaQuery(ScreenSizeQueries.lg);

    const checkScrollAbility = () => {
      const div = scrollDiv.current;
      if (!div) return;

      const scrollLeft = Math.ceil(div.scrollLeft);
      setCanScrollLeft(scrollLeft - HORIZONTAL_PADDING > 0);
      // The +1 here is to err on the side of hiding the right arrow when it's not needed
      setCanScrollRight(div.scrollWidth - div.clientWidth > scrollLeft + 1);

      setTimeout(() => setIsCheckingInitialScrollAbility(false), 200);
    };

    useImperativeHandle(ref, () => ({
      allFiltersButtonRef,
      focusOnAllFiltersButton: () => {
        if (allFiltersButtonRef.current) {
          allFiltersButtonRef.current.focus();
        }
      }
    }));

    useEffect(() => {
      const div = scrollDiv.current;
      if (!div) return;

      setTimeout(checkScrollAbility, 100); // check initial state
      div.addEventListener('scroll', checkScrollAbility);
      window.addEventListener('resize', checkScrollAbility);

      return () => {
        div.removeEventListener('scroll', checkScrollAbility);
        window.removeEventListener('resize', checkScrollAbility);
      };
    }, [filters]);

    useEffect(() => {
      setTimeout(checkScrollAbility, 100);
      // These properties can affect scroll settings
    }, [sortValue, filters]);

    // Negative margins in this component prevent the focus state from getting cut off
    const wrapperClasses = classnames(
      '-m-2 flex overflow-x-scroll p-2 scrollbar-hide max-sm:-mx-4 max-sm:pl-4 sm:overflow-x-visible lg:-mx-4',
      {
        'opacity-0': isCheckingInitialScrollAbility,
        'max-lg:!pl-0 max-sm:!pl-2': !showAllFiltersAndSortButtons
      }
    );
    const buttonClasses = classnames('z-10 bg-white');

    const hasFilters = !!filters?.length;
    const selectFilterCount = filters?.filter(f => f?.data?.checked && f?.canDeselect)?.length;

    const allFiltersButton = useMemo(() => {
      return (
        <TagButton
          ref={allFiltersButtonRef}
          label={`All Filters${selectFilterCount ? ` (${selectFilterCount})` : ''}`}
          icon="filter"
          onClick={onFilterMenuOpen}
          // Disable filter overlay button if there are no filters, except on XS, where the sort menu lives in the overlay
          disabled={!canFilter && isAboveXS}
          styleType="solid"
        />
      );
    }, [canFilter, isAboveXS, onFilterMenuOpen, selectFilterCount]);

    const getArrow = (side: 'left' | 'right', isVisible: boolean) => {
      // Hide ASAP if there are no filters
      if (!hasFilters || loading) return null;

      const scrollViewWidth = scrollDiv.current?.clientWidth;
      const scrollPercentage = isLarge ? 0.6 : isMedium ? 0.5 : 0.3;
      const scrollValue = scrollViewWidth ? scrollViewWidth * scrollPercentage : 300;

      const arrowClasses = classnames(
        'absolute -mt-1 border-silver bg-white px-2 py-1 transition-opacity duration-short',
        {
          // Fade out if invisible while scrolling
          'pointer-events-none opacity-0': !isVisible,
          'opacity-100': isVisible,
          'border-r': side === 'left',
          '-translate-x-full border-l': side === 'right'
        }
      );

      return (
        <div className="relative">
          <div className={arrowClasses}>
            <TagButton
              icon={side === 'left' ? 'chevron-left' : 'chevron-right'}
              aria-hidden
              onClick={() => {
                if (scrollDiv.current) {
                  if (side === 'left') {
                    scrollDiv.current.scrollLeft -= scrollValue;
                  } else {
                    scrollDiv.current.scrollLeft += scrollValue;
                  }
                }
              }}
              tabIndex={-1}
            />
          </div>
        </div>
      );
    };

    const handleFilterChange = (props: TFilter) => {
      onFilterToggle?.(props);
    };

    const searchParams = window.location.search;

    const [tooltipOpen, setTooltipOpen] = useState<string>('');
    const onTooltipClick = useCallback((key: string) => {
      setTooltipOpen(key);
    }, []);

    useEffect(() => {
      const isFirstTime = !Cookies.get(FIRST_TIME_TOOLTIP_KEY);

      if (isFirstTime && filters?.length) {
        setShowFirstTimeTooltip(true);
      }
    }, [filters]);

    const handleCloseFirstTimeTooltip = () => {
      Cookies.set(FIRST_TIME_TOOLTIP_KEY, 'true', { expires: 365 });
      setShowFirstTimeTooltip(false);
    };

    if (!loading && !showQuickFilters) {
      return null;
    }

    return (
      <div className={classnames(className, 'relative')}>
        {/* Display unless you can't filter (Faculty/Authors tab) */}
        {canFilter && label && (
          <h4 className="label mb-3 uppercase text-gray-dark max-sm:hidden lg:mb-4">
            {/* If no filters, keep the space but remove the text to avoid a jump */}
            {hasFilters && !loading ? label : <>&nbsp;</>}
          </h4>
        )}
        <div className={wrapperClasses}>
          {/* Filter button is before list below large */}
          {showAllFiltersAndSortButtons && (
            <div className={classnames(buttonClasses, 'lg:hidden')}>
              {loading ? <Shimmer className="h-14 w-[110px]" /> : allFiltersButton}
            </div>
          )}

          {getArrow('left', canScrollLeft)}

          {loading ? (
            <>
              {Array.from({ length: 5 }).map((_, index) => (
                <Shimmer key={index} className="mr-2 h-14 w-36" />
              ))}
            </>
          ) : null}

          {hasFilters && !loading ? (
            <div
              onScroll={checkScrollAbility}
              className="-my-2 flex scroll-smooth py-2 scrollbar-hide sm:shrink sm:overflow-x-auto"
              style={{ paddingLeft: HORIZONTAL_PADDING, paddingRight: HORIZONTAL_PADDING }}
              ref={scrollDiv}
            >
              <div className="flex gap-2">
                {filters?.map((filter, index) => {
                  if (filter) {
                    return (
                      <FilterRowItem
                        key={(filter.data?.value ?? '') + index}
                        filter={filter}
                        handleFilterChange={handleFilterChange}
                        showFirstTimeTooltip={showFirstTimeTooltip}
                        index={index}
                        auth={auth}
                        fetching={fetching}
                        onTooltipClick={onTooltipClick}
                        handleCloseFirstTimeTooltip={handleCloseFirstTimeTooltip}
                        tooltipOpen={tooltipOpen}
                        setTooltipOpen={setTooltipOpen}
                        searchParams={searchParams}
                        pageLinkPaths={pageLinkPaths}
                        semanticFilterWithTooltip={semanticFilterWithTooltip}
                        invariantFilterTooltipText={invariantFilterTooltipText}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          ) : null}

          {getArrow('right', canScrollRight)}

          {/* Filter button is after list at large */}
          {showAllFiltersAndSortButtons && (
            <div className={classnames(buttonClasses, 'ml-auto mr-2 max-lg:hidden')}>
              {loading ? <Shimmer className="h-14 w-[110px] lg:w-[134px]" /> : allFiltersButton}
            </div>
          )}

          {showAllFiltersAndSortButtons && (
            /* Sort appears inside the filter overlay on mobile */
            <div className={classnames(buttonClasses, 'ml-auto max-sm:hidden lg:ml-0')}>
              {loading ? <Shimmer className="h-14 w-[110px] lg:w-40" /> : sortMenu}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default FilterRow;
