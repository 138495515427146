import { useCallback, useState } from 'react';
import Panel from '@/components/ui/Panel';
import { EcommPanelParams } from './hooks/useEcommData';
import EcommPanelLiveProgram from './EcommPanelLiveProgram';
import EcommPanelOnDemandSegment from './EcommPanelOnDemandSegment';
import EcommPanelOnDemandProgram from './EcommPanelOnDemandProgram';
import EcommPanelPublication from './EcommPanelPublication';
import { AnalyticsContext } from '@/analytics/constants';

const renderContent = (
  params: EcommPanelParams,
  onClose: () => void,
  context: AnalyticsContext | undefined
): JSX.Element => {
  if (params.searchableType === 'liveprogram') {
    return <EcommPanelLiveProgram params={params} onClose={onClose} context={context} />;
  }
  if (params.searchableType === 'ondemandprogram') {
    return <EcommPanelOnDemandProgram params={params} onClose={onClose} context={context} />;
  }
  if (params.searchableType === 'ondemandsegment') {
    return <EcommPanelOnDemandSegment params={params} onClose={onClose} context={context} />;
  }
  if (params.searchableType === 'book') {
    return <EcommPanelPublication params={params} onClose={onClose} context={context} />;
  }
  return <></>;
};

export type EcommPanelProps = {
  params?: EcommPanelParams;
  onClose?: () => void;
  triggerButton?: JSX.Element;
  customPanelId?: string;
  context?: AnalyticsContext;
};

const EcommPanel = ({
  params,
  onClose,
  triggerButton,
  customPanelId,
  context
}: EcommPanelProps) => {
  const [isOpen, setOpen] = useState(false);

  const handleOnClose = useCallback(() => {
    if (triggerButton) {
      setOpen(false);
    } else {
      onClose?.();
    }
  }, [onClose, triggerButton]);

  return (
    <Panel
      isOpen={triggerButton ? isOpen : params !== undefined}
      onOpenChange={triggerButton ? setOpen : onClose}
      size="small"
    >
      {triggerButton && <Panel.Trigger asChild>{triggerButton}</Panel.Trigger>}
      <Panel.Content side="right" hasHorizontalPadding={false} customPanelId={customPanelId}>
        <Panel.Body>
          {params ? renderContent(params, handleOnClose, context) : undefined}
        </Panel.Body>
      </Panel.Content>
    </Panel>
  );
};

export default EcommPanel;
