import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetContactInfoQuery,
  useUpdateContactInfoMutation
} from '@/redux/api/client/userContactInfo';
import PersonalInfo from '@/components/ui/PersonalInfo';
import { UserContactInfo } from '@/@types/client-api';
import ContactInfoPanel from '@/components/ui/ProfilePanels/ContactInfoPanel';
import useToast from '@/hooks/useToast';
import ProfileSectionWrapper from './ProfileSectionWrapper';
import { PersonalInfoContactInfoBlock as PersonalInfoContactInfoBlockProps } from '@/@types/content';
import useNudge from '@/hooks/shared/useNudge';
import { IComponentProps } from '@/lib/ComponentFactory';
import PersonalInfoButton from '@/components/ui/PersonalInfoButton';
import useDebounceLoading from '@/hooks/useDebounceLoading';
import { trackEditProfileEvent } from '@/analytics/profile';
import { ProfileSection, NudgeAnalyticsContext } from '@/analytics/constants';
import { ProfileNudgeSectionNames } from '@/hooks/shared/useNudge/utils';

const ContactInfoSection = ({
  messages,
  index,
  timeUntilStale
}: PersonalInfoContactInfoBlockProps & IComponentProps): JSX.Element => {
  const { data, isLoading, isError, refetch } = useGetContactInfoQuery();
  const [updateContactInfo] = useUpdateContactInfoMutation();

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { showSuccessMessage, showFailMessage } = useToast();

  const subSections = useMemo(() => {
    if (!data || isError) {
      return [
        { title: 'Business email', description: '-' },
        { title: 'Secondary email', description: '-' },
        { title: 'Phone', description: '-' }
      ];
    }

    return [
      {
        title: 'Business email',
        description: data.businessEmail ?? '-',
        fields: ['businessEmail']
      },
      {
        title: 'Secondary email',
        description: data.secondaryEmail ?? '-',
        fields: ['secondaryEmail']
      },
      {
        title: 'Phone',
        description:
          !!data.phone && !!data.phoneExt
            ? `${data.phone}, Ext ${data.phoneExt}`
            : (data.phone ?? '-'),
        fields: ['phone', 'phoneExt']
      }
    ];
  }, [data, isError]);

  const handleOnEditClick = useCallback(() => {
    trackEditProfileEvent(ProfileSection.Contact);
    setIsEditing(true);
  }, []);

  const handleOnSave = useCallback(
    async (data: UserContactInfo) => {
      setIsSaving(true);
      try {
        await updateContactInfo(data).unwrap();
        await refetch();
        showSuccessMessage('Information saved');
      } catch (error) {
        showFailMessage('Error saving contact information');
      } finally {
        setIsSaving(false);
      }
    },
    [refetch, showFailMessage, showSuccessMessage, updateContactInfo]
  );

  useEffect(() => {
    isError && showFailMessage('Error loading contact information');
  }, [isError, showFailMessage]);

  const nudge = useNudge({
    messagesJSON: messages,
    sectionData: data,
    sectionIndex: index,
    sectionName: ProfileNudgeSectionNames.Contact,
    context: NudgeAnalyticsContext.Contact,
    onAccept: handleOnEditClick,
    timeUntilStale
  });

  const debouncedLoading = useDebounceLoading(isLoading);
  const debouncedSaving = useDebounceLoading(isSaving);

  return (
    <ProfileSectionWrapper>
      <PersonalInfo
        sectionTitle="Contact information"
        loading={debouncedLoading || debouncedSaving}
        subSections={subSections}
        nudge={nudge}
        button={
          !debouncedLoading && !isError && data ? (
            <ContactInfoPanel
              isOpen={isEditing}
              onOpenChange={setIsEditing}
              data={data}
              onSave={handleOnSave}
              messages={messages}
              triggerButton={<PersonalInfoButton onClick={handleOnEditClick} />}
            />
          ) : undefined
        }
      />
    </ProfileSectionWrapper>
  );
};

export default ContactInfoSection;
