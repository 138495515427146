import { forwardRef, memo, Suspense, useCallback, useMemo, useState } from 'react';
import { useLazyGetSearchResultsQuery } from '@/redux/api/client/search';
import {
  FacetOperation,
  ICreditRequirement,
  ISearchResultItem,
  ItemAnalyticsModel,
  RequirementKeyType,
  UserPracticeAreaKeywordItem
} from '@/@types/client-api';
import Button from '@/components/ui/Buttons/Button';
import Panel from '@/components/ui/Panel';
import Text from '@/components/cms/Text';
import Shimmer from '@/components/ui/Shimmer';
import SidePanelResultCollection from '@/components/ui/CreditTracker/snippets/SidePanelResultCollection';
import { getInitialSearchRequest } from '@/components/ui/SearchResults/utils/searchParams';
import { MAX_EARN_MORE_ITEMS } from '@/components/ui/CreditTracker/util/constants';
import {
  formatUrl,
  mapProgramFormatToSearchFormat,
  roundCredits
} from '@/components/ui/CreditTracker/util/TrackerHelpers';
import { SidePanelResultCardProps } from '@/components/ui/Cards/SidePanelResultCard';
import useTrackerEarnMore from '@/components/ui/CreditTracker/hooks/useTrackerEarnMoreHook';
import { FILTERS } from '@/components/ui/SearchResults/constants';
import { trackViewItemListEvent } from '@/analytics/ecommerce';
import { getUID } from '@/analytics/analyticsUtils';
import { trackViewAllCrediTrackerEvent } from '@/analytics/creditTracker';

export interface IEarnMoreCreditPanelProps {
  creditRegionName: string;
  button?: React.ReactNode;
  creditRegionShortName: string;
  requirement: ICreditRequirement;
  creditTypeLongDescription: string;
  creditTypePriority?: string[] | null;
  practiceAreas?: UserPracticeAreaKeywordItem[];
  failureMessage?: string | null;
  noResultsMessage?: string | null;
  earnMoreResultPageUrl?: string | null;
  onOpenChange: (open: boolean) => void;
}

export const EarnMoreCreditPanel = memo(
  forwardRef<HTMLButtonElement, IEarnMoreCreditPanelProps>((props, ref) => {
    const {
      creditRegionName,
      creditTypeLongDescription,
      button,
      practiceAreas,
      creditRegionShortName,
      requirement,
      creditTypePriority,
      failureMessage,
      noResultsMessage,
      earnMoreResultPageUrl,
      onOpenChange
    } = props;
    0;
    const failureLinkReplaceRegex = useMemo(() => new RegExp('\\{link\\}'), []);
    const [loading, setLoading] = useState<boolean>(true);
    const [results, setResults] = useState<ISearchResultItem[]>();
    const [resultsMap, setResultsMap] = useState<{ [key: string]: ISearchResultItem[] }>({});
    const [search] = useLazyGetSearchResultsQuery();

    const [searchFailed, setSearchFailed] = useState<boolean>();

    const heading = useMemo(
      () => (
        <>
          {creditTypeLongDescription} programs for{' '}
          <span className="inline-block">{creditRegionName}</span>
        </>
      ),
      [creditRegionName, creditTypeLongDescription]
    );

    const facetOverrides = useMemo(() => {
      return {
        // set credit types as OR search.
        [FILTERS.CREDIT_TYPE]: { operation: FacetOperation._0 }
      };
    }, []);

    const formats = useMemo(() => {
      return requirement.requirementKeys?.some(a => a.requirementKeyType === RequirementKeyType._2)
        ? requirement.requirementKeys?.map(a => mapProgramFormatToSearchFormat(a.key ?? '') ?? '')
        : [];
    }, [requirement.requirementKeys]);

    const { fillSearchResults, createSearchLink } = useTrackerEarnMore({
      practiceAreas,
      formats,
      priorityTypesConfig: creditTypePriority
    });

    const creditsNeeded = useMemo(
      () => (requirement.required ?? 0) - (requirement.counted ?? 0),
      [requirement.counted, requirement.required]
    );

    const searchQueryRecord = useMemo(() => {
      return {
        [creditRegionShortName]: [requirement]
      };
    }, [creditRegionShortName, requirement]);

    const panelQuery = useMemo(
      () => createSearchLink(searchQueryRecord),
      [createSearchLink, searchQueryRecord]
    );
    const viewAllQuery = useMemo(
      () => createSearchLink(searchQueryRecord, false),
      [createSearchLink, searchQueryRecord]
    );

    const memoizedViewAllQuery = useMemo(() => {
      if (earnMoreResultPageUrl)
        return formatUrl(`${earnMoreResultPageUrl}?${viewAllQuery?.toString()}`);
    }, [earnMoreResultPageUrl, viewAllQuery]);

    const memoizedFallbackQuery = useMemo(() => {
      return formatUrl(`/search?${createSearchLink(searchQueryRecord, false, false)}`);
    }, [createSearchLink, searchQueryRecord]);

    const searchForResults = useCallback(
      async (query: URLSearchParams) => {
        const req = getInitialSearchRequest(query, null, null, facetOverrides);
        req.pageSize = MAX_EARN_MORE_ITEMS;
        return await search({ requestBody: { ...req, skipFacetGroupsCalculation: true } });
      },
      [facetOverrides, search]
    );

    const trackEvents = useCallback(
      (items: ISearchResultItem[]) => {
        if (items && items?.length > 0) {
          trackViewItemListEvent(
            `${getUID()}_${creditTypeLongDescription}_${creditRegionName}`
              .toLowerCase()
              .replace(/\s+/g, '_'),
            `${creditTypeLongDescription} for ${creditRegionName}`,
            items
              .map(x => x.itemAnalyticsModel)
              .filter((x): x is ItemAnalyticsModel => x !== undefined) || []
          );
        }
      },
      [creditTypeLongDescription, creditRegionName]
    );

    const handleOpenChange = useCallback(
      async (open: boolean) => {
        if (!panelQuery) return;
        if (onOpenChange) onOpenChange(open);

        if (open) {
          if (resultsMap?.[panelQuery?.toString()]) {
            const filtered = resultsMap?.[panelQuery?.toString()];
            trackEvents(filtered);
            return filtered;
          }

          setLoading(true);
          const response = await searchForResults(panelQuery);
          setSearchFailed(response.isError);

          let results = fillSearchResults(response.data?.searchResults?.searchResultsItems ?? []);

          // try to backfill when not enough results.
          if ((!results?.length || results.length < MAX_EARN_MORE_ITEMS) && !!viewAllQuery) {
            const fallbackResults = await searchForResults(viewAllQuery);
            results = [
              ...(results ?? []),
              ...fillSearchResults(
                fallbackResults?.data?.searchResults?.searchResultsItems?.filter(
                  a => !results?.some(b => b.productCode == a.productCode)
                ) ?? []
              )
            ];
          }

          const filtered = results?.slice(0, MAX_EARN_MORE_ITEMS);
          setResultsMap({
            ...resultsMap,
            [panelQuery?.toString()]: filtered
          });
          trackEvents(filtered);
          setResults(filtered);
          setLoading(false);
        }
      },
      [
        fillSearchResults,
        onOpenChange,
        panelQuery,
        resultsMap,
        searchForResults,
        viewAllQuery,
        trackEvents
      ]
    );

    const creditsNeededMessage = useMemo(() => {
      if (creditsNeeded > 0) {
        const creditWording = creditsNeeded > 1 ? 'credits' : 'credit';
        return `${roundCredits(creditsNeeded)} ${creditWording} needed`;
      }
    }, [creditsNeeded]);

    const sidePanelResultItems = useMemo(() => {
      return (
        results?.map(a => {
          return { ...a } as SidePanelResultCardProps;
        }) ?? []
      );
    }, [results]);

    const header = useMemo(() => {
      return (
        <div className="flex w-full flex-row flex-wrap items-start">
          <h5 className="heading-5-medium w-full">{heading}</h5>
          <Text propertyName="p" element="p" className="text-1 mb-6 mt-3 w-fit text-gray-dark">
            {creditsNeededMessage}
          </Text>
        </div>
      );
    }, [creditsNeededMessage, heading]);

    const shimmer = useMemo(
      () =>
        Array.from({ length: MAX_EARN_MORE_ITEMS }, (_, i) => (
          <Shimmer className="my-5 h-[150px] w-full" key={`result-${i}`} />
        )),
      []
    );

    const failureMessageBanner = useMemo(() => {
      const link = `<a href='${memoizedFallbackQuery}' class='text-link-1 underline hover:text-red' target='__blank'>Search</a>`;
      const message = (searchFailed ? failureMessage : noResultsMessage)?.replace(
        failureLinkReplaceRegex,
        link
      );
      return (
        !!message && (
          <Text element="p" className="text-1 mb-6 mt-3 w-fit text-gray-dark">
            <span dangerouslySetInnerHTML={{ __html: message }}></span>
          </Text>
        )
      );
    }, [
      failureLinkReplaceRegex,
      failureMessage,
      memoizedFallbackQuery,
      noResultsMessage,
      searchFailed
    ]);

    const body = useMemo(() => {
      return (
        <div className="mb-20 mt-10">
          {loading && !sidePanelResultItems?.length ? (
            shimmer
          ) : sidePanelResultItems?.length ? (
            <SidePanelResultCollection items={sidePanelResultItems} />
          ) : (
            failureMessageBanner
          )}
        </div>
      );
    }, [failureMessageBanner, loading, shimmer, sidePanelResultItems]);

    const footer = useMemo(() => {
      return (
        !!sidePanelResultItems?.length && (
          <div className="absolute -top-10 left-0 z-50 flex w-full items-center justify-center bg-white">
            <Button
              label="View all"
              aria-label="view all programs"
              color="outline-black"
              className="!w-[87%] shadow-[0_0_30px_15px_rgba(255,255,255,1)]"
              size="large"
              href={memoizedViewAllQuery?.toString()}
              target="_blank"
              onClick={() =>
                trackViewAllCrediTrackerEvent(creditRegionName, creditTypeLongDescription)
              }
              loading={loading}
            />
          </div>
        )
      );
    }, [
      loading,
      memoizedViewAllQuery,
      sidePanelResultItems?.length,
      creditRegionName,
      creditTypeLongDescription
    ]);

    return (
      !!button && (
        <Panel onOpenChange={handleOpenChange} size="small">
          <Panel.Trigger asChild ref={ref}>
            {button}
          </Panel.Trigger>
          <Suspense fallback={shimmer}>
            <Panel.Content closeLabel="Close earn more credit">
              <Panel.Header className="clear-both !h-auto">{header}</Panel.Header>
              <Panel.Body>{body}</Panel.Body>
              {(loading || sidePanelResultItems?.length >= MAX_EARN_MORE_ITEMS) && (
                <Panel.Footer className="relative">{footer}</Panel.Footer>
              )}
            </Panel.Content>
          </Suspense>
        </Panel>
      )
    );
  })
);

EarnMoreCreditPanel.displayName = 'EarnMoreCreditPanel';
