import { LibraryItem } from '@/@types/client-api';
import type { ISearchResultItem } from '@/@types/client-api/models/ISearchResultItem';
import { useLaunch } from '@/hooks/shared/useLaunch';
import { getSegmentFromLibraryItem } from '@/components/ui/Snippet/SegmentList/helpers';

import { SnippetContext } from './Snippets/types';
import type { SnippetSegmentType } from '../Snippet/SegmentList/types';
import {
  isLiveProgram,
  isOnDemandProgram,
  isOnDemandSegment,
  getIsLiveProgramExpired,
  snippetToAnalyticsContext
} from './Snippets/helpers';
import { type SearchableType } from './searchableTypeToSnippetVariant';

type GetSegmentList = {
  resultItem: Pick<
    ISearchResultItem,
    'parentProductLink' | 'parentProductLinkText' | 'variationPk'
  > &
    Pick<
      LibraryItem,
      | 'segments'
      | 'pk'
      | 'seeMoreSegments'
      | 'parentProductCode'
      | 'totalSegments'
      | 'liveSegments'
      | 'odProgram'
      | 'isPurchased'
      | 'isParentProgramPurchased'
      | 'watchUrl'
    > & {
      searchableType?: unknown;
    };
  context: SnippetContext;
};

const getLaunchUrl = (seg: SnippetSegmentType) =>
  seg.isPurchased || seg.isParentProgramPurchased ? seg.watchUrl : undefined;

const useGetSegmentList = ({ resultItem, context }: GetSegmentList) => {
  const {
    parentProductLinkText,
    parentProductLink,
    pk,
    variationPk,
    parentProductCode,
    segments,
    totalSegments = 3,
    searchableType: _searchableType
  } = resultItem || {};

  const searchableType = _searchableType as unknown as SearchableType;
  const { launch } = useLaunch(snippetToAnalyticsContext(context));

  switch (context) {
    case 'search': {
      if (parentProductLink && parentProductLinkText) {
        return {
          programPk: variationPk || '',
          isProgramSegmentList: false,
          segmentListHeaderTitle: parentProductLinkText,
          segmentLink: parentProductLink,
          featuredSegments: [],
          onLaunchMediaPlayer: null
        };
      }
      break;
    }
    case 'library': {
      if (isOnDemandProgram(searchableType) && segments?.length) {
        const featuredSegments: SnippetSegmentType[] = segments?.map(seg => {
          return getSegmentFromLibraryItem({ ...seg, topicNumber: null });
        });
        return {
          programPk: pk || '',
          isProgramSegmentList: true,
          totalSegments: totalSegments ? totalSegments - featuredSegments.length : 3,
          featuredSegments,
          segmentListHeaderTitle: '',
          onLaunchMediaPlayer: (seg: SnippetSegmentType) => launch(seg.pk, false, getLaunchUrl(seg))
        };
      } else if (isOnDemandSegment(searchableType) && parentProductCode && parentProductLinkText) {
        return {
          programPk: parentProductCode,
          totalSegments: totalSegments || 3,
          isProgramSegmentList: false,
          segmentListHeaderTitle: parentProductLinkText ?? '',
          programLink: parentProductLink || '',
          onLaunchMediaPlayer: (seg: SnippetSegmentType) => launch(seg.pk, false, getLaunchUrl(seg))
        };
      } else if (
        isLiveProgram(searchableType) &&
        getIsLiveProgramExpired(resultItem as unknown as LibraryItem)
      ) {
        const { liveSegments = [], odProgram = {}, segments } = resultItem;
        if (liveSegments?.length === 0 && !odProgram) return null;
        return {
          liveSegments: {
            programPk: resultItem.pk || '',
            featuredSegments: (liveSegments || [])
              .filter(({ cleProgressPct = 0 }) => cleProgressPct > 0)
              .map(getSegmentFromLibraryItem)
              .map(seg => ({ ...seg, playable: false })),
            totalSegments: liveSegments?.length,
            isProgramSegmentList: true,
            segmentListHeaderTitle: '',
            programLink: '',
            onLaunchMediaPlayer: (seg: SnippetSegmentType) =>
              launch(seg.pk, false, getLaunchUrl(seg))
          },
          segments: {
            programPk: resultItem.odProgram?.pk || '',
            featuredSegments: (segments || []).map(getSegmentFromLibraryItem),
            totalSegments,
            isProgramSegmentList: true,
            segmentListHeaderTitle: '',
            programLink: '',
            onLaunchMediaPlayer: (seg: SnippetSegmentType) =>
              launch(seg.pk, false, getLaunchUrl(seg))
          }
        };
      }
      break;
    }
    default: {
      return null;
    }
  }

  return null;
};

export default useGetSegmentList;
