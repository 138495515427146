import type { PodcastPage, SiteSettings } from '@/@types/content';
import withNav from '../hocs/withNav';
import { BreadcrumbProps } from '../ui/Breadcrumbs';
import ContentArea from '../cms/ContentArea';

interface PodcastPageProps extends PodcastPage, SiteSettings {
  breadcrumbs: BreadcrumbProps[];
}

const PodcastPageComponent: React.FC<PodcastPageProps> = props => {
  return (
    <ContentArea
      components={props.mainContentArea}
      componentsProps={{ breadcrumbs: [...props.breadcrumbs] }}
      propertyName="MainContentArea"
    />
  );
};

const PodcastPage = withNav(PodcastPageComponent);
export default PodcastPage;
