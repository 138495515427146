import { CreditJurisdiction, UserLicense } from '@/@types/client-api';
import { SelectOption } from '@/components/ui/DropdownSelectAutocomplete';
import {
  orderByCreditClass,
  orderByRegion
} from '@/components/ui/SearchResults/utils/sortFunctions';

export type LicenseData = UserLicense & { isTopLevelSection?: boolean };

export type LicenseNumberSettings = {
  isRequired?: boolean;
  placeholder?: string;
};

export const getLicenseKey = (license: UserLicense): string =>
  `${license.creditClass_SK}-${license.creditRegion_SK}`;

export const getAddLicenseBody = (license: UserLicense): UserLicense => ({
  creditClass_SK: license?.creditClass_SK,
  creditRegion_SK: license?.creditRegion_SK,
  stateLicenseNumber: license?.stateLicenseNumber,
  yearAdmitted: license?.yearAdmitted,
  complianceGroup_SK: license?.complianceGroup_SK,
  state: license?.state
});

export const findCreditJurisdiction = (
  creditJurisdictions?: CreditJurisdiction[],
  creditClass_SK?: number,
  creditRegion_SK?: number
): CreditJurisdiction | undefined => {
  if (!creditJurisdictions || !creditClass_SK || !creditRegion_SK) {
    return undefined;
  }
  return creditJurisdictions.find(
    i => i.creditClass_SK === creditClass_SK && i.creditRegion_SK === creditRegion_SK
  );
};

export const getEnrichedLicenseData = (
  data?: UserLicense,
  creditJurisdiction?: CreditJurisdiction
) => ({
  ...data,
  creditRegion: creditJurisdiction?.id,
  creditRegionName: creditJurisdiction?.name,
  state: creditJurisdiction?.stateName
});

export const getCreditTypeOptions = (
  creditJurisdictions?: CreditJurisdiction[]
): SelectOption[] => {
  const result: SelectOption[] = [];
  const orderedItems = [...(creditJurisdictions ?? [])].sort(orderByCreditClass);
  orderedItems.forEach(item => {
    const value = item.creditClass_SK?.toString();
    const label = item.creditClass;
    if (!!value && !!label && !result.find(i => i.value === value)) {
      result.push({ value, label });
    }
  });
  return result;
};

export const getJurisdictionsOptions = (
  creditJurisdictions?: CreditJurisdiction[],
  creditClass_SK?: number
): SelectOption[] => {
  if (!creditClass_SK) {
    return [];
  }
  const result: SelectOption[] = [];
  const orderedItems = [
    ...(creditJurisdictions?.filter(
      creditJurisdiction => creditJurisdiction.creditClass_SK === creditClass_SK
    ) ?? [])
  ].sort(orderByRegion);
  orderedItems?.forEach(item => {
    const value = item.creditRegion_SK?.toString();
    const label = item.name;
    if (!!value && !!label && !result.find(i => i.value === value)) {
      result.push({ value, label });
    }
  });
  return result;
};

export const getLicenseNumberSettings = (
  creditJurisdictions?: CreditJurisdiction[],
  creditClass_SK?: number,
  creditRegion_SK?: number
): LicenseNumberSettings | undefined => {
  const creditJurisdiction = findCreditJurisdiction(
    creditJurisdictions,
    creditClass_SK,
    creditRegion_SK
  );
  if (!creditJurisdiction) {
    return undefined;
  }
  return {
    isRequired: creditJurisdiction.isLicenseNumberRequired,
    placeholder: creditJurisdiction.creditRegionLicenseNumberName
      ? creditJurisdiction.creditRegionLicenseNumberName
      : undefined
  };
};

export const getComplianceGroupOptions = (
  creditJurisdictions?: CreditJurisdiction[],
  creditClass_SK?: number,
  creditRegion_SK?: number
): SelectOption[] => {
  const creditJurisdiction = findCreditJurisdiction(
    creditJurisdictions,
    creditClass_SK,
    creditRegion_SK
  );
  if (!creditJurisdiction) {
    return [];
  }
  const result: SelectOption[] = [];
  const complianceGroups = creditJurisdiction.complianceGroups ?? [];
  complianceGroups.forEach(item => {
    const value = item.sk?.toString();
    const label = item.longDescription;
    if (!!value && !!label) {
      result.push({ value, label });
    }
  });
  return result;
};

export const validateRequiredFields = (data?: UserLicense) =>
  data?.creditClass_SK && data?.creditRegion_SK && isYearAdmittedValid(data);

type YearAdmittedRange = {
  minYear: number;
  maxYear: number;
};

const getYearAdmittedRange = (): YearAdmittedRange => ({
  minYear: 1900,
  maxYear: new Date().getFullYear()
});

export const isYearAdmittedValid = (data?: UserLicense) => {
  if (data?.yearAdmitted) {
    const yearAdmittedRange = getYearAdmittedRange();
    const yearAdmitted = parseInt(data.yearAdmitted);
    return yearAdmitted >= yearAdmittedRange.minYear && yearAdmitted <= yearAdmittedRange.maxYear;
  }
  return true;
};

export const getInvalidYearAdmittedMessage = (data?: UserLicense) => {
  if (data?.yearAdmitted) {
    const yearAdmittedRange = getYearAdmittedRange();
    const yearAdmitted = parseInt(data.yearAdmitted);
    if (yearAdmitted < yearAdmittedRange.minYear) {
      return `Please enter a year greater than or equal to ${yearAdmittedRange.minYear}.`;
    }
    if (yearAdmitted > yearAdmittedRange.maxYear) {
      return `Please enter a year less than or equal to ${yearAdmittedRange.maxYear}.`;
    }
  }
  return '';
};

export const getFindApprovedProgramsURL = (data?: UserLicense): string | undefined => {
  if (!data || !data.creditRegion) {
    return undefined;
  }
  return `${window.env?.CONTENT_DELIVERY_API}/api/client/credit-info/page-map/${data.creditRegion}?isTransitionalOrNewlyAdmitted=false`;
};
