import Text from '@/components/cms/Text';
import Block from '@/components/cms/Block';
import CollectionCard from '@/components/ui/Cards/CollectionCard';

interface TileBlockProps {
  contentLink?: {
    id: number;
  };
  heading?: string;
  locationCards: {
    backgroundImage?: {
      url?: string;
    };
    locationText?: string;
    locationName?: string;
    locationAddress?: string;
    locationLink?: {
      href?: string;
      target?: string;
    };
  }[];
}

const LocationsRowBlock: React.FC<TileBlockProps> = ({
  heading,
  locationCards = [],
  contentLink
}: TileBlockProps) => {
  const formatLabels = (address?: string): string[] => {
    return address ? address.split('\n') : [];
  };

  return (
    <Block contentLinkID={contentLink?.id} className="container module-spacing">
      {heading && (
        <Text element="h5" className="heading-5">
          {heading}
        </Text>
      )}
      <ul className="mt-8 grid gap-y-4 sm:grid-cols-2 sm:gap-x-4 lg:gap-x-6">
        {Array.isArray(locationCards) &&
          locationCards.map((card, index) => (
            <li key={index}>
              <CollectionCard
                heading={card.locationName ?? ''}
                headingSmall={card.locationText ?? ''}
                href={card.locationLink?.href}
                target={card.locationLink?.target}
                image={{
                  url: card.backgroundImage?.url ?? ''
                }}
                size="medium"
                labels={formatLabels(card.locationAddress ?? '')}
                hasDropdown={false}
                overlayColor="bg-red-darker"
                isLabelColumn
              />
            </li>
          ))}
      </ul>
    </Block>
  );
};

export default LocationsRowBlock;
