import { ErrorPage as ErrorPageProps, SiteSettings } from '@/@types/content';
import withNav from '../hocs/withNav';
import ContentArea from '../cms/ContentArea';
import RichText from '../cms/RichText';

const ErrorPageComponent: React.FC<ErrorPageProps & SiteSettings> = ({
  mainBody,
  mainContentArea
}) => {
  return (
    <>
      <RichText content={mainBody} className="container module-spacing" />
      <ContentArea components={mainContentArea} propertyName="MainContentArea" />
    </>
  );
};

const ErrorPage = withNav(ErrorPageComponent);
export default ErrorPage;
