import { forwardRef } from 'react';

import Icon, { IconSizes } from '@/components/ui/Icon';
import classnames from 'classnames';

type CircleButtonProps = {
  icon: string;
  label?: string;
  color?: 'dark' | 'light';
  fixedSize?: boolean;
  iconSize?: IconSizes;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

const CircleButton = forwardRef<HTMLButtonElement, CircleButtonProps>(
  (
    {
      icon,
      label,
      color = 'dark',
      fixedSize = false,
      disabled = false,
      iconSize = 'small',
      ...props
    }: CircleButtonProps,
    ref
  ) => {
    const buttonBaseClass = classnames(
      'group text-1-medium flex flex-row items-center justify-center gap-3 lg:gap-2'
    );

    const colorClass = classnames(
      disabled ? 'text-gray' : color === 'dark' ? 'text-black' : 'text-white'
    );

    const buttonClass = classnames(buttonBaseClass, colorClass);

    const circleBaseClass = classnames(
      'flex items-center justify-center rounded-full border',
      { 'size-10 lg:size-8': !fixedSize },
      { 'size-8': fixedSize }
    );
    const circleColorClass = classnames(
      disabled
        ? 'border-black/10'
        : color === 'dark'
          ? 'border-black/60 group-hover:border-black/100 group-active:border-black/100'
          : 'border-white/20 group-hover:border-white/100 group-active:border-white/100'
    );

    const circleClass = classnames(circleBaseClass, circleColorClass);

    return (
      <button className={buttonClass} ref={ref} disabled={disabled} {...props}>
        <span className={circleClass}>
          <Icon name={icon} size={iconSize} />
        </span>
        {label ? <span>{label}</span> : <></>}
      </button>
    );
  }
);

export default CircleButton;
