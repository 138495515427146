import RichText from '@/components/cms/RichText';
import ResponsiveImage from '@/components/ui/ResponsiveImage';
import classnames from 'classnames';
import {
  LargeCarouselBgColorClasses,
  LargeCarouselColor,
  LargeCarouselItemProps
} from '@/components/blocks/LargeCarousel/utils/constants';
import { getThumbnailImageUrl } from '@/components/ui/SearchResults/Snippets/helpers';
import { useMemo } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';

interface LargeCarouselSlideGalleryProps {
  item: LargeCarouselItemProps;
}

const LargeCarouselSlideGallery: React.FC<LargeCarouselSlideGalleryProps> = ({
  item: itemData
}) => {
  const lg = useMediaQuery(ScreenSizeQueries.lg);

  const item = itemData as LargeCarouselItemProps;
  const heading = item?.heading || item?.displayName || undefined;
  const color = item?.itemVariant || LargeCarouselColor.Charcoal;
  const caption = item?.caption || undefined;
  const imageUrl = item?.slideImage?.url || getThumbnailImageUrl(item?.code);
  const mobileImageUrl = item?.mobileImage?.url;

  const imageSrc = useMemo(() => {
    if (lg) {
      return imageUrl || mobileImageUrl;
    }
    return mobileImageUrl || imageUrl;
  }, [lg, imageUrl, mobileImageUrl]);

  return (
    <div
      className={classnames(
        'relative aspect-video overflow-hidden rounded-lg',
        LargeCarouselBgColorClasses[color]
      )}
    >
      {imageSrc && (
        <ResponsiveImage
          src={imageSrc}
          alt={heading}
          aspectRatio={16 / 9}
          imageSizes={{ xs: '330px', sm: '720px', lg: '1280px' }}
        />
      )}

      {caption && (
        <div
          className={classnames(
            'bg-[linear-gradient(180deg,rgba(0,0,0,0)_0%,#000_100%)]',
            'absolute bottom-0 left-0 w-full px-4 pb-4 pt-8',
            'sm:px-6 sm:pb-6 sm:pt-10',
            'lg:px-6 lg:pb-6 lg:pt-[60px]'
          )}
        >
          <RichText className={classnames('text-2 text-white')} content={caption} />
        </div>
      )}
    </div>
  );
};

export default LargeCarouselSlideGallery;
