import type {
  Location,
  IRegistrationDetails,
  LibraryItem,
  ISearchResultUserInfo,
  IKeywordItem
} from '@/@types/client-api';
import type { DateType, DateObject, DateRange } from '@/components/ui/DateTag';
import { EyebrowProps } from '@/components/ui/Eyebrow/Eyebrow';
import type { SnippetPrimaryStatus } from '../../Snippet/Snippet';

import searchableTypeToSnippetVariant, {
  type SearchableType
} from '../searchableTypeToSnippetVariant';
import type { ThumbnailProps } from '../../Thumbnail';
import { CLIENT_API_PATH } from '@/redux/api';
import type { RenderSnippetProps, SnippetContext } from './types';
import { AnalyticsContext } from '@/analytics/constants';
import { SnippetButtonLabel } from './buttonsLabels';

export const isOnDemandProgram = (variant: SearchableType) =>
  searchableTypeToSnippetVariant(variant) === 'ondemandprogram';
export const isOnDemandSegment = (variant: SearchableType) =>
  searchableTypeToSnippetVariant(variant) === 'ondemandsegment';
export const isLiveProgram = (variant: SearchableType) =>
  searchableTypeToSnippetVariant(variant) === 'liveprogram';
const isPublication = (variant: SearchableType) =>
  searchableTypeToSnippetVariant(variant) === 'book';

const IntlDateTimeOnlyDayOptions = (timeZone: string) => ({
  weekday: undefined,
  month: undefined,
  day: 'numeric' as const,
  year: undefined,
  timeZone
});

const IntlDateTimeOnlyMonthOptions = (timeZone: string) => ({
  weekday: undefined,
  month: 'short' as const,
  day: undefined,
  year: undefined,
  timeZone
});

const getProgressFromPercentage = (number: number, percentage: number): number => {
  return (number / 100) * percentage;
};

export const getIsSavedToLibrary = ({
  isFavorite,
  isPurchased,
  isArchived
}: Pick<ISearchResultUserInfo, 'isFavorite' | 'isPurchased' | 'isArchived'>): boolean => {
  if (isFavorite) {
    return true;
  }
  if (isPurchased && !isArchived) {
    return true;
  }
  return false;
};

export const aggregateUserData = ({
  userList,
  variant
}: {
  userList: ISearchResultUserInfo[] | null;
  variant: SearchableType;
}):
  | (Omit<ISearchResultUserInfo, 'isFavorite' | 'userList' | 'registrationDetails'> & {
      isPurchaseRequired: boolean;
      isSavedToLibrary: boolean;
      registrationDetails: IRegistrationDetails[];
      attendanceFormats: string[];
    })
  | null => {
  if (!userList) {
    return null;
  }
  const isPurchaseRequired = (userList || [])
    .map(userItem => {
      const { isPurchased: isUserItemPurchased = false, price = 9999, hasPlusAccess } = userItem;
      return getIsUserPurchaseRequired(isUserItemPurchased, price, variant, hasPlusAccess);
    })
    .reduce((acc, isItemPurchaseRequired) => {
      if (!isItemPurchaseRequired) {
        acc = isItemPurchaseRequired;
      }
      return acc;
    }, true);

  const isPurchased = (userList || []).reduce((acc, { isPurchased }) => {
    if (isPurchased) {
      acc = isPurchased;
    }
    return acc;
  }, false);

  const isParentProgramPurchased = (userList || []).reduce((acc, { isParentProgramPurchased }) => {
    if (isParentProgramPurchased) {
      acc = isParentProgramPurchased;
    }
    return acc;
  }, false);

  const watchUrl = (userList || []).reduce<string | undefined>((acc, { watchUrl }) => {
    if ((isPurchased || isParentProgramPurchased) && watchUrl) {
      acc = watchUrl;
    }
    return acc;
  }, undefined);

  const playbackProgressPct = (userList || [])
    .map(userItem => userItem?.playbackProgressPct || 0)
    .reduce((acc, itemProgressPct) => {
      if (itemProgressPct > acc) {
        return itemProgressPct;
      }
      return acc;
    }, 0);

  const cleProgressPct = (userList || [])
    .map(userItem => userItem?.cleProgressPct || 0)
    .reduce((acc, itemCleProgressPct) => {
      if (itemCleProgressPct > acc) {
        return itemCleProgressPct;
      }
      return acc;
    }, 0);

  const isPlusExpirationSoon = (userList || [])
    .map(userItem => userItem.isPlusExpirationSoon)
    .reduce((acc, isItemPlusExpirationSoon) => {
      if (isItemPlusExpirationSoon) {
        acc = isItemPlusExpirationSoon;
      }
      return acc;
    }, false);

  const plusExpirationDateFormatted = (userList || [])
    .map(userItem => userItem.plusExpirationDateFormatted)
    .reduce((acc, itemPlusExpirationDateFormatted) => {
      if (itemPlusExpirationDateFormatted) {
        acc = itemPlusExpirationDateFormatted;
      }
      return acc;
    }, null);

  const isSavedToLibrary = (userList || [])
    .map(userItem =>
      getIsSavedToLibrary({
        isFavorite: userItem.isFavorite,
        isPurchased: userItem.isPurchased,
        isArchived: userItem.isArchived
      })
    )
    .reduce((acc, isItemSavedToLibrary) => {
      if (isItemSavedToLibrary) {
        acc = isItemSavedToLibrary;
      }
      return acc;
    }, false);

  const registrationDetails = (userList || [])
    .filter(userItem => {
      if (!userItem.registrationDetails) {
        return false;
      }
      const { startDateUtc = '', endDateUtc = '' } = userItem.registrationDetails;
      const startDate = new Date(startDateUtc || 0).setHours(0, 0, 0, 0);
      const endDate = new Date(endDateUtc || 0).setHours(0, 0, 0, 0);
      const today = new Date().setHours(0, 0, 0, 0);
      if (startDate >= today && endDate >= today) {
        return true;
      }
      return false;
    })
    .map(userItem => userItem.registrationDetails) as IRegistrationDetails[];

  const attendanceFormats = registrationDetails.map(
    registrationDetail => registrationDetail.format!
  );

  return {
    isPurchaseRequired,
    isPurchased,
    playbackProgressPct,
    cleProgressPct,
    isPlusExpirationSoon,
    plusExpirationDateFormatted,
    isSavedToLibrary,
    registrationDetails: registrationDetails ?? [],
    attendanceFormats,
    watchUrl,
    isParentProgramPurchased
  };
};

export const getFormattedLocationDates = ({
  startTimeUtc,
  endTimeUtc,
  timezoneIdentifier
}: Location) => {
  const startDate = new Date(startTimeUtc ?? 0);
  const endDate = new Date(endTimeUtc ?? 0);

  const range = new Intl.DateTimeFormat('en-US', {
    weekday: undefined,
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: timezoneIdentifier ?? 'UTC'
  }).formatRange(startDate, endDate);

  return range;
};

export const getAttendanceInfo = (
  attendanceFormats?: string[],
  userAttendanceFormats?: string[]
): string | null => {
  const attendance: string[] = [];
  const attendanceLabel = userAttendanceFormats?.length ? 'Attending' : 'Attend';
  const formats = userAttendanceFormats?.length ? userAttendanceFormats : attendanceFormats;
  const connector = userAttendanceFormats?.length ? ' and ' : ' or ';
  if (formats?.length) {
    if (formats.some(format => format?.toLowerCase()?.includes('webcast'))) {
      attendance.push('Online');
    }
    if (formats.some(format => format?.toLowerCase()?.includes('person'))) {
      attendance.push('In-Person');
    }
    return `${attendanceLabel} ${attendance.join(connector)}`;
  }
  return null;
};

export const getIsRegistrationAvailable = (locations: LibraryItem['locations']) => {
  return locations?.some(location => !location.hasZeroInventory);
};

export const getIsProgramLive = (locations: LibraryItem['locations']) =>
  locations?.some(location => location.isOnAir);

export const mapPublicationEyebrows = (formats: string[]): EyebrowProps[] => {
  return formats
    .filter(format => format.toLowerCase() !== 'other')
    .map(
      (format: string): EyebrowProps => ({
        label: format,
        icon: 'publications'
      })
    );
};

export const mapPodcastEyebrows = (formats: string[] | null): EyebrowProps[] => {
  if (!formats) return [];
  return formats
    .filter(format => format && format !== 'Other')
    .map((format: string) => ({ label: format, icon: 'microphone' }));
};

export const getFullImageUrl = (imageUrl?: string | null | undefined) =>
  imageUrl ? `${window.env?.CONTENT_DELIVERY_API}${imageUrl}` : '';

export const getThumbnailImageUrl = (variationPk: string | null | undefined) =>
  variationPk ? `${window.env?.CONTENT_DELIVERY_API}/api/client/product/${variationPk}/image` : '';

export const getIsUserPurchaseRequired = (
  userIsPurchased: boolean,
  price: number,
  variant: SearchableType,
  hasMembership?: boolean
): boolean => {
  if (isOnDemandProgram(variant) || isOnDemandSegment(variant)) {
    if (price > 0 && !userIsPurchased) {
      return true;
    }
    if (price === 0 || userIsPurchased) {
      return false;
    }
  }

  if (isLiveProgram(variant)) {
    if (price > 0 && !userIsPurchased) {
      return true;
    }
    return false;
  }

  if (isPublication(variant)) {
    if (hasMembership || (!hasMembership && userIsPurchased)) {
      return false;
    }
    return true;
  }

  return true;
};

export const getCreditRequestDetails = ({
  creditRequestStatus,
  cleProgressPct
}: Pick<LibraryItem, 'cleProgressPct'> & { creditRequestStatus: string }):
  | Extract<
      SnippetPrimaryStatus,
      'credits-available' | 'credits-requested' | 'certificates-available'
    >
  | undefined => {
  if (cleProgressPct) {
    if (creditRequestStatus === 'CanRequest') {
      return 'credits-available';
    }
    if (creditRequestStatus === 'Pending') {
      return 'credits-requested';
    }
    if (creditRequestStatus === 'Issued') {
      return 'certificates-available';
    }
  }

  return undefined;
};

export const getThumbnailIconAndTags = ({
  variant,
  runTimeSeconds = 0,
  playbackProgressPct,
  locations,
  registrationDetails,
  isPurchaseRequired = false,
  isAccessExpired = false,
  isLive = false,
  isPrerelease = false
}: {
  variant: SearchableType;
  runTimeSeconds: number;
  playbackProgressPct?: number;
  locations?: Location[] | null;
  registrationDetails?: IRegistrationDetails[] | null;
  isPurchaseRequired?: boolean;
  isAccessExpired?: boolean;
  isLive?: boolean;
  isPrerelease?: boolean;
}) => {
  let tag = undefined;
  let children;
  let icon;
  let dates;

  if (isOnDemandProgram(variant) || isOnDemandSegment(variant)) {
    if (isAccessExpired) {
      icon = undefined;
    } else if (isPrerelease) {
      icon = { name: 'email', label: SnippetButtonLabel.notifyMe };
    } else if (!isPurchaseRequired) {
      icon = { name: 'play', label: 'Launch now' };
    } else {
      icon = { name: 'cart', label: SnippetButtonLabel.buyNow };
    }
  }

  if (isOnDemandProgram(variant)) {
    if (runTimeSeconds >= 3600) {
      const durationInHours = Math.round(runTimeSeconds / 3600);
      children = `${durationInHours} hour${durationInHours > 1 ? 's' : ''}`;
    } else if (runTimeSeconds > 0) {
      const durationInMinutes = Math.floor(runTimeSeconds / 60);
      children = `${durationInMinutes} minute${durationInMinutes > 1 ? 's' : ''}`;
    }
    if (children) {
      tag = { children };
    }
  } else if (isOnDemandSegment(variant)) {
    let segmentProgressLeft;
    if (!playbackProgressPct) {
      if (runTimeSeconds > 0) {
        if (runTimeSeconds >= 3600) {
          const durationInHours = Math.round(runTimeSeconds / 3600);
          children = `${durationInHours} hour${durationInHours > 1 ? 's' : ''}`;
          tag = { children };
        } else {
          const durationInMinutes = Math.floor(runTimeSeconds / 60);
          children = `${durationInMinutes} minute${durationInMinutes > 1 ? 's' : ''}`;
          tag = { children };
        }
      }
    } else {
      segmentProgressLeft = Math.round(
        runTimeSeconds - getProgressFromPercentage(runTimeSeconds, playbackProgressPct)
      );
      if (segmentProgressLeft > 0) {
        const hoursLeft = Math.floor(segmentProgressLeft / 3600);
        const minutesLeft = Math.round((segmentProgressLeft % 3600) / 60);
        children = hoursLeft > 0 ? `${hoursLeft}h${minutesLeft}m left` : `${minutesLeft}m left`;
      } else {
        children = null;
      }
    }
    if (children) {
      tag = { variant: 'transparent' as const, children };
    }
  }

  if (isLiveProgram(variant)) {
    if (isLive) {
      if (!isPurchaseRequired) {
        icon = { name: 'play', label: 'Join now' };
      } else {
        icon = { name: 'cart', label: SnippetButtonLabel.buyNow };
      }
      tag = { children: 'Live', variant: 'emphasis' as const };
    } else {
      if (registrationDetails?.length) {
        /* Getting the first aggregated item in the user data array assuming it's the earliest upcoming date */
        if (registrationDetails[0]?.format?.toLowerCase().includes('webcast')) {
          icon = { name: 'global', label: 'View registration' };
        } else if (registrationDetails[0]?.format?.toLowerCase().includes('person')) {
          icon = { name: 'location-pin', label: 'View registration' };
        }
      } else {
        if (getIsRegistrationAvailable(locations)) {
          icon = { name: 'upcoming', label: 'Register now' };
        } else {
          icon = undefined;
        }
      }

      let dateTags: DateType | null = null;
      let datesToUse: IRegistrationDetails[] | null = null;
      if (registrationDetails?.length) {
        datesToUse = registrationDetails;
      } else if (locations?.length) {
        datesToUse = locations.map(location => ({
          startDateUtc: location.startTimeUtc!,
          endDateUtc: location.endTimeUtc!,
          timezoneIdentifier: location.timezoneIdentifier || 'UTC'
        }));
      }

      let registrationDates: (DateObject | DateRange)[] = [];
      if (datesToUse?.length) {
        datesToUse = [
          ...new Map(datesToUse.map(d => [`${d.startDateUtc}${d.endDateUtc}`, d])).values()
        ];

        registrationDates = datesToUse.map(dateToUse => {
          const startDate = new Date(dateToUse.startDateUtc ?? 0);
          const endDate = new Date(dateToUse.endDateUtc ?? 0);
          const timezone = dateToUse.timezoneIdentifier || 'UTC';
          const startDay = new Intl.DateTimeFormat('en-US', {
            ...IntlDateTimeOnlyDayOptions(timezone)
          }).format(startDate);
          const endDay = new Intl.DateTimeFormat('en-US', {
            ...IntlDateTimeOnlyDayOptions(timezone)
          }).format(endDate);
          const startMonth = new Intl.DateTimeFormat('en-US', {
            ...IntlDateTimeOnlyMonthOptions(timezone)
          }).format(startDate);
          const endMonth = new Intl.DateTimeFormat('en-US', {
            ...IntlDateTimeOnlyMonthOptions(timezone)
          }).format(endDate);

          let formattedDate;

          if (startDate && endDate) {
            if (startMonth === endMonth) {
              if (startDay === endDay) {
                formattedDate = { month: startMonth, day: `${startDay}` };
              } else {
                formattedDate = { month: startMonth, day: `${startDay}–${endDay}` };
              }
            } else {
              formattedDate = {
                startDate: { month: startMonth, day: `${startDay}` },
                endDate: { month: endMonth, day: `${endDay}` }
              };
            }
          } else {
            // fallback
            formattedDate = { month: '', day: '' };
          }

          return formattedDate;
        });
      }

      if (registrationDates.length > 1) {
        dateTags = { multipleDates: registrationDates };
      } else if (registrationDates.length === 1) {
        dateTags = registrationDates[0];
      }
      dates = dateTags;
    }
  }

  /* override everything if access is expired */
  if (isAccessExpired) {
    children = undefined;
    tag = children;
  }

  return { tag, icon, dates };
};

export const getLiveProgramThumbnail = ({
  locations,
  registrationDetails,
  title,
  isLive = false,
  variationPk,
  runTimeSeconds = 0,
  isPurchaseRequired = false,
  streamingUrl,
  action,
  renderButtonWrapper
}: {
  locations: Location[] | null;
  registrationDetails?: IRegistrationDetails[] | null;
  title: string | null;
  isLive: boolean;
  variationPk: string | null;
  runTimeSeconds?: number;
  isPurchaseRequired: boolean;
  streamingUrl?: string | null;
  action: (() => void) | undefined;
  renderButtonWrapper: ThumbnailProps['renderButtonWrapper'];
}): ThumbnailProps => {
  return {
    image: {
      url: getThumbnailImageUrl(variationPk),
      alt: title || ''
    },
    ...(isLive &&
      streamingUrl && {
        video: {
          url: streamingUrl,
          autoPlay: true
        }
      }),
    onClick: action,
    renderButtonWrapper: renderButtonWrapper,
    ...getThumbnailIconAndTags({
      isLive,
      registrationDetails,
      variant: 'liveprogram',
      locations,
      runTimeSeconds,
      isPurchaseRequired
    })
  };
};

export const getOnDemandThumbnail = ({
  variant,
  userInfo,
  data: { title, variationPk, runTimeSeconds = 0 },
  isPurchaseRequired = true,
  isAccessExpired = false,
  isPrerelease = false,
  action,
  renderButtonWrapper
}: {
  userInfo: ISearchResultUserInfo;
  data: RenderSnippetProps['data'];
  variant: SearchableType;
  isPurchaseRequired: boolean;
  isAccessExpired?: boolean;
  isPrerelease?: boolean;
  action: (() => void) | undefined;
  renderButtonWrapper: ThumbnailProps['renderButtonWrapper'];
}): ThumbnailProps => {
  const { playbackProgressPct } = userInfo || {};
  return {
    image: {
      url: getThumbnailImageUrl(variationPk),
      alt: title || ''
    },
    ...getThumbnailIconAndTags({
      variant,
      runTimeSeconds,
      playbackProgressPct,
      isPurchaseRequired,
      isAccessExpired,
      isPrerelease
    }),
    onClick: action,
    renderButtonWrapper: renderButtonWrapper,
    progressValue: playbackProgressPct,
    showProgress:
      isOnDemandSegment(variant) || (isOnDemandProgram(variant) && playbackProgressPct === 100)
  };
};

export const getInlineListProps = (item: IKeywordItem) => {
  const isBEUrl = item.url?.startsWith(CLIENT_API_PATH);
  const href = item.url?.trim() || '#';

  return {
    href: isBEUrl ? window.env?.CONTENT_DELIVERY_API + href : href,
    text: item.keyword ?? '',
    key: item.keyword
  };
};

export const hasMultipleRecordings = (userList?: ISearchResultUserInfo[] | null): boolean =>
  (userList?.length ?? 0) > 1;

export const getIsLiveProgramExpired = (libraryItem: LibraryItem) => {
  const { endTimeUtc, isPurchased = false, price = 0, registrationDetails } = libraryItem;
  if (!getIsUserPurchaseRequired(isPurchased, price, 'liveprogram') && registrationDetails) {
    const endDate = new Date(endTimeUtc || 0).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    if (endDate < today) {
      return true;
    }
  }
  return false;
};

export const getIsLiveProgramExpiredAndOnDemandAvailable = (libraryItem: LibraryItem) =>
  getIsLiveProgramExpired(libraryItem) && libraryItem?.odProgram;

export const launchCreditRequestPopup = (certificateRequestUrl: string) =>
  window.open(certificateRequestUrl, 'popup', 'width=550,height=600');

export const snippetToAnalyticsContext = (
  context?: SnippetContext
): AnalyticsContext | undefined => {
  if (context === 'search') {
    return AnalyticsContext.Search;
  }
  if (context === 'library') {
    return AnalyticsContext.Library;
  }
  return undefined;
};
