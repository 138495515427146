import { isLiveProgramVariation, ProgramVariation } from '@/hooks/PDP/useProgramCatalogRelations';
import Modal, { ModalProps } from '../../Modal/Modal';
import ModalContent from '../../Modal/ModalContent';
import Button, { ButtonColor, ButtonSize } from '../../Buttons/Button';
import { getProductImageUrl } from '../helpers';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import mapIcon from '@/assets/registration-in-person.png';
import Icon from '../../Icon';
import { getFormatedDateRangeParts, getFormatedMonthYear } from '@/lib/helpers/date';
import { OnDemandWebVariation } from '@/@types/content';

export type MemsuiteModalProps = ModalProps & {
  programVariation: ProgramVariation;
  areOtherOptionsAvailable: boolean;
  registerFromRosterAction?: () => void;
  requestGroupcastAction?: () => void;
  labels: {
    modalTitle: string;
    toChooseAnotherOptionLive: string;
    toChooseAnotherOptionOd: string;
    registerFromRosterButton: string;
    requestGroupcastButton: string;
    groupcastUnavailableAdvice: string;
    recorded: string;
    online: string;
    inPerson: string;
    groupcast: string;
  };
  customModalId?: string;
  location?: string; // For Segments, which do not have Location attached on variant
};

const MemsuiteModal = ({
  open,
  setOpen,
  programVariation,
  areOtherOptionsAvailable = false,
  registerFromRosterAction,
  requestGroupcastAction,
  labels,
  customModalId,
  location
}: MemsuiteModalProps) => {
  const isLive = isLiveProgramVariation(programVariation?.contentType?.slice(-1)[0] ?? '');

  const otherOptionAdviceText = isLive
    ? labels.toChooseAnotherOptionLive
    : labels.toChooseAnotherOptionOd;

  const buttonBaseProps = {
    iconRight: 'link-out',
    color: 'outline-black' as ButtonColor,
    className: '!justify-center',
    size: 'large' as ButtonSize
  };

  const selectedEcomVariant = useSelector((state: RootState) => state?.pdp?.selectedVariant);

  const getImage = () => {
    if (!isLive)
      return (
        <img
          src={getProductImageUrl(programVariation.code!) + '?width=64&height=64'}
          role="presentation"
          className="size-12 rounded"
        />
      );
    if (selectedEcomVariant?.type === 'in-person' || selectedEcomVariant?.type === 'groupcast')
      return <img src={mapIcon} role="presentation" className="size-12 rounded" />;
    else
      return (
        <div className="flex size-12 items-center justify-center rounded border border-gray-light bg-gray-lightest">
          <Icon name="global" size="large" />
        </div>
      );
  };

  const getProgramFormatLabel = () => {
    if (!isLive)
      return `${labels.recorded} ${getFormatedMonthYear((programVariation as OnDemandWebVariation)?.publicationDate ?? '')}`;
    switch (selectedEcomVariant?.type) {
      case 'in-person':
        return labels.inPerson;
      case 'online':
        return labels.online;
      case 'groupcast':
        return labels.groupcast;
    }
  };

  const getLiveAttendanceDetails = () => {
    if (!isLive) return <></>;

    let formattedDate = undefined;
    if (
      selectedEcomVariant?.timeZoneIdentifier &&
      selectedEcomVariant?.eventStartDate &&
      selectedEcomVariant?.eventEndDate
    )
      formattedDate = getFormatedDateRangeParts(
        selectedEcomVariant?.timeZoneIdentifier,
        selectedEcomVariant?.eventStartDate,
        selectedEcomVariant?.eventEndDate
      );

    return (
      <div className="text-2 flex flex-col gap-4 text-gray-dark lg:label">
        {selectedEcomVariant?.type !== 'online' && (
          <span>
            <p>{selectedEcomVariant?.address1}</p>
            <p>{selectedEcomVariant?.address2}</p>
          </span>
        )}
        {formattedDate && (
          <div className="flex items-center gap-3">
            <span>{formattedDate.date}</span>
            <span className="inline-block h-4 w-px bg-gray-medium"></span>
            <span>{formattedDate.time}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <ModalContent title={labels.modalTitle} fitContent customModalId={customModalId}>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4 pb-6">
            {/*content*/}
            <div className="flex flex-row gap-4">
              {getImage()}
              <div className="text-2-medium flex flex-col gap-4">
                <div className="text-black">
                  <p>{getProgramFormatLabel()}</p>
                  <p>{programVariation?.location ?? location}</p>
                </div>
                {getLiveAttendanceDetails()}
              </div>
            </div>
            {/* program info*/}
            {areOtherOptionsAvailable ? (
              <p className="text-2-fixed-medium pl-16 text-gray-dark">{otherOptionAdviceText}</p>
            ) : (
              <></>
            )}
          </div>

          <div className="flex flex-col gap-4">
            {/* buttons*/}
            {registerFromRosterAction && (
              <Button
                {...buttonBaseProps}
                label={labels.registerFromRosterButton}
                onClick={registerFromRosterAction}
              />
            )}
            {requestGroupcastAction ? (
              <Button
                {...buttonBaseProps}
                label={labels.requestGroupcastButton}
                onClick={requestGroupcastAction}
              />
            ) : (
              <p className="text-2 text-gray-dark">{labels.groupcastUnavailableAdvice}</p>
            )}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default MemsuiteModal;
