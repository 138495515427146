import ExpandableRichText from '../ExpandableRichText';
import { isCHBVariant, PublicationVariation } from '@/hooks/PDP/usePublicationCatalogRelations';
import { chbOverviewFallback } from './labels';

interface IPublicationOverviewProps {
  heading: string;
  variant: PublicationVariation;
  condensed?: boolean;
}

const PublicationOverview = ({ heading, variant }: IPublicationOverviewProps) => {
  const content = variant?.enrichedDescription?.length
    ? variant.enrichedDescription
    : variant.description;
  const fallback = isCHBVariant(variant)
    ? chbOverviewFallback.replace('{0}', variant.displayName ?? '')
    : content;
  return (
    <div id="overview" className="container module-spacing-pdp flex flex-col gap-8 max-sm:gap-6">
      <h2 className="heading-5">{heading}</h2>
      <ExpandableRichText className="text-1" content={content?.length ? content : fallback} />
    </div>
  );
};

export default PublicationOverview;
