import { memo } from 'react';
import type { BrowseByCreditBlock as BrowseByCreditBlockProps } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';

const BrowseByCreditBlock: React.FC<BrowseByCreditBlockProps> = memo(({ mainContentArea }) => (
  <div className="size-full min-h-screen">
    <ContentArea
      className="my-16 space-y-16"
      components={mainContentArea}
      componentsProps={{
        defaultFacetGroups: [{ groupTypeId: 'CreditRegion', selectDefaultValues: true }]
      }}
    />
  </div>
));

export default BrowseByCreditBlock;
