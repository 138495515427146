import ProductDetailHeader from '@/components/ui/PDP/ProductDetailHeader';
import {
  DownloadVariation,
  OnDemandProgramCatalogRelations
} from '@/hooks/PDP/useProgramCatalogRelations';
import { useOnDemandProgramVariantSelection } from '../../../hooks/PDP/useProgramVariantSelection';
import { useActions } from '@/hooks/PDP/useActions';
import PDPShimmer from './PDPShimmer';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useSelector } from 'react-redux';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { RootState } from '@/redux/store';
import classnames from 'classnames';
import OnDemand from './ECommBox/OnDemand';
import {
  getNotifyPanelItems,
  getExpiredStatus,
  getOnDemandProgramRuntime,
  getProductImageUrl
} from './helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MemsuiteModalLabels } from './labels';
import ShareModal from './Modals/ShareModal';
import usePDPLayout from '@/hooks/PDP/usePDPLayout';
import PDPLayout from '@/components/cms/PDPLayout';
import DownloadModal from './Modals/DownloadModal';
import ProgramExpired from './ECommBox/ProgramExpired';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { PDPFooter } from './PDPFooter';
import NotifyPanel from './ECommBox/NotifyPanel';
import { LiveProgramSeminarVariation } from '@/@types/content';
import { AnalyticsContext } from '@/analytics/constants';
import { useMemsuite } from '@/hooks/PDP/useMemsuite';
import MemsuiteModal from './Modals/MemsuiteModal';
import useCatalogLabels from '@/hooks/PDP/useCatalogLabels';
import { useDownloadModal } from '@/hooks/PDP/useDownloadModal';
import { useShareModal } from '@/hooks/PDP/useShareModal';
import { useTrackViewItem } from '@/analytics/pdp';
import { useFetchProgramMaterials } from '@/hooks/PDP/useFetchProgramMaterials';
import { useLibraryItems } from '@/hooks/PDP/useLibraryItems';
import { useProgramCreditLicenses } from '@/hooks/PDP/useProgramCreditLicenses';
import { useCreditTrackerCodeSearchParam } from '@/hooks/useCreditTrackerCode';
import { ItemAnalyticsModel } from '@/@types/client-api';
import { ProductSEO } from './ProductSEO';

interface OnDemandProgramPDPProps {
  catalogRelations: OnDemandProgramCatalogRelations;
  loadingCatalogRelations?: boolean;
  isProgramSegmentsLoading?: boolean;
  selectDownloadByDefault?: DownloadVariation;
  context: AnalyticsContext;
}
const OnDemandProgramPDP = ({
  catalogRelations,
  loadingCatalogRelations,
  isProgramSegmentsLoading,
  selectDownloadByDefault,
  context
}: OnDemandProgramPDPProps) => {
  const { onDemandEcomLabels, programExpiredLabels } = useCatalogLabels();
  const { programVariants, initialVariantSelection } = catalogRelations;
  const [itemAnalyticsModel, setItemAnalyticsModel] = useState<ItemAnalyticsModel>();

  const programPks = useMemo(
    () =>
      programVariants.length
        ? programVariants.map(pv => pv.code!)
        : [initialVariantSelection.code!],
    [initialVariantSelection.code, programVariants]
  );

  const {
    hydrateLibrary,
    selectLibraryItems,
    selectLibraryItem,
    selectLibraryItemWithFetchingState,
    isLibraryItemsFetching
  } = useLibraryItems();
  useEffect(() => {
    hydrateLibrary(programPks);
  }, [hydrateLibrary, programPks]);
  const ecomLibraryItems = selectLibraryItems(programPks);
  const isLibraryFetching = isLibraryItemsFetching(programPks);
  useFetchProgramMaterials(ecomLibraryItems);

  const { selectedVariant } = useOnDemandProgramVariantSelection(
    initialVariantSelection,
    programVariants
  );
  useTrackViewItem(selectedVariant.code);
  const selectedVariantLibraryItem = selectLibraryItemWithFetchingState(selectedVariant.code);
  const creditTrackerCode = useCreditTrackerCodeSearchParam();

  useEffect(() => {
    const itemLibrary = selectLibraryItem(selectedVariant.code);
    if (itemLibrary) setItemAnalyticsModel(itemLibrary.itemAnalyticsModel);
  }, [selectedVariant.code, selectLibraryItem]);

  const {
    licenses,
    isLicenseFetching,
    loggedOutLicenseProps,
    relevantCredits,
    isRelevantCreditsLoading
  } = useProgramCreditLicenses([selectedVariant.code!]);

  const isExpired = getExpiredStatus(selectedVariant);

  const runtimeFormatted = useMemo(
    () =>
      selectedVariant.customDuration?.length
        ? selectedVariant.customDuration
        : getOnDemandProgramRuntime(selectedVariant.runTimeSeconds).actionBar,
    [selectedVariant.customDuration, selectedVariant.runTimeSeconds]
  );
  const shareModalProps = useShareModal(selectedVariant, itemAnalyticsModel, context);

  const downloadModalProps = useDownloadModal(selectedVariant, selectDownloadByDefault);

  const memsuiteProps = useMemsuite(selectedVariant, catalogRelations);

  const actions = useActions(
    selectedVariant,
    catalogRelations,
    selectedVariantLibraryItem,
    shareModalProps.setOpen,
    downloadModalProps.setOpen,
    memsuiteProps.actionBarMode === 'memsuite' ? memsuiteProps.setOpen : undefined,
    memsuiteProps.actionBarMode === 'roster' ? memsuiteProps.registerFromRosterAction : undefined
  );
  const actionBarProps = {
    runtimeFormatted,
    creditFormatted: relevantCredits,
    isCreditLoading: isRelevantCreditsLoading,
    actions,
    shareModalId: shareModalProps.customModalId,
    downloadModalId: downloadModalProps.customModalId
  };

  const pageLinkPaths = useSelector(selectPageLinkPaths);

  const liveProgramCardProps = useMemo(() => {
    const { program, isLoading } = catalogRelations.liveProgramCrosslink;
    if (!program && !isLoading) return undefined;
    return {
      url: program?.contentLink?.url,
      eventStartDate: program?.eventStartDate,
      eventEndDate: program?.eventEndDate,
      segments: (program as LiveProgramSeminarVariation)?.segments,
      imageUrl: program?.code ? getProductImageUrl(program?.code) : undefined,
      labels: onDemandEcomLabels,
      programTitle: program?.displayName,
      location: (program as LiveProgramSeminarVariation)?.location,
      city: (program as LiveProgramSeminarVariation)?.venue?.city as string,
      isLoading,
      context: AnalyticsContext.PDP
    };
  }, [catalogRelations.liveProgramCrosslink, onDemandEcomLabels]);

  const generateEcomBox = useCallback(
    (isMobile?: boolean) => {
      const notifyItems = getNotifyPanelItems(selectedVariant);
      return notifyItems.url != null && notifyItems.url != undefined && notifyItems.url != '' ? (
        <NotifyPanel isMobile={isMobile} notifyItems={notifyItems} />
      ) : isExpired ? (
        <ProgramExpired
          isMobile={isMobile}
          programId={selectedVariant.code!}
          labels={programExpiredLabels}
          pageLinks={pageLinkPaths}
          programCard={{ liveProps: liveProgramCardProps }}
        />
      ) : (
        <OnDemand
          isMobile={isMobile}
          labels={onDemandEcomLabels}
          loadingCatalogRelations={loadingCatalogRelations}
          upcomingLiveProgram={liveProgramCardProps}
          context={context}
          loadingProgramSegments={isProgramSegmentsLoading}
          fetchingLibrary={isLibraryFetching}
          creditTrackerCode={creditTrackerCode}
        />
      );
    },
    [
      selectedVariant,
      isExpired,
      programExpiredLabels,
      pageLinkPaths,
      liveProgramCardProps,
      onDemandEcomLabels,
      loadingCatalogRelations,
      context,
      isProgramSegmentsLoading,
      isLibraryFetching,
      creditTrackerCode
    ]
  );

  const isLarge = useMediaQuery(ScreenSizeQueries.lg);
  const isLoading = useSelector((state: RootState) => state?.pdp?.loading);

  const layout = usePDPLayout({
    layout: selectedVariant.pdpLayoutBlock,
    contentType: 'OnDemandVariation',
    variant: selectedVariant,
    isOndemand: true,
    isOhb: selectedVariant.isOhb
  });

  return (
    <>
      <ProductSEO
        variant={selectedVariant}
        programProduct={catalogRelations.programProduct}
        masterProgram={catalogRelations.masterProgram}
      />
      <div className="container flex flex-col !p-0 lg:flex-row">
        <div className="min-w-px lg:grow">
          {/* Inside Scroll area */}
          <PDPShimmer show={isLoading} />

          <ProductDetailHeader
            title={selectedVariant.displayName ?? ''}
            formatEyebrow={'On-Demand'}
            actionBarProps={actionBarProps}
            className={classnames({ hidden: isLoading })}
            layout={layout}
          />

          {/* Mobile Ecomm-Box */}
          {!isLarge && generateEcomBox(true)}

          <PDPLayout
            layout={layout}
            catalogRelations={catalogRelations}
            className={classnames({
              hidden: isLoading
            })}
            loggedOutLicenseProps={loggedOutLicenseProps}
            isProgramSegmentsLoading={isProgramSegmentsLoading}
            creditTrackerCode={creditTrackerCode}
          />
        </div>
        {/* Desktop Ecomm-Box */}
        {isLarge && (
          <div className="container relative w-[422px] shrink-0 px-0">{generateEcomBox()}</div>
        )}
      </div>
      <PDPFooter product={catalogRelations.programProduct} isLoading={isLoading} />
      <ShareModal {...shareModalProps} />
      {selectedVariant.downloadVariants ? (
        <DownloadModal
          {...downloadModalProps}
          downloadVariants={selectedVariant.downloadVariants}
          licenses={licenses?.items ?? []}
          isLicensesFetching={isLicenseFetching}
          context={context}
        />
      ) : (
        <></>
      )}
      {memsuiteProps.isMemsuiteAllowed && (
        <MemsuiteModal {...memsuiteProps} labels={MemsuiteModalLabels} />
      )}
    </>
  );
};

export default OnDemandProgramPDP;
