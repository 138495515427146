import { FC, useEffect, useMemo, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ContentReference, IContent } from '@/@types/cms';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';
import { Component } from '@/lib/ComponentFactory';
import Image from '@/components/cms/Image';

type GlobalBannerPanelProps = {
  content?: IContent[];
  isOpen: boolean;
  logos: { logoRed: ContentReference; logoWhite: ContentReference };
  onToggleOpen: VoidFunction;
  theme?: string;
};

const GlobalBannerPanel: FC<GlobalBannerPanelProps> = ({
  content,
  isOpen,
  logos,
  onToggleOpen,
  theme
}) => {
  const panelRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (panelRef) {
      if (isOpen) {
        panelRef.current?.focus();
      } else {
        panelRef.current?.blur();
      }
    }
  }, [isOpen, panelRef]);

  const focusVisibleClasses = classnames({
    'focus-visible:outline-black': theme === 'general',
    'focus-visible:outline-white': theme === 'critical' || theme === 'probono'
  });

  const bannerExpandedWrapperClasses = classnames(
    'absolute inset-0 flex w-full flex-col pt-8',
    'sm:pt-[72px]',
    {
      'z-panel': isOpen,
      'bg-white': theme === 'general',
      'bg-black': theme === 'critical',
      'bg-gradient': theme === 'probono'
    }
  );

  const bannerExpandedHeaderClasses = classnames(
    'container mb-9 flex w-full flex-row-reverse justify-between',
    'sm:mb-0'
  );

  const bannerExpandedBodyClasses = classnames('container flex h-full overflow-y-auto');

  const bannerExpandedInnerBodyClasses = classnames(
    'flex flex-col items-start self-center',
    'md:flex-row'
  );

  const bannerTextColors = {
    'text-black': theme === 'general',
    'text-white': theme === 'critical' || theme === 'probono'
  };

  const panel = {
    open: {
      clipPath: 'inset(0 0 0 0)',
      transition: {
        type: 'spring',
        duration: 0.3
      }
    },
    closed: {
      clipPath: 'inset(0 0 100% 0)',
      transition: {
        type: 'spring',
        duration: 0.8
      }
    }
  };

  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  const logo = useMemo(() => {
    const logoColor = theme === 'general' ? logos.logoRed : logos.logoWhite;
    return {
      src: logoColor?.url,
      alt: logoColor?.name as string,
      title: logoColor?.name as string
    };
  }, [theme, logos]);

  const memoizedLogo = useMemo(
    () => (
      <RouterLink
        className={classnames(focusVisibleClasses, 'block basis-[50px] md:basis-[66px]')}
        to="/"
        target="_self"
        reloadDocument
        tabIndex={0}
      >
        <Image className="h-6 md:h-8" {...logo} />
      </RouterLink>
    ),
    [focusVisibleClasses, logo]
  );

  return (
    <motion.section
      className={bannerExpandedWrapperClasses}
      variants={panel}
      tabIndex={isOpen ? 0 : -1}
      ref={panelRef}
    >
      <motion.div variants={variants} className={bannerExpandedHeaderClasses}>
        <button aria-label="dismiss" onClick={onToggleOpen}>
          <Icon name="close" size="large" className={classnames({ ...bannerTextColors })} />
        </button>
        {memoizedLogo}
      </motion.div>
      <motion.div variants={variants} className={classnames(bannerExpandedBodyClasses)}>
        <div className={classnames(bannerExpandedInnerBodyClasses)}>
          {content?.map((block, index) => <Component key={index} {...block} />)}
        </div>
      </motion.div>
    </motion.section>
  );
};

export default GlobalBannerPanel;
