import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseBox from '../components/BaseBox';
import { Variant, selectVariant, setHardSelectedVariant } from '@/redux/slices/pdpSlice';
import { AppDispatch, RootState } from '@/redux/store';
import SingleRecording from './components/SingleRecording';
import Multiplerecordings from './components/Multiplerecordings';
import UpcomingLiveCard, { UpcomingLiveCardProps } from './components/UpcomingLiveCard';
import PanelButton from '@/components/ui/PDP/ECommBox/components/PanelButton';
import { useAddToCart } from '@/hooks/shared/useAddToCart';
import { useLaunch } from '@/hooks/shared/useLaunch';
import useInViewport from '@/hooks/useInViewport';
import Button from '@/components/ui/Buttons/Button';
import ProgramMaterialsModal from '../../Modals/ProgramMaterialsModal';
import { MaterialsLabels } from '../../labels';
import { v4 as uuid4 } from 'uuid';
import { AnalyticsContext } from '@/analytics/constants';
import Loading from './components/Loading';
import { getDiscountAdviceMessage } from '../../helpers';

export interface OnDemandProps {
  labels: {
    watchNow: string;
    recorded: string;
    launchButton: string;
    purchaseButton: string;
    noSelectionMsg: string;
    privilegedMembers?: string;
    seciWsDiscountAdvice?: string;
    extraMessage?: string;
    programId: string;
    upcomingLiveLabel?: string;
    materials?: string;
    transcript?: string;
  };
  upcomingLiveProgram?: UpcomingLiveCardProps;
  isMobile?: boolean;
  title?: string;
  onClosePanel?: () => void;
  seeDetailsLink?: string;
  loadingCatalogRelations?: boolean;
  loadingProgramSegments?: boolean;
  fetchingLibrary?: boolean;
  context?: AnalyticsContext;
  creditTrackerCode?: string;
}

const OnDemand: React.FC<OnDemandProps> = ({
  labels,
  isMobile = false,
  upcomingLiveProgram,
  title,
  onClosePanel,
  seeDetailsLink,
  loadingCatalogRelations,
  loadingProgramSegments,
  fetchingLibrary,
  context,
  creditTrackerCode
}) => {
  const materialModalId = useMemo(() => `material-modal-${uuid4()}`, []);

  const dispatch = useDispatch<AppDispatch>();
  const [displaySelectionError, setDisplaySelectionError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const catalogRelations = useSelector((state: RootState) => state.pdp.catalogRelations);
  const selectedVariant = useSelector((state: RootState) => state.pdp.selectedVariant);
  const hardSelected = useSelector((state: RootState) => state.pdp.hardSelected);
  const nonExpiredVariants = useMemo(
    () => catalogRelations?.variants?.filter(v => !v.isExpired),
    [catalogRelations?.variants]
  );

  const [showCheck, setShowCheck] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const [stickyLaunchButtonRef, isInView, position] = useInViewport<HTMLButtonElement>();

  const withinPanel = !!onClosePanel;

  const {
    addToCart,
    isSuccess: isCartSuccess,
    reset: cartReset,
    isLoading: isCartLoading,
    isError: isCartError
  } = useAddToCart(context, creditTrackerCode);
  const {
    launch,
    isLoading: isLaunchLoading,
    isSuccess: isLaunchSuccess,
    isError: isLaunchError
  } = useLaunch(context, creditTrackerCode);

  useEffect(() => {
    if (nonExpiredVariants?.length === 1) {
      dispatch(setHardSelectedVariant(nonExpiredVariants[0]));
    } else {
      // select first selected variant, if nothing has been hard-selected yet
      if (hardSelected) return;
      const selectedVariant = nonExpiredVariants?.find(variant => variant.purchased);

      if (selectedVariant) {
        dispatch(selectVariant(selectedVariant));
      }
    }
  }, [nonExpiredVariants, dispatch, hardSelected]);

  useEffect(() => {
    if (isCartSuccess || isLaunchSuccess) {
      setShowCheck(true);
      setShowLoading(false);
      setShowError(false);
      setTimeout(() => {
        setShowCheck(false);
        if (isCartSuccess) {
          cartReset();
        }
      }, 1000);
      if (isCartSuccess && withinPanel) {
        onClosePanel?.();
      }
    }
    if (isCartLoading || isLaunchLoading) {
      setShowLoading(true);
    }

    if (isCartError || isLaunchError) {
      setShowError(true);
      setShowLoading(false);
    }
  }, [
    cartReset,
    isCartError,
    isCartLoading,
    isCartSuccess,
    isLaunchError,
    isLaunchLoading,
    isLaunchSuccess,
    onClosePanel,
    withinPanel
  ]);

  const getButtonProps = useCallback(() => {
    const isPurchased = selectedVariant?.purchased;
    const canLaunch = isPurchased || !selectedVariant?.retail;
    const showPrice = !isPurchased;
    return {
      label: canLaunch ? labels.launchButton : labels.purchaseButton,
      price: showPrice ? selectedVariant?.price : undefined,
      strikePrice: showPrice ? selectedVariant?.originalPrice : undefined,
      purchased: isPurchased
    };
  }, [labels, selectedVariant]);

  const handleSelect = useCallback(
    (variant: Variant) => {
      setDisplaySelectionError(false);
      dispatch(selectVariant(variant));
    },
    [dispatch]
  );

  const handleSubmit = useCallback(async () => {
    setDisplaySelectionError(!hardSelected);

    if (hardSelected) {
      if (!selectedVariant?.purchased && selectedVariant?.retail) {
        addToCart(selectedVariant?.code, selectedVariant?.displayName);
      } else {
        launch(
          selectedVariant?.code,
          false,
          selectedVariant?.purchased ? selectedVariant?.launchUrl : undefined
        );
      }
    }
  }, [addToCart, hardSelected, launch, selectedVariant]);

  const handleStickyButton = useCallback(() => {
    if (nonExpiredVariants && nonExpiredVariants.length > 1) {
      setShowModal(true);
    } else {
      handleSubmit();
    }
  }, [nonExpiredVariants, handleSubmit]);

  const [openProgramMaterials, setOpenProgramMaterials] = useState(false);
  const getProgramMaterialsProps = useCallback(() => {
    return {
      programCode: selectedVariant?.code,
      programTitle: selectedVariant?.displayName ?? '',
      materials: selectedVariant?.materials ?? [],
      open: openProgramMaterials,
      setOpen: setOpenProgramMaterials,
      labels: MaterialsLabels
    };
  }, [
    openProgramMaterials,
    selectedVariant?.displayName,
    selectedVariant?.materials,
    selectedVariant?.code
  ]);

  const secondaryButton = useMemo(() => {
    if (hardSelected && selectedVariant?.hasMaterials) {
      return (
        <Button
          label={labels.materials}
          color="outline-black"
          size="large"
          className="mt-4 w-full"
          onClick={() => setOpenProgramMaterials(true)}
          aria-controls={materialModalId}
        />
      );
    }
    return undefined;
  }, [hardSelected, labels.materials, selectedVariant?.hasMaterials, materialModalId]);

  const tertiaryButton = useMemo(() => {
    if (hardSelected && selectedVariant?.transcriptUrl?.length) {
      return (
        <Button
          label={labels.transcript}
          iconRight="link-out"
          color="outline-black"
          size="large"
          className="mt-4 w-full !justify-center"
          href={selectedVariant?.transcriptUrl}
          target="_blank"
        />
      );
    }
  }, [hardSelected, labels.transcript, selectedVariant?.transcriptUrl]);

  if (loadingCatalogRelations || fetchingLibrary || !selectedVariant) {
    return <Loading isMobile={isMobile} />;
  }

  return (
    <>
      <ProgramMaterialsModal
        customModalId={materialModalId}
        {...getProgramMaterialsProps()}
        context={context}
      />
      <BaseBox
        sticky={!isMobile}
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
        withinPanel={withinPanel}
      >
        {withinPanel && (
          <BaseBox.PanelHeader
            productCode={selectedVariant?.code}
            title={title}
            seeDetailsLink={seeDetailsLink}
            context={context}
          />
        )}
        <BaseBox.Content withinPanel={withinPanel}>
          {!withinPanel && <h3 className="text-2 mb-6 font-bold">{labels.watchNow}</h3>}

          {nonExpiredVariants?.length === 1 && (
            <SingleRecording
              labels={labels}
              variant={nonExpiredVariants[0]}
              onClick={handleSubmit}
              isSuccess={showCheck}
              isLoading={showLoading}
              isError={showError}
              loadingProgramSegments={loadingProgramSegments}
            />
          )}
          {nonExpiredVariants && nonExpiredVariants.length > 1 && (
            <Multiplerecordings
              labels={labels}
              onSelect={handleSelect}
              loadingProgramSegments={loadingProgramSegments}
            />
          )}
        </BaseBox.Content>

        <BaseBox.Footer
          primaryButton={
            <PanelButton
              ref={stickyLaunchButtonRef}
              isSuccess={showCheck}
              isLoading={showLoading}
              isError={showError}
              onClick={handleSubmit}
              {...getButtonProps()}
            />
          }
          secondaryButton={secondaryButton}
          tertiaryButton={tertiaryButton}
          noSelectionError={displaySelectionError && labels.noSelectionMsg}
          discountAdviceMessage={getDiscountAdviceMessage(selectedVariant, labels)}
          extraMessage={labels.extraMessage}
          codeLabel={labels.programId}
          codeValue={selectedVariant.code!}
          withinPanel={withinPanel}
        ></BaseBox.Footer>

        {!!upcomingLiveProgram && <UpcomingLiveCard {...upcomingLiveProgram} />}

        {isMobile && (
          <>
            {nonExpiredVariants && nonExpiredVariants.length > 1 && (
              <BaseBox.Modal className="flex h-screen flex-col">
                <BaseBox.Content inModal>
                  <Multiplerecordings labels={labels} onSelect={handleSelect} />
                </BaseBox.Content>
                <BaseBox.Footer
                  primaryButton={
                    <PanelButton
                      ref={stickyLaunchButtonRef}
                      isSuccess={showCheck}
                      isLoading={showLoading}
                      isError={showError}
                      onClick={handleSubmit}
                      {...getButtonProps()}
                    />
                  }
                  noSelectionError={displaySelectionError && labels.noSelectionMsg}
                  discountAdviceMessage={getDiscountAdviceMessage(selectedVariant, labels)}
                  extraMessage={labels.extraMessage}
                  codeLabel={labels.programId}
                  codeValue={selectedVariant.code!}
                ></BaseBox.Footer>
              </BaseBox.Modal>
            )}
            <BaseBox.StickyButton sticky={!isInView && position === 'above'}>
              <PanelButton
                isSuccess={showCheck}
                isLoading={showLoading}
                isError={showError}
                onClick={handleStickyButton}
                {...getButtonProps()}
              />
            </BaseBox.StickyButton>
          </>
        )}
      </BaseBox>
    </>
  );
};

export default OnDemand;
