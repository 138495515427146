import { useCallback, useState } from 'react';

const useModalPanelState = <T>() => {
  const [params, show] = useState<T | undefined>(undefined);

  const close = useCallback(() => {
    show(undefined);
  }, []);

  const isOpen = params !== undefined;

  return {
    params,
    show,
    close,
    isOpen
  };
};

export default useModalPanelState;
