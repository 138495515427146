import { ExpandFilterProps } from './types';
import { forwardRef } from 'react';
import classnames from 'classnames';
import Icon from '../Icon';

const ExpandFilterButton = forwardRef<HTMLButtonElement, ExpandFilterProps>(
  ({ expandText, onExpandFilter, className, ...props }, ref) => {
    return (
      <div className={classnames('flex grow-0', className)}>
        <button
          ref={ref}
          className="text-2 flex items-center gap-1 text-gray-dark hover:text-red"
          onClick={onExpandFilter}
          {...props}
        >
          {expandText}
          <Icon name="chevron-right" size="small" />
        </button>
      </div>
    );
  }
);

export default ExpandFilterButton;
