import { IContent } from '@/@types/cms';
import { CreditClassPage, JurisdictionCreditInfoPage } from '@/@types/content';

export const PreselectTabQueryKey = 'preselectTab';

const baseCDProps = ['secondaryHeader'];

export const regionPageChildrenCDProps = [...baseCDProps, 'contentType', 'mainBody', 'url'];

export const creditClassChildrenCDProps = [
  ...baseCDProps,
  'contentType',
  'name',
  'hidePage',
  'contentLink',
  'pageLinkText',
  'creditAnnouncements',
  'url'
];

export const riverPageChildrenCDProps = [
  ...baseCDProps,
  'routeSegment',
  'name',
  'hidePage',
  'contentLink',
  'dropdownText',
  'mainBody',
  'sourceCode',
  'keyCode',
  'mainContentArea',
  'renderAsLinks'
];
export const creditTypePageCDProps = [
  ...baseCDProps,
  'regionName',
  'creditAnnouncements',
  'approvedMessaged',
  'headerLinks'
];

export const creditInfoChildPageCDProps = [
  ...baseCDProps,
  'contentLink',
  'name',
  'shortDescription',
  'mainBody',
  'url'
];

export type CreditAnnouncements = { creditAnnouncements?: IContent[] };
export type ApprovalMessage = { approvedMessaged: IContent[] };
export type HeaderLinks = { headerLinks: IContent[] };
export type RelatedPageMap = { [key: string]: JurisdictionCreditInfoPage };
export type CreditClassPageMap = { [key: string]: CreditClassPage };
export type CreditTypeAncestor = Pick<
  JurisdictionCreditInfoPage,
  'regionName' | 'secondaryHeader' | 'creditAnnouncements' | 'approvedMessaged' | 'headerLinks'
>;
