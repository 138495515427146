import { IconLinkBlock, RuleChangesPage as RuleChangesPageProps } from '@/@types/content';
import { FC, useCallback, useEffect, useMemo } from 'react';
import withNav from '@/components/hocs/withNav';
import RichText from '@/components/cms/RichText';
import { useGetRuleChangesQuery } from '@/redux/api/client/useCreditInfo';
import { AnnouncementMessage } from '@/@types/client-api';
import CreditInfoAccordionSection from '@/components/blocks/CreditInfo/snippets/CreditInfoAccordionSection';
import { format } from 'date-fns';
import Text from '@/components/cms/Text';
import { LinkItem } from '@/@types/cms';
import ShareButton from '@/components/ui/Buttons/ShareButton';
import { Theme } from '@/styles/darkMode';
import Icon from '@/components/ui/Icon';
import Link, { LinkProps } from '@/components/ui/Link';
import Shimmer from '@/components/ui/Shimmer';
import { formatBreadCrumbs } from '@/components/blocks/CreditInfo/util/creditInfoHelpers';
import Breadcrumbs from '@/components/ui/Breadcrumbs';
import { useDispatch } from 'react-redux';
import { setHeaderTheme } from '@/redux/slices/pageSlice';

interface RuleChange extends AnnouncementMessage {
  regionName: string;
}

const RuleChangesPage: FC<RuleChangesPageProps> = ({
  pageHeader,
  mainBody,
  ruleChangeType,
  headerLinks,
  ...props
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeaderTheme(2));

    return () => {
      dispatch(setHeaderTheme(undefined));
    };
  }, [dispatch]);

  const ruleChangesResult = useGetRuleChangesQuery(ruleChangeType ?? 0);

  const isLoading = useMemo(
    () => ruleChangesResult.isLoading || ruleChangesResult.isFetching,
    [ruleChangesResult.isFetching, ruleChangesResult.isLoading]
  );

  const isCovid = useMemo(() => ruleChangeType === 1, [ruleChangeType]);

  const ruleChanges: RuleChange[] = useMemo(() => {
    if (!ruleChangesResult.data || Object.keys(ruleChangesResult.data).length === 0) return [];
    return Object.entries(ruleChangesResult.data)
      .flatMap(([regionName, announcements]) => announcements.map(a => ({ ...a, regionName })))
      .sort((a, b) => {
        if (a.effectiveDate && b.effectiveDate) {
          return Date.parse(b.effectiveDate) - Date.parse(a.effectiveDate);
        }

        if (a.effectiveDate) {
          return -1;
        }

        if (b.effectiveDate) {
          return 1;
        }

        return a.regionName.localeCompare(b.regionName);
      });
  }, [ruleChangesResult]);

  const currentRuleChanges = useMemo(
    () => ruleChanges.filter(rc => rc.currentlyActive),
    [ruleChanges]
  );

  const expiredRuleChanges = useMemo(
    () =>
      ruleChanges
        .filter(rc => !rc.currentlyActive)
        .sort((a, b) => a.regionName.localeCompare(b.regionName)),
    [ruleChanges]
  );

  const linkItems: (LinkItem & LinkProps)[] = useMemo(() => {
    return (
      headerLinks?.map((a: IconLinkBlock) => {
        const _link = a.link as LinkItem;
        return {
          href: _link.href,
          target: _link.target,
          text: _link.text,
          icon: a.icon
        } as LinkItem & LinkProps;
      }) ?? []
    );
  }, [headerLinks]);

  const renderAccordionSection = useCallback(
    (ruleChanges: RuleChange[], identifier: string, sectionTitle?: string) => {
      if (isLoading) {
        return (
          <div className="space-y-2">
            <Shimmer className="mb-8 h-11 w-full" />
            <Shimmer className="h-20 w-full" />
            <Shimmer className="h-20 w-full" />
            <Shimmer className="h-20 w-full" />
            <Shimmer className="h-20 w-full" />
            <Shimmer className="h-20 w-full" />
          </div>
        );
      }

      const accordionItems = ruleChanges.map((rc, index) => {
        const title = [rc.regionName];
        if (rc.effectiveDate) {
          title.push(format(new Date(rc.effectiveDate), 'MMMM d, yyyy'));
        }

        return {
          value: `${identifier}-${index}`,
          title: title.join(' · '),
          content: (
            <p className="text-1 space-y-6 p-8 pt-0">
              <RichText content={rc.body?.toString()} />
              {rc.jurisdictionUrl && (
                <Link
                  as="a"
                  variant="text-link-1"
                  href={rc.jurisdictionUrl}
                  text={`Browse Programs for ${rc.regionName}`}
                />
              )}
            </p>
          )
        };
      });

      return (
        <CreditInfoAccordionSection
          identifier={identifier}
          accordionSectionHeader={sectionTitle}
          showSectionHeader={true}
          items={accordionItems}
        />
      );
    },
    [isLoading]
  );

  const breadcrumbs = useMemo(
    () => formatBreadCrumbs(props.breadcrumbs, true),
    [props.breadcrumbs]
  );

  return (
    <>
      <section className="relative bg-gray-lightest">
        <div className="container mt-[-160px] flex flex-row flex-wrap items-end justify-between pb-10 pt-[192px] md:flex-nowrap lg:mt-[-96px] lg:pt-[128px]">
          <div className="flex flex-col items-start justify-center">
            {!props.hideInBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            <Text propertyName="h1" element="h1" className="heading-1 my-0 mr-auto mt-20 w-full">
              {pageHeader}
            </Text>
          </div>
          <div className="flex flex-row flex-wrap items-center justify-start space-x-4">
            {linkItems.map((block, i) => (
              <div
                key={`link-${i}`}
                className="text-link group !text-1-medium relative size-fit pb-0 pt-2 font-medium"
              >
                <div className="relative z-1 flex items-center gap-2 py-2 !pb-0 text-black">
                  {!!block.icon && (
                    <div className="rounded-full border p-[7px]">
                      <Icon name={block.icon} size="small" key={`icon-${i}`} />
                    </div>
                  )}
                  <Link
                    className="font-medium no-underline"
                    variant="text-link-1"
                    text={block?.text}
                    href={block?.href}
                    target={block?.target}
                  />
                </div>
              </div>
            ))}
            <ShareButton
              className="text-link group text-2 pb-0 pt-2 font-medium [&>button]:!pb-0"
              shareUrl={props.contentLink?.url ?? window.location.href}
              theme={Theme.Light}
              title={pageHeader}
            />
          </div>
        </div>
      </section>
      <div className="container py-8">
        {mainBody && <RichText className="heading-5-regular py-16 lg:w-4/5" content={mainBody} />}
        {isCovid ? (
          <>
            {renderAccordionSection(currentRuleChanges, 'covid-rule-changes', 'Current Orders')}
            {isCovid &&
              renderAccordionSection(
                expiredRuleChanges,
                'expired-covid-rule-changes',
                'Expired Orders'
              )}
          </>
        ) : (
          renderAccordionSection(ruleChanges, 'recent-rule-changes')
        )}
      </div>
    </>
  );
};

const RuleChangesPageComponent = withNav(RuleChangesPage);
RuleChangesPageComponent.displayName = 'RuleChangesPage';
export default RuleChangesPageComponent;
