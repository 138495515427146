import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import classnames from 'classnames';
import {
  BreakpointFlags,
  ImagesSourceType,
  appendWebpFormat,
  getFallbackSrcWhenSrcSetUnsupported,
  sizesFromBreakpoints,
  sourcesFromBreakpoints,
  srcSetFromWidths
} from '@/lib/helpers/images';
import { BreakpointObjectType, ScreenSizeQueries } from '@/constants/breakpoints';
import React from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';

export interface ResponsiveImageProps {
  alt?: string;
  aspectRatio?: number;
  className?: string;
  imageSizes?: BreakpointObjectType;
  imageSources?: ImagesSourceType;
  onLoad?: () => void;
  src?: string;
  propertyName?: string;
  loading?: 'lazy' | 'eager';
  title?: string;
  skipWrapper?: boolean;
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  alt,
  aspectRatio = 1,
  className,
  imageSizes,
  imageSources,
  onLoad,
  src,
  loading,
  title,
  skipWrapper
}) => {
  const breakPoints: BreakpointFlags = {
    xs: useMediaQuery(ScreenSizeQueries.xs),
    sm: useMediaQuery(ScreenSizeQueries.sm),
    md: useMediaQuery(ScreenSizeQueries.md),
    lg: useMediaQuery(ScreenSizeQueries.lg),
    xl: useMediaQuery(ScreenSizeQueries.xl),
    xxl: useMediaQuery(ScreenSizeQueries['2xl'])
  };

  if (!src) return null;

  const Wrapper = skipWrapper ? React.Fragment : AspectRatio.Root;

  if ('srcset' in document.createElement('img')) {
    const srcSet = srcSetFromWidths(src, { ratio: aspectRatio });
    const sizes = sizesFromBreakpoints(imageSizes);
    const sources = sourcesFromBreakpoints(imageSources);

    return (
      <Wrapper {...(!skipWrapper ? { ratio: aspectRatio } : {})}>
        <picture>
          {sources?.map(source => (
            <source
              key={source.breakpoint}
              srcSet={source.srcSet}
              media={source.media}
              sizes={sizes}
            />
          ))}
          <img
            className={classnames('size-full object-cover', className)}
            alt={alt}
            onLoad={onLoad}
            src={appendWebpFormat(src)}
            srcSet={srcSet}
            sizes={sizes}
            loading={loading}
            title={title}
          />
        </picture>
      </Wrapper>
    );
  } else {
    const fallbackSrc = getFallbackSrcWhenSrcSetUnsupported(
      src,
      aspectRatio,
      imageSizes,
      breakPoints
    );
    return (
      <Wrapper {...(!skipWrapper ? { ratio: aspectRatio } : {})}>
        <img
          className={classnames('size-full object-cover', className)}
          alt={alt}
          src={fallbackSrc}
          title={title}
          onLoad={onLoad}
        />
      </Wrapper>
    );
  }
};

export default ResponsiveImage;
