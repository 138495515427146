import type { RichTextBlock as RichTextBlockProps } from '@/@types/content';
import RichText from '../cms/RichText';
import CmsButton from '@/components/cms/Button';
import Text from '../cms/Text';
import Block from '../cms/Block';
import Icon from '@/components/ui/Icon';
import { useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

const RichTextBlock: React.FC<RichTextBlockProps & { isPDP?: boolean }> = ({
  contentLink,
  heading,
  copy,
  additionalCopy,
  padding,
  isPDP = false
}) => {
  const [showMore, setShowMore] = useState(false);
  const siteLabels = useSelector((state: RootState) => state.page.siteLabels);

  return (
    <Block
      contentLinkID={contentLink?.id}
      className={classnames(
        { container: padding == 'padding' },
        { 'module-spacing-pdp': isPDP && padding == 'padding' },
        { 'module-spacing !pb-0': !isPDP && padding == 'padding' }
      )}
    >
      <div className={classnames('max-w-[845px]', { 'flex flex-col gap-8 max-sm:gap-6': isPDP })}>
        {!!heading && (
          <Text element="h2" propertyName="Heading" className="heading-5 mb-2">
            {heading}
          </Text>
        )}
        <div className="flex flex-col gap-2">
          {copy && (
            <RichText content={copy} propertyName="Copy" className="text-1 text-gray-darker" />
          )}

          {additionalCopy && showMore && (
            <RichText
              content={additionalCopy}
              propertyName="AdditionalCopy"
              className="text-1 text-gray-darker"
            />
          )}

          {additionalCopy ? (
            <CmsButton
              type="button"
              className={classnames(
                'flex items-center gap-1 py-3 text-black transition-colors',
                'hover:text-red',
                'lg:py-0'
              )}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? (
                <Text propertyName="SeeMoreLabel" className="text-2 font-bold">
                  {siteLabels.seeLessLabel}
                </Text>
              ) : null}
              {!showMore ? (
                <Text propertyName="SeeLessLabel" className="text-2 font-bold">
                  {siteLabels.seeMoreLabel}
                </Text>
              ) : null}
              <Icon
                name={showMore ? 'chevron-up' : 'chevron-down'}
                size="small"
                className="lg:size-5"
              />
            </CmsButton>
          ) : null}
        </div>
      </div>
    </Block>
  );
};

export default RichTextBlock;
