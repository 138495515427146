import classnames from 'classnames';
import Text from '@/components/cms/Text';

interface MembersRowProps {
  membershipGroup?: string[];
  groupClassification?: string;
}

const MembersRowNonCollapsible: React.FC<MembersRowProps> = ({
  membershipGroup = [],
  groupClassification
}) => {
  const secondColumnStartingIndex = Math.ceil(membershipGroup.length / 2);
  const leftColumn = membershipGroup.slice(0, secondColumnStartingIndex);
  const rightColumn = membershipGroup.slice(secondColumnStartingIndex);

  const columnClasses = classnames('col-span-1');
  const memberClasses = classnames('text-2 block text-gray-dark');

  return (
    <div className={classnames('flex flex-col gap-6')}>
      <Text element="h6" className="heading-6-medium">
        {groupClassification}
      </Text>
      <div className="grid grid-cols-1 gap-x-5 sm:grid-cols-2">
        <div className={columnClasses}>
          {leftColumn &&
            leftColumn.map((member, index) => {
              return (
                <Text key={index} className={memberClasses}>
                  {member}
                </Text>
              );
            })}
        </div>
        <div className={columnClasses}>
          {rightColumn &&
            rightColumn.map((member, index) => {
              return (
                <Text key={index} className={memberClasses}>
                  {member}
                </Text>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MembersRowNonCollapsible;
