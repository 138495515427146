import { useCallback, useEffect, useRef, useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import CircleButton from '../../Buttons/CircleButton';

interface ScheduleHeaderProps {
  heading: string;
  dates: string[];
}

const ScheduleHeader: React.FC<ScheduleHeaderProps> = ({ heading, dates }) => {
  const tabsListRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const updateArrowState = useCallback(() => {
    if (tabsListRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsListRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  }, []);

  const checkOverflow = useCallback(() => {
    if (tabsListRef.current) {
      const { scrollWidth, clientWidth } = tabsListRef.current;
      const isOverflow = scrollWidth > clientWidth;
      setIsOverflowing(isOverflow);
      updateArrowState();
    }
  }, [updateArrowState]);

  const scrollTabs = useCallback((direction: string) => {
    if (tabsListRef.current) {
      const scrollAmount = direction === 'left' ? -150 : 150;
      tabsListRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      checkOverflow();
    };

    window.addEventListener('resize', handleResize);
    checkOverflow();
    return () => window.removeEventListener('resize', handleResize);
  }, [checkOverflow]);

  useEffect(() => {
    const handleScroll = () => {
      updateArrowState();
    };

    const currentRef = tabsListRef.current;
    currentRef?.addEventListener('scroll', handleScroll);
    return () => currentRef?.removeEventListener('scroll', handleScroll);
  }, [updateArrowState]);

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="heading-5 mb-12">{heading}</h2>

        {isOverflowing && (
          <div className="flex w-20 justify-between max-sm:hidden">
            <CircleButton
              color="dark"
              icon="chevron-left"
              fixedSize
              disabled={!canScrollLeft}
              onClick={() => scrollTabs('left')}
            />
            <CircleButton
              color="dark"
              icon="chevron-right"
              fixedSize
              disabled={!canScrollRight}
              onClick={() => scrollTabs('right')}
            />
          </div>
        )}
      </div>
      {dates.length > 1 && (
        <div className={classnames({ 'max-md:-m-6': isOverflowing })}>
          <div
            className={classnames('no-scrollbar mb-12 overflow-auto', {
              'max-md:px-6': isOverflowing
            })}
            ref={tabsListRef}
          >
            <Tabs.List className="relative inline-flex min-w-full">
              {dates.map(date => (
                <Tabs.Trigger
                  key={date}
                  value={date}
                  className="text-2 w-[100px] shrink-0 pb-[13px] text-left font-medium text-gray-dark transition-colors hover:text-black data-[state=active]:border-b data-[state=active]:border-black data-[state=active]:text-black md:w-[135px]"
                >
                  {DateTime.fromISO(date).toFormat('LLL dd, yyyy')}
                </Tabs.Trigger>
              ))}
              <span className="absolute inset-x-0 bottom-0 -z-1 block h-px bg-silver"></span>
            </Tabs.List>
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduleHeader;
