import { ISearchResultItem, ItemAnalyticsModel, LibraryItem } from '@/@types/client-api';
import { MenuItemProps } from '@/components/ui/Menu/MenuItem';
import PodcastCard from '@/components/ui/Cards/PodcastCard';
import PublicationCard from '@/components/ui/Cards/PublicationCard';
import SegmentCard from '@/components/ui/Cards/SegmentCard';
import ProgramCard from '@/components/ui/Cards/ProgramCard';
import { Link } from 'react-router-dom';
import {
  aggregateUserData,
  getAttendanceInfo,
  getThumbnailImageUrl,
  getIsSavedToLibrary,
  mapPublicationEyebrows
} from '@/components/ui/SearchResults/Snippets/helpers';
import { memo, useCallback, useMemo } from 'react';
import { DateType } from '@/components/ui/DateTag';
import { useAuth } from 'react-oidc-context';
import SidePanelResultCard from './SidePanelResultCard';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { CardType } from './constants';
import { SearchableType } from '../SearchResults/searchableTypeToSnippetVariant';
import { transformIfPLIUrl } from '@/utils/helpers';

enum CardSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

enum CardColor {
  Dark = 'dark',
  Light = 'light'
}

enum Type {
  Standard = 'standard',
  Faculty = 'faculty',
  Library = 'library'
}

interface CardBuilderProps {
  size?: `${CardSize}`;
  color?: `${CardColor}`;
  cardClassName?: string;
  className?: string;
  cardProps?: ISearchResultItem;
  containerClass?: string;
  listPosition?: number;
  heightAuto?: boolean;
  widthAuto?: boolean;
  showAsList?: boolean;
  useSidePanelCard?: boolean;
  type?: `${Type}`;
  creditTrackerCode?: string;
  clickOnCardContent?: (card: Record<string, unknown>) => void;
  itemAnalyticsModel?: ItemAnalyticsModel;
}

const podcastTypes = ['Podcast', 'EmbeddedMedia', 'PodcastEpisode'];
const publicationTypes = ['Book'];
const programDemandTypes = ['OnDemandProgram'];
const programLiveTypes = ['LiveProgram'];
const segmentTypes = ['OnDemandSegment'];

const typesMap = [
  { types: podcastTypes, cardType: CardType.Podcast },
  { types: publicationTypes, cardType: CardType.Publication },
  { types: programDemandTypes, cardType: CardType.ProgramDemand },
  { types: programLiveTypes, cardType: CardType.ProgramLive },
  { types: segmentTypes, cardType: CardType.Segment }
];

type ISearchResultItemProps = ISearchResultItem & LibraryItem;

const CardBuilder: React.FC<CardBuilderProps> = memo(
  ({
    size,
    color,
    cardClassName,
    className,
    cardProps,
    containerClass,
    listPosition,
    heightAuto,
    widthAuto,
    creditTrackerCode,
    showAsList = false,
    useSidePanelCard = false,
    type = Type.Standard,
    clickOnCardContent,
    itemAnalyticsModel
  }) => {
    const auth = useAuth();
    const isAuthenticated = auth?.isAuthenticated;
    const stateSiteLabels = useSelector((state: RootState) => state.page.siteLabels);

    const {
      title,
      datesFormatted,
      formats,
      creditDetailsFormatted,
      runTimeSeconds,
      variationPk,
      imageUrl,
      userList = [],
      url,
      isLive,
      podcastMediaUrls,
      productCode,
      plusUrl,
      notifyMeUrl,
      productGuidValue = null
    } = (cardProps as ISearchResultItemProps) || {};
    const searchableType = cardProps?.searchableType;
    const userAggregatedData = aggregateUserData({
      userList,
      variant: searchableType?.toLowerCase() as SearchableType
    });
    const {
      playbackProgressPct,
      isPurchased,
      watchUrl,
      isParentProgramPurchased,
      isPurchaseRequired
    } = userAggregatedData || {};

    const hasCreditsForRequest = useMemo(() => {
      return userList?.some(userListItem => userListItem.hasCreditsForRequest);
    }, [userList]);

    const certificateRequestUrl = useMemo(() => {
      const userItem = userList?.find(userListItem => userListItem.hasCreditsForRequest);
      if (userItem) return userItem.certificateRequestUrl;
    }, [userList]);

    const handleClickOnCardContent = useCallback(() => {
      if (clickOnCardContent) clickOnCardContent({ ...cardProps, type: searchableType });
    }, [clickOnCardContent, cardProps, searchableType]);

    if (!searchableType) return null;

    let cardType: CardType | null = null;

    for (const { types, cardType: type } of typesMap) {
      if (types.includes(searchableType)) {
        cardType = type;
        break;
      }
    }

    if (!cardType) return null;

    const cardInheritedProps = {
      size,
      color,
      cardClassName,
      className,
      containerClass,
      listPosition,
      heightAuto,
      widthAuto,
      showAsList
    };

    const creditDetailsFormattedToString = (useKeyword = true) => {
      if (!creditDetailsFormatted || creditDetailsFormatted.length === 0) return '';

      const firstCreditDetail = creditDetailsFormatted[0];
      return useKeyword
        ? firstCreditDetail.keyword || undefined
        : firstCreditDetail.url || undefined;
    };

    const getRuntime = (runTimeSeconds: number, playbackProgressPct?: number) => {
      if (cardType == CardType.ProgramDemand || !playbackProgressPct) {
        if (runTimeSeconds >= 3600) {
          const durationInHours = Math.round(runTimeSeconds / 3600);
          return `${durationInHours} hour${durationInHours > 1 ? 's' : ''}`;
        } else if (runTimeSeconds > 0) {
          const durationInMinutes = Math.floor(runTimeSeconds / 60);
          return `${durationInMinutes} minute${durationInMinutes > 1 ? 's' : ''}`;
        }
      } else {
        const progressLeft = Math.round(
          runTimeSeconds - (runTimeSeconds / 100) * playbackProgressPct
        );
        if (progressLeft > 0) {
          const hoursLeft = Math.floor(progressLeft / 3600);
          const minutesLeft = Math.round((progressLeft % 3600) / 60);
          return hoursLeft > 0 ? `${hoursLeft}h${minutesLeft}m left` : `${minutesLeft}m left`;
        }
      }
    };

    const cardRunTime = getRuntime(runTimeSeconds ?? 0, playbackProgressPct ?? 0);

    const parseDate = (dateString: string | undefined | null): DateType | undefined => {
      if (!dateString) return undefined;

      const regex = /^(\w+)(?: (\d+(?: – \d+)?)(?:,))? (\d{4})$/;
      const match = dateString.match(regex);

      if (!match) return undefined;

      const [, month, day] = match;
      const dayPart = day ? ` ${day}` : '';

      return {
        month,
        day: `${dayPart}`
      };
    };

    const canUserLaunchProgram = () => {
      const isPurchased = !!userList?.some(
        userListItem => userListItem.isPurchased || !!userListItem.isParentProgramPurchased
      );
      const hasPrice = !!userList?.some(userListItem => (userListItem.price || 0) > 0);
      return isPurchased || !hasPrice;
    };

    const getLaunchUrl = () => {
      const purchasedUserListItem = userList?.find(
        userListItem => userListItem.isPurchased || !!userListItem.isParentProgramPurchased
      );
      return purchasedUserListItem?.watchUrl ?? undefined;
    };

    // If we are passed down a creditTrackerCode, include it in the pdp url
    const urlBuilder = new URL(url ?? '/');
    if (creditTrackerCode) {
      urlBuilder.searchParams.append('creditTrackerCode', creditTrackerCode);
    }
    const programUrl = transformIfPLIUrl(urlBuilder.toString());

    const BuilderSidePanelResultCard = () => {
      return (
        <SidePanelResultCard
          {...cardInheritedProps}
          heading={title || undefined}
          labelDetail={datesFormatted || undefined}
          labelInfo={creditDetailsFormattedToString()}
          image={{
            url: getThumbnailImageUrl(variationPk) || imageUrl || '',
            alt: title || undefined
          }}
          productLink={{ url: url ?? '', target: '__blank' }}
          formats={formats}
          userCanLaunch={canUserLaunchProgram()}
          launchUrl={getLaunchUrl()}
          variationPk={variationPk ?? ''}
          itemAnalyticsModel={itemAnalyticsModel}
        />
      );
    };

    const BuilderPodcastCard = () => {
      let podcastLinks: (MenuItemProps | { separator: true })[] = [];

      if (podcastMediaUrls && podcastMediaUrls.length > 0) {
        podcastLinks = podcastMediaUrls.map(podcast => ({
          label: podcast.key,
          to: podcast.value,
          icon: podcast.icon,
          Element: Link
        })) as MenuItemProps[];
        podcastLinks.push({ separator: true });
      }

      let labelFormat: string | undefined = stateSiteLabels?.audioPodcastLabel;
      const falbackPodcastFormat = formats?.find(format =>
        format.toLowerCase().includes('podcast')
      );

      if (formats?.some(format => format.toLowerCase().includes('video'))) {
        labelFormat = stateSiteLabels?.videoPodcastLabel || falbackPodcastFormat;
      }

      return (
        <PodcastCard
          {...cardInheritedProps}
          image={{
            url: getThumbnailImageUrl(variationPk) || imageUrl || '',
            alt: title || undefined
          }}
          heading={title || undefined}
          labelDetail={datesFormatted || undefined}
          labelInfo={labelFormat}
          href={programUrl}
          podcastLinks={podcastLinks}
          productCode={productCode}
          itemAnalyticsModel={itemAnalyticsModel}
        />
      );
    };

    const BuilderPublicationCard = () => {
      const eyebrows = mapPublicationEyebrows(formats ?? []);
      const eyebrowsFormatted = eyebrows.map(eyebrow => eyebrow.label).join(' | ');
      let hasPlusAccess = false;
      if (userList && userList.length > 0) {
        hasPlusAccess = userList?.some(list => list.hasPlusAccess === true);
      }
      const savedInLibrary = userList?.filter(getIsSavedToLibrary).map(u => u.pk ?? '');
      const isSavedInLibrary = savedInLibrary?.includes(variationPk!);

      return (
        <PublicationCard
          {...cardInheritedProps}
          image={{
            url: getThumbnailImageUrl(variationPk) || imageUrl || '',
            alt: title || undefined
          }}
          heading={title || undefined}
          variationPk={variationPk!}
          labelDetail={datesFormatted || undefined}
          labelInfo={eyebrowsFormatted || undefined}
          labelIcon={hasPlusAccess ? 'Read on PLUS' : 'Buy Now'}
          variant={hasPlusAccess ? 'normal' : 'retail'}
          href={programUrl}
          headingSmall={hasPlusAccess ? 'Included in your PLUS subscription' : undefined}
          productCode={productCode}
          plusUrl={plusUrl}
          isSaved={isSavedInLibrary}
          hasPlusAccess={hasPlusAccess}
          searchableType={(searchableType as string).toLowerCase()}
          guidValue={productGuidValue}
          itemAnalyticsModel={itemAnalyticsModel}
        />
      );
    };

    const BuilderProgramDemandCard = () => {
      let programDemandCardVariant:
        | 'notRegistered'
        | 'registeredOnline'
        | 'registeredInPerson'
        | 'live'
        | 'liveRegistered'
        | 'onDemand'
        | 'onDemandRetail'
        | 'notifyMe'
        | undefined = 'onDemand';

      let hasPrice = true;
      if (userList && userList.length > 0) {
        hasPrice = userList?.some(list => (list.price || 0) > 0);
      }

      if (hasPrice) {
        programDemandCardVariant = 'onDemandRetail';
      }

      if (notifyMeUrl) {
        programDemandCardVariant = 'notifyMe';
      }

      const savedInLibrary = userList?.filter(getIsSavedToLibrary).map(u => u.pk ?? '');
      const isSavedInLibrary = savedInLibrary?.includes(variationPk!);

      return useSidePanelCard ? (
        BuilderSidePanelResultCard()
      ) : (
        <ProgramCard
          {...cardInheritedProps}
          image={{
            url: getThumbnailImageUrl(variationPk) || imageUrl || '',
            alt: title || undefined
          }}
          variationPk={variationPk!}
          heading={title || undefined}
          labelDetail={datesFormatted || undefined}
          labelInfo={creditDetailsFormattedToString()}
          tag={cardRunTime ? { children: cardRunTime } : undefined}
          variant={programDemandCardVariant}
          labelIcon={hasPrice ? 'Buy Now' : undefined}
          href={programUrl}
          isSaved={isSavedInLibrary}
          productCode={productCode}
          notifyMeUrl={notifyMeUrl as string}
          hasCreditsForRequest={hasCreditsForRequest}
          certificateRequestUrl={certificateRequestUrl}
          type={type}
          guidValue={productGuidValue}
          isPurchased={isPurchased}
          watchUrl={watchUrl}
          clickOnCardContent={handleClickOnCardContent}
          itemAnalyticsModel={itemAnalyticsModel}
          creditTrackerCode={creditTrackerCode}
        />
      );
    };

    const BuilderProgramLiveCard = () => {
      let programLiveCardVariant:
        | 'notRegistered'
        | 'registeredOnline'
        | 'registeredInPerson'
        | 'live'
        | 'liveRegistered'
        | 'onDemand'
        | 'onDemandRetail'
        | 'notifyMe'
        | undefined = 'notRegistered';
      let programLiveCardDates: DateType | undefined = parseDate(datesFormatted);

      const savedInLibrary = userList?.filter(getIsSavedToLibrary).map(u => u.pk ?? '');
      const isSavedInLibrary = savedInLibrary?.includes(variationPk!);

      let hasPrice = true;
      if (userList && userList.length > 0) {
        hasPrice = userList?.some(list => (list.price || 0) > 0);
      }

      if (notifyMeUrl) {
        programLiveCardVariant = 'notifyMe';
      }

      let hasRegistrationDetails: boolean = false;
      let isPurchased: boolean = false;

      if (userList && userList.length > 0) {
        hasRegistrationDetails = userList.some(userListItem => userListItem.registrationDetails);
        isPurchased = userList.some(userListItem => userListItem.isPurchased);
      }

      if (isLive && hasRegistrationDetails && isPurchased && formats?.includes('Live Webcast')) {
        programLiveCardVariant = 'liveRegistered';
        programLiveCardDates = undefined;
      } else if (
        isLive &&
        hasRegistrationDetails &&
        isPurchased &&
        formats?.includes('Live In-Person')
      ) {
        programLiveCardVariant = 'live';
        programLiveCardDates = undefined;
      } else if (
        isLive &&
        !hasRegistrationDetails &&
        !isPurchased &&
        formats?.includes('Live Webcast')
      ) {
        programLiveCardVariant = 'onDemandRetail';
      }

      if (
        isLive &&
        !hasRegistrationDetails &&
        !isPurchased &&
        !hasPrice &&
        formats?.includes('Live Webcast')
      ) {
        programLiveCardVariant = 'live';
        programLiveCardDates = undefined;
      }

      const userAggregatedData = aggregateUserData({ userList, variant: 'liveprogram' });
      const { attendanceFormats } = userAggregatedData || {};

      const attendanceInfo = getAttendanceInfo(formats ?? [], attendanceFormats ?? []);

      if (!isLive && hasRegistrationDetails && isPurchased) {
        if (attendanceInfo?.includes('In-Person')) {
          programLiveCardVariant = 'registeredInPerson';
        } else if (attendanceInfo?.includes('Online')) {
          programLiveCardVariant = 'registeredOnline';
        }
      }

      const forceLive = isAuthenticated && isLive;

      return useSidePanelCard ? (
        BuilderSidePanelResultCard()
      ) : (
        <ProgramCard
          {...cardInheritedProps}
          image={{
            url: getThumbnailImageUrl(variationPk) || imageUrl || '',
            alt: title || undefined
          }}
          variationPk={variationPk!}
          guidValue={productGuidValue}
          heading={title || undefined}
          labelDetail={attendanceInfo || undefined}
          labelInfo={creditDetailsFormattedToString()}
          variant={programLiveCardVariant}
          dates={forceLive ? undefined : programLiveCardDates}
          href={programUrl}
          isSaved={isSavedInLibrary}
          color={forceLive ? 'dark' : undefined}
          tag={forceLive ? { children: 'Live', variant: 'emphasis' } : undefined}
          forceGetVideo={forceLive}
          searchableType={(searchableType as string).toLowerCase()}
          productCode={productCode}
          notifyMeUrl={notifyMeUrl as string}
          hasCreditsForRequest={hasCreditsForRequest}
          certificateRequestUrl={certificateRequestUrl}
          type={type}
          watchUrl={watchUrl}
          clickOnCardContent={handleClickOnCardContent}
          creditTrackerCode={creditTrackerCode}
          itemAnalyticsModel={itemAnalyticsModel}
        />
      );
    };

    const BuilderSegmentCard = () => {
      let segmentCardVariant:
        | 'default'
        | 'retail'
        | 'watched'
        | 'continueWatching'
        | 'creditRequested'
        | 'creditReceived'
        | undefined = 'default';

      if (userAggregatedData) {
        if (playbackProgressPct === 0 && isPurchaseRequired && isPurchased === false) {
          segmentCardVariant = 'retail';
        } else if (playbackProgressPct === 100) {
          segmentCardVariant = 'watched';
        }
      }

      let isPurchasedUserList: boolean = false;
      let cleProgressPct: number = 0;
      let progressFinished: boolean = false;
      let playbackProgressPctUserList: number = 0;

      if (userList) {
        isPurchasedUserList = userList.some(
          userListItem => userListItem.isPurchased || userListItem.isParentProgramPurchased
        );
      }

      if (userList && isPurchasedUserList) {
        cleProgressPct = userList.filter(u => u.pk === variationPk)[0].cleProgressPct || 0;
        playbackProgressPctUserList =
          userList.filter(u => u.pk === variationPk)[0].playbackProgressPct || 0;
        progressFinished = cleProgressPct === 100 || playbackProgressPctUserList === 100;
      }

      if (playbackProgressPctUserList && playbackProgressPctUserList > 0) {
        segmentCardVariant = 'continueWatching';
      }

      if (progressFinished && certificateRequestUrl) {
        segmentCardVariant = 'watched';
      }
      const savedInLibrary = userList?.filter(getIsSavedToLibrary).map(u => u.pk ?? '');
      const isSavedInLibrary = savedInLibrary?.includes(variationPk!);

      return useSidePanelCard ? (
        BuilderSidePanelResultCard()
      ) : (
        <SegmentCard
          {...cardInheritedProps}
          image={{
            url: getThumbnailImageUrl(variationPk) || imageUrl || '',
            alt: title || undefined
          }}
          heading={title || undefined}
          variationPk={variationPk!}
          labelDetail={datesFormatted || undefined}
          labelInfo={creditDetailsFormattedToString()}
          variant={segmentCardVariant}
          tag={cardRunTime ? { children: cardRunTime } : undefined}
          href={programUrl}
          progressValue={playbackProgressPctUserList || undefined}
          productCode={productCode}
          isSaved={isSavedInLibrary}
          certificateRequestUrl={certificateRequestUrl}
          hasCreditsForRequest={hasCreditsForRequest}
          type={type}
          isPurchased={isPurchased}
          watchUrl={watchUrl}
          isParentProgramPurchased={isParentProgramPurchased}
          itemAnalyticsModel={itemAnalyticsModel}
          labelIcon={segmentCardVariant == 'retail' ? 'Buy Now' : undefined}
          clickOnCardContent={handleClickOnCardContent}
        />
      );
    };

    const Card = () => {
      switch (cardType) {
        case CardType.Podcast:
          return <BuilderPodcastCard />;

        case CardType.Publication:
          return <BuilderPublicationCard />;

        case CardType.ProgramDemand:
          return <BuilderProgramDemandCard />;

        case CardType.ProgramLive:
          return <BuilderProgramLiveCard />;

        case CardType.Segment:
          return <BuilderSegmentCard />;

        default:
          return null;
      }
    };

    return <Card />;
  }
);

export default CardBuilder;
