import { PaymentsCardsBlock as PaymentsCardsBlockProps } from '@/@types/content';
import PaymentCardsSection from '@/components/ui/ProfileSections/PaymentCardsSection';

const PaymentsCardsBlock: React.FC<PaymentsCardsBlockProps> = ({
  creditCardDisplaySettings,
  messages,
  heading
}) => {
  return (
    <PaymentCardsSection
      creditCardDisplaySettings={creditCardDisplaySettings}
      messages={messages}
      heading={heading}
    />
  );
};

export default PaymentsCardsBlock;
