import { useEffect, useState } from 'react';
import convert from 'xml-js';
import { DateTime } from 'luxon';
import { RssContentBlock as RssContentBlockProps } from '@/@types/content';
import SectionTitle from '../ui/Titles/SectionTitle';
import Link from '../ui/Link';
import RichText from '../cms/RichText';

type RSSObject = {
  elements?: RSSObject[];
  [key: string]: string | RSSObject[] | undefined;
};

type Feed = {
  title: string;
  link: string;
  description: string;
  pubDate: string;
  location: string;
};

const RssContentBlock: React.FC<RssContentBlockProps> = ({ title, seeAllLink, rssFeedUrl }) => {
  const [feed, setFeed] = useState<Feed[]>();
  const isCareerBlock = rssFeedUrl?.includes('careers');
  const seeMoreText = isCareerBlock ? 'See more' : 'Read article';

  const formatRSS = (data: string) => {
    const dataJSON = JSON.parse(convert.xml2json(data));
    const parser = new DOMParser();

    return dataJSON.elements?.[0].elements?.[0].elements
      ?.filter((item: RSSObject) => item.name === 'item')
      .map((item: RSSObject) => {
        const description = isCareerBlock
          ? parser.parseFromString(
              item.elements
                ?.find((element: RSSObject) => element.name === 'description')
                ?.elements?.[0].text?.toString() || '',
              'text/html'
            ).body.textContent
          : item.elements?.find((element: RSSObject) => element.name === 'description')
              ?.elements?.[0].cdata;

        return {
          title: item.elements?.find((element: RSSObject) => element.name === 'title')
            ?.elements?.[0].text,
          link: item.elements?.find((element: RSSObject) => element.name === 'link')?.elements?.[0]
            .text,
          description: description,
          pubDate: item.elements?.find((element: RSSObject) => element.name === 'pubDate')
            ?.elements?.[0].text,
          location: item.elements?.find((element: RSSObject) => element.name === 'location')
            ?.elements?.[0].text
        };
      })
      .slice(0, isCareerBlock ? 6 : 3);
  };

  useEffect(() => {
    const fetchRSS = async () => {
      if (!rssFeedUrl) {
        return;
      }

      try {
        const response = await fetch(rssFeedUrl, {
          headers: {
            Accept: 'application/rss+xml'
          }
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
        }

        const data = await response.text();
        const dataJSON = formatRSS(data);

        setFeed(dataJSON);
      } catch (err) {
        console.error(err);
      }
    };

    fetchRSS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rssFeedUrl]);

  if (!feed?.length) {
    return null;
  }

  return (
    <div className="container module-spacing">
      <SectionTitle
        elementType="h2"
        propertyNameTitle="Title"
        title={title}
        divider={{ color: 'light' }}
      >
        <Link variant="bold-link-with-icon" icon="chevron-right" {...seeAllLink} />
      </SectionTitle>

      <div className="mt-10 grid grid-cols-1 gap-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3"  data-component={"RssContentBlock"}>
        {feed &&
          feed.map((item, index) => (
            <div key={index}>
              <div className="mb-8">
                <h3 className="heading-6-medium">{item.title}</h3>
                <p className="text-2 mt-2 text-gray-darker">
                  {item.pubDate && !isCareerBlock
                    ? DateTime.fromRFC2822(item.pubDate).toFormat('MMMM dd, yyyy')
                    : null}
                  {isCareerBlock && item.location}
                </p>
              </div>
              <RichText content={item.description} className="text-2 line-clamp-4 text-gray-dark" />
              {item.link ? <Link text={seeMoreText} href={item.link} className="mt-4" /> : null}
            </div>
          ))}
      </div>
    </div>
  );
};

export default RssContentBlock;
