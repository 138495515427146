import { LaunchElectronicItemRequest } from '@/@types/client-api';
import {
  useGetUpcomingRegistrationsQuery,
  usePlaceRegistrationMutation
} from '@/redux/api/client/registration';
import { useCallback } from 'react';
import { makeToastProblemMessage } from './util';
import libraryEndpoints from '@/redux/api/client/library';
import useToast from '../useToast';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAuth } from 'react-oidc-context';
import { AnalyticsContext } from '@/analytics/constants';
import { useAppDispatch } from '..';
import { trackAddToCart } from '@/analytics/ecommerce';
import { useFetchAnalyticsItems } from '@/analytics/useFetchAnalyticsItems';
import { createTransactionId } from '@/analytics/analyticsUtils';
import { evictCreditTrackerCode, setCreditTrackerCode } from '../useCreditTrackerCode';
import { setOptimisticRegistrationDetailsUpdate } from '@/redux/slices/searchSlice';
import { Variant } from '@/redux/slices/pdpSlice';

export const useRegistrations = (context?: AnalyticsContext, creditTrackerCode?: string) => {
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [registrationMutation, { isLoading, isSuccess, isError, reset, data }] =
    usePlaceRegistrationMutation();
  const { showSuccessMessage, showFailMessage } = useToast();
  const { refetch: regRefetch } = useGetUpcomingRegistrationsQuery(
    !auth.isAuthenticated ? skipToken : undefined
  );
  const { getAnalyticItem } = useFetchAnalyticsItems();

  const trackEvent = useCallback(
    async (code: string, context?: AnalyticsContext) => {
      const data = await getAnalyticItem(code);
      if (context && data) {
        const transactionId = createTransactionId('');
        trackAddToCart(data, context, transactionId);
      }
    },
    [getAnalyticItem]
  );

  const placeRegistration = useCallback(
    async (variant: Variant, isUpdate: boolean = false) => {
      if (!variant.code) return;
      const itemPk = variant.code;
      trackEvent(itemPk, context);
      if (itemPk && creditTrackerCode) {
        setCreditTrackerCode(itemPk, creditTrackerCode);
      }

      const regRequest: LaunchElectronicItemRequest = { itemSk: variant.sk, itemPk: itemPk };
      const regFailedMessage = makeToastProblemMessage('register');
      const regUpdateFailedMessage = makeToastProblemMessage('register-update');
      const regSuccessMessage = 'Registered';
      const regUpdateSuccessMessage = 'Registration updated';
      const regResponse = await registrationMutation(regRequest);
      if ('error' in regResponse) {
        showFailMessage(isUpdate ? regUpdateFailedMessage : regFailedMessage);
      } else if ('data' in regResponse) {
        if (variant.sk) evictCreditTrackerCode(itemPk);
        showSuccessMessage(isUpdate ? regUpdateSuccessMessage : regSuccessMessage);
        regRefetch();
        dispatch(
          libraryEndpoints.util.invalidateTags([
            { type: 'LibraryItem', id: itemPk },
            { type: 'Library', id: 'List' },
            { type: 'Search', id: 'Results' }
          ])
        );
        dispatch(
          setOptimisticRegistrationDetailsUpdate({
            pk: itemPk,
            isRegistered: true,
            format: variant?.type == 'online' ? 'webcast' : variant?.type,
            location: variant?.location,
            startDate: variant?.eventEndDate,
            endDate: variant?.eventEndDate
          })
        );
      }
      return regResponse;
    },
    [
      trackEvent,
      context,
      creditTrackerCode,
      registrationMutation,
      showFailMessage,
      showSuccessMessage,
      regRefetch,
      dispatch
    ]
  );

  return {
    placeRegistration,
    isLoading,
    isSuccess,
    isError,
    reset,
    data
  };
};
