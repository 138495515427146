import CircleButton from '@/components/ui/Buttons/CircleButton';
import classnames from 'classnames';

export type PaginationProps = {
  count: number;
  totalCount: number;
  onClickPrev: () => void;
  onClickNext: () => void;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  color?: 'dark' | 'light';
  className?: string;
};

const Pagination: React.FC<PaginationProps> = ({
  count,
  totalCount,
  onClickPrev,
  onClickNext,
  prevDisabled,
  nextDisabled,
  color,
  className
}) => {
  return (
    <div
      className={classnames(
        'text-1 flex w-full items-center justify-center gap-x-4 py-1 font-medium',
        className
      )}
      data-component={"Pagination"}

    >
      <CircleButton
        aria-label="Previous button"
        icon="chevron-left"
        onClick={onClickPrev}
        disabled={prevDisabled}
        color={color}
      />
      {count} of {totalCount}
      <CircleButton
        aria-label="Next button"
        icon="chevron-right"
        onClick={onClickNext}
        disabled={nextDisabled}
        color={color}
      />
    </div>
  );
};

export default Pagination;
