import { PersonalInfoFacultyBlock as PersonalInfoFacultyBlockProps } from '@/@types/content';
import PersonalInfoFacultySection from '@/components/ui/ProfileSections/FacultySection';
import { IComponentProps } from '@/lib/ComponentFactory';

const PersonalInfoFacultyBlock: React.FC<
  PersonalInfoFacultyBlockProps & IComponentProps
> = props => {
  return <PersonalInfoFacultySection {...props} />;
};

export default PersonalInfoFacultyBlock;
