import { ClientAPI } from '@/redux/api';
import { CreditTrackerViewModel, OrganizationMembersResponse } from '@/@types/client-api';

export const rosterBaseUrl = '/roster';

export interface IRosterMemberQuery {
  orgSk: number;
  indSk: number;
}

const creditTrackerAdminEndpoints = ClientAPI.injectEndpoints({
  endpoints: builder => ({
    getIndividualRosterMember: builder.query<OrganizationMembersResponse, IRosterMemberQuery>({
      query: props => ({
        url: `${rosterBaseUrl}/${props.orgSk}/child-members?query=${props.indSk}&page=0`,
        method: 'GET'
      })
    }),
    getAllRosterMembers: builder.query<CreditTrackerViewModel, IRosterMemberQuery>({
      query: props => ({
        url: `${rosterBaseUrl}/${props.orgSk}/child-members?page=0`,
        method: 'GET'
      })
    })
  })
});

export const { useGetIndividualRosterMemberQuery, useGetAllRosterMembersQuery } =
  creditTrackerAdminEndpoints;

export default creditTrackerAdminEndpoints;
