import Icon from '@/components/ui/Icon';

export type InvalidFieldValueMessageProps = {
  id: string;
  message: string;
  accessibilityMessage?: string;
};

const InvalidFieldValueMessage = ({
  id,
  message,
  accessibilityMessage
}: InvalidFieldValueMessageProps): JSX.Element => {
  return (
    <>
      <div className="label absolute mt-1 flex items-center gap-1 text-red">
        <Icon name="alert" size="small" />
        <span aria-hidden="true">{message}</span>
      </div>
      <div role="alert" id={id} className="absolute size-0 overflow-hidden">
        {accessibilityMessage ?? message}
      </div>
    </>
  );
};

export default InvalidFieldValueMessage;
