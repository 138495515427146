import RichText from '@/components/cms/RichText';
import Divider from '@/components/ui/Divider';
import Icon from '@/components/ui/Icon';
import ComplexTooltip from '@/components/ui/Tooltip/ComplexTooltip';
import { memo, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

export type IAnimatedCounterProps = {
  containerClassName: string;
  total: number;
  numbers: number[];
  description: string;
  overFlowText?: string;
  hexColor?: string;
  animationOffset?: number;
  totalCreditEarnedTooltip?: string;
};

const AnimatedCounter = memo(
  ({
    containerClassName,
    total,
    numbers,
    description,
    overFlowText,
    hexColor,
    animationOffset,
    totalCreditEarnedTooltip
  }: IAnimatedCounterProps) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const animatedNumbers = {
      animation: `animateNumbers ${animationOffset}s 1 ease-in-out forwards`
    };

    const counterColor = {
      color: hexColor ? hexColor : '#000000'
    };

    useEffect(() => {
      setIsAnimating(true);
      const timeout = setTimeout(() => setIsAnimating(false), (animationOffset ?? 1) * 1050);
      return () => clearTimeout(timeout);
    }, [animationOffset]);

    const loadNumbers = useMemo(() => {
      return numbers.reverse().map((number, index) => {
        return (
          <div key={index} className="heading-4 flex justify-end py-1">
            {number}
          </div>
        );
      });
    }, [numbers]);

    const animationClasses = classnames('mt-[-41px] flex h-full flex-col pr-0.5 sm:mt-[-43px]', {
      'backdrop-blur-[150px]': isAnimating
    });

    return (
      <>
        <div className={containerClassName}>
          <div>
            <div
              style={counterColor}
              className="flex w-full items-center justify-center overflow-hidden"
            >
              <div style={animatedNumbers} className={animationClasses}>
                {loadNumbers}
              </div>
              <span className="heading-4">{`/${total}`}</span>
            </div>
          </div>
        </div>

        <div className="mt-2 flex w-full flex-col items-center justify-center">
          {description && <div className="text-2 block">{description}</div>}
          {overFlowText && (
            <div className="z-1 mt-1 animate-slideInUp">
              <Divider color="light" />
              <div className="text-2 mt-1 flex items-center">
                {overFlowText}
                {!!totalCreditEarnedTooltip && (
                  <ComplexTooltip>
                    <ComplexTooltip.Trigger className="mb-[-2px] mr-[-10px] size-6 pl-1 text-gray hover:text-gray-darker focus:text-gray-darker">
                      <Icon name="info" size="small" className="size-5" />
                    </ComplexTooltip.Trigger>
                    <ComplexTooltip.Content
                      closeLabel="Close"
                      sideOffset={8}
                      aria-label="learn more"
                    >
                      <div className="text-2 flex flex-col gap-1">
                        <RichText propertyName="Copy" content={totalCreditEarnedTooltip} />
                      </div>
                    </ComplexTooltip.Content>
                  </ComplexTooltip>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);

export default AnimatedCounter;
