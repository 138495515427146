import Text from '@/components/cms/Text';
import Block from '@/components/cms/Block';
import EditorialCalloutBlock from './EditorialCalloutBlock';

export interface EditorialModuleContainerBlockProps {
  contentLink?: {
    id: number;
  };
  heading?: string;
  copy?: string;
  editorialCallouts?: {
    image?: {
      url?: string;
    };
    heading?: string;
    copy?: string;
    ctaLabel?: string;
    modalHeading?: string;
    modalCopy?: string;
    modalImage?: {
      url?: string;
    };
    modalLink?: {
      href?: string;
      target?: string;
      text?: string;
    };
  }[];
}

const EditorialModuleContainerBlock: React.FC<EditorialModuleContainerBlockProps> = ({
  contentLink,
  heading,
  copy,
  editorialCallouts
}) => {
  return (
    <Block
      contentLinkID={contentLink?.id}
      className="container module-spacing pr-0 md:pr-12 lg:pr-20"
    >
      <div className="container m-0 max-w-[800px] pl-0">
        {heading && (
          <Text element="h4" propertyName="Heading" className="heading-4">
            {heading}
          </Text>
        )}
        {copy && (
          <Text element="p" propertyName="Copy" className="heading-6-regular mt-6">
            {copy}
          </Text>
        )}
      </div>
      <ul className="mt-20 flex flex-nowrap gap-x-4 overflow-x-auto scrollbar-hide lg:mt-16 lg:gap-x-6">
        {Array.isArray(editorialCallouts) &&
          editorialCallouts.map((callout, index) => (
            <li key={index} className="min-w-[245px] flex-1 sm:min-w-[352px] md:min-w-[296px]">
              <EditorialCalloutBlock cTALabel={callout?.ctaLabel} {...callout} />
            </li>
          ))}
      </ul>
    </Block>
  );
};

export default EditorialModuleContainerBlock;
