import { IFacetGroup } from '@/@types/client-api';
import { CheckItem, FilterProps, RadioItem, SelectorItem } from '../../Filter/types';
import { FacetFilter, QueryFilter } from '../constants';

export const groupFilterItemsIntoSections = (items: FilterProps['items']) => {
  return Object.values(
    items.reduce<Record<string, FilterProps['items']>>((acc, item) => {
      const groupKey = 'sectionId' in item ? (item.sectionId ?? '') : '';

      acc[groupKey] ||= [];
      acc[groupKey].push(item as CheckItem & RadioItem & SelectorItem);

      return acc;
    }, {})
  )
    .map(items => {
      const isTopLevelSection = items[0]?.sectionIsTopLevel;
      return {
        items,
        title: items[0]?.sectionTitle,
        isTopLevelSection
      };
    })
    .sort((a, b) => (a.items[0]?.sectionOrder ?? 0) - (b.items[0]?.sectionOrder ?? 0));
};

export const addFacetFiltersToFacetGroups = ({
  facetFilters,
  facetGroups = []
}: {
  facetFilters?: FacetFilter[];
  facetGroups?: IFacetGroup[];
}) => {
  const facetFiltersFormatted = facetFilters?.map(item => ({
    groupTypeId: item.facetGroupType,
    facets: [{ value: item.value, altValue: item.altValue, checked: item.selectedOnDefault }]
  }));

  if (facetFiltersFormatted?.length) {
    facetFiltersFormatted.forEach(filter => {
      const existingGroup = facetGroups.find(group => group.groupTypeId === filter.groupTypeId);

      if (existingGroup) {
        // Merge facets if groupTypeId already exists
        existingGroup.facets = [...(existingGroup.facets || []), ...filter.facets];
      } else {
        // Add new group if groupTypeId does not exist
        facetGroups.push(filter);
      }
    });
  }

  return facetGroups;
};

export const updatePreservedFilterGroupOrder = <T extends { value: string }>(
  preservedOrderList: T[] = [],
  newList: T[]
) => {
  if (!preservedOrderList.length) {
    preservedOrderList = newList;
  }

  // remove any old filters no longer coming through in BE data
  preservedOrderList = preservedOrderList.filter(item =>
    newList.find(filter => filter.value === item.value)
  );

  const newFilters = newList.filter(
    filter => !preservedOrderList.find(item => item.value === filter.value)
  );

  newFilters.forEach(newFilter => {
    const newListIndex = newList.findIndex(item => item.value === newFilter.value);
    const previousItem = newList[newListIndex - 1];
    if (previousItem) {
      const previousIndex = preservedOrderList.findIndex(item => item.value === previousItem.value);
      preservedOrderList.splice(previousIndex + 1, 0, newFilter);
      return;
    }

    const nextItem = newList[newListIndex + 1];
    if (nextItem) {
      const nextIndex = preservedOrderList.findIndex(item => item.value === nextItem.value);
      preservedOrderList.splice(nextIndex, 0, newFilter);
      return;
    }

    preservedOrderList.push(newFilter);
  });

  return preservedOrderList;
};

export const preserveFiltersOrder = <T extends { value: string }>(
  preservedOrder: T[] = [],
  list: T[]
) =>
  [...list].sort((a, b) => {
    const aIndex = preservedOrder?.findIndex(item => item.value === a.value);

    const bIndex = preservedOrder?.findIndex(item => item.value === b.value);

    return aIndex - bIndex;
  });

/* This function removes the transitional suffix "-TR" from the facetValue
  Example:
     - Input: CLE-NY-TR  Output: CLE-NY
     - Input: CLE-NJ-TR  Output: CLE-NJ
     - Input: CLE-IL-TR  Output: CLE-IL
*/
const TransitionalJurisdictionSuffix = '-TR';
export const removeTransitionalJurisdictionSuffix = (facetValue: string): string => {
  if (facetValue.endsWith(TransitionalJurisdictionSuffix)) {
    return facetValue.substring(0, facetValue.length - TransitionalJurisdictionSuffix.length);
  }
  return facetValue;
};

export const getCombinedQueryFilters = (queryFilters?: QueryFilter[]): string => {
  const combinedQueryFilters = queryFilters
    ? queryFilters
        .filter(item => item.checked)
        .map(item => item.query)
        .join('&')
    : '';
  return combinedQueryFilters;
};

/**
 * Truncate a text to a specified length, ensuring truncation occurs before any incomplete word.
 * @returns The truncated text with "..." if truncation occurs
 */
export const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;

  const truncated = text.slice(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  if (lastSpaceIndex !== -1) {
    return `${truncated.slice(0, lastSpaceIndex).trim()}...`;
  }
  return `${truncated.trim()}...`;
};
