import { IFacetGroup } from '@/@types/client-api';

export const FACET_DELIMITER = ';';

export type ResultStatus = 'has-results' | 'no-results-from-query' | 'no-results-from-filters';

export type ActionStatus = 'idle' | 'loading' | 'error';

export const QUERY_FILTER_GROUP = '__qf';

export enum ColumnLayout {
  RESPONSIVE = 'Responsive',
  STATIC = 'Static'
}

export enum FilterTypes {
  SELECT = 'select',
  RADIO = 'radio',
  CHECK = 'check',
  TABS = 'tabs',
  DATE = 'dateRange',
  YEAR_SELECT = 'yearSelect'
}

export enum FilterBehavior {
  CHECKBOX_OR = 'CheckboxOr',
  CHECKBOX_AND = 'CheckboxAnd',
  SINGLE_SELECT = 'SingleSelect',
  DATE_PICKER = 'DatePicker'
}

export enum CONTENT_TABS {
  ALL_RESULTS = 'All Results'
}

export enum FILTERS {
  CONTENT_TABS = 'ContentTabs',
  PUBLICATION = 'BookFormat',
  LEVEL = 'Level',
  FORMAT = 'ProgramFormat',
  DURATION = 'Duration',
  LOCATION = 'Location',
  PRACTICE_AREA = 'PracticeArea',
  INDUSTRY = 'IndustryTaxonomy',
  CREDIT_TYPE = 'CreditType',
  SUGGESTED_FILTERS = 'SuggestedFilters',
  JURISDICTIONS = 'CreditRegion',
  DATERANGE = 'DateRange',
  // DEPRECATED - TODO Remove after BE stops sending it
  AVAILABLE_DATE_RANGE = 'AvailableDateRange',
  INTENDED_AUDIENCE = 'IntendedAudience',
  INCLUDE_ITEM_CLASS = 'IncludeItemClass',
  PROGRAM_TYPE = 'ProgramType'
}

export enum PROGRAM_FORMATS {
  LIVE_WEBCAST = 'LiveWebcast',
  LIVE = 'LiveInPerson',
  ONDEMAND = 'OnDemand'
}

export type AdHocFilter = {
  value: string;
  groupTypeId: string;
  name: string;
};

export type QueryFilter = {
  title: string;
  query: string;
  checked: boolean;
};

export type InvariantFilter = {
  title?: string;
  name: string;
  value: string;
  facetGroupType: string;
};

export type FacetFilter = {
  title: string;
  value: string;
  altValue?: string;
  facetGroupType: string;
  selectedOnDefault: boolean;
};

export const DATE_FACETS = {
  MIN_DATE: 'MinDate',
  MAX_DATE: 'MaxDate',
  START_DATE: 'StartDate',
  END_DATE: 'EndDate'
};

export const EXCLUDE_FROM_FILTER_PANEL: string[] = [
  FILTERS.CONTENT_TABS,
  FILTERS.SUGGESTED_FILTERS,
  FILTERS.AVAILABLE_DATE_RANGE
];

export const EXCLUDE_FROM_URL_PARAMS: string[] = [
  FILTERS.SUGGESTED_FILTERS,
  FILTERS.AVAILABLE_DATE_RANGE
];

export const ALLOWED_SECTIONED_FILTERS_PANEL_L1: string[] = [FILTERS.CREDIT_TYPE];

export const ALLOWED_SECTIONED_FILTERS_PANEL_L2: string[] = [FILTERS.JURISDICTIONS];

export const HAS_DISABLED_FILTERS: string[] = [FILTERS.JURISDICTIONS, FILTERS.FORMAT];

export type SearchRequestState = {
  query?: string;
  page: number;
  pageSize: number;
  facetGroups?: IFacetGroup[];
  returnMarketingModules?: boolean;
};

export const QUERY_PARAMS = {
  ...FILTERS,
  OLD_QUERY: '_q', // skipped query from auto-applied filters
  DISABLE_SEMANTIC_FILTERS: '_dsf',
  QUERY: 'q',
  PAGE: 'p',
  SORT: 's',
  // Allows us to navigate to a new page and reset the result state
  NEW_SEARCH: '_ns',
  EXPLAIN: 'explain',
  CMS_FACET_FILTERS_APPLIED: '_cff',
  SEARCH_OVERLAY: '_so',
  OVERRIDE_EXCLUSIVE: 'CreditsOverrideExclusive'
};

export interface FilterConfig {
  type: FilterTypes;
}
interface Filter {
  id: string;
  label: string;
  value: string;
  checked: boolean;
}

export interface FacetGroup {
  groupTypeId: string;
  facets: Filter[];
}

export const FIRST_PAGE = 1;
export const PAGE_SIZE = 20;

export const FILTER_BEHAVIOR_TO_TYPE: Record<FilterBehavior, FilterTypes> = {
  [FilterBehavior.CHECKBOX_OR]: FilterTypes.SELECT,
  [FilterBehavior.CHECKBOX_AND]: FilterTypes.CHECK,
  [FilterBehavior.SINGLE_SELECT]: FilterTypes.RADIO,
  [FilterBehavior.DATE_PICKER]: FilterTypes.DATE
};

export interface SearchSemanticFilter {
  keyword?: string;
  regex?: string;
  filters?: Array<{ facetGroupType: string; value: string }>;
  tooltipTitle?: string;
  tooltipDescription?: string;
}

export const DEFAULT_INVARIANT_TOOLTIP_TEXT =
  'This filter cannot be removed because the products here are intended to match this particular filter.';
