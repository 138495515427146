import { IContent } from '@/@types/cms';

export type IContentExtended = IContent & {
  [key: string]: unknown;
};

export const expandChildren = (result: IContentExtended) => {
  // Create a new object to avoid mutating the input directly.
  const expandedResult: IContentExtended = {};

  Object.entries(result).forEach(([key, value]) => {
    expandedResult[key] = value;

    if (Array.isArray(value)) {
      // Map over the array only if it's an array objects.
      expandedResult[key] = value.map(object => {
        if (object?.contentLink?.expanded) {
          // Recursively expand the content if the condition is met.
          return expandChildren(object.contentLink.expanded);
        }
        return object;
      });
    } else if (typeof value === 'object' && value !== null && 'expanded' in value) {
      // Recursively expand if the current property is an object with an 'expanded' property.
      expandedResult[key] = expandChildren(value.expanded as IContentExtended);
    }
  });

  return expandedResult;
};

export const expandArray = (array: IContentExtended[]) => {
  return array.map(item => expandChildren(item)).filter(Boolean);
};
