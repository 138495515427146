import { Fragment } from 'react';
import { PAGE_SIZE } from './constants';
import Shimmer from '../Shimmer';
import classnames from 'classnames';

type SearchResultsLoadingStateProps = {
  resultsCount?: number;
};

const SearchResultsLoadingState = ({
  resultsCount = PAGE_SIZE
}: SearchResultsLoadingStateProps): JSX.Element => {
  const results = Array.from({ length: resultsCount });
  return (
    <>
      {results.map((_, index) => (
        <Fragment key={index}>
          <Shimmer
            key={index}
            className={classnames(
              'size-full h-[350px] rounded-lg sm:h-[370px] lg:h-[400px]',
              'min-w-[75%]',
              'sm:min-w-[37.5%]',
              'md:min-w-[calc(25%-18px)]'
            )}
          />
        </Fragment>
      ))}
    </>
  );
};

export default SearchResultsLoadingState;
