import type { EditorialToutWithImageBlock } from '@/@types/content';
import Image from '@/components/cms/Image';
import RichText from '@/components/cms/RichText';
import Text from '@/components/cms/Text';
import Button from '@/components/ui/Buttons/Button';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import { isExternalUrl } from '@/utils/helpers';
import classnames from 'classnames';
import Block from '../cms/Block';
import VimeoVideo from '../ui/VimeoVimeo';
import ResponsiveImage from '../ui/ResponsiveImage';
import type { RequestAQuoteModalBlock as RequestAQuoteModalBlockType } from '@/@types/content';
import RequestAQuoteModalBlock from '@/components/blocks/RequestAQuoteModalBlock';

enum Layout {
  MediaLeft = 'media-left',
  MediaRight = 'media-right'
}

enum LinkStyle {
  Primary = 'primary',
  Secondary = 'secondary'
}

interface EditorialToutWithImageBlockProps extends EditorialToutWithImageBlock {
  smallImage: boolean;
  outerHeading?: string;
}

const EditorialToutWithImageBlock: React.FC<EditorialToutWithImageBlockProps> = ({
  contentLink,
  layout = Layout.MediaLeft,
  image,
  roundedCorners = false,
  videoID,
  logo,
  eyebrowText,
  heading,
  copy,
  primaryLink,
  link1Style,
  secondaryLink,
  link2Style,
  checkListRed = false,
  smallImage = false,
  aspectRadioLayout = '1:1',
  grayBackground = false,
  outerHeading,
  requestQuoteFlowtComponent
}) => {
  const editMode = isEditOrPreviewMode();
  const screenSizeXsOnly = useMediaQuery(ScreenSizeQueries.xsOnly);
  const requestAQuoteModalBlockProps =
    requestQuoteFlowtComponent?.[0] as RequestAQuoteModalBlockType;

  const flexClasses = classnames(
    'mt-6 flex items-center gap-4 sm:mt-8',
    {
      'sm:gap-12': !grayBackground,
      'sm:grid sm:min-h-[360px] sm:grid-cols-2 sm:gap-6': grayBackground,
      'md:gap-10': !grayBackground,
      'lg:gap-[133px]': !grayBackground
    },
    {
      'sm:flex-row': layout === Layout.MediaLeft,
      'sm:flex-row-reverse': layout === Layout.MediaRight
    },
    {
      'flex-col': layout === Layout.MediaLeft,
      'flex-col-reverse': layout === Layout.MediaRight
    },
    {
      'overflow-hidden rounded-lg bg-gray-feather': grayBackground
    }
  );

  const smallImageClasses = classnames('w-1/2 self-start', 'md:w-2/5');

  const imageColumnClasses = classnames('w-full', {
    'lg:w-1/2 lg:flex-[0_0_calc(50%-12px)]': !grayBackground,
    'sm:featured-content-image sm:h-full': grayBackground,
    'sm:order-1': grayBackground && layout === Layout.MediaRight
  });

  const infoColumnClasses = classnames('flex w-full flex-col gap-8', {
    'p-8': grayBackground,
    'lg:p-16': grayBackground,
    'lg:gap-8': !smallImage
  });

  const infoContentClasses = classnames('flex flex-col items-start gap-6', 'md:gap-4', {
    'lg:gap-6': !grayBackground,
    'lg:max-w-[394px]': grayBackground,
    '!gap-4': smallImage
  });

  const stringToAspectRatio = (aspectRatio: string) => {
    const isValidAspectRatio = /^(\d+):(\d+)$/.test(aspectRatio);
    if (!isValidAspectRatio) {
      return 1;
    }

    const [width, height] = aspectRatio.split(':').map(n => parseInt(n, 10));
    return width / height;
  };

  const imageAspectRatio = stringToAspectRatio(aspectRadioLayout);

  const linkColor = (style?: string) => {
    switch (style) {
      case LinkStyle.Primary:
        return 'black';
      case LinkStyle.Secondary:
        return 'outline-black';
      default:
        return 'outline-black';
    }
  };

  return (
    <Block
      contentLinkID={contentLink?.id}
      className={classnames('container bg-white py-10', 'sm:py-16', {
        'border-b border-solid border-gray-light px-0': smallImage
      })}
    >
      {outerHeading && (
        <Text propertyName="OuterHeading" element="h3" className="heading-6-medium">
          {outerHeading}
        </Text>
      )}
      <div className={flexClasses}>
        {image || videoID || editMode ? (
          <div className={smallImage ? smallImageClasses : imageColumnClasses}>
            {(!videoID || editMode) && (
              <ResponsiveImage
                propertyName="Image"
                src={image?.expanded?.url ?? image?.url}
                alt={image?.alt as string}
                className={classnames('size-full object-cover', {
                  'aspect-square': !videoID,
                  'aspect-video': videoID,
                  'rounded-lg': roundedCorners
                })}
                loading="lazy"
                aspectRatio={imageAspectRatio}
                imageSizes={{
                  xs: '330px',
                  sm: '500px',
                  md: '768px',
                  lg: '638px'
                }}
              />
            )}

            {!editMode && videoID && (
              <VimeoVideo
                videoId={videoID}
                thumbnail={image}
                title={image?.alt as string}
                ratio={16 / 9}
              />
            )}

            {editMode && (
              <Text propertyName="videoID" className="mt-2">
                {videoID ? (
                  `Vimeo video ID: ${videoID}`
                ) : (
                  <span className="font-bold">Add Vimeo video ID</span>
                )}
              </Text>
            )}
          </div>
        ) : null}

        <div className={infoColumnClasses}>
          <div className={infoContentClasses}>
            <Image
              propertyName="Logo"
              src={logo?.url}
              alt={logo?.alt as string}
              className={classnames('h-5')}
            />

            <div className="flex flex-col gap-1">
              <Text
                propertyName="EyebrowText"
                element="span"
                className={classnames({
                  'heading-6-medium': imageAspectRatio === 1,
                  'label text-gray-dark': imageAspectRatio !== 1
                })}
              >
                {eyebrowText}
              </Text>

              <Text
                propertyName="Heading"
                element="h2"
                className={smallImage && !screenSizeXsOnly ? 'heading-3' : 'heading-5'}
              >
                {heading}
              </Text>
            </div>

            <RichText
              propertyName="Copy"
              content={copy || ''}
              className="text-1 text-gray-dark"
              checkList={true}
              checklistRed={checkListRed}
            />
          </div>

          {primaryLink || secondaryLink || editMode || requestAQuoteModalBlockProps ? (
            <div className="flex w-fit flex-col gap-2">
              {primaryLink || editMode ? (
                <Button
                  className="!w-auto !justify-center"
                  propertyName="PrimaryLink"
                  color={linkColor(link1Style)}
                  href={primaryLink?.href}
                  label={primaryLink?.text}
                  target={primaryLink?.target}
                  title={primaryLink?.title}
                  iconRight={
                    primaryLink.target === '_blank' || isExternalUrl(primaryLink?.href)
                      ? 'link-out'
                      : undefined
                  }
                  size="large"
                />
              ) : null}

              {secondaryLink || editMode ? (
                <Button
                  className="!w-auto !justify-center"
                  propertyName="SecondaryLink"
                  color={linkColor(link2Style)}
                  href={secondaryLink?.href}
                  label={secondaryLink?.text}
                  target={secondaryLink?.target}
                  title={secondaryLink?.title}
                  iconRight={
                    secondaryLink.target === '_blank' || isExternalUrl(secondaryLink?.href)
                      ? 'link-out'
                      : undefined
                  }
                  size="large"
                />
              ) : null}

              {requestAQuoteModalBlockProps ? (
                <RequestAQuoteModalBlock
                  {...requestAQuoteModalBlockProps}
                  triggerButton={
                    <Button
                      className="!w-auto !justify-center"
                      color={linkColor(link2Style)}
                      label={requestAQuoteModalBlockProps?.heading || ''}
                      size="large"
                    />
                  }
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </Block>
  );
};

export default EditorialToutWithImageBlock;
