import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import SectionTitle from '@/components/ui/Titles/SectionTitle';
import DropdownSelectAutocomplete, {
  SelectOption
} from '@/components/ui/DropdownSelectAutocomplete';
import CreditInfoAccordionItem from '@/components/blocks/CreditInfo/snippets/CreditInfoAccordionItem';
import LinkBlock from '@/components/blocks/LinkGridBlock/LinkBlock';
import { LinkItem } from '@/@types/cms';
import classnames from 'classnames';
import Link from '@/components/ui/Link';

export interface AccordionState {
  value: string;
  title: string;
  content: JSX.Element;
}

export interface CreditInfoAccordionSectionProps {
  jurisdictionDropdownText?: string;
  identifier: string;
  parentName?: string;
  accordionSectionHeader?: string;
  showSectionHeader?: boolean;
  items: AccordionState[];
  creditClassLinks?: CreditClassLink[];
  search?: (searchPhrase: string, items?: string[]) => string[] | undefined;
}

export type CreditClassLink = { text: string; url: string };

const CreditInfoAccordionSection: React.FC<CreditInfoAccordionSectionProps> = ({
  jurisdictionDropdownText,
  identifier,
  items: allAccordions,
  creditClassLinks,
  accordionSectionHeader,
  search,
  showSectionHeader = false
}) => {
  const [filteredAccordions, setFilteredAccordions] = useState<SelectOption[]>();
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<string>();
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);
  const [allExpanded, setAllExpanded] = useState<boolean>();

  const scrollElements = useRef<{ [key: string]: HTMLElement }>({});

  const allRegionNames = useMemo(() => {
    return allAccordions?.map(a => a.title);
  }, [allAccordions]);

  const expandAll = useCallback(() => {
    setExpandedAccordions(
      expandedAccordions?.length != allAccordions?.length
        ? (allAccordions || []).map(item => item.value)
        : []
    );
    setAllExpanded(!allExpanded);
  }, [allAccordions, allExpanded, expandedAccordions?.length]);

  const onTypeAhead = useCallback(
    (phrase: string) => {
      if (phrase?.length == 0 || !allAccordions?.length) {
        setFilteredAccordions([]);
      } else {
        const result = search!(phrase, allRegionNames)?.map(region => {
          return {
            label: region,
            value: allAccordions!.find(a => a.title == region)!.value
          };
        }) as SelectOption[];
        setFilteredAccordions([...new Set(result)]);
      }
    },
    [allAccordions, allRegionNames, search]
  );

  const scrollToItem = useCallback((refItem?: HTMLElement) => {
    if (refItem) {
      const refRect = refItem?.getBoundingClientRect();
      const topPosition = refRect ? refRect.top - refRect.height + window.scrollY : 0;
      window.scrollTo({
        top: topPosition,
        behavior: 'smooth'
      });
    }
  }, []);

  const onTypeAheadValueChange = useCallback(
    (value: string) => {
      const refItem = scrollElements.current[value];

      if (refItem) {
        const expanded = [...expandedAccordions, value];
        setSelectedJurisdiction(value);
        setExpandedAccordions([...new Set(expanded)]);
        setFilteredAccordions([]);
        scrollToItem(refItem);
      }
    },
    [expandedAccordions, scrollToItem]
  );

  const onAccordionItemClick = useCallback(
    (value: string) => {
      const expanded = [...expandedAccordions];
      const foundIndex = expanded.indexOf(value);
      if (foundIndex >= 0) {
        expanded.splice(foundIndex, 1);
      } else {
        expanded.push(value);
      }
      setExpandedAccordions(expanded);
    },
    [expandedAccordions]
  );

  const formatAccordionItems = useMemo(() => {
    return allAccordions?.map(acc => {
      return (
        <CreditInfoAccordionItem
          key={acc.value}
          content={acc.content}
          title={acc.title}
          value={acc.value}
          isExpanded={expandedAccordions.includes(acc.value)}
          onClick={onAccordionItemClick}
          ref={element => {
            if (element && scrollElements.current) {
              scrollElements.current[acc.value] = element;
            }
          }}
        />
      );
    });
  }, [allAccordions, expandedAccordions, onAccordionItemClick]);

  const typeaheadDropdown = useMemo(() => {
    return (
      !!search &&
      !!jurisdictionDropdownText && (
        <div className="my-10 block *:h-full [&>div>button]:h-full">
          <DropdownSelectAutocomplete
            label="Jurisdiction"
            placeholder={jurisdictionDropdownText}
            value={selectedJurisdiction}
            onSearchTextChange={value => {
              onTypeAhead(value);
            }}
            onValueChange={onTypeAheadValueChange}
            options={
              filteredAccordions?.length
                ? filteredAccordions
                : (allRegionNames?.map(a => {
                    return {
                      label: a,
                      value: allAccordions!.find(b => b.title == a)!.value
                    };
                  }) ?? [])
            }
          />
        </div>
      )
    );
  }, [
    allAccordions,
    allRegionNames,
    filteredAccordions,
    jurisdictionDropdownText,
    onTypeAhead,
    onTypeAheadValueChange,
    search,
    selectedJurisdiction
  ]);

  useEffect(() => {
    setAllExpanded(allAccordions?.length == expandedAccordions?.length);
  }, [allAccordions?.length, expandedAccordions?.length]);

  return (
    <div key={`accordion-section-${identifier}`}>
      {typeaheadDropdown}
      {showSectionHeader && (
        <SectionTitle
          title={accordionSectionHeader}
          propertyNameTitle="Title"
          elementType="h2"
          divider={{ color: 'light' }}
          className="mb-10"
        >
          {!!formatAccordionItems?.length && (
            <Link
              href="#"
              text={allExpanded ? 'Collapse all' : 'Expand all'}
              variant="bold-link-with-icon"
              icon={allExpanded ? 'chevron-up' : 'chevron-down'}
              onClick={expandAll}
              as="button"
            />
          )}
        </SectionTitle>
      )}
      {!!creditClassLinks?.length &&
        creditClassLinks.map((item, i) => {
          const _class = classnames({
            'mb-4': i < creditClassLinks.length
          });
          return (
            <div className={_class} key={`accordion-section-link-${identifier}`}>
              <LinkBlock link={{ text: item.text, href: item.url } as LinkItem} />
            </div>
          );
        })}
      {!!formatAccordionItems?.length && (
        <Accordion.Root
          type="multiple"
          value={expandedAccordions}
          key={`${identifier}-accordion-section`}
          className="my-16"
        >
          {formatAccordionItems}
        </Accordion.Root>
      )}
    </div>
  );
};

export default CreditInfoAccordionSection;
