import { isEditOrPreviewMode } from '@/lib/editModeHelpers';
import {
  useGetTaxonomySubjectsQuery,
  useGetTaxonomyIndustriesQuery
} from '@/redux/api/client/taxonomy';
import Link from '@/components/cms/Link';
import { TaxonomyTabItemBlock as TaxonomyTabItemBlockProps } from '@/@types/content';
import { SubjectResponse, UserIndustry } from '@/@types/client-api';
import { useEffect, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import classnames from 'classnames';
import Icon from '@/components/ui/Icon';
import Text from '@/components/cms/Text';
import Shimmer from '@/components/ui/Shimmer';
import LinkUI from '@/components/ui/Link';

export type SubnodeTaxonomyProps = TaxonomyTabItemBlockProps & {
  multiLevel?: boolean;
  isPracticeArea?: boolean;
};

type SubChildrenTaxonomy = SubjectResponse & {
  id?: number;
  name?: string;
  url?: string;
  firstLevel?: boolean;
  className?: string;
  lastItem?: boolean;
  onlyItem?: boolean;
};

export type MultipleItemSubnodes = TaxonomyTabItemBlockProps & {
  subChildren?: SubChildrenTaxonomy[];
  firstLevel?: boolean;
  lastItem?: boolean;
  onlyItem?: boolean;
  expandState?: ExpandState;
  clearExpandState: () => void;
};

const GetSubjects = () => {
  const { isLoading, data } = useGetTaxonomySubjectsQuery(false);
  if (isLoading) return undefined;
  return data as SubChildrenTaxonomy[];
};

const GetIndustries = () => {
  const { isLoading, data } = useGetTaxonomyIndustriesQuery();
  if (isLoading) return undefined;
  return data?.map((industry: UserIndustry) => ({
    id: industry.id,
    name: industry.name,
    url: industry.url,
    subjects: []
  })) as SubChildrenTaxonomy[];
};

enum ExpandState {
  ALL_EXPANDED,
  ALL_COLLAPSED,
  UNCONTROLLED
}

const SingleItemNode: React.FC<SubChildrenTaxonomy> = ({
  name,
  url,
  firstLevel,
  className,
  lastItem,
  onlyItem
}) => {
  const linkLabelClasses = classnames(
    'relative line-clamp-1 w-full text-left text-black underline after:absolute after:inset-0 after:size-full',
    {
      'heading-6-medium': firstLevel,
      'text-1-medium': !firstLevel
    }
  );

  const itemSubnodeClasses = classnames(
    'flex items-center justify-between gap-4',
    {
      'group relative mb-2 rounded-lg border border-gray-light bg-gray-lightest p-6 hover:cursor-pointer hover:border-silver hover:bg-gray-light lg:py-3':
        firstLevel,
      'relative pb-0 before:absolute before:-start-80 before:top-0 before:z-0 before:h-full before:w-[200%] before:hover:bg-gray-light':
        !firstLevel,
      'pb-0': lastItem && onlyItem
    },
    className
  );

  if (url) {
    return (
      <div className={itemSubnodeClasses}>
        <div
          className={classnames('flex w-full items-center justify-between', {
            'border-b border-silver pb-5 lg:pb-3': !firstLevel && !lastItem && !onlyItem,
            'pb-5 lg:pb-3': lastItem
          })}
        >
          <Link propertyName="Name" className={linkLabelClasses} href={url}>
            {name}
          </Link>
        </div>
      </div>
    );
  }
};

const MultipleItemSubnodes: React.FC<MultipleItemSubnodes> = ({
  name,
  subChildren,
  url,
  firstLevel,
  lastItem,
  expandState,
  clearExpandState
}) => {
  const [openSubnode, setOpenSubnode] = useState(false);
  const editMode = isEditOrPreviewMode();

  useEffect(() => {
    if (expandState === ExpandState.ALL_EXPANDED) {
      setOpenSubnode(true);
    } else if (expandState === ExpandState.ALL_COLLAPSED) {
      setOpenSubnode(false);
    }
  }, [expandState]);

  const handleOpenSubnode = () => {
    setOpenSubnode(!openSubnode);
    if (expandState != ExpandState.UNCONTROLLED) {
      clearExpandState();
    }
  };

  const subNodeTrigger = classnames('relative hover:cursor-pointer hover:border-silver', {
    'mb-2 overflow-hidden rounded-lg border border-gray-light bg-gray-lightest': firstLevel
  });

  const itemSubnodeClasses = classnames(
    'group flex w-full items-center justify-between gap-4 px-4 py-3 sm:px-6',
    {
      'relative pb-0 before:absolute before:-start-80 before:top-0 before:z-0 before:h-full before:w-[200%] before:hover:bg-gray-light':
        !firstLevel,
      'hover:bg-gray-light': !openSubnode
    }
  );

  const itemSubnodeChildrenClasses = classnames(itemSubnodeClasses, 'relative');

  const labelClasses = classnames('line-clamp-1 text-left text-black underline', {
    'heading-6-medium': firstLevel,
    'text-1-medium': !firstLevel,
    'max-w-[80%] md:max-w-[90%]': !openSubnode,
    'max-w-[62%] sm:max-w-[70%] md:max-w-[85%]': openSubnode
  });

  const chevronClasses = classnames('relative flex items-center gap-6', {
    'group-hover:text-red': !openSubnode && !firstLevel,
    'text-red': openSubnode && !firstLevel
  });

  return (
    <Collapsible.Root
      className={subNodeTrigger}
      open={openSubnode}
      onOpenChange={handleOpenSubnode}
    >
      <Collapsible.Trigger
        className={classnames(itemSubnodeClasses, {
          'pt-5 lg:pt-3': firstLevel,
          'pb-0': openSubnode,
          'pb-5 lg:pb-3': !openSubnode && lastItem
        })}
      >
        <div
          className={classnames('flex w-full items-center justify-between', {
            'border-b border-silver pb-5 lg:pb-3': openSubnode || (!firstLevel && !lastItem)
          })}
        >
          {(name || editMode) && (
            <Link propertyName="Name" className={labelClasses} href={url}>
              <span className="relative">{name}</span>
            </Link>
          )}
          <div className={chevronClasses}>
            <Icon
              name={openSubnode ? 'chevron-up' : 'chevron-down'}
              size="medium"
              className="min-w-5"
            />
          </div>
        </div>
      </Collapsible.Trigger>
      <Collapsible.Content
        className={classnames('flex flex-col', {
          'ml-6 sm:ml-10': !firstLevel
        })}
      >
        {subChildren!.map((item, index) => {
          const lastItem = subChildren!.length - 1 === index;
          const onlyItem = subChildren!.length === 1;
          if (!item.subjects) {
            return (
              <SingleItemNode
                key={index}
                {...subChildren![index]}
                firstLevel={false}
                className={itemSubnodeChildrenClasses}
                lastItem={lastItem}
                onlyItem={onlyItem}
              />
            );
          } else {
            return (
              <MultipleItemSubnodes
                key={index}
                {...subChildren![index]}
                subChildren={
                  item.subjects?.map(subject => ({
                    name: subject.name ?? '',
                    url: subject.url ?? '',
                    code: subject.code ?? '',
                    subjects: subject.subjects
                  })) ?? []
                }
                firstLevel={false}
                lastItem={lastItem}
                expandState={expandState}
                clearExpandState={clearExpandState}
              />
            );
          }
        })}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

const TaxonomyItem: React.FC<SubnodeTaxonomyProps> = ({ heading, isPracticeArea }) => {
  const [isLoadingSubjectsQuery, setIsLoadingSubjectsQuery] = useState(true);
  const [expandState, setExpandState] = useState(ExpandState.UNCONTROLLED);
  const taxonomyData = isPracticeArea ? GetSubjects() : GetIndustries();

  let taxonomySubNode;

  useEffect(() => {
    if (
      taxonomyData === undefined ||
      taxonomyData?.some(f => (f.subjects?.length ?? 0) > 0) != isPracticeArea
    ) {
      setIsLoadingSubjectsQuery(true);
    } else {
      setIsLoadingSubjectsQuery(false);
    }
  }, [taxonomyData, isPracticeArea]);

  const handleExpandState = () => {
    setExpandState(
      expandState === ExpandState.ALL_EXPANDED
        ? ExpandState.ALL_COLLAPSED
        : ExpandState.ALL_EXPANDED
    );
  };

  const clearExpandState = () => {
    setExpandState(ExpandState.UNCONTROLLED);
  };

  const catalogChildrenBuilderPracticeArea = (
    <>
      {!isLoadingSubjectsQuery ? (
        taxonomyData!.map((item, index) => {
          if ((item?.subjects?.length ?? 0) === 0) {
            taxonomySubNode = (
              <SingleItemNode key={index} name={item.name!} url={item.url!} firstLevel={true} />
            );
          } else {
            taxonomySubNode = (
              <MultipleItemSubnodes
                key={index}
                name={item.name ?? ''}
                url={item.url ?? ''}
                subChildren={
                  item.subjects?.map(subject => ({
                    name: subject.name ?? '',
                    url: subject.url ?? '',
                    code: subject.code ?? undefined,
                    subjects: subject.subjects
                  })) ?? []
                }
                firstLevel={true}
                expandState={expandState}
                clearExpandState={clearExpandState}
              />
            );
          }
          return taxonomySubNode;
        })
      ) : (
        <>
          <Shimmer className="h-12 w-full" />
          <Shimmer className="h-12 w-full" />
          <Shimmer className="h-12 w-full" />
          <Shimmer className="h-12 w-full" />
        </>
      )}
    </>
  );

  return (
    <div className="my-16 w-full">
      <div className="mb-8 flex items-center justify-between">
        <Text element="h3" className="heading-6-medium" propertyName="Heading">
          {heading}
        </Text>
        <LinkUI
          href="#"
          text={expandState != ExpandState.ALL_EXPANDED ? 'Expand all' : 'Collapse all'}
          variant="bold-link-with-icon"
          icon={expandState != ExpandState.ALL_EXPANDED ? 'chevron-down' : 'chevron-up'}
          onClick={handleExpandState}
          as="button"
        />
      </div>
      {catalogChildrenBuilderPracticeArea}
    </div>
  );
};

export default TaxonomyItem;
