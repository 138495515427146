import SearchResults, { SearchResultsProps } from '@/components/ui/SearchResults/SearchResults';
import {
  RecommendedSearchListBlock,
  SearchResultBlock as SearchResultBlockProps
} from '@/@types/content';
import { SearchContext } from '@/analytics/constants';
import { AnalyticsContext } from '@/analytics/constants';
import { IContent } from '@/@types/cms';

type SearchProps = SearchResultBlockProps &
  SearchResultsProps & {
    modules?: IContent[];
    tCode?: string;
  };

const SearchResultBlock = ({
  context = AnalyticsContext.Search,
  searchContext = SearchContext.ResultsBlock,
  modules,
  ...props
}: SearchProps) => {
  // Force reset block when contentLink or invariantFilters change
  const key = [props.contentLink?.guidValue, JSON.stringify(props.invariantFilters)].join('-');

  const recommendedSearchList =
    modules &&
    (Object.values(modules).find(content =>
      content?.contentType?.includes('RecommendedSearchListBlock')
    ) as RecommendedSearchListBlock);

  return (
    <SearchResults
      context={context}
      searchContext={searchContext}
      recommendedSearchList={recommendedSearchList?.enable ? recommendedSearchList : undefined}
      {...props}
      key={key}
    />
  );
};

export default SearchResultBlock;
