import { useEffect, useRef, useState } from 'react';
import Filter from '../Filter/Filter';
import { FilterProps } from '../Filter/types';
import { ALLOWED_SECTIONED_FILTERS_PANEL_L1 } from './constants';
import SearchResultsFilterOverlayL2 from './SearchResultsFilterOverlayL2';
import ExpandFilterButton from '../Filter/ExpandFilterButton';
import { FilterContext, SearchContext } from '@/analytics/constants';

type SearchFilterProps = {
  filter: FilterProps;
  fetching: boolean;
  ariaHint: string;
  onClose: () => void;
  onBack: () => void;
  onClear: (payload?: { groupId?: string }) => void;
  expandButtonFocused: boolean;
  context?: FilterContext;
  searchContext?: SearchContext;
};

const SearchFilter = ({
  fetching,
  filter,
  ariaHint,
  onClose,
  onBack,
  onClear,
  expandButtonFocused,
  context,
  searchContext
}: SearchFilterProps): JSX.Element => {
  const expandButtonRef = useRef<HTMLButtonElement>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (!expandButtonRef.current || !expandButtonFocused) {
      return;
    }
    const timer = setTimeout(() => {
      expandButtonRef.current?.focus();
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, [expandButtonFocused, filter.groupTypeId]);

  return (
    <Filter
      {...filter}
      items={filter.maxCount ? filter.items.slice(0, filter.maxCount) : filter.items}
      allowSections={ALLOWED_SECTIONED_FILTERS_PANEL_L1.includes(filter.groupTypeId)}
      fetching={fetching}
      expandFilterButton={
        filter.hasMore ? (
          <SearchResultsFilterOverlayL2
            isOpen={expanded}
            onOpenChange={setExpanded}
            filter={filter}
            ariaHint={ariaHint}
            onClose={onClose}
            onBack={onBack}
            onClear={onClear}
            fetching={fetching}
            context={context}
            searchContext={searchContext}
            triggerButton={
              <ExpandFilterButton ref={expandButtonRef} expandText={filter.expandText} />
            }
          />
        ) : undefined
      }
    />
  );
};

export default SearchFilter;
