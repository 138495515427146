import { memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import StandardBlackHeaderBlock from '@/components/blocks/Masthead/StandardBlackHeaderBlock';
import Shimmer from '@/components/ui/Shimmer';
import DropdownSelect from '@/components/ui/DropdownSelect';
import TagButton from '@/components/ui/Buttons/TagButton';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ScreenSizeQueries } from '@/constants/breakpoints';
import { selectUserRegions } from '@/redux/selectors/creditTrackerSelectors';
import DropdownMenu from '@/components/ui/DropdownMenu';
import { CreditTrackerContext } from '@/components/blocks/CreditTracker/CreditTrackerContext';

export interface ITrackerHeaderProps {
  setActiveRegion: (region: string) => void;
  setShowExternalCreditModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCarryoverCreditModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddLicenseModal?: React.Dispatch<React.SetStateAction<boolean>>;
  name?: string;
  isSetup?: boolean;
  isError?: boolean;
  individualSk?: string;
  memsuiteUrl?: URL | null;
}

const TrackerHeader: React.FC<ITrackerHeaderProps> = memo(
  ({
    setActiveRegion,
    setShowExternalCreditModal,
    setShowCarryoverCreditModal,
    setShowAddLicenseModal,
    name,
    isSetup,
    isError,
    memsuiteUrl,
    individualSk
  }) => {
    const IsBelowMedium = useMediaQuery(ScreenSizeQueries.belowMd);
    const selectedUserRegions = useSelector(selectUserRegions());
    const {
      selectedRegion,
      isLoading: trackerLoading,
      isTransitionalOrNewlyAdmittedRegion,
      adminDisplayName
    } = useContext(CreditTrackerContext);

    const memsuiteUrlWithParams = useMemo(() => {
      if (memsuiteUrl && individualSk) {
        const url = new URL(memsuiteUrl);
        url.searchParams.append('indv_sk', individualSk);
        return url;
      }
    }, [memsuiteUrl, individualSk]);

    const addDropdownChoices = useMemo(() => {
      if (isError) return [];
      if (isSetup)
        return [
          {
            label: 'Add a new license',
            onClick: () =>
              setTimeout(() => setShowAddLicenseModal && setShowAddLicenseModal(true), 10)
          }
        ];

      const choices = [
        {
          label: 'Add external credit',
          onClick: () => {
            setTimeout(() => setShowExternalCreditModal && setShowExternalCreditModal(true), 10);
          } //temp fix for Radix issue: https://github.com/radix-ui/primitives/issues/1241
        }
      ];

      if (!isTransitionalOrNewlyAdmittedRegion) {
        choices.push({
          label: 'Add carryover credit',
          onClick: () => {
            setTimeout(() => setShowCarryoverCreditModal && setShowCarryoverCreditModal(true), 10);
          }
        });
      }

      choices.push({
        label: 'Add a new license',
        onClick: () => {
          if (memsuiteUrlWithParams) {
            window.open(memsuiteUrlWithParams, '_blank');
          } else {
            setTimeout(() => setShowAddLicenseModal && setShowAddLicenseModal(true), 10);
          }
        }
      });
      return choices;
    }, [
      isError,
      isSetup,
      isTransitionalOrNewlyAdmittedRegion,
      memsuiteUrlWithParams,
      setShowAddLicenseModal,
      setShowCarryoverCreditModal,
      setShowExternalCreditModal
    ]);

    return (
      <div className="h-fit *:flex *:min-h-44 *:items-end">
        <StandardBlackHeaderBlock
          membershipLabel={adminDisplayName ?? ''}
          membershipLabelUrl={memsuiteUrlWithParams}
          pageTitle={name ?? ''}
          rightContent={
            <div className="flex flex-col sm:flex-row">
              {trackerLoading ? (
                <Shimmer className="h-[56px] w-full rounded md:w-[225px]" />
              ) : selectedUserRegions?.length > 3 || IsBelowMedium ? (
                <DropdownSelect
                  button={({ isOpen, value, ...props }) => (
                    <TagButton
                      isActive={isOpen}
                      icon={isOpen ? 'chevron-up' : 'chevron-down'}
                      styleType="black"
                      className={classnames(
                        'mb-2 justify-center px-5 *:max-w-full',
                        'sm:mb-0 sm:w-auto',
                        'focus-visible:outline-white'
                      )}
                      {...props}
                      label={value}
                      aria-label="See more license options"
                    />
                  )}
                  options={
                    selectedUserRegions?.map(region => ({
                      label: region.creditRegionName,
                      value: region.creditRegionShortDescription
                    })) ?? []
                  }
                  value={selectedRegion?.creditRegionShortDescription ?? ''}
                  onValueChange={value => setActiveRegion(value)}
                  menuStyle="standalone-dark-unsorted"
                  placeholder="My licenses: "
                  valueClassName="w-full truncate"
                />
              ) : (
                selectedUserRegions?.map((region, i) => {
                  return (
                    <TagButton
                      isActive={
                        selectedRegion?.creditRegionLongDescription ===
                        region.creditRegionLongDescription
                      }
                      key={i}
                      styleType="black-on-black"
                      label={region.creditRegionName ?? ''}
                      className={classnames(
                        'ml-4 h-[56px] px-4 py-[10px]',
                        'focus-visible:outline-white'
                      )}
                      onClick={() => setActiveRegion(region.creditRegionShortDescription ?? '')}
                    />
                  );
                })
              )}
              {trackerLoading ? (
                <Shimmer className="mt-2 h-[56px] w-full rounded sm:ml-4 sm:mt-0 md:w-[77px]" />
              ) : (
                !!addDropdownChoices?.length && (
                  <DropdownMenu
                    align="end"
                    button={({ isOpen }) => (
                      <TagButton
                        aria-label="Add options"
                        icon={isOpen ? 'chevron-up' : 'chevron-down'}
                        styleType="black"
                        label="Add"
                        className={classnames(
                          'mb-2 w-full justify-center',
                          'sm:mb-0 sm:ml-4 sm:w-auto',
                          'focus-visible:outline-white'
                        )}
                      />
                    )}
                    items={addDropdownChoices?.map(choice => ({
                      label: choice.label,
                      onClick: choice.onClick
                    }))}
                    isModal={false}
                  />
                )
              )}
            </div>
          }
        />
      </div>
    );
  }
);

export default TrackerHeader;
