import { useState, useMemo } from 'react';
import { SubscriptionBlock as SubscriptionBlockProps } from '@/@types/content';
import useToast from '@/hooks/useToast';
import Block from '../cms/Block';
import Text from '../cms/Text';
import { Form } from '@radix-ui/react-form';
import Button from '../ui/Buttons/Button';
import TextInput from '../ui/Form/TextInput';
import { trackFormSubmitComplete } from '@/analytics/form';
import { FormContext } from '@/analytics/constants';

const SubscriptionBlock: React.FC<SubscriptionBlockProps> = ({
  contentLink,
  heading,
  firstNameLabel,
  subHeading,
  lastNameLabel,
  pardotFormHandlerUrl,
  emailLabel,
  hiddenFields,
  subscribeButtonLabel,
  successLabel,
  errorLabel,
  name,
  blockNameForEventLogging
}) => {
  const { showSuccessMessage, showFailMessage } = useToast();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formName = useMemo(() => {
    return blockNameForEventLogging ?? `${name!} ${contentLink?.id}`;
  }, [blockNameForEventLogging, name, contentLink?.id]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    const hiddenParams = hiddenFields?.reduce<{ [key: string]: string }>((acc, curr) => {
      if (curr.name && curr.value) {
        acc[curr.name] = curr.value;
      }
      return acc;
    }, {});

    try {
      await fetch(pardotFormHandlerUrl!, {
        method: 'POST',
        mode: 'no-cors',
        redirect: 'follow',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          firstName: firstName,
          lastName: lastName,
          email: email,
          ...hiddenParams
        }).toString()
      });

      setFirstName('');
      setLastName('');
      setEmail('');

      showSuccessMessage(successLabel!);

      trackFormSubmitComplete({
        formName: formName,
        context: FormContext.Subscribe
      });
    } catch (error) {
      showFailMessage(errorLabel);
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Block contentLinkID={contentLink?.id} className="container module-spacing">
      <div  data-component={"SubscriptionBlock"} className="rounded-lg bg-gray-lightest p-8 lg:p-12">
        <Text element="h2" propertyName="Heading" className="heading-5">
          {heading}
        </Text>
        <Text propertyName="SubHeading" className="text-1 mt-2 text-gray-darker">
          {subHeading}
        </Text>
        <div className="mt-8">
          <Form className="flex flex-col gap-y-8 lg:flex-row lg:gap-x-[35px]" onSubmit={onSubmit}>
            <div className="flex grow flex-col md:flex-row md:flex-wrap md:gap-x-3">
              <TextInput
                label={firstNameLabel!}
                value={firstName}
                onChange={setFirstName}
                onFocus={() => {}}
                onIconClick={() => {}}
                placeholder={firstNameLabel}
                variant="line"
                className="py-[26px] md:flex-1"
                required
              />
              <TextInput
                label={lastNameLabel!}
                value={lastName}
                onChange={setLastName}
                onFocus={() => {}}
                onIconClick={() => {}}
                placeholder={lastNameLabel}
                variant="line"
                className="py-[26px] md:flex-1"
                required
              />
              <TextInput
                type="email"
                label={emailLabel!}
                value={email}
                onChange={setEmail}
                onFocus={() => {}}
                onIconClick={() => {}}
                placeholder={emailLabel}
                variant="line"
                className="py-[26px] md:w-full lg:w-auto lg:flex-1"
                required
              />
            </div>
            <Button
              label={subscribeButtonLabel}
              color="outline-black"
              size="large"
              className="self-start lg:mt-2"
              disabled={isSubmitting}
            />
          </Form>
        </div>
      </div>
    </Block>
  );
};

export default SubscriptionBlock;
