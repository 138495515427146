import React, { ReactNode } from 'react';
import parse from 'html-react-parser';

import Link from '@/components/ui/Link';

export interface SnippetExpandedContentProps {
  data: {
    title?: string;
    content?: string;
    list?: ReactNode[];
    inlineList?: ReactNode[];
    maxWords?: number;
    truncateContent?: boolean;
    hasCreditModal?: boolean;
  }[];
  expandLink: string | null;
}

const SnippetExpandedContent: React.FC<SnippetExpandedContentProps> = ({ data, expandLink }) => {
  const renderContent = (content: string, truncateContent = true, maxWords = 98) => {
    if (!content) return null;

    if (!truncateContent) return parse(content);

    const words = content.split(' ');
    const truncatedContent = words.slice(0, maxWords).join(' ');
    return (
      <>
        <span className="inline">{parse(truncatedContent)}</span>
        {words.length > maxWords && (
          <>
            <span className="inline">...</span>
            <Link
              as="a"
              href={expandLink || ''}
              className="text-link inline underline"
              text="See more"
            />
          </>
        )}
      </>
    );
  };

  const renderInlineList = (inlineList: ReactNode[]) => {
    if (!inlineList) return null;

    if (inlineList.length === 2) {
      return (
        <span className="text-2 flex flex-wrap gap-1">
          {inlineList[0]} <span role="separator">&</span> {inlineList[1]}
        </span>
      );
    }

    return (
      <ul className="text-2 flex flex-wrap gap-1">
        {inlineList.map((inlineItem, idx) => (
          <li key={idx}>{inlineItem}</li>
        ))}
      </ul>
    );
  };

  return (
    <div className="pt-2 md:pt-10">
      {data.map((item, index) => (
        <div key={index} className="mt-6 first:mt-0">
          {item.title && <h2 className="text-2 font-semibold">{item.title}</h2>}
          <div className="text-2">
            {item.content && renderContent(item.content, item.truncateContent, item.maxWords)}
          </div>

          {item.list && (
            <ul className="text-2">
              {(item.hasCreditModal ? item.list.slice(0, 2) : item.list).map((listItem, idx) => (
                <li key={idx}>{listItem}</li>
              ))}
              {/* TODO: When there is a modal component, implemment the Credit details for your licenses modal. */}
              {/* https://www.figma.com/file/Sv9UZxkQpErocgoEPnrKmo/Detailed-Design%3A-Search-%26-FAP?type=design&node-id=5551-194794&mode=design&t=VmhJynffPqSBKyzt-0 */}

              {item.list.length > 2 && item.hasCreditModal && (
                <li>
                  <button className="text-link underline" onClick={() => {}}>
                    See all approved credits
                  </button>
                </li>
              )}
            </ul>
          )}
          {item.inlineList?.length && renderInlineList(item.inlineList)}
        </div>
      ))}
    </div>
  );
};

export default SnippetExpandedContent;

{
  /* When there is a modal component, implemment the Credit details for your licenses modal. */
}
