export interface ILoaderProps {
  showBackdrop?: boolean;
  solidBackground?: boolean;
}

export const Loader = (props: ILoaderProps) => {
  const { showBackdrop = true, solidBackground = true } = props;

  const spinner = <div className={`loader__spinner`} />;

  return (
    <>
      {showBackdrop && (
        <div
          className={`loader ${solidBackground ? 'loader__solid-background' : ''} grid h-screen w-screen content-center justify-items-center`}
          role="img"
          aria-label="Loading"
        >
          {spinner}
        </div>
      )}
      {!showBackdrop && spinner}
    </>
  );
};

export default Loader;
