export const isEditMode = () => {
  const params = window.location.search.split(/[&?]+/);
  return params.includes('epieditmode=true') ? 'True' : 'False';
};

export const isEditOrPreviewMode = () => {
  const params = window.location.search.split(/[&?]+/);
  return params.includes('epieditmode=true') || params.includes('epieditmode=false');
};

export const subcribeContentSavedEvent = (callback: (message: unknown) => void) => {
  if (window.epi) {
    window.epi.subscribe('contentSaved', callback);
  } else {
    setTimeout(() => {
      subcribeContentSavedEvent(callback);
    }, 1000);
  }
};
