import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

export const getIsDismissed = (cookieName: string): boolean => {
  const cookieValue = Cookies.get(cookieName);
  return cookieValue === 'true';
};

function useDismissable(
  cookieName: string,
  expires: number | Date = 365
): [boolean, (dismissed: boolean) => void] {
  const [isDismissed, setIsDismissed] = useState<boolean>(() => {
    return getIsDismissed(cookieName);
  });

  useEffect(() => {
    Cookies.set(cookieName, isDismissed.toString(), { expires });
  }, [isDismissed, cookieName, expires]);

  return [isDismissed, setIsDismissed];
}

export default useDismissable;
