import { TimelineSlideBlock } from '@/@types/content';
import ContentArea from '@/components/cms/ContentArea';
import Text from '@/components/cms/Text';
import { FC } from 'react';

const TimelineCarouselSlide: FC<TimelineSlideBlock> = ({ copy, images, year }) => (
  <article  data-component={"TimelineCarouselSlide"}>
    <div className="relative z-10 inline-flex rounded-[40px] bg-gray-darker px-4 py-3">
      <Text element="h2" className="heading-5 text-white">
        {year}
      </Text>
    </div>
    <Text className="text-1 mb-20 mt-6 w-[302px] text-white">{copy}</Text>
    <ContentArea
      components={images}
      propertyName="Image"
      className="flex items-start gap-x-6"
      componentsProps={{ className: 'max-w-[650px] max-h-[650px]' }}
    />
  </article>
);

export default TimelineCarouselSlide;
