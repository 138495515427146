import Button from '../Buttons/Button';
import Link from '../Link';

type SearchResultsFilterOverlayFooterProps = {
  clearLabel: string;
  onClear: () => void;
  onSubmit: () => void;
  submitAriaLabel?: string;
  submitLabel: string;
};

const SearchResultsFilterOverlayFooter: React.FC<SearchResultsFilterOverlayFooterProps> = ({
  onClear,
  clearLabel,
  onSubmit,
  submitLabel,
  submitAriaLabel
}) => {
  return (
    <div className="flex h-full items-center justify-between">
      <Link variant="text-link-2" text={clearLabel} onClick={onClear} as="button" />
      <Button
        ariaLabel={submitAriaLabel}
        color="red"
        label={submitLabel}
        size="large"
        className="ml-auto"
        onClick={onSubmit}
      />
    </div>
  );
};

export default SearchResultsFilterOverlayFooter;
