import { RecommendedSearchListBlock } from '@/@types/content';
import { useSelector } from 'react-redux';
import { selectPageLinkPaths } from '@/redux/selectors/pageSelectors';
import { QUERY_PARAMS } from './constants';
import Link from '../Link';

type RecommendedSearchListProps = {
  recommendedSearchList: RecommendedSearchListBlock;
};

const RecommendedSearchList: React.FC<RecommendedSearchListProps> = ({ recommendedSearchList }) => {
  const pageLinkPaths = useSelector(selectPageLinkPaths);
  const { keywords, enable } = recommendedSearchList;

  if (!(enable && keywords && keywords.length > 0)) {
    return null;
  }

  return (
    <>
      <div className="label mb-4 font-bold uppercase text-gray-dark">Recommended searches</div>
      {keywords.map(keyword => (
        <div key={keyword} className="py-4">
          <Link
            href={`${pageLinkPaths.Search}?${QUERY_PARAMS.QUERY}=${keyword}`}
            text={keyword}
            variant="text-link-1"
            className="font-medium no-underline"
            reloadDocument
          />
        </div>
      ))}
    </>
  );
};

export default RecommendedSearchList;
