import { useMemo } from 'react';
import { ActionItem } from '@/components/ui/PDP/ActionBar';

export const useMemsuiteAction = (openMemsuiteModal?: (bool: boolean) => void) => {
  const memsuiteAction: ActionItem | undefined = useMemo(() => {
    if (!openMemsuiteModal) return;
    return {
      label: 'Membership Suite',
      icon: 'users',
      onClick: () => openMemsuiteModal(true)
    };
  }, [openMemsuiteModal]);

  return memsuiteAction;
};
