import { IContent } from '@/@types/cms';
import TopicsAndIndustries from '@/components/ui/PDP/TopicsAndIndustries';
import {
  ProgramCatalogRelations,
  LiveProgramCatalogRelations,
  OnDemandProgramCatalogRelations,
  OnDemandSegmentCatalogRelations
} from '@/hooks/PDP/useProgramCatalogRelations';
import { PublicationCatalogRelations } from '@/hooks/PDP/usePublicationCatalogRelations';

interface IPDPTopicsAndIndustriesLayoutBlockProps {
  variant: IContent;
  catalogRelations: ProgramCatalogRelations | PublicationCatalogRelations;
}

interface Category {
  name: string;
  description: string;
  id: number;
}

const mapCategoryProps = (category: Category) => {
  return {
    description: category.description ?? category.name,
    id: category.id
  };
};
const mapTopicProps = (category: Category) => {
  const categoryProps = mapCategoryProps(category);
  return {
    ...categoryProps,
    url: `${window.env?.CONTENT_DELIVERY_API}/api/client/taxonomy/subject-map/${categoryProps.id}`
  };
};
const mapIndustryProps = (category: Category) => {
  const categoryProps = mapCategoryProps(category);
  return {
    ...categoryProps,
    url: `${window.env?.CONTENT_DELIVERY_API}/api/client/taxonomy/industry-map/${categoryProps.id}`
  };
};
const IPDPTopicsAndIndustriesLayoutBlock = ({
  catalogRelations
}: IPDPTopicsAndIndustriesLayoutBlockProps) => {
  const segmentProduct = (catalogRelations as OnDemandSegmentCatalogRelations)?.segmentProduct;
  const odProgramProduct = (catalogRelations as OnDemandProgramCatalogRelations)?.programProduct;
  const liveProgramProduct = (catalogRelations as LiveProgramCatalogRelations)?.product;
  const bookProduct = (catalogRelations as PublicationCatalogRelations)?.bookProduct;
  const product = segmentProduct ?? odProgramProduct ?? liveProgramProduct ?? bookProduct;

  if (!product) return null;

  const topics = product.majorCategory?.map(mapTopicProps);
  const industries = product.industryCategory?.map(mapIndustryProps);
  return <TopicsAndIndustries topics={topics} industries={industries} />;
};

export default IPDPTopicsAndIndustriesLayoutBlock;
